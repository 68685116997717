import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {  Row, Col, Card, CardBody, Label, Input,
  Form, Button} from "reactstrap";
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import {  getLoginCompanyId, getLoginUserId,
  getLoginStaffId, getWorkspaceId, adminAndStaff,getLoginUserType} from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import Swal from "sweetalert2";
import { getStaff } from "../../Constant/LoginConstant";
import { apiencrypt, apidecrypt, encode } from "../../helper";

const GeneralSettings = () => {
  const [getlanguageId, setgetlanguageId] = useState();
  const [date_formatval, setdate_formatval] = useState();
  const [time_zone_formatval, settime_zone_formatval] = useState();
  const [language, setlanguage] = useState([]);
  const [timezone, settimezone] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    axios.get(ServerUrl.replace("/staff", "") + "/get-timezone").then((timezone) => {
      timezone.data = apidecrypt(timezone.data)
      settimezone(timezone.data.data);
    });
    if (getStaff !== "Staff") {
      axios
        .post(ServerUrl + "/get-user-preference", apiencrypt({
          companyId: getLoginCompanyId,
          userId: getLoginUserId,
          staffId: getLoginStaffId,
          workspaceId: getWorkspaceId,
          date_format: "",
          languageId: "",
          timezoneId: "",
        }))
        .then((userPreference) => {
          userPreference.data = apidecrypt(userPreference.data)
          setdate_formatval(userPreference.data.data.date_format);
          settime_zone_formatval(userPreference.data.data.time_zone_format);
        });

      axios.get(ServerUrl + "/get-languages").then((language) => {
        language.data = apidecrypt(language.data)
        setlanguage(language.data.data);
      });

    } else {
      axios
        .post(ServerUrl + "/get-staff-preference", apiencrypt({
          companyId: getLoginCompanyId,
          userId: getLoginUserId,
          staffId: getLoginStaffId,
          workspaceId: getWorkspaceId,
          date_format: "",
          languageId: "",
          timezoneId: "",
        }))
        .then((userPreference) => {
          userPreference.data = apidecrypt(userPreference.data)
          setdate_formatval(userPreference.data.data.date_format);
          settime_zone_formatval(userPreference.data.data.time_zone_format);
        });

      axios.get(ServerUrl + "/get-languages").then((language) => {
        language.data = apidecrypt(language.data)
        setlanguage(language.data.data);
      });
    }

  }, []);

  const onSelectUpdate = (e, key) => {
    let getNofiySetting = {};
    getNofiySetting["companyId"] = getLoginCompanyId;
    getNofiySetting["userId"] = getLoginUserId;
    getNofiySetting["staffId"] = getLoginStaffId;
    getNofiySetting["workspaceId"] = getWorkspaceId;
    for (var i = 1; i <= 3; i++) {
      if (i != 2) {
        var getDetails = document.getElementById("genSet-" + i);
        getNofiySetting[getDetails.name] = getDetails.value;
      }
    }
    return getNofiySetting;
  };

  const onSubmitHandler = (e) => {
    let notify = onSelectUpdate();
    if (getStaff !== "Staff") {
      axios.post(ServerUrl + "/user-preference", apiencrypt(notify)).then((response) => {
        localStorage.removeItem("dateFormat");
        localStorage.setItem("dateFormat", encode(notify.date_format));

        Swal.fire({
          title: t("generalSettings"),
          text: t("savedSuccessfully"),
          icon: "success",
          timer: 5000,
          button: t("okLabel"),
          confirmButtonColor: "#009688",
        });
      });
    } else {
      axios.post(ServerUrl + "/staff-preference", apiencrypt(notify)).then((response) => {
        localStorage.removeItem("dateFormat");
        localStorage.setItem("dateFormat", encode(notify.date_format));
        Swal.fire({
          title: t("generalSettings"),
          text: t("savedSuccessfully"),
          icon: "success",
          timer: 5000,
          button: t("okLabel"),
          confirmButtonColor: "#009688",
        });
      });
    }
  };

  return (
    <Fragment>
      <Col 
        md="12" 
        lg={getLoginUserType=='user'?6:adminAndStaff == 0 ? "6" : "12" }
        sm="12"
      >
        <Card className="shadow shadow-md" style={{ height: "250px" }}>
          <Row className='m-b-20'>
            <Col md="12">
              <HeaderCard title={t("generalSettings")} />
            </Col>
          </Row>
          <CardBody className="animate-chk" style={{ marginTop: "-15px", marginBottom: "-15px" }}>
            <Form className="needs-validation" noValidate="">
              <Row className="g-3">
                <Col md="6">
                  <Label className="form-label" for="date_format"> {t("dateFormat")} </Label>
                  <Input
                    className="form-control form-control-primary btn-square"
                    name="date_format"
                    type="select"
                    id="genSet-1"
                    placeholder={t("dateFormat")}
                    defaultValue=""
                  >
                    <option value="" disabled> {t("selectDateFormat")} </option>
                    <option value="d M Y"
                      selected={date_formatval == "d M Y" ? "selected" : ""}
                    > 01 Jan 2022
                    </option>
                    <option
                      value="d-m-Y"
                      selected={date_formatval == "d-m-Y" ? "selected" : ""}
                    > DD-MM-YYYY
                    </option>
                    <option
                      value="Y-m-d"
                      selected={date_formatval == "Y-m-d" ? "selected" : ""}
                    > YYYY-MM-DD
                    </option>
                    <option
                      value="Y/m/d"
                      selected={date_formatval == "Y/m/d" ? "selected" : ""}
                    > YYYY/MM/DD
                    </option>
                    <option
                      value="Y M d"
                      selected={date_formatval == "Y M d" ? "selected" : ""}
                    >  2022 Jan 01
                    </option>
                  </Input>
                </Col>
                <Col md="6">
                  <Label className="form-label" for="validationCustom02">{t("time")} </Label>
                  <Input
                    className="form-control form-control-primary btn-square"
                    name="timezoneId"
                    type="select"
                    id="genSet-3"
                    placeholder={t("timeZone")}
                    defaultValue=""
                  >
                    <option value="" selected={time_zone_formatval == '' ? "selected" : ""} disabled>
                      {t("selectTimezone")}
                    </option>
                    {timezone.map((timezone) => (
                      <option selected={time_zone_formatval == timezone.name ? "selected" : ""} 
                        value={timezone.name} >{timezone.timezone}</option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <div className="text-end">
                    <Button className="btn btn-primary btn-sm m-t-20" to="#" 
                      onClick={() => { onSubmitHandler(); }} >
                      {t("save")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default GeneralSettings;

//**************************Code by : Praveen****************************//