import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Button,
} from "reactstrap";

import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6, Image } from "../../AbstractElements";
import {
  getLoginToken,
  getLoginUserType,
  getLoginCompanyId,
  getLoginUserId,
  getLoginStaffId,
} from "../../Constant/LoginConstant";
import {
  allowedLogoFormat,
  allowedIconExtensionsUpload,
  apiencrypt,
  apidecrypt,
  encode,
  capitalizeFirstLowercaseRest
} from "../../../src/helper";
import { useTranslation } from "react-i18next";
/************************** SWEET ALERT , API CALLS *********************************/
import Files from "react-files";
import Swal from "sweetalert2";
import axios from "axios";
import noImage from "../../assets/images/dms/icons/noImage.svg";
// import deleteIcon from "../../assets/images/dms/icons/inquiryDeleteIcon.svg";
import deleteIcon from "../../assets/images/dms/icons/deleteIcon.svg";
import { ServerUrl, maxUploadFileSize,maxUploadCompFileSize } from "../../Constant/index";
/***********************************************************************************/
const CompanySettings = () => {
  if (getLoginCompanyId > 0) {
    window.location.href = "/dashboard";
  }
  const { t } = useTranslation();
  const userId = getLoginUserId;
  const [logo, setLogo] = useState("");
  const [logoNew, setLogoNew] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [contact_person, setContactPerson] = useState("");
  const [contact_number, setContactNumber] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country_id, setCountryId] = useState("0");
  const [country, setCountry] = React.useState([""]);
  const [user_id, setUserId] = useState(userId);
  const [gst_number, setGstNumber] = useState("");
  const [ifsc_code, setIfscCode] = useState("");
  const [pan_number, setPanNumber] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [account_number, setAccountNumber] = useState("");
  const [files, setFiles] = useState("");

  const handleChangeStatus = ({ meta, file, e }, status) => {
    setLogo(file);
  };

  useEffect(() => {
    axios.get(ServerUrl + "/get-countries").then((response) => {
      response.data = apidecrypt(response.data);
      setCountry(response.data.data);
    });
  }, []);

  /************************* SAVE BTN  (FUNCTION) [ API ] ******************************************/
  const handleSubmitHandler = (e) => {
    let valid = companySettingValidation();
    if (Object.keys(valid).length == 0) {
      const companySettings = {
        logo: logoNew,
        company_name,
        contact_person,
        contact_number,
        address1,
        address2,
        city,
        user_id,
        gst_number,
        ifsc_code,
        pan_number,
        country_id,
        state,
        zipcode,
        account_number,
      };

      /****************************************************************************/
      /****************** API CALL (POST) *****************************************/
      axios
        .post(
          ServerUrl + "/company-settings",
          companySettings,
          /*---------------POST LOGO------------*/
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          logo,
            company_name,
            contact_person,
            contact_number,
            address1,
            address2,
            city,
            gst_number,
            ifsc_code,
            pan_number,
            state,
            zipcode,
            account_number,
            user_id,
            country_id;

          /******************* RESPONSE - 200 ( Company registerd successfully) ********************************/
          if (response.data.status_code === 200) {
            localStorage.setItem("companyId", encode(response.data.company_id));
            Swal.fire({
              title: company_name,
              text: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000
            }).then((result) => {
               if(result.isConfirmed){
              // setTimeout(() => {
              window.location.href = "/workspace";
              // }, 100);
               }
              setTimeout(() => {
                window.location.href = "/workspace";
                }, 100);
            });
          } else if (response.data.status_code === 401) {

            /*************************** RESPONSE - 401 ( Field missing) ****************************************/
            Swal.fire({
              title:
                t(response.data.errors.logo) ||
                t(response.data.errors.company_name) ||
                t(response.data.errors.state) ||
                t(response.data.errors.contact_person) ||
                t(response.data.errors.contact_number) ||
                t(response.data.errors.address1) ||
                t(response.data.errors.address2) ||
                t(response.data.errors.city) ||
                t(response.data.errors.zipcode) ||
                t(response.data.errors.account_number) ||
                t(response.data.errors.ifsc_code) ||
                t(response.data.errors.gst_number) ||
                t(response.data.errors.pan_number),
              text: t("fieldMissing"),
              icon: "warning",
              button: t("okLabel"),
            });
          }
        })

        /*************************** RESPONSE - 400 ( Company already Exists) ******************************/
        .catch((err) => {
          Swal.fire({
            title: err.t(response.data.message),
            icon: "warning",
            button: t("okLabel"),
          });
        });
    } else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  };
  const deleteFile = (e) => {
    Swal.fire({
      title: t("areYouSureToDeleteThisImage"),
      // text: t("cantRevertBack"),
      icon: "warning",
      showCancelButton: true,
      button: t("okLabel"),
    }).then((result) => {
      if (result.isConfirmed) {
        setFiles([]);
        setLogoNew("");
        return files;
      }
    });
  };

  const onFilesChange = (files) => {
    const filteredfiles = files.filter((e, index) => {
      if (allowedIconExtensionsUpload.exec(e.name)) {
        const getFileSize = Math.round(e.size / 1024);
        if (getFileSize > maxUploadCompFileSize) {
          Swal.fire({
            title: t("fileSizeExceeded"),
            text: t("uploadaFileSize") + maxUploadCompFileSize  + " KB",
            icon: "warning",
            button: t("okLabel"),
          });
          asv = 1;
          return false;
        }

        return true;
      } else {
        Swal.fire({
          title: t("wrongFormatTitleAlert"),
          text: t("uploadFollowingFileFormat") + allowedLogoFormat,
          icon: "warning",
          button: t("okLabel"),
        });
        asv = 1;
        return false;
      }
    });

    setFiles(files);
    setLogoNew(files[0]);
    return files;
  };

  const onFilesError = (error, file) => {
    setFiles(file);
    setLogoNew("");
  };

  const [errors, setValidErrors] = useState({});
  const companySettingValidation = (data) => {
    let errors = {};
    if (!company_name) {
      errors.company_name = "enterCompanyName";
    } else if (!company_name.match(/^[@&0-9a-zA-Z_-\s]+$/g)) {
      errors.company_name = "numbersCharactersAllowed";
    }
    if (!contact_person) {
      errors.contact_person = "enterContactPersonName";
    } else if (!contact_person.match(/^[a-zA-Z\s]+$/g)) {
      errors.contact_person = "allowedCharactersSpace";
    }

    //   if(!/^[0-9]+$/.test(contact_number)){

    //     errors.contact_number = "Numbers only allowed";
    //   }else{
    //   if (((contact_number.length!=null && contact_number.length!='null' && contact_number.length!=''&& contact_number.length!=10))){
    //     errors.contact_number = "Please Enter 10 Digits Mobile Number";
    //   }else if(contact_number!=''|| contact_number.length!=10){
    //     errors.contact_number = 'Please Enter Contact Number';
    //   }
    // }
    if (!contact_number || contact_number.length == 0) {
      errors.contact_number = "enterMobileNumber";
    } else if (!/^[0-9]+$/.test(contact_number)) {
      errors.contact_number = "numbersOnlyAllowed";
    }
    if (
      (contact_number.length != null &&
        contact_number.length != "null" &&
        contact_number.length != "" &&
        parseInt(contact_number.length) < 10) ||
      parseInt(contact_number.length) > 15
    ) {
      errors.contact_number = "enter10DigitsMobileNumber";
    }
    //  if(!address1){
    //   errors.address1 = 'Please Enter Address1 ';
    //  }
    //  if(!address2){
    //   errors.address2 = 'Please Enter Address2 ';
    //  }
    //  if(!city){
    //   errors.city = 'Please Enter City ';
    //  }
    //  if(!state){
    //   errors.state = 'Please Enter State ';
    //  }
    //  if(!zipcode){
    //   errors.zipcode = 'Please Enter Postal Code';
    //  }

    setValidErrors(errors);
    return errors;
  };

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs
          mainTitle={t("companySetting")}
          parent="Company Setting"
          title={t("companySetting")}
        />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
            <CardBody>
              <H6 attrH6={{ className: "pb-2" }}>
                          {t("companyLogo")}
                        </H6>              
           
                  <FormGroup>
                    <Row>
                      <Col sm="12">                        
                          <Row>
                            {files.length > 0 ? (
                              files.map((file) => (
                                <>
                                  <Col lg="1" md="2" sm="2">
                                    <div
                                      className="profile-pic"
                                      style={{
                                        border: "2px solid #E4E6EF",
                                        borderRadius: "10px",
                                        padding: "10px",
                                      }}
                                    >
                                      <img
                                        src={file.preview.url}
                                        width="110px"
                                        height="110px"
                                        style={{ borderRadius: "2px" }}
                                      />

                                      <div className="edit m-t-30 m-r-30">
                                        <img
                                          src={deleteIcon}
                                          width="30px"
                                          height="30px"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            deleteFile(files);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              ))
                            ) : (
                              <Col lg="2" md="12" sm="12">

                                <div
                                  className="upload-logo"
                                  style={{
                                    border: "2px solid #E4E6EF",
                                    borderRadius: "10px",
                                    padding: "10px",
                                  }}
                                >
                                  <span className="logoimg" >
                                    <img
                                      src={noImage}
                                    ></img>
                                  </span>
                                </div>
                              </Col>
                            )}
                            {files.length == 0 ? (
                              <>
                              <Col
                                lg="3"
                                md="6"
                                sm="6"
                                xs="6"
                                className="m-t-30 "
                              >
                                <Files
                                  className="files-dropzone fileContainer"
                                  onChange={onFilesChange}
                                  onError={onFilesError}
                                  accept={[".png,.jpg,.jpeg"]}
                                  multiple={false}
                                  canCancel={false}
                                  clickable
                                >
                                  
                                  <Button className="btn btn-primary f-left m-r-10 m-b-5 upload-button">
                                    {t("uploadLogo")}
                                  </Button>                                  
                                </Files>
                             
                              </Col>
                              <>
                               <span style={{ color:"#B7B8BF" }}>{t("onlyAllowedFileFormat")}</span>
                              </>
                               
                               </>
                            ) : (
                              ""
                            )}
                          </Row>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col sm="12">
                        <H6 attrH6={{ className: "pb-2" }}>
                          {t("companyInfo")}
                        </H6>
                      </Col>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("companyName")}
                            <sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            className={`${errors.company_name && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("companyName")}
                            name="company_name"
                            value={company_name}
                            onChange={(e) => setCompanyName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                            maxLength="30"
                            required="true"
                          />
                          {errors.company_name && (
                            <span className="error-msg">
                              {t(errors.company_name)}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("contactPerson")}{" "}
                            <sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            className={`${errors.contact_person && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("contactPerson")}
                            name="contact_person"
                            value={contact_person}
                            onChange={(e) => setContactPerson(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                            maxLength="30"
                          />
                          {errors.contact_person && (
                            <span className="error-msg">
                              {t(errors.contact_person)}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("contactNumber")}{" "}
                            <sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            className={`${errors.contact_number && "error-valid"
                              }  form-control`}
                            type="tel"
                            placeholder={t("contactNumber")}
                            name="contact_number"
                            value={contact_number}
                            onChange={(e) => setContactNumber(e.target.value)}
                            maxLength="15"
                          />
                          {errors.contact_number && (
                            <span className="error-msg">
                              {t(errors.contact_number)}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup>
                    <Row>
                      <Col sm="12">
                        {" "}
                        <H6 attrH6={{ className: "pb-2" }}>
                          {t("addressInfo")}
                        </H6>
                      </Col>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("addressline1")}
                          </Label>
                          <Input
                            className={`${errors.address1 && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("addressline1")}
                            name="address1"
                            value={address1}
                            onChange={(e) => setAddress1(e.target.value)}
                            maxLength="100"
                          />
                        </FormGroup>

                        {errors.address1 && (
                          <span className="error-msg">{errors.address1}</span>
                        )}
                      </Col>

                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("addressline2")}
                          </Label>
                          <Input
                            className={`${errors.address2 && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("addressline2")}
                            name="address2"
                            value={address2}
                            onChange={(e) => setAddress2(e.target.value)}
                            maxLength="100"
                          />
                        </FormGroup>
                        {errors.address2 && (
                          <span className="error-msg">{errors.address2}</span>
                        )}
                      </Col>

                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("city")}
                          </Label>
                          <Input
                            className={`${errors.city && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("city")}
                            name="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                            maxLength="20"
                          />
                          {errors.city && (
                            <span className="error-msg">{errors.city}</span>
                          )}
                          <Input
                            className="form-control"
                            type="hidden"
                            placeholder={t("email")}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            maxLength="30"
                          />
                          <Input
                            className="form-control"
                            type="hidden"
                            placeholder={t("country")}
                            name="country_id"
                            value="1"
                            onChange={(e) => setCountryId(e.target.value)}
                            maxLength="50"
                          />
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            {t("statePrefecture")}
                          </Label>
                          <Input
                            type="text"
                            name="select"
                            placeholder={t("statePrefecture")}
                            className={`${errors.state && "error-valid"
                              }  form-control`}
                            defaultValue="1"
                            value={state}
                            onChange={(e) => setState(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                            maxlength="30"
                          ></Input>
                          {errors.state && (
                            <span className="error-msg">{errors.state}</span>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup id="country">
                          <Label>{t("country")}</Label>
                          <Input
                            type="select"
                            placeholder={t("selectCountry")}
                            className="form-control digits"
                            name="countryId"
                            onChange={(e) => setCountryId(e.target.value)}
                          >
                            <option selected disabled>
                              {t("selectCountry")}{" "}
                            </option>
                            {country.map((country) => (
                              <option value={country.id}>{country.name}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("postalCode")}
                          </Label>
                          <Input
                            className={`${errors.zipcode && "error-valid"
                              }  form-control`}
                            type="text"
                            placeholder={t("postalCode")}
                            name="Postal Code"
                            value={zipcode}
                            onChange={(e) => setZipCode(e.target.value)}
                          />
                          {errors.zipcode && (
                            <span className="error-msg">{errors.zipcode}</span>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* 
                  <FormGroup>
ss                  <Row>
                  <Col sm="12"> <H6 attrH6={{ className: 'pb-2' }}>
                    Account Number</H6></Col>
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          Account Number
                        </Label>
                        <Input
                          className="form-control"
                          type="number"
                          placeholder="Account Number"
                          value={account_number}
                          onChange={ (e) =>  setAccountNumber(e.target.value) }
                        />
                      </FormGroup>
                    </Col>
  
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          IFSC Code
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="IFSC Code"
                          value={ifsc_code}
                          onChange={ (e) =>  setIfscCode(e.target.value) }
                        />
                      </FormGroup>
                    </Col>
  
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          GST Number
                          </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="GST Number"
                          value={gst_number}
                          onChange={ (e) =>  setGstNumber(e.target.value) }
                        />
                      </FormGroup>
                    </Col>
  
                    <Col sm="3">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          PAN Number 
                          </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder="PAN Number"
                          value={pan_number}
                          onChange={ (e) =>  setPanNumber(e.target.value) }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </FormGroup> */}

                  <Row>
                    <Col sm="12">
                      <FormGroup className="mb-0 f-right">
                        <Button
                          attrBtn={{ color: "success", className: "me-3" }}
                          onClick={() => handleSubmitHandler()}
                        >
                          {t("saveAndContinue")}
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
               
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default CompanySettings;
/***** CODE BY : RAMANAN M  *****/
