import { Container, Row,Col,Card,CardBody,CardFooter,Input, Label, Media  } from 'reactstrap';
import React, { Fragment, useState, useEffect } from 'react';
import { Breadcrumbs, } from '../../../AbstractElements';
import axios from 'axios';

import { BackgroundColor, PaddingBottom, ServerUrl } from '../../../Constant/index';
import {
    getLoginCompanyId, getLoginUserId, getWorkspaceId,
    getLoginStaffId, getStaff
} from '../../../Constant/LoginConstant';
import { apiencrypt, apidecrypt } from "../../../helper";
import { useTranslation } from 'react-i18next'
import ProductiondelayTable from './ProductiondelayTable';
import ProductionStatusTable from './ProductionStatusTable';
import TaskStatusTable from './TaskStatusTable';
import TaskdelayTable from './TaskdelayTable';
import DashboardOrderDetails from '../DashboardOrderDetails';
import switchleft_icon from '../../../assets/images/dms/icons/switchleft_icon.svg';
import switchright_icon1 from '../../../assets/images/dms/icons/switchright_icon1.svg';
import table_icon from '../../../assets/images/dms/icons/table_icon.svg';
import Graphical_icon from '../../../assets/images/dms/icons/Graphical_icon.svg';
import OrderStatusChart from '../OrderStatusChart';
import WelcomeDashboard from '../WelcomeDashboard';


const NewDashboardTable = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [user_id, setUser_id] = useState(getLoginUserId);
    const [notifications, setnotifications] = useState([]);
    const [onGoingList, setonGoingList] = useState([]);
    const [taskdelay, settaskdelay] = useState([]);
    const [delayproduction, setdelayproduction] = useState([]);
    const [productionStatus, setproductionStatus] = useState([]);
    const [dashboardOrderStatus, setDashboardOrderStatus] = useState([]);
    const [orderstatusid, setgeOrderstatusId] = useState();
    const [tasksstatus, setgettasksstatus] = useState([]);
    const [switchDashboard, setSwitchDashboard] = useState(0);
    const [dashboardWidgetsDashboard, setgetdashboardWidgetsDashboard] = useState([]);
    const { t } = useTranslation();
    const staff_id = getLoginStaffId;
    var requestData;
    getStaff === "Staff" ? requestData = { company_id, workspace_id, staff_id } : requestData = { company_id, workspace_id, user_id }
    const reqData = requestData;
    useEffect(async () => {
        
      axios
          .post(ServerUrl + "/get-dashboard-settings", apiencrypt({
              company_id: getLoginCompanyId,
              user_id: getLoginUserId,
              staff_id: getLoginStaffId,
              workspace_id: getWorkspaceId,
          }))
          .then((response) => {
              response.data = apidecrypt(response.data)
              setgetdashboardWidgetsDashboard(response.data.data.dashboardWidgets);
          });
     
  }, []);

    return (
        <Fragment>
            <div className="dashboardBackground"> 
                <Row className="pgbgcolor" style={{ marginTop: '100px' }}>
                    <Col>
                        <Breadcrumbs className="m-t-15" mainTitle='Dashboard' parent="Dashboard"
                        title="Dashboard" subTitle="Overall View" /> 
                    </Col>
                    <Col style={{ marginRight: '12px' }}>
                        <span  style={{float:"right"}}>
                            <img style={{width:"33px"}} className=' m-b-15 p-r-10 cursor-pointer'  
                                title="ChartView" tooltip="ChartView" alt="ChartView"src={Graphical_icon}></img>
                            <img width="40px" src={switchright_icon1} 
                                 title="Click" tooltip="Click" alt="Click" 
                                 className='cursor-pointer p-r-10 p-b-10' onClick={(e) => { setSwitchDashboard(1) }}></img>
                            <img style={{ width: "20px" }} title="TableView" tooltip="TableView" alt="TableView" 
                                 className='m-b-10 p-l-0 cursor-pointer p-b-5' src={table_icon} ></img>
                        </span>    
                    </Col>
                </Row>
            
                <Container fluid={true} className="general-widget topaln m-t-10" >
                    <Row>
                        {/* <DashboardOrderDetails/>
                        <ProductionStatus/>
                        <ProductionDelay geOrderstatusId={setgeOrderstatusId}/>
                        <Task/>
                        <DelayedTask />
                        <OrderStatusChart geOrderstatusId={orderstatusid}/> */}
                        {/* <ApexChart/> */}
                        <DashboardOrderDetails/>
                        {switchDashboard==1?  window.location.href = '/dashboard':""}
                        {(
                            (dashboardWidgetsDashboard.includes("1") != "")
                            || (dashboardWidgetsDashboard.includes("2") != "") ||
                            (dashboardWidgetsDashboard.includes("3") != "")
                            || (dashboardWidgetsDashboard.includes("4") != "") ||
                            (dashboardWidgetsDashboard.includes("5") != "")
                        
                        )
                            ?
                            <>
                            {switchDashboard==false
                            ?
                        <>
                            {dashboardWidgetsDashboard.includes("1") ?
                            (<ProductionStatusTable/>)
                            : ("")}
                            {dashboardWidgetsDashboard.includes("2") ?
                                ( <ProductiondelayTable/>)
                                : ("")}
                            {dashboardWidgetsDashboard.includes("3") ?
                                ( <TaskStatusTable/>)
                                : ("")}
                            {dashboardWidgetsDashboard.includes("4") ?
                                (<TaskdelayTable/>)
                                : ("")}
                            {dashboardWidgetsDashboard.includes("5") ?
                                ( <OrderStatusChart/>
                                )
                                : ("")}
                            </>:
                            ""}
                            
                            </>
                            :
                            <WelcomeDashboard />
                        }

                    </Row>
                
                </Container>
            </div>
        </Fragment>
    );
};

export default NewDashboardTable;

//**************************Code by : Praveen****************************//