import {
  Container, Row, Col, CardBody, Card, CardHeader, FormGroup, Form,
  Label, Input, Button
} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, } from "../../AbstractElements";
import { getLoginCompanyId, getWorkspaceName } from '../../Constant/LoginConstant';
import { ServerUrl } from "../../Constant/index";
import Swal from 'sweetalert2';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt, encode,capitalizeFirstLowercaseRest } from "../../helper";

const Workspace = () => {
  if (getWorkspaceName) {
    window.location.href = '/dashboard';
  }
  const companyId = getLoginCompanyId;
  const [company_id, setCompanyId] = useState(companyId);
  const [workspace_type_value, setWorkspaceTypeValue] = React.useState(['']);
  const [workspace_type, setWorkspaceType] = React.useState('');
  const [name, setName] = useState('');
  const [loading, setloading] = useState(true);
  const { t } = useTranslation();

  // FORM GETS SUBMITTED HERE (Function)
  const onhandleSubmit = (e) => {
    setloading(false);
    let retval = workSpaceValidation();
    if (Object.keys(retval).length == 0) {
      const workspace = { name, workspace_type, company_id };

      // API CALL (POST) 
      axios
        .post(ServerUrl + "/create-workspace", apiencrypt(workspace))
        .then((response) => {
          response.data = apidecrypt(response.data)
          // API CALL [WORKSPACE ADDED SUCCESSFULLY]
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer: 5000,
            })
              .then((result) => {
                if(result.isConfirmed){
                // setTimeout(() => {
                localStorage.setItem("workspaceId", encode(response.data.workspace_id));
                localStorage.setItem("workspaceType", encode(response.data.workspaceType));
                localStorage.setItem("workspaceName", encode(response.data.workspaceName));
                localStorage.setItem("language", response.data.language);
                window.location.href = '/dashboard';
                // }, 100);
                }
                setTimeout(() => {
                  localStorage.setItem("workspaceId", encode(response.data.workspace_id));
                  localStorage.setItem("workspaceType", encode(response.data.workspaceType));
                  localStorage.setItem("workspaceName", encode(response.data.workspaceName));
                  localStorage.setItem("language", response.data.language);
                  window.location.href = '/dashboard';
                  }, 100);
              })
          }

          if (response.data.status_code === 401) {
            Swal.fire({
              title: response.data.errors.name,
              icon: "warning",
              button: t("okLabel"),
            });
            setloading(true);
          } else if (response.data.status_code === 201) {
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              window.location.href = '/dashboard';

            })
          }
        }
        )
    }
  }

  useEffect(() => {
    axios.get(ServerUrl + "/workspace-type")
      .then((response) => {
        response.data = apidecrypt(response.data)
        setWorkspaceTypeValue(response.data.data)
      });
  }, [])

  const [errors, setValidErrors] = useState({});

  /******************** VALIDATION ERRORS ******************************/
  const workSpaceValidation = (data) => {
    let errors = {};
    if (!name.trim()) {
      errors.name = ('enterWorkspaceName');
      setloading(true);
    }
    //  else if (!(name).match(/^[0-9a-zA-Z_-]+$/g)) {
    //   errors.name = 'Allowed only Numbers,Characters and _ - are allowed';
    //  }
    //  if (!workspace_type) {
    //   errors.workspace_type = 'Please Select Workspace Type';
    //  }

    setValidErrors(errors);
    return errors;
  };

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('workSpace')} parent="Workspace" title={t('workSpace')} />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5> {t("createWorkspaceName")}</H5>
              </CardHeader>
              <CardBody>
                  <FormGroup>
                    <Row>
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("workspaceName")}
                          </Label>
                          <Input
                            className={`${errors.name && 'error-valid'}  form-control`}
                            type="text"
                            placeholder={t("workspaceName")}
                            name="workspace_name"
                            onChange={(e) => setName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                          />
                          {errors.name && (
                            <span className="error-msg">{t(errors.name)}</span>
                          )}
                        </FormGroup>
                      </Col>
                      {/* <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup id='workspace_type'>
                          <Label>Workspace Type</Label>
                          <Input type="select" placeholder={t("selectStatus")}
                            className={`${errors.workspace_type && 'select-error-valid'}  form-control digits`}
                            name="workspace_type_value"
                            onChange={(e)=>setWorkspaceType(e.target.value)}>  
                                  <option selected disabled> Select Workspace Type </option> 
                                     {workspace_type_value.map( workspace_type_value => (
                                        <option value={workspace_type_value.name}>
                                          {workspace_type_value.name}
                                        </option>
                                      )
                                      )
                                      }
                          </Input>
                          {errors.workspace_type && (
                         <span  className="error-msg">{errors.workspace_type}</span>
                        )}
                        </FormGroup>
                        </Col> */}
                      <Col sm="12" md="4" lg="4" xs="12">
                        <FormGroup className="p-t-30">
                          <Label htmlFor="exampleInputPassword2">
                          </Label>
                          <Button attrBtn={{ color: "success", className: "me-3" }} disabled={!loading} onClick={() => onhandleSubmit()}>
                            {!loading ? t("loading") : t("saveAndContinue")}
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );

};
export default Workspace;
/***** CODE BY : RAMANAN M  *****/
