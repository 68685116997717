import { Container, Form, Label, Card, CardBody, Col, Row, Input, Button,Pagination, PaginationItem, PaginationLink } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs} from "../../../AbstractElements";
import axios from "axios";
import { Link } from "react-router-dom";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId } from "../../../Constant/LoginConstant";
import noData from "../../../assets/images/dms/nodata.png"
import DataInputLogTable from "./DataInputLogTable";
import { useTranslation } from 'react-i18next';
import { PaginationWithIcons, Previous, Next } from '../../../Constant';
const DataInputLogs = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [errors, setValidErrors] = useState({});
    const [dailyProdData, setDailyProdData] = useState([]);
    const { t } = useTranslation();   
    const [toOpen , setToOpen] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [links, setlinks] = useState([]);

    useEffect(() => {
            
    }, []);
    
    /* The Specific Style Numbers Ends*/     
    const getReportDetails= (page) => {
        let detailToSend = {
            //company_id: company_id,
            //workspace_id: workspace_id,
            start_date : document.getElementById("startDate").value,
            end_date : document.getElementById("endDate").value,
            page : page
        };
        axios
            .post(ServerUrl + "/get-daily-prod-report-log", detailToSend)
            .then((response) => {
                if(response.data.status_code === 200){
                   // setNecessaryDetails(response.data.data.necessaryDetails);
                    setDailyProdData(response.data.data.data);
                    setlinks(response.data.data.links)
                    setToOpen(1);
                }
            });
    }
    return (
        <Fragment>
            <Row  className="pgbgcolor">
            <Breadcrumbs mainTitle={t('dataInputReports')} 
                         parent="Data-Input Reports" 
                         title={t('dataInputReports')}/>
            </Row>
                <Container fluid={true} className="general-widget topaln">
                <Card className="m-b-20">
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                        <Col md={4} lg={3}>
                            <Label>{t("startDate")}</Label>
                            <Input type="date" id="startDate"
                            onChange={(e)=>{
                            setStartDate(e.target.value);
                            document.getElementById("endDate").setAttribute("min",e.target.value);
                            }}
                            onKeyDown={(e) => {
                            e.preventDefault();
                            }}
                            defaultValue={startDate}
                            max={new Date().toISOString().split('T')[0]}>
                            </Input>
                        </Col>
                        <Col md={4} lg={3}>
                            <Label>{t("endDate")}</Label>
                            <Input type="date" id="endDate" 
                            onChange={(e)=>{
                            setEndDate(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                }}
                            defaultValue={endDate}
                            max={new Date().toISOString().split('T')[0]}> 
                            </Input>
                        </Col>
                            
                            <Col md="4" lg="3" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" 
                                        onClick={() => getReportDetails()}>{t("search")}</Button>
                                <Link to="/datainputlogs" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Container>
                {toOpen === 1 ?  dailyProdData.length>0 ?
                    <>
                        <DataInputLogTable dailyProdData={dailyProdData}/>

                        <Pagination aria-label="Page navigation example" className="pagination-primary f-right" >
                        {
                            links.map((link) =>(

                                (link.label > 0 || link.label=='...') ?

                                link.active ? <PaginationItem active > 
                                <PaginationLink style={{cursor:"inherit"}}>{link.label}
                                    </PaginationLink>
                                </PaginationItem>
                                : <PaginationItem>
                                    <PaginationLink onClick={() => getReportDetails(link.label)}>{link.label}
                                    </PaginationLink>
                                </PaginationItem>
                                :""
                            ))
                        }
                        </Pagination>
                    </>
                : <>
                    <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                        <img src={noData} style={{ width:"200px"}} />
                        <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                    </div>
                </> : ""}
        </Fragment>
    );
}

export default DataInputLogs;
