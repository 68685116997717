import React, { Fragment, useState, useEffect } from 'react';
import {
    Col, CardBody, Card, Container, Row, CardHeader,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { H4, H3, LI, P, UL } from '../AbstractElements';
import { ServerUrl } from '../Constant';
import dmslogtag from "../assets/images/dms/dms-log-with-tag.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt, decode } from "../helper";
import { getLanguage, getLoginCompanyId, getLoginUserId, getPlanStatus, getEmail }
    from "../../src/Constant/LoginConstant";
import failurePaymentStatus from '../../src/assets/images/dms/icons/failedPaymentStatus.svg';
import { useSearchParams } from "react-router-dom";

const FailurePaymentStatus = ({ }) => {
    const { t, i18n } = useTranslation();
    const [langdropdown, setLangdropdown] = useState(false);
    const [selected, setSelected] = useState(i18n.language=='jp'?'jp':'en');
        
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentStatus, setPaymentStatus] = useState((searchParams.get("type")));

    /**************** SET LANGUAGE *******************/
    const changeLanguage = lng => {
        if( lng === 'EN'){
          i18n.changeLanguage('en');
          setSelected('en');
        }
        if( lng === 'JP'){
          i18n.changeLanguage('jp');
          setSelected('jp');
        }
        if( lng === 'BD'){
          i18n.changeLanguage('bd');
          setSelected('bd');
        }
       if( lng === 'IN'){
          i18n.changeLanguage('in');
          setSelected('in');
        }
    };

    const flagSelect = (lng) =>{
        if( lng === 'en'){
          return 'us'
        }
        if( lng === 'jp'){
          return 'jp'
        }
        if( lng === 'bd'){
          return 'bd'
        }
       if( lng === 'in'){
          return 'in'
        }
      }


    /**************** UPDATE LANGUAGE  *******************/
    const LanguageSelection = (language) => {
        if (language) {
            setLangdropdown(!language);
        } else {
            setLangdropdown(!language);
        }
    };

    /**************** API (GET) [To show the values in the card ]******/
    useEffect(() => {
        if (getLanguage) {
            if (getLanguage === 'en') {
                i18n.changeLanguage('en');
                setSelected('EN');
            }
            if (getLanguage === 'jp'){
                i18n.changeLanguage('jp');
                setSelected('JP');
            } 
            if( getLanguage === 'bd'){
                i18n.changeLanguage('bd');
                setSelected('bd');
                }
            if( getLanguage === 'in'){
            i18n.changeLanguage('in');
            setSelected('in');
            }
        }
        else {

            i18n.changeLanguage(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');
            setSelected(i18n.language=='jp' ? 'JP' : i18n.language=='bd' ? 'BD' :i18n.language=='in'?'IN':'EN');
        }
    }, [getLanguage]);

    const apiCallFailurePlanPayment = () =>{
        if( paymentStatus == "sling" ){
            var apiCallEditParams = {};
            apiCallEditParams['user_email'] = getEmail;
            apiCallEditParams['type'] = 'firstTime';
            apiCallEditParams['company_id'] = getLoginCompanyId;
            axios
            .post(ServerUrl + "/confirm-plan-payment", apiencrypt(apiCallEditParams))
            .then((response) => {
                response.data = apidecrypt(response.data)
                    window.location.href= '/adminlogin'
            });  
        } else {
            var apiCallParams = {};
            apiCallParams['user_id'] = getLoginUserId;
            apiCallParams['type'] = 'updation';
            apiCallParams['company_id'] = getLoginCompanyId;
            axios
            .post(ServerUrl + "/confirm-plan-payment", apiencrypt(apiCallParams))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code === 200) {
                    getPlanStatus == "Active" ? 
                    window.location.href= '/dashboard'
                    :
                    window.location.href= '/adminlogin'
                } 
            });  
        }
    };
    
  
      
    /*********** HTML (NAVBAR, CARDHEADER, CARD BODY etc)  ************/
    return (
        <>   
            <Card>
            <CardHeader className="pb-0">
                <Row>
                    <Col >
                        <img src={dmslogtag} width="150" />
                    </Col>
                    <Col>
                    <Row className="m-r-50 f-right " style={{ backgroundColor: '#f2f2f2' }}>
                        <LI attrLI={{ className: 'onhover-dropdown m-l-10' }}>
                                <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                                    <div className="current_lang">
                                        <div className="lang d-flex" onClick={() => LanguageSelection(langdropdown)}>
                                        <i className={`flag-icon flag-icon-${flagSelect((i18n.language.toLowerCase()))}`}></i> 
                                        <span className="lang-txt m-l-10">{selected.toUpperCase()}</span>
                                            <i style={{ marginTop: '3px'}} className="fa fa-chevron-down m-l-10"></i>
                                        </div>
                                    </div>
                                    <div className={`more_lang onhover-show-div ${langdropdown ? 'active' : ''}`}>
                                    <div className="lang" onClick={() => {changeLanguage('EN')}}>
                                        <i className="flag-icon flag-icon-us"></i>
                                        <span className="lang-txt m-l-10">English</span>
                                    </div>
                                    <div className="lang" onClick={() => {changeLanguage('JP')}}>
                                        <i className="flag-icon flag-icon-jp"></i>
                                        <span className="lang-txt m-l-10">Japanese</span>
                                    </div>
                                    {/* <div className="lang" onClick={() => {changeLanguage('BD')}}>
                                        <i className="flag-icon flag-icon-bd"></i>
                                        <span className="lang-txt m-l-10">Bengali</span>
                                    </div>
                                    <div className="lang" onClick={() => {changeLanguage('IN')}}>
                                        <i className="flag-icon flag-icon-in"></i>
                                        <span className="lang-txt m-l-10">Hindi</span>
                                    </div> */}
                                    </div>
                                </div>
                            </LI>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            </Card>
            
            <Col sm="6" md="6" lg="6" xl="6" xxl="6" 
                 className="6 offset-sm-3">
            <Card>
                <CardBody className="grid-showcase grid-align">
                    <Row className="align-items-center">
                <div>
                        <div className="text-center">
                            <img  src={failurePaymentStatus} />
                        </div>
                        <H4> <span style={{ fontSize: '25px', color: '#F34850' }}> {t("paymentFailure")} </span></H4>
                        <span style={{ fontSize: '25px', color: '#242934' }}> {t("oops")} </span>
                        <span style={{ fontSize: '15px', color: '#242934' }}> {t("somethingWentWrong")} </span>
                        <span style={{ fontSize: '15px', color: '#242934' }}> {t("tryAgain")} </span>
                        <Col className="text-center">
                        <button
                        style={{ backgroundColor: '#F34850', borderRadius: '25px', color: '#fff'}}  
                        className="btn"
                        onClick={() => {
                            apiCallFailurePlanPayment()
                        }}
                        > {t("continue")} </button>
                        </Col>
                </div>
                </Row>
                </CardBody>
            </Card>
            </Col>
        </>
    );
};

export default FailurePaymentStatus;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/