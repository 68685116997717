import React, { useState,useEffect } from "react";

import {
  Form, Label, Card, ModalBody, Col, Row, Input, InputGroup,
  InputGroupText, Modal, FormGroup, ModalHeader, Table,Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InquiryViewIcon from "../../../assets/images/dms/icons/inquiryViewIcon.svg"; 
import editIcon from "../../../assets/images/dms/icons/inquiryEditIcon.svg";
import { Color } from "../../../Constant";
import { Breadcrumbs, H6 } from "../../../AbstractElements";
import {
  getLoginCompanyId,
  getWorkspaceId,
  getLoginUserId,
  getWorkspaceType,
  getLoginUserType,
  getStaff,
  getLoginStaffId,
  getStaffPermission,
} from "../../../Constant/LoginConstant";
import Select from 'react-select'
import axios from "axios";
import {  ServerUrl } from "../../../Constant";
import { apidecrypt, apiencrypt } from '../../../helper';
import Swal from 'sweetalert2';

const AddVendorModal = ({ toggleViewVendorList, toggleadd, modal, toggle, setVendorList }) => {
  const { t } = useTranslation();
  const toggleAddVendorInfo = () => setModalAddVendor(!modalAddVendor);
  const [modalAddVendor, setModalAddVendor] = useState(false);
  const [vendarName, setVendarName] = useState("");
  const [websiteName, setWebsiteName] = useState("");
  const [headOfficeAddress, setHeadOfficeAddress] = useState("");
  const [factoryAddress, setFactoryAddress] = useState("");
  const [mdname, setMdname] = useState("");
  const [mobileNumber, setMdMobileNumber] = useState("");
  const [mdEmailId, setMdEmailId] = useState("");
  const [salesEmailId, setSalesEmailId] = useState("");
  const [salesMobileNumber, setSalesMobileNumber] = useState("");
  const [salesName, setSalesName] = useState("");
  const [accountsMobileNumber, setAccountsMobileNumber] = useState("");
  const [accountsEmailId, setAccountsEmailId] = useState("");
  const [accountsName, setAccountsname] = useState("");
  const [productionMobileNumber, setProductionMobileNumber] = useState("");
  const [productionEmailId, setProductionEmailId] = useState("");
  const [productionName, setProductionName] = useState("");
const toggleviewVendorinfo = () => setModalViewInfo(!modalViewInfo);
const [modalViewInfo, setModalViewInfo] = useState(false);
const [errors, setValidErrors] = useState({});

const companySettingValidation = (data) => {
  let errors = {};
    if(!vendor_name){
      errors.vendor_name = t('plsEnterVendorName');
    }

    if(!headOfficeAddress){
      errors.headOfficeAddress = t('plsEnterOfficeAddress');
    }

    if(mobileNumber){
      if (!/^[0-9]+$/.test(mobileNumber)) {
        errors.mobileNumber = ("numbersOnlyAllowed");
    } else {
      if( (mobileNumber.length != null &&  mobileNumber.length != 'null' && 
            mobileNumber.length != '' && 
            (parseInt(mobileNumber.length) < 10 ) || (parseInt(mobileNumber.length) > 15 ))){
            errors.mobileNumber = t("enter10DigitsMobileNumber");
      }
    }}

    if(salesMobileNumber){
      if (!/^[0-9]+$/.test(salesMobileNumber)) {
        errors.salesMobileNumber = ("numbersOnlyAllowed");
    } else {
      if( (salesMobileNumber.length != null &&  salesMobileNumber.length != 'null' && 
            salesMobileNumber.length != '' && 
            (parseInt(salesMobileNumber.length) < 10 ) || (parseInt(salesMobileNumber.length) > 15 ))){
            errors.salesMobileNumber = t("enter10DigitsMobileNumber");
      }
    }}

    if(accountsMobileNumber){
      if (!/^[0-9]+$/.test(accountsMobileNumber)) {
        errors.accountsMobileNumber = ("numbersOnlyAllowed");
    } else {
      if( (accountsMobileNumber.length != null &&  accountsMobileNumber.length != 'null' && 
      accountsMobileNumber.length != '' && 
            (parseInt(accountsMobileNumber.length) < 10 ) || (parseInt(accountsMobileNumber.length) > 15 ))){
            errors.accountsMobileNumber = t("enter10DigitsMobileNumber");
      }
    }}

    if(productionMobileNumber){
      if (!/^[0-9]+$/.test(productionMobileNumber)) {
        errors.productionMobileNumber = ("numbersOnlyAllowed");
    } else {
      if( (productionMobileNumber.length != null &&  productionMobileNumber.length != 'null' && 
      productionMobileNumber.length != '' && 
            (parseInt(productionMobileNumber.length) < 10 ) || (parseInt(productionMobileNumber.length) > 15 ))){
            errors.productionMobileNumber = t("enter10DigitsMobileNumber");
      }
    }}

    if(mdEmailId){
      if (!(mdEmailId).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
        errors.mdEmailId = ("enterValidEmailAddress");
    } }

    if(salesEmailId){
    if (!(salesEmailId).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
      errors.salesEmailId = ("enterValidEmailAddress");
    } } 

    if(accountsEmailId){
      if (!(accountsEmailId).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
        errors.accountsEmailId = ("enterValidEmailAddress");
    } } 

    if(productionEmailId){
      if (!(productionEmailId).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
        errors.productionEmailId = ("enterValidEmailAddress");
    } } 

  setValidErrors(errors);
  return errors;
};

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  let contact_details=[]
  const workspace_id = getWorkspaceId;
  const company_id = getLoginCompanyId;
  const User_Type = getLoginUserType;
  const user_id = getLoginUserId;
  const vendor_name = vendarName;
  
  const inputmdparam = {};
  inputmdparam['role'] = "M.D";
  inputmdparam['name'] = mdname;
  inputmdparam['number'] = mobileNumber;
  inputmdparam['email'] = mdEmailId;
  contact_details.push(inputmdparam);

  const inputsalesparam = {};
  inputsalesparam['role'] = "sales";
  inputsalesparam['name'] = salesName;
  inputsalesparam['number'] = salesMobileNumber;
  inputsalesparam['email'] = salesEmailId;
  contact_details.push(inputsalesparam);

  const inputaccountsparam = {};
  inputaccountsparam['role'] = "Accounts";
  inputaccountsparam['name'] = accountsName;
  inputaccountsparam['number'] = accountsMobileNumber;
  inputaccountsparam['email'] = accountsEmailId;
  contact_details.push(inputaccountsparam);

  const inputProductionparam = {};
  inputProductionparam['role'] = "Production";
  inputProductionparam['name'] = productionName;
  inputProductionparam['number'] = productionMobileNumber;
  inputProductionparam['email'] = productionEmailId;
  contact_details.push(inputProductionparam);
 
  const UpdateVendorInformation = () => {
   
    let valid = companySettingValidation();
    if (Object.keys(valid).length == 0) {
      axios.post(ServerUrl + "/add-label-vendor", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        user_type:User_Type,
        vendor_name :vendor_name,
        website:websiteName,
        office_address:headOfficeAddress,
        factory_address:factoryAddress,
        user_id:user_id,
        contact_details:contact_details,
      })) .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code == 200) {
          Swal.fire({
            title: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer: 2000
          })
          .then(()=>{

            axios
            .post(ServerUrl + "/all-vendor-list", apiencrypt({
                "company_id": getLoginCompanyId,
                "workspace_id": getWorkspaceId,
            }))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code == 200) {
                  setVendorList(response.data.data);
                  toggle(false)
                  toggleadd(false)
                  toggleViewVendorList(false)
                }
            });

          })
         
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title:
              t(response.data.errors.vendor_name) ||
              t(response.data.errors.office_address)||
              t(response.data.errors.category_ids) ,
            text: t("fieldMissing"),
            icon: "warning",
            button: t("okLabel"),
            timer: 2000
           
          });
        }
      })
    }
      
  }; 

  return (
    <Modal backdrop="static" isOpen={modal} toggle={toggle} centered className="Addvendormodal datainputmodal" >
        <ModalHeader>
            <Row> 
                <Col className="m-t-10">{t("addVendorInfo")}</Col> 
                <Col className="text-end cursor-pointer">
                    <span onClick={toggle} title={t("close")} tooltip={t("close")} alt={t("close")}>X</span>
                </Col>
            </Row> 
        </ModalHeader>
        <ModalBody >
            <Row className="g-12">
            
                <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                <FormGroup className="">
                    <Label>{t("vendorName")}</Label><sup className="font-danger">*</sup>
                    <InputGroup>
                    <Input
                    className={`${errors.vendor_name && "error-valid"
                    }  form-control`}
                        // className={`${errors.factory && 'select-error-valid'}  form-control`}
                        name="factories"
                        // type="select"
                        placeholder={t("vendorName")}
                        aria-label="factories"
                        defaultValue=""
                        // onChange={handleChange}
                        onChange={(e) => setVendarName(e.target.value)}
                    >
                        
                    </Input>
                    
                    
                    </InputGroup>
                    {errors.vendor_name && (
                    <span className="error-msg">
                        {t(errors.vendor_name)}
                    </span>
                    )}
                    
                </FormGroup>
                </Col> 
                <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                <FormGroup className="">
                    <Label>{t("website")}</Label>
                    <InputGroup>
                    <Input
                        name="factories"
                        placeholder={t("website")}
                        aria-label="factories"
                        onChange={(e) => setWebsiteName(e.target.value)}
                    >
                    </Input>
                    </InputGroup>
                </FormGroup>
                </Col>   
            </Row>
            <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                <Row className="g-12"><Col lg="12" md="12" sm="12" xs="12">
                    <span className="subTitleLine f-left">
                        <span  className="ordersubhead f-w-500 f-16">{t("address")}</span>
                    </span>
                    <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                </Col>
                </Row>
                </Col>

                <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                <FormGroup className="">
                    <Label>{t("headOffAddress")}<sup className="font-danger">*</sup></Label>
                    <InputGroup>
                    <Input
                    className={`${errors.headOfficeAddress && "error-valid"}  form-control`}
                        name="factories"
                        type="textarea"
                        aria-label="factories"
                        defaultValue=""
                        onChange={(e) => setHeadOfficeAddress(e.target.value)}>
                    </Input>
                    </InputGroup>
                    {errors.headOfficeAddress && (
                    <span className="error-msg">
                        {t(errors.headOfficeAddress)}
                    </span>
                    )}
                </FormGroup>
                </Col> 

                <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                <FormGroup className="">
                    <Label>{t("factory")}</Label>
                    <InputGroup>
                    <Input
                        name="factories"
                        type="textarea"
                        aria-label="factories"
                        defaultValue=""
                        onChange={(e) => setFactoryAddress(e.target.value)}
                    >
                    </Input>
                    </InputGroup>
                </FormGroup>
                </Col>  

                {/*end order details */}
                {/*start item description details */}
            
                {/*end item description details */}
            </Row>
            <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  <Row className="g-12">
                    <Col lg="12" md="12" sm="12" xs="12">
                        <span className="subTitleLine f-left">
                          <span className="ordersubhead f-w-500 f-16">{t("contactDtls")}</span>
                        </span>
                        <span><hr className="mt-4 mb-4 m-t-10"/></span>
                    </Col>
                  </Row>
                </Col>

                <div>
                <Table className="table-responsive">
                    <thead>
                      <tr>
                          <th style={{border: "none"}} className='m-l-5'>{t("role")}</th>
                          <th style={{border: "none"}}>{t("name")}</th>
                          <th style={{border: "none"}}>{t("mobileNumber")}</th>
                          <th style={{border: "none"}}>{t("emailId")}</th>
                      </tr>
                    </thead>

                    <tbody className="m-t-5">
                            <tr className='m-l-5 '> 
                              <td style={{border: "none"}}>{t("md")}</td>
                              <td style={{border: "none"}}> 
                                <Input
                                  name="factories"
                                  aria-label="factories"
                                  onChange={(e) => setMdname(e.target.value)}>
                                </Input>
                              </td> 
                              <td style={{border: "none"}}> 
                                  <Input
                                      name="factories"
                                      type="tel"
                                      defaultValue=""
                                      className={`${errors.mobileNumber && "error-valid"}  form-control`}
                                      aria-label="factories"
                                      onChange={(e) => setMdMobileNumber(e.target.value)}
                                      onKeyPress={(e) => handleKeyPress(e)}
                                      >
                                  </Input>
                                  {errors.mobileNumber && (
                                  <span className="error-msg">
                                  {t(errors.mobileNumber)}
                                  </span>)}
                              </td>
                              <td style={{border: "none"}}> 
                                <Input
                                    name="factories"
                                    aria-label="factories"
                                    className={`${errors.mdEmailId && 'error-valid'}  form-control`}
                                    defaultValue=""
                                    onChange={(e) => setMdEmailId(e.target.value)}>
                                </Input>
                                  {errors.mdEmailId && 
                                  (<span className="error-msg">{t(errors.mdEmailId)}</span>)}
                              </td>
                            </tr>
                            <tr className='m-l-5'> 
                              <td style={{border: "none"}}>{t("sales")}</td>
                              <td style={{border: "none"}}> 
                                <Input
                                  name="factories"
                                  aria-label="factories"
                                  defaultValue=""
                                  onChange={(e) => setSalesName(e.target.value)}
                                  >
                                </Input>
                              </td> 
                              <td style={{border: "none"}}> 
                                <Input
                                  className={`${errors.salesMobileNumber && 'error-valid'}  form-control`}
                                  name="factories"
                                  type="tel"
                                  defaultValue=""
                                  aria-label="factories"
                                  onChange={(e) => setSalesMobileNumber(e.target.value)}
                                  onKeyPress={(e) => handleKeyPress(e)}
                                >
                                </Input>
                                {errors.salesMobileNumber && 
                                (<span className="error-msg">{t(errors.salesMobileNumber)}</span>)}
                              </td>
                              <td style={{border: "none"}}> 
                                <Input
                                    name="factories"
                                    aria-label="factories"
                                    className={`${errors.salesEmailId && 'error-valid'}  form-control`}
                                    defaultValue=""
                                    onChange={(e) => setSalesEmailId(e.target.value)}>
                                </Input>
                                  {errors.salesEmailId && 
                                  (<span className="error-msg">{t(errors.salesEmailId)}</span>)}
                              </td>
                            </tr>
                            <tr className='m-l-5'> 
                              <td style={{border: "none"}}>{t("accounts")}</td>
                              <td style={{border: "none"}}> 
                                <Input
                                  name="factories"
                                  aria-label="factories"
                                  defaultValue=""
                                  onChange={(e) => setAccountsname(e.target.value)}>
                                </Input>
                              </td> 
                              <td style={{border: "none"}}> 
                                <Input
                                  className={`${errors.accountsMobileNumber && 'error-valid'}  form-control`}
                                  name="factories"
                                  type="tel"
                                  aria-label="factories"
                                  defaultValue=""
                                  onChange={(e) => setAccountsMobileNumber(e.target.value)}
                                  onKeyPress={(e) => handleKeyPress(e)}
                                  >
                                </Input>
                                {errors.accountsMobileNumber && 
                                (<span className="error-msg">{t(errors.accountsMobileNumber)}</span>)}
                              </td>
                              <td style={{border: "none"}}> 
                                <Input
                                  className={`${errors.accountsEmailId && 'error-valid'}  form-control`}
                                  name="factories"
                                  aria-label="factories"
                                  defaultValue=""
                                  onChange={(e) => setAccountsEmailId(e.target.value)}
                                  >
                                </Input>
                                {errors.accountsEmailId && 
                                ( <span className="error-msg">{t(errors.accountsEmailId)}</span>)}
                              </td>
                            </tr>
                            <tr className='m-l-5 '> 
                              <td style={{border: "none"}}>{t("productions")}</td>
                              <td style={{border: "none"}}> 
                                <Input
                                  name="factories"
                                  aria-label="factories"
                                  onChange={(e) => setProductionName(e.target.value)}>
                                </Input>
                              </td> 
                              <td style={{border: "none"}}> 
                                <Input
                                    className={`${errors.productionMobileNumber && 'error-valid'}  form-control`}
                                    name="factories"
                                    type="tel"
                                    aria-label="factories"
                                    defaultValue=""
                                    onChange={(e) => setProductionMobileNumber(e.target.value)}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    >
                                </Input>
                                {errors.productionMobileNumber && 
                                (<span className="error-msg">{t(errors.productionMobileNumber)}</span>)}
                              </td>
                              <td style={{border: "none"}}> 
                                <Input
                                    name="factories"
                                    aria-label="factories"
                                    className={`${errors.productionEmailId && 'error-valid'}  form-control`}
                                    defaultValue=""
                                    onChange={(e) => setProductionEmailId(e.target.value)}>
                                </Input>
                                  {errors.productionEmailId && 
                                  (<span className="error-msg">{t(errors.productionEmailId)}</span>)}
                              </td>
                            </tr>
                    </tbody>
                </Table>
                    <div>
                        <Row className="f-right">
                            <Col className="m-t-10 f-right m-r-10" >
                                <Button 
                                    style={{float:"Right "}} className= "btn-sm m-t-10" 
                                    onClick={() => UpdateVendorInformation()} >
                                        {t("save")} 
                                </Button>
                            </Col>
                        </Row> 
                    </div>
                </div>
            </Row>
        </ModalBody>
    </Modal>
  );
};

export default AddVendorModal;

//**************************code by: Ramanan M ***************************//