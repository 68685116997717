import React, { Fragment, useState ,useEffect} from 'react';
import { Card, Col, Table, Collapse,CardBody } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import bomb from '../../../assets/images/dms/BombSmiley.svg';
import { ServerUrl } from '../../../Constant/index';
import noData from "../../../assets/images/dms/nodata.png"
import axios from 'axios';
import { Accordion } from "react-bootstrap";
import {
  getLoginCompanyId, getLoginUserId,
  getWorkspaceId, getLoginStaffId
} from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { apiencrypt, apidecrypt } from '../../../helper';
import v1cutgreen_icon from '../../../assets/images/dms/icons/v1cutgreen_icon.svg';
import Sewv1green_icon from '../../../assets/images/dms/icons/Sewv1green_icon.svg';
import packv1_icon from '../../../assets/images/dms/icons/packv1_icon.svg';
const ProductionStatusTable = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [productionStatusTable, setProductionStatusTable] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(0);
  const { t } = useTranslation();
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));
  useEffect(() => {
   
      axios
        .post(ServerUrl + "/get-prod-status", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          setProductionStatusTable(response.data.forTableView);
        })
      }, [])
  const getSmiley = (delay) => {
    let date = Math.abs(delay);
    return (
      <td className="delayText text-left">
        <img className="p-0 img-30" src={bomb} /> &nbsp;{date}
      </td>);
  }
  return (
    <Fragment>
      <Col xs="12" sm="12" md="12" lg="12" xl="6" className="box-col-12 m-t-5">
       
         <div className="default-according" id="accordion">
            <Card  className="collopseradise">
              <CardHeader style={{  borderBottom: 'none', borderRadius: '0.75rem' }}>
              <p className=" p-l-5 f-18 f-w-600">{t("productionStatus")}</p>
              </CardHeader>
                {productionStatusTable.length>0 ? 
                 <CardBody className="p-0 "
                           style={{ height: "350px", borderBottom: 'none', 
                                    borderRadius: '0.75rem' }} >
                 <ScrollBar className="" options={{ suppressScrollX: true }} >
                <div className="table-responsive">
                  <Table className="tableRowCol dasboardTablePadding">
                      <tr style={{ fontSize: "13px", backgroundColor: '#F4F4F4' }}>
                        {/* <th  className='text-uppercase'>{t("orderNo")}</th> */}
                        <th  className='text-uppercase'>{t("styleNo")}</th>
                        <th  className='text-uppercase'>{t("cutting")}</th>
                        <th  className='text-uppercase'>{t("sewing")}</th>
                        <th  className='text-uppercase'>{t("packing")}</th>
                      </tr>
                    {productionStatusTable.map((production, index) =>
                    (
                      <>
                          <tr className={`${ index+1 !== productionStatusTable.length ? "removeBottomBorder" : ""}`}>
                            {/* <td className='cursor-pointer' title={production.orderNo} tooltip={production.orderNo} style={{ paddingLeft: '0.75rem',fontSize: '14px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100px" }} 
                             > {production.orderNo} </td>  */}
                            <td className='cursor-pointer' title={production.orderNo + " " + "/" + " " + production.styleNo} 
                            tooltip={production.orderNo + " " + "/" + " " + production.styleNo} 
                            alt={production.orderNo + " " + "/" + " " + production.styleNo} style={{ paddingLeft: '0.75rem',fontSize: '14px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "30%" }}> 
                               {production.styleNo} </td> 
                            <td className='cursor-pointer' title={production.orderNo + " " + "/" + " " + production.styleNo} 
                            tooltip={production.orderNo + " " + "/" + " " + production.styleNo} 
                            alt={production.orderNo + " " + "/" + " " + production.styleNo}style={{color:"#13D09A",paddingLeft: '0.75rem',fontSize: '14px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "23%"}}> 
                            {/* <span style={{float:"left"}}> */}

                            <img src={v1cutgreen_icon}></img>
                            {/* </span> */}
                            {/* <span style={{float:"left"}}> */}

                             <b style={{ paddingLeft: '0px' }}>{production.cutPercentage}% </b>
                            {/* </span> */}
                             </td> 
                            <td  className='cursor-pointer' title={production.orderNo + " " + "/" + " " + production.styleNo} 
                            tooltip={production.orderNo + " " + "/" + " " + production.styleNo} 
                            alt={production.orderNo + " " + "/" + " " + production.styleNo}
                             style={{color:"#5159AC",paddingLeft: '0.75rem',fontSize: '14px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "23%"}}>
                              {/* <span> */}

                               <img src={Sewv1green_icon}></img>
                              {/* </span> */}
                                <b style={{ paddingLeft: '0px' }}>{production.sewPercentage}%</b> 
                                </td> 
                            <td className='cursor-pointer' title={production.orderNo + " " + "/" + " " + production.styleNo} 
                            tooltip={production.orderNo + " " + "/" + " " + production.styleNo} 
                            alt={production.orderNo + " " + "/" + " " + production.styleNo}
                             style={{color:"#13A1C1",paddingLeft: '0.75rem',fontSize: '14px', textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "23%"}}> 
                            <img  src={packv1_icon}></img> 
                            <b style={{ paddingLeft: '0px' }}>{production.packPercentage}%</b> 
                            </td> 
                          </tr>
                      </>
                    )
                    )}
                  </Table>
                </div>
                </ScrollBar>
                </CardBody>
               : 
               <>
               <div  style={{ height: "104px" }}></div>
              <div className="p-5 align-self-center media-body centerAlign"
                   style={{ height: "350px" }}>
                    <img src={noData} style={{ width: "200px" }} />
                    <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                </div>
                </>
              } 
            </Card>
            </div>
     
      </Col>
    </Fragment>
  );
};

export default ProductionStatusTable;

//**************************Code by : Praveen****************************//