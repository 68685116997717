import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../helper';
import { getLoginCompanyId, getLoginStaffId, getLoginUserId, getWorkspaceId } from '../../Constant/LoginConstant';

const AddShiftModal = ({ modal, toggle, setGetMasterData, setProductionTypeList, setBreakTimeList }) =>{
  
  const {t} = useTranslation();
  const [shiftName, setShiftName] = useState("");
  const [shiftFromTime, setShiftFromTime] = useState("");
  const [shiftEndTime, setShiftEndTime] = useState("");
  
  const [errors, setValidErrors] = useState({});
  const validation = () => {
    let errors = {};
    if (!shiftName) {
      errors.shiftName = t("plsEnterShiftName")
    }
    if (!shiftFromTime) {
      errors.shiftFromTime = t("plsSelectShiftFromTime")
    }
    if (!shiftEndTime) {
      errors.shiftEndTime = t("plsSelectShiftEndTime")
    }
    setValidErrors(errors);
    return errors;
  };

  const refreshDataApiCall = () => {

    var apiInputParams = {};
    apiInputParams['company_id'] = getLoginCompanyId;
    apiInputParams['workspace_id'] = getWorkspaceId;

    axios
      .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setGetMasterData(response.data.data);
      })

      setProductionTypeList(['Sewing', 'Cutting']);
      setBreakTimeList(['1', '2', '3']);

      toggle();
  };

  const onSaveHandle = () => {

    let retval = validation();
    if (Object.keys(retval).length == 0) {
      var apiInputParams = {};
      apiInputParams['company_id'] = getLoginCompanyId;
      apiInputParams['workspace_id'] = getWorkspaceId;
      apiInputParams['user_id'] = getLoginUserId;
      apiInputParams['staff_id'] = getLoginStaffId;

      apiInputParams['type'] = "Shift";
      apiInputParams['value'] = shiftName;
      apiInputParams['shift_from_time'] = shiftFromTime;
      apiInputParams['shift_end_time'] = shiftEndTime;

      axios
        .post(ServerUrl + "/add-sam-master-data", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            })
            .then((result) => {
              if (result.isConfirmed) {
                refreshDataApiCall();
              }
              setTimeout(() => {
                refreshDataApiCall();
              }, 100);
            });
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
    } 
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addShift")}</ModalHeader>
      <ModalBody>

        <Row>
            <FormGroup className="">
                <Label>{t("shiftName")}</Label><sup className="font-danger">*</sup>
                <InputGroup>
                <Input
                    className={`${errors.shiftName && 'select-error-valid'}  form-control`}
                    type="text"
                    placeholder={t("plsEnterShiftName")}
                    onChange={(e) => { setShiftName(e.target.value) }}>
                </Input>
                </InputGroup>
                {errors.shiftName && 
                (<span className="error-msg">{t(errors.shiftName)}</span>)
                }
            </FormGroup>
        </Row> 

        <Row>
            <FormGroup className="">
                <Label> {t("fromTime")} </Label><sup className="font-danger">*</sup>
                <InputGroup>
                <Input
                    className={`${errors.shiftFromTime && 'select-error-valid'}  form-control form-control digits`}
                    type="time"
                    defaultValue="00:00:00"
                    onChange={(e) => {setShiftFromTime(e.target.value)}}>
                </Input>
                </InputGroup>
                {errors.shiftFromTime && 
                (<span className="error-msg">{t(errors.shiftFromTime)}</span>)
                }
            </FormGroup>
        </Row> 

        <Row>
            <FormGroup className="">
                <Label> {t("toTime")} </Label><sup className="font-danger">*</sup>
                <InputGroup>
                <Input
                    className={`${errors.shiftEndTime && 'select-error-valid'}  form-control form-control digits`}
                    type="time"
                    defaultValue="00:00:00"
                    onChange={(e) => {setShiftEndTime(e.target.value)}}>
                </Input>
                </InputGroup>
                {errors.shiftEndTime && 
                (<span className="error-msg">{t(errors.shiftEndTime)}</span>)
                }
            </FormGroup>
        </Row> 

      </ModalBody>
      <ModalFooter>
        <Btn 
          attrBtn={{ color: "btn secondaryBtn btn-sm", 
          onClick: () => {toggle(), setValidErrors(() => "")} }}>
            {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddShiftModal;
/* Code By : Ramanan M */