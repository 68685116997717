import React , {useState}from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges, ServerUrl } from '../../../Constant';
import Swal from "sweetalert2";
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../../helper";
import { useTranslation } from 'react-i18next';

const FormAddTaskSubTaskModal = ({ taskTitle, subtaskOf , modal, toggleModal, newTemplateSave, 
  setTemplates, refreshTemplates, taskTemplate, selectedtemplateId, areTasksEdited}) =>{
  const [taskName, setTaskName] = useState("");
  const { t } = useTranslation();
  setTimeout(() => {
    var element =  document.getElementById('taskInput');
    if (typeof(element) != 'undefined' && element != null)
    {
      element.focus();
    }
     
    }, 1000);
  /******* To Add a new Task/Subtask *********/
  const addTask = () =>{

  if(!taskName.trim()){
    document.getElementById("taskInput").value = "";
    Swal.fire({
      title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
      // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
      text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
      icon: "error",
      button: t("okLabel"),
    });
  }/*else if(taskName.trim().length>0){
    var tskname=taskName.split(",");
    var isBreak=0;
  
      for(let k=0; k>=tskname.length; k++){
 if(tskname[k].trim().length >10){

  Swal.fire({
    title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
    // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
    text: (taskTitle == "Task" ?  t("taskTitleValidation"):"string length too long" ),
    icon: "error",
    button: t("okLabel"),
  });
  return false;
 }
}
  }*/
  else{
    Swal.fire({
      title: t("areYouSure"),
      text: t("addSubTaskTextAlert",{taskName:`${taskTitle}`}),
      text: (taskTitle == "Task" ?  t("addTaskTextAlert") : t("addSubTaskTextAlert") ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("sure")
    })
    .then((result) => {
      if (result.isConfirmed) {
        let errorAlert=false;
        let errorTaskAlert=false;
        let validtask='';
        for(let i=0; i<taskTemplate.length; i++){
          if(taskTemplate[i].id == selectedtemplateId){
            let template = JSON.parse(taskTemplate[i].task_template_structure);
            let newTemplate = [];
            for(let j=0; j<template.length; j++){
             // console.log("Task Title----->",template[j].task_title , subtaskOf , taskTitle);
              if(taskTitle === "Task"){
                template.map((mapData) => {

                  if(mapData.task_title.toLowerCase().trim().replace(/ /g, "")==taskName.trim().toLowerCase().replace(/ /g, "")){
                    errorTaskAlert=true;
                  }

                } )
                if(errorTaskAlert==false){
                  template[template.length]={"task_title": taskName, "task_subtitles" : []};
                }
                newTemplate=template;
                break;
              }
              else if(template[j].task_title === subtaskOf && taskTitle === "Sub Task"){
                let task_subtitles = [];
                for(let k=0; k<=(template[j].task_subtitles).length; k++){
                  if(k===((template[j].task_subtitles).length)){
                    /*Task split comma separate start */
                    var tskname=taskName.split(",");
                    let uNames = {};
                    tskname.forEach(n => {
                      if(n.trim()){
                        let lower = n.toLowerCase().trim().replace(/  +/g, ' ');
                        if (!uNames[lower]) {
                          uNames[lower] = n.toLowerCase().trim().replace(/  +/g, ' ');
                        }
                      }
                      });
                      tskname = Object.values(uNames);
                   // console.log("tskname===>",tskname);
                    var isBreak=0;
                    tskname.map((tsknamevl) => {

                      if(tsknamevl.trim()){
                    var data = template[j].task_subtitles.filter((val) =>(val.toLowerCase().trim().replace(/ /g, ""))==(tsknamevl.trim().toLowerCase().replace(/ /g, "")));
                    if(data.length==0){
                    template[j].task_subtitles[k]=capitalizeFirstLowercaseRest(tsknamevl);
                    task_subtitles.push(template[j].task_subtitles[k]);
                  
                    isBreak=1;
                    //break;
                  }
                    else{
                      if(validtask==''){
                      validtask=tsknamevl;}
                      else{
                        validtask=validtask+","+tsknamevl;
                      }
                      errorAlert=true;

                    } 
                  }
                  });
if(isBreak==1){break;}
                  }
                  else{
                    task_subtitles.push(template[j].task_subtitles[k]);
                  }
                }
                template[j].task_subtitles = task_subtitles 
                newTemplate.push(template[j]);
              }
              else{
                newTemplate.push(template[j]);
              }
            }
            taskTemplate[i].task_template_structure = JSON.stringify(newTemplate);
            newTemplateSave(newTemplate);
          }
          setTemplates(taskTemplate);
        }
        
        if(errorTaskAlert){
          Swal.fire({
            text:taskName+ " "+t(" Task is Already Exists"),
            // title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
            // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
            // text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
            icon: "error",
            button: t("okLabel"),
          });
         return false;
        }
         if(errorAlert){
          Swal.fire({
            text:validtask?validtask+ " "+t("alreadyExists"):taskName+ " "+t("alreadyExists"),
            // title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
            // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
            // text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
            icon: "error",
            button: t("okLabel"),
          });
        }
        setTaskName("");
        toggleModal(false);
        refreshTemplates("1");
        areTasksEdited(1);
      }
    })
  }
  };
  return (
    <Modal isOpen={modal} toggle={toggleModal} centered>
      <ModalHeader>{(taskTitle == "Task" ?  t("Task"): t("subTask") )}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("save")} {(taskTitle == "Task" ?  t("Task"): t("subTask") )}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setTaskName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
          id="taskInput"
        />
        {/* {taskTitle=='Sub Task'?
        <span style={{fontSize:"12px",color:"#9a9fa1"}}>{t("addMulTaskUsingComma")}<br/>{t("egToAddTask")}</span>:""} */}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn btn-light btn-sm", onClick: toggleModal }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary  btn-sm", onClick : ()=>addTask() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddTaskSubTaskModal;
/* Code By : Rithanesh */