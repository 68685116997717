import axios from "axios";
import React , {useState, useEffect}from "react";
import { Modal, 
        ModalBody, ModalHeader, Nav, NavItem,
        NavLink, Row, Card, Col, Input, Table, TabContent,
        TabPane, Form,  Label, FormGroup, InputGroup, ModalFooter, Button} from "reactstrap";
import { ServerUrl } from "../../Constant";
import { apiencrypt, PHPtoJSFormatConversion, apidecrypt, encode, decode } from '../../helper';
import { getLoginCompanyId, getWorkspaceId, getWorkspaceType, getLoginUserId, getLoginUserType,
         getLoginStaffId, getStaff, getStaffPermission } from "../../Constant/LoginConstant";
import Swal from 'sweetalert2';
import Moment from 'moment';
import { H5 } from "../../AbstractElements";
import { useTranslation } from 'react-i18next';
import PartiallyDeliveredIcon from '../../../src/assets/images/dms/icons/partialDeliveredIcon.webp';
import deliveredIcon from '../../../src/assets/images/dms/icons/deliveredIcon.webp';
import deliveryDateCompleted from '../../assets/images/dms/icons/deliveryDateCompleted.svg';

import Select from 'react-select';
const PartialDeliveryModal = ({ 
    modal, toggle, basicInfo, toleranceVolume, 
    tolerancePercent, stepLevel, 
    partialDeliveryDetails, setPartialDeliveryDetails,
    styleNo, 
    comments, setComments, deliveryDate, setDeliveryDate, 
    partialDeliveryStatus, deliveryStatus,
    partialDeliveryErrors, setPartialDeliveryErrors,
    totalDeliveredQty, noOfDeliveries, noOfDelivered,
    setPartialDeliveryStatus, setDeliveryStatus,
    setNoOfDeliveries,
    setNoOfDelivered,
    setTotalDeliveredQty,
    setTotalPackQty, 
    enabledDeliveryId, setEnabledDeliveryId, 
    lastDeliveryDate, setLastDeliveryDate,
    deliveryDateDropdown, setDeliveryDateDropdown
  }) => 
    {

    const { t } = useTranslation();
 
    var color = partialDeliveryDetails.colors;
    var size = partialDeliveryDetails.sizes;
    var skuData = partialDeliveryDetails.sku_data;
    var deliveryDates = partialDeliveryDetails.deliveryDates;
    var totalPackQty = partialDeliveryDetails.total_pack_quantity;
    var addedDeliveryDates = partialDeliveryDetails.addedDeliveryDates;
    var totalDeliveredQty = partialDeliveryDetails.total_delivered_quantity;
    var totalOrderQty = basicInfo.quantity;

    const [ disableStatus, setDisableStatus ] = useState(true); 
    const [ saveBtnStatus, setSaveBtnStatus ] = useState(false); 
    const orderValidation = () => {
        let partialDeliveryErrors = {};
        if (!deliveryDate) {
            partialDeliveryErrors.deliveryDate = t('selectDelDate')
        }
        setPartialDeliveryErrors(partialDeliveryErrors);
        return partialDeliveryErrors;
    };

    /*********************************** COLOR TOTAL ******************/
    const addQty = (e) => {
    var idv = e.target.id;
    var value = e.target.value;

    /***** Split the ID values of Size and Color *****/
    var sptv = idv.split("#");
    var color_id = sptv[0];
    var size_id = sptv[1];
    var totQty = 0;

    size.forEach((e) => {
        var getsizeid = e.size_id;
        var t = document.getElementById(color_id + "#" + getsizeid).value;
        if (parseInt(t) > 0) {
        totQty += parseInt(t);
        }
        document.getElementById("totqty_" + color_id).value = totQty;
    })

    overallTotalQty(e);
    sizeTotalQty(e)
    };
  
    /*********************************** SIZE TOTAL *******************/
    const sizeTotalQty = (e) => {

        var id = e.target.id;
        var value = e.target.value;

        var splitId = id.split("#");
        var color_id = splitId[0];
        var size_id = splitId[1];

        var tot = 0;
        color.forEach((e) => {
            var colorId = e.color_id;
            var qtyValue = document.getElementById(colorId + "#" + size_id).value;
            if (parseInt(qtyValue) > 0) {
            tot += parseInt(qtyValue);
            }
            document.getElementById("SizeId_total_quantity" + size_id).value = tot;
        })
        
    
    };

    /*************************** OVERALL TOTAL ************************/
    const overallTotalQty = (e) => {
        var id = e.target.id;
        var value = e.target.value;

        var splitId = id.split("#");
        var color_id = splitId[0];
        var size_id = splitId[1];

        var sum = 0;
        color.forEach((data) => 
        {
        var totalQtyValue = document.getElementById("totqty_" + data.color_id).value;
        if (parseInt(totalQtyValue) > 0) {
            sum += parseInt(totalQtyValue);
        }
        })
        document.getElementById("Overall_total_quantity").value = sum;
    };

    /**************** PAYLOAD - INPUT PARAMS [API CALL]  *************/
    const getQtyDetails = (e) => {
        var skuDet = [];
        var g = -1;
        color.forEach((c) => {
            size.forEach((e) => {
            g++;
            var tt = document.getElementById(c.color_id + "#" + e.size_id).value;
                var skuData = {};
                skuData['color_id'] = c.color_id
                skuData['size_id'] = e.size_id
                skuData['quantity'] = tt == '' ? 0 : tt;
                skuDet[g] = skuData;
            });
        });
        return skuDet;
    }; 

    /*************************** (SWAL) VALIDATION  ******************/
    const validation = async (e) => {
        let retval = orderValidation();
        if (Object.keys(retval).length == 0) {
            var overallTotal = document.getElementById("Overall_total_quantity").value;
            if( overallTotal == 0 ){
                Swal.fire({
                    title: t("selectColor/SizeValidation"),
                    icon: "warning",
                    button: t("okLabel"),
                })
            }
            else{
                confirmationAlert();      
            }
        }
    };

    const confirmationAlert = () => {

        let sku = getQtyDetails();
        var overallTotal = document.getElementById("Overall_total_quantity").value;

        if( deliveryDate == lastDeliveryDate ){

            if( totalPackQty == totalOrderQty ){
                if( totalOrderQty = overallTotal + totalDeliveredQty ){
                    Swal.fire({
                        title: "Do you want to complete the delivery details ?",
                        text: "You won't be able to change the content !",
                        icon: "question",
                        button: t("okLabel"),
                        showCancelButton: true,
                        })
                        .then((result) => { 
                            if (result.isConfirmed){ 
                                addPartialDeliveryApiCall(sku) 
                            } 
                        });
                } 
            } else{
                Swal.fire({
                    title: "Please Complete the Packing to add delivery details",
                    icon: "warning",
                    button: t("okLabel"),
                    timer: 4000
                })
                .then((result) => {
                    if (result.isConfirmed) { 
                        window.location.href="/datainput?id=" + encode(styleNo);
                    }
                })
            }
        } else{

            if( totalPackQty == totalOrderQty ){
                if( overallTotal == totalDeliveredQty ){
                    Swal.fire({
                        title: "Do you want to complete the delivery details ?",
                        text: "You won't be able to change the content !",
                        icon: "question",
                        button: t("okLabel"),
                        showCancelButton: true,
                    })
                    .then((result) => { 
                        if (result.isConfirmed){ 
                            addPartialDeliveryApiCall(sku) 
                        } 
                    });
                    } 
                else{
                        addPartialDeliveryApiCall(sku) 
                    }
            }
            else{
                addPartialDeliveryApiCall(sku) 
            } 
        }

    };

    ///////////////////////////////////////////////////////////////////
    /****************** API CALL (add-partial-delivery)  *************/
    const addPartialDeliveryApiCall = ( sku ) => {
        axios
        .post(ServerUrl + "/add-partial-delivery", apiencrypt({
            "company_id": getLoginCompanyId,
            "workspace_id": getWorkspaceId,
            "order_id": styleNo,
            "user_id": getLoginUserId,
            "user_type": getLoginUserType,
            "staff_id": getLoginStaffId ? getLoginStaffId : 0,
            "delivery_date": deliveryDate,
            "comments": comments,
            "sku_data": sku,
            }))
        .then((response) => {
            response.data = apidecrypt(response.data);
            if (response.data.status_code === 200) {
                Swal.fire({
                title: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                })
                .then((result) => {
                    if (result.isConfirmed) { refreshDataApiCall(), toggle() }
                })
            }
            })
    };

    /** MOVES TO NEXT INPUT FIELD ( WHEN ENTER IS CLICKED ) *********/
    function handleEnter(event) {   
        if ((event.keyCode === 13 || event.keyCode === 9) && event.target.nodeName === "INPUT") {
        // setPartialDeliveryErrors( () => "");
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        if(form.elements[index + 1].readOnly==false){
        form.elements[index + 1].focus();
        }else{
        if(form.elements[index + 2].readOnly==false){
        form.elements[index + 2].focus();
        }else{
        let index=0;
            form.elements[index].focus();
        }
        }
    }
    };

    /*********** ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
    function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
    };

    const pendingCompletedQty = (id, value) => {
        var splitValue = id.split('#');
        var colorId = splitValue[0];
        var sizeId = splitValue[1];
        var updatedQty = updatedSkuQty(colorId, sizeId);

        skuData.map((mapData) => {                     
            if( colorId == mapData.color_id &&  sizeId == mapData.size_id ){
                var packQty  = Number(mapData.updated_quantity) - Number(updatedQty);
                var pendingValue = Number(packQty) - Number(value);

                if( value <= packQty ){
                    document.getElementById(colorId+"#"+sizeId).value  = value;
                    document.getElementById(colorId+"cq"+sizeId).innerHTML  = "Pending Qty : " + pendingValue;
                }
                if( value > packQty ){
                    document.getElementById(colorId+"#"+sizeId).value  = packQty;
                    document.getElementById(colorId+"cq"+sizeId).innerHTML  = "Pending Qty : " + 0;
                    colorTotal();
                    sizeTotal();
                    overallTotal();
                }
            } 
        });
    };

    const updatedSkuQty = (colorIdValue, sizeIdValue) => {
        var total = 0;
        addedDeliveryDates.map((mapData) => {
            if( mapData.is_delivered == 1 ){
                (mapData.sku_data).map((data) => {
                    if( data.color_id == colorIdValue  &&  data.size_id == sizeIdValue ){
                        total += Number(data.total_quantity);
                        }
                    })    
                }
        })
        return total;
    };

    const colorTotal = () => {
        color.map((colorData) => {
            var data = 0;
            size.map((sizeData) => {
                let value = Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value);
                data +=  value ? value : 0;
            })
            document.getElementById("totqty_"+colorData.color_id).value = data;
        });
    };

    const sizeTotal = () => {
        size.map((sizeData) => {
            var data = 0;
            color.map((colorData) => {
                var value = Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value);
                data +=  value ? value : 0;
            })
            document.getElementById("SizeId_total_quantity"+sizeData.size_id).value = data;
        });
    };

    const overallTotal = () => {
        var overallTotal = 0;
        size.map((mapData) => {
            overallTotal += Number(document.getElementById("SizeId_total_quantity"+mapData.size_id).value);
        });
        document.getElementById("Overall_total_quantity").value = overallTotal;
    };

    const refreshDataApiCall = () => {
        var deliveryDetailsParams = {};
        deliveryDetailsParams['company_id'] = getLoginCompanyId;
        deliveryDetailsParams['workspace_id'] = getWorkspaceId;
        deliveryDetailsParams['user_id'] = getLoginUserId;
        deliveryDetailsParams['order_id'] = styleNo;

        axios
        .post(ServerUrl + "/get-delivery-details", apiencrypt(deliveryDetailsParams))
        .then((response) => {
        response.data = apidecrypt(response.data);    
        setPartialDeliveryDetails(response.data.data);

        setNoOfDeliveries(response.data.data.delivery_count);
        setNoOfDelivered(response.data.data.delivered_count);
        setTotalDeliveredQty(response.data.data.total_delivered_quantity);
        setTotalPackQty(response.data.data.total_pack_quantity);
        
        if( response.data.data.delivery_count == 0 ){
            setDeliveryStatus(true);
            setPartialDeliveryStatus(true);
        } else if( response.data.data.delivery_count == 1 ){
            setDeliveryStatus(false);
            setPartialDeliveryStatus(true);
        } else{
            setDeliveryStatus(true);
            setPartialDeliveryStatus(false);
        }

        var enabledDeliveryId="";
        var notDelivereDates = response.data.data.deliveryDates.filter((filterData) => filterData.is_delivered == 0);
        notDelivereDates.map((mapData, index) => {
            mapData.is_delivered == 0
            if( index == 0 ){ enabledDeliveryId = mapData.id, 
                            setEnabledDeliveryId(mapData.id) }
        })

        response.data.data.deliveryDates.map((mapData) => { 
            setLastDeliveryDate(mapData.delivery_date)
        });

        var deliveryDateDropdown = [];
        response.data.data.deliveryDates.map((mapData) => { 
            var deliveryDateOptions = {};
                deliveryDateOptions['text'] = 
                                                mapData.is_delivered == 1 
                                                    ?
                                                    <span style={{ fontWeight: 500, color: '#000' }}>
                                                        {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                                                    </span>     
                                                    :
                                                        mapData.id == enabledDeliveryId 
                                                        ?
                                                            deliveryDateOptions['value'] = 
                                                            <span style={{ color: '#000' }} className="enabledDeliveryDate">
                                                                {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                                                            </span> 
                                                        : 
                                                            <span>
                                                                {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                                                            </span> 
                deliveryDateOptions['value'] = 
                                                mapData.is_delivered == 1 
                                                    ?
                                                    PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                                                    :
                                                        mapData.id == enabledDeliveryId 
                                                        ?
                                                            deliveryDateOptions['value'] = 
                                                            PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                                                        : 
                                                            PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                deliveryDateOptions['exact_value'] = 
                                                    mapData.is_delivered == 1 
                                                        ?
                                                        mapData.delivery_date
                                                        :
                                                            mapData.id == enabledDeliveryId 
                                                            ?
                                                                deliveryDateOptions['exact_value'] = 
                                                                    mapData.delivery_date
                                                            : 
                                                                    mapData.delivery_date
                                                                
                deliveryDateOptions['icon'] = 
                                                mapData.is_delivered == 1 ?
                                                        <>
                                                            <span style={{ marginLeft: 15, float: 'right',  marginRight: 25 }}>
                                                                <img src={deliveryDateCompleted} width="15px" height="15px" />
                                                            </span>
                                                        </>
                                                        :
                                                        ""
                deliveryDateOptions['id'] = mapData.id;
                deliveryDateOptions['is_delivered'] = mapData.is_delivered;
                deliveryDateOptions['delivery_comments'] = mapData.delivery_comments;
                deliveryDateOptions['total_delivered_quantity'] = mapData.total_delivered_quantity;
                deliveryDateOptions['disabled'] = 
                                                mapData.is_delivered == 1 
                                                    ? 
                                                        false 
                                                    :
                                                        mapData.id == enabledDeliveryId 
                                                        ?
                                                            false    
                                                        : 
                                                            true
                deliveryDateDropdown.push(deliveryDateOptions);
        });

        var initialValue = {
            'text':  
                    <>
                        {/* <span>
                        <span className="f-left" style={{ color: '#009688', fontSize: '14px' }}> Pending Qty </span>
                            { console.log(orderQuantity, "response.data.data.total_delivered_quantity", response.data.data.total_delivered_quantity) }
                            <span 
                                className="text-center m-l-20 f-right"
                                style={{ color: '#009688', fontWeight: 600,  
                                        backgroundColor: '#dbf0ef', borderRadius: '7px', 
                                        padding: '2px', width: '80px' }}> 
                                            {orderQuantity - response.data.data.total_delivered_quantity}
                                        {Math.floor((response.data.data.total_delivered_quantity / orderQuantity ) * 100)} %
                                        
                            </span>
                        </span> */}

                        <div style={{ fontSize: '8px', fontWeight: 350, color: '#000', marginTop: '0px' }}>
                            {t("pendingQty")} - 
                            <span style={{ fontSize: '10px', fontWeight: 600, color: '#000', marginTop: '0px' }}> 
                                250 
                            </span>

                            <span className="f-right" style={{ fontSize: '8px', fontWeight: 350, color: '#000', marginTop: '0px' }}>
                            {t("pendingDelivy")} - 
                                <span style={{ fontSize: '10px', fontWeight: 600, color: '#000', marginTop: '0px' }}> 
                                    2
                                </span>
                            </span>

                        </div>


                        {/* <span>
                            <span>
                                <span style={{ color: '#000', fontSize: '12px' }}> Pending Qty  - </span>
                                <span style={{ color: '#009688', fontWeight: 600 }}> 150 </span>
                            </span>
                            <span className="p-l-15" style={{ color: '#000', fontSize: '12px' }}> Total Delivered  - </span>
                            <span style={{ color: '#009688', fontWeight: 600 }}> 52% </span>
                        </span> */}
                    </>,
            'value': "Select Delivery Date",
            'disabled': "true",
        }
        // deliveryDateDropdown.push(initialValue);
        setDeliveryDateDropdown(deliveryDateDropdown);
        });

    };

    const qtyValidation = ( selectedDate ) => {
            var date = ""; 
            var isDelivered = "";
            deliveryDates.map((mapData) => { 
                date = mapData.delivery_date 
                isDelivered = mapData.is_delivered
            });

            if( date == selectedDate && isDelivered == 0 ){ 
                color.map((colorData) => {
                    size.map((sizeData) =>{
                        autofillPendingData(colorData.color_id, sizeData.size_id )
                    })
                })
                setDisableStatus(true);
                setSaveBtnStatus(false);
            } 
    };

    const autofillPendingData = (colorId, sizeId) => {
        var updatedQty = updatedSkuQty(colorId, sizeId);
        skuData.map((mapData) => {                     
        if( colorId == mapData.color_id &&  sizeId == mapData.size_id ){
            var packQty  = Number(mapData.updated_quantity) - Number(updatedQty);
            document.getElementById(colorId+"#"+sizeId).value  = packQty;
            document.getElementById(colorId+"cq"+sizeId).innerHTML  = "Pending Qty : " + 0;
            colorTotal();
            sizeTotal();
            overallTotal();
        } 
        });
    };

    const autofillDeliveredData = ( value ) => {
        addedDeliveryDates.map((mapData) => { 
            if( mapData.delivery_date == value && mapData.is_delivered == 1 ){ 
                color.map((colorData) => {
                    size.map((sizeData) =>{
                        showDeliveredQty(colorData.color_id, sizeData.size_id, value)
                    })
                })
                setDisableStatus(true);
                setSaveBtnStatus(true);
            } 
        });
    };

    const showDeliveredQty = (colorId, sizeId, value) => {

        var filteredDate = addedDeliveryDates.filter((filterData) => filterData.delivery_date == value && filterData.is_delivered == 1 );
        filteredDate.map((mapData) => {
            (mapData.sku_data).map((skuData) => {
                if( colorId == skuData.color_id &&  sizeId == skuData.size_id ){
                    document.getElementById(colorId+"#"+sizeId).value  = Number(skuData.total_quantity);
                    colorTotal();
                    sizeTotal();
                    overallTotal();
                }
            })
        })

    };

    const handleChangeDeliveryDate = e => {
        setDisableStatus(false),
        setDeliveryDate(e.exact_value), 
        qtyValidation(e.exact_value),
        autofillDeliveredData(e.exact_value)
    };

    return (
        <>
            <Modal 
                centered 
                isOpen={modal}
                toggle={toggle} 
                backdrop="static"
                className="partialDeliveryModal">
                <>
                    <ModalHeader style={{ borderBottom: 'none', paddingBottom: '0rem', paddingTop: '2rem'}}>
                        <div class="row p-l-45 verticalAlignmentStart " style={{ height: '10%' }}>
                        {partialDeliveryStatus == false ? 
                            <div class="col-2 text-center" 
                                style={{ width: '7%', height: '15%',  backgroundColor: '#f2f2f2', 
                                        borderRadius: '10px', padding: '10px', paddingLeft: '10px' }}>
                                <img src={PartiallyDeliveredIcon} width="35px" height="30px" />
                            </div>
                        :
                            <div class="col-2 text-center" 
                                style={{ width: '7%', height: '15%',  backgroundColor: '#f2f2f2', 
                                        borderRadius: '10px', padding: '8px', paddingLeft: '3px' }}>
                                <img src={deliveredIcon} width="50px" height="30px"/> 
                            </div> 
                        }
                            <div class="col verticalAlignment">
                                    <h5 className="verticalAlignment">{t("addDelDtls")}</h5>
                                    { totalOrderQty == totalDeliveredQty ? 
                                        <div style={{ color: '#009688', fontSize: '10px'}}>
                                            <i className="fa fa-circle f-10 m-r-5" 
                                            style={{ color: '#009688'}}></i>
                                            {t("completed")}
                                        </div>
                                    :
                                        ""
                                    }
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ paddingLeft: '3rem', paddingRight: '1rem', paddingTop: '0rem' }}>
                        <Row className="g-12 m-t-20" >
                                {/*start Add Qty */}
                                    { color !== undefined || size !== undefined 
                                    ?
                                        color.length > 0 && size.length > 0 
                                        ?
                                            <>
                                                <Row style={{ paddingRight: '20px' }}>
                                                    <Col md="5">
                                                        <FormGroup>
                                                            <Label> {t("delDate")}</Label><sup className="font-danger">*</sup>
                                                            <Select
                                                                // menuIsOpen={true}
                                                                placeholder={t("selectDelDate")}
                                                                options={deliveryDateDropdown}
                                                                onChange={handleChangeDeliveryDate}
                                                                isOptionDisabled={(option) => option.disabled}
                                                                getOptionLabel={e => (
                                                                    <>
                                                                        <span>
                                                                            <span>
                                                                            {e.text}
                                                                            </span>
                                                                            <span style={{ marginRight: '20px' }}>
                                                                                {e.icon}
                                                                            </span>
                                                                            {   e.is_delivered == 1 ?
                                                                                <div style={{ fontSize: '8px', fontWeight: 350, color: '#000', marginTop: '0px' }}>
                                                                                    {t('deliveredQty')} - 
                                                                                    <span style={{ fontSize: '10px', fontWeight: 600, color: '#009688', marginTop: '0px' }}> 
                                                                                    {e.total_delivered_quantity} 
                                                                                    </span>
                                                                                </div>
                                                                            :
                                                                            ""
                                                                            }
                                                                        </span>
                                                                    </>
                                                                )}
                                                            />
                                                        </FormGroup>
                                                        {partialDeliveryErrors.deliveryDate && (
                                                            <span className="error-msg">{t(partialDeliveryErrors.deliveryDate)}</span>
                                                        )}
                                                    </Col>

                                                    <Col className="verticalAlignmentEnd" >
                                                        <table style={{backgroundColor: '#f2f2f2' }}>
                                                            <thead  className="f-w-300 text-center"
                                                                style={{ fontSize: '13px'}}>
                                                                <td style={{ padding: '10px' }}>
                                                                {t("totalQty")}
                                                                </td>
                                                                <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '4px solid #f2f2f2', }}>
                                                                {t("noOfDeliverys")}
                                                                </td>
                                                                <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '4px solid #f2f2f2', 
                                                                            borderRight: '4px solid #f2f2f2' }}>
                                                                 {t("totDelvd")}
                                                                </td>
                                                            </thead>
                                                            <tbody className="f-w-600 text-center">
                                                                <td style={{ padding: '10px' }}>
                                                                {totalOrderQty}
                                                                </td>
                                                                <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '4px solid #f2f2f2' }} >
                                                                {noOfDelivered ? noOfDelivered : 0} / {noOfDeliveries ? noOfDeliveries : 0}
                                                                </td>
                                                                <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '4px solid #f2f2f2',
                                                                            borderRight: '4px solid #f2f2f2'}}>
                                                                {totalDeliveredQty ? totalDeliveredQty : 0}
                                                                </td>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>

                                                <Col md="12" lg="12" sm="12" style={{ paddingRight: '3.5rem' }}>
                                                    <Row className="g-12">
                                                        <div className="table-responsive">
                                                        <form id="countQty">
                                                            <table className="table" style={{ border: 'none' }}>
                                                            <thead style={{ border: 'none' }}>
                                                                <tr>
                                                                <th style={{ border: 'none', paddingLeft: '0rem' }} scope="col"> {t("color/sizeLabel")} </th>
                                                                {size.map((option) => {
                                                                    return (
                                                                        <>
                                                                        <th style={{ border: 'none', paddingLeft: '0rem' }} className="text-center m-l-5"> {option.sizeName}</th>
                                                                        </>
                                                                    )
                                                                })}
                                                                <th className="text-center" style={{ border: 'none' }} scope="col">{t("totalLabel")}</th>{" "}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {color.map((optionc) => {
                                                                    return (
                                                                        <tr>
                                                                        <th style={{ border: 'none', paddingLeft: '0rem' }} className="middle">{optionc.colorName}</th>
                                                                            {size.map((option) => {
                                                                                return (
                                                                                <th style={{ border: 'none' }}>
                                                                                    <Row>
                                                                                        <Row>
                                                                                            <Table className="table table-striped">
                                                                                                <tr className="text-center">
                                                                                                    <td className="text-center">
                                                                                                        <table className="text-center">
                                                                                                        <tbody className="f-w-600 text-center"> 
                                                                                                            <tr>
                                                                                                            <td className="text-center" style={{ padding: '0.1em' }}>
                                                                                                                <input
                                                                                                                    readOnly={disableStatus} 
                                                                                                                    style={{ width: '148px' }}
                                                                                                                    className=" form-control inpwidthsmall middle text-center"
                                                                                                                    name="userName"
                                                                                                                    id={optionc.color_id + "#" + option.size_id}
                                                                                                                    type="number"
                                                                                                                    placeholder="0"
                                                                                                                    autocomplete="off"
                                                                                                                    min="0"
                                                                                                                    onChange={(e) => { 
                                                                                                                        addQty(e), 
                                                                                                                        pendingCompletedQty(e.target.id, e.target.value), 
                                                                                                                        qtyValidation()
                                                                                                                    }}
                                                                                                                    onKeyDown={handleEnter}
                                                                                                                    onKeyPress={(e) => handleKeyPress(e)}
                                                                                                                />
                                                                                                            </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <span 
                                                                                                                    className="f-left p-l-20"
                                                                                                                    style={{ fontSize: '10px', backgroundColor: '#fff' }} 
                                                                                                                    id={optionc.color_id+"cq"+option.size_id}>
                                                                                                                </span>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                        </table>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </Table>
                                                                                        </Row>
                                                                                    </Row>
                                                                                </th>
                                                                                );
                                                                            }
                                                                            )}
                                                                        <th style={{ border: 'none' }}>
                                                                            <input
                                                                            style={{ backgroundColor: '#F2F2F2'}}
                                                                            className="form-control inpwidthsmall mt-3 text-center"
                                                                            name="totalQuantity"
                                                                            type="number"
                                                                            readOnly
                                                                            placeholder="0"
                                                                            id={"totqty_" + optionc.color_id} />
                                                                        </th>
                                                                        </tr>
                                                                    );
                                                                })}

                                                                <tr>
                                                                <th style={{ border: 'none' }}></th>
                                                                {size.map((data) => {
                                                                    return (
                                                                    <>
                                                                        <th style={{ border: 'none' }}>
                                                                            <input
                                                                                style={{ backgroundColor: '#F2F2F2'}}
                                                                                className="form-control inpwidthsmall f-left m-t-10 text-center"
                                                                                id={"SizeId_total_quantity" + data.size_id}
                                                                                type="number"
                                                                                placeholder="0"
                                                                                autocomplete="on"
                                                                                readOnly
                                                                                onKeyDown={handleEnter}
                                                                            />
                                                                        </th>
                                                                    </>)
                                                                })}
                                                                <th style={{ border: 'none' }}>
                                                                    <input
                                                                    style={{ backgroundColor: '#F2F2F2'}}
                                                                    className="form-control inpwidthsmall m-t-10 text-center"
                                                                    id="Overall_total_quantity"
                                                                    type="number"
                                                                    placeholder="0"
                                                                    autocomplete="off"
                                                                    readOnly
                                                                    />
                                                                </th>
                                                                </tr>
                                                            </tbody>
                                                            </table>
                                                        </form>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </>
                                        :
                                            <div></div>
                                    :
                                    ""
                                    }
                                {/*end Add qty */}
                                <Col md="12" lg="12" sm="12">
                                &nbsp;
                                </Col>
                        </Row>
                        <Row className="p-l-10 p-r-40">
                            <Label className="f-w-500 p-l-5">
                               {t("delvyNotes")}
                            </Label>
                            <Input 
                                defaultValue=""
                                type="textarea" 
                                className="form-control" 
                                style={{ minHeight: '150px' }}  
                                onChange={(e) => { setComments(e.target.value) }} 
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter style={{  borderTop: 'none' }}>
                        <Row style={{ width: '100%', paddingRight: '10px' }}>
                            <Col md="6" lg="6">
                            </Col>
                            <Col>
                                <Button 
                                    disabled={saveBtnStatus}
                                    style={{ width: '33%'}} 
                                    className="btn btn-primary f-right m-r-10 m-b-5" 
                                    onClick={() => { validation() }}>
                                    {t("saveBtn")}
                                </Button>
                                <Button className="btn secondaryBtn f-right m-r-10" value="Close" type="reset"
                                    onClick={() => { 
                                        toggle(), 
                                        setPartialDeliveryStatus(partialDeliveryStatus),
                                        setDisableStatus(true),
                                        setSaveBtnStatus(false)
                                        }}>
                                    {t("cancel")}
                                </Button>
                            </Col>
                        </Row>
                    </ModalFooter>
                </>
            </Modal>
        </>
    );

};

export default PartialDeliveryModal;
/***** CODE BY : RAMANAN M  *****/
// <div class="row">
// <div class="col-9" style={{ fontSize: '8px', fontWeight: 300, 
//               color: '#000', marginTop: '0px' }}>
//     Total Delivered - 
// </div>
// <div class="col-3"
//      style={{ fontSize: '8px', fontWeight: 500, 
//               color: '#009688', marginTop: '0px' }}>
//     {e.total_delivered_quantity}
// </div>
// </div>

{/* <div class="row" style={{ cursor: "pointer" }}>
    <div class="col">
        {e.text}
        {   e.is_delivered == 1 ?
            <div style={{ fontSize: '8px', fontWeight: 350, color: '#000', marginTop: '0px' }}>
                Total Delivered - 
                <span style={{ fontSize: '10px', fontWeight: 500, color: '#009688', marginTop: '0px' }}> 
                {e.total_delivered_quantity} 
                </span>
            </div>
        :
            ""
        }
    </div>
    <div class="col verticalAlignment verticalAlignmentEnd">
        {e.icon}
    </div> 
</div> */}