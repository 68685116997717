import React from "react";
import {
  Col,
  Row,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useTranslation } from "react-i18next";
const ViewSamModal = ({ modal, toggle, samViewDailyList }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>
        {t("viewSamDetails")}
        <span
          className="f-right cursor-pointer"
          title={t("close")}
          tooltip={t("close")}
          alt={t("close")}
          onClick={toggle}
        > X </span>
      </ModalHeader>
      <ModalBody>
        <Row className="g-12 m-t-20">
          <Col md="12" lg="12" sm="12">
            <Row className="g-12">
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="bg-primary">
                    <tr>
                      <th scope="col" className="centerAlign">
                        {t("time")}
                      </th>
                      <th scope="col" className="centerAlign">
                        {t("quantity")}
                      </th>
                      <th scope="col" className="centerAlign">
                        {t("comments")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {samViewDailyList.length > 0 ? (
                      samViewDailyList.map((viewDailyList) => (
                        <tr>
                          <td scope="row" className="centerAlign">
                            {viewDailyList.time_slot}
                          </td>
                          <td scope="row" className="centerAlign">
                            {viewDailyList.quantity}
                          </td>
                          <td scope="row" className="centerAlign">
                            {viewDailyList.comments
                              ? viewDailyList.comments
                              : "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <>
                        <tr className="text-center">
                          <td colSpan="3">{t("listSamDetails")}</td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ViewSamModal;
/*Code By: R.Akshaya Mol*/
