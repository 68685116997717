import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6 } from "../../AbstractElements";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ServerUrl } from "../../Constant";
import { getLoginCompanyId, getWorkspaceId, getLoginStaffId, getLoginUserId,getLoginToken } from '../../Constant/LoginConstant';
import { apidecrypt, apiencrypt, encode } from "../../helper";
import Swal from "sweetalert2";

const Color = () => {
  const history = useNavigate();
  const handleChangeStatus = ({ meta, file }, status) => { };
  const [user_id, setuser_id] = useState(getLoginUserId);
  const [staff_id, setStaff_id] = useState(getLoginStaffId);
  const [company_id, setCompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
  const [colors, setColors] = useState([]);
  const [name, setColorName] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const handleAddStaff = () => {
  //   setTimeout(() => {
  //     window.location.href = "/addstaff";
  //   }, 100);
  // };

  useEffect(() => {
    axios.post(ServerUrl + "/get-color",apiencrypt({

      company_id: company_id,
      workspace_id: workspace_id,
      user_id: user_id,
      staff_id: staff_id,
    }))

      .then((response) => {
        response.data = apidecrypt(response.data);
        setColors(response.data.data);

      });
  }, []);

  const checkStatus = (statusValue) => {
    if (statusValue === "1") {
      return (
        <td>
          <span className="font-success">Activated</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className="font-danger">Deactivated</span>
        </td>
      );
    }
  };

  const editHandle = (id) => {
    setTimeout(() => {
      window.location.href = "/editcolor?id=" + encode(id);
    }, 100);
  };

  const onSaveHandle = (a, b) => {


    Swal.fire({
      title: "Are you sure Want to Delete this Color?",
      position: 'top-middle',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: "btn-success",
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
   }).then((result) => {  
    /* Read more about isConfirmed, isDenied below */  
      if (result.isConfirmed) {    
      //  Swal.fire('Saved!', '', 'success')  
     const colorName = a;
    const colorId = b;
    axios.post(ServerUrl + "/delete-color", {
      company_id: company_id,
      workspace_id: workspace_id,
      staff_id: staff_id,
      user_id: user_id,
      name: colorName,
      id: colorId,
    }).then((response) => {
      if (response.data.status_code === 200) {
        Swal.fire({
          position: 'top-middle',
          icon: 'success',
          title: t(response.data.message),
          button:  t("okLabel")
        })
          .then((result) => {
            // if (result.isConfirmed) {
            //   document.getElementById(b).disabled = true;
            //   setTimeout(() => {
            //   }, 100);
            // }
            window.location.reload();
          })
      }
      if (response.data.status_code === 400) {
        Swal.fire({
          title: t(response.data.message),
          icon: "error",
          button:  t("okLabel"),
          confirmButtonColor: '#009688',
        })
      }

    })
  } else if (result.isDenied) {    
   // Swal.fire('Changes are not saved', '', 'info')  
 }
    


  });
    
  }
  return (
    <Fragment>
      <Row className="pgbgcolor">
           <Breadcrumbs mainTitle='Color Details' parent="Color Details" title="Color Details" subTitle="Color View"/>
           </Row>

      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row className="g-12 m-t-20">
                  {" "}
                  <Col md="12" lg="12" sm="12" className="text-end">
                    <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/addcolor`}> +Add Color</Link>
                    {/* <button
                        className="btn btn-primary"
                        onClick={() => handleAddStaff()}
                      >
                        +Add Staff
                      </button> */}
                  </Col>
                </Row>
                {/*End Add Contact Row*/}
                <Row className="g-12 m-t-20">
                  {/*start contact list */}
                  <Col md="12" lg="12" sm="12">
                    <H6>Color List</H6>
                    <Row className="g-12">
                      <div className="table-responsive">
                        <table className="table shadow shadow-showcase">
                          <thead className="bg-primary">
                            <tr>
                              <th scope="col">S.no</th>
                              <th scope="col">Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            
                            </tr>
                          </thead>
                          <tbody>
                            {colors.length > 0 ? colors.map((color, index) => (
                              
                              <tr>
                                <td scope="row">{index + 1}</td>
                                <td>{color.name}</td>
                              
                                {checkStatus(color.status)}
                                {color.is_default ==="0" ? <td>Default</td> :
                                <td>
                                  <Button
                                    id={color.id}
                                    className="btn btn-danger  btn-xs btn btn-secondary" onClick={() => onSaveHandle(color.name, color.id)} >
                                    Delete
                                  </Button>&nbsp;&nbsp;
                                  <Link className="btn btn-success btn-xs btn btn-secondary" to={`${process.env.PUBLIC_URL}/editcolor?id=${encode(color.id)}&nm=${encode(color.name)}&st=${encode(color.status)}`}>Edit</Link>
                                </td>
                                 }
                              </tr>
                            )) : <>
                              <tr className="text-center"><td colSpan="4">Add Color to List Here</td></tr>
                            </>}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </Col>
                </Row>
                {/*end second row*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Color;
