import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  InputGroup,
  InputGroupText,
  Button
} from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6 } from "../../AbstractElements";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import Swal from "sweetalert2";
import AddRoleModal from "./AddRole"
import { getLoginCompanyId, getWorkspaceId, getStaffPermission, getStaff, getLoginUserId } from '../../Constant/LoginConstant';
import { decode } from "../../helper";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../helper";
const Staff = () => {
  const history = useNavigate();
  const handleChangeStatus = ({ meta, file }, status) => { };
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const AddProject = (data) => {
  //   if (data !== "") {
  //     history(`${process.env.PUBLIC_URL}/app/project/project-list`);
  //   } else {
  //     errors.showMessages();
  //   }
  // };
  const [searchParams, setSearchParams] = useSearchParams();
  const [staff_id, setStaff_id] = useState(decode(searchParams.get("id")));
  const [staffDetails, setstaffDetails] = useState([]);
  const [roles, setRoles] = useState([]);
  const [company_id, setCompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [role_id, setRole_id] = useState("");
  const [status, setStatus] = useState("");
  const [roleModal, setRoleModal] = useState(false);

   /* Previous Data state */
  const [pcountry, setpCountry] = useState("");
  const [pfirst_name, setpFirst_name] = useState("");
  const [plast_name, setpLast_name] = useState("");
  const [pmobile, setpMobile] = useState("");
  const [paddress1, setpAddress1] = useState("");
  const [paddress2, setpAddress2] = useState("");
  const [pcity, setpCity] = useState("");
  const [pstate, setpState] = useState("");
  const [pzipcode, setpZipcode] = useState("");
  const [prole_id, setpRole_id] = useState("");
  const[pworkspace_typeId, setpWorkspace_typeId] = useState("");
  const [pstatus, setpStatus] = useState("");
  const [workspace_type, setWorkspace_type] = useState([]);
  // const[workspace_typeId,setWorkspace_typeId] = useState('');

  const toggleRoleModal = () => setRoleModal(!roleModal);
  const onGoBack = () => {
    setTimeout(() => {
      window.location.href = "/staff";
    }, 100);
  }
// console.log("STadd",staffDetails,workspace_type)
  const [validerrors, setValidErrors] = useState({});
  const validation = (data) => {
    let validerrors = {};
      let firstName = document.getElementById("firstName").value; 
      let firstNameLength = firstName.length;
      if (firstNameLength == 0) {
        validerrors.firstname = ("enterFirstName");
      } else if (!(first_name).match(/^[a-zA-Z]+$/g)) {
        validerrors.firstname = ("specialCharactersNumbersNotAllowed");
      }
      if ((last_name != null && last_name != 'null' && last_name != '')) {
        if (!(last_name).match(/[a-zA-Z]+$/g)) {
          validerrors.lastname = ("specialCharactersNumbersNotAllowed");
        }
      }
      // if (!email) {
      //   validerrors.email = t("enterEmailAddress");
      // } else if (!(email).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
      //   validerrors.email = t("enterValidEmailAddress");
      // }
      // if(!mobile){
      //   validerrors.contact = "Please enter Mobile Number";
      // }
      if (mobile) {
        if (!/^[0-9]+$/.test(mobile)) {
          validerrors.mobile = ("numbersOnlyAllowed");
        } 
        else {
          if (((mobile.length != null && mobile.length != 'null' && mobile.length != '' && (parseInt(mobile.length) < 10 ) || (parseInt(mobile.length) > 15 )))) {
            validerrors.mobile = ("enter10DigitsMobileNumber");
          }
        }
      }
      if (!role_id) {
        validerrors.role = t("pleaseSelectRole");
      }
      // if (!workspace_typeId) {
      //   validerrors.workspace_type = t("pleaseSelectUserType");
      // }
    setValidErrors(validerrors);
    return validerrors;
  }

  const onSubmitData = {};
  onSubmitData['last_name'] = last_name,
  onSubmitData['staff_id'] = staff_id, 
  onSubmitData['company_id'] = company_id, 
  onSubmitData['workspace_id'] = workspace_id, 
  onSubmitData['country'] = country, 
  onSubmitData['first_name'] = first_name, 
  onSubmitData['email'] = email, 
  // onSubmitData['user_type'] = workspace_typeId,
  onSubmitData['mobile'] = mobile, 
  onSubmitData['address1'] = address1, 
  onSubmitData['address2'] = address2, 
  onSubmitData['city'] = city, 
  onSubmitData['state'] = state, 
  onSubmitData['zipcode'] = zipcode, 
  onSubmitData['role_id'] = role_id, 
  onSubmitData['status'] = status, 

  useEffect(() => {
    axios
      .post(ServerUrl + "/get-roles", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setRoles(response.data.data);
      });
      axios.get(ServerUrl +"/workspace-type")
      .then((response) => {
        response.data = apidecrypt(response.data)
        setWorkspace_type(response.data.data);
      });
    axios.get(ServerUrl + "/get-countries").then((response) => {
      response.data = apidecrypt(response.data)
      setCountries(response.data.data);
    });
    axios.post(ServerUrl + "/edit-staff", apiencrypt({
      company_id: company_id,
      workspace_id: workspace_id,
      staff_id: staff_id
    })).then((response) => {
      response.data = apidecrypt(response.data)
      setstaffDetails(response.data.data);
      setStatus(response.data.data.status);
      setRole_id(response.data.data.role_id);
      setZipcode(response.data.data.zipcode);
      setCountry(response.data.data.country);
      setAddress1(response.data.data.address1);
      setAddress2(response.data.data.address2);
      setCity(response.data.data.city);
      setState(response.data.data.state);
      setMobile(response.data.data.mobile);
      setEmail(response.data.data.email);
      // setWorkspace_typeId(response.data.data.user_type);
      setFirst_name(response.data.data.first_name);
      setLast_name(response.data.data.last_name); 
      /* Previous Staff Details */
      setpRole_id(response.data.data.role_id);
      // setpWorkspace_typeId(response.data.data.user_type);
      setpZipcode(response.data.data.zipcode);
      setpCountry(response.data.data.country);
      setpAddress1(response.data.data.address1);
      setpAddress2(response.data.data.address2);
      setpCity(response.data.data.city);
      setpState(response.data.data.state);
      setpMobile(response.data.data.mobile);
      setpFirst_name(response.data.data.first_name);
      setpLast_name(response.data.data.last_name);
      setpStatus(response.data.data.status);
    })
    
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Staff Edit')) || getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }  

  }, []);

  const filterObject = (obj, filter, filterValue,reqValue) => 
    Object.keys(obj).reduce((acc, val) => 
    (obj[val][filter] != filterValue 
      ? acc 
      : {
        //...acc,
        [reqValue]: obj[val][reqValue],
    }                                        
  ), {});

  const OnSubmitHandle = () => {
    let validationData = validation();
    if (Object.keys(validationData).length == 0) {
      
      let previousData={}
      let updatedData={}
      if(pcountry!=country) {
        previousData['Country'] = pcountry != '' ? filterObject(countries,"id",pcountry,"name").name : "";
        updatedData['Country'] = country != '' ? filterObject(countries,"id",country,"name").name : "";
      }
      if(pfirst_name!=first_name) {
        previousData['FirstName'] = pfirst_name
        updatedData['FirstName'] = first_name
      }
      if(plast_name!= document.getElementById("lastName").value) {
        previousData['LastName'] = plast_name;
        updatedData['LastName'] = document.getElementById("lastName").value;
        onSubmitData['last_name'] = document.getElementById("lastName").value;
      } 
      if(pmobile!=mobile) {
        previousData['Mobile'] = pmobile
        updatedData['Mobile'] = mobile
      }
      if(paddress1!=address1) {
        previousData['Address1'] = paddress1
        updatedData['Address1'] = address1
      }
      if(paddress2!=address2) {
        previousData['Address2'] = paddress2
        updatedData['Address2'] = address2
      }
      if(pcity!=city) {
        previousData['City'] = pcity
        updatedData['City'] = city
      }
      if(pstate!=state) {
        previousData['State'] = pstate
        updatedData['State'] = state
      }
      if(pzipcode!=zipcode) {
        previousData['Zipcode'] = pzipcode
        updatedData['Zipcode'] = zipcode
      }
      if(prole_id!=role_id) {
        previousData['Role'] = filterObject(roles,"id",prole_id,"name").name
        updatedData['Role'] = filterObject(roles,"id",role_id,"name").name
      }
      // if(pworkspace_typeId!=workspace_typeId) {
      //   previousData['UserType'] = filterObject(workspace_type,"id",pworkspace_typeId,"name").name
      //   updatedData['UserType'] = filterObject(workspace_type,"id",pworkspace_typeId,"name").name
      // }
      if(pstatus!=status) {
        previousData['Status'] = pstatus==1 ? 'Activated' : 'Deactivated';
        updatedData['Status'] = status==1 ? 'Activated' : 'Deactivated';
      }
      
      onSubmitData['before_values']=previousData;
      onSubmitData['after_values']=updatedData;
      onSubmitData['user_id']=getLoginUserId;

      document.getElementById("save-button").setAttribute("disabled", true);

      // console.log(last_name, "Submit Data", onSubmitData);

      axios.post(ServerUrl + "/update-staff", apiencrypt(onSubmitData))
        .then((response) => {
          response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("staffUpdatedSuccessfully"),
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
            timer: 5000,
          }).then((result) => {
            if (result.isConfirmed) {
            document.getElementById("save-button").removeAttribute("disabled");
            // setTimeout(() => {
              window.location.href = "/staff";
            // }, 100);
          }
          document.getElementById("save-button").removeAttribute("disabled");
          setTimeout(() => {
            window.location.href = "/staff";
          }, 100);
          });
        }
        if (response.data.status_code === 401) {
          document.getElementById("save-button").removeAttribute("disabled");
          Swal.fire({
            title:
              response.data.errors.first_name ||
              response.data.errors.last_name ||
              response.data.errors.state ||
              response.data.errors.country ||
              response.data.errors.role_id ||
              response.data.errors.address1 ||
              response.data.errors.address2 ||
              response.data.errors.city ||
              response.data.errors.zipcode ||
              response.data.errors.status ||
              response.data.errors.email ||
              response.data.errors.mobile,
            text: t("fieldmissing"),
            icon: "warning",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 400) {
          document.getElementById("save-button").removeAttribute("disabled");
          Swal.fire({
            title: email,
            text: t(response.data.message),
            icon: "error",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 600) {
          document.getElementById("save-button").removeAttribute("disabled");
          Swal.fire({
            title: t("staffExistsOrder"),
            text: t(response.data.message),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
    }
    else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  };

  return (
    <Fragment>
      <Row className="pgbgcolor">
      <Breadcrumbs mainTitle={t("editStaffDetails")} parent="Staff Details"
          title={t("editStaffDetails")} subTitle={t("editStaffDetails")} />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row style={{ marginLeft:"10px",marginRight:"10px" }}>
                  {/* <Col sm="12">
                    {" "}
                    <H6 attrH6={{ className: "pb-2" }}>{t("staffLoginInfo")}</H6>
                  </Col> */}
                  <Card className="shadow shadow-showcase">
                    <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 " name="logo">
                                                <span className="p-r-5"></span> {t("staffLoginInfo")}<span className="p-r-5"></span>
                                                </Label>
                     </span>
                   <Row className="m-t-10">
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("firstName")}<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            className={`${validerrors.firstname && 'error-valid'}
                            form-control`}
                            type="text"
                            name="firstName"
                            placeholder={t("firstName")}
                            id="firstName"
                            defaultValue={staffDetails.first_name}
                            onChange={(e) => { setFirst_name(e.target.value = capitalizeFirstLowercaseRest(e.target.value)) }}
                          />
                          {validerrors.firstname &&
                            (<span className="error-msg">{t(validerrors.firstname)}</span>)}
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("lastName")}
                          </Label>
                          <Input
                            id="lastName"
                            className={`${validerrors.lastname && 'error-valid'} form-control`}
                            type="text"
                            placeholder={t("lastName")}
                            defaultValue={staffDetails.last_name}
                            onChange={(e) => 
                              {setLast_name(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}}
                          />
                          {validerrors.lastname &&
                            (<span className="error-msg">{t(validerrors.lastname)}</span>)}
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                            {t("email")}<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            placeholder={t("email")}
                            defaultValue={staffDetails.email}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleInputPassword2">
                            {t("mobileNumber")}
                          </Label>
                          <Input
                            className={`${validerrors.contact && 'error-valid'} form-control`}
                            type="tel"
                            placeholder={t("mobileNumber")}
                            maxLength="15"
                            defaultValue={staffDetails.mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                          {validerrors.mobile &&
                            (<span className="error-msg"> {t(validerrors.mobile)} </span>)}
                        </FormGroup>
                      </Col>
                      {/* <Col sm="12" md="6" lg="4">
                        <FormGroup>          
                        <Label>{t("userType")} <sup className="font-danger">*</sup></Label>
                          <Input type="select"
                        className="form-control"
                          // defaultValue={staffDetails.user_type}
                          onChange={(e) => setWorkspace_typeId(e.target.value)} >                           
                                {workspace_type.map( workspacetype => (
                                  workspacetype.name === staffDetails.user_type ?
                                    <option key={workspacetype.id} value={workspacetype.name} selected>{workspacetype.name=="PCU"?"Buying House":workspacetype.name}</option>
                                      : <option key={workspacetype.id} value={workspacetype.name}>{workspacetype.name=="PCU"?"Buying House":workspacetype.name}</option>
                                  ))}
                                </Input>         
                                  {validerrors.workspace_type && (
                                    <span className="error-msg">{validerrors.workspace_type}</span>
                                  )}
                                </FormGroup>
                    </Col> */}
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            {t("role")}<sup className="font-danger">*</sup>
                          </Label>
                          <InputGroup>
                            <Input
                              type="select"
                              name="select"
                              className="form-control"
                              defaultValue={staffDetails.role_id}
                              onChange={(e) => setRole_id(e.target.value)}
                            >
                              {roles.map((role) => (
                                role.id === staffDetails.role_id ?
                                  <option key={role.id} value={role.id} selected>{role.name}</option>
                                  : <option key={role.id} value={role.id}>{role.name}</option>
                              ))}

                            </Input>
                            {(getStaff === "Staff" && getStaffPermission.includes('Add Roles')) || getStaff == null ?
                              <InputGroupText>
                                {/* <Btn attrBtn={{ color: 'primary', onClick: toggleRoleModal }}>+Add</Btn>  */}
                                <span
                                  className="btn selectbtn"
                                  onClick={toggleRoleModal}
                                >
                                  {t("add")}
                                </span>
                                <AddRoleModal modal={roleModal} toggle={toggleRoleModal} company_id={company_id}
                                  workspace_id={workspace_id} roles={setRoles} />
                              </InputGroupText> : ""
                            }
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col sm="12" md="6" lg="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            {t("status")}<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            type="select"
                            name="select"
                            className="form-control digits"
                            defaultValue={staffDetails.status}
                            onChange={(e) => setStatus(e.target.value)}>
                            {staffDetails.status === '1' ? <><option value={"1"} selected>{t("activated")}</option>
                              <option value={"2"}>{t("deactivated")}</option></> : <><option value={"1"}>{t("activated")}</option>
                              <option value={"2"} selected>{t("deactivated")}</option></>}
                          </Input>
                        </FormGroup>
                      </Col>
                  </Row></Card>
                </Row>
                <Row style={{ margin:"10px" }}>
                  {/* <Col sm="12">
                    {" "}
                    <H6 attrH6={{ className: "pb-2" }}>{t("addressInfo")}</H6>
                  </Col> */}
                  <Card className="shadow shadow-showcase">
                  <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 " name="logo">
                                                <span className="p-r-5"></span> {t("addressInfo")}<span className="p-r-5"></span>
                                                </Label>
                     </span>
                  <Row className="m-t-10">
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("addressline1")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("addressline1")}
                          defaultValue={staffDetails.address1}
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("addressline2")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("addressline2")}
                          defaultValue={staffDetails.address2}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("city")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("city")}
                          defaultValue={staffDetails.city}
                          onChange={(e) => setCity(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("statePrefecture")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("statePrefecture")}
                          defaultValue={staffDetails.state}
                          onChange={(e) => setState(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("postalCode")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("postalCode")}
                          defaultValue={staffDetails.zipcode}
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {t("country")}
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits custom-select"

                          defaultValue={(staffDetails.country) == null ? "Select Country" : staffDetails.country}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option disabled>{t("selectCountry")}</option>
                          {countries.map((country) => (
                            country.id == staffDetails.country ?
                              <option key={country.id} value={country.id} selected>{country.name}</option>
                              : <option key={country.id} value={country.id}>{country.name}</option>

                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row></Card>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup className="mb-0  f-right">
                    <Link to="/staff">
                        <Button className="btn secondaryBtn m-r-10"
                          onClick={() => onGoBack()}>
                          {t("cancel")}
                        </Button>
                      </Link >
                      <Button id="save-button"
                        className="btn btn-primary"
                        onClick={() => OnSubmitHandle()}>
                        {t("save")}
                      </Button>
                    
                    </FormGroup>
                  </Col>
                
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Staff;
//**************************Code by : Rithanesh****************************//

