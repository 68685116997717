import React, { Fragment, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { H5, P } from '../../AbstractElements';
import Knob from 'knob';
import ConfigDB from '../../Config/ThemeConfig';

const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const KnobChartClass2 = (props) => {
    useEffect(() => {

        var profit2 = Knob({
            value: props.numerator,
            left: 1,
            angleOffset: 0,
            className: 'review',
            thickness: 0.2,
            fgColor: primary,
            readOnly: true,
            dynamicDraw: true,
            tickColorizeValues: true,
            bgColor: '#eeeeee',
            lineCap: 'round',
            displayPrevious: false,        
            min: 0,
            max: props.denominator,
            width: 70
        });
        const list = document.getElementById("profit2");

        if (list.hasChildNodes()) {
          list.removeChild(list.children[0]);
        }
        document.getElementById('profit2').appendChild(profit2);
    }, []);

    return (
        <Fragment>
            {/* <Col xl="12" sm="6" className="box-col-3 chart_data_right second">
                <KnobChart price="$4,55,462" knobId={'profit1'} title='Our Annual Income' />
                <Card className="income-card card-secondary">
                    <CardBody> */}
                        <div className="round-progress knob-block text-center">
                            <div className="progress-circle">
                                <div className="knob" id={'profit2'} >
                                </div>
                            </div>
                        </div>
                    {/* </CardBody>
                </Card>
             </Col> */}
        </Fragment>
    );
};

export default KnobChartClass2;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/