import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col, Nav, NavItem, NavLink, TabContent, TabPane  } from "reactstrap";
import Moment from "moment";
import { PHPtoJSFormatConversion } from "../../../helper";
import { useTranslation } from 'react-i18next';

const ApprovalHistoryOffcanvas = ({ modal, toggle, viewOrderBomApproval }) => {
  const [BasicLineTab, setBasicLineTab] = useState('1');
  const { t } = useTranslation();

    const approvedGreyCircleStyle = {
        backgroundColor: '#B8B8B8', 
        height: '6px', 
        borderRadius: '20px', 
        width: '4px', 
        padding: '5px',
        marginTop: '3px',
        marginRight: '3px'
    };

    const approvedGreenCircleStyle = {
        backgroundColor: '#3FC984', 
        height: '6px', 
        borderRadius: '20px', 
        width: '4px', 
        padding: '5px',
        marginTop: '3px',
        marginRight: '3px'
    };

  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"} className="offcanvas-width">
            <OffcanvasHeader className="bg-primary offcanvas-header">
            {t("approvalHistory")} -  {t("approved")}
            <span className="f-right cursor-pointer" onClick={toggle}>X</span></OffcanvasHeader> 
      <OffcanvasBody>
        <>
            <TabContent activeTab={BasicLineTab}>
                <TabPane className="fade show" tabId="1">
                    {
                            viewOrderBomApproval.length > 0 && viewOrderBomApproval !== undefined  
                        ?
                            viewOrderBomApproval
                            .map((data, index)=>
                            {
                                return(
                                    <Card>
                                        <CardBody className="p-2 p-b-0 p-t-0">
                                            <Row className="verticalAlignmentStart">
                                                <Col style={{ padding: '0.2rem' }} className="p-b-0">
                                                    <div className="border-3 b-l-primary f-17 
                                                                    font-primary p-b-0  f-w-500 p-l-5">
                                                        {data.approved_by === null ? "" : data.approved_by}
                                                    </div>
                                                </Col>
                                                <Col md={3} sm={3} className="b-r-light b-l-light p-b-0">
                                                    <div className="fc-event-main-frame">
                                                        <p className="f-w-300"> {t("date")} </p>
                                                        <p className="f-w-600"> 
                                                            {data.approval_date ? PHPtoJSFormatConversion.format(new Date(data.approval_date.split(" ")[0]))  : ""}  
                                                        </p>
                                                    </div>
                                                </Col>
                                                <Col md={3} sm={3} className="p-b-0">
                                                        <div className="fc-event-main-frame">
                                                        <p className="f-w-300">{t("time")}</p>
                                                        <p className="f-w-600">  
                                                            {data.approval_date ?  (data.approval_date.split(" ")[1]) : ""} 
                                                        </p>
                                                        </div>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col md={12} sm={12} className="p-1 p-b-0 p-t-0">
                                                        <div className="card-highlighted-text p-2 fc-event-main-frame">{t("comments")}</div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="p-2" style={{ justifyContent: 'center' }}>
                                                    <Row className="f-w-300 " style={{ paddingLeft: '15px', paddingRight: '15px', justifyContent: 'center' }}> 
                                                        { data.comments } 
                                                    </Row>

                                                    {
                                                            viewOrderBomApproval.length == 1 
                                                        ?
                                                            <Row style={{ float: 'right', paddingRight: '18px', color: '#3FC984', fontSize: '11px' }}> 
                                                                <p style={approvedGreenCircleStyle} ></p> {t("approved")} 
                                                            </Row>
                                                        :
                                                            viewOrderBomApproval.length == 2
                                                        ?
                                                            index+1 == viewOrderBomApproval.length 
                                                                ?
                                                                <Row style={{ float: 'right', paddingRight: '18px', color: '#3FC984', fontSize: '11px' }}> 
                                                                    <p style={approvedGreenCircleStyle} ></p> {t("reapproved")} 
                                                                </Row>
                                                                :
                                                                <Row style={{ float: 'right', paddingRight: '18px', color: '#B8B8B8',  fontSize: '11px' }}> 
                                                                    <p style={approvedGreyCircleStyle} ></p> {t("approved")} 
                                                                </Row>
                                                        :
                                                             // viewOrderBomApproval.length >2
                                                                index+1 == 1 
                                                                ?
                                                                    <Row style={{ float: 'right', paddingRight: '18px', color: '#B8B8B8', fontSize: '11px' }}> 
                                                                        <p style={approvedGreyCircleStyle} ></p> {t("approved")}    
                                                                    </Row>
                                                                :
                                                                    index+1 == viewOrderBomApproval.length 
                                                                    ?
                                                                        <Row style={{ float: 'right', paddingRight: '18px', color: '#3FC984', fontSize: '11px' }}> 
                                                                            <p style={approvedGreenCircleStyle} ></p> {t("reapproved")}  
                                                                        </Row>
                                                                        
                                                                    :
                                                                        <Row style={{ float: 'right', paddingRight: '18px', color: '#B8B8B8', fontSize: '11px' }}> 
                                                                            <p style={approvedGreyCircleStyle} ></p> {t("reapproved")}   
                                                                        </Row>
                                                                        
                                                    }
                                                </div>
                                            </Row> 
                                        </CardBody>
                                    </Card>
                                )
                            })
                        :
                            ""
                    } 
                </TabPane>
            </TabContent>
        </>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default ApprovalHistoryOffcanvas;
/* Code By : Ramanan M */