import { Container, Label, Card, CardBody, Col, Row, Input, InputGroup, Button, FormGroup, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs} from "../../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,
     getWorkspaceType, getStaff, getStaffPermission, 
     getLoginStaffId,getModules } from "../../../Constant/LoginConstant";
import noData from "../../../assets/images/dms/nodata.png"
import DownloadIcon from "../../../assets/images/dms/icons/DowloadIconDms.svg";
import { apiencrypt, apidecrypt } from "../../../helper";
import OrderAccessoriesReportsTable from "./OrderAccessoriesReportsTable";
import PDFDownloadTypeModalFile from './PDFDownloadTypeModal';
import { useTranslation } from 'react-i18next';

const OrderAccessoriesReports = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [pcu, setPcu] = useState("");
    const [styles, setStyles] = useState([]);
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
    const [factories, setFactories] = useState([]);
    const { t } = useTranslation();
    let detailsToSend;
    getStaff === "Staff" ?  
        detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
        staff_id : getLoginStaffId
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
    };

    /***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
    const filterStyle = (type,id)=>{
        if(type ==="Factory"){
            axios.post(ServerUrl + "/get-filters", apiencrypt({
            company_id: company_id,
            workspace_id: workspace_id,
            factory_id : id,
            type : getWorkspaceType,
            })).then((response) => {
                response.data = apidecrypt(response.data)
            if(getWorkspaceType === "Buyer"){
                setPcus(response.data.data);
            }
            else if(getWorkspaceType === "PCU"){
                setBuyers(response.data.data);
            }  
            });
        }
        if(type ==="Buyer"){
            axios.post(ServerUrl + "/get-filters", apiencrypt({
            company_id: company_id,
            workspace_id: workspace_id,
            buyer_id : id,
            type : getWorkspaceType,
            })).then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });
        }
    };

    /*********************** To Download The PDF ********************/
    const choosePDFDownloadType = () => {
        togglePDFDownloadTypeModal();
    };  

    const validation = () => {
        var errors = {};
        if(!orderId){
            errors.orderId = t("styleNoValidationErrorMessage");
        }
        setValidErrors(errors);
        return errors;
    };

    /* Order Details */
    const getBillOfMaterialsReportDetails= ( ) => {

        let retval = validation();
        if (Object.keys(retval).length == 0) {
            axios
            .post(ServerUrl + "/get-order-bom", apiencrypt({ order_id: orderId}))
            .then((response) => {
                response.data = apidecrypt(response.data);
                if(  response.data.data !== null ){
                    setToOpen(1);
                    setGetOrderBOMData(response.data.data);
                    setSewingAccessoriesList(JSON.parse(response.data.data.sewing_accessories));
                    setPackingAccessoriesList(JSON.parse(response.data.data.packing_accessories));
                    setMiscellaneousList(JSON.parse(response.data.data.miscellaneous));
                } else{
                    setToOpen(0);
                    setGetOrderBOMData(response.data.data);
                } 
            });
        }

    };

    const clearData = () => {

        if( styleNoList.length > 0 ){
            document.getElementById('styleNoId').selectedIndex = 0;
        } 
        if( factories.length > 0  ){
            document.getElementById('facID').selectedIndex = 0;
        } 
        if( Pcus.length > 0 ){
            document.getElementById('pcuID').selectedIndex = 0;
        } 
        if( buyers.length > 0 ){
            document.getElementById('buyerID').selectedIndex = 0;
        }

        setValidErrors(() => "");
        setOrderId(() => "");
        setGetOrderBOMData(() => "");
        setToOpen(0);

    };

    const [ orderId, setOrderId ] = useState("");
    const [ styleNo, setStyleNo ] = useState("");
    
    const [ styleNoList, setStyleNoList ] = useState([]);
    const [ vendorList, setVendorList ] = useState([]);

    const [ toOpen , setToOpen ] = useState(0);
    const [ getOrderBOMData, setGetOrderBOMData ] = useState([]);
    const [ sewingAccessoriesList, setSewingAccessoriesList ] = useState([]);
    const [ packingAccessoriesList, setPackingAccessoriesList ] = useState([]);
    const [ miscellaneousList, setMiscellaneousList ] = useState([]);

    const [PDFDownloadTypeModal, setPDFDownloadTypeModal] = useState(false);
    const  togglePDFDownloadTypeModal = () => setPDFDownloadTypeModal(!PDFDownloadTypeModal);

    useEffect(() => {
        {

            (((getStaff === "Staff" && getModules.includes("Reports") && getStaffPermission.includes('View Report')) || getStaff == null )) 
            ?
            ""
            :
            window.location.href = "/dashboard" 
        } 

        var detailsToSend;
        getStaff === "Staff" ?  detailsToSend = {
            company_id: getLoginCompanyId,
            workspace_id: getWorkspaceId,
            staff_id : getLoginStaffId,
        } : detailsToSend = {
            company_id: getLoginCompanyId,
            workspace_id: getWorkspaceId,
            user_id : getLoginUserId,
        } 
        
        const dataToSendAtStarting = detailsToSend;

        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            }); 

        axios
            .post(ServerUrl + "/get-styles", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                    response.data = apidecrypt(response.data)
                    setStyleNoList(response.data.data);
            });

        axios
            .post(ServerUrl + "/all-vendor-list", apiencrypt({
                "company_id": getLoginCompanyId,
                "workspace_id": getWorkspaceId,
            }))
            .then((response) => {
                response.data = apidecrypt(response.data);
                setVendorList(response.data.data);
            });

        
        getWorkspaceType == "Factory" ? "" : window.location.href = '/dashboard'
        
    }, []);
    
    return (
        <Fragment>
            <Row  className="pgbgcolor">
                <Breadcrumbs mainTitle={t('orderAccessoriesReport')} 
                    parent={t('orderAccessoriesReport')} 
                    title={t('orderAccessoriesReport')}
                />
            </Row>
            <Container fluid={true} className="general-widget topaln">
                <Card className="m-b-20">
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">

                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("factory")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                className={`${errors.factory && "select-error-valid"}  form-control`}
                                                name="factory"
                                                type="select"
                                                placeholder={t("factory")}
                                                aria-label="factory"
                                                defaultValue="0"
                                                id="facID"
                                                onChange={(e) => {setFactory(e.target.value);let facId = e.target.value;filterStyle("Factory",facId);}}
                                            >
                                                <option value="0">{t("selectFactory")}</option>
                                                { factories.length > 0 
                                                ?
                                                    factories.map((factory, index) => ( <option key={index} value={factory.id}>{factory.name}</option> ))
                                                :
                                                    ""
                                                }
                                            </Input>
                                        </InputGroup>
                                        {errors.factory && (
                                            <span className="error-msg">{t(errors.factory)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : ( "" )}

                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                className={`${errors.buyer && "select-error-valid"
                                                    }  form-control`}
                                                name="buyer"
                                                type="select"
                                                placeholder={t("buyer")}
                                                aria-label="buyer"
                                                defaultValue="0"
                                                id="buyerID"
                                                onChange={(e) => {setBuyer(e.target.value);let buyerId = e.target.value;filterStyle("Buyer",buyerId);}}
                                            >
                                                <option value="0">{t("selectBuyer")}</option>
                                                { buyers.length > 0 ?
                                                    buyers.map((buy, index) => ( <option key={index} value={buy.id}>{buy.name}</option> ))
                                                :
                                                    ""
                                                }
                                            </Input>
                                        </InputGroup>
                                        {errors.buyer && (
                                            <span className="error-msg">{t(errors.buyer)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : ( "" )}

                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                type="select"
                                                placeholder="Select Status"
                                                className={`${errors.pcu && "select-error-valid"
                                                    }  form-control digits`}
                                                name="pcuid"
                                                defaultValue="0"
                                                id="pcuID"
                                                onChange={(e) => {setPcu(e.target.value);let pcuId = e.target.value;filterStyle("PCU",pcuId);}}
                                            >
                                                <option value="0">
                                                    {t("selectPCU")}
                                                </option>
                                                { Pcus.length > 0 ?
                                                    Pcus.map((pcuv, index) => ( <option key={index} value={pcuv.id}>{pcuv.name}</option> ))
                                                :
                                                    ""}
                                            </Input>
                                        </InputGroup>
                                        {errors.pcu && (
                                            <span className="error-msg">{t(errors.pcu)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                                ) : ( "" )}

                            <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                    <Label>{t("styleNo")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            id="styleNoId"
                                            type="select"
                                            placeholder="Select Status"
                                            className={`${errors.orderId && "select-error-valid"} form-control digits`}
                                            name="dropDown"
                                            value={orderId}
                                            defaultValue="0"
                                            onChange={(e) => { 
                                                setOrderId(e.target.value),
                                                setStyleNo(e.target.id)
                                            }}>
                                            <option value="0" > {t("selectStyleno")} </option>
                                            {styleNoList.map((styleList) => (
                                                <option id={styleList.style_no} value={styleList.id}>{styleList.style_no} 
                                                ({styleList.order_no})
                                                </option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                    {errors.orderId && (
                                        <span className="error-msg">{errors.orderId}</span>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" 
                                        onClick={() => getBillOfMaterialsReportDetails()}>
                                    {t("search")}
                                </Button>
                                <Button className="secondaryBtn f-left m-r-10 m-b-5" 
                                        onClick={() => { clearData() }}>
                                    {t("clear")}
                                </Button>
                            </Col>

                        </Row>
                    </CardBody>
                </Card>
            </Container>
           
            {toOpen===1
            ? 
                getOrderBOMData !== null ?
                    <>
                        <Card className="m-b-10 m-t-0" style={{ border:"0px" }}>
                            <CardBody className=" p-1">                
                                <Row>
                                    <Col lg="12" md="12" sm="12" xs="12" className="centerAlign">
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={4} className="p-1 f-right">
                                                    {(getStaff === "Staff"&&getStaffPermission.includes('Download Report'))||getStaff == null? 
                                                        <div className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" 
                                                                onClick={()=> choosePDFDownloadType()}>
                                                            <img title={t("download")} src={DownloadIcon} />
                                                        </div>
                                                        : ""}
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <OrderAccessoriesReportsTable 
                            sewingAccessoriesList={sewingAccessoriesList} 
                            packingAccessoriesList={packingAccessoriesList} 
                            miscellaneousList={miscellaneousList} 
                            styles={styles} 
                            vendorList={vendorList} 
                        /> 

                    </>
                : 
                    <>
                        <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                            <img src={noData} style={{ width:"200px"}} />
                            <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                        </div>
                    </> 
            : 
                getOrderBOMData == null ?
                    <>
                        <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                            <img src={noData} style={{ width:"200px"}} />
                            <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                        </div>
                    </> 
                :
                    "" 
            }
                
            <PDFDownloadTypeModalFile 
                modal={PDFDownloadTypeModal}
                toggle={togglePDFDownloadTypeModal} 
                orderId={orderId} 
                styleNo={styleNo}
            />

        </Fragment>
    );
}

export default OrderAccessoriesReports;
/* Code By : Ramanan */