import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import {
    Card, CardBody, Col, Row, Table, Collapse,
    FormGroup, InputGroup, Input, Label, Progress, CardFooter
} from 'reactstrap';
import Moment from 'moment';
import SewIcon from "../../../assets/images/dms/icons/sewIcon.svg";
import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const SewKnobChart = ({sewActualTargetValue,sewTodayUpdatedValue,sewEndDate,sewPerDay,sewAvgPerDay,sewEstDate,sewActualDate,sewCompleted}) => {
    const { t, i18n } = useTranslation();
    let sewEstimatedRatePercentage = Math.round((sewTodayUpdatedValue/sewActualTargetValue)*100);
    let currentDate = Moment().format('DD MMM YYYY');
    let endDate=Moment(sewEndDate).format('DD MMM YYYY');
   /******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
    const knobChartColor = () => {
        // if(sewActualTargetValue<=sewAvgPerDay){
        //     return '#00B38E'; 
        // } else{
            
        //     return '#FF3169';
        // }
        if((new Date(sewEndDate).getTime() >= new Date(sewEstDate).getTime())){
            return '#00B38E'; 
        }
        // else if((sewActualTargetValue<=sewAvgPerDay)){
        //     return '#00B38E'; 
        // }
        else{
            return '#FF3169';
        }
        // if(  (sewEstimatedRatePercentage >= 0) && (sewEstimatedRatePercentage <= 40)){
        //     return '#FF3169';
        // }
        // if( (sewEstimatedRatePercentage > 40) && (sewEstimatedRatePercentage <= 70)){
        //     return '#E58F1F';
        // }
        // if( (sewEstimatedRatePercentage > 70) && (sewEstimatedRatePercentage <= 100) || (sewEstimatedRatePercentage > 100)){
        //     return '#00B38E';
        // }
    }
    useEffect(() => {
        var offsetArc = Knob({
            value: sewAvgPerDay?sewAvgPerDay:0,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: sewAvgPerDay
        });
        const sewlist = document.getElementById('sewknob');
        if (sewlist.hasChildNodes()) {
            sewlist.removeChild(sewlist.children[0]);
        }
        document.getElementById('sewknob').appendChild(offsetArc);
    }, [sewAvgPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align">
                <div className="knob text-center m-t-5" id="sewknob">
                </div>
                <div className=' text-muted f-12' style={{ color: "#828282" }}>{t("currentAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                
                {/* <div className=' f-w-600 ' >{sewEstDat}</div> */}
                {sewCompleted==0? <div className='f-w-600'  >-</div>: sewEstDate? <div className=' f-w-600 '  >{(PHPtoJSFormatConversion.format(new Date(sewEstDate)))} </div>:
                <div className=' f-w-600'  >{(PHPtoJSFormatConversion.format(new Date(sewActualDate)))} </div>}
                {sewEstDate=="" ? <div className='text-muted  f-12 '  >{t("actDateCompletion")}</div>:
                <div className='text-muted f-12 '  >{t("estDateCompletion")}</div>}
                
                {/* <div className=' text-muted f-12' style={{ color: "#828282" }}>Est Date Of Completion</div> */}
                {/* <div style={{ backgroundColor: "#5159AC" }} className='taskCardBorder'>
                    <img src={SewIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Sewing</span>
                </div> */}
            </Card>
        </>
    );
}
export default SewKnobChart;