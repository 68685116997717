import configDB from "./ThemeConfig";
const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;
const secondary = localStorage.getItem('secondary_color') || configDB.data.color.secondary_color;

// ******************* Task Pie Chart ***************************//
export const apexPieTaskChart = {
  options: {
    chart: {
      width: 400,
      type: 'pie',
      toolbar: {
        show: false
      },
    },
    legend: {
        show:false,
      position: "right",
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'poppins',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: 400,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: -140,
      offsetY: 20,
      formatter: function (value, opts) {
        return value + "   " + + opts.w.globals.series[opts.seriesIndex];
      },

      itemMargin: {
        horizontal: 10,
        vertical: 10
      },

    },
    dataLabels: {
      style: {
        fontSize: '14',
        fontWeight: '600',
      },
    },
    labels: ['Completed', 'Del.completion', 'Delayed', 'In Progress', 'Yet to start'],
    colors: ['#26A69A', '#E69020', '#FF3838', '#C7D0DD', '#E8EFF0'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'right',
          horizontalAlign: 'center',
        }
      },
      breakpoint: 1000,
      options: {
        plotOptions: {
          pie: {
            horizontal: false
          }
        },

      },
    }]
  },
};

// ******************* Production Pie Chart ***************************//
export const apexPieChart = {
  options: {
    chart: {
      width: 400,
      type: 'pie',
      toolbar: {
        // show: false
        tools: {
          download: false,
        },

      },
      events: {
        dataPointMouseEnter: function (event, chartContext, config) {
        }
      },
    },
    legend: {
      show:false,
      position: "right",
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      horizontalAlign: 'center',
      floating: false,
      fontSize: '14px',
      fontFamily: 'poppins',
      fontWeight: 400,
      formatter: undefined,
      inverseOrder: false,
      width: 300,
      height: undefined,
      tooltipHoverFormatter: undefined,
      customLegendItems: [],
      offsetX: -140,
      offsetY: 90,
      itemMargin: {
        horizontal: 10,
        vertical: 1
      },
      formatter: function (value, opts) {
        if ((value == "Cutting")) {
          return value + "   " + + opts.w.globals.series[opts.seriesIndex] + "%";
        }
        if ((value == "Sewing")) {
          return value + "   " + + opts.w.globals.series[opts.seriesIndex] + "%";
        }
        if ((value == "Packing")) {
          return value + "   " + + opts.w.globals.series[opts.seriesIndex] + "%";
        }
        if ((opts.seriesIndex == 1)) {
          return "";
        }
        if ((opts.seriesIndex == 3)) {
          return "";
        }
        if ((opts.seriesIndex == 5)) {
          return "";
        }
      },
    },
    fill: {
      colors: ['#4A70B1', '#E8EFF0', '#649EBE', '#E8EFF0', '#51B9CC', '#E8EFF0'],
    },
    plotOptions: {
      pie: {
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14',
        fontWeight: '600',
      },
      formatter: function (value,opts) {
        return opts.w.globals.series[opts.seriesIndex] + "%";
      },
    },
    labels: [
      "Cutting", "Cutting-Pending", "Sewing", "Sewing-Pending", "Packing", "Packing-Pending"
    ],
    colors: ['#4A70B1', '#FFF', '#649EBE', '#FFF', '#51B9CC', '#FFF'],
    tooltip: {
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      theme: false,
      style: {
        fontSize: '12px',
        fontFamily: 'poppins',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      formatter: function (opts) {
        return opts.w.globals.series[opts.seriesIndex] + "%";
      },
      marker: {
        show: false,
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 0,
        offsetY: 0,
      },
    }
  },
};
 
//*********************************Code by : Praveen****************************//



// ******************* Notata Pie Chart ***************************//
// export const apexPieChartNoDataFound = {
//   options: {
//     chart: {
//       width: 700,
//       type: 'pie',
//       toolbar: {
//         tools: {
//           download: false,
//         },

//       },
//     },
//     legend: {
//       color: ['#f2f2f2'],
//       position: "right",
//       showForSingleSeries: false,
//       showForNullSeries: true,
//       showForZeroSeries: true,
//       horizontalAlign: 'center',
//       floating: false,
//       fontSize: '14px',
//       fontFamily: 'poppins',
//       fontWeight: 400,
//       formatter: undefined,
//       inverseOrder: false,
//       width: 300,
//       height: undefined,
//       tooltipHoverFormatter: true,
//       customLegendItems: [],
//       offsetX: -50,
//       offsetY: 60,
//       formatter: function (value, opts) {
//         return value + "   " + 0 + "%";
//       },
//       itemMargin: {
//         horizontal: 10,
//         vertical: 10
//       },
//     },
//     fill: {
//       colors: ['#f2f2f2'],
//       // borderColor: ['red']
//     },
//     plotOptions: {
//       pie: {},
//     },
//     dataLabels: {
//       style: {
//         fontSize: '0',
//         fontWeight: '600',
//         // colors: ['#000']
//       },
//     },
//     labels: ["Cutting", "Sewing", "Packing"],
//     colors: ['#4A70B1', '#649EBE', '#51B9CC'],
//     tooltip: { enabled: false, },
    
//   },
// }
