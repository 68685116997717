import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, CardHeader, Collapse, FormGroup, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ServerUrl } from "../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff,getStaffPermission, getLoginStaffId } from "../../Constant/LoginConstant";
import factoryimg from "../../assets/images/dms/order-factory.png";
import pcuimg from "../../assets/images/dms/order-pcu.png";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../Constant";
import factoryImg from '../../assets/images/dms/factory.svg';
import pcuImg from '../../assets/images/dms/PCU.svg';
import PendingTaskAccordion from "./PendingTasksAccordion";
import FilterOffCanvas from "./FilterOffcanvas";
import noData from "../../assets/images/dms/nodata.png"
import FilterIcon from "../../assets/images/dms/icons/FilterIconDms.svg"
import DownloadIcon from "../../assets/images/dms/icons/DowloadIconDms.svg"
import { DownloadFile, apiencrypt, apidecrypt } from "../../helper";
import CompletedIcons from '../../assets/images/dms/taskicons/CompletedSmall.svg';
import DelayCompletionIcons from '../../assets/images/dms/taskicons/DelayedCompletionSmall.svg';
import InprogressIcons from '../../assets/images/dms/taskicons/InProgressSmall.svg';
import YettostartIcons from '../../assets/images/dms/taskicons/YettoStartSmall.svg';
import DelayIcons from '../../assets/images/dms/taskicons/DelaySmall.svg';
import { useTranslation } from 'react-i18next';

const PendingTasks = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pcu, setPcu] = useState("");
    const [orderNo, setOrderNo] = useState("");
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
    const [factories, setFactories] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [details, setDetails] = useState([]);
    const [filterDays, setFilterDay] = useState("0");
    const [filterPIC, setFilterPIC] = useState("0");
    const [filterOperator, setFilterOperator] = useState("0");
    const { t } = useTranslation();
    let detailsToSend;
    getStaff === "Staff" ?  detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
    } 
    const dataToSendAtStarting = detailsToSend;
    const [PIC, setPIC] = useState([]);
    const [toOpen , setToOpen] = useState(0);
    const [filterOffCanvas , setFilterOffCanvas] = useState(false);
    const toggleFilterCanvas = () => setFilterOffCanvas(!filterOffCanvas);
    /* To Call the API's */
    useEffect(() => {
        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-ordersList", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setOrders(response.data.data);
            });  
        
        {
            (((getStaff === "Staff" && getStaffPermission.includes('View Pending Task')) || getStaff == null )) 
            ?
            ""
            :
            window.location.href = "/dashboard" 
        }  
    }, []);
/***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
  const filterStyle = (type,id)=>{
    if(type ==="Factory"){
      axios.post(ServerUrl + "/get-ordersList", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        factory_id : id,
        staff_id : getLoginStaffId,
      })).then((response) => {
          response.data = apidecrypt(response.data)
          setOrders(response.data.data);
      });
    }
    if(type ==="Buyer"){
      axios.post(ServerUrl + "/get-ordersList", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        buyer_id : id,
        staff_id : getLoginStaffId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
          setOrders(response.data.data);
      });
    }
    if(type ==="PCU"){
      axios.post(ServerUrl + "/get-ordersList", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        pcu_id : id,
        staff_id : getLoginStaffId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
          setOrders(response.data.data);
      });
    }
  } 
    /*Order Validation  Start*/
const orderValidation = (data) => {
    let errors={};
    // if (!factory && getWorkspaceType!='Factory') {
    //     errors.factory = 'Please Select a Factory';
    //    }
    //    if (!buyer  && getWorkspaceType!='Buyer') {
    //     errors.buyer = 'Please Select a Buyer';
    //    }
    //    if (!pcu  && getWorkspaceType!='PCU') {
    //     errors.pcu = 'Please Select a PCU';
    //    }
    if (!orderNo) {
      errors.orderNo = ('selectOrderNo');
     }
  
   setValidErrors(errors);
   return errors;
  };

/*********************** To Download The PDF ********************/
const toDownloadAsPdf = (orderNo) => {
    let dataToSendForOrder ;
    getStaff === "Staff" ? dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        orderNo : orderNo,
        staff_id: getLoginStaffId,
        }: dataToSendForOrder = {
            company_id: company_id,
            workspace_id: workspace_id,
            orderNo : orderNo,
            user_id: getLoginUserId,
            };
    if(filterPIC != "0"){
        dataToSendForOrder.picId = filterPIC;
    }
    if(filterOperator != "0" && filterDays != "0"){
        dataToSendForOrder.noOfDays = filterDays;
        dataToSendForOrder.selector = filterOperator;
    }
    axios.post(ServerUrl + "/download-pendingTask-pdf", apiencrypt(dataToSendForOrder),{responseType: 'blob'})
    .then((response)=>{
        let fileName = orderNo+"-PendingTask.pdf";
        DownloadFile(response.data,fileName);
    })
}  
/******************************** To set the styles after filtering ************************/
  const getDetails = async()=>{

    let retval= orderValidation();
    if(Object.keys(retval).length == 0){
    let dataToSendForOrder
    getStaff === "Staff" ? dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        } : dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
        };
    dataToSendForOrder.orderNo = document.getElementById("orderNo").value;
    await axios.post(ServerUrl + "/get-pending-tasks-list", apiencrypt(dataToSendForOrder))
    .then((response) =>{
        response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){            
            setDetails(response.data.data);
            setTaskDetails(response.data.data.styleDetails);
            setPIC(response.data.data.picDetails);
            setToOpen(1);
            // axios.post(ServerUrl+"/get-staffs",{
            //     "company_id": company_id,
            //     "workspace_id": workspace_id,
            //   }).then((response)=>{
            //     console.log("response--->",response.data.data);
            //     setStaffs(response.data.data);
            //   })
        }
    })
}

  }
    return (
        <Fragment>
              <Row className="pgbgcolor">
           <Breadcrumbs mainTitle={t('pendingTask')} parent={t('pendingTask')} title={t('pendingTask')}/>
           </Row>
             <Container fluid={true} className="general-widget topaln">
                <Card>
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("factory")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                // className={`${errors.factories && "select-error-valid"
                                                //     }  form-control`}
                                                name="factories"
                                                type="select"
                                                placeholder="factories"
                                                aria-label="factories"
                                                defaultValue=""
                                                // onChange={handleChange}
                                                onChange={(e) => {setFactory(e.target.value);let facId = e.target.value;filterStyle("Factory",facId);}}
                                            >
                                                <option value="0">{t("selectFactory")}</option>
                                                {factories.map((factory) => (
                                                    <option key={factory.id} value={factory.id}>{factory.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.factories && (
                                            <span className="error-msg">{errors.factories}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                // className={`${errors.buyer && "select-error-valid"
                                                //     }  form-control`}
                                                name="buyer"
                                                type="select"
                                                placeholder={t("buyer")}
                                                aria-label="buyer"
                                                defaultValue=""
                                                //onChange={handleChange}
                                                onChange={(e) => {setBuyer(e.target.value);let buyerId = e.target.value;filterStyle("Buyer",buyerId);}}
                                            // onChange={(e) => setFactory(e.target.value)}
                                            // onChange={(e) => setFactory(e.target.value)}
                                            >
                                                <option value="0">{t("selectBuyer")}</option>
                                                {buyers.map((buy) => (
                                                    <option key={buy.id} value={buy.id}>{buy.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.buyer && (
                                            <span className="error-msg">{errors.buyer}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                type="select"
                                                placeholder={t("selectStatus")}
                                                // className={`${errors.pcu && "select-error-valid"
                                                //     }  form-control digits`}
                                                name="pcuid"
                                                defaultValue=""
                                                // onChange={handleChange}
                                                onChange={(e) => {setPcu(e.target.value);let pcuId = e.target.value;filterStyle("PCU",pcuId);}}
                                            >
                                                <option value="" disabled>
                                                    {t("selectPcu")}
                                                </option>
                                                {Pcus.map((pcuv) => (
                                                    <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.pcu && (
                                            <span className="error-msg">{errors.pcu}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}

                            <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                    <Label>{t("orderNo")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            type="select"
                                            placeholder={t("selectStatus")}
                                            className={`${errors.orderNo && "select-error-valid"
                                                }  form-control digits`}
                                            name="orderNo"
                                            id="orderNo"
                                            defaultValue=""
                                            // onChange={handleChange}
                                            onChange={(e) => setOrderNo(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                {t("orderNoSelect")}
                                            </option>
                                            {orders.map((order,i) => (
                                                <option key={i} value={order.order_no}>{order.order_no}</option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                    {errors.orderNo && (
                                        <span className="error-msg">{t(errors.orderNo)}</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" onClick={() => getDetails()}>{t("search")}</Button>
                                <Link to="/pendingtasks" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {toOpen === 1 ?  taskDetails.length>0?
                <>
                    <Card className="m-b-10 shadow"  style={{ border:"none" }}>
                        <CardHeader className="p-1 f-w-500" style={{background:"#c2ede8f5",fontSize:"14px"}}>
                            <Row>
                                    <Col lg="2" md="2" sm="2" xs="12" className="centerAlign">
                                        <p>{t("orderNo")}</p>
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12" className="centerAlign">
                                        <p>{t("task")}</p>
                                        {/* <UL attrUL={{ className: "flex-row simple-list" }}>
                                            <LI> <span> Styles </span> <P> {details.styleCount} </P> </LI>
                                        </UL> */}
                                    </Col>
                                    <Col lg="4" md="4" sm="4" xs="12" className="centerAlign">
                                        <p>{t("delayedTask")}</p>
                                        {/* <UL attrUL={{ className: "flex-row simple-list" }}>
                                            <LI> <span> Pending&nbsp;Tasks </span> <P> {details.pendingTask} </P> </LI>
                                        </UL> */}
                                    </Col>
                                    <Col lg="2" md="2" sm="2" xs="12" className="centerAlign">
                                        <p>{t("action")}</p>
                                    </Col>
                                </Row>
                        </CardHeader>
                        <CardBody className=" p-1" >                
                            <Row>
                                <Col lg="2" md="2" sm="2" xs="12" className="centerAlign">
                                    <p className="m-t-15">{details.orderNo}</p>
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="12" className="centerAlign">
                                    <Row className="p-1 f-12" >
                                        <Col lg="2" md="2" sm="2" xs="12">
                                            <span className="clr-complete"></span> {t("total")}
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span  className="clr-delaycomp"><img src={DelayCompletionIcons}  width="12px"/></span> {t("delayComplete")}
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="12">
                                            <span  className="clr-delay"><img src={DelayIcons} width="12px"/></span> {t("delay")} 
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="12">
                                            <span className="clr-nstart"><img src={YettostartIcons}  width="12px"/></span> {t("yetToStart")}
                                        </Col>
                                    </Row>
                                    <Row className="p-1">
                                        <Col lg="2" md="2" sm="2" xs="12">
                                            <span className="f-w-500">{details.totalTasks}</span>
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="f-w-500">{details.totalDelayedCompletion}</span>
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="12">
                                            <span className="f-w-500">{details.totalDelays}</span> 
                                        </Col>
                                        <Col lg="3" md="3" sm="3" xs="12">
                                            <span className="f-w-500">{details.totalNotYetStarted}</span>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="12" className="centerAlign">
                                    <Row className="p-1 f-12">
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="clr-delay"><img src={DelayIcons}  width="12px"/></span> {t("ltFiveDays")}
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="clr-delay"><img src={DelayIcons}  width="12px"/></span> {t("fiveToTen")}
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span  className="clr-delay"><img src={DelayIcons}  width="12px"/ ></span> {t("gtTenDays")} 
                                        </Col>
                                    </Row>
                                    <Row className="p-1">
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="f-w-500">{details.totalLessThanFiveDays}</span>
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="f-w-500">{details.totalFiveToTenDays}</span>
                                        </Col>
                                        <Col lg="4" md="4" sm="4" xs="12">
                                            <span className="f-w-500">{details.totalGtTenDays}</span> 
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="2" md="2" sm="2" xs="12" className="centerAlign">
                                        <Row>
                                            <Col lg={6} md={6} sm={6} xs={12} className="p-1">
                                                <div className="cursor-pointer p-1 p-r-0 m-t-5 f-right" onClick={() => toggleFilterCanvas()}>
                                                    <img title={t("filter")} src={FilterIcon} />
                                                </div>
                                            </Col>
                                            <Col lg={6} md={6} sm={6} xs={12} className="p-1">
                                                {(getStaff === "Staff"&&getStaffPermission.includes('Download Pending Task Report'))||getStaff == null? 
                                                    <div className="cursor-pointer p-1 p-l-0 m-t-5 f-left" onClick={()=> toDownloadAsPdf(details.orderNo)}>
                                                        <img title={t("download")} src={DownloadIcon} />
                                                    </div>
                                                    : ""}
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <PendingTaskAccordion TaskDetails={taskDetails} />
                    <FilterOffCanvas modal={filterOffCanvas} toggle={toggleFilterCanvas} PICs={PIC} orderNo={details.orderNo}
                    taskDetails={setTaskDetails} filterDays={setFilterDay} filterOperator={setFilterOperator} filterPIC={setFilterPIC}/>
                </>
                : <>
                    <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                        <img src={noData} style={{ width:"200px"}} />
                        <p className='f-18 m-t-10' >-- {t("noPendingTasksFound")} --</p>
                    </div>
                </> : ""}
            </Container>
        </Fragment>
    );
};

export default PendingTasks;
/* Code By : Rithanesh */