import { Container, Label, Card, CardBody, Col, Row, Input,
    InputGroup, Button, CardHeader, FormGroup, Table,
    Dropdown, DropdownItem, DropdownMenu } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, Btn } from "../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getWorkspaceType, getLoginUserId,
    getLoginStaffId, getStaff, getStaffPermission } from "../../Constant/LoginConstant";
import { Accordion } from "react-bootstrap";
import moreDetailsIconSvg from '../../assets/images/dms/icons/MoreDetailsIconSvg.svg'
import IconOpen from '../../assets/images/dms/taskicons/OpenStatus.svg';
import IconComplete from '../../assets/images/dms/icon-complete.svg';
import IconCancel from '../../assets/images/dms/icon-cancel.svg';
import IconDelete from '../../assets/images/dms/Icon-delete.svg';
import IconOrderCut from '../../assets/images/dms/order-cutting.png';
import IconOrderSew from '../../assets/images/dms/order-sewing.png';
import IconOrderPack from '../../assets/images/dms/order-pack.png';
import deliveryDateCompleted from '../../assets/images/dms/icons/deliveryDateCompleted.svg';
import StatusChart from './StatusChart';
import { encode, DownloadFile, apiencrypt, apidecrypt, decode, PHPtoJSFormatConversion } from "../../helper";
import OrderActionCanvas from './OrderActionCanvas'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import PartialDeliveryModal from './PartialDeliveryModal';
import Swal from 'sweetalert2';
import CompletedIcons from '../../assets/images/dms/taskicons/CompletedSmall.svg';
import DelayCompletionIcons from '../../assets/images/dms/taskicons/DelayedCompletionSmall.svg';
import InprogressIcons from '../../assets/images/dms/taskicons/InProgressSmall.svg';
import YettostartIcons from '../../assets/images/dms/taskicons/YettoStartSmall.svg';
import DelayIcons from '../../assets/images/dms/taskicons/DelaySmall.svg';
import noData from "../../assets/images/dms/nodata.png";
import Moment from 'moment';

const OrderStatus = () => {
    const { t, i18n } = useTranslation();
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [pcu, setPcu] = useState("");
    const [styleNo, setStyleNo] = useState("");
    const [styleNoList, setStyleNoList] = useState([]);
    const [styles, setStyles] = useState([]);
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
  const [ createDate, setCreateDate ] = useState("");
  
    const [factories, setFactories] = useState([]);
    const dataToSendAtStarting = {
        company_id: company_id,
        workspace_id: workspace_id,
        "page_type": "orderstatus",
        "staff_id": getLoginStaffId,
    };

    const [isShown, setIsShown] = useState('show');
    const [isOpen, setIsOpen] = useState(0);
    const [isOpen2, setIsOpen2] = useState(0);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    const toggle2 = (id) => (isOpen2 === id ? setIsOpen2(null) : setIsOpen2(id));
    const data = [2, 3, 4, 5];

    const [orderDetails, setOrderDetails] = useState([]);
    const [filteredOrderDetails, setFilteredOrderDetails] = useState([]);
    const [show, setShow] = useState(false);
    const toggleCanvas = () => setShow(!show);
    const [actionType, setActionType] = useState('');
    const [actionOrderNo, setActionOrderNo] = useState('');
    const [actionOrderId, setActionOrderId] = useState('');
    const [selectedLang, setSelectedLang] = useState(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');
    const [hoildayDetails, setHoildayDetails] = useState("");
    const [weekOffDetails, setWeekOffDetails] = useState("");

    const [partialDeliveryModal, setPartialDeliveryModal] = useState(false);
    const togglePartialDelivery = () => setPartialDeliveryModal(!partialDeliveryModal);
    const [basicInfo, setBasicInfo] = React.useState([]);
    const [toleranceVolume, setToleranceVolume] = React.useState("");
    const [tolerancePercent, setTolerancePercent] = React.useState("");
    const [stepLevel, setStepLevel] = useState("");
    
    const [comments, setComments] = useState("");
    const [deliveryDate, setDeliveryDate] = useState("");
    const [totalDeliveredQty, setTotalDeliveredQty] = useState("");
    const [totalPackQty, setTotalPackQty] = useState("");
    const [noOfDeliveries, setNoOfDeliveries] = useState("");
    const [noOfDelivered, setNoOfDelivered] = useState("");
    const [enabledDeliveryId, setEnabledDeliveryId] = useState("");
    const [deliveryStatus, setDeliveryStatus] = useState(false);
    const [partialDeliveryStatus, setPartialDeliveryStatus] = useState(false);
    const [lastDeliveryDate, setLastDeliveryDate] = useState("");

    const [partialDeliveryDetails, setPartialDeliveryDetails] = useState([]);
    const [deliveryDateDropdown, setDeliveryDateDropdown] = useState([]);
    const [partialDeliveryErrors, setPartialDeliveryErrors] = useState({});
    const [orderQty, setOrderQty] = useState("");
      const [delDate, setDelDate] = useState([]);
//   var currentDate = Moment().format('YYYY-MM-DD');
const [ deliveryDates, setDeliveryDates ] = useState("");
const [ deliveryDateStatus, setDeliveryDateStatus ] = useState("");
     

    const filterData = (e) => {
        let retval = orderValidation();
        if (Object.keys(retval).length == 0) {
            setFilteredOrderDetails('')
            axios
                .post(ServerUrl + "/get-orders",
                    apiencrypt({
                        "company_id": getLoginCompanyId,
                        "workspace_id": getWorkspaceId,
                        "user_id": getLoginUserId,
                        "staff_id": getLoginStaffId,
                        "factory_id": factory,
                        "pcu_id": pcu,
                        "buyer_id": buyer,
                        "style_no": styleNo,
                        "page_type": "orderstatus"
                    }))
                .then((response) => {
                    response.data = apidecrypt(response.data)
                    if (response.data.status_code === 200) {
                        setFilteredOrderDetails(response.data.data);
                        setHoildayDetails(response.data.holidayDetails);
                        setWeekOffDetails(response.data.weekOffs[0].days);
                        setIsShown('hide');
                    }
                    if (response.data.status_code === 400) {
                        setIsShown('hide');
                    }
                })
            partialDeliveryData(styleNo)
        }
    };

    const filterStyle = (type, id) => {
        if (type === "Factory") {
            axios.post(ServerUrl + "/get-styles", apiencrypt({
                company_id: company_id,
                workspace_id: workspace_id,
                factory_id: id,
                staff_id: getLoginStaffId,
                "page_type": "orderstatus"
            })).then((response) => {
                response.data = apidecrypt(response.data)
                setStyles(response.data.data);
                setPcu('');
            });
        }
        if (type === "Buyer") {
            axios.post(ServerUrl + "/get-styles", apiencrypt({
                company_id: company_id,
                workspace_id: workspace_id,
                buyer_id: id,
                staff_id: getLoginStaffId,
                "page_type": "orderstatus"
            })).then((response) => {
                response.data = apidecrypt(response.data)
                setStyles(response.data.data);
            });
        }
        if (type === "PCU") {
            axios.post(ServerUrl + "/get-styles", apiencrypt({
                company_id: company_id,
                workspace_id: workspace_id,
                pcu_id: id,
                staff_id: getLoginStaffId,
                "page_type": "orderstatus"
            })).then((response) => {
                response.data = apidecrypt(response.data)
                setStyles(response.data.data);
            });
        }
    };

    /*********************** To Download The PDF ********************/
    const toDownloadAsPdf = (orderNo) => {
        let dataToSendForOrder = {
            company_id: company_id,
            workspace_id: workspace_id,
            orderNo: orderNo,
            user_id: getLoginUserId,
            staff_id: getLoginStaffId,
        };
        axios.post(ServerUrl + "/download-orderstatus-pdf", apiencrypt(dataToSendForOrder), { responseType: 'blob' })
            .then((response) => {
                let fileName = orderNo + "-Orderstatus.pdf";
                DownloadFile(response.data, fileName);
            })
    };

    const orderValidation = (data) => {
        let errors = {};
        if (!styleNo) {
            errors.styleNo = ('pleaseSelectStyleNo');
        }
        setValidErrors(errors);
        return errors;
    };

    const styleData = (e) => {
        axios
            .post(ServerUrl + "/get-styles",
                apiencrypt({
                    "company_id": getLoginCompanyId,
                    "workspace_id": getWorkspaceId,
                    "factory_id": factory,
                    "pcu_id": pcu,
                    "buyer_id": buyer,
                    page_type: "orderstatus",
                }))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setStyleNoList(response.data.data);
            })
    };

    const clearData = () => {
        setFactory(() => "defaultValue")
        setBuyer(() => "defaultValue")
        setPcu(() => "defaultValue")
        setStyleNo(() => "defaultValue")
        axios
            .post(ServerUrl + "/get-orders",
                apiencrypt({
                    "company_id": getLoginCompanyId,
                    "workspace_id": getWorkspaceId,
                    "page_type": "orderstatus" }))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code == '200') {
                    setOrderDetails(response.data.data);
                    setIsShown('show')
                }
            })
        setFactory(() => "")
        setBuyer(() => "")
        setPcu(() => "")
        setStyleNo(() => "")
    };

    useEffect(() => {
        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-styles", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setStyles(response.data.data);
            });

        // window.addEventListener("resize", getSizes, false);
        // function getSizes() {
        //     var data = (( window.outerWidth - 10 ) / window.innerWidth) * 100;
        //     setZoom(data);
        //     localStorage.setItem("zoomLevel", data);
        // }
    }, []);

    const toOpenOrderActionModal = (theType, orderNo, orderId) => {
        setActionType(theType);
        setActionOrderNo(orderNo);
        setActionOrderId(orderId);
        toggleCanvas();
    }

    const partialDeliveryData = (orderId) => {
        var basicInfoParams = {};
        basicInfoParams['company_id'] = getLoginCompanyId;
        basicInfoParams['workspace_id'] = getWorkspaceId;
        basicInfoParams['user_id'] = getLoginUserId;
        basicInfoParams['staff_id'] = getLoginStaffId;
        basicInfoParams['order_id'] = orderId;
        axios
            .post(ServerUrl + "/get-basic-info", apiencrypt(basicInfoParams))
            .then((response) => {
                response.data = apidecrypt(response.data);
                setStepLevel(response.data.data[0].step_level);
                setBasicInfo(response.data.data[0]);
                setToleranceVolume(response.data.data[0].tolerance_volume);
                setTolerancePercent(response.data.data[0].tolerance_perc);
                setOrderQty(response.data.data[0].quantity);
                setDelDate(response.data.delivery_dates);
                var create_dates = response.data.data[0].created_date;
                setCreateDate(create_dates)
                setDeliveryDates(response.data.delivery_date);
                setDeliveryDateStatus(response.data.delivery_date_exceed);
                // if(response.data.data[0].status != 1 )
                // {
                // window.location.href = "/dashboard" 
                // }
                // else {
                // ""
                // }
            })

        var deliveryDetailsParams = {};
        deliveryDetailsParams['company_id'] = getLoginCompanyId;
        deliveryDetailsParams['workspace_id'] = getWorkspaceId;
        deliveryDetailsParams['user_id'] = getLoginUserId;
        deliveryDetailsParams['order_id'] = orderId;

        axios
            .post(ServerUrl + "/get-delivery-details", apiencrypt(deliveryDetailsParams))
            .then((response) => {
                response.data = apidecrypt(response.data);
                setPartialDeliveryDetails(response.data.data);
                setNoOfDeliveries(response.data.data.delivery_count);
                setNoOfDelivered(response.data.data.delivered_count);
                setTotalDeliveredQty(response.data.data.total_delivered_quantity);
                setTotalPackQty(response.data.data.total_pack_quantity);

                if (response.data.data.delivery_count == 0) {
                    setDeliveryStatus(true);
                    setPartialDeliveryStatus(true);
                } else if (response.data.data.delivery_count == 1) {
                    setDeliveryStatus(false);
                    setPartialDeliveryStatus(true);
                } else {
                    setDeliveryStatus(true);
                    setPartialDeliveryStatus(false);
                }

                var enabledDeliveryId = "";
                var notDelivereDates = response.data.data.deliveryDates.filter((filterData) => filterData.is_delivered == 0);
                notDelivereDates.map((mapData, index) => {
                    mapData.is_delivered == 0
                    if (index == 0) {
                        enabledDeliveryId = mapData.id,
                        setEnabledDeliveryId(mapData.id)
                    }
                })

                response.data.data.deliveryDates.map((mapData) => {
                    setLastDeliveryDate(mapData.delivery_date)
                });

                var deliveryDateDropdown = [];
                response.data.data.deliveryDates.map((mapData) => {
                    var deliveryDateOptions = {};
                    deliveryDateOptions['text'] =
                        mapData.is_delivered == 1
                            ?
                            <span style={{ fontWeight: 500, color: '#000' }}>
                                {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                            </span>
                            :
                            mapData.id == enabledDeliveryId
                                ?
                                deliveryDateOptions['value'] =
                                <span style={{ color: '#000' }} className="enabledDeliveryDate">
                                    {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                                </span>
                                :
                                <span>
                                    {PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))}
                                </span>
                    deliveryDateOptions['value'] =
                        mapData.is_delivered == 1
                            ?
                            PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                            :
                            mapData.id == enabledDeliveryId
                                ?
                                deliveryDateOptions['value'] =
                                PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                                :
                                PHPtoJSFormatConversion.format(new Date(mapData.delivery_date))
                    deliveryDateOptions['exact_value'] =
                        mapData.is_delivered == 1
                            ?
                            mapData.delivery_date
                            :
                            mapData.id == enabledDeliveryId
                                ?
                                deliveryDateOptions['exact_value'] =
                                mapData.delivery_date
                                :
                                mapData.delivery_date

                    deliveryDateOptions['icon'] =
                        mapData.is_delivered == 1 ?
                            <>
                                <span style={{ marginLeft: 15, float: 'right', marginRight: 25 }}>
                                    <img src={deliveryDateCompleted} width="15px" height="15px" />
                                </span>
                            </>  :   ""
                    deliveryDateOptions['id'] = mapData.id;
                    deliveryDateOptions['is_delivered'] = mapData.is_delivered;
                    deliveryDateOptions['delivery_comments'] = mapData.delivery_comments;
                    deliveryDateOptions['total_delivered_quantity'] = mapData.total_delivered_quantity;
                    deliveryDateOptions['disabled'] =
                        mapData.is_delivered == 1 ? false :
                        mapData.id == enabledDeliveryId ? false :  true
                    deliveryDateDropdown.push(deliveryDateOptions);
                });
                setDeliveryDateDropdown(deliveryDateDropdown);
            });

    };

    const clearPartialDeliveryData = () => {
        setPartialDeliveryErrors(() => ""),
        setDeliveryDate(() => "",
        setComments(() => ""))
    };

    return (
        <Fragment>
            <Row className="pgbgcolor">
                <Breadcrumbs mainTitle='Order Status' parent="Order Status"
                    title="Order Status" subTitle="Delay and Production of ongoing Orders" />
            </Row>

            <Container fluid={true} className="general-widget topaln">
                {/***************************** DROP DOWN [Filter] ***********************************/}
                <Card>
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t('factory')}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input id="factory"
                                                className={`${errors.factory && "select-error-valid"}  form-control`}
                                                name="dropDown"   type="select"
                                                placeholder={t('factory')} aria-label="factories"
                                                defaultValue={factory}
                                                //</InputGroup> onChange={(e) =>(setFactory(e.target.value),styleData())}
                                                onChange={(e) => { setFactory(e.target.value); 
                                                    let facId = e.target.value; filterStyle("Factory", facId); }}>
                                                <option value="defaultValue" selected >{t('selectFactory')}</option>
                                                {factories.map((factory) =>
                                                 ( <option key={factory.id} value={factory.id}> {factory.name} </option> ))
                                                }
                                            </Input>
                                        </InputGroup>
                                        {errors.factory && (
                                            <span className="error-msg">{errors.factory}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : ( ""
                            )}
                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                className={`${errors.buyer && "select-error-valid"}  form-control`}
                                                name="dropDown" type="select"
                                                placeholder={t("buyer")} aria-label="buyer"
                                                defaultValue={buyer}
                                                //  onChange={(e) => (setBuyer(e.target.value),styleData())}
                                                onChange={(e) => { setBuyer(e.target.value); 
                                                    let buyerId = e.target.value; filterStyle("Buyer", buyerId); }}>
                                                <option value="defaultValue" selected>{t("selectBuyer")}</option>
                                                {buyers.map((buy) => (
                                                    <option key={buy.id} value={buy.id}>{buy.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.buyer && (
                                            <span className="error-msg">{errors.buyer}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : ( "" )}
                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t('productionControlUnit')}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input id="pcu-id"
                                                type="select"  name="dropDown"
                                                placeholder={t("selectStatus")}
                                                className={`${errors.pcu && "select-error-valid"} form-control digits`}
                                                defaultValue={pcu}
                                                // onChange={(e) => (setPcu(e.target.value),styleData())}
                                                onChange={(e) => { setPcu(e.target.value); 
                                                    let pcuId = e.target.value; filterStyle("PCU", pcuId); }} >
                                                {/* onChange=  {(e) => ( a = e.target.value,styleData(e.target.value), 
                                                    filterData(e.target.value)) }> */}
                                                <option value="defaultValue" selected>
                                                    {t('selectPCU')}
                                                </option>
                                                {Pcus.map((pcuv) => (
                                                    <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.pcu && ( <span className="error-msg">{errors.pcu}</span> )}
                                    </FormGroup>
                                </Col>
                            ) : (  "" )}

                            <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                    <Label>{t('styleNo')}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input type="select"
                                            placeholder={t("selectStatus")}
                                            className={`${errors.styleNo && "select-error-valid"}  form-control digits`}
                                            name="dropDown"  //value={styleNo}
                                            defaultValue={styleNo}
                                            onChange={(e) => { setStyleNo(e.target.value) }}>
                                            <option value="" selected>
                                                {t('selectStyleno')}
                                            </option>
                                            {/* {styleNoList.map((styleList) => (<option value={styleList.id}>{styleList.style_no} 
                                                ({styleList.order_no})</option> ))} */}
                                            {styles.map((style) => {
                                                let styleDetails;
                                                if (style.status === "1") {
                                                    styleDetails = style.style_no + " (" + style.order_no + ") (" + t("active") + ")";
                                                }
                                                if (style.status === "3") {
                                                    styleDetails = style.style_no + " (" + style.order_no + ") (" + t("deleted") + ")";
                                                }
                                                if (style.status === "10") {
                                                    styleDetails = style.style_no + " (" + style.order_no + ") (" + t("cancelled") + ")";
                                                }
                                                if (style.status === "12") {
                                                    styleDetails = style.style_no + " (" + style.order_no + ") (" + t("completed") + ")";
                                                }
                                                return (
                                                    <option key={style.id} value={style.id} className={`${style.status === "3" ? 'font-danger' :
                                                        style.status === "10" ? "yetToCompeteText" : style.status === "12" ? "font-primary" : ""}`}>
                                                        {styleDetails}</option>
                                                )
                                            })}
                                        </Input>
                                    </InputGroup>
                                    {errors.styleNo && (<span className="error-msg">{t(errors.styleNo)}</span> )}
                                </FormGroup>
                            </Col>
                            <Col lg="3" md="6" sm="6" xs="12" className="m-t-30 text-start">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" onClick={() => filterData()}> {t('search')} </Button>
                                <Link to="/orderstatus" className="f-left"><Button className="btn secondaryBtn "> {t('clear')} </Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Accordion defaultActiveKey="0">
                    <Card className="" >
                    {(filteredOrderDetails).length > 0 ?
                    <>
                        <Row>
                        <Col className="m-t-15">
                             <span className="m-l-20 " >{t("orderStartDate")} :  {PHPtoJSFormatConversion.format(new Date(createDate))}</span>
                             </Col>
                             <Col>
                             <span className="m-r-5 m-t-10" style={{float:"right"}}> {t("delDate")} : 
                             {deliveryDates ? 
                                  deliveryDateStatus == 1 ? 
                                  <span className="btn deliveryBtn text-center" 
                                        style={{  height: '35px', width: '145px', borderRadius: '18px', borderColor: '#ccc', 
                                        marginLeft: '10px', marginTop: '5px',marginBottom:"10px", fontSize: '12px', 
                                        color: '#EE335E', fontWeight: '600', cursor:"unset"  }}> 
                                    {PHPtoJSFormatConversion.format(new Date(deliveryDates))} 
                                  </span>
                                 
                              : 
                              <span className="btn deliveryBtn text-center" 
                                    style={{  height: '35px', width: '145px', borderRadius: '18px', borderColor: '#ccc', 
                                    marginLeft: '10px', marginTop: '5px',marginBottom:"10px", fontSize: '12px', 
                                    color: '#878787', fontWeight: '600', cursor:"unset"  }}> 
                                {PHPtoJSFormatConversion.format(new Date(deliveryDates))} 
                              </span>
                              : 
                               <span className="p-l-10 m-r-10"> - </span>
                              }
                            </span> 
                            </Col>
                           
                            
                        </Row>
                         {filteredOrderDetails.map((accordian,i) => {
                            let inProgressCount = (accordian.totalTaskCount - (accordian.subtask_details[0].subtask_delay_count 
                                + accordian.subtask_details[0].subtask_complete_count + accordian.subtask_details[0].subtask_notstart_count 
                                + accordian.subtask_details[0].subtask_delay_complete));

                            let inProgressCountv = inProgressCount > 0 ? inProgressCount : 0;
                            return (

                                <Row key={i} >
                                    <Col lg="10" md="10" sm="8" xs="8" className="p-r-0" >
                                    <div className="table-responsive " >
                                        <Table className="table tableRowCol ordsts" >
                                            <tr style={{ background: "#B2DFDB", padding: "10px" }}>
                                                <th className="text-center heaertit"> {t('orderNo/styleNo')} </th>
                                                <th className="text-center heaertit"> {t('taskStatus')} </th>
                                                <th className="text-center heaertit"> {t('productionStatus')} </th>
                                                <th className="text-center heaertit"> {t('status')} </th>
                                            </tr>
                                            <tr style={{ backgroundColor: "#F5F6F8" }}>
                                                <td width={170} >
                                                    <span>{accordian.orderNo}</span><br/><span>{accordian.styleNo}</span>
                                                </td>
                                                <td className="text-center" style={{ width: "780px" }}>
                                                    <Table className="table-bordernone" style={{ width: "780px", padding: "0.10em !important", verticalAlign: "middle !important" }}>
                                                        <tr  style={{ backgroundColor: "#F5F6F8" }}>
                                                            <td width={100} className="text-center p-0">{t('total')}</td>
                                                            <td width={150} className="text-center">
                                                                <span> <img style={{width:"15px",height:"10px"}} src={CompletedIcons}/></span>
                                                                 {t('completed')}</td>
                                                            <td width={150} className="text-center p-0">
                                                                <span> <img style={{width:"15px",height:"10px"}} src={DelayIcons}/></span>
                                                                {t('delay')}</td>
                                                            <td width={200} className="text-center p-0">
                                                                <span> <img style={{width:"15px",height:"10px"}} src={DelayCompletionIcons}/>
                                                                </span>{t('delCompletion')}</td>
                                                            <td width={150} className="text-center p-0">
                                                                <span> <img style={{width:"15px",height:"10px"}} src={InprogressIcons}/>
                                                                </span>{t('inProgress')}</td>
                                                            <td width={150} className="text-center p-0">
                                                                <span> <img style={{width:"15px",height:"10px"}} src={YettostartIcons}/>
                                                                </span>{t('yetToStart')}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.totalTaskCount} </span></td>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.subtask_details[0].subtask_complete_count} </span></td>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.subtask_details[0].subtask_delay_count} </span></td>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.subtask_details[0].subtask_delay_complete} </span></td>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.subtask_details[0].subtask_inprogress} </span></td>
                                                            <td className="text-center p-0"><span className="f-w-600">{accordian.subtask_details[0].subtask_notstart_count} </span></td></tr>
                                                    </Table>
                                                </td>
                                                <td width={120} className="text-center">
                                                    <Table className="table-bordernone" width={120} style={{ width: "120px !important" }}>
                                                        <tr style={{ backgroundColor: "#F5F6F8" }}>
                                                            <td className="p-0 px-3"><img src={IconOrderCut} /></td>
                                                            <td className="p-0 px-3"><img src={IconOrderSew} /></td>
                                                            <td className="p-0 px-3"><img src={IconOrderPack} /></td>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-0"><span className="f-w-600">
                                                                {accordian.cut_percentage.percentage ? accordian.cut_percentage.percentage : 0}%</span></td>
                                                            <td className="p-0"><span className="f-w-600">
                                                                {accordian.sew_percentage.percentage ? accordian.sew_percentage.percentage : 0}%</span></td>
                                                            <td className="p-0"><span className="f-w-600">
                                                                {accordian.pack_percentage.percentage ? accordian.pack_percentage.percentage : 0}%</span>
                                                            </td>
                                                        </tr>
                                                    </Table>
                                                </td>
                                                <td className="text-center p-0" >  
                                                    {accordian.status == 3  ? <span><img src={IconDelete}/> {t('deleted')}</span> : ""}
                                                    {accordian.status == 10 ? <span><img src={IconCancel} /> {t('cancel')}</span> : ""}
                                                    {accordian.status == 12 ? <span  ><img src={IconComplete} style={{ width:"20px",height:"20px" }} /> {t('completed')}</span> : ""}
                                                    {accordian.status == 1  ? <span ><img src={IconOpen} /> {t('open')}</span> : ""} 
                                                </td>
                                            </tr>
                                            </Table>
                                    </div>
                                    </Col>
                                    <Col lg="2" md="2" sm="4" xs="4" className="p-l-0"  style={{ backgroundColor: "#F5F6F8" }} >
                                        {/* Action Start  */}
                                        <Table className="table tableRowCol ordsts "><tr className="tabalgn"><td></td></tr></Table>
                                        <Table className="table tableRowCol ordsts tabtopaln" >
                                            <tr style={{ background: "#B2DFDB", padding: "10px" }}>
                                                <th className="heaertit text-center action_title">{t('action')}</th>   
                                            </tr>
                                            <tr>
                                                 <td className="dropdown-basic text-center p-0">
                                                    {(getStaff === "Staff" && getStaffPermission.includes("Edit Order")) || getStaff == null 
                                                        ?
                                                            <Dropdown className="dropdown dropbtnvl" >
                                                                <Btn className="dropbtn btn-outline-light">
                                                                    <img src={moreDetailsIconSvg} className="p-t-15"/> 
                                                                    <span><i className="icofont icofont-arrow-down"></i></span>
                                                                </Btn>
                                                                <DropdownMenu className="dropdown-content mminutp">
                                                                    <DropdownItem href={`${process.env.PUBLIC_URL}/orderview?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                        <i className="icofont icofont-eye p-r-5"></i> {t('view')}
                                                                    </DropdownItem>
                                                                    {/* Update */}
                                                                    {
                                                                            <>
                                                                            {
                                                                                accordian.step_level != 6 && accordian.status == 1 
                                                                            ?
                                                                                accordian.step_level == 1 ?
                                                                                    <DropdownItem href={`${process.env.PUBLIC_URL}/ordersku?id=` + encode(accordian.orderId) + `&quantity=` + encode(accordian.quantity)} className='dropbtn actionsty' >
                                                                                        <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                        {t('update')} 
                                                                                    </DropdownItem>
                                                                                    : accordian.step_level == 2 ?
                                                                                        <DropdownItem href={`${process.env.PUBLIC_URL}/ordercontacts?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                                            <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                            {t('update')}  
                                                                                        </DropdownItem>
                                                                                        : accordian.step_level == 3 ?
                                                                                            <DropdownItem href={`${process.env.PUBLIC_URL}/ordertask?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                                                <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                                {t('update')}  
                                                                                            </DropdownItem>
                                                                                            : accordian.step_level == 4 ?
                                                                                                getWorkspaceType == "Factory" ?
                                                                                                <DropdownItem href={`${process.env.PUBLIC_URL}/ordermaterialsLabels?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                                                    <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                                    {t('update')}  
                                                                                                </DropdownItem>
                                                                                                :
                                                                                                <DropdownItem href={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                                                    <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                                    {t('update')}  
                                                                                                </DropdownItem>
                                                                                                    : accordian.step_level == 5 ?
                                                                                                        <DropdownItem href={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(accordian.orderId)} className='dropbtn actionsty'>
                                                                                                            <i className="icofont icofont-ui-edit p-r-5"></i>  
                                                                                                            {t('update')}  
                                                                                                        </DropdownItem>
                                                                                                    : '' 
                                                                                :
                                                                                    <DropdownItem disabled className="actionsty"><i className="icofont icofont-ui-edit"></i>  {t('update')}  </DropdownItem>
                                                                                }
                                                                         
                                                                   
                                                                        </>
                                                                    }

                                                                    {/* Cancel */}
                                                                    {(getStaff === "Staff" && getStaffPermission.includes("Cancel Order")) || getStaff == null
                                                                        ?
                                                                            (accordian.step_level == 6 && accordian.status == 1)
                                                                                ?
                                                                                <DropdownItem className="actionsty" onClick={() => { toOpenOrderActionModal('Cancel', accordian.orderNo, accordian.orderId) }}>
                                                                                    <i className="fa fa-times-circle p-r-5"></i>  {t('cancel')} </DropdownItem>
                                                                                : <DropdownItem className="actionsty" disabled>
                                                                                    <i className="fa fa-times-circle p-r-5"></i>  {t('cancel')} </DropdownItem>
                                                                        : 
                                                                            ''
                                                                    }

                                                                    {/* Delete */}
                                                                    {(getStaff === "Staff" && getStaffPermission.includes("Delete Order")) || getStaff == null 
                                                                        ?
                                                                                (accordian.step_level != 6 && accordian.status == 1) ?
                                                                                    <DropdownItem className="actionsty" onClick={() => { toOpenOrderActionModal('Delete', accordian.orderNo, accordian.orderId) }}>
                                                                                        <i className="icofont icofont-ui-delete p-r-5"></i>  {t('delete')} </DropdownItem>
                                                                                    : 
                                                                                    <DropdownItem className="actionsty" disabled>
                                                                                        <i className="icofont icofont-ui-delete"></i>  {t('delete')} </DropdownItem> 
                                                                        : 
                                                                            ''
                                                                        }

                                                                    {/* Complete */}
                                                                    {(getStaff === "Staff" && getStaffPermission.includes("Complete Order")) || getStaff == null 
                                                                        ?
                                                                                (accordian.step_level == 6 && accordian.status == 1 && accordian.cut_percentage.percentage >= 100 && accordian.sew_percentage.percentage >= 100 
                                                                                    && accordian.pack_percentage.percentage >= 100 && (accordian.totalTaskCount == accordian.totalFinishedTaskCount)) 
                                                                                    ?
                                                                                        <DropdownItem className="actionsty" onClick={() => { toOpenOrderActionModal('Complete', accordian.orderNo, accordian.orderId) }}>
                                                                                            <i className="fa fa-check-circle p-r-5"></i> 
                                                                                            {t('complete')} 
                                                                                        </DropdownItem>
                                                                                    : 
                                                                                    <DropdownItem disabled className="actionsty"><i className="fa fa-check-circle p-r-5"></i>  {t('complete')} </DropdownItem> 
                                                                        : 
                                                                            ''}
                                                                    {/* <DropdownItem className="actionsty" onClick={() => { toOpenOrderActionModal('Complete', accordian.orderNo, accordian.orderId) }}>
                                                                                <i className="icofont icofont-check-circled"></i>  {t('complete')} 
                                                                    </DropdownItem> */}

                                                                    {/* Partially Delivered */}
                                                                    {
                                                                            accordian.step_level == 6 && partialDeliveryStatus == false 
                                                                            ?
                                                                                <DropdownItem className="actionsty"
                                                                                    disabled={partialDeliveryStatus}
                                                                                    onClick={() => {
                                                                                        totalPackQty > 0 ? setPartialDeliveryModal(!partialDeliveryModal) :
                                                                                            Swal.fire({
                                                                                                title: t("plsComptPackAddDevDate"),
                                                                                                icon: "warning",
                                                                                                button: t("okLabel"),
                                                                                                timer: 3000
                                                                                            })
                                                                                            .then((result) => {
                                                                                                if(result.isConfirmed) {
                                                                                                    window.location.href = "/datainput?id=" + encode(styleNo);
                                                                                                }
                                                                                            })
                                                                                        clearPartialDeliveryData()
                                                                                    }} >
                                                                                    <i className="icofont icofont-delivery-time p-r-5" style={{ fontSize: "19px" }}></i>
                                                                                    {t('partialDelivered')} 
                                                                                </DropdownItem> 
                                                                            : ''
                                                                    }

                                                                    {/* Delivery Details */}
                                                                    {
                                                                            accordian.step_level == 6 && deliveryStatus == false 
                                                                            ?
                                                                                <DropdownItem
                                                                                    disabled={deliveryStatus}
                                                                                    onClick={() => {
                                                                                        totalPackQty > 0 ? setPartialDeliveryModal(!partialDeliveryModal) :
                                                                                            Swal.fire({
                                                                                                title: t("plsComptPackAddDevDate"),
                                                                                                icon: "warning",
                                                                                                button: t("okLabel"),
                                                                                                timer: 3000
                                                                                            })
                                                                                            .then((result) => {
                                                                                                if(result.isConfirmed) {
                                                                                                    window.location.href = "/datainput?id=" + encode(styleNo);
                                                                                                }
                                                                                            })
                                                                                        clearPartialDeliveryData()
                                                                                    }}
                                                                                    className="actionsty">
                                                                                    <i className="icofont icofont-fast-delivery p-r-5" style={{ fontSize: "19px" }}></i>
                                                                                    {t('deliveryDetails')} 
                                                                                </DropdownItem> 
                                                                            : 
                                                                                ''
                                                                    }

                                                                    {/* Download */}
                                                                    {
                                                                        accordian.step_level == 6 
                                                                        ?
                                                                            <DropdownItem
                                                                                className="actionsty"
                                                                                onClick={() => toDownloadAsPdf(accordian.orderId)}>
                                                                                <i className="fa fa-download p-r-5"></i>
                                                                                {t('download')} </DropdownItem> 
                                                                            : ''
                                                                    }
                                                                </DropdownMenu>
                                                            </Dropdown> 
                                                        :
                                                            <div className="m-t-20">-</div>}
                                                </td>
                                            </tr>
                                                </Table>
                                        {/* Action End  */}
                                    </Col>
                                    </Row>)
                        })}</> : <div className="table-responsive">
                            {/* <Table className="table tableRowCol ordsts" >
                                <tr style={{ background: "#B2DFDB", padding: "10px" }}>
                                    <th className="text-left heaertit" >{t('orderNo/styleNo')}</th>
                                    <th className="text-center heaertit">{t('taskStatus')}</th>
                                    <th className="text-center heaertit">{t('productionStatus')}</th>
                                    <th className="text-center heaertit">{t('status')}</th>
                                    <th className="heaertit">{t('action')}</th>
                                </tr><tr>
                                    <td colSpan={7} style={{ textAlign: "center" }}>   </td>
                                </tr></Table> */}
                        </div>}
                    </Card>
                    {filteredOrderDetails.length > 0 && filteredOrderDetails.map((accordian, i, taskData1, taskData2, taskData3) =>
                    (
                        <Card key={i} style={{ overflowX: "scroll", borderTop:0 }}>
                            <>
                                {accordian.step_level != 6 && accordian.status == 1 ?
                                    <CardHeader><Row><span className='dangeralt'>{t("noteOrderNotCompleted")}</span></Row></CardHeader> : ""}
                                {accordian.subtask_details[0].subtaskDetailsList.length > 0 ?
                                    <CardHeader style={{ fontSize: "14px", padding: "0px" }}>
                                        {accordian.subtask_details.length > 0 && accordian.subtask_details.map((accordian2, ii) =>
                                        (
                                            <>
                                                {accordian2.subtaskDetailsList.length > 0 ?
                                                    <Row key={ii} className="g-12 m-t-0 p-1" style={{ backgroundColor: "#FFFFFF" }}>
                                                        <Col lg={12} md={12} sm={12} >
                                                            <StatusChart task={accordian2.subtaskDetailsList} startDate={accordian2.task_start_date} l
                                                            ang={selectedLang} hoildayDetails={hoildayDetails} weekOffDetails={weekOffDetails} />
                                                        </Col>
                                                    </Row> : ""}
                                            </>
                                        ))}
                                    </CardHeader> : ""}
                                {!accordian.subtask_details[0].subtaskDetailsList.length > 0 && accordian.step_level == 6 && accordian.status == 1 ?
                                  <CardHeader><Row><span className='dangeralt'>{t("notePleaseUpdateTask")}</span></Row></CardHeader> : ""}
                            </>
                        </Card>
                    ))}
                </Accordion>
            </Container>
            {actionType ?
                <OrderActionCanvas modal={show} toggle={toggleCanvas} actionType={actionType} orderno={actionOrderNo} orderid={actionOrderId}
                    getdata={setFilteredOrderDetails} styleNo={styleNo} /> : ''}

            <PartialDeliveryModal
                modal={partialDeliveryModal}
                toggle={togglePartialDelivery}
                toleranceVolume={toleranceVolume}
                tolerancePercent={tolerancePercent}
                stepLevel={stepLevel}
                basicInfo={basicInfo}

                styleNo={styleNo}
                comments={comments}
                setComments={setComments}
                deliveryDate={deliveryDate}
                setDeliveryDate={setDeliveryDate}
                partialDeliveryDetails={partialDeliveryDetails}
                setPartialDeliveryDetails={setPartialDeliveryDetails}

                partialDeliveryStatus={partialDeliveryStatus}
                setPartialDeliveryStatus={setPartialDeliveryStatus}
                deliveryStatus={deliveryStatus}
                setDeliveryStatus={setDeliveryStatus}

                partialDeliveryErrors={partialDeliveryErrors}
                setPartialDeliveryErrors={setPartialDeliveryErrors}

                enabledDeliveryId={enabledDeliveryId}
                setEnabledDeliveryId={setEnabledDeliveryId}
                lastDeliveryDate={lastDeliveryDate}
                setLastDeliveryDate={setLastDeliveryDate}

                totalDeliveredQty={totalDeliveredQty}
                noOfDeliveries={noOfDeliveries}
                noOfDelivered={noOfDelivered}
                setNoOfDeliveries={setNoOfDeliveries}
                setNoOfDelivered={setNoOfDelivered}
                setTotalDeliveredQty={setTotalDeliveredQty}
                setTotalPackQty={setTotalPackQty}

                deliveryDateDropdown={deliveryDateDropdown}
                setDeliveryDateDropdown={setDeliveryDateDropdown}
            />
        </Fragment>

    );
};

export default OrderStatus;

//**************************Code by : SaravanaKumar****************************//
