import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardHeader, Collapse, Input, Table } from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import { ServerUrl } from "../../../Constant";
import DatePicker from "react-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import TemplateModal from "../CreateOrders/AddTemplateModal"
import FormAddTaskSubTaskModal from "../CreateOrders/AddTaskSubTaskModal"
// import { EXITED } from "react-transition-group/transition";
import { getStaff, getStaffPermission } from "../../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../../helper";
import AddTask from "./AddOrderTask";
import { refineProps } from "@fullcalendar/react";
import Add_icon from '../../../assets/images/dms/icons/Main Task Add_icon.svg'
import subTask_icon from '../../../assets/images/dms/icons/Add Task_icon.svg'
import delete_icon from '../../../assets/images/dms/icons/delete_icon.svg'
import downAccordion_icon from '../../../assets/images/dms/icons/For Accordion_icon.svg'
import up_accordion_icon from '../../../assets/images/dms/icons/up_accordion_icon.svg'
import tasklist_icons from '../../../assets/images/dms/icons/tasklist_icons.svg'
import Subtask_delete from '../../../assets/images/dms/icons/Subtask_delete_icon.svg'
import Moment from 'moment';

// import Add_icon from '../../../assets/images/dms/icons/Main Task Add_icon.svg'
// import subTask_icon from '../../../assets/images/dms/icons/BlackSubTaskAddicon.svg'
// import delete_icon from '../../../assets/images/dms/icons/BlackSubTaskDeleteicon.svg'
// import downAccordion_icon from '../../../assets/images/dms/icons/For Accordion_icon.svg'
// import up_accordion_icon from '../../../assets/images/dms/icons/up_accordion_icon.svg'
// import tasklist_icons from '../../../assets/images/dms/icons/tasklist_icons.svg'

const AddOrderTask = (props) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [personInCharge, setPersonInCharge] = useState([]);
  let templateTasks = props.tasks;
  const selectedTemplateId = props.selectedTemplate;
  const workspaceID = props.workspaceId;
  const companyID = props.companyId;
  const orderID = props.orderId;
  const [isTemplateChanged, setIsTemplateChanged] = useState("0");
  const [newTemplateToSave, setNewTemplateToSave] = useState([]);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const toggleAddTaskModal = () => { setAddTaskModal(!addTaskModal) };
  const [taskTitle, setTaskTitle] = useState("");
  const [subTaskOf, setSubTaskOf] = useState("");
  const [stDate, setstDate] = useState("");
                                     
  useEffect(() => {
    axios.post(ServerUrl + "/get-order-contacts", apiencrypt({
      "company_id": companyID,
      "workspace_id": workspaceID,
      "order_id": orderID
    })).then((response) => {
      response.data = apidecrypt(response.data)
      setPersonInCharge(response.data.data);
    })
  }, []);

  if (isTemplateChanged === "1") {
    setIsTemplateChanged("0");
  }

  const openAddModal = (task, subTaskOff = '') => {
    if (task === "task") {
      setAddTaskModal(!addTaskModal);
      setTaskTitle("Task");
    }
    else if (task === "subtask") {
      setAddTaskModal(!addTaskModal);
      setTaskTitle("Sub Task");
      setSubTaskOf(subTaskOff);
    }
  };

  /************ Function to delete the selected task/subtask ***************/
  const deleteTask = (title, task, subtitle = '') => {
    Swal.fire({
      customClass: {
        confirmButton: 'swal2-delete'
      },
      title: t("areYouSure"),
      text: t("deleteTaskTextAlert"),
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: 'swal2-deny',
      confirmButtonText: t("delete"),
      cancelButtonColor: '#E7EAED'
    }).then((result) => {
      if (result.isConfirmed) {
        for (let i = 0; i < templateTasks.length; i++) {
          if (templateTasks[i].id == selectedTemplateId) {
            let template = JSON.parse(templateTasks[i].task_template_structure);
            let newTemplate = [];
            var resultmain = template.filter(function(item){
              if(item.task_title !== 'deleted') {
                return item;
              }
            });
           // if (template.length === 1 && task === "task")
           //console.log("resultmain==>",resultmain);
            if (resultmain.length === 1 && task === "task")
             {
              Swal.fire({
                title: t("lastTaskDeletionTitleAlert"),
                text: t("lastTaskDeletionTextAlert"),
                icon: "error",
                confirmButtonText: t("okLabel"),
                allowOutsideClick: false,
              }).then(() => {
                templateTasks[i].task_template_structure = JSON.stringify(template);
                setNewTemplateToSave(template);
              });
            }
            else {
              for (let j = 0; j < template.length; j++) {
                if (template[j].task_title === title && task === "task") {
                  template[j].task_title="deleted";
                 // console.log("dddddd==>",template[j].task_title); 
                  newTemplate.push(template[j]);
                 // return false;
                }
                else if (template[j].task_title === title && task === "subTask") {
                  let task_subtitles = [];
                  var result = template[j].task_subtitles.filter(function(item){
                    if(item !== 'deleted') {
                      return item;
                    }
                  });

                  //if ((template[j].task_subtitles).length === 1) 
                  if (result.length === 1) 
                  {
                    newTemplate.push(template[j]);
                    Swal.fire({
                      title: t("lastTaskDeletionTitleAlert"),
                      text: t("mainTaskDeletionTextAlert"),
                      icon: "error",
                      confirmButtonText: t("okLabel"),
                      allowOutsideClick: false,
                    });
                  }
                  else {
                    for (let k = 0; k < (template[j].task_subtitles).length; k++) {
                      if (template[j].task_subtitles[k] === subtitle) {
                      //  task_subtitles.push(template[j].task_subtitles[k]);
                        task_subtitles.push(template[j].task_subtitles[k]="deleted");
                       }
                      else {
                        task_subtitles.push(template[j].task_subtitles[k]);
                      }
                    }
                    template[j].task_subtitles = task_subtitles
                    newTemplate.push(template[j]);
                  }
                }
                else {
                  newTemplate.push(template[j]);
                }
              }
             // console.log("newTemplate==>",newTemplate);
              templateTasks[i].task_template_structure = JSON.stringify(newTemplate);
              setNewTemplateToSave(newTemplate);
            }
          }
          //props.setTemplates(templateTasks);
          props.setTemplateload(templateTasks);
        }
        setIsTemplateChanged("1");
        props.areTasksEdited(1);
      }
    })

  }

  /*Drag start */
  
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const initialX = e.clientX - position.x;
    const initialY = e.clientY - position.y;

    const handleMouseMove = (e) => {
      const newX = e.clientX - initialX;
      const newY = e.clientY - initialY;
      setPosition({ x: newX, y: newY });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
  };
  /*Drag end */
  return (
    <>
      <FormAddTaskSubTaskModal taskTitle={taskTitle} subtaskOf={subTaskOf} modal={addTaskModal} toggleModal={toggleAddTaskModal}
        newTemplateSave={setNewTemplateToSave} setTemplates={props.setTemplates} refreshTemplates={setIsTemplateChanged}
        taskTemplate={templateTasks} selectedtemplateId={selectedTemplateId} areTasksEdited={props.areTasksEdited} />
      {(getStaff === "Staff" && getStaffPermission.includes('Task Edit Template')) || getStaff == null ?
        <Row>
          <div>
            {/* <a href="javascript:void(0)" className="f-right m-b-5 icon-color f-w-600 f-18 p-2" 
            style={{ fontFamily: 'poppins'}} onClick={()=>openAddModal("task")}>
            <i className="fa fa-plus-circle"></i>{ t("addTask")}</a> */}
             <a href="javascript:void(0)" className="f-right m-b-5 icon-color f-w-500 f-16 p-2" 
            style={{ fontFamily: 'poppins'}} onClick={()=>openAddModal("task")}>
            <img className="cursor-pointer "  
              title={t("addTask")}  src={Add_icon} />{ t("addTask")}</a> 
            {/* <img className="cursor-pointer p-r-20" style={{ float: "right" }} 
              title={t("addTask")} onClick={() => openAddModal("task")} src={Add_icon} /> */}
          </div>
        </Row> : ""
      }
      <Row>
        <Col sm="12" xl="12">
          <div className="default-according" id="accordion">
            {templateTasks.map((template) =>
              template.id == selectedTemplateId
                ? JSON.parse(template.task_template_structure).map((templates, i) => (
                  templates.task_title !== "deleted" ?
                  <Card key={i} className="cardRadius">
                    {/* <CardHeader className="p-1 cardRadius" >
                      <Row>
                        <Col onClick={() => toggle(i)} className="cursor-pointer">
                          {(getStaff === "Staff" && getStaffPermission.includes('Task Edit Template')) || getStaff == null ?
                            <>
                             

                              <img className="cursor-pointer f-right p-r-5" title={t("deleteTask")} 
                                onClick={() => deleteTask(templates.task_title, "task")} src={delete_icon} />
                            
                              <img className="cursor-pointer f-right p-r-5" title={t("addSubTask")} 
                                onClick={() => openAddModal("subtask", templates.task_title)} src={subTask_icon} /> </> : ""
                          }
                          <H5 attrH5={{ className: "m-0 p-0 f-left" }}>
                            <Btn attrBtn={{
                                as: Card.Header, className: "btn btn-link",  color: "default",
                                onClick: () => toggle(i), }} >
                              {props.subTaskEmpty === 1 && templates.task_subtitles.length === 0 ?
                                <p>{templates.task_title} <span className="p-0 m-0 m-l-10 f-12 font-danger">{t("addSubTask")}</span></p> : <p>{templates.task_title}</p>}
                            </Btn>
                          </H5>
                          {isOpen === i ? <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5" title={t("close")} src={up_accordion_icon} /></span>
                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5" title={t("open")} src={downAccordion_icon} /></span>}
                        </Col></Row>
                    </CardHeader> */}

                      <CardHeader className="p-1 cardRadius" >
                      <Row>
                    
                          <Col onClick={() => toggle(i)} className="cursor-pointer" 
                          xxl="8" xl="9" lg="9" md="7" sm="7" xs="7">
                          <H5 attrH5={{ className: "m-0 p-0 f-left" }}>
                            <Btn attrBtn={{
                                as: Card.Header, className: "btn btn-link",  color: "default",
                                onClick: () => toggle(i), }} >
                              {props.subTaskEmpty === 1 && templates.task_subtitles.length === 0 ?
                                <p>{templates.task_title} <span className="p-0 m-0 m-l-10 f-12 font-danger">{t("addSubTask")}</span></p> : <p>{templates.task_title}</p>}
                            </Btn>
                          </H5>
                            </Col>
                        <Col  xxl="4" xl="3" lg="3" md="5" sm="5" xs="5">
                      
                          {(getStaff === "Staff" && getStaffPermission.includes('Task Edit Template')) || getStaff == null ?
                            <>
                              {/* <a href="javascript:void(0)" title={t("deleteTask" )}className="icon-color f-right m-t-10 p-r-5" 
                              onClick={()=>deleteTask(templates.task_title,"task")}> <i className="fa fa-trash f-right"></i></a> */}

                              <img className="cursor-pointer f-right p-r-5" title={t("deleteTask")} 
                                onClick={() => deleteTask(templates.task_title, "task")} src={delete_icon} />
                              {/* <a href="javascript:void(0)" title={t("addSubTask")} className="icon-color f-right m-t-10 p-r-5 f-14 f-w-600"
                            onClick={()=>openAddModal("subtask",templates.task_title)}>
                            <i className="fa fa-plus-circle"></i></a> */}
                              <img className="cursor-pointer f-right p-r-5" title={t("addSubTask")} 
                                onClick={() => openAddModal("subtask", templates.task_title)} src={subTask_icon} /> </> : ""
                          }
                            {isOpen === i ? <span className=" p-0 accdview f-right " onClick={() => toggle(i)} > <img className="cursor-pointer f-right p-r-5" title={t("close")} src={up_accordion_icon} /></span>
                            : <span className=" p-0  accdview  f-right " onClick={() => toggle(i)} ><img className="cursor-pointer f-right p-r-5" title={t("open")} src={downAccordion_icon} /></span>}
                      
                          </Col>
                        </Row>
                    </CardHeader>
                    <Collapse isOpen={isOpen === i}>
                      <CardBody className="p-0 cardRadius">
                        <div className="table-responsive">
                          <Table className="">
                            <th className="taskUpdate-tasktable-header text-center f-w-600">{t("task")}</th>
                            <th className="taskUpdate-tasktable-header f-w-600">{t("startDate")}</th>
                            <th className=" taskUpdate-tasktable-header f-w-600">{t("endDate")}</th>
                            <th className="taskUpdate-tasktable-header f-w-600">{t("pic")}</th>
                            {(getStaff === "Staff" && getStaffPermission.includes('Task Edit Template')) || getStaff == null ?
                              <th className="taskUpdate-tasktable-header f-w-600">{t("action")}</th> : ""
                            }
                            {templates.task_subtitles.length === 0 ? props.setSubTaskEmpty(1) : props.setSubTaskEmpty(0)}
                            {templates.task_subtitles.map((subtitle,index) => (
                            
                            subtitle !== "deleted" ?
                            <>
                            {/* <Col md={6}>
                              <div className="bg-light font-dark p-2 f-w-500 f-12">
                                {subtitle}
                              </div> */}
                            <input type="hidden" name="subtitle" id={templates.task_title + "||" + subtitle + "||name"} defaultValue={subtitle} />
                            <tr   key={index}
           className={`draggable ${isDragging ? 'dragging taskUpdate-tr-border' : 'taskUpdate-tr-border'}`}
         
           onMouseDown={handleMouseDown}
            >
                              <td className=""><p className="m-l-20 p-0">  <img src={tasklist_icons} />{subtitle}</p></td>
                              <td>
                               
                                <Input
                                  type="date"
                                  id={templates.task_title + "||" + subtitle + "||startdate"}
                
                                  min={(getStaff === "Staff" && getStaffPermission.includes('Early Task Start') || getStaff == null)?  ""  :props.basicInfo.inquiry_date != null ? props.basicInfo.inquiry_date:
                                  
                                    new Date().toISOString().split('T')[0]
                                     
                                  }
                                  data-target-input="nearest"
                                  onChange={(e) => {
                                    document.getElementById(templates.task_title + "||" + subtitle + "||enddate")
                                      .setAttribute("min", e.target.value);
                                      setstDate(e.target.value)
                                    document.getElementById(templates.task_title+"||"+subtitle+"||enddate")
                                    .disabled=false;
                                    document.getElementById(templates.task_title+"||"+subtitle+"||enddate").value=""

                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                </Input>
                              </td>
                              <td>
                                <Input
                                  type="date"
                                  id={templates.task_title + "||" + subtitle + "||enddate"}
                                  min={new Date().toISOString().split('T')[0]}
                                  disabled={true}
                                  data-target-input="nearest"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                </Input>
                              </td>
                              <td>
                                <Input
                                  type="select"
                                  defaultValue=""
                                  id={templates.task_title + "||" + subtitle + "||pic_id"}
                                  className="pic_select"
                                >
                                  <option value="" disabled>{t("Selectpic")}</option>
                                  {personInCharge.length > 0 ? personInCharge.map((pic) => (
                                    <option value={pic.staff_id}>{pic.staff_name}</option>
                                  )) : ""}
                                </Input>
                              </td>
                              {(getStaff === "Staff" && getStaffPermission.includes('Task Edit Template')) || getStaff == null ?
                                <td>
                                  {/* <a href="javascript:void(0)" onClick={()=>deleteTask(templates.task_title,"subTask",subtitle)}>
                                <div className="reschedule-div m-l-10"><i className="fa fa-trash rescheduleImage"></i></div>
                                </a>  */}
                                  <img className="cursor-pointer" title={t("deleteTask")} onClick={() => deleteTask(templates.task_title, "subTask", subtitle)} src={Subtask_delete} />
                                </td> : ""}
                            </tr>
                            {/* <Row className="m-b-10">  
                                <Col sm={4}>
                                    <Label className="font-dark m-t-5">Start Date</Label>
                                </Col>
                                <Col sm={4}>
                                    <Label className="font-dark m-t-5">End Date</Label>
                                </Col>
                                <Col sm={4}>
                                    <Label className="font-dark m-t-5">Person In Charge</Label>
                                </Col>
                              </Row>   */}
                            {/* </Col> */}
                          </> 
                            :
                             ""
                            ))}
                          </Table></div>
                      </CardBody>
                    </Collapse>
                  </Card> :
                             ""
                ))
                : ""
            )}
            <TemplateModal
              modal={props.modal}
              toggleTemplateModal={props.toggleTemplateModal}
              companyId={companyID}
              workspaceId={workspaceID}
              orderId={orderID}
              templates={props.setTemplates}
              newTemplate={newTemplateToSave}
              savedTemplateId={props.savedTemplateId}
              setTheSelectedTemplate={props.setTheSelectedTemplate}
              areTasksEdited={props.areTasksEdited}
              taskparam={props.tasks}

              selectedTemplateId={selectedTemplateId}
              onSubmitHandle={props.onSubmitHandle}
              setIfTemplateChanged={props.setIfTemplateChanged}

              setOnSubmitData={props.setOnSubmitData}
              setTemplateload={props.setTemplateload}
              setOrderTemplateId={props.setOrderTemplateId}
            />
            {/* <AddTask
                      modal={props.modal}
                      toggleTemplateModal={props.toggleTemplateModal}
                      companyId = {companyID}
                      workspaceId = {workspaceID}
                      orderId = {orderID}
                      templates = {props.setTemplates}
                      newTemplate = {newTemplateToSave}
                      savedTemplateId={props.savedTemplateId}
                      setTheSelectedTemplate={props.setTheSelectedTemplate}
                      areTasksEdited={props.areTasksEdited}
                    /> */}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default AddOrderTask;
/* Code By : Rithanesh */