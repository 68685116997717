import React, { Fragment, useState, useEffect } from "react";
import ProductionCanvas from "./ProductionOffCanvas";
import { Row, Col, Card, CardBody, Label, Input, Button } from "reactstrap";
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import { getLoginCompanyId, getLoginUserId, getLoginStaffId, 
  getWorkspaceId, adminAndStaff, getLoginUserType } from "../../Constant/LoginConstant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";

const DashboardSettings = (props) => {
  const [dashboardWidgets, setgetdashboardWidgets] = useState([]);
  const [getEmail_weekly_reminder, setgetEmail_weekly_reminder] = useState();
  const [show, setShow] = useState(false);
  const toggleCanvas = () => setShow(!show);
  const [getDasboard, setWeekoff] = React.useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    axios
      .post(ServerUrl + "/get-dashboard-settings", apiencrypt({
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setgetdashboardWidgets(response.data.data.dashboardWidgets);
      });
  }, []);

  const onSelectUpdate = (e, key) => {
    var dash = [];
    let getNofiySetting = {};
    getNofiySetting["company_id"] = getLoginCompanyId;
    getNofiySetting["user_id"] = getLoginUserId;
    getNofiySetting["staff_id"] = getLoginStaffId;
    getNofiySetting["workspace_id"] = getWorkspaceId;
    getNofiySetting["data"] = dash;

    for (var i = 1; i <= 5; i++) {
      var getDetails = document.getElementById("dashboardsettings-" + i).checked;
      if (getDetails)
        dash.push(i)
    }
    return getNofiySetting;
  };
  const onSubmitHandler = (e) => {
    let getNotify = onSelectUpdate();
    axios
      .post(ServerUrl + "/add-dashboard-settings", apiencrypt(getNotify))
      .then((addDashboardResponse) => {
        addDashboardResponse.data = apidecrypt(addDashboardResponse.data);
        if (addDashboardResponse.data.status_code == 200) {
          axios
            .post(ServerUrl + "/get-dashboard-settings", apiencrypt({
              company_id: getLoginCompanyId,
              user_id: getLoginUserId,
              staff_id: getLoginStaffId,
              workspace_id: getWorkspaceId,
            }))
            .then((response) => {
              Swal.fire({
                title: t("dashboardsettings"),
                text: t("savedSuccessfully"),
                icon: "success",
                timer: 5000,
                button: t("okLabel"),
                confirmButtonColor: "#009688",
              });
            });
        }

      })
  };

  return (
    <Fragment>
      <Col md="12" lg="12" sm="12">
        <Card className="shadow shadow-md">
          <Row>
            <Col md="12">
              <HeaderCard title={t("dashboardsettings")} />
            </Col>
          </Row>
          <CardBody className="animate-chk" style={{ marginTop: getLoginUserType !== "user" ? adminAndStaff == 1 ? "-10px" : "-10px" : "-10px" , 
                                                     marginBottom: getLoginUserType !== "user" ? adminAndStaff == 1 ? "-4px" : "-20px" : "-20px", 
                                                     height:  getLoginUserType !== "user" ? adminAndStaff == 1 ? "215" : "185px" : "165px" }}>
            <Row>
              <Col className="m-t-10" md="4" lg={ getLoginUserType !== "user" ? adminAndStaff == 1 ? 6 : 2 : 2 } sm="4" xs="4">
                <Label className="d-block" for="dashboardsettings-1">
                  {dashboardWidgets.includes("1") ?
                    (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-1"
                        type="checkbox"
                        name="dashboardsettings"
                        value="1"
                        defaultChecked
                      />
                    ) : (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-1"
                        type="checkbox"
                        name="dashboardsettings"
                        value="1"
                      />
                    )}{" "}
                  {t("productionStatus")}
                </Label>
              </Col>
              <Col className="m-t-10" md="4" lg={ getLoginUserType !== "user" ? adminAndStaff == 1 ? 6 : 3 : 3 }  sm="4" xs="4">
                <Label className="d-block" for="dashboardsettings-2">
                  {dashboardWidgets.includes("2") ? (
                    <Input
                      className="checkbox_animated"
                      id="dashboardsettings-2"
                      type="checkbox"
                      name="dashboardsettings"
                      value="2"
                      defaultChecked
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="dashboardsettings-2"
                      type="checkbox"
                      name="dashboardsettings"
                      value="2"
                    />
                  )}{" "}
                  {t("top5DelayedProduction")}
                </Label>
              </Col>
              <Col className="m-t-10" md="4" lg={ getLoginUserType !== "user" ? adminAndStaff == 1 ? 6 : 2 : 2 }  sm="4" xs="4">
                <Label className="d-block" for="dashboardsettings-3">
                  {dashboardWidgets.includes("3") ?
                    (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-3"
                        type="checkbox"
                        name="dashboardsettings"
                        value="3"
                        defaultChecked
                      />
                    ) : (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-3"
                        type="checkbox"
                        name="dashboardsettings"
                        value="3"
                      />
                    )}{" "}
                  {t("taskStatus")}
                </Label>
              </Col>
              <Col className="m-t-10" md="4" lg={ getLoginUserType !== "user" ? adminAndStaff == 1 ? 6 : 3 : 3 }  sm="4" xs="4">
                <Label className="d-block" for="dashboardsettings-4">
                  {dashboardWidgets.includes("4") ?
                    (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-4"
                        type="checkbox"
                        name="dashboardsettings"
                        value="4"
                        defaultChecked
                      />
                    ) : (
                      <Input
                        className="checkbox_animated"
                        id="dashboardsettings-4"
                        type="checkbox"
                        name="dashboardsettings"
                        value="4"
                      />
                    )}{" "}
                  {t("top5DelayedTasks")}
                </Label>
              </Col>

              {/* <Col className="m-t-15" md="4">
                <Label className="d-block" for="dashboardsettings-5">
                  {dashboardWidgets.includes("5") ? (
                    <Input className="checkbox_animated"
                      id="dashboardsettings-5"  value="5"
                      type="checkbox" name="dashboardsettings" defaultChecked />
                  ) : (
                    <Input className="checkbox_animated"
                      id="dashboardsettings-5" value="5"
                      type="checkbox" name="dashboardsettings" />
                  )} {t("notification")}
                </Label>
              </Col> */}
              {/* <Col className="m-t-15" md="4">
                <Label className="d-block" for="dashboardsettings-6">
                  {dashboardWidgets.includes("6") ? (
                    <Input className="checkbox_animated"
                      id="dashboardsettings-6"  type="checkbox"
                      name="dashboardsettings" value="6" defaultChecked />
                  ) : (
                    <Input className="checkbox_animated"
                      id="dashboardsettings-6" type="checkbox"
                      value="6" name="dashboardsettings"/> )} {t("onGoingList")}
                </Label>
              </Col> */}
              <Col className="m-t-10" md="4" lg={ getLoginUserType !== "user" ? adminAndStaff == 1 ? 6 : 2 : 2 }  sm="4" xs="4">
                <Label className="d-block" for="dashboardsettings-5">
                  {dashboardWidgets.includes("5") ? (
                    <Input
                      className="checkbox_animated"
                      id="dashboardsettings-5"
                      type="checkbox"
                      name="dashboardsettings"
                      value="5"
                      defaultChecked
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="dashboardsettings-5"
                      type="checkbox"
                      value="5"
                      name="dashboardsettings"
                    />
                  )}{" "}
                  {t("Order Status")}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col >
                <div className="m-t-10 text-end">
                  <span>
                    <Button
                      className="btn secondaryBtn btn-sm"
                      title={t("click")} tooltip={t("click")} alt={t("click")}
                      to="#" onClick={() => {toggleCanvas() }}
                    > {t("view")}
                    </Button>
                  </span>
                  <span className="" > <Button
                    className="btn btn-primary btn-sm"
                    to="#" onClick={(e) => { onSubmitHandler(e); }}
                  >
                    {t("save")}
                  </Button>
                  </span>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <ProductionCanvas modal={show} toggle={toggleCanvas} />
    </Fragment>
  );
};

export default DashboardSettings;

//**************************Code by : Praveen****************************//