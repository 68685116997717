import React, { Fragment, useState, useEffect } from "react";
import { Form, Label, Card, CardBody, Col, Row, Input, FormGroup, Table, Button} from "reactstrap";
import { H6 } from "../../../AbstractElements";
import OrderHeaders from "../CreateOrders/OrderHeaders";
import factory from '../../../assets/images/dms/factory.png'
import pcu from '../../../assets/images/dms/pcu.png'
import userimg from '../../../assets/images/dms/user.png'
import buyer from '../../../assets/images/dms/onGoingList.svg';
import AddStaffModal from "../CreateOrders/AddStaffModal";
import { encode,decode, apiencrypt, apidecrypt } from "../../../helper"
import Swal from 'sweetalert2';
import axios from "axios";
import { ServerUrl } from "../../../Constant";
import { useSearchParams, Link } from "react-router-dom";
import { getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission,getLoginUserId,getLoginStaffId }
  from '../../../Constant/LoginConstant';
import {blurEmail} from "../../../../src/helper"
import { useTranslation } from "react-i18next";

/***************************************************/

const AddContacts = (props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [contactList, setContactList] = React.useState([]);
  const [basicInformation, setBasicInformation] = React.useState([]);
  const [staff_id, setStaffId] = React.useState('');
  const [contacts, setContacts] = React.useState([]);
  const [getOrderContacts, setGetOrderContacts] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [roles, setRole] = React.useState([]);
  const [allowplanvalidation, setallowplanvalidation] = React.useState();
  const [workspace_type,setworkspace_type]=useState([]);
  const toggle = () => setModal(!modal);

  var getInputParams = {};
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;
  getInputParams['user_id'] = getLoginUserId;
  getInputParams['staff_id'] = getLoginStaffId;

  const inputParamsUser = {};
  inputParamsUser['company_id'] = getLoginCompanyId;
  inputParamsUser['workspace_id'] = getWorkspaceId;
  inputParamsUser['user_id'] = getLoginUserId;
  inputParamsUser['type'] = 'Staff';

  useEffect(async() => {

    await axios.post(ServerUrl + '/validate-plan', apiencrypt(inputParamsUser))
    .then((response) => {
      response.data = apidecrypt(response.data);
      if (response.data.status_code === 400) {
        setallowplanvalidation("expired");
        // Swal.fire({
        //   title: response.data.error,
        //   icon: "warning",
        //   button: t("okLabel"),
        //   allowOutsideClick: false
        // })
        //   .then((result) => {
        //     if (result.isConfirmed) {
        //      // window.location.href = '/staff';
        //     }
        //   })
      }
    });

    await axios
      .post(ServerUrl + "/get-staffs", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setContactList(response.data.data);
      })
      axios.get(ServerUrl +"/workspace-type")
      .then((response) => {
        response.data = apidecrypt(response.data)
        setworkspace_type(response.data.data);
      });
      await axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setBasicInformation(response.data.data);
        if(response.data.data[0].status != 1 )
        {
          window.location.href = "/dashboard" 
        }
        else {
          ""
        }
      })

      await axios
      .post(ServerUrl + "/get-order-contact", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setGetOrderContacts(response.data.data);
      })
      await axios
      .post(ServerUrl + "/get-roles", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setRole(response.data.data);
      })

      {
        ((( getStaff === "Staff" && getStaffPermission.includes('Edit Order'))||getStaff == null )) 
        ?
        ""
        :
        window.location.href = "/dashboard" 
      } 

  }, [])

  /****************************************** FILTER DATA ******************************/ 
  const filterObject = (obj, filter, filterValue) => 
    Object.keys(obj).reduce((acc, val) => 
    (obj[val][filter] != filterValue 
      ? acc 
      : {
        //...acc,
        ['name']: obj[val]['first_name']+" "+obj[val]['last_name'],
    }                                        
  ), {});

  /******************* PAYLOAD - [INPUT PARAMS] (API CALL) ******************************/ 
  function getChecked(staffListData) {
    /** Order Edit Log starts*/
    var previous_contact=[];
    var after_contact=[];
    getOrderContacts.map((contact) => {
      previous_contact.push(filterObject(contactList,"id",contact).name)
    })
    getInputParams['before_values'] = previous_contact;   
    /** Order Edit Log end */ 
    var staff_arr = {};
    var staff_arr1 = [];
    var j = 0;
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    const length = staffListData.contactList.length;
    getInputParams['contacts'] = staff_arr1;

    for (var i = 0; i < checkboxes.length; i++) {
      var checkBox = checkboxes[i];

      if (checkBox.checked) {
        staff_arr.staff_id = checkBox.id;
        staff_arr1[j] = staff_arr;
        staff_arr = {};
        j++;
        after_contact.push(filterObject(contactList,"id",checkBox.id).name)
      }
    }
    getInputParams['after_values'] = after_contact;   
    if (j == 0) {
      Swal.fire({
        title: t("staffDetails"),
        text: t("selectStaffValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    } else {
      /* API CALLS [Contact Added successfully]*/
      axios
        .post(ServerUrl + "/update-contacts", apiencrypt(getInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer: 5000,
            })
              .then((result) => { 
                if (result.isConfirmed) {
                // setTimeout(() => {
                  window.location.href = '/orderview?id=' +
                    encode(orderId);
                // }, 100);
              }
              setTimeout(() => {
                window.location.href = '/orderview?id=' +
                  encode(orderId);
              }, 100);


              })
          } else if (response.data.status_code === 401) {
            if (response.data.error.contacts) {
              Swal.fire({
                title: t("selectCheckBoxValidation"),
                text: t("fieldMissing"),
                icon: "warning",
                button: t("okLabel"),
              });
            }
          }
        })
    }
  }

  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>

          <CardBody>
            <OrderHeaders title="addcontact" />
          </CardBody>

          <CardBody>
            <Form className="needs-validation" noValidate="">
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  {/* <H6>SKU Details</H6> */}

                  <Row className="g-12">
                    <div className="table-responsive">
                      {basicInformation.map((information,i) => (
                        <Table key={i} className="shadow-sm shadow-showcase">
                          <thead>
                            <tr className="table-active">
                              <th scope="col">
                                <span className="m-l-10">{t("basicInformation")}</span>
                              </th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col" className="text-right">
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item m-l-10"> {t("orderNo")}</li>
                                  </ul>
                                  <h6 className="mt-0 m-l-10">{information.order}</h6>
                                </div>
                              </td>
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item">{t("styleNo")}</li>
                                  </ul>
                                  <h6 className="mt-0">{information.style}</h6>
                                </div>
                              </td>
                              <td>
                                <img src={userimg} alt={t("users")} />
                                <span>
                                  {" "}
                                  <b>{information.workspace}</b>
                                </span>
                              </td>
                              {information.factory != null ?
                                <td>
                                  <img src={factory} alt={t("factory")}/>
                                  <span>
                                    {" "}
                                    <b> {information.factory}</b>
                                  </span>
                                </td>
                                : ""}
                              {information.pcu != null ?
                                <td>
                                  <img src={pcu} alt={t("pcu")}  />{" "}
                                  <span>
                                    {" "}
                                    <b> {information.pcu}</b>
                                  </span>
                                </td>
                                : ""}
                              {information.buyer != null ?
                                <td>
                                  <img src={buyer} alt={t("buyer")} />{" "}
                                  <span>
                                    {" "}
                                    <b> {information.buyer}</b>
                                  </span>
                                </td>
                                : ""}
                            </tr>
                          </tbody>
                        </Table>
                      ))}
                    </div>
                  </Row>

                </Col>
                {/*end order details */}
              </Row>
              {/*Start Add Contact Row*/}
              <Row className="g-12 m-t-20">
                <Col md="12" lg="12" sm="12"
                  className="text-end">
                  {((getStaff === "Staff" && getStaffPermission.includes('Staff Add')) || getStaff == null) && allowplanvalidation!='expired' ?
                    <span className="btn-pill btn btn-outline-primary btn-md"
                      onClick={toggle}>  {t("addStaffBtn")} </span> : ""
                  }

                  <AddStaffModal
                    modal={modal}
                    toggle={toggle}
                    inputParams={getInputParams}
                    roles={roles}
                    setModal={setModal}
                    pagename="editcontacts"
                    ContactListDetails={setContactList}
                    workspace_type={workspace_type}
                  />
                </Col>
              </Row>
              {/*End Add Contact Row*/}
              <Row className="g-12 m-t-20">
                {/*start contact list */}
                <Col md="12" lg="12" sm="12">
                  <H6>{t("contactList")} </H6>
                  <Row className="g-12">
                    <div className="table-responsive">
                      <table className="table shadow-sm shadow-showcase">
                        <thead className="bg-primary">
                          <tr>
                            <th scope="col">{t("serialNo")}</th>
                            <th scope="col"> {t("checkBox")}</th>
                            <th scope="col"> {t("staffName")}</th>
                            { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                            <th scope="col"> {t("email")}</th> :""}
                            <th scope="col">{t("userType")}</th>

                            <th scope="col"> {t("role")}</th>
                          </tr>
                        </thead>
                        {contactList.map((staffList, s) =>
                        (
                          <>
                            <tbody>
                              <tr key={staffList.id}>
                                <th scope="row">{s + 1}</th>
                                <td>
                                  <div >
                                    {
                                      getOrderContacts.includes(staffList.id) ?
                                        <Input className="checkbox_animated "
                                          name="checkBox" disabled
                                          id={staffList.id} type="checkbox" defaultChecked /> :
                                        staffList.status == 1 ?
                                          <Input data-dis={staffList.status} className="checkbox_animated "
                                            name="checkBoxstafflist"
                                            id={staffList.id} type="checkbox"
                                          /> : ''
                                    }
                                    <Label for={staffList.id} className="form-label"></Label>
                                  </div>
                                </td>
                                <td>{staffList.first_name} {staffList.last_name}</td>
                                { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                                      <td>{ staffList.email} </td>: 
                                      // <td className="blurmail">{blurEmail(staffList.email)}</td> 
                                      ""} 
                                <td >{staffList.user_type ? staffList.user_type =="PCU" ? "Buying House":staffList.user_type :"-"}</td>

                                <td>{staffList.role}</td>
                              </tr>

                            </tbody>
                          </>
                        )
                        )}
                      </table>
                    </div>
                  </Row>
                </Col>
                {/*end contact list qty */}
                <Col md="12" lg="12" sm="12">
                  &nbsp;
                </Col>
              </Row>

              {/*end second row*/}
              <Row className="g-12">
                <Col>
                  {" "}
                  <FormGroup className="f-right">
                    {/* <Link 
                     className="btn btn-primary"
                     to={`${process.env.PUBLIC_URL}/editordertask?id=`+encode(orderId)}>{t("next")}</Link> */}
                    &nbsp;&nbsp;
                    <Button
                      className="btn btn-primary"
                      onClick={(e) => getChecked({ contactList })} >
                      {t("saveBtn")}
                    </Button>

                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default AddContacts;
/***** CODE BY : RAMANAN M  *****/
