import React, { Fragment, useState, useEffect, } from "react";
import { Row, Col, Card, CardBody, Table, Input, Label, Form, } from "reactstrap";
import axios from "axios";
import { ServerUrl, } from "../../Constant";
import {
  getLoginCompanyId,
  getLoginUserId,
  getWorkspaceId,
  getStaff,
  getStaffPermission,getWorkspaceType
} from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apidecrypt, apiencrypt } from "../../helper";
import { P } from "../../AbstractElements";
const RolesSettings = () => {
  const [selectdisable, setselectdisable] = useState(false);
  const [permissions, setpermissions] = useState([]);
  const [rolesHasPermissions, setrolesHasPermissions] = useState([]);
  const [rolesAndPermissions, setrolesAndPermissions] = useState([]);
  const [rolesList, setrolesList] = useState([]);
  const [data, setdata] = useState([]);
  const [dataRole, setdataRole] = useState([]);
  const { t } = useTranslation();

  // ********** get-roles-list Api call**********************//
  useEffect(() => {
    axios
      .post(ServerUrl + "/get-roles-list", apiencrypt({
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        role_id: "",
        module: "",
      }))
      .then((response) => {
        response.data=apidecrypt(response.data);
        setpermissions(response.data.data.permissions);
        setrolesHasPermissions(response.data.data.roles_has_permissions);
        setrolesList(
          response.data.data.roles_has_permissions,
          response.data.data.permissions
        );
        setrolesAndPermissions(response.data.data);
      });

    axios
      .get(ServerUrl + "/get-modules", apiencrypt({
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        role_id: "",
        module: "",
      }))
      .then((response) => {
        response.data=apidecrypt(response.data);
        setdata(response.data.data);
      });

    axios
      .post(ServerUrl + "/get-roles", apiencrypt({
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        role_id: "",
        module: "",
      }))
      .then((response) => {
        response.data=apidecrypt(response.data);
        setdataRole(response.data.data);
      });
    (getStaff === "Staff" && getStaffPermission.includes("Add Permissions")) ||
      getStaff == null
      ? ""
      : (document.getElementsByClassName("toDisable").disabled = true);
  }, []);
  // ********** add-privileges Api call**********************//
  const onChangehandler = (e) => {
    {
      const checked = e.target.checked;
      const fields = e.target.id.split("||");
      const data = {};
      data["role_id"] = fields[0];
      data["permission_id"] = fields[1] || 0;
      data["checked"] = checked;
      data["company_id"] = getLoginCompanyId;
      data["workspace_id"] = getWorkspaceId;
      data["user_id"] = getLoginUserId;
      axios.post(ServerUrl + "/add-privileges", apiencrypt(data));
    }
  };

  // ********** Module Filter**********************//
  const onModulesFilter = (e) => {
    if (e.target.value != "") {
      setselectdisable(true);
    } else {
      setselectdisable(false);
    }
    var x = document.getElementById("modulesId").value;
    var y = document.getElementById("roleId").value;

    setpermissions([]);
    setrolesHasPermissions([]);
    setrolesAndPermissions([]);
    axios
      .post(ServerUrl + "/get-roles-list", apiencrypt({
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        role_id: y,
        module: x,
      }))
      .then((response) => {
        response.data=apidecrypt(response.data);
        setpermissions(response.data.data.permissions);
        setrolesHasPermissions(response.data.data.roles_has_permissions);
        setrolesAndPermissions(response.data.data);
      });
  };

  // ********** select All checkbox validate**********************//
  const onCallroll = (e) => {
    let id = e.target.id;
    var classname = "rol_" + id;
    var x = document.getElementsByClassName(classname);
    for (let i = 0; i < x.length; i++) {
      x[i].checked = e.target.checked;
    }
  };
  let Rolev = rolesHasPermissions;
  let ModuleV = permissions;

  return (
    <Fragment>
      <Col md="12">
        <Card className="shadow shadow-showcase m-5">
          <CardBody className="animate-chk"  style={{marginTop:"-15px" }}>
            <Form className="needs-validation" noValidate="">
              <Row className="g-3 form-floating">
                <Col md="4">
                  <Label className="form-label" for="date_format">
                    <b>{t("Roles")}</b>
                  </Label>
                  <Input
                    className="form-control form-control-primary btn-square"
                    name="date_format"
                    type="select"
                    id="roleId"
                    // onClick={this.onRoleshandler}
                    //onChange={this.onModulesFilter}
                    onChange={(e) => onModulesFilter(e)}
                  >
                    <option selected value="">
                      {t("selectAllRoles")}
                    </option>
                    {dataRole.map((value) => (
                      <option key={value.id} value={value.id}>{t(value.name)}</option>
                    ))}{" "}
                    ;
                  </Input>
                </Col>
                <Col md="4">
                  <Label className="form-label" for="date_format">
                    {" "}
                    <b>{t("module")}  </b>
                  </Label>
                  <Input
                    className="form-control form-control-primary btn-square"
                    name="date_format"
                    type="select"
                    id="modulesId"
                    onChange={(e) => onModulesFilter(e)}
                  >
                    <option selected value="">
                      {t("selectAllModule")}
                    </option>
                    {data.map((subvalue,i) => (
                      <option key={i} value={subvalue.module}>{t(subvalue.display_module)}</option>
                    ))}{" "}
                    ;
                  </Input>
                </Col>
              </Row>
            </Form>
          </CardBody>
          </Card>
          {/* <Card>
          <CardBody> */}
            <div className="fixTableHead  shadow shadow-showcase m-5 m-t-30">
              <Table className="table table-bordered">
                <thead>
                  <tr
                    // style={{ backgroundColor: "#f8f8f8" }}
                    className="lightGrayBgColor"
                  >
                    <th>
                      <b style={{ fontSize:"15px"}}>{t("modulesRoles")}</b>
                    </th>
                    {/* ******* Roles has Permission api response mapping ******* */}
                    {Rolev.map((name, index) => (
                      <th className="text-center" scope="col" >
                        {(getStaff === "Staff" &&
                          getStaffPermission.includes("Add Permissions")) ||
                          getStaff == null ? (
                          <Input
                            className="checkbox_animated"
                            disabled={selectdisable}
                            onChange={(e) => onChangehandler(e)}
                            onClick={(e) => onCallroll(e)}
                            type="checkbox"
                            id={name.id}
                          />
                        ) : (
                          ""
                        )}
                        
                        <p style={{ color:"#202020" ,fontStyle:"normal"}}>{t(name.role)}</p>
                      </th>
                    ))}
                  </tr>
                </thead>
                {/* ****Permission api response mapping**********/}
                <tbody>
                {ModuleV.map(function (obj) {
                  return (
                    <>
                      <tr key={obj.id} style={{backgroundColor:"#F4FCFC"}}>
                        <td>&nbsp;&nbsp;
                          {" "}
                          <b>{t(obj.display_module)}</b>{" "}
                        </td>   
                        <td colSpan="50" className="text-center">
                          {" "}
                        </td>
                      </tr>
                      {obj.sub_module_list.map(function (item) {  
                        return (                        
                          //   ******** roles access validation *********
                          (getWorkspaceType == "Factory" || item.display_name!=='BOM Approval')?
                          <tr
                            // style={{ backgroundColor: "#f8f8f8" }}
                            key={item.id}
                          >
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t(item.display_name)}</td>
                            {Rolev.map(function (items) {
                              var substr = "," + item.id + "|";
                              var callRoll = "rol_" + items.id;
                              var chk_id = items.id + "||" + item.id;
                              var checked = false;
                              if (items.sub_module_ids.includes(substr)) {
                                checked = true;
                              }
                              //console.log("checked--->",checked);
                              var clsName = "checkbox_animated " + callRoll;
                              return (
                                <td className="text-center">
                                  {(getStaff === "Staff" &&
                                    getStaffPermission.includes(
                                      "Add Permissions"
                                    )) ||
                                    getStaff == null ? (
                                    <Input
                                      className={clsName}
                                      name="controlled chkbox"
                                      type="checkbox"
                                      onChange={(e) => onChangehandler(e)}
                                      id={chk_id}
                                      defaultChecked={checked}
                                    />
                                  ) : (
                                    <Input
                                      className={clsName}
                                      name="controlled chkbox"
                                      type="checkbox"
                                      // onChange={that.onChangehandler}
                                      onChange={(e) => onChangehandler(e)}
                                      id={chk_id}
                                      defaultChecked={checked}
                                      disabled
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>:""
                        );
                      })}
                     </> );
                    }
                    )}
                    </tbody>
              </Table>
            </div>
          {/* </CardBody>
        </Card> */}
      </Col>
    </Fragment>
  );
  // }
};
export default RolesSettings;

//**************************Code by : Praveen****************************//