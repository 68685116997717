import React , {useState}from "react";
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from "reactstrap";
import CommonModalBox from "../../Common/modal/modal";
import { Btn } from "../../../AbstractElements";
import { Close, SaveChanges , ServerUrl} from "../../../Constant";
import  axios  from "axios";
import Swal from "sweetalert2";
import { t } from "i18next";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../../helper";
const FormAddFactoryModal = ({ modal, toggle, companyId , workspaceId , factory }) => {
  const [factoryName, setFactoryName] = useState("");
  const { t } = useTranslation();
  const onSaveHandle = () => {
    if(!factoryName){
      Swal.fire({
        title:t("factoryName"),
        text: t("enterFactoryName"),
        icon: "error",
        button: t("okLabel"),
      });
    }else{
    axios
      .post(ServerUrl + "/create-user-factory", apiencrypt({
        company_id: companyId,
        workspace_id: workspaceId,
        name: factoryName,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
             title: t("factoryAddedSuccessfully"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer:5000,
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-user-factory", apiencrypt({
                  company_id: companyId,
                  workspace_id: workspaceId,
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  factory(response.data.data);
                });
            }
            setTimeout(() => {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-user-factory", apiencrypt({
                  company_id: companyId,
                  workspace_id: workspaceId,
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  factory(response.data.data);
                });
            }, 100);
          });
        }
        /* To show error if the Role is already present*/
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            text: t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t("factory"),
            text: t(response.data.error.name),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addNewFactory")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
         {t("addNewFactory")}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setFactoryName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>  {t("close")} </Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddFactoryModal;
/* Code By : Rithanesh */
