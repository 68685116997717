import { Container,  Label, Card, CardBody, Col, Row, Input, 
    InputGroup,  Button, CardHeader,  FormGroup, Table} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,
     getWorkspaceType, getStaff, getStaffPermission, getOrderId,
     getLoginStaffId } from "../../../Constant/LoginConstant";
import {Link} from "react-router-dom";
import GreenCutting from '../../../assets/images/dms/icons/cutting_report.svg';
import GreenSewing from '../../../assets/images/dms/icons/sewing_report.svg';
import GreenPacking from '../../../assets/images/dms/icons/packing_report.svg';
import { useTranslation } from 'react-i18next'
import { PHPtoJSFormatConversion } from "../../../helper";
const DataInputSKUReportTable = ({dailyProdData}) =>{
    const { t } = useTranslation();
    let cuttingTarget = 0;
    let cuttingActual = 0;
    let cuttingExcess = 0;
    let cuttingShort = 0;
    let cuttingDiff = 0;
    let sewingTarget = 0;
    let sewingActual = 0;
    let sewingExcess = 0;
    let sewingShort = 0;
    let sewingDiff = 0;
    let packingTarget = 0;
    let packingActual = 0;
    let packingExcess = 0;
    let packingShort = 0;
    let packingDiff = 0;

    var overallTotalqty = 0;

    /* To Calculate the Difference in target and actual */
    const diffFunction = (actual,target) =>{
        let diff = actual-target;
        if(diff === 0)
            return (<td scope="col" className="centerAlign">0/0</td>)
        else if (diff > 0)
            return (<td scope="col" className="centerAlign" ><span style={{ color:"#C4A950" }}>{diff}</span>/0</td>)
        else if (diff < 0)
            return (<td scope="col" className="centerAlign" >0/<span style={{ color:"#C45050" }}>{Math.abs(diff)}</span></td>)
    }
    /* To Calculate the Total excess or short  */
    const excessOrShort = (actual,target)=>{
        let diff = actual-target;
        if(diff > 0)
            return (<><span style={{ color:"#C4A950" }}>{diff}</span>/0</>)
        else if(diff < 0) 
            return (<>0/<span style={{ color:"#C45050" }}>{Math.abs(diff)}</span></>)
        else
            return (<>0/0</>)

    }
    return(
        <>
            <Col>
                    <div className="card-block row">
                        <Col sm="12" lg="12" xl="12">
                            <div className="">
                            <Row>
                            <Col lg={4} md={4} >
                                <Card>
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1">
                                        <img src={GreenCutting} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("cutting")}</p>
                                    </CardHeader>
                                    <Table  className="SKUReportTable custom-table-striped">
                                        <thead>
                                            <tr style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,i)=>{
                                                let cutting_actual=0;
                                                if("Cut" in prodData){
                                                    for(let [key,value] of Object.entries(prodData.Cut)){
                                                        cutting_actual+=value;
                                                    }
                                                }
                                                overallTotalqty = prodData.total_qty
                                                cuttingTarget += prodData.Cut_target!= undefined ? prodData.Cut_target : 0;
                                                cuttingActual += cutting_actual;
                                                cuttingExcess += !isNaN(prodData.Cut_diff)?prodData.Cut_diff:0;
                                                return (
                                                    <tr key={i} className="f-w-400">
                                                        <td scope="col" >{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                        <td scope="col" className="centerAlign">{prodData.Cut_target}</td>
                                                        <td scope="col">
                                                            <table>
                                                            {"Cut" in prodData?
                                                                Object.entries(prodData.Cut).map(([key,value],j)=>(
                                                                    <tr key={j}>
                                                                    <td className="f-w-400 p-1">{ key }</td>
                                                                    <td className="f-w-400 p-1">:</td>
                                                                    <td className="f-w-400 p-1">{ value }</td>
                                                                    </tr>
                                                                )):""
                                                            }
                                                            </table>
                                                        </td>
                                                        <td scope="col" className="centerAlign">{cutting_actual!= 0 ? excessOrShort(cutting_actual,prodData.Cut_target) : "" }</td>
                                                        {/* <td scope="col">{prodData.Cut_diff>=0? prodData.Cut_diff+"/"+ 0 : 0 +"/"+ 
                                                        (prodData.Cut_actual!= undefined? prodData.Cut_diff: 0) }</td> */}
                                                    </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(cuttingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(cuttingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{cuttingActual}</td>
                                                    {diffFunction(cuttingActual,cuttingTarget)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col> 
                            <Col lg={4} md={4} >
                                <Card>
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1">
                                         <img src={GreenSewing} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("sewing")}</p>
                                    </CardHeader>
                                    <Table  className="SKUReportTable custom-table-striped">
                                        <thead>
                                            <tr style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,k)=>{
                                                let sewing_actual=0;
                                                if("Sew" in prodData){
                                                    for(let [key,value] of Object.entries(prodData.Sew)){
                                                        sewing_actual+=value;
                                                    }
                                                }
                                                sewingTarget += prodData.Sew_target!= undefined ? prodData.Sew_target : 0;
                                                sewingActual += sewing_actual;
                                                sewingExcess+= !isNaN(prodData.Sew_diff)?prodData.Sew_diff:0;
                                                return (
                                                <tr key={k} className="f-w-400">
                                                    <td scope="col">{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Sew_target}</td>
                                                    <td scope="col" className="centerAlign">
                                                        <table>
                                                            {"Sew" in prodData ?
                                                                Object.entries(prodData.Sew).map(([key,value],l)=>(
                                                                    <tr key={l}>
                                                                    <td className="f-w-400 p-1">{ key }</td>
                                                                    <td className="f-w-400 p-1">:</td>
                                                                    <td className="f-w-400 p-1">{ value }</td>
                                                                    </tr>
                                                                )):""
                                                            }
                                                        </table>
                                                    </td>
                                                    <td scope="col" className="centerAlign">{sewing_actual!= 0 ?excessOrShort(sewing_actual,prodData.Sew_target) : "" }</td>
                                                </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(sewingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(sewingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{sewingActual}</td>
                                                    {diffFunction(sewingActual,sewingTarget)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col> 
                            <Col lg={4} md={4} >
                                <Card>
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1 centerAlign">
                                        <img src={GreenPacking} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("packing")}</p>
                                    </CardHeader>
                                    <Table  className="SKUReportTable custom-table-striped">
                                        <thead>
                                            <tr style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,m)=>{
                                                 let packing_actual=0;
                                                 if("Pack" in prodData){
                                                     for(let [key,value] of Object.entries(prodData.Pack)){
                                                         packing_actual+=value;
                                                     }
                                                 }
                                                packingTarget += prodData.Pack_target!= undefined ? prodData.Pack_target : 0;
                                                packingActual += packing_actual;
                                                packingExcess+= !isNaN(prodData.Pack_diff)?prodData.Pack_diff:0;
                                                return (
                                                <tr key={m} className="f-w-400">
                                                    <td scope="col">{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Pack_target}</td>
                                                    <td scope="col" className="centerAlign">
                                                        <table>
                                                            {"Pack" in prodData ?
                                                                Object.entries(prodData.Pack).map(([key,value],n)=>(
                                                                    <tr key={n}>
                                                                        <td className="f-w-400 p-1">{ key }</td>
                                                                        <td className="f-w-400 p-1">:</td>
                                                                        <td className="f-w-400 p-1">{ value }</td>
                                                                    </tr>
                                                                )):""
                                                            }
                                                        </table>
                                                    </td>
                                                    <td scope="col" className="centerAlign">{packing_actual!= 0 ?excessOrShort(packing_actual,prodData.Pack_target) : "" }</td>
                                                </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(packingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(packingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{packingActual}</td>
                                                    {diffFunction(packingActual,packingTarget)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>  
                            </Row>  
                               
                            </div>
                        </Col>
                    </div>
            </Col>
        </>
    )
}

export default DataInputSKUReportTable;
/* Code By : Rithanesh */