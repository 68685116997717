import {  Container,  Row,  Col, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs } from "../../AbstractElements";
import axios from "axios";
import Task from "./Task";
import {  ServerUrl} from "../../Constant/index";
import {  getLoginCompanyId,  getLoginUserId,  getWorkspaceId,
  getLoginStaffId,  getStaff,} from "../../Constant/LoginConstant";
import { apiencrypt, apidecrypt } from "../../helper";
import { useTranslation } from "react-i18next";
import DashboardOrderDetails from "./DashboardOrderDetails";
import ProductionStatus from "../NewDashboard-V-1/Productionstatus";
import ProductionDelay from "../NewDashboard-V-1/Productiondelay";
import OrderStatusChart from "./OrderStatusChart/index";
import DelayedTask from "./DelayedTask";
import WelcomeDashboard from "./WelcomeDashboard";
import switchright_icon from "../../assets/images/dms/icons/switchright_icon.svg";
import switchleft_icon1 from "../../assets/images/dms/icons/switchleft_icon1.svg";
import table_icon from "../../assets/images/dms/icons/table_icon.svg";
import Graphical_icon from "../../assets/images/dms/icons/Graphical_icon.svg";

const Dashboard = () => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [switchDashboard, setSwitchDashboard] = useState(0);
  const [orderstatusid, setgeOrderstatusId] = useState();
  const [dashboardWidgetsDashboard, setgetdashboardWidgetsDashboard] = useState([]);
  const { t } = useTranslation();
  const staff_id = getLoginStaffId;
  var requestData;

  getStaff === "Staff"
    ? (requestData = { company_id, workspace_id, staff_id })
    : (requestData = { company_id, workspace_id, user_id });
  const reqData = requestData;
  useEffect(async () => {
    axios.post(
        ServerUrl + "/get-dashboard-settings",
        apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        })
      ).then((response) => {
        response.data = apidecrypt(response.data);
        setgetdashboardWidgetsDashboard(response.data.data.dashboardWidgets);
      });
  }, []);

  return (
    <Fragment>
      <div className="dashboardBackground">
        <Row className="pgbgcolor" style={{ marginTop: '90px' }}>
          <Col>
            <Breadcrumbs className="m-t-15" mainTitle="Dashboard" parent="Dashboard"  
              title="Dashboard" subTitle="Overall View"/>
          </Col>
          <Col style={{ marginRight: '12px' }}>
            <span style={{ float: "right" }}>
              <img style={{ width: "33px" }} title="ChartView" tooltip="ChartView" alt="ChartView"
                className="m-b-20 p-r-10 cursor-pointer" src={Graphical_icon} />
              <img width="40px" src={switchleft_icon1} title="Click" alt="Click"
                className="cursor-pointer p-r-10 p-b-15" onClick={(e) => { setSwitchDashboard(1);}} />
              <img style={{ width: "20px" }} title="TableView" tooltip="TableView" alt="TableView"
                className=" m-b-10 p-l-0 cursor-pointer p-b-10" src={table_icon} />
            </span>
          </Col>
        </Row>
        <Container fluid={true} className="general-widget topaln">
          <Row>
            {/* <DashboardOrderDetails/>
                  <ProductionStatus/>
                  <ProductionDelay geOrderstatusId={setgeOrderstatusId}/>
                  <Task/>
                  <DelayedTask />
                  <OrderStatusChart geOrderstatusId={orderstatusid}/> */}
            {/* <ApexChart/> */}
            <DashboardOrderDetails />
            {switchDashboard == 1 ? (window.location.href = "/dashboardtable") : ""}
            {dashboardWidgetsDashboard.includes("1") != "" ||
            dashboardWidgetsDashboard.includes("2") != "" ||
            dashboardWidgetsDashboard.includes("3") != "" ||
            dashboardWidgetsDashboard.includes("4") != "" ||
            dashboardWidgetsDashboard.includes("5") != "" ? (
              <>
                {switchDashboard == false ? (
                  <>
                    {dashboardWidgetsDashboard.includes("1") ? (
                      <ProductionStatus geOrderstatusId={setgeOrderstatusId} />) : ( "" )}
                    {dashboardWidgetsDashboard.includes("2") ? (
                      <ProductionDelay geOrderstatusId={setgeOrderstatusId} /> ) : ( "")}
                    {dashboardWidgetsDashboard.includes("3") ? (
                      <Task geOrderstatusId={setgeOrderstatusId} /> ) : ( "" )}
                    {dashboardWidgetsDashboard.includes("4") ? (
                      <DelayedTask geOrderstatusId={setgeOrderstatusId} /> ) : ( "")}
                    {dashboardWidgetsDashboard.includes("5") ? (
                      <OrderStatusChart geOrderstatusId={orderstatusid} /> ) : ( "" )}
                  </>
                ) : ("")}
              </>
            ) : ( <WelcomeDashboard />
            )}
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default Dashboard;

//**************************Code by : Praveen****************************//
