import React, { Fragment, useState, useEffect } from "react";
import { Form, Label, Card, CardBody, Col, Row, Input, InputGroup, 
         InputGroupText, Button, FormGroup, Media, Container } from "reactstrap";
import { ServerUrl } from "../../Constant";
import { H6, Btn, Breadcrumbs } from "../../AbstractElements";
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, 
  getWorkspaceType, getStaff, getStaffPermission, getLoginStaffId }
from '../../Constant/LoginConstant';

import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { encode, decode, apiencrypt, apidecrypt } from "../../helper";

import flatIcon from './../../assets/images/dms/icons/flat.svg'
import factorIcon from './../../assets/images/dms/icons/factor.svg'
import AddShiftModal from './AddShiftModal';
import AddUnitModal from './AddUnitModal';
import AddLineNoModal from "./AddLineNoModal";
import AddSupervisorModal from "./AddSupervisorModal";

import AutofillDataModal from './AutofillDataModal'

const AddSAMDetails = () => {
    const { t } = useTranslation();
    const [styleNoList, setStyleNoList] = useState([]);
    const [productionTypeList, setProductionTypeList] = useState(['Sewing', 'Cutting']);
    const [shiftList, setShiftList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [lineNoList, setLineNoList] = useState([]);
    const [breakTimeList, setBreakTimeList] = useState(['1', '2', '3']);
   
    const [styleNo, setStyleNo] = useState("");
    const [productionType, setProductionType] = useState("");
    const [shiftValue, setShiftValue] = useState("");
    const [unitValue, setUnitValue] = useState("");
    const [samValue, setSamValue] = useState("");
    const [supervisorName, setSupervisorName] = useState("");
    const [lineNoValue, setLineNoValue] = useState("");
    const [noOfTailors, setNoOfTailors] = useState("");
    const [noOfHelpers, setNoOfHelpers] = useState("");
    const [tailorSalary, setTailorSalary] = useState("");
    const [helperSalary, setHelperSalary] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [breakTime, setBreakTime] = useState("");
    const [factoryRevenueFactor, setFactoryRevenueFactor] = useState("1");
    const [alertPercentage, setAlertPercentage] = useState("");
    
    const [additionalFromTime, setAdditionalFromTime] = useState("");
    const [additionalToTime, setAdditionalToTime] = useState("");
    const [additionalTailorSalary, setAdditionalTailorSalary] = useState("");
    const [additionalHelperSalary, setAdditionalHelperSalary] = useState("");

    const [shiftId, setShiftId] = useState("");
    const [unitId, setUnitId] = useState("");
    const [supervisorId, setSupervisorId] = useState("");
    const [lineNoId, setLineNoId] = useState("");

    const [orderNo, setOrderNo] = useState("");
    const [orderId, setOrderId] = useState("");
    const [currentDate, setCurrentDate] = useState("");
    const [additionalData, setAdditionalData] = useState(false);
    const [breakFreeStatus, setBreakFreeStatus] = useState(false);
    const [salaryType, setSalaryType] = useState(true);
    const [yes, setYes] = useState();
    const [no, setNo] = useState();
    const [flat, setFlat] = useState();
    const [factor, setFactor] = useState();
    const [errors, setValidErrors] = useState({});
    const [accessoriesValue, setAccessoriesValue] = useState("");

    const [modalAddShift, setModalAddShift] = useState(false);
    const toggleAddShift = () => setModalAddShift(!modalAddShift);

    const [modalAddUnit, setModalAddUnit] = useState(false);
    const toggleAddUnit = () => setModalAddUnit(!modalAddUnit);

    const [modalAddLineNo, setModalAddLineNo] = useState(false);
    const toggleAddLineNo = () => setModalAddLineNo(!modalAddLineNo);

    const [modalAddSupervisor, setModalAddSupervisor] = useState(false);
    const toggleAddSupervisor = () => setModalAddSupervisor(!modalAddSupervisor);
    
    const [modalAutofillData, setModalAutofillData] = useState(false);
    const toggleAutofillData = () => setModalAutofillData(!modalAutofillData);
    
    const [getMasterData, setGetMasterData ] = useState([]); 
    const [getPreviousSamReportDetails, setGetPreviousSamReportDetails] = useState([]);

    var array = [];
    const [ arrayList, setArrayList ] = useState([]);

    useEffect(() => 
    {
      clearValues("yes");
      setCurrentDate(new Date().toJSON().slice(0, 10));
      
      var apiInputParams = {};
      apiInputParams['company_id'] = getLoginCompanyId;
      apiInputParams['workspace_id'] = getWorkspaceId;

      axios
      .post(ServerUrl + "/get-styles",apiencrypt(apiInputParams))
      .then( (response) => {
          response.data = apidecrypt(response.data)
          setStyleNoList(response.data.data);
      })

      axios
      .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setGetMasterData(response.data.data);
      })

    }, [])

    const getPreviousSamReportSettingApiCall = (styleData,orderId) => {
      var apiInputParams = {};
          apiInputParams['company_id'] = getLoginCompanyId;
          apiInputParams['workspace_id'] = getWorkspaceId;
          apiInputParams['style_no'] = styleData;
          apiInputParams['order_id'] = orderId;
      axios
        .post(ServerUrl + "/get-previous-sam-report-setting", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
           if (response.data.status_code === 200) {
            setAccessoriesValue(response.data.accessories_value);
            (response.data.data).map((mapData) => {
              array.push(mapData.unit_id)
            })
            setGetPreviousSamReportDetails(response.data.data);            
            setArrayList(array);
            setModalAutofillData(!modalAutofillData);   
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
           }
        });
    };

    const test = (data1, data2 ) => {
      var apiInputParams = {};
      apiInputParams['company_id'] = getLoginCompanyId;
      apiInputParams['workspace_id'] = getWorkspaceId;
      apiInputParams['style_no'] = data1;
      apiInputParams['unit_id'] = unitId;
      apiInputParams['line_no_id'] = data2;
      axios
        .post(ServerUrl + "/get-previous-sam-report-details", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            if( response.data.data !== null){
                setProductionType(response.data.data.production_type);
                setShiftId(response.data.data.shift_id);
                setUnitId(response.data.data.unit_id);
                setSamValue(response.data.data.sam_value);
                setSupervisorId(response.data.data.supervisor_id);
                setLineNoId(response.data.data.line_no_id);
                setNoOfTailors(response.data.data.no_of_tailors);
                setNoOfHelpers(response.data.data.no_of_helpers);
                setTailorSalary (response.data.data.tailor_salary);
                setHelperSalary(response.data.data.helper_salary);
                setFromTime (response.data.data.from_time);
                setToTime(response.data.data.to_time);
                setBreakFreeStatus(response.data.data.break_hours == 0 ? true : false)
                setBreakTime(response.data.data.break_hours)

                if( response.data.data.additional_hours == 0  ){
                  setYes(false)
                  setNo(true)
                } else{
                  setYes(true)
                  setNo(false)
                }
        
                if( response.data.data.additional_salary_type == 1  ){
                  setSalaryType(true)
                  setFlat(true)
                  setFactor(false)
                } else{
                  setSalaryType(false)
                  setFlat(false)
                  setFactor(true)
                }
                setAdditionalData(response.data.data.additional_hours == 0 ? false : true)
                setAdditionalFromTime(response.data.data.additional_from_time);
                setAdditionalToTime(response.data.data.additional_to_time);
                setSalaryType(response.data.data.additional_salary_type);
                setAdditionalTailorSalary(response.data.data.additional_tailor_salary);
                setAdditionalHelperSalary(response.data.data.additional_helper_salary);
                // toggle();
              } else{
              // toggle();
            }
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
    };
    
    /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
    // function handleKeyPress(e, data) {
    //   var key = e.key;
    //   if( data == "factoryRevenueFactor"){ 
    //     var regex = /[.0-9]/; 
    //   }else{ 
    //     var regex = /[.0-9]/; 
    //   }
    //   if( !regex.test(key) ) {
    //       e.preventDefault();
    //   }
    // }

    const handleKeyPress = (event) => {
      const keyCode = event.which || event.keyCode;
      const currentValue = event.target.value + String.fromCharCode(keyCode);
  
      if (parseInt(currentValue) < 1) {
        event.preventDefault();
      }
    };
    function handleKeyPressPer(e, data) {
      var key = e.key;
      if( data == "factoryRevenueFactor"){ 
        var regex = /[1-9]/; 
      }else{ 
        var regex = /[0-9]/; 
      }
      if( !regex.test(key) ) {
          e.preventDefault();
      }
    }
    const validation = () => {
      let errors = {};
      if (!styleNo) {
        errors.styleNo = t("styleNoValidationErrorMessage");
      }
      if (!productionType) {
        errors.productionType = t("plsSelProductionType");
      }
      if (!shiftValue) {
        errors.shiftValue = t("plsSelShift");
      }
      if (!unitValue) {
        errors.unitValue = t("plsSelUnit");
      }
      if (!samValue) {
        errors.samValue = t("plsSelSamVal");
      }
      if (!supervisorName) {
        errors.supervisorName = t("plsSelSupervisorName");
      }
      if (!lineNoValue) {
        errors.lineNoValue = t("plsSelLineNo");
      }
      
      if (!noOfTailors) {
        errors.noOfTailors = t("plsEnterNoofTailors");
      }
      if (!noOfHelpers) {
        errors.noOfHelpers = t("plsEnterNoofHelpers");
      }
      if (!tailorSalary) {
        errors.tailorSalary = t("plsEnterTailorSalry");
      }
      if (!helperSalary) {
        errors.helperSalary = t("plsEnterHelperSalry");
      }
      if(breakFreeStatus === false){
        if (!breakTime) { errors.breakTime = t("plsSelBrkTime"); }
      }
      // if(factoryRevenueFactor){
      //   if (!factoryRevenueFactor) { errors.factoryRevenueFactor = "Please Enter Factory Revenue Factor"; }
      // }
      // if(alertPercentage){
      //   if (!alertPercentage) { errors.alertPercentage = "Please Enter Alert Percentage"; }
      // }

      setValidErrors(errors);
      return errors;
    };

    const checkBoxValues = ( name ) => {
        var cbs = document.getElementsByName("checkBox");
        for (var i = 0; i < cbs.length; i++) {
            cbs[i].checked = false;
        }
        document.getElementById(name).checked = true;
        if( name == 'yes' ){
            setAdditionalData(true);
        } else {
            setAdditionalData(false);
        }
    };

    const clearValues = ( data ) => {
        if( data == "yes"){
          document.getElementById("styleNo").selectedIndex=0;
        }
        document.getElementById("productionType").selectedIndex=0;
        document.getElementById("shiftValue").selectedIndex=0;
        document.getElementById("unitValue").selectedIndex=0;
        document.getElementById("samValue").value="";
        document.getElementById("supervisorName").selectedIndex=0;
        document.getElementById("lineNoValue").selectedIndex=0;
        document.getElementById("noOfTailors").value="";
        document.getElementById("noOfHelpers").value="";
        document.getElementById("tailorSalary").value="";
        document.getElementById("helperSalary").value="";
        document.getElementById("fromTime").value="00:00";
        document.getElementById("toTime").value="00:00";
        document.getElementById("breakTime").selectedIndex=0;

        if( data == "yes"){
          setStyleNo(() => "");
        }
        setProductionType(() => "");
        setShiftId(() => "");
        setShiftValue(() => "");
        setUnitId(() => "");
        setUnitValue(() => "");
        setSamValue(() => "");
        setSupervisorId(() => "");
        setSupervisorName(() => "");
        setLineNoValue(() => "");
        setNoOfTailors(() => "");
        setNoOfHelpers(() => "");
        setTailorSalary(() => "");
        setHelperSalary(() => "");
        setFromTime(() => "");
        setToTime(() => "");
        setBreakFreeStatus(false)
        setBreakTime(() => "");
        setFactoryRevenueFactor(() => "");
        setAlertPercentage(() => "");

        setYes(() => "")
        setNo(true)
        setSalaryType(() => "")
        setFlat(() => "")
        setFactor(() => "")

        setAdditionalData(() => "")
        setAdditionalFromTime(() => "")
        setAdditionalToTime(() => "")
        setAdditionalTailorSalary(() => "")
        setAdditionalHelperSalary(() => "")

        setArrayList(() => "");
        setValidErrors(() => "");
    };

    const onhandleSubmit = ( ) => {
      let retval = validation();
      if (Object.keys(retval).length == 0) {

        var apiInputParams = {};
        apiInputParams['company_id'] = getLoginCompanyId;
        apiInputParams['workspace_id'] = getWorkspaceId;
        apiInputParams['user_id'] = getLoginUserId;
        apiInputParams['staff_id'] = getLoginStaffId;
        
        apiInputParams['order_id'] = orderId;
        apiInputParams['order_no'] = orderNo;

        apiInputParams['style_no'] = styleNo;
        apiInputParams['production_type'] = productionType;
        apiInputParams['shift_id'] = shiftId;
        apiInputParams['shift_value'] = shiftValue;
        apiInputParams['unit_id'] = unitId;
        apiInputParams['unit_value'] = unitValue;
        apiInputParams['sam_value'] = samValue;
        apiInputParams['supervisor_id'] = supervisorId;
        apiInputParams['supervisor_name'] = supervisorName;
        apiInputParams['line_no_id'] = lineNoId;
        apiInputParams['line_no_value'] = lineNoValue;
        apiInputParams['no_of_tailors'] = noOfTailors;
        apiInputParams['no_of_helpers'] = noOfHelpers;
        apiInputParams['tailor_salary'] = tailorSalary;
        apiInputParams['helper_salary'] = helperSalary;
        apiInputParams['report_date'] = currentDate;
        apiInputParams['from_time'] = fromTime;
        apiInputParams['to_time'] = toTime;
        apiInputParams['break_hours'] = breakFreeStatus === true ? 0 : breakTime;
        apiInputParams['factory_factor'] = factoryRevenueFactor;
        apiInputParams['alert_percentage'] = alertPercentage;
        apiInputParams['additional_hours'] = additionalData === true ? 1 : 0;

        if( additionalData === true ){
          apiInputParams['additional_from_time'] = additionalFromTime ;
          apiInputParams['additional_to_time'] = additionalToTime;
          apiInputParams['additional_salary_type'] = salaryType === true ? 1 : 2;
          apiInputParams['additional_tailor_salary'] = additionalTailorSalary;
          apiInputParams['additional_helper_salary'] = additionalHelperSalary;
        }

        axios
        .post(ServerUrl + "/save-sam-report-settings", apiencrypt(apiInputParams))
        .then((response) => {
            response.data = apidecrypt(response.data);
            if (response.data.status_code === 200) {
              Swal.fire({
                title: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                timer: 5000
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    //  setTimeout(() => {
                       window.location.href = "/viewsamdetails";
                    //  }, 100);
                  }
                  setTimeout(() => {
                    window.location.href = "/viewsamdetails";
                  }, 100);
                });
            }
            if (response.data.status_code === 201) {
              Swal.fire({
                title: t(response.data.message),
                icon: "warning",
                button: t("okLabel"),
              })
            }
        })
      }
    };
  return (
    <Fragment>
        <Row className="pgbgcolor">
            <Breadcrumbs 
              mainTitle="Add SAM Details"
              parent="Add SAM Details"
              title="Add SAM Details"
              subTitle="Add SAM Details" 
            />
        </Row>
        <Container fluid={true} className="general-widget topaln">
        <Card>
          <CardBody>
            <Form>          
                <Row className="g-12">
                    <Col lg="4" md="4" sm="12">
                        <FormGroup>
                        <Label>{t("styleOrderNo")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                            <Input
                              style={{  cursor: 'pointer' }}
                              className={`${errors.styleNo && 'select-error-valid'}  form-control`}
                              name="styleNo"
                              id="styleNo"
                              type="select"
                              placeholder={t("selectStyleno")}
                              onChange={(e) => {
                                clearValues( "no" );
                                var splitData = (e.target.value).split("&&");
                                setOrderId(splitData[0]);
                                setOrderNo(splitData[1]);  
                                setStyleNo(splitData[2]);
                                getPreviousSamReportSettingApiCall(splitData[2],splitData[0]);  
                              }}
                            >
                            <option disabled selected>{t("selectStyleno")}</option>
                            { styleNoList.length > 0 ?
                              styleNoList.map((styleList) => 
                              (
                              <option 
                                onClick={() => { }}
                                key={styleList.id}
                                value={styleList.id + "&&" + styleList.order_no + "&&" + styleList.style_no}>
                                {styleList.style_no} 
                                ({styleList.order_no})
                              </option>
                              ))
                                :
                                ""
                            }
                            </Input>
                            {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                        </InputGroup>
                        {errors.styleNo && (
                            <span className="error-msg">{t(errors.styleNo)}</span>
                        )}
                     
                        </FormGroup>
                    </Col> 
                    <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                        <Label>{t("productionType")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                            <Input
                            style={{  cursor: 'pointer' }}
                            className={`${errors.productionType && 'select-error-valid'}  form-control`}
                            name="productionType"
                            id="productionType"
                            type="select"
                            placeholder={t("selectProductionType")}
                            onChange={(e) => {setProductionType(e.target.value)}}>
                            <option disabled selected>{t("selectProductionType")}</option>
                            { productionTypeList.length > 0 ?  
                                  productionTypeList.map((data,i) => (
                                  productionType == data ? 
                                  <option selected key={i} value={data}>{data}</option>
                                  :
                                  <option key={i} value={data}>{data}</option>
                            ))
                            :
                            ""} 
                            </Input>
                        </InputGroup>
                        {errors.productionType && (
                            <span className="error-msg">{t(errors.productionType)}</span>
                        )} 
                        </FormGroup>
                    </Col> 
                    <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                        <Label>{t("shift")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                            <Input
                            style={{  cursor: 'pointer' }}
                            className={`${errors.shiftValue && 'select-error-valid'}  form-control`}
                            name="shiftValue"
                            id="shiftValue"
                            type="select"
                            placeholder={t("selectShift")}
                            onChange={(e) => 
                            {
                              var splitData = (e.target.value).split("&&");
                              setShiftId(splitData[0]);
                              setShiftValue(splitData[1]);
                              setFromTime(splitData[2]);
                              setToTime(splitData[3]);
                            }}
                            >
                            <option disabled selected>{t("selectShift")}</option>
                            { getMasterData.length > 0 ?
                              getMasterData
                              .filter(data => data.type == "Shift")
                              .map(mapData => (
                                <>
                                  {shiftId == mapData.id ?
                                    <option 
                                      key={mapData.id}
                                      selected 
                                      value={mapData.id +"&&"+ mapData.value+"&&"+mapData.shift_from_time+"&&"+mapData.shift_end_time}>
                                      {mapData.value}
                                    </option>
                                  :
                                    <option 
                                      key={mapData.id}
                                      value={mapData.id +"&&"+ mapData.value+"&&"+mapData.shift_from_time+"&&"+mapData.shift_end_time}>
                                      {mapData.value}
                                    </option>
                                }
                                </>
                              ))
                              :
                              ""
                            }
                            </Input>
                            {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                            <InputGroupText>
                                <span
                                className="btn selectbtn"
                                onClick={toggleAddShift}>
                                +
                                </span>
                            </InputGroupText> 
                            {/* : "" */}
                            {/* } */}
                            <AddShiftModal
                              modal={modalAddShift}
                              setModalAddShift={setModalAddShift}
                              toggle={toggleAddShift}

                              setGetMasterData={setGetMasterData}

                              setProductionTypeList={setProductionTypeList} 
                              setBreakTimeList={setBreakTimeList}
                            /> 
                        </InputGroup>
                        {errors.shiftValue && (
                            <span className="error-msg">{t(errors.shiftValue)}</span>
                        )}
                        </FormGroup>
                    </Col> 
                </Row>

                <Row className="g-12">
                      <Col lg="4" md="4" sm="12">
                        <FormGroup>
                          <Label>{t("unit")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              style={{  cursor: 'pointer' }}
                              className={`${errors.unitValue && 'select-error-valid'}  form-control`}
                              name="unitValue"
                              id="unitValue"
                              type="select"
                              onChange={(e) => {
                                // clearValues();
                                var splitData = (e.target.value).split("&&");
                                setUnitId(splitData[0]);
                                setUnitValue(splitData[1]);
                              }}
                            >
                              <option disabled selected>{t("selectUnit")}</option>
                            { getMasterData.length > 0 ?
                              getMasterData
                              .filter(data => data.type == "Unit")
                              .map(mapData => (
                                <>
                                  {
                                    unitId == mapData.id ?
                                      <option 
                                        key={mapData.id}
                                        selected
                                        value={mapData.id +"&&"+ mapData.value}>
                                        &nbsp;
                                        &nbsp;
                                        {mapData.value}
                                      </option>
                                  :
                                    arrayList.includes(mapData.id) ? 
                                      <option 
                                        key={mapData.id}
                                        value={mapData.id +"&&"+ mapData.value}>
                                        &nbsp;
                                        &nbsp;
                                        {mapData.value}
                                      </option>
                                    :
                                    <option 
                                      key={mapData.id}
                                      value={mapData.id +"&&"+ mapData.value}>
                                      &nbsp;
                                      &nbsp;
                                      {mapData.value}
                                    </option>
                                  }
                                </>
                              ))
                              :
                              ""
                            }
                            </Input>
                            {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={toggleAddUnit}
                                  >
                                  +
                                </span>
                              </InputGroupText> 
                              {/* : "" */}
                            {/* } */}
                            <AddUnitModal
                                modal={modalAddUnit}
                                setModalAddShift={setModalAddUnit}
                                toggle={toggleAddUnit}

                                setGetMasterData={setGetMasterData}

                                setProductionTypeList={setProductionTypeList} 
                                setBreakTimeList={setBreakTimeList}
                            /> 
                          </InputGroup>
                          {errors.unitValue && (
                            <span className="error-msg">{t(errors.unitValue)}</span>
                          )}
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("samValue")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.samValue && 'select-error-valid'}  form-control`}
                              name="samValue"
                              id="samValue"
                              type="text"
                              value={samValue}
                              placeholder={t("plsEnterSamValue")}
                              onChange={(e) => {setSamValue(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                          {errors.samValue && (
                            <span className="error-msg">{t(errors.samValue)}</span>
                          )}
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("supervisor")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              style={{  cursor: 'pointer' }}
                              className={`${errors.supervisorName && 'select-error-valid'}  form-control`}
                              name="supervisorName"
                              id="supervisorName"
                              type="select"
                              placeholder={t("selectSupervisor")}
                              onChange={(e) => {
                                var splitData = (e.target.value).split("&&");
                                setSupervisorId(splitData[0]);
                                setSupervisorName(splitData[1]);
                              }}
                            >
                              <option disabled selected >{t("selectSupervisor")}</option>
                              { getMasterData.length > 0 ?
                                getMasterData
                                .filter(data => data.type == "Supervisor")
                                .map(mapData => (
                                  <>
                                  {
                                     supervisorId == mapData.id ?
                                    <option 
                                      key={mapData.id}
                                      selected
                                      value={mapData.id +"&&"+ mapData.value}>
                                      {mapData.value}
                                    </option>
                                    :
                                    <option 
                                      value={mapData.id +"&&"+ mapData.value}>
                                      {mapData.value}
                                    </option>
                                  }
                                  </>
                                ))
                              :
                              ""
                            }
                            </Input>
                            {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={toggleAddSupervisor}
                                  >
                                  +
                                </span>
                              </InputGroupText> 
                              {/* : "" */}
                            {/* } */}
                            <AddSupervisorModal
                                modal={modalAddSupervisor}
                                toggle={toggleAddSupervisor}
                   
                                setGetMasterData={setGetMasterData}

                                setProductionTypeList={setProductionTypeList} 
                                setBreakTimeList={setBreakTimeList}
                            />
                          </InputGroup>
                          {errors.supervisorName && (
                            <span className="error-msg">{t(errors.supervisorName)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                </Row>

                <Row className="g-12">
                      <Col lg="4" md="4" sm="12">
                        <FormGroup>
                          <Label>{t("lineNo")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              style={{  cursor: 'pointer' }}
                              className={`${errors.lineNoValue && 'select-error-valid'}  form-control`}
                              name="lineNoValue"
                              id="lineNoValue"
                              type="select"
                              placeholder={t("selectLineNo")}
                              onChange={(e) => {
                                // clearValues();
                                var splitData = (e.target.value).split("&&");
                                setLineNoId(splitData[0]);
                                setLineNoValue(splitData[1]);
                                // test(styleNo,splitData[0] )
                              }}
                            >
                              <option disabled selected>{t("selectLineNo")}</option>
                              { getMasterData.length > 0 ?
                              //  getMasterData
                                // .filter(data => data.type == "Line")
                                // .map(mapData => {
                                //   ((unitId == mapData.line_unit)) 
                                //   ? 
                                //   console.log(unitId, unitValue, "mapData", mapData)
                                //   :
                                //     ""
                                //   })
                                getMasterData
                                .filter(data => data.type == "Line")
                                .map(mapData => (
                                  ((unitId == mapData.line_unit) && ( mapData.line_unit != 0)) 
                                  ? 
                                  <>
                                    {
                                      lineNoId == mapData.id ?
                                      <option 
                                        key={mapData.id}
                                        selected
                                        value={mapData.id +"&&"+ mapData.value}>
                                        {mapData.value}
                                      </option>
                                    :
                                    <option 
                                      value={mapData.id +"&&"+ mapData.value}>
                                      {mapData.value}
                                    </option>
                                    }
                                  </>
                                  :
                                    ""
                                ))
                              :
                              ""
                            }
                            </Input>
                            {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={()=>{setModalAddLineNo(!modalAddLineNo)}}
                                  >
                                  +
                                </span>
                              </InputGroupText> 
                              {/* : "" */}
                            {/* } */}
                            <AddLineNoModal
                                modal={modalAddLineNo}
                                toggle={toggleAddLineNo}

                                getMasterData={getMasterData}
                                setGetMasterData={setGetMasterData}

                                setProductionTypeList={setProductionTypeList} 
                                setBreakTimeList={setBreakTimeList}
                            />
                          </InputGroup>
                          {errors.lineNoValue && (
                            <span className="error-msg">{t(errors.lineNoValue)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("noOfTailors")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.noOfTailors && 'select-error-valid'}  form-control`}
                              name="noOfTailors"
                              id="noOfTailors"
                              type="text"
                              placeholder={t("plsEnterNoOfTailors")}
                              value={noOfTailors}
                              onChange={(e) => {setNoOfTailors(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                          {errors.noOfTailors && (
                            <span className="error-msg">{t(errors.noOfTailors)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label> {t("noOfHelpers")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.noOfHelpers && 'select-error-valid'}  form-control`}
                              name="noOfHelpers"
                              id="noOfHelpers"
                              type="text"
                              value={noOfHelpers}
                              placeholder={t("plsEnterNoOfHelpers")}
                              onChange={(e) => {setNoOfHelpers(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                          {errors.noOfHelpers && (
                            <span className="error-msg">{t(errors.noOfHelpers)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                </Row>

                <Row className="g-12">
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label> {t("tailorSalary")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.tailorSalary && 'select-error-valid'}  form-control`}
                              name="tailorSalary"
                              id="tailorSalary"
                              type="text"
                              value={tailorSalary}
                              placeholder={t("plsEnterTailorSalary")}
                              onChange={(e) => {setTailorSalary(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                          {errors.tailorSalary && (
                            <span className="error-msg">{t(errors.tailorSalary)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("helperSalary")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.helperSalary && 'select-error-valid'}  form-control`}
                              name="helperSalary"
                              id="helperSalary"
                              type="text"
                              placeholder={t("plsEnterHelperSalary")}
                              value={helperSalary}
                              onChange={(e) => {setHelperSalary(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                          {errors.helperSalary && (
                            <span className="error-msg">{t(errors.helperSalary)}</span>
                          )}
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label> {t("date")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className="toggleButton"
                              type="date"
                              placeholder={t("date")}
                              disabled
                              value={currentDate}
                            >
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col> 
                </Row>
 
                <Row className="g-12">
                      <Col lg="4" md="4" sm="12">
                        <FormGroup>
                          <Label> {t("fromTime")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              disabled
                              className="form-control"
                              name="fromTime"
                              id="fromTime"
                              type="time"
                              placeholder={t("fromTime")}
                              value={fromTime}
                              onChange={(e) => {setFromTime(e.target.value)}}
                            >
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup>
                          <Label> {t("toTime")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                                disabled
                                className="form-control"
                                name="toTime"
                                id="toTime"
                                type="time"
                                placeholder={t("toTime")}
                                value={toTime}
                                onChange={(e) => {setToTime(e.target.value)}}>
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <Row>
                          <Col lg="3" md="3" sm="12">
                            <Label> {t("breakFree")} </Label>
                            <FormGroup>
                              <Media key="1">
                                <Media body className="text-start icon-state switch-md">
                                  <Label className="switch" name="toggle">
                                    <Input className="toggleButton"
                                      type="checkbox" name="tolisreq"
                                       defaultChecked={breakFreeStatus}
                                       value={breakFreeStatus} 
                                       checked={!!breakFreeStatus}
                                       onChange={(e) => { 
                                        setBreakFreeStatus(prevCheck => !prevCheck) 
                                        }} />
                                    <span className="switch-state"></span>
                                  </Label>
                                </Media>
                              </Media>
                            </FormGroup>
                          </Col>
                          {  breakFreeStatus === false ? 
                            <Col lg="9" md="9" sm="12">
                              <FormGroup>
                              <Label>{t("breakTime")}</Label><sup className="font-danger">*</sup>
                              <InputGroup>
                                  <Input
                                  style={{  cursor: 'pointer' }}
                                  className={`${errors.breakTime && 'select-error-valid'}  form-control`}
                                  name="breakTime"
                                  id="breakTime"
                                  type="select"
                                  placeholder={t("selectBreakTime")}
                                  onChange={(e) => {setBreakTime(e.target.value)}}
                                  >
                                  <option disabled selected >{t("selectBreakTime")}</option>
                                  { breakTimeList.length > 0 ?  
                                  breakTimeList.map((data ,j) => (
                                    <>
                                    { breakTime == data ?
                                      <option key={j} selected value={data}>{data}</option>
                                      :
                                      <option key={j}  value={data}>{data}</option>
                                    }
                                    </>
                                  )
                                  )
                                  :
                                  ""}
                                  </Input>
                              </InputGroup>
                              {errors.breakTime && (
                                  <span className="error-msg">{t(errors.breakTime)}</span>
                              )} 
                              </FormGroup>
                            </Col>
                            :
                            ""
                          }
                        </Row>
                      </Col>
                </Row>

                <Row>
                <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("factRevFactor")}</Label>
                          <InputGroup>
                            <input
                              className={`${errors.factoryRevenueFactor && 'select-error-valid'}  form-control`}
                              name="factoryRevenueFactor"
                              id="factoryRevenueFactor"
                              type="number"
                              min={1}
                              value={factoryRevenueFactor}
                              placeholder={t("plsEnterFactRevFactor")}
                              onChange={(e) => {setFactoryRevenueFactor(e.target.value)}}
                               onKeyPress={(e) => {handleKeyPress(e, "factoryRevenueFactor")}}
                            >
                            </input>
                          </InputGroup>
                          {errors.factoryRevenueFactor && (
                            <span className="error-msg">{t(errors.factoryRevenueFactor)}</span>
                          )}  
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("alertPerc")}</Label>
                          <InputGroup>
                            <Input
                              className={`${errors.alertPercentage && 'select-error-valid'}  form-control`}
                              name="alertPercentage"
                              id="alertPercentage"
                              type="number"
                              placeholder={t("plsEnterAlertPerc")}
                              value={alertPercentage}
                              maxLength="7"
                              onChange={(e) => {setAlertPercentage(e.target.value)}}
                              onKeyPress={(e) => {handleKeyPressPer(e)}}
                            >
                            </Input>
                            <InputGroupText className="input-group-symbol"> 
                              <i className="fa fa-percent m-l-10 m-t-5"></i>
                            </InputGroupText> 
                          </InputGroup>
                          {errors.alertPercentage && (
                            <span className="error-msg">{t(errors.alertPercentage)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("accessoriesValuePerGMT")}</Label>
                          <InputGroup>
                            <Input
                              className={`${errors.accessoriesValue && 'select-error-valid'}  form-control`}
                              name="accessoriesValue"
                              id="accessoriesValue"
                              type="number"
                              placeholder={t("plsEnterAccessoriesValue")}
                              value={accessoriesValue}
                              maxLength="7"
                              onChange={(e) => {setAccessoriesValue(e.target.value)}}
                              onKeyPress={(e) => {handleKeyPressPer(e)}}
                            >
                            </Input>
                            
                          </InputGroup>
                          {errors.accessoriesValue && (
                            <span className="error-msg">{t(errors.accessoriesValue)}</span>
                          )} 
                        </FormGroup>
                      </Col> 
                </Row>

                <Row>
                  <Col>
                    <Row className="g-12" style={{ width: '35%' }}>
                        <Label className="m-t-10"> {t("additionalHours")} </Label>
                        <Col xl="6" lg="6" md="6" sm="12" xs="12"className="checkbox checkbox-primary">
                            <Input
                                type="checkbox"
                                className="form-check-input"
                                name="checkBox"
                                value="yes"
                                id="yes"
                                defaultChecked={yes}
                                onChange={ (e) => {checkBoxValues('yes')}}
                            />
                            <Label
                                for="yes"
                                className="form-label">
                                {t("Yes")}
                            </Label>
                        </Col>
                        <Col xl="6" lg="6" md="6" sm="12" xs="12"className="checkbox checkbox-primary">
                                <Input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="checkBox"
                                    value=""
                                    id="no"
                                    defaultChecked={no}
                                    onChange={ (e) => {checkBoxValues('no')}}
                                />
                                <Label
                                    for="no"
                                    className="form-label">
                                      {t("NO")}
                                </Label>
                          
                        </Col>
                    </Row>
                  </Col>

                  { additionalData === true ? 
                    <Col>
                      <Row className="g-12" style={{ width: '35%' }}>
                            <Label className="m-t-10">  {t("salaryType")}  </Label>
                            <Col lg="6" md="6">
                              <Label className="m-t-10 m-l-5">
                                  <div className="radio radio-primary" >
                                      <Input  id="radioinline1" type="radio" 
                                              name="radio1" value="option1" 
                                              onChange={() => { setSalaryType(!salaryType)}}
                                              defaultChecked={flat} 
                                      />
                                      <Label className="mb-0" for="radioinline1">{Option}
                                          <span className="digits"> {t("flat")} </span>
                                      </Label>
                                  </div>
                              </Label>
                            </Col>
                            <Col lg="6" md="6">
                              <Label className="m-t-10">
                                <div className="radio radio-primary">
                                    <Input  id="radioinline2" type="radio" 
                                            name="radio1" value="option1" 
                                            defaultChecked={factor} 
                                            onChange={() => { setSalaryType(!salaryType)}}
                                    />
                                    <Label className="mb-0" for="radioinline2">{Option}
                                        <span className="digits"> {t("factor")}  </span>
                                    </Label>
                                </div>
                              </Label>
                            </Col>
                      </Row>
                    </Col>
                    :
                    "" }
                </Row>

                { additionalData === true ? 
                  <>
                      <Row className="g-12 m-t-20">
                          <Col lg="3" md="4" sm="12">
                          <FormGroup className="">
                              <Label> {t("fromTime")} </Label><sup className="font-danger">*</sup>
                              <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                  className="form-control digits"
                                  type="time"
                                  value={additionalFromTime}
                                  onChange={(e) => {
                                    setAdditionalFromTime(e.target.value)
                                  }}
                              >
                              </Input>
                              </InputGroup>
                          </FormGroup>
                          </Col> 

                          <Col lg="3" md="4" sm="12">
                              <FormGroup className="">
                                  <Label> {t("toTime")}  </Label><sup className="font-danger">*</sup>
                                  <InputGroup>
                                  <Input
                                  //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                      className="form-control digits"
                                      type="time"
                                      value={additionalToTime}
                                      onChange={(e) => {
                                        setAdditionalToTime(e.target.value)
                                      }}
                                  >
                                  </Input>
                                  </InputGroup>
                                  
                              </FormGroup>
                          </Col> 

                          <Col lg="3" md="4" sm="12">
                            <FormGroup>
                                <Label> {t("tailorSalary")} </Label>
                                <sup className="font-danger">*</sup>
                                <InputGroup>
                                      <Input
                                      //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                          type="text"
                                          value={additionalTailorSalary}
                                          onChange={(e) => { setAdditionalTailorSalary(e.target.value) }}>
                                      </Input>
                                  {salaryType === false ? 
                                    <InputGroupText className="input-group-symbol"> 
                                       <img src={factorIcon}  width="30px"/>
                                    </InputGroupText> 
                                    :
                                    <InputGroupText className="input-group-symbol"> 
                                        <img src={flatIcon} width="30px" />
                                    </InputGroupText> 
                                  }
                                </InputGroup>
                            </FormGroup>
                          </Col> 

                          <Col lg="3" md="4" sm="12">
                            <FormGroup className="">
                                <Label>  {t("helperSalary")}  </Label><sup className="font-danger">*</sup>
                                <InputGroup>
                                <Input
                                //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                    type="text"
                                    value={additionalHelperSalary}
                                    placeholder={t("plsEnterHelperSalary")}
                                    onChange={(e) => {setAdditionalHelperSalary(e.target.value)}}
                                >
                                </Input>
                                {salaryType === false ? 
                                      <InputGroupText className="input-group-symbol"> 
                                        <img src={factorIcon}  width="30px"/>
                                      </InputGroupText> 
                                  :
                                      <InputGroupText className="input-group-symbol"> 
                                          <img src={flatIcon} width="30px" />
                                      </InputGroupText> 
                                  }
                                </InputGroup>
                            </FormGroup>
                          </Col> 
                      </Row>
                  </>
                :
                ""
                }
            
                <Row className="f-right m-t-10">
                    <Col>
                        <Button
                        className="btn btn-primary m-r-20 secondaryBtn"
                        onClick={() =>  {clearValues("yes")}}>
                        {t("clear")}
                        </Button>
            
                        <Button
                          className="btn btn-primary"
                          onClick={() => {
                            onhandleSubmit()
                            }}>
                        {t("saveAndContinue")}
                        </Button>
                    </Col>
                </Row>

            </Form>
          </CardBody>
        </Card>
        </Container>
        <AutofillDataModal 
            modal={modalAutofillData}
            toggle={toggleAutofillData}
            
            orderNo={orderNo}
            styleNo={styleNo}
            unitId={unitId}
            
            setStyleNo={setStyleNo} 
            setShiftValue={setShiftValue}
            setSupervisorName={setSupervisorName}
            setProductionType={setProductionType}
            setShiftId={setShiftId}
            setSamValue={setSamValue}
            setSupervisorId={setSupervisorId}
            setNoOfTailors={setNoOfTailors}
            setNoOfHelpers={setNoOfHelpers}
            setTailorSalary={setTailorSalary}
            setHelperSalary={setHelperSalary}
            setFromTime={setFromTime}
            setToTime={setToTime}
            setBreakFreeStatus={setBreakFreeStatus}
            setBreakTime={setBreakTime}
            setFactoryRevenueFactor={setFactoryRevenueFactor}
            setAlertPercentage={setAlertPercentage}
            
            setYes={setYes}
            setNo={setNo}
            setFlat={setFlat}
            setFactor={setFactor}
            setAdditionalData={setAdditionalData}
            setAdditionalFromTime={setAdditionalFromTime}
            setAdditionalToTime={setAdditionalToTime}
            setSalaryType={setSalaryType}
            setAdditionalTailorSalary={setAdditionalTailorSalary}
            setAdditionalHelperSalary={setAdditionalHelperSalary}

            setUnitId={setUnitId}
            setUnitValue={setUnitValue}
            setLineNoId={setLineNoId}
            setLineNoValue={setLineNoValue}

            getPreviousSamReportDetails={getPreviousSamReportDetails}

        />  
    </Fragment>
  );
};
export default AddSAMDetails;
/* Code By : Ramanan M */