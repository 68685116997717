import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  FormGroup,
  Table,
} from "reactstrap";
import { H6, Btn } from "../../../AbstractElements";
import OrderHeaders from "./OrderHeaders";
import factory from "../../../assets/images/dms/factory.png";
import pcu from "../../../assets/images/dms/pcu.png";
import buyer from "../../../assets/images/dms/onGoingList.svg";
import userimg from "../../../assets/images/dms/user.png";

/****************************************************************************************************/
import AddStaffModal from "./AddStaffModal";
import Swal from "sweetalert2";
import axios from "axios";
import { ServerUrl } from "../../../Constant";
import { useSearchParams } from "react-router-dom";
import { encode, decode, apiencrypt, apidecrypt } from "../../../helper";
import {
  getLoginCompanyId,
  getLoginUserId,
  getLoginStaffId,
  getWorkspaceId,
  getStaff,
  getStaffPermission,
} from "../../../Constant/LoginConstant";

import { useTranslation } from "react-i18next";

/************************************************************************************************/

const AddContacts = (props) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [contactList, setContactList] = React.useState([]);
  const [basicInformation, setBasicInformation] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [roles, setRole] = React.useState([]);
  const [allowplanvalidation, setallowplanvalidation] = React.useState();
const [workspace_type,setworkspace_type]=useState([]);
  /********************* [INPUT PARAMS for API Calls] (To add Order contacts)*******************/
  var getInputParams = {};
  getInputParams["company_id"] = getLoginCompanyId;
  getInputParams["workspace_id"] = getWorkspaceId;
  getInputParams["order_id"] = orderId;
  getInputParams["page_type"] = "order_contacts";

  const inputParamsUser = {};
  inputParamsUser['company_id'] = getLoginCompanyId;
  inputParamsUser['workspace_id'] = getWorkspaceId;
  inputParamsUser['user_id'] = getLoginUserId;
  inputParamsUser['type'] = 'Staff';

  useEffect(() => {

    axios.post(ServerUrl + '/validate-plan', apiencrypt(inputParamsUser))
    .then((response) => {
      response.data = apidecrypt(response.data);
      if (response.data.status_code === 400) {
        setallowplanvalidation("expired");
        // Swal.fire({
        //   title: response.data.error,
        //   icon: "warning",
        //   button: t("okLabel"),
        //   allowOutsideClick: false
        // })
        //   .then((result) => {
        //     if (result.isConfirmed) {
        //      // window.location.href = '/staff';
        //     }
        //   })
      }
    });
    axios.post(ServerUrl + "/get-staffs", apiencrypt(getInputParams)).then((response) => {
      response.data = apidecrypt(response.data);
      setContactList(response.data.data);
    });
    axios.get(ServerUrl +"/workspace-type")
    .then((response) => {
      response.data = apidecrypt(response.data)
      setworkspace_type(response.data.data);
    });
    axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setBasicInformation(response.data.data);
        if(response.data.data[0].status != 1 )
        {
          window.location.href = "/dashboard" 
        }
        else {
          ""
        }
      });
      axios
      .post(ServerUrl + "/get-roles", apiencrypt(getInputParams ))
      .then(( response) => {
        response.data=apidecrypt(response.data)
        setRole(response.data.data);
      });
      
      {
        (((getStaff === "Staff" && getStaffPermission.includes('Add Order'))||getStaff == null )) 
        ?
        ""
        :
        window.location.href = "/dashboard" 
      }
   
  }, []);

  /********************* [To Filter the CHECKED contacts from the list ] **********************/
  function getChecked(staffListData) {
    var staff_arr = {};
    var staff_arr1 = [];
    var j = 0;
    var checkboxes = document.querySelectorAll("input[type=checkbox]");
    const lengthv = staffListData.contactList.length;
    getInputParams["contacts"] = staff_arr1;

    for (var i = 0; i < lengthv; i++) {
      var checkBox = checkboxes[i];
      if (checkBox.checked) {
        staff_arr.staff_id = checkBox.id;
        staff_arr1[j] = staff_arr;
        staff_arr = {};
        j++;
      }
    }

    if (j == 0) {
      Swal.fire({
        title: t("staffDetails"),
        text: t("selectStaffValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    } else {
      //////////////////////////////////////////////////////////////////////////////////////////////////////////
      /********************* API CALLS [Contact Added successfully]********************************************/
      axios
        .post(ServerUrl + "/add-order-contacts", apiencrypt(getInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          /************** RESPONSE - 200 ( Company registerd successfully) ********************************/
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer: 5000
            }).then((result) => {
              if (result.isConfirmed) {
                // setTimeout(() => {
                  window.location.href = '/ordertask?id=' + encode(orderId) 
                // }, 100);
              }
              setTimeout(() => {
                window.location.href = '/ordertask?id=' + encode(orderId) 
              }, 100);
              
            });
          } else if (response.data.status_code === 401) {
            if (response.data.error.contacts) {
              Swal.fire({
                title: t("selectCheckBoxValidation"),
                text: t("fieldMissing"),
                icon: "warning",
                button: t("okLabel"),
              });
            }
          }
        });
      //////////////////////////////////////////////////////////////////////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////////
    }
  }

  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody>
            <OrderHeaders title="addcontact"/>
          </CardBody>

          <CardBody>
            <Form className="needs-validation" noValidate="">
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  {/* <H6>SKU Details</H6> */}
                  <Row className="g-12">
                    <div className="table-responsive">
                      {basicInformation.map((information,i) => (
                        <Table key={i} className="shadow-sm shadow-showcase">
                          <thead >
                            <tr
                              className="table-active"
                              // style={{ backgroundColor: '#abcdef' }}
                            >
                              <th scope="col">
                                <span className="m-l-10">
                                {t("basicInformation")}
                                </span>
                              </th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col" className="text-right"></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr >
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item m-l-10">
                                      {" "}
                                      {t("orderNo")}
                                    </li>
                                  </ul>
                                  <h6 className="mt-0 m-l-10">
                                    {information.order}
                                  </h6>
                                </div>
                              </td>
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item">
                                      {" "}
                                      {t("styleNo")}
                                    </li>
                                  </ul>
                                  <h6 className="mt-0">{information.style}</h6>
                                </div>
                              </td>
                              <td>
                                <img src={userimg} alt={t("users")} />
                                <span>
                                  {" "}
                                  <b>{information.workspace}</b>
                                </span>
                              </td>
                              {information.factory != null ? (
                                <td>
                                  <img src={factory} alt={t("factory")} />
                                  <span>
                                    {" "}
                                    <b> {information.factory}</b>
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                              {information.pcu != null ? (
                                <td>
                                  <img src={pcu} alt={t("pcu")} />{" "}
                                  <span>
                                    {" "}
                                    <b> {information.pcu}</b>
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                              {information.buyer != null ? (
                                <td>
                                  <img src={buyer} alt={t("buyer")}/>{" "}
                                  <span>
                                    {" "}
                                    <b> {information.buyer}</b>
                                  </span>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          </tbody>
                        </Table>
                      ))}
                    </div>
                  </Row>
                </Col>
                {/*end order details */}
              </Row>
              {/*Start Add Contact Row*/}
              {/* <Row className="g-12 m-t-20">
                <Col md="12" lg="12" sm="12" className="text-end">
                  {((getStaff === "Staff" &&
                    getStaffPermission.includes("Staff Add")) ||
                  getStaff == null) && allowplanvalidation!='expired'? (
                    <span
                      className="btn-pill btn btn-outline-primary btn-md"
                      onClick={toggle}
                    >
                      {" "}
                      {t("addStaffBtn")}
                    </span>
                  ) : (
                    // <Btn
                    //   attrBtn={{ color: 'primary',  onClick: toggle }}>
                    //   +Add Staff
                    // </Btn>
                    ""
                  )}
                </Col>
              </Row> */}
              {/*End Add Contact Row*/}
              <Row className="g-12 m-t-20">
                {/*start contact list */}
                <Row >
                <Col sm="6">
                <H6> {t("contactList")} </H6>
                </Col>
                <Col sm="6"className="text-end">
                {((getStaff === "Staff" &&
                    getStaffPermission.includes("Staff Add")) ||
                  getStaff == null) && allowplanvalidation!='expired'? (
                    <span
                      className="btn-pill btn btn-outline-primary btn-md m-b-10"
                      onClick={toggle}
                    >
                      {" "}
                      {t("addStaffBtn")}
                    </span>
                  ) : (
                    // <Btn
                    //   attrBtn={{ color: 'primary',  onClick: toggle }}>
                    //   +Add Staff
                    // </Btn>
                    ""
                  )}
                </Col>
                </Row>
                <Col md="12" lg="12" sm="12">
                  
                  <Row className="g-12">
                    <div className="table-responsive">
                      <table className="table shadow-sm shadow-showcase">
                        <thead className="bg-primary">
                          <tr>
                            <th scope="col" className="centerAlign" style={{ borderRight: "1px solid #DFDFDF" }}>{t("serialNo")}</th>
                            <th scope="col" className="centerAlign"> {t("checkBox")}</th>
                            <th scope="col" > {t("staffName")}</th>
                            { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                            <th scope="col"> {t("email")}</th> :""} 
                            {/* <th scope="col">{t("userType")}</th> */}
                            <th scope="col"> {t("role")}</th>
                          </tr>
                        </thead>
                        {contactList.map((staffList, s) => (
                            <tbody key={staffList.id}>
                              <tr >
                                <th  width="3%" className="centerAlign" style={{ borderRight: "1px solid #DFDFDF" }}>{s + 1}</th>
                                <td width="20%" className="centerAlign">
                                  <div className="checkbox checkbox-primary marg-min-top-10">
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="checkBox"
                                      value={staffList.id}
                                      id={staffList.id}
                                    />
                                    <Label
                                      htmlFor={staffList.id}
                                      className="form-label"
                                    ></Label>
                                  </div>
                                </td>
                                <td width="25%" >
                                  {staffList.first_name} {staffList.last_name}
                                </td>
                                { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                                      <td  width="25%" >{ staffList.email} </td>: 
                                      // <td className="blurmail">{blurEmail(staffList.email)}</td> 
                                      ""} 
                                  {/* <td width="15%">{staffList.user_type ? staffList.user_type =="PCU" ? "Buying House":staffList.user_type :"-"}</td> */}
                                <td width="15%">{staffList.role}</td>
                              </tr>
                            </tbody>
                        ))}
                      </table>
                    </div>
                  </Row>
                </Col>
                {/*end contact list qty */}
                <Col md="12" lg="12" sm="12">
                  &nbsp;
                </Col>
              </Row>

              {/*end second row*/}

              <Row className="g-12">
                <Col>
                  {" "}
                  <FormGroup className="f-right">
                    {/* <Button>Save and Continue</Button> */}
                    <a
                      className="btn btn-primary"
                      onClick={(e) => getChecked({ contactList })}
                    >
                      {t("saveAndContinue")}</a>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
      <AddStaffModal
                    modal={modal}
                    setModal={setModal}
                    toggle={toggle}
                    inputParams={getInputParams}
                    roles={roles}
                    addorderId={orderId}
                    ContactListDetails={setContactList}
                    workspace_type={workspace_type}
                  />
    </Fragment>
  );
};

export default AddContacts;
/***** CODE BY : RAMANAN M  *****/