import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Form,
  Label,
  Input,
  Col,
  Row,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import { ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { apidecrypt, apiencrypt } from "../../helper";
const AddQuantityModal = ({ modal, toggle,reportId,timeSlotList,user_id,staff_id,
  updatedTimeSlotList,styleNo,unit,supervisor,lineNo,samValue,noOfTailors }) => {
  const { t } = useTranslation();
  const [timeSlot, setTimeSlot] = useState("");
  const [addQuantity, setAddQuantity] = useState("");
  const [comments, setComments] = useState("");
  const [errors, setValidErrors] = useState({});

  const orderValidation = (data) => {
    let errors = {};
    if (!timeSlot) {
      errors.timeSlot = t("plsSelectTimeSlot");
    }
    if (!addQuantity) {
      errors.addQuantity = t("plsEnterAddQuantity");
    }
    setValidErrors(errors);
    return errors;
  };
  const onSaveHandle = () => {
    let retval = orderValidation();
    if (Object.keys(retval).length == 0) {
      var apiInputParams = {};
      apiInputParams["user_id"]=user_id;
      apiInputParams["staff_id"]=staff_id;
      apiInputParams["report_id"]=reportId
      apiInputParams["time_slot"] = timeSlot;
      apiInputParams["quantity"] = addQuantity;
      apiInputParams["comments"] = comments;
      axios
        .post(ServerUrl + "/save-sam-quantity", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if(response.data.status_code===200){
            Swal.fire({
                position: 'top-middle',
                icon: 'success',
                title: t(response.data.message),
                button:  t("okLabel"),
                timer: 5000,
              })
              .then((result) => {
                if (result.isConfirmed) {
                    window.location.href = `/viewsamdetails`;
                }
                setTimeout(() => {
                  window.location.href = `/viewsamdetails`;
                }, 100);
              })
          }
          if (response.data.status_code === 400) {
            Swal.fire({
              title: t(response.data.message),
              icon: "error",
              button:  t("okLabel"),
              confirmButtonColor: '#009688',
            })
          }
        });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addQuantity")} - {styleNo}</ModalHeader>
      <ModalBody>
        <Row className="g-12" >
          <Col  md="12" lg="12" sm="12">
          <Row  className="g-12">
            <div  className="table-responsive">
              <table className="table  table-bordered">
                <thead className="bg-primary">
                  <tr>
                  <th scope="col" className="centerAlign">{t("unit")}</th>
                  <th scope="col" className="centerAlign">{t("lineNo")}</th>
                  <th scope="col" className="centerAlign">{t("supervisor")}</th>
                    <th scope="col" className="centerAlign">{t("target")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <td scope="row" className="centerAlign">{unit}</td>
                  <td scope="row" className="centerAlign">{lineNo}</td>
                  <td scope="row" className="centerAlign">{supervisor}</td>
                    <td scope="row" className="centerAlign">{Math.round(60/samValue*noOfTailors)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Row>
          </Col>
        </Row>
        <Form className="m-t-10">
          <Col>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">
                    {t("timeSlot")}
                  </Label>
                  <Input
                    className="form-control"
                    type="select"
                    style={{ cursor:'pointer' }}
                    defaultValue=""
                    onChange={(e) => {
                      setTimeSlot(e.target.value);
                    }}
                  >
                    {""}
                    <option Value="" disabled>
                      {t("selectSlot")}
                    </option>
                    {timeSlotList.map((slot)=>(
                      updatedTimeSlotList.includes(slot) ?
                      <option Value="" disabled>{slot}</option>:
                        <option Value={slot}>{slot}</option>
                    )    
                    )}
                  </Input>
                  {errors.timeSlot && (
                    <span className="error-msg">{errors.timeSlot}</span>
                  )}
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">
                    {t("addQuantity")}
                  </Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder={t("enterQuantity")}
                    onChange={(e) => {
                        setAddQuantity(e.target.value);
                    }}
                  />
                  {errors.addQuantity && (
                    <span className="error-msg">{errors.addQuantity}</span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="col-form-label" for="recipient-name">
                    {t("comments")}
                  </Label>
                  <Input
                    className="form-control"
                    type="text"
                    onChange={(e) => {
                      setComments(e.target.value);
                    }}
                    placeholder={t("comments")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>
          {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: onSaveHandle }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddQuantityModal;
/* Code By: R.Akshaya Mol*/
