import React, { useState } from 'react';
import {   
  Row,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges,ServerUrl } 
  from '../../../Constant';
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId }
    from '../../../Constant/LoginConstant';
import axios from "axios";
import Swal from "sweetalert2";

import { useTranslation } from 'react-i18next';
import { apidecrypt, apiencrypt,capitalizeFirstLowercaseRest,capitalizeuppercase } from '../../../helper';

const FormAddSizeModal = ({ modal, toggle, size, orderId, setCategoryName, setAllSkuDetails,  setGetColor,
  setGetSize,setSizeCategoryList,setshowColor,setshowSize,setSize,setColor}) =>
{

  const {t} = useTranslation();

  const [newCategoryName, setNewCategoryName] = useState('');
  const [sizeName, setSizeName] = useState('');
  const [errors, setValidErrors] = useState({});

  const validation = () => {
    let errors = {};
    if ( !newCategoryName ) {
        errors.categoryName = "Please Enter the Category Name";
    }
    if ( sizeName.length == 0 ) {
        errors.sizeName = "Please Enter the Size Name";
    }
    setValidErrors(errors);
    return errors;
  };

  const onSaveHandle = () => {

    var getCategoryInputParams = {};
        getCategoryInputParams['company_id'] = getLoginCompanyId;
        getCategoryInputParams['workspace_id'] = getWorkspaceId;
        getCategoryInputParams['user_id'] = getLoginUserId;
        getCategoryInputParams['staff_id'] = getLoginStaffId;
        getCategoryInputParams['category'] = newCategoryName;
        getCategoryInputParams['size'] = sizeName;

    var retVal = validation();
    if (Object.keys(retVal).length == 0){
        axios
        .post(ServerUrl + "/add-size-category", apiencrypt(getCategoryInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:3000,
            })
            .then((result) => {
              if (result.isConfirmed) { 
                //setshowColor([]);
                setshowSize([]);
               // setColor([]);
                setSize([]);

                refreshData() }
                  //setshowColor([]);
                  setshowSize([]);
                  // setColor([]);
                   setSize([]);
              refreshData();
            })
          }
          if (response.data.status_code === 400) {
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
              timer:3000,
            })
          }
        })
    }
  };

  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9A-Za-z_-]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  function handleKeyPressSize(e) {
    var key = e.key;
    var regex = /[,A-Za-z0-9_-]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  const refreshData = () => {

  /********************* [INPUT PARAMS for API Calls] (To add Order SKU)****************************/
  var getInputParams = {};
      getInputParams['company_id'] = getLoginCompanyId;
      getInputParams['workspace_id'] = getWorkspaceId;
      getInputParams['user_id'] = getLoginUserId;
      getInputParams['staff_id'] = getLoginStaffId;
      getInputParams['order_id'] = orderId;

    axios
    .post(ServerUrl + "/get-all-sku", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setAllSkuDetails(response.data.data);
      setGetColor(response.data.data.colors);
      setGetSize(response.data.data.sizes);  
    });

    axios
    .post(ServerUrl + "/get-size-category", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setSizeCategoryList(response.data.data);
      setCategoryName(0);
      toggle();
    })


  };

  const clearData = () => {
    setNewCategoryName(() => "");
    setSizeName(() => "");
    setValidErrors(() => "");
    toggle();
  };


  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>
        <span style={{  marginLeft: '10px' }}>
           { t("Add Category")}
        </span>
      </ModalHeader>
      <ModalBody>
        <Row style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <Label className="col-form-label" for="recipient-name" style={{ marginLeft: '-10px' }}>{t("addCategoryName")}<sup className="font-danger">*</sup></Label>
            <Input
            onKeyPress={(e) => handleKeyPress(e)}
            className="form-control"
            type="text"
            onChange={(e) => {setNewCategoryName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}}
            style={{ borderRadius: '5px' }}
            />
            {errors.categoryName && 
            ( <span className="error-msg m-t-5" style={{ marginLeft: '-10px' }}>{t(errors.categoryName)}</span> )}
        </Row>
        <Row style={{ paddingLeft: '20px', paddingRight: '20px', marginTop: '4px' }}>
            <Label className="col-form-label" for="recipient-name" style={{ marginLeft: '-10px' }}>{t("addSize")}<sup className="font-danger">*</sup></Label>
            <Input
            onKeyPress={(e) => handleKeyPressSize(e)}
            className="form-control"
            type="text"
            onChange={(e) => {setSizeName(e.target.value=capitalizeuppercase(e.target.value))}}
            style={{ borderRadius: '5px' }}
            />
                {errors.sizeName && 
            ( <span className="error-msg m-t-5" style={{ marginLeft: '-10px' }}>{t(errors.sizeName)}</span> )}
        </Row>
          <Row style={{ color: '#B0B0B0', fontSize: '13px', marginLeft: '7px', marginTop: '10px' }}>
            {t("noteIfUNeedMulSize")}
          </Row>
          <Row style={{ color: '#B0B0B0', fontSize: '13px', marginLeft: '7px', marginTop: '0px' }}>
           {t("plsAddWidCommaSep")}
          </Row>
      </ModalBody>
      <ModalFooter style={{ borderTop: 'none' }}>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: clearData }}>
        {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary  btn-sm", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddSizeModal;
/***** CODE BY : RAMANAN M  *****/