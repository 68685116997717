import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Card, CardBody, CardHeader, Collapse, Input, Table,Modal,ModalHeader,ModalBody,FormGroup,Button } from "reactstrap";
import { Btn, H5 } from "../../AbstractElements";
import { ServerUrl } from "../../Constant";
import DatePicker from "react-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import TemplateModal from "../Orders/CreateOrders/AddTemplateModal"
import FormAddTaskSubTaskModal from "../Orders/CreateOrders/AddTaskSubTaskModal"
// import { EXITED } from "react-transition-group/transition";
import { getStaff, getStaffPermission } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";
// import AddTask from "./AddOrderTask";
import { refineProps } from "@fullcalendar/react";
import Add_icon from '../../assets/images/dms/icons/Main Task Add_icon.svg'
import subTask_icon from '../../assets/images/dms/icons/BlackSubTaskAddicon.svg'
import delete_icon from '../../assets/images/dms/icons/BlackSubTaskDeleteicon.svg'
import downAccordion_icon from '../../assets/images/dms/icons/For Accordion_icon.svg'
import up_accordion_icon from '../../assets/images/dms/icons/up_accordion_icon.svg'
import tasklist_icons from '../../assets/images/dms/icons/tasklist_icons.svg'
import TaskupdateSubTaskModal from "./TaskupdateSubTaskModal";
import TaskUpdateTemplateModal from "./TaskUpdateTemplateModal";
import Add_subTask_icon from '../../assets/images/dms/icons/Add Task_icon.svg'

const EditTaskupdateModal = (props) => {

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  // const [personInCharge, setPersonInCharge] = useState([]);
  let templateTasks = props.tasks;
  let personInCharge=props.personInCharge;
  const selectedTemplateId = props.selectedTemplateId;
  const workspaceID = props.workspaceId;
  const companyID = props.companyId;
  const orderID = props.orderID;
  const [isTemplateChanged, setIsTemplateChanged] = useState("0");
  const [newTemplateToSave, setNewTemplateToSave] = useState([]);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const toggleAddTaskModal = () => { setAddTaskModal(!addTaskModal) };
  const [taskTitle, setTaskTitle] = useState("");
  const [subTaskOf, setSubTaskOf] = useState("");
  const [taskUpdatetemplete, setTaskUpdatetemplete] = useState([]);
  const [templeteUpdateModal, setTempleteUpdateModal] = useState(false);
  const toggleTempleteUpdateTaskModal = () => { setTempleteUpdateModal(!templeteUpdateModal) };
  const [displayTemplateSave, setdisplayTemplateSave] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [subtaskid, setsubtaskid] = useState("");
  const [startDate, setstartDate] = useState("");
  const [ updateStartDate,  setUpdateStartDate] = useState("");
  const [ updateEndDate,  setUpdateEndDate] = useState("");
  

  // useEffect(() => {
  //   axios.post(ServerUrl + "/get-order-contacts", apiencrypt({
  //     "company_id": companyID,
  //     "workspace_id": workspaceID,
  //     "order_id": orderID
  //   })).then((response) => {
  //     response.data = apidecrypt(response.data)
  //     setPersonInCharge(response.data.data);
  //   })
  // }, []);

  if (isTemplateChanged === "1") {
    setIsTemplateChanged("0");
  }

  const openAddModal = (task, subTaskOff = '') => {
    if (task === "task") {
      setAddTaskModal(!addTaskModal);
      setTaskTitle("Task");
    }
    else if (task === "subtask") {
      setAddTaskModal(!addTaskModal);
      setTaskTitle("Sub Task");
      setSubTaskOf(subTaskOff);
    }
  };

  /************ Function to delete the selected task/subtask ***************/
  const deleteTask = (title, task, subtitle = '') => {
    Swal.fire({
      customClass: {
        confirmButton: 'swal2-delete'
      },
      title: t("areYouSure"),
      text: t("deleteTaskTextAlert"),
      icon: "warning",
      showCancelButton: true,
      // confirmButtonColor: 'swal2-deny',
      confirmButtonText: t("delete"),
      cancelButtonColor: '#E7EAED'
    }).then((result) => {
      if (result.isConfirmed) {
        for (let i = 0; i < templateTasks.length; i++) {
          if (templateTasks[i].id == selectedTemplateId) {
            let template = JSON.parse(templateTasks[i].task_template_structure);
            let newTemplate = [];
            if (template.length === 1 && task === "task") {
              Swal.fire({
                title: t("lastTaskDeletionTitleAlert"),
                text: t("lastTaskDeletionTextAlert"),
                icon: "error",
                confirmButtonText: t("okLabel"),
                allowOutsideClick: false,

              }).then(() => {
                templateTasks[i].task_template_structure = JSON.stringify(template);
                setNewTemplateToSave(template);
              });
            }
            else {
              for (let j = 0; j < template.length; j++) {
                if (template[j].task_title === title && task === "task") { }
                else if (template[j].task_title === title && task === "subTask") {
                  let task_subtitles = [];
                  if ((template[j].task_subtitles).length === 1) {
                    newTemplate.push(template[j]);
                    Swal.fire({
                      title: t("lastTaskDeletionTitleAlert"),
                      text: t("mainTaskDeletionTextAlert"),
                      icon: "error",
                      confirmButtonText: t("okLabel"),
                      allowOutsideClick: false,
                    });
                  }
                  else {
                    for (let k = 0; k < (template[j].task_subtitles).length; k++) {
                      if (template[j].task_subtitles[k] === subtitle) { }
                      else {
                        task_subtitles.push(template[j].task_subtitles[k]);
                      }
                    }
                    template[j].task_subtitles = task_subtitles
                    newTemplate.push(template[j]);
                  }
                }
                else {
                  newTemplate.push(template[j]);
                }
              }
              templateTasks[i].task_template_structure = JSON.stringify(newTemplate);
              setNewTemplateToSave(newTemplate);
            }
          }
          //props.setTemplates(templateTasks);
          props.setTemplateload(templateTasks);
        }
        setIsTemplateChanged("1");
        props.areTasksEdited(1);
      }
    })

  }
  const inhandletaskdeatail=(subtaskdeatils)=>{
    let tsub= displayTemplateSave.filter((val) => val.includes(subtaskdeatils));
    return tsub;
  }
  const onhandlesumit=()=>{
    
    
  }
  const onSubmitHandle = (id) => {
    let sub_arr = [];
    
    {templateTasks.map((template) =>{
      if (template.id == selectedTemplateId) {
          {JSON.parse(template.task_template_structure).map((templates, i) => {
           {templates.task_subtitles.map((subtitle) => {
             if(inhandletaskdeatail(templates.task_title+"###"+subtitle).length>0){ 
           if(document.getElementById(templates.task_title + "||" + subtitle + "||taskupdatestartdate").value==""
     ||document.getElementById(templates.task_title + "||" + subtitle + "||taskupdatestartdate").value==null){
 
       sub_arr.push((templates.task_title + "||" + subtitle + "||taskupdatestartdate"));
       var splitvalue= sub_arr[0].split('||');
      //  Swal.fire({
      //    title: (splitvalue[0]+" "+"("+splitvalue[1]+")"),
      //    text: ("Please Select the"+ " "+((splitvalue[2]=="taskupdatestartdate")?"Start date":"End date")),
      //    icon: "warning",
      //    button: t("okLabel"),
      //  });
     }
     if(document.getElementById(templates.task_title + '||' + subtitle + '||taskupdateenddate').value==""
     ||document.getElementById(templates.task_title + '||' + subtitle + '||taskupdateenddate').value==null){
 
       sub_arr.push((templates.task_title + '||' + subtitle + '||taskupdateenddate'));
     }
     if(document.getElementById(templates.task_title + '||' + subtitle + '||taskupdatepic_id').value==""
     ||document.getElementById(templates.task_title + '||' + subtitle + '||taskupdatepic_id').value==null){
 
       sub_arr.push((templates.task_title + '||' + subtitle + '||taskupdatepic_id'));
     }
    }
    //  if(inhandletaskdeatail(templates.task_title+"###"+subtitle).length>0){
 
      // }
    })}
    if(sub_arr.length>0){
     var splitvalue= sub_arr[0].split('||');
    Swal.fire({
      title: (splitvalue[0]+" "+"("+splitvalue[1]+")"),
      // text: ("Please Select the"+ " "+((splitvalue[2]=="taskupdatestartdate")?"Start date":"End date")),
      text: (((splitvalue[2]=="taskupdatestartdate")?t("selectStartDate"):splitvalue[2]=="taskupdateenddate"?t("selectEndtDate"):splitvalue[2]=="taskupdatepic_id"?t("pleaseSelectPic"):"")),
      icon: "warning",
      button: t("okLabel"),
      timer:5000,
    });}

   })}
  
           }
         })}
    
      if(sub_arr.length==0){

        toggleTempleteUpdateTaskModal();
      }
     
      
  }
return (
    <Modal 
        backdrop="static" isOpen={props.modal} 
        toggle={props.toggle} centered 
        className="Addvendormodal taskupdatemodal" >
        <ModalHeader>
        <Row> 
        <Col className=" cursor-pointer" >
                <span className="f-w-600" onClick={props.toggle}>{t("updateTaskTemplate")}</span>
            </Col>
            
            <Col className="text-end cursor-pointer" >
                <span title={t("close")} tooltip={t("close")} alt={t("close")} onClick={props.toggle}>X</span>
            </Col>
        </Row> 
        </ModalHeader>
        <ModalBody>
        <>
      <TaskupdateSubTaskModal modal={addTaskModal} toggleModal={toggleAddTaskModal} taskTitle={taskTitle}  taskTemplate={templateTasks} setTemplates={props.setTemplates}
      refreshTemplates={setIsTemplateChanged} selectedtemplateId={selectedTemplateId}  newTemplateSave={setNewTemplateToSave}  subtaskOf={subTaskOf} TaskNam={setTaskName} displayTemplateSave={setdisplayTemplateSave}
        //     subtaskOf={subTaskOf}
        // selectedtemplateId={selectedTemplateId} areTasksEdited={props.areTasksEdited} 
        />
      {(getStaff === "Staff" && getStaffPermission.includes('modify_task_template')) || getStaff == null ?
        <Row>
          <div>
            {/* <a href="javascript:void(0)" className="f-right m-b-5 icon-color f-w-600 f-18 p-2" 
            style={{ fontFamily: 'poppins'}} onClick={()=>openAddModal("task")}>
            <i className="fa fa-plus-circle"></i>{ t("addTask")}</a> */}
            <img className="cursor-pointer p-r-20" style={{ float: "right" }} 
              title={t("addTask")} onClick={() => openAddModal("task")} src={Add_icon} />
          </div>
        </Row> : ""
      }
      <Row>
        <Col sm="12" xl="12">
          <div className="default-according" id="accordion">
            {templateTasks.map((template) =>
              template.id == selectedTemplateId
                ? JSON.parse(template.task_template_structure).map((templates, i) => (
                  <Card key={i} className="cardRadius">
                    <CardHeader className="p-1 cardRadius" >
                      <Row>
                        <Col onClick={() => toggle(i)} className="cursor-pointer" 
                        xxl="10" xl="10" lg="10" md="9" sm="9" xs="7" >
                        <H5 attrH5={{ className: "m-0 p-0 f-left" }}>
                            <Btn attrBtn={{
                                as: Card.Header, className: "btn btn-link",  color: "default",
                                onClick: () => toggle(i), }} >
                              { templates.task_subtitles.length === 0 ?
                                <p style={{marginTop:"6px"}}>{templates.task_title} <span className="p-0 m-0 m-l-10 f-12 font-danger">{t("addSubTask")}</span></p> 
                                : <p style={{marginTop:"6px"}}>{templates.task_title}</p>}
                            </Btn>
                            
                          </H5>
                          </Col>
                          <Col  xxl="2" xl="2" lg="2" md="3" sm="3" xs="5">
                          {(getStaff === "Staff" && getStaffPermission.includes('modify_task_template')) || getStaff == null ?
                            <>
                                              

                              {/* <img className="cursor-pointer f-right p-r-5" title={t("deleteTask")} 
                                onClick={() => deleteTask(templates.task_title, "task")} src={delete_icon} /> */}
                            
                              <img className="cursor-pointer f-right p-r-5" title={t("addSubTask")} 
                                onClick={() => openAddModal("subtask", templates.task_title)} src={Add_subTask_icon} /> </> : ""
                          }
                          
                          {isOpen === i ? <span className=" p-0 accdview f-right " onClick={() => toggle(i)}> <img className="cursor-pointer f-right p-r-5"  title={t("close")}  src={up_accordion_icon} /></span>
                            : <span className=" p-0  accdview  f-right " onClick={() => toggle(i)}><img className="cursor-pointer f-right p-r-5"  title={t("open")}  src={downAccordion_icon} /></span>}
                        </Col></Row>
                    </CardHeader>
                    <Collapse isOpen={isOpen === i}>
                      <CardBody className="p-0 cardRadius">
                        <div className="table-responsive">
                          <Table className="">
                            <thead>
                            <th className="taskUpdate-tasktable-header text-center f-w-600">{t("task")}</th>
                            <th className="taskUpdate-tasktable-header f-w-600">{t("startDate")}</th>
                            <th className=" taskUpdate-tasktable-header f-w-600">{t("endDate")}</th>
                            <th className="taskUpdate-tasktable-header f-w-600">{t("pic")}</th>
                            {(getStaff === "Staff" && getStaffPermission.includes('modify_task_template')) || getStaff == null ?
                              <th className="taskUpdate-tasktable-header f-w-600">{t("action")}</th> : ""
                            }
                            </thead>
                            <tbody>
                            {templates.task_subtitles.length === 0 ? props.setSubTaskEmpty(1) : props.setSubTaskEmpty(0)}
                            {templates.task_subtitles.map((subtitle) => (
                              
                              // let data = displayTemplateSave.filter((val) => val.includes('value1'));
                              <>                                
                                <input type="hidden" name="subtitle" id={templates.task_title + "||" + subtitle + "||taskupdatename"} defaultValue={subtitle} />
                                <tr className="taskUpdate-tr-border">
                                  <td className=""><p className="m-l-20 p-0">  <img src={tasklist_icons} />{subtitle}</p></td>
                                    {inhandletaskdeatail(templates.task_title+"###"+subtitle).length>0?
                                    <>
                                  <td>
                                    <Input
                                      type="date"
                                      // disabled
                                      id={templates.task_title + "||" + subtitle + "||taskupdatestartdate"}
                                      // min={(getStaff === "Staff" && getStaffPermission.includes('Early Task Start') || getStaff == null)?  ""  
                                      // :props.basicInfo.inquiry_date != null ? props.basicInfo.inquiry_date:
                                      
                                      //   new Date().toISOString().split('T')[0]
                                         
                                      // }
                                      min={((getStaff === "Staff"&& getStaffPermission.includes('Early Task Start'))||getStaff == null)?"":
                                        (props.inquiryDate != null) ? props.inquiryDate : props.create_dates}
                                      data-target-input="nearest"
                                      onChange={(e) => {
                                        document.getElementById(templates.task_title + "||" + subtitle + "||taskupdateenddate")
                                          .setAttribute("min", e.target.value);
                                          // document.getElementById(templates.task_title + "||" + subtitle + "||taskupdateenddate")
                                          // .disabled=false;
                                          setUpdateStartDate(e.target.value)
                                          document.getElementById(templates.task_title + "||" + subtitle + "||taskupdateenddate")
                                          .disabled=false;
                                          document.getElementById(templates.task_title + "||" + subtitle + "||taskupdateenddate").value=""
                                      }}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                    </Input>
                                  </td>
                                   <td>
                                   <Input
                                     type="date"
                                     // disabled
                                       disabled={true}
                                     id={templates.task_title + "||" + subtitle + "||taskupdateenddate"}
                                     min={new Date().toISOString().split('T')[0]}
                                     data-target-input="nearest"
                                     onChange={(e) => {
                                     
                                        setUpdateEndDate(e.target.value)
                                     
                                    }}
                                     onKeyDown={(e) => {
                                       e.preventDefault();
                                     }}
                                   >
                                   </Input>
                                 </td>
                                 <td>
                                    <Input
                                      type="select"
                                      defaultValue=""
                                      id={templates.task_title + "||" + subtitle + "||taskupdatepic_id"}
                                      className="pic_select"
                                    >
                                      <option value="" disabled>{t("Selectpic")}</option>
                                      {personInCharge.length > 0 ? personInCharge.map((pic) => (
                                        <option value={pic.staff_id}>{pic.staff_name}</option>
                                      )) : ""}
                                    </Input>
                                  </td>
                                 </>
                                  : 
                                  <>
                                  <td>
                                  <Input
                                    type="date"
                                    disabled
                                    id={templates.task_title + "||" + subtitle + "||taskupdatestartdate"}
                                    min={((getStaff === "Staff" && getStaffPermission.includes('Inquiry Date')) || getStaff == null)
                                   }
                                    data-target-input="nearest"
                                    onChange={(e) => {
                                      document.getElementById(templates.task_title + "||" + subtitle + "||taskupdateenddate")
                                        .setAttribute("min", e.target.value);

                                       
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                  </Input>
                                </td>
                                <td>
                                <Input
                                  type="date"
                                  disabled
                                  data-target-input="nearest"
                                  id={templates.task_title + "||" + subtitle + "||taskupdateenddate"}
                                  min={new Date().toISOString().split('T')[0]}
                                
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                </Input>
                              </td> 
                               <td>
                                    <Input
                                      type="select"
                                      disabled
                                      defaultValue=""
                                      id={templates.task_title + "||" + subtitle + "||taskupdatepic_id"}
                                      className="pic_select"
                                    >
                                      <option value="" disabled>{t("Selectpic")}</option>
                                      {personInCharge.length > 0 ? personInCharge.map((pic) => (
                                        <option value={pic.staff_id}>{pic.staff_name}</option>
                                      )) : ""}
                                    </Input>
                                  </td>
                                  </>
                                  }
                                </tr>                                
                              </>
                            ))}
                            </tbody>                                                                                                                                                                                                                                        
                          </Table></div>
                      </CardBody>
                    </Collapse>
                  </Card>
                ))
                : ""
            )}
               <Row className="g-12 m-t-30">
               <Col>
                 <FormGroup className="f-right">
                   {newTemplateToSave.length>0?
                   <Button id="totalSave" onClick={() => onSubmitHandle()} title="">{t("save")}</Button>
                   :
                   <Button id="totalSave" onClick={() => onSubmitHandle()} disabled title="">{t("save")}</Button>}
                 </FormGroup>
               </Col>
             </Row>
            
            <TaskUpdateTemplateModal
              modal={templeteUpdateModal}
              toggleTemplateModal={toggleTempleteUpdateTaskModal}
              companyId={companyID}
              workspaceId={workspaceID}
              orderId={orderID}
              templateTasks={templateTasks}
              newTemplateSave={newTemplateToSave}
              templates={props.setTemplates}
              templateNames={props.templateNames}
              updateEndDate={updateEndDate}
              updateStartDate={updateStartDate}
              // refreshTemplates={isTemplateChanged} 
              // newTemplate={newTemplateToSave}
              // savedTemplateId={props.savedTemplateId}
              // setTheSelectedTemplate={props.setTheSelectedTemplate}
              // areTasksEdited={props.areTasksEdited}
              // taskparam={props.tasks}
             
              selectedTemplateId={selectedTemplateId}
              // onSubmitHandle={props.onSubmitHandle}
              // setIfTemplateChanged={props.setIfTemplateChanged}

              // setOnSubmitData={props.setOnSubmitData}
              // setTemplateload={props.setTemplateload}
              // setOrderTemplateId={props.setOrderTemplateId}
            />
            {/* <AddTask
                      modal={addTaskModal}
                      toggleTemplateModal={toggleTemplateModal}
                      companyId = {companyID}
                      workspaceId = {workspaceID}
                      orderId = {orderID}
                    
                    /> */}
          </div>
        </Col>
      </Row>
    </>
  

        </ModalBody>
    </Modal>
);
};

export default EditTaskupdateModal;

//************************** Code by: Ramanan M ***************************//