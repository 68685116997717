import React, { Fragment } from "react";
import { useTranslation } from 'react-i18next';
import { PolarArea } from "react-chartjs-2";
import { Col, Row,  Table,} from "reactstrap";
import CompletedIcons from '../../../assets/images/dms/taskicons/CompletedSmall.svg';
import DelayCompletionIcons from '../../../assets/images/dms/taskicons/DelayedCompletionSmall.svg';
import InprogressIcons from '../../../assets/images/dms/taskicons/InProgressSmall.svg';
import YettostartIcons from '../../../assets/images/dms/taskicons/YettoStartSmall.svg';
import DelayIcons from '../../../assets/images/dms/taskicons/DelaySmall.svg';

const TaskStatusPolarChart = (props) => {
    const taskStatusDetails = props.generalTaskData;
    const filteredOrderDetails = taskStatusDetails.taskCount;
    const { t } = useTranslation();
    var sdelayedCompleted = (filteredOrderDetails[0].delayedCompleted / filteredOrderDetails[0].total * 100).toFixed(2);
    var scompleted = (filteredOrderDetails[0].completed / filteredOrderDetails[0].total * 100).toFixed(2);
    var sdelay = (filteredOrderDetails[0].delay / filteredOrderDetails[0].total * 100).toFixed(2);
    var sinProgress = (filteredOrderDetails[0].inProgress / filteredOrderDetails[0].total * 100).toFixed(2);
    var syetToStart = (filteredOrderDetails[0].yetToStart / filteredOrderDetails[0].total * 100).toFixed(2);


    const polarData = {
        labels:   ["Completed", "Delay", "Delayed Completion", "In Progress", "Yet To Start"],
        datasets: [
            {
                data: [scompleted, sdelay, sdelayedCompleted, sinProgress, syetToStart],
                backgroundColor: ["#00B38E", "#FF3169", "#E69020", "#7B7B7B", "#C8C8C8"],
            }
        ]
    }

    const polarOption = {
        responsive: true,
        legend: {
            display: false
        },
        scales: {
            // "maintainAspectRatio": false,
            // "xAxes": [{
            //     "categoryPercentage": 1.0,
            //     "barPercentage": 1.0
            // }],
            r: {
                suggestedMin: 25,
                suggestedMax: 50,
                grid:  { display: true },
                ticks: { display: false, stepSize: 50 }
            }
        },

        plugins: {
            legend: {
                position: 'bottom',
                display: false,
            },
            tooltip: {
                boxWidth: 20,
                boxHeight: 30,
                boxPadding: 2,
                borderColor: "#E0E0E0",
                borderWidth: 1,
                backgroundColor: "#fff",
                titleColor: "#000",
                bodyColor: "red",
                usePointStyle: true,

                callbacks: {
                    label: function (context) {
                        var label = context.dataset.label || '';
                        if (context.parsed.y !== null) {
                            label += ' ' + context.formattedValue + '%';
                        }
                        return label;
                    },
                    labelTextColor: (context) => {
                        if (context.dataset.label == "Completed") {
                            return '#00B38E';
                        }
                        else if (context.dataset.label == "Delay") {
                            return '#FF3169';
                        }
                        else if (context.dataset.label == "Delayed Completion") {
                            return '#FF3169';
                        }
                        else if (context.dataset.label == "In Progress") {
                            return '#7B7B7B';
                        }
                        else if (context.dataset.label == "Yet To Start") {
                            return '#C8C8C8';
                        }
                    },
                }
            },
            // "datalabels": {
            //     "formatter": (value, context) => value + '%',
            //     "color": "white"
            // },
            // "labels": {
            //     "render": 'label',
            //     "fontColor": 'white',
            //     "position":"outside"
            //   }
        }
    }

    return (
        <>
        <Row className="alignIconCenter">
        <Col xl="2" lg="2" md="2" sm="2" xs="2"> </Col>
        <Col xl="9" lg="9" md="9" sm="9" xs="9"> <PolarArea data={polarData} options={polarOption} /></Col>        
      </Row>
      <Row className="alignIconCenter">
        <Col xl="2" lg="2" md="12" sm="12" xs="12"> </Col>
        <Col xl="9" lg="9" md="12" sm="12" xs="12" 
        className="offset-xl-1 offset-lg-1 offset-md-1">
            <div className="table-responsive m-t-20 m-b-5">
            <Table className="table-bordernone statustable text-center overflow-hidden">
             <tr><td className="text-left" style={{fontSize: "0.8vw"}}><span><img src={CompletedIcons}/></span>{t('completed')}
                <span className="f-w-500"> {scompleted !=0.0 ? scompleted : 0 }%</span> </td></tr>
             <tr><td className="text-left" style={{fontSize: "0.8vw"}}><span><img src={DelayIcons}/></span>{t('delay')}
                <span className="f-w-500"> {sdelay!=0.0 ? sdelay : 0}%</span></td></tr>
             <tr><td className="text-left" style={{fontSize: "0.8vw"}}><span><img src={DelayCompletionIcons}/></span>{t('delCompletion')}
                <span className="f-w-500"> {sdelayedCompleted!=0.0 ? sdelayedCompleted : 0}%</span></td></tr>
             <tr><td className="text-left" style={{fontSize: "0.8vw"}}><span><img src={InprogressIcons}/></span>{t('inProgress')}
                <span className="f-w-500"> {sinProgress!=0.0 ? sinProgress : 0}%</span></td></tr>
             <tr><td className="text-left" style={{fontSize: "0.8vw"}}><span><img src={YettostartIcons}/></span>{t('yetToStart')}
                <span className="f-w-500"> {syetToStart !=0.0 ? syetToStart : 0}%</span></td></tr> 
            </Table>
         </div>
        </Col>
        </Row>
         </>
    )
}

export default TaskStatusPolarChart;