import {
  Offcanvas,
  OffcanvasBody, Input, Label,
  OffcanvasHeader, Button
} from "reactstrap";
import { ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from 'moment';
import { getLoginCompanyId, getWorkspaceId } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";
const CalendarCanvas = ({ modal, toggle, forCalender, dataRecieved, holidayInfo }) => {
  const { t } = useTranslation();
  var getInputHolidays = {}
  getInputHolidays['company_id'] = getLoginCompanyId;
  getInputHolidays['workspace_id'] = getWorkspaceId;
  const toggleCanvas = () => {
    /*******************Startdate enddate miss match validation ********************/
    {
      var StartDate = document.getElementById('StartDate').value;
      var EndDate = document.getElementById('EndDate').value;
      var eDate = new Date(EndDate);
      var sDate = new Date(StartDate);
      if (StartDate != '' && StartDate != '' && sDate > eDate) {
        // alert("Please ensure that the End Date is greater than or equal to the Start Date.");
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctStartEndDate"),
          timer: 2000
        })
        return false;
      }
      if (StartDate == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectStartDate"),
          timer: 2000
        })
        return false;
      }
      if (EndDate == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectEndtDate"),
          timer: 2000
        })
        return false;
      }
    }
    /*******************Create_holiday Api********************/
    let holiday_flg = 1
    let start = document.getElementById("StartDate").value;
    let end = document.getElementById("EndDate").value;
    let name = document.getElementById("HolidayName").value;

    var stat_date = Moment(start).format('YYYY-MM-DD')
    var endd_date = Moment(end).format('YYYY-MM-DD')
    getInputHolidays["holiday_start_date"] = stat_date;
    getInputHolidays["holiday_end_date"] = endd_date;
    getInputHolidays['name'] = name;
    getInputHolidays['description'] = "Holiday";
    axios
      .post(ServerUrl + "/create-holidays", apiencrypt(getInputHolidays))
      .then((response) => {
        response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            allowOutsideClick: false,
            timer:5000
          }).then((result) => {
            if (result.isConfirmed) {
              axios
                .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  holidayInfo(response.data.data);
                  forCalender(response.data.data);
                  dataRecieved(1);
                  toggle();
                })
            }
            setTimeout(() => {
              axios
              .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
              .then((response) => {
                response.data = apidecrypt(response.data)
                holidayInfo(response.data.data);
                forCalender(response.data.data);
                dataRecieved(1);
                toggle();
              })
            }, 100);
          });
        }
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            icon: "error",
            button: t("okLabel"),
            timer: 2000
          });
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t(response.data.error.startDate) || t(response.data.error.endDate) || t(response.data.error.name),
            icon: "error",
            button: t("okLabel"),
            timer: 2000
          });
        }
      })
  }
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"} className="offcanvas-width">
      <OffcanvasHeader className="bg-primary offcanvas-header">{t("addHolidays")}
        <span className="f-right cursor-pointer" title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggle}>X</span></OffcanvasHeader>
      <OffcanvasBody>
        <Label htmlFor="exampleInputPassword2">
          {t("holidayName")}
        </Label>
        <Input
          type="text"
          id="HolidayName"
          placeholder={t("holidayName")}>
        </Input><br></br>
        <Label htmlFor="exampleInputPassword2">
          {t("startDate")}
        </Label>
        <Input
          type="date"
          id="StartDate"
          min={new Date().toISOString().split('T')[0]}
          onChange={(e) => {
            document.getElementById("EndDate").setAttribute("min", e.target.value)
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        /><br></br>
        <Label htmlFor="exampleInputPassword2">
          {t("endDate")}
        </Label>
        <Input
          type="date"
          id="EndDate"

          onChange={() => {

          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </OffcanvasBody>
      <OffcanvasHeader className="bg-light offcanvas-fooder">
        <Button onClick={() => { toggleCanvas() }} style={{ float: "Right" }}>{t("save")}</Button>
      </OffcanvasHeader>
    </Offcanvas>
  );
};
export default CalendarCanvas;
// Code by : Praveen
