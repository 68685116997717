import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import v1cutgreen_icon from "../../assets/images/dms/icons/v1cutgreen_icon.svg";
import Sewv1green_icon from "../../assets/images/dms/icons/Sewv1green_icon.svg";
import packv1_icon from "../../assets/images/dms/icons/packv1_icon.svg";
import {
  getLoginCompanyId, getLoginUserId, getWorkspaceId,
  getLoginStaffId,
} from "../../Constant/LoginConstant";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import { apiencrypt, apidecrypt } from "../../helper";
import noData from "../../assets/images/dms/nodata.png";
import { useTranslation } from "react-i18next";

const Productionstatus = (props) => {
  const [productionStatusdashboard, setproductionStatusdashboard] = useState([]);
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [cutdata, setcutdata] = useState();
  const [sewdata, setsewdata] = useState();
  const [packdata, setpackdata] = useState();
  const [productiondatas, setProductiondatas] = useState();
  const [productionStatusOrder_id, setproductionStatusOrder_id] = useState();
  const { t } = useTranslation();

  const cutimage = new Image(15, 15);
  cutimage.src = v1cutgreen_icon;
  const sewimage = new Image(15, 15);
  sewimage.src = Sewv1green_icon;
  const packimage = new Image(15, 15);
  packimage.src = packv1_icon;

  useEffect(() => {
    axios.post(
      ServerUrl + "/get-prod-status",
      apiencrypt({
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
      })
    ).then((response) => {
      response.data = apidecrypt(response.data);
      setProductiondatas(response.data.data);
      setproductionStatusdashboard(response.data.data[0].styleNo);
      setproductionStatusOrder_id(response.data.data[0].orderId);
      setcutdata(response.data.data[0].cut);
      setsewdata(response.data.data[0].sew);
      setpackdata(response.data.data[0].pack);
    });
  }, []);

  const ordersttus = (indexValue) => {
    props.geOrderstatusId(productionStatusOrder_id[indexValue]);
  };

  const lineChart2option = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        align: "end",
        display: false,
        labels: {
          boxWidth: 5,
          boxHeight: 5,
        },
      },
      tooltip: {
        boxWidth: 20,
        boxHeight: 30,
        boxPadding: 2,
        borderColor: "#E0E0E0",
        borderWidth: 1,
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "red",
        usePointStyle: true,
        callbacks: {
          labelPointStyle: (context) => {
            if (context.dataset.label == "Cutting") {
              return { pointStyle: cutimage };
            } else if (context.dataset.label == "Sewing") {
              return { pointStyle: sewimage, };
            } else if (context.dataset.label == "Packing") {
              return { pointStyle: packimage, };
            }
          },
          labelTextColor: (context) => {
            if (context.dataset.label == "Cutting") {
              return "#13D09A";
            } else if (context.dataset.label == "Sewing") {
              return "#5159AC";
            } else if (context.dataset.label == "Packing") {
              return "#13A1C1";
            }
          },
          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += " " + context.parsed.y + "%";
            }
            return label;
          },
        },
      },
      title: {
        display: false,
        text: "Production Status",
      },
    },
    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "Percentage",
          color: "black",
          fontStyle: "bold",
          font: {
            size: 13,
          },
        },
        min: 0,
        ticks: { stepSize: 25 },
      },
      x: {
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          font: { size: 13 },
        },
      },
    },
    onClick: (event, index) => {
      if (index.length > 0) {
        ordersttus(index[0].index);
      }
    },
  };

  const lineChart2Data = {
    labels: productionStatusdashboard,
    datasets: [
      {
        backgroundColor: "rgba(247, 49, 100, 0.2)",
        borderColor: "#13D09A",
        pointColor: "#13D09A",
        data: cutdata,
        lineTension: 0,
        label: "Cutting",
      },
      {
        backgroundColor: "rgba(81, 187, 37, 0.2)",
        borderColor: "#5159AC",
        pointColor: "#51bb25",
        data: sewdata,
        lineTension: 0,
        label: "Sewing",
      },
      {
        backgroundColor: "rgba(145, 46, 252, 0.2)",
        borderColor: "#13A1C1",
        pointColor: "13A1C1",
        data: packdata,
        lineTension: 0,
        label: "Packing",
      },
    ],
  };

  const psNoData = {
    labels: "",
    datasets: [
      {
        label: "No Data",
        legend: "No Data",
        data: [],
        backgroundColor: "#7b7b7b",
        borderColor: "#7b7b7b",
        barPercentage: 0.25,
        skipNull: true,
      },
    ],
  };

  const psNoDataOption = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 38,
          boxHeight: 8,
        },  
      }
    },
    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "Percentage",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 },
      },
      x: {    
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
      },
    },
  };

  return (
    <Fragment>
      <Col xl="6" md="12">
        <Card className="shadow shadow-showcase" style={{ marginTop: '-7px' }}>
          <span className="m-t-20 p-l-25">
            <span className="f-16 f-w-600"> {t("productionStatus")} </span>
            <span className="p-r-15" style={{ float: "right" }}>
              <img className="p-l-20 img-35" src={packv1_icon} /> <br />
              <span style={{ color: "#13A1C1" }} className="mb-0 f-12"> {t("packing")} </span>
              <hr style={{ backgroundColor: "#13A1C1", height: "2px" }} className="mt-1" />
            </span>
            <span className="p-r-15" style={{ float: "right" }}>
              <img className="p-l-20 img-35" src={Sewv1green_icon} /> <br />
              <span style={{ color: "#5159AC" }} className="mb-0 f-12"> {t("sewing")} </span>
              <hr style={{ backgroundColor: "#5159AC", height: "2px" }} className="mt-1" />
            </span>
            <span className="p-r-15" style={{ float: "right" }}>
              <img className="p-l-20 img-35" src={v1cutgreen_icon} /> <br />
              <span style={{ color: "#13D09A" }} className="mb-0 f-12"> {t("cutting")} </span>
              <hr style={{ backgroundColor: "#13D09A", height: "2px" }} className="mt-1" />
            </span>
          </span>
          <CardBody className="chart-block pt-0 pb-0 pr-0 ">
            <Row>
              <Col xl="12" md="12">
                {productionStatusdashboard=="" ? 
                 <Line data={psNoData} options={psNoDataOption} width={3500} height={1320} />
                  :
                  <Line data={lineChart2Data} options={lineChart2option} width={3500} height={1320} />
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Productionstatus;
//**************************Code by : Praveen****************************//