import { Container,  Label, Card, CardBody, Col, Row, Input, 
    InputGroup,  Button, CardHeader,  FormGroup, Table} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,
     getWorkspaceType, getStaff, getStaffPermission, getOrderId,
     getLoginStaffId } from "../../../Constant/LoginConstant";
import {Link} from "react-router-dom";
import GreenCutting from '../../../assets/images/dms/icons/cutting_report.svg';
import GreenSewing from '../../../assets/images/dms/icons/sewing_report.svg';
import GreenPacking from '../../../assets/images/dms/icons/packing_report.svg';
import { useTranslation } from 'react-i18next'
import { PHPtoJSFormatConversion } from "../../../helper";
const DataInputReportTable = ({dailyProdData}) =>{
    const { t } = useTranslation();
    let cuttingTarget = 0;
    let cuttingActual = 0;
    let cuttingExcess = 0;
    let cuttingShort = 0;
    let cuttingDiff = 0;    
    let sewingTarget = 0;
    let sewingActual = 0;
    let sewingExcess = 0;
    let sewingShort = 0;
    let sewingDiff = 0;
    let packingTarget = 0;
    let packingActual = 0;
    let packingExcess = 0;
    let packingShort = 0;
    let packingDiff = 0;

    var overallTotalqty = 0;

    /* To Calculate the difference between target and actual */
    const diffFunction = (diff) =>{
        if(diff === 0)
            return (<td scope="col" className="centerAlign">0/0</td>)
        else if (diff > 0)
            return (<td scope="col" className="centerAlign" ><span style={{ color:"#C4A950" }}>{diff}</span>/0</td>)
        else if (diff < 0)
            return (<td scope="col" className="centerAlign" >0/<span style={{ color:"#C45050" }}>{Math.abs(diff)}</span></td>)
    }
    /* To calculate the total excess and short */
    const excessOrShort = (diff)=>{
        if(diff > 0)
            return (<><span style={{ color:"#C4A950" }}>{diff}</span>/0</>)
        else if(diff < 0) 
            return (<>0/<span style={{ color:"#C45050" }}>{Math.abs(diff)}</span></>)
        else
            return (<>0/0</>)

    }
    return(
        <>
            <Col>
                    <div className="card-block row">
                        <Col sm="12" lg="12" xl="12">
                            <div className="">
                            <Row>
                            <Col lg={4} md={4} >
                                <Card className="shadow shadow-shadowcase">
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1">
                                        <img src={GreenCutting} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("cutting")}</p>
                                    </CardHeader>
                                    <Table className="custom-table-striped">
                                        <thead>
                                            <tr  style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,i)=>{
                                                overallTotalqty = prodData.total_qty
                                                cuttingTarget += prodData.Cut_target!= undefined ? prodData.Cut_target : 0;
                                                cuttingActual += prodData.Cut_actual!= undefined ? prodData.Cut_actual : 0;
                                                // prodData.Cut_actual!= undefined?
                                                // (prodData.Cut_diff > 0 ? cuttingExcess+= prodData.Cut_diff : cuttingShort+= Math.abs(prodData.Cut_diff))
                                                // : cuttingExcess=cuttingShort=0;
                                                cuttingExcess+= !isNaN(prodData.Cut_diff)?prodData.Cut_diff:0;
                                                return (
                                                    <tr key={i} className="f-w-400">
                                                        <td scope="col" >{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                        <td scope="col" className="centerAlign">{prodData.Cut_target}</td>
                                                        <td scope="col" className="centerAlign">{prodData.Cut_actual}</td>
                                                        <td scope="col" className="centerAlign">{prodData.Cut_actual!= undefined ? excessOrShort(prodData.Cut_diff) : "" }</td>
                                                        {/* <td scope="col">{prodData.Cut_diff>=0? prodData.Cut_diff+"/"+ 0 : 0 +"/"+ 
                                                        (prodData.Cut_actual!= undefined? prodData.Cut_diff: 0) }</td> */}
                                                    </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(cuttingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(cuttingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{cuttingActual}</td>
                                                    {diffFunction(cuttingExcess)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col> 
                            <Col lg={4} md={4} >
                                <Card className="shadow shadow-shadowcase">
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1">
                                         <img src={GreenSewing} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("sewing")}</p>
                                    </CardHeader>
                                    <Table className="custom-table-striped">
                                        <thead>
                                            <tr style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,j)=>{
                                                sewingTarget += prodData.Sew_target!= undefined ? prodData.Sew_target : 0;
                                                sewingActual += prodData.Sew_actual!= undefined ? prodData.Sew_actual : 0;
                                                // prodData.Sew_actual!= undefined ?
                                                // prodData.Sew_diff > 0 ? sewingExcess+= prodData.Sew_diff : sewingShort+= Math.abs(prodData.Sew_diff)
                                                // : sewingExcess =sewingShort= 0;
                                                sewingExcess+= !isNaN(prodData.Sew_diff)?prodData.Sew_diff:0;
                                                return (
                                                <tr key={j} className="f-w-400">
                                                    <td scope="col">{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Sew_target}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Sew_actual}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Sew_actual!= undefined ?excessOrShort(prodData.Sew_diff) : "" }</td>
                                                </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(sewingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(sewingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{sewingActual}</td>
                                                    {diffFunction(sewingExcess)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col> 
                            <Col lg={4} md={4} >
                                <Card className="shadow shadow-shadowcase">
                                    <CardHeader
                                        style={{ color: '#009688', borderBottom: '1px solid #f2f2f2' }} className="p-1 centerAlign">
                                        <img src={GreenPacking} className="f-left m-l-10"/>
                                        <p className="f-left f-w-500 m-l-10 m-t-5 f-16">{t("packing")}</p>
                                    </CardHeader>
                                    <Table className="custom-table-striped">
                                        <thead>
                                            <tr  style={{ backgroundColor:"#F2F2F2" }}>
                                                <th scope="col" className="p-2">{t("date")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("target")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("actual")}</th>
                                                <th scope="col" className="p-2 centerAlign">{t("excess")+"/"+t("short")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dailyProdData.map((prodData,k)=>{
                                                packingTarget += prodData.Pack_target!= undefined ? prodData.Pack_target : 0;
                                                packingActual += prodData.Pack_actual!= undefined ? prodData.Pack_actual : 0;
                                                //prodData.Pack_actual!= undefined?
                                                // prodData.Pack_diff > 0 ? packingExcess+= prodData.Pack_diff : packingShort+= Math.abs(prodData.Pack_diff)
                                                // : packingExcess =packingShort= 0; 
                                                packingExcess+= !isNaN(prodData.Pack_diff)?prodData.Pack_diff:0;
                                                return (
                                                <tr key={k} className="f-w-400">
                                                    <td scope="col">{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Pack_target}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Pack_actual}</td>
                                                    <td scope="col" className="centerAlign">{prodData.Pack_actual!= undefined ?excessOrShort(prodData.Pack_diff) : "" }</td>
                                                </tr>
                                            )})}
                                                <tr className="f-w-600">
                                                    <td scope="col">{t("total")}</td>
                                                    <td scope="col" className="centerAlign">
                                                        {
                                                            Number(packingTarget) >= Number(overallTotalqty)
                                                        ? 
                                                            Number(overallTotalqty) 
                                                        : 
                                                            Number(packingTarget)
                                                        }
                                                    </td>
                                                    <td scope="col" className="centerAlign">{packingActual}</td>
                                                    {diffFunction(packingExcess)}
                                                </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>  
                            </Row>  
                               
                            </div>
                        </Col>
                    </div>
            </Col>
        </>
    )
}

export default DataInputReportTable;
/* Code By : Rithanesh */