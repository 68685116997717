import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, 
    CardHeader, Collapse, FormGroup, Table } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../../Constant";
import bomb from "../../../assets/images/dms/BombSmiley.svg"
import TwoDaysDelaySmile from "../../../assets/images/dms/TwodaysDelay.svg"

import smile from "../../../assets/images/dms/SmileSmiley.svg"
import YetToComplete from "../../../assets/images/dms/YetToComplete.svg"
import YetToStart from "../../../assets/images/dms/YetToStart.svg"
import yellowSmile from "../../../assets/images/dms/WarningSmiley.svg"
import buyerImg from '../../../assets/images/dms/onGoingList.svg'
import sad from "../../../assets/images/dms/SadSmiley.svg"
import {  getWorkspaceType } from "../../../Constant/LoginConstant";
import { PHPtoJSFormatConversion } from "../../../helper";
import { useTranslation } from 'react-i18next';

const orderReportTable =({styles,statusFilter})=>{
    const [isOpen, setIsOpen] = useState(0);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    const { t } = useTranslation();
    /************ Calculate the Date Difference for Delay/Pending Days and Get the Icon  ****************/
    const getStatusForOrder=(status)=>{
        if(status === "1"){
            return(<td style={{ color:"#2785D6", border: 'none' }}>{t("active")}</td>);
        }
        else if(status === "3"){
            return(<td style={{ color:"#F1556C", border: 'none' }}>{t("deleted")}</td>);
        }
        else if(status === "10"){
            return(<td style={{ color:"#FE9365", border: 'none' }}>{t("cancelled")}</td>);
        }
        else if(status === "12"){
            return(<td style={{ color:"#13B8B4", border: 'none' }}>{t("completed")}</td>);
        }
    }
    return (
        <Row>
            <Col sm="12" xl="12" lg="12" md="12">
                <Accordion defaultActiveKey="0">
                    <div className="default-according" id="accordion">
                        <Card className="m-l-15 m-r-15">
                            <CardBody className="p-0 table-responsive" >
                                    <Table className="table-responsive tableRowCol custom-table-striped">
                                        <tr style={{background:"#c2ede8f5",fontSize:"14px"}}>
                                            <th><p className="m-l-15 p-0">{t("orderNo")}</p></th>
                                            {getWorkspaceType === "Factory" ? 
                                            <>
                                                <th>{t("buyer")}</th> 
                                                <th>{t("pcu")}</th> 
                                            </>
                                            : ""}
                                            {getWorkspaceType === "Buyer" ? 
                                            <>
                                                <th>{t("factory")}</th> 
                                                <th>{t("pcu")}</th> 
                                            </>
                                            : ""}
                                            {getWorkspaceType === "PCU" ? 
                                            <>
                                                <th>{t("factory")}</th> 
                                                <th>{t("buyer")}</th> 
                                            </>
                                            : ""}
                                            <th>{t("startDate")}</th>
                                            <th>{t("endDate")}</th>
                                            <th>{t("createdBy")}</th>
                                            {statusFilter === "All" || statusFilter === "Cancelled" || statusFilter === "Deleted" ? 
                                            <>
                                                <th>{t("actionBy")}</th>
                                                <th>{t("actionDate")}</th>
                                            </> : ""
                                            }
                                            <th>{t("status")}</th>
                                        </tr>
                                        {(styles).length >0? styles.map((style)=>{
                                            return(
                                            <tr>
                                                <td><p className="m-l-15 p-0">{style.order_no+" / "+style.style_no}</p></td>
                                                {getWorkspaceType === "Factory" ? 
                                                <>
                                                    <td>{style.buyerName}</td>
                                                    <td>{style.pcuName}</td>
                                                </>
                                                : ""}
                                                {getWorkspaceType === "Buyer" ? 
                                                <>
                                                    <td>{style.factoryName}</td>
                                                    <td>{style.pcuName}</td>
                                                </>
                                                : ""}
                                                {getWorkspaceType === "PCU" ? 
                                                <>
                                                    <td>{style.factoryName}</td>
                                                    <td>{style.buyerName}</td>
                                                </>
                                                : ""}
                                                {style.startDate === null ? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(style.startDate))}</td>}
                                                {style.endDate === null? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(style.endDate))}</td>}  
                                                <td>{style.createdBy}</td>
                                                {statusFilter === "All" || statusFilter === "Cancelled" || statusFilter === "Deleted" ?
                                                <>
                                                    {style.actionDoneBy === null ? <td>-</td>:<td>{style.actionDoneBy}</td>}
                                                    {style.actionDate === null ? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(style.actionDate))}</td>}
                                                </> : ""
                                                }
                                                {getStatusForOrder(style.status)}
                                            </tr>)
                                            }):<tr>
                                                <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                            </tr>}
                                    </Table>
                            </CardBody>
                        </Card>
                    </div>
                </Accordion>
            </Col>
        </Row>
 )
}

export default orderReportTable;