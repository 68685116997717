import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../helper';
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId } from '../../Constant/LoginConstant';

const AddLineNoModal = ({ 
    modal, toggle, 
    getMasterData, setGetMasterData, 
    setProductionTypeList, setBreakTimeList, setUnitValue
}) =>{
  
  const {t} = useTranslation();

  const [unitId, setUnitId] = useState("");
  const [lineNo, setLineNo] = useState("");
  const [machineType, setMachineType] = useState("");
  const [noOfMachines, setNoOfMachines] = useState("");

  const [errors, setValidErrors] = useState({});

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  }

  const validation = () => {
    let errors = {};
    if (!unitId) {
      errors.unitId = t("plsSelectUnitValue")
    }
    if (!lineNo) {
      errors.lineNo = t("Please Enter Line Number");
    }
    if (!machineType) {
      errors.machineType = t("plsEnterLineNo");
    }
    if (!noOfMachines) {
      errors.noOfMachines = t("plsEnterNoOfMachines");
    }
    setValidErrors(errors);
    return errors;
  };
  
  const refreshDataApiCall = () => {

    var apiInputParams = {};
    apiInputParams['company_id'] = getLoginCompanyId;
    apiInputParams['workspace_id'] = getWorkspaceId;

    axios
      .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setGetMasterData(response.data.data);
      })

      setProductionTypeList(['Sewing', 'Cutting']);
      setBreakTimeList(['1', '2', '3']);

      toggle();
  };

  const onSaveHandle = () => {
     let retval = validation();
     if (Object.keys(retval).length == 0) {

      var apiInputParams = {};
      apiInputParams['company_id'] = getLoginCompanyId;
      apiInputParams['workspace_id'] = getWorkspaceId;
      apiInputParams['user_id'] = getLoginUserId;
      apiInputParams['staff_id'] = getLoginStaffId;

      apiInputParams['type'] = "Line";
      apiInputParams['value'] = lineNo;
      apiInputParams['line_unit'] = unitId;
      apiInputParams['line_machine_type'] = machineType;
      apiInputParams['line_no_of_machines'] = noOfMachines;

      axios
        .post(ServerUrl + "/add-sam-master-data", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            })
            .then((result) => {
              if (result.isConfirmed) {
                refreshDataApiCall();
              }
              setTimeout(() => {
                refreshDataApiCall();
              }, 100);
            });
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
     }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addLineNo")}</ModalHeader>
      <ModalBody>
        <Row>
            <FormGroup className="">
                <Label> {t("unit")} </Label>
                <InputGroup>
                <Input
                    className={`${errors.unitId && 'select-error-valid'}  form-control`}
                    name=""
                    type="select"
                    placeholder={t("plsSelectUnit")}
                    defaultValue=""
                    onChange={(e) => { 
                      var splitData = (e.target.value).split("&&");
                      setUnitId(splitData[0]);
                    }}>
                    <option value="" >{t("selectUnit")}</option>
                     { getMasterData !== undefined  ?
                      getMasterData
                      .filter(data => data.type == "Unit")
                      .map(mapData => (
                        <>
                          <option 
                              key={mapData.id} value={mapData.id +"&&"+ mapData.value}>
                              {mapData.value}
                            </option>
                        </>
                      ))
                      :
                      ""
                      } 
                </Input>
                </InputGroup>
                {errors.unitId && 
                (<span className="error-msg">{t(errors.unitId)}</span>)
                }
            </FormGroup>
        </Row> 

        <Row>
            <FormGroup className="">
                <Label>{t("lineNo")}</Label>
                <InputGroup>
                <Input 
                    className={`${errors.lineNo && 'select-error-valid'}  form-control`}
                    type="text"
                    placeholder={t("plsEnterLineNo")}                 
                    onChange={(e) => {
                      setLineNo(e.target.value)
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                >
                </Input>
                </InputGroup>
                {errors.lineNo && 
                (<span className="error-msg">{t(errors.lineNo)}</span>)
                }
            </FormGroup>
        </Row> 

        <Row>
            <FormGroup className="">
                <Label>{t("machineType")}</Label>
                <InputGroup>
                <Input
                    className={`${errors.machineType && 'select-error-valid'}  form-control`}
                    type="text"
                    placeholder={t("plsEnterMachineType")}                 
                    onChange={(e) => {setMachineType(e.target.value)}}
                >
                </Input>
                </InputGroup>
                {errors.machineType && 
                (<span className="error-msg">{t(errors.machineType)}</span>)
                }
            </FormGroup>
        </Row> 

        <Row>
            <FormGroup className="">
                <Label>{t("noOfMachines")}</Label>
                <InputGroup>
                <Input
                    className={`${errors.noOfMachines && 'select-error-valid'}  form-control`}
                    type="text"
                    placeholder={t("plsEnterNoOfMachines")}                 
                    onChange={(e) => {setNoOfMachines(e.target.value)}}
                >
                </Input>
                </InputGroup>
                {errors.noOfMachines && 
                (<span className="error-msg">{t(errors.noOfMachines)}</span>)
                }
            </FormGroup>
        </Row> 

      </ModalBody>
      <ModalFooter>
          <Btn 
            attrBtn={{ color: "btn secondaryBtn btn-sm", 
            onClick: () => {toggle(), setValidErrors(() => "")} }}>
              {t("close")}
          </Btn>
          <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
            {t("save")}
          </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddLineNoModal;
