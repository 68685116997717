         import React, { Fragment, useState, useEffect,useRef } from "react";
import {
  Form, Label, Card, CardBody, Col, Row, Input, InputGroup,
  InputGroupText, Button, FormGroup, Table
} from "reactstrap";
import { H6, Btn, } from "../../../AbstractElements";
import AddColorModal from "./AddColorModal";
import AddSizeModal from "./AddSizeModal";
import AddCategoryModal from "./AddCategoryModal";
import OrderHeaders from "./OrderHeaders";
import Swal from 'sweetalert2';
import { ServerUrl } from "../../../Constant";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { encode, decode, apiencrypt, apidecrypt } from "../../../helper";
import {
  getLoginCompanyId, getWorkspaceId, getLoginUserId,
  getLoginStaffId, getStaff, getStaffPermission
}
  from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';

const AddSku = (props) => {
  const toggle = () => setModal(!modal);
  const togglepcu = () => setModalpcu(!modalpcu);
  const toggleCategory = () => setModalCategory(!modalCategory);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [quantity, setTotalQuantity] = useState(decode(searchParams.get("quantity")));
  const [modal, setModal] = useState(false);
  const [modalpcu, setModalpcu] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [color, setColor] = React.useState([]);
  const [size, setSize] = React.useState([]);
  const [showcolor, setshowColor] = React.useState([]);
  const [showsize, setshowSize] = React.useState([]);
  const [getColor, setGetColor] = React.useState([]);
  const [getSize, setGetSize] = React.useState([]);
  const [basicInfo, setBasicInfo] = React.useState([]);
  //const [fieldVal, setFieldVal] = React.useState([]);
  const [toleranceVolume, setToleranceVolume] = React.useState("");
  const [tolerancePercent, setTolerancePercent] = React.useState("");
  const [stepLevel, setStepLevel] = useState("");
  const {t} = useTranslation();

  /********************* [INPUT PARAMS for API Calls] (To add Order SKU)****************************/
  var getInputParams = {};
      getInputParams['company_id'] = getLoginCompanyId;
      getInputParams['workspace_id'] = getWorkspaceId;
      getInputParams['user_id'] = getLoginUserId;
      getInputParams['staff_id'] = getLoginStaffId;
      getInputParams['order_id'] = orderId;
      getInputParams['sku'] = [{}];

  var basicInfoParams = {};
      basicInfoParams['company_id'] = getLoginCompanyId;
      basicInfoParams['workspace_id'] = getWorkspaceId;
      basicInfoParams['user_id'] = getLoginUserId;
      basicInfoParams['staff_id'] = getLoginStaffId;
      basicInfoParams['order_id'] = orderId;

  var getCategoryInputParams = {};
      getCategoryInputParams['company_id'] = getLoginCompanyId;
      getCategoryInputParams['workspace_id'] = getWorkspaceId;
      getCategoryInputParams['user_id'] = getLoginUserId;
      getCategoryInputParams['staff_id'] = getLoginStaffId;

  /******************** SELECTED COLOR LIST ******************************/
  const handleChangeColor = (e) => {
    var id = e.nativeEvent.target.selectedIndex;
    var idvc = e.nativeEvent.target[id].value;
    var name = e.nativeEvent.target[id].text;
    var colorary = [];
    colorary['id'] = idvc;
    colorary['name'] = name;
    var t = showcolor;
    var index = t.indexOf(name)
    if (index == -1) {
      setshowColor([...showcolor, name]);
      setColor([...color, colorary]);
    }

  };

  /************** SELECTED SIZE CATEGORY LIST ***************************/
  const handleChangeCategory = (categoryName) => 
   {
    
    setGetSize(() => "");
    setSize(() => "");
    setshowSize(() => "");
    setCategoryName(categoryName);

    const sizes = allSkuDetails.sizes;
    const filteredData = sizes.filter(data => data.category == categoryName );
    setGetSize(filteredData);
  };

  /******************** SELECTED SIZE LIST ******************************/
  const handleChangeSize = (e) => {
    var id = e.nativeEvent.target.selectedIndex;
    var idvs = e.nativeEvent.target[id].value;
    var name = e.nativeEvent.target[id].text;
    var sizeary = [];
    sizeary['id'] = idvs;
    sizeary['name'] = name;

    var t = showsize;
    var index = t.indexOf(name)
    if (index == -1) {
      setshowSize([...showsize, name]);
      setSize([...size, sizeary]);
    }


  };

  /*************************************** COLOR TOTAL ******************/
  var basary=[];
  const addQty = (e) => {
    if (basicInfo.is_tolerance_req === "0") {
      var idv = e.target.id;
      var value = e.target.value;

      /***** Split the ID values of Size and Color *****/
      var sptv = idv.split("#");
      var color_id = sptv[0];
      var size_id = sptv[1];
      var totQty = 0;
    
      size.forEach((e) => {
        var getsizeid = e.id;
        var t = document.getElementById(color_id + "#" + getsizeid).value;
        if (parseInt(t) > 0) {
          totQty += parseInt(t);
          }
        document.getElementById("totqty_" + color_id).value = totQty;
     
      })
      overallTotalQty(e);
      sizeTotalQty(e)

       
    }

    else if (basicInfo.is_tolerance_req === "1") {
      var idv = e.target.id;
      var value = e.target.value;

      /***** Split the ID values of Size and Color *****/
      var sptv = idv.split("#");
      var color_id = sptv[0];
      var size_id = sptv[1];

      var totSumQty = 0;
      size.forEach((e) => {
        var getsizeid = e.id;
        var actualValue = document.getElementById(color_id + "#" + getsizeid).value;
        var toleranceValue = (actualValue / 100) * tolerancePercent;

        var totalToleranceValue = Math.round(toleranceValue);
        document.getElementById(color_id + "@" + getsizeid).value = totalToleranceValue;

        var overallTotalValue = Number(actualValue) + Number(totalToleranceValue);
        document.getElementById(color_id + "+" + getsizeid).value = overallTotalValue;
        if (parseInt(basicInfo.is_tolerance_req) == 1) {
          document.getElementById(color_id + "v" + getsizeid).innerHTML = t("quantityLabel") +  "+" + tolerancePercent + " % = " + overallTotalValue;
        }

        if (parseInt(overallTotalValue) > 0) {
          totSumQty += parseInt(overallTotalValue);
        }
        document.getElementById("totqty_" + color_id).value = totSumQty;

      })
      overallTotalQty(e);
      sizeTotalQty(e)
    }
  };

  /*************************************** SIZE TOTAL *******************/
  const sizeTotalQty = (e) => {

    if (basicInfo.is_tolerance_req === "0") {
      var id = e.target.id;
      var value = e.target.value;

      var splitId = id.split("#");
      var color_id = splitId[0];
      var size_id = splitId[1];

      var tot = 0;
      color.forEach((e) => {
        var colorId = e.id;
        var qtyValue = document.getElementById(colorId + "#" + size_id).value;
        if (parseInt(qtyValue) > 0) {
          tot += parseInt(qtyValue);
        }
        document.getElementById("SizeId_total_quantity" + size_id).value = tot;
      })
    }
    else if (basicInfo.is_tolerance_req === "1") {
      var id = e.target.id;
      var value = e.target.value;

      var splitId = id.split("#");
      var color_id = splitId[0];
      var size_id = splitId[1];

      var tot = 0;
      color.forEach((e) => {
        var colorId = e.id;
        var qtyValue = document.getElementById(colorId + "+" + size_id).value;
        if (parseInt(qtyValue) > 0) {
          tot += parseInt(qtyValue);
        }
        document.getElementById("SizeId_total_quantity" + size_id).value = tot;
      })
    }
  };

  /*************************************** OVERALL TOTAL ****************/
  const overallTotalQty = (e) => {
    var id = e.target.id;
    var value = e.target.value;

    var splitId = id.split("#");
    var color_id = splitId[0];
    var size_id = splitId[1];

    var sum = 0;
    color.forEach((data) => 
    {
      var totalQtyValue = document.getElementById("totqty_" + data.id).value;
      if (parseInt(totalQtyValue) > 0) {
        sum += parseInt(totalQtyValue);
      }
    })
    if (basicInfo.is_tolerance_req === "1") {

      document.getElementById("Overall_total_quantity").value = sum;

    }
    else {
      document.getElementById("Overall_total_quantity").value = sum;

    }
  };

  /***************** PAYLOAD - INPUT PARAMS [API CALL]  ****************/
  const getQtyDetails = (e) => {
    if (basicInfo.is_tolerance_req === "0") {
      var skuDet = {}
      const breakOut = false;
      var g = 0;
      color.forEach((c) => {
        size.forEach((e) => {
          g++;
          var tt = document.getElementById(c.id + "#" + e.id).value;
          if (tt != '' && !parseInt(tt) < 0) {
            Swal.fire({
              title: t("selectCorrectColor/SizeQtyValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });
            breakOut = true;
            return false;
          } else if ((tt != '' && !parseInt(tt) && parseInt(tt) != 0) || (parseInt(tt) < 0)) {
            Swal.fire({
              title: t("selectCorrectNumberValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else {
            var skuData = {};
            skuData['color_id'] = c.id
            skuData['size_id'] = e.id
            skuData['quantity'] = tt == '' ? 0 : tt;
            skuDet[g] = skuData;
          }
        });
      });
      return skuDet;
    }
    else if (basicInfo.is_tolerance_req === "1") {

      var skuDet = {}
      const breakOut = false;
      var g = 0;
      color.forEach((c) => {
        size.forEach((e) => {
          g++;
          var t = document.getElementById(c.id + "+" + e.id).value;
          if (t != '' && !parseInt(t) < 0) {
            Swal.fire({
              title: t("selectCorrectColor/SizeQtyValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else if ((t != '' && !parseInt(t) && parseInt(t) != 0) || (parseInt(t) < 0)) {
            Swal.fire({
              title: t("selectCorrectNumberValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else {
            var skuData = {};
            skuData['color_id'] = c.id
            skuData['size_id'] = e.id
            skuData['quantity'] = t == '' ? 0 : t;
            skuDet[g] = skuData;
          }
        });
      });
      return skuDet;
    }
  };

  /*********************************** COLOR TOTAL [DELETE] ***********/
  const getTotalColorWise = (typ=null) => 
  {
   
    let tqty = 0;
    if (color.length > 0 && size.length > 0) {
      color.forEach((colorData) => {
        let i = 0;
        let astotval = 0;
        size.forEach((sizeData) => {
          let element = document.getElementById(colorData.id + "#" + sizeData.id);
          if (typeof (element) != 'undefined' && element != null) {
            if(typ=='delete'){
              //console.log(colorData.id + "#" + sizeData.id,"typ==>",typ);
              document.getElementById(colorData.id + "#" + sizeData.id).value='';
              let t = 0;
             }else{
            let t = document.getElementById(colorData.id + "#" + sizeData.id).value;
            astotval += Number(t);
             }
           document.getElementById("totqty_"+colorData.id).value = astotval;
          }
        });
       // console.log("totqty_" ,colorData.id,"@@@", astotval);
      
        
      });
    }
  };

  /********************************** SIZE TOTAL [DELETE] ************/
  const getTotalSizeWise = (typ=null) => 
  {
    let tqty = 0;
    if (color.length > 0 && size.length > 0) {
    size.forEach((sizeData) => {
        let i = 0;
        let astotval = 0;
        color.forEach((colorData) => {
          let element = document.getElementById(sizeData.id + "#" + colorData.id);
          if (typeof (element) != 'undefined' && element != null) {
          if(typ=='delete'){
            document.getElementById(sizeData.id + "#" + colorData.id).value='';
            let t = 0;
          }else{
            let t = document.getElementById(sizeData.id + "#" + colorData.id).value;
          
          }
          astotval += Number(t);
          }
          document.getElementById("SizeId_total_quantity" + sizeData.id).value = astotval;
        });
      });
    }
  };

  /********************************* OVERALL TOTAL [DELETE] *********/
  const getOverallTotal = () => { 
  var sum = 0;
  color.forEach( (colorData) => {
    var colorTotalQty =  document.getElementById("totqty_" + colorData.id).value;
    if( colorTotalQty > 0 ){
      sum += Number(colorTotalQty);
    }
  })
  };

  /**************************** (SWAL) VALIDATION  ******************/
  const addSkuData = async (e) => {
    let sku = getQtyDetails();
    if (Object.keys(sku).length === 0) {
      Swal.fire({
        title: t("selectColor/SizeValidation"),
        icon: "warning",
        button: t("okLabel"),
      })
    }
    else {
      findTotalQty();
    }
  };

  //////////////////////////////////////////////////////////////////////////
  /******************************* API CALL (add-order-sku)  *************/
  const findTotalQty = () => {

    let sku = getQtyDetails();
    var qty = basicInfo.quantity;
    var arr = document.getElementsByName('totalQuantity');
    var total = 0;

    for (var i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].value)) {
        total += parseInt(arr[i].value);
      }
    }

    if (total == qty) {
      let updatedTotalQty = document.getElementById("Overall_total_quantity").value;
      axios
        .post(ServerUrl + "/add-order-sku", apiencrypt({
          "company_id": getLoginCompanyId,
          "workspace_id": getWorkspaceId,
          "order_id": orderId,
          "sku": sku,
          "total_qty": updatedTotalQty,
          "tolerance_percent": (basicInfo.tolerance_perc).toString(),
          "tolerance_volume": ((updatedTotalQty / 100) * basicInfo.tolerance_perc).toString(),
          "user_id": getLoginUserId,
          "staff_id": getLoginStaffId ? getLoginStaffId : 0,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            })
            .then((result) => {
              if (result.isConfirmed) {
                // setTimeout(() => {
                  window.location.href = '/ordercontacts?id=' +
                    encode(orderId);
                // }, 100);
              }
              setTimeout(() => {
                window.location.href = '/ordercontacts?id=' +
                  encode(orderId);
              }, 100);
            })
          }
        })
    }
    else {
      if (!total > 0) {
        Swal.fire({
          title: t("updateQtyValidation"),
          text: t("updateSkuQtyValidation"),
          icon: "warning",
          button: t("okLabel"),
        })
      }
      else {
        let Qty = Number(basicInfo.quantity);
        let totalQty = Number(basicInfo.quantity) + Number(toleranceVolume);
        let updatedTotalQty = document.getElementById("Overall_total_quantity").value;

        if (Number(totalQty) === Number(updatedTotalQty)) {
          setTotalQuantity(total)
          axios
            .post(ServerUrl + "/add-order-sku", apiencrypt({
              "company_id": getLoginCompanyId,
              "workspace_id": getWorkspaceId,
              "order_id": orderId,
              "sku": sku,
              "total_qty": updatedTotalQty,
              "tolerance_percent": (basicInfo.tolerance_perc).toString(),
              "tolerance_volume": ((updatedTotalQty / 100) * basicInfo.tolerance_perc).toString(),
              "user_id": getLoginUserId,
              "staff_id": getLoginStaffId ? getLoginStaffId : 0,
            }))
            .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code === 200) {
                Swal.fire({
                  title: t(response.data.message),
                  icon: "success",
                  button: t("okLabel"),
                  timer:5000,
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                      // setTimeout(() => {
                        window.location.href = '/ordercontacts?id=' +
                          encode(orderId);
                      // }, 100);
                    }
                    setTimeout(() => {
                      window.location.href = '/ordercontacts?id=' +
                        encode(orderId);
                    }, 100);
                  })
              }
            })

        }
        else {
          if( basicInfo.is_tolerance_req === "0"){
            Swal.fire({
              title: t("totalQuanitityDiffer",{totalQty:totalQty,updatedTotalQty:updatedTotalQty}),
              text: t("qtyDiffersTextAlert"),
              icon: "warning",
              button: t("okLabel"),
              showCancelButton: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setTotalQuantity(total)
                axios
                  .post(ServerUrl + "/add-order-sku", apiencrypt({
                    "company_id": getLoginCompanyId,
                    "workspace_id": getWorkspaceId,
                    "order_id": orderId,
                    "sku": sku,
                    "total_qty": updatedTotalQty,
                    "tolerance_percent": (basicInfo.tolerance_perc).toString(),
                    "tolerance_volume": ((updatedTotalQty / 100) * basicInfo.tolerance_perc).toString(),
                    "user_id": getLoginUserId,
                    "staff_id": getLoginStaffId ? getLoginStaffId : 0,
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                    if (response.data.status_code === 200) {
                      Swal.fire({
                        title: t(response.data.message),
                        icon: "success",
                        button: t("okLabel"),
                        timer: 5000,  
                      })
                        .then((result) => {
                          if (result.isConfirmed) {
                            // setTimeout(() => {
                              window.location.href = '/ordercontacts?id=' +
                                encode(orderId);
                            // }, 100);
                          }
                          setTimeout(() => {
                            window.location.href = '/ordercontacts?id=' +
                              encode(orderId);
                          }, 100);
                        })
                    }
                  })
              }
            });
          }
          else {
            Swal.fire({
              title: t("totalQuanitityDiffer",{totalQty:totalQty,updatedTotalQty:updatedTotalQty}),
              text: t("qtyDiffersTextAlert"),
              icon: "warning",
              button: t("okLabel"),
              showCancelButton: true,
            })
            .then((result) => {
              if (result.isConfirmed) {
                setTotalQuantity(total)
                axios
                  .post(ServerUrl + "/add-order-sku", apiencrypt({
                    "company_id": getLoginCompanyId,
                    "workspace_id": getWorkspaceId,
                    "order_id": orderId,
                    "sku": sku,
                    "total_qty": updatedTotalQty,
                    "tolerance_percent": (basicInfo.tolerance_perc).toString(),
                    "tolerance_volume": ((updatedTotalQty / 100) * basicInfo.tolerance_perc).toString(),
                    "user_id": getLoginUserId,
                    "staff_id": getLoginStaffId ? getLoginStaffId : 0,
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                    if (response.data.status_code === 200) {
                      Swal.fire({
                        title: t(response.data.message),
                        icon: "success",
                        button: t("okLabel"),
                        timer:5000
                      })
                        .then((result) => {
                          if (result.isConfirmed) {
                            // setTimeout(() => {
                              window.location.href = '/ordercontacts?id=' +
                                encode(orderId);
                            // }, 100);
                          }
                          setTimeout(() => {
                            window.location.href = '/ordercontacts?id=' +
                              encode(orderId);
                          }, 100);
                        })
                    }
                  })
              }
            });
          }    
        }
      }
    }
  };

  /******************************************** DELETE COLOR  *************/
  const deleteColor = (e) => 
  {
    var getColor=e.target.id;
    Swal.fire({
      title: t("colorDeleteAlert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete"),
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed)
      {
        setshowColor(current =>
          current.filter((showcolor, v) => {
            return showcolor !== getColor;
          }),
        );
        setColor(currentv =>
          currentv.filter((color, vf) => {
            return color.name !== getColor;
          }),
        );
        document.getElementById("colorId").selectedIndex = 0; 
        document.getElementById("Overall_total_quantity").value=0;
        getTotalColorWise('delete');
        getTotalSizeWise('delete');
        getOverallTotal('delete');
      
      
      }
    })
   
  };

  /********************************************  DELETE SIZE  *************/
  const deleteSize = (e) => {
    var getSize=e.target.id;
    Swal.fire({
      title: t("sizeDeleteAlert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete"),
      confirmButtonColor: '#d33',
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed)
      {
    setshowSize(current =>
      current.filter((showsize, v) => {
        return showsize !== getSize;
      }),
    );

    setSize(currentv =>
      currentv.filter((size, vf) => {
        return size.name !== getSize;
      }),
    );
    document.getElementById("sizeId").selectedIndex = 0; 
    document.getElementById("Overall_total_quantity").value=0;
    getTotalColorWise('delete');
    getTotalSizeWise('delete');
    getOverallTotal('delete');
  }
})
  };

  /*********** MOVES TO NEXT INPUT FIELD ( WHEN ENTER IS CLICKED ) **************/
  function handleEnter(event) {
    if ((event.keyCode === 13 || event.keyCode === 9) && event.target.nodeName === "INPUT") {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);

      if (form.elements[index + 1].readOnly == false) {
        form.elements[index + 1].focus();

      } else {

        if (form.elements[index + 2].readOnly == false) {
          form.elements[index + 2].focus();

        } else {
          // let index=0;
          if (form.elements[index + 3].readOnly == false) {
            form.elements[index + 3].focus();

          } else {
            if (form.elements[index + 4].readOnly == false) {
              let indexv = index + 4;
              form.elements[indexv].focus();
            } else {
              let index = 0;
              form.elements[index].focus();
            }
          }
        }
      }

    }
    // event.preventDefault();
  };

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  const [ sizeCategoryList, setSizeCategoryList ] = useState([]);
  const [ allSkuDetails, setAllSkuDetails ] = useState({});
  const [ categoryName, setCategoryName ] = useState(0);

  useEffect(() => {

      axios
      .post(ServerUrl + "/get-all-sku", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setAllSkuDetails(response.data.data);
        setGetColor(response.data.data.colors);
        setGetSize(response.data.data.sizes);
      })

      axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(basicInfoParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setStepLevel(response.data.data[0].step_level);
        setBasicInfo(response.data.data[0]);
        setToleranceVolume(response.data.data[0].tolerance_volume);
        setTolerancePercent(response.data.data[0].tolerance_perc);
        
        if(response.data.data[0].status != 1 )
        {
          window.location.href = "/dashboard" 
        }
        else {
          ""
        }

      })

      axios
      .post(ServerUrl + "/get-size-category", apiencrypt(getCategoryInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setSizeCategoryList(response.data.data);
      })

      {
        (((getStaff === "Staff" && getStaffPermission.includes('Add Order'))||getStaff == null )) 
        ?
        ""
        :
        window.location.href = "/dashboard" 
      }  

  }, []);

/* Size Drag start */
   
  const dragItem = useRef();
  const dragOverItem = useRef();
  const dragItemv = useRef();
  const dragOverItemv = useRef();
  const dragStartSize = (e, position) => {
    dragItem.current = position;
    dragItemv.current = position;
    //console.log(e.target.innerHTML);
  };
 
  const dragEnterSize = (e, position) => {
    dragOverItem.current = position;
    dragOverItemv.current = position;
    //console.log(e.target.innerHTML);
  };
 
  const dropSize = (e) => {
    const copyListItems = [...size];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
   
    setSize(copyListItems);

    const copyListItemss = [...showsize];
    const dragItemContentss = copyListItemss[dragItemv.current];
    copyListItemss.splice(dragItemv.current, 1);
    copyListItemss.splice(dragOverItemv.current, 0, dragItemContentss);
    dragItemv.current = null;
    dragOverItemv.current = null;
     setshowSize(copyListItemss);
     reAssignFieldValue();
    };
/* Size Drag End */
/* color Drag start */

const dragItemColor = useRef();
const dragOverItemColor = useRef();
const dragItemvColorv = useRef();
const dragOverItemvColorv = useRef();
const dragStartColor = (e, position) => {
  dragItemColor.current = position;
  dragItemvColorv.current = position;
 // console.log(e.target.innerHTML);
};

const dragEnterColor = (e, position) => {
  dragOverItemColor.current = position;
  dragOverItemvColorv.current = position;
  //console.log(e.target.innerHTML);
};

const dropColor = (e) => {
  const copyListItemsc = [...color];
  const dragItemContentc = copyListItemsc[dragItemColor.current];
  copyListItemsc.splice(dragItemColor.current, 1);
  copyListItemsc.splice(dragOverItemColor.current, 0, dragItemContentc);
  dragItemColor.current = null;
  dragOverItemColor.current = null;
 
  setColor(copyListItemsc);

  const copyListItemscv = [...showcolor];
  const dragItemContentssc = copyListItemscv[dragItemvColorv.current];
  copyListItemscv.splice(dragItemvColorv.current, 1);
  copyListItemscv.splice(dragOverItemvColorv.current, 0, dragItemContentssc);
  dragItemvColorv.current = null;
  dragOverItemvColorv.current = null;
   setshowColor(copyListItemscv);
   reAssignFieldValue();
  };
  
  /* ReAssign Color and Size Qty zero After Drag */
  const reAssignFieldValue=()=>{
    Array.from(document.querySelectorAll('.inpwidthsmall')).forEach(
      input => (input.value = "")
    );
  }
/* color Drag end */
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>

          <CardBody>
            <OrderHeaders title="addsku" />
          </CardBody>

          <CardBody>
            <Form className="needs-validation" noValidate="">
              {/*start first row */}
              <Card className="shadow shadow-case">
                <CardBody>
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  <H6>{t("sKUDetails")}</H6>
                  <Row className="g-12">
                    <Col lg="6" md="6" sm="12">
                      <FormGroup className="">
                        <Label>{t("color")}</Label>
                        <InputGroup>
                          <Input
                            id="colorId"
                            type="select"
                            className="js-example-basic-single form-control"
                            isMulti
                            onChange={(e) => {handleChangeColor(e)}}>
                            <option selected disabled >{t("selectColor")}</option>
                            {getColor.map(colorList =>
                            (
                              <>
                                <option key={colorList.id} value={colorList.id}>
                                  {colorList.name}
                                </option>
                              </>
                            )
                            )
                            }
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Color Add')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={toggle}
                              >
                                {t("add")}
                              </span>
                            </InputGroupText> : ""
                          }
                          <AddColorModal
                            modal={modal}
                            toggle={toggle}
                            inputParams={getInputParams}
                            color={setGetColor} />
                        </InputGroup>
                      </FormGroup>
                      {showcolor.map((colour, index) =>
                      (
                        <span className="btn btn-primary m-r-5 m-t-5"
                          id={colour}
                          name={colour}
                          onDragStart={(e) => dragStartColor(e, index)}
                          onDragEnter={(e) => dragEnterColor(e, index)}
                          onDragEnd={dropColor}
                          key={index}
                          draggable
                          onClick={(e) => deleteColor(e)}>
                          {colour}
                        </span>
                      )
                      )}
                    </Col>
                    <Col lg="6" md="6" sm="12">
                      <FormGroup>
                        <Label> {t("sizeCategory")} </Label>
                        <InputGroup>
                          <Input 
                            id="categoryId"
                            type="select"
                            className="js-example-basic-single form-control"
                            onChange={(e) => {handleChangeCategory(e.target.value)}}>
                            <option selected value="0">{t("selSizeCategory")}</option>
                            
                            {sizeCategoryList.map((mapData,i) => (
                              categoryName==mapData.category ?
                              <option key={i} selected>{mapData.category}</option>
                              :
                              <option key={i}>{mapData.category}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Size Add')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={toggleCategory}
                              >
                                {t("add")}
                              </span>
                            </InputGroupText> : ""
                          }
                          <AddCategoryModal
                            modal={modalCategory}
                            toggle={toggleCategory}
                            orderId={orderId}
                            setCategoryName={setCategoryName}
                            setAllSkuDetails={setAllSkuDetails}
                            setGetColor={setGetColor}
                            setGetSize={setGetSize}
                            setSizeCategoryList={setSizeCategoryList}
                            setshowColor={setshowColor}
                            setshowSize={setshowSize}
                            setColor={setColor}
                            setSize={setSize}
                          />
                        </InputGroup>
                      </FormGroup>

                      { categoryName !=0 && 
                        <Col lg="12" md="12" sm="12">
                          <FormGroup>
                            <Label>{t("size")}</Label>
                            <InputGroup>
                              <Input 
                                id="sizeId"
                                type="select"
                                className="js-example-basic-single form-control"
                                onChange={(e) => {handleChangeSize(e)}}>
                                <option selected disabled >{t("selectSize")}</option>
                                { getSize.length > 0 ?
                                  getSize.map(sizeList =>
                                  (
                                    <option key={sizeList.id} value={sizeList.id} attr-name={sizeList.name}  >
                                      {sizeList.name}
                                    </option>
                                  )
                                  )
                                :
                                "" }
                              </Input>
                              {(getStaff === "Staff" && getStaffPermission.includes('Size Add')) || getStaff == null ?
                                <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                    onClick={togglepcu}
                                  >
                                    {t("add")}
                                  </span>
                                </InputGroupText> : ""
                              }
                              <AddSizeModal
                                modal={modalpcu}
                                toggle={togglepcu}
                                sizes={getSize}
                                setGetSize={setGetSize}
                                setAllSkuDetails={setAllSkuDetails}
                                setGetColor={setGetColor}
                                categoryName={categoryName}
                                setCategoryName={setCategoryName}
                                orderId={orderId}
                                setSizeCategoryList={setSizeCategoryList}
                              />
                            </InputGroup>
                          </FormGroup>
                          {
                            showsize.length > 0 ? 
                            showsize.map((sizes,index) => (
                              <span className="btn btn-primary m-r-5 m-t-5" id={sizes}
                              onDragStart={(e) => dragStartSize(e, index)}
                              onDragEnter={(e) => dragEnterSize(e, index)}
                              onDragEnd={dropSize}
                              key={index}
                              draggable
                                onClick={(e) => deleteSize(e)}>
                                {sizes}
                              </span>
                            )) : ""
                          }
                        </Col>
                      }
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
              </Row>
                </CardBody>
              </Card>
            {color.length > 0 && size.length > 0 ?
            <>
            <Card className="mt-5 shadow shadow-case">
              <CardBody>
              <Row className="g-12 m-t-20" >
                {/*start Add Qty */}
                {color.length > 0 && size.length > 0
                  ?
                  <>
                
                    <Row style={{ borderBottom:"1px solid #f2f2f2" ,marginTop:"-30px" }}>
                      <Col xl="6" className="text-right m-b-20 d-flex align-items-center">
                        <H6 style={{ backgroundColor:"blue" }}>{t("addQty")}</H6>
                      </Col>
                      <Col xl="6" className="verticalAlignmentEnd m-b-20" >
                        <table style={{backgroundColor: '#f2f2f2' }}>
                            <thead  className="f-w-300 text-center"
                                style={{ fontSize: '13px'}}>
                                <td style={{ padding: '10px' }}>
                                   {t("totalQty")}
                                </td>
                                <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', }}>
                                  {t("toleranceQty")}
                                </td>
                                <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', }}>
                                  {t("tolerance%")}
                                </td>
                                <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', 
                                            borderRight: '2px solid #f2f2f2' }}>
                                  {t("orderQty")}
                                </td>
                            </thead>
                            <tbody className="f-w-600">
                                <td style={{ padding: '10px' }} className="text-center">
                                  {
                                    basicInfo.is_tolerance_req === "1"
                                    ?
                                    (basicInfo.tolerance_volume)+(basicInfo.quantity)
                                    :
                                    basicInfo.quantity
                                  }
                                </td>
                                <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2' }} >
                                  {toleranceVolume === null ? 0 : toleranceVolume}
                                </td>
                                <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2' }} >
                                  {toleranceVolume === null ? 0 : tolerancePercent}
                                </td>
                                <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2',
                                            borderRight: '2px solid #f2f2f2'}}>
                                 {basicInfo.quantity}
                                </td>
                            </tbody>
                        </table>
                      </Col>
                      
                    </Row>
                   
                    <Col md="12" lg="12" sm="12" className="m-t-20">
                      <Row className="g-12">
                        <div className="table-responsive">
                          {/* <form id="countQty"> */}
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col"> {t("color/sizeLabel")} </th>
                                  {size.map((option,j) => {
                                    return <th key={j} className="middle"> {option.name}</th>;
                                  })}
                                  <th scope="col">{t("totalLabel")}</th>{" "}
                                </tr>
                              </thead>
                              <tbody>
                                {color.map((optionc,k) => {
                                  return (
                                    <tr key={k}>
                                      <th className="middle">{optionc.name}</th>
                                      {size.map((option,l) => {
                                        return (
                                          <th key={l}>
                                            <Row>
                                              <Row>
                                                <Table className="table table-striped">
                                                  {/* <tr><td style={{ fontWeight:'400', fontSize:'11px' }}>Qty</td><td>Final Qty</td></tr> */}
                                                    <tr>
                                                    <td>
                                                      <table>
                                                        <tbody className="f-w-600 text-center">
                                                          <tr>
                                                            <td style={{ padding: '0.1em' }}>
                                                              <input
                                                                style={{ width: '90px' }}
                                                                className=" form-control inpwidthsmall middle"
                                                                name="userName"
                                                                id={optionc.id + "#" + option.id}
                                                                type="number"
                                                                placeholder="0"
                                                                autoComplete="off"
                                                                min="0"
                                                                onChange={(e) => { addQty(e) }}
                                                                onKeyDown={handleEnter}
                                                                onKeyPress={(e) => handleKeyPress(e)}
                                                                />

                                                              <input
                                                                type="hidden"
                                                                style={{ width: '90px' }}
                                                                id={optionc.id + "+" + option.id}
                                                                className=" form-control inpwidthsmall"
                                                                readOnly
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td style={{ padding: '0.1em' }}
                                                              className="showperqty">
                                                              <span id={optionc.id + "v" + option.id} className="showperqty"></span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                      <td>
                                                        <input
                                                          type="hidden"
                                                          style={{ width: '90px', marginLeft: '10px' }}
                                                          id={optionc.id + "@" + option.id}
                                                          className=" form-control inpwidthsmall"
                                                          readOnly
                                                        />
                                                      </td>
                                                    </tr>
                                                  </Table>
                                              </Row>
                                            </Row>
                                          </th>
                                        );
                                      }
                                      )}
                                      <th>
                                        <input
                                          className="form-control inpwidthsmall mt-3"
                                          name="totalQuantity"
                                          type="number"
                                          readOnly
                                          placeholder={t("totalQty")}
                                          id={"totqty_" + optionc.id} />
                                      </th>
                                    </tr>
                                  );
                                })}

                                <tr>
                                  <th></th>
                                  {size.map((data,m) => {
                                    return (
                                      <>
                                        <th key={m}>
                                          < input
                                            className="form-control inpwidthsmall"
                                            id={"SizeId_total_quantity" + data.id}
                                            type="number"
                                            placeholder="0"
                                            autoComplete="on"
                                            readOnly
                                            onKeyDown={handleEnter}
                                          />

                                        </th>
                                      </>)
                                  })}
                                  <th>
                                    <input
                                      className="form-control inpwidthsmall"
                                      id="Overall_total_quantity"
                                      type="number"
                                      placeholder="0"
                                      autoComplete="off"
                                      readOnly
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          {/* </form> */}
                        </div>
                      </Row>
                    </Col>
                 </>
                  :
                  <div></div>}
                {/*end Add qty */}
                <Col md="12" lg="12" sm="12">
                  &nbsp;
                </Col>
              </Row>
  </CardBody>
</Card>
               
              </>:""}
              {/*end second row*/}

              <Row className="g-12">
                <Col>
                  {" "}

                  <FormGroup className="f-right">
                    {/* <Button>Save and Continue</Button> */}
                    {/* <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>{t("next")}</Link> */}
                    &nbsp;&nbsp;
                    <Button
                      className="btn btn-primary"
                      onClick={(e) => { addSkuData()}}
                      // disabled={((datecount > 0))  ? false : true}>
                      // disabled={((stepLevel == 5))  ? true : false}
                      >
                      {t("saveBtn")}
                    </Button>
                  </FormGroup>
{/*                   
                  <FormGroup className="f-right"> 
                    <button
                      id="saveBtn"
                      className="btn btn-primary"
                      onClick={(e) => { 
                        addSkuData() }}>
                      {t("saveAndContinue")}
                    </button>
                  </FormGroup>  */}
                </Col>
              </Row>

            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default AddSku;
/***** CODE BY : RAMANAN M  *****/