import { Row, Card, Col, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H4, P } from "../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import {  getLoginCompanyId,  getLoginUserId,  getWorkspaceId, 
  getLoginStaffId,  getStaff,} from "../../Constant/LoginConstant";
import { useTranslation } from "react-i18next";
import { apiencrypt, apidecrypt } from "../../helper";
import deleyed_animate from "../../assets/images/dms/icons/delayedanimate.webp";
import active_orders_animat from "../../assets/images/dms/icons/active-orders_animate.webp";
import ontime_order_animate from "../../assets/images/dms/icons/ontimeanimate.webp";
import risky_orders_animate from "../../assets/images/dms/icons/risky-orders_animate.webp";

const DashboardOrderDetails = () => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [getordertaskDetails, setgetordertaskDetails] = useState("");
  const staff_id = getLoginStaffId;
  const { t } = useTranslation();
  var requestData;
  getStaff === "Staff"
    ? (requestData = { company_id, workspace_id, staff_id })
    : (requestData = { company_id, workspace_id, user_id });

  useEffect(() => {
    axios.post(
        ServerUrl + "/new-dashboard-widgets",
        apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        })
      ).then((response) => {
        response.data = apidecrypt(response.data);
        setgetordertaskDetails(response.data);
      });
  }, []);

  return (
    <Fragment>
      <Row style={{ width: '100%', marginLeft: '5px', padding: '0px' }}>
        <Col lg="6" md="6" sm="12" xs="12" xl="3" xxl="3">
          <Card
            className
            style={{ backgroundColor: "#61A8E6", border: "1px solid #61A8E6" }}>
            <span style={{ color: "#fff" }} className="m-t-10 p-l-15">
              <span className="f-16"> {t("activeOrders")} </span>
            </span>
            <span>
              <img style={{ width: "65px" }} className="m-t-15 p-l-15"
                src={active_orders_animat} />
              <span className="circle text-end m-b-15 m-r-15"
                style={{ width: "70px", height: "70px" }}>
                <span className="f-w-600 f-22" style={{ color: "white" }}>
                  {getordertaskDetails.activeOrder ? getordertaskDetails.activeOrder : 0}
                </span>
              </span>
            </span>
          </Card>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12" xl="3" xxl="3">
          <Card style={{ backgroundColor: "#13B8B4", border: "1px solid #13B8B4", marginRight: '3px' }} >
            <span style={{ color: "#fff" }} className="m-t-10 p-l-15">
              <span className="f-16"> {t("onTimeOrders")} </span>
            </span>
            <span>
              <img style={{ width: "70px" }}className="m-t-15 p-l-15"
               src={ontime_order_animate} />
              <span className="circle text-end m-b-15 m-r-15"
                style={{ width: "70px", height: "70px" }} >
                <span className="f-w-600 f-22" style={{ color: "white" }}>
                  {getordertaskDetails.onTimeOrders ? getordertaskDetails.onTimeOrders : 0}
                </span>
              </span>
            </span>
          </Card>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12" xl="3" xxl="3">
          <Card style={{ backgroundColor: "#FE9365", border: "1px solid #FE9365", marginRight: '3px' }} >
            <span style={{ color: "#fff" }} className="m-t-10 p-l-15">
              <span className="f-16"> {t("delayedOrders")} </span>
            </span>
            <span>
              <img style={{ width: "70px" }}  className="m-t-15 p-l-15" src={deleyed_animate}/>
              <span className="circle text-end m-b-15 m-r-15"
                style={{ width: "70px", height: "70px" }} >
                <span className="f-w-600 f-22" style={{ color: "white" }}>
                  {getordertaskDetails.delayedOrder ?  getordertaskDetails.delayedOrder : 0}
                </span>
              </span>
            </span>
            {/* <span className='f-24'> <CountUp end={superAdminDetails.plan} duration={5} /> </span> */}
            {/* <img src={plan_dashboard} className="img-50 f-right" /> */}
          </Card>
        </Col>

        <Col lg="6" md="6" sm="12" xs="12" xl="3" xxl="3" style={{  paddingRight: '15px' }}>
          <Card style={{ backgroundColor: "#F1556C", border: "1px solid #F1556C" }}>
            <span style={{ color: "#fff" }} className="m-t-10 p-l-15">
              <span className="f-16"> {t("riskyOrders")} </span>
            </span>
            <span>
              <img style={{ width: "70px" }}   className="m-t-15 p-l-15" src={risky_orders_animate} />
              <span style={{ width: "70px", height: "70px" }} className="circle text-end m-b-15 m-r-15"  >
                <span className="f-w-600 f-22" style={{ color: "white" }}>
                  {getordertaskDetails.riskyOrders ?  getordertaskDetails.riskyOrders : 0}
                </span>
              </span>
            </span>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DashboardOrderDetails;
