import React, { useState , useEffect} from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Button,
  Table
} from "reactstrap";
import { Btn, H5 } from "../../../AbstractElements";
import { ServerUrl } from "../../../Constant";
import { getLoginCompanyId, getWorkspaceId, getOrderId, getStaff, getStaffPermission } from '../../../Constant/LoginConstant';
// import DatePicker from "react-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { apiencrypt, apidecrypt } from "../../../helper";
// import TemplateModal from "../CreateOrders/AddTemplateModal"
// import FormAddTaskSubTaskModal from "../CreateOrders/AddTaskSubTaskModal"
import downAccordion_icon from '../../../assets/images/dms/icons/For Accordion_icon.svg'
import up_accordion_icon from '../../../assets/images/dms/icons/up_accordion_icon.svg'
const EditOrderTask = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [personInCharge, setPersonInCharge] = useState([]);
  let templateTasks = props.tasks;
  const selectedTemplateId = props.selectedTemplate;
  const workspaceID = props.workspaceId;
  const companyID = props.companyId;
  const orderID = props.orderId;
  const templateData = props.templateData;
  // const [isTemplateChanged, setIsTemplateChanged] = useState("0");
  const [addTaskModal, setAddTaskModal] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    axios.post(ServerUrl+"/get-order-contacts",apiencrypt({
      "company_id": companyID,
      "workspace_id": workspaceID,
      "order_id": orderID
    })).then((response)=>{
      response.data = apidecrypt(response.data)
      setPersonInCharge(response.data.data);
    }) 
    
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Edit Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    } 
    
  }, []);
  // if (isTemplateChanged === "1"){
  //   setIsTemplateChanged("0");
  // }
  return (
    <>
      <Row>
        <Col sm="12" xl="12">
          <div className="default-according" id="accordion">
            {templateTasks.map((template) =>
              template.id == selectedTemplateId
                ? JSON.parse(template.task_template_structure).map((templates,i) => {
                    let tData = templateData[i]['task_subtitles'];
                    return(
                            <Card key={i}>
                            <CardHeader  onClick={()=>toggle(i)}  className="p-1 cursor-pointer" >
                                <H5  attrH5={{ className: "m-0 p-0" }}>
                                <Btn
                                    attrBtn={{
                                    as: Card.Header,
                                    className: "btn btn-link",
                                    color: "default",
                                    onClick: () => toggle(i),
                                    }}
                                >
                                    <p>{templates.task_title}</p>
                                </Btn>
                                {/* {isOpen === i ? <span className="m-t-10 p-r-5 accdview f-right"><i className="fa fa-angle-up"></i></span>
                            : <span className="m-t-10 p-r-5  accdview  f-right"><i className="fa fa-angle-down"></i></span>} */}
                            {isOpen === i ? <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5"  title={t("close")}   src={up_accordion_icon}/></span>
                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5"  title={t("open")}   src={downAccordion_icon}/></span>}
                                </H5>
                            </CardHeader>
                            <Collapse isOpen={isOpen === i} >
                                <CardBody>
                                <div className="table-responsive">
                                <Table className="m-t-5">
                                  <th  className="taskUpdate-tasktable-header">{t("task")}</th>
                                  <th  className="taskUpdate-tasktable-header">{t("startDate")}</th>
                                  <th  className="taskUpdate-tasktable-header">{t("endDate")}</th>
                                  <th  className="taskUpdate-tasktable-header">{t("pic")}</th>
                                {templates.task_subtitles.map((subtitle,j)=>(
                                  tData[j].subtitle === subtitle? 
                                    <>
                                          <tr className="taskUpdate-tr-border">
                                            <input type="hidden" name="subtitle" id={templates.task_title+"||"+subtitle+"||name"} 
                                            defaultValue={subtitle}/>
                                            <td className="askUpdate-tasktable-sideHeader">
                                                {subtitle}
                                            </td>
                                            {tData[j].accomplished_date != null ? 
                                              <>
                                                <td>
                                                    <Input
                                                    type="date"
                                                    id={templates.task_title+"||"+subtitle+"||startdate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    defaultValue={tData[j].start_date}
                                                    disabled
                                                    >
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                    type="date"
                                                    id={templates.task_title+"||"+subtitle+"||enddate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    defaultValue={tData[j].end_date}
                                                    disabled
                                                    >
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                    type="select"
                                                    defaultValue=""
                                                    id={templates.task_title+"||"+subtitle+"||pic_id"}
                                                    className="pic_select"
                                                    disabled
                                                    >
                                                    <option value="" disabled>{t("selectPIC")}</option>
                                                    {personInCharge.length>0 ? personInCharge.map((pic)=>(
                                                    tData[j].pic_id === pic.staff_id
                                                    ?<option value={pic.staff_id} selected>{pic.staff_name}</option> :
                                                    <option value={pic.staff_id}>{pic.staff_name}</option>
                                                    )): ""}
                                                    </Input>
                                                </td>
                                              </> : 
                                              <>
                                                <td>
                                                    <Input
                                                    type="date"
                                                    id={templates.task_title+"||"+subtitle+"||startdate"}
                                                  min={(getStaff === "Staff" && getStaffPermission.includes('Early Task Start') || getStaff == null)?  ""  :props.basicInfo.inquiry_date != null ? props.basicInfo.inquiry_date:
                                                  new Date().toISOString().split('T')[0]
                                         
                                                }
                                      // min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    defaultValue={tData[j].start_date}
                                                    onKeyDown={(e) => {
                                                      e.preventDefault();
                                                   }}
                                                   onChange={(e)=>{
                                                      document.getElementById(templates.task_title+"||"+subtitle+"||enddate")
                                                      .setAttribute("min",e.target.value);
                                                      // document.getElementById(templates.task_title+"||"+subtitle+"||enddate")
                                                      // .disabled=false;
                                                    }}
                                                    >
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                    type="date"
                                                    id={templates.task_title+"||"+subtitle+"||enddate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    // disabled={true}
                                                    defaultValue={tData[j].end_date}
                                                    onKeyDown={(e) => {
                                                      e.preventDefault();
                                                   }}
                                                    >
                                                    </Input>
                                                </td>
                                                <td>
                                                    <Input
                                                    type="select"
                                                    defaultValue=""
                                                    className="pic_select"
                                                    id={templates.task_title+"||"+subtitle+"||pic_id"}
                                                    >
                                                    <option value="" disabled>{t("Selectpic")}</option>
                                                    {personInCharge.length>0 ? personInCharge.map((pic)=>(
                                                    tData[j].pic_id === pic.staff_id
                                                    ?<option value={pic.staff_id} selected>{pic.staff_name}</option> :
                                                    <option value={pic.staff_id}>{pic.staff_name}</option>
                                                    )): ""}
                                                    </Input>
                                                </td>
                                              </>
                                            }
                                          </tr>
                                    </>:""
                                ))}
                                </Table>
                                </div>
                                </CardBody>
                            </Collapse>
                            </Card>

                    )})
                : ""
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EditOrderTask;
/* Code By : Rithanesh */