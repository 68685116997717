import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import {
    Card, CardBody, Col, Row, Table, Collapse,
    FormGroup, InputGroup, Input, Label, Progress, CardFooter
} from 'reactstrap';
import Moment from 'moment';
import PackIcon from "../../../assets/images/dms/icons/packIcon.svg";

import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const PackKnobChartrequired = ({packTodayUpdatedValue,packActualTargetValue,packEndDate,packPerDay}) => {
    const { t, i18n } = useTranslation();
    let packEstimatedRatePercentage = Math.round((packTodayUpdatedValue/packActualTargetValue)*100);
    let currentDate = Moment().format('YYYY-MM-DD');
    let endDate=Moment(packEndDate).format('DD MMM YYYY');
   /******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
    const knobChartColor = () => {
        if((new Date(packEndDate).getTime() >= new Date(currentDate).getTime())){
            return '#00B38E'; 
        }
        // else if((packActualTargetValue>=packPerDay)){
        //     return '#00B38E'; 
        // }
        else{
            return '#FF3169';
        }
        // if(  (packEstimatedRatePercentage >= 0) && (packEstimatedRatePercentage <= 40)){
        //     return '#FF3169';
        // }
        // if( (packEstimatedRatePercentage > 40) && (packEstimatedRatePercentage <= 70)){
        //     return '#E58F1F';
        // }
        // if( (packEstimatedRatePercentage > 70) && (packEstimatedRatePercentage <= 100) || (packEstimatedRatePercentage > 100)){
        //     return '#00B38E';
        // }
    }
    useEffect(() => {

        var offsetArc = Knob({
            value: packPerDay?packPerDay: 0,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: packPerDay
        });
        const packlist = document.getElementById('packknobreqiure');
        if (packlist.hasChildNodes()) {
            packlist.removeChild(packlist.children[0]);
        }

        document.getElementById('packknobreqiure').appendChild(offsetArc);
    }, [packPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align" >
                <div className="knob text-center m-t-5" id="packknobreqiure">
                </div>
                <div className=' text-muted f-12' style={{ color: "#828282" }}>{t("reqAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                <div className=' f-w-600 ' >{(PHPtoJSFormatConversion.format(new Date(packEndDate)))}</div>
                <div className=' text-muted f-12' style={{ color: "#828282" }}>{t("scheduleComDt")}</div>
                {/* <div style={{ backgroundColor: "#13A1C1" }} className='taskCardBorder'>
                    <img src={PackIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Packing</span>
                </div> */}
            </Card>
        </>
    );
}
export default PackKnobChartrequired;