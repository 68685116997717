import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, CardHeader, Collapse, FormGroup, Table, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getStaff } from "../../Constant/LoginConstant";
import noData from "../../../src/assets/images/dms/nodata.png"
import { apiencrypt, apidecrypt } from "../../helper";
import { useTranslation } from 'react-i18next';
import { PHPtoJSFormatConversion } from '../../helper';
const PaymentHistory = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const { t } = useTranslation();
    let detailsToSend;
    detailsToSend = {
        company_id: company_id,
        user_id: getLoginUserId,
    }
    const [ paymentHistoryData, setPaymentHistoryData] = useState([]);

    useEffect(() => {
        getStaff === "Staff" ?
        window.location.href = "/dashboard"
        :
        axios
            .post(ServerUrl + "/payment-history", apiencrypt(detailsToSend))
            .then((response) => {
                response.data= apidecrypt(response.data)
                setPaymentHistoryData(response.data.data);
            });

    }, []);

    return (
        <Fragment> 
            <Row  className="pgbgcolor">
            <Breadcrumbs mainTitle={t('Payment History')} 
                         parent={t('Payment History')} 
                         title={t('Payment History')} />
            </Row>
            <Container fluid={true} className="general-widget topaln"> 
            <Card style={{ height:"100vh" }} >
                {paymentHistoryData.length>0 ?
                <>
                        
                <Card className="m-t-40 m-l-20 m-r-20">
                {/* <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 ">
                                                <span className="p-r-5"></span> {t("Payment History")}<span className="p-r-5"></span>
                                                </Label>
                     </span> */}
                    <CardBody className="p-0 shadow shadow-showcase ">
                               <div className="table-responsive table table-bordered">
                                <Table >
                                    <thead>
                                    <tr style={{ backgroundColor:"#ABD8D4" }}>
                                        <th> {t("planName")} </th>
                                        <th> {t("planType")} </th>
                                        <th> {t("planPrice")} </th>
                                        <th> {t("paymentStatus")} </th>
                                        <th> {t("reason")} </th>
                                        <th> {t("paymentDate")} </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        { paymentHistoryData.map((mapData,i) => (
                                            <tr key={i}>
                                            <td> { mapData.plan_name} </td>
                                            <td> { mapData.plan_type} </td>
                                            <td> { mapData.plan_price} </td>
                                            <td> { mapData.payment_status} </td>
                                            <td> { mapData.reason == null ? "--" : mapData.reason } </td>
                                            <td> 
                                            {PHPtoJSFormatConversion.format(new Date(mapData.payment_date)) } 
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                </div>
                        </CardBody>
                    </Card>
                    
                </>
                : 
                <>
                    <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                        <img src={noData} style={{ width:"200px"}} />
                        <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                    </div>
                </> 
                }
                </Card>
                </Container>
        </Fragment>
    );
}

export default PaymentHistory;
/* Code By : Ramanan M */