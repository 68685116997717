import React , {useState,useEffect}from "react";
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Button } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { Close, SaveChanges , ServerUrl} from "../../Constant";
import  axios  from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { getLoginUserId, getLoginCompanyId, getWorkspaceId, getStaff, getLoginStaffId, getStaffPermission } from "../../Constant/LoginConstant"
import { apidecrypt,apiencrypt, PHPtoJSFormatConversion,capitalizeFirstLowercaseRest } from "../../helper";
const NewTaskUpdateModal = ({ modal, toggle, taskID, templateData, orderId, ForReload, personInCharge, taskSubtitle, setStyle, taskInfo,
  isSubtask,setIsSubtask, subtaskDetails,  inquiryDate,inquiry_dates ,create_dates,setIsSubtaskDetails}) => {
    
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pic, setPic] = useState("");
  const [subtaskTitle, setSubtaskTitle] = useState("");
  const { t } = useTranslation();
  let dataForSend={};
  getStaff === "Staff" ? dataForSend = {
    taskId: taskID,
    startDate : startDate,
    endDate : endDate,
    picId : pic,
    company_id : getLoginCompanyId,
    workspace_id : getWorkspaceId,
    staffId : getLoginStaffId,
  } : 
  dataForSend = {
    taskId: taskID,
    startDate : startDate,
    endDate : endDate,
    picId : pic,
    company_id : getLoginCompanyId,
    workspace_id : getWorkspaceId,
    userId : getLoginUserId,
  };

  useEffect(() => {
    if(!modal){
      setStartDate("");
      setEndDate("");
      setPic("");
      setIsSubtask("");
      setIsSubtaskDetails("");
      setValidErrors("");
      setSubtaskTitle("")
    }
  });
  /* Validation Starts */
  const [errors, setValidErrors] = useState({});
  const validation = ()=>{
    let errors = {};
    if(isSubtask == "Subtask"){
      if(!subtaskTitle){
        errors.subtaskTitle = ("subtaskTitleValidation")
      }
      // if(startDate !=""){
      //   if(!endDate){
      //     errors.endDate = ("endDateValidation")
      //   }
      //   if(!pic){
      //     errors.pic = ("pleaseSelectPic")
      //   }
      // }
      // if(startDate !=""){
      //   if(!endDate){
      //     errors.endDate = ("endDateValidation")
      //   }
      //   if(!pic){
      //     errors.pic = ("pleaseSelectPic")
      //   }
      // }
      // if(endDate != ""){
      //   if(!startDate){
      //     errors.startDate = ("startDateValidation")
      //   }
      //   if(!pic){
      //     errors.pic = ("pleaseSelectPic")
      //   }
      // }
      // if(pic != ""){
      //   if(!startDate){
      //     errors.startDate = ("startDateValidation")
      //   }
      //   if(!endDate){
      //     errors.endDate = ("endDateValidation")
      //   }
      // }
      if(!startDate){
        errors.startDate = ("startDateValidation")
      }
      if(!endDate){
        errors.endDate = ("endDateValidation")
      }
      if(!pic){
        errors.pic = ("pleaseSelectPic")
      }
    }else{
      if(!startDate){
        errors.startDate = ("startDateValidation")
      }
      if(!endDate){
        errors.endDate = ("endDateValidation")
      }
      if(!pic){
        errors.pic = ("pleaseSelectPic")
      }
    }
    setValidErrors(errors);
    return errors;
  };
  /* Validation Ends */
  const onSaveHandle = () =>{
   
    
     
      if(isSubtask == "Subtask"){
        let retval = validation();
      if (Object.keys(retval).length == 0) {
        if(startDate>endDate){
          Swal.fire({
            title: t("startDatemustbeLesserthanorEqualtoEndDate"),
            icon: "error",
            button: t("okLabel"),})
        }
         
        else{
          let dataToSend={};
          getStaff === "Staff" ? dataToSend = {
            taskId: subtaskDetails.taskID,
            order_id : subtaskDetails.orderID,
            template_id : subtaskDetails.templateID,
            cat_title : subtaskDetails.taskTitle,
            task_title :taskSubtitle ,
            company_id : getLoginCompanyId,
            workspace_id : getWorkspaceId,
            staffId : getLoginStaffId,
            userId : getLoginUserId,
          } : 
          dataToSend = {
            taskId: subtaskDetails.taskID,
            order_id : subtaskDetails.orderID,
            template_id : subtaskDetails.templateID,
            cat_title : subtaskDetails.taskTitle,
            task_title :taskSubtitle ,
            company_id : getLoginCompanyId,
            workspace_id : getWorkspaceId,
            userId : getLoginUserId,
          };
          dataToSend.subtask_title = document.getElementById("subtaskTitle").value;
          if(startDate!="" && endDate!="" && pic!=""){
            dataToSend.startDate = startDate;
            dataToSend.endDate = endDate;
            dataToSend.picId = pic;
          }
          axios.post(ServerUrl+"/add-subtask", apiencrypt(dataToSend)). then((response)=>{
            response.data = apidecrypt(response.data)
          if (response.data.status_code === 200) {
              Swal.fire({
              title: t("updatedSuccessfully"),
              icon: "success",
              button: t("okLabel"),
              allowOutsideClick: false,
              timer:5000,
              }).then((result) => {
              if (result.isConfirmed) {
                ForReload(1);
                setStartDate("");
                setEndDate("");
                setPic("");
                setSubtaskTitle("")
                /* To show the updated taskData */
                axios
                .post(ServerUrl + "/get-task-data", apiencrypt({
                  company_id: getLoginCompanyId,
                  workspace_id: getWorkspaceId,
                  order_id : orderId
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  toggle(false);
                  templateData(response.data.data);
                  axios.post(ServerUrl + "/get-style",apiencrypt({
                    company_id: getLoginCompanyId,
                    workspace_id: getWorkspaceId,
                    orderId : orderId
                  })).then((response)=>{
                    response.data = apidecrypt(response.data);
                    setStyle(response.data.data);
                    taskInfo(response.data.taskInfoData);
                    ForReload(0);
                    })
                  });
              }
              setTimeout(() => {
                ForReload(1);
                setStartDate("");
                setEndDate("");
                setPic("");
                setSubtaskTitle("")
                /* To show the updated taskData */
                axios
                .post(ServerUrl + "/get-task-data", apiencrypt({
                  company_id: getLoginCompanyId,
                  workspace_id: getWorkspaceId,
                  order_id : orderId
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  toggle(false);
                  templateData(response.data.data);
                  axios.post(ServerUrl + "/get-style",apiencrypt({
                    company_id: getLoginCompanyId,
                    workspace_id: getWorkspaceId,
                    orderId : orderId
                  })).then((response)=>{
                    response.data = apidecrypt(response.data);
                    setStyle(response.data.data);
                    taskInfo(response.data.taskInfoData);
                    ForReload(0);
                    })
                  });
              }, 100);
              });
          }
          /* To show error if the Data is null*/
          if (response.data.status_code === 400) {
              Swal.fire({
              title: t(response.data.message),
              icon: "error",
              button: t("okLabel"),
              timer:5000,
            });
          }
          // if (response.data.status_code === 401) {
          //     Swal.fire({
          //     title: t(response.data.error.startDate)||t(response.data.error.endDate)||t(response.data.error.picId),
          //     icon: "error",
          //     button: t("okLabel"),
          //   });
          // }
          if (response.data.status_code === 600) {
            Swal.fire({
            title:t(response.data.message),
            icon: "error",
            button: t("okLabel"),
            timer:5000,
          });
        }
          })
      } }
    }
    else{
      let retval = validation();
      if (Object.keys(retval).length == 0) {
        axios.post(ServerUrl+"/update-task", apiencrypt(dataForSend)). then((response)=>{
          response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
            Swal.fire({
            title: t("updatedSuccessfully"),
            icon: "success",
            button: t("okLabel"),
            allowOutsideClick: false,
            timer:5000,
            }).then((result) => {
            if (result.isConfirmed) {
              ForReload(1);
              setStartDate("");
              setEndDate("");
              setPic("");
              /* To show the updated taskData */
              axios
              .post(ServerUrl + "/get-task-data", apiencrypt({
                company_id: getLoginCompanyId,
                workspace_id: getWorkspaceId,
                order_id : orderId
              }))
              .then((response) => {
                response.data = apidecrypt(response.data)
                toggle(false);
                templateData(response.data.data);
                axios.post(ServerUrl + "/get-style",apiencrypt({
                  company_id: getLoginCompanyId,
                  workspace_id: getWorkspaceId,
                  orderId : orderId
                })).then((response)=>{
                  response.data = apidecrypt(response.data);
                  setStyle(response.data.data);
                  taskInfo(response.data.taskInfoData);
                  ForReload(0);
                  })
                });
            }
            setTimeout(() => {
              ForReload(1);
              setStartDate("");
              setEndDate("");
              setPic("");
              /* To show the updated taskData */
              axios
              .post(ServerUrl + "/get-task-data", apiencrypt({
                company_id: getLoginCompanyId,
                workspace_id: getWorkspaceId,
                order_id : orderId
              }))
              .then((response) => {
                response.data = apidecrypt(response.data)
                toggle(false);
                templateData(response.data.data);
                axios.post(ServerUrl + "/get-style",apiencrypt({
                  company_id: getLoginCompanyId,
                  workspace_id: getWorkspaceId,
                  orderId : orderId
                })).then((response)=>{
                  response.data = apidecrypt(response.data);
                  setStyle(response.data.data);
                  taskInfo(response.data.taskInfoData);
                  ForReload(0);
                  })
                });  
            }, 100);
            });
        }
        /* To show error if the Data is null*/
        if (response.data.status_code === 400) {
            Swal.fire({
            title: t(response.data.message),
            icon: "error",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 401) {
            Swal.fire({
            title: t(response.data.error.startDate)||t(response.data.error.endDate)||t(response.data.error.picId),
            icon: "error",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 600) {
          Swal.fire({
          title:t(response.data.message),
          icon: "error",
          button: t("okLabel"),
        });
      }
        })
      }
      }
    }
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      {isSubtask == "Subtask"?
      <>
        <ModalHeader>{t("subTask")} - {taskSubtitle}</ModalHeader> 
        <ModalBody>
                <Label className="col-form-label" for="recipient-name">
                    {t("subTaskTitle")}
                </Label>
                <Input
                 className={`${errors.subtaskTitle && 'select-error-valid'}  form-control`}
                type="text"
                id="subtaskTitle"
                placeholder={t("subTaskTitle")}
                onChange={(e) => {setSubtaskTitle(e.target.value= capitalizeFirstLowercaseRest(e.target.value) )}}
                />
                {errors.subtaskTitle && (
                              <><span className="error-msg">{t(errors.subtaskTitle)}</span><br/></>
                            )}
                <Label className="col-form-label" for="recipient-name">
                    {t("startDate")}
                </Label>
                <Input
                 className={`${errors.startDate && 'select-error-valid'}  form-control`}
                type="date"
                id="startDateSubTask"
                min={subtaskDetails.startDate != null ? subtaskDetails.startDate 
                  : 
                                      
                  new Date().toISOString().split('T')[0]
                   
                }
                // min={
                //   (getStaff === "Staff" && getStaffPermission.includes('Inquiry Date') || getStaff == null)?  ""  
                //   :inquiry_dates != null ? inquiry_dates:subtaskDetails.startDate != null ? subtaskDetails.startDate :
                                      
                //   new Date().toISOString().split('T')[0]
                   
                // }
                  //  new Date().toISOString().split('T')[0]}
                max={subtaskDetails.endDate}
                // max={subtaskDetails.endDate}
                onChange={(e) => {setStartDate(e.target.value);
                document.getElementById("endDateSubTask")
                .setAttribute("min",e.target.value);
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                />
                {errors.startDate && (
                              <><span className="error-msg">{t(errors.startDate)}</span><br/></>
                            )}
                <Label className="col-form-label" for="recipient-name">
                    {t("endDate")}
                </Label>
                <Input
                className={`${errors.endDate && 'select-error-valid'}  form-control`}
                type="date"
                id="endDateSubTask"
                min={subtaskDetails.startDate}
                max={subtaskDetails.endDate}
                onChange={(e) => setEndDate(e.target.value)}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                />
                {errors.endDate && (
                  <><span className="error-msg">{t(errors.endDate)}</span><br/></>
                )}
                <Label className="col-form-label" for="recipient-name">
                {t("personInCharge")}
                </Label>
                <Input
                 className={`${errors.pic && 'select-error-valid'}  form-control`}
                type="select"
                id="picSubTask"
                defaultValue=""
                onChange={(e) => setPic(e.target.value)}
                >
                <option value="" disabled>{t("Selectpic")}</option>
                {personInCharge.map((pic)=>(
                <option value={pic.staff_id}>{pic.staff_name}</option>
                ))}
                </Input>
                {errors.pic && (
                  <><span className="error-msg">{t(errors.pic)}</span><br/></>
                )}
        </ModalBody>
      </> 
      :
      <>
        <ModalHeader>{t("updateTask")} - {taskSubtitle}</ModalHeader> 
        <ModalBody>
                  {isSubtask === "SubtaskAdd"? 
                  <>
                    <span className="font-primary">{t("startDate")} : </span><span>{subtaskDetails.startDate!=''? PHPtoJSFormatConversion.format(new Date(subtaskDetails.startDate)):t("noMainTask")}</span><br/>
                    <span className="font-primary">{t("endDate")} : </span><span>{subtaskDetails.endDate!=''?PHPtoJSFormatConversion.format(new Date(subtaskDetails.endDate)):t("noMainTask")}</span><br/>
                  </> : ""}
                  <Label className="col-form-label" for="recipient-name">
                      {t("startDate")}
                  </Label><sup className="font-danger">*</sup> 
                  {isSubtask === "SubtaskAdd" ? 
                    <Input
                    className="form-control"
                    type="date"
                    id="startDate"
                    min={subtaskDetails.startDate}
                    max={subtaskDetails.endDate}
                    onChange={(e) => {setStartDate(e.target.value);
                    document.getElementById("endDate")
                    .setAttribute("min",e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    />:
                   <Input
                    className="form-control"
                    type="date"
                    id="startDate"
                    min={((getStaff === "Staff"&&getStaffPermission.includes('Early Task Start'))||getStaff == null)?"":
                     (inquiryDate != null) ? inquiryDate : create_dates}


                    // min={(getStaff === "Staff" && getStaffPermission.includes('Inquiry Date') || getStaff == null)?  ""  
                    // :inquiryDate != null ? inquiryDate:create_dates
                                      
                                        // new Date().toISOString().split('T')[0]
                                         
                                      
                    onChange={(e) => {setStartDate(e.target.value);
                    document.getElementById("endDate")
                    .setAttribute("min",e.target.value);
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    />
                  }
                  {errors.startDate && (
                    <><span className="error-msg">{t(errors.startDate)}</span><br/></>
                  )}
                  <Label className="col-form-label" for="recipient-name">
                      {t("endDate")}
                  </Label><sup className="font-danger">*</sup>
                  {isSubtask === "SubtaskAdd" ? 
                    <Input
                    className="form-control"
                    type="date"
                    id="endDate"
                    min={subtaskDetails.startDate}
                    max={subtaskDetails.endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    /> :
                    <Input
                    className="form-control"
                    type="date"
                    id="endDate"
                    min={new Date().toISOString().split('T')[0]}
                    onChange={(e) => setEndDate(e.target.value)}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    />
                  }
                  {errors.endDate && (
                    <><span className="error-msg">{t(errors.endDate)}</span><br/></>
                  )}
                  <Label className="col-form-label" for="recipient-name">
                  {t("personInCharge")}
                  </Label> <sup className="font-danger">*</sup>
                  <Input
                  type="select"
                  defaultValue=""
                  onChange={(e) => setPic(e.target.value)}
                  >
                  <option value="" disabled>{t("Selectpic")}</option>
                  {personInCharge.map((pic)=>(
                  <option value={pic.staff_id}>{pic.staff_name}</option>
                  ))}
                  </Input>
                  {errors.pic && (
                              <><span className="error-msg">{t(errors.pic)}</span><br/></>
                            )}
        </ModalBody>
      </> 
      }  
      <ModalFooter>
        <Button className="btn secondaryBtn" onClick={toggle}>{t("close")}</Button>
        <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default NewTaskUpdateModal;
/* Code By : Rithanesh */