import React, { useState } from "react";
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label, Input } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { Close, SaveChanges , ServerUrl } from "../../../Constant";
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../../helper";
const FormAddPCUModal = ({ modal, toggle, companyId , workspaceId , pcu  }) => {
  
  const {t} = useTranslation();
  const [pcuName, setPcuName] = useState("");

  const onSaveHandle = () => {
    if(!pcuName){
      Swal.fire({
        title: t("pcuName"),
        text: t("pcuValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    }else{
    axios
      .post(ServerUrl + "/create-user-pcu", apiencrypt({
        company_id: companyId,
        workspace_id: workspaceId,
        name: pcuName,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("pcuAddedAlert"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer:5000,
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-user-pcu", apiencrypt({
                  company_id: companyId,
                  workspace_id: workspaceId,
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  pcu(response.data.data);
                });
            }
            setTimeout(() => {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
              .post(ServerUrl + "/get-user-pcu", apiencrypt({
                company_id: companyId,
                workspace_id: workspaceId,
              }))
              .then((response) => {
                response.data = apidecrypt(response.data)
                pcu(response.data.data);
              });
            }, 100);
          });
        }
        /* To show error if the Role is already present*/
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            text:  t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
          });
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t("pcu"),
            text: t(response.data.error.name),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader> {t("addPcu")} </ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
        {t("addPcu")}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setPcuName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary  btn-sm", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddPCUModal;
/* Code By : Rithanesh */