import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Table,
} from "reactstrap";
import ScrollBar from 'react-perfect-scrollbar';
import { P, H5, H2, H6, H3, Btn, UL, LI } from "../../../AbstractElements";

import OrderHeaders from "../CreateOrders/OrderHeaders";
import factory from "../../../assets/images/dms/factory.png";
import buyer from '../../../assets/images/dms/onGoingList.svg';
import pcu from "../../../assets/images/dms/pcu.png";
import userimg from "../../../assets/images/dms/user.png";
import EditTaskCategory from "./EditTaskCategory";
import Dropzone from "react-dropzone-uploader";
import { BuckyBarnes, ServerUrl, maxUploadFileSize, maxNoFileCount, maxFileUpload } from "../../../Constant/index";
import { useSearchParams, Link } from "react-router-dom";
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getOrderId, getStaff, getStaffPermission } from '../../../Constant/LoginConstant';
import Swal from "sweetalert2";
import {
  sizeConvertor, allowedExtensions, DownloadFile, encode, decode, allowedExtensionsUpload, allowedUploadFormat,
  calculateDateDiffCountFromCurrentDate, truncate, apiencrypt,apidecrypt,PHPtoJSFormatConversion 
} from "../../../helper";
import Files from 'react-files';
import noData from "../../../assets/images/dms/nodata.png";
import file_icon from '../../../assets/images/dms/icons/file_icon.svg'
import delete_icon from '../../../assets/images/dms/icons/delete_icon.svg'
import download_iconTask from '../../../assets/images/dms/icons/download_iconTask.svg'
import { useTranslation } from "react-i18next";
import Moment from 'moment';

const EditTask = (props) => {

  const { t } = useTranslation();

  const [savetoggleTemplateModal, setSavetoggleTemplateModal] = useState(false);
  const workspaceId = getWorkspaceId;
  const companyId = getLoginCompanyId;
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [templateNames, setTemplateNames] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [basicInfo, setBasicInfo] = useState([]);
  const [files, setFiles] = useState([]);
  const [savedTemplateId, setSavedTemplateId] = useState("")
  const [getData, setGetData] = useState([]);
  const [taskCuttingDate, setTaskCuttingDate] = useState('');
  const [datecount, setdatecount] = useState(0);
  const [delDate, setDelDate] = useState([]);
  const [errors, setValidErrors] = useState({});
 
  /****************  To handle files and set it in the state *****************/
  const handleChangeStatus = ({ meta, file }, status) => {
    if (meta.status === "error_file_size") {
      Swal.fire({
        title: t("fileSizeExceeded"),
        text: t("uploadFileWithin2MB"),
        icon: "warning",
        button: t("okLabel"),
      })
    }
    else {
      if (allowedExtensions.exec(file.name)) {
        if (status === "done") {
          setFiles([...files, file])
        }
      }
    }
  };
  function ndeleteFile(id) {
    Swal.fire({
      title: "Are you sure you want to delete this file?",
      icon: "warning",
      button: t("okLabel"),
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const filteredfiles = files.filter((e, index) => {
          return index !== id;
        })
        setFiles(filteredfiles);
      }})

  }
  let asv = 0;
  const nonFilesChange = (files) => {
    //console.log("files",uploadedFiles.length,files.length,maxFileUpload)
    if (files.length > maxFileUpload) {
      Swal.fire({
        title: t("exceededLimitTitleAlert"),
        text: t("onlyFileLimitValidation",{filesLimit:maxFileUpload}),
        // text: t("filesLimitTextAlert1") + " " + maxFileUpload + " " + t("filesLimitTextAlert2"),
        icon: "warning",
        button: t("okLabel"),
      })
    } else {
      const fileCount = (uploadedFiles.length + files.length);
      // console.log(fileCount,"fileCount==>",maxNoFileCount);
      if (fileCount > maxNoFileCount) {
        Swal.fire({
          title: t("maxNoFileCountValidationText",{maxFileCount:maxNoFileCount}),
          text: t("alreadyUploadedFilesCountValidation",{uploadedFilesCount:uploadedFiles.length}),
          // title: t("maxFilesTitleAlert1") + " " + maxNoFileCount + " " + t("maxFilesTitleAlert2"),
          // text: t("alreadyUploadedTextAlert1") + " " + uploadedFiles.length + " " + t("alreadyUploadedTextAlert2"),
          icon: "warning",
          button: t("okLabel"),
        })
      }
      else if (files.length > maxFileUpload) {
        Swal.fire({
          title: t("fileExceededTitleAlert"),
          text: t("onlyFileLimitValidation",{filesLimit:maxFileUpload}),
          icon: "warning",
          button: t("okLabel"),
        })
      } else {
        const filteredfiles = files.filter((e, index) => {
          if (allowedExtensionsUpload.exec(e.name)) {
            // console.log("file size==>",e.size);
            const getFileSize = Math.round((e.size / 1024));
            if (getFileSize > maxUploadFileSize) {
              Swal.fire({
                title: t("fileSizeExceeded"),
                text: t("uploadFileTalidationText",{fileSize: (maxUploadFileSize/ 1024)}  ),
                // text: t("uploadaFileSize") + (maxUploadFileSize / 1024) + " MB",
                icon: "warning",
                button: t("okLabel"),
              })
              asv = 1;
              return false;
            }
            //if(e.size)
            return true;
          } else {
            Swal.fire({
              title: t("wrongFormatTitleAlert"),
              text: t("uploadFollowingFileFormat") + allowedUploadFormat,
              icon: "warning",
              button: t("okLabel"),
            })
            asv = 1;
            return false;
          }

        })

        if (asv == 0) {
          setFiles(files)
          return files
        }
        asv = 0;
      }
    }
  }
  const nonFilesError = (error, file) => {
    setFiles(file)

  }
  // const handleRemove = ()=>{

  // }
  /***************  For the Handling of Submit of the Files  **************/
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
    onFileSubmit();
  };
  const onFileSubmit = () => {
    // document.getElementById("totalSave").setAttribute("disabled",true);
    axios.post(ServerUrl + "/add-files",
      {
        "company_id": companyId,
        "workspace_id": workspaceId,
        "order_id": orderId,
        "additional_spec": files
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("fileAddedTitleAlert"),
            icon: "success",
            button: t("okLabel"),
          }).then((result) => {
            if (result.isConfirmed) {
              setFiles([]);
              // document.getElementById("totalSave").removeAttribute("disabled");
              getUploadedFiles();
              if (result.isConfirmed) {
                setTimeout(() => {
                  window.location.href = '/orderview?id=' +
                    encode(orderId);
                }, 100);
              }
            }
          })
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title:
              t(response.data.errors.company_id)||
              t(response.data.errors.workspace_id) ||
              t(response.data.errors.order_id),
            text: t("fieldMissing"),
            icon: "warning",
            button: t("okLabel"),
            timer:5000,
          });
        }
      })
  };

  var currentDate = Moment().format('YYYY-MM-DD');

  /************** To Call the API's **************/
  useEffect(() => {
    axios.post(ServerUrl + "/get-order-template", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId
    })).then((response) => {
      response.data = apidecrypt(response.data);
      setTemplateNames(response.data.data);
    })
    axios.post(ServerUrl + "/get-basic-info", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId
    })).then((response) => {
      response.data = apidecrypt(response.data);
      setBasicInfo(response.data.data)
      setTaskCuttingDate(response.data.data[0].cutting_start_date)
      setDelDate(response.data.delivery_dates);
      if (response.data.data[0].cutting_start_date != null) {
        const dateCountv = calculateDateDiffCountFromCurrentDate(response.data.data[0].cutting_start_date);
        setdatecount(dateCountv);
      } else {
        setdatecount('1');
      }

      if(response.data.data[0].status != 1 )
      {
        window.location.href = "/dashboard" 
      }
      else {
        ""
      }
    })
    axios.post(ServerUrl + "/get-task-data", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId
    })).then((response) => {
      response.data = apidecrypt(response.data)
      setGetData(response.data.data);
      setSelectedTemplate(response.data.templateID);
    });
    getUploadedFiles();

    {
      (((getStaff === "Staff" && getStaffPermission.includes('Edit Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    } 
    
  }, []);


  let sub_arr = [];
  const orderValidation = () => {
    let errors = {};
    let req_param = {};
  templateNames.map((template) => template.id == selectedTemplate ? 
  JSON.parse(template.task_template_structure).map((templates) => {
  /* This variable is to set the position of the object in the array in the below loop  */
  let i = 0
  let sub_req_param = {};
  var task_title = templates.task_title;
  templates.task_subtitles.map((subtitle) => {
    if(document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||startdate'));
    }
    if(document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||enddate'));
    }
    if( document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||pic_id'));

    }
   
   
  })
  
  // req_param[task_title] = sub_req_param;
}) : "")
if(sub_arr.length>0){
 var splitvalue= sub_arr[0].split('||');
  
  Swal.fire({
    title: (splitvalue[0]+" "+"("+splitvalue[1]+")"),
    // text: ("Please Select the"+ " "+((splitvalue[2]=="startdate")?"Start date":splitvalue[2]=="enddate"?"End Date":splitvalue[2]=="pic_id"?"Incharge":"")),
    text: (((splitvalue[2]=="startdate")?t("selectStartDate"):splitvalue[2]=="enddate"?t("selectEndtDate"):splitvalue[2]=="pic_id"?t("pleaseSelectPic"):"")),
    icon: "warning",
    button: t("okLabel"),
  });
}
  
    setValidErrors(errors);
    return errors;
  };
  /****************  To get the list of files and set it in the state *****************/
  const getUploadedFiles = () => {
    axios.post(ServerUrl + "/list-files", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
    })).then((response) => {
      response.data = apidecrypt(response.data);
      setUploadedFiles(response.data.data);
    })
  }
  /***************** To Download the Files  ***************/
  const downloadFile = (filename, orgFileName) => {
    axios.post(ServerUrl + "/download-file", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
      "fileName": filename
    }), { responseType: 'blob' })
      .then((response) => {
        DownloadFile(response.data, orgFileName);
      })
  }
  /**************To Delete the File **************/
  const deleteFile = (filename) => {
    Swal.fire({
      title: t("deleteConfirmationTitleAlert"),
      text: filename,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete")
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(ServerUrl + "/delete-file", apiencrypt({
          "company_id": companyId,
          "workspace_id": workspaceId,
          "order_id": orderId,
          "fileName": filename
        })).then((response) => {
          response.data= apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("deletedSuccessfullyTitleAlert"),
              icon: "success",
              button: t("okLabel"),
            }).then((result) => {
              if (result.isConfirmed) {
                getUploadedFiles();
              }
            })
          }
        })
      }
    })
  }
  /****************  To get the values entered in the template and set it in an array *****************/
  const onSubmitHandle = () => {
    orderValidation()

    if (sub_arr.length==0){
    let req_param = {};
    templateNames.map((template) => template.id == selectedTemplate ? JSON.parse(template.task_template_structure).map((templates) => {
      let i = 0
      let sub_req_param = {};
      var task_title = templates.task_title;
      templates.task_subtitles.map((subtitle) => {
        let sub_arr = {};
        sub_arr.title = document.getElementById(templates.task_title + '||' + subtitle + '||name').value;
        sub_arr.startdate = document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value;
        sub_arr.enddate = document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value;
        sub_arr.pic_id = document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value;
        sub_req_param[i] = sub_arr;
        i++;
      })
      req_param[task_title] = sub_req_param;
    }) : "")
    /****************  To list of the datas to be sent in the API Call *****************/
    const submitData = {
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
      "template_id": selectedTemplate,
      "template_data": req_param
    };
    axios.post(ServerUrl + "/update-order-tasks", apiencrypt(submitData))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("taskAddedTitleAlert"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer: 5000
          }).then((result) => {
            if (result.isConfirmed) {
              // setTimeout(() => {
                window.location.href = "/orderview?id="+encode(orderId);
              // }, 100);
            }
            setTimeout(() => {
              window.location.href = "/orderview?id="+encode(orderId);
            }, 100);
          });
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title:
              t(response.data.errors.company_id) ||
              t(response.data.errors.workspace_id) ||
              t(response.data.errors.order_id) ||
              t(response.data.errors.template_id) ||
              t(response.data.errors.template_data),
            text: t("fieldMissing"),
            icon: "warning",
            button: t("okLabel"),
          });
        }
      })
    }
  }
  if (taskCuttingDate != null) {
    // const dateCountv = calculateDateDiffCountFromCurrentDate(regCuttingDate);
    //setdatecount(dateCountv);
  }
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody>
            <OrderHeaders title="addtask" />
          </CardBody>

          <CardBody>
           
              <Row>
                <Col lg="8" md="12" sm="12">
                  <Card  className=" table-bordered mx-2 shadow-sm shadow-showcase">
                        <Row>
                          <Col lg="12" md="12" sm="12" xs="10">
                          <span className="task_title f-left skudetailsclr p-1 m-l-10">
                          <span className="p-l-5"> </span>{t("basicInformation")}
                          <span className="p-l-5"> </span></span>
                          </Col>
                        </Row>
                      {basicInfo.map((information,i) => (
                        <Row key={i}className="m-t-10 m-b-10">

                          <Col>
                            <FormGroup>
                              <Label  className="m-t-5"><span className="f-w-600 m-l-10" style={{color:"#000"}}>{t("orderNo")}</span></Label>
                              <InputGroup className="m-l-10" style={{color:"#969696 "}}>
                              {information.order}
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col className="b-r-order">
                            <FormGroup>
                              <Label  className="m-t-5"> <span className="f-w-600 m-l-10" style={{color:"#000"}} >{t("styleNo")}</span></Label>
                              <InputGroup className="m-l-10" style={{color:"#969696"}}>
                              {information.style}
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          <Col className="b-r-order">
                            <FormGroup>
                              <Label   className="m-t-5">
                                {/* <img src={userimg} alt={t("users")} /> */}
                              <span className="f-w-600 m-l-10" style={{color:"#000"}}>{t("workSpace")}</span> </Label>
                              <InputGroup className="m-l-10" style={{color:"#969696"}}>
                              {information.workspace}
                              </InputGroup>
                            </FormGroup>
                          </Col>

                          {information.factory != null ?
                          <Col className="b-r-order">
                            <FormGroup>
                              <Label   className="m-t-5"> 
                              {/* <img src={factory} alt={t("factory")} /> */}
                              <span className="f-w-600 m-l-10" style={{color:"#000"}}>{t("factory")}</span></Label>
                              <InputGroup className="m-l-10" style={{color:"#969696"}}>
                              {information.factory}
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          :""}

                            {information.pcu != null ?
                          <Col className="b-r-order">
                            <FormGroup>
                              <Label   className="m-t-5">
                                {/* <img src={pcu} alt={t("pcu")} /> */}
                              <span className="f-w-600 m-l-10" style={{color:"#000"}}>{t("productionControlUnit")}</span></Label>
                              <InputGroup className="m-l-10" style={{color:"#969696"}}>
                              {information.pcu}
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          :""}
                          
                          {information.buyer != null ?
                          <Col className="b-r-order">
                            <FormGroup>
                              <Label   className="m-t-5">  
                              {/* <img src={buyer} alt={t("buyer")} /> */}
                            <span className="f-w-600 m-l-10" style={{color:"#000"}}>{t("buyer")}</span>  </Label>
                              <InputGroup className="m-l-10" style={{color:"#969696"}}>
                              {information.buyer}
                              </InputGroup>
                            </FormGroup>
                          </Col>
                          :""}
                    
                        </Row>
                      ))}
                  </Card>
                </Col>

                <Col lg="4" md="12" sm="12" className="p-r-0">
                  <Card className="table-bordered shadow-sm shadow-showcase" style={{ paddingRight: '10px' }}>
                        <Row>
                          <Col lg="12" md="12" sm="12" xs="10">
                          <span className="task_title f-left skudetailsclr p-1 m-l-10">
                          <span className="p-l-5"> </span>{t("delDate")}
                          <span className="p-l-5"> </span></span>
                          </Col>
                        </Row>
                  
                        <Row style={{ overflowY: 'scroll', height: '80px' }} 
                             className="m-t-10">
                          <Col>
                            <FormGroup>
                              <InputGroup>
                                  {
                                    delDate.length > 0 
                                    ?
                                      delDate.map((dataDeliveryDate) => (
                                            dataDeliveryDate.is_delivered == 0 ? 
                                                currentDate > dataDeliveryDate.delivery_date ? 
                                                  <span className="btn deliveryBtn text-center"
                                                        style={{ height: '35px', width: '110px',
                                                          borderRadius: '18px', borderColor: '#cccccc',
                                                          marginLeft: '10px', marginTop: '12px',
                                                          marginBottom: '10px', fontSize: '12px',
                                                          color: '#EE335E', fontWeight: 600, cursor: 'unset'
                                                        }}>
                                                    {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                                  </span> 
                                                : 
                                                  <span className="btn deliveryBtn text-center"
                                                        style={{ height: '35px', width: '110px',
                                                          borderRadius: '18px', borderColor: '#cccccc',
                                                          marginLeft: '10px', marginTop: '12px',
                                                          marginBottom: '10px', fontSize: '12px',
                                                          color: '#878787', fontWeight: 600, cursor: 'unset'
                                                        }}>
                                                    {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                                  </span>
                                              : 
                                              dataDeliveryDate.updated_date > dataDeliveryDate.delivery_date ? 
                                                  <span className="btn deliveryBtn text-center"
                                                        style={{ height: '35px', width: '110px',
                                                          borderRadius: '18px', borderColor: '#cccccc',
                                                          marginLeft: '10px', marginTop: '12px',
                                                          marginBottom: '10px', fontSize: '12px',
                                                          color: '#E69020', fontWeight: 600, cursor: 'unset'
                                                        }}>
                                                  {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                                  </span> 
                                                : 
                                                  <span className="btn deliveryBtn text-center"
                                                        style={{ height: '35px', width: '110px',
                                                          borderRadius: '18px', borderColor: '#cccccc',
                                                          marginLeft: '10px', marginTop: '12px',
                                                          marginBottom: '10px', fontSize: '12px',
                                                          color: '#009688', fontWeight: 600, cursor: 'unset'
                                                        }}>
                                                    {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                                  </span>
                                      ))
                                      :
                                      "-"
                                  }
                              </InputGroup>
                            </FormGroup>
                          </Col>
                        </Row>
                  </Card>
                </Col>

              </Row>

              <Row className="g-12">
              <Card className="m-t-5 table-bordered g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                <Col md="12" lg="12" sm="12">
                <Row>
                  <Col lg="12" md="12" sm="12" xs="10">
                    <span className="subTitleOrderView f-left skudetailsclr p-1 m-l-10">
                    <span className="p-l-5"> </span>{t("uploadFiles")}
                    <span className="p-l-5"> </span></span>
                  </Col>
                </Row>
                    <Row className="g-12">
                    <Col lg="2" md="2" sm="12">
                      <FormGroup>
                      {(getStaff === "Staff" && getStaffPermission.includes('Task File Upload')) || getStaff == null ?
                <FormGroup>
                  <Files
                    className='files-dropzone fileContainer'
                    onChange={nonFilesChange}
                    onError={nonFilesError}
                    accept=".png,.jpg,.jpeg,.docx,.doc,.html,.htm,.xls,.xlsx,.txt,.ppt,.odf,.pptx,.csv,.rtf,.odt,.ods,.pdf"
                    multiple={true}
                    canCancel={false}
                    // onSubmit={handleSubmit}
                    clickable
                  >
                    <div className=" justify-content-center">
                      <Btn attrBtn={{ className: "mt-3 ml-2", type: "button", color: 'primary' }}>{t("Browse Files")}</Btn>
                    </div> </Files>
                </FormGroup> : ""}
                        <InputGroup>
                        {/* {information.order} */}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    

                    <Col lg="6" md="6" sm="12">
                      
                      
                        <InputGroup>
                        {files.length > 0
                  ? <div className='files-gallery'>
                    <Row >
                      {files.map((file, fi) =>
                        <Col >
                          <table className=" m-t-10"  width="100%">
                            <tr>
                              <td className="" width="5%">
                              <img  className="cursor-pointer" src={file_icon} />
                              </td>
                              <td className="">
                                <p style={{color:"#969696"}} className="cursor-pointer m-l-10 f-left f-12 f-w-600">{file.name}<br />
                                  <small>{sizeConvertor(file.size)}</small></p>
                              </td>
                              <td className='cursor-pointer' align="right" valign="center" width="5%">
                              <img  title={t("deleteFile")} onClick={() => ndeleteFile(fi)} src={delete_icon} /> 
                              </td>
                            </tr>
                          </table>
                        </Col>
                      )}
                      <Col  style={{float:"left"}}>
                        {files.length > 0 ?
                      <div >
                        <Button className="btn secondaryBtn m-t-15"
                          onClick={() => onFileSubmit()}
                        >{t("saveBtn")}</Button>
                      </div> : ""}</Col>
                    </Row>
                    
                  </div> : ''}
                  
                        </InputGroup>
                      
                    </Col>
                    </Row>
                  <Row >
                  {uploadedFiles.length > 0 ?
                  <>
                  <div  style={{backgroundColor:"#F6F6F6",padding:"10px"}}><span   >Uploaded Files</span>
                  </div>
                
                    {uploadedFiles.map((file) => (
                  <Col  className=" p-t-0 p-b-5 " 
                  style={{backgroundColor:"#F6F6F6"}}
                  >
                      <FormGroup>
                        
                        <InputGroup>
                  <table  className="" >
                    <tr>
                      <td className=""  >
                        {/* <i className="fa fa-file-o f-10"></i> */}
                        <img className="cursor-pointer"   title={file.name} src={file_icon} />
                      </td>
                      <td className="">
                        <p style={{color:"#969696"}} className=" f-left f-12 f-w-600 m-l-10">{truncate(file.orginalfilename)}<br />
                          <small>{sizeConvertor(file.filesize)}</small></p>
                      </td>
                      <td className='cursor-pointer' valign="center" width="5%">
                        {(getStaff === "Staff" && getStaffPermission.includes('Task File Delete')) || getStaff == null ?
                          // <a href="javascript:void(0)" title={t("downloadFile")} onClick={() => deleteFile(file.filename, file.orginalfilename)}><i className="fa fa-trash f-16 p-r-10 font-dark"></i></a>
                          <img   title={t("delete File")} onClick={() => deleteFile(file.filename, file.orginalfilename)} src={delete_icon} alt={t("factory")} /> 
                          : ""}
                      </td>
                      <td className='' align="right" valign="center" width="5%" >
                      
                      <img className="fa fa-download f-16 font-dark cursor-pointer " src={download_iconTask}  title={t("downloadFile")} onClick={() => downloadFile(file.filename, file.orginalfilename)}  alt={t("factory")} /> 
                        {/* <a href="javascript:void(0)" title={t("downloadFile")} onClick={() => downloadFile(file.filename, file.orginalfilename)}><i className="fa fa-download f-16 font-dark "></i></a> */}
                      </td>
                      
                    </tr>
                  </table>
                {/* </Col> */}
              
          
          {/* </Row> */}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    ))} </> : ""}
                    </Row>
                  
                

                </Col>
              </Card>
              </Row>
              <Row className="g-12 m-t-20">
                <Col md="12" lg="12" sm="12">
                  <H6>{t("Task")}</H6>
                  <Row className="g-12">
                    <Col md="3" lg="3" sm="6" xs="12">
                      <Label>{t("selectedTemplate")}</Label>
                      <Input
                        type="select"
                        className="form-control digits"
                        name="user_type"
                        disabled
                      >
                        <option value="" disabled>
                          {t("selectTemplate")}
                        </option>
                        {templateNames.map((templateName) => (
                          selectedTemplate === templateName.id ? <option value={templateName.id} selected>{templateName.template_name}</option>
                            : <option value={templateName.id}>{templateName.template_name}</option>))}
                      </Input>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col md="12" lg="12" sm="12">
                  &nbsp;
                </Col>
                <Col md="12" lg="12" sm="12">
                  {selectedTemplate !== "" ? <EditTaskCategory tasks={templateNames} selectedTemplate={selectedTemplate}
                    workspaceId={workspaceId} companyId={companyId} orderId={orderId} setTemplates={setTemplateNames}
                    modal={savetoggleTemplateModal} savedTemplateId={setSavedTemplateId}
                    setTheSelectedTemplate={setSelectedTemplate} templateData={getData} basicInfo={basicInfo[0]} />
                    : <><div className="text-center"><H3><span>{t("selectTemplateLabel")}</span></H3></div></>}
                </Col>
              </Row>
              <Row className="g-12 m-t-10">
                <Col>
                  <FormGroup className="f-right">
                    <Button
                      className="btn btn-primary"
                      onClick={() => onSubmitHandle()}
                      disabled={datecount > 0 ? false : true}>
                      {t("saveBtn")}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};


export default EditTask;
/* Code By : Rithanesh */