import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col,Input,Label, Button } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff,getStaffPermission, getLoginStaffId } from "../../../Constant/LoginConstant";
import { Close, SaveChanges , ServerUrl} from "../../../Constant";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import { apiencrypt,apidecrypt } from "../../../helper";
const FilterOffCanvas = ({ modal, toggle, PICs, orders, taskDetails, statusFilter, factory, buyer, pcu,
  filterStartDate,filterEndDate, filterPic, filterStyle,filteredStyle,filteredPIC, 
  filteredStartDate,filteredEndDate}) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const { t } = useTranslation();
  const [days, setDay] = useState("0");
  const [PIC, setPIC] = useState("0");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [styleNo, setStyleNo] = useState("0");
  const submitFunction = ()=>{
    let picId = document.getElementById("pic").value;
    let style = document.getElementById("styleNo").value;
    let startDates = "";
    let endDates = "";
    let dataToSend ;
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        statusFilter : statusFilter,
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        statusFilter : statusFilter,
    } 
    if(getWorkspaceType === "Buyer"){
      dataToSend.factory_id = factory;
      dataToSend.pcu_id = pcu;
    }
    else if(getWorkspaceType === "PCU"){
      dataToSend.factory_id = factory;
      dataToSend.buyer_id = buyer;
    }
    else if(getWorkspaceType === "Factory"){
      dataToSend.buyer_id = buyer;
      dataToSend.pcu_id = pcu;
    }
    if(picId !="0"){
      dataToSend.pic_id = picId;
      filterPic(picId);
    }
    if(style != "0"){
      dataToSend.styleNo = style;
      filterStyle(style);         
    }
    if(statusFilter === "Completed" || statusFilter=== "DelCompletion" 
    || statusFilter=== "All"|| statusFilter=== "Rescheduled"){
      startDates = document.getElementById("aStartDate").value;
      endDates = document.getElementById("aEndDate").value;
    }
    if((statusFilter === "Completed" || statusFilter=== "DelCompletion" 
    || statusFilter=== "All"|| statusFilter=== "Rescheduled")&&(startDates != "" && endDates != "")){
      dataToSend.aStartDate = document.getElementById("aStartDate").value;
      dataToSend.aEndDate = document.getElementById("aEndDate").value;
      filterStartDate(startDates);
      filterEndDate(endDates);
    }
    axios.post(ServerUrl + "/get-tasks-report", apiencrypt(dataToSend))
    .then((response) =>{
        response.data = apidecrypt(response.data)
        taskDetails(response.data.data.styleDetails);
        toggle();
        })
  };
  const clearFunction=()=>{
    let dataToSend ;
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        statusFilter : statusFilter,
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        statusFilter : statusFilter,
    }
    setStartDate("0");
    setEndDate("0");
    setStyleNo("0");
    setPIC("0");
    filterPic("");
    filterStyle("");
    filterStartDate("");
    filterEndDate("");
    if(getWorkspaceType === "Buyer"){
    dataToSend.factory_id = factory;
    dataToSend.pcu_id = pcu;
    }
    else if(getWorkspaceType === "PCU"){
    dataToSend.factory_id = factory;
    dataToSend.buyer_id = buyer;
    }
    else if(getWorkspaceType === "Factory"){
    dataToSend.buyer_id = buyer;
    dataToSend.pcu_id = pcu;
    }
    axios.post(ServerUrl + "/get-tasks-report", apiencrypt(dataToSend))
    .then((response) =>{
        response.data = apidecrypt(response.data)
        taskDetails(response.data.data.styleDetails);
        toggle();
        })
  };
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"}>
      <OffcanvasHeader className="bg-primary offcanvas-header"><i className="fa fa-filter f-24"></i> {t("filter")} 
        <span className="f-right cursor-pointer" onClick={toggle}>X</span>
      </OffcanvasHeader> 
      <OffcanvasBody>
        {statusFilter === "Completed" || statusFilter=== "DelCompletion" || statusFilter=== "All"|| statusFilter=== "Rescheduled"?
        <>
            <Row className="p-2">
            <Col md={12}>
                <Label>{t("accomplishedStartDate")}</Label>
                <Input type="date" id="aStartDate"
                onChange={(e)=>{
                    setStartDate(e.target.value);
                    document.getElementById("aEndDate").setAttribute("min",e.target.value);
                    let targetDate = moment(e.target.value).add(3,"months").format("YYYY-MM-DD");
                    document.getElementById("aEndDate").setAttribute("max",targetDate);
                }}
                onKeyDown={(e) => {
                e.preventDefault();
                }}
                defaultValue={startDate}>
                </Input>
            </Col>
            </Row>  
            <Row className="p-2">
            <Col md={12}>
                <Label>{t("accomplishedEndDate")}</Label>
                <Input type="date" id="aEndDate" 
                onChange={(e)=>setEndDate(e.target.value)}
                onKeyDown={(e) => {
                    e.preventDefault();
                    }}
                defaultValue={endDate}    >
                </Input>
            </Col>
            </Row>
        </>:""}
        <Row className="p-2">
            <Col md={12}>
                <Label>{t("styleNo")}</Label>
                <Input type="select" defaultValue={styleNo} name="styleNo" id="styleNo" onChange={(e)=>setStyleNo(e.target.value)}>
                <option value={"0"} selected disabled>{t("selectStyleno")}</option>
                {orders.map((style,i)=>(
                styleNo === style.id? <option key={i}value={style.id} selected>{style.style_no}</option> : 
                <option key={i} value={style.id}>{style.style_no}</option>
                ))}
                </Input>
            </Col>
        </Row>
        <Row className="p-2">
            <Col md={12}>
                <Label>{t("personInCharge")}</Label>
                <Input type="select" defaultValue={PIC} name="pic" id="pic" onChange={(e)=>setPIC(e.target.value)}>
                <option value={"0"} selected disabled>{t("selectPIC")}</option>
                {PICs.map((pic,i)=>(
                PIC === pic.id ? <option key={i} value={pic.id} selected >{pic.first_name+" "+pic.last_name}</option> : 
                <option key={i} value={pic.id}>{pic.first_name+" "+pic.last_name}</option>
                ))}
                </Input>
            </Col>
        </Row>
      </OffcanvasBody>
      <footer className="m-20 p-2">
        <Button className="btn secondaryBtn" onClick={ ()=>clearFunction() }>{t("clear")}</Button>
        <div className="f-right">
          <Btn attrBtn={{ color: "primary", onClick : ()=>submitFunction()  }}>
            {t("apply")}
          </Btn>
        </div>
      </footer>
    </Offcanvas>
  );
};

export default FilterOffCanvas;
/* Code By : Rithanesh */