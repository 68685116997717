import React, { Fragment, useState, useEffect } from "react";
import { Form, Card,  CardBody,  CardHeader,  Col,  Row,  Input,
  Button,  FormGroup,  Table,InputGroup} from "reactstrap";
import { P, H6, H4, Btn, } from "../../../AbstractElements";
import OrderHeaders from "./OrderHeaders";
import factory from "../../../assets/images/dms/factory.png";
import pcu from "../../../assets/images/dms/pcu.png";
import userimg from "../../../assets/images/dms/user.png";
import buyer from '../../../assets/images/dms/onGoingList.svg'
import TaskCategory from "./AddTaskCategory";
import { ServerUrl, maxUploadFileSize, maxNoFileCount, maxFileUpload } from "../../../Constant/index";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getStaff, 
         getStaffPermission, getWorkspaceType } from '../../../Constant/LoginConstant';
import Swal from "sweetalert2";
import { sizeConvertor, allowedExtensions, DownloadFile, encode, decode, allowedExtensionsUpload, 
  allowedUploadFormat, truncate,apiencrypt, apidecrypt, PHPtoJSFormatConversion } from "../../../helper"
import Files from 'react-files';
import { useTranslation } from "react-i18next";
import noData from "../../../assets/images/dms/nodata.png";
import Moment from 'moment';
import delete_icon from '../../../assets/images/dms/icons/delete_icon.svg'
import download_iconTask from '../../../assets/images/dms/icons/download_iconTask.svg'
// import ScrollBar from 'react-perfect-scrollbar';
import file_icon from '../../../assets/images/dms/icons/file_icon.svg'
// import Dropzone from "react-dropzone-uploader";

const AddTask = (props) => {

  const { t } = useTranslation();
  const [savetoggleTemplateModal, setSavetoggleTemplateModal] = useState(false);
  const workspaceId = getWorkspaceId;
  const companyId = getLoginCompanyId;
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [
    templateNames, setTemplateNames] = useState([]);
  const [templateload, setTemplateload] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [basicInfo, setBasicInfo] = useState([]);
  const [files, setFiles] = useState([]);
  const [filterfiles, setFilterFiles] = useState([]);
  const [savedTemplateId, setSavedTemplateId] = useState("")
  const [ifTemplateChanged, setIfTemplateChanged] = useState();
  const [isSubTaskEmpty, setIsSubTaskEmpty] = useState(0);
  const [OnSubmitData, setOnSubmitData] = useState(0);
  const [delDate, setDelDate] = useState([]);
  const [orderTemplateId, setOrderTemplateId] = useState("")
  const [reqparamdata, setreqparamdata] = useState("")
  const [errors, setValidErrors] = useState({});

  var currentDate = Moment().format('YYYY-MM-DD');

  function ndeleteFile(id) {

    Swal.fire({
      title: "Are you sure you want to delete this file?",
      icon: "warning",
      button: t("okLabel"),
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const filteredfiles = files.filter((e, index) => {
          return index !== id;
        })
        setFiles(filteredfiles);
      }
    })
  }

  let asv = 0;
  const nonFilesChange = (files) => {
    if (files.length > maxFileUpload) {
      Swal.fire({
        title: t("exceededLimitTitleAlert"),
        text: t("onlyFileLimitValidation", { filesLimit: maxFileUpload }),
        icon: "warning",
        button: t("okLabel"),
        timer:5000,
      })
    } else {
      const fileCount = (uploadedFiles.length + files.length);
      if (fileCount > maxNoFileCount) {
        Swal.fire({
          title: t("maxNoFileCountValidationText", { maxFileCount: maxNoFileCount }),
          text: t("alreadyUploadedFilesCountValidation", { uploadedFilesCount: uploadedFiles.length }),
          icon: "warning",
          button: t("okLabel"),
          timer:5000
        })
      }
      else if (files.length > maxFileUpload) {
        Swal.fire({
          title: t("fileExceededTitleAlert"),
          text: t("onlyFileLimitValidation", { filesLimit: maxFileUpload }),
          icon: "warning",
          button: t("okLabel"),
          timer:5000
        })
      } 
      else {
        const filteredfiles = files.filter((e, index) => {
          if (allowedExtensionsUpload.exec(e.name)) {
            const getFileSize = Math.round((e.size / 1024));
            if (getFileSize > maxUploadFileSize) {
              Swal.fire({
                title: t("sizeExceededTitleAlert"),
                text: t("uploadFileTalidationText", { fileSize: (maxUploadFileSize / 1024) }),
                // text: t("uploadFileWithinTextAlert1") + " " + 
                // (maxUploadFileSize / 1024) + " " + t("uploadFileWithinTextAlert2"),
                icon: "warning",
                button: t("okLabel"),
                timer:5000
              })
              asv = 1;
              return false;
            }
            //if(e.size)
            return true;
          } else {
            Swal.fire({
              title: t("wrongFormatTitleAlert"),
              text: t("uploadFormatTextAlert1") + allowedUploadFormat,
              icon: "warning",
              button: t("okLabel"),
              timer:5000,
            })
            asv = 1;
            return false;
          }
        })

        if (asv == 0) {
          setFiles(files)
          return files
        }
        asv = 0;
      }
    }
  }
  const nonFilesError = (error, file) => {
    setFiles(file)
  }

  /**************To check if the template has changed and need to be saved *******************/
  if (ifTemplateChanged === 1) {
    // document.getElementById("totalSave").setAttribute("disabled", true);
    // document.getElementById("saveTemplate").removeAttribute("disabled", false);
    // if (isSubTaskEmpty === 1) {
    //   document.getElementById("saveTemplate").setAttribute("disabled", true);
    // }
  }

  if (ifTemplateChanged === 0) {
    document.getElementById("totalSave").removeAttribute("disabled");
    // document.getElementById("saveTemplate").setAttribute("disabled", true);
  }

  /*****************  For the Toggling of the Save Template Modal *******************/
  const toggleTemplateModal = () => setSavetoggleTemplateModal(!savetoggleTemplateModal);

  /****************  To handle files and set it in the state *****************/
  const handleChangeStatus = ({ meta, file }, status) => {
    if (meta.status === "error_file_size") {
      Swal.fire({
        title: t("fileSizeExceeded"),
        text: t("uploadFileWithin2MB"),
        icon: "warning",
        button: t("okLabel"),
      })
    }
    else {
      if (allowedExtensions.exec(file.name)) {
        if (status === "done") {
          setFiles([...files, file])
        }
      }
    }
  };

  /***************  For the Handling of Submit of the Files  **************/
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
    onFileSubmit();
  };

  const onFileSubmit = () => {
    axios.post(ServerUrl + "/add-files",
      {
        "company_id": companyId,
        "workspace_id": workspaceId,
        "order_id": orderId,
        "additional_spec": files
      },
      {headers: {'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("fileAddedTitleAlert"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer:5000,
          }).then((result) => {
            if (result.isConfirmed) {
              setFiles([]);
              document.getElementById("totalSave").removeAttribute("disabled");
              getUploadedFiles();
            }
            setTimeout(() => {
              setFiles([]);
              document.getElementById("totalSave").removeAttribute("disabled");
              getUploadedFiles();
          }, 200);
          })
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title:
              response.data.errors.company_id ||
              response.data.errors.workspace_id ||
              response.data.errors.order_id,
            text: t("fieldMissing"),
            icon: "warning",
            button: t("okLabel"),
            timer:5000,
          });
        }
      })
  };

  /************** To Call the API's **************/
  useEffect(() => {
    axios.post(ServerUrl + "/get-order-template", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId
    }))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setTemplateNames(response.data.data);
      setTemplateload(response.data.data)
    })
    axios.post(ServerUrl + "/get-basic-info", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId
    }))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setBasicInfo(response.data.data);
      setDelDate(response.data.delivery_dates);

      if (response.data.data[0].status != 1) {
        window.location.href = "/dashboard"
      }
      else { "" }
    })
    getUploadedFiles();

    {
      (((getStaff === "Staff" && getStaffPermission.includes('Add Order')) || getStaff == null))
        ?  "" : window.location.href = "/dashboard"
    }
  }, []);

  let sub_arr = [];
  const orderValidation = () => {
    let errors = {};
    let req_param = {};
  templateNames.map((template) => template.id == selectedTemplate ? 
  JSON.parse(template.task_template_structure).map((templates) => {
  /* This variable is to set the position of the object in the array in the below loop  */
  let i = 0
  let sub_req_param = {};
  var task_title = templates.task_title;
  if(task_title!='deleted'){

  templates.task_subtitles.map((subtitle) => {
    if(subtitle!='deleted'){
    if(document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||startdate'));
    }
    if(document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||enddate'));
    }
    if( document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value==""
    ||document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value==null){

      sub_arr.push((templates.task_title + '||' + subtitle + '||pic_id'));

    }
  }
  })
}
  
  // req_param[task_title] = sub_req_param;
}) : "")
if(sub_arr.length>0){
 var splitvalue= sub_arr[0].split('||');
  
  Swal.fire({
    title: (splitvalue[0]+" "+"("+splitvalue[1]+")"),
    // text: ("Please Select the"+ " "+((splitvalue[2]=="startdate")?"Start date":splitvalue[2]=="enddate"?"End Date":splitvalue[2]=="pic_id"?"Incharge":"")),
    text: (((splitvalue[2]=="startdate")?t("selectStartDate"):splitvalue[2]=="enddate"?t("selectEndtDate"):splitvalue[2]=="pic_id"?t("pleaseSelectPic"):"")),
    icon: "warning",
    button: t("okLabel"),
    timer:5000,
  });
}
  
    setValidErrors(errors);
    return errors;
  };
  /****************  To get the list of files and set it in the state *****************/
  const getUploadedFiles = () => {
    axios.post(ServerUrl + "/list-files", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
    })).then((response) => {
      response.data = apidecrypt(response.data);
      setUploadedFiles(response.data.data);
    })
  };
  /***************** To Download the Files  ***************/
  const downloadFile = (filename, orgFileName) => {
    axios.post(ServerUrl + "/download-file", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
      "fileName": filename
    }), { responseType: 'blob' })
      .then((response) => {
        DownloadFile(response.data, orgFileName);
      })
  };
  /**************To Delete the File **************/
  const deleteFile = (filename, orgFileName) => {
    Swal.fire({
      title: t("deleteConfirmationTitleAlert"),
      text: orgFileName,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete")
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(ServerUrl + "/delete-file", apiencrypt({
          "company_id": companyId,
          "workspace_id": workspaceId,
          "order_id": orderId,
          "fileName": filename
        })).then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("deletedSuccessfullyTitleAlert"),
              icon: "success",
              button: t("okLabel"),
            }).then((result) => {
              if (result.isConfirmed) {
                getUploadedFiles();
              }
            })
          }
        })
      }
    })
  };

  /****************  To get the values entered in the template and set it in an array *****************/
  // if(OnSubmitData==1){
    const onSubmitHandle = () => {
      orderValidation();
      if (sub_arr.length==0) {
      if (ifTemplateChanged == 1 && OnSubmitData == 0) {
        toggleTemplateModal()
      }
      else {
      if (!selectedTemplate) {
        Swal.fire({
          title: t("template"),
          text: t("templateValidation"),
          icon: "error",
          button: t("okLabel"),
        });
      } else {
        let req_param = {};
        templateNames.map((template) => template.id == selectedTemplate ? 
          JSON.parse(template.task_template_structure).map((templates) => {
          /* This variable is to set the position of the object in the array in the below loop  */
          let i = 0
          let sub_req_param = {};
          var task_title = templates.task_title;
          if(task_title!='deleted'){
          templates.task_subtitles.map((subtitle) => {
            if(subtitle!='deleted'){
            let sub_arr = {};
            sub_arr.title = document.getElementById(templates.task_title + '||' + subtitle + '||name').value;
            sub_arr.startdate = document.getElementById(templates.task_title + '||' + subtitle + '||startdate').value;
            sub_arr.enddate = document.getElementById(templates.task_title + '||' + subtitle + '||enddate').value;
            sub_arr.pic_id = document.getElementById(templates.task_title + '||' + subtitle + '||pic_id').value;
            sub_req_param[i] = sub_arr;
            i++;
            }
          })
          req_param[task_title] = sub_req_param;
        }
        }) : "")
        setreqparamdata(req_param)
        /****************  To list of the datas to be sent in the API Call *****************/
        const submitData = {
          "company_id": companyId,
          "workspace_id": workspaceId,
          "order_id": orderId,
          "template_id": orderTemplateId,
          "template_data": req_param
        };
        axios.post(ServerUrl + "/create-task-data", apiencrypt(submitData))
          .then((response) => {
            response.data = apidecrypt(response.data);
            if (response.data.status_code === 200) {
              Swal.fire({
                title: t("taskAddedTitleAlert"),
                // text: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                timer: 5000
              }).then((result) => {
                if (result.isConfirmed) {
                    getWorkspaceType == "Factory" 
                    ?
                    window.location.href = "/ordermaterialslabels?id=" + encode(orderId)
                    :
                    window.location.href = "/orderproductionplan?id=" + encode(orderId)
                }
                setTimeout(() => {
                  getWorkspaceType == "Factory" 
                  ?
                  window.location.href = "/ordermaterialslabels?id=" + encode(orderId)
                  :
                  window.location.href = "/orderproductionplan?id=" + encode(orderId)
                }, 100);
              });
            }
            if (response.data.status_code === 401) {
              Swal.fire({
                title:
                  response.data.errors.company_id ||
                  response.data.errors.workspace_id ||
                  response.data.errors.order_id ||
                  response.data.errors.template_id ||
                  response.data.errors.template_data,
                text: t("okLabel"),
                icon: "warning",
                button: t("okLabel"),
                timer:5000,
              });
            }
          })
      }}
    }
    // }
  }


  if (OnSubmitData == 1) {
    setOnSubmitData(2)
    setIfTemplateChanged(0)
    onSubmitHandle()
  }
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody>
            <OrderHeaders title="addtask" />
          </CardBody>
          <CardBody className="p-t-0">
            <Form className="needs-validation" >
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  {/* <H6>SKU Details</H6> */}
                  <Row className="g-12">
                    <Col lg="7" md="7" sm="12">
                      <div className="table-responsive shadow-sm shadow-showcase">
                        {basicInfo.map((information) => (
                          <Table className="shadow-sm shadow-showcase">
                            {/* <thead> */}
                            <tr className="table-active" style={{ background: "#DEF0EF" }}>
                              <th scope="col">{t("basicInformation")}</th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col" className="text-right">
                              </th>
                            </tr>
                            {/* </thead> */}
                            <tbody className="basicInfoTableBody">
                              <tr>
                                <td> {t("orderNo")}
                                  <tr style={{ color: '#009688', fontWeight: 600, fontSize: '15px' }}>
                                    {information.order}
                                  </tr>
                                </td>

                                <td> {t("styleNo")}
                                  <tr style={{ color: '#009688', fontWeight: 600, fontSize: '15px' }}>
                                    {information.style}
                                  </tr>
                                </td>
                                {/* <td>
                                  <div className="align-self-center media-body">
                                    <ul className="dates d-flex flex-row list-group">
                                      <li className="list-group-item">{t("orderNo")}</li>
                                    </ul>
                                    <h6 className="mt-0 ">{information.order}</h6>
                                  </div>
                                </td>
                                <td>
                                  <div className="align-self-center media-body">
                                    <ul className="dates d-flex flex-row list-group">
                                      <li className="list-group-item">{t("styleNo")}</li>
                                    </ul>
                                    <h6 className="mt-0">{information.style}</h6>
                                  </div>
                                </td> */}
                                <td>
                                  <img src={userimg} alt={t("users")} />
                                  <span className="p-l-1"> <b> {information.workspace} </b> </span>
                                </td>
                                {information.factory != null ?
                                  <td>
                                    <img src={factory} alt={t("factory")} />
                                    <span className="p-l-1"> <b> {information.factory}</b> </span>
                                  </td>
                                  : ""}
                                {information.pcu != null ?
                                  <td >
                                    <img src={pcu} alt={t("pcu")} />
                                    <span className="p-l-1"> <b> {information.pcu} </b> </span>
                                  </td>
                                  : ""}
                                {information.buyer != null ?
                                  <td>
                                    <img src={buyer} alt={t("buyer")} />
                                    <span className="p-l-1"> <b> {information.buyer}</b> </span>
                                  </td>
                                  : ""}
                              </tr>
                            </tbody>
                          </Table>
                        ))}
                      </div>
                    </Col>
                    <Col lg="5" md="5" sm="12">
                      <CardHeader className="p-2 f-w-600 p-l-2" style={{ background: "#DEF0EF", 
                        fontSize: "15px", height: '45px' }}> {t("delDate")}
                      </CardHeader>
                      <CardBody className="p-1 shadow-sm shadow-showcase cardBodyHeight"
                        style={{ overflowY: "scroll", height: '72px' }}>
                        <div style={{ backgroundColor: '#fff', marginTop: '0px' }}>
                          {
                            delDate.length > 0 
                            ?
                              delDate.map((dataDeliveryDate) => (
                                    dataDeliveryDate.is_delivered == 0 ? 
                                        currentDate > dataDeliveryDate.delivery_date ? 
                                          <span className="btn deliveryBtn text-center"
                                                style={{ height: '35px', width: '110px',
                                                  borderRadius: '18px', borderColor: '#cccccc',
                                                  marginLeft: '10px', marginTop: '12px',
                                                  marginBottom: '10px', fontSize: '12px',
                                                  color: '#EE335E', fontWeight: 600, cursor: 'unset'
                                                }}>
                                            {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                          </span> 
                                        : 
                                          <span className="btn deliveryBtn text-center"
                                                style={{ height: '35px', width: '110px',
                                                  borderRadius: '18px', borderColor: '#cccccc',
                                                  marginLeft: '10px', marginTop: '12px',
                                                  marginBottom: '10px', fontSize: '12px',
                                                  color: '#878787', fontWeight: 600, cursor: 'unset'
                                                }}>
                                            {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                          </span>
                                      : 
                                      dataDeliveryDate.updated_date > dataDeliveryDate.delivery_date ? 
                                          <span className="btn deliveryBtn text-center"
                                                style={{ height: '35px', width: '110px',
                                                  borderRadius: '18px', borderColor: '#cccccc',
                                                  marginLeft: '10px', marginTop: '12px',
                                                  marginBottom: '10px', fontSize: '12px',
                                                  color: '#E69020', fontWeight: 600, cursor: 'unset'
                                                }}>
                                           {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                          </span> 
                                        : 
                                          <span className="btn deliveryBtn text-center"
                                                style={{ height: '35px', width: '110px',
                                                  borderRadius: '18px', borderColor: '#cccccc',
                                                  marginLeft: '10px', marginTop: '12px',
                                                  marginBottom: '10px', fontSize: '12px',
                                                  color: '#009688', fontWeight: 600, cursor: 'unset'
                                                }}>
                                            {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                          </span>
                              ))
                              :
                              <div className="align-self-center media-body centerAlign m-t-5">
                                <img src={noData} style={{ width: "60px" }} />
                                <p className='f-5 m-t-10' >-- {t("noDataFound")} --</p>
                              </div>
                          }
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
              </Row>
              <Row className="g-12 m-t-20 p-l-10 p-r-10">
                {/*start contact list */}
                <Col md="12" lg="12" sm="12" className="shadow shadow-md" >
                  <div className="p-t-15 p-l-30">
                    <H6> {t("Task")} <small style={{ color: "black", fontSize: "11px" }}>
                      ({t("chooseYourTemplate")})</small></H6>
                  </div>
                  <Row className="g-12 p-l-30 p-b-5">
                    <Col md="3" lg="3" sm="6" xs="12">
                      {/* <Label>{t("chooseYourTemplate")}</Label> */}
                      <Input type="select" placeholder={t("Task")} className="form-control digits"
                        name="user_type" defaultValue=""
                        onChange={(e) => {
                          setSelectedTemplate(e.target.value);
                          setIfTemplateChanged(0); setOrderTemplateId(e.target.value);
                        }}>
                        <option value="" disabled> {t("selectTemplate")} </option>
                        {templateload.map((templateName) => (
                          savedTemplateId == templateName.id ?
                            <option value={templateName.id} selected>{templateName.template_name}</option>
                            : <option value={templateName.id} >{templateName.template_name}</option>))}
                      </Input>
                    </Col>
                    <Col md="3" lg="3" sm="6" xs="12">
                      {(getStaff === "Staff" && getStaffPermission.includes('Task File Upload')) || getStaff == null ?
                        <FormGroup>
                          <Files
                            className='files-dropzone fileContainer'
                            onChange={nonFilesChange}
                            onError={nonFilesError}
                            accept=".png,.jpg,.jpeg,.docx,.doc,.html,.htm,.xls,.xlsx,.txt,.ppt,.odf,.pptx,.csv,.rtf,.odt,.ods,.pdf"
                            multiple={true} canCancel={false}  // onSubmit={handleSubmit}
                            clickable
                          ><div className="d-flex justify-content-center">
                              <Btn attrBtn={{ className: "mt-0", type: "button", color: 'primary', }}>
                                <small> {t("uploadFiles")}</small></Btn>
                            </div>
                          </Files>
                        </FormGroup> : ""}
                    </Col>
                    {/*File upload Testing*/}
                    <Col md="6" lg="6" sm="12" xs="12">
                      <div className="justify-content-left">
                        {files.length > 0 ? <div className='files-gallery'>
                            <Row >
                              {files.map((file, fi) =>
                                <Col md="6" lg="4" sm="12" xs="12">
                                  <table className="" cellPadding="4px" width="100%">
                                    <tr>
                                      <td width="5%">
                                        <i className="fa fa-file-o f-24" style={{ color: "#778899" }}></i>
                                      </td>
                                      <td className="">
                                        <p className="f-left f-12 f-w-600">{file.name}<br />
                                          <small style={{ color: "#b3b3b3" }}>{sizeConvertor(file.size)}</small></p>
                                      </td>
                                      <td className='' align="right" valign="center" >
                                          <img  style={{ width: "68px"}}  className="cursor-pointer  b-r-3 f-left p-r-10" title={t("deleteFile")} 
                                            onClick={() =>
                                              ndeleteFile(fi)} src={delete_icon} />

                                      </td>
                                    </tr>
                                  </table>
                                </Col>
                              )}
                            </Row>
                            {files.length > 0 ?
                              <div className="d-flex justify-content-center">
                                <Button className="btn btn-primary bottom"
                                  onClick={() => onFileSubmit()}
                                >{t("saveBtn")}</Button>
                              </div> : ""}
                          </div> : ''}
                      </div>
                    </Col>
                    
                  </Row>
                  {/* {uploadedFiles.length > 0 ?
                    <Row className="m-t-10 taskUpdate-table-sideHeader p-l-30" 
                      style={{ borderTop: '1px solid #B1B1B1', backgroundColor: "#F6F6F6" }}>
                      <Row> <span className="m-l-5 p-t-10 p-b-5 f-w-600">{t("uploadFileBtn")}</span> </Row>
                      <Row>
                        {uploadedFiles.map((file) => (
                          <Col md="3" lg="3" sm="6" xs="12">
                            <table cellPadding="4px" width="100%">
                              <tr>
                                <td width="5%"> <i className="fa fa-file-o f-24" style={{ color: "#778899" }}> </i> </td>
                                <td >
                                  <p className="f-left f-12 f-w-600">{truncate(file.orginalfilename)}<br />
                                    <small style={{ color: "#b3b3b3" }}>{sizeConvertor(file.filesize)}</small></p>
                                </td>
                                <td className='' align="right" valign="center" width="5%">

                                    <img  style={{ width: "55px"}}  className="cursor-pointer p-r-10  b-r-3 f-left " title={t("downloadFile")} 
                               onClick={() => downloadFile(file.filename, file.orginalfilename)}  src={download_iconTask} />
                                </td>
                                <td className='' valign="center" width="5%">
                                  {(getStaff === "Staff" && getStaffPermission.includes('Task File Delete')) || getStaff == null ?

                                <img  style={{ width: "55px"}}  className="cursor-pointer p-r-10  b-r-3 f-left " title={t("deleteFile")} 
                                onClick={() => deleteFile(file.filename, file.orginalfilename)}  src={delete_icon} />
                                    : ""}
                                </td>
                              </tr>
                            </table>
                          </Col>
                        ))}
                      </Row>
                    </Row>
                    : ""} */}
                     <Row >
                  {uploadedFiles.length > 0 ?
                  <>
                  <div  style={{backgroundColor:"#F6F6F6",padding:"10px"}}><span   >{t("uploadFileBtn")}s</span>
                  </div>
                
                    {uploadedFiles.map((file) => (
                  <Col  className=" p-t-0 p-b-5 " 
                  style={{backgroundColor:"#F6F6F6"}}
                  >
                      <FormGroup>
                        
                        <InputGroup>
                  <table  className="" >
                    <tr>
                      <td className=""  >
                        {/* <i className="fa fa-file-o f-10"></i> */}
                        <img className="cursor-pointer"   title={file.name} src={file_icon} />
                      </td>
                      <td className="">
                        <p style={{color:"#969696"}} className=" f-left f-12 f-w-600 m-l-10">{truncate(file.orginalfilename)}<br />
                          <small>{sizeConvertor(file.filesize)}</small></p>
                      </td>
                      <td className='cursor-pointer' valign="center" width="5%">
                        {(getStaff === "Staff" && getStaffPermission.includes('Task File Delete')) || getStaff == null ?
                          // <a href="javascript:void(0)" title={t("downloadFile")} onClick={() => deleteFile(file.filename, file.orginalfilename)}><i className="fa fa-trash f-16 p-r-10 font-dark"></i></a>
                          <img   title={t("deleteFile")} onClick={() => deleteFile(file.filename, file.orginalfilename)} src={delete_icon} alt={t("factory")} /> 
                          : ""}
                      </td>
                      <td className='' align="right" valign="center" width="5%" >
                      
                      <img className="fa fa-download f-16 font-dark cursor-pointer " src={download_iconTask}  title={t("downloadFile")} onClick={() => downloadFile(file.filename, file.orginalfilename)}  alt={t("factory")} /> 
                        {/* <a href="javascript:void(0)" title={t("downloadFile")} onClick={() => downloadFile(file.filename, file.orginalfilename)}><i className="fa fa-download f-16 font-dark "></i></a> */}
                      </td>
                      
                    </tr>
                  </table>
                {/* </Col> */}
              
          
          {/* </Row> */}
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    ))} </> : ""}
                    </Row>
                </Col>
              </Row>
              {selectedTemplate !== "" ?
                <>
                  <Row>
                    <Col md="12" lg="12" sm="12">
                      &nbsp;
                    </Col>
                    {/*end contact list qty */}
                    <Col md="12" lg="12" sm="12">
                      <TaskCategory tasks={templateload} selectedTemplate={selectedTemplate}
                        workspaceId={workspaceId} companyId={companyId} orderId={orderId} setTemplates={setTemplateNames}
                        modal={savetoggleTemplateModal} toggleTemplateModal={toggleTemplateModal} 
                        savedTemplateId={setSavedTemplateId} setTheSelectedTemplate={setSelectedTemplate} 
                        areTasksEdited={setIfTemplateChanged} setSubTaskEmpty={setIsSubTaskEmpty}
                        subTaskEmpty={isSubTaskEmpty} basicInfo={basicInfo[0]} 
                        setOnSubmitData={setOnSubmitData} setTemplateload={setTemplateload}
                        setOrderTemplateId={setOrderTemplateId}
                      />
                    </Col>
                  </Row>
                </>
                :
                <>
                  <Row>
                    <Col md="12" lg="12" sm="12">
                      &nbsp;
                    </Col>
                    <Col>
                      <div className="text-center"><H4><span>{t("selectTemplateLabel")}</span></H4></div>
                    </Col>
                  </Row>
                </>}
              {/*end second row*/}
              <Row className="g-12 m-t-30">
                <Col>
                  <FormGroup className="f-right">
                    {/* {selectedTemplate !== ""? 
                    <Button id="saveTemplate" onClick={() => { toggleTemplateModal() }} 
                    title={t("saveTemplate")}>{t("saveTemplate")}</Button> :
                    <Button id="saveTemplate" onClick={() => { toggleTemplateModal() }} 
                    title={t("saveTemplate")} disabled>{t("saveTemplate")}</Button>}&nbsp;&nbsp;&nbsp;&nbsp; */}
                    {/* <Button id="saveTemplate" onClick={() => { toggleTemplateModal() }} 
                    title="Save Template">{t("saveTemplate")}</Button>&nbsp;&nbsp;&nbsp;&nbsp; */}
                    <Button id="totalSave" onClick={() => onSubmitHandle()} title="">{t("saveAndContinue")}</Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default AddTask;
/* Code By : Rithanesh */