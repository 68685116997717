import { Container, Row, Col, CardBody, Card, Label, Button } from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6 } from "../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../Constant";
import { apidecrypt, apiencrypt, encode } from "../../helper";
import AddIcon from "../../../src/assets/images/dms/icons/addIcon.svg";
import ViewIcon from "../../../src/assets/images/dms/icons/inquiryViewIcon.svg";
import EditIcon from "../../../src/assets/images/dms/icons/inquiryEditIcon.svg";
import AddQuantityModal from "./AddQuantityModal";
import ViewSamModal from "./ViewSamModal";
import {
  getStaff,
  getStaffPermission,
  getLoginCompanyId,
  getWorkspaceId,
  getLoginUserId,
  getLoginStaffId,
} from "../../Constant/LoginConstant";
import { useTranslation } from "react-i18next";

const ViewSamDetails = () => {
  const { t, i18n } = useTranslation();
  const [user_id, setuser_id] = useState(getLoginUserId);
  const [staff_id, setStaff_id] = useState(getLoginStaffId);
  const [samList, setSamList] = useState([]);
  const [samViewDailyList, setSamViewDailyList] = useState([]);
  const [reportId, setReportId] = useState("");
  const [timeSlotList, setTimeSlotList] = useState([]);
  const [updatedTimeSlotList, setUpdatedTimeSlotList] = useState([]);
  const [styleNo, setStyleNo] = useState("");
  const [unit, setUnit] = useState("");
  const [lineNo, setLineNo] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [target, setTarget] = useState("");
  const [samValue, setSamValue] = useState("");
  const [noOfTailors, setNoOfTailors] = useState("");

  var getInputParams = {};
  getInputParams["company_id"] = getLoginCompanyId;
  getInputParams["workspace_id"] = getWorkspaceId;
  const [modalAddQuantity, setModalAddQuantity] = useState(false);
  const toggleAddQuantity = () => setModalAddQuantity(!modalAddQuantity);

  const [modalViewSam, setModalViewSam] = useState(false);
  const toggleViewSam = () => setModalViewSam(!modalViewSam);

  const apiCall = () => {
    axios
      .post(ServerUrl + "/get-sam-report-settings", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setSamList(response.data.data);
      });
  };

  useEffect(() => {
    apiCall();
  }, []);

  /*Add Quantity Modal-Drop down API call- DROPDOWN*/
  const apiCallTimeSlot = (rtId) => {
    var inputTimeSlotparams = {};
    inputTimeSlotparams["report_id"] = rtId;
    axios
      .post(ServerUrl + "/get-sam-report-time", apiencrypt(inputTimeSlotparams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setSamValue(response.data.details.sam_value);
        setNoOfTailors(response.data.details.no_of_tailors);
        setTimeSlotList(response.data.data);
        setUpdatedTimeSlotList(response.data.updated_times);
      });
    setReportId(rtId);
  };

  /* Add Sam View Modal List */
  const apiCallViewSam = (rtId) => {
    var inputparams = {};
    inputparams["report_id"] = rtId;
    axios
      .post(ServerUrl + "/get-sam-quantity", apiencrypt(inputparams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setSamViewDailyList(response.data.data);
      });
  };

  const addReportSetting = () => {
    window.location.href = `/addsamdetails`;
  };

  const editSam = (samid) => {
    window.location.href = `/editsamdetails?id=` + encode(samid);
  };

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs
          mainTitle={t("Daily Setting")} parent={t("Daily Setting")}
          title={t("Daily Setting")}subTitle={t("viewSamDetais")}
        />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row className="g-12 m-t-20">
                  {" "}
                  <Col md="12" lg="12" sm="12" className="text-end">
                    <button
                      className="btn-pill btn btn-outline-primary btn-md"
                      onClick={() => {
                        addReportSetting();
                      }}
                    >+{t("addReportSetting")}</button>
                  </Col>
                </Row>

                <Card className="m-t-5">
                  <span className="p-l-10 editcompanyinfo">
                    <Label className="skudetailsclr p-1 " name="logo">
                      <span className="p-r-5"></span> {t("reportSetting")}<span className="p-r-5"></span>
                    </Label>
                  </span>
                  <Row className="g-12 m-t-20">
                    <Col md="12" lg="12" sm="12">
                      <Row className="g-12">
                        <div className="table-responsive">
                          <table className="table shadow shadow-showcase table-bordered">
                            <thead className="overViewSku">
                              <tr>
                                <th scope="col" className="centerAlign">
                                  {t("styleOrderNo")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("productionType")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("shift")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("unit")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("samValue")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("fromTime")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("toTime")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("supervisor")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("lineNo")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("tailors")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("helpers")}
                                </th>
                                <th scope="col" className="centerAlign">
                                  {t("action")}
                                </th>
                              </tr>
                            </thead>
                            <tbody className="tableRowCol">
                              {samList.length > 0 ? (
                                samList.map((samdtls) => (
                                  <tr>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.style_no}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.production_type}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.shift_value}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.unit_value}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.sam_value}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.from_time}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.to_time}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.supervisor_name}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.line_no_value}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.no_of_tailors}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      {samdtls.no_of_helpers}
                                    </td>
                                    <td scope="row" className="centerAlign">
                                      <a
                                        target="_blank"
                                      >
                                        <img
                                          width="29px"
                                          style={{ cursor: "pointer" }}
                                          className="m-r-30 p-1"
                                          title={t("viewSamDetails")}
                                          src={ViewIcon}
                                          onClick={() => {
                                            setModalViewSam(!modalViewSam),
                                              apiCallViewSam(samdtls.id);
                                          }}
                                        />
                                      </a>
                                      <img
                                        width="29px"
                                        style={{ cursor: "pointer" }}
                                        className="m-r-30 p-1"
                                        title={t("editSamDtls")}
                                        value=""
                                        src={EditIcon}
                                        onClick={() => {
                                          editSam(samdtls.id);
                                        }}
                                      />
                                      <img
                                        width="29px"
                                        style={{ cursor: "pointer" }}
                                        className="m-r-30 p-1"
                                        title={t("addQuantity")}
                                        value=""
                                        src={AddIcon}
                                        onClick={() => {
                                          setModalAddQuantity(
                                            !modalAddQuantity
                                          ),
                                            setReportId(samdtls.id),
                                            setStyleNo(samdtls.style_no),
                                            setUnit(samdtls.unit_value),
                                            setLineNo(samdtls.line_no_value),
                                            setSupervisor(
                                              samdtls.supervisor_name
                                            ),
                                            apiCallTimeSlot(samdtls.id);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <>
                                  <tr className="text-center">
                                    <td colSpan="12                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ">
                                      {t("listSamDetails")}
                                    </td>
                                  </tr>
                                </>
                              )}

                              <AddQuantityModal
                                modal={modalAddQuantity}
                                toggle={toggleAddQuantity}
                                reportId={reportId}
                                timeSlotList={timeSlotList}
                                user_id={user_id}
                                staff_id={staff_id}
                                updatedTimeSlotList={updatedTimeSlotList}
                                styleNo={styleNo}
                                unit={unit}
                                supervisor={supervisor}
                                lineNo={lineNo}
                                samValue={samValue}
                                noOfTailors={noOfTailors}
                              />

                              <ViewSamModal
                                modal={modalViewSam}
                                toggle={toggleViewSam}
                                samViewDailyList={samViewDailyList}
                                reportId={reportId}
                              />

                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default ViewSamDetails;
/*Code By: R.Akshaya Mol*/
