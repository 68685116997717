import React, { Component } from 'react'
import 'moment/locale/ja';
import Scheduler, { SchedulerData, ViewTypes,CellUnits } from 'react-big-scheduler'
import withDragDropContext from './withDnDContext'
import 'react-big-scheduler/lib/css/style.css'
import { calculateDateDiffCountFromCurrentDate, calculateDateDiffCountFromTwoDates } from "../../helper"
import Modal from './TaskModal'
import moment from 'moment';

class Basic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            toggle: true,
            taskData: '',
            weekOffDetails:this.props.weekOffDetails,
            hoildayDetails:this.props.hoildayDetails,
            validhoildayDetails:[]
        }
        /*https://github.com/StephenChou1017/react-big-scheduler/tree/master*/
       // console.log("this.props.startDate==>",this.props.startDate);
        let schedulerData = new SchedulerData(this.props.startDate, ViewTypes.Month, false, false, {
           // schedulerWidth: '80%',
           // schedulerMaxHeight: 500,
         // weekCellWidth: '7%',
         // monthCellWidth: '4%',
              // nonAgendaDayCellHeaderFormat: 'HH:mm',
            nonAgendaOtherCellHeaderFormat: 'dd D',
            startResizable: false,
            endResizable: false,
            movable: false,
            creatable: false,
            
        }, {
            //getDateLabelFunc: this.getDateLabel,
            getCustomDateFunc: this.getCustomDate,
            isNonWorkingTimeFunc: this.isNonWorkingTime
        });
       
        //(SchedulerData.documentWidth - SchedulerData.config.besidesWidth) * SchedulerData.config.schedulerWidth
       // SchedulerData.documentWidth
       // console.log("weekdayOff111==>",this.props.weekOffDetails);

        if (localStorage.getItem("i18nextLng") == 'jp') {
            schedulerData.localeMoment.locale('ja');
        } else {
            schedulerData.localeMoment.locale('en');
        }
        let Resourcedata = [];
        let eventdata = [];
        let dt = '';
        let catary = [];
        var jk = 0;
       
        for (let i = 0; i < this.props.task.length; i++) {
            jk++;
            let asnary = [];
            let asevary = [];
            let catName = props.task[i].cat_title;
            let sdate = props.task[i].task_schedule_start_date;
            let edate = props.task[i].task_schedule_end_date;
            let accompdate = props.task[i].task_accomplished_date;
            let index = catary.indexOf(catName);

           // asevary['rrule']= 'FREQ=WEEKLY;DTSTART=20230419T013000Z;BYDAY=TU,FR';
            /************************start Assing Resource unique data***************************/
            if (parseInt(index) == -1) {
                catary.push(catName);
                asnary['id'] = catName;
                asnary['name'] = catName;
                var arrayToString = JSON.stringify(Object.assign({}, asnary));
                var stringToJsonObject = JSON.parse(arrayToString);
                Resourcedata.push(stringToJsonObject);

            }
            /*******************************end Assing Resource unique data***************************/
            let rest = calculateDateDiffCountFromCurrentDate(edate);
            let srest = calculateDateDiffCountFromCurrentDate(sdate);
            asevary['id'] = jk;
            asevary['start'] = sdate + " 00:00:00";
            asevary['taskstart'] = sdate;
            asevary['taskend'] = edate;

            if (accompdate != '' && accompdate != null) {
                asevary['end'] = accompdate + " 23:59:59";
                asevary['accdate'] = accompdate;
            } else {
                asevary['end'] = edate + " 23:59:59";
                asevary['accdate'] = '-';
            }
            asevary['resourceId'] = catName;
            if (props.task[i].subtask_title != null && props.task[i].subtask_title != '') {
                asevary['title'] = props.task[i].subtask_title;
               
            } else {
                asevary['title'] = props.task[i].task_title;

            }
            asevary['task'] = props.task[i].task_title;
            asevary['subtask'] = props.task[i].subtask_title;
            let picName = '';
            if (props.task[i].first_name != null) {
                picName = props.task[i].first_name;
            }
            if (props.task[i].last_name != null) {
                picName = picName + " " + props.task[i].last_name;
            }
            asevary['pic'] = picName;
            asevary['movable'] = false;
            if (accompdate != '' && accompdate != null) {
                let ctacdate = calculateDateDiffCountFromTwoDates(edate, accompdate);
                if (parseInt(ctacdate) >= 0) {
                    asevary['bgColor'] = '#26A69A';
                } else {
                    asevary['bgColor'] = '#E69020';
                }
            } else if (parseInt(srest) <= 0 && parseInt(rest) >= 0) {
                asevary['bgColor'] = '#C7D0DD';
            }
            else if (parseInt(srest) < 0 && parseInt(rest) < 0) {
                asevary['bgColor'] = '#FF3838';
            } else if (parseInt(rest) > 0) {
                asevary['bgColor'] = '#E8EFF0';
            } else {
                asevary['bgColor'] = '#E8EFF0';
            }
            var arrayToStringev = JSON.stringify(Object.assign({}, asevary));
            var stringToJsonObjectev = JSON.parse(arrayToStringev);
            eventdata.push(stringToJsonObjectev);
        }
        let resources = Resourcedata;
        schedulerData.setResources(resources);
        let event = eventdata;
        schedulerData.setEvents(event);
        this.state = {
            viewModel: schedulerData,
            eventData: event,
        }

        /*Hoilday setting start*/
      
//         if(this.props.hoildayDetails.length>0){
//             var dateArray = new Array();
//             for(var i = 0; i < this.props.hoildayDetails.length; i++){
// var startDate=new Date(this.props.hoildayDetails[i]['holiday_start_date']);
// var endDate=new Date(this.props.hoildayDetails[i]['holiday_end_date']);

// while (startDate <= endDate) {
//     if(dateArray.includes(new Date(startDate).toLocaleDateString('en-GB'))==false){
//     dateArray.push(new Date(startDate).toLocaleDateString('en-GB'))
//     }
//     startDate.setDate(startDate.getDate() + 1);
// }


//             }
      
         
//             this.setState({validhoildayDetails:dateArray}); 
//             //console.log("dateArray==>",dateArray);    
//         }

        /*Hoilday setting end*/
    }
    render() {
        const { viewModel, eventData } = this.state;
        const changeState = (state, value) => {
            this.setState({ modal: false });
        };
        let rightCustomHeader = (
            <div><span style={{fontWeight: 'bold', color: 'red'}}>Text Here</span></div>
        );
        return (
            <div>
                <div>
                    <Scheduler schedulerData={viewModel}
                        prevClick={this.prevClick}
                        nextClick={this.nextClick}
                        onSelectDate={this.onSelectDate}
                        onViewChange={this.onViewChange}
                        eventItemClick={this.eventClicked}
                      //  onScrollLeft={this.onScrollLeft}
                      //  onScrollRight={this.onScrollRight}
                        // onScrollTop={this.onScrollTop}
                        // onScrollBottom={this.onScrollBottom}
                       // rightCustomHeader={rightCustomHeader}
                       // nonAgendaCellHeaderTemplateResolver = {this.nonAgendaCellHeaderTemplateResolver}
                       
                    />
                </div>
                <Modal
                    modal={this.state.modal}
                    toggle={this.state.toggle}
                    data={this.state.taskData}
                    changeState={changeState}
                />
            </div>
        )
    }
    prevClick = (schedulerData) => {
        schedulerData.prev();
        schedulerData.setEvents(this.state.eventData);
        this.setState({
            viewModel: schedulerData
        })
        this.setState({ modal: false });
        this.setState({  weekOffDetails:this.props.weekOffDetails});
        //this.setState({  hoildayDetails:this.props.hoildayDetails});
    }
    nextClick = (schedulerData) => {
        schedulerData.next();
        schedulerData.setEvents(this.state.eventData);
        this.setState({
            viewModel: schedulerData
        })
        this.setState({ modal: false });
        this.setState({  weekOffDetails:this.props.weekOffDetails});
       // this.setState({  hoildayDetails:this.props.hoildayDetails});
    }
    onViewChange = (schedulerData, view) => {
        schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
        schedulerData.setEvents(this.state.eventData);
        this.setState({
            viewModel: schedulerData
        })
        this.setState({ modal: false });
        this.setState({  weekOffDetails:this.props.weekOffDetails});
       // this.setState({  hoildayDetails:this.props.hoildayDetails});
    }
    onSelectDate = (schedulerData, date) => {
        schedulerData.setDate(date);
        schedulerData.setEvents(this.state.eventData);
        this.setState({
            viewModel: schedulerData
        })
        this.setState({ modal: false });
        this.setState({  weekOffDetails:this.props.weekOffDetails});
        //this.setState({  hoildayDetails:this.props.hoildayDetails});
    }
    eventClicked = (schedulerData, event) => {
        this.setState({ modal: true });
        this.setState({ toggle: false });
        this.setState({ taskData: event });
        this.setState({  weekOffDetails:this.props.weekOffDetails});
           // this.setState({  hoildayDetails:this.props.hoildayDetails});
    };

    onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
        schedulerData.next();
       // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        });
  
        schedulerContent.scrollLeft = maxScrollLeft - 1;
      }
  
      onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
        schedulerData.prev();
       // schedulerData.setEvents(DemoData.events);
        this.setState({
            viewModel: schedulerData
        });
  
        schedulerContent.scrollLeft = 1;
      }
  
      onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
          //console.log('onScrollTop');
      }
  
      onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
        //  console.log('onScrollBottom');
      }
  /*  ops1 = (schedulerData, event) => {
    };
    ops2 = (schedulerData, event) => {
    };
    newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
        let newFreshId = 0;
        schedulerData.events.forEach((item) => {
            if (item.id >= newFreshId)
                newFreshId = item.id + 1;
        });
        let newEvent = {
            id: newFreshId,
            title: 'New event you just created',
            start: start,
            end: end,
            resourceId: slotId,
            bgColor: 'purple'
        }
        schedulerData.addEvent(newEvent);
        this.setState({
            viewModel: schedulerData
        })
    }
    updateEventStart = (schedulerData, event, newStart) => {
        schedulerData.updateEventStart(event, newStart);
        this.setState({
            viewModel: schedulerData
        })
    }
    updateEventEnd = (schedulerData, event, newEnd) => {
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
            viewModel: schedulerData
        })
    }
    moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
            viewModel: schedulerData
        })
    }

         nonAgendaCellHeaderTemplateResolver = (schedulerData, item, formattedDateItems, style) => {
            let datetime = schedulerData.localeMoment(item.time);
            let isCurrentDate = false;
      
            if (schedulerData.viewType === ViewTypes.Day) {
                isCurrentDate = datetime.isSame(new Date(), 'hour');
            }
            else {
                isCurrentDate = datetime.isSame(new Date(), 'day');
            }
      
            if (isCurrentDate) {
                style.backgroundColor = '#CCC';
                style.color = 'white';
            }
        }
        getCustomDate = (schedulerData, num, date = undefined) => {
            const {viewType} = schedulerData;
            let selectDate = schedulerData.startDate;
            console.log("selectDate==>",selectDate);
            if(date != undefined)
                selectDate = date;   
    
            let startDate = num === 0 ? selectDate : 
                schedulerData.localeMoment(selectDate).add(2*num, 'days').format(DATE_FORMAT),
                endDate = schedulerData.localeMoment(startDate).add(1, 'days').format(DATE_FORMAT),
                cellUnit = CellUnits.Hour;
            if(viewType === ViewTypes.Custom1) {
                let monday = schedulerData.localeMoment(selectDate).startOf('week').format(DATE_FORMAT);
                startDate = num === 0 ? monday : schedulerData.localeMoment(monday).add(2*num, 'weeks').format(DATE_FORMAT);
                endDate = schedulerData.localeMoment(startDate).add(1, 'weeks').endOf('week').format(DATE_FORMAT);
                cellUnit = CellUnits.Day;
            } else if(viewType === ViewTypes.Custom2) {
                let firstDayOfMonth = schedulerData.localeMoment(selectDate).startOf('month').format(DATE_FORMAT);
                startDate = num === 0 ? firstDayOfMonth : schedulerData.localeMoment(firstDayOfMonth).add(2*num, 'months').format(DATE_FORMAT);
                endDate = schedulerData.localeMoment(startDate).add(1, 'months').endOf('month').format(DATE_FORMAT);
                cellUnit = CellUnits.Day;
            }
                
            return {
                startDate,
                endDate,
                cellUnit
            };
        }*/
        isNonWorkingTime = (schedulerData, time, startDate, endDate) => {
            const { localeMoment } = schedulerData;
            //console.log("localeMoment(time)==>>",schedulerData);
            if(schedulerData.viewType === ViewTypes.Day){
                let hour = localeMoment(time).hour();
                if(hour < 9 || hour > 18)
                   // return true;
                    return false;
            } 
            // else if(schedulerData.viewType === ViewTypes.Year || schedulerData.viewType ===ViewTypes.Quarter){
            //     return false;
            // }
            else {
                var weekdayOff=this.state.weekOffDetails!='' || this.state.weekOffDetails>=0 ?this.state.weekOffDetails:'';
               
                if(weekdayOff!==null && weekdayOff!=undefined && weekdayOff!==''){
                     // console.log("weekdayOff==>",weekdayOff);
                    let dayOfWeek = localeMoment(time).weekday();
                    if(weekdayOff<=0 && weekdayOff>=6){
                        return true;
                    }
                   else if(weekdayOff.indexOf(dayOfWeek)>=0){
                        return true;
                    }else{
                       // return false;
                    }
                   // return false;
              //  if (dayOfWeek === 1 || dayOfWeek === 6)
                  
            }

            /*Hoilday start */
        //     var hoilday=this.state.validhoildayDetails;
        //    // var hoilday= ['25/05/2023', '26/05/2023', '29/05/2023', '30/05/2023', '31/05/2023'];
           
        //     if(hoilday.length >0 ){
        //         var passdate=localeMoment(time).date()+'/'+localeMoment(time).month()+"/"+localeMoment(time).year();
        //        // console.log(startDate,"hoilday==>>",localeMoment(time).day());
        //         if(hoilday.includes(passdate)==true) { return true; }
        //        // console.log("yes");
        //     }
             /*Hoilday end */

//            var hoilday=this.props.hoildayDetails;
//            // console.log("this.props.hoildayDetails===>",hoilday.length);
//             if(hoilday.length >0 ){
//                 for(var i=0; i<hoilday.length;i++){
//                     //console.log("hoilday yes",i);
// var passdate=localeMoment(time).year()+"-"+localeMoment(time).month()+"-"+localeMoment(time).date();
//                     var date = new Date(passdate);
// console.log("passdate==>",passdate);
// var start = new Date(hoilday[i]['holiday_start_date']);
// var end = new Date(hoilday[i]['holiday_end_date']);

// if (date >= start && date <= end) {
//     return true;
// } else {
//   //console.log('⛔️ date is not in the range');
// }
//                 }

//             }
            return false;
            }
           // return false;
        }
}
export default withDragDropContext(Basic)

//**************************Code by : SaravanaKumar****************************//