import React, { Fragment, useState, useEffect } from "react";
  
  import { Container, Row, Col, CardBody, Card, Pagination, PaginationItem, PaginationLink, Button, Label, Input, Table,Media } from "reactstrap";
import { Breadcrumbs } from "../../AbstractElements";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { ServerUrl } from "../../Constant";
import { apidecrypt, apiencrypt,DownloadFile } from "../../helper";
import Swal from "sweetalert2";
import DownloadIcon from "../../assets/images/dms/icons/DowloadIconDms.svg";
import infoIcon from "../../assets/images/dms/icons/samDataInfoIcon.svg";
import noData from "../../assets/images/dms/nodata.png"
import v1cutgreen_icon from '../../assets/images/dms/icons/v1cutgreen_icon.svg';
import Sewv1green_icon from '../../assets/images/dms/icons/Sewv1green_icon.svg';
import logo from "../../assets/images/dms/dms-log-with-tag.png";
import packv1_icon from '../../assets/images/dms/icons/packv1_icon.svg';
import { Line } from 'react-chartjs-2';
import HeaderCard from '../Common/Component/HeaderCard';
import { LineChart } from '../../Constant';
import { Link } from "react-router-dom";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, 
  getWorkspaceType, getStaff, getStaffPermission, getLoginStaffId }
from '../../Constant/LoginConstant';
import { set } from "react-hook-form";
import Moment from 'moment';

const DailySamDetails = () => {
  const { t } = useTranslation();
  const [pgNo, setPgNo] = useState(1);
  const [samReportDetails, SetSamReportDetails] = useState([]);
  const [samReportTimingDetails, SetSamReportTimingDetails] = useState([]);
  const [samDate,setSamDate] = useState(Moment().format('YYYY-MM-DD'));
  const [hiddenData, sethiddenData] = useState(false);
  const [links, setlinks] = useState([]);
  const [expiriesDays, setExpiriesDays] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [styleNoList, setStyleNoList] = useState([]);
    const [lineNoList, setLineNoList] = useState([]);
    const [unitId, setUnitId] = useState("");
    const [unitValue, setUnitValue] = useState("");
    const [shiftList, setShiftList] = useState([]);
    const [orderNo, setOrderNo] = useState("");
    const [styleNo, setStyleNo] = useState("");
    const [shiftId, setShiftId] = useState("");
    const [shiftValue, setShiftValue] = useState("");
    const [supervisorId, setSupervisorId] = useState("");
    const [supervisorName, setSupervisorName] = useState("");
    const [lineNoId, setLineNoId] = useState("");
    const [lineNoValue, setLineNoValue] = useState("");
    const [orderId, setOrderId] = useState("");
    const [manpowerCost, setManpowerCost] = useState(0);
    const [earnedCost, setEarnedCost] = useState(0);
    const [totalProd,setTotalProd]=useState(0);
    const [calculations,setCalculations] = useState([]);
    // const [styleNoData, setStyleNoData] = useState([]);
    const [dataValue,setDataValue] =useState([]);
    const [graphLabel,setGraphLabel] = useState([]);
    const [chartView,setChartView] = useState(false);
    // const [dataSetValue,setDataSetValue] = useState([]);

    const cutimage = new Image(15, 15)
    cutimage.src = v1cutgreen_icon
    const sewimage = new Image(15, 15)
    sewimage.src = Sewv1green_icon
    const packimage = new Image(15, 15)
    packimage.src = packv1_icon

  let totalQty = 0;
  let totalHours=0;
  let workingHours=0;
  let total_eff=0; 

  useEffect(() => {
    var shiftList = [];
    var unitsList = [];
    var lineNoList = [];
    var supervisorList = [];
    var getInputParams = {};
    getInputParams['company_id'] = getLoginCompanyId;
    getInputParams['workspace_id'] = getWorkspaceId;
    axios
    .post(ServerUrl + "/get-sam-master-data", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data)
      
       var samMasterData = response.data.data;
      samMasterData.map((mapData) => {
        if( mapData.type == "Shift"){
          shiftList.push({
            id: mapData.id,
            value: mapData.value
          });
        } if( mapData.type == "Supervisor" ){
          supervisorList.push({
            id: mapData.id,
            value: mapData.value}
            );
        }
        if( mapData.type == "Line" ){
          lineNoList.push({
            id: mapData.id,
            value: mapData.value,
            line_unit: mapData.line_unit});
      }
        if( mapData.type == "Unit"){
          unitsList.push({
            id: mapData.id,
            value: mapData.value
          }
            );
        } 
          
              setShiftList(shiftList);
              setUnitsList(unitsList);
              setLineNoList(lineNoList);
              setSupervisorList(supervisorList);
      });
    })
    axios
      .post(ServerUrl + "/get-styles", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setStyleNoList(response.data.data);
      });
  }, []);

  const getReportDetails = () => {
    let inputParams = {};
    inputParams['company_id'] =getLoginCompanyId;
    inputParams["workspace_id"] = getWorkspaceId;
    inputParams["date"] = document.getElementById('Date').value;
    inputParams["shift_id"] =  document.getElementById('shift_id').value; 
    inputParams["style_no"] =  styleNo;
    inputParams["supervisor_id"] =  supervisorId;
    inputParams["unit_id"] =  unitId;
    inputParams["Line_id"] =  lineNoId;
    axios
      .post(ServerUrl + "/get-sam-report", apiencrypt(inputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
          setCalculations(response.data.calculations);
          SetSamReportDetails(response.data.data);
          setManpowerCost(response.data.manpower_cost);
          setEarnedCost(response.data.earned_cost);
          setTotalProd(response.data.calculations.total_production);
          SetSamReportTimingDetails(response.data.timings);  
          setDataValue(response.data.graph_qty);
          setGraphLabel(response.data.graph_label);
          // LineChartTimeData(response.data.timings,response.data.data)  ;  
        }
        if (response.data.status_code === 401) {
          Swal.fire({
            title:t(response.data.error.date) ||
              t(response.data.error.shift_id),  
            text: t("fieldMissing"),
            icon: "warning",
            button: t("okLabel"),
          });
        }
      });
   
  }
const saveCanvass=()=>{
  const chartCanvas = document.getElementById('LineID');
  //console.log("content--->",chartCanvas)
  chartCanvas.toBlob(function (blob) {
    saveAs(blob, 'GanttChart.png');
  });
}
const saveCanvas = () => {
  // Get th  e chart canvas element
  const chartCanvas = document.getElementById('LineID');
  // Create a new canvas to add header text
  const downloadCanvas = document.createElement('canvas');
  downloadCanvas.width = chartCanvas.width;
  downloadCanvas.height = chartCanvas.height + 200; // Adjusted height for header text
  // Get the 2D context of the new canvas
  const ctx = downloadCanvas.getContext('2d');
  // Load the company logo image
  const logoImg = new Image();
  logoImg.src = logo;
  logoImg.onload = () => {
    // Set the font and position for the title text
ctx.font = '20px Arial';
ctx.textAlign = 'center'; 
ctx.textBaseline = 'middle';
// Draw the company logo
const logoWidth = 100;
const logoHeight = 60;
const logoX = 10;
const logoY = 10;
ctx.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);
// Draw the title text
const titleText = 'Line Chart';
const titleX = downloadCanvas.width / 2; // Center horizontally
// Calculate the vertical center position for the title
const titleHeight = 20; // Height of the title text
const titleY = logoY + logoHeight / 2 + titleHeight / 2; // Vertically center the title
ctx.fillStyle = 'black';
ctx.fillText(titleText, titleX, titleY);
// Draw the current date on the right side
const currentDate = new Date().toLocaleDateString();
const dateX = downloadCanvas.width - 10; // Adjusted X position for the date
const dateY = titleY; // Align with the title vertically
ctx.font = '16px Arial';
ctx.textAlign = 'right';
ctx.fillText(currentDate, dateX, dateY);
// Set the font and position for the filter texts
ctx.font = 'bold 16px Arial';
ctx.textAlign = 'left';
ctx.textBaseline = 'middle';
    // Construct the filter texts
    const filterTexts = [];
    if (samDate) {
      filterTexts.push({ label: 'Date: ', value: samDate });
    }
    if (shiftValue) {
      filterTexts.push({ label: 'Shift: ', value: shiftValue });
    }
    if (styleNo) {
      filterTexts.push({ label: 'Style No: ', value: styleNo });
    }
    if (supervisorName) {
      filterTexts.push({ label: 'Supervisor: ', value: supervisorName });
    }
    if (unitValue) {
      filterTexts.push({ label: 'Unit: ', value: unitValue });
    }
    if (lineNoValue) {
      filterTexts.push({ label: 'Line No: ', value: lineNoValue });
    }
    // Calculate the width and height of each filter text
    const textHeight = 20; // Height of each line
    const filterTextWidths = filterTexts.map((text) => ctx.measureText(text).width);
    const filterTextTotalWidth = filterTextWidths.reduce((acc, width) => acc + width, 0);
    const filterTextGap = 15; // Gap between filter texts
    // Calculate the position for the filter texts
    const filterTextX = logoX + logoWidth + 10; // Position next to the logo
    const filterTextY = titleY + textHeight + 30; // Position below the title text
    // const filterTextY = titleY + titleHeight + 5; // Add 5 pixels of additional spacing
    // Draw each filter text
    let currentX = filterTextX;
    filterTexts.forEach((filterText, index) => {
    ctx.fillStyle = 'grey';
    // Draw the label (bold)
    ctx.font = 'bold 16px Arial';
    ctx.fillText(filterText.label, currentX, filterTextY);
    // Draw the value (regular font style)
    ctx.font = '16px Arial';
    const valueX = currentX + ctx.measureText(filterText.label).width + 5; // Adjust the X position for value
    ctx.fillText(filterText.value, valueX, filterTextY);
    currentX = valueX + ctx.measureText(filterText.value).width + filterTextGap; // Update the currentX position
});
    // Draw the chart canvas
    const chartY = filterTextY + textHeight + 20;
    const chartHeight = chartCanvas.height;
    ctx.drawImage(chartCanvas, 0, chartY, chartCanvas.width, chartHeight);
    // Convert the canvas to a Blob
    downloadCanvas.toBlob((blob) => {
      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'chart.png';
      // Simulate a click on the link to trigger the download
      link.click();
      // Clean up the temporary link
      URL.revokeObjectURL(link.href);
    });
  };
};




const saveCanvasss = () => {
  // Get the chart canvas element
  const chartCanvas = document.getElementById('LineID');

  // Create a new canvas to add header text
  const downloadCanvas = document.createElement('canvas');
  downloadCanvas.width = chartCanvas.width;
  downloadCanvas.height = chartCanvas.scrollHeight + 100; // Adjust the height as needed


  // Get the 2D context of the new canvas
  const ctx = downloadCanvas.getContext('2d');

  // Load the company logo image
  const logoImg = new Image();
  logoImg.src = logo;
  logoImg.onload = () => {
    // Draw the company logo
    const logoWidth = 70;
    const logoHeight = 60;
    const logoX = 10;
    const logoY = 10;
    ctx.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);

    // Set the font and position for the title text
    ctx.font = '20px Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Draw the title text
    const titleText = 'Line Chart';
    const titleX = downloadCanvas.width / 2; // Center horizontally
    const titleY = logoY + logoHeight + 40; // Position below the logo
    ctx.fillStyle = 'black';
    ctx.fillText(titleText, titleX, titleY);

    // Set the font and position for the table
    ctx.font = '16px Arial';
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';

    // Construct the filter texts
    const filterTexts = [];

    if (samDate) {
      filterTexts.push({ label: 'Date', value: samDate });
    }
    if (shiftValue) {
      filterTexts.push({ label: 'Shift', value: shiftValue });
    }
    if (styleNo) {
      filterTexts.push({ label: 'Style No', value: styleNo });
    }
    if (supervisorName) {
      filterTexts.push({ label: 'Supervisor', value: supervisorName });
    }
    if (unitValue) {
      filterTexts.push({ label: 'Unit', value: unitValue });
    }
    if (lineNoValue) {
      filterTexts.push({ label: 'Line No', value: lineNoValue });
    }



// Define table styling
const tableX = logoX + logoWidth + 10; // Position next to the logo
const tableY = titleY + 30; // Position below the title text
const cellWidth = 100;
const cellHeight = 30;
const borderWidth = 1;
const borderColor = '#000000';

// Draw the table cells
let currentX = tableX;
let currentY = tableY;

filterTexts.forEach((filter, index) => {
  // Draw the table header with border
  ctx.strokeStyle = borderColor;
  ctx.fillStyle = 'white';
  ctx.lineWidth = borderWidth;
  ctx.strokeRect(currentX, currentY, cellWidth, cellHeight);
  ctx.fillRect(currentX, currentY, cellWidth, cellHeight);
  ctx.fillStyle = 'black';
  ctx.fillText(filter.label, currentX + 5, currentY + cellHeight / 2);

  // Draw the table data with border
  ctx.strokeStyle = borderColor;
  ctx.fillStyle = 'white';
  ctx.lineWidth = borderWidth;
  ctx.strokeRect(currentX, currentY + cellHeight, cellWidth, cellHeight);
  ctx.fillRect(currentX, currentY + cellHeight, cellWidth, cellHeight);
  ctx.fillStyle = 'black';
  ctx.fillText(filter.value, currentX + 5, currentY + cellHeight + cellHeight / 2);

  // Update the current X position for the next cell
  currentX += cellWidth;
});


    // Draw the chart canvas
    const chartY = tableY + cellHeight + 20; // Position below the table
    ctx.drawImage(chartCanvas, 0, chartY);

    // Convert the canvas to a data URL
    const dataURL = downloadCanvas.toDataURL();

    // Save the data URL as an image file
    saveAs(dataURL, 'chart.png');
  };
};




  
  // const saveCanvas = () => {
  //   // Get the chart canvas element
  //   const chartCanvas = document.getElementById('LineID');
  
  //   // Create a new canvas to add header text
  //   const downloadCanvas = document.createElement('canvas');
  //   downloadCanvas.width = chartCanvas.width;
  //   downloadCanvas.height = chartCanvas.height + 80; // Add 80 pixels for the header text and gap
  
  //   // Get the 2D context of the new canvas
  //   const ctx = downloadCanvas.getContext('2d');
  
  //   // Load the company logo image
  //   const logoImg = new Image();
  //   logoImg.src = logo;
  //   logoImg.onload = () => {
  //     // Draw the company logo
  //     const logoWidth = 70;
  //     const logoHeight = 60;
  //     const logoX = 10;
  //     const logoY = 10;
  //     ctx.drawImage(logoImg, logoX, logoY, logoWidth, logoHeight);
  
  //     // Set the font and position for the header text
  //     ctx.font = '16px Arial';
  //     ctx.textAlign = 'left';
  //     ctx.textBaseline = 'top';
  
  //     // Construct the header text
  //     const headerText = 'Filters:';
  //     const filterTexts = [];
 
   
  //     if (samDate) {
  //       filterTexts.push(` Date: ${samDate} `);
  //     }
  //     if (shiftValue) {
  //       filterTexts.push(`Shift: ${shiftValue}`);
  //     }
  //     if (styleNo) {
  //       filterTexts.push(`Style No: ${styleNo}`);
  //     }
  //     if (supervisorName) {
  //       filterTexts.push(`Supervisor: ${supervisorName}`);
  //     }
  //     if (unitValue) {
  //       filterTexts.push(`Unit: ${unitValue}`);
  //     }
  //     if (lineNoValue) {
  //       filterTexts.push(`Line No: ${lineNoValue}`);
  //     }
  
  //     // Calculate the width and height of the header text
  //     const textHeight = 16; // Height of each line
  
  //     // Calculate the position of the header text
  //     const headerX = logoX + logoWidth + 10; // Position after the logo
  //     const headerY = logoY + logoHeight + 10; // Position below the logo
  
  //     // Draw the header text
  //     ctx.fillStyle = 'black';  
  //     ctx.fillText(headerText, headerX, headerY);
  
  //     // Draw each filter text
  //     let currentX = headerX + ctx.measureText(headerText).width + 10; // Start position for the filter texts
  
  //     filterTexts.forEach((filterText) => {
  //       // Get the width and height of each filter text
  //       const filterTextWidth = ctx.measureText(filterText).width +5;
  
  //       // Draw the background rectangle for each filter text
  //       ctx.fillStyle = '#E4E5E8';
  //       ctx.boxPadding="2px"
  //       ctx.fillRect(currentX, headerY, filterTextWidth, textHeight);
  
  //       // Draw the filter text
  //       ctx.fillStyle = 'grey';
  //       ctx.fillText(filterText, currentX, headerY);
  
  //       // Update the current position for the next filter text
  //       currentX += filterTextWidth + 10; // Add a gap of 10 pixels
  //     });
  
  //     // Draw the chart canvas
  //     const chartY = headerY + textHeight + 20; // Position below the header text
  //     ctx.drawImage(chartCanvas, 0, chartY);
  
  //     // Convert the canvas to a data URL
  //     const dataURL = downloadCanvas.toDataURL();
  
  //     // Save the data URL as an image file
  //     saveAs(dataURL, 'chart.png');
  //   };
  // };
  
  
  
  
  
  
  

  
  
  
  
  // const saveCanvas = () => {
  //   // Get the chart canvas element
  //   const chartCanvas = document.getElementById('LineID');
  
  //   // Create a new canvas to add header text
  //   const downloadCanvas = document.createElement('canvas');
  //   downloadCanvas.width = chartCanvas.width;
  //   downloadCanvas.height = chartCanvas.height + 50; // Add 50 pixels for the header text
  
  //   // Get the 2D context of the new canvas
  //   const ctx = downloadCanvas.getContext('2d');
  
  //   // Draw the chart canvas onto the new canvas
  //   ctx.drawImage(chartCanvas, 0, 50);
  // // Draw the company logo
  // //   const logoImg = new Image();
  // //   logoImg.onload = () => {
  // //     const logoWidth = 50;
  // //     const logoHeight = 50;
  // //     ctx.drawImage(logoImg, textX, textY, logoWidth, logoHeight);
  //   // Render the header text as HTML onto the new canvas
  //   const headerText = `
  //     <div style="font-family: Arial; font-size: 16px;">
  //       <div><img src="${companylogo}" /></div>
  //       ${samDate && `<p style="background-color: red;">Date: ${samDate}</p>`}
  //       ${shiftValue && `<p>Shift: ${shiftValue}</p>`}
  //       ${styleNo && `<p>Style No: ${styleNo}</p>`}
  //       ${supervisorName && `<p>Supervisor: ${supervisorName}</p>`}
  //       ${unitValue && `<p>Unit: ${unitValue}</p>`}
  //       ${lineNoValue && `<p>Line No: ${lineNoValue}</p>`}
  //     </div>
  //   `;
  
  //   const tempElement = document.createElement('div');
  //   tempElement.innerHTML = headerText;
  
  //   // Set the font and position for the header text
  //   ctx.font = '16px Arial';
  //   ctx.fillStyle = 'black';
  //   ctx.textAlign = 'left';
  //   ctx.textBaseline = 'top';
  
  //   // Convert the header HTML to a string
  //   const headerString = tempElement.textContent || tempElement.innerText;
  
  //   // Draw the header text onto the canvas
  //   ctx.fillText(headerString, 10, 20);
  
  //   // Convert the canvas to a data URL
  //   const dataURL = downloadCanvas.toDataURL();
  
  //   // Save the data URL as an image file
  //   saveAs(dataURL, 'chart.png');
  // };
  
//   const saveCanvas = () => {
//     // Get the chart canvas element
//     const chartCanvas = document.getElementById('LineID');
  
//     // Create a new canvas to add header text
//     const downloadCanvas = document.createElement('canvas');
//     downloadCanvas.width = chartCanvas.width;
//     downloadCanvas.height = chartCanvas.height + 50; // Add 50 pixels for the header text
  
//     // Get the 2D context of the new canvas
//     const ctx = downloadCanvas.getContext('2d');
  
//     // Draw the chart canvas onto the new canvas
//     ctx.drawImage(chartCanvas, 0, 50);
  
//     // Set the font and position for the header text
//     const font = '16px Arial';
//     const textColor = 'black';
//     const textX = 10;
//     const textY = 20;
//     const logoWidth = 50;
//     const logoHeight = 50;  
//     const logoImg = new Image();
//     logoImg.src=companylogo
//     // Render the header text onto the new canvas
//     const headerText = `
//       <div style="font-family: Arial; font-size: 16px;">
//         ${ctx.drawImage(logoImg, textX, textY, logoWidth, logoHeight)}
//         ${samDate ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Date: ${samDate}</p>` : ''}
//         ${shiftValue ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Shift: ${shiftValue}</p>` : ''}
//         ${styleNo ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Style No: ${styleNo}</p>` : ''}
//         ${supervisorName ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Supervisor: ${supervisorName}</p>` : ''}
//         ${unitValue ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Unit: ${unitValue}</p>` : ''}
//         ${lineNoValue ? `<p style="background-color: red; border: 1px solid black; margin: 0;">Line No: ${lineNoValue}</p>` : ''}
//       </div>
//     `;
  
//     // Convert the header HTML to a DOM element
//     const tempElement = document.createElement('div');
//     tempElement.innerHTML = headerText;
  
//    // Apply styles to the header text elements
//    const headerDiv = tempElement.firstChild;
//    headerDiv.style.fontFamily = 'Arial';
//    headerDiv.style.fontSize = '16px';   

// const headerImg = headerDiv.querySelector('img');
// headerImg.style.verticalAlign = 'middle';
// headerImg.style.marginRight = '10px';

// const headerParagraphs = headerDiv.querySelectorAll('p');
// headerParagraphs.forEach((p) => {
//   p.style.backgroundColor = 'red';
//   p.style.border = '1px solid black';
//   p.style.margin = '0';
// });
  
//     // Draw the header text onto the canvas
//     ctx.font = font;
//     ctx.fillStyle = textColor;
//     ctx.textAlign = 'left';
//     ctx.textBaseline = 'top';
//     ctx.drawImage(tempElement, textX, textY);
  
//     // Convert the canvas to a data URL
//     const dataURL = downloadCanvas.toDataURL();
  
//     // Save the data URL as an image file
//     saveAs(dataURL, 'chart.png');
//   };
  
  const LineChartTimeData=(timeData,samReportDetails)=>{

    samReportDetails.map((styleNoObj)=>{
      styleNoData.push(styleNoObj.style_no);
    })
    
  }

const toDownloadAsPdf =()=>{
  let inputParams = {};
  inputParams['company_id'] = getLoginCompanyId;
  inputParams["workspace_id"] = getWorkspaceId;
  inputParams["user_id"] = getLoginUserId;
  inputParams["staff_id"] = getLoginStaffId;
  inputParams["date"] = samDate;
  inputParams["shift_id"] =  document.getElementById('shift_id').value;
  inputParams["style_no"] =  styleNo;
  inputParams["supervisor_id"] =  supervisorId;
  inputParams["unit_id"] =  unitId;
  inputParams["Line_id"] =  lineNoId;
 
  axios
    .post(ServerUrl + "/download-sam-report", apiencrypt(inputParams),{responseType: 'blob'})
    .then((response) => {
     
      let name = "samreport-"+Date.now()+".pdf";
      DownloadFile(response.data, name);
    });
}
const lineChartData = {

  labels: samReportTimingDetails,
  datasets: dataValue.map((series, index) => {
    const gLabelData = graphLabel.length !=0 ? graphLabel[index] : 0
    return {
      label: gLabelData,
      data: series.styledata,
      pointBackgroundColor: function(context) {
       if (context.raw == 0) {          
        return '#FF3838';
      }
        else if (gLabelData.target <= context.raw) {          
          return '#9CC450';
        }
        else if (gLabelData.target >= context.raw) {          
          return '#ff9900';
        }
      },
      pointStyle: 'round',
      pointRadius: 5,
      pointBorderColor: 'rgb(0, 0, 0)',
      fill: false,
      borderColor: "rgba(36, 105, 92, 0.4)",
    };
  }),
  };

const lineChartOptions = {
  maintainAspectRatio: false,
  legend: {
      display: false,
  },
  events: ['click'],
  scales: {
    y: {
      beingAtZero: true,
      title: {
        display: true,
        text: 'Target Quantity',
        color: "black",
        fontStyle: "bold",
        font: {
          size: 14
        }
      },
      min: 0,
      // max: 100,
      ticks: { stepSize: 200 }
    },
    x: {
      title: {
        display: true,
        text: 'Time',
        align: 'start',
        color: "black",
        fontStyle: "bold",
        font: {
          size: 14
        }
      },
    }
  },
  plugins: {
      datalabels: {
          display: false,
      },                                                
      legend: { // Hide Legend
        display: false,
    },  
    tooltip: {
      // mode: "index",
      // intersect: false,
      // bodyColor: "black",
      // mode: "y",
      boxWidth: 20,
      boxHeight: 30,
      boxPadding: 2,
      borderColor: "#E0E0E0",
      borderWidth: 1,
      backgroundColor: "#ffffff",
      titleColor: "#000",
      xPadding: 10,
      yPadding: 10,
      usePointStyle: true,
      yAlign: 'bottom',
      bodyColor:"black", 
      callbacks: {
        beforeTitle: function(context){
          const iconSize = 20; 
          const iconUrl= 'path/to/v1cutgreen_icon';

            if (context[0].dataset.label.type == "Cutting") {
                  return `${context[0].dataset.label.type}  ${context[0].parsed.y}/${context[0].dataset.label.target}`;
                }
                else if (context[0].dataset.label.type == "Sewing") {       
                  return ` ${context[0].dataset.label.type} ${context[0].parsed.y}/${context[0].dataset.label.target}`;
                }
                else if (context[0].dataset.label.type == "Packing") {
                  return ` ${context[0].dataset.label.type}  ${context[0].parsed.y}/${context[0].dataset.label.target}`;
                }
        },
      

        title: function(context){
        return `Time: ${context[0].label}`;
        },

        labelColor: (context) =>{
          return {
              backgroundColor: 'red'
          }
      },
      labelPointStyle: (context) => {
        if (context.dataset.label.type == "Cutting") {
          return {
            pointStyle: cutimage,
          }
        }
        else if (context.dataset.label.type == "Sewing") {
          return {
            pointStyle: sewimage,
          }
        }
        else if (context.dataset.label.type == "Packing") {
          return {
            pointStyle: packimage,
          }
        }
      },
        // labelPointStyle:(context) => {
        //   return {
        //     pointStyle : cutimage,
        // } 
        // },

        label: function (context) {
          var label = context.dataset.label;
          if (context.parsed.y !== null) {
            return [ "StyleNo" +': '+ context.dataset.label.style, "Line No" +': ' + context.dataset.label.lineNo,
                      "Shift" +': ' + context.dataset.label.shift,
                      "Unit" +': ' + context.dataset.label.unit ,
                      "SAM"  +': ' +  context.dataset.label.sam,
                      "Supervisor" +': ' +  context.dataset.label.supervisor,
                      "Tailors/Helpers"+': ' +  context.dataset.label.noOfTailors +"/" +context.dataset.label.helpers 
           ]            
          }          
        },
      }
    },
  }
}
  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs
          mainTitle={t("SAM Daily Entries")}
          parent={t("SAM Daily Entries")}
          title={t("SAM Daily Entries")}
        />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <Card className="m-b-20">
          <CardBody className="shadow shadow-showcase">
            <Row className="g-12 ">
              <Col md={10}>
                <Row className="g-12">
                <Col md={3}>
                    <Label>{t("date")}<sup className="font-danger">*</sup></Label>
                    <Input
                      name="date"
                      type="date"
                      //min={new Date().toISOString().split('T')[0]}
                      max={new Date().toISOString().split("T")[0]}
                      placeholder={t("date")}
                      aria-label="Date"
                      onChange={(e)=>setSamDate(e.target.value)}
                      defaultValue={samDate}
                      id="Date"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }
                    }
                    >
                    </Input>
                  </Col>
                  
                  <Col md={3}>
                    <Label>{t("shift")}<sup className="font-danger">*</sup></Label>
                    <Input
                      name="shift_id"
                      type="select"
                      placeholder={t("shift")}
                      aria-label="shift"
                      defaultValue=""
                      id="shift_id"
                      onChange={(e) => {
                        var splitData = (e.target.value).split("&&");
                        setShiftId(splitData[0]);
                        setShiftValue(splitData[1]);
                      }}
                    >
                      <option value="" disabled>{t("selectShift")}</option>
                      { shiftList.length > 0 ?  
                              shiftList.map((data) => (
                                    <option value={data.id +"&&"+ data.value}>{data.value}</option>
                                ))
                            :
                            ""} 
                    
                    </Input>
                  </Col> 
                  <Col md={3}>
                    <Label>{t("styleNo")}</Label>
                    <Input
                      name="styleNo"
                      type="select"
                      placeholder={t("styleNo")}
                      aria-label="styleNo"
                      defaultValue=""
                      id="styleNoID"
                      onChange={(e) => {
                        var splitData = (e.target.value).split("&&");
                        setOrderId(splitData[0]);
                        setOrderNo(splitData[1]);  
                        setStyleNo(splitData[2]);  
                      }}                   
                    >
                      <option value="" disabled>{t("selectStyleno")}</option>
                      { styleNoList.length > 0 ?
                              styleNoList.map((styleList) => 
                              (<option value={styleList.id + "&&" + styleList.order_no + "&&" + styleList.style_no}>{styleList.style_no} 
                                ({styleList.order_no})
                                </option>))
                                :
                                ""
                            }
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>{t("supervisor")}</Label>
                    <Input
                      name="supervisor"
                      type="select"
                      placeholder={t("supervisor")}
                      aria-label="supervisorId"
                      defaultValue=""
                      id="supervisorId"
                      onChange={(e) => {
                        var splitData = (e.target.value).split("&&");
                        setSupervisorId(splitData[0]);
                        setSupervisorName(splitData[1]);
                      }}
                    ><option value="" disabled>{t("selectSupervisor")}</option>
                       { 
                                supervisorList.length > 0 ?  
                                supervisorList.map((data) => (
                                  <option value={data.id + "&&"+data.value }>{data.value}</option>
                              ))
                            :
                            ""}
                    </Input>
                  </Col>
                  <span className="m-t-15"> </span>
                  <Col md={3}>
                    <Label>{t("unit")}</Label>
                    <Input
                      name="unit"
                      type="select"
                      placeholder={t("unit")}
                      aria-label="unit"
                      defaultValue=""
                      id="unit"
                    onChange={(e) => {
                      var splitData = (e.target.value).split("&&");
                      setUnitId(splitData[0]);
                      setUnitValue(splitData[1]);
                    }}
                    >
                     <option value="" disabled>{t("selectUnit")}</option>
                     { 
                              unitsList.length > 0 ? 
                              unitsList.map((data) => (
                                <option value={data.id + "&&"+data.value }>{data.value}</option>
                              )) : ""
                              } 
                    </Input>
                  </Col>
                  <Col md={3}>
                    <Label>{t("lineNo")}</Label>
                    <Input
                      name="line_no"
                      type="select"
                      placeholder={t("lineNo")}
                      aria-label="line_no"
                      defaultValue=""
                      onChange={(e) => {
                        var splitData = (e.target.value).split("&&");
                        setLineNoId(splitData[0]);
                        setLineNoValue(splitData[1]);
                      }}
                    >
                      <option value="" disabled>{t("selectLineNo")}</option>
                              { lineNoList.length > 0 ?  
                                lineNoList.map((data) => (
                                  unitId == data.line_unit 
                                  ? 
                                    <option value={data.id + "&&"+data.value }>{data.value}</option>
                                  :
                                  ""
                                ))
                              :
                              ""} 
                      </Input>
                  </Col>
                  <Col md={3}></Col>

                
                </Row>
              </Col>
              <Col md="2">
                <Row className="g-12">
                  <Col md="12" className="m-t-30 text-end">
                    <Button
                      className="btn btn-primary f-left m-r-10 m-b-5"
                      onClick={() => getReportDetails()}
                    >
                      {t("search")}
                    </Button>
                    <Link to="/dailysamdetails" className="f-left">
                      <Button className="btn secondaryBtn ">
                        {t("clear")}
                      </Button>
                    </Link>
                  </Col>
                </Row>
                <Row  className="g-12">
                <Col md="12" className="m-t-30 text-end">
                 <Row>
                  <Label>Chart View</Label>
                 </Row>
               <Media key="1" >
                     <Media body className="text-end icon-state switch-md">
                     
                       <Label className="switch" name="toggle"> 
                         <Input className="toggleButton"
                           type="checkbox" name="chartView"
                           value={chartView} checked={!!chartView}
                           onChange={(e) => { setChartView(prevCheck => !prevCheck) }} />
                         <span className="switch-state"></span>
                       </Label>
                     </Media>
                   </Media>
         </Col>
                </Row>
               
              </Col>
             
            </Row>
               
            <Row>
              <div className="card-block row centerAlign">
                <Col sm="12" lg="12" xl="12">
                  <Row>
                    {samReportTimingDetails.length>0?
                      <>   
                       {chartView == false ?
                       <>
                       {samReportTimingDetails.length>0? 
                       <div className="cursor-pointer p-1 p-l-0 m-t-45 m-r-25 text-end"
                      onClick={()=> toDownloadAsPdf()} >
                      <img alt={t("download")} title={t("download")} src={DownloadIcon} />
                    </div>:""
                    }
                      <Row className="g-12 m-t-15" >
                     
                       
                        <div className="table-responsive">
                        <Table className="table shadow shadow-showcase  table-bordered ">
                          <thead className="overViewSku ">
                            <tr className="outer inner">

                              <th scope="col" className="p-2 centerAlign" width="2%">{t("unit")}</th>
                              <th scope="col" className="p-2 centerAlign " width="3%">{t("lineNo")}</th>
                              <th scope="col" className="p-2 centerAlign " width="5%">{t("shift")}</th>
                              <th scope="col" className="p-2 centerAlign " width="5%">{t("styleNo")}</th>
                              <th scope="col" className="p-2 centerAlign " width="5%">{t("supervisor")}</th>
                              <th scope="col" className="p-2 centerAlign" width="5%">{t("sam")}</th>
                              <th scope="col" className="p-2 centerAlign" width="5%">{t("tailorsHelpers")}</th>
                              <th scope="col" className="p-2 centerAlign" width="5%">{t("tgt")}</th>
                              {samReportTimingDetails.map((obj) => (

                                <th scope="col" className="p-2 centerAlign" width="5%">{obj}</th>
                              ))}
                              <th scope="col" className="p-2 centerAlign" width="5%">{t("totalOutput")}</th>
                              <th scope="col" className="p-2 centerAlign" width="5%">{t("eff")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(samReportDetails).map((samData, index) => (
                              <tr>
                                <td scope="col"  className="centerAlign" >{samData.unit}</td>
                                <td scope="col" className="centerAlign">{samData.line_no}</td>
                                <td scope="col" className="centerAlign">{samData.shift}</td>
                                <td scope="col" className="centerAlign">{samData.style_no}</td>
                                <td scope="col" className="centerAlign">{samData.supervisor}</td>
                                <td scope="col"  className="centerAlign">{samData.sam}</td>
                                <td scope="col" className="centerAlign">{samData.tailors} / {samData.helpers}</td>
                                <td scope="col" className="centerAlign" style={{backgroundColor:"#458C41",color:'#fff'}}>{samData.target}</td> 

                                {samReportTimingDetails.map((obj) => {
                                  let blink_range_val = (samData.target*samData.blink_per)/100 
                                  let low_value_per=(samData.target/100)*80
                                  samData.hasOwnProperty(obj) ? totalHours++  : 1
                                  totalQty += samData.hasOwnProperty(obj) ? Number(samData[obj]['qty']) : 0

                                  workingHours = totalHours > 4 ? (totalHours-samData.break): totalHours;

                                  return (samData.hasOwnProperty(obj) 
                                  ?
                                  samData.target <= samData[obj]['qty']  
                        
                                  ?
                                              // <td className="blink_me" scope="col" style={{backgroundColor:"#9CC450" ,color:'#fff'}} >
                                              
                                              <td scope="col" style={{backgroundColor:"#9CC450" ,color:'#fff'}} >
                                                <span className="centerAlign alerts-border">{samData[obj]['qty']}</span>
                                              {samData[obj]['comments']==""?"":<span style={{ float: "right" }}>
                                                  <img
                                                    src={infoIcon}
                                                    width="15px"
                                                    height="15px"
                                                    tooltip={t("viewComments")}
                                                    title={samData[obj]['comments']}
                                                    style={{ cursor: 'pointer' }}
                                                  />
                                                </span>}
                                                
                                              </td> 
                                  : 
                                        blink_range_val >= samData[obj]['qty']
                                      ? 
                                            <td  scope="col" style={{backgroundColor:"#FF3838" ,color:'#fff'}} >
                                              <span className="centerAlign blink_me">{samData[obj]['qty']}</span>
                                            {samData[obj]['comments']==""?"":<span style={{ float: "right" }}>
                                                <img
                                                  src={infoIcon}
                                                  width="15px"
                                                  height="15px"
                                                  tooltip={t("viewComments")}
                                                  title={samData[obj]['comments']}
                                                  style={{ cursor: 'pointer' }}
                                                />
                                              </span>}
                                              
                                            </td> 
                                      :
                                            
                                        samData[obj]['qty'] >= low_value_per ?

                                      <td  scope="col " style={{backgroundColor:"#ff9900 " ,color:'#fff'}} >
                                              <span className="centerAlign">{samData[obj]['qty']}</span>
                                            {samData[obj]['comments']==""?"":<span style={{ float: "right" }}>
                                                <img
                                                  src={infoIcon}
                                                  width="15px"
                                                  height="15px"
                                                  tooltip={t("viewComments")}
                                                  title={samData[obj]['comments']}
                                                  style={{ cursor: 'pointer' }}
                                                />
                                              </span>}
                                              
                                            </td> 
                                            :
                                        
                                          <td scope="col" style={{backgroundColor:'#FF3838', color:'#fff'}}>
                                              <span className="centerAlign">{samData[obj]['qty']}</span>
                                              {samData[obj]['comments']==""?"": <span style={{ float: "right" }}>
                                                <img
                                                  src={infoIcon}
                                                  width="15px"
                                                  height="15px"
                                                  tooltip={t("viewComments")}
                                                  title={samData[obj]['comments']}
                                                  style={{ cursor: 'pointer' }}
                                                />
                                              </span>}
                                             
                                            </td> 
                                        :
                                        <td scope="col"  className="centerAlign">-</td>
                                  )
                                  
                                })}
                                 {Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)>=100
                                 ?<td scope="col" style={{backgroundColor:"#9CC450" ,color:'#fff'}}className="centerAlign">{totalQty} </td>
                                 :
                                 Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)>=80
                                 ?
                                 <td scope="col" style={{backgroundColor:'#ff9900',color:'#fff'}} className="centerAlign">{totalQty} </td>
                                 :
                                 <td scope="col" style={{backgroundColor:'#FF3838',color:'#fff'}} className="centerAlign">{totalQty} </td>}
                                
                                {Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)>=100?
                                <td scope="col" style={{backgroundColor:"#9CC450" ,color:'#fff'}} className="centerAlign">{Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)}%</td>
                                :
                                Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)>=80?
                                <td scope="col" style={{backgroundColor:'#ff9900',color:'#fff'}} className="centerAlign">{Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)}%</td>
                                :
                                <td scope="col" style={{backgroundColor:'#FF3838',color:'#fff'}} className="centerAlign">{Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)}%</td>}
                                
                                {/* {hiddenData===true?{totalQty = 0}
                                (totalHours=0):"" } */}
                                <td style={{display:"none"}}>
                                {
                                  total_eff+=Math.round (((totalQty*samData.sam)/(60*samData.tailors*(workingHours)))*100)
                                }
                                {totalQty = 0}
                                {totalHours=0}
                                {workingHours=0}
                                
                                </td>
                              </tr>
                                                                                                                        
                            ))}
                                {/* <td scope="col" className="centerAlign">{totalQty}</td> */}
                                <tr>
                                  <td colSpan={20}></td>
                                </tr >
                              <tr style={{ backgroundColor:'#fbfdd8' }}>
                                <th style={{ textAlign:'right' }} colSpan={5}>{t("total")}</th>
                                <td  className="centerAlign">{calculations.avg_sam}</td>
                                <td  className="centerAlign">{calculations.total_tailors}/{calculations.total_helpers}</td>
                                <td  className="centerAlign" style={{backgroundColor:"#458C41",color:'#fff'}}>{calculations.total_target}</td>
                                
                                {samReportTimingDetails.map((obj) => 
                                (     
                                  calculations.time_arr[obj]!=undefined?
                                    Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + parseInt(a), 0)/calculations.total_target*100) >= 100 ?
                                          <td style={{backgroundColor:"#9CC450" ,color:'#fff'}} >{calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)}</td> :
                                          Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + parseInt(a), 0)/calculations.total_target*100) >= 80  ?
                                          <td  style={{backgroundColor:"#ff9900 " ,color:'#fff'}} >{calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)}</td> :
                                          <td style={{backgroundColor:"#FF3838" ,color:'#fff'}} >{calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)}</td>
                                          :"" 
                                ))
                                }

                              { Math.round((total_eff / samReportDetails.length)) >=100 ?
                                <td  className="centerAlign" style={{backgroundColor:"#9CC450" ,color:'#fff'}}>{calculations.total_production}</td> :
                                Math.round((total_eff / samReportDetails.length)) >=80 ?
                                <td  className="centerAlign" style={{backgroundColor:"#ff9900" ,color:'#fff'}}>{calculations.total_production}</td> :
                                <td  className="centerAlign" style={{backgroundColor:"#FF3838" ,color:'#fff'}}>{calculations.total_production}</td> 
                              } 

                              { Math.round((total_eff / samReportDetails.length)) >=100 ?
                                <td  className="centerAlign" style={{backgroundColor:"#9CC450" ,color:'#fff'}}>{Math.round(total_eff / samReportDetails.length)}%</td> :
                                Math.round((total_eff / samReportDetails.length)) >=80 ?
                                <td  className="centerAlign" style={{backgroundColor:"#ff9900 " ,color:'#fff'}}>{Math.round(total_eff / samReportDetails.length)}%</td> :
                                <td  className="centerAlign" style={{backgroundColor:"#FF3838" ,color:'#fff'}}>{Math.round(total_eff / samReportDetails.length)}%</td> 
                              }  
                             </tr>
                             <tr>
                             <th style={{ textAlign:'right' ,backgroundColor:"#E4E5E8"}}  colSpan={8}>{t("totalEFF")}</th>
                             {samReportTimingDetails.map((obj) => ( 
                              calculations.time_arr[obj]!=undefined ?                            
                              Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)/calculations.total_target*100) >= 100 ?
                                    <td style={{backgroundColor:"#9CC450" ,color:'#fff'}} >{Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)/calculations.total_target*100)}%</td> :
                                    Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)/calculations.total_target*100) > 80  ?
                                    <td  style={{backgroundColor:"#ff9900 " ,color:'#fff'}} >{Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)/calculations.total_target*100)}%</td> :
                                    <td style={{backgroundColor:"#FF3838" ,color:'#fff'}} >{Math.round(calculations.time_arr[obj].reduce((partialSum, a) => partialSum + a, 0)/calculations.total_target*100)}%</td> 
                                    :""
                             ))}
                             </tr>
                          </tbody>
                        </Table>   
                        </div>
                        </Row>
                        </> : 
                        <>                   
                        <Row className="g-12 m-t-15" >
                        {samReportTimingDetails.length>0? 
                       <div className="cursor-pointer p-1 p-l-0 m-t-45 m-r-25 text-end"
                      onClick={()=> saveCanvas()} >
                      <img alt={t("download")} title={t("download")} src={DownloadIcon} />
                    </div>:""
                    }
                        <Col xl="12">
                          <Card>
                            <HeaderCard title={LineChart} />
                            <CardBody className="chart-block">
                                <Line data={lineChartData} options={lineChartOptions} width={717} height={358} id="LineID"/>
                            </CardBody>
                          </Card>
                          </Col>
                          </Row>
                          <Col sm="6" lg="6" xl="6" className="m-t-15 offset-xl-3" >
                            <div className="table-responsive  shadow shadow-showcase" style={{ borderRadius:"10px",borderColor:"#DBDBDB"  }}>
                              <Table  className="table shadow shadow-showcase table-bordered " style={{ borderRadius:"10px",borderColor:"#DBDBDB" }}>
                              <thead style={{ backgroundColor:"#EDEDED" }}>
                              <tr className="outer inner" >
                                <th scope="col" className="p-2 centerAlign" >{t("manpowerCost")}</th>
                                    <th scope="col" className="p-2 centerAlign " >{t("earnedCost")}</th>
                                    <th scope="col" className="p-2 centerAlign ">{t("profitLoss")}</th>
                                    <th scope="col" className="p-2 centerAlign ">{t("manpowerCostPC")}</th>
                                    <th scope="col" className="p-2 centerAlign ">{t("earnedCostPC")}</th>                              
                              </tr>                        
                                </thead>  
                                <tbody>
                                <tr>
                                <td scope="col"  className="centerAlign">{manpowerCost}</td>


                                <td scope="col"  className="centerAlign">{earnedCost}</td>
                                {earnedCost-manpowerCost > 0 
                                  ?
                                  <td scope="col"  className="centerAlign" style={{backgroundColor:"#458C41" ,color:'#fff'}}>{earnedCost-manpowerCost}</td>
                                  :
                                  <td scope="col"  className="centerAlign" style={{backgroundColor:"#FF3838" ,color:'#fff'}}>{earnedCost-manpowerCost}</td>
                                }
                                <td scope="col"  className="centerAlign">{(manpowerCost/totalProd).toFixed(2)}</td>
                                {(                           
                                Math.round((manpowerCost/totalProd).toFixed(2)) < Math.round((earnedCost/totalProd).toFixed(2))) ?
                                <td scope="col"  className="centerAlign" style={{backgroundColor:"#458C41" ,color:'#fff'}}>{(earnedCost/totalProd).toFixed(2)}</td>
                                :
                                <td scope="col"  className="centerAlign" style={{backgroundColor:"#FF3838" ,color:'#fff'}}>{(earnedCost/totalProd).toFixed(2)}</td>
                                }
                                </tr>
                              </tbody>                           
                              </Table>      
                            </div>
                          </Col>
                          </>
                          }
                      </>
                      :<div className="p-5 align-self-center media-body centerAlign"
                      style={{ marginTop: "155px" }}>
                      <img src={noData} style={{ width: "200px" }} />
                      <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                    </div>}
                    
                  </Row>
                </Col>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );

}



export default DailySamDetails;