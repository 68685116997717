import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col, Nav, NavItem, NavLink, TabContent, TabPane  } from "reactstrap";
import Moment from "moment";
import { PHPtoJSFormatConversion } from "../../helper";
import { useTranslation } from 'react-i18next';

const NewTaskHistoryOffCanvas = ({ modal, toggle, taskID, orderId, taskSubtitle , historyStatus , historyData }) => {
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState("");
  const [BasicLineTab, setBasicLineTab] = useState('1');
  const lengthOfHistory = historyData.length;
  let date;
  let type; let prevValue; let nextValue;let sd=0;let ed=0;let picd=0;
  const { t } = useTranslation();
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"} className="offcanvas-width">
            <OffcanvasHeader className="bg-primary offcanvas-header">{taskSubtitle} 
            <span className="f-right cursor-pointer" onClick={toggle}>X</span></OffcanvasHeader> 
      <OffcanvasBody>
      {historyStatus == "failure"?
      <>
        <div className="middle">
          {t("noHistoryOrReschedule")}
        </div>
      </> : 
      <>
          <Nav className="border-tab" tabs>
            <NavItem>
              <NavLink href="javascript:void(0)" className={BasicLineTab === '1' ? 'active linenav-padding' : 'linenav-padding'} onClick={() => setBasicLineTab('1')}>{t("all")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="javascript:void(0)" className={BasicLineTab === '2' ? 'active linenav-padding' : 'linenav-padding'} onClick={() => setBasicLineTab('2')}>{t("startDate")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="javascript:void(0)" className={BasicLineTab === '3' ? 'active linenav-padding' : 'linenav-padding'} onClick={() => setBasicLineTab('3')}>{t("endDate")}</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="javascript:void(0)" className={BasicLineTab === '4' ? 'active linenav-padding' : 'linenav-padding'} onClick={() => setBasicLineTab('4')}>{t("personInCharge")}</NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={BasicLineTab}>
            <TabPane className="fade show" tabId="1">
              {historyData.map((data)=>{
                if((data.start_date != null || data.start_date == null) && data.rescheduled_start_date !=null && data.rescheduled_type==="Reschedule"){
                  type = t("startDate");
                  prevValue = data.start_date != null ? PHPtoJSFormatConversion.format(new Date(data.start_date)): null;
                  nextValue = PHPtoJSFormatConversion.format(new Date(data.rescheduled_start_date));
                }
                if((data.end_date != null || data.end_date == null) && data.rescheduled_end_date !=null && data.rescheduled_type==="Reschedule"){
                  type = t("endDate");
                  prevValue = data.end_date != null ? PHPtoJSFormatConversion.format(new Date(data.end_date)) : null;
                  nextValue = PHPtoJSFormatConversion.format(new Date(data.rescheduled_end_date));
                }
                if(data.pic_id != null && data.prev_pic_id !=null && data.rescheduled_type==="Reassign"){
                  type = t("personInCharge");
                  prevValue = data.prevStaffName;
                  nextValue = data.nextStaffName;
                }
                return(
                    <Card>
                      <CardBody className="p-2 p-b-0 ">
                        <Row>
                          <Col>
                            <div className="m-l-5">{PHPtoJSFormatConversion.format(new Date(data.created_at))}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col className="p-1 p-b-0">
                            <div className="border-3 b-l-primary f-18 p-2 font-primary p-b-0 p-t-0 f-w-500">{data.userName}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="m-l-5">{data.reason}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={12} sm={12} className="p-1 p-b-0">
                                <div className="card-highlighted-text p-2 fc-event-main-frame">{type}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6} className="b-r-light p-b-0">
                              <div className="fc-event-main-frame p-2">
                                <p className="f-w-300">{t("changedFrom")}</p>
                                <p className="f-w-700">{prevValue != null ? prevValue : "---"}</p>
                              </div>
                          </Col>
                          <Col md={6} sm={6} className="p-b-0">
                                <div className="fc-event-main-frame p-2">
                                  <p className="f-w-300">{t("changedTo")}</p>
                                  <p className="f-w-700">{nextValue}</p>
                                </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                )
              })}
            </TabPane>
            <TabPane tabId="2">
            {historyData.map((data,i)=>{
                if((data.start_date != null || data.start_date == null) && data.rescheduled_start_date !=null && data.rescheduled_type==="Reschedule"){
                  sd = sd+1;
                  type = "Start Date";
                  prevValue = data.start_date != null? PHPtoJSFormatConversion.format(new Date(data.start_date)):null;
                  nextValue = PHPtoJSFormatConversion.format(new Date(data.rescheduled_start_date))
                  return(
                    <Card>
                      <CardBody className="p-2 p-b-0 ">
                        <Row>
                          <Col>
                            <div className="m-l-5">{PHPtoJSFormatConversion.format(new Date(data.created_at))}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col className="p-1 p-b-0">
                            <div className="border-3 b-l-primary f-18 p-2 font-primary p-b-0 p-t-0 f-w-500">{data.userName}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="m-l-5">{data.reason}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={12} sm={12} className="p-1 p-b-0">
                                <div className="card-highlighted-text p-2 fc-event-main-frame">{t("startDate")}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6} className="b-r-light p-b-0">
                              <div className="fc-event-main-frame p-2">
                                <p className="f-w-300">{t("changedFrom")}</p>
                                <p className="f-w-700">{prevValue != null ? prevValue : "---"}</p>
                              </div>
                          </Col>
                          <Col md={6} sm={6} className="p-b-0">
                                <div className="fc-event-main-frame p-2">
                                  <p className="f-w-300">{t("changedTo")}</p>
                                  <p className="f-w-700">{nextValue}</p>
                                </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                )
                }else{
                 return i === (lengthOfHistory-1) && sd===0?t("dataNotPresent"):""
                }
              })}
            </TabPane>
            <TabPane tabId="3">
            {historyData.map((data,i)=>{
                if((data.end_date != null || data.end_date == null) && data.rescheduled_end_date !=null && data.rescheduled_type==="Reschedule"){
                  ed =ed +1;
                  type = "End Date";
                  prevValue = data.end_date !=null ?PHPtoJSFormatConversion.format(new Date(data.end_date)):null;
                  nextValue = PHPtoJSFormatConversion.format(new Date(data.rescheduled_end_date))
                  return(
                    <Card>
                      <CardBody className="p-2 p-b-0 ">
                        <Row>
                          <Col>
                            <div className="m-l-5">{PHPtoJSFormatConversion.format(new Date(data.created_at))}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col className="p-1 p-b-0">
                            <div className="border-3 b-l-primary f-18 p-2 font-primary p-b-0 p-t-0 f-w-500">{data.userName}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="m-l-5">{data.reason}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={12} sm={12} className="p-1 p-b-0">
                                <div className="card-highlighted-text p-2 fc-event-main-frame">{t("endDate")}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6} className="b-r-light p-b-0">
                              <div className="fc-event-main-frame p-2">
                                <p className="f-w-300">{t("changedFrom")}</p>
                                <p className="f-w-700">{prevValue != null ? prevValue : "---"}</p>
                              </div>
                          </Col>
                          <Col md={6} sm={6} className="p-b-0">
                                <div className="fc-event-main-frame p-2">
                                  <p className="f-w-300">{t("changedTo")}</p>
                                  <p className="f-w-700">{nextValue}</p>
                                </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                )
                }else{
                  return  i === (lengthOfHistory-1) && ed===0? t("dataNotPresent"):""
                }
              })}
            </TabPane>
            <TabPane tabId="4">
            {historyData.map((data,i)=>{
                if(data.pic_id != null && data.prev_pic_id !=null && data.rescheduled_type==="Reassign"){
                  picd = picd+1;
                  type = "Person In Charge";
                  prevValue = data.prevStaffName;
                  nextValue = data.nextStaffName;
                  return(
                    <Card>
                      <CardBody className="p-2 p-b-0 ">
                        <Row>
                          <Col>
                            <div className="m-l-5">{PHPtoJSFormatConversion.format(new Date(data.created_at))}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col className="p-1 p-b-0">
                            <div className="border-3 b-l-primary f-18 p-2 font-primary p-b-0 p-t-0 f-w-500">{data.userName}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="m-l-5">{data.reason}</div>
                          </Col>
                        </Row>
                        <Row >
                          <Col md={12} sm={12} className="p-1 p-b-0">
                                <div className="card-highlighted-text p-2 fc-event-main-frame">{t("personInCharge")}</div>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6} sm={6} className="b-r-light p-b-0">
                              <div className="fc-event-main-frame p-2">
                                <p className="f-w-300">{t("changedFrom")}</p>
                                <p className="f-w-700">{prevValue != null ? prevValue : "---"}</p>
                              </div>
                          </Col>
                          <Col md={6} sm={6} className="p-b-0">
                                <div className="fc-event-main-frame p-2">
                                  <p className="f-w-300">{t("changedTo")}</p>
                                  <p className="f-w-700">{nextValue}</p>
                                </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                )
                }else{
                  return i === (lengthOfHistory-1) && picd===0? t("dataNotPresent") :""
                }
              })}
            </TabPane>
          </TabContent>
      </>}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default NewTaskHistoryOffCanvas;
/* Code By : Rithanesh */