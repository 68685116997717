import React, { useState } from 'react';
import {   
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Label, Row, Table } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges,ServerUrl } from '../../../Constant';
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId }
    from '../../../Constant/LoginConstant';
import axios from "axios";
import Swal from "sweetalert2";

import { useTranslation } from 'react-i18next';
import { apidecrypt, apiencrypt,capitalizeuppercase } from '../../../helper';

const AddSizeModal = ({ modal, toggle, inputParams, sizes, setGetSize, 
                            setAllSkuDetails, setGetColor, categoryName, orderId, setCategoryName, setSizeCategoryList }) =>{

  const {t} = useTranslation();

  const [sizeName, setSizeName] = useState('');

  const onSaveHandle = () => {
    if(!sizeName.trim()){
      Swal.fire({
        title: t("sizeName"),
        text: t("sizeValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    }else{
      axios
        .post(ServerUrl + "/size", apiencrypt({
          company_id: getLoginCompanyId,
          workspace_id: getWorkspaceId,
          user_id : getLoginUserId,
          staff_id : getLoginStaffId,
          status:1,
          name: sizeName,
          category: categoryName
        }))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("sizeAddedAlert"),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            }).then((result) => {
              if (result.isConfirmed)
              {
               refreshData();
              }
               refreshData();
            });
          }
          /* To show error if the Role is already present*/
          if (response.data.status_code === 400) {
            Swal.fire({
              title: t(response.data.message),
              text: t("enterDifferentName"),
              icon: "warning",
              button: t("okLabel"),
            });
          }
            /* To show error if the color is already present*/
            if (response.data.status_code === 401) {
              Swal.fire({
                title:  t("sizeExistsAlert"),
                text: t("enterDifferentName"),
                icon: "warning",
                button: t("okLabel"),
              });
            }
        });
    }
  };
  
  const refreshData = () => {

      /********************* [INPUT PARAMS for API Calls] (To add Order SKU)****************************/
      var getInputParams = {};
      getInputParams['company_id'] = getLoginCompanyId;
      getInputParams['workspace_id'] = getWorkspaceId;
      getInputParams['user_id'] = getLoginUserId;
      getInputParams['staff_id'] = getLoginStaffId;
      getInputParams['order_id'] = orderId;

    axios
    .post(ServerUrl + "/get-all-sku", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setAllSkuDetails(response.data.data);
      setGetColor(response.data.data.colors);
      setGetSize(response.data.data.sizes.filter(data => data.category == categoryName ));
      setCategoryName(categoryName);
    })

    axios
    .post(ServerUrl + "/get-size-category", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setSizeCategoryList(response.data.data);
      setCategoryName(categoryName);
      toggle();
    })

  };

  const clearData = () => {
    setSizeName(() => "");
    toggle();
  };
  
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[ ,A-Za-z0-9_-]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addSize")}</ModalHeader>
      <ModalBody>
        <Row>
        <div className="table-responsive">
          <Table>
              <thead>
                  <tr style={{ backgroundColor: '#f1f1f1' }} className="text-center">
                      <th scope="col" style={{ border: '1px solid #E2E2E2' }}> {t("category")} </th>
                      <th scope="col" style={{ border: '1px solid #E2E2E2' }}> {t("availSizes")} </th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td scope="col" 
                          style={{ border: '1px solid #E2E2E2', color: '#009688', fontWeight: '600', paddingTop: '2rem' }} 
                          className="text-center"> {categoryName} 
                      </td>
                      <td className="text-center middle" style={{ border: '1px solid #E2E2E2', padding: '1rem'}}>
                        { sizes.length > 0 ?
                          sizes.map(sizeList => (
                          <button key={sizeList.id} id={sizeList.id} 
                                style={{ padding: '10px', backgroundColor: '#f2f2f2', 
                                         marginLeft: '5px', borderRadius: '8px', 
                                         marginTop: '10px', paddingTop: '10px',  border: 'none', cursor: 'auto'}}>
                                {sizeList.name}
                          </button>)) : ""
                        }
                      </td>
                  </tr>
              </tbody>
          </Table>
        </div>
        </Row>
        <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
          <Label className="col-form-label" for="recipient-name" style={{ marginLeft: '-10px' }}>
          {t("addNewSize")}<sup className="font-danger">*</sup>
          </Label>
          <Input
            onKeyPress={(e) => handleKeyPress(e)}
            className="form-control"
            type="text"
            onChange={(e) => {setSizeName(e.target.value=capitalizeuppercase(e.target.value))}}/>
        </Row>
        <Row style={{ color: '#B0B0B0', fontSize: '13px', marginLeft: '7px', marginTop: '10px' }}>
        {t("noteIfUNeedMulSize")}
        </Row>
        <Row style={{ color: '#B0B0B0', fontSize: '13px', marginLeft: '7px', marginTop: '0px' }}>
        {t("plsAddWidCommaSep")}
        </Row>
      </ModalBody>
      <ModalFooter style={{ borderTop: "none" }}>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: () => clearData() }}>
        {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary  btn-sm", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddSizeModal;
/***** CODE BY : RAMANAN M  *****/