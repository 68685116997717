import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import {
    Card, CardBody, Col, Row, Table, Collapse,
    FormGroup, InputGroup, Input, Label, Progress, CardFooter
} from 'reactstrap';
import Moment from 'moment';
import PackIcon from "../../../assets/images/dms/icons/packIcon.svg";

import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const PackKnobChart = ({packTodayUpdatedValue,packActualTargetValue,packEndDate,packPerDay,packAvgPerDay,packEstDate,packActualDate,packCompleted}) => {
    const { t, i18n } = useTranslation();
    let packEstimatedRatePercentage = Math.round((packTodayUpdatedValue/packActualTargetValue)*100);
    let currentDate = Moment().format('DD MMM YYYY');
    let endDate=Moment(packEndDate).format('DD MMM YYYY');
   /******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
    const knobChartColor = () => {
        // if(packActualTargetValue<=packAvgPerDay){
        //     return '#00B38E'; 
        // } else{
            
        //     return '#FF3169';
        // }

        if((new Date(packEndDate).getTime() >= new Date(packEstDate).getTime())){
            return '#00B38E'; 
        }
        // else if((packActualTargetValue<=packAvgPerDay)){
        //     return '#00B38E'; 
        // }
        else{
            return '#FF3169';
        }
        // if(  (packEstimatedRatePercentage >= 0) && (packEstimatedRatePercentage <= 40)){
        //     return '#FF3169';
        // }
        // if( (packEstimatedRatePercentage > 40) && (packEstimatedRatePercentage <= 70)){
        //     return '#E58F1F';
        // }
        // if( (packEstimatedRatePercentage > 70) && (packEstimatedRatePercentage <= 100) || (packEstimatedRatePercentage > 100)){
        //     return '#00B38E';
        // }
    }
    useEffect(() => {

        var offsetArc = Knob({
            value: packAvgPerDay?packAvgPerDay: 0,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: packAvgPerDay
        });
        const packlist = document.getElementById('packknob');
        if (packlist.hasChildNodes()) {
            packlist.removeChild(packlist.children[0]);
        }

        document.getElementById('packknob').appendChild(offsetArc);
    }, [packAvgPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align" >
                <div className="knob text-center m-t-5" id="packknob">
                </div>
                <div className=' text-muted f-12 ' >{t("currentAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                { packCompleted== 0 ?<div className=' f-w-600 '  >-</div>:packEstDate? <div className=' f-w-600 '  >{(PHPtoJSFormatConversion.format(new Date(packEstDate)))} </div>:
                <div className='f-w-600'  >{(PHPtoJSFormatConversion.format(new Date(packActualDate)))} </div>}

                   {packEstDate=="" ? <div className='text-muted f-12 '  >{t("actDateCompletion")}</div>:
                <div className='text-muted f-12 '  >{t("estDateCompletion")}</div>}
                
               
                
                {/* {packEstDate=="Completed"? <div className=' text-muted  f-12'>Act Date Of Completion</div>:
                <div className=' text-muted  f-12' style={{ color: "#828282",}}>Est Date Of Completion</div>} */}
                {/* <div className=' text-muted f-12 ' style={{ color: "#828282" }}>Est Date Of Completion</div> */}
                {/* <div style={{ backgroundColor: "#13A1C1" }} className='taskCardBorder'>
                    <img src={PackIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Packing</span>
                </div> */}
            </Card>
        </>
    );
}
export default PackKnobChart;