import React , { useState }from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges , ServerUrl} from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt ,capitalizeFirstLowercaseRest} from '../../../helper';

const FormAddOrderCatModal = ({ modal, toggle, companyId, workspaceId, category }) =>{

  const {t} = useTranslation();

  const [categoryName, setCategoryName] = useState("");
  const onSaveHandle = () => {
    if(!categoryName){
      Swal.fire({
        title: t("category"),
        text:  t("categoryValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    }else{
    
    axios
      .post(ServerUrl + "/create-user-category", apiencrypt({
        company_id: companyId,
        workspace_id: workspaceId,
        name: categoryName,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("categoryAddedAlert"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer:5000,
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-user-category", apiencrypt({
                  company_id: companyId,
                  workspace_id: workspaceId,
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data);
                  category(response.data.data);
                });
            }
            setTimeout(() => {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-user-category", apiencrypt({
                  company_id: companyId,
                  workspace_id: workspaceId,
                }))
                .then((response) => {
                  response.data = apidecrypt(response.data);
                  category(response.data.data);
                });
            }, 100);
          });
        }
        /* To show error if the Role is already present*/
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t("categoryExistsAlert"),
            text: t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addCategory")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("addCategory")}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setCategoryName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddOrderCatModal;
/* Code By : Rithanesh */