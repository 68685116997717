import {
    Container, Row, Col, CardBody, Card, CardHeader, FormGroup, Form, Label, Input, Button
} from "reactstrap";

import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Btn, H6, Image } from "../../AbstractElements";
//import { useForm } from "react-hook-form";
import Dropzone from "react-dropzone-uploader";
import { getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission, getLoginUserId }
    from '../../Constant/LoginConstant';
import companylogo from "../../assets/images/dms/icons/profile.png";
import Files from 'react-files';
import { allowedLogoFormat, allowedIconExtensionsUpload, apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../../src/helper"
import { useTranslation } from 'react-i18next';


/************************** SWEET ALERT , API CALLS *********************************/

import Swal from 'sweetalert2';
import axios from "axios";
import { AutoCloseIn1000Sec, ServerUrl, maxUploadFileSize,maxUploadCompFileSize } from '../../Constant/index';

/***********************************************************************************/

const EditCompanyProfile = () => {
    // if(getLoginCompanyId>0){
    //   window.location.href = '/dashboard';
    // }

    const userId = getLoginUserId;
    const companyId = getLoginCompanyId;
    const [logoURL, setLogo] = useState('');
    const [logoNew, setLogoNew] = useState('');
    const [company_name, setCompanyName] = useState('');
    const [contact_person, setContactPerson] = useState('');
    const [contact_number, setContactNumber] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [country_id, setCountryId] = useState('');
    const [country, setCountry] = React.useState(['']);
    const [company_settings, setCompanySettings] = React.useState(['']);
    const [user_id, setUserId] = useState(userId);
    const [gst_number, setGstNumber] = useState('');
    const [ifsc_code, setIfscCode] = useState('');
    const [pan_number, setPanNumber] = useState('');
    const [state, setState] = useState('');
    const [zipcode, setZipCode] = useState('');
    const [account_number, setAccountNumber] = useState('');
    const [files, setFiles] = useState('');
    const { t } = useTranslation();
    const handleChangeStatus = ({ meta, file, e }, status) => {
        setLogo(file);
    };

    var getInputSettings = {}

    getInputSettings['company_id'] = getLoginCompanyId;

    useEffect(() => {
        axios
            .post(ServerUrl + "/get-company-settings", apiencrypt(getInputSettings))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setCompanySettings(response.data.data.CompanyDetails);
                setLogo(response.data.data.logoURL)
                setCompanyName(response.data.data.CompanyDetails.company_name)
                setContactPerson(response.data.data.CompanyDetails.contact_person)
                setContactNumber(response.data.data.CompanyDetails.contact_number)
                setAddress1(response.data.data.CompanyDetails.address1)
                setAddress2(response.data.data.CompanyDetails.address2)
                setCity(response.data.data.CompanyDetails.city)
                setState(response.data.data.CompanyDetails.state)
                setZipCode(response.data.data.CompanyDetails.zipcode)
                setCountryId(response.data.data.CompanyDetails.country_id)
            })

        axios
            .get(ServerUrl + "/get-countries")
            .then((response) => {
                response.data = apidecrypt(response.data)
                setCountry(response.data.data);
            })


        {
            (((getStaff === "Staff" && getStaffPermission.includes('editcompany')) || getStaff == null))
                ?
                ""
                :
                window.location.href = "/dashboard"
        }

    }, [])

     /*********** API CALL[POST]-(update-company-settings) **********/
    const handleSubmitHandler = (e) => {
        let valid = companySettingValidation();
        // document.getElementById("SubmitButton").setAttribute("disabled", true);
        if (Object.keys(valid).length == 0) {
            const companySettings = {
                logo: logoNew, company_name, contact_person, contact_number, address1, address2,
                city, user_id, gst_number, ifsc_code, pan_number, country_id, state, zipcode, account_number, company_id: companyId,
            };
            /****************************************************************************/
            /****************** API CALL (POST) *****************************************/
            axios
                .post(ServerUrl + "/update-company-settings", companySettings,
                    /*---------------POST LOGO------------*/
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                .then((response) => {
                    /******************* RESPONSE - 200 ( Company registerd successfully) ********************************/
                    if (response.data.status_code === 200) {

                        Swal.fire({
                            title: company_name,
                            text: t(response.data.message),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false,
                            timer: 5000
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    // setTimeout(() => {
                                        window.location.href = '/company-profile';
                                    // }, 100);
                                }
                                setTimeout(() => {
                                    window.location.href = '/company-profile';
                                }, 100);
                            })
                    }
                    /*************************** RESPONSE - 401 ( Field missing) ****************************************/
                    else if (response.data.status_code === 401) {
                        Swal.fire({
                            title:
                                t(response.data.errors.logoNew) || t(response.data.errors.company_name) || t(response.data.errors.state) ||
                                t(response.data.errors.contact_person) || t(response.data.errors.contact_number) || t(response.data.errors.address1) ||
                                t(response.data.errors.address2) || t(response.data.errors.city) || t(response.data.errors.zipcode) || t(response.data.errors.account_number) ||
                                t(response.data.errors.ifsc_code) || t(response.data.errors.gst_number) || t(response.data.errors.pan_number),
                            text: t("fieldMissing"),
                            icon: "warning",
                            button: t("okLabel"),
                            timer:3000,
                        });
                    }
                })
                /*************************** RESPONSE - 400 ( Company already Exists) ******************************/
                .catch(err => {
                    Swal.fire({
                        title: err.t(response.data.message),
                        icon: "warning",
                        button: t("okLabel"),
                        timer:3000,
                    });
                });
        } else {
            Swal.fire({
                title: t("validationError"),
                text: t("fillRequiredFields"),
                icon: "error",
                button: t("okLabel"),
                timer:3000,
            });
        }
    }
    const [errors, setValidErrors] = useState({});
    const companySettingValidation = (data) => {
        let errors = {};

        if (!company_name) {
            errors.company_name = ("enterCompanyName");
        }
        else if (!(company_name).match(/^[@&0-9a-zA-Z_-\s]+$/g)) {
            errors.company_name = ("numbersCharactersAllowed");
        }
        if (!contact_person || contact_person == "") {
            errors.contact_person = ("enterContactPersonName");
        } else if (!(contact_person).match(/^[a-zA-Z\s]+$/g)) {
            errors.contact_person = ("allowedCharactersSpace");
        }

        //     if(!/^[0-9]+$/.test(contact_number)){
        if (contact_number == '') {
            errors.contact_number = ("enterMobileNumber");
        } else if (!/^[0-9]+$/.test(contact_number)) {
            errors.contact_number = ("numbersOnlyAllowed");
        }
        if (((contact_number.length != null && contact_number.length != 'null' && contact_number.length != '' && (parseInt(contact_number.length) < 10) || (parseInt(contact_number.length) > 15)))) {
            errors.contact_number = ("enter10DigitsMobileNumber");
        }
        setValidErrors(errors);
        return errors;
    };

    /************************* DELETE FILE ******************************************/
    const deleteFile= () => {
         setFiles('');
        setLogoNew('');
        return files

    }
    
    /************************* UPDATE FILE ******************************************/
    const onFilesChange = (files) => {
        const filteredfiles = files.filter((e, index) => {
            if (allowedIconExtensionsUpload.exec(e.name)) {
                const getFileSize = Math.round((e.size / 1024));
                if (getFileSize > maxUploadCompFileSize) {
                    Swal.fire({
                        title: t("fileSizeExceeded"),
                        text: t("uploadaFileSize") + (maxUploadCompFileSize) + " KB",
                        icon: "warning",
                        button: t("okLabel"),
                        timer:3000,
                    })
                    asv = 1;
                    return false;
                }
                //if(e.size)
                return true;
            } else {
                Swal.fire({
                    title: t("wrongFormatTitleAlert"),
                    text: t("uploadFollowingFileFormat") + allowedLogoFormat,
                    icon: "warning",
                    button: t("okLabel"),
                    timer:3000,
                })
                asv = 1;
                return false;
            }
        })
        setFiles(files)
        setLogoNew(files[0]);
       
        return files
    }
    
    /************************* FILES ERROR  ****************************************/
    const onFilesError = (error, file) => {
        setFiles(file)
        setLogoNew('');
    }

    return (
        <Fragment>
            <Row className="pgbgcolor">
                <Breadcrumbs mainTitle={t("editCompanySetting")} parent="Company Setting" title={t("editCompanySetting")} />
            </Row>
            <Container fluid={true} className="general-widget topaln">
                <Row>
                    <Col sm="12" >
                        <Card>
                            <CardBody>
                                
                                    <Card className="shadow shadow-showcase">
                                        <span className="p-l-10 editcompanyprofile">
                                            <Label className="skudetailsclr p-1 editcompanyprofile" name="logo">
                                            <span className="p-r-5"></span> {t("companyLogo")}<span className="p-r-5"></span>
                                            </Label>
                                        </span>

                                        <div className="media m-b-10 p-3 m-t-10">
                                            <Col lg="2">
                                                {logoURL ? <img src={logoURL} alt={t("companyLogo")} width="100" />
                                                    :
                                                    <img src={companylogo} />}
                                            </Col>

                                                <Col lg="10">
                                                    <FormGroup>
                                                        <Files
                                                            className='files-dropzone fileContainer'
                                                            onChange={onFilesChange}
                                                            onError={onFilesError}
                                                            accept={[".png,.jpg,.jpeg"]}
                                                            multiple={false}
                                                            canCancel={false}
                                                            clickable
                                                        >
                                                            {
                                                                files.length > 0
                                                                    ?
                                                                    <div className='files-gallery '>
                                                                        {files.map((file,i) =>
                                                                            < >
                                                                                <Image key={i} style={{ float: "Right" }}
                                                                                    attrImage={{
                                                                                        className: 'files-gallery-item ritemarg', Width: "150", hight: "200",
                                                                                        alt: "img", src: `${file.preview.url}`
                                                                                    }} />
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    : <div className="ritemargine" >
                                                                        <Btn attrbtn={{ className: "mt-2", type: "button", color: 'primary' }} >{t("uploadLogo")}</Btn>
                                                                    </div>
                                                            }
                                                        </Files>

                                                        {files.length > 0 ?
                                                            <span className="ritemargine" style={{ float: "Right" }} >

                                                                <Button attrbtn={{ className: "mt-2", color: 'primary' }}   onClick={() => deleteFile()}>
                                                                    {t("delete")}
                                                                </Button></span> : " "}
                                                    </FormGroup>
                                                </Col>
                                        </div>
                                    </Card>
                                    <FormGroup>
                                        {/* <hr className="mt-4 mb-4" /> */}
                                        <Card className="shadow shadow-showcase">
                                            {/*start order details */}
                                            <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 " name="logo">
                                                <span className="p-r-5"></span> {t("companyInfo")}<span className="p-r-5"></span>
                                                </Label>
                                            </span>

                                            {/* <Col sm="12"><H6 attrH6={{ className: 'pb-2' }}>{t("companyInfo")}</H6></Col> */}
                                            <Row className="m-t-10 p-3">
                                                <Col lg="4" md="4" sm="12" xs="12">

                                                    <FormGroup>
                                                        <Label htmlFor="exampleFormControlInput1">
                                                            {t("companyName")}<sup className="font-danger">*</sup>
                                                        </Label>

                                                        <Input
                                                            className={`${errors.company_name && 'error-valid'}  form-control`}
                                                            type="text"
                                                            value={company_settings.company_name} readOnly
                                                        />
                                                        {errors.company_name && (
                                                            <span className="error-msg">{t(errors.company_name)}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>


                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleInputPassword2">
                                                            {t("contactPerson")}{" "}<sup className="font-danger">*</sup>
                                                        </Label>
                                                        <Input
                                                            className={`${errors.contact_person && 'error-valid'}  form-control`}
                                                            type="text"
                                                            placeholder={t("contactPerson")}
                                                            name="contact_person" 
                                                            defaultValue={company_settings.contact_person}
                                                            onChange={(e) => setContactPerson(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                                                            value={contact_person}
                                                        // maxLength="30"
                                                        />
                                                        {errors.contact_person && (
                                                            <span className="error-msg">{t(errors.contact_person)}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleInputPassword2">
                                                            {t("contactNumber")}{" "}<sup className="font-danger">*</sup>
                                                        </Label>
                                                        <Input
                                                            className={`${errors.contact_number && 'error-valid'}  form-control`}
                                                            type="tel"
                                                            placeholder={t("contactNumber")}
                                                            // name="contact_number"
                                                            // id="mobilenum"
                                                            maxLength="15"
                                                            defaultValue={company_settings.contact_number}
                                                            onChange={(e) => setContactNumber(e.target.value)}
                                                            value={contact_number}
                                                        // onKeyDown={(e) => setContactNumber(e.target.value)}
                                                        // maxLength="25"
                                                        />
                                                        {errors.contact_number && (
                                                            <span className="error-msg">{t(errors.contact_number)}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Card>

                                    </FormGroup>
                                    <FormGroup>

                                        <Card className="shadow shadow-showcase">
                                            <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1" name="logo">
                                                <span className="p-r-5"></span> {t("addressInfo")}<span className="p-r-5"></span>
                                                </Label>
                                            </span>
                                            {/* <Col sm="12"> <H6 attrH6={{ className: 'pb-2' }}>{t("addressInfo")}</H6></Col> */}
                                            <Row className="m-t-10 p-3">
                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleFormControlInput1">{t("addressline1")}
                                                        </Label>
                                                        <Input
                                                            className={`${errors.address1 && 'error-valid'}  form-control`}
                                                            type="text"
                                                            placeholder={t("addressline1")}
                                                            name="address1"
                                                            defaultValue={company_settings.address1}
                                                            onChange={(e) => setAddress1(e.target.value)}
                                                        // maxLength="100"
                                                        />
                                                    </FormGroup>

                                                    {errors.address1 && (
                                                        <span className="error-msg">{errors.address1}</span>
                                                    )}
                                                </Col>

                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleInputPassword2">
                                                            {t("addressline2")}
                                                        </Label>
                                                        <Input
                                                            className={`${errors.address2 && 'error-valid'}  form-control`}
                                                            type="text"
                                                            placeholder={t("addressline2")}
                                                            name="address2"
                                                            defaultValue={company_settings.address2}
                                                            onChange={(e) => setAddress2(e.target.value)}
                                                        // maxLength="100"
                                                        />
                                                    </FormGroup>
                                                    {errors.address2 && (
                                                        <span className="error-msg">{errors.address2}</span>
                                                    )}
                                                </Col>

                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleInputPassword2">
                                                            {t("city")}
                                                        </Label>
                                                        <Input
                                                            className={`${errors.city && 'error-valid'}  form-control`}
                                                            type="text"
                                                            placeholder={t("city")}
                                                            name="city"
                                                            defaultValue={company_settings.city}
                                                            onChange={(e) => setCity(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                                                        // maxLength="20"
                                                        />
                                                        {errors.city && (
                                                            <span className="error-msg">{errors.city}</span>
                                                        )}
                                                        <Input
                                                            className="form-control"
                                                            type="hidden"
                                                            placeholder={t("email")}
                                                            name="email"
                                                            defaultValue={company_settings.email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            maxLength="30"
                                                        />
                                                        <Input
                                                            className="form-control"
                                                            type="hidden"
                                                            placeholder={t("country")}
                                                            name="country_id"
                                                            value="1"

                                                            onChange={(e) => setCountryId(e.target.value)}
                                                        // maxLength="50"
                                                        />

                                                    </FormGroup>
                                                </Col>

                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup>
                                                        <Label htmlFor="exampleFormControlSelect9">
                                                            {t("statePrefecture")}
                                                        </Label>
                                                        <Input
                                                            type="text"
                                                            name="select"
                                                            placeholder={t("statePrefecture")}
                                                            className={`${errors.state && 'error-valid'}  form-control`}
                                                            defaultValue={company_settings.state}
                                                            onChange={(e) => setState(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                                                        // maxlength="30"
                                                        >
                                                        </Input>
                                                        {errors.state && (
                                                            <span className="error-msg">{errors.state}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <FormGroup id='country'>
                                                        <Label>{t("country")}</Label>
                                                        <Input type="select" placeholder={t("selectCountry")} defaultValue={country_id} className="form-control digits" name="countryId"
                                                            onChange={(e) => setCountryId(e.target.value)}>
                                                            <option selected disabled>{t("selectCountry")}</option>
                                                            {country.map(country => (
                                                                <option key={country.id} value={country.id} selected={country_id == country.id ? true : false}>
                                                                    {country.name}
                                                                </option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>


                                                <Col lg="4" md="4" sm="12" xs="12" >
                                                    <FormGroup>
                                                        <Label htmlFor="exampleInputPassword2">{t("postalCode")}</Label>
                                                        <Input
                                                            className={`${errors.zipcode && 'error-valid'}  form-control`}
                                                            type="text"
                                                            placeholder={t("postalCode")}
                                                            name="Postal Code"
                                                            defaultValue={company_settings.zipcode}
                                                            onChange={(e) => setZipCode(e.target.value)}
                                                        />
                                                        {errors.zipcode && (
                                                            <span className="error-msg">{errors.zipcode}</span>
                                                        )}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Card>

                                    </FormGroup>
                                    <Row>
                                        <Col sm="12">
                                            <FormGroup className="mb-0 f-right">
                                                <Button attrbtn={{ color: "success", className: "me-3" }} id="SubmitButton" onClick={() => handleSubmitHandler()}>
                                                    {t("saveAndContinue")}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                               
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

};
export default EditCompanyProfile;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/
