import React, { useState } from "react";
import ProductionView from "./ProductionView";
import TaskView from "./TaskView"
import {
  Offcanvas, OffcanvasBody, OffcanvasHeader, Nav, NavItem,
  NavLink, TabContent, TabPane
} from "reactstrap";
import { getLoginCompanyId, getWorkspaceId } from "../../Constant/LoginConstant"
import { useTranslation } from 'react-i18next';
const ProductionCanvas = ({ modal, toggle }) => {
  const [BasicLineTab, setBasicLineTab] = useState('1');
  const { t } = useTranslation();
  var getInputHolidays = {}
  getInputHolidays['company_id'] = getLoginCompanyId;
  getInputHolidays['workspace_id'] = getWorkspaceId;

  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"} className="productionoffcanvas-width">
      <OffcanvasHeader className="bg-primary offcanvas-header">{t("dashboardTaskProductionSettings")}
        <span className="f-right cursor-pointer" title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggle}>X</span></OffcanvasHeader>
      <OffcanvasBody className="height-equal">
        <Nav className="border-tab" tabs>
          <NavItem>
            <NavLink style={{ cursor: 'pointer' }} className={BasicLineTab === '1' ? 'active' : ''} onClick={() => setBasicLineTab('1')}>{t("taskStatus")}</NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ cursor: 'pointer' }} className={BasicLineTab === '2' ? 'active' : ''} onClick={() => setBasicLineTab('2')}>{t("productionStatus")}</NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={BasicLineTab}>
          <TabPane className="fade show" tabId="1">
            <TaskView />
          </TabPane>
          <TabPane className="fade show" tabId="2">
            <ProductionView />
          </TabPane>
        </TabContent>
      </OffcanvasBody>
    </Offcanvas>
  );
};
export default ProductionCanvas;

//**************************Code by : Praveen****************************//
