import React, { Fragment, useState, useEffect } from "react";
import { Card, Col, CardBody, Row } from "reactstrap";
import { ServerUrl } from "../../Constant/index";
import noData from "../../assets/images/dms/nodata.png";
import axios from "axios";
import {
  getLoginCompanyId, getLoginUserId,
  getWorkspaceId, getLoginStaffId,
} from "../../Constant/LoginConstant";
import { useTranslation } from "react-i18next";
import { apiencrypt, apidecrypt } from "../../helper";
import { Bar } from "react-chartjs-2";
import v1cutgreen_icon from "../../assets/images/dms/icons/v1cutgreen_icon.svg";
import Sewv1green_icon from "../../assets/images/dms/icons/Sewv1green_icon.svg";
import packv1_icon from "../../assets/images/dms/icons/packv1_icon.svg";

const Task = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [delayproduction, settopdelayprod] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(0);
  const { t } = useTranslation();
  const [taskStatusdashboard, settaskStatusdashboard] = useState([]);
  const [taskDelayedComplete, setTaskDelayedComplete] = useState();
  const [taskdelay, setTaskdelay] = useState();
  const [taskcompleted, setTaskcompleted] = useState();
  const [taskinProgress, setTaskinProgress] = useState();
  const [StyleNo, setStyleNo] = useState();
  const [taskyetToStart, setTaskyetToStart] = useState();
  const [orderNo, setOrderNo] = useState();
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));

  const cutimage = new Image(15, 15);
  cutimage.src = v1cutgreen_icon;
  const sewimage = new Image(15, 15);
  sewimage.src = Sewv1green_icon;
  const packimage = new Image(15, 15);
  packimage.src = packv1_icon;

  const converQtytoPerc = (ary, total) => {
    ary.map((data, index) => {
      if (parseInt(data) > 0) {
        ary[index] = ((parseInt(ary[index]) / parseInt(total[index])) * 100).toFixed(2);
      }
    });
    return ary;
  };

  useEffect(() => {
    axios.post(ServerUrl + "/get-tasks-status",
      apiencrypt({
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
      })
    ).then((response) => {
      let datas = apidecrypt(response.data);
      settaskStatusdashboard(datas.data);
      var Statustask = datas.data;
      var delcomplet = converQtytoPerc(Statustask[0].delayedCompleted, Statustask[0].total);
      var complet = converQtytoPerc(Statustask[0].completed, Statustask[0].total);
      var delay = converQtytoPerc(Statustask[0].delay, Statustask[0].total);
      var inProgress = converQtytoPerc(Statustask[0].inProgress, Statustask[0].total);
      var yetToStart = converQtytoPerc(Statustask[0].yetToStart, Statustask[0].total);
      // var yetToStart= converQtytoPerc(Statustask[0].inProgress,response.data.data[0].total[5])
      setTaskDelayedComplete(delcomplet);
      setTaskcompleted(complet);
      setTaskdelay(delay);
      setTaskinProgress(inProgress);
      setTaskyetToStart(yetToStart);
      setStyleNo(Statustask[0].styleNo);
      setOrderNo(Statustask[0].orderId);
    });
  }, []);

  const ordersttus = (indexValue) => {
    props.geOrderstatusId(orderNo[indexValue])
  }

  const barChartData = {
    labels: StyleNo,
    datasets: [
      {
        label: "Yet to Start",
        legend: "Yet to Start",
        data: taskyetToStart,
        borderColor: "#DFDFDF",
        backgroundColor: "#DFDFDF",
        barPercentage: 0.35,
        // highlightFill: '#DFDFDF',
        // highlightStroke: "#DFDFDF",
        // borderWidth: 1,
      },
      {
        label: "In Progress",
        legend: "In Progress",
        data: taskinProgress,
        borderColor: "#7B7B7B",
        backgroundColor: "#7B7B7B",
        barPercentage: 0.35,
      },
      {
        label: "Del.Completion",
        legend: "Del.Completed",
        data: taskDelayedComplete,
        borderColor: "#E69020",
        backgroundColor: "#E69020",
        barPercentage: 0.35,
      },
      {
        label: "Delayed",
        legend: "Delayed",
        data: taskdelay,
        borderColor: "#EE335E",
        backgroundColor: "#EE335E",
        barPercentage: 0.35,
      },
      {
        legend: "Completed",
        label: "Completed",
        data: taskcompleted,
        borderColor: "#27A79B",
        backgroundColor: "#27A79B",
        barPercentage: 0.35,
      },
    ],
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 20,
          boxHeight: 5,
          // padding: 20
        },
      },
      tooltip: {
        yAlign: "bottom",
        padding: 8,
        backgroundColor: "#F8F8F8",
        borderColor: "#F0F0F0",
        cornerRadius: 25,
        position: "nearest",
        titleAlign: "center",
        titleSpacing: 2,
        titleFont: { weight: "bold", },
        boxWidth: 20,
        boxHeight: 30,
        boxPadding: 2,
        borderWidth: 2,
        titleColor: "#000",
        bodyColor: "red",
        usePointStyle: true,

        callbacks: {
          labelColor: (context) => {
            if (context.dataset.label == "Completed") {
              return {
                borderColor: "#27A79B",
                backgroundColor: "#fff",
                borderWidth: 3,
                borderDash: [2, 2],
                borderRadius: 1,
                borderJoinStyle: "round",
              };
            } else if (context.dataset.label == "Delayed") {
              return {
                borderColor: "#EE335E",
                backgroundColor: "#fff",
                borderWidth: 3,
                borderDash: [2, 2],
                borderRadius: 1,
                borderJoinStyle: "round",
              };
            } else if (context.dataset.label == "Del.Completion") {
              return {
                borderColor: "#E69020",
                backgroundColor: "#fff",
                borderWidth: 3,
                borderDash: [2, 2],
                borderRadius: 1,
                borderJoinStyle: "round",
              };
            } else if (context.dataset.label == "In Progress") {
              return {
                borderColor: "#7B7B7B",
                backgroundColor: "#fff",
                borderWidth: 3,
                borderDash: [2, 2],
                borderRadius: 1,
                borderJoinStyle: "round",
              };
            } else if (context.dataset.label == "Yet Start") {
              return {
                borderColor: "#DFDFDF",
                backgroundColor: "#fff",
                borderWidth: 3,
                borderDash: [2, 2],
                borderRadius: 1,
                borderJoinStyle: "round",
              };
            }
          },

          labelTextColor: (context) => {
            if (context.dataset.label == "Completed") {
              return "#27A79B";
            } else if (context.dataset.label == "Delayed") {
              return "#EE335E";
            } else if (context.dataset.label == "Del.Completion") {
              return "#E69020";
            } else if (context.dataset.label == "In Progress") {
              return "#7B7B7B";
            } else if (context.dataset.label == "Yet Start") {
              return "#DFDFDF";
            }
          },
          title: function (tooltipItems, data) {
            return "";
          },
          label: function (context) {
            var label = "";
            if (context.parsed.y !== null) {
              label += " " + context.parsed.y + " " + "%";
            }
            return label;
          },
          labelPointStyle: function (context) {
            return {
              pointStyle: "circle",
              rotation: 0,
            };
          },
        },
      },
    },
    scales: {
      y: {
        stacked: true,
        // stackType: '100%',
        beingAtZero: true,
        title: {
          display: true,
          text: "Percentage",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
        ticks: {
          // forces step size to be 50 units
          // stepSize: 25
        },
        max: 100,
      },
      x: {
        stacked: true,
        // stackType: '100%',
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
        grid: {
          display: false,
        },
      },
    },
    onClick: (event, index) => {
      if (index.length > 0) {
        ordersttus(index[0].index)
      }
    }
  };

  const taskNoData = {
    labels: "",
    datasets: [
      {
        label: "No Data",
        legend: "No Data",
        data: [],
        borderColor: "#7b7b7b ",
        backgroundColor: "#7b7b7b ",
      },
    ],
  };

  const taskNoDataOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 40,
          boxHeight: 11,
        },  
      }
    },
    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "Percentage",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 }
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 },
      },
      x: {
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
      },
    },
  };

  return (
    <Fragment>
      <Col xl="6" md="12">
        <Card className="shadow shadow-showcase" style={{ marginTop: '-7px' }}>
          <span className="m-t-20 p-l-25">
            <span className="f-16 f-w-600"> {t("taskStatus")} </span>
          </span>
          <CardBody className="chart-block pt-1 pb-1" style={{ marginTop: '33PX' }}>
            <Row>
              <Col xxl="12" md="12">
                {StyleNo == "" ?                
                  <Bar data={taskNoData} options={taskNoDataOptions} width={3500} height={1320} />
                  :
                  <Bar data={barChartData} options={barChartOptions} width={3500} height={1320} />
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Task;
//**************************Code by : Praveen****************************//