import React, { Fragment, useEffect, useState } from 'react';
import {Card, CardBody, Col, Row, FormGroup, InputGroup, 
  Input, Label,} from 'reactstrap';
import axios from 'axios';
import { ServerUrl } from '../../../Constant/index'
import Knob from 'knob';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { getLoginCompanyId, getLoginUserId, getWorkspaceId, 
  getLoginStaffId } from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt, PHPtoJSFormatConversion } from '../../../helper';
import CutIcon from "../../../assets/images/dms/icons/cutIcon.svg";
import SewIcon from "../../../assets/images/dms/icons/sewIcon.svg";
import PackIcon from "../../../assets/images/dms/icons/packIcon.svg";
import TaskStatusPolarChart from './TaskStatusPolarChart';
import noData from "../../../assets/images/dms/nodata.png";
import CutKnobChart from './CutKnobChart';
import SewKnobChart from './SewKnobChart';
import PackKnobChart from './PackKnobChart';
import StatusChart from '../../OrderStatus/StatusChart';
import CutKnobChartrequired from './CutKnobChartrequired';
import SewKnobChartrequired from './SewKnobChartrequired';
import PackKnobChartrequired from './PackKnobChartrequired';
import Moment from 'moment';

const OrderStatusChart = ({ geOrderstatusId }) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));
  const [selectedLang, setSelectedLang] = useState(i18n.language == 'jp' ? 'jp' : 'en');
  /*Production Status*/
  const [filteredOrderDetails, setFilteredOrderDetails] = useState();
  const [filteredOrderDetailsProdData, setFilteredOrderDetailsProdData] = React.useState([]);
  /*Task Status*/
  const [completed, setCompleted] = useState("");
  const [delcompleted, setDelCompleted] = useState("");
  const [inProgress, setInProgress] = useState("");
  const [yetToStart, setYetToStart] = useState("");
  const [delay, setDelay] = useState("");
  const [hoildayDetails, setHoildayDetails] = useState("");
  const [weekOffDetails, setWeekOffDetails] = useState("");
  /*Grant Chart*/
  const [ganttChartOrderDetails, setGanttChartOrderDetails] = useState([]);
  const [ganttOrderStartDateDetails, setGanttOrderStartDateDetails] = useState("");
  const [taskdashboardStatus, SettaskdashboardStatus] = useState([]);

  const [ deliveryDate, setDeliveryDate ] = useState("");
  const [ deliveryDateStatus, setDeliveryDateStatus ] = useState("");

  const filterData = (e) => {
    setGanttChartOrderDetails('')
    axios.post(ServerUrl + "/order-status-dashboard",
      apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
        "user_id": getLoginUserId,
        "staff_id": getLoginStaffId,
        "order_id": e,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)

        setHoildayDetails(response.data.holidayDetails);
        setWeekOffDetails(response.data.weekOffs[0].days);
        setFilteredOrderDetails(response.data);
        setInProgress(response.data.taskCount[0].inProgress);
        setCompleted(response.data.taskCount[0].completed);
        setDelCompleted(response.data.taskCount[0].delayedCompleted);
        setDelay(response.data.taskCount[0].delay);
        setYetToStart(response.data.taskCount[0].yetToStart);
        setFilteredOrderDetailsProdData(response.data.prodData);
        setGanttOrderStartDateDetails(response.data.startDate);
        setGanttChartOrderDetails(response.data.taskChart);

        setDeliveryDate(response.data.delivery_date);
        setDeliveryDateStatus(response.data.delivery_date_exceed);

      })
  }

  useEffect(() => {
    setFilteredOrderDetailsProdData(() => "");
    setFilteredOrderDetails(() => "");

    axios.post(ServerUrl + '/get-styles', apiencrypt({
      company_id: getLoginCompanyId,
      user_id: getLoginUserId,
      staff_id: getLoginStaffId,
      workspace_id: getWorkspaceId,
      "page_type": "dashboard"
    })).then((response) => {
      response.data = apidecrypt(response.data)
      SettaskdashboardStatus(response.data.data);
      if (geOrderstatusId == '' || geOrderstatusId == undefined) {
        if ((response.data.data).length > 0) {
          filterData(response.data.data[0].id);
        }
      }
    });
    
    if (geOrderstatusId != '' && geOrderstatusId != undefined) {
      setGanttChartOrderDetails('')
      axios.post(ServerUrl + "/order-status-dashboard",
        apiencrypt({
          "company_id": getLoginCompanyId,
          "workspace_id": getWorkspaceId,
          "user_id": getLoginUserId,
          "staff_id": getLoginStaffId,
          "order_id": geOrderstatusId,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          setHoildayDetails(response.data.holidayDetails);
          setWeekOffDetails(response.data.weekOffs[0].days);
          setFilteredOrderDetails(response.data);
          setInProgress(response.data.taskCount[0].inProgress);
          setCompleted(response.data.taskCount[0].completed);
          setDelCompleted(response.data.taskCount[0].delayedCompleted);
          setDelay(response.data.taskCount[0].delay);
          setYetToStart(response.data.taskCount[0].yetToStart);
          setFilteredOrderDetailsProdData(response.data.prodData);
          setGanttOrderStartDateDetails(response.data.startDate);
          setGanttChartOrderDetails(response.data.taskChart);
        })
    }
  }, [geOrderstatusId]);

  /*To value set after dropdown selection*/
  return (
    <Fragment>
      <Col xs="12" sm="12" md="12" lg="12" xl="12" className="box-col-12" style={{ marginTop: '-7px' }}>
        <Card className="collopseradise" style={{ overflow: "hidden"}}>
          <Row className='m-t-10' 
               style={{ borderBottom: '1px solid #f2f2f2', 
                        boxShadow: '0px 15px 10px -15px #CBCACA'  }}>
            { /*Order Status DropDown*/}
            <Col className='m-t-10 m-l-35' lg="4" md="6" sm="12">
              <FormGroup style={{ marginTop: "2px" }}>
                <InputGroup>
                  <label for="Style_No" className="m-t-10 m-r-15 f-16 f-w-600"> {t("Order Status")} </label>
                  {taskdashboardStatus.length > 0 ? 
                    <Input 
                      style={{ cursor: 'pointer', borderRadius: '8px' }}  
                      className='form-control'
                      name="dropDown"  
                      type="select" 
                      id="Style_No"
                      placeholder={t("orderNo/styleNo")}
                      onChange={(e) => (filterData(e.target.value))}>
                      {/* {taskdashboardStatus.map((taskstatus, index) =>
                      ( <option value={orderStatusDrodwnId} selected>{t(orderStatusDrodwnValue)}</option>) )} */}
                      {taskdashboardStatus.map((taskstatus, index) =>
                      (index == 0 || geOrderstatusId == taskstatus.id ?
                        <option value={taskstatus.id} selected>{(taskstatus.order_no)} / {(taskstatus.style_no)} </option> :
                        <option value={taskstatus.id}> {(taskstatus.order_no)} / {(taskstatus.style_no)} </option>)
                      )}
                    </Input>
                  :                   
                  <Input 
                    style={{ cursor: 'pointer' }}  className='form-control'
                    name="dropDown"  type="select" id="Style_No"
                    placeholder={t("orderNo/styleNo")}> 
                  <option selected>{t('selectStyleno')}</option>) 
                  </Input>
                  }
                </InputGroup>
              </FormGroup>
            </Col>
            <Col className='m-t-10'>
              <FormGroup style={{ marginTop: "2px", float: 'right', paddingRight: '30px' }}>  
              { deliveryDate !== null && deliveryDate !== ""  ? 
                <InputGroup>
                  <label for="Style_No" className="m-t-10 m-r-15 f-16 f-w-600"> {t("delDate")} : </label>
                            {deliveryDate ? 
                              deliveryDateStatus == 1 ? 
                                <strong style={{ color: '#df1f26', marginTop: '12px' }}>
                                  {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                </strong> 
                              : 
                                <strong style={{ color: '#7b7b7b', marginTop: '12px' }}>
                                  {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                </strong>
                            : 
                              " "
                            }
                </InputGroup>
              :
              "" }
              </FormGroup>
            </Col>
          </Row>
          
          <CardBody style={{ paddingTop: '20px' }}>
            <Row>
              {filteredOrderDetailsProdData.length > 0 ?
                <>
                  <Row>
                    { /*Production Status Card */}
                    <Col xs="12" sm="12" md="12" lg="12" xl="8" >
                      <Row>
                        <Label className='justify-content-center text-center m-b-15 f-16 f-w-600'> {t("productionStatus")} </Label>
                        <Col lg="12" sm="12" md="12"  >
                          {filteredOrderDetailsProdData.length > 0 && filteredOrderDetailsProdData != null ?
                            filteredOrderDetailsProdData.map((productiondata) => {
                              let cuttingData = Math.floor(productiondata.cutPercentage);
                              let cuttingPendingValue = (productiondata.total - productiondata.cutCompleted);
                              let cutEndDate = (productiondata.cutEndDate);
                              let sewingData = Math.floor(productiondata.sewPercentage);
                              let sewingPendingValue = (productiondata.total - productiondata.sewCompleted);
                              let sewEndDate = (productiondata.sewEndDate)
                              let packingData = Math.floor(productiondata.packPercentage);
                              let packingPendingValue = (productiondata.total - productiondata.packCompleted);
                              let packEndDate = (productiondata.packEndDate)
                              let cutActualTargetValue = productiondata.cutActualTargetValue;
                              let cutTodayUpdatedValue = Math.abs(parseInt(productiondata.cutTodayUpdatedValue));
                              let cutPerDay = productiondata.cutPerDay;
                              // let cutEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.cutEndDate)));
                              let sewActualTargetValue = productiondata.sewActualTargetValue;
                              let sewTodayUpdatedValue = Math.abs(parseInt(productiondata.sewTodayUpdatedValue));
                              let sewPerDay = productiondata.sewPerDay;
                              // let sewEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.sewEndDate)))
                              let packActualTargetValue = productiondata.packActualTargetValue;
                              let packTodayUpdatedValue = Math.abs(parseInt(productiondata.packTodayUpdatedValue));
                              let packPerDay = productiondata.packPerDay;
                              // let packEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.packEndDate)))
                              let cutAvgPerDay = productiondata.cutAvgPerDay;
                              let sewAvgPerDay = productiondata.sewAvgPerDay;
                              let packAvgPerDay = productiondata.packAvgPerDay;
                              (PHPtoJSFormatConversion.format(new Date(productiondata.cutEndDate)))
                              // let cutEstDate =  (PHPtoJSFormatConversion.format(new Date(productiondata.cutEstDate)));
                              let cutEstDate = productiondata.cutEstDate;
                              let sewEstDate = productiondata.sewEstDate;
                              let packEstDate = productiondata.packEstDate;
                              let cutActualDate = productiondata.cutActualDate;
                              let sewActualDate = productiondata.sewActualDate;
                              let packActualDate = productiondata.packActualDate;

                              let cutCompleted = productiondata.cutCompleted
                              let sewCompleted = productiondata.sewCompleted
                              let packCompleted = productiondata.packCompleted
                              let cutStatus = productiondata.cutStatus
                              let sewStatus = productiondata.sewStatus
                              let packStatus = productiondata.packStatus

                              return (
                                <>
                                  <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="6">
                                      <Card className="productionStatusCard shadow shadow-showcase">
                                        <div className="table-responsive">
                                          <table className='table tableproductionStatusCard'>
                                            <tr>
                                              <td rowSpan="3" width="10px" style={{ backgroundColor:"#13D09A"}} className="proTD">
                                                 <img src={CutIcon} /></td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30' > {t("total")} </td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30' > {t("completed")} </td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30' > {t("pending")} </td>
                                              {/* <td style={{ color: "#828282" }} className='centerAlign' >Completion Date</td> */}
                                            </tr>
                                            <tr className='orderStatusBar'>
                                              <td className='centerAlign' style={{ color: "#000000", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.total}</td>
                                              <td className='centerAlign' style={{ color: "#00B38E", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.cutCompleted}</td>
                                              <td className='centerAlign' style={{ color: "#FF3169", fontSize: "17px", fontWeight: "600" }}>
                                                {cuttingPendingValue}</td>
                                            </tr>
                                            <tr className='sliderAlign'>
                                              <td colSpan="4">
                                                {cutStatus == 'Completed' || cutStatus == 'In Progress' ?
                                                  <Slider 
                                                    color={"#FFF000"} value={cuttingData} 
                                                    style={{ height: '10px',  marginTop: "15px",
                                                    marginBottom: "30px", width: '98%' }} 
                                                    trackStyle={{ backgroundColor: '#00B38E' }}
                                                    handleStyle={{borderColor: '#00B38E' }} />
                                                  : cutStatus == "Delayed Completion" 
                                                  ? 
                                                  <Slider 
                                                    color={"#FFF000"} value={cuttingData} 
                                                    style={{ height: '10px', marginTop: "15px",
                                                    marginBottom: "30px", width: '98%' }} 
                                                    trackStyle={{ backgroundColor: '#ed923e' }}
                                                    handleStyle={{ borderColor: '#ed923e'}} /> 
                                                  :
                                                    cutStatus == "Delayed" ?
                                                  <Slider 
                                                    color={"#FFF000"} 
                                                    value={cuttingData} 
                                                    style={{ height: '10px', marginTop: "15px", 
                                                    marginBottom: "30px", width: '98%' }} 
                                                    trackStyle={{ backgroundColor: '#FF3169' }}
                                                    handleStyle={{ borderColor: '#FF3169' }}/> 
                                                  : 
                                                  <Slider 
                                                      color={"#DFDFDF"} 
                                                      value={cuttingData} 
                                                      style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} 
                                                      trackStyle={{ backgroundColor: '#DFDFDF' }}
                                                      handleStyle={{borderColor: '#DFDFDF'}} />
                                                    }
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' xxl="3" >
                                      <CutKnobChart
                                        cutCompleted={cutCompleted} 
                                        cutActualDate={cutActualDate} 
                                        cutEstDate={cutEstDate} cutAvgPerDay={cutAvgPerDay} 
                                        cutActualTargetValue={cutActualTargetValue} 
                                        cutTodayUpdatedValue={cutTodayUpdatedValue} 
                                        cutEndDate={cutEndDate} cutPerDay={cutPerDay}
                                      />
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' xxl="3">
                                      <CutKnobChartrequired
                                        cutActualTargetValue={cutActualTargetValue} 
                                        cutTodayUpdatedValue={cutTodayUpdatedValue} 
                                        cutEndDate={cutEndDate} cutPerDay={cutPerDay}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="6">
                                      <Card className="productionStatusCard shadow shadow-showcase">
                                        <div className="table-responsive">
                                          <table className='table  tableproductionStatusCard'>
                                            <tr className='mx-0'>
                                              <td rowSpan="3" width="10px" style={{ backgroundColor: "#5159AC" }} className="proTD">
                                                <img src={SewIcon} /></td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("total")}</td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("completed")} </td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("pending")} </td>
                                              {/* <td style={{ color: "#828282" }} className='centerAlign'> Completion Date </td> */}                                          
                                            </tr>
                                            <tr className='orderStatusBar'>
                                              <td className='centerAlign' style={{ color: "#000000", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.total} </td>
                                              <td className='centerAlign' style={{ color: "#00B38E", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.sewCompleted} </td>
                                              <td className='centerAlign' style={{ color: "#FF3169", fontSize: "17px", fontWeight: "600" }}>
                                                {sewingPendingValue}</td>
                                            </tr>
                                            <tr className='sliderAlign'>
                                              <td colSpan="4">
                                                { sewStatus == 'Completed' || sewStatus == 'In Progress' ?
                                                   <Slider color={"#FFF000"} value={sewingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#00B38E' }}
                                                      handleStyle={{borderColor: '#00B38E' }} />
                                                  : sewStatus == 'Delayed Completion' ?
                                                    <Slider color={"#FFF000"} value={sewingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#ed923e' }}
                                                      handleStyle={{ borderColor: '#ed923e', }} />
                                                  : sewStatus == 'Delayed' ?
                                                      <Slider color={"#FFF000"} value={sewingData} style={{ height: '10px', marginTop: "15px", 
                                                        marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#FF3169' }}
                                                        handleStyle={{ borderColor: '#FF3169', }}
                                                      /> 
                                                  :
                                                      <Slider color={"#DFDFDF"} value={sewingData} style={{ height: '10px', marginTop: "15px", 
                                                        marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#DFDFDF' }}
                                                        handleStyle={{ borderColor: '#DFDFDF', }} />
                                                }
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>

                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' xxl="3">
                                      <SewKnobChart
                                        sewCompleted={sewCompleted} 
                                        sewActualDate={sewActualDate} 
                                        sewEstDate={sewEstDate} 
                                        sewAvgPerDay={sewAvgPerDay} 
                                        sewActualTargetValue={sewActualTargetValue} 
                                        sewTodayUpdatedValue={sewTodayUpdatedValue} 
                                        sewEndDate={sewEndDate} 
                                        sewPerDay={sewPerDay}
                                      />
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' xxl="3" >
                                      <SewKnobChartrequired
                                        sewActualTargetValue={sewActualTargetValue} 
                                        sewTodayUpdatedValue={sewTodayUpdatedValue} 
                                        sewEndDate={sewEndDate} 
                                        sewPerDay={sewPerDay}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xs="12" sm="12" md="12" lg="12" xl="6" xxl="6">
                                      <Card className="productionStatusCard shadow shadow-showcase">
                                        <div className="table-responsive">
                                          <table className='table  tableproductionStatusCard'>
                                            <tr className=' m-t-20'>
                                              <td rowSpan="3" width="10px"
                                                style={{ backgroundColor: "#13A1C1" }}
                                                className="proTD"><img src={PackIcon} /></td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("total")} </td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("completed")} </td>
                                              <td style={{ color: "#828282" }} className='centerAlign p-t-30'> {t("pending")} </td>
                                            </tr>
                                            <tr className='orderStatusBar  m-t-15'>
                                              <td className='centerAlign' style={{ color: "#000000", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.total}</td>
                                              <td className='centerAlign' style={{ color: "#00B38E", fontSize: "17px", fontWeight: "600" }}>
                                                {productiondata.packCompleted}</td>
                                              <td className='centerAlign' style={{ color: "#FF3169", fontSize: "17px", fontWeight: "600" }}>
                                                {packingPendingValue}</td>
                                              {/* <td className='centerAlign' style={{ color: "#6E6E6E", fontSize: "17px", fontWeight: "600" }}>{packEndDate}</td> */}
                                            </tr>
                                            <tr className='sliderAlign'>
                                              <td colSpan="4" >
                                                {packStatus == 'Completed' || packStatus == 'In Progress' ?
                                                  <Slider color={"#FFF000"} value={packingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#00B38E' }}
                                                      handleStyle={{ borderColor: '#00B38E' }} />
                                                  : packStatus == 'Delayed Completion' ?
                                                  <Slider color={"#FFF000"} value={packingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#ed923e' }}
                                                      handleStyle={{ borderColor: '#ed923e' }} />
                                                  : packStatus == 'Delayed' ?
                                                  <Slider color={"#FFF000"} value={packingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#FF3169' }}
                                                      handleStyle={{ borderColor: '#FF3169' }}/> 
                                                  :
                                                   <Slider color={"#DFDFDF"} value={packingData} style={{ height: '10px', marginTop: "15px", 
                                                      marginBottom: "30px", width: '98%' }} trackStyle={{ backgroundColor: '#DFDFDF' }}
                                                      handleStyle={{ borderColor: '#DFDFDF',}} />}
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      </Card>
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' xxl="3">
                                      <PackKnobChart
                                        packCompleted={packCompleted} 
                                        packActualDate={packActualDate} 
                                        packEstDate={packEstDate} 
                                        packAvgPerDay={packAvgPerDay} 
                                        packActualTargetValue={packActualTargetValue} 
                                        packTodayUpdatedValue={packTodayUpdatedValue} 
                                        packEndDate={packEndDate} 
                                        packPerDay={packPerDay}
                                      />
                                    </Col>
                                    <Col xs="12" sm="12" md="6" lg="6" xl='3' >
                                      <PackKnobChartrequired
                                        packActualTargetValue={packActualTargetValue} 
                                        packTodayUpdatedValue={packTodayUpdatedValue} 
                                        packEndDate={packEndDate} packPerDay={packPerDay}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )
                            })
                            : ""
                          }
                        </Col>

                        {/* <Col sm="12" md="12" lg="12" xl="12">
                          {filteredOrderDetailsProdData.length > 0 && filteredOrderDetailsProdData != null ?
                            filteredOrderDetailsProdData.map((productiondata) => {
                              let cutActualTargetValue = productiondata.cutActualTargetValue;
                              let cutTodayUpdatedValue = Math.abs(parseInt(productiondata.cutTodayUpdatedValue));
                              let cutPerDay = productiondata.cutPerDay;
                              let cutEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.cutEndDate)));
                              let sewActualTargetValue = productiondata.sewActualTargetValue;
                              let sewTodayUpdatedValue = Math.abs(parseInt(productiondata.sewTodayUpdatedValue));
                              let sewPerDay = productiondata.sewPerDay;
                              let sewEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.sewEndDate)))
                              let packActualTargetValue = productiondata.packActualTargetValue;
                              let packTodayUpdatedValue = Math.abs(parseInt(productiondata.packTodayUpdatedValue));
                              let packPerDay = productiondata.packPerDay;
                              let packEndDate = (PHPtoJSFormatConversion.format(new Date(productiondata.packEndDate)))
                              return (
                                <Row>
                                  <Col>
                                    <CutKnobChart cutActualTargetValue={cutActualTargetValue} cutTodayUpdatedValue={cutTodayUpdatedValue} 
                                    cutEndDate={cutEndDate} cutPerDay={cutPerDay}  />
                                  </Col>
                                  <Col>
                                    <CutKnobChartrequired cutActualTargetValue={cutActualTargetValue} cutTodayUpdatedValue={cutTodayUpdatedValue} 
                                    cutEndDate={cutEndDate} cutPerDay={cutPerDay} />
                                  </Col>
                                  <Col>
                                    <SewKnobChart sewActualTargetValue={sewActualTargetValue} sewTodayUpdatedValue={sewTodayUpdatedValue} 
                                    sewEndDate={sewEndDate} sewPerDay={sewPerDay} />
                                  </Col>
                                  <Col >
                                    <SewKnobChartrequired sewActualTargetValue={sewActualTargetValue} sewTodayUpdatedValue={sewTodayUpdatedValue} 
                                    sewEndDate={sewEndDate} sewPerDay={sewPerDay} />
                                  </Col>
                                  <Col>
                                    <PackKnobChart packActualTargetValue={packActualTargetValue} packTodayUpdatedValue={packTodayUpdatedValue} 
                                    packEndDate={packEndDate} packPerDay={packPerDay}/>
                                  </Col>
                                  <Col>
                                    <PackKnobChartrequired packActualTargetValue={packActualTargetValue} packTodayUpdatedValue={packTodayUpdatedValue} 
                                    packEndDate={packEndDate} packPerDay={packPerDay}/>
                                  </Col>
                                </Row>
                              )
                            })
                            : ""
                          }
                        </Col> */}
                      </Row>
                    </Col>

                    { /*Task Status Chart */}
                    <Col xs="12" sm="12" md="12" lg="12" xl="4">
                      <Row style={{ height: '75%' }}>
                        <Label className='justify-content-center text-center m-b-15 f-16 f-w-600'> {t("taskStatus")} </Label>
                        {(completed != 0 || delcompleted != 0 || inProgress != 0 || yetToStart != 0 || delay != 0) ?
                          <>
                            <Col lg="3" xl="1" md="3"></Col>
                            <Col xs="12" sm="12" md="6" lg="6" xl="10"> 
                              <TaskStatusPolarChart generalTaskData={filteredOrderDetails} />
                            </Col>
                            <Col lg="3" xl="1" md="3"></Col>
                          </>
                          :
                          <>
                            <div className="p-5 align-self-center media-body centerAlign">
                              <img src={noData} style={{ width: "200px" }} />
                              <p className='f-18 m-t-10 text-center' >-- {t("noData")} --</p>
                            </div>
                          </>
                        }
                      </Row>
                    </Col>
                  </Row>

                  { /* Gantt Chart  */}
                  <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                      {ganttChartOrderDetails != null && ganttChartOrderDetails.length > 0 ?
                        <>
                          <Card >
                            <CardBody className='p-2' style={{ overflowX: "scroll" }}>
                              <Row className="g-12 m-t-20 " style={{ backgroundColor: "#FFFFFF" }}>
                                <Col xl={12} lg={11} md={8} sm={12} >
                                  <StatusChart task={ganttChartOrderDetails} 
                                    startDate={ganttOrderStartDateDetails} lang={selectedLang} 
                                    hoildayDetails={hoildayDetails} weekOffDetails={weekOffDetails} />
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </>
                        : ""
                      }
                    </Col>
                  </Row>
                </>
                :
                  <div className=" p-5 align-self-center media-body centerAlign">
                    <img src={noData} style={{ width: "200px" }} />
                    <p className='f-18 m-t-10' >-- {t("noData")} -- </p>
                  </div>
              }
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  )
}

export default OrderStatusChart;