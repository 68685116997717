import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, 
    CardHeader, Collapse, FormGroup, Table } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../../Constant";
import bomb from "../../../assets/images/dms/BombSmiley.svg"
import NotAssigned from "../../../assets/images/dms/NotAssigned.svg"
import smile from "../../../assets/images/dms/SmileSmiley.svg"
import TwoDaysDelaySmile from "../../../assets/images/dms/TwodaysDelay.svg"
import YetToComplete from "../../../assets/images/dms/YetToComplete.svg"
import YetToStart from "../../../assets/images/dms/YetToStart.svg"
import yellowSmile from "../../../assets/images/dms/WarningSmiley.svg"
import downAccordion_icon from '../../../assets/images/dms/icons/For Accordion_icon.svg';
import up_accordion_icon from '../../../assets/images/dms/icons/up_accordion_icon.svg';
import buyerImg from '../../../assets/images/dms/onGoingList.svg'
import sad from "../../../assets/images/dms/SadSmiley.svg"
import {  getWorkspaceType } from "../../../Constant/LoginConstant";
import { PHPtoJSFormatConversion } from "../../../helper";
import { useTranslation } from 'react-i18next';

const ProductionReportsAccordion =({TaskDetails})=>{
    const [isOpen, setIsOpen] = useState(0);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    const { t } = useTranslation();
    /************ Calculate the Date Difference for Delay/Pending Days and Get the Icon  ****************/
    const getSmiley=(day,pendingQuantity,totalQuantity,type,accomplishedDate,endDate)=>{
        if(pendingQuantity === 0 && totalQuantity > 0){
            if(accomplishedDate!= null && accomplishedDate <= endDate)
                return (<td className="completedText text-left"><img className="p-0 img-30" src={smile}/> &nbsp; {t("completed")} </td>);
            else
                return (<td className="completedText text-left"><img className="p-0 img-30" src={smile}/> &nbsp; {t("delayComplete")} </td>);
        }
        else if(type === "YetToBeStarted" && day === 1){
            return (<td className="yetToStart text-left"><img className="p-0 img-30" src={YetToStart}/> &nbsp; {t("dayToStart")}  </td>);
        }
        else if(type === "YetToBeStarted" && day >=2){
            return (<td className="yetToStart text-left"><img className="p-0 img-30" src={YetToStart}/> &nbsp;{day} {t("daysToStart")}  </td>);
        }
        else if (type === "StartsToday"){
            return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/>  {t("startsToday")} </td>);
        }
        else if(day>=0 && type==="Progress"){
            if(day==1){
                return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/> &nbsp; {t("dayRemaining")}  </td>);
            }
            else if(day==0){
                return (<td className="yetToCompeteText"><img className="p-0 img-30" src={YetToComplete}/> &nbsp; {t("zerodayRemaining")} </td>);
            }
            else{
                return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/> &nbsp; {t("daysRemaining",{remainingdayscount:day})}  </td>);
            }
        }else{
            let date = Math.abs(day);
            if(date >3){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={bomb}/> &nbsp;{date} {t("daysDelay")} </td>);
            }
            if(date ==1){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={TwoDaysDelaySmile}/> &nbsp; {t("dayDelay")}</td>);
            }
            if(date <=3){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={TwoDaysDelaySmile}/> &nbsp; {date} {t("daysDelay")}</td>);
            }

       }
    }
    return (
        <Row>
            <Col sm="12" xl="12" lg="12" md="12">
                <Accordion defaultActiveKey="0">
                    <div className="default-according" id="accordion">
                        {TaskDetails.map((detail, i) => (
                            <Card key={i}>
                                {i/2===0?<CardHeader className="p-0" style={{background:"#E6EDEF80"}} >
                                    <Row onClick={() => toggle(i)}>                                                
                                    <Col sm="12" md="2" lg="2" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}
                                                >
                                                <p>{detail.orderNo}</p>
                                        </Btn>        
                                    </Col>
                                    <Col sm="12" md="8" lg="8" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}
                                                >
                                                <p>{detail.styleNo}</p>
                                        </Btn>        
                                    </Col>
                                        {/* <Col sm="12" md="2" lg="2">
                                            <span class="u-step-number txt-success">3</span>
                                            <span class="badge rounded-pill badge bg-danger rounded-pill">6</span>
                                            <span className="pendingTaskCount"> {detail.pendingCount} </span>

                                        </Col> */}
                                        <Col sm="12" md="2" lg="2" className="centerAlign">
                                            <Btn
                                                attrBtn={{
                                                    as: Card.Header,
                                                    className: "btn btn-link f-right",
                                                    color: "default",
                                                    onClick: () => toggle(i),
                                                }}
                                            >
                                            {isOpen === i ?
                                             <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5"   src={up_accordion_icon}/></span>
                                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5"   src={downAccordion_icon}/></span>}
                                            </Btn>
                                        </Col>                                               
                                    </Row>
                                </CardHeader>:
                                <CardHeader className="p-0">
                                <Row onClick={() => toggle(i)}>                                                
                                    <Col sm="12" md="2" lg="2" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}
                                                >
                                                <p>{detail.orderNo}</p>
                                        </Btn>        
                                    </Col>
                                    <Col sm="12" md="8" lg="8" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}
                                                >
                                                <p>{detail.styleNo}</p>
                                        </Btn>        
                                    </Col>
                                    {/* <Col sm="12" md="2" lg="2">
                                        <span class="u-step-number txt-success">3</span>
                                        <span class="badge rounded-pill badge bg-danger rounded-pill">6</span>
                                        <span className="pendingTaskCount"> {detail.pendingCount} </span>

                                    </Col> */}
                                    <Col sm="12" md="2" lg="2" className="centerAlign">
                                        <Btn
                                            attrBtn={{
                                                as: Card.Header,
                                                className: "btn btn-link f-right",
                                                color: "default",
                                                onClick: () => toggle(i),
                                            }}
                                        >
                                        {isOpen === i ?
                                             <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5"   src={up_accordion_icon}/></span>
                                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5"   src={downAccordion_icon}/></span>}
                                        </Btn>
                                    </Col>                                               
                                </Row>
                                </CardHeader>}
                                <Collapse isOpen={isOpen === i}>
                                    <CardBody className="p-0">
                                            <Table className="table-responsive tableRowCol custom-table-striped">
                                                <thead>
                                                <tr style={{background:"#c2ede8f5",fontSize:"14px"}}>
                                                    <th><p className="m-l-15 p-0">{t("Production")}</p></th>
                                                    <th>{t("startDate")}</th>
                                                    <th>{t("endDate")}</th>
                                                    <th >{t("total")}</th>
                                                    <th >{t("completed")}</th>
                                                    <th >{t("pending")}</th>
                                                    <th>{t("status")}</th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                {(detail.prodData).length >0? detail.prodData.map((prod,j)=>{
                                                    return(
                                                    <tr key={j}>
                                                        <td><p className="m-l-15 p-0">{t((prod.title).toLowerCase())}</p></td>
                                                        {prod.startDate === null ? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(prod.startDate))}</td>}
                                                        {prod.endDate === null? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(prod.endDate))}</td>}
                                                        <td >{prod.totalQuantity}</td>
                                                        <td >{prod.updatedQuantity}</td>
                                                        <td >{prod.pendingQuantity}</td>
                                                        {prod.startDate === null && prod.endDate === null ? <td className="yetToStart text-left"><img className="p-0 img-30" src={NotAssigned}/> &nbsp; {t("notAssigned")}  </td>:
                                                        getSmiley(prod.delay,prod.pendingQuantity,prod.totalQuantity,prod.type,prod.accomplishedDate,prod.actualEndDate)}   
                                                    </tr>)
                                                    }):<tr key={j}>
                                                        <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                                    </tr>}
                                                    </tbody>
                                            </Table>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        ))}
                    </div>
                </Accordion>
            </Col>
        </Row>
 )
}

export default ProductionReportsAccordion;