import React, {useState} from 'react';
import { Modal,ModalBody,ModalFooter,ModalHeader, Input, Label,
         Row, Col, FormGroup, InputGroup, InputGroupText    } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { ServerUrl, maxUploadFileSize } from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";
import Files from "react-files";
import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt, convertToBase64 } from '../../../helper';
import imgUpload from "../../../assets/images/dms/icons/imgUpload.svg";
import { getLoginCompanyId, getWorkspaceId, getLoginStaffId,
         getLoginUserId, getStaff, getStaffPermission, getLoginUserType} from "../../../Constant/LoginConstant";    
import deleteIcon from "../../../assets/images/dms/icons/inquiryDeleteIcon.svg";

const AddMaterialsLabelsImageModal = ({ 
    modal, toggle, labelSampleType, orderId,
    uploadedImageList, uploadedImageName, 
    serverURL,  getOrderMaterialsLabelApiCall, accessType
}) =>{
  
    const {t} = useTranslation();

    /****------- Image Upload API Call ---------- ****/
    const uploadImageApiCall = (imageType, file) => {
        axios.post(
            ServerUrl + "/add-order-materials-label-media",
            {
                media_type: labelSampleType,
                order_id: orderId > 0 ? orderId : 1,
                file: file[0],
                company_id: getLoginCompanyId,
                workspace_id: getWorkspaceId,
                upload_type: accessType,
                staff_id: getLoginStaffId,
                user_id: getLoginUserId,
            },
            {
                headers: {
                'Content-Type': 'multipart/form-data',
                },
            },
        )
        .then((response) => {
        if (response.data.status_code == 200){
            getOrderMaterialsLabelApiCall()
        }
        });
        
    };

    /****------- Image Upload Validation ---------- ****/
    const uploadImageValidation = ( type, files ) => {

        if( (type != "MeasurementSheet") && (type != "TechPack") ){
            files.map((fileData) => {
                if(
                    fileData.extension == "jpeg" ||
                    fileData.extension == "jpg" ||
                    fileData.extension == "png" ||
                    fileData.extension == "mp4"
                ){
                    // User //
                    if( getLoginUserType == "user"){
                        const getFileSize = Math.round(fileData.size / 1024);
                        if (getFileSize > maxUploadFileSize){
                            Swal.fire({
                            title: t("sizeExceededTitleAlert"),
                            text: t("uploadFileTalidationText", {
                                fileSize: maxUploadFileSize / 1024,
                            }),
                            icon: "warning",
                            button: t("okLabel"),
                            timer: 2500,
                            });
                        } else {
                            uploadImageApiCall(type, files);
                        }
                    } else{
                        // Staff //
                        if( fileData.extension == "mp4" ){
                            if( getStaffPermission.includes("Video Upload") ){
                                const getFileSize = Math.round(fileData.size / 1024);
                                if (getFileSize > maxUploadFileSize){
                                    Swal.fire({
                                    title: t("sizeExceededTitleAlert"),
                                    text: t("uploadFileTalidationText", {
                                        fileSize: maxUploadFileSize / 1024,
                                    }),
                                    icon: "warning",
                                    button: t("okLabel"),
                                    timer: 2500,
                                    });
                                } else {
                                    uploadImageApiCall(type, files);
                                }
                            } else{
                                Swal.fire({
                                    title: "Permission for video upload is denied",
                                    text: "Kindly Contact Admin",
                                    icon: "warning",
                                    button: t("okLabel"),
                                    timer: 2500,
                                });
                            }
                        } else{
                            const getFileSize = Math.round(fileData.size / 1024);
                            if (getFileSize > maxUploadFileSize){
                                Swal.fire({
                                title: t("sizeExceededTitleAlert"),
                                text: t("uploadFileTalidationText", {
                                    fileSize: maxUploadFileSize / 1024,
                                }),
                                icon: "warning",
                                button: t("okLabel"),
                                timer: 2500,
                                });
                            } else {
                                uploadImageApiCall(type, files);
                            }
                        }
                    }
                } else {
                    Swal.fire({
                        title: t("wrongFileFormat"),
                        text: t("validFileFormatsImages"),
                        icon: "warning",
                        button: t("okLabel"),
                        timer: 2500,
                    });
                }
            });
        } else{
            files.map((fileData) => {
                if ( 
                    fileData.extension == "jpeg" ||
                    fileData.extension == "jpg" ||
                    fileData.extension == "png" ||
                    fileData.extension == "pdf" ||
                    fileData.extension == "xls" ||
                    fileData.extension == "xlsx" ||
                    fileData.extension == "mp4"
                ) {

                    // User //
                    if( getLoginUserType == "user"){
                        const getFileSize = Math.round(fileData.size / 1024);
                        if (getFileSize > maxUploadFileSize){
                            Swal.fire({
                            title: t("sizeExceededTitleAlert"),
                            text: t("uploadFileTalidationText", {
                                fileSize: maxUploadFileSize / 1024,
                            }),
                            icon: "warning",
                            button: t("okLabel"),
                            timer: 2500,
                            });
                        } else {
                            uploadImageApiCall(type, files);
                        }
                    } else{
                        // Staff //
                        if( fileData.extension == "mp4" ){
                            if( getStaffPermission.includes("Video Upload") ){
                                const getFileSize = Math.round(fileData.size / 1024);
                                if (getFileSize > maxUploadFileSize){
                                    Swal.fire({
                                    title: t("sizeExceededTitleAlert"),
                                    text: t("uploadFileTalidationText", {
                                        fileSize: maxUploadFileSize / 1024,
                                    }),
                                    icon: "warning",
                                    button: t("okLabel"),
                                    timer: 2500,
                                    });
                                } else {
                                    uploadImageApiCall(type, files);
                                }
                            } else{
                                Swal.fire({
                                    title: "Permission for video upload is denied",
                                    text: "Kindly Contact Admin",
                                    icon: "warning",
                                    button: t("okLabel"),
                                    timer: 2500,
                                });
                            }
                        } else{
                            const getFileSize = Math.round(fileData.size / 1024);
                            if (getFileSize > maxUploadFileSize){
                                Swal.fire({
                                title: t("sizeExceededTitleAlert"),
                                text: t("uploadFileTalidationText", {
                                    fileSize: maxUploadFileSize / 1024,
                                }),
                                icon: "warning",
                                button: t("okLabel"),
                                timer: 2500,
                                });
                            } else {
                                uploadImageApiCall(type, files);
                            }
                        }
                    }
                } else {
                    Swal.fire({
                        title: t("wrongFileFormat"),
                        text: "Only .jpeg, .jpg, .png, .pdf, .xls, .xlsx, .mp4 file formats are allowed",
                        icon: "warning",
                        button: t("okLabel"),
                        timer: 2500,
                    });
                }
            });
        }
    };  

    const deleteOrderMaterialsLabelMediaApiCall = (media, imageType, file, reason) => {

        var media = {};
        media["media_id"] =  file.media_id ? file.media_id : file.id;
        media["reason"] =  reason ? reason : "";
        media["order_id"] =  orderId ? orderId : "";
        media["staff_id"] =  getLoginStaffId;
        media["user_id"] =  getLoginUserId;

        axios
        .post(ServerUrl + "/delete-order-materials-label-media", apiencrypt(media))
        .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code == 200) {
            Swal.fire({
            title: t(response.data.meassage),
            icon: "success",
            showCancelButton: true,
            button: t("okLabel"),
            }).then((result) => {
            if (result.isConfirmed) {
                uploadImageApiCall(imageType, file);
            }
            }
            )
        }
        })
    };

    /****------- Delete Image  ---------- ****/
    const deleteImageFiles = (imageType, file) => {
        var media = {};
        media["media_id"] =  file.media_id ? file.media_id : file.id;

        if(imageType == "MeasurementSheet"){
            if(accessType == "Edit"){
                (async () => {
                    const { value: reason } = await Swal.fire({
                      title: t("deleteConfirmationTitleAlert"),
                      text: t("cantRevertBack"),
                      icon: "warning",
                      showCancelButton: true,
                      button: t("okLabel"),
                      input: 'text',
                      inputPlaceholder: 'Please Enter the Reason',
                      inputValidator: (reason) => {
                            if (!reason) {
                                return 'Please fill the reason!'    
                            } else{
                                deleteOrderMaterialsLabelMediaApiCall( media, imageType, file, reason )
                            }
                      },
                    })
                })()
            } else{
                Swal.fire({
                    title: t("deleteConfirmationTitleAlert"),
                    text: t("cantRevertBack"),
                    icon: "warning",
                    showCancelButton: true,
                    button: t("okLabel"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteOrderMaterialsLabelMediaApiCall( media, imageType, file )
                    }
                });
            }  
        }
        else {
            if(accessType == "Edit"){
                (async () => {
                    const { value: reason } = await Swal.fire({
                      title: t("areYouSureToDeleteThisImage"),
                      text: t("cantRevertBack"),
                      icon: "warning",
                      showCancelButton: true,
                      button: t("okLabel"),
                      input: 'text',
                      inputPlaceholder: 'Please Enter the Reason',
                      inputValidator: (reason) => {
                            if (!reason) {
                                return 'Please fill the reason!'    
                            }  else{
                                 deleteOrderMaterialsLabelMediaApiCall( media, imageType, file, reason )
                            }
                      },
                      closeOnClickOutside: false,
                    })
                })()
            } else{
                Swal.fire({
                    title: t("areYouSureToDeleteThisImage"),
                    text: t("cantRevertBack"),
                    icon: "warning",
                    showCancelButton: true,
                    button: t("okLabel"),
                })
                .then((result) => {
                if (result.isConfirmed){
                    deleteOrderMaterialsLabelMediaApiCall( media, imageType, file )
                }})
            }
        }
    };

    return (
        <Modal isOpen={modal} toggle={toggle} centered className="datainputmodal">

            <ModalHeader> {t("labelSample")} </ModalHeader>

            <ModalBody>
                <Row>

                    <FormGroup>
                        <Label></Label>
                        <InputGroup>
                            <Input
                                name="labelSample"
                                placeholder={"Please Attach Label sample"}
                                value={uploadedImageName ? uploadedImageName : ""}
                                disabled>
                            </Input>
                            <Files
                                className="files-dropzone fileContainer"
                                accept=".png,.jpg,.jpeg,.mp4"
                                multiple={false}
                                canCancel={false}
                                onChange={files => uploadImageValidation(labelSampleType, files)}
                                clickable>
                            <InputGroupText className="btn imgBackground">
                                <img
                                    src={imgUpload}
                                    width="25px"
                                    height="25px"
                                    type="file">
                                </img>
                            </InputGroupText>
                            </Files>
                        </InputGroup>
                    </FormGroup>

                    <Row>
                        <Row>
                            <Col>
                                {uploadedImageList.length > 0 ? 
                                (
                                    uploadedImageList
                                    .filter(filterData => filterData.media_type == labelSampleType)
                                    .map((file, index) => (
                                        <>
                                        {
                                            file.orginalfilename !== "" 
                                            ? 
                                                (file.orginalfilename).split(".")[1] == "mp4"
                                                ?
                                                    <div className="profile-pic p-10" key={index}>
                                                        <video  src={file.fileurl}
                                                                width="200" 
                                                                height="200" 
                                                                controls
                                                        />
                                                        <div className="edit m-r-30">
                                                            <img
                                                                src={deleteIcon}
                                                                width="35px"
                                                                height="30px"
                                                                style={{ cursor: 'pointer', border: 'none', marginTop: '70px', marginRight: '30px' }}
                                                                onClick={() => {
                                                                deleteImageFiles(labelSampleType, file)
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                :
                                                    ""
                                            :
                                                ""
                                        }
                                        </>
                                    ))
                                ) 
                                : 
                                ( <div>{''}</div> )} 
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {uploadedImageList.length > 0 ? 
                                (
                                    uploadedImageList
                                    .filter(filterData => filterData.media_type == labelSampleType)
                                    .map((file) => (
                                        <>
                                        {
                                            file.orginalfilename !== "" 
                                            ? 
                                                (file.orginalfilename).split(".")[1] !== "mp4"
                                                ?
                                                    <div className="profile-pic p-10">
                                                        <img
                                                            src={file.fileurl}
                                                            width="100px"
                                                            height="100px"
                                                            className="m-10"
                                                            />
                                                        <div className="edit m-t-30 m-r-30">
                                                            <img
                                                                src={deleteIcon}
                                                                width="35px"
                                                                height="30px"
                                                                style={{ cursor: 'pointer', border: 'none' }}
                                                                onClick={() => {
                                                                deleteImageFiles(labelSampleType, file)
                                                                }
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                :
                                                    ""
                                            :
                                                ""
                                        }
                                        </>
                                    ))
                                ) 
                                : 
                                ( <div>{''}</div> )} 
                            </Col>
                        </Row>
                    </Row> 

                </Row> 
            </ModalBody>

            <ModalFooter>
                <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>
                    {t("close")}
                </Btn>
            </ModalFooter>

        </Modal>
    );
};

export default AddMaterialsLabelsImageModal;
/* Code By : Ramanan M */