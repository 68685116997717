import React, { Fragment, useState, useEffect } from "react";
import ProductionCanvas from "./ProductionOffCanvas";
import { Row, Col, Card, CardBody, Label, Input, Button,Table,Media } from "reactstrap";
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import { getLoginCompanyId, getLoginUserId, getLoginStaffId, 
  getWorkspaceId, } from "../../Constant/LoginConstant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";
import ScrollBar from 'react-perfect-scrollbar';
import switchleft_icon1 from "../../assets/images/dms/icons/switchleft_icon1.svg";

const NotificationEmail = () => {
  const [dashboardWidgets, setgetdashboardWidgets] = useState([]);
  const [getEmail_weekly_reminder, setgetEmail_weekly_reminder] = useState();
  const [dataRole, setdataRole] = useState([]);
  const [delayDays, setOfDelayDays] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [noofDelays, setNoofDelays] = useState("");
  const [emailNotificationList, setEmailNotificationList] = useState("");
  const [show, setShow] = useState(false);
  const toggleCanvas = () => setShow(!show);
  const [getDasboard, setWeekoff] = React.useState([]);
  const { t } = useTranslation();
  const [nodelayparam, setnodelayparam] = useState("");
  const [switchwithconsolidedemail, setSwitchwithconsolidedemail] = useState(1);
  const [switchwithoutconsolidedemail, setSwitchwithoutconsolidedemail] = useState();
  const [tolerancereq, setTolerancereq] = useState(false);
  const [consEmail, setConsEmail] = useState("");
  const [noofDayDelay, setNoofDayDelay] = useState("");
  const [adminnoofDelay, setadminnoofDelay] = useState("");
  const [contactEmaildelayed, setContactEmaildelayed] = useState("");
  
  
  useEffect(() => {
   axios
    .post(ServerUrl + "/get-roles", apiencrypt({
      company_id: getLoginCompanyId,
      workspace_id: getWorkspaceId,
      role_id: "",
      module: "",
    }))
    .then((response) => {
      response.data=apidecrypt(response.data);
      setdataRole(response.data.data);
    });
    axios
    .post(ServerUrl + "/view-email-notification-settings", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
        "user_id" : getLoginUserId,
          "staff_id" :getLoginStaffId,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data)
        if (response.data.status_code == 200) {
          setNoofDelays(response.data.data);
          // setEmailNotificationList(response.data.data[0].email_ids);
          onhandledelay(response.data.data);
          // setConsEmail(response.data.data[0].is_consolidated_mail);
          if (response.data.data[0]!=null && response.data.data[0].is_consolidated_mail== 1) {
            setTolerancereq(true);
          }
        }
    });
    onhandledelay();
    getunitCount()
    setValidErrors("")
  }, []);

  const [errors, setValidErrors] = useState({});

  
  const getunitCount = () => {
    let Days = [];
    for (let i = 1; i <= 30; i++) {
      Days.push({ "id": i, "days":  i });
        setOfDelayDays(Days);
    }

}

var ordert=[];
ordert=[['order_delay',t('Task Delay')]];
const orderSt=ordert
 


    const submitForm=(()=>{
    let retval= inquirySettingValidation();
    if(Object.keys(retval).length == 0 ){
      var notifydelayemail=[]
var cme={};

orderSt.map((ordeid)=>{
var arydelay=[];
  dataRole.map((role)=>{
       var obj={}
       
      obj["role"] = role.name;
      obj["id"] = role.id;
      obj["no_of_days"] = document.getElementById(ordeid[0]+"_"+role.name+"_"+role.id).value?document.getElementById(ordeid[0]+"_"+role.name+"_"+role.id).value:"";
      arydelay.push(obj)
    
    })

    cme['order_type']=ordeid[0];
    cme['admin']=document.getElementById(ordeid[0]+"_admin_delay").value?document.getElementById(ordeid[0]+"_admin_delay").value:"";
      cme['no_of_delays']=arydelay;
      // cme['emailids']=document.getElementById(ordeid[0]+"_contact_email").value?document.getElementById(ordeid[0]+"_contact_email").value:"";
      // cme['email_no_of_delays']=document.getElementById(ordeid[0]+"_additional_email_delay").value?document.getElementById(ordeid[0]+"_additional_email_delay").value:"";
   
});
notifydelayemail.push(cme)
// let getNofiye={}
// getNofiye["email"] = cme;
let getNofiyemail = {};

getNofiyemail["company_id"] = getLoginCompanyId;
getNofiyemail["user_id"] = getLoginUserId;
getNofiyemail["staff_id"] = getLoginStaffId;
getNofiyemail["workspace_id"] = getWorkspaceId;
getNofiyemail["email_settings"] = JSON.stringify(notifydelayemail);
getNofiyemail["consolidated_mail"] = tolerancereq == true ? 1 : 0;

  axios.post(ServerUrl + "/email-notification-settings",apiencrypt(getNofiyemail))
.then((response) => {
  response.data = apidecrypt(response.data);
  if (response.data.status_code == 200) {
    Swal.fire({
      title: t("Emailnotificationsuccessfully"),
      icon: "success",
      button: t("okLabel"),
      timer:5000,
    })
    .then(()=>{

      axios
      .post(ServerUrl + "/view-email-notification-settings", apiencrypt({
          "company_id": getLoginCompanyId,
          "workspace_id": getWorkspaceId,
          "user_id" : getLoginUserId,
            "staff_id" :getLoginStaffId,
      }))
      .then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code == 200) {
            var arr=response.data.data;
            setNoofDelays(response.data.data);
            setnodelayparam(response.data.data)
          setConsEmail(response.data.data[0].is_consolidated_mail);
           
            onhandledelay(response.data.data);
            // setNoofDelays(JSON.parse(response.data.data[0].no_of_delays));
            // setEmailNotificationList(response.data.data[0].email_ids);
            if (response.data.data[0].is_consolidated_mailq == 1) {
              setTolerancereq(true);
            }
          }
      });
      setValidErrors("")
      
    })
  }
});
// setContactEmail("")
    }
})
const onhandledelay =(e)=>{
 // document.getElementById("order_delay_Merchandiser_6").value=5;
 if(e){

   orderSt.map((ordermp)=>{
  var ordSt=ordermp[0];
   const found = e.find(obj => {
    return (
    obj.order_type === ordSt
    )     
  });
  // var emailid= (found['email_ids'])
  // var email_no_of_delays= (found['email_no_of_delays']);
  // if(email_no_of_delays>0){
  // document.getElementById(ordSt+"_additional_email_delay").value=email_no_of_delays;
  // }
  var admin_delay= (found['notify_admin']);
  if(admin_delay>0){

    document.getElementById(ordSt+"_admin_delay").value=admin_delay;
  }
  // var nemail_ids= (found['email_ids']);
  // document.getElementById(ordSt+"_contact_email").value=nemail_ids;
  var noday= JSON.parse(found['no_of_delays']);
  if(noday.length>0){
    {noday.map(arob=>{
      document.getElementById(ordSt+"_"+arob.role+"_"+arob.id).value=arob.no_of_days;
  
    })
    
   }
  }

})
}
}
const inquirySettingValidation = (data) => {

  let errors = {};
   if ((noofDayDelay) && (!(noofDayDelay).match(/^[0-9.]+$/g))) {
      errors.noofDayDelay = ("onlyNumbersFormatallowed");
    }
    if ((contactEmaildelayed) && (!(contactEmaildelayed).match(/^[0-9.]+$/g))) {
      errors.contactEmaildelayed = ("onlyNumbersFormatallowed");
    }
    
    if ((adminnoofDelay) && (!(adminnoofDelay).match(/^[0-9.]+$/g))) {
      errors.adminnoofDelay = ("onlyNumbersFormatallowed");
    }
    
//   if(contactEmail){  
//   var emailRegEx = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//   var str =contactEmail;
//    var array = str.split(',');        
//   for(var i =0;i<array.length;i++)
//   { 
//      var email = array[i];                
//       if(!email.match(emailRegEx))
//       {
//         errors.contactEmail= ('The email address '+ ' "' +email+ '" '+ ' is invalid')
//         Swal.fire({
//           title:'The email address '+ ' "' +email+ '" '+ ' is invalid',
//           icon: "warning",
//           button: t("okLabel"),
//           allowOutsideClick: false
//         })
//         //  alert('The email address '+ ' "' +email+ '" '+ ' is invalid');
//         //  return false;
//          setValidErrors(errors);
//          return errors;
//      }
  
//     }
//   return true           
// } 
else{
  setValidErrors(errors);
  return errors;
}
};
function handleKeyPressPrice(e) {
  var key = e.key;
  var regex = /[0-9]/;
  if( !regex.test(key) ) {
      e.preventDefault();
  }
};

  return (
    <Fragment>
      <Col md="12" lg="12" sm="12">
        <Card className="shadow shadow-md">
          <Row className='m-b-20'>
            <Col>
              <HeaderCard className="m-t-5" title={t("notificationEmail")}/>
            </Col>
            <Col  style={{ marginRight: '12px' }}>
            <span className="m-t-20" style={{ float: "right" }}> 
              {/* {switchwithoutconsolidedemail==0? <img  width="40px" src={switchleft_icon1} title="Click" alt="Click"
                className="cursor-pointer p-l-5 p-r-5 " onClick={(e) => { setSwitchwithoutconsolidedemail(0);}} />:
                <img  width="40px" src={switchleft_icon1} title="Click" alt="Click"
                className="cursor-pointer p-l-5 p-r-5 " onClick={(e) => { setSwitchwithconsolidedemail(1);}} />}
             
             {switchwithconsolidedemail==1? <img  width="40px" src={switchleft_icon1} title="Click" alt="Click"
                className="cursor-pointer p-l-5 p-r-5 " onClick={(e) => { setSwitchwithconsolidedemail(1);}} />:""} */}
             {/* <Media className="text-end switch-sm" key="1">
                            <Label className="p-r-10">Consolidated Mail </Label>
                          <Media body className="text-end icon-state switch-sm switch ">
                              <Input className="toggleButton"
                                // type="checkbox" name="tolisreq"
                                // value={tolerancereq} checked={!!tolerancereq}
                                // onChange={(e) => { setTolerancereq(prevCheck => !prevCheck) }} 
                                />
                              <span className="switch-state"></span>
                           
                          </Media>
                        </Media> */}
                       {/* {consEmail==0? */}
                        <Media key={1}>
                                    <Label className="col-form-label m-r-10 f-14">{t("consolidatedMail")}</Label>
                                    <Media body className="text-end switch-sm icon-state">
                                        <Label className="switch">
                                            <Input type="checkbox" 
                                            value={tolerancereq} checked={!!tolerancereq}
                                            onChange={(e) => { setTolerancereq(prevCheck => !prevCheck) }} 
                                            /><span className="switch-state" ></span>
                                        </Label>
                                    </Media>
                                </Media>
                                {/* :
                                <Media key={1}>
                                    <Label className="col-form-label m-r-10">Consolidated Mail</Label>
                                    <Media body className="text-end icon-state switch-outline">
                                        <Label className="switch">
                                            <Input type="checkbox" 
                                           defaultValue={consEmail}
                                            value={tolerancereq} checked={!!tolerancereq}
                                            onChange={(e) => { setconsiderreq(prevCheck => !prevCheck) }} 
                                            /><span className="switch-state" ></span>
                                        </Label>
                                    </Media>
                                </Media>
                                } */}
            </span>
          </Col>
          </Row>
          <CardBody className="animate-chk p-0" >
          <ScrollBar className="" options={{ suppressScrollX: true }} >
                <div className="table-responsive">
                  <Table className="tableRowCol ">
                    

                      <thead>
                  <tr style={{  backgroundColor: '#F4F4F4' }} >
                    <th >
                      <p className="m-l-15 "  style={{ color:"#202020" ,fontStyle:"normal"}}>{t("Status/Role")}</p>
                    </th>
                    {/* ******* Roles has Permission api response mapping ******* */}
                    <th className="">
                      <p  style={{ color:"#202020" ,fontStyle:"normal"}}>{t("admin")}</p>
                    </th>
                    {dataRole.length>0?dataRole.map((role, index) => (
                      <th className="m-l-5" scope="col"  >
                      
                        
                        <p style={{ color:"#202020" ,fontStyle:"normal"}}>{t(role.name)}</p>
                      </th>
                     
                    )):""}
                     {/* <th className="">
                         <p style={{ color:"#202020" ,fontStyle:"normal"}}>{t("additionalEmails")}</p>
                      </th>
                      <th className="p-l-0">
                        <p style={{ color:"#202020" ,fontStyle:"normal"}}>{t("days")}</p>
                      </th> */}
                      {/* <th>
                         <p style={{ color:"#202020" ,fontStyle:"normal"}}>additional Emails Delayed</p>
                      </th> */}
                  </tr>
                 
                </thead>
                                 
                                   

                {orderSt.map( (orderst,i)=> 
                          <tr  >
                            <td ><span className="m-l-15">{orderst[1]}</span></td>
                            <td  className="text-center">
                                 
                                 <Input
                                 style={{width:"100px"}}
                                 className={`${errors.adminnoofDelay && 'error-valid'}  form-control`}
                                   name="orderdelayed"
                                   type="text"
                                   placeholder={t("noOfDays")}
                                   id= {orderst[0]+"_admin_delay"}
                                  //  defaultValue={obj.days}
                                   oninput="validate(this)"
                                   maxlength="2"
                                   pattern="\d{2}"
                                   onChange={(e) => {setadminnoofDelay(e.target.value)}}
                                   onKeyPress={(e) => handleKeyPressPrice(e)}
                                  //  defaultValue={mapData.no_of_days}
                                 >
                                   
                               </Input>
                               {errors.adminnoofDelay && 
                            (<span className="error-msg">{t(errors.adminnoofDelay)}</span>)
                            }
                               
                             </td>
                            
{
                         
                         dataRole.map( (items)=> (
                          
                          //  noofDelays.map((mapData) => (
                              //  mapData.id == items.id ?
                             <td  className="text-center">
                                 
                                 <Input
                                 style={{width:"100px"}}
                                   name="orderdelayed"
                                   type="text"
                                   className={`${errors.noofDayDelay && 'error-valid'}  form-control`}
                                   placeholder={t("noOfDays")}
                                   id={orderst[0]+"_"+items.name+"_"+items.id}
                                  //  defaultValue={obj.days}
                                   oninput="validate(this)"
                                   maxlength="2"
                                   pattern="\d{2}"
                                   onChange={(e) => {setNoofDayDelay(e.target.value)}}
                                  //  defaultValue={mapData.no_of_days}
                                  onKeyPress={(e) => handleKeyPressPrice(e)}
                                  
                                 >
                                   
                               </Input>
                               {errors.noofDayDelay && 
                            (<span className="error-msg">{t(errors.noofDayDelay)}</span>)
                            }
                               
                             </td>
                            //  :
                            //  ""
                           ))
                          
                         
                          
                        //  ))
                      
                     }
                           {/* <td  style={{width:"250px"}} className="text-center">
                           <Input
                          
                            className={`${errors.contactEmail && 'error-valid'}  form-control`}
                            type="email"
                            multiple
                            placeholder={t("emailSeparateemailsbycomma")}
                            name="contact_email" 
                            id={orderst[0]+"_contact_email"}
                            defaultValue={emailNotificationList}
                            onChange={(e) => {setContactEmail(e.target.value)}}
                          >
                         
                           </Input> 
                            {errors.contactEmail && 
                            (<span className="error-msg">{t(errors.contactEmail)}</span>)
                            }
                           </td>
                           <td>
                             <Input
                            
                             style={{width:"100px"}}
                                  className={`${errors.contactEmaildelayed && 'error-valid'}  form-control`}
                                   name="orderdelayed"
                                   type="text"
                                   placeholder={t("noOfDays")}
                                   id={orderst[0]+"_additional_email_delay"}
                                   oninput="validate(this)"
                                   maxlength="2"
                                   pattern="\d{2}"
                                   onChange={(e) => {setContactEmaildelayed(e.target.value)}}
                                   onKeyPress={(e) => handleKeyPressPrice(e)}
                                  
                                 >
                                    {errors.contactEmaildelayed && 
                            (<span className="error-msg">{t(errors.contactEmaildelayed)}</span>)
                            }
                               </Input>
                               
                           </td> */}
                          
                          </tr>
                           )}
                           
                         
                  </Table>
                </div>
                </ScrollBar>
           
          </CardBody>
          <Row>
                            <Col md="12">
                                <div className="m-t-10 m-b-15 text-end m-r-30">
                                    <Button className="btn btn-primary btn-sm"
                                        onClick={(e) => { submitForm(); }}>
                                        {t("save")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
        </Card>
      </Col>
    </Fragment>
  );
};

export default NotificationEmail;

//**************************Code by : Praveen****************************//