import React, { useContext, useState, Fragment } from 'react';
import { AlignCenter } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import CheckContext from '../../_helper/Customizer';
import dmslogtag from "../../assets/images/dms/dms-log-with-tag.png" ;

const Leftbar = () => {

    const { mixLayout, toggleSidebar } = useContext(CheckContext);
    const [toggle, setToggle] = React.useState(false);

    const openCloseSidebar = () => {
        setToggle(!toggle);
        toggleSidebar(toggle); 
    //    if(!toggle){
    //     setTimeout(() => {    
    //         setToggle(toggle);
    //         toggleSidebar(!toggle); 
    //     }, 8500);
    //    }
    };


    // const concernedElement = document.querySelector(".sidemenubc");

    // document.addEventListener("mouseover", (event) => {
    //     if (concernedElement.contains(event.target)) {
                   
    //                   } else {
    //                     if(toggle===false){
    //                   setTimeout(() => {
           
    //        setToggle(toggle);
    //        toggleSidebar(!toggle); 
    //       }, 10000);
    //                     }
                        
                      
    //                   }
    //                 });
    return (
        <Fragment> 
            <div className="main-header-left sidemenub">
                {mixLayout ?
                    <div className="logo-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                        <img src={dmslogtag} width="150"/>
                         
                        </Link>
                    </div>
                    :
                    <div className="dark-logo-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                        <img src={dmslogtag} width="80"/>
                          
                        </Link>
                    </div>
                }
                <div className="toggle-sidebar" onClick={() => openCloseSidebar()}>
                    <AlignCenter className="status_toggle middle" id="sidebar-toggle" />
                </div>
            </div>
        </Fragment >
    );
};

export default Leftbar;