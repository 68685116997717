import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col, FormGroup, InputGroup, InputGroupText,CardBody    } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges, ServerUrl, maxUploadFileSize } from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";
import Files from "react-files";
import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../../helper';
import imgUpload from "../../../assets/images/dms/icons/imgUpload.svg";
import {
    getLoginCompanyId,
    getLoginUserId,
    getLoginStaffId,
    getWorkspaceId,
    getStaff,
    getStaffPermission,
  } from "../../../Constant/LoginConstant";    
import deleteIcon from "../../../assets/images/dms/icons/inquiryDeleteIcon.svg";
import noData from "../../../assets/images/dms/nodata.png"

const AddMaterialsImageModal = ({ 
    modal, toggle, imgmediaList,imgserverURL,media_type,label_type
}) =>{
    const {t} = useTranslation();
    return (
        <Modal isOpen={modal} toggle={toggle} centered className="datainputmodal">
        <ModalHeader>{label_type?label_type: t("labelSample")} 
            <span className="f-right cursor-pointer" title={t("close")} 
                  tooltip={t("close")} alt={t("close")} onClick={toggle}>
                X
            </span>
        </ModalHeader>
        <ModalBody>
            {
                imgmediaList.length > 0 
            ?
                imgmediaList
                .filter(filterData => filterData.media_type == media_type)
                .map((medias) => 
                {
                    return (
                        <td>
                            <img style={{width:"100px", padding:"10px"}} src={medias.fileurl} />
                            {/* <img style={{width:"100px", padding:"10px"}} src={imgserverURL +medias.filepath} /> */}
                        </td>
                    );
                })
            :
                <CardBody className="p-3">
                    <div className="p-5 align-self-center media-body centerAlign"
                        style={{ marginTop: "75px" }}>
                        <img src={noData} style={{ width: "200px" }} />
                        <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                    </div>
                </CardBody>
            }
        </ModalBody>
        <ModalFooter>
            <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>
                {t("close")}
            </Btn>
        </ModalFooter>
        </Modal>
    );
};

export default AddMaterialsImageModal;
