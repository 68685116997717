import React, { useState,useEffect } from "react";

import {
  Form, Label, Card, ModalBody, Col, Row, Input, InputGroup,
  InputGroupText, Modal, FormGroup, ModalHeader, Table,Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InquiryViewIcon from "../../../assets/images/dms/icons/inquiryViewIcon.svg"; 
import editIcon from "../../../assets/images/dms/icons/inquiryEditIcon.svg";
import { Color } from "../../../Constant";
import { Breadcrumbs, H6 } from "../../../AbstractElements";
import {
  getLoginCompanyId,
  getWorkspaceId,
  getLoginUserId,
  getWorkspaceType,
  getLoginUserType,
} from "../../../Constant/LoginConstant";
import axios from "axios";
import { BottomCenter, RightBottom, ServerUrl } from "../../../Constant";
import { apidecrypt, apiencrypt } from '../../../helper';
import Swal from 'sweetalert2';
import Select from 'react-select'
const EditVendorModal = ({modal, toggleadd, toggle, toggleViewVendorList, 
                          setVendorList, selectedEditVendorDetails,
                          selectedVendorId}) => {
    const { t } = useTranslation();
    const toggleAddVendorInfo = () => setModalAddVendor(!modalAddVendor);
    const [modalAddVendor, setModalAddVendor] = useState(false);
    const [vendarName, setVendarName] = useState("");
    const [websiteName, setWebsiteName] = useState("");
    const [headOfficeAddress, setHeadOfficeAddress] = useState("");
    const [factoryAddress, setFactoryAddress] = useState("");
    const [mdname, setMdname] = useState("");
    const [mobileNumber, setMdMobileNumber] = useState("");
    const [mdEmailId, setMdEmailId] = useState("");
    const [salesEmailId, setSalesEmailId] = useState("");
    const [salesMobileNumber, setSalesMobileNumber] = useState("");
    const [salesName, setSalesName] = useState("");
    const [accountsMobileNumber, setAccountsMobileNumber] = useState("");
    const [accountsEmailId, setAccountsEmailId] = useState("");
    const [accountsName, setAccountsname] = useState("");
    const [productionMobileNumber, setProductionMobileNumber] = useState("");
    const [productionEmailId, setProductionEmailId] = useState("");
    const [productionName, setProductionName] = useState("");
    const toggleviewVendorinfo = () => setModalViewInfo(!modalViewInfo);
    const [modalViewInfo, setModalViewInfo] = useState(false);
    var contactDetails = [];

    const UpdateEditVendorInformation = () => {
        let contact_details=[]
        const workspace_id = getWorkspaceId;
        const company_id = getLoginCompanyId;
        const User_Type = getLoginUserType;
        const user_id = getLoginUserId;
    
        const inputmdparam = {};
        inputmdparam['role'] = "M.D";
        inputmdparam['name'] = document.getElementById('mdnames').value;
        inputmdparam['number'] =document.getElementById('number').value;
        inputmdparam['email'] =document.getElementById('emailid').value;
        contact_details.push(inputmdparam);
    
        const inputsalesparam = {};
        inputsalesparam['role'] = "sales";
        inputsalesparam['name'] = document.getElementById('salesnames').value;
        inputsalesparam['number'] = document.getElementById('salesnumber').value;
        inputsalesparam['email'] = document.getElementById('salesemailid').value;
        contact_details.push(inputsalesparam);
    
        const inputaccountsparam = {};
        inputaccountsparam['role'] = "Accounts";
        inputaccountsparam['name'] =document.getElementById('Accountsnames').value;
        inputaccountsparam['number'] = document.getElementById('Accountsnumber').value;
        inputaccountsparam['email'] = document.getElementById('Accountsemailid').value
        contact_details.push(inputaccountsparam);

        const inputProductionparam = {};
        inputProductionparam['role'] = "Production";
        inputProductionparam['name'] = document.getElementById('Productionnames').value;
        inputProductionparam['number'] = document.getElementById('Productionnumber').value;
        inputProductionparam['email'] =  document.getElementById('Productionemailid').value;
        contact_details.push(inputProductionparam);

        axios
        .post(ServerUrl + "/edit-label-vendor", apiencrypt({
            company_id: company_id,
            workspace_id: workspace_id,
            user_type:User_Type,
            vendor_name :document.getElementById('VendorName').value,
            website:document.getElementById('Website').value,
            office_address:document.getElementById('HeadOfficeAddress').value,
            factory_address:document.getElementById('factoryaddress').value,
            user_id:user_id,
            contact_details:contact_details,
            id:selectedVendorId
        }))
        .then((response) => {
            response.data = apidecrypt(response.data)
            if (response.data.status_code == 200) {
            Swal.fire({
                title: t(response.data.message),
                icon: "success",
                timer: 1200            
            })
            axios
            .post(ServerUrl + "/all-vendor-list", apiencrypt({
                "company_id": getLoginCompanyId,
                "workspace_id": getWorkspaceId,
            }))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code == 200) {
                  setVendorList(response.data.data);
                  toggle(false)
                  toggleadd(false)
                  toggleViewVendorList(false)
                }
            }); 
            }
            if (response.data.status_code === 401) {
              Swal.fire({
                  title:
                  t(response.data.errors.vendor_name) ||
                  t(response.data.errors.office_address)||
                  response.data.errors.category_ids,
                  text: t("fieldMissing"),
                  icon: "warning",
                  button: t("okLabel"),
                  timer: 2000
              
              });
            }
    });}

  return (    
    <Modal backdrop="static" 
           isOpen={modal} toggle={toggle} centered 
           className="Addvendormodal datainputmodal">
      
      <ModalHeader>
        <Row> 
          <Col className="m-t-10">{t("Edit Vendor Information")}</Col> 
          <Col className="text-end cursor-pointer">
            <span title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggle}>X</span>
          </Col>
        </Row> 
      </ModalHeader>
      
      <ModalBody>
        {
        selectedEditVendorDetails.length>0 
        ?
        selectedEditVendorDetails.map(( mapData ) => 
              {
                contactDetails = mapData.contact_details
                return (
                  <>
                    <Row className="g-12">
                      <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                        <FormGroup className="">
                          <Label>{t("Vendor Name")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              name="VendorName"
                              id="VendorName"
                              placeholder={t("Vendor Name")}
                              aria-label="VendorName"
                              defaultValue={mapData.vendor_name}
                              onChange={(e) => setVendarName(e.target.value)}
                            >
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col> 
                      <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                        <FormGroup className="">
                          <Label>{t("Website")}</Label>
                          <InputGroup>
                            <Input
                              name="Website"
                              id="Website"
                              placeholder={t("Website")}
                              aria-label="Website"
                              defaultValue={mapData.website}
                              onChange={(e) => setWebsiteName(e.target.value)}
                            >
                            </Input>
                          </InputGroup>
                        </FormGroup>
                      </Col>   
                    </Row>
                    <Row className="g-12">
                        {/*start order details */}
                        <Col lg="12" md="12" sm="12">
                          <Row className="g-12"><Col lg="12" md="12" sm="12" xs="12">
                            <span className="subTitleLine f-left"><span  className="ordersubhead f-w-500 f-16">{t("address")}</span></span>
                            <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                          </Col>
                          </Row>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                          <FormGroup className="">
                            <Label>{t("Head Office Address")}<sup className="font-danger">*</sup></Label>
                            <InputGroup>
                              <Input
                                name="HeadOfficeAddress"
                                type="textarea"
                                id="HeadOfficeAddress"
                                aria-label="factories"
                                defaultValue={mapData.office_address}
                                onChange={(e) => setHeadOfficeAddress(e.target.value)}>
                              </Input>
                            </InputGroup>
                            
                          </FormGroup>
                        </Col> 
                        <Col lg="6" md="6" sm="12" xs="12" xl="6" xxl="6">
                          <FormGroup className="">
                            <Label>{t("Factory")}</Label>
                            <InputGroup>
                              <Input
                                name="factoryaddress"
                                type="textarea"
                                id="factoryaddress"
                                aria-label="factoryaddress"
                                defaultValue={mapData.factory_address}
                                onChange={(e) => setFactoryAddress(e.target.value)}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col>   
                        {/*end order details */}
                    </Row>
                  </>
                )
              }
            )
        :
          ""
        }
        <Row className="g-12">

          {/*start order details */}
          <Col lg="12" md="12" sm="12">
            <Row className="g-12">
              <Col lg="12" md="12" sm="12" xs="12">
                <span className="subTitleLine f-left">
                  <span className="ordersubhead f-w-500 f-16">{t("Contact Details")}</span>
                </span>
                <span><hr className="mt-4 mb-4 m-t-10" /></span>
              </Col>
            </Row>
          </Col>
          
          <div>
            <Table className="table-responsive ">
              <thead>
                <tr>
                  <th style={{border: "none"}} className='m-l-5 '>{t("Role")}</th>
                  <th style={{border: "none"}}>{t("name")}</th>
                  <th style={{border: "none"}}>{t("Mobile Number")}</th>
                  <th style={{border: "none"}}>{t("Email Id")}</th>
                </tr>
              </thead>
                {
                  contactDetails.length > 0 
                  ? 
                    contactDetails.map(( mapData) => 
                  {
                    return (
                            <>
                              <tbody className="m-t-5">
                                { 
                                mapData.role=="M.D"
                                  ?
                                    <tr style={{border: "none"}} className='m-l-5'> 
                                      <td style={{border: "none"}}>{mapData.role}</td>
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="mdnames"
                                          id="mdnames"
                                          aria-label="mdnames"
                                          defaultValue={mapData.name?mapData.name:"-"}
                                          onChange={(e) => setMdname(e.target.value)}>
                                        </Input>
                                      </td> 
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="number"
                                          id="number"
                                          aria-label="number"
                                          defaultValue={mapData.number?mapData.number:"-"}
                                          onChange={(e) => setMdMobileNumber(e.target.value)}>
                                        </Input>
                                      </td>
                                      <td style={{border: "none"}}>  
                                        <Input
                                          name="emailid"
                                          id="emailid"
                                          aria-label="emailid"
                                          defaultValue={mapData.email?mapData.email:"-"}
                                          onChange={(e) => setMdEmailId(e.target.value)}>
                                        </Input>
                                      </td>
                                    </tr>
                                  : 
                                    ""
                                }
                                {
                                mapData.role=="sales"
                                ?
                                    <tr style={{border: "none"}} className='m-l-5'> 
                                      <td style={{border: "none"}}>
                                        {mapData.role}
                                      </td>
                                      <td style={{border: "none"}}> 
                                            <Input
                                              name="salesnames"
                                              id="salesnames"
                                              aria-label="salesnames"
                                              defaultValue={mapData.name?mapData.name:"-"}
                                              onChange={(e) => setSalesName(e.target.value)}> 
                                            </Input>
                                      </td> 
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="salesnumber"
                                          id="salesnumber"
                                          aria-label="salesnumber"
                                          defaultValue={mapData.number?mapData.number:"-"}
                                          onChange={(e) => setSalesMobileNumber(e.target.value)}>
                                        </Input>
                                      </td>
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="salesemailid"
                                          id="salesemailid"
                                          aria-label="salesemailid"
                                          defaultValue={mapData.email?mapData.email:"-"}
                                          onChange={(e) => setSalesEmailId(e.target.value)}>
                                        </Input>
                                      </td>
                                    </tr>
                                :
                                  ""
                                }
                                {
                                mapData.role=="Accounts"
                                ?
                                    <tr style={{border: "none"}} className='m-l-5'> 
                                      <td style={{border: "none"}}>Accounts</td>
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="Accountsnames"
                                          id="Accountsnames"
                                          aria-label="Accountsnames"
                                          defaultValue={mapData.name?mapData.name:"-"}
                                          onChange={(e) => setAccountsname(e.target.value)}>
                                        </Input>
                                      </td> 
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="Accountsnumber"
                                          id="Accountsnumber"
                                          aria-label="Accountsnumber"
                                          defaultValue={mapData.number?mapData.number:"-"}
                                          onChange={(e) => setAccountsMobileNumber(e.target.value)}>
                                        </Input>
                                      </td>
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="Accountsemailid"
                                          id="Accountsemailid"
                                          aria-label="Accountsemailid"
                                          defaultValue={mapData.email?mapData.email:"-"}
                                          onChange={(e) => setAccountsEmailId(e.target.value)}>
                                        </Input>
                                      </td>
                                    </tr>
                                :
                                  ""
                                }
                                {
                                mapData.role=="Production"
                                ?
                                    <tr style={{border: "none"}} className='m-l-5 '> 
                                      <td style={{border: "none"}}>Productions</td>
                                      <td style={{border: "none"}}> 
                                          <Input
                                            name="Productionnames"
                                            id="Productionnames"
                                            aria-label="Productionnames"
                                            defaultValue={mapData.name?mapData.name:"-"}
                                            onChange={(e) => setProductionName(e.target.value)}>
                                          </Input>
                                      </td> 
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="Productionnumber"
                                          id="Productionnumber"
                                          aria-label="Productionnumber"
                                          defaultValue={mapData.number?mapData.number:"-"}
                                          onChange={(e) => setProductionMobileNumber(e.target.value)}>
                                        </Input>
                                      </td>
                                      <td style={{border: "none"}}> 
                                        <Input
                                          name="Productionemailid"
                                          id="Productionemailid"
                                          aria-label="Productionemailid"
                                          defaultValue={mapData.email?mapData.email:"-"}
                                          onChange={(e) => setProductionEmailId(e.target.value)}>
                                        </Input>
                                      </td>
                                    </tr>
                                :
                                  ""
                                }
                              </tbody>
                            </>
                        )
                      }
                    ) 
                  : 
                  ""
                }
            </Table>
          </div>

          <div>
            <Row className="f-right">
              <Col className="m-t-10 m-r-10">
                <Button 
                  style={{float:"Right "}} 
                  className= "btn-sm m-t-10" 
                  onClick={() => UpdateEditVendorInformation()} >
                  {t("Update")}
                </Button>
              </Col>
            </Row>
          </div>

        </Row>
      </ModalBody>

    </Modal>
  );
};

export default EditVendorModal;

//**************************code by: Ramanan M ***************************//