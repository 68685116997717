import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, 
    CardHeader, Collapse, FormGroup, Table } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, H2, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import {  getWorkspaceType } from "../../../Constant/LoginConstant";
import { PHPtoJSFormatConversion } from "../../../helper";
import { useTranslation } from 'react-i18next';

const OrderAccessoriesReports =({styles, vendorList, sewingAccessoriesList, packingAccessoriesList, miscellaneousList})=>{
    const [isOpen, setIsOpen] = useState(0);
    const { t } = useTranslation();
    return (
        <>

            {/* Sewing Accessories */}
            <Row style={{  marginTop: '-27px' }}>
                <Col sm="12" xl="12" lg="12" md="12">
                    <Card className="m-l-15 m-r-15  m-b-0" style={{ border: 'none' }}>
                            <span className="ordersubhead" 
                                style={{ color: '#009688', fontWeight: '600', 
                                        fontSize: '17px', padding: '5px' }}>
                                {t("sewingAccessories")}
                            </span>
                    </Card>
                    <Card className="m-l-15 m-r-15">
                        <CardBody className="p-0 table-responsive" >
                                <Table className="table-responsive tableRowCol custom-table-striped">
                                
                                    <tr style={{background:"#c2ede8f5", fontSize:"14px"}}>
                                        <th> {t("type")} </th>
                                        <th >{t("component")}</th>
                                        <th >{t("color")}</th>
                                        <th >{t("vendorInformation")}</th>
                                        <th >{t("perGMT")}</th>
                                        <th >{t("totalQty")}</th>
                                        <th >{t("units")}</th>
                                        <th >{t("pricePerUnit")}</th>
                                    </tr>

                                    {
                                    sewingAccessoriesList.length > 0
                                    ? 
                                    sewingAccessoriesList.map((style, index)=>{
                                        return(
                                            <tr key={index}>
                                                <td> {style.Type ? style.Type : "-"} </td>
                                                <td> {style.component ? style.component : "-" } </td>
                                                <td> {style.color ? style.color : "-"} </td>
                                                <td> 
                                                {vendorList.length>0
                                                ?
                                                  style.vendor!='0' && style.vendor!='Select Vendor'
                                                  ?
                                                    vendorList.map((vendor, index) =>                                                  
                                                    (style.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                        ) 
                                                    :"-"
                                                :""}
                                                </td>
                                                <td> {style.perGMT ? style.perGMT : "-"} </td>
                                                <td> {style.totalQty ? style.totalQty : "-" } </td>
                                                <td> {style.units !== '0' ? style.units : "-" } </td>
                                                <td> {style.pricePerUnit ? style.pricePerUnit : "-"  } </td>
                                            </tr>
                                        )
                                        })
                                    :
                                        <tr>
                                            <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                        </tr>
                                    }
                                </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Packing Accessories */}
            <Row style={{  marginTop: '-10px' }}>
                <Col sm="12" xl="12" lg="12" md="12">
                    <Card className="m-l-15 m-r-15  m-b-0" style={{ border: 'none', color: '#009688' }}>
                            <span className="ordersubhead" 
                                style={{ color: '#009688', fontWeight: '600', 
                                        fontSize: '17px', padding: '5px' }}>
                                {t("packingAccessories")}
                            </span>
                    </Card>
                    <Card className="m-l-15 m-r-15">
                        <CardBody className="p-0 table-responsive" >
                                <Table className="table-responsive tableRowCol custom-table-striped">
                                
                                    <tr style={{background:"#c2ede8f5", fontSize:"14px"}}>
                                        <th> {t("type")} </th>
                                        <th >{t("component")}</th>
                                        <th >{t("color")}</th>
                                        <th >{t("vendorInformation")}</th>
                                        <th >{t("perGMT")}</th>
                                        <th >{t("totalQty")}</th>
                                        <th >{t("units")}</th>
                                        <th >{t("pricePerUnit")}</th>
                                    </tr>

                                    {
                                    packingAccessoriesList.length > 0
                                    ? 
                                    packingAccessoriesList.map((style, index)=>{
                                        return(
                                            <tr key={index}>
                                                <td> {style.Type ? style.Type : "-"} </td>
                                                <td> {style.component ? style.component : "-" } </td>
                                                <td> {style.color ? style.color : "-"} </td>
                                                <td>   
                                                {vendorList.length>0
                                                ?
                                                  style.vendor!='0' && style.vendor!='Select Vendor'
                                                  ?
                                                    vendorList.map((vendor, index) =>                                                  
                                                    (style.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                        ) 
                                                    :"-"
                                                :""}
                                                </td>
                                                <td> {style.perGMT ? style.perGMT : "-"} </td>
                                                <td> {style.totalQty ? style.totalQty : "-" } </td>
                                                <td> {style.units !== '0' ? style.units : "-" } </td>
                                                <td> {style.pricePerUnit ? style.pricePerUnit : "-"  } </td>
                                            </tr>
                                        )
                                        })
                                    :
                                        <tr>
                                            <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                        </tr>
                                    }
                                </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Miscellaneous */}
            <Row style={{  marginTop: '-10px' }}>
                <Col sm="12" xl="12" lg="12" md="12">
                    <Card className="m-l-15 m-r-15  m-b-0" style={{ border: 'none', color: '#009688' }}>
                            <span className="ordersubhead" 
                                style={{ color: '#009688', fontWeight: '600', 
                                        fontSize: '17px', padding: '5px' }}>
                                {t("miscellaneous")}
                            </span>
                    </Card>
                    <Card className="m-l-15 m-r-15">
                        <CardBody className="p-0 table-responsive" >
                                <Table className="table-responsive tableRowCol custom-table-striped">
                                
                                    <tr style={{background:"#c2ede8f5", fontSize:"14px"}}>
                                        <th> {t("type")} </th>
                                        <th >{t("component")}</th>
                                        <th >{t("color")}</th>
                                        <th >{t("vendorInformation")}</th>
                                        <th >{t("perGMT")}</th>
                                        <th >{t("totalQty")}</th>
                                        <th >{t("units")}</th>
                                        <th >{t("pricePerUnit")}</th>
                                    </tr>

                                    {
                                        miscellaneousList.length > 0
                                    ? 
                                        miscellaneousList.map((style, index)=>{
                                            return(
                                                <tr key={index}>
                                                    <td> {style.Type ? style.Type : "-"} </td>
                                                    <td> {style.component ? style.component : "-" } </td>
                                                    <td> {style.color ? style.color : "-"} </td>
                                                    <td> 
                                                    {vendorList.length>0
                                                    ?
                                                    style.vendor!='0' && style.vendor!='Select Vendor'
                                                    ?
                                                        vendorList.map((vendor, index) =>                                                  
                                                        (style.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                            ) 
                                                        :"-"
                                                    :""}
                                                    </td>
                                                    <td> {style.perGMT ? style.perGMT : "-"} </td>
                                                    <td> {style.totalQty ? style.totalQty : "-" } </td>
                                                    <td> {style.units !== '0' ? style.units : "-" } </td>
                                                    <td> {style.pricePerUnit ? style.pricePerUnit : "-"  } </td>
                                                </tr>
                                            )
                                        })
                                    :
                                        <tr>
                                            <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                        </tr>
                                    }
                                </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </>
        
 )
}

export default OrderAccessoriesReports;
/***** CODE BY : RAMANAN M  *****/