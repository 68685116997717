import React, { Fragment, useState, useEffect, useRef } from "react";
import { Card, Col, CardBody, Row } from "reactstrap";
import { ServerUrl } from "../../Constant/index";
import axios from "axios";
import { getLoginCompanyId,  getLoginUserId,  getWorkspaceId,
  getLoginStaffId,} from "../../Constant/LoginConstant";
import { useTranslation } from "react-i18next";
import { apiencrypt, apidecrypt } from "../../helper";
import { Bar } from "react-chartjs-2";
import v1cutgreen_icon from "../../assets/images/dms/icons/v1cutgreen_icon.svg";
import Sewv1green_icon from "../../assets/images/dms/icons/Sewv1green_icon.svg";
import packv1_icon from "../../assets/images/dms/icons/packv1_icon.svg";
// import noData from "../../assets/images/dms/nodata.png";

const Productiondelay = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [productionOrder_id, setproductionOrder_id] = useState();
  const [productiondelayed, setproductiondelayed] = useState();
  const [cutdelayed, setcutdelayed] = useState();
  const [sewdelayed, setsewdelayed] = useState();
  const [packdelayed, setpackdelayed] = useState();
  const [totalData, setTotalData] = useState();
  const { t } = useTranslation();
  const [maxValue, setMaxValue] = useState();
  const [arrayValues, setArrayValues] = useState([]);
  useEffect(() => {
    axios.post(
        ServerUrl + "/get-top-delay-prod",
        apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        })
      ).then((response) => {
        response.data = apidecrypt(response.data);
        setTotalData(response.data.data);
        setproductiondelayed(response.data.data[0].style_no);
        setproductionOrder_id(response.data.data[0].order_id);
        setcutdelayed(response.data.data[0].cut_delay);
        setsewdelayed(response.data.data[0].sew_delay);
        setpackdelayed(response.data.data[0].pack_delay);

        var cut = response.data.data[0].cut_delay;
        var sew = response.data.data[0].sew_delay;
        var pack = response.data.data[0].pack_delay;
        var array = [].concat(cut, sew, pack);
        setArrayValues(array);

        var maxValue = array.length > 0 ? Math.max(...array) : 150;
        if( maxValue >= 0 && maxValue <= 100 ){
          var additionalValue = Number(maxValue) + Number(35);
        } else if( maxValue > 100 &&  maxValue <= 200 ){
          var additionalValue = Number(maxValue) + Number(25);
        } else if( maxValue > 200 &&  maxValue <= 300 ){
          var additionalValue = Number(maxValue) + Number(55);
        } 
        setMaxValue(additionalValue);
      });
  }, []);

  const ordersttus = (indexValue) => {
    props.geOrderstatusId(productionOrder_id[indexValue]);
  };

  const cutimage = new Image(15, 15);
  cutimage.src = v1cutgreen_icon;
  const sewimage = new Image(15, 15);
  sewimage.src = Sewv1green_icon;
  const packimage = new Image(15, 15);
  packimage.src = packv1_icon;

  const image = new Image();
  image.src = v1cutgreen_icon;
  var arr = [` ${image}`.split("\n")];
  const createImage = function (v1cutgreen_icon, title) {
    return { src: v1cutgreen_icon, title: title };
  };

  const barChartOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        align: "start",
        boxWidth: 20,
        boxHeight: 30,
        boxPadding: 2,
        borderColor: "#ccc",
        borderWidth: 2,
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "red",
        usePointStyle: true,

        callbacks: {
          labelPointStyle: (context) => {
            if (context.dataset.label == "Cutting") {
              return { pointStyle: cutimage };
            } else if (context.dataset.label == "Sewing") {
              return { pointStyle: sewimage };
            } else if (context.dataset.label == "Packing") {
              return { pointStyle: packimage };
            }
          },

          labelTextColor: (context) => {
            if (context.dataset.label == "Cutting") {
              return "#13D09A";
            } else if (context.dataset.label == "Sewing") {
              return "#5159AC";
            } else if (context.dataset.label == "Packing") {
              return "#13A1C1";
            } else if (context.parsed.y != null) {
              return "red";
            }
          },

          label: function (context) {
            var label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              // label += ' ' +context.parsed.y +"\n"+' ' ;
            }
            return label;
          },

          afterTitle: function (context) {
            return context[0]["parsed"]["y"] + " Days Delayed";
          },
        },
      },
    },

    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "No of days",
          color: "black",
          fontStyle: "bold",
        },
        min: 0,
        max: maxValue,
        ticks: { stepSize: 1 },
      },
      x: {
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          font: { size: 13 },
        },
        grid: {
          display: false,
        },
      },
    },
    xAxes: [{ barThickness: 10 }],

    onClick: (event, index) => {
      if (index.length > 0) {
        ordersttus(index[0].index);
      }
    },
  };

  const barChartData = {
    labels: productiondelayed,
    datasets: [
      {
        legend: "Cutting",
        label: "Cutting",
        data: cutdelayed,
        borderColor: "#13D09A",
        backgroundColor: "#13D09A",
        barPercentage: 0.25,
        skipNull: true,
        // highlightFill: "#13D09A",
        // highlightStroke: "#13D09A",
        // borderWidth: 1,
        // barThickness: 2,
        // maxBarThickness: 20,
        // minBarLength: 1,
      },
      {
        label: "Sewing",
        legend: "Sewing",
        data: sewdelayed,
        borderColor: "#5159AC ",
        backgroundColor: "#5159AC ",
        barPercentage: 0.25,
        skipNull: true,
      },
      {
        label: "Packing",
        legend: "Packing",
        data: packdelayed,
        borderColor: "#13A1C1",
        backgroundColor: "#13A1C1",
        barPercentage: 0.25,
        skipNull: true,
      },
    ],
  };

  const barChartNoData = {
    labels: "",
    datasets: [
      {
        label: "No Data",
        legend: "No Data",
        data: [],
        borderColor: "#7b7b7b ",
        backgroundColor: "#7b7b7b ",
        barPercentage: 0.25,
        skipNull: true,
      },
    ],
  };

  const barChartOptionsNoData = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 38,
          boxHeight: 10,
        },  
      }
    },
    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "No of days",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 }
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 },
      },
      x: {
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
      },
    },
  };

  return (
    <Fragment>
      <Col xl="6" md="12">
        <Card className="shadow shadow-showcase" style={{ marginTop: '-7px' }}>
            <span className="m-t-20 p-l-25">
              <span className="f-16 f-w-600"> {t("top5DelayedProduction")} </span>
              <span className="p-r-15" style={{ float: "right" }}>
                <img className="p-l-20" src={packv1_icon} /><br />
                <span style={{ color: "#13A1C1" }} className="pb-2 f-12">
                  {t("packing")}
                </span>
                <hr
                  style={{ backgroundColor: "#13A1C1", height: "2px" }}
                  className="mt-1"
                ></hr>
              </span>
              <span className="p-r-15" style={{ float: "right" }}>
                <img className="p-l-20" src={Sewv1green_icon} />
                <br />
                <span style={{ color: "#5159AC" }} className="mb-0 f-12">
                  {t("sewing")}
                </span>
                <hr
                  style={{ backgroundColor: "#5159AC", height: "2px" }}
                  className="mt-1"
                ></hr>
              </span>
              <span className="p-r-15" style={{ float: "right" }}>
                <img className="p-l-20" src={v1cutgreen_icon} />
                <br />
                <span style={{ color: "#13D09A" }} className="mb-0 f-12">
                  {t("cutting")}
                </span>
                <hr style={{backgroundColor: "#13D09A",height: "2px" }} className="mt-1"/>
              </span>
            </span>
            <CardBody className="chart-block pt-1 pb-1">
              <Row>
                <Col xl="12" md="12">
                  {productiondelayed == "" ? (
                    <>
                      {sewdelayed == undefined || packdelayed == undefined || cutdelayed == undefined ? ("")  : (
                        <Bar data={barChartNoData} options={barChartOptionsNoData} width={3600} height={1320} />  )}
                    </>
                  ) : (
                    <>
                      {sewdelayed == undefined || packdelayed == undefined || cutdelayed == undefined ? ("" ) : (
                        <Bar data={barChartData} options={barChartOptions} width={3600} height={1320} /> )}
                    </>
                  )}
                </Col>
              </Row>
            </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default Productiondelay;
//**************************Code by : Praveen****************************//
