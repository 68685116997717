import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col, FormGroup, InputGroup, InputGroupText,CardBody   } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges, ServerUrl, maxUploadFileSize } from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";
import Files from "react-files";
import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt,DownloadFile } from '../../../helper';
import imgUpload from "../../../assets/images/dms/icons/imgUpload.svg";
import {
    getLoginCompanyId,
    getLoginUserId,
    getLoginStaffId,
    getWorkspaceId,
    getStaff,
    getStaffPermission,
  } from "../../../Constant/LoginConstant";    
import deleteIcon from "../../../assets/images/dms/icons/inquiryDeleteIcon.svg";
import noData from "../../../assets/images/dms/nodata.png"

const AddPermissionImageModal = ({ 
    modal, toggle, orderId,downloadstyle,sewing_accessoriest,packingAccessoriest,media_type,label_type
}) =>{
  const {t} = useTranslation();
    /*********************** To Download The PDF ********************/
  const toDownloadAsPdf = (id) => {
    axios.post(ServerUrl + "/download-order-bom", apiencrypt({
      "order_id": orderId, 
      "company_id": getLoginCompanyId, 
      "workspace_id": getWorkspaceId,
      "user_id": getLoginUserId,
      "staff_id": getLoginStaffId,
      "image":id
    }), { responseType: 'blob' })
      .then((response) => {
        let nameOfFile =  downloadstyle ? downloadstyle + "-BillofMaterials.pdf"  :  "BillofMaterials.pdf"
        DownloadFile(response.data, nameOfFile);

        toggle(false)
      })
  }

    return (
        <Modal backdrop="static" isOpen={modal} toggle={toggle} centered className="">
        <ModalHeader>{t("pdfDownload")} 
            <span className="f-right cursor-pointer" title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggle}>X</span> </ModalHeader>
        <ModalBody>
                                    <Col>
                                <FormGroup className="m-t-15 m-checkbox-inline mb-0 custom-radio-ml">
                                    <div className="radio radio-primary">
                                        <Input id="radioinline1" type="radio" name="radio1" value="1"  onClick={(e)=> {
                                            toDownloadAsPdf(e.target.value)} }/>
                                            <Label className="mb-0" for="radioinline1">{Option}<span className="digits">  {t("pdfWithImage")}</span></Label>
                                    </div>
                                    <div className="radio radio-primary">
                                        <Input id="radioinline2" type="radio" name="radio1" defaultValue="0" 
                                        onClick={(e)=> {
                                            toDownloadAsPdf(e.target.value)} }/>
                                            <Label className="mb-0" for="radioinline2">{Option}<span className="digits">  {t("pdfWithoutImage")}</span></Label>
                                    </div>
                                </FormGroup>
                            </Col>

          

        </ModalBody>
        {/* <ModalFooter>
            <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>{t("close")}</Btn>
           
        </ModalFooter> */}
        </Modal>
    );
};

export default AddPermissionImageModal;
