import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Input, Label, Button
} from "reactstrap";
import { Btn, H6 } from "../../AbstractElements";
import { Close, SaveChanges, ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";
import { getLoginUserId, getLoginCompanyId, getWorkspaceId, getStaff, getLoginStaffId, getStaffPermission } from "../../Constant/LoginConstant"
import Moment from "moment";
import { useTranslation } from 'react-i18next';
import { apiencrypt, PHPtoJSFormatConversion, apidecrypt } from "../../helper";
const NewRescheduleModal = ({ modal, toggle, rescheduleType, personInCharge, taskID, templateData, orderId, 
    showDetails, value , isSubtask , setIsSubtask, parentDate , setParentDate,inquiryDate,startdate,create_dates,setValue,titleType,endDateValue }) => {

    // const [date, setDate] = useState(value);
    const [pic, setPic] = useState("");
    const [reason, setReason] = useState("");
    const { t } = useTranslation();
    const [errors, setValidErrors] = useState({});
    const validation = () => {

        let errors = {};
        if (rescheduleType === "StartDate") {
            if (!value) {
                errors.value = ("startDateValidation")
            }
        }
        if (rescheduleType === "EndDate") {
            if (!value) {
                errors.value = ("endDateValidation")
            }
        }
        if (rescheduleType === "picId") {
            if (!pic) {
                errors.pic = ("pleaseSelectPic")
            }
        }
        if (!reason) {
            errors.reason = ("reasonValidation")
        }

        setValidErrors(errors);
        return errors;
    };
    useEffect(() => {
        if (!modal) {
            setValue("");
            setPic("");
            setValidErrors("");
            setIsSubtask("");
            setParentDate("");
        }
    });
   
    const onSaveHandle = () => {
        let retval = validation();
        if (Object.keys(retval).length == 0) {
            if (rescheduleType === "StartDate") {
                let startDateData;
                getStaff === "Staff" ? startDateData = {
                    id: taskID,
                    rescheduleType: "Reschedule",
                    reason: reason,
                    date: value,
                    theType: rescheduleType,
                    userId: getLoginUserId,
                    staffId: getLoginStaffId,
                    user_type: "Staff",
                    isSubtask : isSubtask,
                } : startDateData = {
                    id: taskID,
                    rescheduleType: "Reschedule",
                    reason: reason,
                    date: value,
                    theType: rescheduleType,
                    userId: getLoginUserId,
                    user_type: "User",
                    isSubtask : isSubtask,
                }
                axios.post(ServerUrl + "/reschedule-task", apiencrypt(startDateData)).then((response) => {
                    response.data = apidecrypt(response.data)
                    if (response.data.status_code === 200) {
                        Swal.fire({
                            title: t("dateRescheduleSuccessfully"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false,
                            timer:5000,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                            }
                            setTimeout(() => {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                              }, 100);
                        });
                    }
                    /* To show error if the Data is null*/
                    if (response.data.status_code === 400) {
                        Swal.fire({
                            title: t("selectDate"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 402) {
                        Swal.fire({
                            title: t("startDateGreater"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 603) {
                        Swal.fire({
                            title: t("rescheduleSubtasksStartDate"),
                            icon: "error",              
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 401) {
                        Swal.fire({
                            title: t(response.data.error.reason),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                })

            }
            else if (rescheduleType === "EndDate") {
                let endDateData;
                getStaff === "Staff" ? endDateData = {
                    id: taskID,
                    rescheduleType: "Reschedule",
                    reason: reason,
                    date: value,
                    theType: rescheduleType,
                    userId: getLoginUserId,
                    staffId: getLoginStaffId,
                    user_type: "Staff",
                    isSubtask : isSubtask,
                } : endDateData = {
                    id: taskID,
                    rescheduleType: "Reschedule",
                    reason: reason,
                    date: value,
                    theType: rescheduleType,
                    userId: getLoginUserId,
                    user_type: "User",
                    isSubtask : isSubtask,
                }
                axios.post(ServerUrl + "/reschedule-task", apiencrypt(endDateData)).then((response) => {
                    response.data = apidecrypt(response.data)
                    if (response.data.status_code === 200) {
                        Swal.fire({
                            title: t("dateRescheduleSuccessfully"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false,
                            timer:5000,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                            }
                            setTimeout(() => {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                              }, 100);
                        });
                    }
                    /* To show error if the Data is null*/
                    if (response.data.status_code === 400) {
                        Swal.fire({
                            title: t("selectDate"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 402) {
                        Swal.fire({
                            title: t("endDateGreater"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 403) {
                        Swal.fire({
                            title: t("endDateParentTask"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 603) {
                        Swal.fire({
                            title: t("rescheduleSubtasksEndDate"),
                            icon: "error",              
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 401) {
                        Swal.fire({
                            title: t(response.data.error.reason),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                })
            }
            else if (rescheduleType === "picId") {
                let picData;
                getStaff === "Staff" ? picData = {
                    id: taskID,
                    rescheduleType: "Reassign",
                    reason: reason,
                    picId: pic,
                    userId: getLoginUserId,
                    staffId: getLoginStaffId,
                    user_type: "Staff",
                    isSubtask : isSubtask,
                } : picData = {
                    id: taskID,
                    rescheduleType: "Reassign",
                    reason: reason,
                    picId: pic,
                    userId: getLoginUserId,
                    user_type: "User",
                    isSubtask : isSubtask,
                }
                axios.post(ServerUrl + "/reschedule-task", apiencrypt(picData)).then((response) => {
                    response.data = apidecrypt(response.data)
                    if (response.data.status_code === 200) {
                        Swal.fire({
                            title: t("picChangedSuccessfully"),
                            icon: "success",
                            button: t("okLabel"),
                            timer:5000
                        }).then((result) => {
                            if (result.isConfirmed) {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                            }
                            setTimeout(() => {
                                toggle(false);
                                showDetails(0);
                                /* To show the updated taskData */
                                axios
                                    .post(ServerUrl + "/get-task-data", apiencrypt({
                                        company_id: getLoginCompanyId,
                                        workspace_id: getWorkspaceId,
                                        order_id: orderId
                                    }))
                                    .then((response) => {
                                        response.data = apidecrypt(response.data)
                                        templateData(response.data.data);
                                        showDetails(1);
                                    });
                              }, 100);
                        });
                    }
                    /* To show error if the Data is null*/
                    if (response.data.status_code === 400) {
                        Swal.fire({
                            title: t("pleaseSelectPic"),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                    if (response.data.status_code === 401) {
                        Swal.fire({
                            title: t(response.data.error.reason),
                            icon: "error",
                            button: t("okLabel"),
                        });
                    }
                })
            }
        }
    }
    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            {rescheduleType === "StartDate" ?
                <ModalHeader>{t("rescheduleDate")}</ModalHeader> : ""
            }
            {rescheduleType === "EndDate" ?
                <ModalHeader>{t("rescheduleDate")}</ModalHeader> : ""
            }
            {rescheduleType === "picId" ?
                <ModalHeader>{t("reassignPIC")}</ModalHeader> : ""
            }

            <ModalBody>
                {rescheduleType === "StartDate" ?
                    <>
                    {titleType=="task"?
                    <>
                     <div>{t("prevStartDate")} :  <H6>{value === null ? t("noStartDateGiven") : PHPtoJSFormatConversion.format(new Date(value))}</H6></div>
                     <Label className="col-form-label" for="recipient-name">
                         {t("rescheduleStartDate")}
                     </Label><sup className="font-danger">*</sup>
                     <Input
                         className="form-control"
                         type="date"
                         id="startDate"
                         defaultValue={value}
                        //  min={((getStaff === "Staff"&&getStaffPermission.includes('Inquiry Date'))||getStaff == null) 
                        //  && (inquiryDate != null) ? inquiryDate :
                        //  create_dates}
                         min={(getStaff === "Staff" && getStaffPermission.includes('Early Task Start') || getStaff == null)?  ""  
                         :inquiryDate != null ? inquiryDate:create_dates}
                                           
                                            
                                              
                                         //   }
                         onChange={(e) => {setValue(e.target.value);
                            }}
                         onKeyDown={(e) => {
                             e.preventDefault();
                         }}
                     />
                     {errors.value && (
                         <><span className="error-msg">{t(errors.value)}</span><br /></>
                     )}
                     <Label className="col-form-label" for="recipient-name">
                         {t("reason")} :
                     </Label><sup className="font-danger">*</sup>
                     <Input
                         className="form-control"
                         type="textarea"
                         onChange={(e) => setReason(e.target.value)}
                     />
                     {errors.reason && (
                         <span className="error-msg">{t(errors.reason)}</span>
                     )}
                   </> :
                      <>
                      <div>{t("prevStartDate")} :  <H6>{value === null ? t("noStartDateGiven") : PHPtoJSFormatConversion.format(new Date(value))}</H6></div>
                      <Label className="col-form-label" for="recipient-name">
                          {t("rescheduleStartDate")}
                      </Label><sup className="font-danger">*</sup>
                      <Input
                          className="form-control"
                          type="date"
                          id="startDate"
                          defaultValue={value}
                         
                          // min={(getStaff === "Staff" && getStaffPermission.includes('Inquiry Date') || getStaff == null)?  ""  
                          // :inquiryDate != null ? inquiryDate:create_dates
                                            
                          min={startdate}                
                           max={endDateValue}                    
                                          //   }
                          onChange={(e) => {setValue(e.target.value);
                             }}
                          onKeyDown={(e) => {
                              e.preventDefault();
                          }}
                      />
                      {errors.value && (
                          <><span className="error-msg">{t(errors.value)}</span><br /></>
                      )}
                      <Label className="col-form-label" for="recipient-name">
                          {t("reason")} :
                      </Label><sup className="font-danger">*</sup>
                      <Input
                          className="form-control"
                          type="textarea"
                          onChange={(e) => setReason(e.target.value)}
                      />
                      {errors.reason && (
                          <span className="error-msg">{t(errors.reason)}</span>
                      )}
                    </>
                    }
                       
                    </>
                    : ""
                }
                {rescheduleType === "EndDate" ?
                    <>
                    {titleType=="task"
                    ?
                  <>
                     <div>{t("prevEndDate")} :  <H6>{value === null ? t("noEndDateGiven") : PHPtoJSFormatConversion.format(new Date(value))}</H6></div>
                        {isSubtask === "Subtask" && parentDate != "" ? 
                        <div>{t("parentEndDate")} :  <H6>{PHPtoJSFormatConversion.format(new Date(parentDate))}</H6></div> : ""
                        }
                        <Label className="col-form-label" for="recipient-name">
                            {t("rescheduleEndDate")}
                        </Label><sup className="font-danger">*</sup>
                        <Input
                            className="form-control"
                            type="date"
                            id="endDate"
                            defaultValue={value}
                            min={startdate}
                            onChange={(e) => setValue(e.target.value)}
                            onKeyDown={(e) => {
                                e.preventDefault();
                            }}
                        />
                        {errors.value && (
                            <><span className="error-msg">{t(errors.value)}</span><br /></>
                        )}
                        <Label className="col-form-label" for="recipient-name">
                            {t("reason")} :
                        </Label> <sup className="font-danger">*</sup>
                        <Input
                            className="form-control"
                            type="textarea"
                            onChange={(e) => setReason(e.target.value)}
                        />
                        {errors.reason && (
                            <span className="error-msg">{t(errors.reason)}</span>
                        )}</>
                    :
                    <>
                    <div>{t("prevEndDate")} :  <H6>{value === null ? t("noEndDateGiven") : PHPtoJSFormatConversion.format(new Date(value))}</H6></div>
                       {isSubtask === "Subtask" && parentDate != "" ? 
                       <div>{t("parentEndDate")} :  <H6>{PHPtoJSFormatConversion.format(new Date(parentDate))}</H6></div> : ""
                       }
                       <Label className="col-form-label" for="recipient-name">
                           {t("rescheduleEndDate")}
                       </Label><sup className="font-danger">*</sup>
                       <Input
                           className="form-control"
                           type="date"
                           id="endDate"
                           defaultValue={value}
                           min={startdate}                
                           max={endDateValue}  
                           onChange={(e) => setValue(e.target.value)}
                           onKeyDown={(e) => {
                               e.preventDefault();
                           }}
                       />
                       {errors.value && (
                           <><span className="error-msg">{t(errors.value)}</span><br /></>
                       )}
                       <Label className="col-form-label" for="recipient-name">
                           {t("reason")} :
                       </Label> <sup className="font-danger">*</sup>
                       <Input
                           className="form-control"
                           type="textarea"
                           onChange={(e) => setReason(e.target.value)}
                       />
                       {errors.reason && (
                           <span className="error-msg">{t(errors.reason)}</span>
                       )}</>
                    }
                     
                    </>
                    : ""
                }
                {rescheduleType === "picId" ?
                    <>
                        <div>{t("prevPIC")} :
                            {personInCharge.map((pic) => (
                                value === pic.staff_id ? <H6>{pic.staff_name}</H6> : ""
                            ))}
                        </div>
                        <Label className="col-form-label" for="recipient-name">
                            {t("reAssignedPIC")}
                        </Label> <sup className="font-danger">*</sup>
                        <Input
                            type="select"
                            defaultValue="0"
                            onChange={(e) => setPic(e.target.value)}
                        >
                            <option value="0" disabled>{t("Selectpic")}</option>
                            {personInCharge.map((pic) => (
                                <option value={pic.staff_id}>{pic.staff_name}</option>
                            ))}
                        </Input>
                        {errors.pic && (
                            <><span className="error-msg">{t(errors.pic)}</span><br /></>
                        )}
                        <Label className="col-form-label" for="recipient-name">
                            {t("reason")} :
                        </Label> <sup className="font-danger">*</sup>
                        <Input
                            className="form-control"
                            type="textarea"
                            onChange={(e) => setReason(e.target.value)}
                        />
                        {errors.reason && (
                            <span className="error-msg">{t(errors.reason)}</span>
                        )}
                    </>
                    : ""
                }
            </ModalBody>
            <ModalFooter>
                <Button className="btn secondaryBtn" onClick={toggle}>{t("close")}</Button>
                <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
                    {t("save")}
                </Btn>
            </ModalFooter>
        </Modal>
    );
};

export default NewRescheduleModal;
/* Code By : Rithanesh */