import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Table, Collapse,CardBody } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import bomb from '../../../assets/images/dms/BombSmiley.svg';

import { ServerUrl } from '../../../Constant/index';
import noData from "../../../assets/images/dms/nodata.png"
import axios from 'axios';
import { Accordion } from "react-bootstrap";
import {
  getLoginCompanyId, getLoginUserId,
  getWorkspaceId, getLoginStaffId
} from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { apiencrypt, apidecrypt } from '../../../helper';

const TaskStatusTable = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [taskStatusprod, settaskStatusprod] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(0);
  const { t } = useTranslation();
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));
  useEffect (() => {
   
      axios
        .post(ServerUrl + "/get-tasks-status", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          settaskStatusprod(response.data.tableView);
        })
      },[])
 
  const getSmiley = (delay) => {
    let date = Math.abs(delay);
    return (
      <td className="delayText text-left">
        <img className="p-0 img-30" src={bomb} /> &nbsp;{date}
      </td>);
  };

  return (
    <Fragment>
      <Col xs="12" sm="12" md="12" lg="12" xl="6" style={{marginTop:"6px"}} className="box-col-12  m-b-25">
       
      <div className="default-according" id="accordion">
            <Card className="borderbottomnone collopseradise" >
             
              <CardHeader style={{  borderBottom: 'none', borderRadius: '0.75rem' }}>
                <p className=" p-l-5  f-18 f-w-600">{t("taskStatus")}</p>
              </CardHeader>
            
                {taskStatusprod.length>0 ? 
                 <CardBody className="p-0 " style={{ height: "350px", borderRadius: '0.75rem' }}>
                 <ScrollBar className="" options={{ suppressScrollX: true }} >
                <div className="table-responsive">
                  <Table className="tableRowCol taskStatusTablePadding">
                      <tr style={{ fontSize: "13px" ,backgroundColor: '#F4F4F4' }}>
                        <th className='m-l-5 text-uppercase'> {t("styleNo")}</th>
                        <th className='text-center text-uppercase'>{t("completed")}</th>
                        <th className='text-center text-uppercase'>{t("delay")}</th>
                        <th className='text-center text-uppercase'>{t("delayComplete")}</th>
                        <th className='text-center text-uppercase'>{t("inProgress")}</th>
                        <th className='text-center text-uppercase p-r-5'>{t("yetToStart")}</th>
                      </tr>
                    {taskStatusprod.map((taskStatus, index) =>
                    (
                      <>
                          <tr className={`${ index+1 !== taskStatusprod.length ? "removeBottomBorder" : ""}`}>
                            <td className='cursor-pointer' title={taskStatus.orderNo + " " + "/" + " " + taskStatus.styleNo} 
                            tooltip={taskStatus.orderNo + " " + "/" + " " + taskStatus.styleNo} 
                            alt={taskStatus.orderNo + " " + "/" + " " + taskStatus.styleNo} style={{ paddingLeft: '0.75rem' }}> {taskStatus.styleNo} </td>
                            <td className='text-center'  style={{color:"#27A79B"}}> <b>{taskStatus.completed} </b></td>
                            <td  className='text-center' style={{color:"#EE335E"}}> <b>{taskStatus.delay}</b> </td>
                            <td   className='text-center' style={{color:"#E69020"}}> <b>{taskStatus.delayedCompleted} </b></td>
                            <td  className='text-center' style={{color:"#7B7B7B"}}> <b>{taskStatus.inProgress} </b></td>
                            <td  className='text-center p-r-5' style={{color:"#DFDFDF"}}> <b>{taskStatus.yetToStart}</b> </td>
                          </tr>
                      </>
                    )
                    )}
                  </Table>
                </div>
                </ScrollBar>
                </CardBody>
               : 
               <>
               <div  style={{ height: "104px" }}></div>
              <div className="p-5 align-self-center media-body centerAlign"
              style={{ height: "350px" }}>
                    <img src={noData} style={{ width: "200px" }} />
                    <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                </div>
                </>
              } 
             
            </Card>
          </div>
       
      </Col>
    </Fragment>
  );
};

export default TaskStatusTable;

//**************************Code by : Praveen****************************//