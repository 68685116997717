import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges, ServerUrl } from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt,capitalizeFirstLowercaseRest } from '../../../helper';
import { getLoginCompanyId, getStaff, getWorkspaceId } from '../../../Constant/LoginConstant';

const FormAddUnitsModal = ({ modal, toggle,setUnitsList,companyId,workspaceId,userId,staffId, toggleType, setGetUnitsList}) =>{
  
  const {t} = useTranslation();
 
  const [unitName, setUnitName] = useState("");

  const allVendorListApiCall = () => {
    axios
    .post(ServerUrl + "/all-vendor-list", apiencrypt({
      company_id: companyId,
      workspace_id: workspaceId,
    }))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setUnitsList(response.data.units);
    });
  };

  const getAllOrderDetailsApiCall = () => {

    const dataToSendAtStarting = {
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId
    };
    axios
    .post(ServerUrl + "/get-all-order-details", apiencrypt(dataToSendAtStarting))
    .then((response) => {
        response.data = apidecrypt(response.data);
        setGetUnitsList(response.data.data.units)
    })
  };

  const onSaveHandle = () => {
    if(!unitName){
      Swal.fire({
        title: t("unitName"),
        text: t("unitNameValidation"),
        icon: "error",
        button: t("okLabel"),
      });
    }else{
    axios
      .post(ServerUrl + "/create-user-unit", apiencrypt({
        company_id: companyId,
        workspace_id: workspaceId,
        user_id: userId,
        staff_id: staffId,
        name: unitName,
        bom_unit: toggleType == "addEditOrder" ? 0 : 1,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("unitAddedSuccessfully"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer: 5000,
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new units in the dropdown */
             if(toggleType == "addEditOrder"){
                getAllOrderDetailsApiCall()
             } else{
                allVendorListApiCall();
             }
            }
            setTimeout(() => {
              toggle(false);
              /* To show the new Units in the dropdown */     
             if(toggleType == "addEditOrder"){
                getAllOrderDetailsApiCall()
             } else{
                allVendorListApiCall();
             }
            }, 100);
          });
        }
        /* To show error if the unit is already present*/
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t("unitExistsAlert"),
            text: t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addUnit")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("addUnit")}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setUnitName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddUnitsModal;
/* Code By : Akshaya Mol */