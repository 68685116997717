import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, Table, Input, Form, Button,Media,Label } from 'reactstrap'
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import { getLoginCompanyId, getLoginUserId, getLoginStaffId, getWorkspaceId } from '../../Constant/LoginConstant';
import axios from "axios";
import Swal from 'sweetalert2';
import { getStaff } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apidecrypt, apiencrypt } from '../../helper';
import StyleDetailsViewModal from './StyleDetailsViewModal';
import AddIconWhiteGrey from '../../assets/images/dms/icons/addIconWhite.svg';

const EmailScheduleSettings = () => {
    const [getEmailScheduleTask, setgetEmailScheduleTask] = useState([]);
    const [assignEmailScheduleTask, setassignEmailScheduleTask] = useState([]);
    const [getListEmailScheduleTask, setgetListEmailScheduleTask] = useState([]);
    const [getDataEmailScheduleTask, setgetDataEmailScheduleTask] = useState([]);

    const [ styleDetailsViewModal, setStyleDetailsViewModal ] = useState();
    const [ heading, setHeading ] = useState();
    const [selectedOrders, setSelectedOrders] = useState([]);
   
    const toggleStyleDetailsViewModal = () => setStyleDetailsViewModal(!styleDetailsViewModal);
    const { t } = useTranslation();
  const [emailschudualsettingsreq, setEmailschudualsettingsreq] = useState(false);
     
    useEffect(() => {
       
        axios.get(ServerUrl + "/get-emailscheduletask")
            .then((response) => {
                response.data = apidecrypt(response.data);
                setgetEmailScheduleTask(response.data.data);
            });
            if (getStaff !== "Staff") {
                axios
                    .post(ServerUrl + "/get-email-settings", apiencrypt({
                        companyId: getLoginCompanyId,
                        userId: getLoginUserId,
                        staffId: getLoginStaffId,
                        workspaceId: getWorkspaceId,
                        emailSchedule: "",
                        days: "",
                    }))
                    .then((response) => {
                        response.data = apidecrypt(response.data);
                        if (response.data.data[0]!=null && response.data.data[0].is_consolidated_mail == 1) {
                            setEmailschudualsettingsreq(true);
                          }
                        var Dayvl = [];
                        var brl = [];
                        response.data.data.map((v) => {
                            // overallCheckBoxHandler(v.email_schedule_task_id)
                              var br=[]
                            var taskId = v.email_schedule_task_id;
                            var dayv = v.days;
                            var dayvv = dayv.split(",");
                            // dayvv.map((d) => {
                                //     var dayJoin = (d + "_" + taskId);
                                //     var t = document.getElementById(d + "_" + taskId).checked;
                                //     if(t==true){
    
                                //         Dayvl.push(dayJoin);
                                //     }
                                // });

                            if( dayvv.length == 7 ){
                                document.getElementById("chkAll_"+taskId).checked = true
                            }

                            dayvv.map((d) => {
                                var dayJoin = d + "_" + taskId;
                                Dayvl.push(dayJoin);
                                
                            });
                           
                            br.push(taskId)
                            br.push(dayvv)
                            brl.push(br)
                            
    
                        });
    
                        setassignEmailScheduleTask(brl)
                    
                        setgetListEmailScheduleTask(Dayvl);
                        let getNofiySetting = {};
                        getNofiySetting["companyId"] = getLoginCompanyId;
                        getNofiySetting["userId"] = getLoginUserId;
                        getNofiySetting["staffId"] = getLoginStaffId;
                        getNofiySetting["workspaceId"] = getWorkspaceId;
                        getNofiySetting["emailSchedule"] = Dayvl;
                        setgetDataEmailScheduleTask(getNofiySetting);
                    });
            }
        else {
            axios
                .post(ServerUrl + "/get-staff-email-settings", apiencrypt({
                    companyId: getLoginCompanyId,
                    userId: getLoginUserId,
                    staffId: getLoginStaffId,
                    workspaceId: getWorkspaceId,
                    emailSchedule: "",
                    days: "",
                }))
                .then((response) => {
                    response.data = apidecrypt(response.data);
                    if (response.data.data[0]!=null && response.data.data[0].is_consolidated_mail == 1) {
                        setEmailschudualsettingsreq(true);
                      }
                    var Dayvl = [];
                    var brl = [];
                    response.data.data.map((v) => {
                        var br=[]
                        var taskId = v.email_schedule_task_id;
                        var dayv = v.days;
                        var dayvv = dayv.split(",");
                        dayvv.map((d) => {
                            var dayJoin = d + "_" + taskId;
                            Dayvl.push(dayJoin);
                            if( dayvv.length == 7 ){
                                document.getElementById("chkAll_"+taskId).checked = true
                            }

                            dayvv.map((d) => {
                                var dayJoin = d + "_" + taskId;
                                Dayvl.push(dayJoin);
                                
                            });
                           
                            br.push(taskId)
                            br.push(dayvv)
                            brl.push(br)
                        });
                    });
                    setassignEmailScheduleTask(brl)
                    setgetListEmailScheduleTask(Dayvl);
                    let getNofiySetting = {};
                    getNofiySetting["companyId"] = getLoginCompanyId;
                    getNofiySetting["userId"] = getLoginUserId;
                    getNofiySetting["staffId"] = getLoginStaffId;
                    getNofiySetting["workspaceId"] = getWorkspaceId;
                    getNofiySetting["emailSchedule"] = Dayvl;
                    setgetDataEmailScheduleTask(getNofiySetting);
                });
        }
    }, []);

    const onCheckTaskAll = (e) => {
        const dayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var chkId = e.target.value;
        var t = document.getElementById("chkAll_" + e.target.value).checked;
        dayArray.forEach((day) => {
            document.getElementById(day + "_" + chkId).checked = t;
        })
        onChangehandler();
    }
    const overallCheckBoxHandler = ( id ) => {
        var overallCheckedStatus = [];
        const dayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            dayArray.forEach((day) => {
                var t = document.getElementById(day + "_" + id).checked;
                if( t == false ){
                    document.getElementById("chkAll_" + id).checked = false
                }
                if( t == true ){
                    overallCheckedStatus.push(t);
                    if( overallCheckedStatus.length == 7 ){
                        document.getElementById("chkAll_" + id).checked = true
                    }
                }
            }) 
            
    };
    // const onChangehandler = (e) => {
    //     const dayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    //     var getSchTask = [];
    //     getEmailScheduleTask.forEach((item) => {
    //         var getDayArray = [];
    //         dayArray.forEach((day) => {
    //             var t = document.getElementById(day + "_" + item.id).checked;
    //             if (t == true) {
    //                 getDayArray.push(day)
    //             }
    //         })
    //         if (getDayArray.length > 0) {
    //             var ar = [];
    //             ar.push(item.id)
    //             ar.push(getDayArray)
    //             getSchTask.push(ar)
    //         }
    //     })
    //     setassignEmailScheduleTask(getSchTask);
    // }
    const onChangehandler = (e) => {
        const dayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var getSchTask = [];
        getEmailScheduleTask.forEach((item) => {
            var getDayArray = [];
            dayArray.forEach((day) => {
                var t = document.getElementById(day + "_" + item.id).checked;
                if (t == true) {
                    getDayArray.push(day)
                }
            })
            if (getDayArray.length > 0) {
                var ar = [];
                ar.push(item.id)
                ar.push(getDayArray)
                getSchTask.push(ar)
            }
        })
        setassignEmailScheduleTask(getSchTask);
    }
    //**************************Emailschedulesettings checkbox save button validation****************************//
    const submitForm = (e) => {
        
        
            const dayArray = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            var getSchTask = [];
            getEmailScheduleTask.forEach((item) => {
                var getDayArray = [];
                dayArray.forEach((day) => {
                    var t = document.getElementById(day + "_" + item.id).checked;
                    if (t == true) {
                        getDayArray.push(day)
                    }
                })
                if (getDayArray.length > 0) {
                    var ar = [];
                    ar.push(item.id)
                    ar.push(getDayArray)
                    getSchTask.push(ar)
                }
            })
            setassignEmailScheduleTask(getSchTask);
     
        let getNofiySetting = {};
        getNofiySetting["companyId"] = getLoginCompanyId;
        getNofiySetting["userId"] = getLoginUserId;
        getNofiySetting["staffId"] = getLoginStaffId;
        getNofiySetting["workspaceId"] = getWorkspaceId;
        getNofiySetting["emailSchedule"] = getSchTask;
        getNofiySetting["consolidated_mail"] = emailschudualsettingsreq == true ? 1 : 0;
        if (getStaff !== "Staff") {
            axios
                .post(ServerUrl + "/email-settings", apiencrypt(getNofiySetting))
                .then((response) => {

                    Swal.fire({
                        title: t("emailScheduleSettings"),
                        text: t("savedSuccessfully"),
                        icon: "success",
                        timer: 5000,
                        button: t("okLabel"),
                        confirmButtonColor: '#009688',
                    })
                });
        }
        else {
            axios
                .post(ServerUrl + "/staff-email-settings", apiencrypt(getNofiySetting))
                .then((response) => {

                    Swal.fire({
                        title: t("emailScheduleSettings"),
                        text: t("savedSuccessfully"),
                        icon: "success",
                        timer: 5000,
                        button: t("okLabel"),
                        confirmButtonColor: '#009688',
                    })
                });
        }
    }

    const getSchTaskDays = (e) => {
        var t = getListEmailScheduleTask;

        var index = t.indexOf(e)
        if (index !== -1) {
            return true;

        } else {
            return false;
        }
    }

    const selectedOrdersApiCall = ( headingType ) => {
        axios
        .post(ServerUrl + "/get-email-settings-order-ids", apiencrypt({
            companyId: getLoginCompanyId,
            userId: getLoginUserId,
            staffId: getLoginStaffId,
            workspaceId: getWorkspaceId,
            task_id: headingType == "Task Report" ? 4 : 5,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          if(  response.data.status_code == 200 ){
              setSelectedOrders( response.data.data.length > 0 ? response.data.data[0].order_ids : "");
              toggleStyleDetailsViewModal();
          }
        });
    };

    return (
        <Fragment>
            <Col md="12">
                <Form>
                    <Card className="shadow shadow-md">
                        <Row className='m-b-20'>
                            <Col>
                                <HeaderCard  title={t("emailScheduleSettings")} />
                            </Col>
                            <Col  style={{ marginRight: '12px' }}>
            <span className="m-t-20" style={{ float: "right" }}> 
              

                        <Media key={1}>
                                    <Label className="col-form-label m-r-10">{t("consolidatedMail")}</Label>
                                    <Media body className="text-end switch-sm icon-state">
                                        <Label className="switch">
                                            <Input type="checkbox" 
                                            value={emailschudualsettingsreq} checked={!!emailschudualsettingsreq}
                                            onChange={(e) => { setEmailschudualsettingsreq(prevCheck => !prevCheck) }} 
                                            /><span className="switch-state" ></span>
                                        </Label>
                                    </Media>
                                </Media>
            </span>
          </Col>
                        </Row>
                        <CardBody className='p-0'>
                            <div className="table-responsive">
                                <Table >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f8f8f8" }} className="lightGrayBgColor">
                                            <th id="check" style={{ border:"none" }} ></th>
                                            {/* <th className='text-center' scope="col" style={{ border:"none" }} >{t('selectAllNone')}</th> */}
                                            <th className='text-center' scope="col" style={{ border:"none" }} >{t('selectAllNone')}</th>
                                            <th className='text-center' scope="col"style={{ borderLeft:"1px solid #B4B4B44F",borderRight:"1px solid #B4B4B44F" }}>
                                                {t('sunDay')}</th>
                                            <th className='text-center' scope="col" style={{borderRight:"1px solid #B4B4B44F" }}>{t('monDay')}</th>
                                            <th className='text-center' scope="col" style={{borderRight:"1px solid #B4B4B44F" }}>{t('tuesDay')}</th>
                                            <th className='text-center' scope="col" style={{ borderRight:"1px solid #B4B4B44F" }}>{t('wedDay')}</th>
                                            <th className='text-center' scope="col" style={{borderRight:"1px solid #B4B4B44F" }}>{t('thursDay')}</th>
                                            <th className='text-center' scope="col" style={{ borderRight:"1px solid #B4B4B44F" }}>{t('friDay')}</th>
                                            <th className='text-center' scope="col" style={{ borderRight:"1px solid #B4B4B44F" }}>{t('satDay')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            getEmailScheduleTask.map((item) =>
                                                <tr key={item.id}>
                                                    <td style={{ backgroundColor: "#f8f8f8" ,border:"none",paddingLeft:"30px"}}>
                                                        {t(item.name)}
                                                        {/* </td> */}
                                                       {/* <td style={{ backgroundColor: "#f8f8f8",border:"none"}} className='text-center'>  */}
                                                       {(item.id == 4) || (item.id == 5) ?
                                                            <img src={AddIconWhiteGrey} className="cursor-pointer m-l-10" width="25px"
                                                                onClick={(e) => { 
                                                                    item.id == 5 ? setHeading("Production Report") : item.id == 4 ? setHeading("Task Report") : "" , 
                                                                    selectedOrdersApiCall( item.id == 4 ? "Task Report" : "Production Report" )
                                                                }}
                                                            />   
                                                        :
                                                             "" }
                                                    </td>
                                                    <td style={{ backgroundColor: "#f8f8f8",border:"none" }} className='text-center'>
                                                        {/***************** / Select ALL/ none  *****************/}
                                                        <Input className="checkbox_animated" id={"chkAll_" + item.id} type="checkbox" value={item.id} onChange={(e) => onCheckTaskAll(e, "chkAll_" + item.id)} />
                                                        {/*******************************************************/}
                                                    </td>
                                                    <td className='text-center' style={{ border:"1px solid #B4B4B44F" }}>
                                                        {getSchTaskDays("Sun_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Sun_" + item.id} name="Sun" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}
                                                            onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            :
                                                            <Input className="checkbox_animated Emailschsetting" id={"Sun_" + item.id} name="Sun" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                        }
                                                    </td>
                                                    <td className='text-center' style={{ border:"1px solid #B4B4B44F" }} >
                                                        {getSchTaskDays("Mon_" + item.id) ?
                                                            <Input className="checkbox_animated " defaultChecked id={"Mon_" + item.id} name="Mon" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }}  />
                                                            :
                                                            <Input className="checkbox_animated " id={"Mon_" + item.id} name="Mon" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}   onChange={(e) => { overallCheckBoxHandler(item.id) }} 
                                                                />
                                                        }
                                                    </td>

                                                    <td className='text-center' style={{ border:"1px solid #B4B4B44F" }} >
                                                        {getSchTaskDays("Tue_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Tue_" + item.id} name="Tue" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            : <Input className="checkbox_animated Emailschsetting" id={"Tue_" + item.id} name="Tue" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}  onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                        }
                                                    </td>
                                                    <td className='text-center'  style={{ border:"1px solid #B4B4B44F" }}>
                                                        {getSchTaskDays("Wed_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Wed_" + item.id} name="Wed" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}  onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            :
                                                            <Input className="checkbox_animated Emailschsetting" id={"Wed_" + item.id} name="Wed" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}  onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                        }
                                                    </td>
                                                    <td className='text-center'  style={{ border:"1px solid #B4B4B44F" }}>
                                                        {getSchTaskDays("Thu_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Thu_" + item.id} name="Thu" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            :
                                                            <Input className="checkbox_animated Emailschsetting" id={"Thu_" + item.id} name="Thu" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }}/>
                                                        }
                                                    </td>
                                                    <td className='text-center' style={{ border:"1px solid #B4B4B44F" }}>
                                                        {getSchTaskDays("Fri_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Fri_" + item.id} name="Fri" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}  onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            :
                                                            <Input className="checkbox_animated Emailschsetting" id={"Fri_" + item.id} name="Fri" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                        }

                                                    </td>
                                                    <td className='text-center' style={{ border:"1px solid #B4B4B44F" }}>
                                                        {getSchTaskDays("Sat_" + item.id) ?
                                                            <Input className="checkbox_animated Emailschsetting" defaultChecked id={"Sat_" + item.id} name="Sat" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }}  onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                            :
                                                            <Input className="checkbox_animated Emailschsetting" id={"Sat_" + item.id} name="Sat" type="checkbox"
                                                            onClick={(e) => { overallCheckBoxHandler(item.id) }} onChange={(e) => { overallCheckBoxHandler(item.id) }} />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                        <Row>
                            <Col md="12">
                                <div className="m-t-10 m-b-15 text-end m-r-30">
                                    <Button className="btn btn-primary btn-sm"
                                        onClick={(e) => { submitForm(); }}>
                                        {t("save")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </Col>

            <StyleDetailsViewModal 
                heading={heading}
                modal={styleDetailsViewModal}
                selectedOrders={selectedOrders}
                toggleStyleDetailsViewModal={toggleStyleDetailsViewModal}
            />
        </Fragment>
    );
}
export default EmailScheduleSettings;

//**************************Code by : SaravanaKumar****************************//