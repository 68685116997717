import React, { useState,useEffect } from "react";
import {
  Form, Label, Card, ModalBody, Col, Row, Input, InputGroup,
  InputGroupText, Modal, FormGroup, ModalHeader, Table,Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { ServerUrl } from "../../../Constant";
import { apidecrypt, apiencrypt } from '../../../helper';

const ViewVendorInfoModal = ({ modal, toggle, selectedViewVendorDetails}) => {
const { t } = useTranslation();
var contact_det = [];

return (
    <Modal 
        backdrop="static" isOpen={modal} 
        toggle={toggle} centered 
        className="Addvendormodal datainputmodal">
        <ModalHeader>
        <Row> 
            <Col className="m-t-10">
                {t("viewVendorInfo")}
            </Col> 
            <Col className="text-end cursor-pointer">
                <span title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggle}>X</span>
            </Col>
        </Row> 
        </ModalHeader>
        <ModalBody>
            <div className="table-bordered" style={{ borderLeft: '1px solid #E5EDEF', borderRight: '1px solid #E5EDEF' }}>
                <Table className="table-responsive">
                
                { selectedViewVendorDetails.length > 0 
                    ?
                    selectedViewVendorDetails.map(( mapData) => 
                        {
                            contact_det = mapData.contact_details
                            return (
                                <>
                                    <tr style={{ borderLeftStyle: 'none' }}>
                                        <td style={{width:"25%"}}>{t("vendorName")}</td>
                                        <td style={{border:"none"}}>{mapData.vendor_name}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"25%"}}>{t("website")}</td>
                                        <td style={{border:"none"}}>{mapData.website}</td>
                                    </tr>
                                    <tr>
                                        <th style={{border:"none"}}>{t("address")}</th>
                                    </tr>
                                    <tr>
                                        <td style={{width:"25%"}}>{t("headOffAddress")}</td>
                                        <td style={{border:"none"}}>{mapData.office_address}</td>
                                    </tr>
                                    <tr>
                                        <td style={{width:"25%"}}>{t("factAddress")}</td>
                                        <td style={{border:"none"}}>{mapData.factory_address}</td>
                                    </tr>
                                    <tr> 
                                        <th style={{border:"none"}}>{t("contactDtls")}</th>
                                    </tr>
                                </> 
                                )
                        })
                    :
                        ""}
                
                    <tr>
                        <th className='m-l-5 '>{t("role")}</th>
                        <th>{t("name")}</th>
                        <th>{t("mobileNumber")}</th>
                        <th>{t("emailId")}</th>
                    </tr>

                    {
                        contact_det.length > 0 
                    ? 
                        contact_det.map(( mapData) => 
                                {
                                    return (
                                    <>
                                    {mapData.role=="M.D"
                                    ?
                                    <tr>                      
                                            <td>{mapData.role?mapData.role:"-"}</td>
                                            <td>{mapData.name?mapData.name:"-"}</td>
                                            <td>{mapData.number?mapData.number:"-"}</td>
                                            <td>{mapData.email?mapData.email:"-"}</td>
                                            </tr>:""}
                                            {mapData.role=="sales"
                                    ?
                                    <tr>                      
                                            <td>{mapData.role?mapData.role:"-"}</td>
                                            <td>{mapData.name?mapData.name:"-"}</td>
                                            <td>{mapData.number?mapData.number:"-"}</td>
                                            <td>{mapData.email?mapData.email:"-"}</td>
                                            </tr>:""}
                                            {mapData.role=="Accounts"
                                    ?
                                    <tr>                      
                                            <td>{mapData.role?mapData.role:"-"}</td>
                                            <td>{mapData.name?mapData.name:"-"}</td>
                                            <td>{mapData.number?mapData.number:"-"}</td>
                                            <td>{mapData.email?mapData.email:"-"}</td>
                                            </tr>:""}
                                            {mapData.role=="Production"
                                    ?
                                    <tr>                      
                                            <td>{mapData.role?mapData.role:"-"}</td>
                                            <td>{mapData.name?mapData.name:"-"}</td>
                                            <td>{mapData.number?mapData.number:"-"}</td>
                                            <td>{mapData.email?mapData.email:"-"}</td>
                                            </tr>:""}
                                    </>
                        )
                    }
                        ) 
                    : 
                        ""
                    }
                        
                </Table>
            </div>
        </ModalBody>
    </Modal>
);

};

export default ViewVendorInfoModal;
//*********** Code by: Ramanan M ******************//