import React, { useState, useEffect } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Input, Label, Button
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import { Close, SaveChanges, ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { getLoginUserId, getLoginCompanyId, getWorkspaceId, getStaff, getLoginStaffId, getStaffPermission } from "../../Constant/LoginConstant"
import { apidecrypt, apiencrypt, PHPtoJSFormatConversion } from "../../helper";
const MainTaskDeleteModal = ({ modal, toggle, taskname, tasktitle, orderID,getTaskID, setTemplates, setTemplateData }) => {
    const [templatename, setTemplateName] = useState("");
    const [reason, setReason] = useState("");

    const { t } = useTranslation();
    let dataForSend = {};
    getStaff === "Staff" ? dataForSend = {
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        order_id: orderID,
        template_name: templatename,
        reason: reason,
        taskname: taskname,
        tasktitle: tasktitle,
        taskId:getTaskID
    } :
    dataForSend = {
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        order_id: orderID,
        template_name: templatename,
        reason: reason,
        taskname: taskname,
        tasktitle: tasktitle,
        taskId:getTaskID
    };

    useEffect(() => {
        if (!modal) {

            setValidErrors("");
        }
    });
    /* Validation Starts */
    const [errors, setValidErrors] = useState({});
    const validation = () => {
        let errors = {};

        if (!templatename.trim()) {
            errors.templatename = ("templateValidation")
        }
        // if (!reason.trim()) {
        //     errors.reason = ("reasonValidation")
        // }

        setValidErrors(errors);
        return errors;
    };
    /* Validation Ends */
    const onSaveHandle = () => {

        let retval = validation();
        if (Object.keys(retval).length == 0) {
            axios
            .post(ServerUrl + "/delete-task-data", apiencrypt(dataForSend))
            .then((response) => {
              response.data = apidecrypt(response.data)
              if (response.data.status_code === 200) {
                Swal.fire({
                    title: t("updatedSuccessfully"),
                    icon: "success",
                    button: t("okLabel"),
                    allowOutsideClick: false,
                    timer:5000,
              }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/taskupdate";
                }
                window.location.href = "/taskupdate";
            });
             
            }else   if (response.data.status_code === 600) {
                Swal.fire({
                title:t(response.data.error),
                icon: "error",
                button: t("okLabel"),
            });
            }
            else   if (response.data.status_code === 401) {
                Swal.fire({
                title:t(response.data.error.template_name)||
                t(response.data.error.company_id)||
                t(response.data.error.user_id)||
                t(response.data.error.staff_id)||
                t(response.data.error.order_id)||
                t(response.data.error.reason)||
                t(response.data.error.taskname)||
                t(response.data.error.tasktitle)||
                t(response.data.error.taskId) ,
                icon: "warning",
                button: t("okLabel"),
            });
            }
              toggle(false);
            
            //   refreshData();
              });
        }
    };

    // const refreshData = () => {

        // var dataToSendAtStarting;
        // getStaff === "Staff" ? dataToSendAtStarting = {
        //     company_id: getLoginCompanyId,
        //     workspace_id: getWorkspaceId,
        //     staff_id: getLoginStaffId,
        //   } : dataToSendAtStarting = {
        //     company_id: getLoginCompanyId,
        //     workspace_id: getWorkspaceId
        //   }

    //    axios.post(ServerUrl + "/get-order-template", apiencrypt(dataToSendAtStarting)).then((response) => {
    //     response.data = apidecrypt(response.data);
    //     setTemplates(response.data.data);
    //   })

    //   let detailsToSendData;
    //   getStaff === "Staff" ? detailsToSendData = {
    //     company_id: company_id,
    //     workspace_id: workspace_id,
    //     staff_id: getLoginStaffId,
    //     order_id: document.getElementById("style").value
    //   } : detailsToSendData = {
    //     company_id: company_id,
    //     workspace_id: workspace_id,
    //     order_id: document.getElementById("style").value
    //   }

    //   await axios.post(ServerUrl + "/get-task-data", apiencrypt(detailsToSendData)).then((response) => {
    //     response.data = apidecrypt(response.data)
    //     setTemplateData(response.data.data);
    //     setToShowDetails(1);
    //   });

    //   await axios
    //   .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
    //   .then((response) => {
    //     response.data = apidecrypt(response.data);
    //     var dates = response.data.data[0].delivery_dates;
    //     var splitId = dates.split(",");
    //     setDelDate(splitId)
    //   })
    // };

    return (
        <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader>{t("delete")} : {tasktitle} - {taskname}</ModalHeader>
            <ModalBody>
                <Label className="col-form-label" for="recipient-name">
                    {t("templateNameLabel")} <sup className="font-danger">*</sup>
                </Label>
                <Input
                    className="form-control"
                    type="text"
                    id="templatename"
                    placeholder={t("templateName")}
                    onChange={(e) => { setTemplateName(e.target.value) }}
                />
                {errors.templatename && (
                    <><span className="error-msg">{t(errors.templatename)}</span><br /></>
                )}
                <Label className="col-form-label" for="recipient-name">
                    {t("reasonForDel")}
                </Label>
                <Input
                    className="form-control"
                    type="text"
                    id="reason"
                    placeholder={t("reason")}
                    onChange={(e) => { setReason(e.target.value) }}
                />
                {errors.reason && (
                    <><span className="error-msg">{t(errors.reason)}</span><br /></>
                )}
            </ModalBody>


            <ModalFooter>
                <Button className="btn secondaryBtn" onClick={toggle}>{t("close")}</Button>
                <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
                    {t("save")}
                </Btn>
            </ModalFooter>
        </Modal>
    );
};

export default MainTaskDeleteModal;
/* Code By : Saravanan 07-07-2023 */