import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Table, CardBody } from 'reactstrap';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { ServerUrl } from '../../../Constant/index';
import axios from 'axios';
import noData from "../../../assets/images/dms/nodata.png"
import { getLoginCompanyId, getLoginUserId, getWorkspaceId, 
  getLoginStaffId } from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { apiencrypt, apidecrypt } from '../../../helper';
import downarrow_animation from '../../../assets/images/dms/icons/downarrow_animation.gif';

const TaskdelayTable = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [toggleOpen, setToggleOpen] = useState(0);
  const [delaytask, settopdelaytask] = useState([]);
  const [isOpen, setIsOpen] = useState(0);
  const { t } = useTranslation();
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));

  useEffect(() => {   
      axios
        .post(ServerUrl + "/get-top-delay-tasks", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          settopdelaytask(response.data.data);
        })
  },[])
  
  const getSmiley = (noOfDays) => {
    let date = Math.abs(noOfDays);
     return (<td className="font-danger f-w-600 text-center ">
        <img className="p-0 img-30" src={downarrow_animation} /> &nbsp;{date}  </td>);
  };

  return (
    <Fragment>
      <Col xs="12" sm="12" md="12" lg="12" xl="6" style={{marginTop:"6px"}} 
        className="box-col-12  m-b-25">
          <div className="default-according" id="accordion">
            <Card className="borderbottomnone collopseradise" >
              <CardHeader style={{  borderBottom: 'none', borderRadius: '0.75rem' }}>
                <p className=" p-l-5  f-18 f-w-600">{t("top5DelayedTasks")}</p>
              </CardHeader>
             
                {delaytask.length > 0 ?
                  <CardBody className="p-0 "style={{ height: "350px", borderRadius: '0.75rem'}} >
                    <ScrollBar options={{ suppressScrollX: true }}>
                      <div className="table-responsive">
                        <Table className="tableRowCol ">
                          <tr style={{ fontSize: "13px", backgroundColor: '#F4F4F4' }}>
                            <th style={{ padding: "0.3rem",paddingLeft:"1.5rem" }}  
                              className='text-uppercase'>{t("styleNo")}</th>
                            <th  className='text-uppercase'> {t("taskName")} </th>
                            <th  className='text-uppercase' > {t("pic")} </th>
                            <th className="text-center text-uppercase"> {t("noofdaysdelays")} </th>
                          </tr>

                          {delaytask.map((tasks, index) =>
                          (
                            <>
                              <tr className={`${ index+1 !== delaytask.length ? "removeBottomBorder" : ""}`}>                                
                                <td  className='cursor-pointer'style={{ padding: "0.3rem",paddingLeft:"1.5rem" }}  
                                    title={tasks.order_no + " " + "/" + " " + tasks.style_no} 
                                    tooltip={tasks.order_no + " " + "/" + " " + tasks.style_no} 
                                    alt={tasks.order_no + " " + "/" + " " + tasks.style_no} >  
                                    {tasks.style_no} </td>
                                <td> {tasks.task_title} </td>
                                <td> {tasks.staffName === null ? <>-</> : (tasks.staffName)} </td>
                                {tasks.noOfDays === null ? <>-</> : getSmiley(tasks.noOfDays, tasks.type)}
                              </tr>
                            </>
                          )
                          )}
                        </Table>
                      </div>
                    </ScrollBar>
                  </CardBody>
                   :
                  <>
                   <div  style={{ height: "104px" }}></div>
                    <div className="p-5 align-self-center media-body centerAlign"
                         style={{ height: "350px" }}>
                         <img src={noData} style={{ width: "200px" }} />
                         <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                    </div>
                  </>
                } 
             
            </Card>
          </div>
      </Col>
    </Fragment>
  );
};

export default TaskdelayTable;

//**************************Code by : Praveen****************************//