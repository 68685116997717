import React, { Fragment } from 'react';
import { Col, CardBody, Card, Container, Row, CardHeader } from 'reactstrap';
import dmslogtag from "../assets/images/dms/dms-log-with-tag.png";

const Termsandconditions = ({ selected }) => {

    return (
        <>
            <Fragment >
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="pb-0">
                                    <Row>
                                        <p><img src={dmslogtag} width="150" /></p>
                                    </Row>
                                </CardHeader>
                                <Col>
                                </Col>
                                <CardBody className='termsalign'>
                                    {/* <Nav className="nav nav-tabs nav-right" >
                                    <NavItem>
                                    <NavLink href="#javascript" className={RightTab === '1' ? 'active' : ''} 
                                    onClick={() => setRightTab('1')}>Monthly</NavLink>
                                    </NavItem>
                                    <NavItem >
                                    <NavLink href="#javascript" className={RightTab === '2' ? 'active' : ''} 
                                    onClick={() => setRightTab('2')}>Yearly</NavLink>
                                    </NavItem>
                                    </Nav> */}
                                    <h3>Terms And Conditions</h3><br></br>
                                    <span className='artichead'>Article 1 : Purpose</span> <br></br>
                                    This agreement is between Concept Art Technologies Co., Ltd. (hereinafter "our company") and companies (hereinafter "service users") that have entered into a usage contract for "ITOHEN -  Digital Merchandising System" (hereinafter "DMS"). The purpose is to establish the necessary matters for our company to provide DMS to users of this service.<br></br><br></br>
                                    <span className='artichead'>Article 2 : Changes to terms and service content, etc.</span> <br></br>
                                    The Company reserves the right to change these Terms at any time by notifying users of the Service at least one month in advance in a manner deemed appropriate by the Company. In this case, the charges and other terms of service will be subject to the changed terms of use.<br></br><br></br>
                                    <span className='artichead'>Article 3 : Special Agreement</span> <br></br>
                                    In addition to this Agreement, the Company may establish special agreements related to DMS and conclude special agreements with users of this service. If there are different provisions between the special agreement and these Terms, the special agreement shall be applied with priority.<br></br><br></br>
                                    <span className='artichead'>Article 4 : Prohibition of Transfer of Rights</span> <br></br>
                                    1.Users of this service may not transfer, lend, or pledge their rights and obligations related to DMS to a third party without our consent.<br></br><br></br>
                                    <span className='artichead'>Article 5 : Usage License</span> <br></br>
                                    1. Copyrights and other intellectual property rights related to all programs, software and documents that make up DMS belong to our company. This service user cannot dispute the attribution of this right in any situation.<br></br>
                                    2. The Company grants non-exclusive use of the DMS provided by the Company (including those newly provided by future version upgrades, etc.) to users of this service on the condition that they comply with these Terms. To do <br></br><br></br>
                                    <span className='artichead'> Article 6 : Prohibited Matters</span> <br></br>
                                    1.The following items are prohibited when using DMS. If any of the prohibited items apply, we may temporarily or permanently deny access to the service, erase user information, or delete data for any reason. In addition, we are not obligated to notify you of any countermeasures against prohibited matters, including emergency cases.<br></br>
                                    ・Acts that infringe on the property, privacy, portrait rights, etc. of a third party.<br></br>
                                    ・Acts that discriminate or slander a third party.<br></br>
                                    ・Acts that damage the credibility or honor of other users of this service, other third parties, or our company.
                                    ・Fraud or other criminal activity.<br></br>
                                    ・Falsification or deletion of information that can be used for DMS.<br></br>
                                    ・Disclosure of DMS information, user company information, passwords, etc. to the general public.<br></br>
                                    ・Transfer, lend, or license the right to use DMS to a third party.<br></br>
                                    ・Acts of impersonating a third party and using DMS.<br></br>
                                    ・Transmitting or posting virus programs or other harmful programs.<br></br>
                                    ・Reproduce DMS in whole or in part.<br></br>
                                    ・Disassemble, decompile, or otherwise reverse engineer and attempt to analyze or decipher all or part of the DMS.<br></br>
                                    ・Acts that interfere with the use or operation of equipment, equipment, etc. (including users' mobile phones) or DMS equipment of the Company or third parties.<br></br>
                                    ・Acts that violate laws and regulations or public order and morals, and that cause disadvantages to third parties.<br></br>
                                    ・Acts that hinder the operation and maintenance of our company's system or interfere with the provision of the system.<br></br>
                                    ・Acts that violate the provisions of this agreement.<br></br>
                                    ・Other acts equivalent to the above acts.<br></br>
                                    2. If the Company suffers damage due to the user of this service performing a prohibited act, the user of this service shall be able to claim compensation.<br></br><br></br>
                                    <span className='artichead'> Article 7 : Handling of data</span> <br></br>
                                    1. We will properly handle the personal information of users of this service based on our privacy policy.<br></br>
                                    2. Users of this service agree that their registration information and usage status information will be stored in our database and owned by our company. (Personal information shall be within the scope of the purpose of use stipulated in our privacy policy.)<br></br>
                                    3. The Company shall be able to freely use statistical information that cannot identify individuals.<br></br><br></br>
                                    <span className='artichead'>Article 8 : Expenses, etc. for equipment on the user side of this service</span> <br></br>
                                    Users of this service shall, at their own responsibility and expense, maintain the environment necessary for using DMS, including hardware, software, Internet connection lines, and security.<br></br><br></br>
                                    <span className='artichead'> Article 9 : Termination of Contract by Service User</span> <br></br>
                                    1. When the user of this service wishes to cancel all or part of the contract, he/she shall submit a document prescribed by the company to the company at least one month prior to the desired date of cancellation.<br></br>
                                    2. If the user of this service cancels in accordance with the preceding paragraph, we will not refund the usage fee that the user of this service has already paid to us.<br></br><br></br>
                                    <span className='artichead'>Article 10 : Cancellation of contract by our company</span> <br></br>
                                    1. If the service user has suspended the use of DMS pursuant to the provisions of Article 11 (Suspension of use of DMS), paragraph 1, and the fact remains unsolved, the Company may cancel the contract related to DMS. You must to do something before you go on. In that case, we will notify the service user in advance.<br></br>
                                    2. If the user of this service falls under any of the following items, our company shall be able to cancel the contract related to DMS without giving any notice. In addition, regardless of whether or not the service is cancelled, the service user will lose the benefit of time when any event occurs, and will immediately pay the DMS usage fee and all other obligations to the Company.<br></br>
                                    ・When there is a suspension of payment for yourself or when you fall into a state of insolvency<br></br>
                                    ・When there is a petition for the start of bankruptcy proceedings, civil rehabilitation proceedings, corporate reorganization proceedings, or special liquidation.<br></br>
                                    ・When receiving a disposition to suspend transactions at a clearinghouse, or when an order or notice of provisional seizure, preservative seizure or seizure for which you are the debtor is sent.<br></br><br></br>
                                    <span className='artichead'>Article 11 : Suspension of use of DMS</span> <br></br>
                                    1. The Company may suspend the use of all or part of DMS if the Service User falls under any of the following items.<br></br>
                                    ・When the DMS usage fee and other obligations are not paid even after the due date has passed.<br></br>
                                    ・When it turns out that you have made a statement that is contrary to the facts when applying for use of DMS<br></br>
                                    ・When the Company recognizes that there is a hindrance to the execution of our business.<br></br>
                                    ・Other violations of these Terms.<br></br>
                                    2. When we suspend the use of DMS pursuant to the provisions of the preceding paragraph, we will notify the service user of the reason, the date and period of suspension in advance.<br></br>
                                    3. The Company shall not be liable for any damages incurred by the user of this service, third parties, or the user of this service due to suspension of use of DMS based on paragraph 1.<br></br><br></br>
                                    <span className='artichead'>Article 12 : Suspension of use of DMS</span> <br></br>
                                    1. The Company may suspend the use of DMS in whole or in part without notifying the user of the Service if any of the following items apply.<br></br>
                                    ・When performing regular or urgent maintenance of the DMS system<br></br>
                                    ・When DMS cannot be provided due to fire, power outage, accident, etc.<br></br>
                                    ・When DMS cannot be provided due to a natural disaster such as an earthquake, eruption, flood, or tsunami.<br></br>
                                    ・When DMS cannot be provided due to war, upheaval, riot, riot, labor dispute, etc.<br></br>
                                    ・Unexpected technical problems<br></br>
                                    ・In addition, when the Company determines that it is necessary to temporarily suspend DMS for operational or technical reasons.<br></br>
                                    2. Notwithstanding the provisions of the preceding paragraph, if the Company plans to suspend the use of all or part of DMS, the Company will notify the Service User in a manner that the Company deems appropriate. However, this is not the case in urgent and unavoidable cases.<br></br><br></br>
                                    3. The Company shall not be liable for any damages caused to the user of this service or a third party due to the suspension of DMS.<br></br><br></br>
                                    <span className='artichead'> Article 13 : Termination of DMS provision </span> <br></br>
                                    1. The Company shall be able to terminate the provision of this service by notifying the user of this service one month before the termination of DMS, and the user of this service shall accept this.<br></br>
                                    2. The Company shall not be liable for any damages caused to the user of this service or a third party due to the termination of DMS based on the preceding paragraph.<br></br><br></br>
                                    <span className='artichead'>Article 14 : Disclaimer </span> <br></br>
                                    1. In providing DMS, the Company has an intention to prevent service defects, service suspensions, information changes, service terminations, and damages to service users or third parties caused by them. We will not be held responsible unless otherwise. In the event of intentional or gross negligence, the user shall be liable for the amount paid by the user as the DMS usage fee.<br></br>
                                    2. The Company shall not be held responsible for any damages incurred by users of this service or third parties due to insufficient management of passwords, errors in use, use by third parties, etc.<br></br>
                                    3. The Company shall not be held responsible for any damages incurred by a third party if the user of this service violates Article 6 (Prohibited items).<br></br><br></br>
                                    <span className='artichead'>Article 15 : Notice to Service Users </span> <br></br>
                                    1. Notifications from the Company to users of the Service shall be made in accordance with the provisions of this Article.<br></br>
                                    In the event of any of the following reasons, the Company will notify the Service User by e-mail or by a method deemed appropriate by the Company.<br></br>
                                    ・Changes to the content of these Terms<br></br>
                                    ・Provision of new functions<br></br>
                                    ・Change of usage fee<br></br>
                                    ・Changes to conditions for providing DMS<br></br>
                                    ・Other matters deemed necessary by the Company<br></br>
                                    2. Unless otherwise specified, notices from the Company to users of the Service shall take effect on the date the Company notifies them pursuant to the preceding paragraph.<br></br><br></br>
                                    <span className='artichead'>Article 16 : Payment Obligation of Service Users</span> <br></br>
                                    1. Based on the invoice notified by the Company, the service user shall pay the usage fee by the method specified by the Company by the date specified on the invoice (hereinafter "payment date"). In addition, all transfer fees and other expenses necessary for payment shall be borne by the user of this service.<br></br>
                                    2. If the usage fee is not paid even after the due date has passed, a late payment charge of 20.0% per year for the period from the day after the due date to the day before the payment date will be charged. shall pay. In addition, all transfer fees and other expenses necessary for payment shall be borne by the user of this service.<br></br><br></br>
                                    <span className='artichead'>Article 17 : Confidentiality</span> <br></br>
                                    The Company shall not divulge to a third party any confidential information of the Contractor that has come to know regarding the provision of DMS. However, this does not apply if there is a valid reason such as when it is necessary to provide DMS, or if it is based on laws and regulations.<br></br><br></br>
                                    <span className='artichead'>Article 18 : Outsourcing to Third Parties</span> <br></br>
                                    The Company shall be able to subcontract all or part of the work based on the Terms of Use to a partner company that has a business tie-up with the Company after notifying the user of this service in advance. In addition, when subcontracting, we will make the partner company bear the same obligations as our company, and we will guarantee that the partner company will comply with these obligations.<br></br><br></br>
                                    <span className='artichead'>Article 19 : Self-responsibility principle</span> <br></br>
                                    If the user of this service causes damage to a third party, regardless of whether it is domestic or overseas, or is notified of a claim by a third party due to the use of DMS, after the contract period and contract cancellation of DMS Regardless, you shall handle and resolve it at your own responsibility and expense. The same shall apply when the user of this service suffers damage from a third party in connection with the use of DMS, or when notifying a third party of a claim.<br></br>
                                    If the service user causes damage to the company due to intentional or negligence, the company may claim compensation for the damage from the service user regardless of the DMS contract period or after the contract is terminated. increase.<br></br><br></br>
                                    <span className='artichead'>Article 20 : Severability</span> <br></br>
                                    If any provision of this Agreement is held invalid, the remaining provisions of this Agreement shall continue in full force and effect.<br></br><br></br>
                                    <span className='artichead'>Article 21 : Governing Law</span> <br></br>
                                    Japanese law shall apply to the formation, effectiveness, performance and interpretation of this agreement.<br></br><br></br>
                                    <span className='artichead'>Article 22 : Exclusive Agreed Jurisdiction</span> <br></br>
                                    The Tokyo District Court shall be the exclusive agreed jurisdictional court for disputes related to these Terms.<br></br><br></br>
                                    <span className='artichead'>Article 23 : Consultation</span> <br></br>
                                    If a problem arises between the user of this service and the user of DMS, the user of this service and the Company shall discuss in good faith and strive to resolve the problem.<br></br><br></br>
                                    <span className='artichead' style={{ textalign: "Center" }}>Enacted and enforced on September 1, 2019</span> <br></br>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );
};

export default Termsandconditions;