import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader, Label, } from "reactstrap";
import { Close, SaveChanges, ServerUrl } from "../../Constant/index";
import { Btn } from "../../AbstractElements";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apidecrypt, apiencrypt,capitalizeFirstLowercaseRest } from "../../helper";
const FormAddRoleModal = ({
  modal,
  toggle,
  company_id,
  workspace_id,
  roles,
}) => {
  const [roleName, setRoleName] = useState();
  const { t } = useTranslation();
  const onSaveHandle = () => {
    axios
      .post(ServerUrl + "/create-new-role", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        name: roleName,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("roleAddedSuccessfully"),
            // text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            timer:5000,
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-roles", apiencrypt({
                  company_id: company_id,
                  workspace_id: workspace_id,
                }))
                .then((response) => {
                  response.data =apidecrypt(response.data)
                  roles(response.data.data);
                });
            }
            setTimeout(() => {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-roles", apiencrypt({
                  company_id: company_id,
                  workspace_id: workspace_id,
                }))
                .then((response) => {
                  response.data =apidecrypt(response.data)
                  roles(response.data.data);
                });
            }, 100);
          });
        }
        /* To show error if the Role is already present*/
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t(response.data.errors.name),
            // text: t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
          });
        }
      });
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader> {t("addNewRole")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("addRole")}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setRoleName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn", onClick: toggle }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddRoleModal;
//**************************Code by : Rithanesh****************************//

