import React , {useState}from "react";
import { Modal,
  ModalBody,
  ModalHeader, Row, Card, Col,  TabContent, TabPane} from "reactstrap";
  import { H6} from '../../AbstractElements';
import { PHPtoJSFormatConversion } from '../../helper';
import GreenCutting from '../../../src/assets/images/dms/icons/green_cutting.svg' 
import GreenSewing from '../../assets/images/dms/icons/green_sewing.svg'
import GreenPacking from '../../assets/images/dms/icons/green_packing.svg'

import OrangeCutting from '../../../src/assets/images/dms/icons/orange_cutting.svg';
import OrangeSewing from '../../assets/images/dms/icons/orange_sewing.svg';
import OrangePacking from '../../assets/images/dms/icons/orange_packing.svg';

import RedCutting from '../../../src/assets/images/dms/icons/red_cutting.svg';
import RedSewing from '../../assets/images/dms/icons/red_sewing.svg';
import RedPacking from '../../assets/images/dms/icons/red_packing.svg';

import { useTranslation } from 'react-i18next';
import Moment from 'moment';

const SkuwiseProgressModal = ({ modal, toggle, 
  skuData, sewPackSkuData, typeOfProduction,
  colors, sizes, productionCutDateDetails, productionSewPackDateDetails
}) => 
  {

    const { t } = useTranslation();
    
    // const cutTotalQty = () => {
    //   let totalSum = 0;
    //   {skuData.map((colorData) => {
    //     totalSum += colorData.total_quantity;
    //   })}
      
    // return totalSum;
    // }

    // const cutTotalUpdatedQty = () => {
    //   let totalSum = 0;
    //   {skuData.map((colorData) => {
    //     totalSum += colorData.updated_quantity;
    // })}

    // return totalSum;
    // }

    // const sewPackTotalQty = () => {
    //   let totalSum = 0;
    //   {sewPackSkuData.map((colorData) => {
    //     totalSum += colorData.total_quantity;
    //   })}

    //   return totalSum;
    // }

    // const sewPackTotalUpdatedQty = () => {
    //   let totalSum = 0;
    //   {sewPackSkuData.map((colorData) => {
    //     totalSum += colorData.updated_quantity;
    //   })}

    //   return totalSum;
    // }

    /*************** UPDATED QUANTITY (BASED ON COLOR, SIZE) ******************************/
    const getColorSizeUpdatedQty=(array,colorid,sizeid)=>
    {
      let aryqty =[];
     if( array.length > 0){
         array.map((obj) => {
              let tqty= (obj.color_id === colorid &&  obj.size_id===sizeid);
              if(tqty==true){
               aryqty.push(obj.updated_quantity)     
              }
           });  
           return aryqty;
     } else{
        return 0;
     }
    }
    const [ totalQty, setTotalQty ] = useState("");

    let currentDate = Moment().format('YYYY-MM-DD');
    return (
      <Modal 
      isOpen={modal} 
      toggle={toggle} 
      backdrop="static"
      centered 
      className="datainputmodal">

      { typeOfProduction === 'Cut' ?
        <>
          <ModalHeader className="text-left m-l-20" 
                style={{  borderBottom: 'none' }}>  
              <Row>
                <Col className="m-t-5">
                { productionCutDateDetails.isCutAccomplished == 0
                  ?
                  (currentDate > productionCutDateDetails.cutEndDate) ? 
                  <>
                    <div className="media">
                        <img src={RedCutting}  width="45px" />
                        <div
                            className="m-l-10 media-body label_align2 m-t-5 ">
                            <span 
                            style={{ color: '#EE335E',
                            fontWeight: '500', 
                            fontSize: '15px' 
                            }} 
                            > {t("cutting")} </span>
                            <div className="f-12" 
                                style={{color: '#EE335E', 
                                        fontWeight: 'normal',
                                        fontSize: '10px'
                                        }}>
                                <i 
                                className="fa fa-circle" 
                                style={{color:'#EE335E',
                                        fontSize: '10px'
                                        }}>
                                </i>
                                <span 
                                style={{ 
                                }}>
                                &nbsp;
                                    {t("delay")}
                                </span> 
                            </div>
                        </div>
                    </div>
                  </>       
                  :
                  <>
                  <img src={GreenCutting}  width="45px" />
                  <strong className="m-l-15 f-w-500" 
                  style={{ color: '#009688' }}>
                  {t("cutting")} </strong>
                  </>
                  :
                  (productionCutDateDetails.cutAccomplishedDate <= productionCutDateDetails.cutEndDate) ? 
                  <>
                    <div className="media">
                        <img src={GreenCutting} width="45px" />
                        <div className="m-l-5 media-body label_align2">
                            <H6> {t("cutting")} </H6>
                            <div className="f-12" 
                                style={{color: '#009688', 
                                        fontWeight: 'normal',
                                        fontSize: '10px'
                                        }}>
                                <i 
                                className="fa fa-circle" 
                                style={{color:'#009688',
                                        fontSize: '10px'
                                        }}>
                                </i>
                                <span 
                                style={{ 
                                }}>
                                &nbsp;
                                    {t("completed")}
                                </span> 
                            </div>
                        </div>
                    </div>
                  </>
                  :
                  <>
                    <div className="media">
                              <img src={OrangeCutting} width="45px" />
                              <div
                                  className="m-l-10 media-body label_align2 m-t-5 ">
                                  <span 
                                  style={{ color: '#E69020',
                                  fontWeight: '500', 
                                  fontSize: '15px' 
                                  }} 
                                  > {t("cutting")} </span>
                                  <div className="f-12" 
                                      style={{color: '#E69020', 
                                              fontWeight: 'normal',
                                              fontSize: '10px'
                                              }}>
                                      <i 
                                      className="fa fa-circle" 
                                      style={{color:'#E69020',
                                              fontSize: '10px'
                                              }}>
                                      </i>
                                      <span 
                                      style={{ 
                                      }}>
                                      &nbsp;
                                          {t("delayComplete")}
                                      </span> 
                                  </div>
                              </div>
                    </div>
                  </>
                  }             
                </Col>
                <Col>
                  <div className="f-right m-t-10 m-r-5" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ toggle()}}>
                    X
                  </div>
                </Col>
              </Row>
          </ModalHeader>

          <ModalBody>
                <Card>
                  <Row className="g-12">
                  <div className="table-responsive">
                          <table className="table">
                            <thead>
                                <tr>
                                <th className="text-center middle" scope="col">
                                {t("color/sizeLabel")}
                                </th>
                               
                                {sizes.map(( data) => 
                                    {
                                    return (
                                        <th key={data.size_id} className="text-center middle">
                                            {data.sizeName}
                                        </th>   
                                    )
                                    }
                                )} 
                                <th className="text-center middle">
                                {t("totalLabel")}
                                </th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(colors)
                                .map( (colorData ) => 
                                {
                                    let totqty=0;
                                return (
                                    <tr key={colorData.color_id}>
                                    <th  className="text-center middle"> {colorData.colorName} </th>
                                        { sizes.map( (data) => {
                                            let getqty=getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id);
                                            totqty=parseInt(totqty)+parseInt(getqty); 
                                            return (
                                                <th key={data.size_id}>
                                                <input 
                                                    disabled 
                                                    className="form-control inpwidthsmall"
                                                    name="userName"
                                                    id={colorData.color_id+"#"+data.size_id}
                                                    type="number"
                                                    placeholder={t("quantity")}
                                                    min="0"
                                                    defaultValue={getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id)} 
                                                />
                                                </th>
                                            );
                                        })} 
                                    <th>
                                        <input 
                                        className="form-control inpwidthsmall"
                                        name="userName"
                                        type="number"
                                        placeholder={t("quantity")}
                                        readOnly
                                        defaultValue={totqty}
                                        id={"total_quantity"+colorData.color_id} 
                                        />
                                    </th>
                                    </tr>

                                )
                                }
                                )
                                }

                            </tbody>
                          </table>
                  </div>  
                  </Row>
                </Card>
            
          </ModalBody>
        </>
        :
        ""
      }

      { typeOfProduction === 'Sew' ? 
      <>
          <ModalHeader className="text-left m-l-20" 
                          style={{  borderBottom: 'none' }}>  
              <Row>
                <Col className="m-t-5"> 
                { productionSewPackDateDetails.isSewAccomplished == 0
                  ?
                  (currentDate > productionSewPackDateDetails.sewEndDate) ? 
                  <>
                    <div className="media">
                          <img src={RedSewing}  width="45px" />
                          <div
                              className="m-l-10 media-body label_align2 m-t-5 ">
                              <span 
                              style={{ color: '#EE335E',
                              fontWeight: '500', 
                              fontSize: '15px' 
                              }} 
                              > {t("sewing")} </span>
                              <div className="f-12" 
                                  style={{color: '#EE335E', 
                                          fontWeight: 'normal',
                                          fontSize: '10px'
                                          }}>
                                  <i 
                                  className="fa fa-circle" 
                                  style={{color:'#EE335E',
                                          fontSize: '10px'
                                          }}>
                                  </i>
                                  <span 
                                  style={{ 
                                  }}>
                                  &nbsp;
                                      {t("delay")}
                                  </span> 
                              </div>
                          </div>
                    </div>
                  </>       
                  :
                  <>
                    <img src={GreenSewing}  width="45px" />
                    <strong className="m-l-15 f-w-500" 
                    style={{ color: '#009688' }}>
                    {t("sewing")} </strong>
                  </>
                  :
                  (productionSewPackDateDetails.sewAccomplishedDate <= productionSewPackDateDetails.sewEndDate) ? 
                  <>
                      <div className="media">
                          <img src={GreenSewing} width="45px" />
                          <div className="m-l-5 media-body label_align2">
                              <H6> {t("sewing")} </H6>
                              <div className="f-12" 
                                  style={{color: '#009688', 
                                          fontWeight: 'normal',
                                          fontSize: '10px'
                                          }}>
                                  <i 
                                  className="fa fa-circle" 
                                  style={{color:'#009688',
                                          fontSize: '10px'
                                          }}>
                                  </i>
                                  <span 
                                  style={{ 
                                  }}>
                                  &nbsp;
                                      {t("completed")}
                                  </span> 
                              </div>
                          </div>
                      </div>
                  </>
                  :
                  <>
                    <div className="media">
                                <img src={OrangeSewing} width="45px" />
                                <div
                                    className="m-l-10 media-body label_align2 m-t-5 ">
                                    <span 
                                    style={{ color: '#E69020',
                                    fontWeight: '500', 
                                    fontSize: '15px' 
                                    }} 
                                    > {t("sewing")} </span>
                                    <div className="f-12" 
                                        style={{color: '#E69020', 
                                                fontWeight: 'normal',
                                                fontSize: '10px'
                                                }}>
                                        <i 
                                        className="fa fa-circle" 
                                        style={{color:'#E69020',
                                                fontSize: '10px'
                                                }}>
                                        </i>
                                        <span 
                                        style={{ 
                                        }}>
                                        &nbsp;
                                            {t("delayComplete")}
                                        </span> 
                                    </div>
                                </div>
                    </div>
                  </>
                  }
                </Col>
                <Col>
                  <div className="f-right m-t-10 m-r-5" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ toggle()}}>
                    X
                  </div>
                </Col>
              </Row>
          </ModalHeader>

          <ModalBody>
                <Card>
                  <Row className="g-12">
                  <div className="table-responsive">
                          <table className="table">
                          <thead>
                              <tr>
                              <th className="text-center middle" scope="col">
                              {t("color/sizeLabel")}
                              </th>
                              {sizes.map(( data) => 
                                  {
                                  return (
                                      <th  key={data.size_id}  className="text-center middle">
                                          {data.sizeName}
                                      </th>   
                                  )
                                  }
                              )} 
                              <th className="text-center middle">
                              {t("totalLabel")}
                              </th>
                              </tr>
                          </thead>
                          <tbody>

                              {Object.values(colors)
                              .map( (colorData ) => {
                                  let totqty=0;
                              return (
                                  <tr key={colorData.color_id}>
                                  <th  className="text-center middle"> {colorData.colorName} </th>
                                      { sizes.map( (data) => {
                                          let getqty=getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id);
                                          totqty=parseInt(totqty)+parseInt(getqty);
                                          return (
                                              <th key={data.size_id}>
                                              <input 
                                                  disabled 
                                                  className="form-control inpwidthsmall"
                                                  name="userName"
                                                  id={colorData.color_id+"#"+data.size_id}
                                                  type="number"
                                                  placeholder={t("quantity")}
                                                  min="0"
                                                  defaultValue={getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id)} 
                                              />
                                              </th>
                                          );
                                      })} 
                                  <th>
                                      <input 
                                      className="form-control inpwidthsmall"
                                      name="userName"
                                      type="number"
                                      placeholder={t("quantity")}
                                      readOnly
                                      defaultValue={totqty}
                                      id={"total_quantity"+colorData.color_id} 
                                      />
                                  </th>
                                  </tr>
                              )
                              }
                              )} 

                          </tbody>
                          </table>
                      </div>  
                  </Row>
                </Card>
            
          </ModalBody>
      </>
      :
      ""
      }
      
      { typeOfProduction === 'Pack' ? 
      <>
        <ModalHeader className="text-left m-l-20" 
                          style={{  borderBottom: 'none' }}>  
              <Row>
                <Col className="m-t-5">
                { productionSewPackDateDetails.isPackAccomplished == 0
                  ?
                  (currentDate > productionSewPackDateDetails.packEndDate) ? 
                  <>
                    <div className="media">
                            <img src={RedPacking}  width="45px" />
                            <div
                                className="m-l-10 media-body label_align2 m-t-5 ">
                                <span 
                                style={{ color: '#EE335E',
                                fontWeight: '500', 
                                fontSize: '15px' 
                                }} 
                                > {t("packing")} </span>
                                <div className="f-12" 
                                    style={{color: '#EE335E', 
                                            fontWeight: 'normal',
                                            fontSize: '10px'
                                            }}>
                                    <i 
                                    className="fa fa-circle" 
                                    style={{color:'#EE335E',
                                            fontSize: '10px'
                                            }}>
                                    </i>
                                    <span 
                                    style={{ 
                                    }}>
                                    &nbsp;
                                        {t("delay")}
                                    </span> 
                                </div>
                            </div>
                    </div>
                  </>       
                  :
                  <>
                  <img src={GreenPacking}  width="45px" />
                  <strong className="m-l-15 f-w-500" 
                  style={{ color: '#009688' }}>
                  {t("packing")} </strong>
                  </>
                  :
                  (productionSewPackDateDetails.packAccomplishedDate <= productionSewPackDateDetails.packEndDate) ? 
                  <>
                      <div className="media">
                          <img src={GreenPacking} width="45px" />
                          <div className="m-l-5 media-body label_align2">
                              <H6> {t("packing")} </H6>
                              <div className="f-12" 
                                  style={{color: '#009688', 
                                          fontWeight: 'normal',
                                          fontSize: '10px'
                                          }}>
                                  <i 
                                  className="fa fa-circle" 
                                  style={{color:'#009688',
                                          fontSize: '10px'
                                          }}>
                                  </i>
                                  <span 
                                  style={{ 
                                  }}>
                                  &nbsp;
                                      {t("completed")}
                                  </span> 
                              </div>
                          </div>
                      </div>
                  </>
                  :
                  <>
                    <div className="media">
                                  <img src={OrangePacking} width="45px" />
                                  <div
                                      className="m-l-10 media-body label_align2 m-t-5 ">
                                      <span 
                                      style={{ color: '#E69020',
                                      fontWeight: '500', 
                                      fontSize: '15px' 
                                      }} 
                                      > {t("packing")} </span>
                                      <div className="f-12" 
                                          style={{color: '#E69020', 
                                                  fontWeight: 'normal',
                                                  fontSize: '10px'
                                                  }}>
                                          <i 
                                          className="fa fa-circle" 
                                          style={{color:'#E69020',
                                                  fontSize: '10px'
                                                  }}>
                                          </i>
                                          <span 
                                          style={{ 
                                          }}>
                                          &nbsp;
                                              {t("delayComplete")}
                                          </span> 
                                      </div>
                                  </div>
                    </div>
                  </>
                  }
                </Col>
                <Col>
                  <div className="f-right m-t-10 m-r-5" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ toggle()}}>
                    X
                  </div>
                </Col>
              </Row>
          </ModalHeader>

          <ModalBody>
                <Card>
                  <Row className="g-12">
                  <div className="table-responsive">
                          <table className="table">
                          <thead>
                              <tr>
                              <th className="text-center middle" scope="col">
                              {t("color/sizeLabel")}
                              </th>
                              {sizes.map(( data) => 
                                  {
                                  return (
                                      <th key={data.size_id} className="text-center middle">
                                          {data.sizeName}
                                      </th>   
                                  )
                                  }
                              )} 
                              <th className="text-center middle">
                              {t("totalLabel")}
                              </th>
                              </tr>
                          </thead>
                          <tbody>

                              {Object.values(colors)
                              .map( (colorData ) => {
                                  let totqty=0;
                              return (
                                  <tr key={colorData.color_id}>
                                  <th  className="text-center middle"> {colorData.colorName} </th>
                                      { sizes.map( (data) => {
                                          let getqty=getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id);
                                          totqty=parseInt(totqty)+parseInt(getqty);
                                          return (
                                              <th key={data.size_id}>
                                              <input 
                                                  disabled 
                                                  className="form-control inpwidthsmall"
                                                  name="userName"
                                                  id={colorData.color_id+"#"+data.size_id}
                                                  type="number"
                                                  placeholder={t("quantity")}
                                                  min="0"
                                                  defaultValue={getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id)} 
                                              />
                                              </th>
                                          );
                                      })} 
                                  <th>
                                      <input 
                                      className="form-control inpwidthsmall"
                                      name="userName"
                                      type="number"
                                      placeholder={t("quantity")}
                                      readOnly
                                      defaultValue={totqty}
                                      id={"total_quantity"+colorData.color_id} 
                                      />
                                  </th>
                                  </tr>
                              )
                              }
                              )} 

                          </tbody>
                          </table>
                      </div>  
                  </Row>
                </Card>
            
          </ModalBody>
      </>
      :
      ""
      } 

      </Modal>
    );
};

export default SkuwiseProgressModal;
/***** CODE BY : RAMANAN M  *****/

      