import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Label,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
} from "reactstrap";
import { Btn } from "../../AbstractElements";
import { ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import {
  getLoginCompanyId,
  getLoginStaffId,
  getLoginUserId,
  getWorkspaceId,
} from "../../Constant/LoginConstant";
import { apidecrypt, apiencrypt } from "../../helper";

const AddSupervisorModal = ({
  modal,
  toggle,
  setGetMasterData,
  setProductionTypeList,
  setBreakTimeList,
}) => {
  const { t } = useTranslation();

  const [supervisorId, setSupervisorId] = useState("");
  const [supervisorName, setSupervisorName] = useState("");

  const [errors, setValidErrors] = useState({});
  const validation = () => {
    let errors = {};
    if (!supervisorId) {
      errors.supervisorId = t("plsEnterSupervisorId")
    }
    if (!supervisorName) {
      errors.supervisorName = t("plsEnterSupervisorName")
    }
    setValidErrors(errors);
    return errors;
  };

  const refreshDataApiCall = () => {

    var apiInputParams = {};
    apiInputParams['company_id'] = getLoginCompanyId;
    apiInputParams['workspace_id'] = getWorkspaceId;

    axios
      .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setGetMasterData(response.data.data);
      })

      setProductionTypeList(['Sewing', 'Cutting']);
      setBreakTimeList(['1', '2', '3']);

      toggle();
  };


  const onSaveHandle = () => {
    let retval = validation();
    if (Object.keys(retval).length == 0) {
      var apiInputParams = {};
      apiInputParams["company_id"] = getLoginCompanyId;
      apiInputParams["workspace_id"] = getWorkspaceId;
      apiInputParams["user_id"] = getLoginUserId;
      apiInputParams["staff_id"] = getLoginStaffId;

      apiInputParams["type"] = "Supervisor";
      apiInputParams["supervisor_id"] = supervisorId;
      apiInputParams["value"] = supervisorName;

      axios
        .post(ServerUrl + "/add-sam-master-data", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            }).then((result) => {
              if (result.isConfirmed) {
                refreshDataApiCall();
              }
              setTimeout(() => {
                refreshDataApiCall();
              }, 100);
            });
          } 
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addSupervisor")}</ModalHeader>
      <ModalBody>
        <Row>
          <FormGroup className="">
            <Label>{t("supervisorId")}</Label>
            <InputGroup>
              <Input
                className={`${
                  errors.supervisorId && "select-error-valid"
                }  form-control`}
                type="text"
                placeholder={t("plsEnterSupervisorId")}
                onChange={(e) => {
                  setSupervisorId(e.target.value);
                }}
              ></Input>
            </InputGroup>
            {errors.supervisorId && (
              <span className="error-msg">{t(errors.supervisorId)}</span>
            )}
          </FormGroup>
        </Row>

        <Row>
          <FormGroup className="">
            <Label>{t("supervisorName")}</Label>
            <InputGroup>
              <Input
                className={`${
                  errors.supervisorName && "select-error-valid"
                }  form-control`}
                type="text"
                placeholder={t("plsEnterSupervisorName")}
                onChange={(e) => {
                  setSupervisorName(e.target.value);
                }}
              ></Input>
            </InputGroup>
            {errors.supervisorName && (
              <span className="error-msg">{t(errors.supervisorName)}</span>
            )}
          </FormGroup>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Btn
          attrBtn={{
            color: "btn secondaryBtn btn-sm",
            onClick: () => {
              toggle(), setValidErrors(() => "");
            },
          }}
        >
          {t("close")}
        </Btn>
        <Btn
          attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}
        >
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddSupervisorModal;
