import { Home, Clock, Folder,  Star, Trash2,Key,Users,Calendar,Sliders,PieChart, Settings} from 'react-feather';
import { getStaff, getModules, getStaffPermission, getWorkspaceType } from '../../Constant/LoginConstant';
// import { useLocation } from "react-router-dom";
// const location = useLocation();
// console.log("path==>>",location.pathname);
export const MENUITEMS = [
    {
        menutitle: 'DMS',
        menucontent: 'Dashboards,Widgets',
        Items: [
            // (getStaff === "Staff"&&getModules.includes("Staffs"))||getStaff == null? : "",
            { path: `${process.env.PUBLIC_URL}/dashboard`, icon: Home, type: 'link', active: false, title: 'Dashboard'},
            // ((getStaff === "Staff"&&getModules.includes("Inquiry")))||getStaff == null?
            // { path: `${process.env.PUBLIC_URL}/inquiry/viewinquiry`, icon: Home, type: 'link', active: false, title: 'Inquiry' }:{},
          
            // { path: `${process.env.PUBLIC_URL}/inquiry/viewinquiry`, icon: Home, type: 'link', active: false, title: 'Inquiry'},
           // { path: `${process.env.PUBLIC_URL}/orderstatus`, icon: Folder, type: 'link', active: false, title: 'Order Status' },
            (getStaff === "Staff"&&getModules.includes("Order Status")&&(getStaffPermission.includes('View Order Status')))||getStaff == null?
            { path: `${process.env.PUBLIC_URL}/orderstatus`, icon: Folder, type: 'link', active: false, title: 'Order Status' }:{},
            (getStaff === "Staff"&&getModules.includes("Task Update")&&(getStaffPermission.includes('View Task Updates')))||getStaff == null?
            { path: `${process.env.PUBLIC_URL}/taskupdate`, icon: Clock, type: 'link', active: false, title: 'Task Update' }:{},
            (getStaff === "Staff"&&getModules.includes("Pending Task")&&getStaffPermission.includes('View Pending Task'))||getStaff == null?
            {
                title: 'Pending', type: 'sub', active: false, children: [
                    { path: `${process.env.PUBLIC_URL}/pendingtasks`, title: 'Task', type: 'link', active: false },
                    { path: `${process.env.PUBLIC_URL}/pendingproduction`, title: 'Production', type: 'link', active: false },
                ]
            }:{},
            // { path: `${process.env.PUBLIC_URL}/pendingtasks`, icon: Home, type: 'link', active: false, title: 'Pending' }:{},
            (getStaff === "Staff"&&getModules.includes("Data Input")&&
            (getStaffPermission.includes('View Data Input')||getStaffPermission.includes('Add Data Input')))||getStaff == null?
            { path: `${process.env.PUBLIC_URL}/datainput`, icon: Star, type: 'link', active: false, title: 'Production Details' }:{},
           
        //    {
        //     title: 'SAM Settings', type: 'sub', active: false, children: [
        //         { path: `${process.env.PUBLIC_URL}/viewsamdetails`, title: 'Daily Setting', type: 'link', active: false },
        //         { path: `${process.env.PUBLIC_URL}/dailysamdetails`, title: 'SAM Daily Entries', type: 'link', active: false },
        //     ]
        // },
            // { path: `${process.env.PUBLIC_URL}/dashboard`, icon: Anchor, type: 'link', active: false, title: 'User Management' },
            {
                title: 'Settings', type: 'sub', active: false, children: [
                    getStaff === "Staff"? {}:{ path: `${process.env.PUBLIC_URL}/company-profile`, icon: Clock, type: 'link', active: false, title: 'Company Profile' },  
                    { path: `${process.env.PUBLIC_URL}/user-settings`, icon: Sliders, title: 'User Settings', type: 'link', active: false },
                    (getStaff === "Staff"&&
                    getModules.includes("Calendar Configuration")&&
                    (getStaffPermission.includes('View Calendar Configuration')||
                    getStaffPermission.includes('Add/Edit Calendar Configuration')))||
                    getStaff == null?
                    { path: `${process.env.PUBLIC_URL}/calendar-configuration`, title: 'Calendar Configuration', type: 'link', active: false }:{},
                    getStaff === "Staff"? {}:
                    { path: `${process.env.PUBLIC_URL}/paymenthistory`, icon: Sliders, title: 'Payment History', type: 'link', active: false },
                ]
            },
                    
        ]
    },
    {
        menutitle: 'SubMenu',
        menucontent: 'subMenu,Widgets',
        Items: [
          //  { path: `${process.env.PUBLIC_URL}/user-settings`, icon: Trash2, type: 'link', active: false, title: 'User Settings' },

           // { path: `${process.env.PUBLIC_URL}/companysetting`, icon: Trash2, type: 'link', active: false, title: 'Company Settings' },
            // { path: `${process.env.PUBLIC_URL}/pricing`, icon: Trash2, type: 'link', active: false, title: 'Payment Plan' },
           // getStaff === "Staff"? {}:{ path: `${process.env.PUBLIC_URL}/company-profile`, icon: Clock, type: 'link', active: false, title: 'Company Profile' },            
            (getStaff === "Staff"&&getModules.includes("Roles")&&(getStaffPermission.includes('View Permissions')||
            getStaffPermission.includes('Add Permissions')))||getStaff == null?
            { path: `${process.env.PUBLIC_URL}/roles`, icon: Key, type: 'link', active: false, title: 'Roles' }
            :
            {},
            (getStaff === "Staff"&&getModules.includes("Staffs")&&(getStaffPermission.includes('View Staff')
            // ||getStaffPermission.includes('Staff Add')||getStaffPermission.includes('Staff Edit')
            ))||getStaff == null? 
            { path: `${process.env.PUBLIC_URL}/staff`, icon: Users, type: 'link', active: false, title: 'Staff' }
            :
            {},
           // (getStaff === "Staff"&&getModules.includes("Calendar Configuration")&&(getStaffPermission.includes('View Calendar Configuration')||getStaffPermission.includes('Add/Edit Calendar Configuration')))||getStaff == null?{ path: `${process.env.PUBLIC_URL}/calendar-configuration`, icon: Calendar, type: 'link', active: false, title: 'Calendar Configuration' }:{},
           
           //{ path: `${process.env.PUBLIC_URL}/addsamdetails`, icon: Clock, type: 'link', active: false, title: 'SAM Details' }:{}

            (getStaff === "Staff"&&getModules.includes("Reports")&&getStaffPermission.includes('View Report'))||getStaff == null?
            {
                title: 'Reports', type: 'sub', children: [
                    { path: `${process.env.PUBLIC_URL}/taskreports`, title: 'Task', type: 'link', active: false },
                    { path: `${process.env.PUBLIC_URL}/productionreports`, title: 'Production', type: 'link', active: false },
                    { path: `${process.env.PUBLIC_URL}/datainputreports`, title: 'Production Details', type: 'link', active: false },
                    { path: `${process.env.PUBLIC_URL}/datainputskureports`, title: 'SKU Data', type: 'link', active: false },
                    { path: `${process.env.PUBLIC_URL}/orderreports`, title: 'Order Status', type: 'link', active: false },

                    getWorkspaceType == "Factory" ?
                        { path: `${process.env.PUBLIC_URL}/orderaccessoriesreports`, title: 'Order Accessories', type: 'link', active: false }
                    :
                        ""                    
                ]
            }:{},
        ]
    },
]