import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, 
    CardHeader, Collapse, FormGroup, Table } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Moment from "moment";
import bomb from "../../../assets/images/dms/BombSmiley.svg"
import smile from "../../../assets/images/dms/SmileSmiley.svg"
import NotAssigned from "../../../assets/images/dms/NotAssigned.svg"
import TwoDaysDelaySmile from "../../../assets/images/dms/TwodaysDelay.svg"
import yellowSmile from "../../../assets/images/dms/WarningSmiley.svg"
import sad from "../../../assets/images/dms/SadSmiley.svg"
import YetToStart from "../../../assets/images/dms/YetToStart.svg"
import YetToComplete from "../../../assets/images/dms/YetToComplete.svg"
import downAccordion_icon from '../../../assets/images/dms/icons/For Accordion_icon.svg';
import up_accordion_icon from '../../../assets/images/dms/icons/up_accordion_icon.svg';
import {  getWorkspaceType } from "../../../Constant/LoginConstant";
import { getConvertDateFormat,PHPtoJSFormatConversion } from "../../../helper";
import { useTranslation } from 'react-i18next';
 
const TaskReportAccordion =({TaskDetails,statusFilter})=>{
    const [isOpen, setIsOpen] = useState(0);
    const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
    const { t } = useTranslation();
    /************ Calculate the Date Difference for Delay/Pending Days and Get the Icon  ****************/
    const getSmiley=(day,isAccomplished,endDate,type)=>{
       if(isAccomplished != null){
           if(endDate >= isAccomplished ){
               return (<td className="completedText text-left"><img className="p-0 img-30" src={smile}/> &nbsp; {t("completed")} </td>);
           }else{
               return (<td className="completedText text-left"><img className="p-0 img-30" src={smile}/> &nbsp;{t("delayComplete")}</td>);
           }
       }
       else if(type === "YetToBeStarted" && day >=2){
        return (<td className="yetToStart text-left"><img className="p-0 img-30" src={YetToStart}/> &nbsp;{day} {t("daysToStart")}  </td>);
        }
        else if(type === "YetToBeStarted" && day === 1){
            return (<td className="yetToStart text-left"><img className="p-0 img-30" src={YetToStart}/> &nbsp;{t("dayToStart")}  </td>);
        }
        else if (type === "StartsToday"){
            return (<td className="yetToCompeteText text-left "><img className="p-0 img-30" src={YetToComplete}/> &nbsp; {t("startsToday")}  </td>);
        }
        else if(day>=0 && type==="Progress"){
            if(day==1){
                return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/> &nbsp;{t("dayRemaining")} </td>);
            }
            else if(day==0){
                return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/> &nbsp; {t("zerodayRemaining")} </td>);
            }
            else{
                return (<td className="yetToCompeteText text-left"><img className="p-0 img-30" src={YetToComplete}/> &nbsp;{t("daysRemaining",{remainingdayscount:day})}</td>);
            }
        }else{
            let date = Math.abs(day);
            if(date >3){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={bomb}/> &nbsp;{date} {t("daysDelay")}</td>);
            }
            if(date ==1){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={TwoDaysDelaySmile}/> &nbsp;{t("dayDelay")}</td>);
            }
            if(date <=3){
                return (<td className="delayText text-left"><img className="p-0 img-30" src={TwoDaysDelaySmile}/> &nbsp;{date} {t("daysDelay")}</td>);
            }
            
        }
    }
    return (
        <Row>
            <Col sm="12" xl="12" lg="12" md="12">
                <Accordion defaultActiveKey="0">
                    <div className="default-according" id="accordion">
                        {TaskDetails.map((detail, i) => (
                            <Fragment key={i}>
                            <Card >
                                {i/2===0?
                                (<CardHeader className="p-0" style={{background:"#E6EDEF80"}} >
                                    <Row onClick={() => toggle(i)}>                                                
                                        <Col sm="12" md="2" lg="2" className="f-left m-t-15">
                                            <Btn
                                                        attrBtn={{
                                                            as: Card.Header,
                                                            className: "btn btn-link",
                                                            color: "default",
                                                            onClick: () => toggle(i),
                                                        }}
                                                    >
                                                    <p>{detail.orderNo}</p>
                                            </Btn>        
                                        </Col>
                                        <Col sm="12" md="8" lg="8" className="f-left m-t-15">
                                            <Btn
                                                        attrBtn={{
                                                            as: Card.Header,
                                                            className: "btn btn-link",
                                                            color: "default",
                                                            onClick: () => toggle(i),
                                                        }}
                                                    >
                                                    <p>{detail.styleNo}</p>
                                            </Btn>        
                                        </Col>
                                        {/* <Col sm="12" md="2" lg="2">
                                            <span class="u-step-number txt-success">3</span>
                                            <span class="badge rounded-pill badge bg-danger rounded-pill">6</span>
                                            <span className="pendingTaskCount"> {detail.pendingCount} </span>

                                        </Col> */}
                                        <Col sm="12" md="2" lg="2" className="centerAlign">
                                            <Btn
                                                attrBtn={{
                                                    as: Card.Header,
                                                    className: "btn btn-link p-0",
                                                    color: "default",
                                                    onClick: () => toggle(i),
                                                }}
                                            >
                                            {isOpen === i ?
                                             <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5"   src={up_accordion_icon}/></span>
                                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5"   src={downAccordion_icon}/></span>}
                                            </Btn>
                                        </Col>                                               
                                    </Row>
                                </CardHeader>):
                                (<CardHeader className="p-0">
                                <Row onClick={() => toggle(i)}>                                                
                                    <Col sm="12" md="2" lg="2" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}>
                                                <p>{detail.orderNo}</p>
                                        </Btn>        
                                    </Col>
                                    <Col sm="12" md="8" lg="8" className="f-left m-t-15">
                                        <Btn
                                                    attrBtn={{
                                                        as: Card.Header,
                                                        className: "btn btn-link",
                                                        color: "default",
                                                        onClick: () => toggle(i),
                                                    }}>
                                                <p>{detail.styleNo}</p>
                                        </Btn>        
                                    </Col>
                                    {/* <Col sm="12" md="2" lg="2">
                                        <span class="u-step-number txt-success">3</span>
                                        <span class="badge rounded-pill badge bg-danger rounded-pill">6</span>
                                        <span className="pendingTaskCount"> {detail.pendingCount} </span>

                                    </Col> */}
                                    <Col sm="12" md="2" lg="2" className="centerAlign">
                                        <Btn
                                            attrBtn={{
                                                as: Card.Header,
                                                className: "btn btn-link",
                                                color: "default",
                                                onClick: () => toggle(i),
                                            }}
                                        >
                                         {isOpen === i ?
                                             <span className=" p-0 accdview f-right "> <img className="cursor-pointer f-right p-r-5"   src={up_accordion_icon}/></span>
                                            : <span className=" p-0  accdview  f-right "><img className="cursor-pointer f-right p-r-5"   src={downAccordion_icon}/></span>}
                                        </Btn>
                                    </Col>                                               
                                </Row>
                                </CardHeader>)}
                                <Collapse isOpen={isOpen === i}>
                                    <CardBody className="p-0">
                                            <Table className="table-responsive custom-table-striped">
                                                <thead>
                                                <tr style={{background:"#c2ede8f5",fontSize:"14px"}}>
                                                    <th>{t("Task")}</th>
                                                    <th>{t("personInCharge")}</th>
                                                    <th>{t("startDate")}</th>
                                                    <th>{t("endDate")}</th>
                                                    {statusFilter === "Completed" || statusFilter=== "DelCompletion" || statusFilter=== "All"||statusFilter=== "Rescheduled"
                                                        ?<th>{t("accomplishedDate")}</th>:""}
                                                    <th>{t("status")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {(detail.taskData).length >0? detail.taskData.map((task,k)=>{
                                                    return(
                                                    <>
                                                        <tr>
                                                            <td className="f-w-600">{task.title}</td>
                                                            {task.pic === "" ? <td>-</td>:<td>{task.pic}</td>}
                                                            {task.startDate === null? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(task.startDate))}</td>}
                                                            {task.scheduledDate === null? <td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(task.scheduledDate))}</td>}
                                                            {statusFilter === "Completed" || statusFilter=== "DelCompletion" || statusFilter=== "All"|| statusFilter=== "Rescheduled"
                                                            ?task.accomplishedDate === null?<td>-</td>:<td>{PHPtoJSFormatConversion.format(new Date(task.accomplishedDate))}</td>:""}
                                                            {task.days === null? <td className="yetToStart text-left"><img className="p-0 img-30" src={NotAssigned}/> &nbsp; {t("notAssigned")}</td>:getSmiley(task.days,task.accomplishedDate,task.scheduledDate,task.type)}   
                                                        </tr>
                                                        {task.hasOwnProperty("subtasks") ? task.subtasks.map((subtask,j)=>(
                                                        <tr>
                                                            <td><span className="f-12">{subtask.subtasktitle}</span></td>
                                                            {subtask.pic ==="" ? <td>-</td>:<td className="f-12 f-w-500">{subtask.pic}</td>}
                                                            {subtask.startDate === null? <td>-</td>:<td className="f-12 f-w-500">{PHPtoJSFormatConversion.format(new Date(subtask.startDate))}</td>}
                                                            {subtask.scheduledDate === null? <td>-</td>:<td className="f-12 f-w-500">{PHPtoJSFormatConversion.format(new Date(subtask.scheduledDate))}</td>}
                                                            {statusFilter === "Completed" || statusFilter=== "DelCompletion" || statusFilter=== "All"|| statusFilter=== "Rescheduled"
                                                            ?subtask.accomplishedDate === null?<td>-</td>:<td className="f-12 f-w-500">{PHPtoJSFormatConversion.format(new Date(subtask.accomplishedDate))}</td>:""}
                                                            {subtask.days === null? <td className="yetToStart text-left"><img className="p-0 img-30" src={NotAssigned}/> &nbsp; {t("notAssigned")}</td>:getSmiley(subtask.days,subtask.accomplishedDate,subtask.scheduledDate,subtask.type)}   
                                                        </tr>)
                                                        ):""}
                                                    </>
                                                    )
                                                    }):
                                                     <tr >
                                                      <td colSpan={4} style={{ textAlign: "center" }}> {t("noDataFound")}</td>
                                                     </tr>
                                                    }
                                                    </tbody>
                                            </Table>
                                    </CardBody>
                                </Collapse>
                            </Card>
                            </Fragment>))}
                    </div>
                </Accordion>
            </Col>
        </Row>
 )}
export default TaskReportAccordion;
/* Code By : Rithanesh */