import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Form,
    Label,
    Input,
    CardFooter,
    InputGroup,
    InputGroupText,
    Button
  } from "reactstrap";
  import React, { Fragment, useContext, useState, useEffect } from "react";
  import { Breadcrumbs, H5, P, Btn, H6, } from "../../AbstractElements";
  import { useNavigate, Link } from "react-router-dom";
  import { useForm } from "react-hook-form";
  import Dropzone from "react-dropzone-uploader";
  import axios from "axios";
  import { ServerUrl } from "../../Constant/index";
  import Swal from "sweetalert2";
  import {getLoginCompanyId,getWorkspaceId,getLoginUserId,getLoginStaffId,getLoginToken} from '../../Constant/LoginConstant';
import { t } from "i18next";
 

  const Addsize = () => {
    const history = useNavigate();
    const handleChangeStatus = ({ meta, file }, status) => {};
  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  
    // const AddProject = (data) => {
    //   if (data !== "") {
    //     history(`${process.env.PUBLIC_URL}/app/project/project-list`);
    //   } else {
    //     errors.showMessages();
    //   }
    // };
  
  
    const [user_id, setuser_id] = useState(getLoginUserId);
    const [staff_id, setStaff_id] = useState(getLoginStaffId);
    const [company_id, setCompany_id] = useState(getLoginCompanyId);
    const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
    const [name, setSizeName] = useState("");
    const [status, setStatus] = useState("1");
    
    const toggleRoleModal = () => setRoleModal(!roleModal);
    const onGoBack = () =>{
      // setTimeout(() => {
      //   window.location.href = "/staff";
      // }, 100);
    }
    const [validerrors, setValidErrors] = useState({});
    const validation = (data) => {
      let validerrors={};
       if(!name){
        validerrors.name="Please Enter Size";
         }
     setValidErrors(validerrors);
     return validerrors;
   };
   
    useEffect(() => {
 
    
    }, []);

    // getToken = ()=>{
    //   const token = localStorage.getItem('api_token');
    //   console.log("123456789123456897",localStorage.getItem('api_token'));
    //   return token;
    // }
   
   
    const OnSubmitHandle = () => {
      
     let retval= validation();
     // console.log("OnSubmitHandle called",Object.keys(retval).length)
   if(Object.keys(retval).length == 0){
      document.getElementById("save-button").setAttribute("disabled", true);
      axios.post(ServerUrl + "/size",{
          company_id: company_id,
          workspace_id: workspace_id,
          name:name,
          staff_id :staff_id,
          user_id:user_id,
          status: status,  
      },
      )
           .then((response) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t("sizeAddedAlert"),
            // text: t(response.data.message),
            icon: "success",
            button:  t("okLabel"),
          }).then((result) => {
           // if (result.isConfirmed) {
              document.getElementById("save-button").removeAttribute("disabled");
             // setTimeout(() => {
                window.location.href = "/size";
            //  }, 100);
          //  }
          });
        }
        if (response.data.status_code === 400) {
          document.getElementById("save-button").removeAttribute("disabled");
          Swal.fire({
            title: t(response.data.message),
            icon: "error",
            button:  t("okLabel"),
          });
        }
      });
    }else{
      Swal.fire({
        title: t("valError"),
        text: t("plsFillReqFields"),
        icon: "error",
        button:  t("okLabel"),
      });
    }
    };
   
    return (
      <Fragment>
        <Row className="pgbgcolor">
           <Breadcrumbs mainTitle='Size Details' parent="Size Details" title="Size Details" subTitle="Add Size"/>
           </Row>
        <Container fluid={true}  className="general-widget topaln">
          <Form>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        {" "}
                        <H6 attrH6={{ className: "pb-2" }}>{t("sizeInfo")}</H6>
                      </Col>
                      <Col sm="12" md="12" lg="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                             {t("name")}<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                             className={`${validerrors.firstname && 'error-valid'}  form-control`}
                            type="text"
                            placeholder="Size Name"
                            onChange={(e) => setSizeName(e.target.value)}
                          />
                           {validerrors.name && (
                      <span className="error-msg">{validerrors.name}</span>
                    )}
                        </FormGroup>
                      </Col>
                                                               
                      <Col sm="12" md="12" lg="6" xs="12">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            {t("status")}<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            type="select"
                            name="select"
                            className="form-control digits"
                            defaultValue="Activated"
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value={"1"}>{"Activated"}</option>
                            <option value={"2"}>{"Deactivated"}</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-0">
                          <Button
                            className="btn btn-primary" id="save-button"
                            onClick={() => OnSubmitHandle()}
                          >
                            {t("save")}
                          </Button>
                          <Link to="/size">
                            <Button className="btn btn-danger m-l-10" 
                            >
                              {t("cancel")}
                            </Button>
                          </Link>
  
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  };
  
  export default Addsize;
  