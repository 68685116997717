    import { Label, Card, CardBody, Col, InputGroup,
        Button, Nav, NavItem,  FormGroup, Input,
        NavLink, TabContent, TabPane, Container, Row } from 'reactstrap';
    import React, { Fragment, useState, useEffect } from 'react';
    import { Breadcrumbs, P, H6, Image} from '../../AbstractElements';
    import { getLoginCompanyId, getWorkspaceId, getWorkspaceType,
            getStaff, getStaffPermission, getLoginStaffId }
            from "../../Constant/LoginConstant";
            
    import axios from "axios";
    import { Medium, ServerUrl } from "../../Constant/index";
    import { PHPtoJSFormatConversion, apiencrypt,apidecrypt,encode, decode } from '../../helper';

    import FullCalendar, { WindowScrollController } from '@fullcalendar/react';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
    import dayGridPlugin from '@fullcalendar/daygrid';

    // import KnobComponentCuttingEstimated from './KnobChartCuttingEstimated';
    // import KnobComponentCuttingRequired from "./KnobChartCuttingRequired";

    // import KnobComponentSewingEstimated from './KnobChartSewingEstimated';
    // import KnobComponentSewingRequired from "./KnobChartSewingRequired";

    // import KnobComponentPackingEstimated from './KnobChartPackingEstimated';
    // import KnobComponentPackingRequired from "./KnobChartPackingRequired";

    import GreenCutting from '../../../src/assets/images/dms/icons/green_cutting.svg';
    import GreenSewing from '../../assets/images/dms/icons/green_sewing.svg';
    import GreenPacking from '../../assets/images/dms/icons/green_packing.svg';

    import OrangeCutting from '../../../src/assets/images/dms/icons/orange_cutting.svg';
    import OrangeSewing from '../../assets/images/dms/icons/orange_sewing.svg';
    import OrangePacking from '../../assets/images/dms/icons/orange_packing.svg';

    // import RedCutting from '../../../src/assets/images/dms/icons/red_cutting.svg';
    // import RedSewing from '../../assets/images/dms/icons/red_sewing.svg';
    // import RedPacking from '../../assets/images/dms/icons/red_packing.svg';

    import RedCutting from '../../../src/assets/images/dms/icons/red_cutting_production_details.svg';
    import RedSewing from '../../assets/images/dms/icons/red_sewing_production_details.svg';
    import RedPacking from '../../assets/images/dms/icons/red_packing_production_details.svg';

    import GreyCutting from '../../../src/assets/images/dms/icons/grey_cutting.svg'; 
    import GreySewing from '../../assets/images/dms/icons/grey_sewing.svg';
    import GreyPacking from '../../assets/images/dms/icons/grey_packing.svg';

    import DataInputSkuModal from './DataInputSkuModal';
    import DataInputSkuDelayedModal from './DataInputSkuDelayedModal';
    import SkuwiseProgressModal from '../DataInput/SkuwiseProgressModal';

    import Swal from 'sweetalert2';
    import Moment from 'moment';

    import { useTranslation } from 'react-i18next';
    import jaLocale from '@fullcalendar/core/locales/ja';

    import {  useLocation  } from "react-router-dom";
    import Knob from 'knob';   


const DataInput = () => {
    
   const [styleNo, setStyleNo] = useState("");

   const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
   const [company_id, setcompany_id] = useState(getLoginCompanyId);
   const [staff_id, setStaffId] = useState(getLoginStaffId);
   const [factory, setFactory] = useState("");
   const [buyer, setBuyer] = useState("");
   const [buyers, setBuyers] = useState([]);
   const [pcu, setPcu] = useState("");
   const [styleNoList, setStyleNoList] = useState([]);
   const [errors, setValidErrors] = useState({});
   const [Pcus, setPcus] = useState([]);
   const [selectedDate, setSelectedDate] = useState("");
   const [targetValue, setTargetValue]= useState("");
   const [factories, setFactories] = useState([]);
   const [colors, setColors] = useState([]);
   const [sizes , setSizes] = useState([]);

   let detailsToSend;
    getStaff === "Staff" ?  detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : staff_id,
        type: "DataInput" 
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        type: "DataInput" 
    } 
   
    const dataToSendAtStarting = detailsToSend;

    let ct=0;
    let sw=0;
    let pk=0;

    let cur_ct=1;
    let cur_sw=1;
    let cur_pk=1;

    let cutData = [];
    let sewData = [];
    let packData = [];

    let hol_ct = cutData.length;
    let hol_sw = sewData.length;
    let hol_pk = packData.length;

    const [ cut, setCut] = useState(true);
    const [ sew, setSew] = useState(false);
    const [ pack, setPack] = useState(false);

    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    const [skuModal, setSkuModal] = useState(false);
    const toggleSkuModal = () => setSkuModal(!skuModal);

    const [ BasicTab, setBasicTab ] = useState('1');
    const [ activeTab, setActiveTab ] = useState('active');
    
    const [ typeOfProd, setTypeOfProd ] = useState("");
    const [ completedQtyRate, setCompletedQtyRate ] = useState("");

    const [ cutKnobChart, setCutKnobChart] = useState([]);
    const [ sewKnobChart, setSewKnobChart] = useState([]);
    const [ packKnobChart, setPackKnobChart] = useState([]);

    const [ cutHolidayList, setCutHolidayList ] = useState([]);
    const [ sewHolidayList, setSewHolidayList ] = useState([]);
    const [ packHolidayList, setPackHolidayList ] = useState([]);

    const [ cutPendingQty, setCutPendingQty ] = useState("");
    const [ sewPendingQty, setSewPendingQty ] = useState("");
    const [ packPendingQty, setPackPendingQty ] = useState("");
    
    const [ pendingStatus, setPendingStatus ] = useState("");

    const [ cutEndDate, setCutEndDate ] = useState("");
    const [ sewEndDate, setSewEndDate ] = useState("");
    const [ packEndDate, setPackEndDate ] = useState("");

    const [ cutWeekOff, setCutWeekOff ] = useState([]);
    const [ sewWeekOff, setSewWeekOff ] = useState([]);
    const [ packWeekOff, setPackWeekOff ] = useState([]);

    const [ deliveryDate, setDeliveryDate ] = useState("");
    const [ deliveryDateStatus, setDeliveryDateStatus ] = useState("");

    const [ orderDelayStatus, setOrderDelayStatus ] = useState("0");
    const [ dataInputStatus, setDataInputStatus ] = useState("0");
    const [ dataInputStatusStaffLogin, setDataInputStatusStaffLogin ] = useState("0");

    const [filterProductionDetails, setFilterProductionDetails] = useState(false);

    const [cutCurrentDateTargetValue, setCutCurrentDateTargetValue] = useState("");
    const [sewCurrentDateTargetValue, setSewCurrentDateTargetValue] = useState(""); 
    const [packCurrentDateTargetValue, setPackCurrentDateTargetValue] = useState(""); 

    const [ cutActualUpdatedData, setCutActualUpdatedData] = useState([]); 
    const [ sewPackActualUpdatedData, setSewPackActualUpdatedData] = useState([]); 
    
    const[ getCurrentDate, setgetCurrentDate ]=useState(Moment().format('Y-MM-DD'));

    const [ productionSkuDataDetails, setProductionSkuDataDetails] = useState([]);
    const [ productionSewPackSkuDataDetails, setProductionSewPackSkuDataDetails] = useState([]);

    const [ productionCutDateDetails, setProductionCutDateDetails] = useState([]);
    const [ productionSewPackDateDetails, setProductionSewPackDateDetails] = useState([]);

    const [ productionCutCalendarDataDetails, setProductionCutCalendarDataDetails] = useState([]);
    const [ productionSewPackCalendarDataDetails, setProductionSewPackCalendarDataDetails] = useState([]);

    var currentDate = Moment().format('YYYY-MM-DD');

    const { t, i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(i18n.language=='jp'?jaLocale:'');

    const url = useLocation();
    
    useEffect( () => {

                const searchParams = new URLSearchParams(url.search);
                const idValue = decode(searchParams.get('id'));
                if(idValue) {
                    setStyleNo(idValue);
                    filterData(idValue, "selectedData" )
                }
                
                axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                    response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });

                axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

                axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });

                axios
            .post(ServerUrl + "/get-styles", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                    response.data = apidecrypt(response.data)
                    setStyleNoList(response.data.data);
            });

            {
                (((getStaff === "Staff" && getStaffPermission.includes('View Data Input')) || getStaff == null )) 
                ?
                ""
                :
                window.location.href = "/dashboard" 
            }  
        
    }, [ url.search ]);

    /************ VALIDATION ERROR MESSAGE ( STYLE NO FIELD )*****************************/
    const dataInputValidation = (data) => {
        let errors ={};
        if(!styleNo){
            errors.styleNo = t("styleNoValidationErrorMessage");
        }
        setValidErrors(errors);
        return errors;
    }

    ///////////////////////////////////////////////////////////////////////////////////////
    /************ API CALL[POST]-(get-styles)  ( GET STYLE NO LIST )***********************/
    const styleData = (pcuData) => {

        axios
        .post(ServerUrl + "/get-styles",
        apiencrypt({
            "company_id" : getLoginCompanyId, 
            "workspace_id" : getWorkspaceId,
            "factory_id": factory,
            "pcu_id": pcuData,
            "buyer_id": buyer,
            "staff_id": staff_id,
        }))
        .then( (response) => {
            response.data = apidecrypt(response.data)
            setStyleNoList(response.data.data);
        })

    };

    /************ CLEARS THE SELECTED VALUES (CLEAR BUTTON) ******************************/
    const clearData = () => {

        setFactory( () => "defaultValue")
        setBuyer( () => "defaultValue")
        setPcu( () => "defaultValue")
        setStyleNo( () => "defaultValue")

        setFactory( () => "")
        setBuyer( () => "")
        setPcu( () => "")
        setStyleNo( () => "")

        setFilterProductionDetails(false);
        setValidErrors( () => "");

        setCompletedQtyRate( () => "");
    };

    const refreshData = () => {

        setProductionCutDateDetails(() => "");
        setProductionSewPackDateDetails(() => "");
        setProductionCutCalendarDataDetails(() => "");
        setProductionSewPackCalendarDataDetails(() => "");
        setProductionSkuDataDetails(() => "");
        setProductionSewPackSkuDataDetails(() => "");
        setCutActualUpdatedData(() => "");
        setSewPackActualUpdatedData(() => "");
        setCutCurrentDateTargetValue(() => "");
        setSewCurrentDateTargetValue(() => "");
        setPackCurrentDateTargetValue(() => "");
        setFilterProductionDetails(false);
    };

    var cutEstKnobColorValue = "";
    var sewEstKnobColorValue = "";
    var packEstKnobColorValue = "";
    
    var cutReqKnobColorValue = "";
    var sewReqKnobColorValue = "";
    var packReqKnobColorValue = "";

    var cutTargetValue = "";
    var cutCompletedRate = "";
    var sewTargetValue = "";
    var sewCompletedRate = "";
    var packTargetValue = "";
    var packCompletedRate = "";

    ////////////////////////////////////////////////////////////////////////////////////////
    /************ API CALL[POST]-(get-calendar-data) (Cutting)( GET CALENDAR DATA )*******/
    const filterData = (e, data) =>
    {
                cutEstKnobColorValue = "";
                sewEstKnobColorValue = "";
                packEstKnobColorValue = "";

                cutReqKnobColorValue = "";
                sewReqKnobColorValue = "";
                packReqKnobColorValue = "";

            /***** ACTIVE - CUTTING TAB *****/
            setBasicTab('1');
            setActiveTab('active');   
            setCut(true)
            setSew(false)
            setPack(false)

            /********************************/
            if( data == "selectedData" ){

                    axios
                    .post(ServerUrl + "/get-calendar-data",
                    apiencrypt({
                        "company_id" :  getLoginCompanyId,
                        "workspace_id" : getWorkspaceId,
                        "factory_id": factory ? factory : "0" ,
                        "pcu_id": pcu ? pcu : "0",
                        "buyer_id": buyer ? buyer : "0",
                        "order_id": e,
                        "type_of_production": 'Cut'
                    }))
                    .then( (response) => {
                    response.data = apidecrypt(response.data)
                        if( response.data.status_code == 200 ){
                            if( response.data.data.prodDetails.cutStartDate == null &&
                                response.data.data.prodDetails.cutEndDate == null  )
                            {
                                Swal.fire({
                                            title: t("incompleteOrderTitleAlert"),
                                            text: t("incompleteOrderTextAlert"),
                                            icon: "warning",
                                            button: t("okLabel"),
                                            confirmButton: "#009688",
                                            allowOutsideClick: false
                                })
                                .then( ( result ) => {
                                    if(result.isConfirmed)
                                    {
                                        clearData();
                                        /***** ACTIVE - CUTTING TAB *****/
                                            setBasicTab('1');
                                            setActiveTab('active');   
                                            setCut(true)
                                            setSew(false)
                                            setPack(false)
                                        /********************************/
                                    }
                                })
                            }
                            else {
                                let cutOrderEndDate = response.data.data.prodDetails.cutEndDate;
                                cutTargetValue = "";
                                cutCompletedRate = "";
                                setCutEndDate(cutOrderEndDate);
                                var dayByDayUpdates = (response.data.data.dayByDayUpdates).length == 0 ? "0" : response.data.data.dayByDayUpdates;
                                setCutWeekOff(response.data.data.weekOffs);
                                setCompletedQtyRate( () => "");
                                setFilterProductionDetails(true);
                                setSizes(response.data.data.sizes);
                                setColors(response.data.data.colors);
                                setCutActualUpdatedData(dayByDayUpdates);
                                setCutHolidayList(response.data.data.holidays);
                                setCutKnobChart(response.data.data.knobChart);
                                setProductionSkuDataDetails(response.data.data.skuData);
                                setProductionCutDateDetails(response.data.data.prodDetails);
                                setProductionCutCalendarDataDetails(response.data.data.CalendarData);                        
                                setCutPendingQty(response.data.data.knobChart.pendingQuantity);

                                setDeliveryDate(response.data.delivery_date);
                                setDeliveryDateStatus(response.data.delivery_date_exceed);

                                getTargetValue(response.data.data.CalendarData);   
                                orderDelay(response.data.data.prodDetails, "cut", response.data.data.knobChart.pendingQuantity);
                                
                                /***************TARGET VALUE [KNOB CHART - [MAXIMUM](Required && Estimated)] ****************/
                                    if( currentDate > cutOrderEndDate &&  response.data.data.knobChart.pendingQuantity > 0){
                                        setCutCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                                        cutTargetValue=response.data.data.knobChart.pendingQuantity;
                                    }
                                    else {
                                        if( response.data.data.knobChart.pendingQuantity > 0 ){
                                            (response.data.data.CalendarData)
                                            .filter( data => data.date_of_production == currentDate )
                                            .map( (mapData) =>{
                                                setCutCurrentDateTargetValue(mapData.target_value);
                                                cutTargetValue=mapData.target_value;
                                            }
                                            )
                                        } else{
                                            cutTargetValue=0;
                                        }
                                    }

                                /***************************** CUTTING QUANTITY RATE [KNOB CHART] *****************************/
                                    var completedCutQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0);
                                    if( completedCutQtyDateList.length !== 0 || Object.keys(completedCutQtyDateList).length !== 0){
                                        let completedCutQtyDateCount = Object.keys(completedCutQtyDateList).length;
                                        let completedCutQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedCutQtyDateCount));
                                        setCompletedQtyRate(completedCutQtyRate);
                                        cutCompletedRate = completedCutQtyRate;
                                    } else {
                                        setCompletedQtyRate('0');
                                        cutCompletedRate = 0;
                                    }

                                    if(cutKnobChart.pendingQuantity === 0){ setPendingStatus("0") }
                                    
                                    var cutEstimatedRatePercent = cutTargetValue > 0 ? Math.floor((cutCompletedRate/cutTargetValue)*100) : 0;
                                    var cutRequiredRatePercent = cutTargetValue > 0 ? Math.floor((response.data.data.knobChart.pendingQuantity/cutTargetValue)*100) : 0;
                                /***************************************** ORDER STATUS  ***************************************/
                                    if( currentDate >= response.data.data.prodDetails.cutStartDate && 
                                        currentDate <= response.data.data.prodDetails.cutEndDate ){
                                            if(response.data.data.knobChart.pendingQuantity > 0){

                                                if( cutEstimatedRatePercent >= 0 && cutEstimatedRatePercent <= 50 ){
                                                    cutEstKnobColorValue = "#EE335E";
                                                } else if( cutEstimatedRatePercent > 50 && cutEstimatedRatePercent <= 99 ){
                                                    cutEstKnobColorValue = "#FEC901";
                                                } else if( cutEstimatedRatePercent > 99 ){
                                                    cutEstKnobColorValue = "#009688";
                                                }

                                                if( cutRequiredRatePercent >= 0 && cutRequiredRatePercent <= 50 ){
                                                    cutReqKnobColorValue = "#009688";
                                                } else if( cutRequiredRatePercent > 50 && cutRequiredRatePercent <= 99 ){
                                                    cutReqKnobColorValue = "#FEC901";
                                                } else if( cutRequiredRatePercent > 99 ){
                                                    cutReqKnobColorValue = "#EE335E";
                                                }

                                            } else{
                                                cutEstKnobColorValue = "#545454";
                                                cutReqKnobColorValue = "#545454";
                                            }
                                    
                                    } else{
                                        if( currentDate < response.data.data.prodDetails.cutStartDate ){ cutEstKnobColorValue = "#545454", cutReqKnobColorValue = "#545454" }
                                        
                                        if( currentDate > response.data.data.prodDetails.cutEndDate ){
                                            if(response.data.data.knobChart.pendingQuantity > 0){
                                                cutEstKnobColorValue = "#EE335E";
                                                cutReqKnobColorValue = "#EE335E";
                                            } else{
                                                cutEstKnobColorValue = "#545454";
                                                cutReqKnobColorValue = "#545454";
                                            }
                                        }
                                    }
                                    knobChart( cutCompletedRate, cutTargetValue, cutEstKnobColorValue, cutReqKnobColorValue, "knob1", response.data.data.knobChart.reqOutputRate, "knob2");
                            }
                        } else if( response.data.status_code == 401 ){
                            Swal.fire({
                                title: response.data.error.order_id,
                                icon: "warning",
                                button: t("okLabel"),
                                confirmButton: "#009688",
                                allowOutsideClick: false
                            })
                        }
                    })

            } else{
                    let retval = dataInputValidation();
                    if(Object.keys(retval).length == 0)
                    {
                        setPcu(e);
                        
                        axios
                        .post(ServerUrl + "/get-calendar-data",
                        apiencrypt({
                            "company_id" :  getLoginCompanyId,
                            "workspace_id" : getWorkspaceId,
                            "factory_id": factory ? factory : "0" ,
                            "pcu_id": pcu ? pcu : "0",
                            "buyer_id": buyer ? buyer : "0",
                            "order_id": styleNo,
                            "type_of_production": 'Cut'
                        }))
                        .then( (response) => {
                            response.data = apidecrypt(response.data)

                            if( response.data.status_code == 200){
                                if( response.data.data.prodDetails.cutStartDate == null &&
                                    response.data.data.prodDetails.cutEndDate == null  )
                                {
                                    Swal.fire({
                                                title: t("incompleteOrderTitleAlert"),
                                                text: t("incompleteOrderTextAlert"),
                                                icon: "warning",
                                                button: t("okLabel"),
                                                confirmButton: "#009688",
                                                allowOutsideClick: false
                                    })
                                    .then( ( result ) => {
                                        if(result.isConfirmed)
                                        {
                                            clearData();
                                            /***** ACTIVE - CUTTING TAB *****/
                                                setBasicTab('1');
                                                setActiveTab('active');   
                                                setCut(true)
                                                setSew(false)
                                                setPack(false)
                                            /********************************/
                                        }
                                    })
                                }
                                else {

                                    let cutOrderEndDate = response.data.data.prodDetails.cutEndDate;
                                    cutTargetValue = "";
                                    cutCompletedRate = "";
                                    setCutEndDate(cutOrderEndDate);
                                    let dayByDayUpdates = (response.data.data.dayByDayUpdates).length == 0 ? "0" : response.data.data.dayByDayUpdates;

                                    setCutWeekOff(response.data.data.weekOffs);

                                    setCompletedQtyRate( () => "");
                                    setFilterProductionDetails(true);
                                    setSizes(response.data.data.sizes);
                                    setColors(response.data.data.colors);
                                    setCutActualUpdatedData(dayByDayUpdates);
                                    setCutHolidayList(response.data.data.holidays);
                                    setCutKnobChart(response.data.data.knobChart);
                                    setProductionSkuDataDetails(response.data.data.skuData);
                                    setProductionCutDateDetails(response.data.data.prodDetails);
                                    setProductionCutCalendarDataDetails(response.data.data.CalendarData);                           
                                    setCutPendingQty(response.data.data.knobChart.pendingQuantity);

                                    setDeliveryDate(response.data.delivery_date);
                                    setDeliveryDateStatus(response.data.delivery_date_exceed);

                                    getTargetValue(response.data.data.CalendarData);   
                                    orderDelay(response.data.data.prodDetails, "cut", response.data.data.knobChart.pendingQuantity);
                                    
                                    /***************TARGET VALUE [KNOB CHART - [MAXIMUM](Required && Estimated)] ****************/
                                    if( currentDate > cutOrderEndDate &&  response.data.data.knobChart.pendingQuantity > 0){
                                        setCutCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                                        cutTargetValue=response.data.data.knobChart.pendingQuantity;
                                    }
                                    else {
                                        if( response.data.data.knobChart.pendingQuantity > 0 ){
                                            (response.data.data.CalendarData)
                                            .filter( data => data.date_of_production == currentDate )
                                            .map( (mapData) =>{
                                                setCutCurrentDateTargetValue(mapData.target_value);
                                                cutTargetValue = mapData.target_value;
                                            }
                                            )
                                        } else{
                                            cutTargetValue = 0;
                                        }
                                    }

                                    /***************************** CUTTING QUANTITY RATE [KNOB CHART] *****************************/
                                    let completedCutQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0);
                                    if( completedCutQtyDateList.length !== 0 || Object.keys(completedCutQtyDateList).length !== 0){
                                        let completedCutQtyDateCount = Object.keys(completedCutQtyDateList).length;
                                        let completedCutQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedCutQtyDateCount));
                                        setCompletedQtyRate(completedCutQtyRate);
                                        cutCompletedRate = completedCutQtyRate;
                                    } else {
                                        setCompletedQtyRate('0');
                                        cutCompletedRate = 0;
                                    }
                                    if(cutKnobChart.pendingQuantity === 0){ setPendingStatus("0") }

                                    var cutEstimatedRatePercent = cutTargetValue > 0 ? Math.floor((cutCompletedRate/cutTargetValue)*100) : 0;
                                    var cutRequiredRatePercent = cutTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/cutTargetValue)*100) : 0 ;

                                    /***************************************** ORDER STATUS  ***************************************/
                                    if( currentDate >= response.data.data.prodDetails.cutStartDate && 
                                        currentDate <= response.data.data.prodDetails.cutEndDate ){
                                            if( response.data.data.knobChart.pendingQuantity > 0 ){

                                                if( cutEstimatedRatePercent >= 0 && cutEstimatedRatePercent <= 50 ){
                                                    cutEstKnobColorValue = "#EE335E";
                                                } else if( cutEstimatedRatePercent > 50 && cutEstimatedRatePercent <= 99 ){
                                                    cutEstKnobColorValue = "#FEC901";
                                                } else if( cutEstimatedRatePercent > 99 ){
                                                    cutEstKnobColorValue = "#009688";
                                                }

                                                if( cutRequiredRatePercent >= 0 && cutRequiredRatePercent <= 50 ){
                                                    cutReqKnobColorValue = "#009688";
                                                } else if( cutRequiredRatePercent > 50 && cutRequiredRatePercent <= 99 ){
                                                    cutReqKnobColorValue = "#FEC901";
                                                } else if( cutRequiredRatePercent > 99 ){
                                                    cutReqKnobColorValue = "#EE335E";
                                                }
                                            } else{
                                                cutEstKnobColorValue = "#545454";
                                                cutReqKnobColorValue = "#545454";
                                            }
                                    } else{
                                        if( currentDate < response.data.data.prodDetails.cutStartDate ){
                                                cutEstKnobColorValue = "#545454";
                                                cutReqKnobColorValue = "#545454";
                                            }
                                        
                                        if( currentDate > response.data.data.prodDetails.cutEndDate ){
                                            if(response.data.data.knobChart.pendingQuantity > 0){
                                                cutEstKnobColorValue = "#EE335E";
                                                cutReqKnobColorValue =  "#EE335E";
                                            } else{
                                                cutEstKnobColorValue = "#545454";
                                                cutReqKnobColorValue = "#545454";
                                            }
                                        }
                                    }
                                    knobChart( cutCompletedRate, cutTargetValue, cutEstKnobColorValue, cutReqKnobColorValue, "knob1", response.data.data.knobChart.reqOutputRate, "knob2");
                                
                                }
                            } else if( response.data.status_code == 401 ){
                                Swal.fire({
                                    title: response.data.error.order_id,
                                    icon: "warning",
                                    button: t("okLabel"),
                                    confirmButton: "#009688",
                                    allowOutsideClick: false
                                })
                            }
                        })

                    }
            }
            /********************************/

            setCutCurrentDateTargetValue( () => "");
            setSewCurrentDateTargetValue( () => "");
            setPackCurrentDateTargetValue( () => "");
    };

    ////////////////////////////////////////////////////////////////////////////////////////
    /******* API CALL[POST]-(get-calendar-data) (Sewing, Packing)( GET CALENDAR DATA )*****/
    const sewPackData = (data) =>
    {
            cutEstKnobColorValue = "";
            sewEstKnobColorValue = "";
            packEstKnobColorValue = "";

            cutReqKnobColorValue = "";
            sewReqKnobColorValue = "";
            packReqKnobColorValue = "";
            
            axios  
            .post(ServerUrl + "/get-calendar-data",
            apiencrypt({
                "company_id" :getLoginCompanyId ,
                "workspace_id" : getWorkspaceId,
                "factory_id": factory ? factory : "0" ,
                "pcu_id": pcu ? pcu : "0",
                "buyer_id": buyer ? buyer : "0",
                "order_id": styleNo,
                "type_of_production": data
            }))
            .then( (response) => {
                response.data = apidecrypt(response.data)

                if( response.data.status_code == 200){
                    if( response.data.data.prodDetails.sewStartDate === null &&
                        response.data.data.prodDetails.sewEndDate === null  ||
                        response.data.data.prodDetails.packStartDate === null &&
                        response.data.data.prodDetails.packEndDate === null )
                    {
                        Swal.fire({
                                    title: t("incompleteOrderTitleAlert"),
                                    text: t("incompleteOrderTextAlert"),
                                    icon: "warning",
                                    button: t("okLabel"),
                                    confirmButton: "#009688",
                                    allowOutsideClick: false
                        })
                        .then( ( result ) => {
                            if(result.isConfirmed)
                            {
                                clearData();
                            }
                        })
                    }
                    else {
                        if( response.data.ProductionType === "Sew")
                        {
                            sewTargetValue = "";
                            sewCompletedRate = "";

                            let currentDate = Moment().format('YYYY-MM-DD');
                            let  sewOrderEndDate = response.data.data.prodDetails.sewEndDate;
                            setSewEndDate(sewOrderEndDate);

                            let sewDayByDayUpdates = (response.data.data.dayByDayUpdates).length == 0 ? "0" : response.data.data.dayByDayUpdates;
                            setSewWeekOff(response.data.data.weekOffs);
                            setCompletedQtyRate( () => "");
                            setSewPackActualUpdatedData(sewDayByDayUpdates);
                            setProductionSewPackCalendarDataDetails(response.data.data.CalendarData);
                            setProductionSewPackDateDetails(response.data.data.prodDetails);
                            setProductionSewPackSkuDataDetails( response.data.data.skuData);
                            setSewKnobChart(response.data.data.knobChart);
                            setSewHolidayList(response.data.data.holidays);

                            setDeliveryDate(response.data.delivery_date);
                            setDeliveryDateStatus(response.data.delivery_date_exceed);

                            orderDelay(response.data.data.prodDetails, "sew", response.data.data.knobChart.pendingQuantity);
                            setSewPendingQty(response.data.data.knobChart.pendingQuantity);

                            if( currentDate > sewOrderEndDate &&  response.data.data.knobChart.pendingQuantity > 0)
                            {
                                setSewCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                                sewTargetValue = response.data.data.knobChart.pendingQuantity;
                            }
                            else {
                                if( response.data.data.knobChart.pendingQuantity > 0 ){
                                    (response.data.data.CalendarData)
                                    .filter( data => data.date_of_production == Moment().format('YYYY-MM-DD') )
                                    .map( (mapData) => 
                                    {
                                    setSewCurrentDateTargetValue(mapData.target_value);
                                    sewTargetValue = mapData.target_value;
                                    }
                                    )
                                } else{
                                    sewTargetValue = 0;
                                }
                            }
                            
                            /***************************** SEWING QUANTITY RATE [KNOB CHART] *****************************/
                            let completedSewQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0)
                            if( completedSewQtyDateList.length !== 0 || Object.keys(completedSewQtyDateList).length !== 0 ){
                                let completedSewQtyDateCount = Object.keys(completedSewQtyDateList).length;
                                let completedSewQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedSewQtyDateCount));
                                setCompletedQtyRate(completedSewQtyRate);
                                sewCompletedRate = completedSewQtyRate;
                            } else {
                                setCompletedQtyRate('0');
                                sewCompletedRate = 0;
                            }

                            if(sewKnobChart.pendingQuantity === 0){ setPendingStatus("0")}
                              
                            var sewEstimatedRatePercent = sewTargetValue > 0 ? Math.floor((sewCompletedRate/sewTargetValue)*100) : 0;
                            var sewRequiredPcsPercent = sewTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/sewTargetValue)*100) : 0 ;

                            /***************************************** ORDER STATUS  ***************************************/
                            if( currentDate >= response.data.data.prodDetails.sewStartDate && 
                                currentDate <= response.data.data.prodDetails.sewEndDate ){

                                    if(response.data.data.knobChart.pendingQuantity > 0){

                                        if( sewEstimatedRatePercent >= 0 && sewEstimatedRatePercent <= 50 ){
                                            sewEstKnobColorValue = "#EE335E";
                                        } else if( sewEstimatedRatePercent > 50 && sewEstimatedRatePercent <= 99 ){
                                            sewEstKnobColorValue = "#FEC901";
                                        } else if( sewEstimatedRatePercent > 99 ){
                                            sewEstKnobColorValue = "#009688";
                                        }
                                        
                                        if( sewRequiredPcsPercent >= 0 && sewRequiredPcsPercent <= 50 ){
                                            sewReqKnobColorValue = "#009688";
                                        } else if( sewRequiredPcsPercent > 50 && sewRequiredPcsPercent <= 99 ){
                                            sewReqKnobColorValue = "#FEC901";
                                        } else if( sewRequiredPcsPercent > 99 ){
                                            sewReqKnobColorValue = "#EE335E";
                                        }

                                    } else{
                                        sewEstKnobColorValue = "#545454";
                                        sewReqKnobColorValue =  "#545454";
                                    }
                            
                            } else{
                                if( currentDate < response.data.data.prodDetails.sewStartDate ){
                                        sewEstKnobColorValue = "#545454";
                                        sewReqKnobColorValue =  "#545454";
                                    }
                                if( currentDate > response.data.data.prodDetails.sewEndDate ){
                                    if(response.data.data.knobChart.pendingQuantity > 0){
                                        sewEstKnobColorValue = "#EE335E";
                                        sewReqKnobColorValue =  "#EE335E";

                                    } else{
                                        sewEstKnobColorValue = "#545454";
                                        sewReqKnobColorValue =  "#545454";
                                    }
                                }
                            }
                            
                            knobChart( sewCompletedRate, sewTargetValue, sewEstKnobColorValue, sewReqKnobColorValue, "knob3", response.data.data.knobChart.reqOutputRate, "knob4");

                        }
                        else if( response.data.ProductionType === "Pack")
                        {
                            packTargetValue = "";
                            packCompletedRate = "";

                            let currentDate = Moment().format('YYYY-MM-DD');
                            
                            let  packOrderEndDate = response.data.data.prodDetails.packEndDate;   
                            setPackEndDate(packOrderEndDate);
                            
                            let packDayByDayUpdates = (response.data.data.dayByDayUpdates).length == 0 ? "0" : response.data.data.dayByDayUpdates;
                            setPackWeekOff(response.data.data.weekOffs);
                            setCompletedQtyRate( () => "");
                            setSewPackActualUpdatedData(packDayByDayUpdates);
                            setProductionSewPackCalendarDataDetails(response.data.data.CalendarData)
                            setProductionSewPackDateDetails(response.data.data.prodDetails);
                            setProductionSewPackSkuDataDetails( response.data.data.skuData)
                            setPackKnobChart(response.data.data.knobChart);
                            setPackHolidayList(response.data.data.holidays);

                            setDeliveryDate(response.data.delivery_date);
                            setDeliveryDateStatus(response.data.delivery_date_exceed);

                            orderDelay(response.data.data.prodDetails, "pack", response.data.data.knobChart.pendingQuantity);
                            setPackPendingQty(response.data.data.knobChart.pendingQuantity);

                            if( currentDate > packOrderEndDate &&  response.data.data.knobChart.pendingQuantity > 0){
                                setPackCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                                packTargetValue = response.data.data.knobChart.pendingQuantity;
                            }
                            else {
                                if( response.data.data.knobChart.pendingQuantity > 0 ){
                                    (response.data.data.CalendarData)
                                    .filter( data => data.date_of_production == Moment().format('YYYY-MM-DD') )
                                    .map( (mapData) => 
                                    {
                                        setPackCurrentDateTargetValue(mapData.target_value);
                                        packTargetValue = mapData.target_value;
                                    }
                                    )
                                } else{
                                    packTargetValue = 0;
                                }
                            }

                            /***************************** PACKING QUANTITY RATE [KNOB CHART] *****************************/
                            let completedPackQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0)
                            if( completedPackQtyDateList.length !== 0 ||  Object.keys(completedPackQtyDateList).length !== 0){
                                let completedPackQtyDateCount = Object.keys(completedPackQtyDateList).length;
                                let completedPackQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedPackQtyDateCount));
                                setCompletedQtyRate(completedPackQtyRate);
                                packCompletedRate = completedPackQtyRate;
                            } else {
                                setCompletedQtyRate('0');
                                packCompletedRate = 0;
                            }

                            if(packKnobChart.pendingQuantity === 0){ setPendingStatus("0") }

                            var packEstimatedRatePercent = packTargetValue > 0 ? Math.floor((packCompletedRate/packTargetValue)*100) : 0;
                            var packRequiredRatePercent = packTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/packTargetValue)*100) : 0 ;

                            /***************************************** ORDER STATUS  ***************************************/
                            if( currentDate >= response.data.data.prodDetails.packStartDate && 
                                currentDate <= response.data.data.prodDetails.packEndDate ){
                                    if(response.data.data.knobChart.pendingQuantity > 0){

                                        if( packEstimatedRatePercent >= 0 && packEstimatedRatePercent <= 50 ){
                                            packEstKnobColorValue = "#EE335E";
                                        } else if( packEstimatedRatePercent > 50 && packEstimatedRatePercent <= 99 ){
                                            packEstKnobColorValue = "#FEC901";
                                        } else if( packEstimatedRatePercent > 99 ){
                                            packEstKnobColorValue = "#009688";
                                        }

                                        if( packRequiredRatePercent >= 0 && packRequiredRatePercent <= 50 ){
                                            packReqKnobColorValue = "#009688";
                                        } else if( packRequiredRatePercent > 50 && packRequiredRatePercent <= 99 ){
                                            packReqKnobColorValue = "#FEC901";
                                        } else if( packRequiredRatePercent > 99 ){
                                            packReqKnobColorValue = "#EE335E";
                                        }

                                    } else{
                                        packEstKnobColorValue = "#545454";
                                        packReqKnobColorValue = "#545454";
                                    }
                            
                            } else{
                                if( currentDate < response.data.data.prodDetails.packStartDate ){
                                        packEstKnobColorValue = "#545454";
                                        packReqKnobColorValue = "#545454";
                                    }
                                
                                if( currentDate > response.data.data.prodDetails.packEndDate ){
                                    if(response.data.data.knobChart.pendingQuantity > 0){
                                        packEstKnobColorValue = "#EE335E";
                                        packReqKnobColorValue = "#EE335E";
                                    } else{
                                        packEstKnobColorValue = "#545454";
                                        packReqKnobColorValue = "#545454";
                                    }
                                }
                            }

                            knobChart( packCompletedRate, packTargetValue, packEstKnobColorValue, packReqKnobColorValue, "knob5", response.data.data.knobChart.reqOutputRate, "knob6");

                        }
                    }
                } else if( response.data.status_code == 401 ){
                    Swal.fire({
                        title: response.data.error.order_id,
                        icon: "warning",
                        button: t("okLabel"),
                        confirmButton: "#009688",
                        allowOutsideClick: false
                    })
                }
            })

            setCutCurrentDateTargetValue( () => "");
            setSewCurrentDateTargetValue( () => "");
            setPackCurrentDateTargetValue( () => "");
    };

    const knobChart = ( completedRate, targetValue, knobColorValue, 
                        knobReqColorValue, idValue1, reqOutputRate, idValue2 ) => {

        var offsetArcEstimated = Knob({
            value : completedRate ? (completedRate) : 0,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 150,
            fgColor: knobColorValue,
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: targetValue ? ((targetValue) < (completedRate) ? completedRate : targetValue ) : 0
        });

        const list1 = document.getElementById(idValue1);
        if (list1.hasChildNodes()) 
        {
        list1.removeChild(list1.children[0]);
        }

        document.getElementById(idValue1).appendChild(offsetArcEstimated);

        var offsetArcRequired = Knob({
            value: reqOutputRate,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 150,
            fgColor: knobReqColorValue,
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: targetValue ? ((targetValue) < (reqOutputRate)  ? reqOutputRate : targetValue ) : 0
        });

        const list2 = document.getElementById(idValue2);
        if (list2.hasChildNodes()) 
        {
        list2.removeChild(list2.children[0]);
        }

        document.getElementById(idValue2).appendChild(offsetArcRequired);
    };

    
    /************  GET CLICKED CALENDAR DATE, TOGGLE OFF-CANVAS ******************************/
    const getCalendarData = (date)=>
    {
        setSelectedDate(date);
        toggleModal();
    };

    /***********************  ( Selected date's ) TARGET VALUE  ******************************/
    const getTargetValue = (date, prodType) => 
    {
        let cutArray = [];
        let sewArray = [];
        let packArray = [];
        
        if( prodType === "Cut" ){
            productionCutCalendarDataDetails
            .map( (mapData) => {
                if( date == mapData.date_of_production ){
                    cutArray.push( mapData.target_value );
                }
            })
            
            if( cutArray.length > 0 ){
                setTargetValue(cutArray[0]);
            } else {
                setTargetValue(cutKnobChart.pendingQuantity);
            }
        
        } 
        if( prodType === "Sew" ){
            productionSewPackCalendarDataDetails
            .map( (mapData) => {
                if( date == mapData.date_of_production ){
                    sewArray.push( mapData.target_value );
                }
            })
            
            if( sewArray.length > 0 ){
                setTargetValue(sewArray[0]);
            } else {
                setTargetValue(sewKnobChart.pendingQuantity);
            }

        }
        if( prodType === "Pack" ){
            productionSewPackCalendarDataDetails
            .map( (mapData) => {
                if( date == mapData.date_of_production ){
                    packArray.push( mapData.target_value );
                }
            })
            
            if( packArray.length > 0 ){
                setTargetValue(packArray[0]);
            } else {
                setTargetValue(packKnobChart.pendingQuantity);
            }

        }
    };
  
    /***********************  ( Selected date's ) ORDER DELAY STATUS *************************/
    const orderDelay = ( prodDetails, typeOfProd, orderPendingQty ) => 
    {
            if( typeOfProd === "cut"){
                let currentDate = Moment().format('Y-MM-DD');
                if(currentDate > prodDetails.cutEndDate && orderPendingQty >= 0)
                {
                    setOrderDelayStatus("1");
                }else{
                    setOrderDelayStatus("0");
                }



            }
            else if( typeOfProd === "sew"){
                let currentDate = Moment().format('Y-MM-DD');
                if( currentDate > prodDetails.sewEndDate &&  orderPendingQty >= 0 )
                {
                    setOrderDelayStatus("1");
                }else{
                    setOrderDelayStatus("0");
                }

            }
            else if( typeOfProd === "pack") {
                let currentDate = Moment().format('Y-MM-DD');
                if( currentDate > prodDetails.packEndDate &&  orderPendingQty >= 0 )
                {
                    setOrderDelayStatus("1");
                }else{
                    setOrderDelayStatus("0");
                }
            }     
    };

    /***********************  ( Selected date's ) DATA ENTRY RESTRICTION  *********************/
    const updateCalendarData = ( selectedDate, typeOfProd ) => 
    {
        if( typeOfProd === "cut"){

            let currentDate = Moment().format('Y-MM-DD');
            if( selectedDate <= currentDate )
            {
                setDataInputStatus("1");
            }else{
                setDataInputStatus("0");
            }

            if( getStaff === "Staff"  && 
                productionCutDateDetails.isCutAccomplished == 0 &&
                getStaffPermission.includes('Add Data Input') &&  
                selectedDate == currentDate){
                    setDataInputStatusStaffLogin("1");
            } 
            else if( getStaff === "Staff"  && 
                productionCutDateDetails.isCutAccomplished == 0 &&
                getStaffPermission.includes('Edit Data Input') &&  
                selectedDate < currentDate){
                setDataInputStatusStaffLogin("1");
            } else {
                setDataInputStatusStaffLogin("0");
            }

        }
        else if( typeOfProd === "sew"){
            let currentDate = Moment().format('Y-MM-DD');
            if( selectedDate <= currentDate )
            {
                setDataInputStatus("1");
            }else{
                setDataInputStatus("0");
            }

            if( getStaff === "Staff"  && 
            productionSewPackDateDetails.isSewAccomplished == 0 &&
            getStaffPermission.includes('Add Data Input') &&  
            selectedDate == currentDate){
                setDataInputStatusStaffLogin("1");
            } 
            else if( getStaff === "Staff"  && 
                productionSewPackDateDetails.isSewAccomplished == 0 &&
                getStaffPermission.includes('Edit Data Input') &&  
                selectedDate < currentDate){
                setDataInputStatusStaffLogin("1");
            } else {
                setDataInputStatusStaffLogin("0");
            }

        }
        else if( typeOfProd === "pack") {
            let currentDate = Moment().format('Y-MM-DD');
            if( selectedDate <= currentDate )
            {
                setDataInputStatus("1");
            }else{
                setDataInputStatus("0");
            }

            if( getStaff === "Staff"  && 
            productionSewPackDateDetails.isPackAccomplished == 0 &&
            getStaffPermission.includes('Add Data Input') &&  
            selectedDate == currentDate){
                setDataInputStatusStaffLogin("1");
            } 
            else if( getStaff === "Staff"  && 
                productionSewPackDateDetails.isPackAccomplished == 0 &&
                getStaffPermission.includes('Edit Data Input') &&  
                selectedDate < currentDate){
                setDataInputStatusStaffLogin("1");
            } else {
                setDataInputStatusStaffLogin("0");
            }
        }
    };

    /***********************  ORDER STATUS DELAYED MODAL  *************************************/
    const DataInputSkuDelayed = () => {
        return(
            <DataInputSkuDelayedModal 
                modal={modal} 
                toggle={toggleModal} 

                skuData={productionSkuDataDetails} 
                sewPackSkuData={productionSewPackSkuDataDetails}

                cutDayByDayUpdates={cutActualUpdatedData}
                setCutDayByDayUpdates={setCutActualUpdatedData}

                sewPackDayByDayUpdates={sewPackActualUpdatedData}
                setSewPackDayByDayUpdates={setSewPackActualUpdatedData}
                
                productionCutDateDetails={productionCutDateDetails}
                productionSewPackDateDetails={productionSewPackDateDetails}

                companyId={getLoginCompanyId}
                workspaceId={getWorkspaceId}
                orderId={styleNo} 
                actualDate={selectedDate}
                colors={colors}
                sizes={sizes}
                typeOfProduction={typeOfProd}
                cutKnobChart={setCutKnobChart}
                getcutKnobChart={cutKnobChart}
                sewKnobChart={setSewKnobChart}
                getsewKnobChart={sewKnobChart}
                getpackKnobChart={packKnobChart}
                packKnobChart={setPackKnobChart}
                prodSkuData={setProductionSkuDataDetails}
                sewPackProdSkuData={setProductionSewPackSkuDataDetails}
                dateDetails={setProductionCutDateDetails}
                sewPackDateDetails={setProductionSewPackDateDetails}
                calendarData={setProductionCutCalendarDataDetails}
                sewPackCalendarData={setProductionSewPackCalendarDataDetails}
                setSize={setSizes}
                setColors={setColors}

                orderStatus={orderDelayStatus}
                
                pendingtotal={Math.abs(cutKnobChart.pendingQuantity)}
                dataInputStatus={dataInputStatus}

                setCompletedQtyRate={setCompletedQtyRate}
                targetValue={targetValue}
                setTargetValue={setTargetValue}

                knobChartFn={knobChart}
                dataInputStatusStaffLogin={dataInputStatusStaffLogin}
                setCutCurrentDateTargetValue={setCutCurrentDateTargetValue}
                setSewCurrentDateTargetValue={setSewCurrentDateTargetValue}
                setPackCurrentDateTargetValue={setPackCurrentDateTargetValue}
            /> 
        ); 
    };

    return (
       <Fragment>
            <Row className="pgbgcolor">
               <Breadcrumbs mainTitle='Production Details' parent="Production Details" 
               title="Production Details" subTitle="Data of listed Orders "/>
            </Row>        

            <Container fluid={true} className="general-widget topaln">
                {/************ ****************************/}
                <Card>
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">

                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("factory")}</Label>
                                        <InputGroup>
                                            <Input
                                                className={`${errors.factories && "select-error-valid"}  form-control`}
                                                id="factory"
                                                name="dropDown"
                                                type="select"
                                                placeholder={t("selectFactory")}
                                                aria-label="factories"
                                                value={factory}
                                                onChange={(e) => (setFactory(e.target.value),styleData())}>
                                                <option value="defaultValue" selected >
                                                {t("selectFactory")}
                                                </option>
                                                {factories.map((factory) => 
                                                (
                                                    <option key={factory.id} value={factory.id}>
                                                    {factory.name}
                                                    </option>
                                                ))
                                                }
                                            </Input>
                                        </InputGroup>
                                        {errors.factories && (
                                            <span className="error-msg">{errors.factories}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}

                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label> {t("buyer")}</Label>
                                        <InputGroup>
                                            <Input
                                                className={`${errors.buyer && "select-error-valid"}  form-control`}
                                                name="dropDown"
                                                type="select"
                                                placeholder={t("buyer")}
                                                aria-label="buyer"
                                                value={buyer}
                                                onChange={(e) => (setBuyer(e.target.value),styleData())}>
                                                <option value="defaultValue" selected>{t("selectBuyer")}</option>
                                                {buyers.map((data) => (
                                                    <option key={data.id} value={data.id}>{data.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.buyer && (
                                            <span className="error-msg">{errors.buyer}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}

                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        <InputGroup>
                                            <Input
                                                id="pcu-id"
                                                type="select"
                                                placeholder="Select Status"
                                                className={`${errors.pcu && "select-error-valid"} form-control digits`}
                                                name="dropDown"
                                                value={pcu}
                                                onChange={(e) => (setPcu(e.target.value),styleData(e.target.value))}>
                                                <option  value="defaultValue" selected>
                                                {t("selectPCU")}
                                                </option>
                                                {Pcus.map((pcuv) => (
                                                    <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.pcu && (
                                            <span className="error-msg">{errors.pcu}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) 
                            :(
                            ""
                            )}

                            <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                    <Label>{t("styleNo")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            type="select"
                                            placeholder="Select Status"
                                            className={`${errors.styleNo && "select-error-valid"} form-control digits`}
                                            name="dropDown"
                                            value={styleNo}
                                            onChange={(e) => {refreshData(), setStyleNo(e.target.value)}}>
                                            <option  value="" selected>
                                            {t("selectStyleno")}
                                            </option>
                                            {styleNoList.map((styleList) => (
                                                <option key={styleList.id} value={styleList.id}>{styleList.style_no} 
                                                ({styleList.order_no})
                                                </option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                    {errors.styleNo && (
                                        <span className="error-msg">{errors.styleNo}</span>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col lg="3" md="6" sm="6" xs="6" 
                                 className="m-t-30 text-start">
                                <Button  title={t("click")} className="btn btn-primary  f-left m-r-10 m-b-5" onClick={() => filterData()}>
                                    {t("search")}
                                </Button>
                                {/* <Link to="/o" className="f-left"> */}
                                    <Button  title={t("click")} className="btn secondaryBtn" value="Clear" type="reset"
                                        onClick={() => { clearData() }}>{t("clear")}</Button>
                                {/* </Link> */}
                            </Col>

                            {( (orderDelayStatus==0) && 
                               (dataInputStatus=== "1"))
                            ?                 
                            <DataInputSkuModal
                                modal={modal} 
                                toggle={toggleModal} 

                                skuData={productionSkuDataDetails} 
                                sewPackSkuData={productionSewPackSkuDataDetails}

                                cutDayByDayUpdates={cutActualUpdatedData}
                                setCutDayByDayUpdates={setCutActualUpdatedData}

                                sewPackDayByDayUpdates={sewPackActualUpdatedData}
                                setSewPackDayByDayUpdates={setSewPackActualUpdatedData}

                                productionCutDateDetails={productionCutDateDetails}
                                productionSewPackDateDetails={productionSewPackDateDetails}

                                companyId={getLoginCompanyId}
                                workspaceId={getWorkspaceId}
                                orderId={styleNo} 
                                actualDate={selectedDate}
                                colors={colors}
                                sizes={sizes}
                                typeOfProduction={typeOfProd}
                                cutKnobChart={setCutKnobChart}
                                getcutKnobChart={cutKnobChart}
                                sewKnobChart={setSewKnobChart}
                                getsewKnobChart={sewKnobChart}
                                getpackKnobChart={packKnobChart}
                                packKnobChart={setPackKnobChart}
                                prodSkuData={setProductionSkuDataDetails}
                                sewPackProdSkuData={setProductionSewPackSkuDataDetails}

                                dateDetails={setProductionCutDateDetails}
                                sewPackDateDetails={setProductionSewPackDateDetails}

                                calendarData={setProductionCutCalendarDataDetails}
                                sewPackCalendarData={setProductionSewPackCalendarDataDetails}
                                setSize={setSizes}
                                setColors={setColors}
                                
                                orderStatus={orderDelayStatus}

                                pendingtotal={Math.abs(cutKnobChart.pendingQuantity)}
                                dataInputStatus={dataInputStatus}

                                cutPendingQty={cutPendingQty}

                                setCompletedQtyRate={setCompletedQtyRate}
                                targetValue={targetValue}
                                setTargetValue={setTargetValue}

                                knobChartFn={knobChart}
                                dataInputStatusStaffLogin={dataInputStatusStaffLogin}
                                setCutCurrentDateTargetValue={ setCutCurrentDateTargetValue}
                                setSewCurrentDateTargetValue={setSewCurrentDateTargetValue}
                                setPackCurrentDateTargetValue={setPackCurrentDateTargetValue}
                            />
                            :
                            "" 
                            } 

                            {
                                typeOfProd == 'Cut' ? 
                                ((orderDelayStatus==1) && selectedDate===getCurrentDate )
                                    ?
                                        cutPendingQty !== 0 ? 
                                        DataInputSkuDelayed()
                                        :
                                        ""
                                    :
                                    ""         
                                : ""
                            }

                            
                            {
                                typeOfProd == 'Sew' ? 
                                ((orderDelayStatus==1) && selectedDate===getCurrentDate )
                                    ?
                                        sewPendingQty !== 0 ? 
                                        DataInputSkuDelayed() 
                                        :
                                        ""
                                    :
                                    ""         
                                : ""
                            }

                            {
                                typeOfProd == 'Pack' ? 
                                ((orderDelayStatus==1) && selectedDate===getCurrentDate )
                                    ?
                                        packPendingQty !== 0 ? 
                                        DataInputSkuDelayed()
                                        :
                                        ""
                                    :
                                    ""         
                                : ""
                            }
                        

                        </Row>
                    </CardBody>
                </Card>
                
                {filterProductionDetails ?
                <CardBody>
                
                    <Nav tabs className=""
                        style={{ height: '10%', width: '100%', justifyContent: 'center'}} >
                        <Row style={{ width: '50%' }} xs="1" sm="3" md="3" lg="3">
                                            
                                <NavItem
                                    lg="`style={{ height: '30%', width: '33.3%'}}`"
                                    md="`style={{ height: '10%', width: '33.3%'}}`"
                                    sm="`style={{ height: '30%', width: '100%'}}`"
                                    className="text-center" >
                                    <NavLink  title={t("click")}className={BasicTab === '1' ? activeTab : ''} 
                                        onClick={() => {setBasicTab('1'),
                                            setCut(true)
                                            setSew(false)
                                            setPack(false)
                                            filterData();
                                            }}
                                        style={{ cursor:'pointer', borderColor: '#E6EDEF' }}>
                                        {BasicTab === '1' 
                                        ?  
                                        <>
                                        <img src={GreenCutting} />
                                        <strong className="f-w-500" style={{ marginLeft: '15px' }}>{t("cutting")}</strong>
                                        </>
                                        :
                                        <>
                                        <img src={GreyCutting} />
                                        <strong className="f-w-500" style={{ color: '#4C5259', marginLeft: '15px' }}>{t("cutting")}</strong>
                                        </>
                                        }
                                    </NavLink>
                                </NavItem >
                                        
                                <NavItem
                                    lg="`style={{ height: '30%', width: '33.3%'}}`"
                                    md="`style={{ height: '10%', width: '33.3%'}}`"
                                    sm="`style={{ height: '30%', width: '100%'}}`"
                                    className="text-center">
                                    <NavLink 
                                        title={t("click")}
                                        className={BasicTab === '2' ? activeTab : ''} 
                                        onClick={() => {setBasicTab('2'), 
                                        setCut(false)
                                        setSew(true)
                                        setPack(false) 
                                        sewPackData('Sew')}}
                                        style={{ cursor:'pointer', borderColor: '#E6EDEF' }}>
                                        {BasicTab === '2' 
                                        ?  
                                        <>
                                        <img src={GreenSewing} />
                                        <strong className="f-w-500" style={{ marginLeft: '15px' }}>{t("sewing")}</strong>
                                        </>
                                        :
                                        <>
                                        <img src={GreySewing} />
                                        <strong className="f-w-500" style={{ color: '#4C5259', marginLeft: '15px' }}>{t("sewing")}</strong>
                                        </>
                                        }
                                    </NavLink>
                                </NavItem >
                                
                                <NavItem
                                    className="text-center">
                                    <NavLink
                                     title={t("click")}
                                        className={BasicTab === '3' ? activeTab: ''} 
                                        onClick={() => {setBasicTab('3'),
                                        setCut(false)
                                        setSew(false)
                                        setPack(true) 
                                        sewPackData('Pack')}}
                                        style={{ cursor:'pointer', borderColor: '#E6EDEF' }}>
                                        {BasicTab === '3' 
                                        ?  
                                        <>
                                        <img src={GreenPacking} />
                                        <strong className="f-w-500" style={{ marginLeft: '15px' }}>{t("packing")}</strong>
                                        </>
                                        :
                                        <>
                                        <img src={GreyPacking} />
                                        <strong className="f-w-500" style={{ color: '#4C5259', marginLeft: '15px' }}>{t("packing")}</strong>
                                        </>
                                        }
                                    </NavLink>
                                </NavItem >
                        </Row>
                    </Nav>

                    <TabContent activeTab={BasicTab}>

                        <TabPane tabId="1">
                            <Row className="pt-4" md="1" lg="2" sm="1">
                                <Col>
                                    <Card className='shadow shadow-showcase' style={{ border:"none" }}>
                                            <Row style={{ padding: '30px' }}>

                                                <Col className="text-center label_align1">
                                                    { productionCutDateDetails.isCutAccomplished == 0
                                                    ?
                                                        (currentDate > productionCutDateDetails.cutEndDate) ? 
                                                        <>
                                                            <div className="media">
                                                                <img src={RedCutting}  width="45px" />
                                                                <div
                                                                    className="m-l-10 media-body label_align2 m-t-5 ">
                                                                    <span 
                                                                    style={{ color: '#EE335E',
                                                                    fontWeight: '500', 
                                                                    fontSize: '15px' 
                                                                    }} 
                                                                    > {t("cutting")} </span>
                                                                    <div className="f-12" 
                                                                        style={{color: '#EE335E', 
                                                                                fontWeight: 'normal',
                                                                                fontSize: '10px'
                                                                                }}>
                                                                        <i 
                                                                        className="fa fa-circle" 
                                                                        style={{color:'#EE335E',
                                                                                fontSize: '10px'
                                                                                }}>
                                                                        </i>
                                                                        <span 
                                                                        style={{ 
                                                                        }}>
                                                                        &nbsp;
                                                                            {t("delay")}
                                                                        </span> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>       
                                                        :
                                                        <>
                                                            <img src={GreenCutting}  width="45px" />
                                                            <strong className="text-center p-l-10 f-w-500" 
                                                                style={{ color: '#009688' }}>
                                                                {t("cutting")} 
                                                            </strong> 
                                                        </>
                                                    : 
                                                        ((productionCutDateDetails.cutAccomplishedDate !== null) &&
                                                        (productionCutDateDetails.cutAccomplishedDate <= productionCutDateDetails.cutEndDate)) ? 
                                                        <>
                                                            <div className="media">
                                                                <img src={GreenCutting} width="45px" />
                                                                <div className="m-l-5 media-body label_align2">
                                                                    <H6> {t("cutting")} </H6>
                                                                    <div className="f-12" 
                                                                        style={{color: '#009688', 
                                                                                fontWeight: 'normal',
                                                                                fontSize: '8px'
                                                                                }}>
                                                                        <i 
                                                                        className="fa fa-circle" 
                                                                        style={{color:'#009688',
                                                                                fontSize: '8px'
                                                                                }}>
                                                                        </i>
                                                                        <span 
                                                                        style={{ 
                                                                        }}>
                                                                        &nbsp;
                                                                            {t("completed")}
                                                                        </span> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="media">
                                                                    <img src={OrangeCutting} width="45px" />
                                                                    <div
                                                                        className="m-l-10 media-body label_align2 m-t-5 ">
                                                                        <span 
                                                                        style={{ color: '#E69020',
                                                                        fontWeight: '500', 
                                                                        fontSize: '15px' 
                                                                        }} 
                                                                        > {t("cutting")} </span>
                                                                        <div className="f-12" 
                                                                            style={{color: '#E69020', 
                                                                                    fontWeight: 'normal',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            <i 
                                                                            className="fa fa-circle" 
                                                                            style={{color:'#E69020',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            </i>
                                                                            <span 
                                                                            style={{ 
                                                                            }}>
                                                                            &nbsp;
                                                                                {t("delayComplete")}
                                                                            </span> 
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </>
                                                    }
                                                </Col>

                                                <Col className="text-center mt-1">
                                                    {t("startDate")}:
                                                    <Col className="p-l-10" > 
                                                        <strong className="f-w-600"> 
                                                        { PHPtoJSFormatConversion.format(new Date(productionCutDateDetails.cutStartDate)) }
                                                        </strong> 
                                                    </Col>
                                                </Col>
                                                <Col className="text-center mt-1" >
                                                    {t("endDate")}:
                                                    <Col className="p-l-15">
                                                        <strong  className="f-w-600"> 
                                                        { PHPtoJSFormatConversion.format(new Date(productionCutDateDetails.cutEndDate)) }
                                                        </strong>
                                                    </Col>
                                                </Col>
                                                <Col className="text-center mt-1" >
                                                    {t("delDate")} :
                                                        {deliveryDate ? 
                                                            deliveryDateStatus == 1 ? 
                                                            <strong className="f-w-600" style={{ color: '#EE335E' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong> 
                                                        : 
                                                            <strong className="f-w-600" style={{ color: '#000' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong>
                                                        : 
                                                            "-"
                                                        }
                                                </Col>

                                            </Row>
                                            <div style={{ backgroundColor: "#ECEDED", height: '40px',
                                                     cursor: 'pointer' }}>
                                                <div className="text-center m-t-10"  style={{ color:"#000000" }}
                                                    onClick={() => { setTypeOfProd('Cut'), toggleSkuModal() } }> 
                                                    {t("skuwiseProgress")}
                                                </div>                                    
                                            </div>
                                            <SkuwiseProgressModal 
                                                modal={skuModal} 
                                                toggle={toggleSkuModal} 
                                                skuData={productionSkuDataDetails}
                                                colors={colors}
                                                sizes={sizes} 
                                                sewPackSkuData={productionSewPackSkuDataDetails}
                                                typeOfProduction={typeOfProd}
                                                productionCutDateDetails={productionCutDateDetails}
                                                productionSewPackDateDetails={productionSewPackDateDetails}
                                            />
                                            <Row  md="1" lg="3" sm="1" xs="1" style={{ borderBottom:" 1px solid #EEEEEE", margin: '2px' }}>
                                                <div className='text-center m-t-10 m-b-10' style={{ borderRight: "1px solid #EEEEEE"}}>
                                                    <div className=' m-t-10 m-b-10'>
                                                    <strong> {cutKnobChart.completedQuantity} </strong>  
                                                    <Col><small className=" qtyCard " style={{ color:"#7B7B7B" }}>{t("completed")}</small> </Col>
                                                    </div>
                                                </div>
                                                <div className='text-center m-t-10 m-b-10'style={{ borderRight: "1px solid #EEEEEE",height:"100%"}} >
                                                    <div className=' m-t-10 m-b-10'>
                                                        <strong> {cutKnobChart.totalQuantity} </strong>  
                                                    <Col> <small className="qtyCard"  style={{ color:"#7B7B7B" }}>{t("total")}</small> </Col>
                                                    </div>
                                                </div>
                                                <div className='text-center m-t-10 m-b-10'>
                                                    <div className=' m-t-10 m-b-10'>
                                                        <span style={{ color: "#EE335E"}}> 
                                                            <strong>
                                                            {Math.abs(cutKnobChart.pendingQuantity)}
                                                            </strong>
                                                        </span> 
                                                        <Col> 
                                                            <small className="qtyCard">
                                                            <span style={{ color: "#EE335E"}}>{t("Pending")}</span> 
                                                            </small>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Row>
                                            <CardBody className="m-1">
                                                {/*****************************************   KNOB CHART  **************************************/}
                                                <Row md="1" lg="2" className='m-t-40'>
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                          
                                                                <small style={{ color: '#000000', fontSize: '14px',fontWeight:'600'  }}>
                                                                {t("currentOutputRate")}
                                                                </small>
                                                                
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob1"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small style={{ color:"#7B7B7B" }}>
                                                                {t("currentDate")}
                                                                </small>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934',  fontSize: '14px' }}>
                                                                <strong>
                                                                    <small className="text-center" >
                                                                    {PHPtoJSFormatConversion.format(new Date(currentDate)) } 
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col>
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                
                                                                <small style={{ color: '#2D2D2D', fontSize: '14px',fontWeight:'600' }}>
                                                                {t("requiredOutputRate")}
                                                                </small>
                                                                
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob2"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small style={{ color:"#7B7B7B" }}>
                                                                {t("requiredCompletionDate")}
                                                                </small>
                                                            </div>
                                                            <div style={{ color: '#242934', fontSize: '14px' }} className="text-center">
                                                                <strong>
                                                                    <small>
                                                                    { PHPtoJSFormatConversion.format(new Date(cutEndDate)) }
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                    </Card>
                                </Col>

                                <Col >
                                    <Card className='shadow shadow-showcase' style={{ border:"none" }}>
                                        <CardBody>
                                            <Row lg ="4" md="4" sm="4" style={{ paddingBottom: '15px'}}>
                                                <Col xl="3" md="3">
                                                    <Row>
                                                        <Col >
                                                            <Row className="f-right"
                                                                style={{borderRadius:'03px', backgroundColor: '#458C41', 
                                                                width: '15px', height: '15px'}}>
                                                            </Row>
                                                        </Col>
                                                        <Col  style={{color: '#3A3A3A',marginTop:"-2px" }}> 
                                                        {t("target")}                                                    
                                                        </Col>
                                                    </Row>      
                                                </Col>
                                                <Col xl="3" md="3">
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                            style={{borderRadius:'03px', backgroundColor: '#9BC34F', 
                                                            width: '15px', height: '15px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{ color: '#3A3A3A',marginTop:"-2px"  }}>  
                                                        {t("actual")}                                                    
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl="3" md="3">
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                        style={{borderRadius:'03px', backgroundColor: '#C4A950',
                                                        width: '15px', height: '15px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{  color: '#3A3A3A',marginTop:"-2px"  }}>  
                                                        {t("excess")}                                                     
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col xl="3" md="3">
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                        style={{borderRadius:'03px',backgroundColor: '#EE335E',
                                                        width: '15px', height: '15px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{  color: '#3A3A3A',marginTop:"-2px"  }}> 
                                                        {t("short")}                                                   
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
        
                                            {  
                                                productionCutCalendarDataDetails.length > 0 ?
                                                    productionCutCalendarDataDetails.map((cutCalendarData) => 
                                                    {
                                                        let actualQty=cutCalendarData.actual_value ? cutCalendarData.actual_value:0;
                                                        let targetQty=cutCalendarData.target_value ? cutCalendarData.target_value:0;
                                                        let absBalanceQty =Math.abs(targetQty-actualQty);
                                                        let cutBalanceQty = cutCalendarData.date_of_production <= Moment().format('Y-MM-DD') ? absBalanceQty : 0;
                                                        let balanceQty=cutBalanceQty?cutBalanceQty:0;

                                                            if (cutCalendarData.holiday_flag == 0) 
                                                            {
                                                                /**************************** TARGET QTY ****************************/
                                                                { ( orderDelayStatus === "1" &&
                                                                    cutCalendarData.date_of_production > productionCutDateDetails.cutEndDate  
                                                                )   
                                                                    ?
                                                                    cutData[ct] =
                                                                    {
                                                                        date: '',
                                                                        title: '',
                                                                        color: '',
                                                                    }  
                                                                    :
                                                                    cutData[ct] =
                                                                    {
                                                                        date: cutCalendarData.date_of_production,
                                                                        title: targetQty,
                                                                        color: '#458C41',
                                                                    } 
                                                                    ct++;
                                                                }
                                                                /**************************** ACTUAL QTY ****************************/
                                                                cutData[ct] =
                                                                {
                                                                    date: cutCalendarData.date_of_production,
                                                                    title: actualQty,
                                                                    color: '#9CC450',      
                                                                } 
                                                                ct++
                                                                if( productionCutDateDetails.cutEndDate < Moment().format('Y-MM-DD') )
                                                                {
                                                                        var len = ct;
                                                                        {productionCutCalendarDataDetails.map( (orderDelayData,al) => 
                                                                            {
                                                                                if( productionCutCalendarDataDetails.length == cur_ct &&
                                                                                    productionCutCalendarDataDetails.length == al+1 )
                                                                                {
                                                                                    if( orderDelayData.date_of_production !== Moment().format('Y-MM-DD') &&
                                                                                        cutKnobChart.pendingQuantity !== 0 )
                                                                                    {         
                                                                                        len++;
                                                                                        cutData[len] =
                                                                                        {
                                                                                            date: Moment().format('Y-MM-DD'),
                                                                                            title: '0',
                                                                                            color: '#9CC450', 
                                                                                        } 
                                                                                        len++;
                                                                                        cutData[len] =
                                                                                        {
                                                                                            date: Moment().format('Y-MM-DD'),
                                                                                            title: cutKnobChart.pendingQuantity,
                                                                                            color:  '#EE335E',
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        if( (orderDelayData.date_of_production == Moment().format('Y-MM-DD') && 
                                                                                        orderDelayData.target_value !== 0))
                                                                                        {
                                                                                            cutData[len] =
                                                                                            {
                                                                                                date: '',
                                                                                                title: '',
                                                                                                color: '',      
                                                                                            } 
                                                                                            len++;
                                                                                            cutData[len] =
                                                                                            {
                                                                                                date: '',
                                                                                                title: '',
                                                                                                color: ''           
                                                                                            } 
                                                                                        }
                                                                                        else{
                                                                                                if( cutKnobChart.pendingQuantity != 0 ){  
                                                                                                    cutData[len] =
                                                                                                    {
                                                                                                        date: Moment().format('Y-MM-DD'),
                                                                                                        title: '0',
                                                                                                        color: '#9CC450',  
                                                                                                    } 
                                                                                                    len++;
                                                                                                    cutData[len] =
                                                                                                    {
                                                                                                        date: Moment().format('Y-MM-DD'),
                                                                                                        title: cutKnobChart.pendingQuantity,
                                                                                                        color:  '#EE335E',
                                                                                                    }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    cur_ct++;
                                                                                }
                                                                            }
                                                                        )
                                                                        }
                                                                }
                                                                /**************************** PENDING QTY ****************************/
                                                                if( actualQty < targetQty )
                                                                {
                                                                    cutData[ct] =
                                                                    {
                                                                        date: cutCalendarData.date_of_production,
                                                                        title: balanceQty,
                                                                        color: '#EE335E'                                              
                                                                    }  
                                                                    ct++;
                                                                }
                                                                else {
                                                                    cutData[ct] =
                                                                    {
                                                                        date: cutCalendarData.date_of_production,
                                                                        title: balanceQty,
                                                                        color: '#C4A950'                                                
                                                                    } 
                                                                    ct++;
                                                                }
                                                            } 
                                                            else {
                                                                cutData[ct] =
                                                                {
                                                                    date: cutCalendarData.date_of_production,
                                                                    title: cutCalendarData.holiday_detail,
                                                                    color: '#5F5A5A'
                                                                };
                                                                ct++;
                                                                
                                                            }
                                                            cur_ct++;
                                                    })
                                                :
                                                    ""
                                            }
                                            
                                            { cutHolidayList ? 
                                                cutHolidayList.map(( cutHolidayData ) => {
                                                    let hol_ct = cutData.length;
                                                    let currentHoliday = Moment(cutHolidayData.start_date).format('YYYY-MM-DD');
                                                    if( cutHolidayData.days > 1 ){
                                                        for( let i=0; i < cutHolidayData.days; i++ )
                                                        {
                                                            let actualDate = Moment(currentHoliday).add(i,'d').format('YYYY-MM-DD');
                                                            cutData[hol_ct] =
                                                            {
                                                                date: actualDate,
                                                                title: cutHolidayData.name,
                                                                color: '#5F5A5A',
                                                            } 
                                                            hol_ct++;
                                                        } 
                                                    } 
                                                    else {
                                                        cutData[hol_ct] =
                                                        {
                                                            date: cutHolidayData.start_date,
                                                            title: cutHolidayData.name,
                                                            color: '#5F5A5A',
                                                        } 
                                                        hol_ct++;
                                                    }
                                                })
                                            : 
                                            "" 
                                            } 
 
                                            { (cutWeekOff)
                                            ? 
                                            cutWeekOff.map((cutWeekOffData) => {
                                                let weekOffStartDate = Moment(cutEndDate).add(1, 'd').format('YYYY-MM-DD');
                                                let ct_wkOf = cutData.length;
                                                cutData[ct_wkOf] = {
                                                        startRecur: weekOffStartDate,
                                                        title: 'Week Off',
                                                        daysOfWeek: [cutWeekOffData.days],
                                                        display: 'background',
                                                        backgroundColor: '#FFD1D1'
                                                }
                                                ct_wkOf++
                                            })
                                            :
                                            ""
                                            }  

                                            { cut ?       
                                            <FullCalendar
                                                defaultView="dayGridMonth"
                                                header={{
                                                    left: 'prev,next today',
                                                    center: 'title',
                                                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                                }}
                                                rerenderDelay={10}
                                                eventDurationEditable={false}
                                                editable={false}
                                                droppable={true}
                                                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} 
                                                events= {cutData} 
                                                eventClick = {(e)=>{
                                                    getCalendarData(e.event.startStr), 
                                                    updateCalendarData(e.event.startStr, "cut"),
                                                    setTypeOfProd('Cut'),
                                                    getTargetValue(e.event.startStr, "Cut")
                                                }}
                                                allDay= {true}
                                                locale={selectedLang}
                                                eventOrder= {'color,start'}
                                            />   
                                            :
                                            ""                               
                                            }

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                                        
                        <TabPane  tabId="2">
                            <Row className="pt-3" md="1" lg="2" sm="1">
                                <Col>
                                    <Card className='shadow-sm shadow-showcase' >

                                            <Row style={{ padding: '30px' }}>

                                                <Col className="text-center  label_align1">
                                                    { productionSewPackDateDetails.isSewAccomplished == 0
                                                    ?
                                                        (currentDate > productionSewPackDateDetails.sewEndDate) ? 
                                                        <>
                                                            <div className="media">
                                                                    <img src={RedSewing}  width="45px" />
                                                                    <div
                                                                        className="m-l-10 media-body label_align2 m-t-5 ">
                                                                        <span 
                                                                        style={{ color: '#EE335E',
                                                                        fontWeight: '500', 
                                                                        fontSize: '15px' 
                                                                        }} 
                                                                        > {t("sewing")} </span>
                                                                        <div className="f-12" 
                                                                            style={{color: '#EE335E', 
                                                                                    fontWeight: 'normal',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            <i 
                                                                            className="fa fa-circle" 
                                                                            style={{color:'#EE335E',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            </i>
                                                                            <span 
                                                                            style={{ 
                                                                            }}>
                                                                            &nbsp;
                                                                                {t("delay")}
                                                                            </span> 
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </>       
                                                        :
                                                        <>
                                                        <img src={GreenSewing}  width="45px" />
                                                        <strong className="text-center p-l-15 f-w-500" 
                                                            style={{ color: '#009688' }}>
                                                            {t("sewing")} 
                                                        </strong> 
                                                        </>
                                                    : 
                                                    ((productionSewPackDateDetails.sewAccomplishedDate !== null) &&
                                                     (productionSewPackDateDetails.sewAccomplishedDate <= productionSewPackDateDetails.sewEndDate)) ? 
                                                    <>
                                                        <div className="media">
                                                            <img src={GreenSewing} width="45px" />
                                                            <div className="m-l-5 media-body label_align2">
                                                                <H6> {t("sewing")} </H6>
                                                                <div className="f-12" 
                                                                    style={{color: '#009688', 
                                                                            fontWeight: 'normal',
                                                                            fontSize: '8px'
                                                                            }}>
                                                                    <i 
                                                                    className="fa fa-circle" 
                                                                    style={{color:'#009688',
                                                                            fontSize: '8px'
                                                                            }}>
                                                                    </i>
                                                                    <span 
                                                                    style={{ 
                                                                    }}>
                                                                    &nbsp;
                                                                        {t("completed")}
                                                                    </span> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="media">
                                                                    <img src={OrangeSewing} width="45px" />
                                                                    <div
                                                                        className="m-l-10 media-body label_align2 m-t-5 ">
                                                                        <span 
                                                                        style={{ color: '#E69020',
                                                                        fontWeight: '500', 
                                                                        fontSize: '15px' 
                                                                        }} 
                                                                        > {t("sewing")} </span>
                                                                        <div className="f-12" 
                                                                            style={{color: '#E69020', 
                                                                                    fontWeight: 'normal',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            <i 
                                                                            className="fa fa-circle" 
                                                                            style={{color:'#E69020',
                                                                                    fontSize: '10px'
                                                                                    }}>
                                                                            </i>
                                                                            <span 
                                                                            style={{ 
                                                                            }}>
                                                                            &nbsp;
                                                                                {t("delayComplete")}
                                                                            </span> 
                                                                        </div>
                                                                    </div>
                                                        </div>
                                                    </>
                                                    }
                                                   
                                                </Col>

                                                <Col className="text-center mt-1">
                                                    {t("startDate")}:
                                                    <Col className="p-l-10" > 
                                                        <strong  className="f-w-600"> 
                                                        { PHPtoJSFormatConversion.format(new Date(productionSewPackDateDetails.sewStartDate)) }
                                                        </strong> 
                                                    </Col>
                                                </Col>
                                                <Col  className="text-center mt-1">
                                                    {t("endDate")}:
                                                    <Col className="p-l-15" >
                                                        <strong  className="f-w-600">
                                                        { PHPtoJSFormatConversion.format(new Date(productionSewPackDateDetails.sewEndDate)) }
                                                        </strong>
                                                    </Col>
                                                </Col>
                                                <Col className="text-center mt-1" >
                                                    {t("delDate")} :
                                                        {deliveryDate ? 
                                                            deliveryDateStatus == 1 ? 
                                                            <strong className="f-w-600" style={{ color: '#EE335E' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong> 
                                                        : 
                                                            <strong className="f-w-600" style={{ color: '#000' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong>
                                                        : 
                                                            "-"
                                                        }
                                                </Col>

                                            </Row>
                                            <div style={{ backgroundColor: "#ECEDED", height: '40px',
                                                     cursor: 'pointer' }}>
                                                <div className="text-center m-t-10"  
                                                    onClick={() => { setTypeOfProd('Sew'), toggleSkuModal() } }> 
                                                    {t("skuwiseProgress")}
                                                </div>                                    
                                            </div>
                                            <Row  md="1" lg="3" sm="1" xs="1" style={{ borderBottom:" 1px solid #EEEEEE", margin: '2px' }}>
                                                <div className='text-center m-t-10 m-b-10' style={{ borderRight: "1px solid #EEEEEE"}}>
                                                    <div className=' m-t-10 m-b-10'>
                                                    <strong> {sewKnobChart.completedQuantity} </strong>  
                                                    <Col><small className=" qtyCard ">{t("completed")}</small> </Col>
                                                    </div>
                                                </div>
                                                <div className='text-center m-t-10 m-b-10'style={{ borderRight: "1px solid #EEEEEE",height:"100%"}} >
                                                    <div className=' m-t-10 m-b-10'>
                                                        <strong> {sewKnobChart.totalQuantity} </strong>  
                                                    <Col> <small className="qtyCard">{t("total")}</small> </Col>
                                                    </div>
                                                </div>
                                                <div className='text-center m-t-10 m-b-10'>
                                                    <div className=' m-t-10 m-b-10'>
                                                        <span style={{ color: "#EE335E"}}> 
                                                            <strong>
                                                            {Math.abs(sewKnobChart.pendingQuantity)}
                                                            </strong>
                                                        </span> 
                                                        <Col> 
                                                            <small className="qtyCard">
                                                            <span style={{ color: "#EE335E"}}>{t("Pending")}</span> 
                                                            </small>
                                                        </Col>
                                                    </div>
                                                </div>
                                            </Row>
                                            <CardBody className="m-1">
                                                {/*****************************************   KNOB CHART  **************************************/}
                                                <Row md="2" lg="2" className='m-t-40'>
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                <small style={{ color: '#2D2D2D', fontSize: '14px' }}>
                                                                {t("currentOutputRate")}
                                                                </small>
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob3"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small>
                                                                {t("currentDate")}
                                                                </small>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934',  fontSize: '14px' }}>
                                                                <strong>
                                                                    <small className="text-center">
                                                                    {PHPtoJSFormatConversion.format(new Date(currentDate)) } 
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col>
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                <small style={{ color: '#2D2D2D', fontSize: '14px' }}>
                                                                    {t("requiredOutputRate")}
                                                                </small>
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob4"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small>
                                                                    {t("requiredCompletionDate")}
                                                                </small>
                                                            </div>
                                                            <div style={{ color: '#242934', fontSize: '14px' }} className="text-center">
                                                                <strong>
                                                                    <small>
                                                                        { PHPtoJSFormatConversion.format(new Date(sewEndDate)) }
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col> 
                                                </Row>   
                                            </CardBody>

                                    </Card>
                                </Col>
                                <Col>
                                    <Card>
                                        <CardBody>

                                            <Row lg ="4" md="4" sm="4"  style={{ paddingBottom: '15px'}}>
                                                <Col>
                                                    <Row>
                                                        <Col >
                                                            <Row className="f-right"
                                                                style={{borderRadius:'03px', backgroundColor: '#458C41', 
                                                                width: '20px', height: '20px'}}>
                                                            </Row>
                                                        </Col>
                                                        <Col className="" style={{ marginRight: '15px', color: '#3A3A3A' }}> 
                                                        {t("target")}                                                    
                                                        </Col>
                                                    </Row>      
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                            style={{borderRadius:'03px', backgroundColor: '#9BC34F', 
                                                            width: '20px', height: '20px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{ marginRight: '15px', color: '#3A3A3A' }}>  
                                                        {t("actual")}                                                    
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                        style={{borderRadius:'03px', backgroundColor: '#C4A950',
                                                        width: '20px', height: '20px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{ marginRight: '15px', color: '#3A3A3A' }}>  
                                                        {t("excess")}                                                    
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                        <Row className="f-right"
                                                            style={{borderRadius:'03px',backgroundColor: '#EE335E',
                                                            width: '20px', height: '20px'}}>
                                                        </Row> 
                                                        </Col>
                                                        <Col style={{ marginRight: '15px', color: '#3A3A3A' }}> 
                                                        {t("short")}                                                
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                                                            
                                            {
                                                productionSewPackCalendarDataDetails.length > 0 
                                                ?
                                                    productionSewPackCalendarDataDetails.map((sewCalendarData) => 
                                                    {
                                                    let actualQty=sewCalendarData.actual_value ? sewCalendarData.actual_value:0;
                                                    let targetQty=sewCalendarData.target_value ? sewCalendarData.target_value:0;
                                                    let absBalanceQty=Math.abs(targetQty-actualQty);
                                                    let sewBalanceQty = sewCalendarData.date_of_production <= Moment().format('Y-MM-DD') ? absBalanceQty : 0;
                                                    let balanceQty=sewBalanceQty?sewBalanceQty:0;
                                                    if (sewCalendarData.holiday_flag == 0) {

                                                            /**************************** TARGET QTY ****************************/
                                                            {  (orderDelayStatus === "1" &&
                                                                sewCalendarData.date_of_production > productionSewPackDateDetails.sewEndDate )
                                                                // ( productionSewPackDateDetails.isSewAccomplished == 1 )
                                                                ?

                                                                sewData[sw] =
                                                                {
                                                                    date: '',
                                                                    title: '',
                                                                    color: '',
                                                                } 
                                                                :
                                                                sewData[sw] =
                                                                {
                                                                    date: sewCalendarData.date_of_production,
                                                                    title: targetQty,
                                                                    color: '#458C41',
                                                                } 
                                                                sw++
                                                            }

                                                            /**************************** ACTUAL QTY ****************************/
                                                            sewData[sw] =
                                                            {
                                                            date: sewCalendarData.date_of_production,
                                                            title: actualQty,
                                                            color: '#9CC450',      
                                                            }  
                                                            sw++;

                                                            if( 
                                                            productionSewPackDateDetails.sewEndDate < Moment().format('Y-MM-DD') )
                                                            {
                                                                var len = sw;
                                                                    {productionSewPackCalendarDataDetails.map( (orderDelayData,al) => 
                                                                    {
                                                                    if(productionSewPackCalendarDataDetails.length == cur_sw && 
                                                                        productionSewPackCalendarDataDetails.length == al+1)
                                                                        {
                                                                            if( orderDelayData.date_of_production !== Moment().format('Y-MM-DD') &&
                                                                                sewKnobChart.pendingQuantity != 0)
                                                                            {

                                                                                len++;
                                                                                sewData[len] =
                                                                                {
                                                                                    date: Moment().format('Y-MM-DD'),
                                                                                    title: '0',
                                                                                    color: '#9CC450',  
                                                                                } 
                                                                                len++;
                                                                                
                                                                                sewData[len] =
                                                                                {
                                                                                    date: Moment().format('Y-MM-DD'),
                                                                                    title: sewKnobChart.pendingQuantity,
                                                                                    color:  '#EE335E',
                                                                                }
                                                                            }
                                                                            else {
                                                                                if( orderDelayData.date_of_production == Moment().format('Y-MM-DD') && 
                                                                                orderDelayData.target_value !== 0)
                                                                                {
                                                                                    sewData[len] =
                                                                                    {
                                                                                    date: '',
                                                                                    title: '',
                                                                                    color: '',      
                                                                                    } 
                                                                                    len++;
                                                                                    sewData[len] =
                                                                                    {
                                                                                    date: '',
                                                                                    title: '',
                                                                                    color: ''           
                                                                                    } 
                                                                                }
                                                                                else{
                                                                                    if(sewKnobChart.pendingQuantity !=0){
                                                                                        sewData[len] =
                                                                                        {
                                                                                            date: Moment().format('Y-MM-DD'),
                                                                                            title: '0',
                                                                                            color: '#9CC450',  
                                                                                        } 
                                                                                        len++;
                                                                                        sewData[len] =
                                                                                        {
                                                                                            date: Moment().format('Y-MM-DD'),
                                                                                            title: sewKnobChart.pendingQuantity,
                                                                                            color:  '#EE335E',
                                                                                        }
                                                                                    }
                                                                                
                                                                                }
                                                                            }
                                                                            cur_sw++;
                                                                        }
                                                                    })
                                                                }
                                                            }
                                                        
                                                            if( actualQty < targetQty )
                                                            {
                                                                sewData[sw] =
                                                                {
                                                                date: sewCalendarData.date_of_production,
                                                                title:balanceQty,
                                                                color: '#EE335E'                                              
                                                                }  
                                                                sw++;
                                                            }
                                                            else {
                                                                sewData[sw] =
                                                                {
                                                                date: sewCalendarData.date_of_production,
                                                                title: balanceQty,
                                                                color: '#C4A950'                                                
                                                                } 
                                                                sw++;
                                                            }
                                                    } 
                                                    
                                                    else {
                                                        sewData[sw] =
                                                        {
                                                        date: sewCalendarData.date_of_production,
                                                        title: sewCalendarData.holiday_detail,
                                                        color: '#5F5A5A'
                                                        };
                                                        sw++;
                                                    }
                                                    cur_sw++;
                                                    })
                                                :
                                                    ""
                                            } 

                                            { sewHolidayList ? 
                                                sewHolidayList.map(( sewHolidayData) => {
                                                    let hol_sw = sewData.length;
                                                    let currentHoliday = Moment(sewHolidayData.start_date).format('YYYY-MM-DD');
                                                    if( sewHolidayData.days > 1 ){
                                                        for( let i=0; i < sewHolidayData.days; i++){
                                                            let actualDate = Moment(currentHoliday).add(i,'d').format('YYYY-MM-DD');
                                                            sewData[hol_sw] =
                                                                {
                                                                    date: actualDate,
                                                                    title: sewHolidayData.name,
                                                                    color: '#5F5A5A'
                                                                } 
                                                                hol_sw++;
                                                        } 
                                                    } else {
                                                        sewData[hol_sw] =
                                                        {
                                                            date: sewHolidayData.start_date,
                                                            title: sewHolidayData.name,
                                                            color: '#5F5A5A'
                                                        } 
                                                        hol_sw++;
                                                    }
                                                })
                                                : 
                                                ""
                                            } 

                                            { (sewWeekOff) ? 
                                                sewWeekOff.map((sewWeekOffData) => {
                                                    let weekOffStartDate = Moment(sewEndDate).add(1, 'd').format('YYYY-MM-DD');
                                                    let sw_wkOf = sewData.length;
                                                    sewData[sw_wkOf] = {
                                                            startRecur: weekOffStartDate,
                                                            title: 'Week Off',
                                                            daysOfWeek: [sewWeekOffData.days],
                                                            display: 'background',
                                                            backgroundColor: '#FFD1D1'
                                                    }
                                                    sw_wkOf++
                                                })
                                                :
                                                ""
                                            }

                                            { sew ? 
                                            <FullCalendar
                                            defaultView="dayGridMonth"
                                            header={{
                                                left: 'prev,next today',
                                                center: 'title',
                                                right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                            }}
                                            rerenderDelay={10}
                                            eventDurationEditable={false}
                                            editable={false}
                                            droppable={true}
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            events= {sewData} 
                                            eventClick = {(e)=>{
                                                getCalendarData(e.event.startStr), 
                                                setTypeOfProd('Sew'),
                                                updateCalendarData(e.event.startStr, "sew"),
                                                getTargetValue(e.event.startStr, "Sew") 
                                            }}
                                            allDay= {true}
                                            locale={selectedLang}
                                            eventOrder= {'color,start'}
                                            />   
                                            :
                                            ""}
                                        

                                        </CardBody>
                                    </Card>
                                </Col>
                            
                            </Row>
                        </TabPane>

                        <TabPane  tabId="3">
                            <Row className="pt-3" md="1" lg="2" sm="1">

                                <Col>
                                    <Card className='shadow-sm shadow-showcase'>
                                            <Row style={{ padding: '30px' }}>

                                                <Col className="text-center label_align1">
                                                            { productionSewPackDateDetails.isPackAccomplished == 0
                                                            ?
                                                                (currentDate > productionSewPackDateDetails.packEndDate) ? 
                                                                <>
                                                                <div className="media">
                                                                            <img src={RedPacking}  width="45px" />
                                                                            <div
                                                                                className="m-l-10 media-body label_align2 m-t-5 ">
                                                                                <span 
                                                                                style={{ color: '#EE335E',
                                                                                fontWeight: '500', 
                                                                                fontSize: '15px' 
                                                                                }} 
                                                                                > {t("packing")} </span>
                                                                                <div className="f-12" 
                                                                                    style={{color: '#EE335E', 
                                                                                            fontWeight: 'normal',
                                                                                            fontSize: '10px'
                                                                                            }}>
                                                                                    <i 
                                                                                    className="fa fa-circle" 
                                                                                    style={{color:'#EE335E',
                                                                                            fontSize: '10px'
                                                                                            }}>
                                                                                    </i>
                                                                                    <span 
                                                                                    style={{ 
                                                                                    }}>
                                                                                    &nbsp;
                                                                                        {t("delay")}
                                                                                    </span> 
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </>       
                                                                :
                                                                <>
                                                                <img src={GreenPacking}  width="45px" />
                                                                <strong className="text-center p-l-15 f-w-500" 
                                                                    style={{ color: '#009688' }}>
                                                                    {t("packing")} 
                                                                </strong> 
                                                                </>
                                                            : 
                                                            ((productionSewPackDateDetails.packAccomplishedDate !== null) &&
                                                            (productionSewPackDateDetails.packAccomplishedDate <= productionSewPackDateDetails.packEndDate)) ? 
                                                            <>
                                                                <div className="media">
                                                                    <img src={GreenPacking} width="45px" />
                                                                    <div className="m-l-5 media-body label_align2">
                                                                        <H6> {t("packing")} </H6>
                                                                        <div className="f-12" 
                                                                            style={{color: '#009688', 
                                                                                    fontWeight: 'normal',
                                                                                    fontSize: '8px'
                                                                                    }}>
                                                                            <i 
                                                                            className="fa fa-circle" 
                                                                            style={{color:'#009688',
                                                                                    fontSize: '8px'
                                                                                    }}>
                                                                            </i>
                                                                            <span 
                                                                            style={{ 
                                                                            }}>
                                                                            &nbsp;
                                                                                {t("completed")}
                                                                            </span> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                            <div className="media">
                                                                            <img src={OrangePacking} width="45px" />
                                                                            <div
                                                                                className="m-l-10 media-body label_align2 m-t-5 ">
                                                                                <span 
                                                                                style={{ color: '#E69020',
                                                                                fontWeight: '500', 
                                                                                fontSize: '15px' 
                                                                                }} 
                                                                                > {t("packing")} </span>
                                                                                <div className="f-12" 
                                                                                    style={{color: '#E69020', 
                                                                                            fontWeight: 'normal',
                                                                                            fontSize: '10px'
                                                                                            }}>
                                                                                    <i 
                                                                                    className="fa fa-circle" 
                                                                                    style={{color:'#E69020',
                                                                                            fontSize: '10px'
                                                                                            }}>
                                                                                    </i>
                                                                                    <span 
                                                                                    style={{ 
                                                                                    }}>
                                                                                    &nbsp;
                                                                                        {t("delayComplete")}
                                                                                    </span> 
                                                                                </div>
                                                                            </div>
                                                                </div>
                                                            </>
                                                            }
                                                        
                                                </Col>

                                                <Col className="text-center mt-1">
                                                    {t("startDate")}:
                                                    <Col className="p-l-10" > 
                                                        <strong  className="f-w-600">
                                                        { PHPtoJSFormatConversion.format(new Date(productionSewPackDateDetails.packStartDate)) }
                                                        </strong> 
                                                    </Col>
                                                </Col>
                                                <Col  className="text-center mt-1" >
                                                    {t("endDate")}:
                                                    <Col className="p-l-15" >
                                                            <strong className="f-w-600"> 
                                                            { PHPtoJSFormatConversion.format(new Date(productionSewPackDateDetails.packEndDate)) }
                                                            </strong>
                                                    </Col>
                                                </Col>
                                                <Col className="text-center mt-1" >
                                                    {t("delDate")} :
                                                        {deliveryDate ? 
                                                            deliveryDateStatus == 1 ? 
                                                            <strong className="f-w-600" style={{ color: '#EE335E' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong> 
                                                        : 
                                                            <strong className="f-w-600" style={{ color: '#000' }}>
                                                            {PHPtoJSFormatConversion.format(new Date(deliveryDate))}
                                                            </strong>
                                                        : 
                                                            "-"
                                                        }
                                                </Col>
                                            </Row>
                                            <div style={{ backgroundColor: "#ECEDED", height: '40px',
                                                    cursor: 'pointer' }}>
                                                <div className="text-center m-t-10"  
                                                    onClick={() => { setTypeOfProd('Pack'), toggleSkuModal() } }> 
                                                    {t("skuwiseProgress")}
                                                </div>                                    
                                            </div>
                                            <Row  md="1" lg="3" sm="1" xs="1" style={{ borderBottom:" 1px solid #EEEEEE", margin: '2px' }}>
                                                    <div className='text-center m-t-10 m-b-10' style={{ borderRight: "1px solid #EEEEEE"}}>
                                                        <div className=' m-t-10 m-b-10'>
                                                        <strong> {packKnobChart.completedQuantity} </strong>  
                                                        <Col><small className=" qtyCard ">{t("completed")}</small> </Col>
                                                        </div>
                                                    </div>
                                                    <div className='text-center m-t-10 m-b-10'style={{ borderRight: "1px solid #EEEEEE",height:"100%"}} >
                                                        <div className=' m-t-10 m-b-10'>
                                                            <strong> {packKnobChart.totalQuantity} </strong>  
                                                        <Col> <small className="qtyCard">{t("total")}</small> </Col>
                                                        </div>
                                                    </div>
                                                    <div className='text-center m-t-10 m-b-10'>
                                                        <div className=' m-t-10 m-b-10'>
                                                            <span style={{ color: "#EE335E"}}> 
                                                                <strong>
                                                                {Math.abs(packKnobChart.pendingQuantity)}
                                                                </strong>
                                                            </span> 
                                                            <Col> 
                                                                <small className="qtyCard">
                                                                <span style={{ color: "#EE335E"}}>{t("Pending")}</span> 
                                                                </small>
                                                            </Col>
                                                        </div>
                                                    </div>
                                            </Row>
                                            { pack 
                                            ? 
                                                <Row md="1" lg="2">
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                <small style={{ color: '#2D2D2D', fontSize: '14px' }}>
                                                                {t("currentOutputRate")}
                                                                </small>
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob5"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small>
                                                                {t("currentDate")}
                                                                </small>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934',  fontSize: '14px' }}>
                                                                <strong>
                                                                    <small className="text-center">
                                                                    {PHPtoJSFormatConversion.format(new Date(currentDate)) } 
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col>
                                                    <Col>
                                                        <CardBody>
                                                            <div  className="text-center">
                                                                <small style={{ color: '#2D2D2D', fontSize: '14px' }}>
                                                                {t("requiredOutputRate")}
                                                                </small>
                                                            </div>
                                                            <div className="knob-block text-center mt-4">
                                                                <div className="knob" id={"knob6"}></div>
                                                            </div>
                                                            <div className="text-center" style={{ color: '#242934', fontSize: '14px' }}>
                                                                <small>
                                                                {t("requiredCompletionDate")}
                                                                </small>
                                                            </div>
                                                            <div style={{ color: '#242934', fontSize: '14px' }} className="text-center">
                                                                <strong>
                                                                    <small>
                                                                    { PHPtoJSFormatConversion.format(new Date(packEndDate)) }
                                                                    </small>
                                                                </strong>
                                                            </div>
                                                        </CardBody>
                                                    </Col> 
                                                </Row>
                                            :
                                                "" }
                                    </Card>
                                </Col>

                                <Col>
                                    <Card>
                                        <CardBody>

                                        <Row lg ="4" md="4" sm="4"  style={{ paddingBottom: '15px'}}>
                                            <Col>
                                                <Row>
                                                    <Col >
                                                        <Row className="f-right"
                                                            style={{borderRadius:'03px', backgroundColor: '#458C41', 
                                                            width: '20px', height: '20px'}}>
                                                        </Row>
                                                    </Col>
                                                    <Col className="" style={{ marginRight: '15px', color: '#3A3A3A' }}> 
                                                    {t("target")}                                                       
                                                    </Col>
                                                </Row>      
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                    <Row className="f-right"
                                                        style={{borderRadius:'03px', backgroundColor: '#9BC34F', 
                                                        width: '20px', height: '20px'}}>
                                                    </Row> 
                                                    </Col>
                                                    <Col style={{ marginRight: '15px', color: '#3A3A3A' }}>  
                                                    {t("actual")}                                                      
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                    <Row className="f-right"
                                                    style={{borderRadius:'03px', backgroundColor: '#C4A950',
                                                    width: '20px', height: '20px'}}>
                                                    </Row> 
                                                    </Col>
                                                    <Col style={{ marginRight: '15px', color: '#3A3A3A' }}>  
                                                    {t("excess")}                                                      
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                    <Row className="f-right"
                                                    style={{borderRadius:'03px',backgroundColor: '#EE335E',
                                                    width: '20px', height: '20px'}}>
                                                    </Row> 
                                                    </Col>
                                                    <Col style={{ marginRight: '15px', color: '#3A3A3A' }}> 
                                                    {t("short")}                                                    
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    
                                        {
                                            productionSewPackCalendarDataDetails.length > 0 
                                            ? 
                                                productionSewPackCalendarDataDetails.map((productionPackData) => 
                                            {
                                                let actualQty=productionPackData.actual_value?productionPackData.actual_value:0;
                                                let targetQty=productionPackData.target_value?productionPackData.target_value:0;
                                                let absBalanceQty=Math.abs(targetQty-actualQty);
                                                let packBalanceQty = productionPackData.date_of_production <= Moment().format('Y-MM-DD') ? absBalanceQty : 0;
                                                let balanceQty=packBalanceQty?packBalanceQty:0;
                                                if (productionPackData.holiday_flag == 0)
                                                    {
                                                        /**************************** TARGET QTY ****************************/
                                                        {  (orderDelayStatus === "1" &&
                                                            productionPackData.date_of_production > productionSewPackDateDetails.packEndDate ) 
                                                            // ( productionSewPackDateDetails.isPackAccomplished == 1 )
                                                            ?
                                                            packData[pk] =
                                                            {
                                                                date: '',
                                                                title: '',
                                                                color: '',
                                                            }  
                                                            :
                                                            packData[pk] =
                                                            {
                                                                date: productionPackData.date_of_production,
                                                                title: targetQty,
                                                                color: '#458C41',
                                                            } 
                                                            pk++;
                                                        }

                                                        /**************************** ACTUAL QTY ****************************/
                                                        packData[pk] =
                                                        {
                                                            date: productionPackData.date_of_production,
                                                            title: actualQty,
                                                            color: '#9CC450'      
                                                        }  
                                                        pk++;
                                                    

                                                    if( 
                                                        // orderDelayStatus === "1"  && 
                                                        productionSewPackDateDetails.packEndDate < Moment().format('Y-MM-DD') )
                                                        {
                                                            var len = pk;
                                                            {productionSewPackCalendarDataDetails.map( (orderDelayData,al) => {
                                                                if(productionSewPackCalendarDataDetails.length == cur_pk &&
                                                                    productionSewPackCalendarDataDetails.length == al+1)
                                                                {
                                                                    if( orderDelayData.date_of_production !== Moment().format('Y-MM-DD') &&
                                                                        packKnobChart.pendingQuantity != 0)
                                                                    {
                                                                        len++;
                                                                        packData[len] =
                                                                        {
                                                                            date: Moment().format('Y-MM-DD'),
                                                                            title: '0',
                                                                            color: '#9CC450',  
                                                                        } 
                                                                        len++;
                                                                        
                                                                        packData[len] =
                                                                        {
                                                                            date: Moment().format('Y-MM-DD'),
                                                                            title: packKnobChart.pendingQuantity,
                                                                            color:  '#EE335E',
                                                                        }
                                                                    }
                                                                    else {
                                                                        if( orderDelayData.date_of_production == Moment().format('Y-MM-DD') && 
                                                                        orderDelayData.target_value !== 0)
                                                                        {
                                                                            packData[len] =
                                                                            {
                                                                            date: '',
                                                                            title: '',
                                                                            color: '',      
                                                                            } 
                                                                            len++;
                                                                            packData[len] =
                                                                            {
                                                                            date: '',
                                                                            title: '',
                                                                            color: ''           
                                                                            } 
                                                                        }
                                                                        else{

                                                                        if(packKnobChart.pendingQuantity !=0){
                                                                            packData[len] =
                                                                            {
                                                                                date: Moment().format('Y-MM-DD'),
                                                                                title: '0',
                                                                                color: '#9CC450',  
                                                                            } 
                                                                            len++;
                                                                            packData[len] =
                                                                            {
                                                                                date: Moment().format('Y-MM-DD'),
                                                                                title: packKnobChart.pendingQuantity,
                                                                                color:  '#EE335E',
                                                                            }
                                                                        }
                                                                        }
                                                                    }
                                                                    cur_pk++;
                                                                }
                                                                })
                                                            }
                                                        }


                                                    /**************************** PENDING QTY ****************************/
                                                    if( actualQty < targetQty )
                                                    {
                                                        packData[pk] =
                                                        {
                                                        date: productionPackData.date_of_production,
                                                        title:balanceQty,
                                                        color: '#EE335E'                                              
                                                        }  
                                                        pk++
                                                    }
                                                    else {
                                                        packData[pk] =
                                                        {
                                                        date: productionPackData.date_of_production,
                                                        title:balanceQty,
                                                        color: '#C4A950'                                              
                                                        } 
                                                        pk++
                                                    }
                                                } 
                                                else {
                                                    packData[pk] =
                                                    {
                                                    date: productionPackData.date_of_production,
                                                    title: productionPackData.holiday_detail,
                                                    color: '#5F5A5A'
                                                    }
                                                    pk++
                                                } 
                                                cur_pk++;
                                                })
                                            :
                                                ""
                                        }

                                        { packHolidayList ? 
                                            packHolidayList.map(( packHolidayData) => {
                                                let hol_pk = packData.length;
                                                let currentHoliday = Moment(packHolidayData.start_date).format('YYYY-MM-DD');
                                                if( packHolidayData.days > 1 ){
                                                    for( let i=0; i < packHolidayData.days; i++){
                                                        let actualDate = Moment(currentHoliday).add(i,'d').format('YYYY-MM-DD');
                                                        packData[hol_pk] =
                                                            {
                                                                date: actualDate,
                                                                title: packHolidayData.name,
                                                                color: '#5F5A5A'
                                                            } 
                                                            hol_pk++;
                                                    } 
                                                } else {
                                                    packData[hol_pk] =
                                                    {
                                                        date: packHolidayData.start_date,
                                                        title: packHolidayData.name,
                                                        color: '#5F5A5A'
                                                    } 
                                                    hol_pk++;
                                                }
                                            })
                                            : 
                                            ""
                                        } 

                                        { (packWeekOff)
                                        ? 
                                            packWeekOff.map((packWeekOffData) => {
                                                let weekOffStartDate = Moment(packEndDate).add(1, 'd').format('YYYY-MM-DD');
                                                let pk_wkOf = packData.length;
                                                    packData[pk_wkOf] = {
                                                        startRecur: weekOffStartDate,
                                                        title: 'Week Off',
                                                        daysOfWeek: [packWeekOffData.days],
                                                        display: 'background',
                                                        backgroundColor: '#FFD1D1'
                                                }
                                                pk_wkOf++
                                            })
                                            :
                                            ""
                                        }

                                        { pack ? 
                                        <FullCalendar
                                        defaultView="dayGridMonth"  
                                        header={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                        rerenderDelay={10}
                                        eventDurationEditable={false}
                                        editable={false}
                                        droppable={true}
                                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} 
                                        events= {packData} 
                                        eventClick = {(e)=>{
                                            getCalendarData(e.event.startStr), 
                                            setTypeOfProd('Pack'), 
                                            updateCalendarData(e.event.startStr, "pack"),
                                            getTargetValue(e.event.startStr, "Pack")
                                        }}
                                        locale={selectedLang} 
                                        eventOrder= {'color,start'}
                                        />   
                                        :
                                        ""                               
                                        }
                                        </CardBody>
                                    </Card>
                                </Col>
                            
                            </Row>
                        </TabPane>
                        
                    </TabContent>

                </CardBody>
                : 
                null
                }
        
            </Container>
       </Fragment>
   );
};

export default DataInput;
/***** CODE BY : RAMANAN M  *****/