import React, { useState } from "react";
import {
  Offcanvas,
  OffcanvasBody, Input, Label, FormGroup,
  OffcanvasHeader, Card, CardBody, CardHeader, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, Button
} from "reactstrap";
import { Btn, P } from "../../AbstractElements";
import { Close, SaveChanges, ServerUrl } from "../../Constant";
import axios from "axios";
import Swal from "sweetalert2";
import Moment from 'moment';
import { getLoginUserId, getLoginCompanyId, getWorkspaceId, getLoginStaffId, getWorkspaceType } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt, encode, decode } from "../../helper";
const OrderActionCanvas = (props) => {
  const [show, setShow] = useState(false);
  const [reason, setReason] = useState("");
  const [toggle, settoggle] = useState(true);
  const [orderNo, setOrderNo] = useState(props.orderno);
  const [orderId, setOrderId] = useState(props.orderid);
  const [errors, setValidErrors] = useState({});
  const { t, i18n } = useTranslation();
  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['user_id'] = getLoginUserId;
  getInputParams['staff_id'] = getLoginStaffId;
  getInputParams['order_id'] = props.orderid;
  getInputParams['order_no'] = props.orderno;
  getInputParams['order_action'] = props.actionType;
  getInputParams['reason'] = reason;
  const orderValidation = (data) => {
    let errors = {};
    if (!reason) {
      errors.reason = ('reasonValidation');
    }
    setValidErrors(errors);
    return errors;
  };
  const UpdateOrderAction = () => {

    let retval = orderValidation();
    if (Object.keys(retval).length == 0) {
      axios
        .post(ServerUrl + "/update-order-action", apiencrypt(getInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              allowOutsideClick: false
            }).then((result) => {
              axios
                .post(ServerUrl + "/get-orders",
                  apiencrypt({
                    "company_id": getWorkspaceId,
                    "workspace_id": getLoginCompanyId,
                    "user_id": getLoginUserId,
                    "staff_id": getLoginStaffId,
                    "style_no": props.styleNo,
                    "page_type": "orderstatus"
                  }))
                .then((response) => {
                  response.data = apidecrypt(response.data)
                  if (response.data.status_code === 200) {
                    props.getdata(response.data.data);
                  } else {
                    window.location.reload();
                  }
                  props.toggle(false);
                  if( getWorkspaceType !== "Factory" ){ window.location.href = '/orderfeedbackform?id=' + encode(orderId); } 
                })
            });
          }
          if (response.data.status_code === 400) {
            Swal.fire({
              title: t(response.data.message),
              icon: "error",
              button: t("okLabel"),
            });
          }
          if (response.data.status_code === 401) {
            Swal.fire({
              title: t("fieldMissing"),
              icon: "error",
              button: t("okLabel"),
            });
          }
        })
    }
  }

  return (
    <Offcanvas isOpen={props.modal} toggle={props.toggle} direction={"end"} className="offcanvas-width">
      <OffcanvasHeader className="bg-primary offcanvas-header">
        {props.actionType == "Cancel" ? t("orderCancelValidation", { OrderNo: props.orderno }) : props.actionType == "Complete" ? t("orderCompleteValidation", { OrderNo: props.orderno }) : t("orderDeleteValidation", { OrderNo: props.orderno })}
        <span className="f-right cursor-pointer" onClick={props.toggle}>X</span></OffcanvasHeader>
      <OffcanvasBody className="m-l-25 m-r-25">
        <Row >
          <Col>
          <Label className="col-form-label" for="recipient-name" style={{ fontSize:"bolder" }}>{t("reason")}:{props.toggle}
          </Label>
          <Input
            className={`${errors.reason && "select-error-valid"
              }  form-control`}
            type="textarea"
            onChange={(e) => setReason(e.target.value)}
          />
          {errors.reason && (
            <span className="error-msg">{t(errors.reason)}</span>
          )}
          </Col>
        </Row>
        <Row className="p-t-15">
          <Col md={12} className="text-center">
            <Button  onClick={() => { UpdateOrderAction() }} >{t("update")}</Button>
          </Col>
        </Row>
      </OffcanvasBody>
    </Offcanvas>
  );
};
export default OrderActionCanvas;

//**************************Code by : Saravana Kumar****************************//