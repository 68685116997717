import React, { useState, useEffect } from 'react';
import {
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Label, FormGroup,Row,Col
} from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { encode, decode, apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../../helper";
import { useSearchParams } from "react-router-dom";
import { Close, SaveChanges, ServerUrl } from '../../../Constant';
import { getLoginToken, getLoginUserType, getLoginCompanyId, getLoginUserId, getLoginStaffId, getWorkspaceId }
  from '../../../Constant/LoginConstant';

import { useTranslation } from 'react-i18next';

/********************************************/

import axios from "axios";
import Swal from "sweetalert2";

/********************************************/


const FormAddColorModal = ({ modal, toggle, pagename, roles, setModal,addorderId,ContactListDetails,workspace_type}) => {

  const { t } = useTranslation();

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobileNo] = useState('');
  const [role_id, setRoleId] = useState('');
  const [Btnloading, setBtnLoading] = useState(false);
// const[workspace_typeId,setworkspace_typeId] = useState('');
  //const [modal, setModal] = useState(true);
  const [contactList, setContactList] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  ////////// PARAMS TO BE SENT IN API //////////////////
  var getInputOrder = {};
  getInputOrder["company_id"] = getLoginCompanyId;
  getInputOrder["workspace_id"] = getWorkspaceId;
  getInputOrder["order_id"] = addorderId;
  getInputOrder["page_type"] = "order_contacts";

  var getInputParams = {};
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['first_name'] = first_name;
  getInputParams['last_name'] = last_name;
  getInputParams['email'] = email;
  getInputParams['mobile'] = mobile;
  // getInputParams['user_type'] = workspace_typeId
  getInputParams['role_id'] = role_id;
  getInputParams['user_id'] = getLoginUserId;
  //////////////////////////////////////////////////////

  var getInputParamsforStaff = {};
  getInputParamsforStaff['company_id'] = getLoginCompanyId;
  getInputParamsforStaff['workspace_id'] = getWorkspaceId;
  getInputParamsforStaff['order_id'] = orderId;

  const [validerrors, setValidErrors] = useState({});

  /*********** VALIDATION (STAFF) **********************/
  const satffValidation = (data) => {
    let validerrors = {};
    if (!first_name) {
      validerrors.firstname = t("enterFirstName");
    } else if (!(first_name).match(/^[a-zA-Z]+$/g)) {
      validerrors.firstname = t("specialCharactersNumbersNotAllowed");
    }
    if (!(last_name).match(/^[a-zA-Z]+$/g) && (last_name != '')) {
      validerrors.lastname = t("specialCharactersNumbersNotAllowed");
    }
    if (!email) {
      validerrors.email = t("enterEmailAddress");
    } else if (!(email).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
      validerrors.email = t("enterValidEmailAddress");
    }


    if (mobile) {

      if (!/^[0-9]+$/.test(mobile)) {
        validerrors.mobile = t("numbersOnlyAllowed");
      } else {
        if( (mobile.length != null &&  mobile.length != 'null' && mobile.length != '' && (parseInt(mobile.length) < 10 ) || (parseInt(mobile.length) > 15 ))){
          validerrors.mobile = t("enter10DigitsMobileNumber");
        }
      }
    }

    if (!role_id) {
      validerrors.role = t("pleaseSelectRole");
    }
    // if (!workspace_typeId) {
    //   validerrors.workspace_type = t("pleaseSelectUserType");
    // }
    setValidErrors(validerrors);
    return validerrors;
  };


  useEffect(() => {
    ////////////// API (POST) [ Get roles list ] ////////
    if (!modal) {
      setValidErrors('');
      setFirstName('');
      setLastName('');
      setEmail('');
      setMobileNo('');
      setRoleId('');
      // setworkspace_typeId('');
    }
    /////////////////////////////////////////////////////
  })

   ///////////////////////////////////////////////////////////////////////////////////////
   /************ API CALL[POST]-(register-staff) ***********************/
  const onSaveHandle = () => {
    //setBtnLoading(true);
    ///// API (POST) [ Register Staff ] //////////////////
    let retval = satffValidation();
    if (Object.keys(retval).length == 0) {
      axios
        .post(ServerUrl + "/register-staff", apiencrypt(getInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code === 200) {
            /********  STAFF ADDED SUCCESSFULLY **************/
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            }).then((result) => {
              if (result.isConfirmed) {
                setModal(!modal);
                // if (pagename == 'editcontacts') {
                //   window.location.href = "/editcontacts?id=" + encode(orderId);
                // } else {
                //   window.location.href = "/ordercontacts?id=" + encode(orderId);
                // }
                axios.post(ServerUrl + "/get-staffs", apiencrypt(getInputOrder)).then((response) => {
                  response.data = apidecrypt(response.data);
                  ContactListDetails(response.data.data);
                });
              }
              setTimeout(() => {
                setModal(!modal);
                // if (pagename == 'editcontacts') {
                //   window.location.href = "/editcontacts?id=" + encode(orderId);
                // } else {
                //   window.location.href = "/ordercontacts?id=" + encode(orderId);
                // }
                axios.post(ServerUrl + "/get-staffs", apiencrypt(getInputOrder)).then((response) => {
                  response.data = apidecrypt(response.data);
                  ContactListDetails(response.data.data);
                });
              }, 100);
            })

          }
          /***************  STAFF ALREADY EXISTS **********/
          if (response.data.status_code === 400) {
            Swal.fire({
              title: t(response.data.message),
              // text: t("validStaffDetailsAlert"),
              icon: "warning",
              button: t("okLabel"),
            });
          } else if (response.data.status_code === 401) {
            Swal.fire({
              title:
                t(response.data.errors.first_name) || t(response.data.errors.email) || t(response.data.errors.mobile) || t(response.data.errors.role_id),
              text: t("fieldMissing"),
              icon: "warning",
              button: t("okLabel"),
            });
          }
        });
      //////////////////////////////////////////////////////
    } else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  };

  return (
    <Modal  isOpen={modal} toggle={toggle} centered id="addsatffmodal">
      <ModalHeader>{t("addStaff")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
        </Label>
        <Row className="g-12">
        <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
        <FormGroup>
          <Label> {t("firstName")} <sup className="font-danger">*</sup></Label>
          <Input
            className={`${validerrors.firstname && 'error-valid'}  form-control`}
            type="text"
            placeholder={t("firstName")}
            onChange={(e) => setFirstName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
          />
          {validerrors.firstname && (
            <span className="error-msg">{validerrors.firstname}</span>
          )}
        </FormGroup>
        </Col>
        <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
     
        <FormGroup>
          <Label>{t("lastName")} </Label>
          <Input
            className={`${validerrors.lastname && 'error-valid'}  form-control`}
            type="text"
            placeholder={t("lastName")}
            onChange={(e) => setLastName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
          />
          {validerrors.lastname && (
            <span className="error-msg">{validerrors.lastname}</span>
          )}
        </FormGroup>
        </Col>
        <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
        <FormGroup>
          <Label>{t("email")}<sup className="font-danger">*</sup></Label>
          <Input
            className={`${validerrors.email && 'error-valid'}  form-control`}
            type="text"
            placeholder={t("email")}
            onChange={(e) => setEmail(e.target.value)}
          />
          {validerrors.email && (
            <span className="error-msg">{validerrors.email}</span>
          )}
        </FormGroup>
        </Col>
        <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
        <FormGroup>
          <Label>{t("mobileNumber")}</Label>
          <Input
            className={`${validerrors.mobile && 'error-valid'}  form-control`}
            type="tel"
            maxLength="15"
            placeholder={t("mobileNumber")}
            onChange={(e) => setMobileNo(e.target.value)}
          />
          {validerrors.mobile && (
            <span className="error-msg">{validerrors.mobile}</span>
          )}
        </FormGroup>
        </Col>
        <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
        <FormGroup>
          <Label>{t("role")} <sup className="font-danger">*</sup></Label>
          <Input
            type="select"
            className={`${validerrors.role && 'select-error-valid'}  form-control digits`}
            isMulti
            onChange={(e) => setRoleId(e.target.value)} >
            <option
              value="">
              {t("selectRole")}
            </option>
            {roles.map(rolesName =>
            (
              <option
              key={rolesName.id}
                value={rolesName.id}>
                {rolesName.name}
              </option>
            )
            )}
          </Input>
          {validerrors.role && (
            <span className="error-msg">{validerrors.role}</span>
          )}
        </FormGroup>
        </Col>
        {/* <Col lg="6" md="12" sm="12" xs="12" xl="6" xxl="6">
        <FormGroup>          
          <Label>{t("userType")} <sup className="font-danger">*</sup></Label>
            <Input type="select"
            className={`${validerrors.workspace_type && 'select-error-valid'}  form-control digits`}
            isMulti   
            onChange={(e) => setworkspace_typeId(e.target.value)} >
                  <option selected disabled value=""> {t("selectUserType")} </option>
                   {workspace_type.map( workspace_type => (
                    <option value={workspace_type.name}>
                        {workspace_type.name=="PCU"?"Buying House":workspace_type.name}
                        </option>
                     ))}
                  </Input>
                         
                    {validerrors.workspace_type && (
                      <span className="error-msg">{validerrors.workspace_type}</span>
                    )}
                  </FormGroup>
        </Col> */}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: toggle }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddColorModal;
/***** CODE BY : RAMANAN M  *****/