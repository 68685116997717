import React , {useState, useEffect}from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { Close, SaveChanges, ServerUrl } from '../../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from '../../../helper';

const FormAddTemplateModal = ({ modal, toggleTemplateModal, companyId, workspaceId, orderId , templates, 
  newTemplate , setOrderTemplateId,savedTemplateId, setTheSelectedTemplate, areTasksEdited,setTemplates,selectedTemplateId,onSubmitHandle,setIfTemplateChanged,setOnSubmitData,setTemplateload}) =>{
  const { t } = useTranslation();
  // const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [templateName, setTemplateName] = useState("");
  /* Validation Starts */
  const [errors, setValidErrors] = useState({});
  const validation = ()=>{
    let errors = {};
    if(!templateName){
      errors.templateName = ("templateName") 
    }
    setValidErrors(errors);
    return errors;
  };
  /* Validation Ends */
  useEffect(()=>{
    if(!modal){
      // setTemplateName("");
      // setValidErrors("");
    }
  });
  // console.log(newTemplate,"setTemplateload",templateName)
  const onCancelHandle =()=>{
    toggleTemplateModal(false)
    setTemplateName("")
  }

  setTimeout(() => {
    var element =  document.getElementById('createtemplatemodal');
    if (typeof(element) != 'undefined' && element != null)
    {
      element.focus();
    }
     
    }, 1000);

    const task_template_structure=()=>{
      var fildvald=[];
      var tempassign=[];
      newTemplate.map((templates) => {
        if(templates.task_title!='deleted'){
    var subtask=[];
    templates.task_subtitles.map((subtasktemplate,i)=>{
      if(subtasktemplate!='deleted'){
          let isFound = subtask.some(element => {
          return element === subtasktemplate;
        });
     
        if (!isFound) {
          subtask.push(subtasktemplate);
        }   
      }   
      
    })
    var arytit={};
    arytit['task_title']=templates.task_title;
    arytit['task_subtitles']=subtask;
    tempassign.push(arytit);
  }
      })
      //console.log("nnnntempassign==>",newTemplate);
      //console.log("tempassign==>",tempassign);
      return tempassign;
    }
  
  /**************** To Save the Template Name  ************************/
  const onSaveHandle = async() => {
    var tempstr=task_template_structure();

    let retval = validation();
    if (Object.keys(retval).length == 0) {
      await axios
        .post(ServerUrl + "/create-order-template", apiencrypt({
          company_id: companyId,
          workspace_id: workspaceId,
          template_name: templateName,
          order_id : orderId,
          //task_template_structure : newTemplate
          task_template_structure : tempstr
        }))
        .then((response) => {
          response.data = apidecrypt(response.data);
          setOrderTemplateId(response.data.templateID);
          // savedTemplateId(response.data.templateID);
           //setTheSelectedTemplate(response.data.templateID);
        //  console.log("response",response.data.templateID)
          if (response.data.status_code === 200) {
            // setOnSubmitData(1)
            // Swal.fire({
            //   title: t(response.data.message),
            //   // text: t(response.data.message),
            //   icon: "success",
            //   button:  t("okLabel"),
            //   timer:5000,
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     setTimeout(()=>{

            //       setOnSubmitData(1)
            //     },700)
              
            // }
             /* To show the saved templates in the dropdown */
            // if (result.isConfirmed) {
                 
            axios
                  .post(ServerUrl + "/get-order-template", apiencrypt({
                      company_id: companyId,
                      workspace_id: workspaceId,
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                    //setTemplateload(response.data.data);
                    templates(response.data.data);
                  });
                  // areTasksEdited(0);
                  toggleTemplateModal(false); 
                  setTimeout(()=>{

                          setOnSubmitData(1)
                        },100)
              // }
            /*    setTimeout(() => {
                axios
                  .post(ServerUrl + "/get-order-template", apiencrypt({
                      company_id: companyId,
                      workspace_id: workspaceId,
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                      templates(response.data.data);
                  });
                  areTasksEdited(0);
                  toggleTemplateModal(false);
              }, 100);*/
            // });
          }
          /* To show error if the Template name is already present*/
          if (response.data.status_code === 401) {
            Swal.fire({
              title: t("templateExistsTitleAlert"),
              text: t("enterDifferentNameTextAlert"),
              icon: "error",
              button: t("okLabel"),
            });
          }
        });
    }
  };
  return (
    <Modal backdrop="static" isOpen={modal} toggle={toggleTemplateModal} centered>
      <ModalHeader>{t("saveTemplate")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("saveTemplate")}
        </Label>
        <Input
          className="form-control"
          id="createtemplatemodal"
          type="text"
          onChange={(e) => setTemplateName(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
        />
        {errors.templateName && (
                            <><span className="error-msg">{t(errors.templateName)}</span><br/></>
                          )}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn", onClick: () => onCancelHandle()  
        // onClick: toggleTemplateModal 
      }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddTemplateModal;
/* Code By : Rithanesh */