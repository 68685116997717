import React, { Fragment,useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Table, Input, Label} from 'reactstrap'
import axios from 'axios';
import {  ServerUrl} from '../../Constant';
import { getLoginUserType, getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission }
  from '../../Constant/LoginConstant';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import CalendarCanvas from './calenderOffCanvas'
import Moment from 'moment';
import {PHPtoJSFormatConversion, apiencrypt, apidecrypt} from "../../helper";
import { useTranslation } from 'react-i18next';
// import delete_icon from '../../assets/images/dms/icons/delete_icon.svg'
// import delete_icon from '../../assets/images/dms/icons/BlackSubTaskDeleteicon.svg'
import jaLocale from '@fullcalendar/core/locales/ja';
import delete_icon from '../../assets/images/dms/icons/delete_icon.svg'
const CalendarConfigurationSettings = (props) => {
  const submitFun = () => {
  }
  // ********** get-roles-list Api call********************** 
  const [orderId, setOrderId] = useState();
  const [state, setState] = useState('');
  const [showAddData, setShowAddData] = useState([]);
  const [HolidayInformation, setHolidayInformation] = React.useState([]);
  const [WeekOffDayClass, setWeekOffDayClass] = React.useState('');
  const [getWeekOff, setWeekoff] = React.useState([]);
  const [calendar, setCalendar] = useState([]);
  const [isDataRecieved, setIsDataRecieved] = useState(0);
  const [show, setShow] = useState(false);
  const toggleCanvas = () => setShow(!show);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language == 'jp' ? jaLocale : '');
  let cuttingData = [];
  var productionData = {}
  productionData['company_id'] = getLoginCompanyId;
  productionData['workspace_id'] = getWorkspaceId;
  productionData['order_id'] = orderId;

  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;

  var getInputHolidays = {}
  getInputHolidays['company_id'] = getLoginCompanyId;
  getInputHolidays['workspace_id'] = getWorkspaceId;

  var getInputWeekOff = {}
  getInputWeekOff['company_id'] = getLoginCompanyId;
  getInputWeekOff['workspace_id'] = getWorkspaceId;
  /********************Get holiday and get weekoff api call*************************/
  useEffect(() => {
    axios
      .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setHolidayInformation(response.data.data);
        calenderInfo(response.data.data);
      })
    axios
      .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
      .then((response) => {
        response.data = apidecrypt(response.data)
        let wk_of = [];
        let wk_of_class = [];
        for (let i = 0; i < (response.data.data).length; i++) {
          wk_of[i] = response.data.data[i].day;
          wk_of_class[i] = response.data.data[i].day;
        }
        setWeekOffDayClass(wk_of_class);
        setWeekoff(wk_of);
      })
    {
      (((getStaff === "Staff" && getStaffPermission.includes('View Calendar Configuration')) || getStaff == null))
        ?
        ""
        :
        window.location.href = "/dashboard"
    }
  }, [])
  const onWeekoffChecked = (day) => {
    if (getWeekOff.includes(day)) {
      getWeekOff.pop(day)
    } else {
      getWeekOff.push(day)
    }
    let v = [...new Set(getWeekOff)];
    setWeekoff(v);
    setWeekOffDayClass(v);
    axios
      .post(ServerUrl + "/create-weekOffs", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
        "days": day
      })).then((response) => {
        response.data = apidecrypt(response.data)
        if (response.data.status_code === 200) {
          Swal.fire({
            title: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            allowOutsideClick: false,
          })
            .then((result) => {
              if (result.isConfirmed) {
                axios
                  .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    let wk_of = [];
                    let wk_of_class = [];
                    for (let i = 0; i < (response.data.data).length; i++) {
                      wk_of[i] = response.data.data[i].day;
                      wk_of_class[i] = response.data.data[i].day;
                    }
                    setWeekOffDayClass(wk_of_class);
                    setWeekoff(wk_of);
                  })
              }
            })
        }
        if (response.data.status_code === 201) {
          Swal.fire({
            title: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
            allowOutsideClick: false
          })
            .then((result) => {
              if (result.isConfirmed) {
                axios
                  .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    let wk_of = [];
                    let wk_of_class = [];
                    for (let i = 0; i < (response.data.data).length; i++) {
                      wk_of[i] = response.data.data[i].day;
                      wk_of_class[i] = response.data.data[i].day;
                    }
                    setWeekOffDayClass(wk_of_class);
                    setWeekoff(wk_of);
                  })
              }
            })
        }
      })
  }
  /*******************add holiday validation********************/
  const getConvertDate = (sdate, edate) => {
    var dates = [];
    var startDate = Moment(sdate, 'YYYY-MM-DD');
    dates.push(startDate.format('YYYY-MM-DD'));
    while (!startDate.isSame(edate)) {
      startDate = startDate.add(1, 'days');
      dates.push(startDate.format('YYYY-MM-DD'));
    }
    return dates;
  }
  const calenderInfo = (holidays) => {
    let cut_prod_data = [];

    for (let k = 0; k < holidays.length; k++) {
      let h = 0;
      var sdate = holidays[k].holiday_start_date;
      var edate = holidays[k].holiday_end_date;
      let d = 0;
      var start = new Date(sdate);
      var end = new Date(edate);

      let getDate = getConvertDate(sdate, edate);


      for (let hs = 0; hs < getDate.length; hs++) {
        let date_arry = {};
        var holiday_text = holidays[k].name;
        var holiday_flg = 1;
        date_arry.date_of_production = getDate[hs];
        date_arry.holiday_flag = holiday_flg;
        date_arry.date = getDate[hs];
        // date_arry.date = edate;
        date_arry.holiday_detail = holiday_text;
        cut_prod_data.push(date_arry);
      }
    }
    setShowAddData(cut_prod_data);
  }
  /*******************Delete Holiday api ********************/
  const removeEventClick = (id) => {
    Swal.fire({
      title: t("deleteThisHoliday"),
      position: 'top-middle',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: "btn-success",
      confirmButtonText: t('Yes'),
      cancelButtonText: t('NO')
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        getInputHolidays['id'] = id;
        axios
          .post(ServerUrl + "/delete-holiday", apiencrypt(getInputHolidays))
          .then((response) => {
            response.data = apidecrypt(response.data)
            if (response.data.status_code === 200) {
              Swal.fire({
                title: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                allowOutsideClick: false
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    axios
                      .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
                      .then((response) => {
                        response.data = apidecrypt(response.data)
                        setHolidayInformation(response.data.data);
                        calenderInfo(response.data.data);
                      })
                  }
                })
            }
          })
      }
    })
  }

  if (isDataRecieved === 1) {
    calenderInfo(calendar);
    setIsDataRecieved(0);
  }
  if (HolidayInformation.length == 0) {
    cuttingData = [{
      daysOfWeek: WeekOffDayClass,
      display: "background",
      title: "",
      className: "weekoffcolor",
      overLap: false,
      allDay: true
    }];
  }
  return (
    <Fragment>
      <Col sm="12" lg="7" md="12">
        <Card className=" card-activity card o-hidden border-0 b-r-10  shadow shadow-showcase">
          <CardHeader className=" pb-3 d-flex justify-content-between align-items-center b-b-light card-header p-text-left " style={{ marginTop: '-5px' }}>
            <span className="p-l-10 p-b-5"><h5>{t('weekOff')}</h5>  </span>
          </CardHeader>
          <CardBody>
            <div className="table-responsive">
              <Table borderless>
                <thead >
                  {(getStaff === "Staff" && getStaffPermission.includes('Add/Edit Calendar Configuration')) || getStaff == null ?
                    <tr className="lightGrayBgColor">
                      {getWeekOff.includes(0) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(0)} value="0"
                              id="wk0" />
                            <Label for="wk0" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")} >{t('sunDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(0)} value="0"
                              id="wk0" />
                            <Label for="wk0" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('sunDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(1) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(1)} value="1"
                              id="wk1" />
                            <Label for="wk1" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}> {t('monDay')} </Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(1)} value="1"
                              id="wk1" />
                            <Label for="wk1" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}> {t('monDay')} </Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(2) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" checked name="checkBox" onClick={(e) => onWeekoffChecked(2)} value="2"
                              id="wk2" />
                            <Label for="wk2" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('tuesDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(2)} value="2"
                              id="wk2" />
                            <Label for="wk2" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('tuesDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(3) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(3)} value="3"
                              id="wk3" />
                            <Label for="wk3" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('wedDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(3)} value="3"
                              id="wk3" />
                            <Label for="wk3" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('wedDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(4) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(4)} value="4"
                              id="wk4" />
                            <Label for="wk4" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('thursDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(4)} value="4"
                              id="wk4" />
                            <Label for="wk4" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('thursDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(5) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(5)} value="5"
                              id="wk5" />
                            <Label for="wk5" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('friDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(5)} value="5"
                              id="wk5" />
                            <Label for="wk5" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('friDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(6) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(6)} value="6"
                              id="wk6" />
                            <Label for="wk6" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('satDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(6)} value="6"
                              id="wk6" />
                            <Label for="wk6" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('satDay')}</Label>
                          </div>
                        </th>
                      }
                    </tr> :
                    <tr className="lightGrayBgColor">
                      {/* <th id="check"></th> */}

                      {getWeekOff.includes(0) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(0)} value="0"
                              id="wk0" disabled />
                            <Label for="wk0" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('sunDay')}</Label>
                          </div>

                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(0)} value="0"
                              id="wk0" disabled />
                            <Label for="wk0" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('sunDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(1) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(1)} value="1"
                              id="wk1" disabled />
                            <Label for="wk1" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('monDay')} </Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(1)} value="1"
                              id="wk1" disabled />
                            <Label for="wk1" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('monDay')} </Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(2) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" checked name="checkBox" onClick={(e) => onWeekoffChecked(2)} value="2"
                              id="wk2" disabled />
                            <Label for="wk2" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('tuesDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(2)} value="2"
                              id="wk2" disabled />
                            <Label for="wk2" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('tuesDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(3) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(3)} value="3"
                              id="wk3" disabled />
                            <Label for="wk3" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('wedDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(3)} value="3"
                              id="wk3" disabled />
                            <Label for="wk3" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('wedDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(4) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(4)} value="4"
                              id="wk4" disabled />
                            <Label for="wk4" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('thursDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(4)} value="4"
                              id="wk4" disabled />
                            <Label for="wk4" className="form-label weekdayoff" title={t("weekOff")} tooltip={t("weekOff")} alt={t("weekOff")}>{t('thursDay')}</Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(5) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(5)} value="5"
                              id="wk5" disabled />
                            <Label for="wk5" className="form-label weekdayoff">{t('friDay')} </Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(5)} value="5"
                              id="wk5" disabled />
                            <Label for="wk5" className="form-label weekdayoff">{t('friDay')} </Label>
                          </div>
                        </th>
                      }
                      {getWeekOff.includes(6) ?
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" checked onClick={(e) => onWeekoffChecked(6)} value="6"
                              id="wk6" disabled />
                            <Label for="wk6" className="form-label weekdayoff">{t('satDay')}</Label>
                          </div>
                        </th>
                        :
                        <th className='text-center' scope="col">
                          <div className="checkbox checkbox-primary marg-min-top-10">
                            <Input type="checkbox" className="form-check-input" name="checkBox" onClick={(e) => onWeekoffChecked(6)} value="6"
                              id="wk6" disabled />
                            <Label for="wk6" className="form-label weekdayoff">{t('satDay')}</Label>
                          </div>
                        </th>
                      }
                    </tr>
                  }
                </thead>
              </Table>
            </div>
          </CardBody>
        </Card>
        <Card className="o-hidden border-0 b-r-10  shadow shadow-showcase">
          <CardBody>
            <div className="text-center">
              {showAddData.map((cutDatas, i) => {

                if (cutDatas.holiday_flag == 0) {
                  cuttingData[i] =
                  {
                    id: i + '||' + cutDatas.holiday_flag,
                    start: cutDatas.date,
                    end: cutDatas.date,
                    title: cutDatas.holiday_detail,
                    className: 'holi_day_lists',
                    daysOfWeek: [0, 6],


                  };
                } else {
                  cuttingData[i] =
                  {
                    id: i + '||' + cutDatas.holiday_flag,
                    start: cutDatas.date,
                    end: cutDatas.date,
                    title: cutDatas.holiday_detail,
                    className: 'holi_day_lists',
                  };
                }
                i++;
                if (WeekOffDayClass) {
                  cuttingData[i] =
                  {
                    daysOfWeek: WeekOffDayClass,
                    display: "background",
                    title: "",
                    className: "weekoffcolor",
                    overLap: false,
                    allDay: true
                  }
                }
              })}
              <FullCalendar
                defaultView="dayGridMonth"
                header={{
                  left: 'prev,next day',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                locale={selectedLang}
                rerenderDelay={10}
                eventDurationEditable={false}
                selectable={true}
                droppable={false}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                weekends={state.calendarWeekend}
                events={cuttingData}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="12" lg="5" md="12">
        <Card className="card-activity card o-hidden border-0 b-r-10  shadow shadow-showcase">
          <CardHeader className=" d-flex justify-content-between align-items-center b-b-light card-header p-text-left holidayalign" style={{ marginTop: '-13  px' }} >
            <span className="p-l-10 "><h5>{t("holidayList")}</h5>  </span>
            
              {(getStaff === "Staff" && getStaffPermission.includes('Add/Edit Calendar Configuration')) || getStaff == null ?
              <div className='add-Holiday '>
                <span style={{ float: "Right" }} onClick={() => { toggleCanvas() }} className="btn-pill btn btn-outline-primary btn-md"> {t("addHoliday")}</span>
            </div>
                : <div className='add-Holiday m-20 pb-1'><span className=''></span></div>}
          </CardHeader>
          <CardBody className='holidaylistpad'>
            <Row>
              <Col lg="12" sm="12" md="12">
                {HolidayInformation.length > 0 ? HolidayInformation.map((data,j) => (
                  <Row key={j} className='p-1'>
                    <Col xl="5" lg="5" sm="5" md="5" xs="11" style={{ backgroundColor: "#F8F9FA" }} className="text-bottom p-t-15 "><span className="hoildaytit"> {PHPtoJSFormatConversion.format(new Date(data.holiday_start_date))}  - {PHPtoJSFormatConversion.format(new Date(data.holiday_end_date))}</span></Col>
                    <Col xl="6" lg="6" sm="6" md="6" xs="11" >
                      <Input type="text" value={data.name} readOnly disabled className='inputdisable'></Input></Col>
                    <Col xl="1" lg="1" sm="1" md="1" xs="1" > <a href="javascript:void(0)" title={t("delete")} tooltip={t("delete")} alt={t("delete")} onClick={(e) => removeEventClick(data.id)}>
                      {(getLoginUserType === "user") ?
                        <img src={delete_icon} className="" style={{ marginLeft:"-15px" }} />
                        : ""}
                    </a>
                    </Col>
                  </Row>
                )) : <>
               <Row className='holidaynodatapad'>
                  <span className="m-t-10 centerAlign">{t("noHolidayList")}</span>
                  </Row>
                </>}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {/* *******************Props pass ******************** */}
      <CalendarCanvas modal={show} toggle={toggleCanvas} forCalender={setCalendar} dataRecieved={setIsDataRecieved} holidayInfo={setHolidayInformation} />
    </Fragment>
  )
}
export default CalendarConfigurationSettings;
// Code by : Praveen