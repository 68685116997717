import React, { Fragment, useState, useEffect } from "react";
import { Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, FormGroup, Media } from "reactstrap";
import { ServerUrl } from "../../../Constant";
import { H6, Btn } from "../../../AbstractElements";
import AddFabaricModal from "../CreateOrders/AddFabaricModal";
import AddOrderCategoryModal from "../CreateOrders/AddOrderCategoryModal";
import AddArticleModal from "../CreateOrders/AddArticleModal";
import OrderHeaders from "../CreateOrders/OrderHeaders";
import AddBuyerModal from "../CreateOrders/AddBuyerModal";
import AddUnitsModal from './../CreateOrders/AddUnitsModal';
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff, getStaffPermission, getLoginStaffId }
  from '../../../Constant/LoginConstant';
import Swal from "sweetalert2";
import { useSearchParams, Link } from "react-router-dom";
import { encode, decode, calculateDateDiffCountFromCurrentDate, apiencrypt, apidecrypt, PHPtoJSFormatConversion } from "../../../helper"
import { useTranslation } from 'react-i18next';

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";

const OrderRegistration = (props) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams(); 
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const workspace_id = getWorkspaceId;
  const company_id = getLoginCompanyId;
  const order_id = orderId;
  const user_id = getLoginUserId;

  const dataToSendAtStarting = {
    "company_id": company_id,
    "workspace_id": workspace_id,
  };

  const [orderDetails, setOrderDetails] = React.useState([]);
  const [modal, setModal] = useState(false);
  const [modalpcu, setModalpcu] = useState(false);
  const [modalfabaric, setModalfabaric] = useState(false);
  const [modalBuyer, setModalBuyer] = useState(false);
  const [modalcat, setModalcat] = useState(false);
  const [modalart, setModalart] = useState(false);
  const [factories, setFactories] = useState([]);
  const [Pcus, setPcus] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [incomeTerms, setIncomeTerms] = useState([]);
  const deliveries = [];
  const units = [];

  const [currencies, setCurrencies] = useState([]);
  const [factory, setFactory] = useState();
  const [pcu, setPcu] = useState('');
  const [fabric, setFabric] = useState('');
  const [buyer, setBuyer] = useState('');
  const [category, setCategory] = useState('');
  const [article, setArticle] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [styleNo, setStyleNo] = useState('');
  const [currency, setCurrency] = useState('');
  const [price, setPrice] = useState('');
  const [incomeTerm, setIncomeTerm] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [totalDelivery, setDelivery] = useState('1');
  const [quantityWise, setQuantityWise] = useState('SKU-Wise')
  const [toleranceVolume, setToleranceVolume] = useState('');
  const [tolerancePercentage, setTolerancePercentage] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [regCuttingDate, setRegCuttingDate] = useState('');
  const [tolerancereq, setTolerancereq] = useState(false);
  const [ToleReq, setToleReq] = useState('');
  const [disableField, setDisableField] = useState(false);
  const [orderPriority, setOrderPriority] = useState(['High','Medium','Low']);
  const [orderPriorityValue, setOrderPriorityValue] = useState('');
  const [basicInfo, setBasicInfo] = useState([]);
  const [value, setValue] = useState([]);
  const [getWeekOff, setWeekoff] = React.useState([]);
  const [modalUnits, setModalUnits] = useState(false);
  const toggle = () => setModal(!modal);
  const togglepcu = () => setModalpcu(!modalpcu);
  const togglefabaric = () => setModalfabaric(!modalfabaric);
  const togglecat = () => setModalcat(!modalcat);
  const toggleart = () => setModalart(!modalart);
  const toggleBuyer = () => setModalBuyer(!modalBuyer);
  const toggleUnits = () => setModalUnits(!modalUnits);
  const [datecount, setdatecount] = useState(0);
  const [Unitno, setpUnit] = useState('');
  const [unitdetails, setunitdetails] = useState('');
  const [ getUnitsList, setGetUnitsList] = useState([]);
  const [inquiryDate,setInquiryDate] = useState('');
  const date = new Date();
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  /* Previous Data state */
  const [pcategory, setpCategory] = useState('');
  const [particle, setpArticle] = useState('');
  const [pcurrency, setpCurrency] = useState('');
  const [pprice, setpPrice] = useState('');
  const [pincomeTerm, setpIncomeTerm] = useState('');
  const [ptotalDelivery, setpDelivery] = useState('');
  const [pfabric, setpFabric] = useState('');
 
  const [ofUnits, setOfUnits] = useState([]);
  const [ comments, setComments ] = useState('');
  const submitData = {
    "company_id": company_id,
    "order_id": orderId,
    "workspace_id": workspace_id,
    "user_id": user_id,
    "factory_id": orderDetails.factory_id,
    "pcu_id": pcu,
    "order_no": orderNo,
    "style_no": styleNo,
    "order_price": price ? price : "",
    "income_terms": incomeTerm ? incomeTerm : 0,
    "currency_type": currency ? currency : 0,
    "fabric_id": fabric,
    "category_id": category ? category : 0,
    "article_id": article,
    "inquiry_date": inquiryDate ,
    "no_of_deliverys": totalDelivery,
    "quantity_wise": quantityWise,
    "tolerance_volume": toleranceVolume ? toleranceVolume : 0,
    "tolerance_perc": tolerancePercentage ? tolerancePercentage : 0,
    "delivery_dates": deliveryDate,
    "is_tolerance_req": tolerancereq == true ? 1 : 0,
    "total_quantity": totalQuantity,
    "order_priority":orderPriorityValue,
    "units":Unitno,
  };
  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;

  var getInputWeekOff = {}
  getInputWeekOff['company_id'] = getLoginCompanyId;
  getInputWeekOff['workspace_id'] = getWorkspaceId;

  useEffect(() => {
      axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setDisableField((response.data.data[0].step_level == 6 ) ? true : false );
        setBasicInfo(response.data.data[0]);  
        setRegCuttingDate(response.data.data[0].cutting_start_date);
        var dates = response.data.data[0].delivery_dates;
        var splitId = dates.split(",");
        setDeliveryDate(splitId);
        setValue(splitId);

        if (response.data.data[0].cutting_start_date != null) {
          const dateCountv = calculateDateDiffCountFromCurrentDate(response.data.data[0].cutting_start_date);
          setdatecount(dateCountv);
        } else {
          setdatecount('1');
        }

        if(response.data.data[0].status != 1  )
        {
          // window.location.href = "/dashboard" 
        }
        else {
          ""
        }
        
        if( response.data.data[0].step_level != 6 
          || calculateDateDiffCountFromCurrentDate(response.data.data[0].cutting_start_date) <= 1 
          ){
          // window.location.href = "/dashboard" 
        }
      })

      axios
      .post(ServerUrl + "/edit-order", apiencrypt({
        "company_id": company_id,
        "workspace_id": workspace_id,
        "order_id": orderId,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setOrderDetails(response.data.data);
        setBuyer(response.data.data.buyer_id)
        setFabric(response.data.data.fabric_id)
        setPcu(response.data.data.pcu_id)
        setCategory(response.data.data.category_id)
        setArticle(response.data.data.article_id)
        setInquiryDate(response.data.data.inquiry_date)
        setOrderNo(response.data.data.order_no)
        setStyleNo(response.data.data.style_no)
        setCurrency(response.data.data.currency_type)
        setPrice(response.data.data.order_price)
        setIncomeTerm(response.data.data.income_terms)
        setTotalQuantity(response.data.data.total_quantity)
        setDelivery(response.data.data.no_of_deliverys)
        setQuantityWise(response.data.data.quantity_wise)
        setOrderPriorityValue(response.data.data.order_priority)
        /* Previous Order Data */
        setpFabric(response.data.data.fabric_id)
        setpCategory(response.data.data.category_id)
        setpArticle(response.data.data.article_id)
        setpCurrency(response.data.data.currency_type)
        // setpPrice(response.data.data.order_price)
        setpIncomeTerm(response.data.data.income_terms)
        setpDelivery(response.data.data.no_of_deliverys)
        setpUnit(response.data.data.units)
        {
          response.data.data.is_tolerance_req === "1" ?
          setToleranceVolume(Math.round((response.data.data.total_quantity / 100) * response.data.data.tolerance_perc))
          :
          setToleranceVolume(response.data.data.tolerance_volume)
        }
        setTolerancePercentage(response.data.data.tolerance_perc)
        setToleReq(response.data.data.is_tolerance_req)
        if (response.data.data.is_tolerance_req == 1) {
          setTolerancereq(true);
        }
      });

      axios
      .post(ServerUrl + "/get-all-order-details", apiencrypt(dataToSendAtStarting))
      .then((response) => {
          response.data = apidecrypt(response.data);
          setArticles(response.data.data.article)
          setBuyers(response.data.data.buyers)
          setCategories(response.data.data.category)
          setCurrencies(response.data.data.currencies)
          setFabrics(response.data.data.fabric)
          setFactories(response.data.data.factories)
          setIncomeTerms(response.data.data.incomeTerms)
          setPcus(response.data.data.pcus)
          setGetUnitsList(response.data.data.units)
      })

      // axios
      // .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
      // .then((response) => {
      //   response.data = apidecrypt(response.data)
      //   let wk_of = [];
      //   for(let i = 0; i < (response.data.data).length; i++) {
      //     wk_of[i] = response.data.data[i].day;
      //   }
      //   setWeekoff(wk_of);
      // })
    
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Edit Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    } 

  }, []);

  for (let i = 0; i <= 20; i++) {
    deliveries.push(
      orderDetails.no_of_deliverys == i ?
        <option value={i} selected >{i}</option> :
        <option value={i}>{i}</option>
    );
  }
 
  for (let i = 1; i <= 12; i++) {
    units.push(
      orderDetails.units == i ?
        <option value={i} selected >{i>1?t("packOf")+" "+i:t("piece")}</option> :
        <option value={i}>{i>1?("packOf")+" "+i:t("piece")}</option>
        );
  }

  /*Order Validation  Start*/
  const [errors, setValidErrors] = useState({});

  const orderOnValidation = (val) => {

    let errors = {};

    if (!(orderNo).match(/^[0-9a-zA-Z_-]+$/g) && orderNo.trim() != '') {
      errors.orderNo = ('orderNumberAllowedValidationText');
    }

    // if (!(styleNo).match(/^[0-9a-zA-Z_-]+$/g) && styleNo.trim()!='') {
    //   errors.styleNo = 'Allowed only Numbers,Characters and _ - are allowed';
    // }

    setValidErrors(errors);
    return errors;
  }
  const orderValidation = (data) => {

    let errors = {};
    // if (!factory && getWorkspaceType != 'Factory') {
    //   errors.factory = ("factoryValidationText")
    // }
    if (!buyer && getWorkspaceType != 'Buyer') {
      errors.buyer = ("buyerValidationText");
    }
    // if (!pcu && getWorkspaceType != 'PCU') {
    //   errors.pcu = ("pcuValidationText");
    // }
    if (!fabric) {
      errors.fabric = ("fabricValidationText");
    }
    //  if (!category) {
    //   errors.category = 'Please Select a Category';
    //  }
    if (!article) {
      errors.article = ("articleValidationText");
    }

    if (!orderNo.trim()) {
      errors.orderNo = ("orderNumberValidationText");
    } else if (!(orderNo).match(/^[0-9a-zA-Z_-]+$/g)) {
      errors.orderNo = ("orderNumberAllowedValidationText");
    }
    if (!styleNo.trim()) {
      errors.styleNo = ("styleNumberValidationText");
    }
    // else if (!(styleNo).match(/^[0-9a-zA-Z_-]+$/g)) {
    //   errors.styleNo = 'Allowed only Numbers,Characters and _ - are allowed';
    // }
    if((getStaff === "Staff" && getStaffPermission.includes('Edit Price')) || getStaff == null){
      if(price!=""){
      if (((price) == 0)) {
        errors.price = ("invalidPriceValidationText");
      }
    }
  }
    if (totalQuantity == '') {
      errors.totquantity = ("totalQuantityRequired");
    } else if (!(totalQuantity.toString()).match(/^[0-9]+$/g)) {
      errors.totquantity = ("onlyNumbersAreAllowed");
    }
    else if (parseInt(totalQuantity) <= 0) {
      errors.totquantity = ("minimumQty");
    }

    if (!totalDelivery) {
      errors.totaldelivery = ("totalDeliveryRequired");
    } else if (totalDelivery <= 0 || totalDelivery > 20) {
      errors.totaldelivery = ("totalDeliverymismatch");
    }

    if ((toleranceVolume != '') && (!(toleranceVolume.toString()).match(/^[0-9.]+$/g))) {
      errors.toleranceVolume = ("onlyNumbersFormatallowed");
    }
    if ((tolerancePercentage != '') && (!(tolerancePercentage.toString()).match(/^[0-9.]+$/g))) {
      errors.tolerancePercentage = ("onlyNumbersFormatallowed");
    }
    if (tolerancereq == true) {
      if ((toleranceVolume == '' || toleranceVolume == 0)) {
        errors.toleranceVolume = ("toleranceQuantityPercentage");
      }
    }
    
    if(deliveryDate.length < totalDelivery ){
      errors.deliveryDate = ("plsSelectDelvyDates");
    }
    if (parseInt(totalQuantity) <= parseInt(toleranceVolume) && totalQuantity != '' && toleranceVolume != '') {
      errors.toleranceVolume = ("reachedTotalQuantity");
    }

    setValidErrors(errors);
    return errors;
  };

  function handleKeyPressPrice(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  }
  /*Order Validation End*/
  const filterObject = (obj, filter, filterValue,reqValue) => 
    Object.keys(obj).reduce((acc, val) => 
    (obj[val][filter] != filterValue 
      ? acc 
      : {
        //...acc,
        [reqValue]: obj[val][reqValue],
    }                                        
  ), {});
  
  const commentsAlert = () => {
    
      Swal.fire({
        title: t("updateOrderConfirmation"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
        input: 'textarea',
        inputPlaceholder: t("addYouComments"),
        closeOnClickOutside: false,
        inputValidator: (reason) => {
          if (reason) {
            submitData['comments'] = reason;
          } else{
            submitData['comments'] = "";
          }
      },
      })
      .then((result) => {
        if (result.isConfirmed){
          updateOrderApiCall();
      }
      });
    
  };

  const updateOrderApiCall = () => {
    axios.post(ServerUrl + "/update-order", apiencrypt(submitData))
    .then((response) => {
      response.data = apidecrypt(response.data)
      if (response.data.status_code === 200) {
        if( response.data.qty_status == 1){
            Swal.fire({
              title: t("orderAddedSuccessfully"),
              icon: "success",
              button: t("okLabel"),
              timer: 5000
            })
            .then((result) => {
                if (result.isConfirmed) {
                  window.location.href = '/orderview?id=' + encode(orderId);
              }
              setTimeout(() => {
                window.location.href = '/orderview?id=' + encode(orderId);
              }, 100);
            });
        } else{
            Swal.fire({
              title: response.data.message,
              icon: "warning",
              button: t("okLabel"),
            })
            .then((result) => {
              if (result.isConfirmed) { setTimeout(() => { 
                window.location.href = '/editsku?id=' + encode(orderId) + '&qty_arr='+ encode( JSON.stringify(response.data.qty_arr)); 
              }, 100); }
              if (result.isConfirmed) {
                setTimeout(() => {
                  window.location.href = '/editsku?id=' + encode(orderId) + '&qty_arr='+ encode( JSON.stringify(response.data.qty_arr));
                }, 100);
              }
            });
          }
      }
      if (response.data.status_code === 401) {
        Swal.fire({
          title:
            t(response.data.errors.company_id) ||
            t(response.data.errors.workspace_id) ||
            t(response.data.errors.order_no) ||
            t(response.data.errors.style_no) ||
            t(response.data.errors.pcu_id) ||
            t(response.data.errors.factory_id) ||
            t(response.data.errors.category_id) ||
            t(response.data.errors.article_id) ||
            t(response.data.errors.total_quantity) ||
            t(response.data.errors.no_of_deliverys) ||
            t(response.data.errors.order_price),
          text: t("fieldMissing"),
          icon: "warning",
          button: t("okLabel"),
        });
      }
    })
  };

  const onhandleSubmit = (v) => {
    let retval = orderValidation();
    if (Object.keys(retval).length == 0) {
      let previousData={}
      let updatedData={}
      // if(pprice!=price) {
      //   previousData['Price'] = pprice
      //   updatedData['Price'] = price
      // }
      if(pcurrency!=currency) {
        previousData['Currency'] = pcurrency
        updatedData['Currency'] = filterObject(currencies,"id",currency,"name").name
      }
      if(ptotalDelivery!=totalDelivery) {
        previousData['TotalDelivery'] = ptotalDelivery
        updatedData['TotalDelivery'] = totalDelivery
      }
      if(pfabric!=fabric) {
        previousData['Fabric'] = filterObject(fabrics,"id",pfabric,"name").name
        updatedData['Fabric'] = filterObject(fabrics,"id",fabric,"name").name
      }
      if(pcategory!=category) {
        previousData['Category'] = filterObject(categories,"id",pcategory,"name").name
        updatedData['Category'] = filterObject(categories,"id",category,"name").name
      }
      if(particle!=article) {
        previousData['Article'] = filterObject(articles,"id",particle,"name").name
        updatedData['Article'] = filterObject(articles,"id",article,"name").name
      }
      if(pincomeTerm!=incomeTerm) {
        previousData['Incoterms'] = filterObject(incomeTerms,"id",pincomeTerm,"name").name
        updatedData['Incoterms'] = filterObject(incomeTerms,"id",incomeTerm,"name").name
      }
     
      submitData['before_values']=previousData;
      submitData['after_values']=updatedData;
      
      if (tolerancePercentage > 5) {
        Swal.fire({
          title: t('tolerancePercentageGreaterThan5'),
          text: t('areYouSureContinue'),
          icon: "warning",
          button: t("okLabel"),
          showCancelButton: true,
        })
          .then((result) => {
            if (result.isConfirmed){    
              commentsAlert();
            }
          });
      } else {
        commentsAlert();
      }

    }
    else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }

  };

  if (regCuttingDate != null) {
    // const dateCountv = calculateDateDiffCountFromCurrentDate(regCuttingDate);
    //setdatecount(dateCountv);
  }
  const selectedDatesList = ( value ) => {
    var array = [];
    {value.length || value !== null > 0 
      ? 
      value.map((mapData) => 
        {
          let day = (mapData.day).toString();
          let month = (mapData.monthIndex+1).toString();
          let year = (mapData.year).toString();
          let date = year+"-"+month+"-"+day;
          array.push(date);
        })
      :
        ""} 
      setDeliveryDate(array);
      setValue(array);
  };

  const RemainingDeliveryDates = () => 
  {

    var remainingDays = totalDelivery;
    if( value!== null ){
      if( value.length !== undefined || value.length > 0 )
      {
        remainingDays = totalDelivery - value.length;
      }
    }
    return  <div  style={{ backgroundColor: '#E5F4F3', color: '#009688', fontWeight: 300, 
                          paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px' }}> 
                Remaining Delivery Dates : 
               <strong style={{ fontWeight: 600, marginLeft: '5px' }}> {remainingDays} </strong> 
            </div>
  };

  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody style={{ marginBottom:"-20px" }}>
            <OrderHeaders title="addorder" />
          </CardBody>

          <CardBody>
            <Form
              className="needs-validation"
              noValidate=""
              src={`${process.env.PUBLIC_URL}/ordersku`}>
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}

                <Col md="12" lg="12" sm="12">
                  <Row className="g-12">
                    <Col lg="12" md="12" sm="12" xs="12">
                      <span className="subTitleLine f-left"><H6 className="ordersubhead">{t("OrderDetails")}</H6></span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                    </Col>
                  </Row>
                  <Row className="g-12">
                    {getWorkspaceType != 'Factory' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup className="">
                          <Label>{t("factory")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              disabled
                              name="select"
                              type="select"
                              placeholder={t("factory")}
                              aria-label="Recipient's username"
                            >
                              {factories.map((factory) => (
                                orderDetails.factory_id == factory.id ?
                                  <option key={factory.id} value={factory.id} selected>
                                    {factory.name}
                                  </option> :
                                  <option key={factory.id} value={factory.id}>{factory.name}</option>
                              ))}
                            </Input>
                            {/* <InputGroupText>
                             <span
                            className="btn selectbtn"
                             onClick={toggle} 
                            >
                              +Add
                            </span>
                             <AddFactoryModal
                              modal={modal}
                              toggle={toggle}
                              companyId = {company_id}
                              workspaceId = {workspace_id}
                              factory = {setFactories}
                            /> 
                          </InputGroupText> */}
                          </InputGroup>
                          {errors.factory && (
                            <span className="error-msg">{t(errors.factory)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}

                    {getWorkspaceType != 'Buyer' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup className="">
                          <Label> {t("buyer")} </Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              disabled
                              className={`${errors.buyer && 'select-error-valid'}  form-control`}
                              name="buyer"
                              type="select"
                              placeholder={t("buyer")}
                              aria-label="buyer"
                              defaultValue={buyer}
                              //onChange={handleChange}
                              onChange={(e) => setBuyer(e.target.value)}
                            // onChange={(e) => setFactory(e.target.value)}
                            // onChange={(e) => setFactory(e.target.value)}
                            >
                              <option disabled >{t("selectBuyer")}</option>
                              {buyers.map((buy) => (
                                orderDetails.buyer_id == buy.id ?
                                  <option key={buy.id} selected value={buy.id}>{buy.name}</option> :
                                  <option key={buy.id} value={buy.id}>{buy.name}</option>
                              ))}
                            </Input>
{/* 
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={toggleBuyer}
                              >
                                {t("add")}
                              </span>
                              <AddBuyerModal
                                modal={modalBuyer}
                                toggle={toggleBuyer}
                                companyId={company_id}
                                workspaceId={workspace_id}
                                buyer={setBuyers}
                              />
                            </InputGroupText> */}
                          </InputGroup>
                          {errors.buyer && (
                            <span className="error-msg">{t(errors.buyer)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}

                    {getWorkspaceType != 'PCU' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup>
                          <Label>{t("productionControlUnit")}</Label>
                          {/* <sup className="font-danger">*</sup> */}
                          <InputGroup>
                            <Input
                              disabled
                              type="select"
                              placeholder={t("selectStatus")}
                              className={`${errors.pcu && 'select-error-valid'}  form-control digits`}
                              name="PCU"
                              defaultValue=""
                              onChange={(e) => setPcu(e.target.value)}

                            >
                              <option value="" disabled>
                                {t("selectPCU")}
                              </option>
                              {Pcus.map
                                ((pcuv) =>
                                (
                                  orderDetails.pcu_id == pcuv.id ?
                                    <option key={pcuv.id} selected>{pcuv.name}</option> :
                                    <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                                )
                                )
                              }
                            </Input>
                            {/* <InputGroupText> */}
                              {" "}
                              {/* <Btn
                              attrBtn={{ color: "primary", onClick: togglepcu }}
                            >
                              +Add
                            </Btn> */}
                              {/* <span
                                className="btn selectbtn"
                                onClick={togglepcu}
                              >
                                {t("add")}
                              </span> */}
                              {/* <AddPCUModal
                              modal={modalpcu}
                              toggle={togglepcu}
                              companyId = {company_id}
                              workspaceId = {workspace_id}
                              pcu = {setPcus}
                            /> */}
                            {/* </InputGroupText> */}
                          </InputGroup>
                          {errors.pcu && (
                            <span className="error-msg">{t(errors.pcu)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("orderNo")} </Label><sup className="font-danger">*</sup>
                        <input
                          className={`${errors.orderNo && 'error-valid'}  form-control`}
                          id="validationCustom02"
                          type="text"
                          placeholder={t("orderNo")}
                          name="order"
                          disabled
                          defaultValue={orderNo}
                          onKeyPress={(e) => orderOnValidation(e.target.value)}
                          onKeyUp={(e) => orderOnValidation(e.target.value)}
                          onKeyDown={(e) => orderOnValidation(e.target.value)}
                          onChange={(e) => setOrderNo(e.target.value)}
                        />
                        {errors.orderNo && (
                          <span className="error-msg">{t(errors.orderNo)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("styleNo")} </Label><sup className="font-danger">*</sup>
                        <input
                          className={`${errors.styleNo && 'error-valid'}  form-control`}
                          id="validationCustom02"
                          type="text"
                          placeholder={t("styleNo")}
                          name="style"
                          disabled
                          defaultValue={styleNo}
                          onChange={(e) => setStyleNo(e.target.value)}
                        />
                        {errors.styleNo && (
                          <span className="error-msg">{t(errors.styleNo)}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
                {/*start item description details */}
                <Col md="12" lg="12" sm="12" style={{marginTop:"-10px" }}>
                  <Row className="g-12  m-t-20">
                      <Col lg="12" md="12" sm="12" xs="12">
                        <span className="subTitleLine3 f-left">
                          <H6 className="ordersubhead">
                            {t("itemDescriptionArticle")}
                          </H6></span>
                        <span >
                          <hr className="mt-4 mb-4 m-t-10" />
                        </span>
                      </Col>
                  </Row>
                  <Row className="g-12">
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("fabricType")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                          <Input
                            type="select"
                            placeholder={t("selectStatus")}
                            className={`${errors.fabric && 'select-error-valid'}  form-control digits`}
                            name="fabric"
                            defaultValue=""
                            onChange={(e) => setFabric(e.target.value)}>
                            <option value="" disabled>
                              {t("selectFabricType")}
                            </option>
                            {fabrics.map((fabric) =>
                            (
                              orderDetails.fabric_id == fabric.id ?
                                <option key={fabric.id} value={fabric.id} selected>{fabric.name}</option> :
                                <option key={fabric.id}  value={fabric.id}>{fabric.name}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Fabric Add')) || getStaff == null ?
                          <InputGroupText>
                            {/* <Btn
                              attrBtn={{
                                color: "primary",
                                onClick: togglefabaric,
                              }}
                            >
                              +Add
                            </Btn> */}
                            <span
                              className="btn selectbtn"
                              onClick={togglefabaric}
                            >
                              {t("add")}
                            </span>
                            <AddFabaricModal
                              modal={modalfabaric}
                              toggle={togglefabaric}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              fabric={setFabrics}
                            />
                          </InputGroupText> : ""
                          }
                        </InputGroup>
                        {errors.fabric && (
                          <span className="error-msg">{t(errors.fabric)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("category")}</Label>
                        <InputGroup>
                          <Input
                            type="select"
                            placeholder={t("selectStatus")}
                            className={` ${errors.category && 'select-error-valid'}  form-control digits`}
                            name="category"
                            defaultValue=""
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="" disabled>
                              {t("selectCategory")}
                            </option>
                            {categories.map((category) =>
                            (
                              orderDetails.category_id == category.id ?
                                <option key={category.id} value={category.id} selected >{category.name}</option> :
                                <option key={category.id} value={category.id}>{category.name}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Category Add')) || getStaff == null ?
                          <InputGroupText>
                            {" "}
                            {/* <Btn
                              attrBtn={{ color: "primary", onClick: togglecat }}
                            >
                              +Add
                            </Btn> */}
                            <span
                              className="btn selectbtn"
                              onClick={togglecat}
                            >
                              {t("add")}
                            </span>
                        
                          </InputGroupText>: ""
                          }
                              <AddOrderCategoryModal
                              modal={modalcat}
                              toggle={togglecat}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              category={setCategories}
                            />
                        </InputGroup>
                        {errors.category && (
                          <span className="error-msg">{t(errors.category)}</span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>  {t("articleName")} </Label><sup className="font-danger">*</sup>
                        <InputGroup>
                          <Input
                            type="select"
                            placeholder={t("selectStatus")}
                            className={` ${errors.article && 'select-error-valid'}  form-control digits`}
                            name="Article Name"
                            defaultValue=""
                            onChange={(e) => setArticle(e.target.value)}
                          >
                            <option value="" disabled>
                              {t("selectArticle")}
                            </option>
                            {articles.map((article) =>
                            (
                              orderDetails.article_id == article.id ?
                                <option key={article.id} value={article.id} selected > {article.name}</option> :
                                <option key={article.id} value={article.id}> {article.name}</option>
                            )
                            )}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Article Add')) || getStaff == null ?
                          <InputGroupText>
                            {/* <Btn
                              attrBtn={{ color: "primary", onClick: toggleart }}
                            >
                              +Add
                            </Btn> */}
                            <span
                              className="btn selectbtn"
                              onClick={toggleart}
                            >
                              {t("add")}
                            </span>
                            
                          </InputGroupText>: ""
                          }
                          <AddArticleModal
                              modal={modalart}
                              toggle={toggleart}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              article={setArticles}
                            />
                        </InputGroup>
                        {errors.article && (
                          <span className="error-msg">{t(errors.article)}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end item description details */}
              </Row>
              {/*end first row */}

              {/*start second row*/}
              <Row className="g-12 m-t-20">
                {/*start order details */}
                <Col md="12" lg="12" sm="12"  style={{marginTop:"-15px" }}>
                  <Row className="g-12 m-t-15">
                    <Col lg="12" md="12" sm="12" xs="12">
                      <span className="subTitleLine2 f-left">
                        <H6 className="ordersubhead">{t("commercialDetails")}</H6>
                      </span>
                      <span>
                        <hr className="mt-4 mb-4 m-t-10" />
                      </span>
                    </Col>
                  </Row>
                  <Row className="g-12">
                  {(getStaff === "Staff" && getStaffPermission.includes('Edit Price')) || getStaff == null ?<>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("currency")}</Label>
                        <Input
                          type="select"
                          placeholder={t("selectStatus")}
                          className="form-control digits"
                          name="Currency"
                          defaultValue=""
                          onChange={(e) => setCurrency(e.target.value)}
                        >
                          <option value="" disabled>
                            {t("selectCurrency")}
                          </option>
                          {currencies.map((currency) =>
                          (
                            orderDetails.currency_type == currency.name ?
                              <option key={currency.id} value={currency.name} selected >{currency.symbol + ' ' + currency.name}</option> :
                              <option key={currency.id} value={currency.name}>{currency.symbol + ' ' + currency.name}</option>
                          )
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("price")}</Label>
                        <Input
                          id="price"
                          placeholder={t("price")}
                          className={`${errors.price && 'error-valid'}  form-control digits`}
                          name="price"
                          type="tel"
                          min="1"
                          maxLength="20"
                          value={price == 0 ? "" : price}
                          // onChange={handlePriceChange}
                          onKeyPress={(e) => handleKeyPressPrice(e)}
                          onChange={(e) => {
                            setPrice(e.target.value)
                          }}
                        ></Input>
                        {errors.price && (
                          <span className="error-msg">{t(errors.price)}</span>
                        )}
                      </FormGroup>

                    </Col></>:""}

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("units")}</Label>
                        <InputGroup>
                          <Input
                            type="select"
                            placeholder={t("selectStatus")}
                            className="form-control digits"
                            name="unitsdetails"
                            // defaultValue={Unitno}
                            onChange={(e) => setpUnit(e.target.value)}
                          >
                          <option value="" disabled>
                              {t("unit")}
                          </option>
                            { 
                                Object.keys(getUnitsList).length > 0 
                                ? 
                                  Object.values(getUnitsList)
                                  .map((mapData) => {
                                    return(
                                      <>
                                      {Unitno == mapData.id
                                      ?
                                        <option 
                                          key={mapData.id} 
                                          selected 
                                          value={mapData.id}
                                          title={mapData.name}>
                                          {mapData.name}
                                        </option>
                                      :
                                        <option key={mapData.id} value={mapData.id}
                                          title={mapData.name}>
                                          {mapData.name}
                                        </option>
                                      }
                                      </>
                                    )
                                  }) 
                                :
                                  ""
                              }
                          </Input>
                          <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={() => { 
                                    toggleUnits()
                                  }}>
                                {t("add")}
                              </span>
                          </InputGroupText> 
                        </InputGroup>

                        <AddUnitsModal
                          modal={modalUnits}
                          toggle={toggleUnits}
                          companyId={getLoginCompanyId}
                          workspaceId={getWorkspaceId}
                          staffId={getLoginStaffId}
                          userId={getLoginUserId}
                          setGetUnitsList={setGetUnitsList}
                          toggleType="addEditOrder"
                        />
                      </FormGroup>
                    </Col>

                    
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("incomeTerms")}</Label>
                        <Input
                          type="select"
                          placeholder={t("selectStatus")}
                          className="form-control digits"
                          name="Income Terms"
                          defaultValue=""
                          onChange={(e) => setIncomeTerm(e.target.value)}
                        >
                          <option value="" disabled>
                            {t("selectIncomeTerms")}
                          </option>
                          {/* { orderDetails.income_terms == } */}
                          {incomeTerms.map((incomeTerm) =>
                            orderDetails.income_terms == incomeTerm.id ?
                              <option key={incomeTerm.id} selected>{incomeTerm.name}</option> :
                              <option key={incomeTerm.id} value={incomeTerm.id}>
                                {incomeTerm.name}
                              </option>
                          )}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
                {/*start item description details */}
                <Col md="12" lg="12" sm="12">
                <Row className="g-12 m-t-15">
                  <Col lg="12" md="12" sm="12" xs="12"  style={{marginTop:"-10px" }}>
                      <span className="subTitleLine2 f-left">
                        <H6 className="ordersubhead">{t("quantityDetails")}</H6></span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                  </Col>
                </Row>
                  <Row className="g-12" xs="2" sm="2" md="4" lg="4">
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("totalQuantity")}</Label><sup className="font-danger">*</sup>
                        <Input
                          // readOnly={disableField}
                          placeholder={t("totalQuantity")}
                          className={`${errors.totquantity && 'error-valid'}  form-control digits`}
                          name="totquantity"
                          id="totquantity"
                          maxLength="10"
                          defaultValue={totalQuantity}
                          onChange={(e) => {
                            setTotalQuantity(e.target.value),
                              setToleranceVolume(Math.round((e.target.value / 100) * tolerancePercentage))
                          }}>
                          {" "}
                        </Input>
                        {errors.totquantity && (
                          <span className="error-msg">{t(errors.totquantity)}</span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("totalDeliveries")}</Label><sup className="font-danger">*</sup>
                        <Input
                          type="select"
                          placeholder={t("totalDeliveries")}
                          className={`${errors.totaldelivery && 'select-error-valid'}  form-control digits`}
                          name="factory"
                          defaultValue=""
                          onChange={(e) => {
                            setDelivery(e.target.value), 
                            setValue(() => "")
                            }} >
                          <option value="" disabled>
                            {t("selectTotalDeliveries")}
                          </option>
                          {deliveries}
                        </Input>
                        {errors.totaldelivery && (
                          <span className="error-msg">{t(errors.totaldelivery)}</span>
                        )}
                      </FormGroup>
                    </Col>
                 
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <Label>{t("isToleranceRequired")}</Label>
                      <FormGroup>
                        <Media key="1">
                          <Media body className="text-start icon-state switch-md ">
                            <Label className="switch" name="toggle">
                              <Input
                                type="checkbox"
                                name="tolisreq"
                                value={tolerancereq}
                                // disabled={disableField}
                                checked={!!tolerancereq}
                                onChange={(e) => {
                                  setTolerancereq(prevCheck => !prevCheck)
                                }} />
                              <span className="switch-state" ></span>
                            </Label>
                          </Media>
                        </Media>
                      </FormGroup>
                    </Col>

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                              <Label>{t("toleranceQuantity")}</Label>
                              <Input
                                placeholder={t("toleranceQuantity")}
                                className={`${errors.toleranceVolume && 'select-error-valid'}  form-control digits`}
                                name="toleranceVolume"
                                id="tolVol"
                                maxLength="10"
                                value={toleranceVolume}
                                onChange={(e) => {
                                  setToleranceVolume(e.target.value);
                                  if (totalQuantity) {
                                    let totQuantity = document.getElementById("totquantity").value;
                                    let tolVol = e.target.value;
                                    let tolPercentage = ((tolVol / totQuantity) * 100).toFixed(2);
                                    setTolerancePercentage(tolPercentage);
                                  } else {
                                    document.getElementById("tolVol").value = '';
                                    document.getElementById("tolPer").value = '';
                                    setTolerancePercentage('');
                                    setToleranceVolume('');
                                    Swal.fire({
                                      title: t("totalQuantityError"),
                                      text: t("fillTotalQuantity"),
                                      icon: "error",
                                      button: t("okLabel"),
                                    });
                                  }
                                }}
                              >
                                {" "}
                              </Input>
                              {errors.toleranceVolume && (
                                <span className="error-msg">{t(errors.toleranceVolume)}</span>
                              )}
                      </FormGroup>
                    </Col>
                    <Row style={{ width: '100%' }}>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                            <Label>{t("tolerancePercentage")}</Label>
                            <Input
                              readOnly={false}
                              placeholder={t("tolerancePercentage")}
                              className={`${errors.tolerancePercentage && 'select-error-valid'}  form-control digits`}
                              name="totPerc"
                              id="tolPer"
                              value={tolerancePercentage}
                              onChange={(e) => {
                                setTolerancePercentage(e.target.value);
                                if (totalQuantity.trim()) {
                                  let totQuantity = document.getElementById("totquantity").value;
                                  let tolVol = e.target.value;
                                  let tolPercentage = ((tolVol / 100) * totQuantity).toFixed(0);
                                  setToleranceVolume(tolPercentage);
                                } else {
                                  document.getElementById("tolVol").value = '';
                                  document.getElementById("tolPer").value = '';
                                  setTolerancePercentage('');
                                  setToleranceVolume('');

                                  Swal.fire({
                                    title: t("totalQuantityError"),
                                    text: t("fillTotalQuantity"),
                                    icon: "error",
                                    button: t("okLabel"),
                                  });
                                }
                              }}
                            >
                              {" "}
                            </Input>
                            {errors.tolerancePercentage && (
                              <span className="error-msg">{t(errors.tolerancePercentage)}</span>
                            )}
                      </FormGroup>
                    </Col>
                    {/* Inquiry Date Starts*/}
                    {((getStaff === "Staff"&&getStaffPermission.includes('Edit Inquiry Date'))||getStaff == null) ? 
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                          <Label>{t("poDate")}</Label>
                            <Input className="toggleButton"
                              type="date" name="inquiryDate"
                              defaultValue={inquiryDate}
                              value={inquiryDate}
                              max={new Date().toISOString().split('T')[0]}
                              onChange={(e) => { setInquiryDate(e.target.value) }} />
                      </FormGroup>
                    </Col> : ""
                    }

                    {/* <Col>
                        <Row>
                          <Label>{t("delDate")}
                          <sup className="font-danger">*</sup>
                          </Label>
                          { 
                                deliveryDate.length > 0?  
                                deliveryDate.map((dataDeliveryDate,i) => (
                              <span key={i} className="btn text-center " 
                                    style={{ height: '35px', width: '145px', borderRadius: '18px', 
                                             borderColor: '#ccc', marginLeft: '10px', marginTop: '5px',
                                             marginBottom:"10px", fontSize: '12px', color: '#878787', 
                                             fontWeight: '600',cursor:"unset"  }}> 
                                {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate))} 
                              </span>
                                ))
                              :
                              ""} 
                        </Row>
                    </Col> */}
                    {/* Delivery Date*/}
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("delDate")}</Label><sup className="font-danger">*</sup>
                          <Row 
                            onKeyPress={(e) => handleKeyPress(e)}
                            >
                              <DatePicker
                                value={value}
                                sort
                                multiple
                                id="datePicker"
                                // onChange={date => setValue(date)} 
                                onChange={date => selectedDatesList(date)} 
                                // onChange={date => selectedDatesList(date) } 
                                fixMainPosition
                                calendarPosition="top-left"
                                dateSeparator="     "
                                inputClass={`${errors.deliveryDate && 'select-error-valid'}  form-control`}
                                placeholder={t("selectDelDate")}
                                plugins={
                                  [ 
                                    <DatePanel />, 
                                      getWeekOff.length > 0 ? weekends(getWeekOff) :  weekends([-1]),
                                    <Toolbar
                                      style={{ backgroundColor: '#E5F4F3', paddingTop: '10px' }}
                                      position="bottom"
                                      sort={["deselect"]} 
                                      names={{
                                        // today: "Today",
                                        deselect: "Clear",
                                        // close: "Close"
                                      }} />,
                                      <DatePickerHeader 
                                        position="top" 
                                        size="small" 
                                        style={{ backgroundColor: "#E5F4F3", color: '#009688', paddingTop: '10px' }} 
                                      />,
                                      <RemainingDeliveryDates 
                                        position="bottom" 
                                      />,
                                  ]}
                                // minDate={new Date()}
                                // maxDate={ value.length == totalDelivery ? previous ? previous : ""  :  "" }
                                disableDayPicker={ 
                                  value !== null ? 
                                    value.length > 0  ? value.length == totalDelivery ? true : false : false
                                  :
                                  false }
                                style={{ cursor: 'pointer' }}
                                readOnly={totalDelivery !== "" ? false : true}
                                // disableYearPicker
                                // disableMonthPicker
                                // animations={[
                                //   opacity(), 
                                //   transition({ from: 35, duration: 800 })
                                // ]}
                                >
                              </DatePicker>
                          </Row>
                          {errors.deliveryDate && (
                                <span className="error-msg">{t(errors.deliveryDate)}</span>
                              )}
                      </FormGroup>
                    </Col>
                    </Row>
                  </Row>
                </Col>
                {/*end item description details */}
              </Row>
              {/*end second row*/}

              <Row>
                <Col>
                  {" "}
                  <FormGroup className="f-right p-t-20">
                    {/* <Button>Save and Continue</Button> */}
                    {/* <Link className="btn btn-primary"
                      to={`${process.env.PUBLIC_URL}/editsku?id=` + encode(orderId) + `&quantity=` + encode(totalQuantity)}>{t("next")}</Link> */}
                    &nbsp;&nbsp;
                    
                    <Button
                      // href={`${process.env.PUBLIC_URL}/ordersku`}
                      className="btn btn-primary"
                      onClick={() => {onhandleSubmit()}}
                      disabled={  datecount > 0 ? false : true}
                      >
                      {t("save")}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default OrderRegistration;
/* Code By : Rithanesh */
