import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,InputGroup,InputGroupText,
  FormGroup,
  Table,
  Button
} from "reactstrap";
import { H6, Btn } from "../../../AbstractElements";
import OrderHeaders from "./OrderHeaders";
/****************************************************************************************************/
import Swal from "sweetalert2";
import axios from "axios";
import { ServerUrl, maxUploadFileSize } from "../../../Constant";
import { useSearchParams } from "react-router-dom";
import { encode, decode, apiencrypt, apidecrypt, truncate } from "../../../helper";
import {
  getLoginCompanyId,
  getLoginUserId,
  getLoginStaffId,
  getWorkspaceId,
  getStaff,
  getStaffPermission,
  getWorkspaceType
} from "../../../Constant/LoginConstant";    

import { useTranslation } from "react-i18next";
import Files from "react-files";
import labelSampleIcon from "../../../assets/images/dms/icons/labelSampleIcon.svg";
import pdfDocIcon from "../../../assets/images/dms/icons/pdfDocIcon.svg";
import excelDocIconIcon from "../../../assets/images/dms/icons/excelDocIcon.svg";
import docIcon from "../../../assets/images/dms/icons/docIcon.svg";
import uploadDocIcon from "../../../assets/images/dms/icons/uploadDoc.svg";
import deleteDocIcon from "../../../assets/images/dms/icons/deleteDocIcon.svg";
import AddNewUnitModal from "./AddUnitsModal"
import AddNewDropdownModal from "./AddNewDropdownModal";
import VendorListModal from "./ViewVendorListModal";
// import ViewVendorModal from "./ViewVendorModal";

import ScrollBar from 'react-perfect-scrollbar';
import Add_icon from '../../../assets/images/dms/icons/Main Task Add_icon.svg'
import DeleteRowIcon from '../../../assets/images/dms/icons/deleteRowIcon.svg'
import AddMaterialsLabelsImageModal from './AddMaterialsLabelsImageModal';
import { max } from "moment/moment";

/************************************************************************************************/

const AddMaterialsLabels = (props) => {
  const { t } = useTranslation();
  const workspace_id = getWorkspaceId;
  const company_id = getLoginCompanyId;
  const user_id = getLoginUserId;
  const staff_id = getLoginStaffId
  /********************* [INPUT PARAMS for API Calls] (To add Order contacts)*******************/
  var getInputParams = {};
      getInputParams["company_id"] = getLoginCompanyId;
      getInputParams["workspace_id"] = getWorkspaceId;

  const inputParamsUser = {};
        inputParamsUser['company_id'] = getLoginCompanyId;
        inputParamsUser['workspace_id'] = getWorkspaceId;
        inputParamsUser['user_id'] = getLoginUserId;

  const [showAddNew, setShowAddNew] = useState(false);
  const toggleAddNew = () => setShowAddNew(!showAddNew);

  const [viewVendorModal, setViewVendorModal] = useState(false);
  const toggleViewVendor = () => setViewVendorModal(!viewVendorModal);

  const [ViewVendorListModal, setViewVendorListModal] = useState(false);
  const toggleViewVendorListModal = () => setViewVendorListModal(!ViewVendorListModal);

  const [searchParams, setSearchParams] = useSearchParams();
  const [referenceId, setReferenceId] = useState(Date.now());
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));

  const [vendorDetails, SetvendorDetails] = useState("");
  const [vendor_idDetails, Setvendor_idDetails] = useState(0);

  const [awsUrl, setAwsUrl] = useState();
  const [poId, setPoId] = useState("");

  const [ vendorList, setVendorList ] = useState([]);
  const [ getInquiryMasterDataList, setGetInquiryMasterDataList ] = useState([]);
  // const [ leadTimeDays, setLeadTimeDays ] = useState();
  // const maxDayValue = 10;
  useEffect(() => {

    {
      getWorkspaceType == "Factory" 
    ?
      ""
    :
      window.location.href="/dashboard"
    }


     axios.post(ServerUrl + "/all-vendor-list", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        setVendorList(response.data.data);
        setUnitsList(response.data.units);
    });

    {
      (((getStaff === "Staff" && getStaffPermission.includes('Add Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }

    // const generateDaysArray = () => {
    //   const numbersArray = [];
    //   for (let b = 1; b <= maxDayValue; b++) {
    //     numbersArray.push(b);
    //   }
    //   return numbersArray;
    // };

    // const newLeadTimeDays = generateDaysArray();
    // setLeadTimeDays(newLeadTimeDays);
   
  }, []);
  const [ printArtworkImageName, setPrintArtworkImageName ] = useState("");
  const [ printArtworkImageList, setPrintArtworkImageList ] = useState([]);
  
  const [mainLabel, setMainLabel] = useState("");
  const [mainLabelVendor, setMainLabelVendor] = useState("");
  const [mainLabelImageName, setMainLabelImageName ] = useState("");
  const [mainLabelImageList, setMainLabelImageList ] = useState([]);

  const [washCareVendor, setWashCareVendor] = useState("");
  const [washCareImageName, setWashCareImageName ] = useState("");
  const [washCareImageList, setWashCareImageList ] = useState([]);

  const [hangtagVendor, setHangtagVendor] = useState("");
  const [hangtagImageName, setHangtagImageName ] = useState("");
  const [hangtagImageList, setHangtagImageList ] = useState([]);

  const [barcodeStickers, setBarcodeStickers] = useState("");
  const [barcodeStickersVendor, setBarcodeStickersVendor] = useState("");
  const [barcodeStickersImageName, setBarcodeStickersImageName ] = useState("");
  const [barcodeStickersImageList, setBarcodeStickersImageList ] = useState([]);

  const [polybagImageName, setPolybagImageName] = useState("");
  const [polybagImageList, setPolybagImageList ] = useState([]);

  const [cartonVendor, setCartonVendor] = useState("");
  const [cartonImageName, setCartonImageName] = useState("");
  const [cartonImageList, setCartonImageList ] = useState([]);

  const [measurementSheetPdfName, setMeasurementSheetPdfName] = useState("");
  const [measurementSheetPdfList, setMeasurementSheetPdfList ] = useState([]);
  
  const [techPackPdfName, setTechPackPdfName] = useState("");
  const [techPackPdfList, setTechPackPdfList ] = useState([]);

  const [polybagVendor, setPolybagVendor] = useState("");

  const [masterType, setMasterType] = useState();

  const [showUnitAddNew, setShowUnitAddNew] = useState(false);
  const toggleUnitAddNew = () => setShowUnitAddNew(!showUnitAddNew);

  /****------- Image Upload API Call ---------- ****/
  const uploadImageApiCall = (imageType, file) => {
    axios.post(
      ServerUrl + "/add-order-materials-label-media",
      {
        media_type: imageType,
        order_id:orderId > 0 ? orderId : 1,
        file: file[0],
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then((response) => {
      if(response.data.status_code == 200){
        getOrderMaterialsLabelApiCall();
        if (imageType == "PrintArtwork") {
          var printArtworkData = response.data.files;
          setPrintArtworkImageList(printArtworkData.files);          
          setAwsUrl(printArtworkData.serverURL);
          {
            printArtworkData.files.length >0 ?
            (printArtworkData.files).map((mapData)=>{setPrintArtworkImageName(mapData.orginalfilename);})
            :
            setPrintArtworkImageName(()=>"");
          }
        } else if (imageType == "MainLabel") {
          let mainLabelData = response.data.files;
          setMainLabelImageList(mainLabelData.files);
          setAwsUrl(mainLabelData.serverURL);
          {
            mainLabelData.files.length >0 ?
            (mainLabelData.files).map((mapData)=>{setMainLabelImageName(mapData.orginalfilename);})
            :
            setMainLabelImageName(()=>"");
          }
        } else if (imageType == "WashCareLabel") {
          let washCareData = response.data.files;
          setWashCareImageList(washCareData.files);
          setAwsUrl(washCareData.serverURL);
          {
            washCareData.files.length >0 ?
            (washCareData.files).map((mapData)=>{setWashCareImageName(mapData.orginalfilename);})
            :
            setWashCareImageName(()=>"");
          }
        } else if (imageType == "Hangtag") {
          var hangtagData = response.data.files;
          setHangtagImageList(hangtagData.files);
          setAwsUrl(hangtagData.serverURL);
          {
            hangtagData.files.length >0 ?
            (hangtagData.files).map((mapData)=>{setHangtagImageName(mapData.orginalfilename);})
            :
            setHangtagImageName(()=>"");
          }
        } else if (imageType == "BarcodeStickers") {
          let barcodeStickersData = response.data.files;
          setBarcodeStickersImageList(barcodeStickersData.files);
          setAwsUrl(barcodeStickersData.serverURL);
          {
            barcodeStickersData.files.length >0 ?
            (barcodeStickersData.files).map((mapData)=>{setBarcodeStickersImageName(mapData.orginalfilename);})
            :
            setBarcodeStickersImageName(()=>"");
          }
        } else if (imageType == "Polybag") {
          let polybagData = response.data.files;
          setPolybagImageList(polybagData.files);
          setAwsUrl(polybagData.serverURL);
          {
            polybagData.files.length >0 ?
            (polybagData.files).map((mapData)=>{setPolybagImageName(mapData.orginalfilename);})
            :
            setPolybagImageName(()=>"");
          }
        } else if (imageType == "Carton") {
          let cartonData = response.data.files;
          setCartonImageList(cartonData.files);
          setAwsUrl(cartonData.serverURL);
          {
            cartonData.files.length >0 ?
            (cartonData.files).map((mapData)=>{setCartonImageName(mapData.orginalfilename);})
            :
            setCartonImageName(()=>"");
          }
        } else if (imageType == "MeasurementSheet"){
          let measurementSheetData = response.data.files;
          setMeasurementSheetPdfList(measurementSheetData.files);
          setAwsUrl(measurementSheetData.serverURL);
          {
            measurementSheetData.files.length >0 ?
            (measurementSheetData.files).map((mapData)=>{
              setMeasurementSheetPdfName(mapData.orginalfilename);})
            :
            setMeasurementSheetPdfName(()=>"");
          }
        } else if (imageType == "TechPack"){
          let techPackData = response.data.files;
          setTechPackPdfList(techPackData.files);
          setAwsUrl(techPackData.serverURL);
          {
            techPackData.files.length >0 ?
            (techPackData.files).map((mapData)=>{
              setTechPackPdfName(mapData.orginalfilename);})
            :
            setTechPackPdfName(()=>"");
          }
        }
      }
    });
  };
  /* -----Add New Modal Call for unit Fields----*/
  const dropVal = (idValue) => {  
    document.getElementById(idValue).value = 0;
    toggleUnitAddNew();
  };
  /****------- Image Upload Validation ---------- ****/
  const uploadImageValidation = ( type, files ) => {
    if( (type != "MeasurementSheet") && (type != "TechPack") ){
      files.map((fileData) => {
        if(
            fileData.extension == "jpeg" ||
            fileData.extension == "jpg" ||
            fileData.extension == "png"
        ){
          const getFileSize = Math.round(fileData.size / 1024);
          if (getFileSize > maxUploadFileSize) {
            Swal.fire({
              title: t("sizeExceededTitleAlert"),
              text: t("uploadFileTalidationText", {
                fileSize: maxUploadFileSize / 1024,
              }),
              icon: "warning",
              button: t("okLabel"),
              timer: 5000,
            });
          } else {
            uploadImageApiCall(type, files);
          }
        } else {
          Swal.fire({
            title: t("wrongFileFormat"),
            text: t("validFileFormatsImages"),
            icon: "warning",
            button: t("okLabel"),
            timer: 5000,
          });
        }
      });
    } else{
      files.map((fileData) => {
        if ( 
            fileData.extension == "jpeg" ||
            fileData.extension == "jpg" ||
            fileData.extension == "png" ||
            fileData.extension == "pdf" ||
            fileData.extension == "xls" ||
            fileData.extension == "xlsx" 
          ) {
          const getFileSize = Math.round(fileData.size / 1024);
          if (getFileSize > maxUploadFileSize) {
            Swal.fire({
              title: t("sizeExceededTitleAlert"),
              text: t("uploadFileTalidationText", {
                fileSize: maxUploadFileSize / 1024,
              }),
              icon: "warning",
              button: t("okLabel"),
              timer: 5000,
            });
          } else{
            setLabelSampleType(type);
            uploadImageApiCall(type, files);
          }
        } else {
          Swal.fire({
            title: t("wrongFileFormat"),
            text: "Only .jpeg, .jpg, .png, .pdf, .xls, .xlsx file format are allowed",
            icon: "warning",
            button: t("okLabel"),
            timer: 5000,
          });
        }
      });
    }
  };

  /****------- Delete Image  ---------- ****/
  const deleteImageFiles = (imageType, file) => {
    var media = {};
    media["media_id"] =  file.media_id ? file.media_id : file.id;
    if(imageType == "MeasurementSheet") {
      Swal.fire({
        title: t("deleteConfirmationTitleAlert"),
        text: t("cantRevertBack"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
        timer: 5000,
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(ServerUrl + "/delete-order-materials-label-media", apiencrypt(media))
            .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code == 200) {
                Swal.fire({
                  title: t(response.data.meassage),
                  icon: "success",
                  showCancelButton: true,
                  button: t("okLabel"),
                  timer: 5000,
                }).then((result) => {
                  if (result.isConfirmed) {
                    uploadImageApiCall(imageType, file);
                  }
                }
                )
              }
            })
        }
      });

    }
    else {
      Swal.fire({
        title: t("areYouSureToDeleteThisImage"),
        text: t("cantRevertBack"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
        timer: 5000,
      }).then((result) => {
        if (result.isConfirmed){
          axios.post(ServerUrl + "/delete-order-materials-label-media", apiencrypt(media))
                .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code == 200) {
                Swal.fire({
                  title: t(response.data.meassage),
                  icon: "success",
                  showCancelButton: true,
                  button: t("okLabel"),
                  timer: 5000,
                }).then((result) => {
                  if (result.isConfirmed) {
                    uploadImageApiCall(imageType, file);
                  }
                }
                )
              }
            })
        }
      });
    }
  };
  
  const viewinformation = (idValue) => {

    document.getElementById(idValue).value = 0;

    Setvendor_idDetails(() => "");
    axios.post(ServerUrl + "/label-vendor-list", apiencrypt({
            "company_id": getLoginCompanyId,
            "workspace_id": getWorkspaceId,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data)
        SetvendorDetails(response.data.data);
        Setvendor_idDetails(response.data.vendor_id);
    });

    setTimeout(() => { toggleViewVendorListModal() }, 100);
      
  };

  const [addMaterialsLabelsImageModal, setAddMaterialsLabelsImageModal] = useState(false);
  const  toggleAddMaterialsLabelsImageModal = () => setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal);
  const [sewAccessoriesRowData, setSewAccessoriesRowData] = useState([]);
  const [packAccessoriesRowData, setPackAccessoriesRowData] = useState([]);
  const [miscellaneousRowData, setMiscellaneousRowData] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [ labelSampleType, setLabelSampleType ] = useState("");
  const [ measurementSheetLabelSample, setMeasurementSheetLabelSample ] = useState("");
  const [ techPackLabelSample, setTechPackLabelSample ] = useState("");
  const [ uploadedImageList, setUploadedImageList ] = useState([]);
  const [ uploadedImageName, setUploadedImageName ] = useState("");
  const [ serverURL, setServerURL ] = useState("");
  const [uploadedMediaList, setUploadedMediaList ] = useState([]);

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function OnlyNumbersValidation(e, type, id) {
    if( (type == "PricePerUnit") || (type == "perGMT")||(type=="TotalPrice")||(type=="pricePerUnit") ){
      var key = e.key;
      var currentValue= e.target.value;
      var regex = /[0-9.]/;
      if( !regex.test(key) ) {
          e.preventDefault();
      }
      if (key === "." && currentValue.includes(".")) {
        e.preventDefault();
      }
    } else if(type == "leadTime"){
      var key = e.key;
      var regex = /[0-9]/;
      var currentValue = e.target.value;
      if (!regex.test(key) || currentValue.length >= 3) {
        e.preventDefault();
    }
    }else{
      var key = e.key;
      var regex = /[0-9]/;
      if( !regex.test(key) ) {
          e.preventDefault();
      }
    }
  
    if( e.target.value == 0 && e.target.value !== "" ){
      document.getElementById(id+"_Error").textContent = t("invalidNumber");
    }
    else{ 
        document.getElementById(id+"_Error").textContent = ""; 
    }
  
    };

  const AddTotalPrice = (id, id2,id3) =>{
    var Sewing_totalQty = document.getElementById(id).value !=null ? document.getElementById(id).value : "";
    var Sewing_pricePerUnit = document.getElementById(id2).value !=null ? document.getElementById(id2).value : "";
    if(Sewing_totalQty != null && Sewing_pricePerUnit != null){
       document.getElementById(id3).value = Sewing_totalQty * Sewing_pricePerUnit
       }
    }


  const [ sewAdditionalIdValues, setSewAdditionalIdValues ] = useState([]);
  const [ packAdditionalIdValues, setPackAdditionalIdValues ] = useState([]);
  const [ miscAdditionalIdValues, setMiscAdditionalIdValues ] = useState([]);

  const [ sewRemovedIdValues, setSewRemovedIdValues ] = useState([]);
  const [ packRemovedIdValues, setPackRemovedIdValues ] = useState([]);
  const [ miscRemovedIdValues, setMiscRemovedIdValues ] = useState([]);

  const [ sewCount, setSewCount ] = useState(4);
  const [ packCount, setPackCount ] = useState(2);
  const [ miscellaneousCount, setMiscellaneousCount ] = useState(1);

  const [ accessType, setAccessType ] = useState();

  const addSewAccessoriesTableRows = () => {
    const rowsInput={
        id: '',
        Type: '',
        component:'',
        color:'',
        vendor:'',  
        perGMT:'',  
        totalQty:'',
        leadTime: '',
        units: '',  
        pricePerUnit: '',  
        totalPrice: '',
    } 
    setSewAccessoriesRowData([...sewAccessoriesRowData, rowsInput]);
    setSewAdditionalIdValues([...sewAdditionalIdValues, sewCount]);
  };

  const addPackAccessoriesTableRows = ()=>{
    const rowsInput={
        id: '',
        Type: '', 
        component:'',
        color:'',
        vendor:'',  
        perGMT:'',  
        totalQty:'',
        leadTime: '',
        units: '',  
        pricePerUnit: '',  
        totalPrice: '',
    } 
    setPackAccessoriesRowData([...packAccessoriesRowData, rowsInput]);
    setPackAdditionalIdValues([...packAdditionalIdValues, packCount]);

  };

  const addMiscellaneousTableRows = ()=>{
    const rowsInput={
        id: '',
        Type: '', 
        component:'',
        color:'',
        vendor:'',  
        perGMT:'',  
        totalQty:'',
        leadTime: '',
        units: '',  
        pricePerUnit: '',  
        totalPrice: '',
    } 
    setMiscellaneousRowData([...miscellaneousRowData, rowsInput]);
    setMiscAdditionalIdValues([...miscAdditionalIdValues, miscellaneousCount]);

  };
  const deleteSewAccessoriesTableRows = (index)=>
  {

    var remainingSewArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime","units", "pricePerUnit","totalPrice"];
    sewAccessoriesRowData.map((mapData, index) => {
      var index = sewAdditionalIdValues[index];
      var remainingSewObj = {};
      keyList.map((keyList) => {
          if( keyList == 'id' ){ 
            var data = index; 
          } else{
            var data = document.getElementById("Sewing_"+keyList+ "_" +index).value;
          }
          remainingSewObj[keyList] = data === undefined ? "" : data;
      })
      remainingSewArray.push(remainingSewObj);
    });

    var value = sewAdditionalIdValues.indexOf(index) !== -1 && sewAdditionalIdValues.splice(sewAdditionalIdValues.indexOf(index),1);
    // array.splice(index-4, 1);
    const rows = [...sewAccessoriesRowData];
    rows.splice(-1, 1);
    setSewAccessoriesRowData(rows);

    var filteredSewArray = remainingSewArray.filter(filterData => filterData.id !== index);
    setSewAccessoriesRowData(filteredSewArray);

    setSewRemovedIdValues([...sewRemovedIdValues, value]);

  };
   
  const deletePackAccessoriesTableRows = (index)=>
  {

    var remainingPackArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime", "units", "pricePerUnit","totalPrice"];
                         
    packAccessoriesRowData.map((mapData, index) => {
      var index = packAdditionalIdValues[index];
      var remainingPackObject = {};
      keyList.map((keyList) => {
        if( keyList == 'id' ){ 
          var data = index; 
        } else{
          var data = document.getElementById("Packing"+"_"+keyList+ "_" +index).value;
        }
        remainingPackObject[keyList] = data === undefined ? "" : data;
      })
      remainingPackArray.push(remainingPackObject);
    });

    var value = packAdditionalIdValues.indexOf(index) !== -1 && packAdditionalIdValues.splice(packAdditionalIdValues.indexOf(index), 1);
    const rows = [...packAccessoriesRowData];
    rows.splice(-1, 1);
    setPackAccessoriesRowData(rows);

    var filteredPackArray = remainingPackArray.filter(filterData => filterData.id !== index);
    setPackAccessoriesRowData(filteredPackArray);

    setPackRemovedIdValues([...packRemovedIdValues, value]);

  };

  const deleteMiscellaneousTableRows = (index)=>
  {

    var remainingMiscArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty" ,"leadTime", "units", "pricePerUnit","totalPrice"];

    miscellaneousRowData.map((mapData, index) => {
      var index = miscAdditionalIdValues[index];
      var remainingMiscObject = {};
      keyList.map((keyList) => {
        if( keyList == 'id' ){ 
          var data = index; 
        } else{
          var data = document.getElementById("Miscellaneous"+"_"+keyList+ "_" +index).value;
        }
        remainingMiscObject[keyList] = data === undefined ? "" : data;
      })
      remainingMiscArray.push(remainingMiscObject);
    });

    var value = miscAdditionalIdValues.indexOf(index) !== -1 && miscAdditionalIdValues.splice(miscAdditionalIdValues.indexOf(index), 1);
    const rows = [...miscellaneousRowData];
    rows.splice(-1, 1);
    setMiscellaneousRowData(rows);

    var filteredMiscArray = remainingMiscArray.filter(filterData => filterData.id !== index);
    setMiscellaneousRowData(filteredMiscArray);

    setMiscRemovedIdValues([...miscRemovedIdValues, value]);

  };

  const onSaveBillOfMaterials = () => {

    var sewArray = [];
    var packArray = [];
    var miscellaneousArray = [];

    const sewAccessories = ["MainLabel", "Washcare", "BarcodeStickers", "Hangtag"];
    const packAccessories = ["Polybag", "Cartonbox"];
    const miscellaneousAccessories = ["Miscellaneous"];

    const keyData = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime","units", "pricePerUnit","totalPrice", "media_type"];

    sewAccessories.map((mapData, index) => {
      var sewObject1 = {};
      keyData.map((keyData) => {
        if("Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_0"){ 
          var data = "MainLabel";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_1" ){
          var data = "Washcare";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_2" ){
          var data = "BarcodeStickers";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_3" ){
          var data = "Hangtag";
        } else{

        if( keyData == 'media_type'){ 
          var data = "Sewing_Type_" +index   
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var data = document.getElementById("Sewing"+"_"+keyData+ "_" +index).value;
        }
        }
        sewObject1[keyData] = data === undefined ? "" : data;
      })
      sewArray.push(sewObject1);
    });
    
    sewAccessoriesRowData.map((mapData, index) => {
      var index = sewAdditionalIdValues[index];
      var sewObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Sewing_Type_"+index;
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var data = document.getElementById("Sewing_"+keyData+ "_" +index).value;
        }
          sewObject2[keyData] = data === undefined ? "" : data;
      })
      sewArray.push(sewObject2);
    });

    packAccessories.map((mapData, index) => {
      var packObject1 = {};
      keyData.map((keyData) => {
        if("Packing"+"_"+keyData+ "_" +index == "Packing_Type_0"){ 
          var data = "Polybag";
        } else if( "Packing"+"_"+keyData+ "_" +index == "Packing_Type_1" ){
          var data = "Cartonbox";
        } else{
          if( keyData == "media_type" ){
            var data = "Packing_Type_"+index;
          } else if( keyData == 'id' ){
            var data = index;
          } else{
            var data = document.getElementById("Packing"+"_"+keyData+ "_" +index).value;
          }
        }
        packObject1[keyData] = data === undefined ? "" : data;
      })
      packArray.push(packObject1);
    });
    
    packAccessoriesRowData.map((mapData, index) => {
      var index = packAdditionalIdValues[index];
      var packObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Packing_Type_"+index;
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var data = document.getElementById("Packing"+"_"+keyData+ "_" +index).value;
        }
          packObject2[keyData] = data === undefined ? "" : data;
      })
      packArray.push(packObject2);
    });
    
    miscellaneousAccessories.map((mapData, index) => {
      var miscellaneousObject1 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Miscellaneous_Type_"+0;
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var data = document.getElementById("Miscellaneous_"+keyData+"_"+0).value;
        }
        
        miscellaneousObject1[keyData] = data === undefined ? "" : data;
      })
      miscellaneousArray.push(miscellaneousObject1);
    });

    miscellaneousRowData.map((mapData, index) => {
      var index = miscAdditionalIdValues[index];
      var miscellaneousObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Miscellaneous_Type_" +index
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var data = document.getElementById("Miscellaneous_"+keyData+ "_" +index).value;
        }
        
          miscellaneousObject2[keyData] = data === undefined ? "" : data;
      })
      miscellaneousArray.push(miscellaneousObject2);
    });

    var errorValues = emptyValidation1(sewArray, packArray, miscellaneousArray);
    // return false;
    if ((errorValues).length == 0) {
      var apiInputParams = {};
          apiInputParams['company_id'] = getLoginCompanyId; 
          apiInputParams['workspace_id'] = getWorkspaceId; 
          apiInputParams['user_id'] = getLoginUserId; 
          apiInputParams['staff_id'] = getLoginStaffId; 
          apiInputParams['order_id'] = orderId; 
          apiInputParams['sewing_accessories'] = sewArray ? sewArray : []; 
          apiInputParams['packing_accessories'] = packArray ? packArray : [];  
          apiInputParams['miscellaneous_accessories'] = miscellaneousArray ? miscellaneousArray : [];

          axios.post(ServerUrl + "/add-order-bom", apiencrypt(apiInputParams))
          .then((response) => {
            response.data = apidecrypt(response.data);      
            if( response.data.status_code == 200 ){
              Swal.fire({
                title: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                allowOutsideClick: false,
                timer: 5000
              })
              .then((result) => {
                if (result.isConfirmed) { 
                  window.location.href = "/orderproductionplan?id=" + encode(orderId);
                }
                window.location.href = "/orderproductionplan?id=" + encode(orderId);
              })
            } else if( response.data.status_code == 401 ){
              Swal.fire({
                title: t(response.data.error.order_id),
                icon: "warning",
                button: t("okLabel"),
                allowOutsideClick: false,
                timer: 5000
              })
            }
          });
    }

  };

  const getOrderMaterialsLabelApiCall = () => {

    axios.post(ServerUrl + "/get-order-bom", apiencrypt({ "order_id": orderId }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        var uploadedImages = response.data.media;
        setUploadedImageList(uploadedImages);          
        setServerURL(response.data.serverURL);
        {
          uploadedImages.length > 0 ?
          (uploadedImages)
          .filter(filterData => filterData.media_type == labelSampleType)
          .map((mapData)=>{ setUploadedImageName(mapData.orginalfilename) })
          :
          ""
          setUploadedImageName(()=>"");
        }
    });

  };
 
  const emptyValidation1 = ( sewArray, packArray, miscellaneousArray ) => {

    var errors = [];
    sewArray.map((mapData) => {
      if( mapData.Type == '' || mapData.Type === undefined ){
        document.getElementById("Sewing_Type_"+mapData.id+"_Error").textContent = t("typeValidation");
        errors.push("sew_id_"+mapData.id);
      } 
    });

    packArray.map((mapData) => {
      if( mapData.Type == '' || mapData.Type === undefined ){
        document.getElementById("Packing_Type_"+mapData.id+"_Error").textContent = t("typeValidation");;
        errors.push("pack_id_"+mapData.id);
      } 
    });

    miscellaneousArray.map((mapData, index) => {
      if( mapData.id !== 0 ){
        if( mapData.Type == '' || mapData.Type === undefined ){
          document.getElementById("Miscellaneous_Type_"+mapData.id+"_Error").textContent = t("typeValidation");;
          errors.push("misc_id_"+mapData.id);
        } 
      }
    });

    return errors;
  };

  const emptyValidation2 = ( value, id ) => {
      if( value == "" ){ 
        document.getElementById(id).textContent = t("typeValidation");
      }
      else{ 
        document.getElementById(id).textContent = " "; 
      }
  };

  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">

        <Card style={{ border: 'none' }}>
          <CardBody style={{ border: 'none' }}>
            <OrderHeaders title="addmaterialsLabels"/>
          </CardBody>
        </Card>

        {/* Sewing Accessories */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-40px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>
              <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead">{t("sewingAccessories")}</H6>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <ScrollBar  options={{ suppressScrollX: true }}>
                  <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding removeTableBottomBorder tableViewRes">
                        <thead>
                          <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                            <th> {t("type")} </th>
                            <th className="text-center">{t("component")}</th>
                            <th className='text-center'>{t("color")}</th>
                            <th className='text-center'>{t("vendorInformation")}</th>
                            <th className='text-center'>{t("perGMT")}</th>
                            <th className='text-center'>{t("totalQty")}</th>
                            <th className='text-center'>{t("leadTime")}</th>
                            <th className='text-center'>{t("units")}</th>
                            <th className='text-center'>{t("pricePerUnit")}</th>
                            <th className="text-center">{t("totPrice")}</th>
                            <th className='text-center'>{t("labelSample")}</th>
                          </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td className="p-t-0 p-b-0" id={"Removed_Sew_Ids_"+sewRemovedIdValues}>
                            <span className="error-msg"></span>           
                          </td>
                        </tr>

                        {/* Mainlabel */}
                        <tr>
                          <td id={"Sewing_Type_0"}> 
                            {t("mainLabel") }
                            <span className="error-msg"></span>           
                          </td>

                          <td width="12%">
                            <Input
                              id={"Sewing_component_0"}
                              name="MainLabel"
                              placeholder={t("component")}
                              onChange={(e) => setMainLabel(e.target.value)}
                            >
                            </Input>
                            <span className="error-msg"></span>           
                          </td>

                          <td width="10%">
                            <Input
                              id={"Sewing_color_0"}
                              name="MainLabelColor"
                              placeholder={t("color")}
                            />
                            <span className="error-msg"></span>           
                          </td>

                          <td width="12%"> 
                            <Input
                              type="select"
                              id={"Sewing_vendor_0"}
                              className="form-control digits selectheight"
                              name="Vendor Information"
                              onChange={(e) =>{ 
                                e.target.value!="addNew"?
                                setMainLabelVendor(e.target.value): viewinformation("Sewing_vendor_0") }} >
                              <option value={0}> {t("selectVendor")} </option>
                            { vendorList.length > 0 
                            ? 
                              (vendorList)
                              .map((mapData) => 
                              (
                                <option
                                key={mapData.id}
                                  id={"Sewing_vendor_0"}
                                  value={mapData.id}
                                  title={mapData.vendor_name} >
                                  {mapData.vendor_name}
                                </option>
                              ))
                            :
                              ""
                            }
                          <option value="addNew">+{t("addNew")}</option>
                          </Input>
                          <span className="error-msg"></span>           
                          </td>

                          <td>
                            <Input
                              id={"Sewing_perGMT_0"}
                              name="MainLabelPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_0")}
                            />
                            <span id={"Sewing_perGMT_0_Error"} className="error-msg"></span>            
                          </td>

                          <td>
                            <Input
                              id={"Sewing_totalQty_0"}
                              name="MainLabelTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_0"),AddTotalPrice("Sewing_totalQty_0","Sewing_pricePerUnit_0","Sewing_totalPrice_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_0")}
                            />
                            <span id={"Sewing_totalQty_0_Error"} className="error-msg"></span>                    
                          </td>

                          <td>
                            <Input
                               id={"Sewing_leadTime_0"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_0")}
                            />
                            <span id={"Sewing_leadTime_0_Error"} className="error-msg"></span>            
                          </td>

                          {/* <td>
                            <Input
                              type="select"
                              id={"Sewing_leadTime_0"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                               leadTimeDays.map((day)=>(
                                <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                               )) :""}
                            

                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}

                          <td width="10%">
                            <Input
                              type="select"
                              id={"Sewing_units_0"}
                              name="MainLabelUnits"
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Sewing_units_0") :""}}
                            >
                            <option selected disabled value={0}>{t("selectUnits")}</option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,i) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Sewing_units_0"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg"></span>           
                          </td>

                          <td>
                            <Input
                              id={"Sewing_pricePerUnit_0"}
                              name="MainLabelPricePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_0"),AddTotalPrice("Sewing_totalQty_0","Sewing_pricePerUnit_0","Sewing_totalPrice_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_0")}
                            />
                            <span id={"Sewing_pricePerUnit_0_Error"} className="error-msg"></span>           
                          </td>
                          <td>
                            <Input
                               id={"Sewing_totalPrice_0"}
                               name="MainLabelTotalPrice"
                               placeholder={t("totPrice")}
                               disabled
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_0")}
                            />
                            <span id={"Sewing_totalPrice_0_Error"} className="error-msg"></span>            
                          </td>
                          <td 
                            style={{  backgroundColor: '#fff', paddingTop: '1.10rem'}} 
                            className="f-left">
                              <img
                                width="23px"
                                height="23px"
                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                onClick={() => { 
                                  setAccessType(""),
                                  getOrderMaterialsLabelApiCall(),
                                  setLabelSampleType("Sewing_Type_0"),
                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                }}
                                src={labelSampleIcon}
                              />
                          </td>

                        </tr>

                        {/* WashCare */}
                        <tr>
                          <td id={"Sewing_Type_1"}> 
                             {t("washCare")}
                            <span className="error-msg"></span>           
                          </td>

                          <td width="12%">
                            <Input
                              id={"Sewing_component_1"}
                              name="WashCare"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg"></span>           
                          </td>

                          <td width="10%">
                            <Input
                              id={"Sewing_color_1"}
                              name="WashCareColor"
                              placeholder={t("color")}
                            />
                            <span className="error-msg"></span>           
                          </td>

                          <td width="12%"> 
                            <Input
                                type="select"
                                id={"Sewing_vendor_1"}
                                className="form-control digits selectheight"
                                name="Vendor Information"
                                onChange={(e) =>{ 
                                  e.target.value!="addNew" ?
                                  setWashCareVendor(e.target.value): viewinformation("Sewing_vendor_1") }} >
                                <option selected disabled value={0}>
                                  {t("selectVendor")}
                                </option>
                                { vendorList.length > 0 
                                ? 
                                  (vendorList).map((mapData) => 
                                  (
                                    <option
                                    key={mapData.id}
                                      id={"Sewing_vendor_1"}
                                      value={mapData.id}
                                      title={mapData.vendor_name} >
                                      {mapData.vendor_name}
                                    </option>
                                  ))
                                :
                                  ""
                                }
                              <option value="addNew">+{t("addNew")}</option>
                            </Input>
                            <span className="error-msg"></span>           
                          </td>

                          <td>
                            <Input
                              id={"Sewing_perGMT_1"}
                              name="WashCarePerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_1") }}
                              onKeyPress={(e) => {OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_1")}}
                            />
                            <span id={"Sewing_perGMT_1_Error"} className="error-msg"></span>            
                          </td>

                          <td>
                            <Input
                              id={"Sewing_totalQty_1"}
                              name="WashCareTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_1"),AddTotalPrice("Sewing_totalQty_1","Sewing_pricePerUnit_1","Sewing_totalPrice_1") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_1")}
                            />
                            <span id={"Sewing_totalQty_1_Error"} className="error-msg"></span>       
                          </td>
                          <td>
                            <Input
                               id={"Sewing_leadTime_1"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_1")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_1")}
                            />
                            <span id={"Sewing_leadTime_1_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                            <Input
                              type="select"
                              id={"Sewing_leadTime_1"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                               leadTimeDays.map((day)=>(
                                <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                               )) :""}
                             

                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}

                          <td width="10%">
                            <Input
                              type="select"
                              id={"Sewing_units_1"}
                              name="WashcareUnits"
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Sewing_units_1") :""}}
                            >
                            
                            <option selected disabled value={0}> {t("selectUnits")} </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,j) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Sewing_units_1"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg"></span>           
                          </td>

                          <td>
                            <Input
                              id={"Sewing_pricePerUnit_1"}
                              name="WashCarePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_1"),AddTotalPrice("Sewing_totalQty_1","Sewing_pricePerUnit_1","Sewing_totalPrice_1") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_1")}
                            />
                            <span id={"Sewing_pricePerUnit_1_Error"} className="error-msg"></span>            
                          </td>
                          <td>
                            <Input
                               disabled
                               id={"Sewing_totalPrice_1"}
                               name="WashCareTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_1")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_1")}
                            />
                            <span id={"Sewing_totalPrice_1_Error"}className="error-msg"></span>            
                          </td>
                          <td 
                           style={{  backgroundColor: '#fff', paddingTop: '1.10rem'}} 
                            className="f-left">
                              <img
                                width="23px"
                                height="23px"
                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                onClick={() => { 
                                  setAccessType(""),
                                  getOrderMaterialsLabelApiCall(),
                                  setLabelSampleType("Sewing_Type_1"),
                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                }}
                                src={labelSampleIcon}
                              />
                          </td>

                        </tr>

                        {/* Barcode Stickers */}
                        <tr>
                          <td id={"Sewing_Type_2"}> 
                          {t("barcodeStickers")} 
                            <span className="error-msg"></span>           
                          </td>

                          <td width="12%">
                            <Input
                              id={"Sewing_component_2"}
                              name="Barcode Stickers"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg"></span>   
                          </td>

                          <td width="10%">
                            <Input
                              id={"Sewing_color_2"}
                              name="BarcodeStickersColor"
                              placeholder={t("color")}
                            />
                             <span className="error-msg"></span>   
                          </td>

                          <td width="12%"> 
                            <Input
                              id={"Sewing_vendor_2"}
                              type="select"
                              className="form-control digits selectheight"
                              name="Vendor Information"
                              onChange={(e) =>{ 
                                e.target.value!="addNew"?
                                setBarcodeStickersVendor(e.target.value): viewinformation("Sewing_vendor_2")
                              }}
                            >
                            <option selected disabled value={0}>
                              {t("selectVendor")}
                            </option>
                            { vendorList.length > 0 
                            ? 
                              (vendorList).map((mapData) => 
                              (
                                <option
                                key={mapData.id}
                                  id={"Sewing_vendor_2"}
                                  value={mapData.id}
                                  title={mapData.vendor_name} >
                                  {mapData.vendor_name}
                                </option>
                              ))
                            :
                              ""
                            }
                        <option value="addNew">+{t("addNew")}</option>
                        </Input>
                        <span className="error-msg"></span>   
                          </td>

                          <td>
                            <Input
                              id={"Sewing_perGMT_2"}
                              name="BarcodeStickersPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_2") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_2")}
                            />
                            <span id={"Sewing_perGMT_2_Error"} className="error-msg"></span>            
                          </td>

                          <td>
                            <Input
                              id={"Sewing_totalQty_2"}
                              name="BarcodeStickersTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_2"),AddTotalPrice("Sewing_totalQty_2","Sewing_pricePerUnit_2","Sewing_totalPrice_2") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_2")}
                            />
                            <span id={"Sewing_totalQty_2_Error"} className="error-msg"></span>    
                          </td>
                          <td>
                            <Input
                               id={"Sewing_leadTime_2"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_2")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_2")}
                            />
                            <span id={"Sewing_leadTime_2_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                            <Input
                              type="select"
                              id={"Sewing_leadTime_2"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                               leadTimeDays.map((day)=>(
                                <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                               )) :""}
                           

                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}

                          <td width="10%">
                            <Input
                              type="select"
                              id={"Sewing_units_2"}
                              name="BarcodeStickersUnits"
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Sewing_units_2") :""}}
                            >
                            
                            <option selected disabled value={0}> {t("selectUnits")} </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,k) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Sewing_units_2"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg"></span>   
                          </td>

                          <td>
                            <Input
                              id={"Sewing_pricePerUnit_2"}
                              name="BarcodeStickersPricePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_2"),AddTotalPrice("Sewing_totalQty_2","Sewing_pricePerUnit_2","Sewing_totalPrice_2") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_2")}
                            />
                            <span id={"Sewing_pricePerUnit_2_Error"} className="error-msg"></span> 
                          </td>
                          <td>
                            <Input
                                disabled
                               id={"Sewing_totalPrice_2"}
                               name="BarcodeStickersTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_2")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_2")}
                            />
                            <span id={"Sewing_totalPrice_2_Error"} className="error-msg"></span>            
                          </td>
                          <td 
                            style={{  backgroundColor: '#fff', paddingTop: '1.10rem'}} 
                            className="f-left">
                              <img
                                width="23px"
                                height="23px"
                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                onClick={() => { 
                                  setAccessType(""),
                                  getOrderMaterialsLabelApiCall(),
                                  setLabelSampleType("Sewing_Type_2"),
                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                }}
                                src={labelSampleIcon}
                              />
                          </td>
                          
                        </tr>

                        {/* Hangtag */}
                        <tr>
                          <td id={"Sewing_Type_3"}> 
                          {t("hangtag")} 
                            <span className="error-msg"></span>   
                          </td>

                          <td width="12%">
                            <Input
                              id={"Sewing_component_3"}
                              name="Hangtag"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg"></span>   
                          </td>

                          <td width="10%">
                            <Input
                              id={"Sewing_color_3"}
                              name="HangtagColor"
                              placeholder={t("color")}
                            />
                            <span className="error-msg"></span>   

                          </td>

                          <td width="12%"> 
                            <Input
                                id={"Sewing_vendor_3"}
                                type="select"
                                className="form-control digits selectheight"
                                name="Vendor Information"
                                onChange={(e) =>{ 
                                  e.target.value!="addNew"?
                                  setHangtagVendor(e.target.value): viewinformation("Sewing_vendor_3") }}
                              >
                                <option selected disabled value={0}>
                                {t("selectVendor")} 
                                </option>
                                { vendorList.length > 0 
                                ? 
                                  (vendorList).map((mapData) => 
                                  (
                                    <option
                                    key={mapData.id}
                                      id={"Sewing_vendor_3"}
                                      value={mapData.id}
                                      title={mapData.vendor_name} >
                                      {mapData.vendor_name}
                                    </option>
                                  ))
                                :
                                  ""
                                }
                              <option value="addNew">+{t("addNew")}</option>
                              </Input>
                            <span className="error-msg"></span>   

                          </td>

                          <td>
                            <Input
                             id={"Sewing_perGMT_3"}
                              name="HangtagPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_3") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Sewing_perGMT_3")}
                            />
                            <span id={"Sewing_perGMT_3_Error"} className="error-msg"></span>   
                          </td>

                          <td>
                            <Input
                              id={"Sewing_totalQty_3"}
                              name="HangtagTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_3"),AddTotalPrice("Sewing_totalQty_3","Sewing_pricePerUnit_3","Sewing_totalPrice_3") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Sewing_totalQty_3")}
                            />
                            <span id={"Sewing_totalQty_3_Error"} className="error-msg"></span>      
                          </td>
 
                          <td>
                            <Input
                               id={"Sewing_leadTime_3"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_3")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_3")}
                            />
                            <span id={"Sewing_leadTime_3_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                            <Input
                              type="select"
                              id={"Sewing_leadTime_3"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                               leadTimeDays.map((day)=>(
                                <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                               )) :""}

                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}

                          <td width="10%">
                            <Input
                              type="select"
                              id={"Sewing_units_3"}
                              name="HangtagUnits"
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Sewing_units_3") :""}}
                            >
                            
                            <option selected disabled value={0}>  {t("selectUnits")}  </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,l) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Sewing_units_3"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg"></span>   

                          </td>

                          <td>
                            <Input
                              id={"Sewing_pricePerUnit_3"}
                              name="HangtagPricePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_3"),AddTotalPrice("Sewing_totalQty_3","Sewing_pricePerUnit_3","Sewing_totalPrice_3") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_3")}
                            />
                            <span id={"Sewing_pricePerUnit_3_Error"} className="error-msg"></span>      
                          </td>
                          <td>
                            <Input
                              disabled
                               id={"Sewing_totalPrice_3"}
                               name="HangtagTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_3")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_3")}
                            />
                            <span id={"Sewing_totalPrice_3_Error"} className="error-msg"></span>            
                          </td>
                          <td 
                           style={{  backgroundColor: '#fff', paddingTop: '1.10rem'}} 
                            className="f-left">
                              <img
                                width="23px"
                                height="23px"
                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                onClick={() => {
                                  setAccessType(""), 
                                  setLabelSampleType("Sewing_Type_3"),
                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal),
                                  getOrderMaterialsLabelApiCall()
                                }}
                                src={labelSampleIcon}
                              />
                          </td>
                        </tr>
  
                        {sewAccessoriesRowData.map((mapData, index)=>
                        {
                          var index = sewAdditionalIdValues[index];
                            return(
                              <>
                                <tr key={index}>
                                  <td>
                                    <Input
                                      id={"Sewing_Type"+"_"+index}
                                      name={index}
                                      placeholder={t("type")}
                                      defaultValue={mapData.Type}
                                      style={{ textTransform: "capitalize" }}
                                      onChange={(e)=> { emptyValidation2(e.target.value, "Sewing_Type_"+index+"_Error")}}
                                      >
                                    </Input>
                                      <span id={"Sewing_Type_"+index+"_Error"} 
                                            className="error-msg" 
                                            style={{ fontSize: '12px' }}>
                                      </span>
                                  </td>
                                  {(Object.keys(mapData))
                                  .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                                  .map((objMapData,m) => {
                                    return(
                                      <>
                                        <td key={m}>
                                          {
                                            objMapData == "vendor" 
                                            ? 
                                              <>
                                                <Input
                                                  id={"Sewing_vendor_"+index}
                                                  type="select"
                                                  className="form-control digits selectheight"
                                                  name="Vendor Information"
                                                  onChange={(e) =>{ 
                                                    e.target.value!="addNew"?
                                                    setHangtagVendor(e.target.value): viewinformation("Sewing_vendor_"+index) }}
                                                  >
                                                  <option selected disabled value={0} >
                                                  {t("selectVendor")}
                                                  </option>
                                                  { vendorList.length > 0 
                                                  ? 
                                                    (vendorList).map((vendorData) => 
                                                    (
                                                      mapData.vendor == vendorData.id ?
                                                        <option
                                                        key={vendorData.id}
                                                          id={"Sewing_vendor_"+index}
                                                          value={vendorData.id}
                                                          title={vendorData.vendor_name}
                                                          selected >
                                                          {vendorData.vendor_name}
                                                        </option>
                                                      :
                                                        <option
                                                          id={"Sewing_vendor_"+index}
                                                          value={vendorData.id}
                                                          title={vendorData.vendor_name} >
                                                          {vendorData.vendor_name}
                                                        </option>
                                                    ))
                                                  :
                                                    ""
                                                  }
                                                <option value="addNew">+{t("addNew")}</option>
                                                </Input>
                                                <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>
                                            : 
                                            objMapData == "units" ? 
                                                <>
                                                  <Input
                                                    type="select"
                                                    id={"Sewing_"+objMapData+"_"+index}
                                                    placeholder={t("units")} 
                                                    onChange={(e) =>{ 
                                                      e.target.value =="addNew"?
                                                      dropVal("Sewing_"+objMapData+"_"+index) :""}}
                                                   >
                                                    <option selected disabled value={0}> {t("selectUnits")} </option>
                                                    {Object.values(unitsList).length > 0 ? 
                                                        Object.values(unitsList).map((unitsValue,n) => 
                                                        (
                                                          mapData.units == unitsValue.name ?
                                                            <option
                                                            key={mapData.id}
                                                              id={"Sewing_"+objMapData+"_"+index}
                                                              value={unitsValue.name}
                                                              title={unitsValue.name} 
                                                              selected>
                                                              {unitsValue.name}
                                                            </option>
                                                          :
                                                            <option
                                                            key={mapData.id}
                                                              id={"Sewing_"+objMapData+"_"+index}
                                                              value={unitsValue.name}
                                                              title={unitsValue.name} 
                                                              >
                                                              {unitsValue.name}
                                                            </option>
                                                        ))
                                                      :
                                                        ""}
                                                         <option value="addNew" >+{t("addNew")}</option>
                                                  </Input> 
                                                  <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                                </>
                                            //    : 
                                            //   objMapData == "leadTime" ? 
                                            //    <>
                                            //    <Input
                                            //     type="select"
                                            //      id={"Sewing_"+objMapData+"_"+index}
                                            //      name="LeadTime"
                                            //      placeholder={t("selLeadTime")}
                                            //  >
                                            //     <option selected disabled value={0}>{t("selLeadTime")}</option>
                                            //      {leadTimeDays.length > 0 ?
                                            //     leadTimeDays.map((day)=>(
                                            //       <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                            //     )) :""}  

                                            //    </Input>
                                            //  <span className="error-msg"></span> 
                                            //    </>
                                              :
                                                <>
                                                  <Input
                                                    id={"Sewing_"+objMapData+"_"+index}
                                                    name={"Sewing_"+objMapData+"_"+index}
                                                    defaultValue={
                                                      objMapData == "component" ? mapData.component :
                                                      objMapData == "color" ? mapData.color :
                                                      objMapData == "perGMT" ? mapData.perGMT :
                                                      objMapData == "totalQty" ? mapData.totalQty :
                                                      objMapData == "leadTime" ? mapData.leadTime :
                                                      objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                      objMapData == "totalPrice" ? mapData.totalPrice :
                                                      ""
                                                    } 
                                                    placeholder={
                                                      objMapData == "component" ? t("component") :
                                                      objMapData == "color" ? t("color") :
                                                      objMapData == "perGMT" ? t("perGMT") :
                                                      objMapData == "totalQty" ? t("totalQty") :
                                                      objMapData=="leadTime" ? t("noOfDays") :
                                                      objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                      objMapData == "totalPrice" ? t("totPrice")  :
                                                      ""
                                                    }
                                                    disabled={ objMapData == "totalPrice" ? true : false}
                                                    onChange={ (e) => {
                                                      ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                      (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                       (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                       (AddTotalPrice("Sewing_totalQty_"+index,"Sewing_pricePerUnit_"+index,"Sewing_totalPrice_"+index),
                                                       OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index))
                                                       :
                                                       OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index)
                                                         
                                                       :
                                                         "")
                                                     }}
                                                    onKeyPress={(e) => { 
                                                      (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                      OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index) :
                                                   
                                                      ""
                                                    }}
                                                    >
                                                  </Input>
                                                  <span id={"Sewing_"+objMapData+"_"+index+"_Error"} className="error-msg"></span>      
                                                </>
                                          }
                                        </td>
                                      </>
                                    ) 
                                  })}
                                  <tr>
                                    <td className="text-center p-r-0"   
                                        style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.3rem' }} >
                                        <img
                                            className="labelSampleIcon"
                                            width="23px"
                                            height="23px"
                                            style={{ cursor: 'pointer', marginTop: '10px' }}
                                            onClick={() => {
                                              setAccessType(""),
                                              getOrderMaterialsLabelApiCall(), 
                                              setLabelSampleType(
                                                  "Sewing_Type"+"_"+index ? 
                                                  "Sewing_Type"+"_"+index : 
                                                  ""),
                                              setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                            }}
                                            src={labelSampleIcon}
                                          />
                                        <img
                                          className="labelSampleIcon"
                                          width="35px"
                                          height="35px"
                                          style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                          onClick={() => { 
                                            deleteSewAccessoriesTableRows(index)
                                            }}
                                          src={DeleteRowIcon}
                                        /> 
                                    </td>
                                  </tr>
                                </tr>
                              </>
                            )
                        })}

                        {/* Add Icon */} 
                        <tr>
                          <td colSpan={10}  ></td>
                          <td width="100%" className="f-right">
                            <img 
                                className="cursor-pointer p-r-20" 
                                style={{ float: "right" }} 
                                onClick={() => { 
                                  setSewCount(sewCount+1), 
                                  addSewAccessoriesTableRows() 
                                }} 
                                src={Add_icon} />
                          </td>
                        </tr>
                        </tbody>
                    </Table>
                  </div>
                </ScrollBar>
              </Row>
          </Card>
        </Row>

        {/* Packing Accessories */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px'}}>
          <Card className="shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>

            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead">{t("packingAccessories")} </H6>
                  </span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <ScrollBar  options={{ suppressScrollX: true }}>
                  <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding removeTableBottomBorder tableViewRes">
                      <thead>
                        <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                          <th> {t("type")} </th>
                          <th className="text-center">{t("component")}</th>
                          <th className='text-center'>{t("color")}</th>
                          <th className='text-center'>{t("vendorInformation")}</th>
                          <th className='text-center'>{t("perGMT")}</th>
                          <th className='text-center'>{t("totalQty")}</th>
                          <th className='text-center'>{t("leadTime")}</th>
                          <th className='text-center'>{t("units")}</th>
                          <th className='text-center'>{t("pricePerUnit")}</th>
                          <th className="text-center">{t("totPrice")}</th>
                          <th className='text-center'>{t("labelSample")}</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                          <td className="p-t-0 p-b-0" id={"Removed_Pack_Ids_"+packRemovedIdValues}>
                            <span className="error-msg"></span>           
                          </td>
                        </tr>

                        {/* Polybag */}
                        <tr>
                          <td id={"Packing_Type_0"}> 
                            {t("polybag")} 
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>           
                          </td>

                          <td width="12%">
                            <Input
                              id={"Packing_component_0"}
                              name="Polybag"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td width="10%">
                            <Input
                              id={"Packing_color_0"}
                              name="PolybagColor"
                              placeholder={t("color")}
                                />
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td width="12%"> 
                            <Input
                                id={"Packing_vendor_0"}
                                type="select"
                                className="form-control digits selectheight"
                                name="Vendor Information"
                                onChange={(e) =>{ 
                                  e.target.value!="addNew"?
                                  setPolybagVendor(e.target.value): viewinformation("Packing_vendor_0")
                                }}
                              >
                              <option selected disabled value={0}>
                              {t("selectVendor")}
                              </option>
                              { vendorList.length > 0 
                              ? 
                                (vendorList).map((mapData) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Packing_vendor_0"}
                                    value={mapData.id}
                                    title={mapData.vendor_name} >
                                    {mapData.vendor_name}
                                  </option>
                                ))
                              :
                                ""
                              }
                              <option value="addNew">+{t("addNew")}</option>
                            </Input>
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_perGMT_0"}
                              name="PolybagPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_0") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_0")}
                            />
                            <span id={"Packing_perGMT_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_totalQty_0"}
                              name="PolybagTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_0"),AddTotalPrice("Packing_totalQty_0","Packing_pricePerUnit_0","Packing_totalPrice_0") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_0") }
                            />
                            <span id={"Packing_totalQty_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>
                          <td>
                            <Input
                               id={"Packing_leadTime_0"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_0")}
                            />
                            <span id={"Packing_leadTime_0_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                            <Input
                              type="select"
                              id={"Packing_leadTime_0"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                                 leadTimeDays.map((day)=>(
                                    <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                )) :""}
                               
                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}
                          <td width="10%">
                            <Input
                              type="select"
                              id={"Packing_units_0"}
                              name="PolybagUnits"
                              placeholder={t("units")} 
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Packing_units_0") :""}}
                            >
                            <option selected disabled value={0}> {t("selectUnits")} </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,o) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Packing_units_0"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_pricePerUnit_0"}
                              name="PolybagPricePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Packing_pricePerUnit_0"),AddTotalPrice("Packing_totalQty_0","Packing_pricePerUnit_0","Packing_totalPrice_0")}}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Packing_pricePerUnit_0")}
                            />
                            <span id={"Packing_pricePerUnit_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                               disabled
                               id={"Packing_totalPrice_0"}
                               name="PolybagTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_0")}
                            />
                            <span id={"Packing_totalPrice_0_Error"} className="error-msg"></span>            
                          </td>
                          <tr>
                            <td className="text-center"   
                                style={{  backgroundColor: '#fff',  border: 'none' }} >
                                <img
                                    className="labelSampleIcon"
                                    width="23px"
                                    height="23px"
                                    style={{ cursor: 'pointer', marginTop: '10px' }}
                                    onClick={() => { 
                                      setAccessType(""),
                                      getOrderMaterialsLabelApiCall(),
                                      setLabelSampleType("Packing_Type_0"),
                                      setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                    }}
                                    src={labelSampleIcon}
                                  />
                            </td>
                          </tr>
                          
                        </tr>

                        {/* Carton Box */}
                        <tr>
                          <td  id={"Packing_Type_1"}> 
                            {t("cartonBox")}
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td width="12%">
                            <Input
                              id={"Packing_component_1"}
                              name="CartonBox"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td width="10%">
                            <Input
                              id={"Packing_color_1"}
                              name="CartonBoxColor"
                              placeholder={t("color")}
                            />
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td width="12%"> 
                            <Input
                              type="select"
                              id={"Packing_vendor_1"}
                              className="form-control digits selectheight"
                              name="Vendor Information"
                              onChange={(e) =>{ 
                                e.target.value!="addNew"?
                                setCartonVendor(e.target.value) :viewinformation("Packing_vendor_1")
                              }}
                            >
                            <option selected disabled value={0}>
                            {t("selectVendor")}
                            </option>
                              { vendorList.length > 0 
                              ? 
                                (vendorList).map((mapData) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Packing_vendor_1"}
                                    value={mapData.id}
                                    title={mapData.vendor_name} >
                                    {mapData.vendor_name}
                                  </option>
                                ))
                              :
                                ""
                              }
                              <option value="addNew">+{t("addNew")}</option>
                            </Input>
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_perGMT_1"}
                              name="CartonBoxPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_1") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_1")}
                            />
                            <span id={"Packing_perGMT_1_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_totalQty_1"}
                              name="CartonBoxTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_1"),AddTotalPrice("Packing_totalQty_1","Packing_pricePerUnit_1","Packing_totalPrice_1") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_1")}
                            />
                            <span id={"Packing_totalQty_1_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>
                          <td>
                            <Input
                               id={"Packing_leadTime_1"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_1")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_1")}
                            />
                            <span id={"Packing_leadTime_1_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                            <Input
                              type="select"
                              id={"Packing_leadTime_1"}
                              name="LeadTime"
                              placeholder={t("selLeadTime")}
                            >
                               <option selected disabled value={0}>{t("selLeadTime")}</option>
                               {leadTimeDays.length > 0 ?
                                 leadTimeDays.map((day)=>(
                                    <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                )) :""}
                              

                            </Input>
                            <span className="error-msg"></span> 
                          </td> */}

                          <td width="10%">
                            <Input
                              type="select"
                              id={"Packing_units_1"}
                              name="CartonBoxUnits"
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Packing_units_1") :""}}
                            >
                            <option selected disabled value={0}> {t("selectUnits")} </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,p) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Packing_units_1"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input>
                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Packing_pricePerUnit_1"}
                              name="CartonBoxPerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={() => { 
                                OnlyNumbersValidation(e, "PricePerUnit", "Packing_pricePerUnit_1"),AddTotalPrice("Packing_totalQty_1","Packing_pricePerUnit_1","Packing_totalPrice_1")
                              }}
                              onKeyPress={(e) => {OnlyNumbersValidation(e, "PricePerUnit", "Packing_pricePerUnit_1")}}
                            />
                            <span id={"Packing_pricePerUnit_1_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>
                          <td>
                            <Input
                              disabled
                               id={"Packing_totalPrice_1"}
                               name="CartonBoxTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_1")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_1")}
                            />
                            <span id={"Packing_totalPrice_1_Error"} className="error-msg"></span>            
                          </td>
                          <tr>
                            <td className="text-center"   
                                style={{  backgroundColor: '#fff',  border: 'none' }} >
                                <img
                                    className="labelSampleIcon"
                                    width="23px"
                                    height="23px"
                                    style={{ cursor: 'pointer', marginTop: '10px' }}
                                    onClick={() => {
                                      setAccessType(""), 
                                      getOrderMaterialsLabelApiCall(),
                                      setLabelSampleType("Packing_Type_1"),
                                      setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                    }}
                                    src={labelSampleIcon}
                                  />
                            </td>
                          </tr>

                        </tr>

                        {packAccessoriesRowData.map((mapData, index)=>{
                          var index = packAdditionalIdValues[index];
                          return(
                            <>
                              <tr key={index}>
                                <td>
                                  <Input
                                    id={"Packing_Type"+"_"+index}
                                    placeholder={t("type")}
                                    defaultValue={mapData.Type}
                                    style={{ textTransform: "capitalize" }}
                                    onChange={(e)=> { emptyValidation2(e.target.value, "Packing_Type_"+index+"_Error")}}
                                    >
                                  </Input>
                                  <span id={"Packing_Type_"+index+"_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                                </td>
                                {(Object.keys(mapData))
                                .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                                .map((objMapData,q) => {
                                  return(
                                    <>
                                      <td key={q}>
                                        {
                                          objMapData == "vendor" ? 
                                          <>
                                            <Input
                                              id={"Packing_vendor_"+index}
                                              type="select"
                                              className="form-control digits selectheight"
                                              name="Vendor Information"
                                              onChange={(e) =>{ 
                                                e.target.value!="addNew"?
                                                setHangtagVendor(e.target.value): viewinformation("Packing_vendor_"+index) }}>
                                              <option selected disabled value={0}>
                                              {t("selectVendor")} 
                                              </option>
                                              { vendorList.length > 0 
                                              ? 
                                                (vendorList).map((vendorData) => 
                                                (
                                                  mapData.vendor == vendorData.id ?
                                                  <option
                                                    key={vendorData.id}
                                                    id={"Packing_vendor_"+index}
                                                    value={vendorData.id}
                                                    title={vendorData.vendor_name} 
                                                    selected>
                                                    {vendorData.vendor_name}
                                                  </option>
                                                  :
                                                  <option
                                                  id={"Packing_vendor_"+index}
                                                  value={vendorData.id}
                                                  title={vendorData.vendor_name} >
                                                  {vendorData.vendor_name}
                                                </option>
                                                ))
                                              :
                                                ""
                                              }
                                            <option value="addNew">+{t("addNew")}</option>
                                            </Input>
                                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                          </>

                                          :   
                                            objMapData == "units" ? 
                                              <>
                                                <Input
                                                  type="select"
                                                  id={"Packing_"+objMapData+"_"+index}
                                                  placeholder={t("units")}
                                                  onChange={(e) =>{ 
                                                    e.target.value =="addNew"?
                                                    dropVal("Packing_"+objMapData+"_"+index) :""}}
                                                >
                                                <option selected disabled value={0}>  {t("selectUnits")} </option>
                                                {Object.values(unitsList).length > 0 ? 
                                                    Object.values(unitsList).map((unitsValue,r) => 
                                                    (
                                                      mapData.units == unitsValue.name ?
                                                      <option
                                                        key={mapData.id}
                                                        id={"Packing_"+objMapData+"_"+index}
                                                        value={unitsValue.name}
                                                        title={unitsValue.name} 
                                                        selected>
                                                        {unitsValue.name}
                                                      </option>
                                                      :
                                                      <option
                                                      key={mapData.id}
                                                        id={"Packing_"+objMapData+"_"+index}
                                                        value={unitsValue.name}
                                                        title={unitsValue.name} 
                                                        >
                                                        {unitsValue.name}
                                                      </option>
                                                    ))
                                                  :
                                                    ""}
                                                     <option value="addNew" >+{t("addNew")}</option>
                                                </Input> 
                                                <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>
                                            :
                                            // objMapData == "leadTime" ? 
                                            //   <>
                                            //   <Input
                                            //     type="select"
                                            //     id={"Packing_"+objMapData+"_"+index}
                                            //     name="LeadTime"
                                            //     placeholder={t("selLeadTime")}
                                            //   >
                                            //     <option selected disabled value={0}>{t("selLeadTime")}</option>
                                            //     {leadTimeDays.length > 0 ?
                                            //     leadTimeDays.map((day)=>(
                                            //         <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                            //     )) :""}
                                    

                                            //   </Input>
                                            //   <span className="error-msg"></span> 
                                            //   </>
                                            //   :
                                              <>
                                                <Input
                                                  id={"Packing_"+objMapData+"_"+index}
                                                  name={"Packing_"+objMapData+"_"+index}
                                                  defaultValue={
                                                    objMapData == "component" ? mapData.component :
                                                    objMapData == "color" ? mapData.color :
                                                    objMapData == "perGMT" ? mapData.perGMT :
                                                    objMapData == "totalQty" ? mapData.totalQty :
                                                    objMapData == "leadTime" ? mapData.leadTime :
                                                    objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                    objMapData == "totalPrice" ? mapData.totalPrice :
                                                    ""
                                                  }
                                                  disabled={ objMapData == "totalPrice" ? true : false}
                                                  placeholder={
                                                    objMapData == "component" ? t("component") :
                                                    objMapData == "color" ? t("color") :
                                                    objMapData == "perGMT" ? t("perGMT") :
                                                    objMapData == "totalQty" ? t("totalQty") :
                                                    objMapData == "leadTime" ? t("noOfDays") :
                                                    objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                    objMapData == "totalPrice" ? t("totPrice")  :
                                                    ""
                                                  }
                                                  onChange={ (e) => {
                                                    ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                    (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                     (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                     (AddTotalPrice("Packing_totalQty_"+index,"Packing_pricePerUnit_"+index,"Packing_totalPrice_"+index),
                                                     OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index))
                                                     :
                                                     OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index)
                                                       
                                                     :
                                                       "")
                                                   }}
                                                  onKeyPress={(e) => { 
                                                    (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime")|| (objMapData == "totalPrice") ?
                                                  OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index)
                                                   :
                                                  ""
                                                  }}
                                                  >
                                                </Input>
                                                <span id={"Packing_"+objMapData+"_"+index+"_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>
                                        }
                                      </td>
                                    </>
                                  ) 
                                })}
                                <tr>
                                  <td className="text-center"   
                                      style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.3rem' }} >
                                          <img
                                              className="labelSampleIcon"
                                              width="23px"
                                              height="23px"
                                              style={{ cursor: 'pointer', marginTop: '10px' }}
                                              onClick={() => {
                                                setAccessType(""), 
                                                getOrderMaterialsLabelApiCall(),
                                                setLabelSampleType(
                                                    "Packing_Type"+"_"+index ? 
                                                    "Packing_Type"+"_"+index : 
                                                    ""),
                                                setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                              }}
                                              src={labelSampleIcon}
                                            />
                                          <img
                                            className="labelSampleIcon"
                                            width="35px"
                                            height="35px"
                                            style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                            onClick={() => { deletePackAccessoriesTableRows(index) }}
                                            src={DeleteRowIcon}
                                          /> 
                                  </td>
                                </tr>
                              </tr>
                            </>
                          )
                        })}
                        
                        {/* Add Icon */} 
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td width="100%" className="f-right">
                          <img 
                              className="cursor-pointer p-r-20" 
                              style={{ float: "right" }} 
                              onClick={ () => { 
                                setPackCount(packCount+1), 
                                addPackAccessoriesTableRows()
                              }} 
                              src={Add_icon} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
              </ScrollBar>
            </Row>

          </Card>
        </Row>

        {/* Miscellaneous */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-10px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>
            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead"> {t("miscellaneous")} </H6>
                  </span>
                </Col>
            </Row>

            <Row style={{ marginTop: '10px' }}>
              <ScrollBar  options={{ suppressScrollX: true }}>
                  <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding removeTableBottomBorder tableViewRes">
                     <thead>
                        <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                            <th> {t("type")} </th>
                            <th className="text-center">{t("component")}</th>
                            <th className='text-center'>{t("color")}</th>
                            <th className='text-center'>{t("vendorInformation")}</th>
                            <th className='text-center'>{t("perGMT")}</th>
                            <th className='text-center'>{t("totalQty")}</th>
                            <th className='text-center'>{t("leadTime")}</th>
                            <th className='text-center'>{t("units")}</th>
                            <th className='text-center'>{t("pricePerUnit")}</th>
                            <th className="text-center">{t("totPrice")}</th>
                            <th className='text-center'>{t("labelSample")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td className="p-t-0 p-b-0" id={"Removed_Misc_Ids_"+miscRemovedIdValues}>
                              <span className="error-msg"></span>           
                            </td>
                        </tr>

                        {/* Miscellaneous */}
                        <tr>
                          <td>   
                            <Input
                              id={"Miscellaneous_Type_0"}
                              name="Miscellaneous"
                              placeholder={t("type")}
                              style={{ textTransform: "capitalize" }}
                            >
                            </Input> 
                            <span style={{ fontSize: '12px' }} className="error-msg"></span>   
                          </td>

                          <td width="12%">
                            <Input
                              id={"Miscellaneous_component_0"}
                              name="Miscellaneous"
                              placeholder={t("component")}
                            >
                            </Input>
                            <span className="error-msg"></span>   
                          </td>

                          <td width="10%">
                            <Input
                              id={"Miscellaneous_color_0"}
                              name="PolybagColor"
                              placeholder={t("color")}
                                />
                              <span className="error-msg"></span>   
                          </td>

                          <td width="12%"> 
                            <Input
                                type="select"
                                id={"Miscellaneous_vendor_0"}
                                className="form-control digits selectheight"
                                name="Vendor Information"
                                onChange={(e) =>{ 
                                  e.target.value!="addNew"?
                                  setPolybagVendor(e.target.value): viewinformation("Miscellaneous_vendor_0")
                                }}
                              >
                                <option value={0}>
                                  {t("selectVendor")}
                                </option>
                                { vendorList.length > 0 
                                ? 
                                  (vendorList).map((mapData) => 
                                  (
                                    <option
                                    key={mapData.id}
                                      id={"Miscellaneous_vendor"}
                                      value={mapData.id}
                                      title={mapData.vendor_name} >
                                      {mapData.vendor_name}
                                    </option>
                                  ))
                                :
                                  ""
                                }
                              <option value="addNew">+{t("addNew")}</option>
                            </Input>
                            <span className="error-msg"></span>   
                          </td>

                          <td>
                            <Input
                              id={"Miscellaneous_perGMT_0"}
                              name="PolybagPerGMT"
                              placeholder={t("perGMT")}
                              onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_0") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_0")}
                            />
                            <span id={"Packing_perGMT_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>

                          <td>
                            <Input
                              id={"Miscellaneous_totalQty_0"}
                              name="PolybagTotalQty"
                              placeholder={t("totalQty")}
                              onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Miscellaneous_totalQty_0"),AddTotalPrice("Miscellaneous_totalQty_0","Miscellaneous_pricePerUnit_0","Miscellaneous_totalPrice_0") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Miscellaneous_totalQty_0")}
                            />
                            <span id={"Miscellaneous_totalQty_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>
                          <td>
                            <Input
                               id={"Miscellaneous_leadTime_0"}
                               name="LeadTime"
                              placeholder={t("noOfDays")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"LeadTime","Miscellaneous_leadTime_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Miscellaneous_leadTime_0")}
                            />
                            <span id={"Miscellaneous_leadTime_0_Error"} className="error-msg"></span>            
                          </td>
                          {/* <td>
                          <Input
                            type="select"
                            id={"Miscellaneous_leadTime_0"}
                            name="LeadTime"
                            placeholder={t("selLeadTime")}
                            >
                            <option selected disabled value={0}>{t("selLeadTime")}</option>
                            {leadTimeDays.length > 0 ?
                                 leadTimeDays.map((day)=>(
                                    <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                )) :""}
                         

                          </Input>
                          <span className="error-msg"></span> 
                          </td> */}
                                              
                          <td width="10%">
                            <Input
                              type="select"
                              id={"Miscellaneous_units_0"}
                              placeholder={t("units")}
                              onChange={(e) =>{ 
                                e.target.value =="addNew"?
                                dropVal("Miscellaneous_units_0") :""}}
                            >
                            <option selected disabled value={0}>  {t("selectUnits")}  </option>
                            {Object.values(unitsList).length > 0 ? 
                                Object.values(unitsList).map((mapData,s) => 
                                (
                                  <option
                                  key={mapData.id}
                                    id={"Miscellaneous_units"}
                                    value={mapData.name}
                                    title={mapData.name} >
                                    {mapData.name}
                                  </option>
                                ))
                              :
                                ""}
                                 <option value="addNew" >+{t("addNew")}</option>
                            </Input> 
                            <span className="error-msg"></span>   
                          </td>

                          <td>
                            <Input
                              id={"Miscellaneous_pricePerUnit_0"}
                              name="PolybagPricePerUnit"
                              placeholder={t("pricePerUnit")}
                              onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Miscellaneous_pricePerUnit_0"),AddTotalPrice("Miscellaneous_totalQty_0","Miscellaneous_pricePerUnit_0","Miscellaneous_totalPrice_0") }}
                              onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Miscellaneous_pricePerUnit_0")}
                            />
                            <span id={"Miscellaneous_pricePerUnit_0_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                          </td>
                          <td>
                            <Input
                              disabled
                               id={"Miscellaneous_totalPrice_0"}
                               name="PolybagTotalPrice"
                              placeholder={t("totPrice")}
                              onChange={(e) => { 
                                OnlyNumbersValidation(e,"TotalPrice","Miscellaneous_totalPrice_0")
                              }}
                              onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Miscellaneous_totalPrice_0")}
                            />
                            <span  id={"Miscellaneous_totalPrice_0_Error"} className="error-msg"></span>            
                          </td>
                          <td 
                            style={{  backgroundColor: '#fff', paddingTop: '1.5rem' }} 
                            className="f-left">
                              <img
                                width="23px"
                                height="23px"
                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                onClick={() => {
                                  setAccessType(""), 
                                  getOrderMaterialsLabelApiCall(), 
                                  setLabelSampleType("Miscellaneous_Type_0"),
                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                }}
                                src={labelSampleIcon}
                              />
                          </td>

                        </tr>

                        {miscellaneousRowData.map((mapData, index)=>{
                          var index = miscAdditionalIdValues[index];
                          return(
                            <>
                              <tr key={index}>
                                <td>
                                  <Input
                                      id={"Miscellaneous_Type_"+index}
                                      placeholder={t("type")}
                                      defaultValue={mapData.Type}
                                      style={{ textTransform: "capitalize" }}
                                      onChange={(e)=> { emptyValidation2(e.target.value, "Miscellaneous_Type_"+index+"_Error")}}
                                      >
                                  </Input>
                                  <span id={"Miscellaneous_Type_"+index+"_Error"} 
                                        className="error-msg" 
                                        style={{ fontSize: '12px' }}></span>
                                </td>
                                {(Object.keys(mapData))
                                .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                                .map((objMapData,z) => {
                                  return(
                                    <>
                                      <td key={z}>
                                        {
                                          objMapData == "vendor" ? 
                                          <>
                                            <Input
                                              id={"Miscellaneous_vendor_"+index}
                                              type="select"
                                              className="form-control digits selectheight"
                                              name="Vendor Information"
                                              onChange={(e) =>{ 
                                                e.target.value!="addNew"?
                                                setHangtagVendor(e.target.value): viewinformation("Miscellaneous_vendor_"+index) }}>
                                              <option selected disabled value={0}>
                                                {t("selectVendor")}
                                              </option>
                                              { vendorList.length > 0 
                                              ? 
                                                (vendorList).map((vendorData) => 
                                                (
                                                  mapData.vendor == vendorData.id ?
                                                  <option
                                                  key={vendorData.id}
                                                    id={"Miscellaneous_vendor_"+index}
                                                    value={vendorData.id}
                                                    title={vendorData.vendor_name} 
                                                    selected>
                                                    {vendorData.vendor_name}
                                                  </option>
                                                  :
                                                  <option
                                                  key={vendorData.id}
                                                    id={"Miscellaneous_vendor_"+index}
                                                    value={vendorData.id}
                                                    title={vendorData.vendor_name} >
                                                    {vendorData.vendor_name}
                                                  </option>
                                                ))
                                              :
                                                ""
                                              }
                                            <option value="addNew">+{t("addNew")}</option>
                                            </Input>
                                            <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                          </>
                                          : 
                                            objMapData == "units" ? 
                                              <>
                                                <Input
                                                  type="select"
                                                  id={"Miscellaneous_"+objMapData+"_"+index}
                                                  placeholder={t("units")}
                                                  onChange={(e) =>{ 
                                                    e.target.value =="addNew"?
                                                    dropVal("Miscellaneous_"+objMapData+"_"+index) :""}}
                                                >
                                                <option selected disabled value={0}> {t("selectUnits")} </option>
                                                {Object.values(unitsList).length > 0 ? 
                                                    Object.values(unitsList).map((unitsValue,u) => 
                                                    (
                                                      mapData.units == unitsValue.name ?
                                                      <option
                                                      key={mapData.id}
                                                        id={"Miscellaneous_"+objMapData+"_"+index}
                                                        value={unitsValue.name}
                                                        title={unitsValue.name} 
                                                        selected>
                                                        {unitsValue.name}
                                                      </option>
                                                      :
                                                      <option
                                                      key={mapData.id}
                                                      id={"Miscellaneous_"+objMapData+"_"+index}
                                                      value={unitsValue.name}
                                                      title={unitsValue.name} 
                                                      >
                                                      {unitsValue.name}
                                                    </option>
                                                    ))
                                                  :
                                                    ""}
                                                     <option value="addNew" >+{t("addNew")}</option>
                                                </Input>  
                                                <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>
                                            : 
                                            // objMapData == "leadTime" ? 
                                            // <>
                                            // <Input
                                            //   type="select"
                                            //   id={"Miscellaneous_"+objMapData+"_"+index}
                                            //   name="LeadTime"
                                            //   placeholder={t("selLeadTime")}
                                            // >
                                            //   <option selected disabled value={0}>{t("selLeadTime")}</option>
                                            //   {leadTimeDays.length > 0 ?
                                            //   leadTimeDays.map((day)=>(
                                            //       <option value={day}>{day} {day== 1 ? "Day" : "Days"}</option>
                                            //   )) :""}
                                           

                                            // </Input>
                                            // <span className="error-msg"></span> 
                                            // </>
                                            // :
                                              <>
                                                <Input
                                                  id={"Miscellaneous_"+objMapData+"_"+index}
                                                  defaultValue={
                                                    objMapData == "component" ? mapData.component :
                                                    objMapData == "color" ? mapData.color :
                                                    objMapData == "perGMT" ? mapData.perGMT :
                                                    objMapData == "totalQty" ? mapData.totalQty :
                                                    objMapData == "leadTime" ? mapData.leadTime :
                                                    objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                    objMapData == "totalPrice" ? mapData.totalPrice :
                                                    ""
                                                  }
                                                  disabled={ objMapData == "totalPrice" ? true : false}
                                                  placeholder={
                                                    objMapData == "component" ? t("component") :
                                                    objMapData == "color" ? t("color") :
                                                    objMapData == "perGMT" ? t("perGMT") :
                                                    objMapData == "totalQty" ? t("totalQty") :
                                                    objMapData == "leadTime" ? t("noOfDays") :
                                                    objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                    objMapData == "totalPrice" ? t("totPrice")  :
                                                    ""
                                                  }   
                                                  onChange={ (e) => {
                                                    ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                    (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                     (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                     (AddTotalPrice("Miscellaneous_totalQty_"+index,"Miscellaneous_pricePerUnit_"+index,"Miscellaneous_totalPrice_"+index),
                                                     OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index))
                                                     :
                                                     OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index)
                                                       
                                                     :
                                                       "")
                                                   }}
                                                  onKeyPress={(e) => { 
                                                    (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime")|| (objMapData == "totalPrice") ?
                                                    OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index)
                                                    : ""
                                                  }}
                                                  
                                                  >
                                                </Input>
                                                <span  id={"Miscellaneous_"+objMapData+"_"+index+"_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>
                                        }
                                      </td>
                                    </>
                                  ) 
                                })}

                                <tr>
                                  <td className="text-center"   
                                      style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.3rem' }} >
                                          <img
                                              className="labelSampleIcon"
                                              width="23px"
                                              height="23px"
                                              style={{ cursor: 'pointer', marginTop: '10px' }}
                                              onClick={() => { 
                                                setAccessType(""), 
                                                getOrderMaterialsLabelApiCall(),
                                                setLabelSampleType(
                                                    "Miscellaneous_Type"+"_"+index ? 
                                                    "Miscellaneous_Type"+"_"+index : 
                                                    ""),
                                                setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                              }}
                                              src={labelSampleIcon}
                                            />
                                          <img
                                            className="labelSampleIcon"
                                            width="35px"
                                            height="35px"
                                            style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                            onClick={() => { deleteMiscellaneousTableRows(index) }}
                                            src={DeleteRowIcon}
                                          /> 
                                  </td>
                                </tr>

                              </tr>
                            </>
                          )
                        })} 
                    
                        {/* Add Icon */} 
                        <tr>
                          <td colSpan={10}></td>
                          <td width="100%" className="f-right">
                            <img 
                                className="cursor-pointer p-r-20" 
                                style={{ float: "right" }} 
                                onClick={() => { 
                                  setMiscellaneousCount(miscellaneousCount+1), 
                                  addMiscellaneousTableRows()
                                }} 
                                src={Add_icon} />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  </ScrollBar>
            </Row>
          </Card>
        </Row>

        {/* Others */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-10px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>

            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead"> {t("others")} </H6>
                  </span>
                </Col>
            </Row>

            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label> {t("measurementSheet")} </Label>
                  <InputGroup>
                    <Input
                      name="Measurement Sheet"
                      value={measurementSheetPdfName ? measurementSheetPdfName : ""}
                      placeholder={t("attachMeasurementSheet")}
                      disabled>
                    </Input>
                    <Files
                      className="files-dropzone fileContainer"
                      accept=".pdf, .xls, .xlsx, .jpeg, .jpg, .png"
                      multiple={false}
                      canCancel={false}
                      onChange={files => {setMeasurementSheetLabelSample("MeasurementSheet"), uploadImageValidation("MeasurementSheet", files)}}
                      clickable>
                      <InputGroupText className="btn imgBackground">
                        <img
                          src={uploadDocIcon}
                          width="25px"
                          height="25px"
                          type="file"
                        ></img>
                      </InputGroupText>
                    </Files>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="8">
                <Row>
                  {uploadedImageList.length > 0 
                  ?
                  uploadedImageList
                  .filter( filterData => filterData.media_type == measurementSheetLabelSample )
                  .map((file) => (
                    <>
                    <Col lg="4">
                      <tr>
                        <td  width="5%">
                          {(file.orginalfilename).split(".")[1] == "pdf" ?
                            <img src={pdfDocIcon} />
                          :
                          (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                          <img src={excelDocIconIcon} />
                          :
                          <img src={docIcon} />
                          }
                        </td>
                        <td className="verticalAlignmentStart">
                          <p className="f-left f-12 f-w-600 m-l-5 m-t-35 ">{truncate(file.orginalfilename)}</p >
                        </td>
                        <td align="right" valign="center" width="5%">
                          <a href="javascript:void(0)" 
                            title={t("deleteFile")} 
                            onClick={() => deleteImageFiles("MeasurementSheet", file)}>
                            <img src={deleteDocIcon} />
                          </a>
                        </td>
                      </tr>
                    </Col>
                    </>
                  ))
                  :
                  ""}
                </Row>
              </Col>
            </Row>
              
            <Row className="m-t-10">
              <Col lg="4">
                <FormGroup>
                  <Label> {t("techPack")} </Label>
                  <InputGroup>
                    <Input
                      name="TechPack"
                      value={techPackPdfName ? techPackPdfName : ""}
                      placeholder={t("attachTechPack")}
                      disabled
                    ></Input>
                    <Files
                      className="files-dropzone fileContainer"
                      accept=".pdf, .xls, .xlsx, .jpeg, .jpg, .png"
                      multiple={false}
                      canCancel={false}
                      onChange={files => {
                        setTechPackLabelSample("TechPack"), 
                        uploadImageValidation("TechPack", files)}}
                      clickable
                    >
                      <InputGroupText className="btn imgBackground">
                        <img
                          src={uploadDocIcon}
                          width="25px"
                          height="25px"
                          type="file"
                        ></img>
                      </InputGroupText>
                    </Files>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="8">
                <Row>
                  {uploadedImageList.length > 0 
                  ?
                  uploadedImageList.filter( filterData => filterData.media_type == techPackLabelSample )
                  .map((file) => (
                  <>
                    <Col lg="4">
                    <tr>
                      <td width="5%">
                      {(file.orginalfilename).split(".")[1] == "pdf" ?
                        <img src={pdfDocIcon} />
                      :
                      (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                      <img src={excelDocIconIcon} />
                      :
                      <img src={docIcon} />
                      }
                      </td>
                      <td className="verticalAlignmentStart">
                        <p className="f-left f-12 f-w-600 m-l-5 m-t-35">{truncate(file.orginalfilename)}<br />
                        </p>
                      </td>
                      <td valign="center" width="5%">
                          <a href="javascript:void(0)" 
                              title={t("downloadFile")} 
                              onClick={() => deleteImageFiles("TechPack", file)}>
                              <img src={deleteDocIcon} />
                          </a>
                      </td>
                    </tr>
                    </Col>
                  </>
                  ))
                  :
                    ""}
                </Row>
              </Col>
            </Row>

          </Card>
        </Row> 

        <Row className="f-right">
          <FormGroup className="f-right m-r-25 ">
                <Button
                  className="btn btn-primary"
                  onClick={(e) => { onSaveBillOfMaterials()}} >
                  {t("saveAndContinue")}
                </Button>
          </FormGroup>
        </Row>

      </Col>

      <AddMaterialsLabelsImageModal
        modal={addMaterialsLabelsImageModal}
        toggle={toggleAddMaterialsLabelsImageModal}
        labelSampleType={labelSampleType}
        orderId={orderId}   
        uploadedImageList={uploadedImageList}   
        uploadedImageName={uploadedImageName}  
        setUploadedImageList={setUploadedImageList}   
        setUploadedImageName={setUploadedImageName}  
        serverURL={serverURL} 
        setServerURL={setServerURL} 
        setUploadedMediaList={setUploadedMediaList}
        getOrderMaterialsLabelApiCall={getOrderMaterialsLabelApiCall}
        />

      { /*****PaymentTerms,penalty,No of ply,Patterns,Carton Edge Finish Drop Down "+Add More" ****/ }
      <AddNewDropdownModal
        modal={showAddNew}
        toggle={toggleAddNew}
        masterType={masterType}
        referenceId={referenceId}
        setGetInquiryMasterDataList={setGetInquiryMasterDataList}
        orderId={orderId}
      />
         <AddNewUnitModal
          modal={showUnitAddNew}
          toggle={toggleUnitAddNew}
          setUnitsList={setUnitsList}
          companyId ={company_id}
          workspaceId={workspace_id} 
          userId ={user_id}
          staffId ={staff_id}
          toggleType="addEditBOM"
          />
      <VendorListModal 
        modal={ViewVendorListModal} 
        toggleViewVendorList={toggleViewVendorListModal} 
        vendorDetails={vendorDetails} 
        setVendorList={setVendorList}
      />

    </Fragment>
  );
};

export default AddMaterialsLabels;
/***** CODE BY : RAMANAN M  *****/