import React, { useState,useEffect } from "react";

import {
  Form, Label, Card, ModalBody, Col, Row, Input, InputGroup,
  InputGroupText, Modal, FormGroup, ModalHeader, Table,Button
} from "reactstrap";
import { useTranslation } from "react-i18next";
import InquiryViewIcon from "../../../assets/images/dms/icons/inquiryViewIcon.svg"; 
import editIcon from "../../../assets/images/dms/icons/inquiryEditIcon.svg";
import { Color } from "../../../Constant";
import { BottomCenter, RightBottom, ServerUrl } from "../../../Constant";
import axios from "axios";
import {getLoginCompanyId} from "../../../Constant/LoginConstant";
import { apidecrypt, apiencrypt } from '../../../helper';
import Swal from 'sweetalert2';
import noData from "../../../assets/images/dms/nodata.png";

import ViewVendorInfoModal from "./ViewVendorModal";
import AddVendorModal from "./AddVendorModal";
import EditVendorModal from "./EditVendorModal";

const ViewVendorListModal = ({ modal, toggleViewVendorList, setVendorList, vendorDetails }) => 
{
  const { t } = useTranslation();
  const [selectedViewVendorDetails, setSelectedViewVendorDetails] = useState("");
  const [selectedEditVendorDetails, setSelectedEditVendorDetails] = useState("");
  const [selectedVendorId, setSelectedVendorId] = useState("");

  const [modalAddVendor, setModalAddVendor] = useState(false);
  const toggleAddVendorInfo = () => setModalAddVendor(!modalAddVendor);

  const [viewVendorInfo, setModalViewVendorInfo] = useState(false);
  const toggleViewVendorinfo = () => setModalViewVendorInfo(!viewVendorInfo);

  const [editVendorInfo, setModaleditVendorInfo] = useState(false);
  const toggleEditVendorinfo = () => setModaleditVendorInfo(!editVendorInfo);

  const ViewVendorInfo = (vendorId) => {
    
    setSelectedViewVendorDetails(()=>"")

    axios.post(ServerUrl + "/get-label-vendor-info",
    apiencrypt({  "id":vendorId }))
    .then((response) => {
      response.data = apidecrypt(response.data)
      setSelectedViewVendorDetails(response.data.data);
      toggleViewVendorinfo()
    });
  }

  const editVenderinfo = (vendorId) => {

    setSelectedEditVendorDetails(()=> "")

    axios
    .post(ServerUrl + "/get-label-vendor-info",
    apiencrypt({
      "id":vendorId
    }))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setSelectedEditVendorDetails(response.data.data);
    });

    setTimeout(() => {
      toggleEditVendorinfo();
    }, 500);
  }

  const addVendorInformation = () => {
    toggleAddVendorInfo();
  }

  return (
    <Modal backdrop="static" 
           isOpen={modal} 
           toggle={toggleViewVendorList} 
           centered 
           className="Addvendormodal datainputmodal">

          <ModalHeader className="modal_radius">
            <Row> 
              <Col className="m-t-10">
                {t("vendorList")}
              </Col> 
              <Col className="text-end cursor-pointer">
                <span title={t("close")} tooltip={t("close")} alt={t("close")} onClick={toggleViewVendorList}>X</span>
              </Col>
            </Row> 
          </ModalHeader>
      
          <ModalBody >
              {vendorDetails.length>0 
                ?
                  <>
                    <div className="table-bordered">
                      <Table className="table-responsive ">
                          <tr style={{ backgroundColor:"#009688" }} className="text-center">
                            <th width="2%" className='m-l-5 vendorinfo' style={{ color: '#fff' }}>{t("serialNo")}</th>
                            <th width="25%" className="vendorinfo" style={{ color: '#fff' }} >{t("vendorName")}</th>
                            <th  width="35%" className="vendorinfo" style={{ color: '#fff' }} >{t("headOffAddress")}</th>
                            <th width="20%"className="vendorinfo" style={{ color: '#fff' }} >{t("action")}</th>
                          </tr>
                            {
                              vendorDetails.length > 0 
                              ?
                                  vendorDetails.map(( mapData,index) => 
                                      {
                                        return (
                                          <tr className="text-center">
                                              <td>{index + 1}</td>
                                              <td>{mapData.vendor_name}</td> 
                                              <td>{mapData.office_address}</td> 
                                              <td> 
                                                <a 
                                                  href="javascript:voide(0)" 
                                                  onClick={() => ViewVendorInfo(mapData.id)}>
                                                  <img 
                                                    style={{ cursor: "pointer" }} 
                                                    width="29px" className=" p-1"
                                                    title={t("viewVendorInfo")} src={InquiryViewIcon} />
                                                </a>&nbsp;
                                                <a 
                                                  href="javascript:voide(0)" 
                                                  onClick={() => {  editVenderinfo(mapData.id), setSelectedVendorId(mapData.id) }}>
                                                  <img    
                                                    style={{ cursor: "pointer" }}
                                                    className=" p-1"
                                                    title={t("editIcon")}
                                                    src={editIcon} />
                                                </a>
                                              </td> 
                                          </tr>
                                        )
                                      }
                                    )
                              :
                                    ""
                            }
                      </Table>
                    </div>
                  </>
                :
                  <div className="centerAlign">
                    <img src={noData} style={{ width: "200px" }} />
                    <div> {t("noVendorList")} </div>
                  </div>
              }
                  <Button 
                    className= "btn-sm m-t-10" 
                    onClick={() =>{ addVendorInformation() }} >
                    {t("addVendor")}
                  </Button>
          </ModalBody>

          <ViewVendorInfoModal 
              modal={viewVendorInfo} 
              toggle={toggleViewVendorinfo} 
              selectedViewVendorDetails={selectedViewVendorDetails} 
          />

          <AddVendorModal 
            toggleadd={toggleAddVendorInfo} modal={modalAddVendor} 
            toggle={toggleAddVendorInfo} toggleViewVendorList={toggleViewVendorList} 
            setVendorList={setVendorList}
          /> 
                  
          <EditVendorModal 
              toggleadd={toggleEditVendorinfo} 
              modal={editVendorInfo} 
              toggle={toggleEditVendorinfo} 
              toggleViewVendorList={toggleViewVendorList} 
              setVendorList={setVendorList} 
              selectedEditVendorDetails={selectedEditVendorDetails} 
              selectedVendorId={selectedVendorId} 
          />
    
    </Modal>
  );
};

export default ViewVendorListModal;

//**************************code by: Ramanan M ***************************//