import React, { useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import { ServerUrl } from '../../../Constant';
import axios from "axios";
import Swal from "sweetalert2";

import { useTranslation } from "react-i18next";
import { apiencrypt, apidecrypt } from "../../../helper";   

const FormAddNewModal = ({ modal, toggle, masterType, orderId }) => {
  const { t } = useTranslation();

  const [printTyp,setPrintTyp]=useState("");
  const [mainLabel,setMainLabel]=useState("");

  const addInquiryMasterDataApiCall = () => {
    axios.post(ServerUrl + "/add-inquiry-master-data", apiencrypt(
      {
        type: masterType,
        content: masterType == "PrintType" ? printTyp : mainLabel,
        referenceId: orderId
      }
      ))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 200) {
          Swal.fire({
            text: t(response.data.message),
            icon: "success",
            button: t("okLabel"),
          }).then((result) => {
            if (result.isConfirmed) {
              toggle(false);
              /* To show the new roles in the dropdown */
              axios
                .post(ServerUrl + "/get-inquiry-master", apiencrypt({
                  referenceId:orderId>0? orderId : 1}))
                .then((response) => {
                  response.data = apidecrypt(response.data);
                  setGetInquiryMasterDataList(response.data.data);
                });
            }
          });
        }
        /* To show error if the Role is already present*/
        if (response.data.status_code === 401) {
          Swal.fire({
            title: t("printTypeExistsAlert"),
            text: t("enterDifferentName"),
            icon: "error",
            button: t("okLabel"),
            timer: 1500,
          });
        }
      });
  };

  const onSaveHandle = () => {
    if(masterType==="PrintType")
    {
      if (!printTyp) {
        Swal.fire({
          title: t("plsEnterPrintType"),
          icon: "error",
          button: t("okLabel"),
          timer: 1000,
        });
      } else {
        addInquiryMasterDataApiCall();
      }
    } else{
      if (!mainLabel) {
        Swal.fire({
          title: "Please Enter Main Label",
          icon: "error",
          button: t("okLabel"),
          timer: 1000,
        });
      } else{
        addInquiryMasterDataApiCall();
      }
    }
  };

  const refreshData = () => {
    setPrintTyp(() => "");
    setMainLabel(() => "");
    toggle();
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader> 
          { masterType==="PrintType"? t("addPrintType") :
            masterType==="MainLabel"? t("mainLabel") :
            "" }
        </ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
            { masterType==="PrintType"? t("addPrintType") :
              masterType==="MainLabel"? "Add MainLabel" :
              "" }
        </Label>
        <Input
          className="form-control"
          type="text"
          defaultValue=""
          onChange={(e) => { masterType==="PrintType"? setPrintTyp(e.target.value):
                             masterType==="MainLabel"? setMainLabel(e.target.value):
                             "" }}
        />
      </ModalBody>
      <ModalFooter>
        <Btn  attrBtn={{ color: "btn secondaryBtn btn-sm", onClick: () => refreshData() }}>{t("close")}</Btn>
        <Btn  attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}> {t("save")}</Btn>
      </ModalFooter>
    </Modal>
  );
};

export default FormAddNewModal;
/***** CODE BY : RAMANAN M  *****/