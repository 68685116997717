import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, CardHeader, Collapse, FormGroup, 
    Pagination, PaginationItem, PaginationLink} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,
     getWorkspaceType, getStaff, getStaffPermission, 
     getLoginStaffId,getModules } from "../../../Constant/LoginConstant";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem, Previous, Next } from "../../../Constant";
import FilterIcon from "../../../assets/images/dms/icons/FilterIconDms.svg";
import noData from "../../../assets/images/dms/nodata.png"
import DownloadIcon from "../../../assets/images/dms/icons/DowloadIconDms.svg";
import { DownloadFile,apiencrypt, apidecrypt } from "../../../helper";
import DataInputReportTable from "./DataInputReportTable";
import FilterOffCanvas from "./FilterOffCanvas";
import { useTranslation } from 'react-i18next';
const DataInputReports = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pcu, setPcu] = useState("");
    const [styleNo, setStyleNo] = useState("");
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
    const [factories, setFactories] = useState([]);
    const [styleNoList, setStyleNoList] = useState([]);
    const [dailyProdData, setDailyProdData] = useState([]);
    const [necessaryDetails, setNecessaryDetails] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState("");
    const [filterEndDate, setFilterEndDate] = useState("");
    const [filterOperator, setFilterOperator] = useState("");
    const [filterPieces, setFilterPieces] = useState("");
    const [filterType, setFilterType] = useState("");
    const [currentPage, setCurrentPage] =useState(1);
    const [pageDetails, setPageDetails] =useState(1);
    const { t } = useTranslation();
    const getPagination = () =>{
        let pagesCount = necessaryDetails.pages;
        let pages = "";
        const page=[];
        for (let i = 1; i <= pagesCount; i++){
            page.push(i);
        }
        return (
            <Pagination aria-label="Page navigation example" className="pagination-primary f-right" >
                {/* <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">«</span><span className="sr-only">{Previous}</span></PaginationLink></PaginationItem> */}
                    {page.map((pageNumber)=>(
                        pageNumber === necessaryDetails.currentPage ?
                        <PaginationItem active disabled><PaginationLink onClick={()=>getReportDetails(pageNumber)} >{pageNumber}</PaginationLink></PaginationItem>:
                        <PaginationItem><PaginationLink onClick={()=>getReportDetails(pageNumber)}>{pageNumber}</PaginationLink></PaginationItem>
                    ))}
                {/* <PaginationItem><PaginationLink href="#javascript"><span aria-hidden="true">»</span><span className="sr-only">{Next}</span></PaginationLink></PaginationItem> */}
            </Pagination>
        )
    };
    let detailsToSend;
    getStaff === "Staff" ?  
        detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id
    }
    const [toOpen , setToOpen] = useState(0);
    const [filterOffCanvas , setFilterOffCanvas] = useState(false);
    const toggleFilterCanvas = () => setFilterOffCanvas(!filterOffCanvas); 
    useEffect(() => {
        {
            (((getStaff === "Staff" &&getModules.includes("Reports")&&getStaffPermission.includes('View Report'))||getStaff == null )) 
            ?
            ""
            :
            window.location.href = "/dashboard" 
          } 

        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(detailsToSend ))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });
        detailsToSend.type = "ReportDataInput";
        axios
            .post(ServerUrl + "/get-styles", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setStyleNoList(response.data.data);
            });    
    }, []);
    /***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
    const filterStyle = (type,id)=>{
    if(type ==="Factory"){
        axios.post(ServerUrl + "/get-filters", apiencrypt({
          company_id: company_id,
          workspace_id: workspace_id,
          factory_id : id,
          type : getWorkspaceType,
        })).then((response) => {
            response.data = apidecrypt(response.data)
          if(getWorkspaceType === "Buyer"){
              setPcus(response.data.data);
          }
          else if(getWorkspaceType === "PCU"){
              setBuyers(response.data.data);
          }  
        });
      }
      if(type ==="Buyer"){
        axios.post(ServerUrl + "/get-filters", apiencrypt({
          company_id: company_id,
          workspace_id: workspace_id,
          buyer_id : id,
          type : getWorkspaceType,
        })).then((response) => {
            response.data = apidecrypt(response.data)
          setPcus(response.data.data);
        });
      }
    } 
    /* The Specific Style Numbers Starts */
    const setSpecificStyle=()=>{
        if(getWorkspaceType === "Buyer"){
            detailsToSend.factory_id = document.getElementById("facID").value;
            detailsToSend.pcu_id = document.getElementById("pcuID").value;
        }
        if(getWorkspaceType === "Factory"){
            detailsToSend.buyer_id = document.getElementById("buyerID").value;
            detailsToSend.pcu_id = document.getElementById("pcuID").value;
        }
        if(getWorkspaceType === "PCU"){
            detailsToSend.factory_id = document.getElementById("facID").value;
            detailsToSend.buyer_id = document.getElementById("buyerID").value;
        }
        detailsToSend.type = "ReportDataInput";
        axios
            .post(ServerUrl + "/get-styles", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setStyleNoList(response.data.data);
            });
        }
    /* The Specific Style Numbers Ends*/
    /*Order Validation  Start*/
    const orderValidation = (data) => {
    let errors={};
    // if (!factory && getWorkspaceType!='Factory') {
    //     errors.factory = 'Please Select a Factory';
    //    }
    //    if (!buyer  && getWorkspaceType!='Buyer') {
    //     errors.buyer = 'Please Select a Buyer';
    //    }
    //    if (!pcu  && getWorkspaceType!='PCU') {
    //     errors.pcu = 'Please Select a PCU';
    //    }
    if (!styleNo) {
      errors.styleNo = 'Please Select a Style Number';
     }
  
   setValidErrors(errors);
   return errors;
    };

    /*********************** To Download The PDF ********************/
    const toDownloadAsPdf = () => {
        let dataToSendForOrder ;
        getStaff === "Staff" ? dataToSendForOrder = {
            company_id: company_id,
            workspace_id: workspace_id,
            staff_id: getLoginStaffId,
            }: dataToSendForOrder = {
                company_id: company_id,
                workspace_id: workspace_id,
                user_id: getLoginUserId,
                };
        if (getWorkspaceType === "Buyer"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
        }
        else if (getWorkspaceType === "PCU"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
        }
        else if (getWorkspaceType === "Factory"){
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
        }
        if(filterStartDate != "" && filterEndDate !=""){
            dataToSendForOrder.start_date=filterStartDate;
            dataToSendForOrder.end_date=filterEndDate;
        }
        dataToSendForOrder.type = filterType;
        if(filterType === "Excess" && filterOperator!="" && filterPieces!=""){
            dataToSendForOrder.no_of_excess = filterPieces;
            dataToSendForOrder.operator_symb = filterOperator;
        }
        if(filterType === "Short" && filterOperator!="" && filterPieces!=""){
            // dataToSendForOrder.type = filterType;
            dataToSendForOrder.no_of_short = filterPieces;
            dataToSendForOrder.operator_symb = filterOperator;
        }
        dataToSendForOrder.order_id = document.getElementById("styleNo").value;
        axios.post(ServerUrl + "/download-daily-prod-report",apiencrypt(dataToSendForOrder),{responseType: 'blob'})
        .then((response)=>{
            let name;
            for(let i=0; i<styleNoList.length; i++){
                if(styleNo == styleNoList[i].id)
                    name = styleNoList[i].style_no;
            }
            let fileName = "DailyProductionReport-"+name+"-"+Date.now()+".pdf";
            DownloadFile(response.data,fileName);
        })
    }  
    const getReportDetails= (page) => {
        let retval= orderValidation();
        let detailToSend = {
            company_id: company_id,
            workspace_id: workspace_id,
            order_id : document.getElementById('styleNo').value,
            page : page,
        };
        setCurrentPage(page);
        if(filterStartDate != "" && filterEndDate !=""){
            detailToSend.start_date=filterStartDate;
            detailToSend.end_date=filterEndDate;
        }
        if(filterType === "Excess" && filterOperator!="" && filterPieces!=""){
            detailToSend.type = filterType;
            detailToSend.no_of_excess = filterPieces;
            detailToSend.operator_symb = filterOperator;
        }
        if(filterType === "Short" && filterOperator!="" && filterPieces!=""){
            detailToSend.type = filterType;
            detailToSend.no_of_short = filterPieces;
            detailToSend.operator_symb = filterOperator;
        }
        if(filterType === "All" ){
            detailToSend.type = filterType;
        }
        axios
            .post(ServerUrl + "/get-daily-prod-report", apiencrypt(detailToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if(response.data.status_code === 200){
                    setNecessaryDetails(response.data.data.necessaryDetails);
                    setPageDetails(response.data.data.necessaryDetails.pages);
                    setDailyProdData(response.data.data.prodData);
                    setToOpen(1);
                }
            });
    }
    // const getPaginatedReport = (page) =>{
    //     let detailToSend = {
    //         company_id: company_id,
    //         workspace_id: workspace_id,
    //         order_id : document.getElementById('styleNo').value,
    //     };
    //     axios
    //         .post(ServerUrl + "/get-daily-prod-report?page="+page, detailToSend)
    //         .then((response) => {
    //             if(response.data.status_code === 200){
    //                 setNecessaryDetails(response.data.data.necessaryDetails);
    //                 setDailyProdData(response.data.data.prodData);
    //                 setToOpen(1);
    //             }
    //         });
    // }
    return (
        <Fragment>
            <Row  className="pgbgcolor">
            <Breadcrumbs mainTitle={t('dataInputReports')} 
                         parent="Data-Input Reports" 
                         title={t('dataInputReports')}/>
            </Row>
                <Container fluid={true} className="general-widget topaln">
                <Card className="m-b-20">
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("factory")}</Label>
                                        <InputGroup>
                                            <Input
                                                // className={`${errors.factory && "select-error-valid"} form-control`}
                                                name="factory"
                                                type="select"
                                                placeholder="factory"
                                                aria-label="factory"
                                                defaultValue="0"
                                                id="facID"
                                                onChange={(e) => 
                                                {
                                                    if(factory !=""){
                                                        if(getWorkspaceType === "Buyer"){
                                                            document.getElementById("pcuID").selectedIndex=0;
                                                        }
                                                        else if(getWorkspaceType === "PCU"){
                                                            document.getElementById("buyerID").selectedIndex=0;
                                                        }
                                                    }
                                                    setFactory(e.target.value);
                                                    let facId = e.target.value;
                                                    filterStyle("Factory",facId)}}>
                                                <option value="0">{t("selectFactory")}</option>
                                                {factories.map((factory) => (
                                                    <option key={factory.id} value={factory.id}>{factory.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.factory && (
                                            <span className="error-msg">{t("selectFactory")}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        <InputGroup>
                                            <Input
                                                // className={`${errors.buyer && "select-error-valid"
                                                //     }  form-control`}
                                                name="buyer"
                                                type="select"
                                                placeholder="buyer"
                                                aria-label="buyer"
                                                defaultValue="0"
                                                id="buyerID"
                                                onChange={(e) => {
                                                                if(factory !=""){
                                                                    if(getWorkspaceType === "Factory"){
                                                                        document.getElementById("pcuID").selectedIndex=0;
                                                                    }
                                                                }
                                                                setBuyer(e.target.value);
                                                                let buyerId = e.target.value;
                                                                filterStyle("Buyer",buyerId);
                                                                if(getWorkspaceType === "PCU"){
                                                                    setSpecificStyle();
                                                                    document.getElementById("styleNo").selectedIndex=0;
                                                                }
                                                }}
                                            >
                                                <option value="0">{t("selectBuyer")}</option>
                                                {buyers.map((buy) => (
                                                    <option key={buy.id} value={buy.id}>{buy.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.buyer && (
                                            <span className="error-msg">{t("selectBuyer")}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        <InputGroup>
                                            <Input
                                                type="select"
                                                placeholder="Select Status"
                                                // className={`${errors.pcu && "select-error-valid"
                                                //     }  form-control digits`}
                                                name="pcuid"
                                                defaultValue="0"
                                                id="pcuID"
                                                // onChange={handleChange}
                                                onChange={(e) => {setPcu(e.target.value);
                                                    let pcuId = e.target.value;
                                                    filterStyle("PCU",pcuId);
                                                    if(getWorkspaceType != "PCU"){
                                                        setSpecificStyle();
                                                        document.getElementById("styleNo").selectedIndex=0;
                                                    }
                                                }}
                                            >
                                                <option value="0">
                                                    {t("selectPcu")}
                                                </option>
                                                {Pcus.map((pcuv) => (
                                                    <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {/* {errors.pcu && (
                                            <span className="error-msg">{t("selectPcu")}</span>
                                        )} */}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}

                            <Col lg="3" md="12" sm="12">
                            <FormGroup>
                                    <Label>{t("styleNo")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            type="select"
                                            placeholder="Select Status"
                                            className={`${errors.styleNo && "select-error-valid"
                                                }  form-control digits`}
                                            name="statusFilter"
                                            id="styleNo"
                                            defaultValue="0"
                                            onChange={(e) => {setStyleNo(e.target.value);
                                                setFilterStartDate("");
                                                setFilterEndDate("");
                                                setFilterOperator("");
                                                setFilterPieces("");
                                                setFilterType("");
                                            }}
                                        >
                                            <option value="0" disabled>
                                                {t("selectStyleno")}
                                            </option>
                                            { styleNoList.map((data)=>(
                                                <option key={data.id} value={data.id}> {data.order_no+"("+data.style_no+")"} </option>
                                            ))}
                                        </Input>
                                    </InputGroup>
                                    {errors.styleNo && (
                                        <span className="error-msg">{t("pleaseSelectStyleNo")}</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" 
                                        onClick={() => getReportDetails()}>{t("search")}</Button>
                                <Link to="/datainputreports" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Container>
                {toOpen === 1 ?  dailyProdData.length>0 ?
                    <>
                        <Card className="m-b-10 m-t-0" style={{ border:"0px" }}>
                            <CardBody className=" p-1"  >                
                                <Row>
                                    <Col lg="12" md="12" sm="12" xs="12" className="centerAlign">
                                            <Row>
                                                <Col lg={6} md={8} sm={8} xs={8} className="p-1 f-left">
                                                    <div className="p-l-20 m-t-15 f-left"><p className="middle f-w-500 f-18">{necessaryDetails.orderNo}&nbsp;&nbsp;
                                                    &nbsp;&nbsp;{necessaryDetails.styleNo}</p></div>
                                                </Col>
                                                <Col lg={6} md={4} sm={4} xs={4} className="p-1 f-right">
                                                    {(getStaff === "Staff"&&getStaffPermission.includes('Download Report'))||getStaff == null? 
                                                        <div className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" onClick={()=> toDownloadAsPdf()}>
                                                            <img title={t("download")}  src={DownloadIcon} />
                                                        </div>
                                                        : ""}
                                                    <div className="cursor-pointer p-1 p-r-0 m-t-5 f-right" onClick={() => toggleFilterCanvas()}>
                                                        <img  title={t("filter")} src={FilterIcon} />
                                                    </div>
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <DataInputReportTable dailyProdData={dailyProdData}/> 
                        <FilterOffCanvas modal={filterOffCanvas} toggle={toggleFilterCanvas} styleNo={styleNo}  
                        dailyProdData={setDailyProdData} filterStartDate={setFilterStartDate} filterEndDate={setFilterEndDate} 
                        filterOperator={setFilterOperator} filterPieces={setFilterPieces} filterType={setFilterType} 
                        necessaryDetails={setNecessaryDetails}/>
                        {necessaryDetails.pages > 1 ? 
                           getPagination()  : ""
                        }
                    </>
                : <>
                    <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                        <img src={noData} style={{ width:"200px"}} />
                        <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                    </div>
                </> : ""}
        </Fragment>
    );
}

export default DataInputReports;
/* Code By : Rithanesh */