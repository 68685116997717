import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../helper';
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId }
from '../../Constant/LoginConstant';

const AutofillDataModal = ({ 
    modal, toggle, 
    getPreviousSamReportDetails,
    orderNo, styleNo,
    setStyleNo,
    setUnitValue, setLineNoValue, 
    setProductionType, setShiftId, setShiftValue,
    setUnitId, setSamValue, setSupervisorId, setSupervisorName,
    setLineNoId, setNoOfTailors, setNoOfHelpers,
    setTailorSalary, setHelperSalary, setYes, setNo, setFlat, setFactor,
    setFromTime, setToTime, setBreakFreeStatus, setBreakTime, setSalaryType, setFactoryRevenueFactor, setAlertPercentage,
    setAdditionalData, setAdditionalFromTime, setAdditionalToTime, setAdditionalTailorSalary, setAdditionalHelperSalary
   }) =>{
  
  const {t} = useTranslation();

   const [unitIdValue, setUnitIdValue] = useState("");
   const [lineNoIdValue, setLineNoIdValue] = useState("");

  const [errors, setValidErrors] = useState({});

  const validation = () => {
    let errors = {};
    if (!unitIdValue) {
      errors.unitIdValue = "Please Select a Unit";
    }
    if (!lineNoIdValue) {
      errors.lineNoIdValue = "Please Select the Line Number";
    }
    setValidErrors(errors);
    return errors;
  };
  
  const clearValues = ( data ) => {
    if( data == "yes"){
      document.getElementById("styleNo").selectedIndex=0;
    }
    document.getElementById("productionType").selectedIndex=0;
    document.getElementById("shiftValue").selectedIndex=0;
    document.getElementById("unitValue").selectedIndex=0;
    document.getElementById("samValue").value="";
    document.getElementById("supervisorName").selectedIndex=0;
    document.getElementById("lineNoValue").selectedIndex=0;
    document.getElementById("noOfTailors").value="";
    document.getElementById("noOfHelpers").value="";
    document.getElementById("tailorSalary").value="";
    document.getElementById("helperSalary").value="";;
    document.getElementById("fromTime").value="00:00";
    document.getElementById("toTime").value="00:00";
    document.getElementById("breakTime").selectedIndex=0;

    if( data == "yes"){
      setStyleNo(() => "");
    }
    setProductionType(() => "");
    setShiftValue(() => "");
    setUnitId(() => "");
    setUnitValue(() => "");
    setSamValue(() => "");
    setSupervisorName(() => "");
    setLineNoValue(() => "");
    setNoOfTailors(() => "");
    setNoOfHelpers(() => "");
    setTailorSalary(() => "");
    setHelperSalary(() => "");
    setFromTime(() => "");
    setBreakFreeStatus(false)
    setBreakTime(() => "");

    setYes(() => "")
    setNo(true)
    setSalaryType(() => "")
    setFlat(() => "")
    setFactor(() => "")

    setAdditionalData(() => "")
    setAdditionalFromTime(() => "")
    setAdditionalToTime(() => "")
    setAdditionalTailorSalary(() => "")
    setAdditionalHelperSalary(() => "")

    setUnitIdValue(() => "");
    setLineNoIdValue(() => "");
    setValidErrors(() => "");
};

  const onSaveHandle = () => {

     let retval = validation();
     if (Object.keys(retval).length == 0) {
      var apiInputParams = {};
          apiInputParams['company_id'] = getLoginCompanyId;
          apiInputParams['workspace_id'] = getWorkspaceId;
          apiInputParams['style_no'] = styleNo;
          apiInputParams['unit_id'] = unitIdValue;
          apiInputParams['line_no_id'] = lineNoIdValue;
        axios
        .post(ServerUrl + "/get-previous-sam-report-details", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            if( response.data.data !== null){
                setProductionType(response.data.data.production_type);
                setShiftId(response.data.data.shift_id);
                setShiftValue(response.data.data.shift_value);
                setUnitId(response.data.data.unit_id);
                setSamValue(response.data.data.sam_value);
                setSupervisorId(response.data.data.supervisor_id);
                setSupervisorName(response.data.data.supervisor_name)
                setLineNoId(response.data.data.line_no_id);
                setNoOfTailors(response.data.data.no_of_tailors);
                setNoOfHelpers(response.data.data.no_of_helpers);
                setTailorSalary (response.data.data.tailor_salary);
                setHelperSalary(response.data.data.helper_salary);
                setFromTime (response.data.data.from_time);
                setToTime(response.data.data.to_time);
                setBreakFreeStatus(response.data.data.break_hours == 0 ? true : false)
                setBreakTime(response.data.data.break_hours)
                setFactoryRevenueFactor(response.data.data.factory_factor)
                setAlertPercentage(response.data.data.alert_percentage)

                if( response.data.data.additional_hours == 0  ){
                  setYes(false)
                  setNo(true)
                } else{
                  setYes(true)
                  setNo(false)
                }
        
                if( response.data.data.additional_salary_type == 1  ){
                  setSalaryType(true)
                  setFlat(true)
                  setFactor(false)
                } else{
                  setSalaryType(false)
                  setFlat(false)
                  setFactor(true)
                }
                setAdditionalData(response.data.data.additional_hours == 0 ? false : true)
                setAdditionalFromTime(response.data.data.additional_from_time);
                setAdditionalToTime(response.data.data.additional_to_time);
                setSalaryType(response.data.data.additional_salary_type);
                setAdditionalTailorSalary(response.data.data.additional_tailor_salary);
                setAdditionalHelperSalary(response.data.data.additional_helper_salary);
                toggle();
              } else{
              toggle();
            }
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
     }
  };

  /*To avoid duplicate line no in dropdown */
  const SamLineNoOptions = getPreviousSamReportDetails.filter(
    (option, index, self) =>
      index === self.findIndex((o) => o.unit_value === option.unit_value)
  ); 
  return (
    <Modal isOpen={modal} toggle={toggle} centered backdrop='static'>
      <ModalHeader>  {styleNo} - { orderNo} </ModalHeader>
      <ModalBody>

        <Row>
            <FormGroup className="">
                            <Label>Units</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                                <Input
                                  style={{  cursor: 'pointer' }}
                                  className={`${errors.unitValue && 'select-error-valid'}  form-control`}
                                  name="unitValue"
                                  id="unitValue"
                                  type="select"
                                  placeholder="Select Units"
                                  onChange={(e) => {
                                      var splitData = (e.target.value).split("&&");
                                      setUnitIdValue(splitData[0]);
                                      setUnitValue(splitData[1]);
                                  }}
                                >
                                <option selected disabled >Select Units</option>
                                { SamLineNoOptions.length > 0 ?
                                  SamLineNoOptions
                                  .map(mapData => (
                                    <>
                                      <option 
                                          value={mapData.unit_id +"&&"+ mapData.unit_value}>
                                          {mapData.unit_value}
                                        </option>
                                    </>
                                  ))
                                  :
                                  ""
                                }
                                </Input>
                                {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                                {/* <InputGroupText>
                                    <span
                                    className="btn selectbtn"
                                    onClick={toggleAddUnit}
                                    >
                                    +
                                    </span>
                                </InputGroupText>  */}
                                {/* : "" */}
                                {/* } */}
                                {/* <AddUnitModal
                                    modal={modalAddUnit}
                                    setModalAddShift={setModalAddUnit}
                                    toggle={toggleAddUnit}
                                    setShiftList={setShiftList}
                                    setUnitsList={setUnitsList}
                                    setSupervisorList={setSupervisorList}
                                    setLineNoList={setLineNoList}
                                    setProductionTypeList={setProductionTypeList} 
                                    setBreakTimeList={setBreakTimeList}
                                />  */}
                            </InputGroup>
                            {errors.unitIdValue && (
                                <span className="error-msg">{t(errors.unitIdValue)}</span>
                            )}
            </FormGroup>
        </Row> 
        <Row>
            <FormGroup className="">
                            <Label>Line No</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                                <Input
                                style={{  cursor: 'pointer' }}
                                className={`${errors.lineNoValue && 'select-error-valid'}  form-control`}
                                name="lineNoValue"
                                id="lineNoValue"
                                type="select"
                                placeholder="Select Line No"
                                onChange={(e) => {
                                    var splitData = (e.target.value).split("&&");
                                    setLineNoIdValue(splitData[0]);
                                    setLineNoValue(splitData[1]);
                                }}
                                >
                                <option selected disabled>Select Line No</option>
                                { getPreviousSamReportDetails.length > 0 ?
                                    getPreviousSamReportDetails
                                    .filter(data => unitIdValue == data.unit_id)
                                    .map(mapData => (
                                      <>
                                        <option 
                                          value={mapData.line_no_id +"&&"+ mapData.line_no_value}>
                                          {mapData.line_no_value}
                                           {/* - {mapData.line_no_id} */}
                                        </option>
                                      </>
                                    ))
                                  :
                                  ""
                                }
                                </Input>
                                {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                                {/* <InputGroupText>
                                    <span
                                    className="btn selectbtn"
                                    onClick={()=>{setModalAddLineNo(!modalAddLineNo)}}
                                    >
                                    +
                                    </span>
                                </InputGroupText>  */}
                                {/* : "" */}
                                {/* } */}
                                {/* <AddLineNoModal
                                    modal={modalAddLineNo}
                                    toggle={toggleAddLineNo}
                                    unitsList={unitsList}
                                    setUnitValue={setUnitValue}
                                    setShiftList={setShiftList}
                                    setUnitsList={setUnitsList}
                                    setSupervisorList={setSupervisorList}
                                    setLineNoList={setLineNoList}
                                    setProductionTypeList={setProductionTypeList} 
                                    setBreakTimeList={setBreakTimeList}
                                /> */}
                            </InputGroup>
                            {errors.lineNoIdValue && (
                                <span className="error-msg">{t(errors.lineNoIdValue)}</span>
                            )} 
            </FormGroup>
        </Row> 
        
      </ModalBody>
      <ModalFooter>
        <Btn 
          attrBtn={{ color: "btn secondaryBtn btn-sm", 
          onClick: () => { 
            setValidErrors(() => ""),  
            setUnitIdValue(()=> ""), 
            setLineNoIdValue(() => ""), 
           // setStyleNo(() => ""), 
            toggle(),
            clearValues()
            } }}>
            {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => {onSaveHandle()} }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AutofillDataModal;
/* Code By : Ramanan M */