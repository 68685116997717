import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col,Input,Label, Button, FormGroup } from "reactstrap";
import { Btn, H6 } from "../../../AbstractElements";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff,getStaffPermission, getLoginStaffId } from "../../../Constant/LoginConstant";
import { Close, SaveChanges , ServerUrl} from "../../../Constant";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import { apidecrypt, apiencrypt } from "../../../helper";
const FilterOffCanvas = ({ modal, toggle, styleNo,color, size, dailyProdData,filterStartDate,filterEndDate, 
    filterColor,filterSize, filterProdType,necessaryDetails}) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const { t } = useTranslation();
  const [pieces, setPieces] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [prodType, setProdType] = useState("All");
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
//   const dayss = [1, 2, 3, 4, 5, 6, 7, 8, 9,10];
 
  const submitFunction = ()=>{
    let dataToSend ;
    let type = [];
    let selectedColor = [];
    let selectedSize = [];
    let sizeState=[];
    let colorState=[];
    // let cut = document.getElementById("cut").checked;
    // let sew = document.getElementById("sew").checked;
    // let pack = document.getElementById("pack").checked;
    // if(cut)
    //   type.push("Cut");
    // if(sew)
    //   type.push("Sew");
    // if(pack)
    //   type.push("Pack");
    for(let i=0; i<color.length; i++){
      let isColorChecked = document.getElementById("color||"+color[i].id).checked;
      if(isColorChecked){
        selectedColor.push(color[i].id);
        let statePush = color[i].id+"||";
        colorState.push(statePush);
      }
    }
    for(let i=0; i<size.length; i++){
      let isSizeChecked = document.getElementById("size||"+size[i].id).checked;
      if(isSizeChecked){
        selectedSize.push(size[i].id);
        let statePush = size[i].id+"||";
        sizeState.push(statePush);
      }
    }
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        order_id : styleNo
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id : getLoginUserId,
        order_id : styleNo
    }
    // if(type != ""){
    //   dataToSend.production_type = type;
    //   setProdType(type);
    //   filterProdType(type);
    // }
    if(selectedColor != ""){
      dataToSend.colors = selectedColor;
      filterColor(selectedColor);
      setColors(colorState);
    }
    if(selectedSize != ""){
      dataToSend.sizes = selectedSize;
      filterSize(selectedSize);
      setSizes(sizeState);
    }
    let startDates = document.getElementById("startDate").value;
    let endDates = document.getElementById("endDate").value;
    if(startDates != "" && endDates != ""){
      dataToSend.start_date = document.getElementById("startDate").value;
      dataToSend.end_date = document.getElementById("endDate").value;
      filterStartDate(startDates);
      filterEndDate(endDates);
    }
    //console.log("dataToSend: ",dataToSend);
    axios
    .post(ServerUrl + "/get-daily-sku-reports", apiencrypt(dataToSend))
    .then((response) => {
        response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){
            necessaryDetails(response.data.data.necessaryDetails);
            dailyProdData(response.data.data.prodData);
            toggle();
        }
    });
  };
  const clearFunction=()=>{
    let dataToSend ;
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        order_id : styleNo,
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        order_id : styleNo,
    }
    setStartDate("0");
    setEndDate("0");
    setProdType("");
    setColors([]);
    setSizes([]);
    filterStartDate("");
    filterEndDate("");
    filterProdType("");
    filterColor([]);
    filterSize([]);
    axios
    .post(ServerUrl + "/get-daily-sku-reports", apiencrypt(dataToSend))
    .then((response) => {
      response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){
            dailyProdData(response.data.data.prodData);
            toggle();
        }
    });
  };
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"}>
      <OffcanvasHeader className="bg-primary offcanvas-header"><i className="fa fa-filter f-24"></i> {t("filter")} 
        <span className="f-right cursor-pointer" onClick={toggle}>X</span>
      </OffcanvasHeader> 
      <OffcanvasBody>
        <Row className="p-2">
            <Col md={6}>
                <Label>{t("startDate")}</Label>
                <Input type="date" id="startDate"
                onChange={(e)=>{
                  setStartDate(e.target.value);
                  document.getElementById("endDate").setAttribute("min",e.target.value);
                  let targetDate = moment(e.target.value).add(3,"months").format("YYYY-MM-DD");
                  document.getElementById("endDate").setAttribute("max",targetDate);
                }}
                onKeyDown={(e) => {
                e.preventDefault();
                }}
                defaultValue={startDate}>
                </Input>
            </Col>
            <Col md={6}>
                <Label>{t("endDate")}</Label>
                <Input type="date" id="endDate" 
                onChange={(e)=>{
                  setEndDate(e.target.value);
                }}
                onKeyDown={(e) => {
                    e.preventDefault();
                    }}
                defaultValue={endDate}> 
                </Input>
            </Col>
        </Row>
        {/* <div>
            <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                      <span className="f-left m-r-10">
                        <span className="f-16">{t("Production")}</span>
                      </span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                </Col>
            </Row>
            <div className="checkbox checkbox-primary marg-min-top-10">
              {prodType.includes("Cut")?
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Cut" 
                  // onClick={(e) => onWeekoffChecked(3)} value="3"
                    id="cut" defaultChecked/>
                  <Label for="cut" className="form-label weekdayoff">{t("cutting")}</Label>
                </> :
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Cut" 
                  // onClick={(e) => onWeekoffChecked(3)} value="3"
                    id="cut" />
                  <Label for="cut" className="form-label weekdayoff">{t("cutting")}</Label>
                </> 
              }
            </div>
            <div className="checkbox checkbox-primary marg-min-top-10">
              {prodType.includes("Sew")?
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Sew" 
                    id="sew" defaultChecked/>
                  <Label for="sew" className="form-label weekdayoff">{t("sewing")}</Label>
                </> :
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Sew" 
                    id="sew" />
                  <Label for="sew" className="form-label weekdayoff">{t("sewing")}</Label>
                </>
              }
            </div>
            <div className="checkbox checkbox-primary marg-min-top-10">
              {prodType.includes("Pack")?
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Pack" 
                    id="pack"  defaultChecked/>
                  <Label for="pack" className="form-label weekdayoff">{t("packing")}</Label>
                </> :
                <>
                  <Input type="checkbox"  className="checkbox_animated" name="Pack" 
                  // onClick={(e) => onWeekoffChecked(3)} value="3"
                    id="pack" />
                  <Label for="pack" className="form-label weekdayoff">{t("packing")}</Label>
                </>
              }
            </div>
        </div> */}
        <div>
            <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                      <span className="f-left m-r-10">
                        <span className="f-16">{t("color")}</span>
                      </span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                </Col>
                {color.map((colour,i)=>(
                  <div key={i} className="checkbox checkbox-primary marg-min-top-10">
                    {colors.includes(colour.id+"||")?
                    <>
                      <Input type="checkbox"  className="checkbox_animated" key="color1" name={"color||"+colour.id}
                        id={"color||"+colour.id} defaultChecked/>
                      <Label for={"color||"+colour.id} className="form-label weekdayoff">{colour.name}</Label>
                    </> : 
                    <>
                      <Input type="checkbox"  className="checkbox_animated" key="color1" name={"color||"+colour.id}
                        id={"color||"+colour.id} />
                      <Label for={"color||"+colour.id} className="form-label weekdayoff">{colour.name}</Label>
                    </>  
                  }
                  </div>
                ))}
            </Row>
        </div>
        <div>
            <Row>
                <Col lg="12" md="12" sm="12" xs="12">
                      <span className="f-left m-r-10">
                        <span className="f-16">{t("size")}</span>
                      </span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                </Col>
                {size.map((size,i)=>(
                  <div key={i}className="checkbox checkbox-primary marg-min-top-10">
                    {sizes.includes(size.id+"||")?
                    <>
                      <Input type="checkbox"  className="checkbox_animated" key="Size1" name={"size||"+size.id}
                        id={"size||"+size.id} defaultChecked/>
                      <Label for={"size||"+size.id} className="form-label weekdayoff">{size.name}</Label>
                    </>:
                    <>
                      <Input type="checkbox"  className="checkbox_animated" key="Size1" name={"size||"+size.id}
                        id={"size||"+size.id} />
                      <Label for={"size||"+size.id} className="form-label weekdayoff">{size.name}</Label>
                    </>  
                  }
                  </div>
                ))}
            </Row>
        </div>
      </OffcanvasBody>
      <footer className="m-20 p-2">
        <Row>
           <Col lg={6} md={6} sm={6}>
                <Button className="btn secondaryBtn" style={{ width:"100%" }} onClick={ ()=>clearFunction() }>{t("clear")}</Button>
           </Col> 
           <Col lg={6} md={6} sm={6}>
                <Button className="btn primaryBtn" onClick = {()=>submitFunction()}  style={{ width:"100%" }}>
                    {t("apply")}
                </Button>
           </Col> 
        </Row>
      </footer>
    </Offcanvas>
  );
};

export default FilterOffCanvas;
/* Code By : Rithanesh */