import React , {useState, useEffect}from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Close, SaveChanges, ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from '../../helper';

const TaskUpdateTemplateModal = ({ modal, toggleTemplateModal, companyId, workspaceId, orderId , templates, templateTasks,templateNames,refreshTemplates,
  newTemplateSave , setOrderTemplateId,savedTemplateId, setTheSelectedTemplate, areTasksEdited,setTemplates,selectedTemplateId,onSubmitHandle,
  setIfTemplateChanged,setOnSubmitData,setTemplateload,updateStartDate,updateEndDate}) =>{
  const { t } = useTranslation();
  const [templateName, setTemplateName] = useState("");
  // const [orderNewTemplateId, setOrderNewTemplateId] = useState("");
  // const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  /* Validation Starts */
  const [errors, setValidErrors] = useState({});
  const validation = ()=>{
    let errors = {};
    if(!templateName){
      errors.templateName = ("templateName") 
    }
    setValidErrors(errors);
    return errors;
  };

  setTimeout(() => {
    var element =  document.getElementById('templatemodal');
    if (typeof(element) != 'undefined' && element != null)
    {
      element.focus();
    }
     
    }, 1000);
  /* Validation Ends */
  useEffect(()=>{
    if(!modal){
      // setTemplateName("");
      // setValidErrors("");
    }
  });
  /**************** To Save the Template Name  ************************/


  const onSaveHandle = async() => {
    let retval = validation();
    if (Object.keys(retval).length == 0) {
      await axios
        .post(ServerUrl + "/create-order-template", apiencrypt({
          company_id: companyId,
          workspace_id: workspaceId,
          template_name: templateName,
          order_id : orderId,
          task_template_structure : newTemplateSave
        }))
        .then((response) => {
          response.data = apidecrypt(response.data);
          // setOrderNewTemplateId(response.data.templateID);
          // savedTemplateId(response.data.templateID);
           //setTheSelectedTemplate(response.data.templateID);
          if (response.data.status_code === 200) {
            // setOnSubmitData(1)
            // Swal.fire({
            //   title: t(response.data.message),
            //   // text: t(response.data.message),
            //   icon: "success",
            //   button:  t("okLabel"),
            //   timer:5000,
            // }).then((result) => {
            //   if (result.isConfirmed) {
            //     setTimeout(()=>{

            //       setOnSubmitData(1)
            //     },700)
              
            // }
             /* To show the saved templates in the dropdown */
            // if (result.isConfirmed) {

            let req_param = {};
            templateNames.map((template) => template.id == selectedTemplateId ? 
              JSON.parse(template.task_template_structure).map((templates) => {
              /* This variable is to set the position of the object in the array in the below loop  */
              let i = 0
              let sub_req_param = {};
              var task_title = templates.task_title;
              templates.task_subtitles.map((subtitle) => {
                let sub_arr = {};
                sub_arr.title = document.getElementById(templates.task_title + '||' + subtitle + '||taskupdatename').value;
                sub_arr.startdate = document.getElementById(templates.task_title + '||' + subtitle + '||taskupdatestartdate').value;
                sub_arr.enddate = document.getElementById(templates.task_title + '||' + subtitle + '||taskupdateenddate').value;
                sub_arr.pic_id = document.getElementById(templates.task_title + '||' + subtitle + '||taskupdatepic_id').value;
                sub_req_param[i] = sub_arr;
                i++;
              })
              req_param[task_title] = sub_req_param;
            }) : "")
                 
            axios
                  .post(ServerUrl + "/update-task-data", apiencrypt({
                      company_id: companyId,
                      workspace_id: workspaceId,
                      template_id: response.data.templateID,
                      template_data : req_param,
                        order_id : orderId,
                        // update_startdate:updateStartDate,
                        // update_enddate:updateEndDate
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    if (response.data.status_code === 200) {
                      Swal.fire({
                          title: t("updatedSuccessfully"),
                          icon: "success",
                          button: t("okLabel"),
                          allowOutsideClick: false,
                           timer:5000,
                    }).then((result) => {
                      if (result.isConfirmed) {
                          window.location.href = "/taskupdate";
                      }
                      window.location.href = "/taskupdate";

                    });
                   
                  }
                    response.data = apidecrypt(response.data);
                    //setTemplateload(response.data.data);
                    templates(response.data.data);
                  });
                  // areTasksEdited(0);
                  toggleTemplateModal(false); 
                  // setTimeout(()=>{

                  //         setOnSubmitData(1)
                  //       },100)
              // }
            /*    setTimeout(() => {
                axios
                  .post(ServerUrl + "/get-order-template", apiencrypt({
                      company_id: companyId,
                      workspace_id: workspaceId,
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                      templates(response.data.data);
                  });
                  areTasksEdited(0);
                  toggleTemplateModal(false);
              }, 100);*/
            // });
          }
          /* To show error if the Template name is already present*/
          if (response.data.status_code === 401) {
            Swal.fire({
              title: t("templateExistsTitleAlert"),
              text: t("enterDifferentNameTextAlert"),
              icon: "error",
              button: t("okLabel"),
            });
          }
        });
    }
  };
  return (
    <Modal isOpen={modal} toggle={toggleTemplateModal} centered>
      <ModalHeader>{t("saveTemplate")}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("saveTemplate")}
        </Label>
        <Input
          className="form-control"
          type="text"
          id="templatemodal"
          onChange={(e) => setTemplateName(e.target.value= capitalizeFirstLowercaseRest(e.target.value))}
        />
        {errors.templateName && (
                            <><span className="error-msg">{t(errors.templateName)}</span><br/></>
                          )}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn", onClick: toggleTemplateModal }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary", onClick: () => onSaveHandle() }}>
        {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default TaskUpdateTemplateModal;