import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Table, Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";
import { P, H5, H2, H6, Btn, UL } from "../../../AbstractElements";

import OrderHeaders from "../CreateOrders/OrderHeaders"
import factory from '../../../assets/images/dms/factory.png'
import pcu from '../../../assets/images/dms/pcu.png'
import buyer from '../../../assets/images/dms/onGoingList.svg';
import userimg from '../../../assets/images/dms/user.png'
import cutting from '../../../assets/images/dms/icons/green_cutting.svg'
import Sewing from '../../../assets/images/dms/icons/green_sewing.svg'
import Packing from '../../../assets/images/dms/icons/green_packing.svg'
import greycutting from '../../../assets/images/dms/icons/grey_cutting.svg'
import greySewing from '../../../assets/images/dms/icons/grey_sewing.svg'
import greyPacking from '../../../assets/images/dms/icons/grey_packing.svg'
import DatePicker from 'react-datepicker';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import axios from "axios";
import { BackgroundColor, ServerUrl } from "../../../Constant";
import { useSearchParams } from "react-router-dom";
import { encode, decode, calculateDateDiffCountFromCurrentDate, apiencrypt, apidecrypt,PHPtoJSFormatConversion } from "../../../helper";
import Swal from 'sweetalert2';
import Moment from "moment/moment";
import { getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission, getLoginUserId, getLoginStaffId } from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import jaLocale from '@fullcalendar/core/locales/ja';

const ProductionPlan = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [basicInformation, setBasicInformation] = React.useState([]);
  const [state, setState] = useState('');
  const [quantity, setQuantity] = useState('');
  const [IconWithTab, setIconWithTab] = useState('1');
  const [showCalendar, setShowCalendar] = useState(0);
  const [showSewCalendar, setShowSewCalendar] = useState(1);
  const [showPackCalendar, setShowPackCalendar] = useState(1);
  const [showCutData, setShowCutData] = useState([]);
  const [showCutData1, setShowCutData1] = useState([]);
  const [showSewData, setShowSewData] = useState([]);
  const [showPackData, setShowPackData] = useState([]);
  const [calendar_data, setCalendarData] = React.useState([]);
  const [HolidayInformation, setHolidayInformation] = React.useState([]);
  const [OrderProduction, setOrderProduction] = React.useState([]);
  const [WeekOffInformation, setWeekOffInformation] = React.useState([]);
  const [WeekOffDay, setWeekOffDay] = React.useState('');
  const [getWeekOff, setWeekoff] = React.useState([]);
  const [productionType, setproductionType] = React.useState('cut');
  const [DeleteInformation, setDeleteInformation] = React.useState([]);
  const [cut_start_date, setcut_start_date] = React.useState('');
  const [cut_end_date, setcut_end_date] = React.useState('');
  const [sew_start_date, setsew_start_date] = React.useState('');
  const [sew_end_date, setsew_end_date] = React.useState('');
  const [pack_start_date, setpack_start_date] = React.useState('');
  const [pack_end_date, setpack_end_date] = React.useState('');
  const [datecount, setdatecount] = useState(0);
  const [changecutstartdate, setchangecutstartdate] = React.useState('');
  const [cutProductionDta, setCutProductionDta] = useState('0');
  const [sewProductionDta, setSewProductionDta] = useState('0');
  const [packProductionDta, setPackProductionDta] = useState('0');
  const [prodCuttingDate, setProdCuttingDate] = useState('');
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language == 'jp' ? jaLocale : '');
  /* Previous Data state */
  const [pcut_start_date, setpcut_start_date] = React.useState('');
  const [pcut_end_date, setpcut_end_date] = React.useState('');
  const [psew_start_date, setpsew_start_date] = React.useState('');
  const [psew_end_date, setpsew_end_date] = React.useState('');
  const [ppack_start_date, setppack_start_date] = React.useState('');
  const [ppack_end_date, setppack_end_date] = React.useState('');
  const [delDate, setDelDate] = useState([]);
  let cuttingData = [];
  var productionData = {}
  productionData['company_id'] = getLoginCompanyId;
  productionData['workspace_id'] = getWorkspaceId;
  productionData['order_id'] = orderId;

  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;

  var getInputHolidays = {}
  getInputHolidays['company_id'] = getLoginCompanyId;
  getInputHolidays['workspace_id'] = getWorkspaceId;

  var getInputWeekOff = {}
  getInputWeekOff['company_id'] = getLoginCompanyId;
  getInputWeekOff['workspace_id'] = getWorkspaceId;
  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;


  const scrollToTop = () => window.scrollTo(0, 0);

  var currentDate = Moment().format('YYYY-MM-DD');

  /********************Basic Information Api*************************/
  useEffect(() => {
    let common_input = getInputParams;
    common_input['type_of_production'] = 'Cut';
    axios
      .post(ServerUrl + "/get-order-production", apiencrypt(common_input))
      .then((response) => {
        response.data = apidecrypt(response.data);
        var results = response.data.data;
        var cut_prod_data = []; var i = 0;
        var start_date = ''; var end_date = '';
        results.map(res => {
          if (i == 0) {
            start_date = res.date_of_production;
          }
          if ((i + 1) == results.length) {
            end_date = res.date_of_production;;
          }
          var date_arry = [];
          date_arry.date_of_production = res.date_of_production;
          if (res.holiday_flag == 0)
            date_arry.target_value = res.target_value;
          else
            date_arry.target_value = res.holiday_detail;
          date_arry.holiday_flag = res.holiday_flag;
          date_arry.date = new Date(res.date_of_production);
          date_arry.holiday_detail = res.holiday_detail;
          cut_prod_data[i] = date_arry;
          i++;
        })
        setcut_start_date(start_date)
        setcut_end_date(end_date)
        setpcut_start_date(start_date)
        setpcut_end_date(end_date)
        setShowCutData(cut_prod_data)
        setShowCutData1(cut_prod_data)
        setShowCalendar(1)
      })
    common_input['type_of_production'] = 'Sew';
    axios
      .post(ServerUrl + "/get-order-production", apiencrypt(common_input))
      .then((response) => {
        response.data = apidecrypt(response.data);
        var results = response.data.data;
        var cut_prod_data = []; var i = 0;
        var start_date = ''; var end_date = '';
        results.map(res => {
          if (i == 0) {
            start_date = res.date_of_production;
          }
          if ((i + 1) == results.length) {
            end_date = res.date_of_production;;
          }
          var date_arry = [];
          date_arry.date_of_production = res.date_of_production;
          if (res.holiday_flag == 0)
            date_arry.target_value = res.target_value;
          else
            date_arry.target_value = res.holiday_detail;
          date_arry.holiday_flag = res.holiday_flag;
          date_arry.date = new Date(res.date_of_production);
          date_arry.holiday_detail = res.holiday_detail;
          cut_prod_data[i] = date_arry;
          i++;
        })
        setShowSewData(cut_prod_data)
        setShowCalendar(1)
        setsew_start_date(start_date)
        setsew_end_date(end_date)
        setpsew_start_date(start_date)
        setpsew_end_date(end_date)
      })
    common_input['type_of_production'] = 'Pack';
    axios
      .post(ServerUrl + "/get-order-production", apiencrypt(common_input))
      .then((response) => {
        response.data = apidecrypt(response.data);
        var results = response.data.data;
        var cut_prod_data = []; var i = 0;
        var start_date = ''; var end_date = '';
        results.map(res => {
          var date_arry = [];
          if (i == 0) {
            start_date = res.date_of_production;
          }
          if ((i + 1) == results.length) {
            end_date = res.date_of_production;;
          }
          date_arry.date_of_production = res.date_of_production;
          if (res.holiday_flag == 0)
            date_arry.target_value = res.target_value;
          else
            date_arry.target_value = res.holiday_detail;
          date_arry.holiday_flag = res.holiday_flag;
          date_arry.date = new Date(res.date_of_production);
          date_arry.holiday_detail = res.holiday_detail;
          cut_prod_data[i] = date_arry;
          i++;
        })
        setShowPackData(cut_prod_data)
        setShowCalendar(1)
        setpack_start_date(start_date)
        setpack_end_date(end_date)
        setppack_start_date(start_date)
        setppack_end_date(end_date)
      })
    axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setBasicInformation(response.data.data);
        setQuantity(response.data.data[0].quantity);
        setProdCuttingDate(response.data.data[0].cutting_start_date)
        setDelDate(response.data.delivery_dates);

        if (response.data.data[0].cutting_start_date != null) {
          const dateCountv = calculateDateDiffCountFromCurrentDate(response.data.data[0].cutting_start_date);
          setdatecount(dateCountv);
        } else {
          setdatecount('1');
        }

        if (response.data.data[0].status != 1) {
          window.location.href = "/dashboard"
        }
        else {
          ""
        }

      })
    axios
      .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setHolidayInformation(response.data.data);
      })
    axios
      .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
      .then((response) => {
        response.data = apidecrypt(response.data)
        let wk_of = [];
        for (let i = 0; i < (response.data.data).length; i++) {
          wk_of[i] = response.data.data[i].day;
        }
        setWeekoff(wk_of);
      })

    {
      (((getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null))
        ?
        ""
        :
        window.location.href = "/dashboard"
    }
  }, [])

  /********************Get WeekOff(no of sunday mean weekoff) Validation*************************/
  const getNumberOfWeekDays = (start, end) => {
    var no_of_days = 0;

    let arr = [...new Set(getWeekOff)];
    for (let i = 0; i < arr.length; i++) {
      var dayNum = arr[i];
      var daysInInterval = Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
      var toNextTargetDay = (7 + dayNum - start.getDay()) % 7;
      var daysFromFirstTargetDay = Math.max(daysInInterval - toNextTargetDay, 0);
      no_of_days += Math.ceil(daysFromFirstTargetDay / 7);
    }
    return no_of_days;
  }
  /********************Start date to end date between oneday quantity (cutting,sewing,Packing)*************************/
  const dateCaluation = (frm_date, to_date, action = '', ex_date = '') => {
    let from_date = new Date(frm_date);
    let todate = new Date(to_date);
    let dateArr = [];
    let m = 0;
    /********************Api response default holidays validation*************************/
    let todate_plus_one = new Date(to_date);
    new Date(todate_plus_one.setDate(todate_plus_one.getDate() + 1));
    if (getWeekOff.length != 0)
      var no_of_sunday = getNumberOfWeekDays(from_date, todate_plus_one);
    else
      var no_of_sunday = 0
    if (action != '') {
      if (action == 'remove')
        no_of_sunday += 1
      else
        no_of_sunday -= 1
    }
    for (let k = 0; k < HolidayInformation.length; k++) {
      var holiday_start_date = new Date(HolidayInformation[k].holiday_start_date);
      var holiday_start_date1 = new Date(HolidayInformation[k].holiday_start_date);
      var holiday_end_date = new Date(HolidayInformation[k].holiday_end_date);
      if (holiday_start_date >= from_date && holiday_end_date <= todate) {
        let l = 0;
        do {
          var holiday_start_date1 = holiday_start_date;
          new Date(holiday_start_date1.setDate(holiday_start_date1.getDate() + l));
          if (getWeekOff.includes(holiday_start_date1.getDay())) {
          }
          else {
            dateArr[m] = new Date(holiday_start_date1).toISOString().split('T')[0];
          }
          l = 1; m++;
        }
        while (holiday_start_date1 < holiday_end_date);
      }
    }
    dateArr = dateArr.filter(onlyUnique);
    no_of_sunday += dateArr.length;
    /***************/
    let dayDiff = Math.ceil((((todate - from_date) / (1000 * 3600 * 24)) + 1));
    let productQuantity = Math.floor(quantity / (dayDiff - no_of_sunday));
    let remainingQuantities = quantity % (dayDiff - no_of_sunday);
    return productQuantity + '||' + dayDiff + '||' + remainingQuantities;
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  function calculateData(cut_start_date, cut_end_date, action, ex_date, newholiday_id = 0) {
    let cut_prod_data = [];
    if (cut_start_date != '' && cut_end_date != '') {
      let cut_det = dateCaluation(cut_start_date, cut_end_date, action, ex_date);
      let cut_qty = cut_det.split('||')[0];
      let remaining_qty = cut_det.split('||')[2];
      let cut_days = parseInt(cut_det.split('||')[1]);
      let j = 0;
      for (var i = 0; i < cut_days; i++) {
        var date = new Date(cut_start_date);
        var newdate = new Date(date.setDate(date.getDate() + i)).toISOString().split('T')[0];
        let date_arry = {};
        /*******************continue holiday validation********************/
        var holiday_text = '';
        var holiday_flg = 0; var holiday_id = 0; let week_of_flag = 0
        for (let k = 0; k < HolidayInformation.length; k++) {

          var holiday_start_date = new Date(HolidayInformation[k].holiday_start_date);
          var holiday_end_date = new Date(HolidayInformation[k].holiday_end_date);

          if (date >= holiday_start_date && date <= holiday_end_date) {
            holiday_flg = 1
            holiday_text = HolidayInformation[k].name;
            holiday_id = HolidayInformation[k].id;
            break;
          }
        }
        /*******************Create holiday and Delete holiday Action*******************/
        if (action != '') {
          ex_date = new Date(ex_date);
          if (date.getTime() === ex_date.getTime()) {
            if (action == 'remove') {
              holiday_flg = 1;
              holiday_text = 'Holiday';
              holiday_id = newholiday_id;
            }
            else {
              holiday_flg = 0
            }
          }
        }
        /**************/
        if (holiday_flg === 1) {
          //title: eventClick.event.title,  
          var cut_qty1 = holiday_text;
        }
        //else if (date.getDay() == Week_of) {
        else if (getWeekOff.includes(date.getDay())) {
          var cut_qty1 = 'WeekOff';
          holiday_flg = 1;
          week_of_flag = 1;
        }
        else {
          var cut_qty1 = (j < remaining_qty) ? parseInt(cut_qty) + 1 : parseInt(cut_qty);
          j++;
        }
        date_arry.date_of_production = newdate;
        date_arry.target_value = cut_qty1;
        date_arry.holiday_flag = holiday_flg;
        date_arry.holiday_id = holiday_id;
        date_arry.date = date;
        date_arry.holiday_detail = '';
        date_arry.week_of_flag = week_of_flag;
        cut_prod_data[i] = date_arry;
      }
    }
    return cut_prod_data;
  }
  // alert("Cutting start date and end date is holiday so can't assigned event in calender")//

  const cutData = (type, action = '', ex_date = '', newholiday_id = 0) => {
    if (type === 'cut') {
      var cut_start_date = document.getElementById('cut_start_date').value;
      var cut_end_date = document.getElementById('cut_end_date').value;
      const cutValue = calculateData(cut_start_date, cut_end_date, action, ex_date, newholiday_id);
      if (cutValue.length > 0) {
        var lastDay = cutValue[cutValue.length - 1];
        if (cutValue[0].holiday_flag === 1) {
          Swal.fire({
            position: 'top-middle',
            icon: 'warning',
            title: t("cutting"),
            text: t("cuttingStartDateHoliday"),
            button: t("okLabel"),
            timer: 2000
          })
          setcut_end_date('');
          setcut_start_date('');
          document.getElementById("cut_start_date").value = '';
          document.getElementById("cut_end_date").value = '';
          return false;
        } else if (lastDay.holiday_flag == 1) {
          Swal.fire({
            position: 'top-middle',
            icon: 'warning',
            title: t("cutting"),
            text: t("cuttingEndDateHoliday"),
            button: t("okLabel"),
            timer: 2000
          })
          setcut_end_date('');
          setcut_start_date('');
          document.getElementById("cut_start_date").value = '';
          document.getElementById("cut_end_date").value = '';
          return false;
        } else {
          setShowCutData(cutValue);
        }
      }
    } else if (type === 'sew') {
      var cut_start_date = document.getElementById('sew_start_date').value;
      var cut_end_date = document.getElementById('sew_end_date').value;
      const cutValue = calculateData(cut_start_date, cut_end_date, action, ex_date, newholiday_id);
      var lastDay = cutValue[cutValue.length - 1];
      if (cutValue[0].holiday_flag === 1) {
        Swal.fire({
          position: 'top-middle',
          icon: 'warning',
          title: t("sewing"),
          text: t("sewingStartDateHoliday"),
          button: t("okLabel"),
          timer: 2000

        })
        setsew_start_date('');
        setsew_end_date('');
        document.getElementById('sew_start_date').value = '';
        document.getElementById('sew_end_date').value = '';
        return false;
      } else if (lastDay.holiday_flag == 1) {
        Swal.fire({
          position: 'top-middle',
          icon: 'warning',
          title: t("sewing"),
          text: t("sewingEndDateHoliday"),
          button: t("okLabel"),
          timer: 2000

        })
        setsew_start_date('');
        setsew_end_date('');
        document.getElementById('sew_start_date').value = '';
        document.getElementById('sew_end_date').value = '';
        return false;
      }

      else {
        setShowSewData(cutValue);
      }
    }
    else if (type === 'pack') {
      var cut_start_date = document.getElementById('pak_start_date').value;
      var cut_end_date = document.getElementById('pak_end_date').value;
      const cutValue = calculateData(cut_start_date, cut_end_date, action, ex_date, newholiday_id);
      var lastDay = cutValue[cutValue.length - 1];
      if (cutValue[0].holiday_flag === 1) {
        Swal.fire({
          position: 'top-middle',
          icon: 'warning',
          title: t("packing"),
          text: t("packingStartDateHoliday"),
          button: t("okLabel"),
          timer: 2000

        })
        setpack_start_date('');
        setpack_end_date('');
        document.getElementById('pak_start_date').value = '';
        document.getElementById('pak_end_date').value = '';
        return false;
      } else if (lastDay.holiday_flag == 1) {
        Swal.fire({
          position: 'top-middle',
          icon: 'warning',
          title: t("packing"),
          text: t("packingEndDateHoliday"),
          button: t("okLabel"),
          timer: 2000

        })
        setpack_start_date('');
        setpack_end_date('');
        document.getElementById('pak_start_date').value = '';
        document.getElementById('pak_end_date').value = '';
        return false;
      }

      else {
        setShowPackData(cutValue);
      }
    }
  }
  /*******************WeekOff Api********************/
  const onWeekoffChecked = (day) => {
    if (getWeekOff.includes(day)) {
      getWeekOff.pop(day)
    } else {
      getWeekOff.push(day)
    }
    let v = [...new Set(getWeekOff)];
    setWeekoff(v);
    // cutData(productionType)

    axios
      .post(ServerUrl + "/create-weekOffs", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
        "days": day

      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        axios
          .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
          .then((response) => {
            response.data = apidecrypt(response.data)
            let wk_of = [];
            for (let i = 0; i < (response.data.data).length; i++) {
              wk_of[i] = response.data.data[i].day;
            }
            setWeekoff(wk_of);
            setsew_start_date('');
            setsew_end_date('');
            setpack_start_date('');
            setpack_end_date('');
            setShowSewCalendar('');
            setShowPackCalendar('');
            document.getElementById('sew_start_date').value = '';
            document.getElementById('sew_end_date').value = '';
            document.getElementById('pak_start_date').value = '';
            document.getElementById('pak_end_date').value = '';
          })
        if (response.data.status_code === 200) {

          Swal.fire({
            position: 'top-middle',
            icon: 'success',
            title: t(response.data.message),
            timer: 1700
          }).then((result) => {
            if (result.isConfirmed) {
             
              cutData(productionType)
            }
            cutData(productionType)
          });
        }
        if (response.data.status_code === 201) {
          Swal.fire({
            position: 'top-middle',
            icon: 'success',
            title: t(response.data.message),
          })
        }
      })
  }
  /*******************Create Holiday api ********************/
  const eventClick = (eventInfo) => {
    let holiday_flg = (eventInfo.event.id).split('||')[1];
    let start = eventInfo.event.start;
    let id = (eventInfo.event.id).split('||')[2];
    if (holiday_flg == 0) {
      Swal.fire({
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#f46a6a',
        title: t("assignHoliday"),
        icon: "warning",
        confirmButtonText: t('Yes'),
        cancelButtonText: t('NO')
      })
        .then(result => {
          if (result.value) {
            var stat_date = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate();
            getInputHolidays['holiday_start_date'] = stat_date;
            getInputHolidays['holiday_end_date'] = stat_date;
            getInputHolidays['name'] = "Holiday";
            getInputHolidays['description'] = "Holiday";

            axios
              .post(ServerUrl + "/create-holidays", apiencrypt(getInputHolidays))
              .then((response) => {
                response.data = apidecrypt(response.data)
                var Holiday_id = response.data.holiday_id;
                axios
                  .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    setHolidayInformation(response.data.data);
                  })

                cutData(productionType, 'remove', start, Holiday_id);
              })
            Swal.fire(t('holidayDay'), t('declaredHoliday'), 'success');
          }
        });
    }
    /*******************Delete Holiday api********************/
    else {
      Swal.fire({
        html:
          `<div className="table-responsive">
         
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#f46a6a',
        title: t("assignWorkingDay"),
        icon: "warning",
        confirmButtonText: t('Yes'),
        cancelButtonText: t('NO')
      })
        .then(result => {
          if (result.value) {
            var stat_date = start.getFullYear() + "-" + (start.getMonth() + 1) + "-" + start.getDate();
            getInputHolidays['startDate'] = stat_date;
            getInputHolidays['endDate'] = stat_date;
            getInputHolidays['id'] = id;
            axios
              .post(ServerUrl + "/delete-holiday", apiencrypt(getInputHolidays))
              .then((response) => {
                response.data = apidecrypt(response.data)
                setDeleteInformation(response.data.data);
                axios
                  .post(ServerUrl + "/get-holidays", apiencrypt(getInputHolidays))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    setHolidayInformation(response.data.data);
                  })
                cutData(productionType, 'add', start);
              })
            Swal.fire(t('workingDay'), t('declaredWorkingDay'), 'success');
          }
        });
    }
  }
  // Sewing start date and end date input empty validation alert and sewing start date and end date mas match validation
  function getDataproductplan(cut_start_date, cut_end_date) {
    let cut_prod_data = [];
    if (cut_start_date != '' && cut_end_date != '') {
      let cut_det = dateCaluation(cut_start_date, cut_end_date);
      let cut_qty = cut_det.split('||')[0];
      let cut_days = parseInt(cut_det.split('||')[1]);
      let remaining_qty = cut_det.split('||')[2];
      let j = 0;
      for (var i = 0; i < cut_days; i++) {
        var date = new Date(cut_start_date);
        var newdate = new Date(date.setDate(date.getDate() + i)).toISOString().split('T')[0];
        let date_arry = {};
        let holiday_flg = 0; let holiday_text = ''; let holiday_id = 0; let week_of_flag = 0;
        for (let k = 0; k < HolidayInformation.length; k++) {
          var holiday_start_date = new Date(HolidayInformation[k].holiday_start_date);
          var holiday_end_date = new Date(HolidayInformation[k].holiday_end_date);
          if (date >= holiday_start_date && date <= holiday_end_date) {
            holiday_flg = 1
            holiday_text = HolidayInformation[k].name;
            holiday_id = HolidayInformation[k].id;
            break;
          }
        }
        if (getWeekOff.includes(date.getDay())) {
          holiday_text = 'WeekOff';
          holiday_flg = 1;
          week_of_flag = 1;
        }

        if (holiday_flg == 1) {
          var cut_qty1 = 0;
        } else {
          var cut_qty1 = (j < remaining_qty) ? parseInt(cut_qty) + 1 : parseInt(cut_qty);
          j++;
        }
        date_arry.date_of_production = newdate;
        date_arry.target_value = cut_qty1;
        date_arry.holiday_flag = holiday_flg;
        date_arry.holiday_id = holiday_id;
        date_arry.holiday_detail = holiday_text;
        date_arry.week_of_flag = week_of_flag;
        cut_prod_data[i] = date_arry;
      }
      return cut_prod_data;
    }
  }
  const getProductionChecked = (a) => {
    let cut_array = {};
    if (a == 'sew') {
      let cut_end_date = document.getElementById('cut_end_date').value;
      let sew_end_date = document.getElementById('sew_end_date').value;
      let cut_start_date = document.getElementById('cut_start_date').value;
      let sew_start_date = document.getElementById('sew_start_date').value;
      if (sew_start_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectSewingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (sew_end_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectSewingEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (cut_start_date > sew_start_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctSewingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }

      if (cut_end_date > sew_end_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctSewingEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
    }
    if (a == 'pack') {
      let pak_end_date = document.getElementById('pak_end_date').value;
      let pak_start_date = document.getElementById('pak_start_date').value;
      let sew_start_date = document.getElementById('sew_start_date').value;
      let sew_end_date = document.getElementById('sew_end_date').value;
      if (pak_start_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("packingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (pak_end_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("packingEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (sew_start_date > pak_start_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctPackingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (sew_end_date > pak_end_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("packingCorrectEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
    }
    if (a == 'cut') {
      let cut_start_date = document.getElementById('cut_start_date').value;
      let cut_end_date = document.getElementById('cut_end_date').value;

      if (cut_start_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("cuttingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (cut_end_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("cuttingEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (cut_start_date > cut_end_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctStartEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }

      const getVal = getDataproductplan(cut_start_date, cut_end_date);
      cut_array.type_of_production = 'Cut';
      cut_array.start_date = cut_start_date;
      cut_array.end_date = cut_end_date;
      cut_array.prod_data = getVal;
    }
    let sew_array = {};
    if (a == 'sew') {
      let sew_start_date = document.getElementById('sew_start_date').value;
      let sew_end_date = document.getElementById('sew_end_date').value;

      if (sew_start_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectSewingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (sew_end_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("selectSewingEndDate"),
          didClose: () => window.scrollTo(0, 0)

        })
        return false;
      }
      if (sew_start_date > sew_end_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctStartEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      const getVal = getDataproductplan(sew_start_date, sew_end_date);
      sew_array.type_of_production = 'Sew';
      sew_array.start_date = sew_start_date;
      sew_array.end_date = sew_end_date;
      sew_array.prod_data = getVal;
    }
    let pak_array = {};
    if (a == 'pack') {
      let pak_start_date = document.getElementById('pak_start_date').value;
      let pak_end_date = document.getElementById('pak_end_date').value;

      if (pak_start_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("packingStartDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (pak_end_date == '') {
        Swal.fire({
          position: 'top-middle',
          icon: "warning",
          title: t("packingEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      if (pak_start_date > pak_end_date) {
        Swal.fire({
          position: 'top-middle',
          icon: "error",
          title: t("dateMismatch"),
          text: t("correctStartEndDate"),
          didClose: () => window.scrollTo(0, 0)
        })
        return false;
      }
      const getVal = getDataproductplan(pak_start_date, pak_end_date);
      pak_array.type_of_production = 'Pack';
      pak_array.start_date = pak_start_date;
      pak_array.end_date = pak_end_date;
      pak_array.prod_data = getVal;
    }
    // let arrMer = [["cut",cut_array],["sew", sew_array], ["pack",pak_array]];
    if (a != null && a != 'null' && a != 'undefined') {
      // let arrMer = [cut_array,sew_array,pak_array];
      // productionData['prod_datas'] = arrMer;
      var previous_data = {}
      var updated_data = {}
      if (a == 'cut') {
        const artv = cut_array;
        productionData['prod_datas'] = [cut_array];
        previous_data['Production'] = 'Cut';
        previous_data['StartDate'] = pcut_start_date;
        previous_data['EndDate'] = pcut_end_date;
        updated_data['Production'] = 'Cut';
        updated_data['StartDate'] = document.getElementById('cut_start_date').value;
        updated_data['EndDate'] = document.getElementById('cut_end_date').value;

      } else if (a == 'sew') {
        const artv = sew_array;
        productionData['prod_datas'] = [sew_array];
        previous_data['Production'] = 'Sew';
        previous_data['StartDate'] = psew_start_date;
        previous_data['EndDate'] = psew_end_date;
        updated_data['Production'] = 'Sew';
        updated_data['StartDate'] = document.getElementById('sew_start_date').value;
        updated_data['EndDate'] = document.getElementById('sew_end_date').value;
      } else if (a == 'pack') {
        const artv = pak_array;
        productionData['prod_datas'] = [pak_array];
        previous_data['Production'] = 'Pack';
        previous_data['StartDate'] = ppack_start_date;
        previous_data['EndDate'] = ppack_end_date;
        updated_data['Production'] = 'Pack';
        updated_data['StartDate'] = document.getElementById('pak_start_date').value;
        updated_data['EndDate'] = document.getElementById('pak_end_date').value;
      }

      productionData['before_values'] = previous_data;
      productionData['after_values'] = updated_data;
      productionData['user_id'] = getLoginUserId;
      productionData['staff_id'] = getLoginStaffId;
      // productionData['prod_datas'] = [artv];
      /*******************Add-order-Production Api Post********************/
      setCalendarData(productionData['prod_datas']);

      axios
        .post(ServerUrl + "/add-order-production", apiencrypt(productionData))

        .then((response) => {
          response.data = apidecrypt(response.data);
          if (a == 'cut') {
            scrollToTop();
            setCutProductionDta("1");
            setIconWithTab("2");
            setproductionType('sew');

            Swal.fire({
              position: 'top-middle',
              icon: 'success',
              title: t("cuttingData"),
              text: t("cuttingDataAddedSuccess"),
              button: t("okLabel"),
              timer:3000,
              didClose: () => window.scrollTo(0, 0)
            })


          }
          else if (a == 'sew') {
            scrollToTop();
            setCutProductionDta("1");
            setSewProductionDta("1");
            setIconWithTab("3");
            setproductionType('pack');

            Swal.fire({
              position: 'top-middle',
              icon: 'success',
              title: t("sewingData"),
              text: t("sewingDataAddedSuccessfully"),
              button: t("okLabel"),
              timer:3000,
              didClose: () => window.scrollTo(0, 0)
            })


          }
          else if (a == 'pack') {
            Swal.fire({
              position: 'top-middle',
              icon: 'success',
              title: t("packingData"),
              text: t("packingDataAddedSuccessfully"),
              button: t("okLabel"),
              timer:3000,
            })
              .then((result) => {
                {
                  setTimeout(() => {
                    window.location.href = '/orderview?id=' +
                      encode(orderId);
                  }, 100);
                }
                window.location.href = '/orderview?id=' +
                      encode(orderId);
              })

          }
        })
    }

    // if (response.data.status_code === 400) {
    //   Swal.fire({
    //     position: 'top-middle',
    //     icon: "warning",
    //     title: t(response.data.message),
    //   })
    // }
  }
  /*******************Cutting,sewing and packing  save and continue validation ********************/
  const showTab = (a) => {
    if (cutProductionDta == 0 && sew_end_date == "" && (a == 2 || a == 3)) {
      Swal.fire({
        position: 'top-middle',
        icon: 'warning',
        title: t("cuttingData"),
        text: t("saveCuttingData"),
        button: t("okLabel"),
        timer: 2000
      })

    }
    if ((cut_start_date == "" || cut_end_date == "") && a == 1) {
      Swal.fire({
        position: 'top-middle',
        icon: 'warning',
        title: t("cuttingData"),
        text: t("saveCuttingData"),
        button: t("okLabel"),
        timer: 2000
      })
    }

    else if (sewProductionDta == 0 && sew_end_date == "" && a == 2) {
      Swal.fire({
        position: 'top-middle',
        icon: 'warning',
        title: t("cuttingData"),
        text: t("saveCuttingData"),
        button: t("okLabel"),
        timer: 2000
      })
    }
    else if (sewProductionDta == 0 && sew_end_date == "" && a == 3) {
      Swal.fire({
        position: 'top-middle',
        icon: 'warning',
        title: t("sewingData"),
        text: t("saveSewingData"),
        button: t("okLabel"),
        timer: 2000
      })

    } else {
      // setIconWithTab(a)
      setShowCalendar(1);
      if (a == 1) {
        setIconWithTab("1");
        setShowCutData('showCutData1');
        setproductionType('cut');
        cutData('cut')
      } else if (a == 2) {
        setIconWithTab("2");
        setShowCutData('showSewData');
        setproductionType('sew');
        cutData('sew')
      }
      else if (a == 3) {
        setIconWithTab("3");
        setShowCutData('showPackData');
        setproductionType('pack');
        cutData('pack')
      }
    }
  }
  if (HolidayInformation.length == 0) {
    cuttingData = [{
      daysOfWeek: getWeekOff,
      display: "background",
      title: t("weekOff"),
      className: "weekoffcolor",
      overLap: false,
      allDay: true
    }];
  }
  // const dateCount = calculateDateDiffCountFromCurrentDate(prodCuttingDate);
  // console.log("dateCount====>",dateCount)
  if (prodCuttingDate != null) {
    // const dateCountv = calculateDateDiffCountFromCurrentDate(regCuttingDate);
    //setdatecount(dateCountv);
  }
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody>
            <OrderHeaders title="addproductionpan" />
          </CardBody>

          <CardBody>
            <Form className="needs-validation" noValidate="">
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  {/* <H6>SKU Details</H6> */}
                  <Row className="g-12">
                    <div className="table-responsive">
                      {basicInformation.map((information) => (
                        <Table className="shadow shadow-showcase">
                          <thead>
                            <tr className="table-active">
                              <th scope="col">{t('basicInformation')}</th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col"></th>
                              <th scope="col" className="text-right">

                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item">{t('orderNo')} </li>
                                  </ul>
                                  <h6 className="mt-0">{information.order}</h6>
                                </div>
                              </td>
                              <td>
                                <div className="align-self-center media-body">
                                  <ul className="dates d-flex flex-row list-group">
                                    <li className="list-group-item"> {t('styleNo')}</li>
                                  </ul>
                                  <h6 className="mt-0">{information.style}</h6>
                                </div>
                              </td>
                              <td>
                                <img src={userimg} alt={t("users")} />
                                <span>
                                  {" "}
                                  <b>{information.workspace}</b>
                                </span>
                              </td>
                              {information.factory != null ?
                                <td>
                                  <img src={factory} alt={t("factory")} />
                                  <span>
                                    {" "}
                                    <b> {information.factory}</b>
                                  </span>
                                </td>
                                : ""}
                              {information.pcu != null ?
                                <td>
                                  <img src={pcu} alt={t("pcu")} />{" "}
                                  <span>
                                    {" "}
                                    <b> {information.pcu}</b>
                                  </span>
                                </td>
                                : ""}
                              {information.buyer != null ?
                                <td>
                                  <img src={buyer} alt={t("buyer")} />{" "}
                                  <span>
                                    {" "}
                                    <b> {information.buyer}</b>
                                  </span>
                                </td>
                                : ""}
                            </tr>
                          </tbody>
                        </Table>
                      ))}
                    </div>
                  </Row>
                </Col>
                {/*end order details */}
              </Row>
              <Row className="g-12 m-t-20">
                <Col md="12" lg="12" sm="12" xs="12">

                </Col>
              </Row>
              <CardHeader style={{ backgroundColor: "#EDF1F2 " }} 
                          className=" pb-3 d-flex justify-content-between align-items-center b-b-light card-header p- text-left " >
                <span className="p-l-10 p-b-"><h5>{t("productionPlan")}</h5>  </span>
              </CardHeader>
              <Row>
                <Col sm="12" lg="6">
                  {
                    (((getStaff === "Staff" && getStaffPermission.includes('Add/Edit Calendar Configuration')) || getStaff == null))
                      ?
                      <Card className="shadow-showcase">
                        <div className="m-l-10 m-t-10" style={{ backgroundColor: '#fff', marginTop: '0px'}}>    
                              <span className="m-t-10 f-18 f-w-600" > 
                              {t("delDate")}
                              </span>
                              {
                                delDate.length > 0 
                                ?
                                  delDate.map((dataDeliveryDate) => (
                                        dataDeliveryDate.is_delivered == 0 ? 
                                            currentDate > dataDeliveryDate.delivery_date ? 
                                              <span className="btn deliveryBtn text-center"
                                                    style={{ height: '35px', width: '110px',
                                                      borderRadius: '18px', borderColor: '#cccccc',
                                                      marginLeft: '10px', marginTop: '12px',
                                                      marginBottom: '10px', fontSize: '12px',
                                                      color: '#EE335E', fontWeight: 600, cursor: 'unset'
                                                    }}>
                                                {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                              </span> 
                                            : 
                                              <span className="btn deliveryBtn text-center"
                                                    style={{ height: '35px', width: '110px',
                                                      borderRadius: '18px', borderColor: '#cccccc',
                                                      marginLeft: '10px', marginTop: '12px',
                                                      marginBottom: '10px', fontSize: '12px',
                                                      color: '#878787', fontWeight: 600, cursor: 'unset'
                                                    }}>
                                                {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                              </span>
                                          : 
                                          dataDeliveryDate.updated_date > dataDeliveryDate.delivery_date ? 
                                              <span className="btn deliveryBtn text-center"
                                                    style={{ height: '35px', width: '110px',
                                                      borderRadius: '18px', borderColor: '#cccccc',
                                                      marginLeft: '10px', marginTop: '12px',
                                                      marginBottom: '10px', fontSize: '12px',
                                                      color: '#E69020', fontWeight: 600, cursor: 'unset'
                                                    }}>
                                              {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                              </span> 
                                            : 
                                              <span className="btn deliveryBtn text-center"
                                                    style={{ height: '35px', width: '110px',
                                                      borderRadius: '18px', borderColor: '#cccccc',
                                                      marginLeft: '10px', marginTop: '12px',
                                                      marginBottom: '10px', fontSize: '12px',
                                                      color: '#009688', fontWeight: 600, cursor: 'unset'
                                                    }}>
                                                {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate.delivery_date))}
                                              </span>
                                  ))
                                  :
                                  "-"
                              } 
                            </div>
                        <div className=" p-l-7  border-8  d-flex border-4  justify-content-between align-items-center   p-text-left m-t-40 " >
                          <span className="p-l-10" >{t('weekOff')}</span>
                        </div>

                        <CardBody>
                          <p style={{ minHeight: "50px" }}>
                            <div>



                              {getWeekOff.includes(0) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger'
                                    onClick={(e) => onWeekoffChecked(0)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover', id: 'Popover-0' }}>
                                    {t('sunDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button
                                    onClick={(e) => onWeekoffChecked(0)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-0' }}>
                                    {t('sunDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(1) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger'
                                    onClick={(e) => onWeekoffChecked(1)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-1' }}>
                                    {t('monDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(1)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-1' }}>
                                    {t('monDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(2) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger' onClick={(e) => onWeekoffChecked(2)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-2' }}>
                                    {t('tuesDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(2)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-2' }}>
                                    {t('tuesDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(3) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger' onClick={(e) => onWeekoffChecked(3)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-3' }}>
                                    {t('wedDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(3)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-3' }}>
                                    {t('wedDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(4) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger' onClick={(e) => onWeekoffChecked(4)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-4' }}>
                                    {t('thursDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(4)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-4' }}>
                                    {t('thursDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(5) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger' onClick={(e) => onWeekoffChecked(5)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-5' }}>
                                    {t('friDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(5)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day ', id: 'Popover-5' }}>
                                    {t('friDay')}
                                  </Button>
                                </span>
                              }
                              {getWeekOff.includes(6) ?
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button className='btn-danger' onClick={(e) => onWeekoffChecked(6)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-6' }}>
                                    {t('satDay')}
                                  </Button>
                                </span>
                                :
                                <span className="m-r-5 m-b-20 inline-block">
                                  <Button onClick={(e) => onWeekoffChecked(6)}
                                    disabled={IconWithTab == 1 ? false : true}
                                    attrbtn={{ color: 'primary', className: 'example-popover weekoff-day ', id: 'Popover-6' }}>
                                    {t('satDay')}
                                  </Button>
                                </span>
                              }
                            </div>
                            <div className="clearfix"></div>
                            <span className="m-r-15 font-danger">{t("clickHoliday")}</span>
                          </p>
                        </CardBody>
                      </Card>
                      :
                      ""
                  }

                  {(getStaff === "Staff" && getStaffPermission.includes('View Calendar Configuration')
                    && !getStaffPermission.includes('Add/Edit Calendar Configuration')
                  )
                    ?
                    <Card className="shadow-showcase">
                      <div className=" p-l-7  border-8  d-flex border-4  justify-content-between align-items-center   p-text-left m-t-40 " >
                        <span className="p-l-10" >{t('weekOff')}</span>
                      </div>

                      <CardBody>
                        <p style={{ minHeight: "50px" }}>

                          <div>
                            {getWeekOff.includes(0) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger'
                                  onClick={(e) => onWeekoffChecked(0)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover', id: 'Popover-0' }}>
                                  {t('sunDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button
                                  onClick={(e) => onWeekoffChecked(0)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-0' }}>
                                  {t('sunDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(1) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger'
                                  onClick={(e) => onWeekoffChecked(1)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-1' }}>
                                  {t('monDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(1)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-1' }}>
                                  {t('monDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(2) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger' onClick={(e) => onWeekoffChecked(2)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-2' }}>
                                  {t('tuesDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(2)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-2' }}>
                                  {t('tuesDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(3) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger' onClick={(e) => onWeekoffChecked(3)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-3' }}>
                                  {t('wedDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(3)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-3' }}>
                                  {t('wedDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(4) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger' onClick={(e) => onWeekoffChecked(4)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-4' }}>
                                  {t('thursDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(4)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-4' }}>
                                  {t('thursDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(5) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger' onClick={(e) => onWeekoffChecked(5)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-5' }}>
                                  {t('friDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(5)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day ', id: 'Popover-5' }}>
                                  {t('friDay')}
                                </Button>
                              </span>
                            }
                            {getWeekOff.includes(6) ?
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button className='btn-danger' onClick={(e) => onWeekoffChecked(6)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day', id: 'Popover-6' }}>
                                  {t('satDay')}
                                </Button>
                              </span>
                              :
                              <span className="m-r-5 m-b-20 inline-block">
                                <Button onClick={(e) => onWeekoffChecked(6)}
                                  disabled
                                  attrbtn={{ color: 'primary', className: 'example-popover weekoff-day ', id: 'Popover-6' }}>
                                  {t('satDay')}
                                </Button>
                              </span>
                            }
                          </div>
                          <div className="clearfix"></div>
                          <span className="m-r-15 font-danger">{t("clickHoliday")}</span>
                        </p>
                      </CardBody>
                    </Card>
                    :
                    ""
                  }


                  <Card>
                    <div className="table-responsive">
                      <Table border >
                        <thead >
                          <tr style={{ backgroundColor: "#F6F6F6" }} className="lightGrayBgColor">
                            <th ><b></b></th>
                            <th ><b>{t("startDate")}</b></th>
                            <th ><b>{t("endDate")}</b></th>
                            <th className='text-center' scope="col">
                              <b></b></th>
                          </tr>
                        </thead>

                        <tbody  >
                          <tr >
                            <td style={{ backgroundColor: "#F6F6F6" }} > <b><img src={greycutting} /> <span className="prodtyphd"> {t('cutting')} </span></b> </td>
                            <td ><div className="input-group">
                              <span className="input-group-text">
                                <i className="icon-calendar"></i>
                              </span>
                              <Col xl="10" sm="10">
                                <Input
                                  type="date"
                                  disabled={productionType == 'cut' ? false : true}
                                  id="cut_start_date"
                                  onkeydown="return false"
                                  min={new Date().toISOString().split('T')[0]}
                                  defaultValue={cut_start_date}
                                  onChange={(e) => {
                                    setShowCalendar(1);
                                    cutData('cut');
                                    document.getElementById("cut_end_date").setAttribute("min", e.target.value),
                                      document.getElementById("sew_start_date").setAttribute("min", e.target.value)
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                />
                              </Col>
                            </div></td>
                            <td>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                                <Col xl="10" sm="10">
                                  <Input
                                    type="date"
                                    disabled={productionType == 'cut' ? false : true}
                                    id="cut_end_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    defaultValue={cut_end_date}
                                    onChange={() => {
                                      setShowCalendar(1);
                                      cutData('cut');
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Col>
                              </div>
                            </td>
                            <td colSpan='50' className='text-center' > </td>
                          </tr>
                          <tr >
                            <td style={{ backgroundColor: "#F6F6F6" }} > <img src={greySewing} /><span className="prodtyphd"> {t('sewing')}</span> </td>
                            <td>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                                <Col xl="10" sm="10">
                                  <Input
                                    type="date"
                                    disabled={productionType == 'sew' ? false : true}
                                    id="sew_start_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    defaultValue={sew_start_date}
                                    onChange={(e) => {
                                      setShowSewCalendar(1);
                                      cutData('sew');
                                      document.getElementById("sew_end_date").setAttribute("min", e.target.value),
                                        document.getElementById("pak_start_date").setAttribute("min", e.target.value)
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Col>
                              </div>
                            </td>
                            <td>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                                <Col xl="10" sm="10">
                                  <Input
                                    type="date"
                                    disabled={productionType == 'sew' ? false : true}
                                    id="sew_end_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    defaultValue={sew_end_date}
                                    onChange={() => {
                                      setShowSewCalendar(1);
                                      cutData('sew');
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  // onChange={capturesewData}
                                  />
                                </Col>
                              </div>
                            </td>
                            <td colSpan='50' className='text-center' > </td>
                          </tr>
                          <tr >
                            <td style={{ backgroundColor: "#F6F6F6" }} > <b> <img src={greyPacking} /><span className="prodtyphd"> {t('packing')}</span></b> </td>
                            <td>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                                <Col xl="10" sm="10">
                                  <Input
                                    type="date"
                                    disabled={productionType == 'pack' ? false : true}
                                    id="pak_start_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    defaultValue={pack_start_date}
                                    onChange={(e) => {
                                      setShowPackCalendar(1);
                                      cutData('pack');
                                      document.getElementById("pak_end_date").setAttribute("min", e.target.value);
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Col>
                              </div></td>
                            <td>
                              <div className="input-group">
                                <span className="input-group-text">
                                  <i className="icon-calendar"></i>
                                </span>
                                <Col xl="10" sm="10">
                                  <Input
                                    type="date"
                                    disabled={productionType == 'pack' ? false : true}
                                    id="pak_end_date"
                                    min={new Date().toISOString().split('T')[0]}
                                    defaultValue={pack_end_date}
                                    onChange={() => {
                                      setShowPackCalendar(1);
                                      cutData('pack');
                                    }}
                                    onKeyDown={(e) => {
                                      e.preventDefault();
                                    }}
                                  />
                                </Col>
                              </div></td>
                            <td colSpan='50' className='text-center' > </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                </Col>
                <Col sm="12" lg="6">
                  <Card>
                    <CardBody>
                      <Row className="g-12 m-t-20">
                        <Col md="12" lg="12" sm="12">
                          <Row className="g-12">
                            <Col md="12" lg="12" sm="12" xs="12" >
                              <Card className="shadow shadow-showcase">
                                <CardBody>
                                  <Nav tabs className="text-center d-flex justify-content-center" >
                                    <NavItem className="text-center">
                                      <NavLink
                                        href="javascript:void(0)"
                                        style={{ width: "210px" }}
                                        className={
                                          IconWithTab === "1" ? "active" : ""
                                        }
                                        onClick={() => {
                                          showTab(1)
                                        }}
                                      >
                                        <img src={cutting} /><span className="prodtyphd"> {t('cutting')}</span>  <br></br>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="text-center">
                                      <NavLink
                                        href="javascript:void(0)"
                                        style={{ width: "210px" }}
                                        className={
                                          IconWithTab === "2" ? "active" : ""
                                        }
                                        onClick={() => {
                                          showTab(2)

                                        }}
                                      >
                                        <img src={Sewing} /><span className="prodtyphd"> {t('sewing')} </span><br></br>
                                      </NavLink>
                                    </NavItem>
                                    <NavItem className="text-center">
                                      <NavLink
                                        href="javascript:void(0)"
                                        style={{ width: "210px" }}
                                        className={
                                          IconWithTab === "3" ? "active" : ""
                                        }
                                        onClick={() => {
                                          showTab(3)

                                        }}
                                      >
                                        <img src={Packing} /> <span className="prodtyphd">  {t('packing')} </span><br></br>
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  {(showCalendar === 1 && showCutData != undefined && productionType == 'cut' && cut_start_date != null) ?
                                    <Row className="g-12 m-t-20">
                                      <Col md="12" lg="12" sm="12" >
                                        <div className="demo-app-calendar " id="mycalendar-cut">
                                          {showCutData.map((cutDatas, i) => {
                                            if (cutDatas.holiday_flag == 0) {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day',
                                              };
                                            } else {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day_list',
                                                url: (cutDatas.week_of_flag == 1) ? "WEEKOFF" : ""
                                              };
                                            } i++;
                                            if (getWeekOff) {
                                              cuttingData[i] =
                                              {
                                                daysOfWeek: getWeekOff,
                                                display: "background",
                                                title: "",
                                                className: "weekoffcolor",
                                                overLap: false,
                                                allDay: true
                                              }
                                            }
                                          })}
                                          <FullCalendar
                                            defaultView="dayGridMonth"
                                            header={{
                                              left: 'prev,next day',
                                              center: 'title',
                                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                            }}
                                            locale={selectedLang}
                                            rerenderDelay={10}
                                            eventDurationEditable={false}
                                            selectable={true}
                                            droppable={false}
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            weekends={state.calendarWeekend}
                                            events={cuttingData}
                                            disabled={datecount > 0 ? false : true}
                                            eventClick={function (info) {
                                              if (info.event.url == 'WEEKOFF')
                                                info.jsEvent.preventDefault();

                                              else {
                                                if (((getStaff === "Staff" && getStaffPermission.includes('Add/Edit Calendar Configuration')) || getStaff == null)) {
                                                  eventClick(info);
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row> : ""
                                  }
                                  {(showSewCalendar === 1 && showSewData != undefined && productionType == 'sew' && sew_start_date != null) ?
                                    <Row className="g-12 m-t-20">
                                      <Col md="12" lg="12" sm="12" >
                                        <div className="demo-app-calendar " id="mycalendar-sew">
                                          {showSewData.map((cutDatas, i) => {
                                            if (cutDatas.holiday_flag == 0) {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day',
                                                url: ((cutDatas.date <= new Date(cut_end_date))) ? "WEEKOFF" : ""
                                              };
                                            } else {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day_list',
                                                url: (cutDatas.week_of_flag == 1 || (cutDatas.date <= new Date(cut_end_date))) ? "WEEKOFF" : ""
                                              };
                                            } i++;
                                            if (getWeekOff) {
                                              cuttingData[i] =
                                              {
                                                daysOfWeek: getWeekOff,
                                                display: "background",
                                                title: "",
                                                className: "weekoffcolor",
                                                overLap: false,
                                                allDay: true
                                              }
                                            }
                                          })}
                                          <FullCalendar
                                            defaultView="dayGridMonth"
                                            header={{
                                              left: 'prev,next day',
                                              center: 'title',
                                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                            }}
                                            locale={selectedLang}
                                            rerenderDelay={10}
                                            eventDurationEditable={false}
                                            selectable={true}
                                            droppable={false}
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            weekends={state.calendarWeekend}
                                            events={cuttingData}
                                            eventClick={function (info) {
                                              if (info.event.url == 'WEEKOFF')
                                                info.jsEvent.preventDefault();
                                              else {
                                                eventClick(info);
                                              }
                                            }}
                                          />
                                        </div>
                                      </Col>
                                    </Row> : ""
                                  }
                                  {(showPackCalendar === 1 && showPackData != undefined && productionType == 'pack' && pack_start_date != null) ?
                                    <Row className="g-12 m-t-20">
                                      <Col md="12" lg="12" sm="12" >
                                        <div className="demo-app-calendar " id="mycalendar-pack">
                                          {showPackData.map((cutDatas, i) => {
                                            if (cutDatas.holiday_flag == 0) {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day',
                                                url: ((cutDatas.date <= new Date(cut_end_date)) || (cutDatas.date <= new Date(sew_end_date))) ? "WEEKOFF" : ""
                                              };
                                            } else {
                                              cuttingData[i] =
                                              {
                                                id: i + '||' + cutDatas.holiday_flag + '||' + cutDatas.holiday_id,
                                                start: cutDatas.date,
                                                end: cutDatas.date,
                                                title: cutDatas.target_value,
                                                className: 'holi_day_list',
                                                url: (cutDatas.week_of_flag == 1 || (cutDatas.date <= new Date(cut_end_date)) || (cutDatas.date <= new Date(sew_end_date))) ? "WEEKOFF" : ""
                                              };
                                            } i++;
                                            if (getWeekOff) {
                                              cuttingData[i] =
                                              {
                                                daysOfWeek: getWeekOff,
                                                display: "background",
                                                title: "",
                                                className: "weekoffcolor",
                                                overLap: false,
                                                allDay: true
                                              }
                                            }
                                          })}
                                          <FullCalendar
                                            defaultView="dayGridMonth"
                                            header={{
                                              left: 'prev,next day',
                                              center: 'title',
                                              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                            }}
                                            locale={selectedLang}
                                            rerenderDelay={10}
                                            eventDurationEditable={false}
                                            selectable={true}
                                            droppable={false}
                                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                            weekends={state.calendarWeekend}
                                            events={cuttingData}
                                            // eventClick={eventClick}
                                            eventClick={function (info) {
                                              if (info.event.url == 'WEEKOFF')
                                                info.jsEvent.preventDefault();
                                              else {
                                                eventClick(info);
                                              }
                                            }}
                                          />
                                        </div>
                                        <div>
                                        </div>
                                      </Col>
                                    </Row> : ""
                                  }
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
            <Row className="g-12">
              <Col>
                <FormGroup><br></br>
                  <Button style={{ float: "Right" }}
                    onClick={(e) => getProductionChecked(productionType)}
                    disabled={((productionType == 'cut' && showCutData.length != 0) || (productionType == 'sew' && showSewData.length != 0) || (productionType == 'pack' && showPackData.length != 0)) && (datecount > 0) ? false : true}> {t("saveAndContinue")} </Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default ProductionPlan;

//**************************Code by : Praveen****************************//