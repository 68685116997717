import {
  Container, Row, Col, CardBody, Card, CardHeader, FormGroup, Label
} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6, UL, LI, Image } from "../../AbstractElements";
import { Link } from "react-router-dom";
import axios from "axios";
import { ServerUrl } from '../../Constant/index';
import companylogo from "../../assets/images/dms/icons/profile.png";
import Moment from 'moment';
import { getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission}
  from '../../Constant/LoginConstant';
// import edit from '../../assets/images/dms/icons/CompanyProEditIcon.svg';
import edit from'../../assets/images/dms/newprofileedit.svg';
// import edit from "../../assets/images/dms/editPencil.svg"
//import Knob from 'knob';
// import KnobChartClass from "./KnobChartClass";
import KnobChartClass1 from "./KnobChartClass1";
import KnobChartClass3 from "./KnobChartClass3";
import KnobChartClass2 from "./KnobChartClass2";
import KnobChartClass4 from "./KnobChartClass4";
import { apiencrypt, apidecrypt, PHPtoJSFormatConversion } from "../../helper";
import { useTranslation } from 'react-i18next';
import { Settings } from 'react-feather';
import ReportHeaderLogo from "./ReportHeader";
// import ConfigDB from '../../../Config/ThemeConfig';

// const primary = localStorage.getItem('default_color') || ConfigDB.data.color.primary_color;

const CompanyProfile = () => {
  const [logoURL, setLogo] = useState('');
  const [workspaceCount, setworkspaceCount] = useState('');
  const [getcompanyProfileData, setcompanyProfileData] = useState([]);
  const [staffCount, setstaffCount] = useState('');
  const [styleCount, setstyleCount] = useState('');
  const [orderCount, setorderCount] = useState('');
  const [country, setcountry] = useState('');

  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);

  const [ useLogo, setUseLogo ] = useState('');
  const [ selectedCompanyLogo, setSelectedCompanyLogo ] = useState(false);
  const [ selectedDmsLogo, setSelectedDmsLogo ] = useState(false);

  const reqData = { company_id, workspace_id };
  const { t } = useTranslation();
  
  const [modalReportHeaderLogo, setModalReportHeaderLogo] = useState(false);
  const toggleReportHeaderLogo = () => setModalReportHeaderLogo(!modalReportHeaderLogo);

  useEffect(() => {
    axios
      .post(ServerUrl + "/get-company-settings", apiencrypt(reqData))
      .then(async (response) => {
        response.data = apidecrypt(response.data)
        setcompanyProfileData(response.data.data.CompanyDetails)
        // let base64 = await getBase64Image(response.data.data.logoURL)
        setLogo( response.data.data.logoURL != "" ? await getBase64Image(response.data.data.logoURL) : response.data.data.logoURL)
        setworkspaceCount(response.data.data.workspaceCount)
        setstaffCount(response.data.data.staffCount)
        setstyleCount(response.data.data.styleCount)
        setorderCount(response.data.data.orderCount)
        setcountry(response.data.data.countryName)

        if( response.data.data.CompanyDetails.use_logo == 0 ){
            setSelectedDmsLogo(true)
            setSelectedCompanyLogo(false)
        } else{
            setSelectedDmsLogo(false)
            setSelectedCompanyLogo(true)
        }
      });
      {
        (((getStaff === "Staff" && getStaffPermission.includes('company-profile'))||getStaff == null )) 
        ?
        ""
        :
        window.location.href = "/dashboard" 
      }  
  }, [])
  /* Fetch Image and convert it to base64 */
  const getBase64Image= async(img)=> {
    const base64 = await fetch(img,{
      method: "GET",
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin':'*',
      }
    })
    .then(async (response) => {
      let blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {   
        reader.onloadend = () => {
        res(reader.result);
      }})
    })
    return await base64;
  }
  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t("companyProfile")} parent={t("companyProfile")} title={t("companyProfile")} />
      </Row>

      <Container fluid={true} className="general-widget topaln ">
        <Row >
          <Col sm="12" md="12" lg="12" xl="8" xs="12" >
            <Card className="shadow-sm shadow-showcase">
              {/* <CardHeader className=""> */}
              <div  className="m-10 p-r-15">
                {/* <H5> */}
                <span  style={{ float: "Right" }}><Link to={`${process.env.PUBLIC_URL}/editcompany`}> &nbsp; 
                 <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")}  /></Link></span>
                  {/* <span className="p-l-10"><Link  className="btn-pill btn btn-outline-primary btn-md "
                          to={`${process.env.PUBLIC_URL}/editcompany`}>
                        <i className="icofont icofont-ui-edit "></i> 
                        <span className="p-l-5">{t("edit")}</span> </Link>
                  </span> */}
                 {/* </H5> */}
                {/* <hr className="mt-12 mb-12 " /> */}
                 </div>
              {/* </CardHeader> */}

              <CardBody>
                <Card className="shadow shadow-showcase" style={{ marginTop:"-20px" }}>
                <span className="p-l-10 editcompanyinfo">
                    <Label className="skudetailsclr p-1 " name="logo">
                  <span className="p-r-5"></span>{t("companyLogo")}<span className="p-r-5"></span>
                    </Label>
                  </span>
                  <Row className="m-t-10 p-3 align-items-end">
                    <Col sm="12" md="12" lg="6" xs="12" xl="6">
                      <FormGroup>
                        <div className="media">
                          {logoURL ? 
                            <>
                              <img  src={logoURL} width="150px"/>
                            </>
                            : <img  src={companylogo} width="150px" />}
                        </div>
                        <br></br>
                      </FormGroup>
                    </Col>
                    <Col className="f-right" style={{ bottom: '0px' }}>
                      <Row className="align-items-end">
                        <Col className="f-right">
                          <span  
                            style={{ cursor: 'pointer' }}
                            className="f-right m-r-10 m-t-5" 
                            onClick={() => {setModalReportHeaderLogo(!modalReportHeaderLogo)}}> 
                           { t("changeLogoReport")}
                          </span>
                          <span
                            className="f-right m-r-10"
                            title={t("changeLogo")} 
                            onClick={() => {setModalReportHeaderLogo(!modalReportHeaderLogo)}}>
                            <a   className="setting-primary f-right" href="#javascript">
                              <Settings />
                            </a>
                          </span>
                      
                        </Col>
                        {/* <Col 
                          className="m-b-5" 
                          onClick={() => 
                          { setModalReportHeaderLogo(!modalReportHeaderLogo)}}>
                          <span 
                            className="f-left m-r-10"
                            style={{ cursor: 'pointer' }}>
                           { t("changeLogoReport")}
                          </span>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row> 
                </Card>
                <Card className="shadow shadow-showcase">
                <span className="p-l-10 editcompanyinfo">
                  <Label className="skudetailsclr p-1 " name="logo">
                <span className="p-r-5"></span> {t("companyInfo")}<span className="p-r-5"></span>
                  </Label>
            </span>
            <Row className="m-t-10 p-3">

                <Row>
                  {/* <Col sm="12"> <H6 attrH6={{ className: 'pb-2' }}>{t("companyInfo")}</H6></Col> */}
                  <Col sm="6">
                    <FormGroup id="contact_person" className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>
                        {t("companyName")}{" "}
                      </label>
                      <br />
                      <b>{getcompanyProfileData.company_name}</b>
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>
                        {t("userType")}{" "}
                      </label>

                      <br />
                      <b>{getcompanyProfileData.Type == "PCU"?"BH":getcompanyProfileData.Type}</b>
                    </FormGroup>

                  </Col>
                  <Col sm="6">
                    <FormGroup id="contact_person" className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>
                        {t("contactPerson")}{" "}
                      </label>
                      <br />
                      <b>{getcompanyProfileData.contact_person}</b>
                    </FormGroup>
                  </Col>

                  <Col sm="6">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>
                        {t("contactNumber")}{" "}
                      </label>

                      <br />
                      <b>{getcompanyProfileData.contact_number}</b>
                    </FormGroup>

                  </Col>
                  <Col sm="6">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>
                        {t("email")}{" "}
                      </label>

                      <br />
                      <b>{getcompanyProfileData.Email}</b>
                    </FormGroup>

                  </Col>
                </Row>
                </Row>
                </Card>
                <Card className="shadow shadow-showcase m-t-5">
                                            <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1" name="logo">
                                                <span className="p-r-5"></span> {t("addressInfo")}<span className="p-r-5"></span>
                                                </Label>
                                            </span>
                <Row className="m-t-10 p-3">
                  
                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleFormControlInput1" style={{ color:"#3F4254" }}>{t("addressline1")}</label>
                      <br />
                      <b>{getcompanyProfileData.address1 ? getcompanyProfileData.address1 : "-"}</b>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleFormControlInput1" style={{ color:"#3F4254" }}>  {t("addressline2")}</label>
                      <br />
                      <b>{getcompanyProfileData.address2 ? getcompanyProfileData.address2 : "-"}</b>
                    </FormGroup>
                  </Col>

                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>{t("city")}</label>

                      <br />
                      <b>{getcompanyProfileData.city ? getcompanyProfileData.city : "-"}</b>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}> {t("statePrefecture")} </label>

                      <br />
                      <b>{getcompanyProfileData.state ? getcompanyProfileData.state : "-"}</b>
                    </FormGroup>
                  </Col>

                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}>{t("countryName")}</label>
                      <br />
                      <b>{country ? country : "-"}</b>
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="12" lg="6" xs="12">
                    <FormGroup className="formcmpname">
                      <label htmlFor="exampleInputPassword2" style={{ color:"#3F4254" }}> {t("postalCode")} </label>
                      <br />
                      <b>{getcompanyProfileData.zipcode ? getcompanyProfileData.zipcode : "-"}</b>
                    </FormGroup>
                  </Col>
                </Row>
                </Card>
              </CardBody>
            </Card>
          </Col>
          <Col sm="12" md="12" lg="12" xl="4" xs="12">
            <Card className="shadow shadow-showcase" style={{border:"none" }}>
              <Col sm="12" md="12" lg="12" xs="12" >
                {/* <CardHeader className="pb-0 "> */}
                <div className="m-10">
                  <H5>&nbsp;&nbsp;&nbsp;{t("planDetails")}</H5>
                  <hr className="mt-12 mb-12" />
                  </div>
                {/* </CardHeader> */}
              </Col>
            <Card className="shadow shadow-showcase border_company_profile m-l-15 m-r-15">
              <CardBody>
                <div className="media p-l-5 ">
                    <div className="media-body">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard`}><H6 className="f-12 labeltexthdp">{getcompanyProfileData.purchased_plan_name} :   ${getcompanyProfileData.purchased_plan_price}</H6></Link>
                        <p style={{color:"#9D9D9D",fontSize:"14px"}}>{t("expiresOn")} {PHPtoJSFormatConversion.format(new Date(getcompanyProfileData.ExpiryDate))}</p>
                        {/* <UL attrUL={{ className: 'rating-star d-flex flex-row' }} >
                            <LI><i className="fa fa-star"></i></LI>
                            <LI><i className="fa fa-star"></i></LI>
                            <LI><i className="fa fa-star"></i></LI>
                            <LI><i className="fa fa-star"></i></LI>
                            <LI><i className="fa fa-star"></i></LI>
                        </UL> */}
                    </div>
                    <Link className="btn-pill btn btn-outline-primary  btn-xs "
                    to="/editpricing" >{t("upgrade")} </Link>
                </div>
            </CardBody>
            </Card>
            <Card className="shadow shadow-showcase border_company_profile m-l-15 m-r-15" style={{ marginTop:"-10px" }}>
              <CardBody className="">
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <div className="media p-t-10">
                        <div className="media-body">
                          <span className="labeltexthd"> {styleCount}/{getcompanyProfileData.no_of_style} {t("styles")} </span>
                          <p className="f-12 labeltexthdp" style={{color:"#9D9D9D",fontSize:"14px"}}>{t("numberOfStyles")}</p>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {styleCount && getcompanyProfileData.no_of_style ?
                      <KnobChartClass2 numerator={styleCount} denominator={getcompanyProfileData.no_of_style} /> : ""}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow shadow-showcase border_company_profile m-l-15 m-r-15" style={{ marginTop:"-10px" }}>
              <CardBody className="">
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <div className="media p-t-10">
                        <div className="media-body">
                          <span className="labeltexthd">{staffCount}/{getcompanyProfileData.no_of_user} {t("users")}</span>
                          <p className="f-12 labeltexthdp" style={{color:"#9D9D9D",fontSize:"14px"}}>{t("numberOfUsers")}</p>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {staffCount && getcompanyProfileData.no_of_user ?
                      <KnobChartClass3 numerator={staffCount} denominator={getcompanyProfileData.no_of_user} /> : ""}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="shadow shadow-showcase border_company_profile m-l-15 m-r-15" style={{ marginTop:"-10px" }}>
              <CardBody className="">
                <Row>
                  <Col md="8">
                    <FormGroup>
                      <div className="media p-t-10">
                        <div className="media-body">
                          <span className="labeltexthd"> {workspaceCount}/{getcompanyProfileData.no_of_workspace}  {t("workSpace")}</span>
                          <p className="f-12 labeltexthdp" style={{color:"#9D9D9D",fontSize:"14px"}}>{t("numberOfWorkspace")}</p>
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    {workspaceCount && getcompanyProfileData.no_of_workspace ?
                      <KnobChartClass4 numerator={workspaceCount} denominator={getcompanyProfileData.no_of_workspace} /> : ""}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            </Card>
          </Col>
        </Row>
      </Container>
      <ReportHeaderLogo
				  modal={modalReportHeaderLogo}
				  setModalReportHeaderLogo={setModalReportHeaderLogo}
				  toggle={toggleReportHeaderLogo}
          selectedCompanyLogo={selectedCompanyLogo}
          setSelectedCompanyLogo={setSelectedCompanyLogo}
          selectedDmsLogo={selectedDmsLogo}
          setSelectedDmsLogo={setSelectedDmsLogo}
          useLogo={useLogo}
          logoURL={logoURL}
			  />
    </Fragment>
  );
};
export default CompanyProfile;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/
