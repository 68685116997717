import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, CardHeader, Collapse, FormGroup, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff,getStaffPermission, getLoginStaffId,getModules } from "../../../Constant/LoginConstant";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../../Constant";
import FilterIcon from "../../../assets/images/dms/icons/FilterIconDms.svg";
import noData from "../../../assets/images/dms/nodata.png"
import DownloadIcon from "../../../assets/images/dms/icons/DowloadIconDms.svg";
import { DownloadFile, apiencrypt, apidecrypt, namesOfFacOrBuyer } from "../../../helper";
import TaskReportAccordion from "./TaskReportAccordion";
import FilterOffCanvas from "./FilterOffCanvas";
import { useTranslation } from 'react-i18next';
const TaskReports = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pcu, setPcu] = useState("");
    const [statusFilter, setStatusFilter] = useState("");
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
    const [factories, setFactories] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [details, setDetails] = useState([]);
    const [statusOfFilter, setStatusOfFilter] = useState("");
    const [filterAStartDate, setfilterAStartDate] = useState("");
    const [filterAEndDate, setfilterAEndDate] = useState("");
    const [filterPic, setFilterPic] = useState(0);
    const [filterStyleNo, setFilterStyleNo] = useState(0);

    const { t } = useTranslation();
    let detailsToSend;
    getStaff === "Staff" ?  detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
    } 
    const dataToSendAtStarting = detailsToSend;
    const [PIC, setPIC] = useState([]);
    const [toOpen , setToOpen] = useState(0);
    const [filterOffCanvas , setFilterOffCanvas] = useState(false);
    const toggleFilterCanvas = () => setFilterOffCanvas(!filterOffCanvas);
    useEffect(() => {

        {
            (((getStaff === "Staff" &&getModules.includes("Reports")&&getStaffPermission.includes('View Report'))||getStaff == null )) 
            ?
            ""
            :
            window.location.href = "/dashboard" 
          } 
          
        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });   
        axios
            .post(ServerUrl + "/get-staffs", apiencrypt(dataToSendAtStarting))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPIC(response.data.data);
            });    
    }, []);
/***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
  const filterStyle = (type,id)=>{
    if(type ==="Factory"){
      axios.post(ServerUrl + "/get-filters", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        factory_id : id,
        type : getWorkspaceType,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        if(getWorkspaceType === "Buyer"){
            setPcus(response.data.data);
        }
        else if(getWorkspaceType === "PCU"){
            setBuyers(response.data.data);
        }  
      });
    }
    if(type ==="Buyer"){
      axios.post(ServerUrl + "/get-filters", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        buyer_id : id,
        type : getWorkspaceType,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        setPcus(response.data.data);
      });
    }
    // if(type ==="PCU"){
    //   axios.post(ServerUrl + "/get-ordersList", {
    //     company_id: company_id,
    //     workspace_id: workspace_id,
    //     pcu_id : id,
    //     staff_id : getLoginStaffId,
    //   }).then((response) => {
    //       setOrders(response.data.data);
    //   });
    // }
  } 
    /*Order Validation  Start*/
const orderValidation = (data) => {
    let errors={};
    let facID = 0;
    let buyerID = 0;
    let pcuID = 0;
    if(getWorkspaceType != "Factory"){
        facID = document.getElementById("facID").value;
    }
    if(getWorkspaceType != "Buyer"){
        buyerID = document.getElementById("buyerID").value;
    }
    // if(getWorkspaceType != "PCU"){
    //     pcuID = document.getElementById("pcuID").value;
    // }
    if (getWorkspaceType!='Factory' && facID == 0) {
        errors.factory = ('factoryValidationText');
    }
    if (getWorkspaceType!='Buyer' && buyerID == 0) {
        errors.buyer = ('buyerValidationText');
    }
    // if (getWorkspaceType!='PCU' && pcuID == 0) {
    //     errors.pcu = ('pcuValidationText');
    // }
    if (!statusOfFilter) {
      errors.statusOfFilter = ('pleaseSelectaStatus');
    }
  
   setValidErrors(errors);
   return errors;
  };

/*********************** To Download The PDF ********************/
const toDownloadAsPdf = () => {
    let dataToSendForOrder ;
    getStaff === "Staff" ? dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id: getLoginStaffId,
        }: dataToSendForOrder = {
            company_id: company_id,
            workspace_id: workspace_id,
            user_id: getLoginUserId,
            };
    if (getWorkspaceType === "Buyer"){
        dataToSendForOrder.factory_id = document.getElementById("facID").value;
        dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
    }
    else if (getWorkspaceType === "PCU"){
        dataToSendForOrder.factory_id = document.getElementById("facID").value;
        dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
    }
    else if (getWorkspaceType === "Factory"){
        dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
        dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
    }
    dataToSendForOrder.statusFilter = document.getElementById("statusFilter").value;
    /* Advanced Filter  Starts*/
    // let startDates = document.getElementById("aStartDate").value;
    // let endDates = document.getElementById("aEndDate").value;
    // let picId = document.getElementById("pic").value;
    // let style = document.getElementById("styleNo").value;
    if(filterPic !=""){
        dataToSendForOrder.pic_id = filterPic;
    }
    if(filterStyleNo != ""){
        dataToSendForOrder.styleNo = filterStyleNo;       
    }
    if((statusFilter === "Completed" || statusFilter=== "DelCompletion" 
    || statusFilter=== "All"|| statusFilter=== "Rescheduled")&&(filterAStartDate != "" && filterAEndDate != "")){
        dataToSendForOrder.aStartDate = filterAStartDate;
        dataToSendForOrder.aEndDate = filterAEndDate;
    }
    /* Advanced Filter Ends */
    let statusFilters = document.getElementById("statusFilter").value;
    axios.post(ServerUrl + "/download-tasks-report", apiencrypt(dataToSendForOrder),{responseType: 'blob'})
    .then((response)=>{
        let name;
        if(filterStyleNo != ""){
            for(let i=0; i<orders.length; i++){
                if(filterStyleNo == orders[i].id)
                    name = orders[i].style_no;
            }
        }else{
            name = namesOfFacOrBuyer(factories,factory,buyers,buyer);
        }
        let fileName = "TaskReport-"+name+"-"+statusFilters+"-"+Date.now()+".pdf";
        // let fileName = Date.now()+"-"+statusFilters+"-TaskReport.pdf";
        DownloadFile(response.data,fileName);
    })
}  
/******************************** To set the styles after filtering ************************/
  const getDetails = async()=>{
    let retval= orderValidation();
    if(Object.keys(retval).length == 0){
    let dataToSendForOrder
    getStaff === "Staff" ? dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        } : dataToSendForOrder = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
        };
        if (getWorkspaceType === "Buyer"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
        }
        else if (getWorkspaceType === "PCU"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
        }
        else if (getWorkspaceType === "Factory"){
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
        }
        dataToSendForOrder.statusFilter = document.getElementById("statusFilter").value;
        setFilterPic("");
        setFilterStyleNo("");
        setfilterAStartDate("");
        setfilterAEndDate("");
    await axios.post(ServerUrl + "/get-tasks-report",apiencrypt(dataToSendForOrder))
    .then((response) =>{
        response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){
            setDetails(response.data.data);
            setTaskDetails(response.data.data.styleDetails);
            setStatusFilter(statusOfFilter);
            setToOpen(1);
            axios
            .post(ServerUrl + "/get-styles", apiencrypt(dataToSendForOrder))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setOrders(response.data.data);
            }); 
        }
    })
}
}
    return (
        <Fragment>
              <Row className="pgbgcolor">
           <Breadcrumbs mainTitle={t('taskReports')} parent={t('taskReports')} title={t('taskReports')}/>
           </Row>
             <Container fluid={true} className="general-widget topaln">
                <Card className="m-b-20">
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                            {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("factory")}</Label>
                                        <sup className="font-danger">*</sup>
                                        <InputGroup>
                                            <Input
                                                className={`${errors.factory && "select-error-valid"
                                                    }  form-control`}
                                                name="factories"
                                                type="select"
                                                placeholder="factories"
                                                aria-label="factories"
                                                defaultValue="0"
                                                id="facID"
                                                onChange={(e) => {setFactory(e.target.value);
                                                    let facId = e.target.value;filterStyle("Factory",facId);}}
                                            >
                                                <option value="0" selected>{t("selectFactory")}</option>
                                                {factories.map((factory,i) => (
                                                    <option key={i} value={factory.id}>{factory.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.factory && (
                                            <span className="error-msg">{t(errors.factory)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        <sup className="font-danger">*</sup>
                                        <InputGroup>
                                            <Input
                                                className={`${errors.buyer && "select-error-valid"
                                                    }  form-control`}
                                                name="buyer"
                                                type="select"
                                                placeholder="buyer"
                                                aria-label="buyer"
                                                defaultValue="0"
                                                id="buyerID"
                                                onChange={(e) => {setBuyer(e.target.value);let buyerId = e.target.value;filterStyle("Buyer",buyerId);}}
                                            // onChange={(e) => setFactory(e.target.value)}
                                            // onChange={(e) => setFactory(e.target.value)}
                                            >
                                                <option value="0" selected>{t("selectBuyer")}</option>
                                                {buyers.map((buy,j) => (
                                                    <option key={j} value={buy.id}>{buy.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.buyer && (
                                            <span className="error-msg">{t(errors.buyer)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                type="select"
                                                placeholder="Select Status"
                                                className={`${errors.pcu && "select-error-valid"
                                                    }  form-control digits`}
                                                name="pcuid"
                                                defaultValue="0"
                                                id="pcuID"
                                                onChange={(e) => {setPcu(e.target.value);let pcuId = e.target.value;filterStyle("PCU",pcuId);}}
                                            >
                                                <option value="0" selected>
                                                {t("selectPcu")}
                                                </option>
                                                {Pcus.map((pcuv,k) => (
                                                    <option key={k} value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.pcu && (
                                            <span className="error-msg">{t(errors.pcu)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                    <Label>{t("status")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            type="select"
                                            placeholder="Select Status"
                                            className={`${errors.statusOfFilter && "select-error-valid"
                                                }  form-control digits`}
                                            name="statusFilter"
                                            id="statusFilter"
                                            defaultValue=""
                                            // onChange={handleChange}
                                            onChange={(e) => setStatusOfFilter(e.target.value)}
                                        >
                                            <option value="" disabled>
                                            {t("selectStatus")}
                                            </option>
                                            <option value="All">{t("all")}</option>
                                            <option value="Completed">{t("completed")}</option>
                                            <option value="Delay">{t("delay")}</option>
                                            <option value="DelCompletion">{t("delayComplete")}</option>
                                            <option value="InProgress">{t("inProgress")}</option>
                                            <option value="Rescheduled">{t("reschedule")}</option>
                                            <option value="YetToStart">{t("yetToStart")}</option>
                                            <option value="Notassign">{t("notAssigned")}</option>
                                            
                                            {/* <option value="Cancelled">Cancelled</option> */}
                                        </Input>
                                    </InputGroup>
                                    {errors.statusOfFilter && (
                                        <span className="error-msg">{t(errors.statusOfFilter)}</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" onClick={() => getDetails()}>{t("search")}</Button>
                                <Link to="/taskreports" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {toOpen === 1 ? taskDetails.length>0 ?
                <>
                    <Card className="m-b-10 m-t-0" style={{ border:"0px" }}>
                        <CardBody className=" p-1" >                
                            <Row>
                                <Col lg="12" md="12" sm="12" xs="12" className="centerAlign">
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12} className="p-1">
                                                {(getStaff === "Staff"&&getStaffPermission.includes('Download Report'))||getStaff == null? 
                                                    <div className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" onClick={()=> toDownloadAsPdf()}>
                                                        <img  title={t("download")} src={DownloadIcon} />
                                                    </div>
                                                    : ""}
                                                {statusOfFilter != "Notassign" ? 
                                                    <div className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" onClick={() => toggleFilterCanvas()}>
                                                        <img title={t("filter")} src={FilterIcon} />
                                                    </div> : ""
                                                }
                                            </Col>
                                        </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <TaskReportAccordion TaskDetails={taskDetails} statusFilter={statusFilter}/>
                    <FilterOffCanvas modal={filterOffCanvas} toggle={toggleFilterCanvas} PICs={PIC} orders={orders}
                    taskDetails={setTaskDetails} statusFilter={statusFilter} factory={factory} buyer={buyer} pcu={pcu}
                    filterStartDate={setfilterAStartDate} filterEndDate={setfilterAEndDate} filterPic={setFilterPic} 
                    filterStyle={setFilterStyleNo} filteredStyle={filterStyleNo} filteredPIC={filterPic} 
                    filteredStartDate={filterAStartDate} filteredEndDate={filterAEndDate} />
                </> : <>
                                <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                                    <img src={noData} style={{ width:"200px"}} />
                                    <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                                </div>
                                </>
                : ""}
            </Container>
        </Fragment>
    );
};
export default TaskReports;
/* Code By : Rithanesh */