import UserLogin from '../Auth/UserLogin';
import StaffLogin from '../Auth/StaffLogin';
import Register from '../Auth/Register';
import Pricing from '../Pricing';
import EditPricing from '../Auth/../Pricing/EditPricing';
import SuccessfulPaymentStatus from '../Pricing/SuccessfulPaymentStatus';
import FailurePaymentStatus from './../Pricing/FailurePaymentStatus';
import Termsandconditions from '../Termsandconditions';
import Privacypolicy from '../Privacypolicy';

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/adminlogin`, Component: <UserLogin /> },
  { path: `${process.env.PUBLIC_URL}/stafflogin`, Component: <StaffLogin /> },
  { path: `${process.env.PUBLIC_URL}/register`, Component: <Register /> },
  { path: `${process.env.PUBLIC_URL}/pricing`, Component: <Pricing /> },
  { path: `${process.env.PUBLIC_URL}/editpricing`, Component: <EditPricing /> },
  { path: `${process.env.PUBLIC_URL}/successpaymentstatus`, Component: <SuccessfulPaymentStatus /> },
  { path: `${process.env.PUBLIC_URL}/failurepaymentstatus`, Component: <FailurePaymentStatus /> },
  { path: `${process.env.PUBLIC_URL}/termsandconditions`, Component: <Termsandconditions /> },
  { path: `${process.env.PUBLIC_URL}/privacypolicy`, Component: <Privacypolicy /> },
];
