import React, {  Fragment, useState, useEffect } from "react";
import { Row, Col, Label, Input, Button, Offcanvas, OffcanvasBody, 
         OffcanvasHeader} from "reactstrap";
import noData from "../../assets/images/dms/nodata.png";
import { ServerUrl } from "../../Constant";
import {getLoginCompanyId,getLoginUserId,getLoginStaffId,
        getWorkspaceId, getWorkspaceType} from "../../Constant/LoginConstant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt, decode } from "../../helper";
import { set } from "react-hook-form";

const StyleDetailsViewModal = ({ modal, toggleStyleDetailsViewModal, heading, selectedOrders }) => 
{

  const [orderDetails, setOrderDetails] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {

    axios
      .post(ServerUrl + "/get-dashboard-settings-orders", apiencrypt({
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
        widget_id: "2",
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setOrderDetails(response.data.data.orders);
      });

  }, []);

  const onSelectOrderList = () => {
    var array = [];
    var checkboxes;
    for (var i = 0; i < orderDetails.length; i++) {
      checkboxes = (document.getElementById("taskorder||" + orderDetails[i].id).checked);
      if(checkboxes){
        array.push(orderDetails[i].id);
      }
    }
    return array;
  };

  const OnSubmitHandle = () => {
    var selectedStylesList = onSelectOrderList();
    if( selectedStylesList.length > 0 ){
      axios
        .post(ServerUrl + "/email-settings-order-ids", apiencrypt({
          companyId: getLoginCompanyId,
          userId: getLoginUserId,
          staffId: getLoginStaffId,
          workspaceId: getWorkspaceId,
          task_id: heading == "Task Report" ?  4 : 5,
          order_ids: selectedStylesList
        })).then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code == 401) {
              Swal.fire({
                  icon: 'error',
                  title: t("plsSelectStyle"),
                  button: t("okLabel"),
                
              })
          }
          else if( response.data.status_code == 200 ){
            Swal.fire({
              title: response.data.message,
              icon: "success",
              button: t("okLabel"),
              confirmButtonColor: "#009688",
              timer: 2000
            });
            toggleStyleDetailsViewModal()
          }
        })
    }else{
      Swal.fire({
        title: t('selectCheckBoxValidation'),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
        timer: 2000
      })
    }
  };

  const checkCheckBoxStatus = () => {

    var selectedCheckBoxes = [];
    var checkboxes;
    for (var i = 0; i < orderDetails.length; i++){
      checkboxes = (document.getElementById("taskorder||" + orderDetails[i].id).checked);
      if (checkboxes) {
        selectedCheckBoxes.push(orderDetails[i].id);
      }
    }

    if( selectedCheckBoxes.length == orderDetails.length ){
      document.getElementById("OverallCheckbox").checked = true
    } else{
      document.getElementById("OverallCheckbox").checked = false
    }

  };

  const selectAllCheckBoxes = ( value ) => {
    if( value == true ){
      for (var i = 0; i < orderDetails.length; i++){ document.getElementById("taskorder||" + orderDetails[i].id).checked = true }
    } else if( value == false ) {
      for (var i = 0; i < orderDetails.length; i++){ document.getElementById("taskorder||" + orderDetails[i].id).checked = false }
    }
  };
  
  return (
    <>
      <Offcanvas isOpen={modal} toggle={toggleStyleDetailsViewModal} 
                 direction={"end"} className="productionoffcanvas-width">
      <OffcanvasHeader className="bg-primary offcanvas-header">
          {heading} - {t("emailScheduleSettings")}
        <span className="f-right cursor-pointer" 
              title={t("close")} tooltip={t("close")} alt={t("close")} 
              onClick={() => { toggleStyleDetailsViewModal() }}>
          X
        </span>
      </OffcanvasHeader>
      <OffcanvasBody className="height-equal">
        <Fragment>
          {
          orderDetails.length>0 
          ?
            <Col>
              <Col md="12" lg="12" sm="12">
                <Row className="g-12 ">
                  {/*start contact list */}
                  <Col md="12" lg="12" sm="12">
                    <Row className="g-12">
                      <div className="table-responsive">
                        <table className="table shadow shadow-showcase">
                          <thead className="contact-head">
                            <tr>
                              <th scope="col">
                                { 
                                selectedOrders.length > 0 ? 
                                selectedOrders.split(",").length == orderDetails.length ?
                                  <Input
                                    type="checkbox"
                                    className="checkbox_animated arg-min-top-10"
                                    name="checkbox"
                                    id="OverallCheckbox"
                                    defaultChecked
                                    onChange={(e) => {  selectAllCheckBoxes(e.target.checked) }
                                    }
                                  />
                                :
                                  <Input
                                    type="checkbox"
                                    className="checkbox_animated arg-min-top-10"
                                    name="checkbox"
                                    id="OverallCheckbox"
                                    onChange={(e) => {  selectAllCheckBoxes(e.target.checked) }}
                                  />
                                :
                                  <Input
                                    type="checkbox"
                                    className="checkbox_animated arg-min-top-10"
                                    name="checkbox"
                                    id="OverallCheckbox"
                                    onChange={(e) => {  selectAllCheckBoxes(e.target.checked) }}
                                  />
                                }
                              </th>
                              <th scope="col">{t("orderNo/styleNo")}</th>
                              { getWorkspaceType == "Buyer" ?
                                (
                                <>
                                  <th scope="col">{t("PCU")}</th>
                                  <th scope="col">{t("factory")}</th>
                                </>
                                )
                                :
                                ""
                              }

                              { getWorkspaceType == "Factory" ?
                                (
                                <>
                                  <th scope="col">{t("buyer")}</th>
                                  <th scope="col">{t("PCU")}</th>
                                </>
                                )
                                :
                                ""
                              }

                              { getWorkspaceType == "PCU" ?
                                ( 
                                <>
                                  <th scope="col">{t("buyer")}</th>
                                  <th scope="col">{t("factory")}</th>
                                </>
                                )
                                :
                                ""
                              }  
                            </tr>
                          </thead>
                          {orderDetails.map((mapData) =>
                          (
                            <tbody>
                              <tr key={mapData.id}>
                                <td>
                                  {selectedOrders.includes((mapData.id)) ?
                                    (
                                      <Input
                                        type="checkbox"
                                        className="checkbox_animated arg-min-top-10"
                                        name="checkbox"
                                        value={mapData.id}
                                        defaultChecked
                                        id={"taskorder||" + mapData.id}
                                        onChange={ (e) => { checkCheckBoxStatus() }}
                                        onClick={(e) => { onSelectOrderList(e.target.value) }
                                        }
                                      />
                                    ) : (
                                      <Input
                                        type="checkbox"
                                        className="checkbox_animated arg-min-top-10"
                                        name="checkbox"
                                        value={mapData.id}
                                        id={"taskorder||" + mapData.id}
                                        onChange={ (e) => { checkCheckBoxStatus() }}
                                        onClick={(e) => { onSelectOrderList(e.target.value) }
                                        }
                                      />
                                    )
                                  } {""}
                                </td>
                                <td>
                                  <Label for={mapData.id} className="form-label"></Label>
                                  {mapData.order_no} / {mapData.style_no}&nbsp;
                                </td>
                                { getWorkspaceType == "Buyer" ?
                                  (
                                  <>
                                    <td>  {mapData.pcuName !== null ? mapData.pcuName : "-"} </td>
                                    <td>  {mapData.factoryName !== null ? mapData.factoryName : "-"} </td>
                                  </>
                                  )
                                :
                                  ""
                                }

                              { getWorkspaceType == "Factory" ?
                                  (
                                  <>
                                    <td>  {mapData.buyerName !== null ? mapData.buyerName : "-"} </td>
                                    <td>  {mapData.pcuName !== null ? mapData.pcuName : "-"} </td>
                                  </>
                                  )
                                :
                                  ""
                              }

                              {  getWorkspaceType == "PCU" ?
                                  ( 
                                  <>
                                    <td>  {mapData.buyerName !== null ? mapData.buyerName : "-"} </td>
                                    <td>  {mapData.factoryName !== null ? mapData.factoryName : "-"} </td>
                                  </>
                                  )
                                :
                                  ""
                              } 
                              </tr>
                            </tbody>
                          )
                          )}
                        </table>
                      </div>
                    </Row>
                  </Col>
                  <Col md="12" lg="12" sm="12">&nbsp;</Col>
                </Row>
              </Col>
            </Col>
          : 
            <div className="align-self-center media-body centerAlign"
                style={{ marginTop: "100px" }}>
                <img src={noData} style={{ width: "200px" }} />
                <p className='f-18 m-t-10' >-- {t("noData")} --</p>
            </div>  
          }

        </Fragment>
      </OffcanvasBody>
      <OffcanvasHeader>
          <Button className="text-end m-3 m-b-5" 
                  onClick={() => { OnSubmitHandle() }} style={{ float: "Right" }}>
            {t("apply")}
          </Button>
          <Button className="text-end m-3 btn secondaryBtn" 
                  onClick={() => { toggleStyleDetailsViewModal() }} style={{ float: "Right" }}>
            {t("cancel")}
          </Button> 
      </OffcanvasHeader>
      </Offcanvas>
    </>
  );

};

export default StyleDetailsViewModal;

//************************** Code by : Ramanan M ****************************//

