import { Container, Row, Col, CardBody, Card,Label } from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6 } from "../../AbstractElements";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ServerUrl } from "../../Constant";
import { apidecrypt, apiencrypt, encode } from "../../helper";
import Swal from 'sweetalert2';
// import EditStaff from '../../assets/images/dms/EditStaffIcon.svg'
import EditStaff from '../../assets/images/dms/EditStaffBlk.svg'
// import StaffEmail from '../../assets/images/dms/MailIcon.svg'
import StaffEmail from '../../assets/images/dms/Mail.svg'
import {
  getStaff, getStaffPermission, getLoginCompanyId,
  getWorkspaceId, getLoginUserId, getLoginStaffId
} from '../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';

const Staff = () => {
  const history = useNavigate();
  const handleChangeStatus = ({ meta, file }, status) => { };
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [staffs, setStaffs] = useState([]);
  const [staff_id, setstaff_id] = useState("");
  const { formState: { errors }, } = useForm();
  const [allowplanvalidation, setallowplanvalidation] = React.useState();
  const { t } = useTranslation();
  useEffect(() => {
    axios.post(ServerUrl + "/get-staffs", apiencrypt({ company_id, workspace_id }))
    .then((response) => {
      response.data = apidecrypt(response.data)
      setStaffs(response.data.data);
    });

    {
      (((getStaff === "Staff" && getStaffPermission.includes('View Staff')) || getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }  

  }, []);

  const inputParamsUser = {};
  inputParamsUser['company_id'] = getLoginCompanyId;
  inputParamsUser['workspace_id'] = getWorkspaceId;
  inputParamsUser['user_id'] = getLoginUserId;
  inputParamsUser['type'] = 'Staff';

  const checkStatus = (statusValue) => {
    if (statusValue === "1") {
      return (
        <td>
          <span className="font-success">{t("activated")}</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className="font-danger">{t("deactivated")}</span>
        </td>
      );
    }
  };

  const editHandle = (id) => {
    setTimeout(() => {
      window.location.href = "/editstaff?id=" + encode(id);
    }, 100);
  };

  const planValidation = () => {
    axios.post(ServerUrl + '/validate-plan', apiencrypt(inputParamsUser))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            icon: "warning",
            button: t("okLabel"),
            allowOutsideClick: false
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/staff';
            }
          })
        } else if (response.data.status_code === 200) {
          window.location.href = '/addstaff';
        }
      });

  }

const Editstaff =(Staffid)=>{
  window.location.href =`${process.env.PUBLIC_URL}/editstaff?id=${encode(Staffid)}`
}

  const resendEmail = (id)=>{
    document.getElementById("resendMail||"+id).remove();
    axios.post(ServerUrl+'/resend-email-staff',apiencrypt({
      company_id : getLoginCompanyId,
      workspace_id : getWorkspaceId,
      user_id : getLoginUserId,
      staff_id : id
    })).then((response)=>{
      response.data = apidecrypt(response.data)
      if(response.data.status_code === 200){
        Swal.fire({
          title: t(response.data.message),
          icon: "success",
          timer:5000,
          button: t("okLabel"),
          allowOutsideClick: false
        })
      }
    })
  }
  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('staffDetails')} parent="Staff Details" title="Staff Details" subTitle="View Staff Details" />
      </Row>

      <Container fluid={true} className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
           
              <CardBody>
                <Row className="g-12  m-b-15">
                  {" "}
                  <Col md="12" lg="12" sm="12" className="text-end">

                    {(getStaff === "Staff" && getStaffPermission.includes('Staff Add')) || getStaff == null ?
                    <Link 
                      to="/addstaff"
                      className="btn btn-primary">
                      {t("addStaffBtn")}
                    </Link> : "" } 
                  </Col>
                </Row>
                {/*End Add Contact Row*/}
                <Card className="m-t-5">
                <span className="p-l-10 editcompanyinfo">
                    <Label className="skudetailsclr p-1 " name="logo">
                    <span className="p-r-5"></span> {t("staffList")}<span className="p-r-5"></span>
                    </Label>
                </span>
                <Row className="g-12 m-t-20">
                  {/*start contact list */}
                  
                  <Col md="12" lg="12" sm="12">
                    <Row className="g-12">
                      <div className="table-responsive">
                        <table className="table shadow shadow-showcase table-striped staffpad">
                          <thead className="overViewSku">
                            <tr>
                              <th scope="col" className="centerAlign" style={{ borderRight:"1px solid #0000001F" }}>{ t("serialNo")}</th>
                              <th scope="col" style={{ borderRight:"1px solid #0000001F" }}>{ t("name")}</th>
                              <th scope="col" style={{ borderRight:"1px solid #0000001F" }}>{ t("email")}</th>
                              {/* <th scope="col" style={{ borderRight:"1px solid #0000001F" }}>{ t("userType")}</th> */}
                              <th scope="col" style={{ borderRight:"1px solid #0000001F" }}>{ t("role")}</th>
                              <th scope="col" style={{ borderRight:"1px solid #0000001F" }}>{ t("status")}</th>
                              {(getStaff === "Staff" && getStaffPermission.includes('Staff Edit')) || getStaff == null ?
                                <th scope="col" className="centerAlign">{ t("action")}</th> : ""
                              }
                            </tr>
                          </thead>
                          <tbody className="tableRowCol">
                            {staffs.length > 0 ? staffs.map((staff, index) => (
                              <tr key={index}>
                                <td scope="row" className=" centerAlign" style={{ borderRight:"1px solid #0000001F",margintop:"10px",verticalAlign:"middle" }}>{index + 1}</td>
                                <td style={{ margin:"-5px",borderRight:"1px solid #0000001F",verticalAlign:"middle" }}>{staff.first_name + ' ' + staff.last_name}</td>
                                <td style={{ borderRight:"1px solid #0000001F",verticalAlign:"middle" }}>{staff.email}</td>
                                {/* <td style={{ borderRight:"1px solid #0000001F",verticalAlign:"middle" }}>{staff.user_type ? staff.user_type =="PCU" ? "Buying House":staff.user_type :"-"}</td> */}
                                <td style={{ borderRight:"1px solid #0000001F",verticalAlign:"middle" }}>{staff.role}</td>
                                <td style={{ borderRight:"1px solid #0000001F",verticalAlign:"middle" }}> {checkStatus(staff.status)}</td>
                                {(getStaff === "Staff" && getStaffPermission.includes('Staff Edit')) || getStaff == null ?
                                  <td className="centerAlign" style={{ borderLeft:"1px solid #0000001F" }}>
                                    <img title={t("editStaffDetails")} src={EditStaff} className="cursor-pointer"  onClick={()=>{Editstaff(staff.id)}}/>&nbsp;&nbsp;
                                    {/* <Link
                                    className="btn-primary btn"
                                    to={`${process.env.PUBLIC_URL}/editstaff?id=${encode(staff.id)}`}>{ t("edit")}</Link> */}
                                    {getStaff !== "Staff" && staff.status === "1" ?
                                    <img className="cursor-pointer" src={StaffEmail} id={"resendMail||"+staff.id}   title={t("resendMail")}
                                    onClick={()=>resendEmail(staff.id)}></img> :""
                                    }
                                  </td>
                                  : ""}
                              </tr>
                            )) : <>
                              <tr className="text-center"><td colSpan="6">{ t("addStaffList")}</td></tr>
                            </>}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </Col>
                </Row>
                </Card>
                {/*end second row*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Staff;
//**************************Code by : Rithanesh****************************//

