
import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { PHPtoJSFormatConversion } from '../../helper';
import { useTranslation } from 'react-i18next';

const TaskModal = (props) => {
  const [smodel, setmodel] = useState(false);
  const { t } = useTranslation();
  const onSaveHandle = () => {
    setmodel(false);
    props.changeState("modal", false);
  };
  useEffect(() => {
    if (props.modal === true && props.data.resourceId !== null) {
      setmodel(true);
    }
  }, [props]);
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <Modal isOpen={smodel} centered lg={12}>
      <ModalHeader>{t("taskDetails")}</ModalHeader>
      <ModalBody>
        {smodel === true && props.data.resourceId !== null ?
          <table className="table table-bordered">
            <tr><td className="taslviewmodal">{Capitalize(t("category"))}</td><td>{props.data.resourceId}</td></tr>
            <tr><td className="taslviewmodal">{t("task")}</td><td>{props.data.task}</td></tr>
            {props.data.subtask ?
              <tr><td className="taslviewmodal">{t("subTask")}</td><td>{props.data.subtask ? props.data.subtask : "-"}</td></tr> : ""}
            <tr><td className="taslviewmodal">{t("startDate")}</td><td>{PHPtoJSFormatConversion.format(new Date(props.data.taskstart))}</td></tr>
            <tr><td className="taslviewmodal">{t("endDate")}</td><td>{PHPtoJSFormatConversion.format(new Date(props.data.taskend))}</td></tr>
            <tr><td className="taslviewmodal">{t("accomplishedDate")}</td><td>{PHPtoJSFormatConversion.format(new Date(props.data.accdate))}</td></tr>
            <tr><td className="taslviewmodal">{t("personInCharge")}</td><td>{props.data.pic}</td></tr>
          </table>
          : ''}
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn secondaryBtn", onClick: () => onSaveHandle() }}>
          {t("close")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default TaskModal;

//**************************Code by : SaravanaKumar****************************//