import {
  Container,
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  CardHeader,
  Collapse,
  FormGroup,
} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import {
  Breadcrumbs,
  H5,
  H4,
  P,
  H6,
  Btn,
  LI,
  UL,
} from "../../AbstractElements";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import {
  getLoginCompanyId,
  getWorkspaceId,
  getLoginUserId,
  getWorkspaceType, getStaff, getStaffPermission, getLoginStaffId
} from "../../Constant/LoginConstant";
import factoryimg from "../../assets/images/dms/order-factory.png";
import pcuimg from "../../assets/images/dms/order-pcu.png";
import buyerimg from "../../assets/images/dms/buyer.png"
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../Constant";
import { useNavigate, Link } from "react-router-dom";
import TaskAccordion from "./NewTaskAccordion";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiencrypt,apidecrypt, PHPtoJSFormatConversion } from "../../helper";
import noData from "../../assets/images/dms/nodata.png";
import NewTaskAccordion from "./NewTaskAccordion";

const NewTaskUpdate = () => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [factory, setFactory] = useState("");
  const [buyer, setBuyer] = useState("");
  const [buyers, setBuyers] = useState([]);
  const [pcu, setPcu] = useState("");
  const [errors, setValidErrors] = useState({});
  const [Pcus, setPcus] = useState([]);
  const [factories, setFactories] = useState([]);
  const [styles, setStyles] = useState([]);
  const [style, setStyle] = useState('');
  const [thestyle, setTheStyle] = useState([]);
  const [theNames, setTheNames] = useState([]);
  const [theTaskInfo, setTheTaskInfo] = useState([]);
  const [toShowDetails, setToShowDetails] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [forReload, setForReload] = useState(0);
 const { t } = useTranslation();
  const [delDate, setDelDate] = useState([]);
  const [basicInfo, setBasicInfo] = useState([]);
  const [ deliveryDate, setDeliveryDate ] = useState("");
  const [ createDate, setCreateDate ] = useState("");
  const [ deliveryDateStatus, setDeliveryDateStatus ] = useState("");
  setCreateDate
  let detailsToSend;
  getStaff === "Staff" ? detailsToSend = {
    company_id: company_id,
    workspace_id: workspace_id,
    staff_id: getLoginStaffId,
  } : detailsToSend = {
    company_id: company_id,
    workspace_id: workspace_id,
  }
  const dataToSendAtStarting = detailsToSend;
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
 

  var getInputParams = {}
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = style;

  useEffect(async () => {
    await axios
      .post(ServerUrl + "/get-user-factory", apiencrypt(dataToSendAtStarting))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setFactories(response.data.data);
      });
    await axios
      .post(ServerUrl + "/get-user-pcu", apiencrypt(dataToSendAtStarting))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setPcus(response.data.data);
      });

    await axios
      .post(ServerUrl + "/get-user-buyer", apiencrypt(dataToSendAtStarting))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setBuyers(response.data.data);
      });
    await axios
      .post(ServerUrl + "/get-styles", apiencrypt(dataToSendAtStarting)).then((response) => {
        response.data = apidecrypt(response.data)
        setStyles(response.data.data);
      });

      {
        (((getStaff === "Staff" && getStaffPermission.includes('View Task Updates')) || getStaff == null )) 
        ?
        ""
        :
        window.location.href = "/dashboard" 
      }  

  }, []);
  /***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
  const filterStyle = (type, id) => {
    if (type === "Factory") {
      axios.post(ServerUrl + "/get-styles", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        factory_id: id,
        staff_id: getLoginStaffId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        setStyles(response.data.data);
      });
    }
    if (type === "Buyer") {
      axios.post(ServerUrl + "/get-styles", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        buyer_id: id,
        staff_id: getLoginStaffId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        setStyles(response.data.data);
      });
    }
    if (type === "PCU") {
      axios.post(ServerUrl + "/get-styles", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
        pcu_id: id,
        staff_id: getLoginStaffId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        setStyles(response.data.data);
      });
    }
  }

  /*Order Validation  Start*/
  const orderValidation = (data) => {
    let errors = {};
    if (!style) {
      errors.style = ('pleaseSelectStyleNo');
    }

    setValidErrors(errors);
    return errors;
  };
  /******************************** To set the styles after filtering ************************/
  const getDetails = async () => {

    setToShowDetails(0);
    let retval = orderValidation();
    if (Object.keys(retval).length == 0) {
      let dataToSendForStyle = {
        company_id: company_id,
        workspace_id: workspace_id,
      };
      if (getWorkspaceType != "Factory") {
        dataToSendForStyle.factory_id = document.getElementById("factory").value;
      }
      if (getWorkspaceType != "PCU") {
        dataToSendForStyle.pcu_id = document.getElementById("pcu").value;
      }
      if (getWorkspaceType != "Buyer") {
        dataToSendForStyle.buyer_id = document.getElementById("buyer").value;
      }
      dataToSendForStyle.orderId = document.getElementById("style").value;
      await axios.post(ServerUrl + "/get-style", apiencrypt(dataToSendForStyle))
        .then((response) => {
          response.data = apidecrypt(response.data);
          setTheStyle(response.data.data);
          setTheNames(response.data.name);
          setTheTaskInfo(response.data.taskInfoData);
        })
      await axios.post(ServerUrl + "/get-order-template", apiencrypt(dataToSendAtStarting)).then((response) => {
        response.data = apidecrypt(response.data);
        setTemplates(response.data.data);
      })

      let detailsToSendData;
      getStaff === "Staff" ? detailsToSendData = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id: getLoginStaffId,
        order_id: document.getElementById("style").value
      } : detailsToSendData = {
        company_id: company_id,
        workspace_id: workspace_id,
        order_id: document.getElementById("style").value
      }

      await axios.post(ServerUrl + "/get-task-data", apiencrypt(detailsToSendData)).then((response) => {
        response.data = apidecrypt(response.data)
        setTemplateData(response.data.data);
        setToShowDetails(1);
        setDeliveryDate(response.data.delivery_date);
        setDeliveryDateStatus(response.data.delivery_date_exceed);
      });

      await axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        var create_dates = response.data.data[0].created_date;
        setCreateDate(create_dates)
        setBasicInfo(response.data.data);

        var dates = response.data.data[0].delivery_dates;
        var splitId = dates.split(",");
        setDelDate(splitId)
      })

    }
    else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  }

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('Task Update')} parent={t('Task Update')} title={t('Task Update')} />
      </Row>
      <Container fluid={true} className="general-widget  topaln">
        <Card>
          <CardBody className="shadow shadow-showcase">
            <Row className="g-12 ">
              {getWorkspaceType != "Factory" ? (
                <Col lg="3" md="12" sm="12">
                  <FormGroup className="">
                    <Label>{t("factory")}</Label>
                    {/* <sup className="font-danger">*</sup> */}
                    <InputGroup>
                      <Input
                        className={`${errors.factories && "select-error-valid"
                          }  form-control`}
                        name="factories"
                        id="factory"
                        type="select"
                        placeholder={t("factory")}
                        aria-label="factories"
                        defaultValue=""
                        // onChange={handleChange}
                        onChange={(e) => { setFactory(e.target.value); let facId = e.target.value; filterStyle("Factory", facId); }}
                      >
                        <option value="0" selected disabled>{t("selectFactory")}</option>
                        {factories.map((factory) => (
                          <option key={factory.id} value={factory.id}>{factory.name}</option>
                        ))}
                      </Input>
                    </InputGroup>
                    {errors.factories && (
                      <span className="error-msg">{errors.factories}</span>
                    )}
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}
              {getWorkspaceType != "Buyer" ? (
                <Col lg="3" md="12" sm="12">
                  <FormGroup className="">
                    <Label>{t("buyer")}</Label>
                    {/* <sup className="font-danger">*</sup> */}
                    <InputGroup>
                      <Input
                        className={`${errors.buyer && "select-error-valid"
                          }  form-control`}
                        name="buyer"
                        type="select"
                        id="buyer"
                        placeholder={t("buyer")}
                        aria-label="buyer"
                        defaultValue=""
                        //onChange={handleChange}
                        onChange={(e) => { setBuyer(e.target.value); let buyerId = e.target.value; filterStyle("Buyer", buyerId); }}
                      // onChange={(e) => setFactory(e.target.value)}
                      // onChange={(e) => setFactory(e.target.value)}
                      >
                        <option value="0" selected disabled>{t("selectBuyer")}</option>
                        {buyers.map((buy) => (
                          <option key={buy.id} value={buy.id}>{buy.name}</option>
                        ))}
                      </Input>
                    </InputGroup>
                    {errors.buyer && (
                      <span className="error-msg">{errors.buyer}</span>
                    )}
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}
              {getWorkspaceType != "PCU" ? (
                <Col lg="3" md="12" sm="12">
                  <FormGroup>
                    <Label>{t("productionControlUnit")}</Label>
                    {/* <sup className="font-danger">*</sup> */}
                    <InputGroup>
                      <Input
                        type="select"
                        placeholder={t("pcu")}
                        id="pcu"
                        className={`${errors.pcu && "select-error-valid"
                          }  form-control digits`}
                        name="pcuid"
                        defaultValue=""
                        // onChange={handleChange}
                        onChange={(e) => { setPcu(e.target.value); let pcuId = e.target.value; filterStyle("PCU", pcuId); }}
                      >
                        <option value="0" selected disabled>
                          {t("selectPCU")}
                        </option>
                        {Pcus.map((pcuv) => (
                          <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                        ))}
                      </Input>
                    </InputGroup>
                    {errors.pcu && (
                      <span className="error-msg">{errors.pcu}</span>
                    )}
                  </FormGroup>
                </Col>
              ) : (
                ""
              )}

              <Col lg="3" md="12" sm="12">
                <FormGroup>
                  <Label>{t("styleNo")}</Label>
                  <sup className="font-danger">*</sup>
                  <InputGroup>
                    <Input
                      type="select"
                      placeholder={t("styleNo")}
                      className={`${errors.style && "select-error-valid"
                        }  form-control digits`}
                      name="styleNo"
                      id="style"
                      defaultValue=""
                      // onChange={handleChange}
                      onChange={(e) => setStyle(e.target.value)}
                    >
                      <option value="" disabled>
                        {t("selectStyleno")}
                      </option>
                      {styles.map((style) => (
                        <option key={style.id} value={style.id}>{style.style_no} ({style.order_no})</option>
                      ))}
                    </Input>
                  </InputGroup>
                  {errors.style && (
                    <span className="error-msg">{t(errors.style)}</span>
                  )}
                </FormGroup>
              </Col>

              <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                <Button className="btn btn-primary f-left m-r-10 m-b-5" onClick={() => getDetails()}>{t("search")}</Button>
                <Link to="/taskupdate" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {toShowDetails === 1 ?
          <>
            <Row>
              <Col lg="9" md="9">
                <Card className="m-b-10 shadow shadow-md">
                  <CardHeader className="p-1 f-w-500" style={{ background: "#c2ede8f5", fontSize: "14px" }}>
                    <table style={{  width: '100%' }}>
                      <thead className="f-w-300" style={{  fontSize: '14px' }}>
                        <tr className="f-w-500">
                          <th width="50%">
                            <tr className="f-left" style={{ paddingLeft: '13%' }}> 
                              {t("orderNo")} /
                            </tr>
                            <tr className="f-left"> 
                              {t("styleNo")}  
                            </tr>
                          </th>
                          <td> {t("taskStatus")} </td>
                        </tr>
                      </thead>
                    </table>
                  </CardHeader>
                  <CardBody className="p-3" >
                    <table style={{  width: '100%' }}>                   
                    <thead   style={{  fontSize: '13px' }}>
                        <tr className="text-center">
                          <th className="f-w-400" style={{  fontSize: '14px' }}> {thestyle.order_no} </th>
                          <th className="f-w-400" style={{  fontSize: '12px' }} > {t("total")} </th>
                          <th className="f-w-400" style={{  fontSize: '12px' }} > {t("yetToStart")} </th>
                          <th className="f-w-400" style={{  fontSize: '12px' }} > {t("scheduled")} </th>
                          <th className="f-w-400" style={{  fontSize: '12px' }} > {t("accomplished")} </th>
                          <th className="f-w-400" style={{  fontSize: '12px' }} > {t("pending")} </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="text-center">
                          <td className="f-w-400" style={{  fontSize: '14px' }}> {thestyle.style_no} </td>
                          <td className="f-w-600" style={{  fontSize: '12px' }} > {theTaskInfo.totalTask} </td>
                          <td className="f-w-600" style={{  fontSize: '12px' }} > {theTaskInfo.yetToStart} </td>
                          <td className="f-w-600" style={{  fontSize: '12px' }} > {theTaskInfo.scheduledTasks} </td>
                          <td className="f-w-600" style={{  fontSize: '12px' }} > {theTaskInfo.accomplishedTasks} </td>
                          <td className="f-w-600" style={{  fontSize: '12px' }} > {theTaskInfo.totalTask - theTaskInfo.accomplishedTasks} </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3" md="3">
                <Card className="m-b-10 shadow shadow-md">
                  <CardHeader className="p-1 f-w-500 text-center" style={{ background: "#c2ede8f5", fontSize: "14px" }}>
                    {t("delDate")}
                  </CardHeader>
                  <CardBody className="p-1" style={{ overflowY: "scroll", height: '78px'}} >
                    <div 
                        className="text-center verticalAlignment"
                         style={{ backgroundColor: '#fff', marginTop: '10px' }}>
                              {deliveryDate ? 
                                  deliveryDateStatus == 1 ? 
                                  <span className="btn deliveryBtn text-center" 
                                        style={{  height: '35px', width: '145px', borderRadius: '18px', borderColor: '#ccc', 
                                        marginLeft: '10px', marginTop: '5px',marginBottom:"10px", fontSize: '12px', 
                                        color: '#EE335E', fontWeight: '600', cursor:"unset"  }}> 
                                    {PHPtoJSFormatConversion.format(new Date(deliveryDate))} 
                                  </span>
                                 
                              : 
                              <span className="btn deliveryBtn text-center" 
                                    style={{  height: '35px', width: '145px', borderRadius: '18px', borderColor: '#ccc', 
                                    marginLeft: '10px', marginTop: '5px',marginBottom:"10px", fontSize: '12px', 
                                    color: '#878787', fontWeight: '600', cursor:"unset"  }}> 
                                {PHPtoJSFormatConversion.format(new Date(deliveryDate))} 
                              </span>
                              : 
                                <div className="align-self-center media-body centerAlign m-t-5">
                                  <img src={noData} style={{ width:"60px"}} />
                                  <p className='f-5 m-t-10' >-- {t("noDataFound")} --</p>
                                </div>
                              }
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Card>
              <NewTaskAccordion workspaceId={workspace_id} companyId={company_id} orderId={style} tasks={templates}
                details={setToShowDetails} selectedTemplate={thestyle.order_task_template} templateData={templateData} setTemplateData={setTemplateData}
                ForReload={setForReload} setStyle={setTheStyle} taskInfo={setTheTaskInfo} theStyle={thestyle}  create_dates={createDate} basicInfo={basicInfo[0]}
                />
            </Card>
          </>
          : t("selectAStyle")}
      </Container>
    </Fragment>
  );
};

export default NewTaskUpdate;
/* Code By : Rithanesh */

