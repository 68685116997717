import React, { Fragment, useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs } from '../../AbstractElements';
import RolesSettings from './RolesSettings';
import { useTranslation } from 'react-i18next';
import { getStaff, getStaffPermission } from "../../Constant/LoginConstant";
const UserSettings = () => {
  const { t } = useTranslation();

  useEffect(() => {
    {
      (((getStaff === "Staff" && getStaffPermission.includes('View Permissions')) || getStaff == null))
        ?
        ""
        :
        window.location.href = "/dashboard"
    }
  }, []);

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('managePermissions')} parent="Manage Permissions" title="Manage Permissions" subTitle="Roles And Manage Permissions" />
      </Row>

      <Container fluid={true} className="general-widget topaln">
        <div className="email-wrap bookmark-wrap">
          <Row>
            <RolesSettings />
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default UserSettings;

//**************************Code by : Praveen****************************//