
import {
    Container,
    Row,
    Col,
    CardBody,
    Card,
    CardHeader,
    FormGroup,
    Form,
    Label,
    Input,
    CardFooter,
    InputGroup,
    InputGroupText,
    Button
  } from "reactstrap";
  import React, { Fragment, useContext, useState, useEffect } from "react";
  import { Breadcrumbs, H5, P, Btn, H6, } from "../../AbstractElements";
  import { useNavigate, Link } from "react-router-dom";
  import { useForm } from "react-hook-form";
  import Dropzone from "react-dropzone-uploader";
  import axios from "axios";
  import { ServerUrl } from "../../Constant/index";
  import Swal from "sweetalert2";
  import {decode } from "../../../src/helper";
  import { useSearchParams } from "react-router-dom";
 


  import {getLoginCompanyId,getWorkspaceId,getLoginUserId,getLoginStaffId,getLoginToken} from '../../Constant/LoginConstant';
  
  const EditColor = () => {
    const history = useNavigate();
    const handleChangeStatus = ({ meta, file }, status) => {};
  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();
  
    // const AddProject = (data) => {
    //   if (data !== "") {
    //     history(`${process.env.PUBLIC_URL}/app/project/project-list`);
    //   } else {
    //     errors.showMessages();
    //   }
    // };
  
  
    
    const [user_id, setuser_id] = useState(getLoginUserId);
    const [staff_id, setStaff_id] = useState(getLoginStaffId);
    const [company_id, setCompany_id] = useState(getLoginCompanyId);
    const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
    const [searchParams, setSearchParams]= useSearchParams();
    const [name, setEditName] = useState(decode(searchParams.get("nm")));
    const [status, setStatus] = useState("1");
    const [token_id, setToken_id] = useState(getLoginToken);
    const [colorId, setcolorId] = useState(decode(searchParams.get("id")));
   
    const toggleRoleModal = () => setRoleModal(!roleModal);
    const onGoBack = () =>{
      // setTimeout(() => {
      //   window.location.href = "/staff";
      // }, 100);
    }
    const [validerrors, setValidErrors] = useState({});
    const validation = (data) => {
      let validerrors={};
       if(!name){
        validerrors.name="Please Enter Color Name";
         }
    
     setValidErrors(validerrors);
     return validerrors;
   };
    useEffect(() => {
    }, []);
    const OnSubmitHandle = () => {
     let retval= validation();
     // console.log("OnSubmitHandle called",Object.keys(retval).length)
  if(Object.keys(retval).length == 0){
      document.getElementById("save-button").setAttribute("disabled", true);
      axios.post(ServerUrl + "/update-color",{
        company_id: company_id,
        workspace_id: workspace_id,
        name:name,
        staff_id :staff_id,
        user_id:user_id, 
        id: colorId,
        status:status,    
    })
      .then((response) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            title: "Color Added Successfully",
            // text: t(response.data.message),
            icon: "success",
            button:  t("okLabel"),
            timer: 2000
          }).then((result) => {
           // if (result.isConfirmed) {
              document.getElementById("save-button").removeAttribute("disabled");
             // setTimeout(() => {
                window.location.href = "/color";
              //}, 100);
           // }
          });
        }
       
        if (response.data.status_code === 400) {
          document.getElementById("save-button").removeAttribute("disabled");
          Swal.fire({
            title: t(response.data.message),
            icon: "error",
            button:  t("okLabel"),
          });
        }
      });
    }else{
      Swal.fire({
        title: "Validation Error",
        text: "Please Fill Required Fields ",
        icon: "error",
        button:  t("okLabel"),
      });
    }
    };
    return (
      <Fragment>
         <Row className="pgbgcolor">
           <Breadcrumbs mainTitle='Color Details' parent="Color Details" title="Color Details" subTitle="Edit Color"/>
           </Row>
       
        <Container fluid={true} className="general-widget topaln">
          <Form>
            <Row>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        {" "}
                        <H6 attrH6={{ className: "pb-2" }}>Color Info</H6>
                      </Col>
                      <Col sm="3">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlInput1">
                             Name<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                             className={`${validerrors.name && 'error-valid'}  form-control`}
                            type="text"
                            placeholder="Color Name"
                            value={name}
                            onChange={(e) => setEditName(e.target.value)
                                  }
                          />                  
                           {validerrors.name && (
                      <span className="error-msg">{validerrors.name}</span>
                    )}
                        </FormGroup>
                      </Col>
                                                               
                      <Col sm="4">
                        <FormGroup>
                          <Label htmlFor="exampleFormControlSelect9">
                            Status<sup className="font-danger">*</sup>
                          </Label>
                          <Input
                            type="select"
                            name="select"
                            className="form-control digits"
                            defaultValue={status}
                            onChange={(e) => setStatus(e.target.value)}
                          >
                            <option value="1">{"Activated"}</option>
                            <option value="2">{"Deactivated"}</option>
                           
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <FormGroup className="mb-0">
                          <Button
                            className="btn btn-primary" id="save-button"
                            onClick={() => OnSubmitHandle()}
                          >
                            Save
                          </Button>
                          <Link to="/color">
                            <Button className="btn btn-danger m-l-10" 
                            >
                              Cancel
                            </Button>
                          </Link>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </Fragment>
    );
  };
  
  export default EditColor;
  