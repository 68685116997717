import { Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';
import { apidecrypt, apiencrypt, decode } from '../helper';

axios.interceptors.request.use((config) => {
  document.body.classList.add('loading-indicator');
  if (config.data == null || config.data == undefined || config.data == '') {
    document.body.classList.remove('loading-indicator');
  }

  return config;
}, (error) => {
  document.body.classList.remove('loading-indicator');
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  document.body.classList.remove('loading-indicator');
  if((response.config.url.split('/api/')[1] != "register-user" && 
  response.config.url.split('/api/')[1] != "user-get-otp" && 
  response.config.url.split('/api/')[1] != "verify-otp" && 
  response.config.url.split('/api/')[1] != "get-countries" && 
  response.config.url.split('/api/')[1] != "get-languages" && 
  response.config.url.split('/api/')[1] != "get-timezone" && 
  response.config.url.split('/api/')[1] != "get-plan" && 
  response.config.url.split('/api/')[1] != "new-plan-payment" && 
  response.config.url.split('/api/')[1] != "workspace-type" && 
  response.config.url.split('/api/')[1] != "confirm-plan-payment" && 
  response.config.url.split('/api/')[1] != "company-settings" && 
  response.config.url.split('/api/')[1] != "get-company-settings" && 
  response.config.url.split('/api/')[1] != "update-company-settings" && 
  response.config.url.split('/api/')[1] != "update-plan"&&
  response.config.url.split('/api/')[1] != "get-chat" &&
  response.config.url.split('/api/')[1] != "add-chat"&&
  response.config.url.split('/api/')[1] != "add-files"&&
  response.config.url.split('/api/')[1] != "download-file"&&
  response.config.url.split('/api/')[1] != "download-tasks-report"&&
  response.config.url.split('/api/')[1] != "download-daily-prod-report"&&
  response.config.url.split('/api/')[1] != "download-daily-sku-reports"&&
  response.config.url.split('/api/')[1] != "download-order-reports"&&
  response.config.url.split('/api/')[1] != "download-production-report"&&
  response.config.url.split('/api/')[1] != "download-orderstatus-pdf"&&
  response.config.url.split('/api/')[1] != "download-pendingTask-pdf"&&
  response.config.url.split('/api/')[1] != "download-production-pdf"&&
  response.config.url.split('/api/')[1] != "export-chat"&&
  response.config.url.split('/api/')[1] != "download-sam-report" &&
  response.config.url.split('/api/')[1] != "add-order-materials-label-media" &&
  response.config.url.split('/api/')[1] != "download-order-materials-label" &&
  response.config.url.split('/api/')[1] != "download-order-bom" &&
  response.config.url.split('/api/')[1] != "download-files"
 
  ) && (
    response.config.url.split('/api/staff/')[1] != "register-user" && 
  response.config.url.split('/api/staff/')[1] != "user-get-otp" && 
  response.config.url.split('/api/staff/')[1] != "verify-otp" && 
  response.config.url.split('/api/staff/')[1] != "get-countries" && 
  response.config.url.split('/api/staff/')[1] != "get-languages" && 
  response.config.url.split('/api/staff/')[1] != "get-timezone" && 
  response.config.url.split('/api/staff/')[1] != "get-plan" && 
  response.config.url.split('/api/staff/')[1] != "new-plan-payment" && 
  response.config.url.split('/api/staff/')[1] != "workspace-type" && 
  response.config.url.split('/api/staff/')[1] != "confirm-plan-payment" && 
  response.config.url.split('/api/staff/')[1] != "company-settings" && 
  response.config.url.split('/api/staff/')[1] != "get-company-settings" && 
  response.config.url.split('/api/staff/')[1] != "update-company-settings" && 
  response.config.url.split('/api/staff/')[1] != "update-plan"&&
  response.config.url.split('/api/staff/')[1] != "get-chat" &&
  response.config.url.split('/api/staff/')[1] != "add-chat"&&
  response.config.url.split('/api/staff/')[1] != "add-files"&&
  response.config.url.split('/api/staff/')[1] != "download-file"&&
  response.config.url.split('/api/staff/')[1] != "download-tasks-report"&&
  response.config.url.split('/api/staff/')[1] != "download-daily-prod-report"&&
  response.config.url.split('/api/staff/')[1] != "download-daily-sku-reports"&&
  response.config.url.split('/api/staff/')[1] != "download-order-reports"&&
  response.config.url.split('/api/staff/')[1] != "download-production-report"&&
  response.config.url.split('/api/staff/')[1] != "download-orderstatus-pdf"&&
  response.config.url.split('/api/staff/')[1] != "download-pendingTask-pdf"&&
  response.config.url.split('/api/staff/')[1] != "download-production-pdf"&&
  response.config.url.split('/api/staff/')[1] != "export-chat"&&
  response.config.url.split('/api/staff/')[1] != "download-sam-report" &&
  response.config.url.split('/api/staff/')[1] != "add-order-materials-label-media" &&
  response.config.url.split('/api/staff/')[1] != "download-order-materials-label" &&
  response.config.url.split('/api/staff/')[1] != "download-order-bom" &&
  response.config.url.split('/api/staff/')[1] != "download-files"
  
  
  ))
  {
        let responseData = apidecrypt(response.data)
        if(responseData.hasOwnProperty("status_code") && responseData.status_code === 40004) {
          if (decode(localStorage.getItem("staff")) === "Staff") {
            localStorage.clear();
            window.location.href = `${process.env.PUBLIC_URL}/stafflogin`;
          }
          else {
            localStorage.clear();
            window.location.href = `${process.env.PUBLIC_URL}/adminlogin`;
          }
        }
      }
  return response
}, function (error) {
  // if(error.response.data.message!=''){
  document.body.classList.remove('loading-indicator');
  // }
//  console.log("error:",error)
  if ( error.response.data != undefined && ((error.response.data.status_code === 401 && error.response.data.error === 'Unauthenticated.') || (error.response.data.message ==="Unauthenticated."))) {

    if(localStorage.getItem("role")!==null){
    localStorage.clear();
    // return "";
    return window.location.href = `${process.env.PUBLIC_URL}/stafflogin`
    }else{
      localStorage.clear();
    // return "";
    return window.location.href = `${process.env.PUBLIC_URL}/adminlogin`
    }
    // Route.push('/userlogin');
    // return <Navigate exact to={`${process.env.PUBLIC_URL}/userlogin`} />
    // return <Redirect to={`${process.env.PUBLIC_URL}/userlogin`} />
  }
  //return Promise.reject(error.response.data)
})

const PrivateRoute = () => {
  const authValidate = localStorage.getItem("apiToken");
  // let cookies = document.cookie
  //                .split(';')
  //                .map( cookie => cookie.split('='))
  //                .reduce((accumulator, [Key, value]) => 
  //                      ({ ...accumulator, [Key.trim()] : decodeURIComponent(value)}), {});
  // const authValidate = cookies.apiToken;

  return (
    authValidate !== null ?
      <Outlet />
      :
        localStorage.getItem("staff") === "staff" ?
        <Navigate exact to={`${process.env.PUBLIC_URL}/stafflogin`} />
        :
        window.location.href="/adminlogin"
  );
}

export default PrivateRoute;