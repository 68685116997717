import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Media,
  Container
} from "reactstrap";
import { ServerUrl } from "../../Constant";
import { H6, Btn, Breadcrumbs } from "../../AbstractElements";
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType,
  getStaff, getStaffPermission, getLoginStaffId }
from '../../Constant/LoginConstant';

import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
import { encode, decode, apiencrypt, apidecrypt } from "../../helper";
import { useSearchParams, Link } from "react-router-dom";
import AddShiftModal from './AddShiftModal';

import flatIcon from './../../assets/images/dms/icons/flat.svg'
import factorIcon from './../../assets/images/dms/icons/factor.svg'

const EditSAMDetails = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [reportId, setReportId] = useState(decode(searchParams.get("id")));

    const [styleNoList, setStyleNoList] = useState([]);
    const [productionTypeList, setProductionTypeList] = useState(['Sewing', 'Cutting']);
    const [shiftList, setShiftList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [lineNoList, setLineNoList] = useState([]);
    const [breakTimeList, setBreakTimeList] = useState(['1', '2', '3']);

    const [styleNo, setStyleNo] = useState("");
    const [productionType, setProductionType] = useState("");
    const [shiftValue, setShiftValue] = useState("");
    const [unitValue, setUnitValue] = useState("");
    const [samValue, setSamValue] = useState("");
    const [supervisorName, setSupervisorName] = useState("");
    const [lineNoValue, setLineNoValue] = useState("");
    const [noOfTailors, setNoOfTailors] = useState("");
    const [noOfHelpers, setNoOfHelpers] = useState("");
    const [tailorSalary, setTailorSalary] = useState("");
    const [helperSalary, setHelperSalary] = useState("");
    const [fromTime, setFromTime] = useState("");
    const [toTime, setToTime] = useState("");
    const [breakTime, setBreakTime] = useState("");
    const [factoryRevenueFactor, setFactoryRevenueFactor] = useState("");
    const [alertPercentage, setAlertPercentage] = useState("");

    const [additionalFromTime, setAdditionalFromTime] = useState("");
    const [additionalToTime, setAdditionalToTime] = useState("");
    const [additionalTailorSalary, setAdditionalTailorSalary] = useState("");
    const [additionalHelperSalary, setAdditionalHelperSalary] = useState("");

    const [shiftId, setShiftId] = useState("");
    const [unitId, setUnitId] = useState("");
    const [supervisorId, setSupervisorId] = useState("");
    const [lineNoId, setLineNoId] = useState("");

    const [orderNo, setOrderNo] = useState("");
    const [orderId, setOrderId] = useState("")
    const [currentDate, setCurrentDate] = useState("");
    const [additionalData, setAdditionalData] = useState(false);
    const [breakFreeStatus, setBreakFreeStatus] = useState(false);
    const [yes, setYes] = useState();
    const [no, setNo] = useState();
    const [flat, setFlat] = useState();
    const [factor, setFactor] = useState();
    const [salaryType, setSalaryType] = useState(true);

    const [getMasterData, setGetMasterData] = useState([]);

    const [errors, setValidErrors] = useState({});

    const [modalAddShift, setModalAddShift] = useState(false);

    const toggleAddShift = () => setModalAddShift(!modalAddShift);

    useEffect(() => 
    {
        setCurrentDate(new Date().toJSON().slice(0, 10));

        var apiInputParams = {};
        apiInputParams['company_id'] = getLoginCompanyId;
        apiInputParams['workspace_id'] = getWorkspaceId;
  
        axios
        .post(ServerUrl + "/get-styles",apiencrypt(apiInputParams))
        .then( (response) => {
            response.data = apidecrypt(response.data)
            setStyleNoList(response.data.data);
        })
  
        axios
          .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
          .then((response) => {
            response.data = apidecrypt(response.data);
            setGetMasterData(response.data.data);
          })

        var apiInputParams = {};
        apiInputParams['report_id'] = reportId;

        axios
        .post(ServerUrl + "/sam-report-setting-details", apiencrypt(apiInputParams))
        .then((response) => {
            response.data = apidecrypt(response.data);
            setStyleNo(response.data.data.style_no)
            setOrderId(response.data.data.order_id)
            setProductionType(response.data.data.production_type)
            setShiftValue(response.data.data.shift_value)
            setUnitValue(response.data.data.unit_value)
            setSamValue(response.data.data.sam_value)
            setSupervisorName(response.data.data.supervisor_name)
            setNoOfTailors(response.data.data.no_of_tailors)
            setNoOfHelpers(response.data.data.no_of_helpers)
            setTailorSalary(response.data.data.tailor_salary)
            setHelperSalary(response.data.data.helper_salary)
            setFromTime(response.data.data.from_time)
            setToTime(response.data.data.to_time)
            setBreakTime(response.data.data.break_hours)
            setBreakFreeStatus(response.data.data.break_hours == 0 ? true : false)
            setFactoryRevenueFactor(response.data.data.factory_factor);
            setAlertPercentage(response.data.data.alert_percentage);
    
            setShiftId(response.data.data.shift_id)
            setUnitId(response.data.data.unit_id)
            setSupervisorId(response.data.data.supervisor_id)
            setLineNoId(response.data.data.line_no_id)
    
            if( response.data.data.additional_hours == 0  ){
              setYes(false)
              setNo(true)
            } else{
              setYes(true)
              setNo(false)
            }
    
            if( response.data.data.additional_salary_type == 1  ){
              setSalaryType(true)
              setFlat(true)
              setFactor(false)
            } else{
              setSalaryType(false)
              setFlat(false)
              setFactor(true)
            }
            setAdditionalData(response.data.data.additional_hours == 0 ? false : true)
            setAdditionalFromTime(response.data.data.additional_from_time)
            setAdditionalToTime(response.data.data.additional_to_time)
            setSalaryType(response.data.data.additional_salary_type)
            setAdditionalTailorSalary(response.data.data.additional_tailor_salary)
            setAdditionalHelperSalary(response.data.data.additional_helper_salary)
        })
    }, []);


    const validation = () => {
      let errors = {};
      if (!styleNo) {
        errors.styleNo = t("styleNoValidationErrorMessage");
      }
      if (!productionType) {
        errors.productionType = t("plsSelProductionType");
      }
      if (!shiftValue) {
        errors.shiftValue = t("plsSelShift");
      }
      if (!unitValue) {
        errors.unitValue = t("plsSelUnit");
      }
      if (!samValue) {
        errors.samValue = t("plsSelSamVal");
      }
      if (!supervisorName) {
        errors.supervisorName = t("plsSelSupervisorName");
      }
      if (!lineNoValue) {
        errors.lineNoValue = t("plsSelLineNo");
      }
      
      if (!noOfTailors) {
        errors.noOfTailors = t("plsEnterNoofTailors");
      }
      if (!noOfHelpers) {
        errors.noOfHelpers = t("plsEnterNoofHelpers");
      }
      if (!tailorSalary) {
        errors.tailorSalary = t("plsEnterTailorSalry");
      }
      if (!helperSalary) {
        errors.helperSalary = t("plsEnterHelperSalry");
      }
      if(breakFreeStatus === false){
        if (!breakTime) { errors.breakTime = t("plsSelBrkTime"); }
      }

      setValidErrors(errors);
      return errors;
    };

    const checkBoxValues = ( name ) => {
        var cbs = document.getElementsByName("checkBox");
        for (var i = 0; i < cbs.length; i++) {
            cbs[i].checked = false;
        }
        document.getElementById(name).checked = true;
        if( name == 'yes' ){
            setAdditionalData(true);
        } else {
            setAdditionalData(false);
        }
    };

    const clearValues = ( ) => {
        setStyleNo(() => "");
        setProductionType(() => "");
        setShiftValue(() => "");
        setUnitValue(() => "");
        setSamValue(() => "");
        setSupervisorName(() => "");
        setLineNoValue(() => "");
        setNoOfTailors(() => "");
        setNoOfHelpers(() => "");
        setTailorSalary(() => "");
        setHelperSalary(() => "");
        setFromTime(() => "");
        setToTime(() => "");
        setBreakTime(() => "");
    };

    const onhandleSubmit = ( ) => {
      // let retval = validation();
      // if (Object.keys(retval).length == 0) {

        var apiInputParams = {};
        apiInputParams['style_no'] = styleNo;
        apiInputParams['production_type'] = productionType;
        apiInputParams['shift_value'] = shiftValue;
        apiInputParams['unit_value'] = unitValue;
        apiInputParams['sam_value'] = samValue;
        apiInputParams['supervisor_name'] = supervisorName;
        apiInputParams['line_no'] = lineNoValue;
        apiInputParams['no_of_tailors'] = noOfTailors;
        apiInputParams['no_of_helpers'] = noOfHelpers;
        apiInputParams['tailor_salary'] = tailorSalary;
        apiInputParams['helper_salary'] = helperSalary;
        apiInputParams['from_time'] = fromTime;
        apiInputParams['to_time'] = toTime;
        apiInputParams['break_time'] = breakTime;

        apiInputParams['report_id'] = reportId;
        apiInputParams['user_id'] = getLoginUserId;
        apiInputParams['staff_id'] = getLoginStaffId;
        apiInputParams['additional_hours'] = additionalData == false ? '0' : '1';

        apiInputParams['additional_from_time'] = additionalFromTime;
        apiInputParams['additional_to_time'] = additionalToTime;
        apiInputParams['additional_salary_type'] = salaryType === true ? 1 : 2;
        apiInputParams['additional_tailor_salary'] = additionalTailorSalary;
        apiInputParams['additional_helper_salary'] = additionalHelperSalary;

        axios
        .post(ServerUrl + "/update-sam-report-setting", apiencrypt(apiInputParams))
        .then((response) => {
            response.data = apidecrypt(response.data);
            if (response.data.status_code === 200) {
              Swal.fire({
                title: t(response.data.message),
                icon: "success",
                button: t("okLabel"),
                timer: 5000
              })
                .then((result) => {
                  if (result.isConfirmed) {
                    //  setTimeout(() => {
                       window.location.href = "/viewsamdetails";
                    //  }, 100);
                  }
                  setTimeout(() => {
                    window.location.href = "/viewsamdetails";
                  }, 100);
                });
            }
        })
      // }
    };

  return (
    <Fragment>
          <Row className="pgbgcolor">
              <Breadcrumbs 
                  mainTitle={t("editSamDetails")}
                  parent={t("editSamDetails")}
                  title={t("editSamDetails")}
                  subTitle={t("editSamDetails")}
              />
          </Row>
        <Container fluid={true} className="general-widget topaln">
          <Card>
            <CardBody>
              <Form>        
                  <Row className="g-12">
                      <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("styleOrderNo")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                              type="select"
                              placeholder={t("selectStyleno")}
                              disabled
                              onChange={(e) => {setStyleNo(e.target.value)}}>
                                <option value="">{t("selectStyleno")}</option>
                                { styleNoList.length > 0 
                                  ?
                                    styleNoList.map((styleList) => 
                                    styleList.style_no == styleNo ?  
                                      (
                                          <option selected value={styleList.id + "&&" + styleList.order_no + "&&" + styleList.style_no}>
                                              {styleList.style_no} 
                                              ({styleList.order_no})
                                          </option>
                                      )
                                      :
                                      (   <option value={styleList.id + "&&" + styleList.order_no + "&&" + styleList.style_no}>
                                              {styleList.style_no} 
                                              ({styleList.order_no})
                                          </option>
                                      )
                                      )
                                  :
                                    ""
                                }
                              </Input>
                              {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                          </InputGroup>
                          {errors.styleNo && (
                              <span className="error-msg">{t(errors.styleNo)}</span>
                          )}
                          </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("productionType")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                              type="select"
                              placeholder={t("selectProductionType")}
                              defaultValue={productionType}
                              disabled
                              onChange={(e) => {setProductionType(e.target.value)}} >
                                <option value="">{t("selectProductionType")}</option>
                              { productionTypeList.length > 0 
                              ?  
                                    productionTypeList.map((data) => 
                                    data == productionType 
                                    ?  
                                    ( <option selected value={data}>{data}</option> )
                                    :
                                    ( <option value={data}>{data}</option> )
                                  )
                              :
                              ""} 
                              </Input>
                          </InputGroup>
                          {errors.productionType && (
                              <span className="error-msg">{t(errors.productionType)}</span>
                          )} 
                          </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("shift")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                              type="select"
                              placeholder={t("selectShift")}
                              defaultValue={shiftValue}
                              onChange={(e) => {setShiftValue(e.target.value)}}
                              disabled>
                             <option value="">{t("selectShift")}</option>
                             { getMasterData.length > 0 
                              ?
                                getMasterData
                                .filter(data => data.type == "Shift")
                                .map(mapData =>  
                                  (
                                    <>
                                    {mapData.id == shiftId ?
                                    <option 
                                        selected
                                        value={mapData.id +"&&"+ mapData.value+"&&"+mapData.shift_from_time+"&&"+mapData.shift_end_time}>
                                        {mapData.value}
                                    </option>
                                  :
                                    <option 
                                      value={mapData.id +"&&"+ mapData.value+"&&"+mapData.shift_from_time+"&&"+mapData.shift_end_time}>
                                      {mapData.value}
                                    </option>
                                    }
                                    </>))
                              :
                              ""
                            }
                              </Input>
                              {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                              {/* <InputGroupText>
                                  <span
                                  className="btn selectbtn"
                                  onClick={toggleAddShift}
                                  >
                                  +
                                  </span>
                              </InputGroupText>  */}
                              {/* : "" */}
                              {/* } */}
                              {/* <AddShiftModal
                              modal={modalAddShift}
                              setModalAddShift={setModalAddShift}
                              toggle={toggleAddShift}
                              />  */}
                          </InputGroup>
                          {errors.shiftValue && (
                              <span className="error-msg">{t(errors.shiftValue)}</span>
                          )}
                          </FormGroup>
                      </Col> 
                  </Row>

                  <Row className="g-12">
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                            <Label>{t("unit")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="select"
                                placeholder={t("selectUnit")}         
                                disabled
                                onChange={(e) => {setUnitValue(e.target.value)}}>
                                <option value="" >{t("selectUnit")}</option>
                                { getMasterData.length > 0 
                                ?
                                  getMasterData
                                  .filter(data => data.type == "Unit")
                                  .map(mapData =>  
                                    (
                                      <>
                                      {mapData.id == unitId ?
                                       <option selected value={mapData.id + "&&"+mapData.value }>{mapData.value}</option>
                                    :
                                       <option value={mapData.id + "&&"+mapData.value }>{mapData.value}</option>
                                      }
                                      </>)
                                      )
                                :
                                ""
                              }
                              </Input>
                              {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                                {/* <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                  //   onClick={toggle}
                                    >
                                    +
                                  </span>
                                </InputGroupText>  */}
                                {/* : "" */}
                              {/* } */}
                              {/* <AddFactoryModal
                                modal={modal}
                                toggle={toggle}
                                companyId={company_id}
                                workspaceId={workspace_id}
                                factory={setFactories}
                              /> */}
                            </InputGroup>
                            {errors.unitValue && (
                              <span className="error-msg">{t(errors.unitValue)}</span>
                            )}
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                            <Label>{t("samValue")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="text"
                                placeholder={t("plsEnterSamValue")}
                                defaultValue={samValue}
                                disabled
                                onChange={(e) => {setSamValue(e.target.value)}}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("supervisor")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                name=""
                                type="select"
                                placeholder={t("selectSupervisor")}
                                aria-label=""
                                defaultValue={supervisorName}
                                disabled
                                onChange={(e) => {setSupervisorName(e.target.value)}}
                              >
                              <option value="" >{t("selectSupervisor")}</option>
                              { getMasterData.length > 0 
                                ?
                                  getMasterData
                                  .filter(data => data.type == "Supervisor")
                                  .map(mapData =>  
                                    (
                                      <>
                                      {mapData.id == supervisorId ?
                                       <option selected value={mapData.id + "&&"+mapData.value }>{mapData.value}</option>
                                    :
                                       <option value={mapData.id + "&&"+mapData.value }>{mapData.value}</option>
                                      }
                                      </>))
                                :
                                ""
                              }
                              </Input>
                              {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                                {/* <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                  //   onClick={toggle}
                                    >
                                    +
                                  </span>
                                </InputGroupText>  */}
                                {/* : "" */}
                              {/* } */}
                              {/* <AddFactoryModal
                                modal={modal}
                                toggle={toggle}
                                companyId={company_id}
                                workspaceId={workspace_id}
                                factory={setFactories}
                              /> */}
                            </InputGroup>
                            {errors.supervisorName && (
                              <span className="error-msg">{t(errors.supervisorName)}</span>
                            )} 
                          </FormGroup>
                        </Col> 
                  </Row>

                  <Row className="g-12">
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("lineNo")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                name=""
                                type="select"
                                placeholder={t("selectLineNo")}
                                aria-label=""
                                defaultValue={lineNoValue}
                                disabled
                                onChange={(e) => {setLineNoValue(e.target.value)}}
                              >
                              <option value="">{t("selectLineNo")}</option>
                              { getMasterData.length > 0 ?
                              //  getMasterData
                                // .filter(data => data.type == "Line")
                                // .map(mapData => {
                                //   ((unitId == mapData.line_unit)) 
                                //   ? 
                                //   console.log(unitId, unitValue, "mapData", mapData)
                                //   :
                                //     ""
                                //   })
                                getMasterData
                                .filter(data => data.type == "Line")
                                .map(mapData => (
                                  ((unitId == mapData.line_unit) && ( mapData.line_unit != 0)) 
                                  ? 
                                  <>
                                  { 
                                  lineNoId == mapData.id 
                                  ?
                                    <option 
                                      selected
                                      value={mapData.id +"&&"+ mapData.value}>
                                      {mapData.value}
                                    </option>
                                  :
                                    <option 
                                      value={mapData.id +"&&"+ mapData.value}>
                                      {mapData.value}
                                    </option>
                                  }
                                  </>
                                  :
                                    ""
                                ))
                              :
                              ""
                              }
                              </Input>
                              {/* {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ? */}
                                {/* <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                  //   onClick={toggle}
                                    >
                                    +
                                  </span>
                                </InputGroupText>  */}
                                {/* : "" */}
                              {/* } */}
                              {/* <AddFactoryModal
                                modal={modal}
                                toggle={toggle}
                                companyId={company_id}
                                workspaceId={workspace_id}
                                factory={setFactories}
                              /> */}
                            </InputGroup>
                            {errors.lineNoValue && (
                              <span className="error-msg">{t(errors.lineNoValue)}</span>
                            )} 
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("noOfTailors")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="text"
                                placeholder={t("plsEnterNoOfTailors")}
                                defaultValue={noOfTailors}
                                disabled
                                onChange={(e) => {setNoOfTailors(e.target.value)}}>
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("noOfHelpers")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="text"
                                placeholder={t("plsEnterNoOfHelpers")}
                                defaultValue={noOfHelpers}
                                disabled
                                onChange={(e) => {setNoOfHelpers(e.target.value)}}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 

                  </Row>

                  <Row className="g-12">
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label> {t("tailorSalary")} </Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="text"
                                placeholder={t("plsEnterTailorSalary")}
                                defaultValue={tailorSalary}
                                disabled
                                onChange={(e) => {setTailorSalary(e.target.value)}}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label>{t("helperSalary")}</Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                type="text"
                                placeholder={t("plsEnterHelperSalary")}
                                defaultValue={helperSalary}
                                disabled
                                onChange={(e) => {setHelperSalary(e.target.value)}}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label> {t("date")} </Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                className="toggleButton"
                                type="date"
                                placeholder={t("date")}
                                disabled
                                defaultValue={currentDate}
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 

                  </Row>
  
                  <Row className="g-12">
                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label> {t("fromTime")} </Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                className="form-control digits"
                                type="time"
                                defaultValue={fromTime}
                                onChange={(e) => {setFromTime(e.target.value)}}
                                disabled
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 

                        <Col lg="4" md="4" sm="12">
                          <FormGroup className="">
                          <Label> {t("toTime")} </Label><sup className="font-danger">*</sup>
                            <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                  className="form-control digits"
                                  type="time"
                                  defaultValue={toTime}
                                  onChange={(e) => {setToTime(e.target.value)}}
                                  disabled
                              >
                              </Input>
                            </InputGroup>
                          </FormGroup>
                        </Col> 

                        <Col lg="4" md="4" sm="12">
                          <Row>
                            <Col lg="3" md="3" sm="12">
                            <Label> {t("breakFree")} </Label>
                              <FormGroup>
                                <Media key="1">
                                  <Media body className="text-start icon-state switch-md">
                                    <Label className="switch" name="toggle">
                                      <Input className="toggleButton"
                                        disabled
                                        type="checkbox" name="tolisreq"
                                        value={breakFreeStatus} 
                                        checked={!!breakFreeStatus}
                                        onChange={(e) => { 
                                          setBreakFreeStatus(prevCheck => !prevCheck) 
                                          }} />
                                      <span className="switch-state"></span>
                                    </Label>
                                  </Media>
                                </Media>
                              </FormGroup>
                            </Col>

                            {  breakFreeStatus === false ? 
                              <Col lg="9" md="9" sm="12">
                                <FormGroup>
                                <Label>{t("breakTime")}</Label><sup className="font-danger">*</sup>
                                <InputGroup>
                                    <Input
                                    style={{  cursor: 'pointer' }}
                                    className={`${errors.breakTime && 'select-error-valid'}  form-control`}
                                    name="breakTime"
                                    id="breakTime"
                                    type="select"
                                    placeholder={t("selectBreakTime")}
                                    onChange={(e) => {setBreakTime(e.target.value)}}
                                    >
                                    <option value="" >{t("selectBreakTime")}</option>
                                    { breakTimeList.length > 0 ?  
                                    breakTimeList.map((data) => 
                                      data == breakTime
                                      ?
                                      (<option selected value={data}>{data}</option>)
                                      :
                                      (<option value={data}>{data}</option>)
                                    )
                                    :
                                    ""}
                                    </Input>
                                </InputGroup>
                                {errors.breakTime && (
                                    <span className="error-msg">{t(errors.breakTime)}</span>
                                )} 
                                </FormGroup>
                              </Col>
                              :
                              ""
                            }

                          </Row>
                        </Col>
                  </Row>


                  <Row>
                <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("factRevFactor")}</Label>
                          {/* <sup className="font-danger">*</sup> */}
                          <InputGroup>
                            <Input
                              // className={`${errors.factoryRevenueFactor && 'select-error-valid'}  form-control`}
                              disabled
                              name="factoryRevenueFactor"
                              id="factoryRevenueFactor"
                              type="text"
                              defaultValue={factoryRevenueFactor}
                              placeholder={t("plsEnterFactRevFactor")}
                              onChange={(e) => {setFactoryRevenueFactor(e.target.value)}}
                              onKeyPress={(e) => {handleKeyPress(e, "factoryRevenueFactory")}}
                            >
                            </Input>
                          </InputGroup>
                          {/* {errors.factoryRevenueFactor && (
                            <span className="error-msg">{t(errors.factoryRevenueFactor)}</span>
                          )}   */}
                        </FormGroup>
                      </Col> 
                      <Col lg="4" md="4" sm="12">
                        <FormGroup className="">
                          <Label>{t("alertPerc")}</Label>
                          {/* <sup className="font-danger">*</sup> */}
                          <InputGroup>
                            <Input
                              // className={`${errors.alertPercentage && 'select-error-valid'}  form-control`}
                              disabled
                              name="alertPercentage"
                              id="alertPercentage"
                              type="number"
                              placeholder={t("plsEnterAlertPerc")}
                              defaultValue={alertPercentage}
                              onChange={(e) => {setAlertPercentage(e.target.value)}}
                              onKeyPress={(e) => {handleKeyPress(e)}}
                            >
                            </Input>
                            <InputGroupText className="input-group-symbol"> 
                              <i className="fa fa-percent m-l-10 m-t-5"></i>
                            </InputGroupText> 
                          </InputGroup>
                          {/* {errors.alertPercentage && (
                            <span className="error-msg">{t(errors.alertPercentage)}</span>
                          )}  */}
                        </FormGroup>
                      </Col> 
                </Row>

                  <Row>
                        <Col>
                          <Row className="g-12" style={{ width: '35%' }}>
                          <Label className="m-t-10"> {t("additionalHours")} </Label>
                          <Col xl="6" lg="6" md="6" sm="12" xs="12"className="checkbox checkbox-primary">
                                  <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      name="checkBox"
                                      value="yes"
                                      id="yes"
                                      defaultChecked={yes}
                                      onChange={ (e) => {checkBoxValues('yes')} }/>
                                  <Label
                                      for="yes"
                                      className="form-label">
                                     {t("Yes")}
                                  </Label>
                              </Col>
                              <Col xl="6" lg="6" md="6" sm="12" xs="12"className="checkbox checkbox-primary">
                                      <Input
                                          type="checkbox"
                                          className="form-check-input"
                                          name="checkBox"
                                          value="no"
                                          id="no"
                                          defaultChecked={no}
                                          onChange={ (e) => {checkBoxValues('no')}}/>
                                      <Label
                                          for="no"
                                          className="form-label">
                                          {t("NO")}
                                      </Label>
                                
                              </Col>
                          </Row>
                        </Col>       
                      { additionalData === true ? 
                          <Col>
                            <Row className="g-12" style={{ width: '35%' }}>
                                <Label className="m-t-10">  {t("salaryType")}  </Label>
                                  <Col lg="6" md="6">
                                    <Label className="m-t-10 m-l-5">
                                        <div className="radio radio-primary" >
                                            <Input  id="radioinline1" type="radio" 
                                                    name="radio1" value="option1" 
                                                    onChange={() => { setSalaryType(!salaryType)}}
                                                    defaultChecked={flat} 
                                            />
                                            <Label className="mb-0" for="radioinline1">{Option}
                                                <span className="digits"> {t("flat")} </span>
                                            </Label>
                                        </div>
                                    </Label>
                                  </Col>
                                  <Col lg="6" md="6">
                                    <Label className="m-t-10">
                                      <div className="radio radio-primary">
                                          <Input  id="radioinline2" type="radio" 
                                                  defaultChecked={factor} 
                                                  name="radio1" value="option1" 
                                                  onChange={() => { setSalaryType(!salaryType)}}
                                          />
                                          <Label className="mb-0" for="radioinline2">{Option}
                                                <span className="digits"> {t("factor")}  </span>
                                          </Label>
                                      </div>
                                    </Label>
                                  </Col>
                            </Row>
                          </Col>
                          :
                          "" }
                  </Row>

                  { additionalData === true ? 
                      <Row className="g-12">
                          <Col lg="3" md="4" sm="12">
                          <FormGroup className="">
                              <Label> {t("fromTime")} </Label><sup className="font-danger">*</sup>
                              <InputGroup>
                              <Input
                              //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                  className="form-control digits"
                                  type="time"
                                  defaultValue={additionalFromTime}
                                  onChange={(e) => {setAdditionalFromTime(e.target.value)}}
                              >
                              </Input>
                              </InputGroup>
                          </FormGroup>
                          </Col> 

                          <Col lg="3" md="4" sm="12">
                              <FormGroup className="">
                                  <Label> {t("toTime")}  </Label><sup className="font-danger">*</sup>
                                  <InputGroup>
                                  <Input
                                  //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                      className="form-control digits"
                                      type="time"
                                      defaultValue={additionalToTime}
                                      onChange={(e) => {setAdditionalToTime(e.target.value)}}
                                  >
                                  </Input>
                                  </InputGroup>
                              </FormGroup>
                          </Col> 

                          <Col lg="3" md="4" sm="12">
                              <FormGroup>
                                  <Row>
                                    <Col>
                                      <Label> {t("tailorSalary")} </Label>
                                      <sup className="font-danger">*</sup>
                                    </Col>
                                  </Row>
                                  <InputGroup>
                                        <Input
                                        //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                            type="text"
                                            defaultValue={additionalTailorSalary}
                                            placeholder={t("plsEnterTailorSalary")}
                                            onChange={(e) => 
                                              {
                                                setAdditionalTailorSalary(e.target.value)
                                              }}>
                                        </Input>
                                    {salaryType === false ? 
                                      <InputGroupText className="input-group-symbol"> 
                                      <img src={factorIcon}  width="30px"/>
                                    </InputGroupText> 
                                :
                                    <InputGroupText className="input-group-symbol"> 
                                        <img src={flatIcon} width="30px" />
                                    </InputGroupText> 
                                    }
                                  </InputGroup>
                              </FormGroup>
                            </Col> 

                            <Col lg="3" md="4" sm="12">
                              <FormGroup className="">
                                <Label>  {t("helperSalary")}  </Label><sup className="font-danger">*</sup>
                                  <InputGroup>
                                  <Input
                                  //   className={`${errors.factory && 'select-error-valid'}  form-control`}
                                      defaultValue={additionalHelperSalary}
                                      name=""
                                      type="text"
                                      placeholder={t("plsEnterHelperSalary")}
                                      onChange={(e) => {setAdditionalHelperSalary(e.target.value)}}
                                  >
                                  </Input>
                                  {salaryType === false ? 
                                        <InputGroupText className="input-group-symbol"> 
                                        <img src={factorIcon}  width="30px"/>
                                      </InputGroupText> 
                                  :
                                      <InputGroupText className="input-group-symbol"> 
                                          <img src={flatIcon} width="30px" />
                                      </InputGroupText> 
                                    }
                                  </InputGroup>
                              </FormGroup>
                            </Col> 
                      </Row>
                  :
                  ""
                  }
              
                  <Row className="f-right m-t-10">
                      <Col>
                          <Button
                            className="btn btn-primary m-r-20 secondaryBtn"
                            onClick={() =>  
                              {
                              // clearValues()
                              window.location.href="/viewsamdetails"
                              }}>
                          {t("goBack")}
                          </Button>
              
                          <Button
                          className="btn btn-primary"
                          onClick={() => {onhandleSubmit()}}>
                          {t("saveAndContinue")}
                          </Button>
                      </Col>
                  </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
    </Fragment>
  );
};

export default EditSAMDetails;
/* Code By : Ramanan M */