import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Media
} from "reactstrap";
import { ServerUrl } from "../../../Constant";
import { H6, Btn } from "../../../AbstractElements";
import AddFactoryModal from "./AddFactoryModal";
import AddBuyerModal from "./AddBuyerModal";
import AddPCUModal from "./AddPCUModal";
import AddFabaricModal from "./AddFabaricModal";
import AddOrderCategoryModal from "./AddOrderCategoryModal";
import AddArticleModal from "./AddArticleModal";
import AddUnitsModal from './AddUnitsModal';
import OrderHeaders from "./OrderHeaders";
import axios from "axios";
import {
  getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType,
  getStaff, getStaffPermission, getLoginStaffId
}
  from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import Swal from "sweetalert2";
//import useForm from "./OrderRegistrationForm";
//import {validate} from './OrderCreateValidation';
import { encode, apiencrypt, apidecrypt } from "../../../helper";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import weekends from "react-multi-date-picker/plugins/highlight_weekends";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import DatePickerHeader from "react-multi-date-picker/plugins/date_picker_header";
const OrderRegistration = (props) => {

  // const {
  //   values,
  //   errors,
  //   handleSubmit,
  //   handleChange,

  // } = useForm(login, validate);

  // function login() {
  //  // console.log('No errors, submit callback called!',values,values.orderNo);
  //  //onhandleSubmit(values);
  // }

  const { t } = useTranslation();

  const workspace_id = getWorkspaceId;
  const company_id = getLoginCompanyId;
  const user_id = getLoginUserId;
  const dataToSendAtStarting = {
    "company_id": company_id,
    "workspace_id": workspace_id
  };
  const [modal, setModal] = useState(false);
  const [modalpcu, setModalpcu] = useState(false);
  const [modalBuyer, setModalBuyer] = useState(false);
  const [modalfabaric, setModalfabaric] = useState(false);
  const [modalcat, setModalcat] = useState(false);
  const [modalart, setModalart] = useState(false);
  const [modalUnits, setModalUnits] = useState(false);
  const [factories, setFactories] = useState([]);
  const [buyers, setBuyers] = useState([]);

  const [Pcus, setPcus] = useState([]);
  const [fabrics, setFabrics] = useState([]);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [incomeTerms, setIncomeTerms] = useState([]);
  const [orderPriority, setOrderPriority] = useState(['High','Medium','Low']);
  const [orderPriorityValue, setOrderPriorityValue] = useState('');
  const deliveries = [];
  const [currencies, setCurrencies] = useState([]);
  const [factory, setFactory] = useState('');
  const [buyer, setBuyer] = useState('');
  const [pcu, setPcu] = useState('');
  const [fabric, setFabric] = useState('');
  const [category, setCategory] = useState('');
  const [article, setArticle] = useState('');
  const [orderNo, setOrderNo] = useState('');
  const [styleNo, setStyleNo] = useState('');
  const [currency, setCurrency] = useState('');
  
  const [price, setPrice] = useState('');
  const [incomeTerm, setIncomeTerm] = useState('');
  const [inquiryDate, setInquiryDate] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [totalDelivery, setDelivery] = useState('1');
  const [quantityWise, setQuantityWise] = useState('SKU-Wise')
  const [toleranceVolume, setToleranceVolume] = useState('');
  const [tolerancePercentage, setTolerancePercentage] = useState('');
  const [tolerancereq, setTolerancereq] = useState(false);
  const [value, setValue] = useState([]);
  const [getWeekOff, setWeekoff] = React.useState([]);
  
  const [unitdetails, setunitdetails] = useState('');
  const [ofUnits, setOfUnits] = useState([]);

  const [ getUnitsList, setGetUnitsList] = useState([]);
  
  const toggle = () => setModal(!modal);
  const togglepcu = () => setModalpcu(!modalpcu);
  const toggleBuyer = () => setModalBuyer(!modalBuyer);
  const togglefabaric = () => setModalfabaric(!modalfabaric);
  const togglecat = () => setModalcat(!modalcat);
  const toggleart = () => setModalart(!modalart);
  const toggleUnits = () => setModalUnits(!modalUnits);


  const date = new Date();
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);
  
  var requestData;

  const inputParamsUser = {};
  inputParamsUser['company_id'] = getLoginCompanyId;
  inputParamsUser['workspace_id'] = getWorkspaceId;
  inputParamsUser['user_id'] = getLoginUserId;
  inputParamsUser['type'] = 'Order';

  const inputParamsStaff = {};
  inputParamsStaff['company_id'] = getLoginCompanyId;
  inputParamsStaff['workspace_id'] = getWorkspaceId;
  inputParamsStaff['staff_id'] = getLoginStaffId;
  inputParamsStaff['type'] = 'Order';

  var getInputWeekOff = {}
  getInputWeekOff['company_id'] = getLoginCompanyId;
  getInputWeekOff['workspace_id'] = getWorkspaceId;

  { getStaff === "Staff" ? requestData = inputParamsStaff : requestData = inputParamsUser }

  useEffect(() => {

      document.getElementById('totalDeliveries').selectedIndex = 0;

      axios
      .post(ServerUrl + '/validate-plan', apiencrypt(requestData))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            icon: "warning",
            button: t("okLabel"),
            allowOutsideClick: false
          })
            .then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/dashboard';
              }
            })
        }
      }
      ,[]);

      axios
      .post(ServerUrl + "/get-all-order-details", apiencrypt(dataToSendAtStarting))
      .then((response) => {
          response.data = apidecrypt(response.data);
          setArticles(response.data.data.article)
          setBuyers(response.data.data.buyers)
          setCategories(response.data.data.category)
          setCurrencies(response.data.data.currencies)
          setFabrics(response.data.data.fabric)
          setFactories(response.data.data.factories)
          setIncomeTerms(response.data.data.incomeTerms)
          setPcus(response.data.data.pcus)
          setGetUnitsList(response.data.data.units)
      })

      axios
      .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
      .then((response) => {
        response.data = apidecrypt(response.data)
        let wk_of = [];
        for(let i = 0; i < (response.data.data).length; i++) {
          wk_of[i] = response.data.data[i].day;
        }
        setWeekoff(wk_of);
      })

      getunitCount();
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Add Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }

  }, []);

  for (let i = 1; i <= 20; i++) {
    deliveries.push(i)
  }
  /*Order Validation  Start*/
  const [errors, setValidErrors] = useState({});
  const orderOnValidation = (val) => {
    let errors = {};

    if (!(orderNo).match(/^[0-9a-zA-Z_-]+$/g) && orderNo.trim() != '') {
      errors.orderNo = ('orderNumberAllowedValidationText');
    }

    // if (!(styleNo).match(/^[0-9a-zA-Z_-]+$/g) && styleNo.trim()!='') {
    //   errors.styleNo = 'Allowed only Numbers,Characters and _ - are allowed';
    // }

    setValidErrors(errors);
    return errors;
  }
 
  const getunitCount = () => {
    let Units = [];
    for (let i = 1; i <= 12; i++) {
      Units.push({ "id": i, "unit": t("packOf")+" "+ i });
        setOfUnits(Units);
    }

  }


  const deliveryDatesList = ( ) => {
    var array = [];
    var deliveryDateValue = "";

    {value.length || value !== null > 0 ? 
      value.map((mapData) => 
        {
          let day = (mapData.day).toString();
          let month = (mapData.monthIndex+1).toString();
          let year = (mapData.year).toString();
          let date = year+"-"+month+"-"+day;
          deliveryDateValue = date;
          array.push(date);
        })
      :
      ""} 
        return [array, deliveryDateValue]
    };

  const RemainingDeliveryDates = () => {

    var remainingDays = totalDelivery;
    if( value!== null ){
      if( value.length !== undefined || value.length > 0 )
      {
        remainingDays = totalDelivery - value.length;
      }
    }
    return  <div  style={{ backgroundColor: '#E5F4F3', color: '#009688', fontWeight: 300, 
                          paddingLeft: '20px', paddingRight: '20px', paddingBottom: '10px' }}> 
                Remaining Delivery Dates : 
               <strong style={{ fontWeight: 600, marginLeft: '5px' }}> {remainingDays} </strong> 
            </div>
  };

  const orderValidation = (deliveryDates) => {
    let errors = {};
    if (!factory && getWorkspaceType != 'Factory') {
     // errors.factory = t("factoryValidationText")
      errors.factory = "factoryValidationText";
    }
    if (!buyer && getWorkspaceType != 'Buyer') {
      errors.buyer = ("buyerValidationText");
    }
    // if (!pcu && getWorkspaceType != 'PCU') {
    //   //errors.pcu = t("pcuValidationText");
    //   errors.pcu = ("pcuValidationText");
    // }
    if (!fabric) {
      errors.fabric = ("fabricValidationText");
    }
    //  if (!category) {
    //   errors.category = 'Please Select a Category';
    //  }
    if (!article) {
      errors.article = ("articleValidationText");
    }

    if (!orderNo.trim()) {
      errors.orderNo = ("orderNumberValidationText");
    } else if (!(orderNo).match(/^[0-9a-zA-Z_-]+$/g)) {
      errors.orderNo = ("orderNumberAllowedValidationText");
    }
    if (!styleNo.trim()) {
      errors.styleNo = ("styleNumberValidationText");
    }
    // else if (!(styleNo).match(/^[0-9a-zA-Z_-]+$/g)) {
    //   errors.styleNo = 'Allowed only Numbers,Characters and _ - are allowed';
    // }
    if((getStaff === "Staff" && getStaffPermission.includes('Add Price')) || getStaff == null){
      if(price!=""){
    if (((price) == 0)) {
      errors.price = ("invalidPriceValidationText");
    }
  }
  }
    if (!totalQuantity.trim()) {
      errors.totquantity = ("totalQuantityRequired");
    } else if (!(totalQuantity).match(/^[0-9]+$/g)) {
      errors.totquantity = ("onlyNumbersAreAllowed");
    }
    else if (parseInt(totalQuantity) <= 0) {
      errors.totquantity = ("minimumQty");
    }

    if (!totalDelivery) {
      errors.totaldelivery = ("totalDeliveryRequired");
    } else if (totalDelivery <= 0 || totalDelivery > 20) {
      errors.totaldelivery = ("totalDeliverymismatch");
    }

    if ((toleranceVolume != '') && (!(toleranceVolume).match(/^[0-9.]+$/g))) {
      errors.toleranceVolume = ("onlyNumbersFormatallowed");
    }
    if ((tolerancePercentage != '') && (!(tolerancePercentage).match(/^[0-9.]+$/g))) {
      errors.tolerancePercentage = ("onlyNumbersFormatallowed");
    }
    if (tolerancereq == true) {
      if ((toleranceVolume == '' || toleranceVolume == 0)) {
        errors.toleranceVolume = ("toleranceQuantityPercentage");
      }
    }
    if (parseInt(totalQuantity) <= parseInt(toleranceVolume) && totalQuantity != '' && toleranceVolume != '') {
      errors.toleranceVolume = ("reachedTotalQuantity");
    }
    if(deliveryDates.length < totalDelivery ){
      errors.deliveryDate = ("plsSelectDelvyDates");
      
    }
    if (!unitdetails) {
      errors.unitdetails = ("unitValidationText");
    }
    setValidErrors(errors);
    return errors;
  };

  function handleKeyPressPrice(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  };

  /*Order Validation End*/
  const onhandleSubmit = () => {
    var data = deliveryDatesList();

    const submitData = {
      "company_id": company_id,
      "workspace_id": workspace_id,
      "user_id": user_id,
      "staff_id": getLoginStaffId ? getLoginStaffId : 0,
      "factory_id": factory,
      "pcu_id": pcu,
      "buyer_id": buyer,
      "order_no": orderNo,
      "style_no": styleNo,
      "order_price": price,
      "income_terms": incomeTerm,
      "currency_type": currency,
      "fabric_id": fabric,
      "category_id": category,
      "article_id": article,
      "inquiry_date": inquiryDate,
      "delivery_date": data[1],   
      "delivery_dates": data[0] ? data[0] : "",
      "total_quantity": totalQuantity,
      "no_of_deliverys": totalDelivery,
      "quantity_wise": quantityWise,
      "tolerance_volume": toleranceVolume ? toleranceVolume : "0",
      "tolerance_perc": tolerancePercentage ? tolerancePercentage : "0",
      "is_tolerance_req": tolerancereq == true ? 1 : 0,
      "order_priority":orderPriorityValue,
      "units":unitdetails
    };

    let retval = orderValidation(data[0]);
    if (Object.keys(retval).length == 0) {
      if (tolerancePercentage > 5) {

        Swal.fire({
          title: t('tolerancePercentageGreaterThan5'),
          text: t('areYouSureContinue'),
          icon: "warning",
          showCancelButton: true,
          button: t("okLabel"),
         
        })
          .then((result) => {
            if (result.isConfirmed) {

              axios.post(ServerUrl + "/add-order", apiencrypt(submitData))
                .then((response) => {
                  response.data = apidecrypt(response.data);
                  if (response.data.status_code === 200) {
                    Swal.fire({
                      title: t("orderAddedSuccessfully"),
                      icon: "success",
                      button: t("okLabel"),
                      timer:5000,
                    })
                      .then((result) => {
                         if (result.isConfirmed) {
                            window.location.href = "/ordersku?id=" + encode(response.data.id) + "&quantity=" + encode(response.data.totalQuantity);
                        }
                        setTimeout(() => {
                          window.location.href = "/ordersku?id=" + encode(response.data.id) + "&quantity=" + encode(response.data.totalQuantity);
                        }, 100);
                      });
                  }
                  if (response.data.status_code === 401) {
                    Swal.fire({
                      title:
                        t(response.data.errors.company_id) ||
                        t(response.data.errors.workspace_id) ||
                        t(response.data.errors.order_no )||
                        t(response.data.errors.style_no) ||
                        t(response.data.errors.pcu_id) ||
                        t(response.data.errors.factory_id) ||
                        t(response.data.errors.category_id) ||
                        t(response.data.errors.article_id) ||
                        t(response.data.errors.total_quantity) ||
                        t(response.data.errors.no_of_deliverys) ||
                        t(response.data.errors.order_price),
                      text: t("fieldMissing"),
                      icon: "warning",
                      button: t("okLabel"),
                     
                    });
                  }
                })
            } else {

            }
          });
      } else {

        axios.post(ServerUrl + "/add-order", apiencrypt(submitData))
          .then((response) => {
            response.data = apidecrypt(response.data);
            if (response.data.status_code === 200) {
              Swal.fire({
                title: t("orderAddedSuccessfully"),
                icon: "success",
                button: t("okLabel"),
                timer:5000,
              })
                .then((result) => {
                  if (result.isConfirmed) {
                      window.location.href = "/ordersku?id=" + encode(response.data.id) + "&quantity=" + encode(response.data.totalQuantity);
                  }
                  setTimeout(() => {
                      window.location.href = "/ordersku?id=" + encode(response.data.id) + "&quantity=" + encode(response.data.totalQuantity);
                    }, 100);
                });
            }
            if (response.data.status_code === 401) {
              Swal.fire({
                title:
                  t(response.data.errors.company_id) ||
                  t(response.data.errors.workspace_id) ||
                  t(response.data.errors.order_no) ||
                  t(response.data.errors.style_no) ||
                  t(response.data.errors.pcu_id) ||
                  t(response.data.errors.factory_id) ||
                  t(response.data.errors.category_id) ||
                  t(response.data.errors.article_id) ||
                  t(response.data.errors.total_quantity) ||
                  t(response.data.errors.no_of_deliverys) ||
                  t(response.data.errors.order_price),
                text: t("fieldMissing"),
                icon: "warning",
                button: t("okLabel"),

              });
            }
          })

      }
    }
    else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  };
  
   /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
   function handleKeyPress(e) {
        e.preventDefault();
  }

  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>
          <CardBody style={{ marginBottom:"-20px" }}>
            <OrderHeaders title="addorder" />
          </CardBody>

          <CardBody>
            <Form
            >
              {/*start first row */}
              <Row className="g-12">
                {/*start order details */}
                <Col lg="12" md="12" sm="12">
                  <Row className="g-12">
                  <Col lg="12" md="12" sm="12" xs="12">
                    <span className="subTitleLine f-left"><H6 className="ordersubhead">{t("OrderDetails")}</H6></span>
                    <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                  </Col>
                  </Row>
                  <Row className="g-12">
                    {getWorkspaceType != 'Factory' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup className="">
                          <Label>{t("factory")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.factory && 'select-error-valid'}  form-control`}
                              name="factories"
                              type="select"
                              placeholder={t("factory")}
                              aria-label="factories"
                              defaultValue=""
                              // onChange={handleChange}
                              onChange={(e) => setFactory(e.target.value)}
                            >
                              <option value="" >{t("selectFactory")}</option>
                              { factories.length > 0 ?  
                              factories.map((factory) => (
                                <option key={factory.id} value={factory.id}>{factory.name}</option>
                              ))
                            :
                            ""}
                            </Input>
                            {(getStaff === "Staff" && getStaffPermission.includes('Factory Add')) || getStaff == null ?
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={toggle}>
                                  {t("add")}
                                </span>
                              </InputGroupText> : ""
                            }
                            <AddFactoryModal
                              modal={modal}
                              toggle={toggle}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              factory={setFactories}
                            />
                          </InputGroup>
                          {errors.factory && (
                            <span className="error-msg">{t(errors.factory)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}
                    {getWorkspaceType != 'Buyer' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup className="">
                          <Label> {t("buyer")}</Label><sup className="font-danger">*</sup>
                          <InputGroup>
                            <Input
                              className={`${errors.buyer && 'select-error-valid'}  form-control`}
                              name="buyer"
                              type="select"
                              placeholder={t("buyer")}
                              aria-label="buyer"
                              defaultValue=""
                              //onChange={handleChange}
                              onChange={(e) => setBuyer(e.target.value)}
                            // onChange={(e) => setFactory(e.target.value)}
                            // onChange={(e) => setFactory(e.target.value)}
                            >
                              <option value="" >{t("selectBuyer")}</option>
                              {  buyers.length > 0 ?  
                              buyers.map((buy) => (
                                <option key={buy.id} value={buy.id}>{buy.name}</option>
                              ))
                            :
                            ""}
                            </Input>
                            {(getStaff === "Staff" && getStaffPermission.includes('Buyer Add')) || getStaff == null ?
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={toggleBuyer}
                                >
                                  {t("add")}
                                </span>
                              </InputGroupText> : ""
                            }
                            <AddBuyerModal
                              modal={modalBuyer}
                              toggle={toggleBuyer}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              buyer={setBuyers}
                            />
                          </InputGroup>
                          {errors.buyer && (
                            <span className="error-msg">{t(errors.buyer)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}
                    {getWorkspaceType != 'PCU' ?
                      <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                        <FormGroup>
                          <Label>{t("productionControlUnit")}</Label>
                          {/* <sup className="font-danger">*</sup> */}
                          <InputGroup>
                            <Input
                              type="select"
                              // placeholder={t("selectStatus")}
                              className={`${errors.pcu && 'select-error-valid'}  form-control digits`}
                              name="pcuid"
                              defaultValue=""
                              // onChange={handleChange}
                              onChange={(e) => setPcu(e.target.value)}
                            >
                              <option value="" >
                                {t("selectPCU")}
                              </option>
                              { Pcus.length > 0 ?
                              Pcus.map((pcuv) => (
                                <option key={pcuv.id} value={pcuv.id}>{pcuv.name}</option>
                              ))
                            :
                            ""}
                            </Input>
                            {(getStaff === "Staff" && getStaffPermission.includes('PCU Add')) || getStaff == null ?
                              <InputGroupText>
                                <span
                                  className="btn selectbtn"
                                  onClick={togglepcu}
                                >
                                  {t("add")}
                                </span>
                              </InputGroupText> : ""
                            }
                            <AddPCUModal
                              modal={modalpcu}
                              toggle={togglepcu}
                              companyId={company_id}
                              workspaceId={workspace_id}
                              pcu={setPcus}
                            />
                          </InputGroup>
                          {errors.pcu && (
                            <span className="error-msg">{t(errors.pcu)}</span>
                          )}
                        </FormGroup>
                      </Col> : ""}

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>
                          {t("orderNo")}
                        </Label><sup className="font-danger">*</sup>
                        <input
                          className={`${errors.orderNo && 'error-valid'}  form-control`}
                          id="validationCustom02"
                          type="text"
                          placeholder={t("orderNo")}
                          name="orderNo"
                          autoComplete="off"
                          // onChange={handleChange}

                          onKeyPress={(e) => orderOnValidation(e.target.value)}
                          onKeyUp={(e) => orderOnValidation(e.target.value)}
                          onKeyDown={(e) => orderOnValidation(e.target.value)}
                          onChange={(e) => setOrderNo(e.target.value)}
                        />
                        {errors.orderNo && (
                          <span className="error-msg">{t(errors.orderNo)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("styleNo")}</Label><sup className="font-danger">*</sup>
                        <input
                          className={`${errors.styleNo && 'error-valid'}  form-control`}
                          id="validationCustom02"
                          type="text"
                          placeholder={t("styleNo")}
                          name="styleNo"
                          // onChange={handleChange}
                          //  onKeyPress={(e) => orderOnValidation(e.target.value)}
                          //  onKeyUp={(e) => orderOnValidation(e.target.value)}
                          //  onKeyDown={(e) => orderOnValidation(e.target.value)}

                          onChange={(e) => setStyleNo(e.target.value)}
                        />
                        {errors.styleNo && (
                          <span className="error-msg">{t(errors.styleNo)}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
                {/*start item description details */}
                <Col md="12" lg="12" sm="12" style={{marginTop:"-10px" }}>
                  <Row className="g-12  m-t-20">
                    <Col lg="12" md="12" sm="12" xs="12">
                      <span className="subTitleLine3 f-left">
                        <H6 className="ordersubhead">
                          {t("itemDescriptionArticle")}
                        </H6></span>
                      <span >
                        <hr className="mt-4 mb-4 m-t-10" />
                      </span>
                    </Col>
                  </Row>

                  {/* <Row className="g-12"><Col lg="4" md="6" sm="6" xs="6"> <H6 className="ordersubhead">Item&nbsp;Description&nbsp;/&nbsp;Article</H6></Col><Col lg="8" md="6" sm="6" xs="6"><hr className="mt-4 mb-4 m-t-10" /></Col></Row> */}
                  <Row className="g-12">
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>  {t("fabricType")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                          <Input
                            type="select"
                            // placeholder={t("selectStatus")}
                            className={`${errors.fabric && 'select-error-valid'}  form-control digits`}
                            name="fabric"
                            defaultValue=""
                            // onChange={handleChange}
                            onChange={(e) => setFabric(e.target.value)}
                          >
                            <option value="" disabled>
                              {t("selectFabricType")}
                            </option>
                            {fabrics.map((fabric) => (
                              <option key={fabric.id} value={fabric.id}>{fabric.name}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Fabric Add')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={togglefabaric}
                              >
                                {t("add")}
                              </span>
                            </InputGroupText> : ""
                          }
                          <AddFabaricModal
                            modal={modalfabaric}
                            toggle={togglefabaric}
                            companyId={company_id}
                            workspaceId={workspace_id}
                            fabric={setFabrics}
                          />
                        </InputGroup>
                        {errors.fabric && (
                          <span className="error-msg">{t(errors.fabric)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("category")}</Label>
                        <InputGroup>
                          <Input
                            type="select"
                            // placeholder={t("selectStatus")}
                            className={` ${errors.category && 'select-error-valid'}  form-control digits`}
                            name="category"
                            defaultValue=""
                            // onChange={handleChange}
                            onChange={(e) => setCategory(e.target.value)}
                          >
                            <option value="" >
                              {t("selectCategory")}
                            </option>
                            {categories.map((category) => (
                              <option key={category.id} value={category.id} data-id="${category.id}">{category.name}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Category Add')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={togglecat}>
                                {t("add")}
                              </span>
                            </InputGroupText> : ""
                          }
                          <AddOrderCategoryModal
                            modal={modalcat}
                            toggle={togglecat}
                            companyId={company_id}
                            workspaceId={workspace_id}
                            category={setCategories}
                          />
                        </InputGroup>
                        {errors.category && (
                          <span className="error-msg">{errors.category}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>   {t("articleName")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                          <Input
                            type="select"
                            // placeholder={t("selectStatus")}
                            className={` ${errors.article && 'select-error-valid'}  form-control digits`}
                            name="article"
                            defaultValue=""
                            // onChange={handleChange}
                            onChange={(e) => setArticle(e.target.value)}
                          >
                            <option value="" disabled>
                              {t("selectArticle")}
                            </option>
                            {
                            articles.length > 0 ?  
                            articles.map((article) => (
                              <option key={article.id}value={article.id}>{article.name}</option>
                            ))
                            :
                            ""
                            }
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Article Add')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={toggleart}
                              >
                                {t("add")}
                              </span>
                            </InputGroupText> : ""
                          }
                          <AddArticleModal
                            modal={modalart}
                            toggle={toggleart}
                            companyId={company_id}
                            workspaceId={workspace_id}
                            article={setArticles}
                          />
                        </InputGroup>
                        {errors.article && (
                          <span className="error-msg">{t(errors.article)}</span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end item description details */}
              </Row>
              {/*end first row */}

              {/*start second row*/}
              <Row className="g-12 m-t-10">
                {/*start order details */}
                <Col md="12" lg="12" sm="12"  style={{marginTop:"-15px" }}>
                  <Row className="g-12 m-t-15"><Col lg="12" md="12" sm="12" xs="12"><span className="subTitleLine2 f-left"><H6 className="ordersubhead">{t("commercialDetails")}</H6></span><span ><hr className="mt-4 mb-4 m-t-10" /></span></Col></Row>
                  {/* <Row className="g-12"><Col lg="3" md="4" sm="3" xs="6"> <H6 className="ordersubhead">Commercial&nbsp;Details</H6></Col><Col lg="9" md="8" sm="9" xs="6"><hr className="mt-4 mb-4 m-t-10" /></Col></Row> */}
                  <Row className="g-12">
                  {(getStaff === "Staff" && getStaffPermission.includes('Add Price')) || getStaff == null ?<>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label> {t("currency")} </Label>
                        <Input
                          type="select"
                          // placeholder={t("selectStatus")}
                          className="form-control digits selectheight"
                          name="Currency"
                          defaultValue=""
                          onChange={(e) => setCurrency(e.target.value)}
                        >
                          <option value="" disabled>
                            {t("selectCurrency")}
                          </option>
                          
                          { currencies.length > 0 ?  
                          currencies.map((currency) => (
                           
                            <option key={currency.id} value={currency.name}>{currency.symbol + ' ' + currency.name}</option>
                          ))
                          :
                          ""
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("price")}</Label>
                        
                        <Input
                          placeholder={t("price")}
                          className={`${errors.price && 'error-valid'}  form-control digits`}
                          name="price"
                          type="tel"
                          min="1"
                          maxLength="20"
                          // value={price}
                          // onChange={handlePriceChange}
                          onChange={(e) => setPrice(e.target.value)}
                          onKeyPress={(e) => handleKeyPressPrice(e)}
                        ></Input>
                        {errors.price && (
                          <span className="error-msg">{t(errors.price)}</span>
                        )}
                      </FormGroup>
                    </Col></>:""}

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("units")}</Label><sup className="font-danger">*</sup>
                        <InputGroup>
                          <Input
                            type="select"
                            // placeholder={t("selectStatus")}
                            className={`${errors.unitdetails && 'error-valid'}  form-control digits selectheight`}
                            name="units"
                            defaultValue=""
                            onChange={(e) => setunitdetails(e.target.value)}
                          >
                            <option selected disabled> {t("units")} </option>
                            { 
                              Object.keys(getUnitsList).length > 0 
                              ? 
                                Object.values(getUnitsList)
                                .map((mapData) => {
                                  return(
                                    <>
                                    <option key={mapData.id} value={mapData.id}
                                      title={mapData.name}>
                                      {mapData.name}
                                    </option>
                                    </>
                                  )
                                }) 
                              :
                                ""
                            }
                          </Input>
                          <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={() => { 
                                    toggleUnits()
                                  }}>
                                {t("add")}
                              </span>
                          </InputGroupText> 
                          <AddUnitsModal
                            modal={modalUnits}
                            toggle={toggleUnits}
                            companyId={getLoginCompanyId}
                            workspaceId={getWorkspaceId}
                            staffId={getLoginStaffId}
                            userId={getLoginUserId}
                            setGetUnitsList={setGetUnitsList}
                            toggleType="addEditOrder"
                          />
                        </InputGroup>
                        {errors.unitdetails && (
                          <span className="error-msg">{t(errors.unitdetails)}</span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("incomeTerms")}</Label>
                        <Input
                          type="select"
                          // placeholder={t("selectStatus")}
                          className="form-control digits selectheight"
                          name="Income Terms"
                          defaultValue=""
                          onChange={(e) => setIncomeTerm(e.target.value)}
                        >
                          <option value="" disabled>
                            {t("selectIncomeTerms")}
                          </option>
                          { incomeTerms.length > 0 ?  
                          incomeTerms.map((incomeTerm) => (
                            <option key={incomeTerm.id} value={incomeTerm.id}
                              title={incomeTerm.description}>
                              {incomeTerm.name}
                            </option>
                          ))
                          :
                          ""
                          }
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end order details */}
                {/*start item description details */}
                <Col md="12" lg="12" sm="12">
                  <Row className="g-12  m-t-20">
                    <Col lg="12" md="12" sm="12" xs="12"  style={{marginTop:"-10px" }}>
                      <span className="subTitleLine2 f-left">
                        <H6 className="ordersubhead">{t("quantityDetails")}</H6>
                      </span>
                      <span ><hr className="mt-4 mb-4 m-t-10" /></span>
                    </Col>
                  </Row>
                  <Row className="g-12" xs="2" sm="2" md="4" lg="4" >
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("totalQuantity")}</Label><sup className="font-danger">*</sup>
                        <Input
                          placeholder={t("totalQuantity")}
                          className={`${errors.totquantity && 'error-valid'}  form-control digits selectheight`}
                          name="totquantity"
                          id="totquantity"
                          maxLength="10"
                          onChange={(e) => setTotalQuantity(e.target.value)}
                        >
                          {" "}
                        </Input>
                        {errors.totquantity && (
                          <span className="error-msg">{t(errors.totquantity)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("totalDeliveries")}</Label><sup className="font-danger">*</sup>
                        <Input
                          id="totalDeliveries"
                          type="select"
                          placeholder={t("totalDeliveries")}
                          className={`${errors.totaldelivery && 'select-error-valid'}  form-control digits selectheight`}
                          name="totaldelivery"
                          onChange={(e) => { 
                            setDelivery(e.target.value), 
                            setValue([])
                            }} >
                          <option disabled>
                            {t("selectTotalDeliveries")}
                          </option>
                          {deliveries.map((mapData,i) => (
                            mapData == totalDelivery ?
                            <option key={i} id={mapData} value={mapData} selected>{mapData} </option>
                            :
                            <option key={i} id={mapData} value={mapData}> {mapData} </option>
                          ))}
                        </Input>
                        {errors.totaldelivery && (
                          <span className="error-msg">{t(errors.totaldelivery)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <Label>{t("isToleranceRequired")}</Label>
                      <FormGroup>
                        <Media key="1">
                          <Media body className="text-start icon-state switch-md">
                            <Label className="switch" name="toggle">
                              <Input className="toggleButton"
                                type="checkbox" name="tolisreq"
                                value={tolerancereq} checked={!!tolerancereq}
                                onChange={(e) => { setTolerancereq(prevCheck => !prevCheck) }} />
                              <span className="switch-state"></span>
                            </Label>
                          </Media>
                        </Media>
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                            <Label>{t("toleranceQuantity")}</Label>
                            <Input
                              placeholder={t("toleranceQuantity")}
                              className={`${errors.toleranceVolume && 'select-error-valid'}  form-control digits`}
                              name="toleranceVolume"
                              id="tolVol"
                              maxLength="10"
                              value={toleranceVolume}
                              onChange={(e) => {
                                setToleranceVolume(e.target.value);
                                //let totQuantity = totalQuantity;

                                if (totalQuantity.trim()) {
                                  let totQuantity = document.getElementById("totquantity").value;
                                  let tolVol = e.target.value;
                                  let tolPercentage = ((tolVol / totQuantity) * 100).toFixed(2);
                                  setTolerancePercentage(tolPercentage);
                                } else {
                                  document.getElementById("tolVol").value = '';
                                  document.getElementById("tolPer").value = '';
                                  setTolerancePercentage('');
                                  setToleranceVolume('');
                                  Swal.fire({
                                    title: t("totalQuantityError"),
                                    text: t("fillTotalQuantity"),
                                    icon: "error",
                                    button: t("okLabel"),
                                  });
                                }
                              }}
                            >
                              {" "}
                            </Input>
                            {errors.toleranceVolume && (
                              <span className="error-msg">{t(errors.toleranceVolume)}</span>
                            )}
                      </FormGroup>
                    </Col>
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                            <Label>{t("tolerancePercentage")}</Label>
                            <Input
                              placeholder={t("tolerancePercentage")}
                              className={`${errors.tolerancePercentage && 'select-error-valid'}  form-control digits`}
                              name="totPerc"
                              id="tolPer"

                              value={tolerancePercentage}
                              onChange={(e) => {
                                setTolerancePercentage(e.target.value);
                                //let totQuantity = totalQuantity;

                                if (totalQuantity.trim()) {
                                  let totQuantity = document.getElementById("totquantity").value;
                                  let tolVol = e.target.value;
                                  let tolPercentage = ((tolVol / 100) * totQuantity).toFixed(0);
                                  setToleranceVolume(tolPercentage);
                                } else {
                                  document.getElementById("tolVol").value = '';
                                  document.getElementById("tolPer").value = '';
                                  setTolerancePercentage('');
                                  setToleranceVolume('');

                                  Swal.fire({
                                    title: t("totalQuantityError"),
                                    text: t("fillTotalQuantity"),
                                    icon: "error",
                                    button: t("okLabel"),
                                  });
                                }
                              }}
                            >
                              {" "}

                            </Input>
                            {errors.tolerancePercentage && (
                              <span className="error-msg">{t(errors.tolerancePercentage)}</span>
                            )}
                      </FormGroup>
                    </Col>
                    {/* Inquiry Date Starts*/}
                    {((getStaff === "Staff"&&getStaffPermission.includes('Add Inquiry Date'))||getStaff == null) ? 
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                          <Label>{t("poDate")}</Label>
                            <Input className="toggleButton"
                              type="date" name="inquiryDate"
                              value={inquiryDate}
                              max={new Date().toISOString().split('T')[0]}
                              onChange={(e) => { setInquiryDate(e.target.value) }} />
                      </FormGroup>
                    </Col> : ""
                    }

                    {/* Delivery Date*/}
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("delDate")}</Label><sup className="font-danger">*</sup>
                          <Row 
                            onKeyPress={(e) => handleKeyPress(e)}
                            >
                              <DatePicker
                                value={value}
                                sort
                                multiple
                                id="datePicker"
                                onChange={date => setValue(date)} 
                                fixMainPosition
                                calendarPosition="top-left"
                                dateSeparator="     "
                                inputClass={`${errors.deliveryDate && 'select-error-valid'}  form-control`}
                                placeholder={t("selectDelDate")}
                                plugins={
                                  [ 
                                    <DatePanel />, 
                                      getWeekOff.length > 0 ? weekends(getWeekOff) :  weekends([-1]),
                                    <Toolbar
                                      style={{ backgroundColor: '#E5F4F3', paddingTop: '10px' }}
                                      position="bottom"
                                      sort={["deselect"]} 
                                      names={{
                                        // today: "Today",
                                        deselect: "Clear",
                                        // close: "Close"
                                      }} />,
                                      <DatePickerHeader 
                                        position="top" 
                                        size="small" 
                                        style={{ backgroundColor: "#E5F4F3", color: '#009688', paddingTop: '10px' }} 
                                      />,
                                      <RemainingDeliveryDates 
                                      position="bottom" />,
                                ]}
                                minDate={new Date()}
                                // maxDate={ value.length == totalDelivery ? previous ? previous : ""  :  "" }
                                disableDayPicker={ 
                                  value !== null ? 
                                    value.length > 0  ? value.length == totalDelivery ? true : false : false
                                  :
                                  false }
                                style={{ cursor: 'pointer' }}
                                readOnly={totalDelivery !== "" ? false : true}
                                // disableYearPicker
                                // disableMonthPicker
                                // animations={[
                                //   opacity(), 
                                //   transition({ from: 35, duration: 800 })
                                // ]}
                                >
                              </DatePicker>
                          </Row>
                          {errors.deliveryDate && (
                                <span className="error-msg">{t(errors.deliveryDate)}</span>
                              )}
                      </FormGroup>
                    </Col>
                    {/* Inquiry Date Ends */}
                    <Col lg="4" md="6" sm="12" xs="12" xl="3" xxl="3">
                      <FormGroup>
                        <Label>{t("orderPriority")}</Label>
                        <Input
                          type="select"
                          className="form-control digits selectheight"
                          name="Order Priority"
                          defaultValue=""
                          onChange={(e) => setOrderPriorityValue(e.target.value)}
                        >
                          <option value="" >
                            {t("selectOrderPriority")}
                          </option>
                          {orderPriority.map((orderpriority,j) => (
                            <option key={j} value={orderpriority}
                              title={orderpriority}>
                              {orderpriority}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
                {/*end item description details */}
              </Row>
              {/*end second row*/}

              <Row>
                <Col>
                  {" "}
                  <FormGroup className="f-right p-t-20">
                    <Button
                      className="btn btn-primary"
                      onClick={() => onhandleSubmit()}>
                      {t("saveAndContinue")}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};


export default OrderRegistration;
/* Code By : Rithanesh */