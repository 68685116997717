import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Breadcrumbs} from '../../AbstractElements';
import GeneralSettings from './GeneralSettings';
import NotificationSettings from './NotificationSettings';
import EmailScheduleSettings from './EmailScheduleSettings';
import DashboardSettings from './DashboardSettings';
import { useTranslation } from 'react-i18next';   
import NotificationEmail from './NotificationEmail';
import { getStaff, getStaffPermission, adminAndStaff, 
         getLoginUserType } from "../../Constant/LoginConstant";
// import ZoomSettings from './ZoomSettings';
import { decode } from '../../helper';

const UserSettings = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t("userSettings")} parent="User Settings" 
                     title="User Settings" subTitle="User Configuration and Settings" 
        />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <div className="email-wrap bookmark-wrap">

          { }
          { getLoginUserType !== "user" 
            ?
              adminAndStaff == 1
                ?
                      <Row>
                        <Col lg={6}>
                        <GeneralSettings />
                        </Col>
                        <Col lg={6} >
                        <DashboardSettings />
                        </Col>
                      </Row>
                :
                  <Row>
                    <>
                    <GeneralSettings />
                    <NotificationSettings />
                    <EmailScheduleSettings /> 
                    <DashboardSettings />
                    {
                      (getStaff != "Staff") || getStaff == null 
                    ?
                      <NotificationEmail/>
                    :
                      ""
                    }
                    {/* <ZoomSettings /> */}
                    </>
                  </Row> 
            :
                  <Row>
                    <>
                    <GeneralSettings />
                    <NotificationSettings />
                    <EmailScheduleSettings /> 
                    <DashboardSettings />
                    {
                      (getStaff != "Staff") || getStaff == null 
                    ?
                      <NotificationEmail/>
                    :
                      ""
                    }
                    {/* <ZoomSettings /> */}
                    </>
                  </Row> 
          }
            
        </div>
      </Container>
    </Fragment>
  );
};
export default UserSettings;

//**************************code by : SaravanaKumar****************************//