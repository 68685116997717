import React, { Fragment, useState, useEffect } from 'react';
import {
    Col, CardBody, Card, Container, Row, CardHeader,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { H3, LI, P, UL } from '../AbstractElements';
import { ServerUrl } from '../Constant';
import dmslogtag from "../assets/images/dms/dms-log-with-tag.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { getLanguage } from "../../src/Constant/LoginConstant";
import { apiencrypt, apidecrypt, decode } from "../helper";

const Pricing = ({ }) => {
    const { t, i18n } = useTranslation();
    const [langdropdown, setLangdropdown] = useState(false);
    const [selected, setSelected] = useState(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');

     /**************** SET LANGUAGE  *******************/
    // console.log("selected",selected,i18n.language)
    const changeLanguage = lng => {
        if (lng === 'EN') {
            i18n.changeLanguage('en');
            setSelected('EN');
        }
        else if(lng === 'JP') {
            i18n.changeLanguage('jp');
            setSelected('JP');
        }
        else if(lng === 'BD') {
            i18n.changeLanguage('bd');
            setSelected('BD');
        }
        else{
            i18n.changeLanguage('in');
            setSelected('IN');
        }
    };

    const flagSelect = (lng) =>{
        if( lng === 'en'){
          return 'us'
        }
        if( lng === 'jp'){
          return 'jp'
        }
        if( lng === 'bd'){
          return 'bd'
        }
       if( lng === 'in'){
          return 'in'
        }
      }
  

    /**************** UPDATE LANGUAGE  *******************/
    const LanguageSelection = (language) => {
        if (language) {
            setLangdropdown(!language);
        } else {
            setLangdropdown(!language);
        }
    };

    let duration;
    const [RightTab, setRightTab] = useState('1');
    const [monthlyPlan, setMonthlyPlan] = React.useState(['']);
    const [yearlyPlan, setYearlyPlan] = React.useState(['']);

    /**************** API (GET) [To show the values in the card ]******/
    useEffect(() => {
        axios
            .get(ServerUrl.replace("/staff", "") + "/get-plan")
            .then((response) => {
                response.data = apidecrypt(response.data)
                const monthlyPriceValue = response.data.data.Monthly;
                const yearlyPriceValue = response.data.data.Yearly;
                setMonthlyPlan(monthlyPriceValue);
                setYearlyPlan(yearlyPriceValue);
            }
            );
        if (getLanguage) {
            if (getLanguage === 'en') {
                i18n.changeLanguage('en');
                setSelected('EN');
            }
            else if(getLanguage === 'jp') {
                i18n.changeLanguage('jp');
                setSelected('JP');
            }
            else if(getLanguage === 'bd') {
                i18n.changeLanguage('bd');  
                setSelected('BD');
            }
            else{
                i18n.changeLanguage('in');
                setSelected('IN');
            }
        }
        else {
            i18n.changeLanguage(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');
            setSelected(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');
        }
    }, [getLanguage]);


    /**************** COLOR CHANGE HEADER  *******************/
    const handlePriceStyle = (planName, price, duration) => {
        if (planName === "Trial") {
            return (
                <>
                    <div className="pricing-header" style={{ backgroundColor: `#E7F9F6`, borderRadius: `0% 0% 15% 15%`, color: `#17BFA6` }} >
                        <h7>{t(planName)}</h7>
                        <div className="price-box" style={{ backgroundColor: `#17BFA6` }}>
                            <div> <H3> $ 0</H3>
                                {/* <H3> $ {price} </H3> */}
                                <P>/ {duration}</P>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (planName === "Silver") {
            return (
                <>
                    <div className="pricing-header" style={{ backgroundColor: `#EDEEF6`, borderRadius: `0% 0% 15% 15%`, color: `#4F57A2` }}>
                        <h7>{t(planName)}</h7>
                        <div className="price-box" style={{ backgroundColor: `#4F57A2` }}>
                            <div><H3> $ 0</H3>
                                {/* <H3> $ {price} </H3> */}
                                <P>/ {duration}</P>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (planName === "Gold") {
            return (
                <>
                    <div className="pricing-header"
                        style={{ backgroundColor: `#FDF7EA`, borderRadius: `0% 0% 15% 15%`, color: `#EBAB34` }}>
                        <h7>{t(planName)}</h7>
                        <div className="price-box" style={{ backgroundColor: `#EBAB34` }} >
                            <div> <H3> $ 0</H3>
                                {/* <H3> $ {price} </H3> */}
                                <P>/ {duration}</P>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (planName === "Platinum") {
            return (
                <>
                    <div className="pricing-header" style={{ backgroundColor: `#FFF0E5`, borderRadius: `0% 0% 15% 15%`, color: `#FF6B01` }}>
                        <h7>{t(planName)}</h7>
                        <div className="price-box" style={{ backgroundColor: `#FF6B01` }}>
                            <div><H3> $ 0</H3>
                                {/* <H3> $ {price} </H3> */}
                                <P>/ {duration}</P>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (planName === "Ultimate") {
            return (
                <>
                    <div className="pricing-header" style={{ backgroundColor: `#FFE5E5`, borderRadius: `0% 0% 15% 15%`, color: `#D15A5A` }} >
                        <h7>{t(planName)}</h7>
                        <div className="price-box" style={{ backgroundColor: `#D15A5A` }}>
                            <div>
                            <H3> $ 0</H3>
                                {/* <H3> $ {price} </H3> */}
                                <P>/ {duration}</P>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    const emailAddress = decode(localStorage.getItem("Email"));

    const choosePlan = (id, planName, planPrice,
        planPaymentCurrency, plantype) => {
        const emailAddress = decode(localStorage.getItem("Email"));

        var apiCall = {};
        apiCall['plan_id'] = id;
        apiCall['plan_name'] = planName;
        apiCall['plan_type'] = plantype;
        apiCall['user_email'] = emailAddress;
        apiCall['plan_price'] = planPrice.toString();
        apiCall['plan_discount'] = 0;
        apiCall['plan_subtotal'] = 0;
        apiCall['plan_grandtotal'] = planPrice.toString();
        apiCall['payment_currency'] = planPaymentCurrency.toString();
        // apiCall['reference_id'] = Math.random(1111, 9999);
        apiCall['payment_currency'] = 'INR';
        apiCall['payment_type'] = 'Stripe';
        apiCall['payment_status'] = 'Success';

        axios
            .post(ServerUrl.replace("/staff", "") + "/new-plan-payment", apiencrypt(apiCall))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code === 200) {
                    if( planName == "Trial"){
                        Swal.fire({
                            title: planName,
                            text: t("updatePlanAlert"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false,
                            timer: 5000,
                        })
                        .then((result) => {
                            window.location.href = "/adminlogin"
                        })
                    } else {
                        Swal.fire({
                            title: planName,
                            text: t("updatePlanAlert"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false,
                            timer: 5000,
                        })
                        .then((result) => {
                            //  setTimeout(() => {
                            // if(result.isConfirmed)
                            //  {
                            // localStorage.removeItem("Email");
                            window.location.href = response.data.payment_url;
                            // localStorage.removeItem("Email");
                            // window.location.href = '/adminlogin';
                            // }
                            // }, 1000);    
                        })
                    }
                }
            });
        // }

    }

    /**************** ROUTE  *******************/
    const registerUser = () => {
        Swal.fire({
            title: t("incompleteSignUpAlert"),
            icon: "warning",
            button: t("okLabel"),
            allowOutsideClick: false
        })
            .then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/register';
                }
            })

    }
  

    /*********** HTML (NAVBAR, CARDHEADER, CARD BODY etc)  ************/
    return (
        <>
            <Fragment >
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className="pb-0">
                                    <Row>
                                        <Col >
                                            <img src={dmslogtag} width="150" />
                                        </Col>
                                        <Col>
                                        <Row className="m-r-50 f-right " style={{ backgroundColor: '#f2f2f2' }}>
                                            <LI attrLI={{ className: 'onhover-dropdown m-l-10' }}>
                                                    <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                                                        <div className="current_lang">
                                                            <div className="lang d-flex" onClick={() => LanguageSelection(langdropdown)}>
                                                            <i className={`flag-icon flag-icon-${flagSelect((i18n.language.toLowerCase()))}`}></i> 
                                                            <span className="lang-txt m-l-10">{selected.toUpperCase()}</span>
                                                                <i style={{ marginTop: '3px'}} className="fa fa-chevron-down m-l-10"></i>
                                                            </div>
                                                        </div>
                                                        <div className={`more_lang onhover-show-div ${langdropdown ? 'active' : ''}`}>
                                                        <div className="lang" onClick={() => {changeLanguage('EN')}}>
                                                            <i className="flag-icon flag-icon-us"></i>
                                                            <span className="lang-txt m-l-10">English</span>
                                                        </div>
                                                        <div className="lang" onClick={() => {changeLanguage('JP')}}>
                                                            <i className="flag-icon flag-icon-jp"></i>
                                                            <span className="lang-txt m-l-10">Japanese</span>
                                                        </div>
                                                        {/* <div className="lang" onClick={() => {changeLanguage('BD')}}>
                                                            <i className="flag-icon flag-icon-bd"></i>
                                                            <span className="lang-txt m-l-10">Bengali</span>
                                                        </div>
                                                        <div className="lang" onClick={() => {changeLanguage('IN')}}>
                                                            <i className="flag-icon flag-icon-in"></i>
                                                            <span className="lang-txt m-l-10">Hindi</span>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </LI>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <Col>
                                </Col>
                                <CardBody>
                                    <Nav className="nav nav-tabs nav-right" >
                                        <NavItem>
                                            <NavLink className={RightTab === '1' ? 'active' : ''}
                                                onClick={() => setRightTab('1')}>
                                                {(t("monthlyLabel")).toLocaleUpperCase()}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem >
                                            <NavLink className={RightTab === '2' ? 'active' : ''}
                                                onClick={() => setRightTab('2')}>
                                                {(t("yearlyLabel")).toLocaleUpperCase()}
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                    <TabContent activeTab={RightTab}>
                                        <TabPane className="fade show" tabId="1">
                                            <P attrpara={{ className: 'mb-0 m-t-30' }}>
                                                <CardBody className="row pricing-content pricing-col">
                                                    {priceDetailsMonthly()}
                                                </CardBody>
                                            </P>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <P attrpara={{ className: 'mb-0 m-t-30' }}>
                                                <CardBody className="row pricing-content pricing-col">
                                                    {priceDetailsYearly()}
                                                </CardBody>
                                            </P>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        </>
    );

    /***************** PAYMENT DETAILS CARD (MONTHLY) *****************/
    function priceDetailsMonthly() {
        return (
            monthlyPlan.map((priceDetail) =>
            (
                <>
                    <Col>
                        <Card className="pricing-block card text-center">
                            {handlePriceStyle(priceDetail.plan_name, priceDetail.price, duration = t("monthLabel"))}
                            <div className="pricing-list">
                                <UL attrUL={{ className: 'pricing-inner' }}>
                                    <LI>{t("maxStorageSize")}<span> {priceDetail.max_storage_size} MB </span></LI>
                                    <LI>{t("reportRange")}<span> {priceDetail.report_range} </span></LI>
                                    <LI>{t("noOfUser")}<span> {priceDetail.no_of_user} </span></LI>
                                    <LI>{t("noOfStyle")}<span> {priceDetail.no_of_style} </span></LI>
                                    <LI>{t("noOfDays")}<span> {priceDetail.no_of_days} </span></LI>
                                </UL>
                                <div className="pricingtable-signup">
                                    {emailAddress ?
                                        <button className="btn btn-primary"
                                            onClick={() =>
                                                choosePlan(priceDetail.id,
                                                    priceDetail.plan_name,
                                                    priceDetail.price,
                                                    priceDetail.currency,
                                                    'Monthly'
                                                )}>
                                            {t("choosePlan")}
                                        </button> : <button className="btn btn-primary" onClick={() => registerUser()}>{t("signUp")}</button>}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </>
            )
            )
        )
    }

    /***************** PAYMENT DETAILS CARD (YEARLY) *****************/
    function priceDetailsYearly() {
        return (
            yearlyPlan.map(priceDetail =>
            (
                <>
                    <Col>
                        <Card className="pricing-block card text-center">
                            {handlePriceStyle(priceDetail.plan_name, priceDetail.price, duration = t("yearLabel"))}
                            <div className="pricing-list">
                                <UL attrUL={{ className: 'pricing-inner' }}>
                                    <LI>{t("maxStorageSize")}<span> {priceDetail.max_storage_size} MB</span></LI>
                                    <LI>{t("reportRange")}<span> {priceDetail.report_range} </span></LI>
                                    <LI>{t("noOfUser")}<span> {priceDetail.no_of_user} </span></LI>
                                    <LI>{t("noOfStyle")}<span> {priceDetail.no_of_style} </span></LI>
                                    <LI>{t("noOfDays")}<span> {priceDetail.no_of_days} </span></LI>
                                </UL>
                                <div className="pricingtable-signup">
                                    {emailAddress ?
                                        <button className="btn btn-primary"
                                            onClick={() =>
                                                choosePlan(priceDetail.id,
                                                    priceDetail.plan_name,
                                                    priceDetail.price,
                                                    priceDetail.currency,
                                                    'Yearly',
                                                )}>
                                            {t("choosePlan")}
                                        </button> :
                                        <button className="btn btn-primary" onClick={() => registerUser()}>{t("signUp")}</button>}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </>
            )
            )
        )
    }
};

export default Pricing;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/