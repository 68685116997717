import React, { Fragment, useState, useEffect } from "react";
import {
  Form, Label, Card, CardBody, Col, Row, Input, InputGroup,
  FormGroup, Collapse, CardHeader, Container, Table, Button
} from "reactstrap";
import { ServerUrl } from "../../../Constant";
import { Breadcrumbs, Btn, P, H5 } from "../../../AbstractElements";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { useSearchParams } from "react-router-dom";
import {
  decode, DownloadFile, sizeConvertor, encode, calculateDateDiffCountFromCurrentDate,
  getColorSizeQty, apiencrypt, apidecrypt, PHPtoJSFormatConversion
} from "../../../helper";
import edit from "../../../assets/images/dms/editPencil.svg";
import task_icon from "../../../assets/images/dms/task_icon.svg";
import Moment from 'moment';
import { Accordion } from "react-bootstrap";
import axios from "axios";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,getModules, getStaff, getStaffPermission, getLoginStaffId, getWorkspaceType }
  from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import jaLocale from '@fullcalendar/core/locales/ja';
import order from "../../../assets/images/dms/RegView.svg";
import viewcontact from "../../../assets/images/dms/ContactView.svg"
import sku from "../../../assets/images/dms/SkuView.svg"
import viewtask from "../../../assets/images/dms/TaskViewSt.svg"
import viewproduction from "../../../assets/images/dms/ProductionViewSt.svg"
import contactdata from "../../../assets/images/dms/ContactViewSt.svg"
import orderWhite from "../../../assets/images/dms/order.svg"
import productiondata from "../../../assets/images/dms/ProductionView.svg"
import skuWhite from "../../../assets/images/dms/sku_white.svg"
import taskdata from "../../../assets/images/dms/TaskView.svg"
import orderRegSelect from "../../../assets/images/dms/RegViewSt.svg"
import orderSkuSelect from "../../../assets/images/dms/SkuViewSt.svg"
import orderMaterialLabelSelect from "../../../assets/images/dms/MaterialViewSt.svg"
import deliveredHeaderIcon from "../../../assets/images/dms/icons/deliveredHeaderIcon.svg";
import deliveredSelectedHeaderIcon from "../../../assets/images/dms/icons/deliveredSelectedHeaderIcon.svg";
import DownloadIcon from "../../../assets/images/dms/icons/download.svg";
import materialsLabels from "../../../assets/images/dms/MaterialView.svg";
import materialsLabelsActive from "../../../assets/images/dms/AddMaterialsLabelsActive.svg";
import pdfDocIcon from "../../../assets/images/dms/icons/pdfDocIcon.svg";
import excelDocIconIcon from "../../../assets/images/dms/icons/excelDocIcon.svg";
import docIcon from "../../../assets/images/dms/icons/docIcon.svg";
// import uploadDocIcon from "../../../assets/images/dms/icons/uploadDoc.svg";
import labelSampleIcon from "../../../assets/images/dms/icons/labelSampleIcon.svg";
import viewLabelSampleIcon from "../../../assets/images/dms/icons/viewLabelSampleIcon.svg";
import AddMaterialsImageModal from "./AddMaterialsImageModal";
import AddPermissionImageModal from "./AddPermissionImageModal";
import noData from "../../../assets/images/dms/nodata.png";
import download_iconTask from '../../../assets/images/dms/icons/download_iconTask.svg'
import reschedule from "../../../assets/images/dms/reschedule.svg"
import Swal from "sweetalert2";
import {blurEmail} from "../../../../src/helper"
import ApprovalHistoryOffcanvas from '../ViewOrder/ApprovalHistoryOffcanvas.jsx';

const OrderView = (props) => {
  const [state, setState] = useState('');
  const [colorTotal, setGetColorTotal] = React.useState([]);
  const [sizeTotal, setGetSizeTotal] = React.useState([]);
  const [getWeekOff, setWeekoff] = React.useState([]);
  const [getColor, setGetColor] = React.useState([]);
  const [getSize, setGetSize] = React.useState([]);
  const [orderView, setOrderView] = React.useState([]);
  const [orderContact, setOrdercontact] = React.useState([]);
  const [orderTaskDetails, setOrderTaskDetails] = React.useState([]);
  const [orderskuDetails, setOrderskuDetails] = React.useState([]);
  const [orderFabricType, setOrderFabricType] = useState([]);
  const [showAddData, setShowAddData] = useState([]);
  const [orderProduction, setOrderProduction] = React.useState([]);
  const [orderProductionDetails, setOrderProductionDetails] = React.useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [HolidayInformation, setHolidayInformation] = React.useState([]);
  const [WeekOffDayClass, setWeekOffDayClass] = React.useState('');
  const [orderViewData, setorderViewData] = React.useState([]);
  const [orderTaskFiles, setOrderTaskFiles] = useState([]);
  const [uploadedFile, setUploadedFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(5);
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));
  const workspaceId = getWorkspaceId;
  const companyId = getLoginCompanyId;
  const userId = getLoginUserId;
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language == 'jp' ? jaLocale : '');

  const [statusDeliveryDate, viewStatusDeliveryDate] = useState([]);
  const [showTab, setshowTab] = useState(1);
  const [registrationTab, setSelectedRegistrationTab] = useState("");
  const [selectedSkuTab, setSelectedSkuTab] = useState("");
  const [selectedContactsTab, setSelectedContactsTab] = useState("");
  const [selectedTaskTab, setSelectedTaskTab] = useState("");
  const [selectedproductionTab, setSelectedproductionTab] = useState("");
  
  const [addMaterialsImageModal, setAddMaterialsImageModal] = useState(false);
  const  toggleAddMaterialsImageModal = () => setAddMaterialsImageModal(!addMaterialsImageModal);

  const [downloadImageModal, setDownloadImageModal] = useState(false);
  const  togglePermissionImageModal = () => setDownloadImageModal(!downloadImageModal);
  
  const [downloadstyle, setdownloadstyle] = useState("");
  const [sewing_accessoriest, setsewing_accessoriest] = useState("");
  const [packingAccessoriest, setPackingAccessoriest] = useState("");
  const [miscellaneousList, setmiscellaneousList] = useState("");
  const [mediaList, setMediaList] = useState("");
  const [serverURL, setServerURL] = useState("");
  const [media_type, setMedia_type] = useState("");
  const [label_type, setLable_type] = useState("");
  var overallTotal = 0;
  const [basicInfo, setBasicInfo] = React.useState([]);
  const [addedDeliveryDates, setAddedDeliveryDates] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryComments, setDeliveryComments] = useState("");
  const [totalDeliveredQuantity, setTotalDeliveredQuantity] = useState("");
  const [deliveryDates, setDeliveryDates] = useState([]);
  const [color, setColor] = useState([]);
  const [size, setSize] = useState([]);
  const [partialDeliveredSkuData, setPartialDeliveredSkuData] = useState([]);
  const [totalDeliveredQty, setTotalDeliveredQty] = useState("");
  const [noOfDeliveries, setNoOfDeliveries] = useState("");
  const [noOfDelivered, setNoOfDelivered] = useState("");
  const [delDate, setDelDate] = useState([]);

  const [ sampleMediaListType, setSampleMediaListType ] = useState([]);
  
  let cuttingData = [];
  var sum = 0;
  var getInputParams = {};
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['order_id'] = orderId;
  getInputParams['user_id'] = getLoginUserId;
  var getInputWeekOff = {}
  getInputWeekOff['company_id'] = getLoginCompanyId;
  getInputWeekOff['workspace_id'] = getWorkspaceId;
  
  const [ vendorList, setVendorList ] = useState([]);
  const [ unitsList, setUnitsList ] = useState([]);
  
  // const [ vendorList, vendorList ] = useState([]);
  const [ getOrderMaterialsLabel, setGetOrderMaterialsLabel ] = useState([]);
  const [ getOrderMaterialsLabelImageList, setGetOrderMaterialsLabelImageList ] = useState([]);
  const [ awsUrl, setAwsUrl ] = useState("");
  
  const qty_arr = {
    is_tolerance_req: 0,
    tolerance_volume: 0,
    tolerance_perc: 0,
    total_quantity: 0,
  };

  const MaterialsImageModal = ((id,type)=>
  {
    setMedia_type(id)
    setLable_type(type)
    toggleAddMaterialsImageModal()

  });

  const DownloadimagePermissionModal =(()=>{
    togglePermissionImageModal()
  });

  //*******************************Get WeekOFF Api Validation*************************************
  useEffect(async () => {
    axios
      .post(ServerUrl + "/get-weekOffs", apiencrypt(getInputWeekOff))
      .then((response) => {
        response.data = apidecrypt(response.data)
        let wk_of = [];
        let wk_of_class = [];
        for (let i = 0; i < (response.data.data).length; i++) {
          wk_of[i] = response.data.data[i].day;
          wk_of_class[i] = response.data.data[i].day;
        }
        setWeekOffDayClass(wk_of_class);
        setWeekoff(wk_of);
      })

    await axios
      .post(ServerUrl + "/view-order", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data)
        var dates = (response.data.data.basicInfo[0].delivery_dates);
        if (dates != null && dates != undefined) {
          var splitId = dates.split(",");
          viewStatusDeliveryDate(splitId);
          setDelDate(splitId)
        }
        // var dates = response.data.data.basicInfo[0].delivery_dates;
        // var splitId = dates.split(",");
        //setDelDate(splitId)
        setGetColorTotal(response.data.data.colorTotal);
        setGetSizeTotal(response.data.data.sizeTotal);
        setGetColor(response.data.data.skucolor);
        setGetSize(response.data.data.skusize);
        setIsOpen(1);
        setOrderView(response.data.data.basicInfo);
        setorderViewData(response.data.data.basicInfo[0].cutting_start_date);
        setOrderFabricType(response.data.data.fabrictype);
        setOrdercontact(response.data.data.orderContact);
        setOrderTaskDetails(response.data.data.taskDetails);
        setOrderskuDetails(response.data.data.skuDetails);
        setOrderProductionDetails(response.data.data.productionDetails);
        setOrderProduction(response.data.data);
        setOrderTaskFiles(response.data.data.taskFiles);
        setUploadedFiles(response.data.data.taskFiles);
        // calenderInfo(response.data.data.updateSkuDetails);
      })
  
      axios.post(ServerUrl + "/all-vendor-list", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
        "units": "order_units" 
      }))
      .then((response) => {
          response.data = apidecrypt(response.data);
          setVendorList(response.data.data);
          setUnitsList(response.data.units);
      });


  }, []);

  /*Minimize Pdf Name */
  const truncate = (str) => {
    const n = 22;
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };

  const [viewOrderBomApproval, setViewOrderBomApproval] = useState([]);
  const [approvalBOMData, setApprovalBOMData] = useState([]);
  const [preparedByBOMData, setPreparedByBOMData] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [ getOrderBomData, setGetOrderBomData ] = useState([]);
     
  const [dataAvailability, setDataAvailability ] = useState([]);
  const materialsLabelsApiCall = async() => {

    const viewOrderBOMApprovalApiCall = ( orderBomId ) => {
      var apiInputParams = {};
        apiInputParams['company_id'] = getLoginCompanyId;
        apiInputParams['workspace_id'] = getWorkspaceId;
        apiInputParams['order_id'] = orderId;
        apiInputParams['order_bom_id'] = orderBomId;
  
      axios.post(ServerUrl + "/view-orderbom-approval", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setViewOrderBomApproval(response.data.data);
      });
    };

    const dataAvailabilty  = ( sewingAccessoriesData, packingAccessoriesData, miscellaneousData ) => 
    {
      const data = [];
      sewingAccessoriesData.length > 0 ?
        sewingAccessoriesData
        .map((mapData1) => {
            if(mapData1.Type == "MainLabel" || mapData1.Type == "Washcare" ||
              mapData1.Type == "BarcodeStickers" || mapData1.Type == "Hangtag"){
            } else{
              data.push( mapData1.Type );
            }
            if( mapData1.component !== "" ){
              data.push( mapData1.component );
            } 
            if( mapData1.color !== "" ){
              data.push( mapData1.color );
            } 
            if( mapData1.vendor !== "" || mapData1.vendor !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.perGMT !== "" ){
              data.push( mapData1.perGMT );
            } 
            if( mapData1.totalQty !== "" ){
              data.push( mapData1.totalQty );
            } 
            if( mapData1.units !== "" || mapData1.units !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.pricePerUnit !== "" ){
              data.push( mapData1.pricePerUnit );
            }
        })
      :
        ""

      packingAccessoriesData.length > 0 ?
        packingAccessoriesData
        .map((mapData1) => {
            if(mapData1.Type == "MainLabel" || mapData1.Type == "Washcare" ||
              mapData1.Type == "BarcodeStickers" || mapData1.Type == "Hangtag"){
            } else{
              data.push( mapData1.Type );
            }
            if( mapData1.component !== "" ){
              data.push( mapData1.component );
            } 
            if( mapData1.color !== "" ){
              data.push( mapData1.color );
            } 
            if( mapData1.vendor !== "" || mapData1.vendor !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.perGMT !== "" ){
              data.push( mapData1.perGMT );
            } 
            if( mapData1.totalQty !== "" ){
              data.push( mapData1.totalQty );
            } 
            if( mapData1.units !== "" || mapData1.units !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.pricePerUnit !== "" ){
              data.push( mapData1.pricePerUnit );
            }
        })
      :
      ""

      miscellaneousData.length > 0 ?
        miscellaneousData
        .map((mapData1) => {
            if(mapData1.Type == "MainLabel" || mapData1.Type == "Washcare" ||
              mapData1.Type == "BarcodeStickers" || mapData1.Type == "Hangtag"){
            } else{
              data.push( mapData1.Type );
            }
            if( mapData1.component !== "" ){
              data.push( mapData1.component );
            } 
            if( mapData1.color !== "" ){
              data.push( mapData1.color );
            } 
            if( mapData1.vendor !== "" || mapData1.vendor !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.perGMT !== "" ){
              data.push( mapData1.perGMT );
            } 
            if( mapData1.totalQty !== "" ){
              data.push( mapData1.totalQty );
            } 
            if( mapData1.units !== "" || mapData1.units !== 0 ){
              data.push( mapData1.vendor );
            } 
            if( mapData1.pricePerUnit !== "" ){
              data.push( mapData1.pricePerUnit );
            }
        })
      :
      ""

      setDataAvailability(data);

    };

    await axios.post(ServerUrl + "/get-order-bom", apiencrypt({
      order_id: orderId
    }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        if(  response.data.data !== null ){
          var mediaListType = [];
          setsewing_accessoriest(response.data.data.sewing_accessories);
          setPackingAccessoriest(response.data.data.packing_accessories);
          setmiscellaneousList(response.data.data.miscellaneous);
          setMediaList(response.data.media);
          setServerURL(response.data.serverURL);
          setApprovalBOMData(response.data.approvalBOM);
          setPreparedByBOMData(response.data.preparedBy);
          setApprovalStatus(response.data.data.is_approval);
          (response.data.media).map((mapData) => {
            mediaListType.push(mapData.media_type);
          })
          setSampleMediaListType(mediaListType);
          setGetOrderBomData(response.data);

          var sewingAccessoriesData = JSON.parse(response.data.data.sewing_accessories);
          var packingAccessoriesData = JSON.parse(response.data.data.packing_accessories);
          var miscellaneousData = JSON.parse(response.data.data.miscellaneous);

          dataAvailabilty(sewingAccessoriesData, packingAccessoriesData, miscellaneousData)
          viewOrderBOMApprovalApiCall(response.data.data.id);

        } else if( response.data.data == null){
          setApprovalBOMData(null);
        }
    });

  };

  ///****************  To get the list of files and set it in the state *****************/
  const getUploadedFiles = () => {
    axios.post(ServerUrl + "/view-order", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
      "user_id": userId,
    })).then((response) => {
      response.data = apidecrypt(response.data)
      setUploadedFiles(response.data.data.taskFiles);

    })
  };

  ///***************** To Download the Files Validation  ***************/
  const downloadFile = (filename, orgFileName) => {
    axios.post(ServerUrl + "/download-file", apiencrypt({
      "company_id": companyId,
      "workspace_id": workspaceId,
      "order_id": orderId,
      "user_id": userId,
      "fileName": filename
    }), { responseType: 'blob' })
      .then((response) => {
        DownloadFile(response.data, orgFileName);
      })
  };

  ///***************** To convert the date formate validation  ***************/
  const getConvertDate = (sdate, edate) => {
    var day1 = Moment(sdate);
    var day2 = Moment(edate);
    var result = [Moment({ ...day1 }).format("YYYY-MM-DD")];

    while (day1.date() != day2.date()) {
      let lopdat = day1.add(1, 'day');
      result.push(Moment({ ...day1 }).format("YYYY-MM-DD"));
    }
    return result;
  };

  const calenderInfo = (holidays) => {
    let cut_prod_data = [];
    for (let k = 0; k < holidays.length; k++) {
      let h = 0;
      var sdate = holidays[k].holiday_start_date;
      var edate = holidays[k].holiday_end_date;
      let d = 0;
      var start = new Date(sdate);
      var end = new Date(edate);
      let getDate = getConvertDate(sdate, edate);
      for (let hs = 0; hs < getDate.length; hs++) {
        let date_arry = {};
        var holiday_text = holidays[k].name;
        var holiday_flg = 1;
        date_arry.date_of_production = getDate[hs];
        date_arry.holiday_flag = holiday_flg;
        date_arry.date = getDate[hs];
        date_arry.holiday_detail = holiday_text;
        cut_prod_data.push(date_arry);
      }
    }
    setShowAddData(cut_prod_data);
  };

  if (HolidayInformation.length == 0) {
    cuttingData = [{
      daysOfWeek: WeekOffDayClass,
      display: "background",
      title: "Weekoff",
      className: "weekoffcolor",
      overLap: false,
      allDay: true
    }];
  };

  const dateCount = calculateDateDiffCountFromCurrentDate(orderViewData);
  let ct = 0;

  const partialDeliveryApiCall = () => {

    var basicInfoParams = {};
    basicInfoParams['company_id'] = getLoginCompanyId;
    basicInfoParams['workspace_id'] = getWorkspaceId;
    basicInfoParams['user_id'] = getLoginUserId;
    basicInfoParams['staff_id'] = getLoginStaffId;
    basicInfoParams['order_id'] = orderId;
    axios
      .post(ServerUrl + "/get-basic-info", apiencrypt(basicInfoParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setBasicInfo(response.data.data[0]);
      })

    var deliveryDetailsParams = {};
    deliveryDetailsParams['company_id'] = getLoginCompanyId;
    deliveryDetailsParams['workspace_id'] = getWorkspaceId;
    deliveryDetailsParams['user_id'] = getLoginUserId;
    deliveryDetailsParams['order_id'] = orderId;

    axios
      .post(ServerUrl + "/get-delivery-details", apiencrypt(deliveryDetailsParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setAddedDeliveryDates(response.data.data.addedDeliveryDates);
        setDeliveryDates(response.data.data.deliveryDates);
        setColor(response.data.data.colors);
        setSize(response.data.data.sizes);
        (response.data.data.addedDeliveryDates).map((mapData) => {
          if (mapData.delivery_date == response.data.data.deliveryDates[0].delivery_date) {
            setPartialDeliveredSkuData(mapData.sku_data);
            setDeliveryComments(mapData.delivery_comments);
            setTotalDeliveredQuantity(mapData.total_delivered_quantity);
          }
        })
        setNoOfDeliveries(response.data.data.delivery_count);
        setNoOfDelivered(response.data.data.delivered_count);
        setTotalDeliveredQty(response.data.data.total_delivered_quantity);
        setDeliveryDate(response.data.data.deliveryDates[0].delivery_date)
      });
  };

  const partialDeliveryDetails = (deliveryDateValue) => {
    addedDeliveryDates.map((mapData) => {
      if (mapData.delivery_date == deliveryDateValue) {
        setPartialDeliveredSkuData(mapData.sku_data);
        setDeliveryComments(mapData.delivery_comments);
        
       
      }
    })
  };

  const partialDeliveredQty = (array, colorid, sizeid) => {
    let aryqty = [];
    array.map((obj) => {
      let tqty = (obj.color_id === colorid && obj.size_id === sizeid);
      if (tqty == true) {
        aryqty.push(obj.total_quantity)
      }
    });
    if (aryqty.length == 0) {
      return 0;
    } else {
      return aryqty;
    }
  };

  /*********************** To Download The PDF ********************/
  // const toDownloadAsPdf = (styleNo) => {
  //   axios.post(ServerUrl + "/download-order-bom", apiencrypt({
  //     "order_id": orderId, 
  //     "company_id": getLoginCompanyId, 
  //     "workspace_id": getWorkspaceId,
  //     "user_id": getLoginUserId,
  //     "staff_id": getLoginStaffId,
  //     "image":"0"
  //   }), { responseType: 'blob' })
  //     .then((response) => {
  //       let nameOfFile =  styleNo ? styleNo + "-BillofMaterials.pdf"  :  "BillofMaterials.pdf"
  //       DownloadFile(response.data, nameOfFile);
  //     })
  // }
 
  const downloadMediaFile = ( filepath, originalFileName ) => {
    axios
    .post(ServerUrl + "/download-files", apiencrypt({
      "company_id": getLoginCompanyId,
      "workspace_id": getWorkspaceId,
      "orginalfilename": originalFileName,
      "filepath": filepath
    }), { responseType: 'blob' })
    .then((response) => {
      DownloadFile(response.data, originalFileName);
    })
  };

  const approveCommentsApiCall = ( comments ) => {

    var getInputParams = {};
        getInputParams["company_id"] = getLoginCompanyId;
        getInputParams["workspace_id"] = getWorkspaceId;
        getInputParams["user_id"] = getLoginUserId;
        getInputParams["staff_id"] = getLoginStaffId;
        getInputParams["order_id"] = orderId;
        getInputParams["order_bom_id"] = getOrderBomData.data.id;
        getInputParams["comments"] =  comments;

    axios.post(ServerUrl + "/add-orderbom-approval", apiencrypt(getInputParams))
    .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code == 200) {
          Swal.fire({
            title: t(response.data.message),
            icon: "success",
            showCancelButton: true,
            button: t("okLabel"),
            timer: 5000
          }).then((result) => {
            if (result.isConfirmed) {
              location.reload();
            }
            location.reload();
          }
          )
        } else if (response.data.status_code == 401) {
          Swal.fire({
            title: t(response.data.error),
            icon: "warning",
            showCancelButton: true,
            button: t("okLabel"),
            timer: 5000
          }).then((result) => {
            if (result.isConfirmed) {
              toggle();
            }
            toggle();
          }
          )
        }
    });

  };

  const approveHandler = () => {
    (async () => {
      const { value: reason } = await Swal.fire({
        title: t("areYouSureWantToApprove"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
        input: 'textarea',
        inputAttributes:{
          id:"approveCommentsId"
        },
        inputPlaceholder: t("addYouComments"),
        inputValidator: (comments) => {
              if (!comments) {
                  approveCommentsApiCall(comments )
              }  else{
                   approveCommentsApiCall(comments )
              }
        },
        closeOnClickOutside: false,
      })
    })()
  };

  const [ approvalHistoryOffcanvas, setApprovalHistoryOffcanvas ] = useState( false );
  const  toggleApprovalHistoryOffcanvas = () => setApprovalHistoryOffcanvas(!approvalHistoryOffcanvas);

   return (
    <Fragment>

      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('overView')} parent="Order Status"
          title="Order Status" subTitle="Delay and Production of ongoing Orders" />
      </Row>

      <Container fluid={true} className="general-widget topaln">
        <Row 
          className="p-l-15 p-r-15 p-t-10" 
          // lg="6" md="3" sm="1" xs="1" 
          style={{ borderRadius: '5px' }}>

          { showTab == 1 
            ?
              <Col className="shadow shadow-md orderHeaderColActive"
                  style={{ backgroundColor: '#fff', color: '#000', 
                          borderRadius: ' 5px 0px 0px 5px ' }}>
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("order")} tooltip={t("order")} alt={t("order")} >
                    {
                      getWorkspaceType == "Factory" 
                      ?
                        <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                          <img className="selectedHeaderIcon" src={orderRegSelect} />
                        </Col>
                      :
                        <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1 p-r-0">
                          <img className="selectedHeaderIcon" src={orderRegSelect} />
                        </Col>
                    }
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("order")} </span>
                      <p className="subHeadingActive">{t("basicInformation")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            :
              <Col className="shadow shadow-md orderHeaderCol"
                    style={{  borderRadius: ' 5px 0px 0px 5px '  }}>
                <Row className="p-t-15 p-b-15 orderHeaderRow"  
                        title={t("order")} tooltip={t("order")} alt={t("order")}
                        onClick={() => {
                        setSelectedRegistrationTab("order")
                        setshowTab(1)
                      }}>
                  { getWorkspaceType == "Factory" 
                  ?
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                      <img className="unselectedHeaderIcon" src={order} />
                  </Col>
                  :
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1 p-r-0">
                    <img className="unselectedHeaderIcon" src={order} />
                  </Col>
                  }
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("order")} </span>
                      <p className="subHeading">{t("basicInformation")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
          }

          { showTab == 2 ?
              <Col className="shadow shadow-md orderHeaderColActive"
                    style={{ backgroundColor: '#fff', color: '#000' }}>
                <Row 
                  className="p-t-15 p-b-15 orderHeaderRow"
                  title={t("SKU")} tooltip={t("SKU")} alt={t("SKU")}>
                  {
                  getWorkspaceType == "Factory" 
                  ?
                    <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                        <img className="selectedHeaderIcon" src={orderSkuSelect} />
                    </Col>
                  :
                    <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1 p-r-0">
                        <img className="selectedHeaderIcon" src={orderSkuSelect} />
                    </Col>
                  }
                    <Col> 
                      <div>
                        <span className="f-w-600 heading"> {t("SKU")} </span>
                        <p className="subHeadingActive">{t("skuBreakUpData")}</p>
                      </div>
                    </Col>
                </Row>
              </Col>
            :
              <Col className="shadow shadow-md orderHeaderCol"
                  >
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("SKU")} tooltip={t("SKU")} alt={t("SKU")}
                      onClick={() => {
                        partialDeliveryApiCall(),
                        setSelectedSkuTab("orderSku")
                        setshowTab(2)
                      }}>
                {getWorkspaceType == "Factory" ?
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                      <img className="unselectedHeaderIcon" src={sku} />
                  </Col>
                  :
                  <Col lg="4" md="12" sm="12" xs="12" className="orderHeaderCol1 p-r-0">
                      <img className="unselectedHeaderIcon" src={sku} />
                  </Col>
                }
                  <Col className="p-l-0"> 
                    <div>
                      <span className="f-w-600 heading"> {t("SKU")} </span>
                      <p className="subHeading">{t("skuBreakUpData")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
          }

          { showTab == 3 ?
              <Col className="shadow shadow-md orderHeaderColActive"
                    style={{ backgroundColor: '#fff', color: '#000' }}>
                    {/* style={{ backgroundColor: '#009688', color: '#fff' }}> */}
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                    title={t("contacts")} tooltip={t("contacts")} alt={t("contacts")} >
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: "#fff" }}> */}
                      <img className="selectedHeaderIcon" src={contactdata} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("contacts")} </span>
                      <p className="subHeadingActive">{t("contactsInvolved")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            :
              <Col className="shadow shadow-md orderHeaderCol"
                  style={{ backgroundColor: '#F5F5F6' }}>
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("contacts")} tooltip={t("contacts")} alt={t("contacts")}
                      onClick={() => {
                        setSelectedContactsTab("orderContacts")
                        setshowTab(3)
                      }} 
                      >
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: '#F5F5F6'}}> */}
                      <img className="unselectedHeaderIcon" src={viewcontact} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("contacts")} </span>
                      <p className="subHeading">{t("contactsInvolved")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
          }

          { showTab == 4 ?
              <Col className="shadow shadow-md orderHeaderColActive"
                    style={{ backgroundColor: '#fff', color: '#000' }}>
                    {/* style={{ backgroundColor: '#009688', color: '#000' }}> */}
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("Task")} tooltip={t("Task")} alt={t("Task")}>
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: "#fff" }}> */}
                      <img className="selectedHeaderIcon" src={viewtask} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading">{t("Task")}</span>
                      <p className="subHeadingActive">{t("taskSchedule")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            :
              <Col className="shadow shadow-md orderHeaderCol"
                  style={{ backgroundColor: '#F5F5F6' }}>
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("Task")} tooltip={t("Task")} alt={t("Task")}
                      onClick={() => {
                        setSelectedTaskTab("ordertaskSchedule")
                        setshowTab(4)
                      }}>
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: '#F5F5F6'}}> */}
                      <img className="unselectedHeaderIcon" src={taskdata} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("Task")}</span>
                      <p className="subHeading">{t("taskSchedule")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
          }

          {
            getWorkspaceType == "Factory" 
            ?
            showTab == 5 ?
                <Col className="shadow shadow-md orderHeaderColActive"
                      style={{ backgroundColor: '#fff', color: '#000' }}>
                    {/* style={{ backgroundColor: '#009688', color: '#fff' }}> */}
                  <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("Materials And Labels")} tooltip="Materials, Labels" alt="Materials, Labels">
                    <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                      {/* <span style={{ backgroundColor: "#fff" }}> */}
                        <img className="selectedHeaderIcon" src={orderMaterialLabelSelect} />
                      {/* </span> */}
                    </Col>
                    <Col> 
                      <div>
                        <span className="f-w-600 heading">{t("billOfMaterials")}</span>
                        <p className="subHeadingActive">{t("printTrimsPackings")}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              :
                <Col className="shadow shadow-md orderHeaderCol"
                    style={{ backgroundColor: '#F5F5F6' }}>
                  <Row className="p-t-15 p-b-15 orderHeaderRow"
                      title={t("Materials And Labels")} tooltip="Materials, Labels" alt="Materials, Labels"
                      onClick={() => {
                        materialsLabelsApiCall(),
                        setshowTab(5)
                      }}>
                    <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                      {/* <span style={{ backgroundColor: '#F5F5F6'}}> */}
                        <img className="unselectedHeaderIcon" src={materialsLabels} />
                      {/* </span> */}
                    </Col>
                    <Col> 
                      <div>
                        <span className="f-w-600 heading"> {t("billOfMaterials")} </span>
                        <p className="subHeading">{t("printTrimsPackings")}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
          :
          ""}

          { showTab == 6 ?
              <Col className="shadow shadow-md orderHeaderColActive"
                  style={{ backgroundColor: '#fff', color: '#000', borderRadius: ' 0px 5px 5px 0px '  }}>
                  {/* style={{ backgroundColor: '#009688', color: '#000' }}> */}
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                    title={t("productionPlan")} tooltip={t("productionPlan")} 
                    alt={t("productionPlan")} >
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: "#fff" }}> */}
                      <img className="selectedHeaderIcon" src={viewproduction} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("productionPlan")} </span>
                      <p className="subHeadingActive">{t("routinePlanTarget")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            :
              <Col className="shadow shadow-md orderHeaderCol"
                  style={{ backgroundColor: '#F5F5F6',  borderRadius: ' 0px 5px 5px 0px '  }}>
                <Row className="p-t-15 p-b-15 orderHeaderRow"
                    title={t("productionPlan")} tooltip={t("productionPlan")} 
                    alt={t("productionPlan")} 
                    onClick={() => {
                      setSelectedproductionTab("productionPlan")
                      setshowTab(6)
                    }}>
                  <Col lg="3" md="12" sm="12" xs="12" className="orderHeaderCol1">
                    {/* <span style={{ backgroundColor: '#F5F5F6'}}> */}
                      <img className="unselectedHeaderIcon" src={productiondata} />
                    {/* </span> */}
                  </Col>
                  <Col> 
                    <div>
                      <span className="f-w-600 heading"> {t("productionPlan")} </span>
                      <p className="subHeading">{t("routinePlanTarget")}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
          }

        </Row> 

        {/********************************OverView Order *****************************************/}
        {showTab == 1
          ? <Col className="table-responsive m-t-10" md="12" sm="12" lg="12">
            {/* <Accordion defaultActiveKey="0"> */}
            <div className="default-according" id="accordion">
              <Card style={{ backgroundColor: "#FFFFFF" }} className="p-l ">
                {/* {orderView.length > 0 && orderView.map((buy) => (
              <span className="p-l-10 cursor-pointer" title={t("click")} tooltip={t("click")} 
              alt={t("click")} onClick={() => toggle(1)} ><>
                <Btn attrBtn={{ as: Card.Header,className: "btn btn-link",color: "default", onClick: ()=> toggle(1)}} >
                  <h5>{t("orderRegistration")}</h5>
                </Btn>
                <span className="acorv" style={{ float: "Right" }}>
                  <Btn attrBtn={{ as: Card.Header, className: "btn btn-link", color: "default", onClick: ()=> toggle(1)}}>
                    {isOpen === 1 ? <span ><i className="fa fa-angle-up"></i></span>
                      : <span ><i className="fa fa-angle-down"></i></span>}
                  </Btn>
                </span>
                {(dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) && 
                  (buy.status == 1) && buy.step_level == 5 ?
                  // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editorder?id=` + encode(orderId)}>
                  //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>
                  <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5 m-b-5"
                   to={`${process.env.PUBLIC_URL}/editorder?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> 
                   {t("edit")} </Link> : ""}
                {buy.step_level != 5 && buy.status == 1 ?
                  buy.step_level == 1 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                    <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                      to={`${process.env.PUBLIC_URL}/ordersku?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> 
                      {t("updateOrder")} </Link>
                    : buy.step_level == 2 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                      <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                        to={`${process.env.PUBLIC_URL}/ordercontacts?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> 
                        {t("updateOrder")} </Link>
                      : buy.step_level == 3 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                        <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                          to={`${process.env.PUBLIC_URL}/ordertask?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> 
                          {t("updateOrder")} </Link>
                        : buy.step_level == 4 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                          <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                            to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                            <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                          : '' : "" } </></span> ))} */}

                {/* <Collapse isOpen={isOpen === 1}> */}
                <Card>
                  {orderView.length > 0 && orderView.map((buy) => (
                    <span className="p-l-10 cursor-pointer m-t-5 m-r-10" onClick={() => toggle(1)}><>
                     {(getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null ?
                      <>
                      {(dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) && 
                        (buy.status == 1) && buy.step_level == 6 
                        ?
                        //  {/* // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editorder?id=` + encode(orderId)}>  */}
                        // {/* //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>  */}
                        <Link 
                        title={t("Edit Order")} 
                        tooltip={t("Edit Order")}
                        style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5 m-b-5"
                              to={`${process.env.PUBLIC_URL}/editorder?id=` + encode(orderId) + '&qty_arr='+ encode(JSON.stringify(qty_arr))}>
                            <i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                        : ""
                      }
                      </>
                        :""}
                      {
                        getWorkspaceType == "Factory" 
                        ?
                          buy.step_level != 6 && buy.status == 1 ?
                            buy.step_level == 1 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                              <Link  
                              title={t("updateOrder")} 
                              tooltip={t("updateOrder")}
                              style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                to={`${process.env.PUBLIC_URL}/ordersku?id=` + encode(orderId)}>
                                  <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                              :
                              buy.step_level == 2 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                <Link 
                                title={t("updateOrder")} 
                                tooltip={t("updateOrder")}
                                style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/ordercontacts?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                : buy.step_level == 3 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                <Link  title={t("updateOrder")} 
                                tooltip={t("updateOrder")}
                                style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/ordertask?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                : buy.step_level == 4 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                  <Link  title={t("updateOrder")} 
                                  tooltip={t("updateOrder")}
                                  style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                    to={`${process.env.PUBLIC_URL}/ordermaterialslabels?id=` + encode(orderId)}>
                                      <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                    : buy.step_level == 5 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                    <Link  title={t("updateOrder")} 
                                    tooltip={t("updateOrder")}
                                    style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                      to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                                        <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                    : ''
                            :
                            ""
                        :
                            // Buyer || PCU
                            buy.step_level != 6 && buy.status == 1 ?
                            buy.step_level == 1 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                              <Link  
                              title={t("updateOrder")} 
                              tooltip={t("updateOrder")}
                              style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                to={`${process.env.PUBLIC_URL}/ordersku?id=` + encode(orderId)}>
                                  <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                              :
                              buy.step_level == 2 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                  <Link 
                                title={t("updateOrder")} 
                                tooltip={t("updateOrder")}
                                style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/ordercontacts?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                : buy.step_level == 3 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                  <Link  title={t("updateOrder")} 
                                tooltip={t("updateOrder")}
                                style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/ordertask?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                : buy.step_level == 4 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                  <Link  title={t("updateOrder")} 
                                    tooltip={t("updateOrder")}
                                    style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                      to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                                        <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                    : ''
                            :
                            ""
                      }
                    </>
                    </span>
                  ))}
                  <CardBody>
                    <Form>
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="table-bordered" >
                          <Col lg="12" md="12" sm="12">
                            <Row className="g-12">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <span className="subTitleOrderView f-left skudetailsclr p-1 "><span className="p-l-5">
                                 </span>{t("OrderDetails")}<span className="p-l-5"> </span></span>
                              </Col>
                            </Row>
                            <Row className="g-12 m-t-10">
                              {orderView.length > 0 ?
                                orderView[0]['factory'] == null ? " " :
                                  <Col className="b-r-order" lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                      <Label>{t("factory")}</Label>
                                      <InputGroup>
                                        {orderView[0]['factory']}
                                      </InputGroup>
                                    </FormGroup>
                                  </Col> : ""
                              }

                              {orderView.length > 0 ?
                                orderView[0]['buyer'] == null ? " " :
                                  <Col className="b-r-order" lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                      <Label>{t("buyer")}</Label>
                                      <InputGroup>
                                        {orderView[0]['buyer']}
                                      </InputGroup>
                                    </FormGroup>
                                  </Col> : ""
                              }
                              {orderView.length > 0 ?
                                orderView[0]['pcu'] == null ? " " :
                                  <Col className="b-r-order" lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                      <Label>{t("productionControlUnit")}</Label>
                                      <InputGroup>
                                        {orderView[0]['pcu']}
                                      </InputGroup>
                                    </FormGroup>
                                  </Col> : ""
                              }
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label>{t("orderNo")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ?
                                      orderView[0]['order'] :
                                      "-"
                                    }
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label>{t("styleNo")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ?
                                      orderView[0]['style'] :
                                      "-"
                                    }
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Row>
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="m-t-5 table-bordered p-r-10">
                          <Col md="12" lg="12" sm="12">
                            <Row className="g-12"><Col lg="12" md="12" sm="12" xs="12">
                              <span className="subTitleOrderView f-left skudetailsclr p-1">
                              <span className="p-l-5"> </span>{t("itemDescriptionArticle")}
                              <span className="p-l-5"> </span></span></Col></Row>
                            <Row className="g-12 m-t-10">
                              <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("fabricType")}</Label>
                                  <InputGroup>
                                    {orderFabricType.length > 0 ?
                                      orderFabricType[0]['name'] :
                                      "-"
                                    }
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("category")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ?
                                      orderView[0]['catname'] ? orderView[0]['catname']:"-" :
                                      ""
                                    }
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("articleName")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['articlename'] : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                        </Card>
                      </Row>
                      {(getStaff === "Staff" && getStaffPermission.includes('View Price')) || getStaff == null ?
                        <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                          <Card className="m-t-5 table-bordered">
                            <Col md="12" lg="12" sm="12">
                              <Row className="g-12">
                                <Col lg="12" md="12" sm="12" xs="12">
                                <span className="subTitleOrderView f-left skudetailsclr p-1">
                                <span className="p-l-5"> </span> {t("commercialDetails")}
                                <span className="p-l-5"> </span></span>
                                </Col>
                              </Row>
                              <Row className="g-12 m-t-10">
                                <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("currency")}</Label>
                                    <InputGroup>
                                      {orderView.length > 0 ? orderView[0]['currency_type'] !=""?orderView[0]['currency_type']:"-": ""}
                                    </InputGroup>
                                  </FormGroup>
                                </Col>

                                <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("price")}</Label>
                                    <InputGroup>
                                      {orderView.length > 0 ? orderView[0]['order_price'] !=0 ? orderView[0]['order_price']: "-":''}
                                    </InputGroup>
                                  </FormGroup>
                                </Col>

                                <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("units")}</Label>
                                    <InputGroup>
                                    { 
                                      orderView.length > 0 && orderView[0]['units']!= 0 
                                      ?
                                        Object.keys(unitsList).length > 0 
                                        ? 
                                          Object.values(unitsList)
                                          .map((mapData) => {
                                            return(
                                              <>
                                                {
                                                  orderView[0]['units'] == mapData.id
                                                  ?
                                                    mapData.name
                                                  :
                                                    ""
                                                }
                                              </>
                                            )
                                          }) 
                                        :
                                          ""
                                      :
                                        ""
                                    }
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                                <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("incomeTerms")}</Label>
                                    <InputGroup>
                                      {orderView.length > 0 ? orderView[0]['incoTerms'] != null ? orderView[0]['incoTerms']:"-" : ""}
                                    </InputGroup>
                                  </FormGroup>
                                </Col>

                              </Row>
                            </Col>
                          </Card>
                        </Row> :
                         <>
                        <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                          <Card className="m-t-5 table-bordered">
                            <Col md="12" lg="12" sm="12">
                              <Row className="g-12">
                                <Col lg="12" md="12" sm="12" xs="12">
                                <span className="subTitleOrderView f-left skudetailsclr p-1">
                                <span className="p-l-5"> </span> {t("commercialDetails")}
                                <span className="p-l-5"> </span></span>
                                </Col>
                              </Row>
                              <Row className="g-12 m-t-10">
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("units")}</Label>
                                    <InputGroup>
                                    { 
                                      orderView.length > 0 && orderView[0]['units']!= 0 
                                      ?
                                        Object.keys(unitsList).length > 0 
                                        ? 
                                          Object.values(unitsList)
                                          .map((mapData) => {
                                            return(
                                              <>
                                                {
                                                  orderView[0]['units'] == mapData.id
                                                  ?
                                                    mapData.name
                                                  :
                                                    ""
                                                }
                                              </>
                                            )
                                          }) 
                                        :
                                          ""
                                      :
                                        ""
                                    }
                                    </InputGroup>
                                  </FormGroup>
                                </Col>
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                  <FormGroup>
                                    <Label className="m-t-5">{t("incomeTerms")}</Label>
                                    <InputGroup>
                                      {orderView.length > 0 ? orderView[0]['incoTerms'] != null ? orderView[0]['incoTerms']:"-" : ""}
                                    </InputGroup>
                                  </FormGroup>
                                </Col>         
                            
                                </Row>  
                            </Col>
                          </Card>
                          </Row>
                          </>}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="m-t-5 table-bordered ">
                          <Col md="12" lg="12" sm="12">
                            <Row className="g-12">
                              <Col lg="12" md="12" sm="12" xs="12">
                              <span className="subTitleOrderView f-left skudetailsclr p-1">
                              <span className="p-l-5"> </span>{t("quantityDetails")}
                              <span className="p-l-5"> </span>
                              </span>
                              </Col>
                              </Row>
                            <Row className="g-12 m-t-10 ">
                              <Col lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("totalQuantity")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['quantity'] : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("totalDeliveries")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['no_of_deliverys'] : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("toleranceQuantity")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['tolerance_volume'] : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("tolerancePercentage")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['tolerance_perc'] : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("poDate")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['inquiry_date']!= null ? orderView[0]['inquiry_date']:"-" : ""}
                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("delDate")}</Label>
                                  <InputGroup>
                                    {/* {orderView.length > 0 ? orderView[0]['delivery_date'] : ''} */}

                                    { 
                                delDate.length > 0?  
                                delDate.map((dataDeliveryDate,index) => (
                              // <span className="btn text-center " 
                              //       style={{ height: '35px', width: '145px', borderRadius: '18px', borderColor: '#ccc', 
                              //                marginLeft: '10px', marginTop: '5px',marginBottom:"10px", fontSize: '12px', color: '#878787', fontWeight: '600',cursor:"unset"  }}
                              //                > 
                              <>
                              {PHPtoJSFormatConversion.format(new Date(dataDeliveryDate))}<br></br>
                              {/* // </span> */}
                            
                            </>
                               ))
                             :
                             "-"} 

                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("orderPriority")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ? orderView[0]['order_priority'] != ""?orderView[0]['order_priority']:"-": ''}

                                  </InputGroup>
                                </FormGroup>
                              </Col>

                              <Col className="b-r-order" lg="3" md="12" sm="12">
                                <FormGroup>
                                  <Label className="m-t-5">{t("isToleranceRequired")}</Label>
                                  <InputGroup>
                                    {orderView.length > 0 ?
                                      orderView[0]['is_tolerance_req'] == 1 ? "Yes" : "No" : "-"}
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>

                          </Col>
                        </Card>
                      </Row>
                      <Row>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                {/* </Collapse> */}
              </Card>
            </div>
            {/* </Accordion> */}
          </Col>
          : ""}

        {/********************************OverView SKU Table *****************************************/}
        {showTab == 2
          ? 
            <Col className="table-responsive m-t-10" md="12" sm="12" lg="12">
              <div className="default-according" id="accordions5">
                <Card style={{ backgroundColor: "#FFFFFF" }} className="m-t-5">
                  <Col md="12" sm="12" lg="12">
                    <Card className="shadow-sm shadow-showcase border-none">
                      <CardBody className="m-t-10">
                        <Card>
                          <Form className="needs-validation" noValidate="">
                            <CardHeader>
                              <Row className="g-12 subTitleOrderSku">
                                <Col lg="12" md="12" sm="12">
                                  <span className="skudetailsclr p-1 "><span className="p-l-5"> </span>
                                    {t("sKUDetails")}<span className="p-l-5"> </span>
                                  </span>
                                  <Row className="g-12">
                                  </Row>
                                </Col>
                              </Row>
                            </CardHeader>
                            {getSize.length>0?
                            <Row className="g-12">
                              <div className="table-responsive ">
                                <table className="table table-bordered custom-table-striped text-center">
                                  <thead >
                                    <tr className="overViewSku">
                                      <th scope="col">{t("colorSize")}</th>
                                      {getSize.length > 0 && getSize.map((option) => {
                                        return ( 
                                        <th>{option.name}</th>)  })}
                                        <th scope="col">{t("total")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tableRowCol" >
                                    {getColor.map((optionc) => {
                                      return (
                                        <tr>
                                          <th>{optionc.name}</th>
                                          {getSize.map((option) => {
                                            return (
                                              <th className="text-muted">
                                                {getColorSizeQty(orderskuDetails, optionc.id, option.id)}
                                              </th>
                                            );
                                          })}
                                          {colorTotal.length > 0 && colorTotal.map((totalcolor) => {
                                            let colorTotal = [];
                                            if (totalcolor.sku_color_id === optionc.id) {
                                              colorTotal.push(totalcolor.colorTotal)
                                              sum += Number(colorTotal);
                                            }
                                            return (
                                              <>
                                                {colorTotal.length > 0 ?
                                                  <th>
                                                    {colorTotal}
                                                  </th> : ""
                                                }
                                              </>
                                            );
                                          })}
                                        </tr>
                                      );
                                    })}
                                    <tr>
                                      <th scope="col">{t("total")}</th>
                                      {sizeTotal.length > 0 && sizeTotal.map((totalsize) => {
                                        return (
                                          <th>
                                            {totalsize.sizeTotal}
                                          </th>
                                        );
                                      })}
                                      {getColor.length > 0 && getColor.map((optionc, p) => {
                                        return (
                                          <>
                                            {getColor.length > 0 && p == (getColor.length - 1) ?
                                              <th>
                                                {sum}
                                              </th> : ""
                                            }
                                          </>
                                        );
                                      })}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Row>
                            :
                            <div className="p-5 align-self-center media-body centerAlign"
                              style={{ marginTop: "75px" }}>
                              <img src={noData} style={{ width: "200px" }} />
                              <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                            </div>
                          }
                          </Form>
                        </Card>
                        
                        <Col className="my-4" md="12" sm="12" lg="12" >
                          <div className="default-according" id="accordions1">
                            <Col className="table-responsive m-t-10" md="12" sm="12" lg="12">
                              <div className="default-according" id="accordions5">
                                <Card style={{ backgroundColor: "#FFFFFF" }} className="m-t-15 ">
                                  <Col md="12" sm="12" lg="12">
                                    <CardBody className="shadow-sm shadow-showcase">
                                      <Form className="needs-validation" noValidate="">
                                        <Row className="g-12 subTitleOrderSku">
                                          <Col lg="12" md="12" sm="12">
                                            {
                                            orderView.length > 0 ?
                                              orderView[0].no_of_deliverys > 1
                                                ?
                                                <span className="skudetailsclr p-1 ">
                                                  <span className="p-l-5"> </span>
                                                  {t("viewPartiallyDel")}
                                                  <span className="p-l-5"> </span>
                                                </span>

                                                :
                                                <span className="skudetailsclr p-1 ">
                                                  <span className="p-l-5"> </span>
                                                  {t("deliveryDetails")}
                                                  <span className="p-l-5"> </span>
                                                </span>
                                                :
                                                ""
                                            }
                                            <Row className="g-12"></Row>
                                          </Col>
                                        </Row>

                                        <Row className="m-t-20">
                                          <Col className="verticalAlignmentStart">
                                            <FormGroup style={{ width: '50%', color: '#000' }}>
                                              <Label>{t("delDate")}</Label>
                                              <InputGroup>
                                                <Input
                                                  type="select"
                                                  placeholder={t("selectDelDate")}
                                                  className="form-control"
                                                  name="Delivery Date"
                                                  onChange={(e) => { partialDeliveryDetails(e.target.value) }}
                                                >
                                                  <option value="" disabled>
                                                    {t("selectDelDate")}
                                                  </option>
                                                  {
                                                    deliveryDates !== undefined ?
                                                      deliveryDates.length > 0
                                                        ?
                                                        deliveryDates.map((mapData) =>
                                                        (
                                                          mapData.delivery_date == deliveryDate ?
                                                            <option selected value={mapData.delivery_date}>{mapData.delivery_date}</option>
                                                            :
                                                            <option value={mapData.delivery_date}>{mapData.delivery_date}</option>
                                                        )
                                                        )
                                                        :
                                                        ""
                                                      :
                                                      ""
                                                  }
                                                </Input>
                                              </InputGroup>
                                            </FormGroup>
                                          </Col>
                                          <Col className="verticalAlignmentEnd">
                                            <table className="shadow-sm shadow-showcase" style={{ backgroundColor: '#f2f2f2' }}>
                                              <thead className="f-w-400 text-center " style={{ fontSize: '13px' }}>
                                                <td style={{padding:'10px'}}> {t("totalQuantity")} </td>
                                                <td style={{padding:'10px'}}> {t("noOfDeliverys")} </td>
                                                <td style={{padding:'10px'}}> {t("totDelvd")} </td>
                                              </thead>
                                              <tbody className="f-w-600 text-center " style={{border: '1px solid #f2ffff'}}>
                                                <td style={{ padding: '10px', paddingTop: '10px', backgroundColor: '#fff'}}>
                                                  {basicInfo.quantity}
                                                </td>
                                                <td style={{ padding: '10px', paddingTop: '10px', backgroundColor: '#fff', }} >
                                                  {noOfDelivered ? noOfDelivered : 0} / {noOfDeliveries ? noOfDeliveries : 0}
                                                </td>
                                                <td style={{ padding: '10px', paddingTop: '10px', backgroundColor: '#fff'}}>
                                                  {totalDeliveredQty ? totalDeliveredQty : 0}
                                                </td>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                        {totalDeliveredQuantity != 0?
                                          <>  
                                        {size.length > 0 && color.length > 0 ?
                                          <Row className="m-t-20 m-b-20">
                                            <div className="table-responsive">
                                              <table className="table custom-table-striped text-center shadow-sm shadow-showcase">
                                                <thead >
                                                  <tr className="overViewSku">
                                                    <th className="" scope="col"> {t("colorSize")} </th>
                                                    {size.length > 0 && size.map((option) => {
                                                      return (
                                                      <th className="">{option.sizeName}</th>) })}
                                                      <th className="" scope="col">{t("total")}</th>
                                                  </tr>
                                                </thead>
                                                <tbody className="tableRowCol" >
                                                  {color.map((optionc) => {
                                                    var colorTotal = 0;
                                                    return (
                                                      <tr>
                                                        <th className="">{optionc.colorName}</th>
                                                        {size.map((option) => {
                                                          colorTotal += Number(partialDeliveredQty(partialDeliveredSkuData, optionc.color_id, option.size_id))
                                                          return (
                                                            <th className="text-muted">
                                                              {partialDeliveredQty(partialDeliveredSkuData, optionc.color_id, option.size_id)}
                                                            </th>
                                                          );
                                                        })}
                                                        <th className=""> {colorTotal} </th>
                                                      </tr>
                                                    );
                                                  })}
                                                  <tr>
                                                    <th className="">{t("total")}</th>
                                                    {size.map((option) => {
                                                      var sizeTotal = 0;
                                                      color.map((optionc) => {
                                                        sizeTotal += Number(partialDeliveredQty(partialDeliveredSkuData, optionc.color_id, option.size_id));
                                                      })
                                                      overallTotal += Number(sizeTotal);
                                                      return (<> <th className=""> {sizeTotal} </th> </>  )
                                                    })}
                                                    <th className=""> {overallTotal} </th>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </Row>
                                          :
                                          <div className="p-5 align-self-center media-body centerAlign"
                                            style={{ marginTop: "75px" }}>
                                            <img src={noData} style={{ width: "200px" }} />
                                            <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                                          </div>
                                        } 
                                        <Row style={{ paddingRight: '15px' }}>
                                          {/* <Card style={{ backgroundColor: "#FFFFFF" }} className="m-t-15 m-b-10 shadow-sm shadow-showcase">
                                            <Col md="12" sm="12" lg="12" >
                                              <CardBody>
                                                <Form className="needs-validation" noValidate="">
                                                  <Row className="g-12 subTitleOrderSku ">
                                                    <Col lg="12" md="12" sm="12">
                                                      <span className="skudetailsclr p-1 ">
                                                        <span className="p-l-5"> </span>
                                                        {t("delvyNotes")}
                                                        <span className="p-l-5"> </span>
                                                      </span>
                                                      <Row className="g-12 m-t-35 p-l-10">
                                                        {deliveryComments ? deliveryComments : t("delvyNotesNotAvailable")}
                                                      </Row>
                                                    </Col>
                                                  </Row>
                                                </Form>
                                              </CardBody>
                                            </Col>
                                          </Card> */}

                                          <Col>
                                          
                                          <P className="p-2">
                                            <span className="f-14"> {t("delvyNotes")} : </span>
                                            <span className="f-12"> {deliveryComments ? deliveryComments : t("delvyNotesNotAvailable")} </span>
                                        </P> 
                                          </Col>
                                        </Row>
                                        </>:""} 
                                      </Form>
                                    </CardBody>
                                  </Col>
                                </Card>
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Card>
              </div>
            </Col>
          : 
            ""
          }

        {/******************************** OverView Contact List *****************************************/}
        {showTab == 3
          ? 
            <Col className="table-responsive m-t-20" lg="12" md="12" sm="12">
              <Col>
                {/* <Accordion defaultActiveKey="0"> */}
                <div className="default-according" id="accordion" >
                  <Card style={{ backgroundColor: "#FFFFFF", minHeight: "650px"}} >
                    {/* {orderView.map((buy) => (
                    <span className="p-l-10 cursor-pointer" title={t("click")} tooltip={t("click")} alt={t("click")} onClick={() => toggle(3)}  >
                      <>
                        <Btn attrBtn={{ as: Card.Header,className: "btn btn-link", color: "default",
                            onClick: () => toggle(3),  }}  >
                          <h5>{t("contactList")}</h5>
                        </Btn>
                        <span style={{ float: "Right" }}><Btn
                          attrBtn={{as: Card.Header,className: "btn btn-link ", color: "default",
                            onClick: () => toggle(3),}}  >
                          {isOpen === 3 ? <span ><i className="fa fa-angle-up"></i></span>
                            : <span ><i className="fa fa-angle-down"></i></span>}
                        </Btn>
                        </span>
                        {buy.status == 1 && buy.step_level == 5 ?
                          // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                          //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>
                            <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                            to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                            <i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                          : ""}
                      </>
                    </span>
                  ))} */}
                    {/* <Collapse isOpen={isOpen === 3}> */}
                    <CardBody className="m-t-10">
                      {/* {orderView.map((buy) => (
                    <span className="p-l-10 cursor-pointer" title={t("click")} tooltip={t("click")} alt={t("click")} onClick={() => toggle(3)}  >
                      <>
                        <Btn
                          attrBtn={{ as: Card.Header,
                            className: "btn btn-link",color: "default", onClick: () => toggle(3),}}>
                          <h5>{t("contactList")}</h5>
                        </Btn>
                        <span style={{ float: "Right" }}><Btn
                          attrBtn={{as: Card.Header,
                            className: "btn btn-link ",color: "default",onClick: () => toggle(3)}}
                        >
                          {isOpen === 3 ? <span ><i className="fa fa-angle-up"></i></span>
                            : <span ><i className="fa fa-angle-down"></i></span>}
                        </Btn>
                        </span>
                        {buy.status == 1 && buy.step_level == 5 ?
                          // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                          //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>
                            <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                            to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                            <i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                          :
                          ""}
                      </>
                    </span>
                  ))} */}
                      <Row className="g-12 subTitleOrdercontact ">
                        {orderView.map((buy) => (
                          <span className="p-l-10 cursor-pointer" onClick={() => toggle(3)}  >
                            {(getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null ?
                            <>
                          
                              {buy.status == 1 && buy.step_level == 6 ?
                                // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                                //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>
                                <Link  
                                title={t("Edit Order")} 
                                tooltip={t("Edit Order")}  style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/editcontacts?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                                :
                                ""}
                            </>
                            :""} 
                          </span>
                        ))}
                        <Col className="m-t-25" md="12" lg="12" sm="12">
                          <Card >
                          {/* <CardHeader> */}
                              <Row className="g-12 subTitleOrdercontact">
                                <Col lg="12" md="12" sm="12" className="m-l-10">
                                  <span className="skudetailsclr p-1 "><span className="p-l-5"> </span>
                                    {t("contacts")}<span className="p-l-5"> </span>
                                  </span>
                                  <Row className="g-12">
                                  </Row>
                                </Col>
                              </Row>
                            {/* </CardHeader> */}
                            {orderContact.length>0?
                            <Row className="g-12 m-t-10">
                              <div className="table-responsive ">
                                <table className="table table-bordered">
                                  <thead className="overViewSku">
                                    <tr>
                                      <th scope="col">{t("serialNo")}</th>
                                      <th className="marg-min-top-10">
                                        <Label className="form-label"> </Label> {t("name")} </th>
                                        { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                                      <th scope="col">{t("email")}</th> :""}
                                      <th scope="col">{t("userType")}</th>
                                      <th scope="col">{t("role")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tableRowCol">
                                    {orderContact.map((staffList, s) =>
                                    (
                                      <>
                                        <tr key={staffList.id}>
                                          <td scope="row">{s + 1}</td>
                                          <td>
                                            <Label for={staffList.id} className="form-label"></Label>
                                            {staffList.first_name} {staffList.last_name}
                                          </td>                                 
                                      { (getStaff === "Staff"&&(getStaffPermission.includes('View Staff')))||getStaff == null? 
                                      <td>{ staffList.email} </td>: ""
                                      // <td className="">{blurEmail(staffList.email)}</td>
                                      }
                                         <td>{staffList.user_type ? staffList.user_type =="PCU" ? "Buying House":staffList.user_type :"-"}</td>
                                          
                                          <td>{staffList.name}</td>
                                        </tr>
                                      </>
                                    )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </Row>:
                            <div className="p-5 align-self-center media-body centerAlign"
                            style={{ marginTop: "75px" }}>
                            <img src={noData} style={{ width: "200px" }} />
                            <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                          </div>}
                          </Card>
                        </Col>
                        <Col md="12" lg="12" sm="12">
                          &nbsp;
                        </Col>
                      </Row>
                    </CardBody>
                    {/* </Collapse> */}
                  </Card>
                </div>
                {/* </Accordion> */}
              </Col>
            </Col>
          : 
            ""
          }

        {/********************************OverView Task List *****************************************/}
        {showTab == 4
          ? 
            <Col className="table-responsive m-t-10" lg="12" md="12" sm="12">
                <Col>
                  {/* <Accordion defaultActiveKey="0"> */}
                  <div className="default-according" id="accordions5">
                    <Card style={{ backgroundColor: "#FFFFFF" }} className="">
                      {/* {orderView.map((buy) => (
                      <span className="p-l-10 cursor-pointer m-t-5" title={t("click")} tooltip={t("click")} 
                      alt={t("click")} onClick={() => toggle(4)}>
                        <>
                          <Btn
                            attrBtn={{
                              as: Card.Header,
                              className: "btn btn-link", color: "default", onClick: () => toggle(4),
                            }}
                          >
                            <h5>{t("taskSchedule")}</h5>
                          </Btn>

                          <span style={{ float: "Right" }}>
                            <Btn
                              attrBtn={{
                                as: Card.Header,
                                className: "btn btn-link", color: "default", onClick: () => toggle(4),
                              }}
                            >
                              {isOpen === 4 ? <span className="accdview"><i className="fa fa-angle-up"></i></span>
                                : <span className="accdview"><i className="fa fa-angle-down"></i></span>}
                            </Btn>
                          </span>
                          {(dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) && buy.status == 1 && buy.step_level == 5 ?
                            // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editordertask?id=` + encode(orderId)}>
                            //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link>

                            <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                            to={`${process.env.PUBLIC_URL}/editordertask?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                            :
                            ""}
                        </>
                      </span>
                    ))} 

                      {/* <Collapse isOpen={isOpen === 4}> */}
                      {orderView.map((buy) => (
                        <span className="p-l-10 cursor-pointer m-r-10 m-t-4" onClick={() => toggle(4)}>
                            {(getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null ?
                          <>
                            {(dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) && buy.status == 1 && buy.step_level == 6 ?
                              //  {/* // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editordertask?id=` + encode(orderId)}>
                              //  //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" /></Link> */}
                              <Link title={t("Edit Order")} 
                              tooltip={t("Edit Order")}  style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                to={`${process.env.PUBLIC_URL}/editordertask?id=` + encode(orderId)}><i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                              :
                              ""}
                          </>
                          :""}
                        </span>
                      ))}

                      {uploadedFile == ""
                        ? "" :
                        <Col lg="12" md="12" sm="12">
                          <CardHeader className="m-t-1" style={{ backgroundColor: "#F6F6F6 " }}>
                            <Row className="m-b-15">
                            <H5>{t("uploadFileBtn")}</H5>
                            </Row>
                            <Row className="g-12 m-t-1" style={{ backgroundColor: "#F6F6F6" }}>
                              {uploadedFile.map((file) => (
                                <>
                                  <Col lg="3" md="12" sm="12">
                                    <table>
                                      <tr>
                                        <td className="">
                                          <i className="fa fa-file-o f-30"></i>
                                        </td>
                                        <td className="">
                                          <p className="f-left f-12 filesfnt " ><strong>{truncate(file.filename)}</strong><br />
                                            <small>{sizeConvertor(file.filesize)}</small></p>
                                        </td> &nbsp;&nbsp;
                                        &nbsp;
                                        &nbsp;&nbsp;
                                        &nbsp;&nbsp;
                                        <td className=''>
                                          <a href="javascript:void(0)" title={t("downloadFile")}
                                          onClick={() => downloadFile(file.filename, file.orginalfilename)}>
                                            <i className="fa fa-download f-16 font-dark "></i></a>
                                        </td>
                                      </tr>
                                    </table>
                                  </Col>
                                </>
                              ))}
                            </Row>
                          </CardHeader>
                          {/* <hr className="m-t-5" /> */}
                        </Col>}
                        {orderTaskDetails!=""?
                      <CardBody style={{ overflowY: 'hidden' }}>
                        {orderTaskDetails.map((subtitle) => (
                          <Col lg="12" md="12" sm="12">
                            {/* <Card>
                              <Form className="needs-validation" noValidate="">
                              <>
                              <CardHeader>
                                  <Row className="g-12 subTitleOrderSku m-t-5">
                                    <Col lg="12" md="12" sm="12">
                                      <span className="skudetailsclr p-1 "><span className="p-l-5"> </span>
                                        {t("sKUDetails")}<span className="p-l-5"> </span>
                                      </span>
                                      <Row className="g-12">
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardHeader> */}
                            {/* <span className="p-l-10 editcompanytask"><span className="m-t-5 p-1 overViewSku">
                                  <span className="p-l-10 p-t-5 p-b-5"></span>
                                  {subtitle.task_title} <span className="p-l-10 p-t-10 p-b-10">
                                  </span></span></span> */}

                            <CardBody className="p-t-0">
                              <Row className="m-b-1 border-1 b-light shadow-sm shadow-showcase">
                                <Table className="table-responsive">
                                  <th className="taskUpdate-table-overview m-r-5"><span className="p-l-5"> </span><b>{subtitle.task_title}</b></th>
                                  <th className="taskUpdate-table-overview f-12"><b>{t("scheduleStartDate")}</b></th>
                                  <th className="taskUpdate-table-overview f-12"><b>{t("scheduleEndDate")}</b></th>
                                  <th className="taskUpdate-table-overview f-12"><b>{t("accomplishedDate")}</b></th>
                                  <th Person className="taskUpdate-table-overview f-12"><b>{t("personInCharge")}</b></th>
                                  <>
                                    {subtitle.task_subtitles.map((data) => (
                                      <><tr className="taskUpdate-tr-border">
                                        <td className="taskUpdate-table-side-overview" width="25%"> <span className="p-l-5">
                                          <img src={task_icon} title={t("Task")} tooltip={t("Task")} alt={t("Task")} className="rescheduleImage" />
                                        </span>{data.subtitle} </td>
                                        <td>
                                          <Row>
                                            <Col sm={11}>
                                              <Input type="date"data-target-input="nearest" defaultValue={data.start_date} 
                                                    readOnly style={{ backgroundColor: "#FFFFFF" }} >
                                              </Input>
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          <Row>
                                            <Col sm={11}>
                                              <Input type="date" data-target-input="nearest"
                                                defaultValue={data.end_date}
                                                readOnly style={{ backgroundColor: "#FFFFFF" }}
                                              >
                                              </Input>
                                            </Col>
                                          </Row>
                                        </td>
                                        <td>
                                          <>
                                            <Input  type="date" data-target-input="nearest"
                                              defaultValue={data.accomplished_date}
                                              style={{ backgroundColor: "#FFFFFF" }}
                                              readOnly
                                            >
                                            </Input>
                                          </>
                                        </td>
                                        <td>
                                          <>
                                            {data.first_name === null
                                              ?
                                              "--------"
                                              :
                                              <Input
                                                className="cursor-pointer"
                                                title={data.first_name + "  " + data.last_name} tooltip={data.first_name + "  " + data.last_name} alt={data.first_name + "  " + data.last_name}
                                                type="data"
                                                defaultValue={data.first_name + "  " + data.last_name}
                                                style={{ backgroundColor: "#FFFFFF" }}
                                                readOnly
                                              >
                                              </Input>
                                            }
                                          </>
                                        </td>
                                      </tr>
                                      </>
                                    ))}
                                  </>
                                </Table>
                              </Row>
                            </CardBody>
                            {/* </> */}
                            {/* //  </Form> */}
                            {/* //      </Card> */}
                          </Col>
                        ))}
                      </CardBody>
                      :
                      <div className="p-5 align-self-center media-body centerAlign"
                      style={{ marginTop: "75px" }}>
                      <img src={noData} style={{ width: "200px" }} />
                      <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                    </div>}
                    </Card>
                  </div>
                </Col>
            </Col>
          : 
            ""
        }
       {/******************************** Overview Materials Labels *****************************************/}
       { showTab == 5
        ?
          <Col className="table-responsive m-t-10" md="12" sm="12" lg="12">
            <div className="default-according" id="accordion">
              <Card style={{ backgroundColor: "#FFFFFF" }} className="p-l">
                <Card> 
                <span className="p-l-10 cursor-pointer m-t-5 m-r-10" onClick={() => toggle(1)}>               
                  {orderView.length > 0 && orderView.map((buy) => (
                    <>
                      {(getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null ?
                     <>
                      {
                      // (dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) &&  
                      (buy.status == 1) && buy.step_level == 6 ?
                        <>
                          <Link  
                            title={t("Edit Order")} 
                            tooltip={t("Edit Order")} 
                            alt={t("Edit Order")}
                            style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5 m-b-5 m-r-20"
                            to={`${process.env.PUBLIC_URL}/editordermaterialslabels?id=` + encode(orderId)}>
                              <i className="icofont icofont-ui-edit"></i>&nbsp;
                              {t("edit")} 
                          </Link>
                          
                        </>
                        : ""}
                      </>
                        :""}
                      {/* {buy.step_level != 6 && buy.status == 1 ?
                           buy.step_level == 2 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                            <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                              to={`${process.env.PUBLIC_URL}/ordercontacts?id=` + encode(orderId)}>
                                <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                            : buy.step_level == 3 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                              <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                to={`${process.env.PUBLIC_URL}/ordertask?id=` + encode(orderId)}>
                                  <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                              : buy.step_level == 4 && buy.status == 1 && (dateCount > 0 || orderViewData == null) ?
                                <Link style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                                  to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                                    <i className="icofont icofont-ui-edit"></i> {t("updateOrder")} </Link>
                                : ''
                        :
                        ""
                      } */}
                    </>
                    
                  ))}

                  {
                  (sewing_accessoriest.length > 0) || 
                  (packingAccessoriest.length > 0) || 
                  (miscellaneousList.length > 0) 
                  ?
                    <div 
                        title={t("download")} 
                        tooltip={t("download")} 
                        className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" 
                        onClick={()=> {
                          DownloadimagePermissionModal()
                          setdownloadstyle(orderView[0].style)
                          }}
                    >
                      <img src={DownloadIcon} />
                    </div>
                  :
                    ""
                  } 

                </span>
                  <CardBody className="viewMaterialsLabel">
                    <Form>
                      {/* Sewing Accessories */}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="table-bordered">
                          <Col lg="12" md="12" sm="12">
                            <Row className="g-12">
                              <Col className="" lg="12" md="12" sm="12" xs="12">
                                <span className="subTitleOrderView f-left skudetailsclr p-1 "><span className="p-l-5">
                                 </span>{t("sewingAccessories")}<span className="p-l-5"> </span></span>
                              </Col>
                            </Row>
                            <Row className="g-12 m-t-10">

                            {
                            sewing_accessoriest.length>0
                            ?
                              <div className="table-responsive p-l-0 p-r-0">
                                <table className="table table-bordered">
                                  <thead className="overViewSku">
                                    <tr>
                                      <th className=' '>{t("type")}</th>
                                      <th className=''>{t("component")}</th>
                                      <th className=''>{t("colour")}</th>
                                      <th className=''>{t("vendorInformation")}</th>
                                      <th className=''>{t("perGMT")}</th>
                                      <th className=''>{t("totQty")}</th>
                                      <th className=''>{t("leadTime")}</th>
                                      <th className=''>{t("units")}</th>
                                      <th className=''>{t("pricePerUnit")}</th>
                                      <th className=''>{t("totPrice")}</th>
                                      <th className='text-center '>{t("labelSample")}</th>
                                    </tr>
                                  </thead>
                                  <tbody className="tableRowCol">
                                      {JSON.parse(sewing_accessoriest).map((sewing, index)=>
                                        (
                                          <>
                                              <tr className={`${ index+1 !== sewing_accessoriest.length ? "" : ""}`}>
                                                <td className=''  > {sewing.Type ? sewing.Type : "-"} </td>
                                                <td className='' > {sewing.component? sewing.component : "-"} </td>
                                                <td className=''> {sewing.color? sewing.color : "-"} </td>
                                                <td className=''>
                                                  {vendorList.length>0?
                                                    (sewing.vendor!='0' && sewing.vendor!='Select Vendor' )?
                                                    vendorList.map((vendor, index) =>                                                  
                                                    (sewing.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                  ) :"-"
                                                  :"-"}
                                                </td>
                                                <td id={sewing.perGMT} className=''> {sewing.perGMT? sewing.perGMT : "-"} </td>
                                                <td className=''> {sewing.totalQty? sewing.totalQty : "-"} </td>
                                               <td  className=''> {sewing.leadTime ?sewing.leadTime:"-"}  </td>
                                                <td className=''> {sewing.units=="0"?"-":sewing.units} </td>
                                                <td className=''> {sewing.pricePerUnit? sewing.pricePerUnit : "-"} </td>
                                                <td className=''> {sewing.totalPrice? sewing.totalPrice : "-"} </td>
                                                <td className='text-center'>  
                                                  {
                                                  sampleMediaListType.includes(sewing.media_type) ?
                                                  <img
                                                    title="View Sample Images"
                                                    className="cursor-pointer"
                                                    onClick={() => { MaterialsImageModal(sewing.media_type,sewing.Type) }}
                                                    src={viewLabelSampleIcon}
                                                    width="27px"
                                                    height="27px" >
                                                  </img> 
                                                :
                                                  <img
                                                    title={t("noData")}
                                                    style={{ cursor: 'no-drop' }}
                                                    src={labelSampleIcon}
                                                    width="25px"
                                                    height="25px" >
                                                  </img> 
                                                  }
                                                </td>
                                              </tr>
                                          </>
                                        ))
                                      }
                                  </tbody>
                                </table>
                              </div>
                            :
                              <div className="p-5 align-self-center media-body centerAlign"
                                style={{ marginTop: "75px" }}>
                                <img src={noData} style={{ width: "200px" }} />
                                <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                              </div>
                            }
                            </Row>
                          
                          </Col>
                        </Card>
                      </Row>

                      {/* Packing Accessories */}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="m-t-5 table-bordered p-r-10">
                          <Col md="12" lg="12" sm="12">
                            <Row className="g-12"><Col lg="12" md="12" sm="12" xs="12">
                              <span className="subTitleOrderView f-left skudetailsclr p-1">
                              <span className="p-l-5"> </span>{t("packingAccessories")}</span></Col></Row>
                              <Row className="g-12 m-t-10">

                                { packingAccessoriest.length>0
                                ?
                                  <div className="table-responsive p-r-0 p-l-0">
                                    <table className="table table-bordered">
                                      <thead className="overViewSku">
                                        <tr>
                                        <th className=' '>{t("type")}</th>
                                          <th className=''>{t("component")}</th>
                                          <th className=''>{t("colour")}</th>
                                          <th className=''>{t("vendorInformation")}</th>
                                          <th className=''>{t("perGMT")}</th>
                                          <th className=''>{t("totQty")}</th>
                                          <th className=''>{t("leadTime")}</th>
                                          <th className=''>{t("units")}</th>
                                          <th className=''>{t("pricePerUnit")}</th>
                                          <th className=''>{t("totPrice")}</th>
                                          <th className='text-center '>{t("labelSample")}</th>
                                        </tr>
                                      </thead>
                                      <tbody className="tableRowCol">
                                      {JSON.parse(packingAccessoriest).map((packing, index) =>
                                        (
                                          <>
                                            <tr className={`${ index+1 !== packingAccessoriest.length ? "" : ""}`}>
                                              <td className=''  > {packing.Type ? packing.Type : "-"} </td>
                                              <td  className='' > {packing.component  ? packing.component : "-"}</td>
                                              <td   className=''> {packing.color ? packing.color : "-"}</td>
                                              <td className=''>
                                                {vendorList.length>0?
                                                    (packing.vendor!='0' && packing.vendor != 'Select Vendor')?
                                                    vendorList.map((vendor, index) =>                                                  
                                                    (packing.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                  ) :"-"
                                                  :"-"}
                                              </td>
                                              <td  className=''> {packing.perGMT  ? packing.perGMT : "-"} </td>
                                              <td  className=''> {packing.totalQty  ? packing.totalQty : "-"} </td>
                                              <td  className=''> {packing.leadTime ? packing.leadTime:"-"}  </td>
                                              <td  className=''> {packing.units=="0"?"-":packing.units}  </td>
                                              <td  className=''> {packing.pricePerUnit  ? packing.pricePerUnit : "-"}</td>
                                              <td className=''> {packing.totalPrice? packing.totalPrice : "-"} </td>
                                              <td  className='text-center '> 
                                              {
                                                sampleMediaListType.includes(packing.media_type) 
                                              ?
                                                <img
                                                  title="View Sample Images"
                                                  className="cursor-pointer"
                                                  onClick={() => { MaterialsImageModal(packing.media_type,packing.Type) }}
                                                  src={viewLabelSampleIcon}
                                                  width="27px"
                                                  height="27px">
                                                </img> 
                                              :
                                                <img
                                                  title={t("noData")}
                                                  style={{ cursor: 'no-drop' }}
                                                  src={labelSampleIcon}
                                                  width="25px"
                                                  height="25px">
                                                </img> 
                                                }
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      ) 
                                      }
                                      </tbody>
                                    </table>
                                  </div>
                                :
                                  <div className="p-5 align-self-center media-body centerAlign"
                                    style={{ marginTop: "75px" }}>
                                    <img src={noData} style={{ width: "200px" }} />
                                    <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                                  </div>
                                }
                          </Row>
                          </Col>
                        </Card>
                      </Row>

                      {/* Miscellaneous */}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="m-t-5 table-bordered">
                          <Col md="12" lg="12" sm="12">
                            <Row className="g-12">
                              <Col lg="12" md="12" sm="12" xs="12">
                              <span className="subTitleOrderView f-left skudetailsclr p-1">
                              <span className="p-l-5"> </span> {t("miscellaneous")}
                              <span className="p-l-5"> </span></span>
                              </Col>
                            </Row>
                           
                            <Row className="g-12 m-t-10">
                            {  miscellaneousList.length>0
                            ?
                              <div className="table-responsive p-r-0 p-l-0">
                                <table className="table table-bordered">
                                  <thead className="overViewSku">
                                    <tr>
                                    <th className=' '>{t("type")}</th>
                                      <th className=''>{t("component")}</th>
                                      <th className=''>{t("colour")}</th>
                                      <th className=''>{t("vendorInformation")}</th>
                                      <th className=''>{t("perGMT")}</th>
                                      <th className=''>{t("totQty")}</th>
                                      <th className=''>{t("leadTime")}</th>
                                      <th className=''>{t("units")}</th>
                                      <th className=''>{t("pricePerUnit")}</th>
                                      <th className=''>{t("totPrice")}</th>
                                      <th className='text-center '>{t("labelSample")}</th>
                                    </tr>
                                  </thead>                                
                                  <tbody className="tableRowCol">  
                                  {JSON.parse( miscellaneousList).map(( miscellaneous, index) =>
                                    (
                                      <>
                                          <tr className={`${ index+1 !== miscellaneousList.length ? "" : ""}`}>
                                            <td className=''> {miscellaneous.Type ? miscellaneous.Type : "-"} </td>
                                            <td className=''> {miscellaneous.component? miscellaneous.Type : "-"} </td>
                                            <td className=''> {miscellaneous.color ? miscellaneous.Type : "-"}</td>
                                            <td className=''>
                                              {vendorList.length>0?
                                                  (miscellaneous.vendor!='0' &&  miscellaneous.vendor!='Select Vendor')?
                                                  vendorList.map((vendor, index) =>                                                  
                                                  (miscellaneous.vendor==vendor.id?vendor.vendor_name:'')                                                                                                 
                                                ) :"-"
                                                :"-"}
                                            </td>
                                            <td  className=''> {miscellaneous.perGMT ? miscellaneous.perGMT : "-"} </td>
                                            <td  className=''> {miscellaneous.totalQty ? miscellaneous.totalQty : "-"} </td>
                                            <td  className=''> {miscellaneous.leadTime ? miscellaneous.leadTime:"-"}  </td>
                                            <td  className=''> {miscellaneous.units=="0"?"-":miscellaneous.units}</td>
                                            <td  className=''> {miscellaneous.pricePerUnit ? miscellaneous.pricePerUnit : "-"} </td>
                                            <td className=''> {miscellaneous.totalPrice? miscellaneous.totalPrice : "-"} </td>
                                            
                                            <td  className='text-center'>  
                                            {
                                              sampleMediaListType.includes(miscellaneous.media_type) ?
                                              <img
                                                title="View Sample Images"
                                                className="cursor-pointer"
                                                onClick={() => { MaterialsImageModal(miscellaneous.media_type,miscellaneous.Type) }}
                                                src={viewLabelSampleIcon}
                                                width="27px"
                                                height="27px">
                                              </img>
                                              :
                                              <img
                                                title={t("noData")}
                                                style={{ cursor: 'no-drop' }}
                                                src={labelSampleIcon}
                                                width="25px"
                                                height="25px">
                                              </img>
                                            }
                                              
                                            </td>
                                          </tr>
                                      </>
                                    )
                                    )
                                  }
                                  </tbody>
                                </table>
                              </div>
                            :
                              <div className="p-5 align-self-center media-body centerAlign"
                                style={{ marginTop: "75px" }}>
                                <img src={noData} style={{ width: "200px" }} />
                                <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                              </div>
                            }
                          </Row>
                           
                          </Col>
                        </Card>
                      </Row> 

                      {/* Others */}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="m-t-5 table-bordered p-r-10">
                          <Col md="12" lg="12" sm="12">
                            <Row className="g-12">
                              <Col lg="12" md="12" sm="12" xs="12">
                                <span className="subTitleOrderView f-left skudetailsclr p-1">
                                <span className="p-l-5"> </span>{t("others")}
                                <span className="p-l-5"> </span></span>
                              </Col>
                            </Row>
                              {
                                mediaList.length > 0 ?
                                  <> 
                                    <Row className="g-12 m-t-10">
                                      
                                        <FormGroup>
                                          <Label className="m-t-5">{t("measurementSheet")}</Label>
                                          <InputGroup>
                                          { mediaList !== undefined  && mediaList.length > 0 ? (
                                              mediaList.filter((filterData) => filterData.media_type == "MeasurementSheet")
                                              .map((file) => (
                                                <>
                                                    {(file.orginalfilename).split(".")[1] == "pdf" ?
                                                        <img  className="m-l-10" src={pdfDocIcon} />
                                                      :
                                                      (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                                                      <img  className="m-l-10" src={excelDocIconIcon} />
                                                      :
                                                      <img  className="m-l-10" src={docIcon} />
                                                      }
                                                    <td className="m-l-10 m-t-5 verticalAlignmentStart">
                                                      {file.orginalfilename}
                                                    </td>
                                                      <a href="javascript:void(0)" title={t("downloadFile")}
                                                        onClick={() => downloadMediaFile(file.filepath, file.orginalfilename)}>
                                                        <img  style={{ width: "55px"}}  
                                                              className="cursor-pointer p-r-10 p-t-10  b-r-3 f-left" 
                                                              title={t("downloadFile")} 
                                                              src={download_iconTask} />
                                                      </a>
                                                </>
                                              ))
                                            ) : (
                                              <div>{''}</div>
                                            )}
                                          </InputGroup>
                                        </FormGroup>
                                      
                                        <FormGroup>
                                          <Label className="m-t-5">{t("techPack")}</Label>
                                          <InputGroup>
                                          { mediaList !== undefined  && mediaList.length > 0 ? (
                                              mediaList.filter((filterData) => filterData.media_type == "TechPack")
                                              .map((file) => (
                                                <>
                                                {(file.orginalfilename).split(".")[1] == "pdf" ?
                                                        <img className="m-l-10" src={pdfDocIcon} />
                                                      :
                                                      (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                                                        <img  className="m-l-10" src={excelDocIconIcon} />
                                                      :
                                                        <img  className="m-l-10" src={docIcon} />
                                                      }
                                                    <td className="m-l-10 m-t-5 verticalAlignmentStart">
                                                      {file.orginalfilename}
                                                    </td>
                                                    <a href="javascript:void(0)" title={t("downloadFile")}
                                                      onClick={() => downloadMediaFile(file.filepath, file.orginalfilename)}>
                                                      <img  style={{ width: "55px"}}  
                                                            className="cursor-pointer p-r-10 p-t-10  b-r-3 f-left" 
                                                            title={t("downloadFile")} 
                                                            src={download_iconTask} />
                                                    </a>
                                                </>
                                              ))
                                            ) : (
                                              <div>{''}</div>
                                            )}
                                          </InputGroup>
                                        </FormGroup>
                                      
                                    </Row>
                                  </>
                                :
                                  <div className="p-5 align-self-center media-body centerAlign"
                                    style={{ marginTop: "75px" }}>
                                    <img src={noData} style={{ width: "200px" }} />
                                    <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                                  </div>
                              }
                          </Col>
                        </Card>
                      </Row>

                      {/* Approval */}
                      <Row className="g-12 m-t-20 m-b-30 mx-2 shadow-sm shadow-showcase">
                        <Card className="table-bordered">
                          <Col lg="12" md="12" sm="12">
                                  <Row className="g-12">
                                    <Col lg="12" md="12" sm="12" xs="12">
                                      <span className="subTitleOrderView f-left skudetailsclr p-1 "><span className="p-l-5">
                                      </span>{t("approval")}<span className="p-l-5"> </span></span>
                                    </Col>
                                  </Row>
                                    <Row className="g-12 m-t-10">
                                        <div className="table-responsive p-l-0 p-r-0">
                                          <table className="table table-bordered">
                                            <thead className="overViewSku">
                                              <tr>
                                                <th width="50%" className="text-center"> {t("prepared")} </th>
                                                <th width="50%" className="text-center"> {t("approved")} </th>
                                              </tr>
                                              <tr> 
                                                <th style={{ backgroundColor: '#fff' }}> 
                                                  <Row style={{ color: '#000', fontWeight: '300', height: '45px' }}>
                                                    <Col className="verticalAlignmentStart"> 
                                                      { 
                                                        approvalBOMData !== null 
                                                        ? 
                                                          preparedByBOMData.prepared_by
                                                        :
                                                          "" }
                                                    </Col>
                                                    <Col className="verticalAlignmentStart">  
                                                      {  
                                                      approvalBOMData !== null 
                                                      ?
                                                        preparedByBOMData.prepared_date 
                                                        ? 
                                                          PHPtoJSFormatConversion.format(new Date(preparedByBOMData.prepared_date.split(" ")[0])) 
                                                        : 
                                                          ""
                                                      :
                                                        ""
                                                      } 
                                                    </Col>
                                                    <Col className="verticalAlignmentStart">
                                                    {  
                                                      approvalBOMData !== null 
                                                      ?
                                                        preparedByBOMData.prepared_date 
                                                        ? 
                                                          (preparedByBOMData.prepared_date.split(" ")[1])
                                                        : 
                                                          ""
                                                      :
                                                          ""
                                                      } 
                                                    </Col>
                                                    <Col></Col>
                                                  </Row>
                                                </th>
                                                <th style={{ backgroundColor: '#fff' }}> 
                                                  <Row style={{ color: '#000', fontWeight: '300' }}>
                                                    <Col className="verticalAlignmentStart"> { approvalBOMData !== null ? approvalBOMData.approved_by : "" } </Col>
                                                    <Col className="verticalAlignmentStart">  
                                                      {  
                                                        approvalBOMData !== null ?
                                                          approvalBOMData.approval_date 
                                                          ? 
                                                            PHPtoJSFormatConversion.format(new Date(approvalBOMData.approval_date.split(" ")[0])) 
                                                          : 
                                                            ""
                                                        :
                                                          ""
                                                      } 
                                                    </Col>
                                                    <Col className="verticalAlignmentStart">
                                                      {  
                                                        approvalBOMData !== null 
                                                        ?
                                                          approvalBOMData.approval_date 
                                                          ? 
                                                            (approvalBOMData.approval_date.split(" ")[1])
                                                          : 
                                                            ""
                                                        :
                                                            ""
                                                      }
                                                    </Col>
                                                    <Col className="verticalAlignmentEnd">
                                                    {  
                                                      approvalBOMData !== null 
                                                      ?
                                                        <img 
                                                          title="View Approval History"
                                                          tooltip="View Approval History"
                                                          className="f-right"
                                                          src={reschedule} 
                                                          style={{ cursor: 'pointer' }}
                                                          width="45px" 
                                                          onClick={() => { 
                                                            toggleApprovalHistoryOffcanvas() 
                                                          }}
                                                        />
                                                      :
                                                        ""
                                                    }
                                                    </Col>
                                                    {  
                                                      approvalBOMData === null 
                                                    ?
                                                      <span style={{ color: '#000' }}> - </span>
                                                    :
                                                      " "
                                                    }
                                                  </Row>
                                                </th>
                                              </tr>
                                              <tr>
                                                <th width="100%" colSpan={2} className="text-center" style={{ backgroundColor: '#CAEAE8' }}> 
                                                  {t("comments")}
                                                </th>
                                              </tr>
                                              <tr>
                                                <td width="100%" colSpan={2} className="text-center" style={{ backgroundColor: '#fff' }}> 
                                                { 
                                                  approvalBOMData !== null 
                                                    ? 
                                                        approvalBOMData.comments ? approvalBOMData.comments  : ""
                                                    :
                                                    ""
                                                }
                                                {  
                                                  approvalBOMData === null 
                                                ?

                                                  <span style={{ color: '#000', textAlign: 'center' }}> - </span>
                                                :
                                                  " "
                                                }
                                                </td>
                                              </tr>
                                            </thead>
                                            <tbody className="tableRowCol">
                                            </tbody>
                                          </table>
                                        </div>
                                    </Row> 
                                  
                          </Col>
                        </Card>
                      </Row>
                      {
                        (getStaff === "Staff" && getStaffPermission.includes('BOM Approval')) || getStaff == null 
                        ?
                              approvalStatus == "3" 
                              ?
                                dataAvailability.length > 0 
                                ?
                                  <Col className="f-right">
                                    <FormGroup className="f-right m-r-25 ">
                                          <Button
                                            className="btn btn-primary"
                                            onClick={(e) => { approveHandler()}} >
                                            {t("reapprove")}
                                          </Button>
                                    </FormGroup>
                                  </Col>
                                :
                                  ""
                              : 
                              approvalStatus == "0" 
                              ?
                                  dataAvailability.length > 0 
                                  ?
                                    <Col className="f-right">
                                      <FormGroup className="f-right m-r-25 ">
                                            <Button
                                              className="btn btn-primary"
                                              onClick={(e) => { approveHandler()}} >
                                              {t("approve")}
                                            </Button>
                                      </FormGroup>
                                    </Col>
                                  :
                                    ""
                              :
                                  ""
                        :
                          ""}
                          
                    </Form>
                  </CardBody>
                </Card>
              </Card>
            </div>
          </Col>
        :
          ""
        }  

        {/********************************OverView Production *****************************************/}
        {showTab == 6
          ?
            <Col className="m-t-10" md="12" sm="12" lg="12" >
              {/* <Accordion defaultActiveKey="0"> */}
              <div className="default-according" id="accordions1">
                <Card style={{ backgroundColor: "#FFFFFF", border: 'none' }} className="shadow-lg text-left" >
                  {orderView.map((buy) => (
                    <span className="p-l-10 cursor-pointer m-r-10 m-t-5" onClick={() => toggle(5)} >
                      <>
                      {(getStaff === "Staff" && getStaffPermission.includes('Edit Order')) || getStaff == null ?
                      <>
                        {(dateCount > 0 || orderViewData == null) && (dateCount > 0 || orderProductionDetails.length == 0) && 
                        buy.status == 1 && buy.step_level == 6 ?
                          // {/* // <Link style={{ float: "Right" }} to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                          // //   <img src={edit} title={t("edit")} tooltip={t("edit")} alt={t("edit")} className="rescheduleImage" />
                          // // </Link> */}
                          <Link    
                          title={t("Edit Order")} 
                          tooltip={t("Edit Order")}  style={{ float: "Right" }} className="btn-pill btn btn-outline-primary btn-md m-t-5"
                            to={`${process.env.PUBLIC_URL}/editproductionplan?id=` + encode(orderId)}>
                              <i className="icofont icofont-ui-edit"></i> {t("edit")} </Link>
                          :
                          ""}</>
                          :""}
                      </>
                    </span>
                  ))}
                  <CardBody className="p-t-1">
                    <div style={{ backgroundColor: '#fff', marginTop: '1px' }}>
                      {statusDeliveryDate == "" ? "" : 
                      <span className="text-center f-w-500 p-l-40 m-t-5" style={{ fontSize: '16px' }}>
                        {t("delDate")}
                      </span>}

                      {
                        statusDeliveryDate.length > 0 ?
                          statusDeliveryDate.map((statusDeliveryDate) => (
                            <span className="btn text-center"
                              style={{
                                borderRadius: '18px', borderColor: '#878787',
                                marginLeft: '10px', marginTop: '0px', marginBottom: "8px", fontSize: '12px', 
                                color: '#878787', fontWeight: '600', cursor: "unset"
                              }}>
                              {PHPtoJSFormatConversion.format(new Date(statusDeliveryDate))}
                            </span>
                          ))
                          :
                          ""}
                    </div>

                    {orderView.map((buy) => (
                      <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="8" className="m-l-18">
                        <br></br>
                        <Row style={{ paddingBottom: '15px' }}>
                          <Col xs="12" sm="12" md="12" lg="2" xl="2" xxl="2">
                            <Row >
                              <Col >
                                <Row className="f-right"
                                  style={{
                                    borderRadius: '50%', backgroundColor: '#4A70B1',
                                    width: '12px', height: '12px'
                                  }}>
                                </Row>
                              </Col>
                              <Col className="" style={{  color: '#3A3A3A', marginTop: '-3px' }}>
                                {t('cutting')}
                              </Col>
                              {/* <span className="">{orderView[0].cutting_start_date}</span> */}
                            </Row>

                          </Col>
                          <Col xs="12" sm="12" md="12" lg="2" xl="2" xxl="2">
                            <Row>
                              <Col>
                                <Row className="f-right"
                                  style={{
                                    borderRadius: '50%', backgroundColor: '#649EBE',
                                    width: '12px', height: '12px'
                                  }}>
                                </Row>
                              </Col>
                              <Col style={{  color: '#3A3A3A', marginTop: '-3px' }}>
                                {t('sewing')}
                              </Col>
                            </Row>

                          </Col>
                          <Col xs="12" sm="12" md="12" lg="2" xl="2" xxl="2">
                            <Row>
                              <Col>
                                <Row className="f-right"
                                  style={{
                                    borderRadius: '50%', backgroundColor: '#51B9CC',
                                    width: '12px', height: '12px'
                                  }}>
                                </Row>
                              </Col>
                              <Col style={{  color: '#3A3A3A', marginTop: '-3px' }}>
                                {t('packing')}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                    {/********************************calender inside the Event value for map function ******************************/}
                    {orderProductionDetails.map((cutCalendarData) => {
                      if (cutCalendarData.holiday_flag == 0) {
                        if (cutCalendarData.type_of_production === "Cut") {
                          cuttingData[ct] =
                          {
                            date: cutCalendarData.date_of_production,
                            title: cutCalendarData.type_of_production + "-" + cutCalendarData.target_value,
                            color: '#4A70B1',
                            textColor: '#FFFFFF'
                          }
                          ct++;
                        }
                        else if (cutCalendarData.type_of_production === "Sew") {
                          cuttingData[ct] =
                          {
                            date: cutCalendarData.date_of_production,
                            title: cutCalendarData.type_of_production + "-" + cutCalendarData.target_value,
                            color: '#649EBE',
                            textColor: '#FFFFFF '
                          }
                          ct++;
                        }
                        else if (cutCalendarData.type_of_production === "Pack") {
                          cuttingData[ct] =
                          {
                            date: cutCalendarData.date_of_production,
                            title: cutCalendarData.type_of_production + "-" + cutCalendarData.target_value,
                            color: '#51B9CC',
                            textColor: '#FFFFFF'
                          }
                          ct++;
                        }
                      }
                      else {
                        cuttingData[ct] =
                        {
                          date: cutCalendarData.date_of_production,
                          title: cutCalendarData.type_of_production + "-" + cutCalendarData.holiday_detail,
                          color: '#eaeaea',
                          textColor: '#000000'
                        };
                        ct++;
                      }
                      if (WeekOffDayClass) {
                        cuttingData[ct] =
                        {
                          daysOfWeek: WeekOffDayClass,
                          display: "background",
                          title: t("weekOff"),
                          className: "weekoffcolor",
                          overLap: false,
                          allDay: true
                        }
                      }
                    })
                    }
                    <Row>
                      {/* <Col xsm="12" sm="12" md="12" lg="1"></Col> */}
                      <Col xs="12" sm="12" md="12" lg="10" className="m-l-50">
                      <Card className="shadow-lg" >
                        <CardBody>
                        <FullCalendar
                          defaultView="dayGridMonth"
                          header={{
                            left: 'prev,next day',
                            center: 'title',
                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                          }}
                          locale={selectedLang}
                          rerenderDelay={10}
                          eventDurationEditable={false}
                          selectable={true}
                          droppable={false}
                          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                          weekends={state.calendarWeekend}
                          events={cuttingData}
                          eventOrder={'color,start'}
                          initialDate={orderProductionDetails[0] ? 
                            orderProductionDetails[0].date_of_production : new Date().toISOString().split('T')[0]}
                        //  initialDate={Moment.utc(sewstartdate).valueOf()} 
                        />
                        </CardBody>
                      </Card>
                      </Col>
                    </Row>
                  </CardBody>
                  {/* </Collapse> */}
                </Card>
              </div>
              {/* </Accordion> */}
            </Col>
          : 
            ""
          }
        
      </Container>
      
      <AddMaterialsImageModal modal={addMaterialsImageModal}
        toggle={toggleAddMaterialsImageModal} imgmediaList={mediaList} imgserverURL={serverURL} sewing_accessoriest={sewing_accessoriest}
        packingAccessoriest={packingAccessoriest} media_type={media_type} label_type={label_type}> 
        </AddMaterialsImageModal>

        <AddPermissionImageModal modal={downloadImageModal}
        toggle={togglePermissionImageModal} downloadstyle={downloadstyle} orderId={orderId}></AddPermissionImageModal>

        <ApprovalHistoryOffcanvas 
          modal={approvalHistoryOffcanvas}
          toggle={toggleApprovalHistoryOffcanvas}
          viewOrderBomApproval={viewOrderBomApproval}
        />
    </Fragment>
  );

};
export default OrderView;

//**************************Code by : Praveen****************************//