import React, {useState} from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label, Row, Col,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";

import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../helper';
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId }
from '../../Constant/LoginConstant';

const AddUnitModal = ({ modal, toggle, setGetMasterData, setProductionTypeList, setBreakTimeList}) =>{
  
  const {t} = useTranslation();

  const [unitValue, setUnitValue] = useState("");

  const [errors, setValidErrors] = useState({});

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  }

  const validation = () => {
    let errors = {};
    if (!unitValue) {
      errors.unitValue = t("plsEnterUnitName")
    }
    setValidErrors(errors);
    return errors;
  };
  
  const refreshDataApiCall = () => {
    var apiInputParams = {};
    apiInputParams['company_id'] = getLoginCompanyId;
    apiInputParams['workspace_id'] = getWorkspaceId;

    axios
      .post(ServerUrl + "/get-sam-master-data", apiencrypt(apiInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setGetMasterData(response.data.data);
      })

      setProductionTypeList(['Sewing', 'Cutting']);
      setBreakTimeList(['1', '2', '3']);

      toggle();
  };
  
  const onSaveHandle = () => {

    let retval = validation();
    if (Object.keys(retval).length == 0) {
      var apiInputParams = {};
      apiInputParams['company_id'] = getLoginCompanyId;
      apiInputParams['workspace_id'] = getWorkspaceId;
      apiInputParams['user_id'] = getLoginUserId;
      apiInputParams['staff_id'] = getLoginStaffId;

      apiInputParams['type'] = "Unit";
      apiInputParams['value'] = unitValue;

      axios
        .post(ServerUrl + "/add-sam-master-data", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer:5000,
            })
            .then((result) => {
              if (result.isConfirmed) {
                refreshDataApiCall();
              }
              setTimeout(() => {
                refreshDataApiCall();
              }, 100);
            });
          }
          if( response.data.status_code === 201 ){
            Swal.fire({
              title: t(response.data.message),
              icon: "warning",
              button: t("okLabel"),
            })
          }
        });
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader>{t("addUnit")}</ModalHeader>
      <ModalBody>

        <Row>
            <FormGroup className="">
                <Label>{t("addUnit")}</Label><sup className="font-danger">*</sup>
                <InputGroup>
                <Input
                    className={`${errors.unitValue && 'select-error-valid'}  form-control`}
                    type="text"
                    placeholder={t("plsEnterUnitName")}
                    onChange={(e) => { setUnitValue(e.target.value) }}
                    onKeyPress={(e) => handleKeyPress(e)}>
                </Input>
                </InputGroup>
                {errors.unitValue && 
                (<span className="error-msg">{t(errors.unitValue)}</span>)
                }
            </FormGroup>
        </Row> 
        
      </ModalBody>
      <ModalFooter>
        <Btn 
          attrBtn={{ color: "btn secondaryBtn btn-sm", 
          onClick: () => {toggle(), setValidErrors(() => "")} }}>
            {t("close")}
        </Btn>
        <Btn attrBtn={{ color: "primary btn-sm", onClick: () => onSaveHandle() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default AddUnitModal;
/* Code By : Ramanan M */