import {
  Container, Row, Col, CardBody, Card, CardHeader, FormGroup,
  Form, Label, Input, CardFooter, InputGroup, InputGroupText, Button
} from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6, } from "../../AbstractElements";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ServerUrl } from "../../Constant/index";
import Swal from "sweetalert2";
import AddRoleModal from "./AddRole"
import { getLoginCompanyId, getWorkspaceId, getStaff, getStaffPermission, getLoginUserId } from '../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt,capitalizeFirstLowercaseRest } from "../../helper";

const Staff = () => {
  const history = useNavigate();
  const handleChangeStatus = ({ meta, file }, status) => { };
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [roles, setRoles] = useState([]);
  const [company_id, setCompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
  const [user_id, setUserId] = useState(getLoginUserId);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [first_name, setFirst_name] = useState("");
  const [last_name, setLast_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [role_id, setRole_id] = useState("");
  const [status, setStatus] = useState("1");
  const [roleModal, setRoleModal] = useState(false);
const [workspace_type, setWorkspace_type] = useState([]);
// const[user_type,setUser_type] = useState('')
  const inputParamsUser = {};
  inputParamsUser['company_id'] = getLoginCompanyId;
  inputParamsUser['workspace_id'] = getWorkspaceId;
  inputParamsUser['user_id'] = getLoginUserId;
  inputParamsUser['type'] = 'Staff';

  const toggleRoleModal = () => setRoleModal(!roleModal);
  const [validerrors, setValidErrors] = useState({});
  
  const validation = (data) => {
    let validerrors = {};
    if (!first_name) {
      validerrors.firstname = ("enterFirstName");
    } else if (!(first_name).match(/^[a-zA-Z]+$/g)) {
      validerrors.firstname = ("specialCharactersNumbersNotAllowed");
    }
    if ((last_name != null && last_name != 'null' && last_name != '')) {
      if (!(last_name).match(/[a-zA-Z]+$/g)) {
        validerrors.lastname = ("specialCharactersNumbersNotAllowed");
      }
    }
    if (!email) {
      validerrors.email = ("enterEmailAddress");
    } else if (!(email).match(/^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i)) {
      validerrors.email = ("enterValidEmailAddress");
    }
    if (mobile) {
      if (!/^[0-9]+$/.test(mobile)) {
        validerrors.mobile = ("numbersOnlyAllowed");
      } else {
        if( (mobile.length != null &&  mobile.length != 'null' && mobile.length != '' && (parseInt(mobile.length) < 10 ) || (parseInt(mobile.length) > 15 ))){
          validerrors.mobile = t("enter10DigitsMobileNumber");
        }
        // if (((mobile.length != null && mobile.length != 'null' && mobile.length != '' && (parseInt(mobile.length) < 10 ) || (parseInt(mobile.length) > 15 )))) {
        //   validerrors.mobile = ("enter10DigitsMobileNumber");
        // }
      }
    }
    if (!role_id) {
      validerrors.role = ("pleaseSelectRole");
    }
    // if (!user_type) {
    //   validerrors.workspace_type = t("pleaseSelectUserType");
    // }
    setValidErrors(validerrors);
    return validerrors;
  };
  
  const onSubmitData = {
    company_id,
    workspace_id,
    user_id,
    country,
    first_name,
    last_name,
    email,
    // user_type,
    mobile,
    address1,
    address2,
    city,
    state,
    zipcode,
    role_id,
    status,
  };

  useEffect(async() => {

      await axios.post(ServerUrl + '/validate-plan', apiencrypt(inputParamsUser))
      .then((response) => {
        response.data = apidecrypt(response.data);
        if (response.data.status_code === 400) {
          Swal.fire({
            title: response.data.message,
            icon: "warning",
            button: t("okLabel"),
            allowOutsideClick: false
          })
          .then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/staff';
            }
          })
        }
      });

      await  axios.post(ServerUrl + "/get-roles", apiencrypt({
        company_id: company_id,
        workspace_id: workspace_id,
      }))
      .then((response) => {
        response.data = apidecrypt(response.data)
        setRoles(response.data.data);
      });
      axios.get(ServerUrl +"/workspace-type")
      .then((response) => {
        response.data = apidecrypt(response.data)
        setWorkspace_type(response.data.data);
      });
      await  axios.get(ServerUrl + "/get-countries")
      .then((response) => {
          response.data = apidecrypt(response.data)
          setCountries(response.data.data);
      });

    
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Staff Add')) || getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }  

  }, []);

  const OnSubmitHandle = () => {
    let retval = validation();
    if (Object.keys(retval).length == 0) {
      document.getElementById("save-button").setAttribute("disabled", true);
      axios.post(ServerUrl + "/register-staff",
       apiencrypt(onSubmitData))
        .then((response) => {
          response.data = apidecrypt(response.data)
          console.log("sub",response.data)
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("staffAddedSuccessfully"),
              // text: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              allowOutsideClick: false,
              allowEscapeKey: false,
              timer: 5000,
            }).then((result) => {
               if (result.isConfirmed) {
                document.getElementById("save-button").removeAttribute("disabled");
                // setTimeout(() => {
                  window.location.href = "/staff";
                // }, 100);
                 }
              // if (result.isConfirmed) {
              document.getElementById("save-button").removeAttribute("disabled");
              setTimeout(() => {
                window.location.href = "/staff";
              }, 100);
              // }
            });
          }
          if (response.data.status_code === 401) {
            document.getElementById("save-button").removeAttribute("disabled");
            Swal.fire({
              title:
                t(response.data. errors.first_name) ||
                t(response.data.errors.last_name) ||
                t(response.data.errors.state) ||
                t(response.data.errors.country) ||
                t(response.data.errors.role_id) ||
                t(response.data.errors.address1) ||
                t(response.data.errors.address2) ||
                t(response.data.errors.city) ||
                t(response.data.errors.zipcode) ||
                t(response.data.errors.status) ||
                t(response.data.errors.email) ||
                t(response.data.errors.mobile),
              text: t("fieldmissing"),
              icon: "warning",
              button: t("okLabel"),
            });
          }
          if (response.data.status_code === 400) {
            document.getElementById("save-button").removeAttribute("disabled");
            Swal.fire({
              title: email,
              text: t(response.data.message),
              icon: "error",
              button: t("okLabel"),
            });
          }
        });
    } else {
      Swal.fire({
        title: t("validationError"),
        text: t("fillRequiredFields"),
        icon: "error",
        button: t("okLabel"),
      });
    }
  };

  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  }

  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle={t('addStaffDetails')} parent="StaffDetails" title="Staff Details" subTitle="Add Staff Details" />
      </Row>

      <Container fluid={true} className="general-widget topaln">
        <Form>
          <Row>
            <Col sm="12">
              <Card >
                <CardBody>
                  <Row style={{ marginLeft:"10px",marginRight:"10px" }}>
                    {/* <Col sm="12">
                      {" "}
                      <H6 attrH6={{ className: "pb-2" }}>{t("staffLoginInfo")}</H6>
                    </Col> */}
                    <Card className="shadow shadow-showcase" >
                    <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 " name="logo">
                                                <span className="p-r-5"></span> {t("staffLoginInfo")}<span className="p-r-5"></span>
                                                </Label>
                     </span>
                   
                   
                   <Row className="m-t-10">
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("firstName")} <sup className="font-danger">*</sup>
                        </Label>
                        <Input
                          className={`${validerrors.firstname && 'error-valid'}  form-control`}
                          type="text"
                          placeholder={t("firstName")}
                          onChange={(e) => setFirst_name(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                        {validerrors.firstname && (
                          <span className="error-msg">{t(validerrors.firstname)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("lastName")}
                        </Label>
                        <Input
                          className={`${validerrors.lastname && 'error-valid'}  form-control`}
                          type="text"
                          placeholder={t("lastName")}
                          onChange={(e) => setLast_name(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                        {validerrors.lastname && (
                          <span className="error-msg">{t(validerrors.lastname)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("email")} <sup className="font-danger">*</sup>
                        </Label>
                        <Input
                          className={`${validerrors.email && 'error-valid'}  form-control`}
                          type="text"
                          placeholder={t("email")}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {validerrors.email && (
                          <span className="error-msg">{t(validerrors.email)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("mobileNumber")}
                        </Label>
                        <Input
                          className={`${validerrors.contact && 'error-valid'}  form-control`}
                          type="tel"
                          placeholder={t("mobileNumber")}
                          maxLength="15"
                          onChange={(e) => setMobile(e.target.value)}
                          onKeyPress={(e) => handleKeyPress(e)}
                        />
                        {validerrors.mobile && (
                          <span className="error-msg">{t(validerrors.mobile)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    {/* <Col sm="12" md="6" lg="4">
                    <FormGroup>          
                    <Label>{t("userType")} <sup className="font-danger">*</sup></Label>
                      <Input type="select"
                      className={`${validerrors.workspace_type && 'select-error-valid'}  form-control digits`}
                      isMulti   
                      onChange={(e) => setUser_type(e.target.value)} >
                            <option selected disabled value=""> {t("selectUserType")} </option>
                            {workspace_type.map( workspace_type => (
                              <option value={workspace_type.name}>
                                  {workspace_type.name=="PCU"?"Buying House":workspace_type.name}
                                  </option>
                              ))}
                            </Input>                        
                    {validerrors.workspace_type && (
                      <span className="error-msg">{validerrors.workspace_type}</span>
                    )}
                  </FormGroup>
                    </Col> */}
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {t("role")}  <sup className="font-danger">*</sup>
                        </Label>
                        <InputGroup>
                          <Input
                            type="select"
                            name="select"
                            className={`${validerrors.role && 'select-error-valid'}  form-control digits`}
                            defaultValue={t("selectRole")}
                            onChange={(e) => setRole_id(e.target.value)}
                          >
                            <option disabled>{t("selectRole")}</option>
                            {roles.map((role) => (
                              <option key={role.id} value={role.id}>{role.name}</option>
                            ))}
                          </Input>
                          {(getStaff === "Staff" && getStaffPermission.includes('Add Roles')) || getStaff == null ?
                            <InputGroupText>
                              <span
                                className="btn selectbtn"
                                onClick={toggleRoleModal}
                              >
                                {t("add")}
                              </span>
                              <AddRoleModal modal={roleModal} toggle={toggleRoleModal} company_id={company_id}
                                workspace_id={workspace_id} roles={setRoles} />
                            </InputGroupText> : ""
                          }
                        </InputGroup>
                        {validerrors.role && (
                          <span className="error-msg">{t(validerrors.role)}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {t("status")} <sup className="font-danger">*</sup>
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          defaultValue={t("activated")}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value={"1"}>{t("activated")}</option>
                          <option value={"2"}>{t("deactivated")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    </Row>
                    </Card>
                  </Row>
                  <Row style={{ margin:"10px" }}>
                    <Card className="shadow shadow-showcase" >
                    <span className="p-l-10 editcompanyinfo">
                                                <Label className="skudetailsclr p-1 " name="logo">
                                                <span className="p-r-5"></span> {t("addressInfo")}<span className="p-r-5"></span>
                                                </Label>
                     </span>
                   <Row className="m-t-10">
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("addressline1")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("addressline1")}
                          onChange={(e) => setAddress1(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlInput1">
                          {t("addressline2")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("addressline2")}
                          onChange={(e) => setAddress2(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("city")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("city")}
                          onChange={(e) => setCity(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("statePrefecture")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder= {t("statePrefecture")}
                          onChange={(e) => setState(e.target.value = capitalizeFirstLowercaseRest(e.target.value))}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleInputPassword2">
                          {t("postalCode")}
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          placeholder={t("postalCode")}
                          onChange={(e) => setZipcode(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="exampleFormControlSelect9">
                          {t("country")}
                        </Label>
                        <Input
                          type="select"
                          name="select"
                          className="form-control digits"
                          defaultValue={t("selectCountry")}
                          onChange={(e) => setCountry(e.target.value)}
                        >
                          <option disabled>{t("selectCountry")}</option>
                          {countries.map((country) => (
                            <option key={country.id} value={country.id}>{country.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    </Row>
                    </Card>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="mb-0 f-right">
                        {/* <Link to="/staff"> */}
                          <Button 
                          className="btn secondaryBtn m-r-10"
                          onClick={ () => { window.location.href = "/staff"}}>
                            {t("cancel")}
                          </Button>
                        {/* </Link>  */}

                        <Button
                          className="btn btn-primary" id="save-button"
                          onClick={() => OnSubmitHandle()} >
                          {t("save")}
                        </Button>
                     
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  
                </CardBody>
              </Card>
            </Col>
            
          </Row>
        </Form>
      </Container>
    </Fragment>
  );
};

export default Staff;
//**************************Code by : Rithanesh****************************//

