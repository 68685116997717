import React, { Fragment } from "react";
import { Col, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Link } from 'react-router-dom';
import { H6 } from '../../AbstractElements';
import { useTranslation } from 'react-i18next';
import welcomeDashboard from '../../assets/images/dms/welcomeDashboard.svg';
import wavingHand from '../../assets/images/dms/waving-hand.svg';

const WelcomeDashboard = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Col md="12">
        <Card className="text-center">
          <CardHeader>
            <p className="f-30 p-3"><img src={wavingHand} width={35} /> {t("welcome")}  !</p>
          </CardHeader>
          <CardBody>
            <div className="helper-classes f-w-600" style={{ backgroundColor: "#f9f9f9" }}>
              <img src={welcomeDashboard} width={50} className="mb-3" />
              <H6 className="p-3"> {t("customizeyourDashboard")} </H6>
              <p> <small> {t("step")} 1 :<span className="font-primary f-w-600">
                {t("Settings")} &#62;  {t("User Settings")} &#62; {t("Dashboard")} </span> </small> </p>
              <p> <small> {t("widgetstobedisplayedinthedashboard")} </small> </p> <br />
              <p> <small> {t("step")} 2 : <span className="font-primary f-w-600">
                {t("Settings")} &#62;  {t("User Settings")} &#62; {t("dashboardsettings")} &#62;
                {t("view")} </span> </small> </p>
              <p> <small> {t("taskproductionstatusinyourdashboard")} </small> </p> <br />
              <Link to="/user-settings"> <Button className=" m-3 btn Btn" >{t("next")}</Button></Link>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};
export default WelcomeDashboard;

//**************************Code by : Praveen****************************//