import { Container,  Label, Card, CardBody, Col, Row, Input, 
    InputGroup,  Button, CardHeader,  FormGroup, Table} from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { useTranslation } from 'react-i18next'
import { PHPtoJSFormatConversion } from "../../../helper";
const DataInputLogTable = ({dailyProdData}) =>{
    const { t } = useTranslation();
    
    return(
        <Col>
            <div className="card-block row">
                <Col sm="12" lg="12" xl="12">
                    <Row>
                    <Col >
                        <Card>                                    
                            <Table className="custom-table-striped">
                                <thead>
                                    <tr >
                                        <th scope="col" className="p-2">{t("date")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("workSpace")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("companyName")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("user/staff")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("styleNo")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("color")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("size")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("Production")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("quantity")}</th>
                                        <th scope="col" className="p-2 centerAlign">{t("Created At")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dailyProdData.map((prodData)=>{                                                
                                        return (
                                            <tr className="f-w-400">
                                                <td scope="col" >{PHPtoJSFormatConversion.format(new Date(prodData.sku_date))}</td>
                                                <td scope="col" className="centerAlign">{prodData.name}</td>
                                                <td scope="col" className="centerAlign">{prodData.company_name}</td>
                                                <td scope="col" className="centerAlign">{ prodData.username!=null ? prodData.username : prodData.staffname  }</td>
                                                <td scope="col" className="centerAlign">{prodData.style_no}</td>
                                                <td scope="col" className="centerAlign">{prodData.colorname}</td>
                                                <td scope="col" className="centerAlign">{prodData.size}</td>
                                                <td scope="col" className="centerAlign">{prodData.type_of_production}</td>
                                                <td scope="col" className="centerAlign">{prodData.updated_quantity}</td>
                                                <td scope="col" className="centerAlign">{prodData.created_date}</td>
                                            </tr>
                                    )})}
                                </tbody>
                            </Table>
                        </Card>
                    </Col>                         
                    </Row>
                </Col>
            </div>
        </Col>
    )
}

export default DataInputLogTable;