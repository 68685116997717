import React , {useState,useRef,useEffect}from 'react';
import { Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, Input, Label } from 'reactstrap';
import { Btn } from '../../AbstractElements';
import { Close, SaveChanges, ServerUrl } from '../../Constant';
import Swal from "sweetalert2";
import { apidecrypt, apiencrypt,capitalizeFirstLowercaseRest } from '../../helper';
import { useTranslation } from 'react-i18next';

const TaskupdateSubTaskModal = ({ taskTitle, subtaskOf , modal, toggleModal, newTemplateSave, 
  setTemplates, refreshTemplates, taskTemplate, selectedtemplateId, areTasksEdited,TaskNam,displayTemplateSave}) =>{
  const [taskName, setTaskName] = useState("");
  const { t } = useTranslation();
 
    // inputRef.current.focus();
   
    setTimeout(() => {
      var element =  document.getElementById('taskInput');
      if (typeof(element) != 'undefined' && element != null)
      {
        element.focus();
      }
       
      }, 1000);
 
  /******* To Add a new Task/Subtask *********/
  const addTask = () =>{
   
    TaskNam(taskName)
  if(!taskName.trim()){
    document.getElementById("taskInput").value = "";
    Swal.fire({
      title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
      // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
      text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
      icon: "error",
      button: t("okLabel"),
    });
  }
  else{
    Swal.fire({
      title: t("areYouSure"),
      text: t("addSubTaskTextAlert",{taskName:`${taskTitle}`}),
      text: (taskTitle == "Task" ?  t("addTaskTextAlert") : t("addSubTaskTextAlert") ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("sure")
    })
    .then((result) => {
      if (result.isConfirmed) {
        let errorAlert=false;
        let errorTaskAlert=false;
        for(let i=0; i<taskTemplate.length; i++){
          if(taskTemplate[i].id == selectedtemplateId){
            let template = JSON.parse(taskTemplate[i].task_template_structure);
            let newTemplate = [];
           // let displayTemplate = [];
            for(let j=0; j<template.length; j++){
           
              if(taskTitle === "Task"){
             
                 template.map((mapData) => {

                  if(mapData.task_title.toLowerCase().trim().replace(/ /g, "")==taskName.trim().toLowerCase().replace(/ /g, "")){
                    errorTaskAlert=true;
                  }

                } )
                if(errorTaskAlert==false){
                  template[template.length]={"task_title": taskName, "task_subtitles" : []};
                }
                
                newTemplate=template;
                break;
                 
              }
              else if(template[j].task_title === subtaskOf && taskTitle === "Sub Task"){
                let task_subtitles = [];
                for(let k=0; k<=(template[j].task_subtitles).length; k++){
                     if((k===((template[j].task_subtitles).length))){

                    var data = template[j].task_subtitles.filter((val) => (val.toLowerCase().trim().replace(/ /g, ""))==(taskName.trim().toLowerCase().replace(/ /g, "")));
                    if(data.length==0){
                   
                    template[j].task_subtitles[k]=taskName;
                    task_subtitles.push(template[j].task_subtitles[k]);
                   
                    let ary=template[j].task_title+"###"+taskName;
                    //displayTemplateSave.push(ary);
                    displayTemplateSave(current => [...current, ary]);
                    break;
                    }else{
                      errorAlert=true;

                    } 
                  }
                  else{
                    task_subtitles.push(template[j].task_subtitles[k]);
                  }
                }
                template[j].task_subtitles = task_subtitles 
                newTemplate.push(template[j]);
               // displayTemplate.push(template[j]);
              }
              else{
                newTemplate.push(template[j]);
               // displayTemplate.push(template[j]);
              }
            }
            taskTemplate[i].task_template_structure = JSON.stringify(newTemplate);
           // taskTemplate[i].task_template_structure = JSON.stringify(displayTemplate);
              newTemplateSave(newTemplate);
              //displayTemplateSave(displayTemplate)
          }
          setTemplates(taskTemplate);
        
        }

        if(errorTaskAlert){
          Swal.fire({
            text:taskName+ " "+t(" Task is Already Exists"),
            // title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
            // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
            // text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
            icon: "error",
            button: t("okLabel"),
            timer:5000,
          });
         return false;
        }
        if(errorAlert){
          Swal.fire({
            text:taskName+ " "+t("alreadyExists"),
            // title: (taskTitle == "Task" ?  t("Task"): t("subTask") ),//taskTitle,
            // text: t("taskTitleValidation",{taskName:`${taskTitle}`}),
            // text: (taskTitle == "Task" ?  t("taskTitleValidation"): t("subTaskTitleValidation") ),
            icon: "error",
            button: t("okLabel"),
            timer:5000,
          });
        }
     
        setTaskName("");
        toggleModal(false);
        refreshTemplates("1");
        // areTasksEdited(0);
      }
    })
  }
  };
  return (
    <Modal isOpen={modal} toggle={toggleModal} centered>
      <ModalHeader>{(taskTitle == "Task" ?  t("Task"): t("subTask") )}</ModalHeader>
      <ModalBody>
        <Label className="col-form-label" for="recipient-name">
          {t("save")} {(taskTitle == "Task" ?  t("Task"): t("subTask") )}
        </Label>
        <Input
          className="form-control"
          type="text"
          onChange={(e) => setTaskName(e.target.value= capitalizeFirstLowercaseRest(e.target.value) )}
          id="taskInput"
        />
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "btn btn-light btn-sm", onClick: toggleModal }}>{t("close")}</Btn>
        <Btn attrBtn={{ color: "primary  btn-sm", onClick : ()=>addTask() }}>
          {t("save")}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default TaskupdateSubTaskModal;
