import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Label, Input, Button, Media } from "reactstrap";
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import { getLoginCompanyId, getLoginUserId, getLoginStaffId, 
  getWorkspaceId, } from "../../Constant/LoginConstant";
import axios from "axios";
import Swal from "sweetalert2";
import { getStaff } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";

const NotificationSettings = (props) => {
  const [getEmail_daily_reminder, setgetEmail_daily_reminder] = useState();
  const [getEmail_weekly_reminder, setgetEmail_weekly_reminder] = useState();
  const [getEmail_task_accomplishment, setgetEmail_task_accomplishment] = useState();
  const [getEmail_task_reschedule, setgetEmail_task_reschedule] = useState();
  const [getEmail_due_today, setgetEmail_due_today] = useState();
  const [getEmail_due_tomorrow, setgetEmail_due_tomorrow] = useState();
  const [getEmail_daily_schedule, setgetEmail_daily_schedule] = useState();
  const [notificationTolerancereq, setNotificationTolerancereq] = useState(false);
  const { t } = useTranslation();

  useEffect(async() => {
    if (getStaff !== "Staff") {
     await axios
        .post(ServerUrl + "/get-notification-settings", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((Notifyset) => {
          Notifyset.data = apidecrypt(Notifyset.data);
          if (Notifyset.data.data!=null &&  Notifyset.data.data.is_consolidated_mail== 1) {
            setNotificationTolerancereq(true);
          }
         
          setgetEmail_daily_reminder(Notifyset.data.data.email_daily_reminder);
          setgetEmail_weekly_reminder(
            Notifyset.data.data.email_weekly_reminder
          );
          setgetEmail_task_accomplishment(
            Notifyset.data.data.email_task_accomplishment
          );
          setgetEmail_task_reschedule(
            Notifyset.data.data.email_task_reschedule
          );
          setgetEmail_due_today(Notifyset.data.data.email_due_today);
          setgetEmail_due_tomorrow(Notifyset.data.data.email_due_tomorrow);
          setgetEmail_daily_schedule(Notifyset.data.data.email_daily_schedule);
        });
    } else {
      axios
        .post(ServerUrl + "/get-staff-notification-settings", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((Notifyset) => {
          Notifyset.data = apidecrypt(Notifyset.data);
          if (Notifyset.data.data!=null && Notifyset.data.data.is_consolidated_mail== 1)
          
          {
            setNotificationTolerancereq(true);
          }
          setgetEmail_daily_reminder(Notifyset.data.data.email_daily_reminder);
          setgetEmail_weekly_reminder(
            Notifyset.data.data.email_weekly_reminder
          );
          setgetEmail_task_accomplishment(
            Notifyset.data.data.email_task_accomplishment
          );
          setgetEmail_task_reschedule(
            Notifyset.data.data.email_task_reschedule
          );
          setgetEmail_due_today(Notifyset.data.data.email_due_today);
          setgetEmail_due_tomorrow(Notifyset.data.data.email_due_tomorrow);
          setgetEmail_daily_schedule(Notifyset.data.data.email_daily_schedule);
        });
    }
  }, []);

  const onSelectUpdate = (e, key) => {
    let getNofiySetting = {};
    getNofiySetting["company_id"] = getLoginCompanyId;
    getNofiySetting["user_id"] = getLoginUserId;
    getNofiySetting["staff_id"] = getLoginStaffId;
    getNofiySetting["workspace_id"] = getWorkspaceId;
    getNofiySetting["consolidated_mail"] = notificationTolerancereq == true ? 1 : 0;

    for (var i = 1; i <= 6; i++) {
      var getDetails = document.getElementById("chk-" + i);
      if (getDetails.checked == true) {
        getNofiySetting[getDetails.name] = "6";
      } else {
        getNofiySetting[getDetails.name] = "7";
      }
    }
    return getNofiySetting;
  };
  //**************************Notification checkbox save button validation****************************//
  const onSubmitHandler = (e) => {
    let getNotify = onSelectUpdate();
    if (getStaff !== "Staff") {
      axios
        .post(ServerUrl + "/notification-settings", apiencrypt(getNotify))
        .then((response) => {
          Swal.fire({
            title: t("notificationSettings"),
            text: t("savedSuccessfully"),
            icon: "success",
            timer: 5000,
            button: t("okLabel"),
            confirmButtonColor: "#009688",
          });
        });
    } else {
      axios
        .post(ServerUrl + "/staff-notification-settings", apiencrypt(getNotify))
        .then((response) => {
          Swal.fire({
            title: t("notificationSettings"),
            text: t("savedSuccessfully"),
            icon: "success",
            timer: 5000,
            button: t("okLabel"),
            confirmButtonColor: "#009688",
          });
        });
    }
  };
  const getNotifySet = (e) => {
    var y = getEmail_task_reschedule;
    if (y == 6) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Fragment>
      <Col md="12" lg="6" sm="12">
        <Card className="shadow shadow-md m-b-5" style={{ height: "250px" }}>
          <Row className='m-b-20'>
            <Col >
              <HeaderCard title={t("notificationSettings")}
              /> 
             

            </Col >
            <Col  style={{ marginRight: '12px' }}>
            <span className="m-t-15" style={{ float: "right" }}> 
              

                        <Media key={1}>
                                    <Label className="col-form-label m-r-10">{t("consolidatedMail")}
                                    </Label>
                                    <Media body className="text-end switch-sm icon-state">
                                        <Label className="switch">
                                            <Input type="checkbox" 
                                            value={notificationTolerancereq} checked={!!notificationTolerancereq}
                                            onChange={(e) => { setNotificationTolerancereq(prevCheck => !prevCheck) }} 
                                            /><span className="switch-state" ></span>
                                        </Label>
                                    </Media>
                                </Media>
            </span>
          </Col>
          </Row>
          <CardBody className="animate-chk" style={{ marginTop: "-15px", marginBottom: "-15px" }}>
            <Row>
              <Col md="6">
                <Label className="d-block" for="chk-2">
                  {getEmail_due_today == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-2"
                      type="checkbox"
                      name="email_due_today"
                      defaultChecked
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-2"
                      type="checkbox"
                      name="email_due_today"
                    />
                  )} {t("dueToday")}
                </Label>
                <Label className="d-block" for="chk-5">
                  {getEmail_due_tomorrow == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-5"
                      type="checkbox"
                      defaultChecked
                      name="email_due_tomorrow"
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-5"
                      type="checkbox"
                      name="email_due_tomorrow"
                    />
                  )} {t("dueTomorrow")}
                </Label>
                <Label className="d-block" for="chk-4">
                  {getEmail_task_reschedule == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-4"
                      type="checkbox"
                      name="email_task_reschedule"
                      defaultChecked
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-4"
                      type="checkbox"
                      name="email_task_reschedule"
                    />
                  )} {t("taskReschedule")}
                </Label>

              </Col>
              <Col md="6">
                <Label className="d-block" for="chk-3">
                  {getEmail_daily_reminder == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-3"
                      type="checkbox"
                      name="email_daily_reminder"
                      defaultChecked
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-3"
                      type="checkbox"
                      name="email_daily_reminder"
                    />
                  )}{" "}
                  {t("dailyReminder")}
                </Label>
                <Label className="d-block" for="chk-6">
                  {getEmail_weekly_reminder == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-6"
                      type="checkbox"
                      defaultChecked
                      name="email_weekly_reminder"
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-6"
                      type="checkbox"
                      name="email_weekly_reminder"
                    />
                  )}{" "}
                  {t("weeklyReminder")}
                </Label>
                <Label className="d-block" for="chk-1">
                  {getEmail_task_accomplishment == "6" ? (
                    <Input
                      className="checkbox_animated"
                      id="chk-1"
                      type="checkbox"
                      defaultChecked
                      name="email_task_accomplishment"
                    />
                  ) : (
                    <Input
                      className="checkbox_animated"
                      id="chk-1"
                      type="checkbox"
                      name="email_task_accomplishment"
                    />
                  )} {t("accomplishedTask")}
                </Label>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="text-end m-t-10">
                  <Button
                    className="btn btn-primary btn-sm"
                    to="#"
                    onClick={(e) => {
                      onSubmitHandler(e);
                    }}
                  > {t("save")}
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default NotificationSettings;

//**************************Code by : SaravanaKumar****************************//