import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  FormGroup,
  Form,
  Label,
  Input,
  CardFooter,
  Button,
} from "reactstrap";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { Breadcrumbs, H5, P, Btn, H6 } from "../../AbstractElements";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ServerUrl } from "../../Constant";
import { getLoginCompanyId, getWorkspaceId, getLoginStaffId, getLoginUserId, getLoginToken } from '../../Constant/LoginConstant';
import { apidecrypt, apiencrypt, encode } from "../../helper";
import Swal from "sweetalert2";

const Size = () => {
  const history = useNavigate();
  const handleChangeStatus = ({ meta, file }, status) => { };
  const [user_id, setuser_id] = useState(getLoginUserId);
  const [staff_id, setStaff_id] = useState(getLoginStaffId);
  const [company_id, setCompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setWorkspace_id] = useState(getWorkspaceId);
  const [sizes, setSizes] = useState([]);
  const [name, setColorName] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const handleAddStaff = () => {
  //   setTimeout(() => {
  //     window.location.href = "/addstaff";
  //   }, 100);
  // };

  useEffect(() => {
    axios.post(ServerUrl + "/get-size", apiencrypt({
      company_id: company_id,
      workspace_id: workspace_id,
      user_id: user_id,
      staff_id: staff_id,
    }))
      .then((response) => {
        response.data = apidecrypt(response.data);
        setSizes(response.data.data)
      });
  }, []);

  const checkStatus = (statusValue) => {
    // alert("statusValue");
    if (statusValue === "1") {

      return (
        <td>
          <span className="font-success">Activated</span>
        </td>
      );
    } else {
      return (
        <td>
          <span className="font-danger">Deactivated</span>
        </td>
      );
    }
  };
  const editHandle = (id) => {
    setTimeout(() => {
      window.location.href = "/editsize?id=" + encode(id);
    }, 100);
  };
  const onSaveHandle = (a, b) => {
    Swal.fire({
      title: "Are you sure Want to Delete this Size?",
      position: 'top-middle',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonClass: 'btn-danger',
      cancelButtonClass: "btn-success",
      confirmButtonText: 'Yes',
      cancelButtonText: "No"
   }).then((result) => {  
    /* Read more about isConfirmed, isDenied below */  
      if (result.isConfirmed) {   
    const sizeName = a;
    const sizeId = b;
    axios.delete(ServerUrl + "/size/" + (b),
      {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id: staff_id,
        user_id: user_id,
        name: sizeName,
        id: sizeId,
      }).then((response) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            position: 'top-middle',
            icon: 'success',
            title: t(response.data.message),
            button:  t("okLabel")
          })
            .then((result) => {
              // if (result.isConfirmed) {
              //   document.getElementById(b).disabled = true;
              //   setTimeout(() => {
              //   }, 100);
              // }
              window.location.reload();
            })
        }
        if (response.data.status_code === 400) {
          Swal.fire({
            title: t(response.data.message),
            icon: "error",
            button:  t("okLabel"),
            confirmButtonColor: '#009688',
          })
        }
        

      })
    } else if (result.isDenied) {    
      // Swal.fire('Changes are not saved', '', 'info')  
    }
       
   
   
     });

  }
  return (
    <Fragment>
       <Row className="pgbgcolor">
           <Breadcrumbs mainTitle='Size Details' parent="Size Details" title="Size Details" subTitle="Size View"/>
           </Row>

      <Container fluid={true}  className="general-widget topaln">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Row className="g-12 m-t-20">
                  {" "}
                  <Col md="12" lg="12" sm="12" className="text-end">
                    <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/addsize`}> +Add Size</Link>
                    {/* <button
                        className="btn btn-primary"
                        onClick={() => handleAddStaff()}
                      >
                        +Add Staff
                      </button> */}
                  </Col>
                </Row>
                {/*End Add Contact Row*/}
                <Row className="g-12 m-t-20">
                  {/*start contact list */}
                  <Col md="12" lg="12" sm="12">
                    <H6>Size List</H6>
                    <Row className="g-12">
                      <div className="table-responsive">
                        <table className="table shadow shadow-showcase">
                          <thead className="bg-primary">
                            <tr>
                              <th scope="col">S.no</th>
                              <th scope="col">Name</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sizes.length > 0 ? sizes.map((size, index) => (

                              <tr>
                                <td scope="row">{index + 1}</td>
                                <td>{size.name}</td>
                                {checkStatus(size.status)}
                                {/* {checkDefault(size.is_default)} */}
                                {size.is_default === "0" ? <td>Default</td> :
                                  <td>
                                    <Button
                                      id={size.id}
                                      className="btn btn-danger  btn-xs btn btn-secondary " onClick={() => onSaveHandle(size.name, size.id)} >
                                      Delete
                                    </Button>&nbsp;&nbsp;
                                    <Link className="btn btn-success btn-xs btn btn-secondary" to={`${process.env.PUBLIC_URL}/editsize?id=${encode(size.id)}&nm=${encode(size.name)}&st=${encode(size.status)}}`}>Edit</Link>
                                  </td>
                                }
                              </tr>
                            )) : <>
                              <tr className="text-center"><td colSpan="4">Add Size to List Here</td></tr>
                            </>}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </Col>
                </Row>
                {/*end second row*/}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Size;
