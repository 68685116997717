import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Chart } from 'react-google-charts';
import axios from 'axios';
import { ServerUrl } from '../../Constant/index';
import { apiencrypt, apidecrypt } from '../../helper';
import {
  getLoginCompanyId, getLoginUserId, getWorkspaceId,
  getLoginStaffId,
} from '../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import noData from "../../assets/images/dms/nodata.png";
import { Line } from 'react-chartjs-2';
import delayedTaskIcon from '../../assets/images/dms/icons/delayedTaskIcon.svg';

const PieChartClass = (props) => {
  const [delayedTaskList, setDelayedTaskList] = useState(false);
  const { t } = useTranslation();
  const [style1, setStyle1] = useState('');
  const [days1, setDays1] = useState('');
  const [taskName1, setTaskName1] = useState('');
  const [style2, setStyle2] = useState('');
  const [days2, setDays2] = useState('');
  const [taskName2, setTaskName2] = useState('');
  const [style3, setStyle3] = useState('');
  const [days3, setDays3] = useState('');
  const [taskName3, setTaskName3] = useState('');
  const [style4, setStyle4] = useState('');
  const [days4, setDays4] = useState('');
  const [taskName4, setTaskName4] = useState('');
  const [style5, setStyle5] = useState('');
  const [days5, setDays5] = useState('');
  const [taskName5, setTaskName5] = useState('');
  const [orderId1, setOrderId1] = useState('');
  const [orderId2, setOrderId2] = useState('');
  const [orderId3, setOrderId3] = useState('');
  const [orderId4, setOrderId4] = useState('');
  const [orderId5, setOrderId5] = useState('');

  const delayedTaskImage = new Image(15, 15);
  delayedTaskImage.src = delayedTaskIcon;

  useEffect(() => {
    axios.post(ServerUrl + '/get-top-delay-tasks', apiencrypt(
      {
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
      })).then((response) => {
        response.data = apidecrypt(response.data)
        let data = response.data;
        setDelayedTaskList(data.data.length > 0 ? true : false);
        (data.data).map((mapData, index) => {
          if (index == 0) {
            setStyle1(mapData.style_no), setDays1(Math.abs(mapData.noOfDays),
              setTaskName1(mapData.task_title), setOrderId1(mapData.order_id))
          }
          if (index == 1) {
            setStyle2(mapData.style_no), setDays2(Math.abs(mapData.noOfDays),
              setTaskName2(mapData.task_title), setOrderId2(mapData.order_id))
          }
          if (index == 2) {
            setStyle3(mapData.style_no), setDays3(Math.abs(mapData.noOfDays),
              setTaskName3(mapData.task_title), setOrderId3(mapData.order_id))
          }
          if (index == 3) {
            setStyle4(mapData.style_no), setDays4(Math.abs(mapData.noOfDays),
              setTaskName4(mapData.task_title), setOrderId4(mapData.order_id))
          }
          if (index == 4) {
            setStyle5(mapData.style_no), setDays5(Math.abs(mapData.noOfDays),
              setTaskName5(mapData.task_title), setOrderId5(mapData.order_id))
          }
        })
      });
  }, []);

  const ordersttus = (indexValue) => {
    { indexValue == 0 ? props.geOrderstatusId(orderId1) : "" }
    { indexValue == 1 ? props.geOrderstatusId(orderId2) : "" }
    { indexValue == 2 ? props.geOrderstatusId(orderId3) : "" }
    { indexValue == 3 ? props.geOrderstatusId(orderId4) : "" }
    { indexValue == 4 ? props.geOrderstatusId(orderId5) : "" }
    // props.geOrderstatusId(orderId1[1])
    // props.geOrderstatusId(orderId2[indexValue])
    // props.geOrderstatusId(orderId3[indexValue])
    // props.geOrderstatusId(orderId4[indexValue])
    // props.geOrderstatusId(orderId5[indexValue])
  }

  const labels = [style1, style2, style3, style4, style5];
  const data = {
    labels,
    datasets: [
      {
        label: 'Days Delayed',
        data: [days1, days2, days3, days4, days5],
        borderColor: '#B2365B',
        backgroundColor: 'rgba(240, 206, 216, 1)',
        fill: {
          target: "origin",
          above: "rgba(240, 206, 216, 0.6)"
        }
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        position: 'top',
        align: 'end',
        labels: {
          boxWidth: 20,
          boxHeight: 5,
          // padding: 20
        },
      },
      tooltip: {
        boxWidth: 20,
        boxHeight: 30,
        boxPadding: 2,
        borderColor: "#E0E0E0",
        borderWidth: 1,
        backgroundColor: "#fff",
        titleColor: "#000",
        bodyColor: "#B2365B",
        usePointStyle: true,
        callbacks: {
          labelPointStyle: (context) => {
            return {
              pointStyle: delayedTaskImage,
              bodyColor: context.dataset.bodyColor,
            }
          },
          label: (context) => {
            var label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              var daysDelayed = context.parsed.y;
              var data = daysDelayed + " " + label;
            }
            return data;
          },
          afterTitle: function (context) {
            if (context[0].dataIndex == 0) { return taskName1; }
            else if (context[0].dataIndex == 1) { return taskName2; }
            else if (context[0].dataIndex == 2) { return taskName3; }
            else if (context[0].dataIndex == 3) { return taskName4; }
            else if (context[0].dataIndex == 4) { return taskName5; }
          },
        },
      },
    },
    scales: {
      y: {
        // beingAtZero: true,
        title: {
          display: true,
          text: 'No of Days',
          color: "black",
          fontStyle: "bold",
        },
        ticks: { stepSize: 10 },
      },
      x: {
        title: {
          display: true,
          text: 'Style',
          color: "black",
          fontStyle: "bold",
        //   padding: {
        //     top: 40,
        //     bottom: 5
        // }
        },
      },
    },

    onClick: (event, index) => {
      if (index.length > 0) {
        ordersttus(index[0].index)
      }
    }
  };

  const delayedTaskNoData = {
    labels: "",
    datasets: [
      {
        label: "No Data",
        legend: "No Data",
        data: [],
        borderColor: "#7b7b7b ",
        backgroundColor: "#7b7b7b ",
      },
    ],
  };

  const dtNoDataOptions = {
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: true,
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: 38,
          boxHeight: 8,
        },  
      }
    },
    scales: {
      y: {
        beingAtZero: true,
        title: {
          display: true,
          text: "No of days",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 }
        },
        min: 0,
        max: 5,
        ticks: { stepSize: 1 },
      },
      x: {
        title: {
          display: true,
          text: "Style",
          color: "black",
          fontStyle: "bold",
          // font: { size: 13 },
        },
      },
    },
  };

  return (
    <Fragment>
      <Col xl="6" md="12">
        <Card className='shadow shadow-showcase' style={{ marginTop: '-7px' }}>
          <span className="m-t-20 p-l-25"> 
            <span className="f-16 f-w-600"> {t("top5DelayedTasks")} </span>
          </span>
          <CardBody className="chart-block pt-1 pb-1" style={{ marginTop: '33PX' }}>
            <Row>
              <Col xl="12" md="12">
                {delayedTaskList !== true ?
                  <Line options={dtNoDataOptions} data={delayedTaskNoData} width={3500} height={1320} />
                  :
                  <Line options={options} data={data} width={3500} height={1320}/> 
                }
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default PieChartClass;