import { Container, Form, Label, Card, CardBody, Col, Row, Input, InputGroup, InputGroupText, Button, CardHeader, Collapse, FormGroup, } from "reactstrap";
import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, H4, P, H6, Btn, LI, UL, } from "../../../AbstractElements";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { ServerUrl } from "../../../Constant/index";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId,
     getWorkspaceType, getStaff, getStaffPermission, 
     getLoginStaffId,getModules } from "../../../Constant/LoginConstant";
import { Accordion } from "react-bootstrap";
import { AllCloseAccordion, CollapsibleGroupItem } from "../../../Constant";
import FilterIcon from "../../../assets/images/dms/icons/FilterIconDms.svg";
import noData from "../../../assets/images/dms/nodata.png"
import DownloadIcon from "../../../assets/images/dms/icons/DowloadIconDms.svg";
import { DownloadFile, apiencrypt, apidecrypt, namesOfFacOrBuyer } from "../../../helper";
import OrderReportTable from "./OrderReportTable";
// import FilterOffCanvas from "./FilterOffCanvas";
import { useTranslation } from 'react-i18next';
const orderReports = () => {
    const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
    const [company_id, setcompany_id] = useState(getLoginCompanyId);
    const [factory, setFactory] = useState("");
    const [buyer, setBuyer] = useState("");
    const [buyers, setBuyers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [pcu, setPcu] = useState("");
    const [styles, setStyles] = useState([]);
    const [errors, setValidErrors] = useState({});
    const [Pcus, setPcus] = useState([]);
    const [factories, setFactories] = useState([]);
    const [statusFilter, setStatusFilter] = useState("All");
    const [selectedStatus, setselectedStatus] = useState("");
    const [necessaryDetails, setNecessaryDetails] = useState([]);
    const [filterStartDate, setFilterStartDate] = useState();
    const [filterEndDate, setFilterEndDate] = useState();
    const [filterOperator, setFilterOperator] = useState();
    const [filterPieces, setFilterPieces] = useState();
    const [filterType, setFilterType] = useState([]);
    const { t } = useTranslation();
    let detailsToSend;
    getStaff === "Staff" ?  
        detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
        staff_id : getLoginStaffId
    } : detailsToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id: getLoginUserId,
    }
    const [toOpen , setToOpen] = useState(0);
    const [filterOffCanvas , setFilterOffCanvas] = useState(false);
    const toggleFilterCanvas = () => setFilterOffCanvas(!filterOffCanvas); 
    useEffect(() => {
        {
            (((getStaff === "Staff" &&getModules.includes("Reports")&&getStaffPermission.includes('View Report'))||getStaff == null )) 
            ?
            ""
            :
            window.location.href = "/dashboard" 
          } 
        axios
            .post(ServerUrl + "/get-order-reports", apiencrypt(detailsToSend))
            .then((response) => {
                response.data= apidecrypt(response.data)
                setselectedStatus("All");
                setStyles(response.data.data);
                setToOpen(1);
            });
        axios
            .post(ServerUrl + "/get-user-factory", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setFactories(response.data.data);
            });
        axios
            .post(ServerUrl + "/get-user-pcu", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });

        axios
            .post(ServerUrl + "/get-user-buyer", apiencrypt(detailsToSend))
            .then((response) => {
                response.data = apidecrypt(response.data)
                setBuyers(response.data.data);
            });    
    }, []);
    /***********************To get the factory/pcu/buyer to filter and get the respective styles ***************/
    const filterStyle = (type,id)=>{
        if(type ==="Factory"){
            axios.post(ServerUrl + "/get-filters", apiencrypt({
            company_id: company_id,
            workspace_id: workspace_id,
            factory_id : id,
            type : getWorkspaceType,
            })).then((response) => {
                response.data = apidecrypt(response.data)
            if(getWorkspaceType === "Buyer"){
                setPcus(response.data.data);
            }
            else if(getWorkspaceType === "PCU"){
                setBuyers(response.data.data);
            }  
            });
        }
        if(type ==="Buyer"){
            axios.post(ServerUrl + "/get-filters", apiencrypt({
            company_id: company_id,
            workspace_id: workspace_id,
            buyer_id : id,
            type : getWorkspaceType,
            })).then((response) => {
                response.data = apidecrypt(response.data)
                setPcus(response.data.data);
            });
        }
    }
    /*Order Validation  Start*/
//     const orderValidation = (data) => {
//     let errors={};
//     // if (!factory && getWorkspaceType!='Factory') {
//     //     errors.factory = 'Please Select a Factory';
//     //    }
//     //    if (!buyer  && getWorkspaceType!='Buyer') {
//     //     errors.buyer = 'Please Select a Buyer';
//     //    }
//     //    if (!pcu  && getWorkspaceType!='PCU') {
//     //     errors.pcu = 'Please Select a PCU';
//     //    }
//     if (!styleNo) {
//       errors.styleNo = 'Please Select a Style Number';
//      }
  
//    setValidErrors(errors);
//    return errors;
//     };

    /*********************** To Download The PDF ********************/
    const toDownloadAsPdf = () => {
        let dataToSendForOrder ;
        getStaff === "Staff" ? dataToSendForOrder = {
            company_id: company_id,
            workspace_id: workspace_id,
            staff_id: getLoginStaffId,
            }: dataToSendForOrder = {
                company_id: company_id,
                workspace_id: workspace_id,
                user_id: getLoginUserId,
                };
        if (getWorkspaceType === "Buyer"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
            // dataToSendForOrder.factory_id = factory;
            // dataToSendForOrder.pcu_id = pcu;
        }
        else if (getWorkspaceType === "PCU"){
            dataToSendForOrder.factory_id = document.getElementById("facID").value;
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
            // dataToSendForOrder.factory_id = factory;
            // dataToSendForOrder.buyer_id = buyer;
        }
        else if (getWorkspaceType === "Factory"){
            dataToSendForOrder.buyer_id = document.getElementById("buyerID").value;
            dataToSendForOrder.pcu_id = document.getElementById("pcuID").value;
            // dataToSendForOrder.buyer_id = buyer;
            // dataToSendForOrder.pcu_id = pcu;
        }
        let statusFilter = document.getElementById('statusFilter').value;
        dataToSendForOrder.statusFilter = statusFilter;
        axios.post(ServerUrl + "/download-order-reports",apiencrypt(dataToSendForOrder),{responseType: 'blob'})
        .then((response)=>{
            let name = namesOfFacOrBuyer(factories,factory,buyers,buyer);
            let fileName = name=="" || name === undefined ? "OrderStatusReport-"+statusFilter+"-"+Date.now()+".pdf" : 
            "OrderStatusReport-"+name+"-"+statusFilter+"-"+Date.now()+".pdf";
            // let fileName = Date.now()+"-OrderStatusReport.pdf";
            DownloadFile(response.data,fileName);
        })
    }  
    /* Order Details */
    const getReportDetails= () => {
        // let retval= orderValidation();
        let detailToSend;
        getStaff === "Staff" ? detailToSend = {
            company_id: company_id,
            workspace_id: workspace_id,
            staff_id: getLoginStaffId,
        }: detailToSend = {
            company_id: company_id,
            workspace_id: workspace_id,
            user_id: getLoginUserId,
        };
        let statusFilter = document.getElementById('statusFilter').value;
        if (getWorkspaceType === "Buyer"){
            detailToSend.factory_id = document.getElementById("facID").value;
            detailToSend.pcu_id = document.getElementById("pcuID").value;
            // detailToSend.factory_id = factory;
            // detailToSend.pcu_id = pcu;
        }
        else if (getWorkspaceType === "PCU"){
            detailToSend.factory_id = document.getElementById("facID").value;
            detailToSend.buyer_id = document.getElementById("buyerID").value;
            // detailToSend.factory_id = factory;
            // detailToSend.buyer_id = buyer;
        }
        else if (getWorkspaceType === "Factory"){
            detailToSend.buyer_id = document.getElementById("buyerID").value;
            detailToSend.pcu_id = document.getElementById("pcuID").value;
            // detailToSend.buyer_id = buyer;
            // detailToSend.pcu_id = pcu;
        }
        detailToSend.statusFilter = statusFilter;
        axios
        .post(ServerUrl + "/get-order-reports", apiencrypt(detailToSend))
        .then((response) => {
            response.data= apidecrypt(response.data)
            if(response.data.status_code === 200){
                    setselectedStatus(statusFilter);
                    setStyles(response.data.data);
                    setToOpen(1);
                }
            });
    }
    return (
        <Fragment>
            <Row  className="pgbgcolor">
            <Breadcrumbs mainTitle={t('orderStatusReport')} 
                         parent={t('orderStatusReport')} 
                         title={t('orderStatusReport')}/>
            </Row>
                <Container fluid={true} className="general-widget topaln">
                <Card className="m-b-20">
                    <CardBody className="shadow shadow-showcase">
                        <Row className="g-12 ">
                        {getWorkspaceType != "Factory" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("factory")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                className={`${errors.factory && "select-error-valid"
                                                    }  form-control`}
                                                name="factory"
                                                type="select"
                                                placeholder={t("factory")}
                                                aria-label="factory"
                                                defaultValue="0"
                                                id="facID"
                                                // onChange={handleChange}
                                                onChange={(e) => {setFactory(e.target.value);let facId = e.target.value;filterStyle("Factory",facId);}}
                                            >
                                                <option value="0">{t("selectFactory")}</option>
                                                {factories.map((factory) => (
                                                    <option value={factory.id}>{factory.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.factory && (
                                            <span className="error-msg">{t(errors.factory)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "Buyer" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup className="">
                                        <Label>{t("buyer")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                className={`${errors.buyer && "select-error-valid"
                                                    }  form-control`}
                                                name="buyer"
                                                type="select"
                                                placeholder={t("buyer")}
                                                aria-label="buyer"
                                                defaultValue="0"
                                                id="buyerID"
                                                onChange={(e) => {setBuyer(e.target.value);let buyerId = e.target.value;filterStyle("Buyer",buyerId);}}
                                            >
                                                <option value="0">{t("selectBuyer")}</option>
                                                {buyers.map((buy) => (
                                                    <option value={buy.id}>{buy.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.buyer && (
                                            <span className="error-msg">{t(errors.buyer)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}
                            {getWorkspaceType != "PCU" ? (
                                <Col lg="3" md="12" sm="12">
                                    <FormGroup>
                                        <Label>{t("productionControlUnit")}</Label>
                                        {/* <sup className="font-danger">*</sup> */}
                                        <InputGroup>
                                            <Input
                                                type="select"
                                                placeholder="Select Status"
                                                className={`${errors.pcu && "select-error-valid"
                                                    }  form-control digits`}
                                                name="pcuid"
                                                defaultValue="0"
                                                id="pcuID"
                                                // onChange={handleChange}
                                                onChange={(e) => {setPcu(e.target.value);let pcuId = e.target.value;filterStyle("PCU",pcuId);}}
                                            >
                                                <option value="0">
                                                    {t("selectPCU")}
                                                </option>
                                                {Pcus.map((pcuv) => (
                                                    <option value={pcuv.id}>{pcuv.name}</option>
                                                ))}
                                            </Input>
                                        </InputGroup>
                                        {errors.pcu && (
                                            <span className="error-msg">{t(errors.pcu)}</span>
                                        )}
                                    </FormGroup>
                                </Col>
                            ) : (
                                ""
                            )}

                            <Col lg="3" md="12" sm="12">
                            <FormGroup>
                                    <Label>{t("status")}</Label>
                                    <sup className="font-danger">*</sup>
                                    <InputGroup>
                                        <Input
                                            type="select"
                                            placeholder={t("selectStatus")}
                                            className={`${errors.statusFilter && "select-error-valid"
                                                }  form-control digits`}
                                            name="statusFilter"
                                            id="statusFilter"
                                            defaultValue=""
                                            onChange={(e) => setStatusFilter(e.target.value)}
                                        >
                                            {/* <option value="" disabled>
                                                {t("selectStatus")}
                                            </option> */}
                                            <option value="All" selected>{t("all")}</option>
                                            <option value="Completed">{t("completed")}</option>
                                            <option value="Active">{t("active")}</option>
                                            <option value="Cancelled">{t("cancelled")}</option>
                                            <option value="Deleted">{t("deleted")}</option>
                                        </Input>
                                    </InputGroup>
                                    {errors.statusFilter && (
                                        <span className="error-msg">{t(errors.statusFilter)}</span>
                                    )}
                                </FormGroup>
                            </Col>
                            <Col lg="3" md="6" sm="6" xs="6" className="m-t-30 text-end">
                                <Button className="btn btn-primary f-left m-r-10 m-b-5" 
                                        onClick={() => getReportDetails()}>{t("search")}</Button>
                                <Link to="/orderreports" className="f-left"><Button className="btn secondaryBtn ">{t("clear")}</Button></Link>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                </Container>
                {toOpen===1? styles.length>0 ?
                    <>
                        <Card className="m-b-10 m-t-0" style={{ border:"0px" }}>
                            <CardBody className=" p-1"  >                
                                <Row>
                                    <Col lg="12" md="12" sm="12" xs="12" className="centerAlign">
                                            <Row>
                                                <Col lg={12} md={12} sm={12} xs={4} className="p-1 f-right">
                                                    {(getStaff === "Staff"&&getStaffPermission.includes('Download Report'))||getStaff == null? 
                                                        <div className="cursor-pointer p-1 p-l-0 m-t-5 m-r-10 f-right" onClick={()=> toDownloadAsPdf()}>
                                                            <img title={t("download")} src={DownloadIcon} />
                                                        </div>
                                                        : ""}
                                                    {/* <div className="cursor-pointer p-1 p-r-0 m-t-5 f-right" onClick={() => toggleFilterCanvas()}>
                                                        <img src={FilterIcon} />
                                                    </div> */}
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <OrderReportTable styles={styles} statusFilter={selectedStatus}/> 
                        {/* <FilterOffCanvas modal={filterOffCanvas} toggle={toggleFilterCanvas} styleNo={styleNo}  
                        dailyProdData={setDailyProdData} filterStartDate={setFilterStartDate} filterEndDate={setFilterEndDate} 
                        filterOperator={setFilterOperator} filterPieces={setFilterPieces} filterType={setFilterType} /> */}
                    </>
                : <>
                    <div className="align-self-center media-body centerAlign" style={{ marginTop:"100px" }}>
                        <img src={noData} style={{ width:"200px"}} />
                        <p className='f-18 m-t-10' >-- {t("noDataFound")} --</p>
                    </div>
                </> : ""}
        </Fragment>
    );
}

export default orderReports;
/* Code By : Rithanesh */