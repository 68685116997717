import React, { Fragment } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { P } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import order from "../../../assets/images/dms/Add-order-color.svg"
import contacts from "../../../assets/images/dms/AddContacts.svg"
import materialsLabels from "../../../assets/images/dms/AddMaterialsLabels1.svg";
import materialsLabelsActive from "../../../assets/images/dms/AddMaterialsLabelsActive.svg";
import sku from "../../../assets/images/dms/AddSku.svg"
import tasks from "../../../assets/images/dms/AddTasks.svg"
import production from "../../../assets/images/dms/AddProductionData.svg"
import contactWhite from "../../../assets/images/dms/contact_white.svg"
import orderWhite from "../../../assets/images/dms/order.svg"
import productionWhite from "../../../assets/images/dms/production_white.svg"
import skuWhite from "../../../assets/images/dms/sku_white.svg"
import taskWhite from "../../../assets/images/dms/task_white.svg";
import { getWorkspaceType } from "../../../Constant/LoginConstant";

import {useTranslation} from "react-i18next";
import { get } from 'react-hook-form';

const OrderHeaders = (props) => {

  const { t } = useTranslation();

    return (
        <Fragment>
           <Row className="u-steps fontSizeHeader">
            {/* Start OrderRegistration Active*/}
                {props.title=='addorder' ?
                     <Col  className="u-step current" 
                     lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                     style={{ border: 'none' }}>
                      <span className="u-step-number"><img src={orderWhite} /></span>
                      <div className="u-step-desc">
                        <span className="u-step-title">
                          {t("orderRegistration")}
                          </span>
                        <p className="fontSizeContent"> {t("basicInformation")}</p>
                      </div>
                    </Col>
                  :
                    <Col className="u-step" 
                    lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                    style={{ border: 'none' }}>
                      <span className="u-step-number" style={{ backgroundColor: "#E0F8E5" }}>
                        <img src={order} />
                      </span>
                      <div className="u-step-desc">
                        <span className="u-step-title"> {t("orderRegistration")}</span>
                        <p className="fontSizeContent">{t("basicInformation")}</p>
                      </div>
                    </Col>}
                 {/* End OrderRegistration Active*/}

                  {/* Start AddSKU Active*/}
                  {props.title=='addsku' ?
                    <Col  className="u-step current" 
                    lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                    style={{ border: 'none' }}>
                    <span className="u-step-number"><img src={skuWhite} /></span>
                    <div className="u-step-desc">
                      <span className="u-step-title"> {t("addSku")}</span>
                      <p className="fontSizeContent">{t("skuBreakUpData")}</p>
                    </div>
                    </Col>
                  :
                    <Col  className="u-step " 
                    lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                    style={{ border: 'none' }}>
                    <span className="u-step-number"
                    style={{ backgroundColor: "#E7F9F6" }}> <img src={sku} width="35px"  style={{ marginTop: '8px', marginLeft: '7px'}}/></span>
                    <div className="u-step-desc">
                      <span className="u-step-title">{t("addSku")}</span>
                      <p className="fontSizeContent">{t("skuBreakUpData")}</p>
                    </div>
                    </Col>}
                 {/* End AddSKU Active*/}

                 {props.title=='addcontact' ?
              <Col className="u-step current" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
              style={{ border: 'none' }}>
                <span className="u-step-number"><img src={contactWhite} /></span>
                <div className="u-step-desc">
                  <span className="u-step-title">{t("addContacts")}</span>
                  <P>{t("contactsInvolved")}</P>
                </div>
              </Col>:<Col className="u-step" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
               style={{ border: 'none' }}>
                <span className="u-step-number" style={{ backgroundColor: "#EDEEF6" }}>
                  <img className="" src={contacts} width="35px"  style={{ marginTop: '5px', marginLeft: '6px'}}/>
                </span>
                <div className="u-step-desc">
                  <span className="u-step-title">{t("addContacts")}</span>
                  <p className="fontSizeContent">{t("contactsInvolved")}</p>
                </div>
              </Col>}

              {props.title=='addtask' ?
              <Col className="u-step current" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
              style={{ border: 'none' }}>
                <span className="u-step-number"><img src={taskWhite} /></span>
                <div className="u-step-desc">
                  <span className="u-step-title">{t("Task")}</span>
                  <p className="fontSizeContent">{t("taskSchedule")}</p>
                </div>
              </Col>:<Col  className="u-step" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
              style={{ border: 'none' }}>
                <span className="u-step-number" style={{ backgroundColor: "#FDF7EA" }}> 
                <img  src={tasks} width="35px"  style={{ marginTop: '5px', marginLeft: '6px'}}/></span>
                <div className="u-step-desc">
                  <span className="u-step-title">{t("Task")}</span>
                  <p className="fontSizeContent">{t("taskSchedule")}</p>
                </div>
              </Col>}

              {
                getWorkspaceType == "Factory" 
              ?
                props.title=='addmaterialsLabels' 
                ?
                  <Col className="u-step current" 
                  lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                  style={{ border: 'none' }}>
                    <span className="u-step-number"><img src={materialsLabelsActive} /></span>
                    <div className="u-step-desc">
                      <span className="u-step-title">{t("billOfMaterials")}</span>
                      <p className="fontSizeContent">{t("printTrimsPackings")}</p>
                    </div>
                  </Col>
                :
                  <Col className="u-step" 
                  lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
                  style={{ border: 'none' }}>
                    <span className="u-step-number" style={{ backgroundColor: "#FFE1ED" }}>
                      <img className="" src={materialsLabels} width="35px"  style={{ marginTop: '8px', marginLeft: '6px'}}/>
                    </span>
                    <div className="u-step-desc">
                      <span className="u-step-title">{t("billOfMaterials")}</span>
                      <p className="fontSizeContent">{t("printTrimsPackings")}</p>
                    </div>
                  </Col>
              :
                ""
              }

              {props.title=='addproductionpan' ?
              <Col  className="u-step current" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
              style={{ border: 'none' }}>
                <span className="u-step-number" ><img src={productionWhite} /></span>
                <div className="u-step-desc">
                  <span className="u-step-title">{t("productionPlan")}</span>
                  <p className="fontSizeContent">{t("routinePlanTarget")}</p>
                </div>
              </Col>:
              <Col className="u-step" 
              lg={ getWorkspaceType == "Factory" ? "2" : "" } md={ getWorkspaceType == "Factory" ? "4" : "" }
              style={{ border: 'none' }}>
                <span className="u-step-number" style={{ backgroundColor: "#FFF0E5" }}> <img  src={production} width="35px"  style={{ marginTop: '5px', marginLeft: '6px'}}/></span>
              <div className="u-step-desc">
                <span className="u-step-title">{t("productionPlan")}</span>
                <p className="fontSizeContent">{t("routinePlanTarget")}</p>
              </div>
              </Col>}
            </Row>
        </Fragment>
    );
};

export default OrderHeaders;