import React, { Fragment, useState  } from 'react';
import StepZilla from 'react-stepzilla';
import OrderRegistration from './OrderRegistration';
import Addsku from './AddSku';
import AddContacts from './AddContacts';
import Task from './Task';
import ProductionPlan from './ProductionPlan';
import OrderHeaders from './OrderHeaders';
import { Container, Row, Col, Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FormWizardWithIcon,Hometxt, Profile, Contact, MaterialTabWithColor } from '../../../Constant';
import { Breadcrumbs,P } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import { TabCardData } from '../../Common/Data/Bonus-ui';

const CreateOrderForm = () => {
    const steps =
        [
            { name: 'OrderRegistration', component: <OrderRegistration /> },
            { name: 'AddSku', component: <Addsku /> },
            { name: 'AddContacts', component: <AddContacts /> },
            { name: 'Task', component: <Task /> },
            { name: 'ProductionPlan', component: <ProductionPlan /> },

        ];
        const [activeTab, setActiveTab] = useState('2');
    return (


        <Fragment>
            
        {/* <Breadcrumbs mainTitle="Order" parent="Order" title="Order" /> */}
      
            <OrderRegistration/>
            {/* <Addsku/> */}
            {/* <AddContacts/> */}
         
    </Fragment>
           );
};

export default CreateOrderForm;