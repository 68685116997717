import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LI, UL, H6 } from '../../AbstractElements';
import { MENUITEMS } from './Menu';
import { Label } from 'reactstrap';
// import dashboardIcon from "../../assets/images/dms/icons/dashboard-icon.svg";
// import dashboardIconWhite from "../../assets/images/dms/icons/white-dashboard.svg";
import orderstatusIcon from "../../assets/images/dms/icons/orderstatus-icon.svg";
// import orderstatusIconWhite from "../../assets/images/dms/icons/white-order.svg";
// import taskIconWhite from "../../assets/images/dms/icons/white-task.svg";
import taskIcon from "../../assets/images/dms/icons/task-icon.svg";
import pendingtaskIcon from "../../assets/images/dms/icons/pendingtask-icon.svg";
// import pendingtaskIconWhite from "../../assets/images/dms/icons/white-pending.svg";
// import datainputIconWhite from "../../assets/images/dms/icons/white-datainput.svg";
import datainputIcon from "../../assets/images/dms/icons/datainput-icon.svg";
import usermgmtWhite from "../../assets/images/dms/icons/white-usermanagement.svg";
import usermgmt from "../../assets/images/dms/icons/usermanagement-icon.svg";
import staffIcon from "../../assets/images/dms/icons/staff.svg";
// import staffIconWhite from "../../assets/images/dms/icons/staff_white.svg";
// import rolesIconWhite from "../../assets/images/dms/icons/roles_white.svg";
import rolesIcon from "../../assets/images/dms/icons/roles.svg";
import companyIcon from "../../assets/images/dms/icons/company_profile.svg";
import companyIconWhite from "../../assets/images/dms/icons/company_white.svg";
import calendarIconWhite from "../../assets/images/dms/icons/Calendar_white.svg";
import calendarIcon from "../../assets/images/dms/icons/Calendar.svg";
import samMenu from "../../assets/images/dms/icons/sam_animated_icon.webp";
import { getStaff, getModules } from '../../Constant/LoginConstant';
import help_icon from "../../assets/images/dms/icons/help_icon.svg";

import dashboardIcon from "../../assets/images/dms/icons/dashboardnormal.webp";
import dashboardIconWhite from "../../assets/images/dms/icons/dashboardActiveIcon.webp";
import taskIconWhite from "../../assets/images/dms/icons/taskupdateIcon.webp";
import orderstatusIconWhite from "../../assets/images/dms/icons/orderStatusMenuIcon.webp";
import staffIconWhite from "../../assets/images/dms/icons/staffIcon.webp";
import datainputIconWhite from "../../assets/images/dms/icons/dataInputIcon.webp";
import pendingtaskIconWhite from "../../assets/images/dms/icons/pending_animated.webp";
import rolesIconWhite from "../../assets/images/dms/icons/rolesIcon.webp";
import settingsIcon from "../../assets/images/dms/icons/settingIcon.webp";
import reportsIcon from "../../assets/images/dms/icons/reportIcon.webp";
// import companyIconWhite from "../../assets/images/dms/icons/company_white.svg";
import helpIcon from "../../assets/images/dms/icons/help-active.webp";
import dmstoinquiery from "../../assets/images/dms/icons/inquiry_animation.webp";
// import fabric_animate_Icon from "../../assets/images/dms/icons/fabric_animate_Icon.webp";

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive }) => {
  // eslint-disable-next-line
  const { t } = useTranslation();

  const toggletNavActive = (item, title) => {
    Object.values(MENUITEMS)
    .map((mapData1) => 
    {
      mapData1.Items.map((mapData2) => {
        if( mapData2.children ){
          (mapData2.children).map((mapData3) => 
          {
            if( mapData3.title == title ){
              mapData3.active = true;
            } else{
              mapData3.active = false;
            }
          })
        }
      })
    })
    setMainMenu({ mainmenu: MENUITEMS });
    // if (!item.active){
    //   MENUITEMS.map((a) => {
    //     a.Items.filter((Items) => {
    //       if (a.Items.includes(item)) Items.active = true;
    //       if (!Items.children) return true;

    //       console.log("Items.children", Items.children);

    //       Items.children.forEach((b) => {
    //         if(Items.children.includes(item)){ 
    //           item.active = true 
    //         } else{
    //           item.active = false 
    //         }
    //         // if (!b.children) return false;
    //         // b.children.forEach((c) => {
    //         //   if (b.children.includes(item)) { c.active = true } 
    //         // });
    //       });
    //       return Items;
    //     });
    //     return a;
    //   });
    // }
    // item.active = !item.active;
    // setMainMenu({ mainmenu: MENUITEMS });
  };
  
  return (
    <Fragment>
      <UL attrUL={{ className: 'nav-menu custom-scrollbar p-t-10' }}>
        <LI attrLI={{ className: 'back-btn' }}>
          <div className="mobile-back text-end"><span>Back</span><i className="fa fa-angle-right ps-2"></i></div>
        </LI>
        {MENUITEMS.map((Item, i) => (
          < Fragment key={i} className="p-t-10" >
            {/* <LI attrLI={{ className: 'sidebar-main-title' }} >
              <div>
                <H6>{t(Item.menutitle)}</H6>
              </div>
            </LI> */}
            {Item.Items.map((menuItem, i) => (
              menuItem.title != '' && menuItem.title != null && menuItem.title != 'null' ?
                <LI attrLI={{ className: 'dropdown' }} key={i}>
                  {menuItem.type === 'sub' && (
                    <a href="javascript"
                      id="nav-link"
                      className={`nav-link menu-title ${menuItem.active ? 'active' : ''}`}
                      onClick={(event) => {
                        event.preventDefault(); setNavActive(menuItem);
                      }} >
                         {(menuItem.title == 'Pending' && (menuItem.active)) ? 
                            <img className="pendingTaskIconActive" src={pendingtaskIconWhite} /> : 
                      (menuItem.title == 'Pending') ? 
                            <img className="pendingTaskIcon" src={pendingtaskIconWhite} /> 
                      : ""} 

                      {(menuItem.title == 'Settings' && (menuItem.active)) ?
                            <img className="settingIconActive" src={settingsIcon} /> : 
                      (menuItem.title == 'Settings') ? 
                            <img className="settingIcon" src={settingsIcon} /> : ""}

                      {(menuItem.title == 'Reports' && (menuItem.active)) ?
                            <img className="reportsIconActive" src={reportsIcon} /> : 
                      (menuItem.title == 'Reports') ? 
                            <img className="reportsIcon" src={reportsIcon} /> : ""}
                      {(menuItem.title == 'SAM Settings' && (menuItem.active)) ?
                            <img className="samIconActive" src={samMenu} /> : 
                      (menuItem.title == 'SAM Settings') ? 
                            <img className="samIcon" src={samMenu} /> : ""}
                      {menuItem.icon !== undefined && <menuItem.icon />}

                      <span>{t(menuItem.title)} </span>
                      <div className="according-menu">
                        {menuItem.active ? (
                          <i className="fa fa-angle-down"></i>
                        ) : (
                          <i className="fa fa-angle-right"></i>
                        )}
                      </div>
                    </a>
                  )}
                    {menuItem.title=='Inquiry'? <a href='inquiry/viewinquiry' className="nav-link menu-title">
                      <img className="inquirytoDms " src={dmstoinquiery} width="12" style={{  width: '25px'}}/>
                      <span>{t("Inquiry")}</span></a>:
                      <>
                      {menuItem.type === 'link' && 
                      (
                        <Link
                          to={menuItem.path}
                          id="nav-link"
                          className={`nav-link menu-title ${menuItem.active ? 'active' : ''}`}
                          onClick={() => toggletNavActive(menuItem)}>
                            {(menuItem.title == 'Dashboard' && (menuItem.active)) ? <img className="dashboardIconActive" src={dashboardIconWhite} /> : (menuItem.title == 'Dashboard') ? <img className="dashboardIcon" src={dashboardIcon} /> : ""}
                            {/* {(menuItem.title == 'Inquiry' && (menuItem.active)) ? <img className="inquirytoDms" src={dmstoinquiery}/> : (menuItem.title == 'Inquiry') ? <img className="inquirytoDms" src={dmstoinquiery} /> : ""}   */}
                          {(menuItem.title == 'Order Status' && (menuItem.active)) ? <img className="orderStatusIconActive" src={orderstatusIconWhite} /> : (menuItem.title == 'Order Status') ? <img className="orderStatusIcon" src={orderstatusIconWhite} /> : ""}
                          {(menuItem.title == 'Task Update' && (menuItem.active)) ? <img className="taskUpdateIconActive" src={taskIconWhite} /> : (menuItem.title == 'Task Update') ? <img className="taskUpdateIcon" src={taskIconWhite} /> : ""}
                          {(menuItem.title == 'Production Details' && (menuItem.active)) ? <img className="dataInputIconActive" src={datainputIconWhite} /> : (menuItem.title == 'Production Details') ? <img className="dataInputIcon" src={datainputIconWhite} /> : ""}
                          {(menuItem.title == 'User Settings' && (menuItem.active)) ? <img className="settingsIconActive" src={settingsIcon} /> : (menuItem.title == 'User Settings') ? <img className="settingsIcon" src={settingsIcon} /> : ""}
                          {(menuItem.title == 'Staff' && (menuItem.active)) ? <img className="staffIconActive" src={staffIconWhite} /> : (menuItem.title == 'Staff') ? <img className="staffIcon" src={staffIconWhite} /> : ""}
                          {(menuItem.title == 'Roles' && (menuItem.active)) ? <img  className="rolesIconActive" src={rolesIconWhite} /> : (menuItem.title == 'Roles') ? <img className="rolesIcon" src={rolesIconWhite} /> : ""}
                          {(menuItem.title == 'Company Profile' && (menuItem.active)) ? <img src={companyIconWhite} /> : (menuItem.title == 'Company Profile') ? <img src={companyIcon} /> : ""}
                          {(menuItem.title == 'Calendar Configuration' && (menuItem.active)) ? <img src={calendarIconWhite} /> : (menuItem.title == 'Calendar Configuration') ? <img src={calendarIcon} /> : ""}
                          

                          {/* {(menuItem.title == 'Dashboard' && (menuItem.active)) ? <img src={dashboardIconWhite} /> : (menuItem.title == 'Dashboard') ? <img src={dashboardIcon} /> : ""}
                          {(menuItem.title == 'Order Status' && (menuItem.active)) ? <img src={orderstatusIconWhite} /> : (menuItem.title == 'Order Status') ? <img src={orderstatusIcon} /> : ""}
                          {(menuItem.title == 'Task Update' && (menuItem.active)) ? <img src={taskIconWhite} /> : (menuItem.title == 'Task Update') ? <img src={taskIcon} /> : ""}
                          {(menuItem.title == 'Pending Tasks' && (menuItem.active)) ? <img src={pendingtaskIconWhite} /> : (menuItem.title == 'Pending Tasks') ? <img src={pendingtaskIcon} /> : ""}
                          {(menuItem.title == 'Production Details' && (menuItem.active)) ? <img src={datainputIconWhite} /> : (menuItem.title == 'Production Details') ? <img src={datainputIcon} /> : ""}
                          {(menuItem.title == 'User Settings' && (menuItem.active)) ? <img src={usermgmtWhite} /> : (menuItem.title == 'User Settings') ? <img src={usermgmt} /> : ""}
                          {(menuItem.title == 'Staff' && (menuItem.active)) ? <img src={staffIconWhite} /> : (menuItem.title == 'Staff') ? <img src={staffIcon} /> : ""}
                          {(menuItem.title == 'Roles' && (menuItem.active)) ? <img src={rolesIconWhite} /> : (menuItem.title == 'Roles') ? <img src={rolesIcon} /> : ""}
                          {(menuItem.title == 'Company Profile' && (menuItem.active)) ? <img src={companyIconWhite} /> : (menuItem.title == 'Company Profile') ? <img src={companyIcon} /> : ""}
                          {(menuItem.title == 'Calendar Configuration' && (menuItem.active)) ? <img src={calendarIconWhite} /> : (menuItem.title == 'Calendar Configuration') ? <img src={calendarIcon} /> : ""}
                          {(menuItem.title == 'SAM Settings' && (menuItem.active)) ? <img src={samMenu} /> : (menuItem.title == 'SAM Settings') ? <img src={samMenu} /> : ""} */}


                          {/* {(menuItem.title=='Order Status' && (menuItem.active))?<img src={orderstatusIcon}/>:<img src={orderstatusIcon}/>} */}
                          {/* {menuItem.icon !== undefined && <menuItem.icon />} */}

                          <span>{t(menuItem.title)}</span>
                          {menuItem.badge && (
                            <Label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </Label>
                          )}
                        </Link>
                      )}
                      </>
                      }
                  {menuItem.children && (
                    <UL attrUL={{
                      className: 'simple-list sidebar-submenu',
                      style:
                        menuItem.active
                          ? sidebartoogle
                            ? {
                              opacity: 1,
                              transition: 'opacity 500ms ease-in',
                            }
                            : { display: 'block' }
                          : { display: 'none' }
                    }}>
                      <UL attrUL={{ className: 'nav-submenu menu-content' }}>
                        {menuItem.children.map((childrenItem, index) => 
                        {
                          return (
                            <LI key={index}>
                              {childrenItem.type === 'sub' && (
                                <a href="javascript" className={`${childrenItem.active ? 'active' : ''}`}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    toggletNavActive(childrenItem);
                                  }}>
                                  {t(childrenItem.title)}
                                  <div className="according-menu">
                                    {childrenItem.active ? (<i className="fa fa-caret-down"></i>) : (<i className="fa fa-caret-right"></i>)} </div>
                                </a>
                              )}
                              {childrenItem.type === 'link' && (
                                childrenItem.active == true 
                                ?
                                  <a
                                    href={childrenItem.path} 
                                    className={`${childrenItem.active ? 'active' : ''} submenuActive`}
                                    onClick={() => {
                                      toggletNavActive(childrenItem, childrenItem.title)
                                    }}>
                                    {t(childrenItem.title)} 
                                  </a>
                                :
                                  <a
                                    style={{ backgroundColor: '#F2F6F6' }}
                                    href={childrenItem.path} 
                                    className={`${childrenItem.active ? 'active' : ''} submenuInActive`}
                                    onClick={() => {
                                      toggletNavActive(childrenItem, childrenItem.title)
                                    }}>
                                    {t(childrenItem.title)} 
                                  </a>

                              )}
                              {childrenItem.children && (
                                <UL attrUL={{
                                  className: 'simple-list nav-sub-childmenu submenu-content',
                                  style: childrenItem.active
                                    ? { display: 'block' }
                                    : { display: 'none' }
                                }}>
                                  {childrenItem.children.map(
                                    (childrenSubItem, key) => (
                                      <LI key={key}>
                                        {childrenSubItem.type === 'link' && (
                                          <Link
                                            to={childrenSubItem.path}
                                            className={`${childrenSubItem.active ? 'active' : ''}`}
                                            onClick={() => toggletNavActive(
                                              childrenSubItem)}>
                                            {t(childrenSubItem.title)} 
                                          </Link>
                                        )}
                                      </LI>
                                    )
                                  )}
                                </UL>
                              )}
                            </LI>
                          );
                        }
                        )}

                      </UL>
                    </UL>
                  )}
                </LI> : ''
            ))}
          </Fragment>
        ))
        }
        {/* <LI>
        <a href='inquiry/viewinquiry' className="nav-link menu-title">
          <img className="inquirytoDms " src={dmstoinquiery} width="12" style={{  width: '25px'}}/>
          <span>{t("Inquiry")}</span></a>
      </LI> */}
      {/* <LI>
        <a href='/inquiry' className="nav-link menu-title">
          <img className="fabricicon i" src={fabric_animate_Icon} width="12" style={{  width: '25px'}}/>
          <span>{t("Fabric")}</span></a>
      </LI> */}
      
        <LI className="dropdown list-group-item ">
          <a href={`${process.env.PUBLIC_URL}/DMS_Manual.pdf`} className="nav-link menu-title" target={"_blank"}>
            <img className="helpIcon" src={helpIcon} width="12" style={{ width: '22px' }} />
            <span>
              {t("help")}
            </span> 
          </a>
        </LI>
      </UL>
    </Fragment>
  );

};
export default SidebarMenuItems;
