import React, {useState, useEffect} from 'react';
import { Modal, ModalBody, Row, Col, Card, CardBody } from 'reactstrap';
import { ServerUrl } from '../../Constant';
import  axios  from "axios";
import Swal from "sweetalert2";
import companylogo from "../../assets/images/dms/icons/profile.png";
import dmsPdfLogo from "../../assets/images/dms/icons/dms_pdf_logo.png";
import {useTranslation} from "react-i18next";
import { apidecrypt, apiencrypt } from '../../helper';
import { getLoginCompanyId, getWorkspaceId} from '../../Constant/LoginConstant';

const ReportHeaderLogo = ({ 
    modal, toggle, logoURL, 
    selectedCompanyLogo, setSelectedCompanyLogo, 
    selectedDmsLogo, setSelectedDmsLogo }) =>{
  
  const {t} = useTranslation();
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const reqData = { company_id, workspace_id };

  const onSaveHandle = (data) => {
    
    var apiInputParams = {
      company_id: getLoginCompanyId,
      use_logo: data
    };
    axios
        .post(ServerUrl + "/update-default-logo", apiencrypt(apiInputParams))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
                icon: 'success',
                title: t(response.data.message),
                showConfirmButton: false,
                timer: 1500
              })
            toggle(false);
          }
        });

  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalBody style={{ backgroundColor: '#F9F9F9' }}>

        <Row style={{ borderBottom:"1px solid #B5B5B5" }}>
            <h5 className="text-left m-t-5 m-b-15" >
                {t("reportHeaderLogo")}
            <span className="f-right cursor-pointer" style={{ color:"#B5B5B5" }}  onClick={toggle} title={t("close")}>X</span>
            </h5>
        </Row>

        <Row 
            style={{ height: '200px'}} 
            className='g-12 p-4'>
           
            <Col 
                className={`text-center containerClass reportHeader ${selectedCompanyLogo===true ? 'selectedLogo' : ''} `} 
                style={{ border: '1px solid #D0D0D0', borderRadius: '10px', cursor: 'pointer', width:"193px" ,height:"167px" }}
                onClick={ () => { setSelectedCompanyLogo(true), setSelectedDmsLogo(false), onSaveHandle(1) }}>
                        {logoURL 
                    ? 
                      <img 
                            className="text-center m-t-10"
                            src={logoURL} 
                            alt="companylogo" 
                        />
                        : 
                      <img  className="text-center" src={companylogo} />}
            </Col>
                       
           
            <Col 
                className={`text-center m-l-30  reportHeader ${selectedDmsLogo===true ? 'selectedLogo' : ''}`}
                style={{ border: '1px solid #D0D0D0',  borderRadius: '10px',cursor: 'pointer', width:"193px" ,height:"167px" }}
                onClick={ () => { setSelectedCompanyLogo(false), setSelectedDmsLogo(true), onSaveHandle(0) }}>
                <img  className="m-t-40 " src={dmsPdfLogo} />
            </Col> 
        </Row> 

        <Row style={{ color: '#E86262', marginTop: '15px' }}>
            <span className="text-center">
                 {t("noteThisLogoShownReports")}   
            </span>
        </Row>

      </ModalBody>
    </Modal>
  );
};

export default ReportHeaderLogo;
/* Code By : Ramanan M */