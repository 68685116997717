import React, { useState, useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import {  Card,  CardBody,  CardHeader, Collapse,  Input, Table} from "reactstrap";
import { Btn, H5 } from "../../AbstractElements";
import { ServerUrl } from "../../Constant";
// import DatePicker from "react-datepicker";
import axios from "axios";
import Swal from "sweetalert2";
import reschedule from "../../assets/images/dms/reschedule.svg"
import more from "../../assets/images/dms/showMore.svg"
import edit from "../../assets/images/dms/editPencil.svg"
import chat from "../../assets/images/dms/chat_icon.svg"
import RescheduleTaskModal from "./NewRescheduleModal"
import TaskUpdateModal from "./NewTaskUpdateModal"
import TaskHistoryCanvas from "./NewHistoryOffCanvas"
//import Chatoffcanvas from '../Chat/Chatoffcanvas'
import { getLoginCompanyId,getWorkspaceId, getLoginUserId, getStaff, 
  getStaffPermission, getLoginStaffId } from "../../Constant/LoginConstant";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";
// import TemplateModal from "../CreateOrders/AddTemplateModal"
// import FormAddTaskSubTaskModal from "../CreateOrders/AddTaskSubTaskModal"
import task_icon from "../../assets/images/dms/task_icon.svg";
import Add_icon from '../../assets/images/dms/icons/Main Task Add_icon.svg'
import NewTaskHistoryOffCanvas from "./NewHistoryOffCanvas";
import NewRescheduleModal from "./NewRescheduleModal";
import NewTaskUpdateModal from "./NewTaskUpdateModal";
import EditTaskupdateModal from "./EditTaskupdateModal";
import DeleteMainTask from "./DeleteMainTask"
import downAccordion_icon from '../../assets/images/dms/icons/For Accordion_icon.svg'
import up_accordion_icon from '../../assets/images/dms/icons/up_accordion_icon.svg'
import delete_icon from '../../assets/images/dms/icons/delete_icon.svg'
import subTask_icon from '../../assets/images/dms/icons/Add Task_icon.svg'
import task_history from '../../assets/images/dms/reschedule.svg'
import edittaskupdate_icon from '../../assets/images/dms/icons/edittaskupdate_icon.svg'
import Subtask_delete from '../../assets/images/dms/icons/Subtask_delete_icon.svg'
import new_chat_icon from '../../assets/images/dms/icons/new_chat_icon.svg'
import tasklist_icons from '../../assets/images/dms/icons/tasklist_icons.svg'
import Subtasklist_icon from '../../assets/images/dms/icons/taskupdate_sub task icon.svg'

 
const NewTaskAccordion = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const toggle = (id) => (isOpen === id ? setIsOpen(null) : setIsOpen(id));
  const [personInCharge, setPersonInCharge] = useState([]);
  let templateTasks = props.tasks;
  const selectedTemplateId = props.selectedTemplate;
  const workspaceID = props.workspaceId;
  const companyID = props.companyId;
  const orderID = props.orderId;
  const templateData = props.templateData;
  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [offCanvasHistory, setOffCanvasHistory] = useState(false);
  const [rescheduleType, setRescheduleType] = useState("");
  const [taskId, setTaskId] = useState();
  const [value, setValue] = useState();
   const [startDateValue, setStartDateValue] = useState();
   const [endDateValue, setEndDateValue] = useState();
   
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState("");
  // const [accomplishedDate, setAccomplishedDate] = useState();
  const [subtitle, setSubtitle] = useState("");
  const [isSubtask, setIsSubtask] = useState("");
  const [taskType, setTaskType] = useState("");
  const [parentDate, setParentDate] = useState("");
  const [chatpic, setchatpic] = useState("");
  const [chatpicName, setchatpicName] = useState("");
  const [serverURL, setServerURL] = useState("");
  const [isSubtaskDetails, setIsSubtaskDetails] = useState({});
  const toggleModal = () => setModal(!modal);
  const toggleModalUpdate = () => setModalUpdate(!modalUpdate);
  const toggleOffcanvasHistory = () => setOffCanvasHistory(!offCanvasHistory);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const toggleCanvas = () => setShow(!show);
  const [chatMessages, setChatMessages] = useState("");
  const [addTaskinfoModal, setAddTaskinfoModal] = useState(false);
  const toggleAddTaskinfo = () => setAddTaskinfoModal(!addTaskinfoModal);
  const [templateload, setTemplateload] = useState([]);
  const [isSubTaskEmpty, setIsSubTaskEmpty] = useState(0);
  const [templateNames, setTemplateNames] = useState([]);
  const [isTemplateChanged, setIsTemplateChanged] = useState("0");
  const [titleType, settitleType] = useState("");

  
    /**Main Task Delete Modal */
    const [modalMainTaskDelete, setModalMainTaskDelete] = useState(false);
    const toggleMainTaskDelete = () => setModalMainTaskDelete(!modalMainTaskDelete);
    const[mainTaskName,setMainTaskName]=useState("");
    const[mainTaskTitle,setMainTaskTitle]=useState("");
    const[getTaskID,settaskID]=useState("");

  /************ To open the reschedule Modal****************/

  const toOpenRescheduleModal = (startdate,enddate,title,theType, id, value,) => {

    var subtask="task";
    var parentDate="";
    
    if (theType === "StartDate") {
      setStartDateValue(startdate)
    setEndDateValue(enddate)
      settitleType(title)
      setRescheduleType("StartDate");
      setTaskId(id);
      setValue(value);
      setTaskType(subtask);
      setParentDate(parentDate);
    }
    else if (theType === "EndDate") {
      setStartDateValue(startdate)
    setEndDateValue(enddate)
      settitleType(title)
      setRescheduleType("EndDate");
      setTaskId(id);
      setValue(value);
      setTaskType(subtask);
      setParentDate(parentDate);
    }
    else if (theType === "PIC") {
      setStartDateValue(startdate)
    setEndDateValue(enddate)
      settitleType(title)
      setRescheduleType("picId");
      setTaskId(id);
      setValue(value);
      setTaskType(subtask);
      setParentDate(parentDate);
    }
    toggleModal();
  }
  /*******************To open the update modal *******************/
  const toOpenUpdateModal = (id, subtitle , subtask="", details={}) => {
    setTaskId(id);
    setSubtitle(subtitle);
    subtask!="" ? setIsSubtask(subtask) : "";
    details!="" ? setIsSubtaskDetails(details) : "";
    toggleModalUpdate();
  }
  const toCHatUpdateModal = async(id, subtitle , pic) => {
   
    setTaskId(id);
    setSubtitle(subtitle);
    setchatpic(pic)
    for(let i=0;i<personInCharge.length;i++){
      if(personInCharge[i].staff_id === pic){
        setchatpicName(personInCharge[i].staff_name)
      }
    }    
    await axios
    .post(ServerUrl + "/get-chat",apiencrypt({
      page_id:id,
      page_type:"taskupdate",
      workspace_id: getWorkspaceId,
      company_id: getLoginCompanyId,
    }))
    .then((response) => {
      response.data = apidecrypt(response.data);
      setChatMessages (response.data.data.chats )
      setServerURL(response.data.data.serverURL)
      toggleCanvas();
    });
   
  }

  /**************** To Add Actual Start Date ******************/
  const actualStartDate = (id,title,taskStartDate) =>{
    let dataToSend = {
      company_id: companyID,
      workspace_id: workspaceID,
      taskId: id
    };
    dataToSend.actualStartDate = new Date().toISOString().slice(0, 10);
    if(taskStartDate > dataToSend.actualStartDate){
      Swal.fire({
        title: t("checkStartDateTryAgain"),
        icon: "warning",
        button: t("yes"),
        allowOutsideClick: false
      })
    }else{
      Swal.fire({
        title: t("areYouSure"),
        html: (t("actualStartDateAddition",{date:dataToSend.actualStartDate,title:title})),
        icon: "success",
        button: t("yes"),
        showCancelButton: true,
        allowOutsideClick: false
      }).then((result) =>{
          if(result.isConfirmed){
            axios.post(ServerUrl+"/actual-start-date",apiencrypt(dataToSend)).then((response) =>{
              response.data = apidecrypt(response.data)
              if (response.data.status_code === 200) {
                Swal.fire({
                  title: t("updatedSuccessfully"),
                  icon: "success",
                  button: t("okLabel"),
                  allowOutsideClick: false,
                  timer:5000,
                }).then((result) => {
                  if (result.isConfirmed) {
                    /* To show the updated taskData */
                    axios
                      .post(ServerUrl + "/get-task-data", apiencrypt({
                        company_id: getLoginCompanyId,
                        workspace_id: getWorkspaceId,
                        order_id: orderID
                      }))
                      .then((response) => {
                        response.data = apidecrypt(response.data)
                        props.setTemplateData(response.data.data);
                        props.ForReload(1);
                        axios.post(ServerUrl + "/get-style", apiencrypt({
                          company_id: getLoginCompanyId,
                          workspace_id: getWorkspaceId,
                          orderId: orderID
                        })).then((response) => {
                          response.data = apidecrypt(response.data);
                          props.setStyle(response.data.data);
                          props.taskInfo(response.data.taskInfoData)
                          props.ForReload(0);
                        })
                      });
                  }
                  setTimeout(() => {
                    axios
                    .post(ServerUrl + "/get-task-data", apiencrypt({
                      company_id: getLoginCompanyId,
                      workspace_id: getWorkspaceId,
                      order_id: orderID
                    }))
                    .then((response) => {
                      response.data = apidecrypt(response.data)
                      props.setTemplateData(response.data.data);
                      props.ForReload(1);
                      axios.post(ServerUrl + "/get-style", apiencrypt({
                        company_id: getLoginCompanyId,
                        workspace_id: getWorkspaceId,
                        orderId: orderID
                      })).then((response) => {
                        response.data = apidecrypt(response.data);
                        props.setStyle(response.data.data);
                        props.taskInfo(response.data.taskInfoData)
                        props.ForReload(0);
                      })
                    });
                  }, 100);
                });
              }
              if (response.data.status_code === 401) {
                Swal.fire({
                  title: t(response.data.error),
                  icon: "error",
                  button: t("okLabel"),
                });
              }
              if (response.data.status_code === 600) {
                Swal.fire({
                  title: t(response.data.message),
                  icon: "error",
                  button: t("okLabel"),
                });
              }
            })
          }
      })
    }
  }

  /*** Delete Main Task */
  const deleteMaintaskAlert=(()=>{
    Swal.fire({
      title: t("lastTaskDeletionTitleAlert"),
      text: t("subTaskDeletionTextAlert"),
      icon: "error",
      confirmButtonText: t("okLabel"),
      allowOutsideClick: false,
    });
  })
const deleteMaintask = (orderId,templateId,taskTitle,subtitle,taskID) =>{
 // console.log(orderId,"=",templateId,"=",taskTitle,"=",subtitle,"=",taskID);
  Swal.fire({
    title: t("areYouSure"),
    html: (t("deleteTheTask",{title : subtitle})),
    icon: "warning",
    button: t("yes"),
    showCancelButton: true,
    allowOutsideClick: false
  }).then((result) => {
    if(result.isConfirmed){
     // console.log("yeee");
      setMainTaskName(subtitle);
      setMainTaskTitle(taskTitle);
      settaskID(taskID);
      toggleMainTaskDelete();
    }
  });
}
  /******************** To Delete the Subtask ************************/
  const deleteSubtask = (id,taskName) =>{
    let dataToSend = {
      company_id: companyID,
      workspace_id: workspaceID,
      taskId: id,
    };
    Swal.fire({
      title: t("areYouSure"),
      html: (t("deleteTheSubtask",{title : taskName})),
      icon: "warning",
      button: t("yes"),
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if(result.isConfirmed){
        axios.post(ServerUrl + "/delete-subtask", apiencrypt(dataToSend)).then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("deletedSuccess"),
              icon: "success",
              button: t("okLabel"),
              allowOutsideClick: false,
              timer:5000,
            }).then((result) => {
              if (result.isConfirmed) {
                /* To show the updated taskData */
                axios
                  .post(ServerUrl + "/get-task-data", apiencrypt({
                    company_id: getLoginCompanyId,
                    workspace_id: getWorkspaceId,
                    order_id: orderID
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    props.setTemplateData(response.data.data);
                    props.ForReload(1);
                    axios.post(ServerUrl + "/get-style", apiencrypt({
                      company_id: getLoginCompanyId,
                      workspace_id: getWorkspaceId,
                      orderId: orderID
                    })).then((response) => {
                      response.data = apidecrypt(response.data);
                      props.setStyle(response.data.data);
                      props.taskInfo(response.data.taskInfoData)
                      props.ForReload(0);
                    })
                  });
              }
              setTimeout(() => {
                axios
                  .post(ServerUrl + "/get-task-data", apiencrypt({
                    company_id: getLoginCompanyId,
                    workspace_id: getWorkspaceId,
                    order_id: orderID
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    props.setTemplateData(response.data.data);
                    props.ForReload(1);
                    axios.post(ServerUrl + "/get-style", apiencrypt({
                      company_id: getLoginCompanyId,
                      workspace_id: getWorkspaceId,
                      orderId: orderID
                    })).then((response) => {
                      response.data = apidecrypt(response.data);
                      props.setStyle(response.data.data);
                      props.taskInfo(response.data.taskInfoData)
                      props.ForReload(0);
                    })
                  });
              }, 100);
            });
          }
          if (response.data.status_code === 401) {
            Swal.fire({
              title: t(response.data.error),
              icon: "error",
              button: t("okLabel"),
            });
          }
          if (response.data.status_code === 600) {
            Swal.fire({
              title: t(response.data.errors),
              icon: "error",
              button: t("okLabel"),
            });
          }
        }) 
      }
    })   
  }


  const toOpenHistoryOffCanvas = async (id, subtitle) => {
    setTaskId(id);
    setSubtitle(subtitle);
    await axios.post(ServerUrl + "/reschedule-history", apiencrypt({
      company_id: companyID,
      workspace_id: workspaceID,
      order_id: orderID,
      task_id: id
    })).then((response) => {
      response.data = apidecrypt(response.data)
      if (response.data.status === "failure") {
        setStatus(response.data.status);
        setHistory([]);
      }
      if (response.data.status === "success") {
        setStatus(response.data.status);
        setHistory(response.data.data);
      }
    })
    toggleOffcanvasHistory();
  }
  /******************To add Task Accomplished  Date **********************/
  const toAccomplishTask = (id, subtitle, date , type , subtasks) => {
    let accomplishData;
    const dataDate = date;
    if(date === ""){
      date = new Date().toISOString().slice(0, 10);
    }
    if(type === "Task" && subtasks.length > 0){
      var subtasksAccomplished = 0;
      let length = subtasks.length;
      for (let i = 0; i < length; i++) {
        if(subtasks[i].accomplished_date != null){
          subtasksAccomplished ++;
        }
      }
      if(subtasksAccomplished < length){
        Swal.fire({
          title: t("accomplishedAllSubtasks"),
          icon: "error",
          button: t("yes"),
          allowOutsideClick: false
        }).then(() => {
          if(dataDate != "")
            document.getElementById("accomplishedDate||"+id).value = "";
        })
        return false;
      }
    }
    getStaff === "Staff" ? accomplishData = {
      company_id: companyID,
      workspace_id: workspaceID,
      taskId: id,
      accomplishedDate: date,
      staffId: getLoginStaffId,
    } : accomplishData = {
      company_id: companyID,
      workspace_id: workspaceID,
      taskId: id,
      accomplishedDate: date,
      userId: getLoginUserId,
    };
    Swal.fire({
      title: t("areYouSure"),
      // html: `${t("addThisDate")} <strong>${date}</strong> ${t("asAccomplished")} <strong>${subtitle}</strong>?`,
      html: (t("addThisDateValidation",{date:date,taskName:subtitle})),
      icon: "success",
      button: t("yes"),
      showCancelButton: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(ServerUrl + "/accomplished-task", apiencrypt(accomplishData)).then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code === 201) {
            Swal.fire({
              title: t(response.data.message),
              icon: "error",
              button: t("okLabel"),
              allowOutsideClick: false
            })
          }
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t("dateAddedSuccess"),
              icon: "success",
              button: t("okLabel"),
              allowOutsideClick: false,
              timer:5000,
            }).then((result) => {
              if (result.isConfirmed) {
                /* To show the updated taskData */
                axios
                  .post(ServerUrl + "/get-task-data", apiencrypt({
                    company_id: getLoginCompanyId,
                    workspace_id: getWorkspaceId,
                    order_id: orderID
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data)
                    props.setTemplateData(response.data.data);
                    props.ForReload(1);
                    axios.post(ServerUrl + "/get-style", apiencrypt({
                      company_id: getLoginCompanyId,
                      workspace_id: getWorkspaceId,
                      orderId: orderID
                    })).then((response) => {
                      response.data = apidecrypt(response.data);
                      props.setStyle(response.data.data);
                      props.taskInfo(response.data.taskInfoData)
                      props.ForReload(0);
                    })
                  });
              }
              setTimeout(() => {
               /* To show the updated taskData */
               axios
               .post(ServerUrl + "/get-task-data", apiencrypt({
                 company_id: getLoginCompanyId,
                 workspace_id: getWorkspaceId,
                 order_id: orderID
               }))
               .then((response) => {
                 response.data = apidecrypt(response.data)
                 props.setTemplateData(response.data.data);
                 props.ForReload(1);
                 axios.post(ServerUrl + "/get-style", apiencrypt({
                   company_id: getLoginCompanyId,
                   workspace_id: getWorkspaceId,
                   orderId: orderID
                 })).then((response) => {
                   response.data = apidecrypt(response.data);
                   props.setStyle(response.data.data);
                   props.taskInfo(response.data.taskInfoData)
                   props.ForReload(0);
                 })
               });
              }, 100);
              
            });
          }
          if (response.data.status_code === 401) {
            Swal.fire({
              title: t(response.data.error.accomplishedDate),
              icon: "error",
              button: t("okLabel"),
            });
          }
          if (response.data.status_code === 600) {
            Swal.fire({
              title: t(response.data.message),
              icon: "error",
              button: t("okLabel"),
            });
          }
        })
        /* To show the new roles in the dropdown */
      }
      else{
        if(dataDate != "")
          document.getElementById("accomplishedDate||"+id).value = "";
      }
    })
    // }
  }
  useEffect(async() => {
  await axios.post(ServerUrl + "/get-order-contacts", apiencrypt({
      "company_id": companyID,
      "workspace_id": workspaceID,
      "order_id": orderID
    })).then((response) => {
      response.data = apidecrypt(response.data)
      setPersonInCharge(response.data.data);
    })
   await axios.post(ServerUrl + "/get-order-template", apiencrypt({
      "company_id": companyID,
      "workspace_id": workspaceID,
    })).then((response) => {
      response.data = apidecrypt(response.data);
      setTemplateNames(response.data.data);
      setTemplateload(response.data.data)
    })
  }, []);

  
  
  const AddTaskInfo = (id) => {
    
   
  
      
    toggleAddTaskinfo();
  }
  return (
    <>
    {(getStaff === "Staff" && getStaffPermission.includes('modify_task_template')) || getStaff == null ?
        <Row>
          <div>
            {/* <a href="javascript:void(0)" className="f-right m-b-5 icon-color f-w-600 f-18 p-2" 
            style={{ fontFamily: 'poppins'}} onClick={()=>openAddModal("task")}>
            <i className="fa fa-plus-circle"></i>{ t("addTask")}</a> */}
            <img className="cursor-pointer p-r-20" style={{ float: "right" }} 
              title={t("addTask")} onClick={() => AddTaskInfo("task")} src={Add_icon} />
          </div>
        </Row> : ""
      }
      <Row>
        <Col sm="12" xl="12">
          <div className="default-according task_accomplish" id="accordion">
                  {/* {templateTasks.length>0 ?  */}
            {
             
              templateData
                ? (templateData).map((templates, i) => {
                              
                  return (
                    <Card key={i}>
                      <CardHeader className="p-1 cardRadius">
                        <Row>
                          <Col  onClick={()=>toggle(i)} className="cursor-pointer">
                            {/* <H5 attrH5={{ className: "m-0 p-0 " }}> */}
                            {isOpen === i ?
                              <Btn attrBtn={{ as: Card.Header, color: "default", onClick: () => toggle(i)}} >
                                <p style={{marginTop:"6px"}} className=" font-primary "> {templates.task_title} </p>
                              </Btn>
                              :
                              <Btn attrBtn={{as: Card.Header,color: "default",onClick: () => toggle(i)}}>
                                <p style={{marginTop:"6px"}}> {templates.task_title} </p>
                              </Btn>
                            }
                                 {/* </H5> */}
                            
                          
                           
                            {/* <Btn attrBtn={{as: Card.Header, className: "f-right", color: "default",
                                onClick: () => toggle(i),}} > */}
                              {/* {isOpen === i ? <i className="fa fa-angle-up"></i>
                                : <i className="fa fa-angle-down"></i>} */}
                                {isOpen === i ? <span className="  accdview f-right "> <img className="cursor-pointer f-right p-r-5 " title={t("close")}  src={up_accordion_icon} /></span>
                            : <span className="   accdview  f-right "><img className="cursor-pointer f-right p-r-5 "title={t("open")} src={downAccordion_icon} /></span>}
                            {/* </Btn> */}
                           
                          </Col>
                        </Row>
                      </CardHeader>
                     {/*Start Task */}
                     <Collapse isOpen={isOpen === i} >
                        <CardBody className="p-0">
                          <Row >
                          <div className="table-responsive">
                            <Table className="table-responsive">
                              <thead>
                              <th className="taskUpdate-table-header" style={{ width:"16%" }}></th>
                              <th className="taskUpdate-table-header" style={{ width:"14%" }}>{t("startDate")}</th>
                              {/* <th className="taskUpdate-table-header" style={{ width:"14%" }}>Actual Start Date</th> */}
                              <th className="taskUpdate-table-header" style={{ width:"14%" }}>{t("endDate")}</th>
                              <th className="taskUpdate-table-header" style={{ width:"14%" }}>{t("accomplishedDate")}</th>
                              <th  className="taskUpdate-table-header" style={{ width:"16%" }}>{t("personInCharge")}</th>
                              <th className="taskUpdate-table-header" style={{ width:"12%" }}>{t("action")}</th>
                              </thead>
                              {/* {("task_subtitles" in  templates && (templates.task_subtitles).length > 0) ? */}
                            <tbody>
                              {((templates.task_subtitles)).map((mainTaskv, j) => (
                             
                              <>
                                                  
                                      <tr key={j} className="taskUpdate-tr-border">
                                      <td className="taskUpdate-table-sideHeader p-1">
                                      <p className="m-l-10"> 
                                                       {/* <span className="p-l-">
                                        <img src={task_icon} title={t("Task")} tooltip={t("Task")} alt={t("Task")} className="rescheduleImage" />
                                      </span>  */} <img className="img-40" src={tasklist_icons} />
                                      {mainTaskv.subtitle}
                                       {/* <span className="f-right m-l-5"
                                       onClick={() => { toCHatUpdateModal( mainTaskv.id,mainTaskv.subtitle,mainTaskv.pic_id) }}> 
                                        {
                                               ((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('Add Task Updates')))
                                                || (getLoginStaffId ==  mainTaskv.pic_id )|| getStaff == null) ?
                                      <img className="cursor-pointer" src={new_chat_icon} title={t("taskCommunication")} tooltip={t("taskCommunication")} alt={t("taskCommunication")}  />:""}</span> */}
                                      </p> 
                                      </td>
                                      {mainTaskv.start_date === null && mainTaskv.end_date === null && mainTaskv.pic_id ===0  &&((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')) || getStaff == null) ?
                                        <td className="p-1">
                                          <Row>
                                            <Col sm={9} className="p-r-0"> 
                                              <Input
                                                type="text"
                                                id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                min={new Date().toISOString().split('T')[0]}
                                                data-target-input="nearest"
                                                defaultValue={mainTaskv.start_date}
                                                placeholder="yyyy-mm-dd"
                                                readOnly style={{ backgroundColor: "#FFFFFF" }}
                                              >
                                              </Input>
                                            </Col>
                                          </Row>
                                        </td>
                                        :
                                        <td className="p-1">
                                          <Row>
                                            {(mainTaskv.accomplished_date === null ) /* && actual_start_date === null  */
                                            && (mainTaskv.start_date === null || mainTaskv.start_date !== null) 
                                            && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                              || getLoginStaffId == mainTaskv.pic_id || getStaff == null) ?
                                              /* For Add Task Update Permissions Starts*/
                                              ((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')) || getStaff == null ?                                 
                                                <>
                                                  <Col sm={9} className="p-r-0">
                                                    <Input
                                                      type="text"
                                                      id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                      min={new Date().toISOString().split('T')[0]}
                                                      data-target-input="nearest"
                                                      defaultValue={mainTaskv.start_date}
                                                      placeholder="yyyy-mm-dd"
                                                      readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                    </Input>
                                                  </Col>
                                                  <Col sm={1} className="p-l-0">
                                                    <a href="javascript:void(0)" title={t("rescheduleStartDate")} 
                                                       onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"task","StartDate",mainTaskv.id,)}>
                                                      <div className="reschedule-div" ><i className="fa fa-calendar-o rescheduleImage"></i></div></a>
                                                  </Col></> :
                                                <Col sm={9}  className="p-r-0">
                                                  <Input
                                                    type="text"
                                                    id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    defaultValue={mainTaskv.start_date}
                                                    placeholder="yyyy-mm-dd"
                                                    readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                  >
                                                  </Input>
                                                </Col>)
                                              /* For Add Task Update Permissions Ends*/
                                              :<>
                                              <Col sm={9} className="p-r-0" >
                                                <Input
                                                  type="text"
                                                  id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                  min={new Date().toISOString().split('T')[0]}
                                                  data-target-input="nearest"
                                                  defaultValue={mainTaskv.start_date}
                                                  placeholder="yyyy-mm-dd"
                                                  readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                </Input>
                                              </Col>
                                              {((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('Add Task Updates')))
                                                || (getLoginStaffId ==  mainTaskv.pic_id )|| getStaff == null) && (mainTaskv.accomplished_date === null )?
                                                <></>
                                            //   <Col sm={1} className="p-l-0">
                                            //   <a href="javascript:void(0)" title={t("rescheduleStartDate")} onClick={() => toOpenRescheduleModal("StartDate",mainTaskv.id,mainTaskv.start_date)}>
                                            //     <div className="reschedule-div" ><i className="fa fa-calendar-o rescheduleImage"></i></div></a>
                                            // </Col>
                                            :""}
                                            </>
                                            }
                                          </Row>
                                        </td>
                                      }
                                      {/* <td>
                                        <Col sm={11}>
                                            <Input
                                              type="date"
                                              min={new Date().toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              readOnly style={{ backgroundColor: "#FFFFFF" }}
                                              defaultValue={actual_start_date}
                                            >
                                            </Input>
                                        </Col>
                                      </td> */}
                                      {mainTaskv.start_date === null && mainTaskv.end_date === null && mainTaskv.pic_id ===0 ?
                                        <td className="p-1">
                                          <Row>
                                            <Col sm={9} className="p-r-0">
                                              <Input
                                                type="text"
                                                id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                min={mainTaskv.start_date}
                                                data-target-input="nearest"
                                                defaultValue={mainTaskv.end_date}
                                                placeholder="yyyy-mm-dd"
                                                readOnly style={{ backgroundColor: "#FFFFFF" }}
                                              >
                                              </Input>
                                            </Col>
                                          </Row>
                                        </td>
                                        :
                                        <td className="p-1">
                                          <Row>
                                            {(mainTaskv.accomplished_date === null ) && (mainTaskv.end_date === null || mainTaskv.end_date !== null) 
                                            && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                              || getLoginStaffId == mainTaskv.pic_id || getStaff == null)?
                                              /* For Add Task Update Permissions Starts*/
                                              ((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')) || getStaff == null ?
                                                <>
                                                  <Col sm={9} className="p-r-0">
                                                    <Input
                                                      type="text"
                                                      id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                      min={new Date().toISOString().split('T')[0]}
                                                      data-target-input="nearest"
                                                      defaultValue={mainTaskv.end_date}
                                                      placeholder="yyyy-mm-dd"
                                                      readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                    </Input>
                                                  </Col>
                                                  <Col sm={1} className="p-l-0">
                                                    <a href="javascript:void(0)" title={t("rescheduleEndDate")}
                                                     onClick={() => toOpenRescheduleModal(  mainTaskv.start_date,mainTaskv.end_date,"task","EndDate", mainTaskv.id)}>
                                                      <div className="reschedule-div"><i className="fa fa-calendar-o rescheduleImage"></i>
                                                      </div></a>
                                                  </Col> </>
                                                : <Col sm={9} className="p-r-0" >
                                                  <Input
                                                    type="text"
                                                    id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                    min={mainTaskv.start_date}
                                                    data-target-input="nearest"
                                                    defaultValue={mainTaskv.end_date}
                                                    placeholder="yyyy-mm-dd"
                                                    readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                  >
                                                  </Input>
                                                </Col>)
                                              /* For Add Task Update Permissions Ends*/
                                              :
                                              <>
                                              <Col sm={9} className="p-r-0" >
                                                <Input
                                                  type="text"
                                                  id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                  min={new Date().toISOString().split('T')[0]}
                                                  data-target-input="nearest"
                                                  defaultValue={mainTaskv.end_date}
                                                  placeholder="yyyy-mm-dd"
                                                  readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                </Input>
                                              </Col >
                                               {((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('Add Task Updates')))
                                               || (getLoginStaffId ==  mainTaskv.pic_id )|| getStaff == null) && (mainTaskv.accomplished_date === null )?
                                               <></>
                                          //    <Col sm={1} className="p-l-0">
                                          //    <a href="javascript:void(0)" title={t("rescheduleStartDate")} onClick={() => toOpenRescheduleModal("EndDate",mainTaskv.id, mainTaskv.end_date)}>
                                          //      <div className="reschedule-div" ><i className="fa fa-calendar-o rescheduleImage"></i></div></a>
                                          //  </Col>
                                          :""} </>}
                                            
                                          </Row>
                                        </td>
                                      }
                                      <td className="p-1">
                                        <Row>
                                        {(mainTaskv.accomplished_date === null )  && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                              || getLoginStaffId == mainTaskv.pic_id || getStaff == null)? 
                                        (mainTaskv.end_date === null || mainTaskv.start_date === null || mainTaskv.pic_id === 0) ?
                                          <>
                                           <Col sm={9} className="p-r-0" >
                                          <Input
                                              type="text"
                                              id={mainTaskv.id}
                                              min={new Date(mainTaskv.start_date).toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              defaultValue={mainTaskv.accomplished_date}
                                              placeholder="yyyy-mm-dd"
                                              style={{ backgroundColor: "#FFFFFF" }}
                                              readOnly
                                            >
                                            </Input>
                                            </Col>
                                          </> :
                                          /* For Add Task Update Permissions Starts */
                                          (((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')) || getStaff == null)
                                           /* && actual_start_date != null */ ?
                                            <>
                                              <Col sm={11} className="p-r-0" >
                                            <Input
                                                type="date"
                                                placeholder="yyyy-mm-dd"
                                                id={"accomplishedDate||"+mainTaskv.id}
                                                min={new Date(mainTaskv.start_date).toISOString().split('T')[0]}
                                                max={new Date().toISOString().split('T')[0]}
                                                data-target-input="nearest"
                                                defaultValue={mainTaskv.accomplished_date}
                                                onChange={(e) => { toAccomplishTask(mainTaskv.id, mainTaskv.subtitle, e.target.value,"Task",
                                               mainTaskv.hasOwnProperty("subtasks")?mainTaskv.subtasks : "") }}
                                                onKeyDown={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                              </Input>
                                              </Col>
                                            </> : 
                                             <Col sm={9} className="p-r-0" >
                                          <Input
                                              type="text"
                                              placeholder="yyyy-mm-dd"
                                              // id={templates.task_title+"||"+ mainTaskv.subtitle + "||enddate"}
                                              min={new Date().toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              defaultValue={mainTaskv.accomplished_date}
                                              style={{ backgroundColor: "#FFFFFF" }} readOnly
                                            >
                                            </Input></Col>)
                                          /* For Add Task Update Permissions Ends*/
                                          :
                                          <Col sm={9} className="p-r-0" >
                                         <Input
                                            type="text"
                                            // id={templates.task_title+"||"+subtitle+"||enddate"}
                                            min={new Date().toISOString().split('T')[0]}
                                            data-target-input="nearest"
                                            placeholder="yyyy-mm-dd"
                                            defaultValue={mainTaskv.accomplished_date}
                                            style={{ backgroundColor: "#FFFFFF" }} readOnly
                                          >
                                          </Input></Col>
                                        }
                                        </Row>
                                      </td>
                                      <td className="p-1">
                                        <Row>
                                          {
                                           (mainTaskv.accomplished_date === null )  ?
                                            ((mainTaskv.end_date === null && mainTaskv.start_date === null && mainTaskv.pic_id ===0 ) ?
                                              <Col sm={9} className="p-r-0">
                                                <Input className="pic_select" value="-------" readOnly style={{ backgroundColor: "#FFFFFF" }}></Input>
                                              </Col>
                                              :
                                              /* For Add Task Update Permissions Starts */
                                              ((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')
                                              && (getStaffPermission.includes('Edit Others Task')|| getLoginStaffId == mainTaskv.pic_id)) || getStaff == null ?
                                                <>
                                                  <Col sm={9} className="p-r-0">
                                                    {personInCharge.length > 0 ? mainTaskv.pic_id === 0 ? <Input className="pic_select" value={t("Selectpic")} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                      (personInCharge.map((pic) => (
                                                        mainTaskv.pic_id === pic.staff_id
                                                          ? <Input className="pic_select" key={pic.staff_id} value={pic.staff_name} id="PICName" readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                          ""
                                                      ))) : ""}
                                                  </Col>
                                                  <Col sm={1} className="p-l-0">
                                                    <a href="javascript:void(0)" title={t("reassignPIC")} onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"task","PIC", mainTaskv.id, mainTaskv.pic_id)}>
                                                      <div className="reschedule-div"><img src={edit} className="rescheduleImage" />
                                                      </div></a>
                                                  </Col></>
                                                :
                                                <>
                                                <Col sm={9} className="p-r-0">
                                                  {personInCharge.length > 0 ? mainTaskv.pic_id === 0 && getStaff === "Staff" ?
                                                    <Input className="pic_select" value={"--"+t("notAssigned")+"--"} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                    personInCharge.map((pic) => (
                                                      mainTaskv.pic_id === pic.staff_id  
                                                        ? <Input className="pic_select" key={pic.staff_id} value={pic.staff_name} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                        ""
                                                    )) : ""}
                                                </Col>
                                                {/* {(getStaff === "Staff" 
                                              && (getStaffPermission.includes('Edit Others Task')|| getLoginStaffId == mainTaskv.pic_id ||  mainTaskv.pic_id==0)) || getStaff == null ?
                                                <Col sm={1} className="p-l-0">
                                                <a href="javascript:void(0)" title={t("reassignPIC")} onClick={() => toOpenRescheduleModal("PIC", mainTaskv.id, mainTaskv.pic_id)}>
                                                  <div className="reschedule-div"><img src={edit} className="rescheduleImage" />
                                                  </div></a>
                                              </Col>:""} */}
                                              </> 
                                              ))
                                            /* For Add Task Update Permissions Ends*/
                                            :
                                            <Col sm={9} className="p-r-0">
                                              {personInCharge.length > 0 ? personInCharge.map((pic) => (
                                               mainTaskv.pic_id === pic.staff_id
                                                  ? <Input className="pic_select" key={pic.staff_id} value={pic.staff_name} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                  ""
                                              )) : ""}
                                            </Col>
                                            
                                            
                                          }
                                        </Row>
                                      </td>
                                      <td className="p-1">
                                      {/* <span className="f-left"
                                       onClick={() => { toCHatUpdateModal( mainTaskv.id,mainTaskv.subtitle,mainTaskv.pic_id) }}> 
                                        {
                                               ((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('Add Task Updates')))
                                                || (getLoginStaffId ==  mainTaskv.pic_id )|| getStaff == null) ?
                                      <img style={{ width: "40px",marginTop:"8px"}} className="cursor-pointer b-r-3 m-r-5" src={new_chat_icon} title={t("taskCommunication")} tooltip={t("taskCommunication")} alt={t("taskCommunication")}  />:""}</span> */}
                                        {(mainTaskv.end_date === null && mainTaskv.start_date === null && mainTaskv.pic_id === 0) ?
                                          /* For Add Task Update Permissions Starts */
                                          ((getStaff === "Staff" && getStaffPermission.includes('Add Task Updates')) || getStaff == null ?
                                            // <a href="javascript:void(0)" title={t("addTaskData")}
                                            //   onClick={() => toOpenUpdateModal(mainTaskv.id, mainTaskv.subtitle)}>
                                            //   <div className=" b-r-3 f-left"><img src={edit} className="rescheduleImage" />
                                            //   </div></a>
                                            <img  style={{ width: "40px",marginTop:"8px"}}  className="cursor-pointer   b-r-3 f-left p-0" title={t("addTaskData")} 
                                               onClick={() =>  toOpenUpdateModal(mainTaskv.id, mainTaskv.subtitle)} src={edittaskupdate_icon} />
                                               : "")
                                               
                                              
                                          :
                                          // <a href="javascript:void(0)" title={t("viewHistory")}
                                          //   onClick={() => toOpenHistoryOffCanvas(mainTaskv.id, mainTaskv.subtitle)}>
                                          //   <div className="reschedule-div b-r-3 f-left"><img src={reschedule} className="rescheduleImage" />
                                          //   </div></a>
                                             <img  style={{ width: "40px",marginTop:"8px"}}  className="cursor-pointer   b-r-3 f-left p-0" title={t("viewHistory")} 
                                             onClick={() => toOpenHistoryOffCanvas(mainTaskv.id, mainTaskv.subtitle)} src={task_history} />
                                        }
                                                                                   {(mainTaskv.accomplished_date === null ) &&
                                        ((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null) ? 
                                    
                                        // <a href="javascript:void(0)" title={t("subtaskAdd")}
                                        //   onClick={() => {
                                        //     const details = {
                                        //       orderID: orderID,
                                        //       templateID : selectedTemplateId,
                                        //       taskTitle : mainTaskv.title,
                                        //       taskID : mainTaskv.id,
                                        //       startDate : mainTaskv.start_date != null ? mainTaskv.start_date : "",
                                        //       endDate : mainTaskv.end_date != null ? mainTaskv.end_date : "",
                                        //     };
                                        //     toOpenUpdateModal(mainTaskv.id, mainTaskv.subtitle, "Subtask",details)}}>
                                        //   <div className="reschedule-div b-r-3 f-left m-l-10"><i className="icofont icofont-plus-circle font-dark" ></i>
                                        //   </div>
                                        // </a> 
                                        <img  style={{ width: "45px",}} className="cursor-pointer m-t-5 f-left  p-0" title={t("subtaskAdd")} 
                                        onClick={() => {
                                              const details = {
                                                orderID: orderID,
                                                templateID : selectedTemplateId,
                                                taskTitle : mainTaskv.title,
                                                taskID : mainTaskv.id,
                                                startDate : mainTaskv.start_date != null ? mainTaskv.start_date : "",
                                                endDate : mainTaskv.end_date != null ? mainTaskv.end_date : "",
                                              };
                                              toOpenUpdateModal(mainTaskv.id, mainTaskv.subtitle, "Subtask",details)}} src={subTask_icon} />
                                        
                                        : ""
                                        }
                                        {mainTaskv.hasOwnProperty("subtasks")? ""
                                       : <>
                                    

                                       {((getStaff === "Staff" && getStaffPermission.includes('delete_task')) || getStaff == null)?(mainTaskv.accomplished_date === null )? mainTaskv.hasOwnProperty("subtasks")? 
                                          // <a href="javascript:void(0)" title={t("taskDelete")}
                                          //     onClick={() => deleteMaintaskAlert() }>
                                          //     <div className="reschedule-div m-l-10 b-r-3 f-left m-l-10"><i className="icofont icofont-minus-circle font-dark"></i>
                                          //     </div>
                                          //   </a>
                                          <img  style={{ width: "45px"}}  className="cursor-pointer  b-r-3 f-left m-t-5 p-0" title={t("deleteTask")} 
                                          onClick={() =>deleteMaintaskAlert() } src={delete_icon} />
                                          :
                                          <img  style={{ width: "45px"}} className="cursor-pointer  b-r-3 f-left m-t-5 p-0" title={t("deleteTask")} 
                                          onClick={() =>deleteMaintask(orderID,selectedTemplateId,mainTaskv.title,mainTaskv.subtitle,mainTaskv.id) } src={delete_icon} />
                                            //  <a href="javascript:void(0)" title={t("taskDelete")}
                                            //   onClick={() => deleteMaintask(orderID,selectedTemplateId,mainTaskv.title,mainTaskv.subtitle,mainTaskv.id) }>
                                            //   <div className="reschedule-div b-r-3 f-left m-l-10"><i className="icofont icofont-minus-circle font-dark"></i>
                                            //   </div>
                                            // </a>
                                         
                                        :"":""}</>}
                                        </td>

                                        
                                    </tr>
                       
                                    {/* Sub Task Start*/}

                                    {mainTaskv.hasOwnProperty("subtasks") && mainTaskv.subtasks.map((subtasks,k) =>(
                                    <>  
                                       <tr key={k} className="taskUpdate-tr-border">
                                        <td className="taskUpdate-table-sideHeader p-1"><p className="m-l-20 font-primary">
                                        <img className="img-40" src={Subtasklist_icon} />{subtasks.subtasktitle} 
                                        {/* <span className="m-l-5 f-right" onClick={() => { toCHatUpdateModal( subtasks.id, subtasks.subtasktitle, subtasks.pic_id) }}>
                                        {
                                          ((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('add_sub_task')))
                                                || (getLoginStaffId ==  subtasks.pic_id )|| getStaff == null) ?
                                           <img className="cursor-pointer" src={new_chat_icon} title={t("subtaskCommunication")} 
                                            tooltip={t("subtaskCommunication")} alt={t("subtaskCommunication")}/>:""}</span> */}
                                            </p>
                                          </td>
                                        {subtasks.start_date === null && subtasks.end_date === null && subtasks.pic_id ===0  
                                          &&((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null) ?
                                          <td className="p-1">
                                            <Row>
                                              <Col sm={9} className="p-r-0">
                                                <Input
                                                  type="text" placeholder="yyyy-mm-dd"
                                                  id={mainTaskv.title + "||" + subtitle + "||startdate"}
                                                  min={new Date().toISOString().split('T')[0]}
                                                  data-target-input="nearest"
                                                  defaultValue={subtasks.start_date}
                                                  readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                >
                                                </Input>
                                              </Col>
                                            </Row>
                                          </td>
                                          :
                                          <td className="p-1">
                                            <Row>
                                              {subtasks.accomplished_date === null /* && subtasks.actual_start_date === null */ 
                                              && (subtasks.start_date === null || subtasks.start_date !== null) 
                                              && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                                || getLoginStaffId == subtasks.pic_id || getStaff == null) ?
                                                /* For Add Task Update Permissions Starts*/
                                                ((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null ?
                                                  <>
                                                    <Col sm={9} className="p-r-0">
                                                      <Input
                                                        type="text" placeholder="yyyy-mm-dd"
                                                        id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        data-target-input="nearest"
                                                        value={subtasks.start_date}
                                                        readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                      >
                                                      </Input>
                                                    </Col>
                                                    <Col sm={1} className="p-l-0">
                                                      <a href="javascript:void(0)" title={t("rescheduleStartDate")} 
                                                        onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"sub_task","StartDate", subtasks.id, subtasks.start_date,"Subtask")}>
                                                        <div className="reschedule-div" ><i className="fa fa-calendar-o rescheduleImage"></i></div></a>
                                                    </Col></> :
                                                  <Col sm={9} className="p-r-0">
                                                    <Input
                                                      type="text" placeholder="yyyy-mm-dd"
                                                      id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                      min={new Date().toISOString().split('T')[0]}
                                                      data-target-input="nearest"
                                                      value={subtasks.start_date}
                                                      readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                    </Input>
                                                  </Col>)
                                                /* For Add Task Update Permissions Ends*/
                                                :
                                                <Col sm={9} className="p-r-0">
                                                  <Input
                                                    type="text" placeholder="yyyy-mm-dd"
                                                    id={mainTaskv.title + "||" + mainTaskv.subtitle + "||startdate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    value={subtasks.start_date}
                                                    readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                  >
                                                  </Input>
                                                </Col>
                                              }
                                            </Row>
                                          </td>
                                        }
                                        {/* Actual Start Date Starts */}
                                        {/* <td>
                                          <Col sm={11}>
                                            <Input
                                              type="date"
                                              min={new Date().toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              readOnly style={{ backgroundColor: "#FFFFFF" }}
                                              defaultValue={subtasks.actual_start_date}
                                             >
                                            </Input>
                                          </Col>
                                        </td> */}
                                        {/* Actual Start Date Ends */}
                                        {subtasks.start_date === null && subtasks.end_date === null && subtasks.pic_id ===0 ?
                                          <td className="p-1">
                                            <Row>
                                              <Col sm={9} className="p-r-0" >
                                                <Input
                                                  type="text" placeholder="yyyy-mm-dd"
                                                  id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                  min={new Date().toISOString().split('T')[0]}
                                                  data-target-input="nearest"
                                                  defaultValue={subtasks.end_date}
                                                  readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                  >
                                                </Input>
                                              </Col>
                                            </Row>
                                          </td>
                                          :
                                          <td className="p-1">
                                            <Row>
                                              {subtasks.accomplished_date === null && (subtasks.end_date === null || subtasks.end_date !== null) 
                                              && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                                || getLoginStaffId == subtasks.pic_id || getStaff == null)?
                                                /* For Add Task Update Permissions Starts*/
                                                ((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null ?
                                                  <>
                                                    <Col sm={9} className="p-r-0">
                                                      <Input
                                                        type="text" placeholder="yyyy-mm-dd"
                                                        id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                        min={new Date().toISOString().split('T')[0]}
                                                        data-target-input="nearest"
                                                        value={subtasks.end_date}
                                                        readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                      >
                                                      </Input>
                                                    </Col>
                                                    <Col sm={1} className="p-l-0">
                                                      <a href="javascript:void(0)" title={t("rescheduleEndDate")} 
                                                        onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"sub_task","EndDate", subtasks.id, subtasks.end_date,"Subtask")}>
                                                        <div className="reschedule-div"><i className="fa fa-calendar-o rescheduleImage"></i>
                                                        </div></a>
                                                    </Col> </>
                                                  : <Col sm={9} className="p-r-0" >
                                                    <Input
                                                      type="text" placeholder="yyyy-mm-dd"
                                                      id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                      min={new Date().toISOString().split('T')[0]}
                                                      data-target-input="nearest"
                                                      value={subtasks.end_date}
                                                      readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                    >
                                                    </Input>
                                                  </Col>)
                                                /* For Add Task Update Permissions Ends*/
                                                :
                                                <Col sm={9} className="p-r-0" >
                                                  <Input
                                                    type="text" placeholder="yyyy-mm-dd"
                                                    id={mainTaskv.title + "||" + mainTaskv.subtitle + "||enddate"}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    data-target-input="nearest"
                                                    value={subtasks.end_date}
                                                    readOnly style={{ backgroundColor: "#FFFFFF" }}
                                                  >
                                                  </Input>
                                                </Col>
                                              }
                                            </Row>
                                          </td>
                                        }
                                        <td className="p-1">
                                          <Row>
                                        {subtasks.accomplished_date === null  && ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                              || getLoginStaffId == subtasks.pic_id || getStaff == null)? 
                                        (subtasks.end_date === null || subtasks.start_date === null || subtasks.pic_id === 0) ?
                                          <>
                                           <Col sm={9} className="p-r-0" >
                                            <Input
                                              type="date"
                                              id={subtasks.id}
                                              min={new Date(subtasks.start_date).toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              defaultValue={subtasks.accomplished_date}
                                              style={{ backgroundColor: "#FFFFFF" }}
                                              readOnly
                                            >
                                            </Input>
                                            </Col>
                                          </> :
                                          /* For Add Task Update Permissions Starts */
                                          (((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null)
                                            /* && subtasks.actual_start_date != null */ ?
                                            <>
                                             <Col sm={11} className="p-r-0" >
                                              <Input
                                                type="date"
                                                id={"accomplishedDate||"+subtasks.id}
                                                min={new Date(subtasks.start_date).toISOString().split('T')[0]}
                                                max={new Date().toISOString().split('T')[0]}
                                                data-target-input="nearest"
                                                defaultValue={subtasks.accomplished_date}
                                                onChange={(e) => { toAccomplishTask(subtasks.id, subtasks.subtasktitle, e.target.value,"Subtask","") }}
                                                onKeyDown={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                              </Input>
                                              </Col>
                                            </> : 
                                            <Col sm={9} className="p-r-0" >
                                            <Input
                                              type="date"
                                              // id={templates.task_title+"||"+subtitle+"||enddate"}
                                              min={new Date().toISOString().split('T')[0]}
                                              data-target-input="nearest"
                                              defaultValue={subtasks.accomplished_date}
                                              style={{ backgroundColor: "#FFFFFF" }} readOnly
                                            >
                                            </Input></Col>)
                                          /* For Add Task Update Permissions Ends*/
                                          :
                                          <Col sm={9} className="p-r-0" >
                                          <Input
                                            type="date"
                                            // id={templates.task_title+"||"+subtitle+"||enddate"}
                                            min={new Date().toISOString().split('T')[0]}
                                            data-target-input="nearest"
                                            defaultValue={subtasks.accomplished_date}
                                            style={{ backgroundColor: "#FFFFFF" }} readOnly
                                          >
                                          </Input>
                                          </Col>
                                        }
                                        </Row>
                                      </td>
                                      <td className="p-1">
                                        <Row>
                                          {subtasks.accomplished_date === null  ?
                                            ((subtasks.end_date === null && subtasks.start_date === null && subtasks.pic_id ===0 ) ?
                                              <Col sm={9} className="p-r-0">
                                                <Input className="pic_select" value="-------" readOnly style={{ backgroundColor: "#FFFFFF" }}></Input>
                                              </Col>
                                              :
                                              /* For Add Task Update Permissions Starts */
                                              ((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')
                                              && (getStaffPermission.includes('Edit Others Task')|| getLoginStaffId == subtasks.pic_id)) || getStaff == null ?
                                                <>
                                                  <Col sm={9} className="p-r-0">
                                                    {personInCharge.length > 0 ? subtasks.pic_id === 0 ? <Input className="pic_select" value={t("Selectpic")} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                      (personInCharge.map((pic) => (
                                                        subtasks.pic_id === pic.staff_id
                                                          ? <Input className="pic_select" key={pic.staff_id} value={pic.staff_name} id="PICName" readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                          ""
                                                      ))) : ""}
                                                  </Col>
                                                  <Col sm={1} className="p-l-0">
                                                    <a href="javascript:void(0)" title={t("reassignPIC")} 
                                                      onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"sub_task","PIC", subtasks.id, subtasks.pic_id,"Subtask")}>
                                                      <div className="reschedule-div"><img src={edit} className="rescheduleImage" />
                                                      </div></a>
                                                  </Col></>
                                                :<>
                                                <Col sm={9} className="p-r-0">
                                                  {personInCharge.length > 0 ? subtasks.pic_id === 0 && getStaff === "Staff" ?
                                                    <Input className="pic_select" value={"--"+t("notAssigned")+"--"} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                    personInCharge.map((pic) => (
                                                      subtasks.pic_id === pic.staff_id  
                                                        ? <Input className="pic_select"key={pic.staff_id} value={pic.staff_name} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                        ""
                                                    )) : ""}
                                                </Col>
                                                 {(getStaff === "Staff" && getStaffPermission.includes('add_sub_task')
                                              && (getStaffPermission.includes('Edit Others Task')|| getLoginStaffId == subtasks.pic_id)) || getStaff == null ?
                                                   <Col sm={1} className="p-l-0">
                                                   <a href="javascript:void(0)" title={t("reassignPIC")} onClick={() => toOpenRescheduleModal(mainTaskv.start_date,mainTaskv.end_date,"sub_task","PIC", mainTaskv.id, mainTaskv.pic_id)}>
                                                     <div className="reschedule-div"><img src={edit} className="rescheduleImage" />
                                                     </div></a>
                                                 </Col>:""}
                                                 </>  
                                              ))
                                            /* For Add Task Update Permissions Ends*/
                                            :
                                            <Col sm={9} className="p-r-0">
                                              {personInCharge.length > 0 ? personInCharge.map((pic) => (
                                                subtasks.pic_id === pic.staff_id
                                                  ? <Input className="pic_select" key={pic.staff_id} value={pic.staff_name} readOnly style={{ backgroundColor: "#FFFFFF" }}></Input> :
                                                  ""
                                              )) : ""}
                                            </Col>
                                          }
                                        </Row>
                                      </td>
                                      <td className="p-1">
                                      {/* <span className=" f-left" onClick={() => { toCHatUpdateModal( subtasks.id, subtasks.subtasktitle, subtasks.pic_id) }}>
                                        {
                                          ((getStaff === "Staff" && (getStaffPermission.includes('Edit Others Task') ||  getStaffPermission.includes('add_sub_task')))
                                                || (getLoginStaffId ==  subtasks.pic_id )|| getStaff == null) ?
                                           <img style={{ width: "40px",marginTop:"8px"}} className="cursor-pointer m-r-5" src={new_chat_icon} title={t("subtaskCommunication")} 
                                            tooltip={t("subtaskCommunication")} alt={t("subtaskCommunication")}/>:""}</span> */}
                                        {(subtasks.end_date === null && subtasks.start_date === null && subtasks.pic_id === 0) ?
                                          /* For Add Task Update Permissions Starts */
                                          ((getStaff === "Staff" && getStaffPermission.includes('add_sub_task')) || getStaff == null ?
                                            // <a href="javascript:void(0)" title={t("addTaskData")}
                                            //   onClick={() => {
                                            //     const details = {
                                            //       startDate : mainTaskv.start_date != null ? mainTaskv.start_date : "",
                                            //       endDate : mainTaskv.end_date != null ? mainTaskv.end_date : "",
                                            //     };
                                            //     toOpenUpdateModal(subtasks.id, subtasks.subtasktitle,"SubtaskAdd",details)}}>
                                            //   <div className="reschedule-div b-r-3 f-left"><img src={edit} className="rescheduleImage" />
                                            //   </div></a> 
                                              <img  style={{ width: "40px",marginTop:"8px"}}  className="cursor-pointer   b-r-3 f-left p-0" title={t("addTaskData")} 
                                              onClick={() => {
                                                    const details = {
                                                      startDate : mainTaskv.start_date != null ? mainTaskv.start_date : "",
                                                      endDate : mainTaskv.end_date != null ? mainTaskv.end_date : "",
                                                    };
                                                    toOpenUpdateModal(subtasks.id, subtasks.subtasktitle,"SubtaskAdd",details)}}
                                              src={edittaskupdate_icon} />
                                              : "")
                                          :
                                          // <a href="javascript:void(0)" title={t("viewHistory")}
                                          //   onClick={() => toOpenHistoryOffCanvas(subtasks.id, subtasks.subtasktitle)}>
                                          //   <div className="reschedule-div b-r-3 f-left"><img src={reschedule} className="rescheduleImage" />
                                          //   </div></a>
                                            <img  style={{ width: "40px",marginTop:"8px"}}  className="cursor-pointer  b-r-3 f-left p-0" title={t("addTaskData")} 
                                            onClick={() => toOpenHistoryOffCanvas(subtasks.id, subtasks.subtasktitle)} src={task_history} />
                                        }
                                        {/* {(subtasks.end_date === null && subtasks.start_date === null && subtasks.pic_id ===0 ) && */}
                                        {(subtasks.accomplished_date === null ) &&
                                        ((getStaff === "Staff" && getStaffPermission.includes('delete_sub_task')) || getStaff == null) ?
                                          // <a href="javascript:void(0)" title={t("subtaskDelete")}
                                          //   onClick={() => deleteSubtask(subtasks.id,subtasks.subtasktitle) }>
                                          //   <div className="reschedule-div b-r-3 f-left m-l-10"><i className="icofont icofont-minus-circle font-dark"></i>
                                          //   </div>
                                          // </a> 
                                          <img style={{ width: "30px",marginTop:"12px"}}  className="cursor-pointer  b-r-3 f-left m-l-5  p-0" title={t("deleteTask")} 
                                          onClick={() =>deleteSubtask(subtasks.id,subtasks.subtasktitle) } src={Subtask_delete} />
                                          : ""
                                        }
                                      
                                        {/* {(subtasks.actual_start_date == null && subtasks.accomplished_date == null && 
                                        subtasks.end_date != null && subtasks.start_date != null && subtasks.pic_id !=0) &&
                                        ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                        || getLoginStaffId == subtasks.pic_id || getStaff == null) ?
                                          <a href="javascript:void(0)" title={t("actualStartDate")}
                                            onClick={() => actualStartDate(subtasks.id, subtasks.subtasktitle,subtasks.start_date) }
                                            >
                                            <div className="reschedule-div b-r-3 f-left m-l-10"><i className="icofont icofont-ui-play font-dark"></i>
                                            </div>
                                          </a> 
                                          :"" 
                                        }
                                        {(subtasks.actual_start_date != null && subtasks.accomplished_date == null) &&
                                          ((getStaff === "Staff" && getStaffPermission.includes('Edit Others Task')) 
                                          || getLoginStaffId == subtasks.pic_id || getStaff == null) ?
                                          <a href="javascript:void(0)" title={t("accomplishedDate")}
                                            onClick={() => toAccomplishTask(subtasks.id, subtasks.subtasktitle,"","Subtask","") }
                                          >
                                          <div className="reschedule-div b-r-3 f-left m-l-10"><i className="icofont icofont-ui-play-stop font-dark"></i>
                                          </div>
                                          </a> 
                                          :"" 
                                        } */}
                                      </td>
                                        </tr>
                                    </>

                                    ))}
                                        {/*Sub Task End */}
                                    </>
                                
                              ))}
                              </tbody>
                              {/* Sub Task Start*/}
                             
                              {/*Sub Task End */}
                            </Table>
                            </div>
                          </Row>
                        </CardBody>
                      </Collapse>
                      {/*Start End */}
                     
                   
                    </Card>

                  )
                })
                : ""
            }
            <NewRescheduleModal  modal={modal} toggle={toggleModal} rescheduleType={rescheduleType} templateData={props.setTemplateData}
              personInCharge={personInCharge} taskID={taskId} orderId={props.orderId} showDetails={props.details} value={value} 
              isSubtask={taskType} setIsSubtask={setTaskType} parentDate={parentDate} setParentDate={setParentDate} startdate={startDateValue}
              setValue={setValue} titleType={titleType} endDateValue={endDateValue}
              inquiryDate={props.theStyle.inquiry_date} subtaskDetail={isSubtaskDetails} create_dates={props.create_dates}/>
            <NewTaskUpdateModal modal={modalUpdate} toggle={toggleModalUpdate} taskID={taskId} orderId={props.orderId}
              templateData={props.setTemplateData} personInCharge={personInCharge} taskSubtitle={subtitle}
              setStyle={props.setStyle} ForReload={props.ForReload} taskInfo={props.taskInfo} isSubtask={isSubtask} 
              setIsSubtask={setIsSubtask} subtaskDetails={isSubtaskDetails} setIsSubtaskDetails={setIsSubtaskDetails} 
              inquiryDate={props.theStyle.inquiry_date}  inquiry_dates={props.inquiry_dates} create_dates={props.create_dates}/>
            <NewTaskHistoryOffCanvas modal={offCanvasHistory} toggle={toggleOffcanvasHistory} taskID={taskId} orderId={props.orderId}
              taskSubtitle={subtitle} historyStatus={status} historyData={history} personInCharge={personInCharge} />
            {/* <Chatoffcanvas modal={show} toggle={toggleCanvas} pic={chatpic} subtitle={subtitle} pageId={taskId} 
            recivorrname={chatpicName} chatMessages={chatMessages}  setChatMessages={setChatMessages} serverURL={serverURL} styleDetails={props.theStyle}/> */}
            <EditTaskupdateModal modal={addTaskinfoModal} toggle={toggleAddTaskinfo} tasks={templateload} setSubTaskEmpty={setIsSubTaskEmpty} selectedTemplateId={selectedTemplateId}
           workspaceId={workspaceID} companyId={companyID}  orderID={orderID} setTemplates={setTemplateNames}  refreshTemplates={setIsTemplateChanged} taskTemplate={templateTasks}personInCharge={personInCharge}
           templateNames={templateNames} basicInfo={props.basicInfo} inquiryDate={props.theStyle.inquiry_date}  create_dates={props.create_dates}></EditTaskupdateModal>
           <DeleteMainTask modal={modalMainTaskDelete} toggle={toggleMainTaskDelete} taskname={mainTaskName} tasktitle={mainTaskTitle} orderID={orderID} getTaskID={getTaskID} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NewTaskAccordion;
/*******************Code By : Rithanesh ***********Updated by :Praveen***********/
/*date format not showing in safari. so, input type changed "date to text"  in readonly. Updated by saravanan 20-06-2023*/