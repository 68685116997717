import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import { Card } from 'reactstrap';
import CutIcon from "../../../assets/images/dms/icons/cutIcon.svg";
import Moment from 'moment';
import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const cutKnobChart = ({cutActualTargetValue,cutTodayUpdatedValue,cutEndDate,cutPerDay,cutAvgPerDay,cutEstDate,cutActualDate,cutCompleted}) => {
let cutEstimatedRatePercentage = Math.round((cutTodayUpdatedValue/cutActualTargetValue)*100);
let currentDate = Moment().format('DD MMM YYYY');
let endDate=Moment(cutEndDate).format('DD MMM YYYY');


   /******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
    const knobChartColor = () => {
        // if(cutActualTargetValue<=cutAvgPerDay){
        //     return '#00B38E'; 
        // } else{
            
        //     return '#FF3169';
        // }
        if((new Date(cutEndDate).getTime() >= new Date(cutEstDate).getTime())){
            return '#00B38E'; 
        }
        // else if((cutActualTargetValue<=cutAvgPerDay)){
        //     return '#00B38E'; 
        // }
        else{
            return '#FF3169';
        }
        
        // if(  (cutEstimatedRatePercentage >= 0) && (cutEstimatedRatePercentage <= 40)){
        //     return '#FF3169';
        // }
        // if( (cutEstimatedRatePercentage > 40) && (cutEstimatedRatePercentage <= 70)){
        //     return '#E58F1F';
        // }
        // if( (cutEstimatedRatePercentage > 70) && (cutEstimatedRatePercentage <= 100) || (cutEstimatedRatePercentage > 100)){
        //     return '#00B38E';
        // }
    }
    const { t, i18n } = useTranslation();
    useEffect(() => {
        var offsetArc = Knob({
            value: cutAvgPerDay?cutAvgPerDay:0, 
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: cutAvgPerDay
          });
          const cutlist = document.getElementById('cutknob');
          if (cutlist.hasChildNodes()) {
            cutlist.removeChild(cutlist.children[0]);
          }
           document.getElementById('cutknob').appendChild(offsetArc);
    },[cutAvgPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align"  >
                <div className="knob text-center m-t-5" id="cutknob">
                </div>
                <div className=' text-muted f-12  mb-0' >{t("currentAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                { cutCompleted== 0 ?<div className=' f-w-600 '  >-</div>:cutEstDate? <div className=' f-w-600 '  >{(PHPtoJSFormatConversion.format(new Date(cutEstDate)))} </div>:
                <div className='f-w-600 '  >{(PHPtoJSFormatConversion.format(new Date(cutActualDate)))} </div>}
                {cutEstDate=="" ? <div className='text-muted f-12 '  >{t("actDateCompletion")}</div>:
                <div className='text-muted f-12 '>{t("estDateCompletion")}</div>}
               
                {/* <div style={{ backgroundColor: "#13D09A" }} className='taskCardBorder'>
                    <img src={CutIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Cutting</span>
                </div> */}
            </Card>
        </>
    );
}
export default cutKnobChart;