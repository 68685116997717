import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Label,
  Card,
  CardBody,
  Col,
  Row,
  Input,InputGroup,InputGroupText,
  FormGroup,
  Table,
  Button
} from "reactstrap";
import { H6, Btn } from "../../../AbstractElements";
import OrderHeaders from "../CreateOrders/OrderHeaders";
/****************************************************************************************************/
import Swal from "sweetalert2";
import axios from "axios";
import { ServerUrl, maxUploadFileSize } from "../../../Constant";
import { useSearchParams } from "react-router-dom";
import { encode, decode, apiencrypt, apidecrypt, truncate, PHPtoJSFormatConversion } from "../../../helper";
import {
  getLoginCompanyId,
  getLoginUserId,
  getLoginStaffId,
  getWorkspaceId,
  getStaff,
  getStaffPermission,
  getWorkspaceType
} from "../../../Constant/LoginConstant";    

import { useTranslation } from "react-i18next";
import Files from "react-files";
import labelSampleIcon from "../../../assets/images/dms/icons/labelSampleIcon.svg";
import pdfDocIcon from "../../../assets/images/dms/icons/pdfDocIcon.svg";
import excelDocIconIcon from "../../../assets/images/dms/icons/excelDocIcon.svg";
import docIcon from "../../../assets/images/dms/icons/docIcon.svg";
import uploadDocIcon from "../../../assets/images/dms/icons/uploadDoc.svg";
import deleteDocIcon from "../../../assets/images/dms/icons/deleteDocIcon.svg";

import AddNewDropdownModal from "../CreateOrders/AddNewDropdownModal";
import VendorListModal from "../CreateOrders/ViewVendorListModal";
import AddNewUnitModal from "../CreateOrders/AddUnitsModal"
// import ViewVendorModal from "./ViewVendorModal";

import ScrollBar from 'react-perfect-scrollbar';
import Add_icon from '../../../assets/images/dms/icons/Main Task Add_icon.svg'
import DeleteRowIcon from '../../../assets/images/dms/icons/deleteRowIcon.svg'
import AddMaterialsLabelsImageModal from '../CreateOrders/AddMaterialsLabelsImageModal';

import Moment from 'moment';

/************************************************************************************************/

const EditMaterialsLabels = (props) => {
  const { t } = useTranslation();
  /********************* [INPUT PARAMS for API Calls] (To add Order contacts)*******************/
  const workspace_id = getWorkspaceId;
  const company_id = getLoginCompanyId;
  const user_id = getLoginUserId;
  const staff_id = getLoginStaffId;
  var getInputParams = {};
      getInputParams["company_id"] = getLoginCompanyId;
      getInputParams["workspace_id"] = getWorkspaceId;

  const inputParamsUser = {};
        inputParamsUser['company_id'] = getLoginCompanyId;
        inputParamsUser['workspace_id'] = getWorkspaceId;
        inputParamsUser['user_id'] = getLoginUserId;

  const [showAddNew, setShowAddNew] = useState(false);
  const toggleAddNew = () => setShowAddNew(!showAddNew);

  const [viewVendorModal, setViewVendorModal] = useState(false);
  const toggleViewVendor = () => setViewVendorModal(!viewVendorModal);

  const [ViewVendorListModal, setViewVendorListModal] = useState(false);
  const toggleViewVendorListModal = () => setViewVendorListModal(!ViewVendorListModal);

  const [showUnitAddNew, setShowUnitAddNew] = useState(false);
  const toggleUnitAddNew = () => setShowUnitAddNew(!showUnitAddNew);

  const [searchParams, setSearchParams] = useSearchParams();
  const [referenceId, setReferenceId] = useState(Date.now());
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));

  const [vendorDetails, SetvendorDetails] = useState("");
  const [VenCat_id, setVenCat_id] = useState("");
  const [vendor_idDetails, Setvendor_idDetails] = useState(0);

  const [awsUrl, setAwsUrl] = useState();
  const [poId, setPoId] = useState("");

  const [ vendorList, setVendorList ] = useState([]);

  const [ printType, setPrintType ] = useState("");
  const [ printSize, setPrintSize ] = useState("");
  const [ noOfColors, setNoOfColors ] = useState("");
  
  const [ printArtworkVendor, setPrintArtworkVendor ] = useState("");
  const [ printArtworkImageName, setPrintArtworkImageName ] = useState("");
  const [ printArtworkImageList, setPrintArtworkImageList ] = useState([]);
  
  const [mainLabel, setMainLabel] = useState("");
  const [mainLabelVendor, setMainLabelVendor] = useState("");
  const [mainLabelImageName, setMainLabelImageName ] = useState("");
  const [mainLabelImageList, setMainLabelImageList ] = useState([]);

  const [washCareLabel, setWashCareLabel] = useState("");
  const [washCareVendor, setWashCareVendor] = useState("");
  const [washCareImageName, setWashCareImageName ] = useState("");
  const [washCareImageList, setWashCareImageList ] = useState([]);

  const [hangtag, setHangtag] = useState("");
  const [hangtagVendor, setHangtagVendor] = useState("");
  const [hangtagImageName, setHangtagImageName ] = useState("");
  const [hangtagImageList, setHangtagImageList ] = useState([]);

  const [barcodeStickers, setBarcodeStickers] = useState("");
  const [barcodeStickersVendor, setBarcodeStickersVendor] = useState("");
  const [barcodeStickersImageName, setBarcodeStickersImageName ] = useState("");
  const [barcodeStickersImageList, setBarcodeStickersImageList ] = useState([]);

  const [polybagSizeThickness, setPolybagSizeThickness] = useState("");
  const [polybagMaterial, setPolybagMaterial] = useState("");
  const [polybagImageName, setPolybagImageName] = useState("");
  const [polybagImageList, setPolybagImageList ] = useState([]);

  const [cartonVendor, setCartonVendor] = useState("");
  const [cartonImageName, setCartonImageName] = useState("");
  const [cartonImageList, setCartonImageList ] = useState([]);

  const [measurementSheetPdfName, setMeasurementSheetPdfName] = useState("");
  const [measurementSheetPdfList, setMeasurementSheetPdfList ] = useState([]);
  
  const [techPackPdfName, setTechPackPdfName] = useState("");
  const [techPackPdfList, setTechPackPdfList ] = useState([]);

  const [printDetailsPolybag, setPrintDetailsPolybag] = useState("");
  const [polybagVendor, setPolybagVendor] = useState("");
  const [cartonBoxDimension, setCartonBoxDimension] = useState("");
  const [cartonColors, setCartonColors] = useState("");
  const [cartonEdgeFinish, setCartonEdgeFinish] = useState("");
  const [cartonMarkDetails, setCartonMarkDetails] = useState("");
  const [cartonMakeUp, setCartonMakeUp] = useState("");
  const [airFreight, setAirFreight] = useState("");
  const [filmsCD, setFilmsCD] = useState("");
  const [pictureCard, setPictureCard] = useState("");
  const [innerCardBoard, setInnerCardBoard] = useState("");
  const [shippingSize, setShippingSize] = useState("");
  const [noOfPly, setNoOfPly] = useState("");  

  const [trimsInformationTextArea, setTrimsInformationTextArea] = useState("");
  const [masterType, setMasterType] = useState();

  const [PrintartVendorId, setPrintartVendorId] = useState(0);
  const [mainLabelVendorId, setMainLabelVendorId] = useState(0);
  const [washCareVendorId, setWashCareVendorId] = useState(0);
  const [hangTagVendorId, setHangTagVendorId] = useState(0);
  const [barCodeVendorId, setBarCodeVendorId] = useState(0);
  const [polyBagVendorId, setPolyBagVendorId] = useState(0);
  const [cartonVendorId, setCartonVendorId] = useState(0);

  /****------- Image Upload API Call ---------- ****/
  const uploadImageApiCall = (imageType, file) => {
    axios.post(
      ServerUrl + "/add-order-materials-label-media",
      {
        media_type: imageType,
        order_id:orderId > 0 ? orderId : 1,
        file: file[0],
        company_id: getLoginCompanyId,
        workspace_id: getWorkspaceId,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
    .then((response) => {
      if (response.data.status_code == 200) {

        getOrderMaterialsLabelApiCall();
        setNewUploadedImages(response.data.files.length);

        if (imageType == "PrintArtwork") {
          var printArtworkData = response.data.files;
          setPrintArtworkImageList(printArtworkData.files);          
          setAwsUrl(printArtworkData.serverURL);
          {
            printArtworkData.files.length >0 ?
            (printArtworkData.files).map((mapData)=>{setPrintArtworkImageName(mapData.orginalfilename);})
            :
            setPrintArtworkImageName(()=>"");
          }
        } else if (imageType == "MainLabel") {
          let mainLabelData = response.data.files;
          setMainLabelImageList(mainLabelData.files);
          setAwsUrl(mainLabelData.serverURL);
          {
            mainLabelData.files.length >0 ?
            (mainLabelData.files).map((mapData)=>{setMainLabelImageName(mapData.orginalfilename);})
            :
            setMainLabelImageName(()=>"");
          }
        } else if (imageType == "WashCareLabel") {
          let washCareData = response.data.files;
          setWashCareImageList(washCareData.files);
          setAwsUrl(washCareData.serverURL);
          {
            washCareData.files.length >0 ?
            (washCareData.files).map((mapData)=>{setWashCareImageName(mapData.orginalfilename);})
            :
            setWashCareImageName(()=>"");
          }
        } else if (imageType == "Hangtag") {
          var hangtagData = response.data.files;
          setHangtagImageList(hangtagData.files);
          setAwsUrl(hangtagData.serverURL);
          {
            hangtagData.files.length >0 ?
            (hangtagData.files).map((mapData)=>{setHangtagImageName(mapData.orginalfilename);})
            :
            setHangtagImageName(()=>"");
          }
        } else if (imageType == "BarcodeStickers") {
          let barcodeStickersData = response.data.files;
          setBarcodeStickersImageList(barcodeStickersData.files);
          setAwsUrl(barcodeStickersData.serverURL);
          {
            barcodeStickersData.files.length >0 ?
            (barcodeStickersData.files).map((mapData)=>{setBarcodeStickersImageName(mapData.orginalfilename);})
            :
            setBarcodeStickersImageName(()=>"");
          }
        } else if (imageType == "Polybag") {
          let polybagData = response.data.files;
          setPolybagImageList(polybagData.files);
          setAwsUrl(polybagData.serverURL);
          {
            polybagData.files.length >0 ?
            (polybagData.files).map((mapData)=>{setPolybagImageName(mapData.orginalfilename);})
            :
            setPolybagImageName(()=>"");
          }
        } else if (imageType == "Carton") {
          let cartonData = response.data.files;
          setCartonImageList(cartonData.files);
          setAwsUrl(cartonData.serverURL);
          {
            cartonData.files.length >0 ?
            (cartonData.files).map((mapData)=>{setCartonImageName(mapData.orginalfilename);})
            :
            setCartonImageName(()=>"");
          }
        } else if (imageType == "MeasurementSheet"){
          let measurementSheetData = response.data.files;
          setMeasurementSheetPdfList(measurementSheetData.files);
          setAwsUrl(measurementSheetData.serverURL);
          {
            measurementSheetData.files.length >0 ?
            (measurementSheetData.files).map((mapData)=>{
              setMeasurementSheetPdfName(mapData.orginalfilename);})
            :
            setMeasurementSheetPdfName(()=>"");
          }
        } else if (imageType == "TechPack"){
          let techPackData = response.data.files;
          setTechPackPdfList(techPackData.files);
          setAwsUrl(techPackData.serverURL);
          {
            techPackData.files.length >0 ?
            (techPackData.files).map((mapData)=>{
              setTechPackPdfName(mapData.orginalfilename);})
            :
            setTechPackPdfName(()=>"");
          }
        }
      }
    });
  };
  
  /****------- Image Upload Validation ---------- ****/
  const uploadImageValidation = ( type, files ) => {
    if( (type != "MeasurementSheet") && (type != "TechPack") ){
      files.map((fileData) => {
        if(
            fileData.extension == "jpeg" ||
            fileData.extension == "jpg" ||
            fileData.extension == "png"
        ){
          const getFileSize = Math.round(fileData.size / 1024);
          if (getFileSize > maxUploadFileSize) {
            Swal.fire({
              title: t("sizeExceededTitleAlert"),
              text: t("uploadFileTalidationText", {
                fileSize: maxUploadFileSize / 1024,
              }),
              icon: "warning",
              button: t("okLabel"),
              timer: 2500,
            });
          } else {
            uploadImageApiCall(type, files);
          }
        } else {
          Swal.fire({
            title: t("wrongFileFormat"),
            text: t("validFileFormatsImages"),
            icon: "warning",
            button: t("okLabel"),
            timer: 2500,
          });
        }
      });
    } else{
      files.map((fileData) => {
        if ( 
            fileData.extension == "jpeg" ||
            fileData.extension == "jpg" ||
            fileData.extension == "png" ||
            fileData.extension == "pdf" ||
            fileData.extension == "xls" ||
            fileData.extension == "xlsx" 
          ) {
          const getFileSize = Math.round(fileData.size / 1024);
          if (getFileSize > maxUploadFileSize) {
            Swal.fire({
              title: t("sizeExceededTitleAlert"),
              text: t("uploadFileTalidationText", {
                fileSize: maxUploadFileSize / 1024,
              }),
              icon: "warning",
              button: t("okLabel"),
              timer: 2500,
            });
          } else{
            uploadImageApiCall(type, files);
          }
        } else {
          Swal.fire({
            title: t("wrongFileFormat"),
            text: "Only .jpeg, .jpg, .png, .pdf, .xls, .xlsx file format are allowed",
            icon: "warning",
            button: t("okLabel"),
            timer: 2500,
          });
        }
      });
    }
  };

  /****------- Delete Image  ---------- ****/
  const deleteImageFiles = (imageType, file) => {
    var media = {};
    media["media_id"] =  file.media_id ? file.media_id : file.id;
    if(imageType == "MeasurementSheet") {
      Swal.fire({
        title: t("deleteConfirmationTitleAlert"),
        text: t("cantRevertBack"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .post(ServerUrl + "/delete-order-materials-label-media", apiencrypt(media))
            .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code == 200) {
                Swal.fire({
                  title: t(response.data.meassage),
                  icon: "success",
                  showCancelButton: true,
                  button: t("okLabel"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    uploadImageApiCall(imageType, file);
                  }
                }
                )
              }
            })
        }
      });

    }
    else {
      Swal.fire({
        title: t("areYouSureToDeleteThisImage"),
        text: t("cantRevertBack"),
        icon: "warning",
        showCancelButton: true,
        button: t("okLabel"),
      }).then((result) => {
        if (result.isConfirmed){
          axios.post(ServerUrl + "/delete-order-materials-label-media", apiencrypt(media))
                .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code == 200) {
                Swal.fire({
                  title: t(response.data.meassage),
                  icon: "success",
                  showCancelButton: true,
                  button: t("okLabel"),
                }).then((result) => {
                  if (result.isConfirmed) {
                    uploadImageApiCall(imageType, file);
                  }
                }
                )
              }
            })
        }
      });
    }
  };

  const viewinformation = (cat_id) => {

    Setvendor_idDetails(() => "");
    setVenCat_id(cat_id);
    axios.post(ServerUrl + "/label-vendor-list", apiencrypt({
            "company_id": getLoginCompanyId,
            "workspace_id": getWorkspaceId,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data)
        SetvendorDetails(response.data.data);
        Setvendor_idDetails(response.data.vendor_id);
    });

    setViewVendorListModal(!ViewVendorListModal)
      
  };

  const [addMaterialsLabelsImageModal, setAddMaterialsLabelsImageModal] = useState(false);
  const  toggleAddMaterialsLabelsImageModal = () => setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal);

  const [ sewAccessoriesRowData, setSewAccessoriesRowData ] = useState([]);
  const [ packAccessoriesRowData, setPackAccessoriesRowData ] = useState([]);
  const [ miscellaneousRowData, setMiscellaneousRowData ] = useState([]);

  const [unitsList, setUnitsList] = useState([]);

  const [ labelSampleType, setLabelSampleType ] = useState("");
  const [ uploadedImageList, setUploadedImageList ] = useState([]);
  const [ uploadedImageName, setUploadedImageName ] = useState("");
  const [ serverURL, setServerURL ] = useState("");

  const [ getOrderBomData, setGetOrderBomData ] = useState([]);
  const [ sewingAccessories, setSewingAccessories ] = useState([]);
  const [ packingAccessories, setPackingAccessories ] = useState([]);
  const [ miscellaneous, setMiscellaneous ] = useState([]);

  const [ measurementSheetLabelSample, setMeasurementSheetLabelSample ] = useState("");
  const [ techPackLabelSample, setTechPackLabelSample ] = useState("");

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function OnlyNumbersValidation(e, type, id) {
    if( (type == "PricePerUnit") || (type == "perGMT")|| (type == "TotalPrice")||(type=="pricePerUnit") ){
    var key = e.key;
    var currentValue=e.target.value;
    var regex = /[0-9.]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    } 
    if (key === "." && currentValue.includes(".")) {
      e.preventDefault();
    }
  } else if(type == "leadTime"){
    var key = e.key;
    var regex = /[0-9]/;
    var currentValue = e.target.value;
    if (!regex.test(key) || currentValue.length >= 3) {
      e.preventDefault();
  }
  }
  else{
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
  }

  if( e.target.value == 0 && e.target.value !== "" ){
    document.getElementById(id+"_Error").textContent = t("invalidNumber");
  }
  else{ 
      document.getElementById(id+"_Error").textContent = ""; 
  }

  };
  const AddTotalPrice = (id, id2,id3) =>{
    var Sewing_totalQty = document.getElementById(id).value !=null ? document.getElementById(id).value : "";
    var Sewing_pricePerUnit = document.getElementById(id2).value !=null ? document.getElementById(id2).value : "";
    if(Sewing_totalQty != null && Sewing_pricePerUnit != null){
       document.getElementById(id3).value = Sewing_totalQty * Sewing_pricePerUnit
       }
  };

  const [ sewAdditionalIdValues, setSewAdditionalIdValues ] = useState([]);
  const [ packAdditionalIdValues, setPackAdditionalIdValues ] = useState([]);
  const [ miscAdditionalIdValues, setMiscAdditionalIdValues ] = useState([]);

  const [ sewRemovedIdValues, setSewRemovedIdValues ] = useState([]);
  const [ packRemovedIdValues, setPackRemovedIdValues ] = useState([]);
  const [ miscRemovedIdValues, setMiscRemovedIdValues ] = useState([]);

  const [ sewCount, setSewCount ] = useState();
  const [ packCount, setPackCount ] = useState();
  const [ miscellaneousCount, setMiscellaneousCount ] = useState();

  const [ sewEditStatus, setSewEditStatus ] = useState();
  const [ packEditStatus, setPackEditStatus ] = useState();

  const [ accessType, setAccessType ] = useState();
  
  var currentDate = Moment().format('YYYY-MM-DD');
  const [ oldUploadedImages, setOldUploadedImages ] = useState('');
  const [ newUploadedImages, setNewUploadedImages ] = useState('');

  // const [ , setNewUploadedImages ] = useState('');
  
  useEffect(() => {

    {
      getWorkspaceType == "Factory" 
    ?
      ""
    :
      window.location.href="/dashboard"
    }

    var getInputParams = {};
        getInputParams["company_id"] = getLoginCompanyId;
        getInputParams["workspace_id"] = getWorkspaceId;
        getInputParams["order_id"] = orderId;

    axios.post(ServerUrl + "/all-vendor-list", apiencrypt({
        "company_id": getLoginCompanyId,
        "workspace_id": getWorkspaceId,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        setVendorList(response.data.data);
        setUnitsList(response.data.units);
    });
  	
    axios.post(ServerUrl + "/get-order-bom", apiencrypt({
      "order_id": orderId ? orderId : 1,
    }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        var uploadedImages = response.data.media; 
        setUploadedImageList(uploadedImages);
        setOldUploadedImages(uploadedImages.length);
        setServerURL(response.data.serverURL);
        {
          uploadedImages.length > 0 ?
          (uploadedImages)
          .filter(filterData => filterData.media_type == labelSampleType)
          .map((mapData)=>{ setUploadedImageName(mapData.orginalfilename) })
          :
          ""
          setUploadedImageName(()=>"");
        }

        if( response.data.data !== null ){
          setGetOrderBomData(response.data.data);
          setSewingAccessories(JSON.parse(response.data.data.sewing_accessories));
          setPackingAccessories(JSON.parse(response.data.data.packing_accessories));
          setMiscellaneous(JSON.parse(response.data.data.miscellaneous));

          var parsedSewData = JSON.parse(response.data.data.sewing_accessories);
          var parsedPackData = JSON.parse(response.data.data.packing_accessories);
          var parsedMiscData = JSON.parse(response.data.data.miscellaneous);

          parsedSewData.map((mapData) => { setSewCount( mapData.id ? mapData.id + 1 : parsedSewData.length ) })
          parsedPackData.map((mapData) => { setPackCount( mapData.id ? mapData.id + 1 : parsedPackData.length ) })
          parsedMiscData.map((mapData) => { setMiscellaneousCount( mapData.id ? mapData.id + 1 : parsedMiscData.length ) })
        } else{

          var sewArray = [];
          var packArray = [];
          var miscArray = [];

          const sewAccessories = ["MainLabel", "Washcare", "BarcodeStickers", "Hangtag"];
          const packAccessories = ["Polybag", "Cartonbox"];
          const miscellaneousAccessories = ["Miscellaneous"];
          const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                          "totalQty","leadTime", "units", "pricePerUnit", "media_type"];

          sewAccessories.map((mapData, index) => {
            var sewObject1 = {};
            keyList.map((keyData) => {
                if( index == 0 ){
                  if( keyData == 'Type' ){ var data = t("mainLabel")}
                } else if( index == 1 ){
                  if( keyData == 'Type' ){ var data = t("washCare")}
                } else if( index == 2 ){
                  if( keyData == 'Type' ){ var data = t("barcodeStickers")}
                } else{
                  if( keyData == 'Type' ){ var data = t("hangtag")}
                } 
                sewObject1[keyData] = data === undefined ? "" : data;
            })
            sewArray.push(sewObject1);
          });

          packAccessories.map((mapData, index) => {
            var packObject1 = {};
            keyList.map((keyData) => {
                if( index == 0 ){
                  if( keyData == 'Type' ){ var data = t("polybag")}
                } else{
                  if( keyData == 'Type' ){ var data = t("cartonBox")}
                } 
                packObject1[keyData] = data === undefined ? "" : data;
            })
            packArray.push(packObject1);
          });

          miscellaneousAccessories.map((mapData, index) => {
            var miscObject1 = {};
            keyList.map((keyData) => {
                miscObject1[keyData] =  "";
            })
            miscArray.push(miscObject1);
          });

          setSewingAccessories(sewArray);
          setPackingAccessories(packArray);
          setMiscellaneous(miscArray);

          setSewCount(4) 
          setPackCount(2) 
          setMiscellaneousCount(1) 
        }

    });

    axios.post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      var sewingStartDate = response.data.data[0].sewing_start_date;
      var packingStartDate = response.data.data[0].packing_start_date;

      if( currentDate < sewingStartDate ){ 
        setSewEditStatus(false)
      } else{
        setSewEditStatus(true)
      }
  
      if( currentDate < packingStartDate ){ 
        setPackEditStatus(false)
      } else{
        setPackEditStatus(true)
      }
    });

    setMeasurementSheetLabelSample("MeasurementSheet");
    setTechPackLabelSample("TechPack");

    {
      (((getStaff === "Staff" && getStaffPermission.includes('Add Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    }

  }, []);

  /* -----Add New Modal Call for unit Fields----*/
  const dropVal = (idValue) => {
    document.getElementById(idValue).value = 0;
    toggleUnitAddNew();
  };
  
  const updatedSewingAccessoriesRow = ( index ) => {

    var data = document.querySelectorAll()
    var updatedSewingAccessories = sewingAccessories.filter(filterData => filterData.id != index );
    // setSewingAccessories(updatedSewingAccessories);
  };

  const addSewAccessoriesTableRows = () => {

        const rowsInput={
            id: '',
            Type: '',
            component:'',
            color:'',
            vendor:'',  
            perGMT:'',  
            totalQty:'',
            leadTime: '',
            units: '',  
            pricePerUnit: '', 
            totalPrice:'', 
        } 
        setSewAccessoriesRowData([...sewAccessoriesRowData, rowsInput]);
        setSewAdditionalIdValues([...sewAdditionalIdValues, sewCount]);
  };

  const addPackAccessoriesTableRows = ()=>{
    const rowsInput={
        id: '',
        Type: '',
        component:'',
        color:'',
        vendor:'',  
        perGMT:'',  
        totalQty:'',
        leadTime: '',
        units: '',  
        pricePerUnit: '', 
        totalPrice:'',  
    } 
    setPackAccessoriesRowData([...packAccessoriesRowData, rowsInput]);
    setPackAdditionalIdValues([...packAdditionalIdValues, packCount]);

  };

  const addMiscellaneousTableRows = ()=>{
    const rowsInput={
        id: '',
        Type: '',
        component:'',
        color:'',
        vendor:'',  
        perGMT:'',  
        totalQty:'',
        leadTime: '',
        units: '',  
        pricePerUnit: '',
        totalPrice:'',   
    } 
    setMiscellaneousRowData([...miscellaneousRowData, rowsInput]);
    setMiscAdditionalIdValues([...miscAdditionalIdValues, miscellaneousCount]);
  };

  const deleteSewAccessoriesTableRows = (index)=>
  {

    var remainingSewArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime", "units", "pricePerUnit","totalPrice"];
    sewAccessoriesRowData.map((mapData, index) => {
      var index = sewAdditionalIdValues[index];
      var remainingSewObj = {};
      keyList.map((keyList) => {
          if( keyList == 'id' ){ 
            var data = index; 
          } else{
            var data = document.getElementById("Sewing_"+keyList+ "_" +index).value;
          }
          remainingSewObj[keyList] = data === undefined ? "" : data;
      })
      remainingSewArray.push(remainingSewObj);
    });

    var value = sewAdditionalIdValues.indexOf(index) !== -1 && sewAdditionalIdValues.splice(sewAdditionalIdValues.indexOf(index), 1);
    const rows = [...sewAccessoriesRowData];
    rows.splice(-1, 1);
    setSewAccessoriesRowData(rows);

    var filteredSewArray = remainingSewArray.filter(filterData => filterData.id !== index);
    setSewAccessoriesRowData(filteredSewArray);

    setSewRemovedIdValues([...sewRemovedIdValues, value]);


  };
   
  const deletePackAccessoriesTableRows = (index)=>
  {
    var remainingPackArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime", "units", "pricePerUnit","totalPrice"];
                         
    packAccessoriesRowData.map((mapData, index) => {
      var index = packAdditionalIdValues[index];
      var remainingPackObject = {};
      keyList.map((keyList) => {
        if( keyList == 'id' ){ 
          var data = index; 
        } else{
          var data = document.getElementById("Packing"+"_"+keyList+ "_" +index).value;
        }
        remainingPackObject[keyList] = data === undefined ? "" : data;
      })
      remainingPackArray.push(remainingPackObject);
    });

    var value = packAdditionalIdValues.indexOf(index) !== -1 && packAdditionalIdValues.splice(packAdditionalIdValues.indexOf(index), 1);
    const rows = [...packAccessoriesRowData];
    rows.splice(-1, 1);
    setPackAccessoriesRowData(rows);

    var filteredPackArray = remainingPackArray.filter(filterData => filterData.id !== index);
    setPackAccessoriesRowData(filteredPackArray);

    setPackRemovedIdValues([...packRemovedIdValues, value]);


  };

  const deleteMiscellaneousTableRows = (index)=>
  {
    var remainingMiscArray = [];
    const keyList = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime", "units", "pricePerUnit","totalPrice"];

    miscellaneousRowData.map((mapData, index) => {
      var index = miscAdditionalIdValues[index];
      var remainingMiscObject = {};
      keyList.map((keyList) => {
        if( keyList == 'id' ){ 
          var data = index; 
        } else{
          var data = document.getElementById("Miscellaneous"+"_"+keyList+ "_" +index).value;
        }
        remainingMiscObject[keyList] = data === undefined ? "" : data;
      })
      remainingMiscArray.push(remainingMiscObject);
    });

    var value = miscAdditionalIdValues.indexOf(index) !== -1 && miscAdditionalIdValues.splice(miscAdditionalIdValues.indexOf(index), 1);
    const rows = [...miscellaneousRowData];
    rows.splice(-1, 1);
    setMiscellaneousRowData(rows);

    var filteredMiscArray = remainingMiscArray.filter(filterData => filterData.id !== index);
    setMiscellaneousRowData(filteredMiscArray);

    setMiscRemovedIdValues([...miscRemovedIdValues, value]);

  };

  const onUpdateBillOfMaterials = () => {

    var sewArray = [];
    var packArray = [];
    var miscellaneousArray = [];

    const sewAccessories = ["MainLabel", "Washcare", "BarcodeStickers", "Hangtag"];
    const packAccessories = ["Polybag", "Cartonbox"];
    const miscellaneousAccessories = ["Miscellaneous"];

    const keyData = ["id", "Type", "component", "color", "vendor", "perGMT", 
                      "totalQty","leadTime", "units", "pricePerUnit","totalPrice", "media_type"];

    sewingAccessories.map((mapData, index) => {
      var index = mapData.id ? mapData.id : index;
      var sewObject1 = {};
      keyData.map((keyData) => {
        if("Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_0"){ 
          var data = "MainLabel";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_1" ){
          var data = "Washcare";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_2" ){
          var data = "BarcodeStickers";
        } else if( "Sewing"+"_"+keyData+ "_" +index == "Sewing_Type_3" ){
          var data = "Hangtag";
        } else{

        if( keyData == 'media_type'){ 
          var data = "Sewing_Type_" +index   
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var element = document.getElementById("Sewing"+"_"+keyData+ "_" +index);
          if (typeof(element) != 'undefined' && element != null){ var data=element.value }
        }
        }
          sewObject1[keyData] = data === undefined ? "" : data;
      })
      sewArray.push(sewObject1);
    });
    
    sewAccessoriesRowData.map((mapData, index) => {
      var index = sewAdditionalIdValues[index];
      var sewObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Sewing_Type_"+index;
        } else if( keyData == 'id' ){
          var data = index;
        }  else{
          var data = document.getElementById("Sewing_"+keyData+ "_" +index).value;
        }
          sewObject2[keyData] = data===undefined ? "" : data;
      })
      sewArray.push(sewObject2);
    });

    packingAccessories.map((mapData, index) => {
      var index = mapData.id ? mapData.id : index;
      var packObject1 = {};
      keyData.map((keyData) => {
        if("Packing"+"_"+keyData+ "_" +index == "Packing_Type_0"){ 
          var data = "Polybag";
        } else if( "Packing"+"_"+keyData+ "_" +index == "Packing_Type_1" ){
          var data = "Cartonbox";
        } else{
          if( keyData == "media_type" ){
            var data = "Packing_Type_"+index;
          } else if( keyData == 'id' ){
            var data = index;
          } else{
            var element = document.getElementById("Packing"+"_"+keyData+ "_" +index);
            if (typeof(element) != 'undefined' && element != null){ var data=element.value }
          }
        }
        packObject1[keyData] = data===undefined ? "" : data;
      })
      packArray.push(packObject1);
    });
    
    packAccessoriesRowData.map((mapData, index) => {
      var index = packAdditionalIdValues[index];
      var packObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Packing_Type_"+index;
        } else if( keyData == 'id' ){
          var data = index;
        }  else{
          var data = document.getElementById("Packing"+"_"+keyData+ "_" +index).value;
        }
          packObject2[keyData] = data===undefined ? "" : data;
      })
      packArray.push(packObject2);
    });
    
    miscellaneous.map((mapData, index) => {
      var index = mapData.id ? mapData.id : index;
      var miscellaneousObject1 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Miscellaneous_Type_"+index;
        } else if( keyData == 'id' ){
          var data = index;
        } else{
          var element = document.getElementById("Miscellaneous_"+keyData+"_"+index);
          if (typeof(element) != 'undefined' && element != null){ var data=element.value }
        }
        
        miscellaneousObject1[keyData] = data===undefined ? "" : data;
      })
      miscellaneousArray.push(miscellaneousObject1);
    });

    miscellaneousRowData.map((mapData, index) => {
      var index = miscAdditionalIdValues[index];
      var miscellaneousObject2 = {};
      keyData.map((keyData) => {
        if( keyData == "media_type" ){
          var data = "Miscellaneous_Type_" +index
        } else if( keyData == 'id' ){
          var data = index;
        } else {
          var data = document.getElementById("Miscellaneous_"+keyData+ "_" +index).value;
        }
        
          miscellaneousObject2[keyData] = data===undefined ? "" : data;
      })
      miscellaneousArray.push(miscellaneousObject2);
    });

    var errorValues = emptyValidation1(sewArray, packArray, miscellaneousArray);
    if ((errorValues).length == 0) {

      function arraysHaveChanges(oldArray, newArray) {
        if (oldArray.length !== newArray.length) {
          return true; // Different lengths, definitely changes
        }
      
        for (let i = 0; i < oldArray.length; i++) {
          const oldObject = oldArray[i];
          const newObject = newArray[i];
      
          for (const key in oldObject) {
            if (oldObject.hasOwnProperty(key)) {
              if (oldObject[key] !== newObject[key]) {
                return true; // Change detected
              }
            }
          }
        }
      
        return false; // No changes detected
      }
 
      const sewEmptyArray = [{
        Type: "MainLabel",
        color: "",
        component: "",
        id: 0,
        leadTime: "",
        media_type: "Sewing_Type_0",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      },
      {
        Type: "Washcare",
        color: "",
        component: "",
        id: 1,
        leadTime: "",
        media_type: "Sewing_Type_1",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      },
      {
        Type: "BarcodeStickers",
        color: "",
        component: "",
        id: 2,
        leadTime: "",
        media_type: "Sewing_Type_2",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      },{
        Type: "Hangtag",
        color: "",
        component: "",
        id: 3,
        leadTime: "",
        media_type: "Sewing_Type_3",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      }];

      const packEmptyArray = [{
        Type: "Polybag",
        color: "",
        component: "",
        id: 0,
        leadTime: "",
        media_type: "Packing_Type_0",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      },
      {
        Type: "Cartonbox",
        color: "",
        component: "",
        id: 1,
        leadTime: "",
        media_type: "Packing_Type_1",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      }
      ];

      const miscellaneousEmptyArray = [{
        Type: "",
        color: "",
        component: "",
        id: 0,
        leadTime: "",
        media_type: "Miscellaneous_Type_0",
        perGMT: "",
        pricePerUnit: "",
        totalPrice: "",
        totalQty: "",
        units: "0",
        vendor: "0",
      }
      ];
      
      // var oldImagesLists = uploadedImageList.length > 0 ? uploadedImageList.length : 0;
      // var newImagesList = uploadedImageList.length > 0 ? uploadedImageList.length : 0;
      // console.log("oldImagesLists", oldImagesLists);


      var sewingAccessoriesOldArray = Object.keys(getOrderBomData).length > 0 ? JSON.parse(getOrderBomData.sewing_accessories) : sewEmptyArray;
      var packingAccessoriesOldArray = Object.keys(getOrderBomData).length > 0 ? JSON.parse(getOrderBomData.packing_accessories) : packEmptyArray; 
      var miscellaneousOldArray = Object.keys(getOrderBomData).length > 0 ? JSON.parse(getOrderBomData.miscellaneous) : miscellaneousEmptyArray; 

      const sewChangeStatus = arraysHaveChanges(sewingAccessoriesOldArray, sewArray);
      const packChangeStatus = arraysHaveChanges(packingAccessoriesOldArray, packArray);
      const miscellaneousChangeStatus = arraysHaveChanges(miscellaneousOldArray, miscellaneousArray);

      // if(oldUploadedImages == newUploadedImages){ console.log("No Changes Detected")} else{ console.log("Changes Detected") }

      // console.log("sewChangeStatus", sewChangeStatus, "packChangeStatus", packChangeStatus, "miscellaneousChangeStatus", miscellaneousChangeStatus);
      // console.log("getOrderBomData", getOrderBomData);

      if ( sewChangeStatus || packChangeStatus || miscellaneousChangeStatus ){
        var apiInputParams = {};
            apiInputParams['company_id'] = getLoginCompanyId; 
            apiInputParams['workspace_id'] = getWorkspaceId; 
            apiInputParams['user_id'] = getLoginUserId; 
            apiInputParams['staff_id'] = getLoginStaffId; 
            apiInputParams['order_id'] = orderId; 
            apiInputParams['sewing_accessories'] = sewArray ? sewArray : []; 
            apiInputParams['packing_accessories'] = packArray ? packArray : [];  
            apiInputParams['miscellaneous_accessories'] = miscellaneousArray ? miscellaneousArray : [];
            apiInputParams['before_values'] = getOrderBomData ? getOrderBomData : [];

            axios.post(ServerUrl + "/update-order-bom", apiencrypt(apiInputParams))
            .then((response) => {
              response.data = apidecrypt(response.data);      
              if( response.data.status_code == 200 ){
                Swal.fire({
                  title: t(response.data.message),
                  icon: "success",
                  button: t("okLabel"),
                  allowOutsideClick: false,
                  timer:5000
                })
                .then((result) => {
                  if (result.isConfirmed) { 
                    window.location.href = '/orderview?id=' + encode(orderId) 
                  }
                  window.location.href = '/orderview?id=' + encode(orderId)
                })
              } else if( response.data.status_code == 401 ){
                Swal.fire({
                  title: t(response.data.error.order_id),
                  icon: "warning",
                  button: t("okLabel"),
                  allowOutsideClick: false,
                  timer:5000
                })
              }
            });

      } else {
        Swal.fire({
          title: t("notice"),
          html: `<strong>${t("note")}:</strong> ${t("noChangesMade")},<br>${t("doYouWantViewOrder")}`,
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t("close"), 
          button: t("okLabel"),
          allowOutsideClick: false,
          reverseButtons: true,
          timer: 5000
        })
        .then((result) => {
          if (result.isConfirmed) { 
            window.location.href = '/orderview?id=' + encode(orderId) 
          }
        })
      }

    }
  };

  const getOrderMaterialsLabelApiCall = () => {

    axios.post(ServerUrl + "/get-order-bom", apiencrypt({ "order_id": orderId }))
    .then((response) => {
        response.data = apidecrypt(response.data);
        var uploadedImages = response.data.media;
        setUploadedImageList(uploadedImages);          
        setServerURL(response.data.serverURL);
        {
            uploadedImages.length > 0 ?
            (uploadedImages)
            .filter(filterData => filterData.media_type == labelSampleType)
            .map((mapData)=>{
              setUploadedImageName(mapData.orginalfilename);
            })
          :
            ""
          setUploadedImageName(()=>"");
        }
    });

  };

  const emptyValidation1 = ( sewArray, packArray, miscellaneousArray ) => {

    var errors = [];
    sewArray.map((mapData) => {
      if( mapData.Type == '' || mapData.Type === undefined ){
        document.getElementById("Sewing_Type_"+mapData.id+"_Error").textContent = "Please Fill the Type";
        errors.push("sew_id_"+mapData.id);
      } 
    });

    packArray.map((mapData) => {
      if( mapData.Type == '' || mapData.Type === undefined ){
        document.getElementById("Packing_Type_"+mapData.id+"_Error").textContent = "Please Fill the Type";
        errors.push("pack_id_"+mapData.id);
      } 
    });

    miscellaneousArray.map((mapData) => {
      if( mapData.id !== 0 ){
        if( mapData.Type == '' || mapData.Type === undefined ){
          document.getElementById("Miscellaneous_Type_"+mapData.id+"_Error").textContent = "Please Fill the Type";
          errors.push("misc_id_"+mapData.id);
        } 
      }
    });

    return errors;
  };

  const emptyValidation2 = ( value, id ) => {
      if( value == "" ){ 
        document.getElementById(id).textContent = "Please Fill the Type";
      }
      else{ 
        document.getElementById(id).textContent = " "; 
      }
  };

  /*Minimize Pdf Name */
  const truncate = (str) => {
    const n = 22;
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };
  
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">

        <Card style={{ border: 'none' }}>
          <CardBody style={{ border: 'none' }}>
            <OrderHeaders title="addmaterialsLabels"/>
          </CardBody>
        </Card>

        {/* Sewing Accessories */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-40px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>
              <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead">{t("sewingAccessories")}</H6>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '10px' }}>
                <ScrollBar  options={{ suppressScrollX: true }}>
                  <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding tableViewRes">

                        <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                          <th> {t("type")} </th>
                          <th className="text-center">{t("component")}</th>
                          <th className='text-center'>{t("color")}</th>
                          <th className='text-center'>{t("vendorInformation")}</th>
                          <th className='text-center'>{t("perGMT")}</th>
                          <th className='text-center'>{t("totalQty")}</th>
                          <th className='text-center'>{t("leadTime")}</th>
                          <th className='text-center'>{t("units")}</th>
                          <th className='text-center'>{t("pricePerUnit")}</th>
                          <th className="text-center">{t("totPrice")}</th>
                          <th className='text-center'>{t("labelSample")}</th>
                        </tr>

                          <tr>
                            <td className="p-t-0 p-b-0" id={"Removed_Sew_Ids_"+sewRemovedIdValues}>
                              <span className="error-msg"></span>           
                            </td>
                          </tr>
                          { sewingAccessories.length > 0 ?
                            sewingAccessories.map((mapData, index) => {
                              var index = mapData.id ? mapData.id : index;
                              return (
                                <tr>

                                  <td> 
                                    {
                                      index <= 3 ? 
                                        mapData.Type 
                                    :
                                      <>
                                        <Input
                                          style={{ textTransform: "capitalize" }}
                                          disabled={sewEditStatus}
                                          id={"Sewing_Type_"+index}
                                          placeholder="Type"
                                          defaultValue = {mapData.Type}>
                                        </Input>
                                        <span id={"Sewing_Type_"+index+"_Error"} className="error-msg"></span>
                                      </>
                                    } 
                                  </td>

                                  <td width="12%">
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_component_"+index}
                                      name="MainLabel"
                                      placeholder={t("component")}
                                      defaultValue = {mapData.component}
                                    >
                                    </Input>
                                    <span className="error-msg"></span>
                                  </td>

                                  <td width="10%">
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_color_"+index}
                                      name="MainLabelColor"
                                      placeholder={t("color")}
                                      defaultValue = {mapData.color}
                                    />
                                     <span className="error-msg"></span>
                                  </td>

                                  <td width="12%"> 
                                    <Input
                                      disabled={sewEditStatus}
                                      type="select"
                                      id={"Sewing_vendor_"+index}
                                      className="form-control digits selectheight"
                                      name="Vendor Information"
                                      onChange={(e) =>{ 
                                        e.target.value!="addNew"?
                                        setMainLabelVendor(e.target.value): viewinformation("Sewing_vendor_0") }} >
                                      <option selected disabled value={0}> {t("selectVendor")}  </option>
                                    { vendorList.length > 0 
                                    ? 
                                      (vendorList)
                                      .map((vendors) => 
                                      (
                                        mapData.vendor == vendors.id ?
                                        <option
                                          id={"Sewing_vendor_"+index}
                                          value={vendors.id}
                                          title={vendors.vendor_name}
                                          selected >
                                          {vendors.vendor_name}
                                        </option>
                                        :
                                        <option
                                          id={"Sewing_vendor_"+index}
                                          value={vendors.id}
                                          title={vendors.vendor_name} >
                                          {vendors.vendor_name}
                                        </option>
                                      ))
                                    :
                                      ""
                                    }
                                    <option value="addNew">+{t("addNew")}</option>
                                    </Input>
                                    <span className="error-msg"></span>
                                  </td>

                                  <td>
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_perGMT_"+index}
                                      name="MainLabelPerGMT"
                                      placeholder={t("perGMT")}
                                      defaultValue = {mapData.perGMT}
                                      onChange={(e) => { OnlyNumbersValidation(e,"perGMT","Sewing_perGMT_"+index) }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"perGMT","Sewing_perGMT_"+index)}
                                    />
                                    <span id={"Sewing_perGMT_"+index+"_Error"} className="error-msg"></span>
                                  </td>

                                  <td>
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_totalQty_"+index}
                                      name="MainLabelTotalQty"
                                      placeholder={t("totalQty")}
                                      defaultValue = {mapData.totalQty}
                                      onChange={(e) => { OnlyNumbersValidation(e,"totalQty","Sewing_totalQty_"+index),AddTotalPrice("Sewing_totalQty_"+index,"Sewing_pricePerUnit_"+index,"Sewing_totalPrice_"+index) }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"totalQty","Sewing_totalQty_"+index) }
                                    />
                                    <span id={"Sewing_totalQty_"+index+"_Error"} className="error-msg"></span>
                                  </td>
                                  <td>
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_leadTime_"+index}
                                      name="LeadTime"
                                      placeholder={t("noOfDays")}
                                      defaultValue={mapData.leadTime}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Sewing_leadTime_"+index)}
                                    />
                                    <span id={"Sewing_leadTime_"+index+"_Error"} className="error-msg"></span>            
                                  </td>
                                 

                                  <td width="10%">
                                    <Input
                                      disabled={sewEditStatus}
                                      type="select"
                                      id={"Sewing_units_"+index}
                                      name="MainLabelUnits"
                                      placeholder={t("units")}
                                      onChange={(e) =>{ 
                                        e.target.value =="addNew"?
                                        dropVal("Sewing_units_"+index) :""}}
                                    >
                                    <option selected disabled value={0}>  Select Units </option>
                                    {Object.values(unitsList).length > 0 ? 
                                        Object.values(unitsList).map((units) => 
                                        (
                                          mapData.units == units.name ?
                                          <option
                                            id={"Sewing_units_"+index}
                                            value={units.name}
                                            title={units.name} 
                                            selected >
                                            {units.name}
                                          </option>
                                          :
                                          <option
                                            id={"Sewing_units_"+index}
                                            value={units.name}
                                            title={units.name} >
                                            {units.name}
                                          </option>
                                        ))
                                      :
                                        ""}
                                       <option value="addNew">+{t("addNew")}</option>
                                    </Input> 
                                    <span className="error-msg"></span>
                                  </td>

                                  <td>
                                    <Input
                                      disabled={sewEditStatus}
                                      id={"Sewing_pricePerUnit_"+index}
                                      name="MainLabelPricePerUnit"
                                      placeholder={t("pricePerUnit")}
                                      defaultValue = {mapData.pricePerUnit}
                                      onChange={(e) => { OnlyNumbersValidation(e,"PricePerUnit","Sewing_pricePerUnit_"+index),AddTotalPrice("Sewing_totalQty_"+index,"Sewing_pricePerUnit_"+index,"Sewing_totalPrice_"+index) }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Sewing_pricePerUnit_"+index)}
                                    />
                                    <span  id={"Sewing_pricePerUnit_"+index+"_Error"} className="error-msg"></span>
                                  </td>
                                <td>
                                    <Input
                                     disabled={sewEditStatus}
                                      id={"Sewing_totalPrice_"+index}
                                      name="MainLabelTotalPrice"
                                      defaultValue = {mapData.totalPrice}
                                      placeholder={t("totPrice")}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Sewing_totalPrice_"+index)}
                                      readOnly/>
                                    <span id={"Sewing_totalPrice_"+index+"_Error"} className="error-msg"></span>            
                                </td>
                                  <tr>
                                    <td className="text-center"   
                                        style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '1.13rem' }} >
                                          {sewEditStatus == true ?
                                            <img
                                              width="23px"
                                              height="23px"
                                              style={{ cursor: 'no-drop', paddingBottom: '0px', paddingLeft: '0px' }}
                                              src={labelSampleIcon}
                                            />
                                            :
                                              <img
                                                width="23px"
                                                height="23px"
                                                style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                                onClick={() => { 
                                                  setAccessType("Edit"),
                                                  getOrderMaterialsLabelApiCall(),
                                                  setLabelSampleType("Sewing_Type_"+index),
                                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                                }}
                                                src={labelSampleIcon}
                                              />
                                            }

                                            {/* {index > 3 
                                            ? 
                                            <img
                                              className="labelSampleIcon"
                                              width="35px"
                                              height="35px"
                                              style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                              onClick={() => { 
                                                updatedSewingAccessoriesRow(index) 
                                              }}
                                              src={DeleteRowIcon}
                                            />  
                                            :
                                            ""} */}
                                    </td>
                                  </tr>

                                  <td 
                                    style={{  backgroundColor: '#fff' }} 
                                    className="f-left"> 
                                  </td>

                                </tr>
                              )
                            })
                          :
                            ""
                          }

                          {sewAccessoriesRowData.map((mapData, index)=>
                          {
                            var index = sewAdditionalIdValues[index];
                            return(
                              <>
                                <tr key={index}>
                                  <td>
                                    <Input
                                      style={{ textTransform: "capitalize" }}
                                      id={"Sewing_Type"+"_"+index}
                                      name={index}
                                      placeholder={t("type")}
                                      defaultValue={mapData.Type}
                                      onChange={(e)=> { emptyValidation2(e.target.value, "Sewing_Type_"+index+"_Error")}}
                                      >
                                    </Input>
                                    <span id={"Sewing_Type_"+index+"_Error"} 
                                            className="error-msg" 
                                            style={{ fontSize: '12px' }}>
                                    </span>
                                  </td>
                                  {(Object.keys(mapData))
                                  .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                                  .map((objMapData) => {
                                    return(
                                      <>
                                        <td>
                                          {
                                            objMapData == "vendor" 
                                            ? 
                                              <>
                                                <Input
                                                  id={"Sewing_vendor_"+index}
                                                  type="select"
                                                  className="form-control digits selectheight"
                                                  name="Vendor Information"
                                                  onChange={(e) =>{ 
                                                    e.target.value!="addNew"?
                                                    setHangtagVendor(e.target.value): viewinformation("Sewing_vendor_"+index) }}
                                                  >
                                                  <option selected disabled value={0} >
                                                  {t("selectVendor")}
                                                  </option>
                                                  { vendorList.length > 0 
                                                  ? 
                                                    (vendorList).map((vendorData) => 
                                                    (
                                                      mapData.vendor == vendorData.id ?
                                                      <option
                                                        id={"Sewing_vendor_"+index}
                                                        value={vendorData.id}
                                                        title={vendorData.vendor_name}
                                                        selected >
                                                        {vendorData.vendor_name}
                                                      </option>
                                                      :
                                                      <option
                                                        id={"Sewing_vendor_"+index}
                                                        value={vendorData.id}
                                                        title={vendorData.vendor_name} >
                                                        {vendorData.vendor_name}
                                                      </option>
                                                    ))
                                                  :
                                                    ""
                                                  }
                                                <option value="addNew">+{t("addNew")}</option>
                                                </Input>
                                                <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                              </>

                                            : 
                                            objMapData == "units" ? 
                                                <>
                                                  <Input
                                                    type="select"
                                                    id={"Sewing_"+objMapData+"_"+index}
                                                    placeholder={t("units")} 
                                                    onChange={(e) =>{ 
                                                      e.target.value =="addNew"?
                                                      dropVal("Sewing_"+objMapData+"_"+index) :""}}
                                                  >
                                                    <option selected disabled value={0}> {t("selectUnits")} </option>
                                                    {Object.values(unitsList).length > 0 ? 
                                                        Object.values(unitsList).map((unitsValue) => 
                                                        (
                                                          mapData.units == unitsValue.name ?
                                                          <option
                                                            id={"Sewing_"+objMapData+"_"+index}
                                                            value={unitsValue.name}
                                                            title={unitsValue.name} 
                                                            selected>
                                                            {unitsValue.name}
                                                          </option>
                                                          :
                                                          <option
                                                            id={"Sewing_"+objMapData+"_"+index}
                                                            value={unitsValue.name}
                                                            title={unitsValue.name} >
                                                          {unitsValue.name}
                                                        </option>
                                                        ))
                                                      :
                                                        ""}
                                                        <option value="addNew">+{t("addNew")}</option>
                                                  </Input> 
                                                  <span className="error-msg" style={{ fontSize: '12px' }}></span>
                                                </>

                                              : 
                                                <>
                                                  <Input
                                                    id={"Sewing_"+objMapData+"_"+index}
                                                    name={"Sewing_"+objMapData+"_"+index}
                                                    disabled={ objMapData == "totalPrice" ? true : false}
                                                    defaultValue={
                                                      objMapData == "component" ? mapData.component :
                                                      objMapData == "color" ? mapData.color :
                                                      objMapData == "perGMT" ? mapData.perGMT :
                                                      objMapData == "totalQty" ? mapData.totalQty :
                                                      objMapData == "leadTime" ? mapData.leadTime :
                                                      objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                      objMapData == "totalPrice" ? mapData.totalPrice :
                                                      ""
                                                    }
                                                    placeholder={
                                                      objMapData == "component" ? t("component") :
                                                      objMapData == "color" ? t("color") :
                                                      objMapData == "perGMT" ? t("perGMT") :
                                                      objMapData == "totalQty" ? t("totalQty") :
                                                      objMapData == "leadTime" ? t("noOfDays") :
                                                      objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                      objMapData == "totalPrice" ? t("totPrice")  :
                                                      ""
                                                    }
                                                    onChange={ (e) => {
                                                      ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                      (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                       (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                       (AddTotalPrice("Sewing_totalQty_"+index,"Sewing_pricePerUnit_"+index,"Sewing_totalPrice_"+index),
                                                       OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index))
                                                       :
                                                       OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index)
                                                         
                                                       :
                                                         "")
                                                     }}
                                              
                                                    onKeyPress={(e) => { 
                                                      (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime")|| (objMapData == "totalPrice") ?
                                                        OnlyNumbersValidation(e, objMapData, "Sewing_"+objMapData+"_"+index)
                                                      :
                                                      ""
                                                    }}
                                                    >
                                                  </Input>
                                                  <span 
                                                    id={"Sewing_"+objMapData+"_"+index+"_Error"} 
                                                    className="error-msg" style={{ fontSize: '12px' }}>
                                                  </span>
                                                </>
                                          }
                                        </td>
                                      </>
                                    ) 
                                  })}
                                  <tr>
                                    <td className="text-center"   
                                        style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.5rem' }} >
                                            <img
                                                className="labelSampleIcon"
                                                width="23px"
                                                height="23px"
                                                style={{ cursor: 'pointer', marginTop: '10px' }}
                                                onClick={() => {
                                                  setAccessType("Edit"),
                                                  getOrderMaterialsLabelApiCall(), 
                                                  setLabelSampleType(
                                                      "Sewing_Type"+"_"+index ? 
                                                      "Sewing_Type"+"_"+index : 
                                                      ""),
                                                  setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                                }}
                                                src={labelSampleIcon}
                                              />
                                            <img
                                              className="labelSampleIcon"
                                              width="35px"
                                              height="35px"
                                              style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                              onClick={() => { 
                                                deleteSewAccessoriesTableRows(index) }}
                                              src={DeleteRowIcon}
                                            /> 
                                    </td>
                                  </tr>
                                </tr>
                              </>
                            )
                          })}

                          {/* Add Icon */} 
                          <tr>
                            <td colSpan={10}></td>
                            <td width="100%" className="f-right">
                            {
                            sewEditStatus == true 
                            ?
                              ""
                            :
                              <img 
                              className="cursor-pointer p-r-20" 
                              style={{ float: "right" }} 
                              onClick={ () => {
                                setSewCount(sewCount+1), 
                                addSewAccessoriesTableRows()
                              }} 
                              src={Add_icon} />
                            }
                            </td>
                          </tr>

                    </Table>
                  </div>
                </ScrollBar>
              </Row>
          </Card>
        </Row>

        {/* Packing Accessories */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px'}}>
          <Card className="shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>

            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead">{t("packingAccessories")} </H6>
                  </span>
                </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <ScrollBar  options={{ suppressScrollX: true }}>
              <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding tableViewRes">

                        <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                          <th> {t("type")} </th>
                          <th className="text-center">{t("component")}</th>
                          <th className='text-center'>{t("color")}</th>
                          <th className='text-center'>{t("vendorInformation")}</th>
                          <th className='text-center'>{t("perGMT")}</th>
                          <th className='text-center'>{t("totalQty")}</th>
                          <th className='text-center'>{t("leadTime")}</th>
                          <th className='text-center'>{t("units")}</th>
                          <th className='text-center'>{t("pricePerUnit")}</th>
                          <th className="text-center">{t("totPrice")}</th>
                          <th className='text-center'>{t("labelSample")}</th>
                        </tr>

                        <tr>
                          <td className="p-t-0 p-b-0" id={"Removed_Pack_Ids_"+packRemovedIdValues}>
                            <span className="error-msg"></span>           
                          </td>
                        </tr>

                        { packingAccessories.map((mapData, index) => {
                          var index = mapData.id ? mapData.id : index;
                          return (
                            <>
                              <tr>

                                <td>
                                  {
                                    index <= 1 ? 
                                      mapData.Type
                                  :
                                    <>
                                      <Input
                                        style={{ textTransform: "capitalize" }}
                                        disabled={packEditStatus}
                                        id={"Packing_Type_"+index}
                                        name="Polybag"
                                        placeholder={t("type")}
                                        defaultValue={mapData.Type}>
                                      </Input>
                                      <span  id={"Packing_Type_"+index+"_Error"} className="error-msg"></span>
                                    </>
                                  }
                                </td>

                                <td width="12%">
                                  <Input
                                    disabled={packEditStatus}
                                    id={"Packing_component_"+index}
                                    name="Polybag"
                                    placeholder={t("component")}
                                    defaultValue={mapData.component}>
                                  </Input>
                                  <span className="error-msg"></span>
                                </td>

                                <td width="10%">
                                  <Input
                                    disabled={packEditStatus}
                                    id={"Packing_color_"+index}
                                    name="PolybagColor"
                                    placeholder={t("color")}
                                    defaultValue={mapData.color}
                                    />
                                  <span className="error-msg"></span>
                                </td>

                                <td width="12%"> 
                                  <Input
                                  disabled={packEditStatus}
                                  id={"Packing_vendor_"+index}
                                  type="select"
                                  className="form-control digits selectheight"
                                  name="Vendor Information"
                                  onChange={(e) =>{ 
                                    e.target.value!="addNew"?
                                    setPolybagVendor(e.target.value): viewinformation("Packing_vendor_0")
                                  }}
                                  >
                                  <option selected disabled value={0}>
                                  {t("selectVendor")}
                                  </option>
                                  { vendorList.length > 0 
                                  ? 
                                    (vendorList).map((vendors) => 
                                    (
                                        mapData.vendor == vendors.id ?
                                      <option
                                        id={"Packing_vendor_"+index}
                                        value={vendors.id}
                                        title={vendors.vendor_name}
                                        selected >
                                        {vendors.vendor_name}
                                      </option>
                                      :
                                        <option
                                        id={"Packing_vendor_"+index}
                                        value={vendors.id}
                                        title={vendors.vendor_name} >
                                        {vendors.vendor_name}
                                      </option>
                                    ))
                                  :
                                    ""
                                  }
                                  <option value="addNew">+{t("addNew")}</option>
                                  </Input>
                                  <span className="error-msg"></span>
                                </td>

                                <td>
                                  <Input
                                    disabled={packEditStatus}
                                    id={"Packing_perGMT_"+index}
                                    name="PolybagPerGMT"
                                    placeholder={t("perGMT")}
                                    defaultValue={mapData.perGMT}
                                    onChange={(e)=> { OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_"+index) }}
                                    onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Packing_perGMT_"+index)}
                                  />
                                  <span id={"Packing_perGMT_"+index+"_Error"} className="error-msg"></span>
                                </td>

                                <td>
                                  <Input
                                    disabled={packEditStatus}
                                    id={"Packing_totalQty_"+index}
                                    name="PolybagTotalQty"
                                    placeholder={t("totalQty")}
                                    defaultValue={mapData.totalQty}
                                    onChange={(e)=> { OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_"+index),AddTotalPrice("Packing_totalQty_"+index,"Packing_pricePerUnit_"+index,"Packing_totalPrice_"+index)  }}
                                    onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Packing_totalQty_"+index) }
                                  />
                                  <span  id={"Packing_totalQty_"+index+"_Error"} className="error-msg"></span>
                                </td>
                                <td>
                                    <Input
                                      disabled={packEditStatus}
                                      id={"Packing_leadTime_"+index}
                                      name="LeadTime"
                                      placeholder={t("noOfDays")}
                                      defaultValue={mapData.leadTime}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Packing_leadTime_"+index)}
                                    />
                                    <span id={"Packing_leadTime_"+index+"_Error"} className="error-msg"></span>            
                                  </td>
                                

                                <td width="10%">
                                  <Input
                                    disabled={packEditStatus}
                                    type="select"
                                    id={"Packing_units_"+index}
                                    name="PolybagUnits"
                                    placeholder={t("units")}
                                    onChange={(e) =>{ 
                                      e.target.value =="addNew"?
                                      dropVal("Packing_units_"+index) :""}}
                                  >
                                  <option selected disabled value={0}> {t("selectUnits")} </option>
                                  {Object.values(unitsList).length > 0 ? 
                                      Object.values(unitsList).map((unitsData) => 
                                      (
                                        mapData.units == unitsData.name ?
                                        <option
                                          id={"Packing_units_"+index}
                                          value={unitsData.name}
                                          title={unitsData.name}
                                          selected >
                                          {unitsData.name}
                                        </option>
                                        :
                                        <option
                                          id={"Packing_units_"+index}
                                          value={unitsData.name}
                                          title={unitsData.name} >
                                          {unitsData.name}
                                        </option>
                                      ))
                                    :
                                      ""}
                                      <option value="addNew">+{t("addNew")}</option>
                                  </Input> 
                                  <span className="error-msg"></span>
                                </td>

                                <td>
                                  <Input
                                    disabled={packEditStatus}
                                    id={"Packing_pricePerUnit_"+index}
                                    name="PolybagPricePerUnit"
                                    placeholder={t("pricePerUnit")}
                                    defaultValue={mapData.pricePerUnit}
                                    onChange={(e)=> { OnlyNumbersValidation(e, "pricePerUnit", "Packing_pricePerUnit_"+index),AddTotalPrice("Packing_totalQty_"+index,"Packing_pricePerUnit_"+index,"Packing_totalPrice_"+index) }}
                                    onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Packing_pricePerUnit_"+index)}
                                  />
                                  <span  id={"Packing_pricePerUnit_"+index+"_Error"} className="error-msg"></span>
                                </td>
                                <td>
                                    <Input
                                     disabled={packEditStatus}
                                      id={"Packing_totalPrice_"+index}
                                      name="PolybagTotalPrice"
                                      defaultValue = {mapData.totalPrice}
                                      placeholder={t("totPrice")}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Packing_totalPrice_"+index)}
                                    readOnly/>
                                    <span id={"Packing_totalPrice_"+index+"_Error"} className="error-msg"></span>            
                                </td>
                                <tr>
                                  <td className="text-center"   
                                      style={{  backgroundColor: '#fff',  border: 'none', paddingTop: '1.5rem' }} >
                                        {packEditStatus == true 
                                        ?
                                            <img
                                              width="23px"
                                              height="23px"
                                              style={{ cursor: 'no-drop', paddingBottom: '0px', paddingLeft: '0px' }}
                                              src={labelSampleIcon}
                                            />
                                        :
                                        <img
                                            className="labelSampleIcon"
                                            width="23px"
                                            height="23px"
                                            style={{ cursor: 'pointer', marginTop: '10px' }}
                                            onClick={() => { 
                                              setAccessType("Edit"),
                                              getOrderMaterialsLabelApiCall(),
                                              setLabelSampleType("Packing_Type_"+index),
                                              setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                            }}
                                            src={labelSampleIcon}
                                        />
                                    }
                                  </td>
                                </tr>
                            
                              </tr>
                            </>
                          )

                        })} 

                        { packAccessoriesRowData.map((mapData, index)=>{
                          var index = packAdditionalIdValues[index];
                          return(
                            <>
                              <tr key={index}>
                                <td>
                                  <Input
                                    style={{ textTransform: "capitalize" }}
                                    id={"Packing_Type"+"_"+index}
                                    placeholder={t("type")}
                                    defaultValue={mapData.Type}
                                    onChange={(e)=> { emptyValidation2(e.target.value, "Packing_Type_"+index+"_Error")}}
                                    >
                                  </Input>
                                  <span id={"Packing_Type_"+index+"_Error"} className="error-msg" style={{ fontSize: '12px' }}></span>
                                </td>
                                {(Object.keys(mapData))
                                .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                                .map((objMapData) => {
                                  return(
                                    <>
                                      <td>
                                        {
                                          objMapData == "vendor" ? 
                                          <>
                                          <Input
                                            id={"Packing_vendor_"+index}
                                            type="select"
                                            className="form-control digits selectheight"
                                            name="Vendor Information"
                                            onChange={(e) =>{ 
                                              e.target.value!="addNew"?
                                              setHangtagVendor(e.target.value): viewinformation("Packing_vendor_"+index) }}>
                                            <option selected disabled value={0}>
                                            {t("selectVendor")} 
                                            </option>
                                            { vendorList.length > 0 
                                            ? 
                                              (vendorList).map((vendorData) => 
                                              (
                                                mapData.vendor == vendorData.id ?
                                                <option
                                                  id={"Packing_vendor_"+index}
                                                  value={vendorData.id}
                                                  title={vendorData.vendor_name}
                                                  selected >
                                                  {vendorData.vendor_name}
                                                </option>
                                                :
                                                <option
                                                  id={"Packing_vendor_"+index}
                                                  value={vendorData.id}
                                                  title={vendorData.vendor_name} >
                                                  {vendorData.vendor_name}
                                                </option>
                                              ))
                                            :
                                              ""
                                            }
                                          <option value="addNew">+{t("addNew")}</option>
                                          </Input>
                                          <span className="error-msg"></span>
                                          </>
                                          :   
                                            objMapData == "units" ? 
                                            <>
                                              <Input
                                                type="select"
                                                id={"Packing_"+objMapData+"_"+index}
                                                placeholder={t("units")}
                                                onChange={(e) =>{ 
                                                  e.target.value =="addNew"?
                                                  dropVal("Packing_"+objMapData+"_"+index) :""}}
                                              >
                                              
                                              <option selected disabled value={0}>  {t("selectUnits")} </option>
                                              {Object.values(unitsList).length > 0 ? 
                                                  Object.values(unitsList).map((unitsValue) => 
                                                  (
                                                    mapData.units == unitsValue.name ?
                                                    <option
                                                      id={"Packing_"+objMapData+"_"+index}
                                                      value={unitsValue.name}
                                                      title={unitsValue.name} 
                                                      selected>
                                                      {unitsValue.name}
                                                    </option>
                                                    :
                                                    <option
                                                      id={"Packing_"+objMapData+"_"+index}
                                                      value={unitsValue.name}
                                                      title={unitsValue.name}>
                                                      {unitsValue.name}
                                                    </option>
                                                  ))
                                                :
                                                  ""}
                                                  <option value="addNew">+{t("addNew")}</option>
                                              </Input> 
                                              <span className="error-msg"></span>
                                            </>
                                            :
                                            <>
                                              <Input
                                                id={"Packing_"+objMapData+"_"+index}
                                                name={"Packing_"+objMapData+"_"+index}
                                                disabled={ objMapData == "totalPrice" ? true : false}
                                                defaultValue={
                                                  objMapData == "component" ? mapData.component :
                                                  objMapData == "color" ? mapData.color :
                                                  objMapData == "perGMT" ? mapData.perGMT :
                                                  objMapData == "totalQty" ? mapData.totalQty :
                                                  objMapData == "leadTime" ? mapData.leadTime :
                                                  objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                  objMapData == "totalPrice" ? mapData.totalPrice :
                                                  ""
                                                }
                                                placeholder={
                                                  objMapData == "component" ? t("component") :
                                                  objMapData == "color" ? t("color") :
                                                  objMapData == "perGMT" ? t("perGMT") :
                                                  objMapData == "totalQty" ? t("totalQty") :
                                                  objMapData == "leadTime" ? t("noOfDays") :
                                                  objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                  objMapData == "totalPrice" ? t("totPrice")  :
                                                  ""
                                                }
                                                onChange={ (e) => {
                                                  ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                  (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                   (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                   (AddTotalPrice("Packing_totalQty_"+index,"Packing_pricePerUnit_"+index,"Packing_totalPrice_"+index),
                                                   OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index))
                                                   :
                                                   OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index)
                                                     
                                                   :
                                                     "")
                                                 }}
                                                  onKeyPress={(e) => { 
                                                    (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime")|| (objMapData == "totalPrice") ?
                                                  OnlyNumbersValidation(e, objMapData, "Packing_"+objMapData+"_"+index)
                                                  :
                                                 ""
                                                }}
                                                >
                                              </Input>
                                              <span  id={"Packing_"+objMapData+"_"+index+"_Error"} className="error-msg"></span>
                                            </>

                                        }
                                      </td>
                                    </>
                                  ) 
                                })}
                                <tr>
                                  <td className="text-center"   
                                      style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.5rem' }} >
                                          <img
                                              className="labelSampleIcon"
                                              width="23px"
                                              height="23px"
                                              style={{ cursor: 'pointer', marginTop: '10px' }}
                                              onClick={() => { 
                                                setAccessType("Edit"),
                                                getOrderMaterialsLabelApiCall(),
                                                setLabelSampleType(
                                                    "Packing_Type"+"_"+index ? 
                                                    "Packing_Type"+"_"+index : 
                                                    ""),
                                                setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                              }}
                                              src={labelSampleIcon}
                                            />
                                          <img
                                            className="labelSampleIcon"
                                            width="25px"
                                            height="25px"
                                            style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                            onClick={() => { 
                                              deletePackAccessoriesTableRows(index) }}
                                            src={DeleteRowIcon}
                                          /> 
                                  </td>
                                </tr>
                              </tr>
                            </>
                          )
                        })}
                        
                        {/* Add Icon */} 
                        <tr>
                          <td colSpan={10}></td>
                          <td width="100%" className="f-right">
                            {
                            packEditStatus == true 
                            ?
                              ""
                            :
                              <img 
                                className="cursor-pointer p-r-20" 
                                style={{ float: "right" }} 
                                onClick={()=>{
                                  setPackCount(packCount+1), 
                                  addPackAccessoriesTableRows()
                                }} 
                                src={Add_icon} />
                            }  
                          </td>
                        </tr>

                    </Table>
                  </div>
                  </ScrollBar>
            </Row>

          </Card>
        </Row>

        {/* Miscellaneous */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-10px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>
            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead">  {t("miscellaneous")} </H6>
                  </span>
                </Col>
            </Row>

            <Row style={{ marginTop: '10px' }}>
              <ScrollBar  options={{ suppressScrollX: true }}>
              <div className="table-responsive tableResScroll">
                    <Table className="tableRowCol reducedTablePadding tableViewRes">

                      <tr style={{ fontSize: "13px", backgroundColor: '#DCF0EF' }}>
                          <th> {t("type")} </th>
                          <th className="text-center">{t("component")}</th>
                          <th className='text-center'>{t("color")}</th>
                          <th className='text-center'>{t("vendorInformation")}</th>
                          <th className='text-center'>{t("perGMT")}</th>
                          <th className='text-center'>{t("totalQty")}</th>
                          <th className='text-center'>{t("leadTime")}</th>
                          <th className='text-center'>{t("units")}</th>
                          <th className='text-center'>{t("pricePerUnit")}</th>
                          <th className="text-center">{t("totPrice")}</th>
                          <th className='text-center'>{t("labelSample")}</th>
                      </tr>

                      <tr>
                          <td className="p-t-0 p-b-0" id={"Removed_Misc_Ids_"+miscRemovedIdValues}>
                            <span className="error-msg"></span>           
                          </td>
                      </tr>

                      {/* Miscellaneous */}
                      { miscellaneous.map((mapData, index) => {
                        var index = mapData.id ? mapData.id : index;
                        return(
                          <>
                            <tr>
                              <td>
                                <>
                                  <Input
                                    style={{ textTransform: "capitalize" }}
                                    id={"Miscellaneous_Type_"+index}
                                    name="Miscellaneous"
                                    placeholder={t("type")}
                                    defaultValue={mapData.Type}
                                    onChange={(e)=> { emptyValidation2(e.target.value, "Miscellaneous_Type_"+index+"_Error")}}>
                                  </Input>
                                  <span className="error-msg" ></span>
                                  </>
                              </td>

                              <td width="12%">   
                                <Input
                                  id={"Miscellaneous_component_"+index}
                                  name="Miscellaneous"
                                  placeholder={t("component")}
                                  defaultValue={mapData.component}
                                >
                                </Input> 
                                <span className="error-msg"></span>
                              </td>

                              <td width="10%">
                                <Input
                                  id={"Miscellaneous_color_"+index}
                                  name="Miscellaneous"
                                  placeholder={t("color")}
                                  defaultValue={mapData.color}
                                >
                                </Input>
                                <span className="error-msg"></span>

                              </td>

                              <td width="12%"> 
                                <Input
                                    type="select"
                                    id={"Miscellaneous_vendor_"+index}
                                    className="form-control digits selectheight"
                                    name="Vendor Information"
                                    onChange={(e) =>{ 
                                      e.target.value!="addNew"?
                                      setPolybagVendor(e.target.value): 
                                      viewinformation("Miscellaneous_vendor")
                                    }}
                                  >
                                    <option selected disabled value={0}>
                                    {t("selectVendor")}
                                    </option>
                                    { vendorList.length > 0 
                                    ? 
                                      (vendorList).map((vendors) => 
                                      (
                                        mapData.vendor == vendors.id ?
                                        <option
                                          id={"Miscellaneous_vendor"+index}
                                          value={vendors.id}
                                          title={vendors.vendor_name} 
                                          selected>
                                          {vendors.vendor_name}
                                        </option>
                                        :
                                        <option
                                          id={"Miscellaneous_vendor"+index}
                                          value={vendors.id}
                                          title={vendors.vendor_name}>
                                          {vendors.vendor_name}
                                        </option>                                        
                                      ))
                                    :
                                      ""
                                    }
                                  <option value="addNew">+{t("addNew")}</option>
                                </Input>
                                <span className="error-msg"></span>

                              </td>

                              <td>
                                <Input
                                  id={"Miscellaneous_perGMT_"+index}
                                  name="MiscellaneousPerGMT"
                                  placeholder={t("perGMT")}
                                  defaultValue={mapData.perGMT}
                                  onChange={(e) => { OnlyNumbersValidation(e, "perGMT", "Miscellaneous_perGMT_"+index)}}
                                  onKeyPress={(e) => OnlyNumbersValidation(e, "perGMT", "Miscellaneous_perGMT_"+index)}
                                />
                                <span id={"Miscellaneous_perGMT_"+index+"_Error"} className="error-msg"></span>

                              </td>

                              <td>
                                <Input
                                  id={"Miscellaneous_totalQty_"+index}
                                  name="PolybagTotalQty"
                                  placeholder={t("totalQty")}
                                  defaultValue={mapData.totalQty}
                                  onChange={(e) => { OnlyNumbersValidation(e, "totalQty", "Miscellaneous_totalQty_"+index),AddTotalPrice("Miscellaneous_totalQty_"+index,"Miscellaneous_pricePerUnit_"+index,"Miscellaneous_totalPrice_"+index)}}
                                  onKeyPress={(e) => OnlyNumbersValidation(e, "totalQty", "Miscellaneous_totalQty_"+index)}
                                />
                                <span id={"Miscellaneous_totalQty_"+index+"_Error"} className="error-msg"></span>

                              </td>
                              <td>
                                    <Input
                                      id={"Miscellaneous_leadTime_"+index}
                                      name="LeadTime"
                                      placeholder={t("noOfDays")}
                                      defaultValue={mapData.leadTime}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"LeadTime","Miscellaneous_leadTime_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"LeadTime","Miscellaneous_leadTime_"+index)}
                                    />
                                    <span id={"Miscellaneous_leadTime_"+index+"_Error"} className="error-msg"></span>            
                                  </td>
                            
                              <td width="10%">
                                <Input
                                  type="select"
                                  id={"Miscellaneous_units_"+index}
                                  placeholder={t("units")}
                                  onChange={(e) =>{ 
                                    e.target.value =="addNew"?
                                    dropVal("Miscellaneous_units_"+index) :""}}
                                
                                >
                                <option selected disabled value={0}>  {t("selectUnits")}  </option>
                                {Object.values(unitsList).length > 0 ? 
                                    Object.values(unitsList).map((unitsData) => 
                                    (
                                      mapData.units == unitsData.name ?
                                        <option
                                          id={"Packing_units_"+index}
                                          value={unitsData.name}
                                          title={unitsData.name}
                                          selected >
                                          {unitsData.name}
                                        </option>
                                        :
                                        <option
                                          id={"Packing_units_"+index}
                                          value={unitsData.name}
                                          title={unitsData.name} >
                                          {unitsData.name}
                                        </option>
                                    ))
                                  :
                                    ""}
                                    <option value="addNew">+{t("addNew")}</option>
                                </Input> 
                                <span className="error-msg"></span>

                              </td>

                              <td>
                                <Input
                                  id={"Miscellaneous_pricePerUnit_"+index}
                                  name="PolybagPricePerUnit"
                                  placeholder={t("pricePerUnit")}
                                  defaultValue={mapData.pricePerUnit}
                                  onChange={(e) => { OnlyNumbersValidation(e, "PricePerUnit", "Miscellaneous_pricePerUnit_"+index),AddTotalPrice("Miscellaneous_totalQty_"+index,"Miscellaneous_pricePerUnit_"+index,"Miscellaneous_totalPrice_"+index)}}
                                  onKeyPress={(e) => OnlyNumbersValidation(e, "PricePerUnit", "Miscellaneous_pricePerUnit_"+index)}
                                />
                                <span  id={"Miscellaneous_pricePerUnit_"+index+"_Error"} className="error-msg"></span>

                              </td>
                              <td>
                                    <Input
                                      id={"Miscellaneous_totalPrice_"+index}
                                      name="PolybagTotalPrice"
                                      defaultValue = {mapData.totalPrice}
                                      placeholder={t("totPrice")}
                                      onChange={(e) => { 
                                        OnlyNumbersValidation(e,"TotalPrice","Miscellaneous_totalPrice_"+index)
                                      }}
                                      onKeyPress={(e) => OnlyNumbersValidation(e,"TotalPrice","Miscellaneous_totalPrice_"+index)}
                                    disabled/>
                                    <span id={"Miscellaneous_totalPrice_"+index+"_Error"} className="error-msg"></span>            
                                </td>
                              <td 
                                style={{  backgroundColor: '#fff', paddingTop: '1.13rem' }} 
                                className="f-left">
                                  <img
                                    width="32px"
                                    height="32px"
                                    style={{ cursor: 'pointer', paddingBottom: '0px', paddingLeft: '0px' }}
                                    onClick={() => {
                                      setAccessType("Edit"),
                                      getOrderMaterialsLabelApiCall(), 
                                      setLabelSampleType("Miscellaneous_Type_"+index),
                                      setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                    }}
                                    src={labelSampleIcon}
                                  />
                                  {/* { index >= 1 ?
                                    <img
                                      className="labelSampleIcon"
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                      onClick={() => { deleteMiscellaneousTableRows(index) }}
                                      src={DeleteRowIcon}
                                    /> 
                                  :
                                    ""
                                  } */}
                              </td>

                            </tr>
                          </>
                        )
                       })}

                      {miscellaneousRowData.map((mapData, index)=>{
                        var index = miscAdditionalIdValues[index];
                        return(
                          <>
                            <tr key={index}>
                              <td>
                                <Input
                                    style={{ textTransform: "capitalize" }}
                                    id={"Miscellaneous_Type"+"_"+index}
                                    placeholder={t("type")}
                                    defaultValue={mapData.Type}
                                    onChange={(e)=> { emptyValidation2(e.target.value, "Miscellaneous_Type_"+index+"_Error")}}
                                  >
                                </Input>
                                <span id={"Miscellaneous_Type_"+index+"_Error"} 
                                      className="error-msg" 
                                      style={{ fontSize: '12px' }}></span>
                              </td>
                              {(Object.keys(mapData))
                              .filter(filterData => filterData !== 'id' && filterData !== 'Type')
                              .map((objMapData) => {
                                return(
                                  <>
                                    <td>
                                      {
                                        objMapData == "vendor" ? 
                                        <>
                                          <Input
                                            id={"Miscellaneous_vendor_"+index}
                                            type="select"
                                            className="form-control digits selectheight"
                                            name="Vendor Information"
                                            onChange={(e) =>{ 
                                              e.target.value!="addNew"?
                                              setHangtagVendor(e.target.value): viewinformation("Miscellaneous_vendor_"+index) }}>
                                            <option selected disabled value={0}>
                                              {t("selectVendor")}
                                            </option>
                                            { vendorList.length > 0 
                                            ? 
                                              (vendorList).map((vendorData) => 
                                              (
                                                mapData.vendor == vendorData.id ?
                                                <option
                                                  id={"Miscellaneous_vendor_"+index}
                                                  value={vendorData.id}
                                                  title={vendorData.vendor_name} 
                                                  selected>
                                                  {vendorData.vendor_name}
                                                </option>
                                                :
                                                <option
                                                  id={"Miscellaneous_vendor_"+index}
                                                  value={vendorData.id}
                                                  title={vendorData.vendor_name} >
                                                  {vendorData.vendor_name}
                                                </option>
                                              ))
                                            :
                                              ""
                                            }
                                          <option value="addNew">+{t("addNew")}</option>
                                          </Input>
                                          <span className="error-msg"></span> 
                                        </>

                                        : 
                                          objMapData == "units" ? 
                                            <>
                                              <Input
                                                type="select"
                                                id={"Miscellaneous_"+objMapData+"_"+index}
                                                placeholder={t("units")}
                                                onChange={(e) =>{ 
                                                  e.target.value =="addNew"?
                                                  dropVal("Miscellaneous_"+objMapData+"_"+index) :""}}
                                              
                                              >
                                              <option selected disabled value={0}> {t("selectUnits")} </option>
                                              {Object.values(unitsList).length > 0 ? 
                                                  Object.values(unitsList).map((unitsValue) => 
                                                  (
                                                    mapData.units == unitsValue.name ?
                                                    <option
                                                      id={"Miscellaneous_"+objMapData+"_"+index}
                                                      value={unitsValue.name}
                                                      title={unitsValue.name} 
                                                      selected>
                                                      {unitsValue.name}
                                                    </option>
                                                    :
                                                    <option
                                                      id={"Miscellaneous_"+objMapData+"_"+index}
                                                      value={unitsValue.name}
                                                      title={unitsValue.name} >
                                                    {unitsValue.name}
                                                    </option>
                                                  ))
                                                :
                                                  ""}
                                              </Input>  
                                              <span className="error-msg"></span> 
                                            </>
                                          : 
                                            <>
                                              <Input
                                                id={"Miscellaneous_"+objMapData+"_"+index}
                                                defaultValue={
                                                  objMapData == "component" ? mapData.component :
                                                  objMapData == "color" ? mapData.color :
                                                  objMapData == "perGMT" ? mapData.perGMT :
                                                  objMapData == "totalQty" ? mapData.totalQty :
                                                  objMapData == "leadTime" ? mapData.leadTime :
                                                  objMapData == "pricePerUnit" ? mapData.pricePerUnit :
                                                  objMapData == "totalPrice" ? mapData.totalPrice :
                                                  ""
                                                }
                                                placeholder={
                                                  objMapData == "component" ? t("component") :
                                                  objMapData == "color" ? t("color") :
                                                  objMapData == "perGMT" ? t("perGMT") :
                                                  objMapData == "totalQty" ? t("totalQty") :
                                                  objMapData == "leadTime" ? t("noOfDays") :
                                                  objMapData == "pricePerUnit" ? t("pricePerUnit")  :
                                                  objMapData == "totalPrice" ? t("totPrice")  :
                                                  ""
                                                }
                                                onChange={ (e) => {
                                                  ( (objMapData == "perGMT") || (objMapData == "totalQty") || (objMapData == "pricePerUnit") || 
                                                  (objMapData == "leadTime") || (objMapData == "totalPrice")?
                                                   (objMapData == "totalQty") || (objMapData == "pricePerUnit") ?
                                                   (AddTotalPrice("Miscellaneous_totalQty_"+index,"Miscellaneous_pricePerUnit_"+index,"Miscellaneous_totalPrice_"+index),
                                                   OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index))
                                                   :
                                                   OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index)
                                                     
                                                   :
                                                     "")
                                                 }}
                                                onKeyPress={(e) => { 
                                                  (objMapData == "perGMT") || (objMapData == "totalQty")|| (objMapData == "pricePerUnit") || (objMapData == "leadTime")|| (objMapData == "totalPrice")  ?
                                                  OnlyNumbersValidation(e, objMapData, "Miscellaneous_"+objMapData+"_"+index)
                                                  :
                                                  ""
                                                }}
                                                >
                                              </Input>
                                              <span  id={"Miscellaneous_"+objMapData+"_"+index+"_Error"} className="error-msg"></span> 
                                            </>

                                      }
                                    </td>
                                  </>
                                ) 
                              })}

                              <tr>
                                <td className="text-center"   
                                    style={{  backgroundColor: '#fff',  border: 'none', paddingRight: '0px', paddingTop: '0.5rem' }} >
                                    <img
                                        className="labelSampleIcon"
                                        width="23px"
                                        height="23px"
                                        style={{ cursor: 'pointer', marginTop: '10px' }}
                                        onClick={() => { 
                                          setAccessType("Edit"),
                                          getOrderMaterialsLabelApiCall(),
                                          setLabelSampleType(
                                              "Miscellaneous_Type"+"_"+index ? 
                                              "Miscellaneous_Type"+"_"+index : 
                                              ""),
                                          setAddMaterialsLabelsImageModal(!addMaterialsLabelsImageModal)
                                        }}
                                        src={labelSampleIcon}
                                      />
                                    <img
                                      className="labelSampleIcon"
                                      width="35px"
                                      height="35px"
                                      style={{ cursor: 'pointer', marginLeft: '5px', marginTop: '13px' }}
                                      onClick={() => { deleteMiscellaneousTableRows(index) }}
                                      src={DeleteRowIcon}
                                    /> 
                                </td>
                              </tr>

                            </tr>
                          </>
                        )
                      })} 
                  
                      {/* Add Icon */} 
                      <tr>
                        <td colSpan={10}></td>
                        <td width="100%" className="f-right">
                        <img 
                            className="cursor-pointer p-r-20" 
                            style={{ float: "right" }} 
                            onClick={() => { 
                              setMiscellaneousCount(miscellaneousCount+1), 
                              addMiscellaneousTableRows() 
                            }} 
                            src={Add_icon} />
                        </td>
                      </tr>

                    </Table>
                  </div>
                  </ScrollBar>
            </Row>

          </Card>
        </Row>

        {/* Others */}
        <Row style={{ paddingLeft: '40px', paddingRight: '40px', marginTop: '-10px'}}>
          <Card className="m-t-10 shadow shadow-md" style={{  padding: '15px',  border: 'none' }}>

            <Row className="g-12">
                <Col lg="12" md="12" sm="12" xs="12">
                  <span>
                    <H6 className="ordersubhead"> {t("others")} </H6>
                  </span>
                </Col>
            </Row>

            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label> {t("measurementSheet")} </Label>
                  <InputGroup>
                    <Input
                      name="Measurement Sheet"
                      value={measurementSheetPdfName ? measurementSheetPdfName : ""}
                      placeholder={t("attachMeasurementSheet")}
                      disabled>
                    </Input>
                    <Files
                      className="files-dropzone fileContainer"
                      accept=".pdf, .xls, .xlsx, .jpeg, .jpg, .png"
                      multiple={false}
                      canCancel={false}
                      onChange={files => {
                        setMeasurementSheetLabelSample("MeasurementSheet"),
                        uploadImageValidation("MeasurementSheet", files)}}
                      clickable>
                      <InputGroupText className="btn imgBackground">
                        <img
                          src={uploadDocIcon}
                          width="25px"
                          height="25px"
                          type="file"
                        ></img>
                      </InputGroupText>
                    </Files>
                  </InputGroup>
                </FormGroup>
              </Col>
            {/* </Row> */}

            {/* <Row style={{ width: '30%', paddingLeft: '10px' }}> */}

              <Col lg="8">
                <Row>
                  {uploadedImageList.length > 0 
                  ?
                  uploadedImageList
                  .filter( filterData => filterData.media_type == measurementSheetLabelSample )
                  .map((file) => (
                    <>
                    <Col lg="4">
                      <tr>
                        <td  width="5%">
                          {(file.orginalfilename).split(".")[1] == "pdf" ?
                            <img src={pdfDocIcon} />
                          :
                          (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                          <img src={excelDocIconIcon} />
                          :
                          <img src={docIcon} />
                          }
                        </td>
                        <td className="verticalAlignmentStart">
                          <p className="f-left f-12 f-w-600 m-l-5 m-t-35 ">{truncate(file.orginalfilename)}</p >
                        </td>
                        <td align="right" valign="center" width="5%">
                          <a href="javascript:void(0)" 
                            title={t("deleteFile")} 
                            onClick={() => deleteImageFiles("MeasurementSheet", file)}>
                            <img src={deleteDocIcon} />
                          </a>
                        </td>
                      </tr>
                    </Col>
                    </>
                  ))
                  :
                  ""}
                </Row>
              </Col>
            </Row>
              
            <Row className="m-t-10">
              <Col lg="4">
                <FormGroup>
                  <Label>  {t("techPack")} </Label>
                  <InputGroup>
                    <Input
                      name="TechPack"
                      value={techPackPdfName ? techPackPdfName : ""}
                      placeholder={t("attachTechPack")}
                      disabled
                    ></Input>
                    <Files
                      className="files-dropzone fileContainer"
                      accept=".pdf, .xls, .xlsx, .jpeg, .jpg, .png"
                      multiple={false}
                      canCancel={false}
                      onChange={files => {
                        setTechPackLabelSample("TechPack"), 
                        uploadImageValidation("TechPack", files)}}
                      clickable
                    >
                      <InputGroupText className="btn imgBackground">
                        <img
                          src={uploadDocIcon}
                          width="25px"
                          height="25px"
                          type="file"
                        ></img>
                      </InputGroupText>
                    </Files>
                  </InputGroup>
                </FormGroup>
              </Col>
      
              <Col lg="8">
                <Row>
                  {uploadedImageList.length > 0 
                  ?
                  uploadedImageList.filter( filterData => filterData.media_type == techPackLabelSample )
                  .map((file) => (
                  <>
                    <Col lg="4">
                    <tr>
                      <td width="5%">
                      {(file.orginalfilename).split(".")[1] == "pdf" ?
                        <img src={pdfDocIcon} />
                      :
                      (file.orginalfilename).split(".")[1] == "xls" || (file.orginalfilename).split(".")[1] == "xlsx" ?
                      <img src={excelDocIconIcon} />
                      :
                      <img src={docIcon} />
                      }
                      </td>
                      <td className="verticalAlignmentStart">
                        <p className="f-left f-12 f-w-600 m-l-5 m-t-35">{truncate(file.orginalfilename)}<br />
                        </p>
                      </td>
                      <td valign="center" width="5%">
                          <a href="javascript:void(0)" 
                              title={t("deleteFile")} 
                              onClick={() => deleteImageFiles("TechPack", file)}>
                              <img src={deleteDocIcon} />
                          </a>
                      </td>
                    </tr>
                    </Col>
                  </>
                  ))
                  :
                    ""}
                </Row>
              </Col>
            </Row>

          </Card>
        </Row> 
		
        <Row className="f-right">
          <FormGroup className="f-right m-r-25 ">
                <Button
                  className="btn btn-primary"
                  onClick={(e) => { onUpdateBillOfMaterials()}} >
                  {t("update")}
                </Button>
          </FormGroup>
        </Row>

      </Col>

      <AddMaterialsLabelsImageModal
        modal={addMaterialsLabelsImageModal}
        toggle={toggleAddMaterialsLabelsImageModal}
        labelSampleType={labelSampleType}
        orderId={orderId}   
        uploadedImageList={uploadedImageList}   
        uploadedImageName={uploadedImageName}  
        serverURL={serverURL} 
        getOrderMaterialsLabelApiCall={getOrderMaterialsLabelApiCall}
        accessType={accessType}
        />

      { /*****PaymentTerms,penalty,No of ply,Patterns,Carton Edge Finish Drop Down "+Add More" ****/ }
      <AddNewDropdownModal
        modal={showAddNew}
        toggle={toggleAddNew}
        masterType={masterType}
        orderId={orderId}
      />
      <AddNewUnitModal
        modal={showUnitAddNew}
        toggle={toggleUnitAddNew}
        setUnitsList={setUnitsList}
        companyId ={company_id}
        workspaceId={workspace_id} 
        userId ={user_id}
        staffId ={staff_id}
        toggleType="addEditBOM"
        />
      <VendorListModal 
          modal={ViewVendorListModal} 
          setVendorList={setVendorList}
          vendorDetails={vendorDetails} 
          Selectedvendor_id={vendor_idDetails} 
          toggleViewVendorList={toggleViewVendorListModal} 
          />

    </Fragment>
  );
};

export default EditMaterialsLabels;
/***** CODE BY : RAMANAN M  *****/