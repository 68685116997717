import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col,Input,Label, Button } from "reactstrap";
import { Btn } from "../../AbstractElements";
import { getLoginCompanyId, getWorkspaceId  } from "../../Constant/LoginConstant";
import { Close, SaveChanges , ServerUrl} from "../../Constant";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";
const FilterOffCanvas = ({ modal, toggle, PICs, orderNo, taskDetails,
  filterDays,filterOperator,filterPIC }) => {
  const dayss = [1, 2, 3, 4, 5, 6, 7, 8, 9,10];
  const { t } = useTranslation();
  const [days, setDay] = useState("0");
  const [PIC, setPIC] = useState("0");
  const [operator, setOperator] = useState("0");
  const submitFunction = ()=>{
      let picId = document.getElementById("pic").value;
      let operator = document.getElementById("operator").value;
      let day = document.getElementById("days").value;
      let dataToSend = {
        company_id : getLoginCompanyId,
        workspace_id : getWorkspaceId,
        orderNo : orderNo,
      };
      if(picId !="0"){
        dataToSend.picId = picId;
        filterPIC(picId);
      }
      if(day != "0" && operator !="0"){
        dataToSend.noOfDays = (-day);
        dataToSend.selector = operator;
        filterDays(-day);
        filterOperator(operator);
      }
      axios.post(ServerUrl + "/get-pending-tasks-list", apiencrypt(dataToSend))
          .then((response) =>{
            response.data = apidecrypt(response.data)
            taskDetails(response.data.data.styleDetails);
            toggle();
          })
  };
  const clearFunction=()=>{
    let dataToSend = {
        company_id : getLoginCompanyId,
        workspace_id : getWorkspaceId,
        orderNo : orderNo,
      };
      setDay("0");
      setOperator("0");
      setPIC("0");
      filterDays("0");
      filterOperator("0");
      filterPIC("0");
      axios.post(ServerUrl + "/get-pending-tasks-list", apiencrypt(dataToSend))
          .then((response) =>{
            response.data = apidecrypt(response.data)
            taskDetails(response.data.data.styleDetails);
            toggle();
          })
  };
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"}>
      <OffcanvasHeader className="bg-primary offcanvas-header"><i className="fa fa-filter f-24"></i> {t("filter")} 
        <span className="f-right cursor-pointer" title={t("close")} onClick={toggle}>X</span>
      </OffcanvasHeader> 
      <OffcanvasBody>
        <Row className="p-2">
          <Col md={3}>
            <Label>{t("operator")}</Label>
            <Input type="select" id="operator" onChange={(e)=>setOperator(e.target.value)}>
              {operator === "="?<option value="=" selected>=</option> : <option value="=">=</option>}
              {/* Note :: The less than and greater than value are changed due to 
              dealing with the negative values in the backend*/}
              {operator === "<="?<option value="<=" selected>{'>='}</option> : <option value="<=">{'>='}</option>}
              {operator === ">="?<option value=">=" selected>{'<='}</option> : <option value=">=">{'<='}</option>}
            </Input>
          </Col>
          <Col md={9}>
                <Label>{t("noOfDaysDelay")}</Label>
                <Input type="select" defaultValue={days} id="days" onChange={(e)=>setDay(e.target.value)}>
                  <option value="0">{t("days")}</option>
                  {/* Note: The Value is in negative values to get the delay */}
                  {dayss.map((day,i)=>(
                   days === day? <option key={i} value={day} selected>{day}</option> : <option key={i} value={day}>{day}</option>
                  ))}
                </Input>
          </Col>
        </Row>
        <Row className="p-2">
          <Col>
              <Label>{t("personInCharge")}</Label>
              <Input type="select" defaultValue={PIC} name="pic" id="pic" onChange={(e)=>setPIC(e.target.value)}>
              <option value="0">{t("selectPIC")}</option>
              {Object.values(PICs).map((pic)=>(
                PIC == pic.id? <option key={pic.id} value={pic.id} selected>{pic.name}</option> : 
                <option key={pic.id} value={pic.id}>{pic.name}</option>
              ))}
              </Input>
          </Col>
        </Row>
      </OffcanvasBody>
      <footer className="m-20 p-2">
        <Button  title={t("click")} className="btn secondaryBtn" onClick={ ()=>clearFunction() }>{t("clear")}</Button>
        <div className="f-right">
          <Btn title={t("click")} attrBtn={{ color: "primary", onClick : ()=>submitFunction()  }}>
            {t("search")}
          </Btn>
        </div>
      </footer>
    </Offcanvas>
  );
};

export default FilterOffCanvas;
/* Code By : Rithanesh */