import React, { Fragment, useState,useEffect } from 'react';
import { Card, Col, Table, Collapse,CardBody } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import CardHeader from 'react-bootstrap/esm/CardHeader';
import downarrow_icon from '../../../assets/images/dms/icons/downarrow_icon.svg';
import { ServerUrl } from '../../../Constant/index';
import noData from "../../../assets/images/dms/nodata.png"
import axios from 'axios';
import { Accordion } from "react-bootstrap";
import {
  getLoginCompanyId, getLoginUserId,
  getWorkspaceId, getLoginStaffId
} from '../../../Constant/LoginConstant';
import { useTranslation } from 'react-i18next';
import ScrollBar from 'react-perfect-scrollbar';
import { apiencrypt, apidecrypt } from '../../../helper';
import v1cutgreen_icon from '../../../assets/images/dms/icons/v1cutgreen_icon.svg';
import Sewv1green_icon from '../../../assets/images/dms/icons/Sewv1green_icon.svg';
import packv1_icon from '../../../assets/images/dms/icons/packv1_icon.svg';
import downarrow_animation from '../../../assets/images/dms/icons/downarrow_animation.gif';
const ProductiondelayTable = (props) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const [user_id, setUser_id] = useState(getLoginUserId);
  const [delayproduction, settopdelayprod] = useState([]);
  const [toggleOpen, setToggleOpen] = useState(0);
  const [isOpen, setIsOpen] = useState(0);
  const { t } = useTranslation();
  const toggle = (i) => (isOpen === i ? setIsOpen(null) : setIsOpen(i));
  useEffect(() => {
   
      axios
        .post(ServerUrl + "/get-top-delay-prod", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
        }))
        .then((response) => {
          response.data = apidecrypt(response.data)
          settopdelayprod(response.data.forTableView);
        })
    },[])
 
  const getSmiley = (delay) => {
    let date = Math.abs(delay);
    return (
      <td style={{ padding: "0.3rem",paddingLeft:"1.5rem" }} className="font-danger f-w-600 text-center">
        <img className="img-30 p-0" src={downarrow_animation} /> &nbsp;{date}
      </td>);
  }

  return (
    <Fragment>
      <Col xs="12" sm="12" md="12" lg="12" xl="6" className="box-col-12 m-t-5">
             <div className="default-according" id="accordion">
              <Card className=' collopseradise' >
                <CardHeader  style={{  borderBottom: 'none', borderRadius: '0.75rem' }}>
                  <p className=" p-l-5 f-18 f-w-600">{t("top5DelayedProduction")}</p>
                </CardHeader>
              
                  {delayproduction.length>0 ? 
                  <CardBody className="p-0 " style={{ height: "350px", borderRadius: '0.75rem' }}>
                  <ScrollBar className="" options={{ suppressScrollX: true }} >
                  <div className="table-responsive">
                    <Table className="tableRowCol ">
                        <tr style={{ fontSize: "13px", backgroundColor: '#F4F4F4' }}>
                          <th style={{ padding: "0.3rem",paddingLeft:"1.5rem" }} className='text-uppercase'>{t("styleNo")}</th>
                          <th style={{ padding: "0.5rem",paddingLeft:"3.5rem" }} className="text-center text-uppercase">{t("productionStatus")}</th>
                          <th style={{ padding: "0.5rem",paddingLeft:"3.5rem" }}  className='text-center text-uppercase'>{t("noofdaysdelays")}</th>
                        </tr>
                      {delayproduction.map((production, index) =>
                      (
                        <>
                            <tr className={`${ index+1 !== delayproduction.length ? "removeBottomBorder" : ""}`}>
                              <td  className='cursor-pointer'  tooltip={production.order_no + " " + "/" + " " + production.style_no} 
                            alt={production.order_no + " " + "/" + " " + production.style_no} title={production.order_no + " " + "/" + " " + production.style_no}
                              style={{ padding: "0.3rem",paddingLeft:"1.5rem" }}> {production.style_no}</td>
                              {production.type_of_production == "Cut" ? <td   className="text-center" style={{color:"#13D09A",padding: "0.5rem",paddingLeft:"3.5rem"}}><img src={v1cutgreen_icon}></img>{t("cutting")}</td> : ""}
                              {production.type_of_production == "Pack" ? <td  className="text-center" style={{color:"#5159AC",padding: "0.5rem",paddingLeft:"3.5rem"}}><img src={Sewv1green_icon}></img>{t("packing")}</td> : ""}
                              {production.type_of_production == "Sew" ? <td   className="text-center" style={{color:"#13A1C1",padding: "0.5rem",paddingLeft:"3.5rem"}}><img  src={packv1_icon}></img>{t("sewing")}</td> : ""}
                              {production.delay === null ? <td >-</td> : getSmiley(production.delay, production.type)}
                            </tr>
                            
                        </>
                      )
                      )}
                    </Table>
                  </div>
                  </ScrollBar>
                  </CardBody>
                : 
                  <>
                    <div  style={{ height: "104px" }}></div>
                    <div className="p-5 align-self-center media-body centerAlign" style={{ height: "350px" }}>
                        <img src={noData} style={{ width: "200px" }} />
                        <p className='f-18 m-t-10' >-- {t("noData")} --</p>
                    </div>
                  </>
                }
              
              </Card>
            </div>
      </Col>
    </Fragment>
  );
};

export default ProductiondelayTable;

//**************************Code by : Praveen****************************//