import React, { Fragment, useState, useEffect,useRef  } from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import {
  Form,
  Label,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  FormGroup,
  Table
} from "reactstrap";

import { H6, Btn } from "../../../AbstractElements";

import AddColorModal from "../CreateOrders/AddColorModal";
import AddSizeModal from "../CreateOrders/AddSizeModal";
import AddCategoryModal from "../CreateOrders/AddCategoryModal";
import OrderHeaders from "../CreateOrders/OrderHeaders";

import Swal from 'sweetalert2';
import { ServerUrl } from "../../../Constant";
import axios from "axios";
import { useSearchParams, Link } from "react-router-dom";
import { encode, decode, calculateDateDiffCountFromCurrentDate, apiencrypt, apidecrypt } from "../../../helper"
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getLoginStaffId, getStaff, getStaffPermission}
  from '../../../Constant/LoginConstant';

import { useTranslation } from 'react-i18next';

const EditSku = (props) => {

  const toggle = () => setModal(!modal);
  const togglepcu = () => setModalpcu(!modalpcu);

  // JSON.parse() 
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderId, setOrderId] = useState(decode(searchParams.get("id")));
  const [qtyArray, setQtyArray] = useState(JSON.parse(decode(searchParams.get("qty_arr"))));
  const [quantity, setTotalQuantity] = useState(decode(searchParams.get("quantity")));

  const [modal, setModal] = useState(false);
  const [modalpcu, setModalpcu] = useState(false);
  const [color, setColor] = React.useState([]);
  const [showcolor, setshowColor] = React.useState([]);
  const [size, setSize] = React.useState([]);
  const [showsize, setshowSize] = React.useState([]);
  const [getColor, setGetColor] = React.useState([]);
  const [getSize, setGetSize] = React.useState([]);
  const [skuDetails, setSkuDetails] = React.useState([]);
  const [skuCuttingDate, setSkuCuttingDate] = useState('');
  const [basicInfo, setBasicInfo] = useState([]);
  const [toleranceVolume, setToleranceVolume] = React.useState("");
  const [tolerancePercent, setTolerancePercent] = React.useState("");
  const [toleranceReq, setToleranceReq] = React.useState("");

  const [stepLevel, setStepLevel] = useState("");
  const [datecount, setdatecount] = useState(0);

  const { t } = useTranslation();

  /********************* [INPUT PARAMS for API Calls] (To add Order SKU)****************************/
  var getInputParams = {};
  getInputParams['company_id'] = getLoginCompanyId;
  getInputParams['workspace_id'] = getWorkspaceId;
  getInputParams['user_id'] = getLoginUserId;
  getInputParams['staff_id'] = getLoginStaffId;
  getInputParams['order_id'] = orderId;

  var getOrderSkuInputParams = {};
  getOrderSkuInputParams['company_id'] = getLoginCompanyId;
  getOrderSkuInputParams['workspace_id'] = getWorkspaceId;
  getOrderSkuInputParams['order_id'] = orderId;

  let basicInformation = {};
  
  /******************** SELECTED COLOR LIST ******************************/
  const handleChangeColor = (e) => {
    var id = e.nativeEvent.target.selectedIndex;
    var idvc = e.nativeEvent.target[id].value;
    var name = e.nativeEvent.target[id].text;
    var colorary = [];
    colorary['id'] = idvc;
    colorary['name'] = name;
    var t = showcolor;
    var index = t.indexOf(name)
    if (index == -1) {
      setshowColor([...showcolor, name]);
      setColor([...color, colorary]);
    }
  };

  /********************* SELECTED SIZE LIST ******************************/
  const handleChangeSize = (e) => {
    var id = e.nativeEvent.target.selectedIndex;
    var idvs = e.nativeEvent.target[id].value;
    var name = e.nativeEvent.target[id].text;
    var sizeary = [];
    sizeary['id'] = idvs;
    sizeary['name'] = name;

    var t = showsize;
    var index = t.indexOf(name)
    if (index == -1) {
      setshowSize([...showsize, name]);
      setSize([...size, sizeary]);
    }
  };

  /************************************* COLOR TOTAL **********************/
  const addQty = (e) => {

    if ( qtyArray ? qtyArray.is_tolerance_req == "0" : basicInfo.is_tolerance_req === "0") {
      var idv = e.target.id;
      var value = e.target.value;

      /***** Split the ID values of Size and Color *****/
      var sptv = idv.split("#");
      var color_id = sptv[0];
      var size_id = sptv[1];
      /************************************************/

      var totQty = 0;
      size.forEach((e) => {
        var getsizeid = e.id;
        var t = document.getElementById(color_id + "#" + getsizeid).value;
        if (parseInt(t) > 0) {
          totQty += parseInt(t);
        }
        document.getElementById("totqty_" + color_id).value = totQty;
      })
      overallTotalQty(e);
      sizeTotalQty(e)
    }
    else if (qtyArray ? qtyArray.is_tolerance_req == "1" : basicInfo.is_tolerance_req === "1") {

      var idv = e.target.id;
      var value = e.target.value;

      /***** Split the ID values of Size and Color *****/
      var sptv = idv.split("#");
      var color_id = sptv[0];
      var size_id = sptv[1];
      /************************************************/

      var totSumQty = 0;
      size.forEach((e) => {
        var getsizeid = e.id;
        var actualValue = document.getElementById(color_id + "#" + getsizeid).value;
        var toleranceValue = (actualValue / 100) * tolerancePercent;

        var totalToleranceValue = Math.round(toleranceValue);
        document.getElementById(color_id + "@" + getsizeid).value = totalToleranceValue;

        var overallTotalValue = Number(actualValue) + Number(totalToleranceValue);
        document.getElementById(color_id + "+" + getsizeid).value = overallTotalValue;

        if (parseInt(qtyArray ? qtyArray.is_tolerance_req  : basicInfo.is_tolerance_req ) == 1 ) {
          document.getElementById(color_id + "v" + getsizeid).innerHTML = t("quantityLabel") + "+" + tolerancePercent + " % = " + overallTotalValue;
        }
        if (parseInt(overallTotalValue) > 0) {
          totSumQty += parseInt(overallTotalValue);
        }
        document.getElementById("totqty_" + color_id).value = totSumQty;

      })
      overallTotalQty(e);
      sizeTotalQty(e);
    }
  };

  /**************************************** SIZE TOTAL **********************/
  const sizeTotalQty = (e) => {
    if (qtyArray ? qtyArray.is_tolerance_req == 0 : basicInfo.is_tolerance_req === "0") {
      var id = e.target.id;
      var value = e.target.value;

      var splitId = id.split("#");
      var color_id = splitId[0];
      var size_id = splitId[1];

      var tot = 0;
      color.forEach((e) => {
        var colorId = e.id;
        var qtyValue = document.getElementById(colorId + "#" + size_id).value;
        if (parseInt(qtyValue) > 0) {
          tot += parseInt(qtyValue);
        }
        document.getElementById("SizeId_total_quantity" + size_id).value = tot;
      })
    }
    else if ( qtyArray ? qtyArray.is_tolerance_req == 1 : basicInfo.is_tolerance_req === "1"){
      var id = e.target.id;
      var value = e.target.value;

      var splitId = id.split("#");
      var color_id = splitId[0];
      var size_id = splitId[1];

      var tot = 0;
      color.forEach((e) =>{

        var colorId = e.id;
        var actualValue = document.getElementById(colorId + "#" + size_id).value;
        var toleranceValue = (actualValue / 100) * tolerancePercent;

        var totalToleranceValue = Math.round(toleranceValue);
        document.getElementById(colorId + "@" + size_id).value = totalToleranceValue;

        var overallTotalValue = Number(actualValue) + Number(totalToleranceValue);
        document.getElementById(colorId + "+" + size_id).value = overallTotalValue;

        if (parseInt(overallTotalValue) > 0) {
          tot += parseInt(overallTotalValue)
        }
        document.getElementById("SizeId_total_quantity" + size_id).value = tot;
      })

    }
  };

  /************************************* OVERALL TOTAL **********************/
  const overallTotalQty = (e) => {
    var id = e.target.id;
    var value = e.target.value;

    var splitId = id.split("#");
    var color_id = splitId[0];
    var size_id = splitId[1];

    var sum = 0;
    color.forEach((data) => {
      var totalQtyValue = document.getElementById("totqty_" + data.id).value;
      if (parseInt(totalQtyValue) > 0) {
        sum += parseInt(totalQtyValue);
      }
    })
    if ( qtyArray ? qtyArray.is_tolerance_req == 1 :  basicInfo.is_tolerance_req === "1") {
      let totalSum = sum + qtyArray ? qtyArray.tolerance_volume : basicInfo.tolerance_volume;
      document.getElementById("Overall_total_quantity").value = sum;
    }
    else {
      let totalSum = sum;
      document.getElementById("Overall_total_quantity").value = sum;
    }
  }

  /*********************** PAYLOAD - INPUT PARAMS [API CALL]  ****************/
  const getQtyDetails = (e) => {
    if ( qtyArray ? qtyArray.is_tolerance_req == 0 :  basicInfo.is_tolerance_req === "0") {
      var skuDet = {}
      const breakOut = false;
      var g = 0;
      color.forEach((c) => {
        size.forEach((e) => {
          g++;
          var tt = document.getElementById(c.id + "#" + e.id).value;
          if (tt != '' && !parseInt(tt) < 0) {
            Swal.fire({
              title: t("selectCorrectColor/SizeQtyValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else if ((tt != '' && !parseInt(tt) && parseInt(tt) != 0) || (parseInt(tt) < 0)) {
            Swal.fire({
              title: t("selectCorrectNumberValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else {
            var skuData = {};
            skuData['color_id'] = c.id
            skuData['size_id'] = e.id
            skuData['quantity'] = tt == '' ? 0 : tt;
            skuDet[g] = skuData;
          }
        });
      });
      return skuDet;
    }
    else if (qtyArray ? qtyArray.is_tolerance_req == 1 :  basicInfo.is_tolerance_req === "1") {

      var skuDet = {}
      const breakOut = false;
      var g = 0;
      color.forEach((c) => {
        size.forEach((e) => {
          g++;
          var t = document.getElementById(c.id + "#" + e.id).value;
          var tolerancePer =  qtyArray ? qtyArray.tolerance_perc : basicInfo.tolerance_perc;
          var overallValue = Math.round(Number(t)+Number(Number(t)*(Number(tolerancePer/100))));
          if (overallValue != '' && !parseInt(overallValue) < 0) {
            Swal.fire({
              title: t("selectCorrectColor/SizeQtyValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else if ((overallValue != '' && !parseInt(overallValue) && parseInt(overallValue) != 0) || (parseInt(overallValue) < 0)) {
            Swal.fire({
              title: t("selectCorrectNumberValidation"),
              icon: "warning",
              button: t("okLabel"),
            }).then((result) => {
              document.getElementById(c.id + "#" + e.id).focus();
            });

            breakOut = true;
            return false;
          } else {
            var skuData = {};
            skuData['color_id'] = c.id
            skuData['size_id'] = e.id
            skuData['quantity'] = overallValue == '' ? 0 : overallValue;
            skuDet[g] = skuData;
          }
        });
      });
      return skuDet;

    }
  }

  /************************************ (SWAL) VALIDATION  ******************/
  const addSkuData = async (e) => {
    let sku = getQtyDetails();
    if (Object.keys(sku).length === 0) {
      Swal.fire({
        title: t("selectColor/SizeValidation"),
        icon: "warning",
        button: t("okLabel"),
      })
    }
    else {
      findTotalQty();
    }
  }

  //////////////////////////////////////////////////////////////////////////
  /********************************** API CALL (add-order-sku)  *************/
  const findTotalQty = () => {

    let sku = getQtyDetails();
    var qty = quantity;
    var arr = document.getElementsByName('totalQuantity');
    var total = 0;

    for (var i = 0; i < arr.length; i++) {
      if (parseInt(arr[i].value)) {
        total += parseInt(arr[i].value);
      }
    }

    if (total == qty) {
      let updatedTotalQty = document.getElementById("Overall_total_quantity").value;
      axios
        .post(ServerUrl + "/update-sku", apiencrypt({
          "company_id": getLoginCompanyId,
          "workspace_id": getWorkspaceId,
          "order_id": orderId,
          "sku": sku,
          "total_qty": updatedTotalQty,
          "user_id": getLoginUserId,
          "staff_id": getLoginStaffId ? getLoginStaffId : 0,
          "qty_arr": qtyArray
        }))
        .then((response) => {
          response.data = apidecrypt(response.data);
          if (response.data.status_code === 200) {
            Swal.fire({
              title: t(response.data.message),
              icon: "success",
              button: t("okLabel"),
              timer: 5000
            })
              .then((result) => {
                if (result.isConfirmed) {
                  // setTimeout(() => {
                    window.location.href = '/orderview?id=' +
                      encode(orderId);
                  // }, 100);
                }
                // setTimeout(() => {
                  window.location.href = '/orderview?id=' +
                    encode(orderId);
                // }, 100);
              })
          }
        })
    }
    else {

      if (!total > 0) {
        Swal.fire({
          title: t("updateQtyValidation"),
          text: t("updateSkuQtyValidation"),
          icon: "warning",
          button: t("okLabel"),
        })
      } else {
        let totalQty = qtyArray? Number(qtyArray.total_quantity) + Number(qtyArray.tolerance_volume) : 
                                 Number(basicInfo.quantity) + Number(toleranceVolume);
        let updatedTotalQty = document.getElementById("Overall_total_quantity").value;
        if (Number(totalQty) === Number(updatedTotalQty)) {
          setTotalQuantity(total)
          axios
            .post(ServerUrl + "/update-sku", apiencrypt({
              "company_id": getLoginCompanyId,
              "workspace_id": getWorkspaceId,
              "order_id": orderId,
              "sku": sku,
              "total_qty": updatedTotalQty,
              "user_id": getLoginUserId,
              "staff_id": getLoginStaffId ? getLoginStaffId : 0,
              "qty_arr": qtyArray
            }))
            .then((response) => {
              response.data = apidecrypt(response.data);
              if (response.data.status_code === 200) {
                Swal.fire({
                  title: t(response.data.message),
                  icon: "success",
                  button: t("okLabel"),
                })
                  .then((result) => {
                    if (result.isConfirmed) {
                      setTimeout(() => {
                        window.location.href = '/orderview?id=' +
                          encode(orderId);
                      }, 100);
                    }
                    setTimeout(() => {
                      window.location.href = '/orderview?id=' +
                        encode(orderId);
                    }, 100);
                  })
              }
            })

        }
        else {
          Swal.fire({
            title: t("totalQuanitityDiffer",{totalQty:totalQty,updatedTotalQty:updatedTotalQty}),
            text: t("qtyDiffersTextAlert"),
            icon: "warning",
            button: t("okLabel"),
            showCancelButton: true,
          })
            .then((result) => {
              if (result.isConfirmed) {
                setTotalQuantity(total)
                axios
                  .post(ServerUrl + "/update-sku", apiencrypt({
                    "company_id": getLoginCompanyId,
                    "workspace_id": getWorkspaceId,
                    "order_id": orderId,
                    "sku": sku,
                    "total_qty": updatedTotalQty,
                    "user_id": getLoginUserId,
                    "staff_id": getLoginStaffId ? getLoginStaffId : 0,
                    "qty_arr": qtyArray
                  }))
                  .then((response) => {
                    response.data = apidecrypt(response.data);
                    if (response.data.status_code === 200) {
                      Swal.fire({
                        title: t(response.data.message),
                        icon: "success",
                        button: t("okLabel"),
                        timer: 5000
                      })
                        .then((result) => {
                          if (result.isConfirmed) {
                            setTimeout(() => {
                              window.location.href = '/orderview?id=' +
                                encode(orderId);
                            }, 100);
                          }
                          setTimeout(() => {
                            window.location.href = '/orderview?id=' +
                              encode(orderId);
                          }, 100);
                        })
                    }
                  })
              }
            });
        }
      }
    }
  }

  /********************************************* DELETE COLOR  *************/
  const deleteColor = (e) => {
    var getColor=e.target.id;
    Swal.fire({
      title: t("colorDeleteAlert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete"),
      confirmButtonColor: '#d33',
      allowOutsideClick: false,
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if(result.isConfirmed)
      {
    setshowColor(current =>
      current.filter((showcolor, v) => {
        return showcolor != getColor;
      }),
    );
    setColor(currentv =>
      currentv.filter((color, vf) => {
        return color.name != getColor;
      }),
    );
    document.getElementById("Overall_total_quantity").value=0;
    getTotalColorWise('delete');
    getTotalSizeWise('delete');
    getOverallTotal('delete');
  }
})
  };

  /************************************************  DELETE SIZE  *************/
  const deleteSize = (e) => {
    var getSize=e.target.id;
    Swal.fire({
      title: t("sizeDeleteAlert"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("delete"),
      confirmButtonColor: '#d33',
      allowOutsideClick: false,
      cancelButtonText: t("cancel")
    }).then((result) => {
      if(result.isConfirmed)
      {
        setshowSize(current =>
          current.filter((showcolor, v) => {
            return showcolor !=getSize;
          }),
        );
        setSize(currentv =>
          currentv.filter((color, vf) => {
            return color.name != getSize;
          }),
        );
        document.getElementById("Overall_total_quantity").value=0;
        getTotalColorWise('delete');
        getTotalSizeWise('delete');
        getOverallTotal('delete');
      }
})
  };

  /*************************************** UPDATED SKU QUANTITY  *************/
  const getSelectedColorandSize = (skuData) => {

    const colorarry = [];
    const colormularry = [];
    const sizearry = [];
    const sizemularry = [];
    skuData.forEach((cs) => {
      var color_id = cs.color_id;
      var color_name = cs.color_name;
      var size_id = cs.size_id;
      var size_name = cs.size_name;

      /*Assign Color data to state*/
      if (color_id != '') {
        var colorary = [];
        colorary['id'] = color_id;
        colorary['name'] = color_name;
        var t = colorarry;
        var index = t.indexOf(color_name)

        if (index == -1) {
          colorarry.push(color_name);
          colormularry.push(colorary);
        }
      }
      /*Assign Size data to state*/
      if (size_id != '') {
        var szary = [];
        szary['id'] = size_id;
        szary['name'] = size_name;
        var t = sizearry;
        var index = t.indexOf(size_name)

        if (index == -1) {
          sizearry.push(size_name);
          sizemularry.push(szary);
        }
      }

    });
    setshowColor(colorarry);
    setColor(colormularry);

    setshowSize(sizearry);
    setSize(sizemularry);

    getassignSkuQty(skuData);

  }

  /***************************** (DISPLAYS) UPDATED SKU QUANTITY  *************/
  const getassignSkuQty = (skuData) => {

    let total = 0;
    skuData.forEach( (data) => {
      const id = data.color_id + "#" + data.size_id;
      const tolId = data.color_id + "v" + data.size_id;
         
      const tolerancePercent = qtyArray ? qtyArray.tolerance_perc : basicInformation.data.tolerance_perc;
       
      if( qtyArray ? qtyArray.is_tolerance_req : basicInformation.data.is_tolerance_req == "1"){
        qtyArray ? qtyArray.tolerance_perc : ""
        var qty = (((data.quantity) * 100) / ((100) + (basicInformation.data.tolerance_perc)));

        var toleranceValue = ((qty/100)*tolerancePercent);
        document.getElementById(id).value = Math.round(qty);
        document.getElementById(tolId).innerHTML =
                                         t("quantityLabel") + "+" + 
                                         (qtyArray ? qtyArray.tolerance_perc : basicInformation.data.tolerance_perc) + 
                                         " % = " + 
                                         (Math.round(qty) + Math.round(toleranceValue));
        total += (Math.round(qty) + Math.round(toleranceValue));
      }
      else {
        total += data.quantity;
        document.getElementById(id).value = data.quantity;
      }
    });

    document.getElementById("Overall_total_quantity").value = Math.round(total); 
  }

  /********************* (DISPLAYS) UPDATED TOTAL COLOR QUANTITY  *************/
  const assignTotalColorQty = (skuData) => {
    let colorIdList = [];
    skuData.forEach((element) => {
      if (!colorIdList.includes(element.color_id)) {
        colorIdList.push(element.color_id);
      }
    });
  
  for( let i=0; i<=colorIdList.length; i++){
    let colorTotal = 0;
    skuData.forEach((data) => {
      if(colorIdList[i] == data.color_id){
          if( qtyArray ? qtyArray.is_tolerance_req == 1 : basicInfo.is_tolerance_req === "1")
          {
            if( qtyArray ){
              var toleranceValue = Math.round((data.quantity/100) * qtyArray.tolerance_perc); 
              var overallValue = Number(data.quantity) + Number(toleranceValue);
              colorTotal += overallValue; 
            } else{
              var toleranceValue = Math.round((data.quantity/100) * basicInfo.tolerance_perc); 
              var overallValue = Number(data.quantity) + Number(toleranceValue);
              colorTotal += overallValue; 
            }
          } else{
            colorTotal += data.quantity; 
          }
      }
    })

    function resolveAfter2Seconds(x) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(x);
        }, 100);
      });
    }

    async function updateColorTotal() {
      const x = await resolveAfter2Seconds(5);
      document.getElementById("totqty_" + colorIdList[i]).value = Math.round(colorTotal);
    }
    
    updateColorTotal();
  }
  };

  /********************** (DISPLAYS) UPDATED TOTAL SIZE QUANTITY  *************/
  const assignTotalSizeQty = (skuData) => {
    let sizeIdList = [];
    skuData.forEach((element) => {
      if (!sizeIdList.includes(element.size_id)) {
        sizeIdList.push(element.size_id);
      }
  });
  for( let i=0; i<=sizeIdList.length; i++){
    let sizeTotal = 0;
    skuData.forEach((data) => {
      if(sizeIdList[i] == data.size_id){
        if( qtyArray ? qtyArray.is_tolerance_req == 1 : basicInfo.is_tolerance_req === "1"){
          if( qtyArray ){
            var toleranceValue = Math.round((data.quantity/100) * qtyArray.tolerance_perc); 
            var overallValue = Number(data.quantity) + Number(toleranceValue);
            sizeTotal += overallValue; 
          } else{
            var toleranceValue = Math.round((data.quantity/100) * basicInfo.tolerance_perc); 
            var overallValue = Number(data.quantity) + Number(toleranceValue);
            sizeTotal += overallValue; 
          }
        } else{
          sizeTotal += data.quantity;
        }
      }
    })
    document.getElementById("SizeId_total_quantity" + sizeIdList[i]).value = Math.round(sizeTotal);
  }
       
  };

  const [ sizeCategoryList, setSizeCategoryList ] = useState([]);
  const [ allSkuDetails, setAllSkuDetails ] = useState({});
  const [ selCategoryName, setSelCategoryName ] = useState('');
  const [ categoryName, setCategoryName ] = useState(0);
  const toggleCategory = () => setModalCategory(!modalCategory);
  const [modalCategory, setModalCategory] = useState(false);

  var getCategoryInputParams = {};
      getCategoryInputParams['company_id'] = getLoginCompanyId;
      getCategoryInputParams['workspace_id'] = getWorkspaceId;
      getCategoryInputParams['user_id'] = getLoginUserId;
      getCategoryInputParams['staff_id'] = getLoginStaffId;

    const getAllOrderSkuApiCall = ( categoryValue ) => {
  
      axios
      .post(ServerUrl + "/get-all-sku", apiencrypt(getInputParams))
      .then((response) => {
        response.data = apidecrypt(response.data);
        const sizes = response.data.data.sizes;
        const filteredData = sizes.filter(data => data.category == categoryValue );
        setGetSize(filteredData);
        setAllSkuDetails(response.data.data);
        setGetColor(response.data.data.colors);
      });
    
    };

  useEffect(async () => {

   axios
    .post(ServerUrl + "/get-basic-info", apiencrypt(getInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      basicInformation['data'] = response.data.data[0];
      setStepLevel(response.data.data[0].step_level);
      setBasicInfo(response.data.data[0]);
      setTolerancePercent(qtyArray ? qtyArray.tolerance_perc : response.data.data[0].tolerance_perc);
      setToleranceVolume(qtyArray ? qtyArray.tolerance_volume : response.data.data[0].tolerance_volume);
      setSkuCuttingDate(response.data.data[0].cutting_start_date);
      setToleranceReq(qtyArray ? qtyArray.is_tolerance_req : response.data.data[0].is_tolerance_req)
      if (response.data.data[0].cutting_start_date != null) {
        const dateCountv = calculateDateDiffCountFromCurrentDate(response.data.data[0].cutting_start_date);
        setdatecount(dateCountv);
      } else {
        setdatecount('1');
      }

      if(response.data.data[0].status != 1 )
      {
        window.location.href = "/dashboard" 
      }
      else {
        ""
      }
    });

   axios
  .post(ServerUrl + "/get-size-category", apiencrypt(getCategoryInputParams))
  .then((response) => {
    response.data = apidecrypt(response.data);
    setSizeCategoryList(response.data.data);
  });

   axios
    .post(ServerUrl + "/get-order-sku", apiencrypt(getOrderSkuInputParams))
    .then((response) => {
      response.data = apidecrypt(response.data);
      getAllOrderSkuApiCall(response.data.data[0].category);
      const skuDet = response.data.data;
      setSelCategoryName(response.data.data[0].category);
      setCategoryName(response.data.data[0].category);
      setSkuDetails(skuDet);
      getSelectedColorandSize(skuDet);
      // assignTotalColorQty(skuDet);
      // assignTotalSizeQty(skuDet);
    });

  
    
  
    {
      (((getStaff === "Staff" && getStaffPermission.includes('Edit Order'))||getStaff == null )) 
      ?
      ""
      :
      window.location.href = "/dashboard" 
    } 
    
  }, [])

  if (skuCuttingDate != null) {
    // const dateCountv = calculateDateDiffCountFromCurrentDate(regCuttingDate);
    //setdatecount(dateCountv);
  }

  /*********** MOVES TO NEXT INPUT FIELD ( WHEN ENTER IS CLICKED ) **************/
  function handleEnter(event) {

    if ((event.keyCode === 13 || event.keyCode === 9) && event.target.nodeName === "INPUT") {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);

      if (form.elements[index + 1].readOnly == false) {
        form.elements[index + 1].focus();

      } else {

        if (form.elements[index + 2].readOnly == false) {
          form.elements[index + 2].focus();

        } else {
          // let index=0;
          if (form.elements[index + 3].readOnly == false) {
            form.elements[index + 3].focus();

          } else {
            if (form.elements[index + 4].readOnly == false) {
              let indexv = index + 4;
              form.elements[indexv].focus();
            } else {
              let index = 0;
              form.elements[index].focus();
            }
          }
        }
      }

    }
    // event.preventDefault();
  }

  /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
  function handleKeyPress(e) {
    var key = e.key;
    var regex = /[0-9]/;
    if( !regex.test(key) ) {
        e.preventDefault();
    }
}

  /************************************ UPDATED QTY TOTAL (COLOR)  *************/
  const getTotalColorWise = (typ=null) => 
  {
    let tqty = 0;
    if (color.length > 0 && size.length > 0) {
      color.forEach((colorData) => {
        let i = 0;
        let astotval = 0;
        size.forEach((sizeData) => {
          document.getElementById(colorData.id+"v"+sizeData.id).innerHTML = ""; 
          let element = document.getElementById(colorData.id + "#" + sizeData.id);
          if (typeof (element) != 'undefined' && element != null) {
            if(typ=='delete'){
              document.getElementById(colorData.id + "#" + sizeData.id).value='';
              let t = 0;
             }else{
            let t = document.getElementById(colorData.id + "#" + sizeData.id).value;
            astotval += Number(t);
             }
           document.getElementById("totqty_"+colorData.id).value = astotval;
          }
        });
      });
    }
  }

  /************************************ UPDATED QTY TOTAL (SIZE)  *************/
  const getTotalSizeWise = (typ=null) => 
  {
    let tqty = 0;
    if (color.length > 0 && size.length > 0) {
    size.forEach((sizeData) => {
        let i = 0;
        let astotval = 0;
        color.forEach((colorData) => {
         document.getElementById(colorData.id+"v"+sizeData.id).innerHTML = ""; 

          let element = document.getElementById(sizeData.id + "#" + colorData.id);
          if (typeof (element) != 'undefined' && element != null) {
          if(typ=='delete'){
            document.getElementById(sizeData.id + "#" + colorData.id).value='';
            let t = 0;
          }else{
            let t = document.getElementById(sizeData.id + "#" + colorData.id).value;           
          }
          astotval += Number(t);
          }
          document.getElementById("SizeId_total_quantity" + sizeData.id).value = astotval;
        });
      });
    }
  }

  /************************************ UPDATED QTY TOTAL (OVERALL)  *************/
  const getOverallTotal = () => {
  var sum = 0;
  color.forEach( (colorData) => {
    var colorTotalQty =  document.getElementById("totqty_" + colorData.id).value;
    if( colorTotalQty > 0 ){
      sum += Number(colorTotalQty);
    }
  })
  }

  /************** SELECTED SIZE CATEGORY LIST ***************************/
  const handleChangeCategory = (categoryName) => 
    {
     // document.getElementById('categoryId').selectedIndex = 0;
     setGetSize(() => "");
     setSize(() => "");
     setshowSize(() => "");
     setCategoryName(categoryName);
     const sizes = allSkuDetails.sizes;
     const filteredData = sizes.filter(data => data.category == categoryName );
     setGetSize(filteredData);
  };

/* Size Drag start */
const dragItem = useRef();
const dragOverItem = useRef();
const dragItemv = useRef();
const dragOverItemv = useRef();
const dragStartSize = (e, position) => {
  dragItem.current = position;
  dragItemv.current = position;
};

const dragEnterSize = (e, position) => {
  dragOverItem.current = position;
  dragOverItemv.current = position;
};

const dropSize = (e) => {
  const copyListItems = [...size];
  const dragItemContent = copyListItems[dragItem.current];
  copyListItems.splice(dragItem.current, 1);
  copyListItems.splice(dragOverItem.current, 0, dragItemContent);
  dragItem.current = null;
  dragOverItem.current = null;
 
  setSize(copyListItems);

  const copyListItemss = [...showsize];
  const dragItemContentss = copyListItemss[dragItemv.current];
  copyListItemss.splice(dragItemv.current, 1);
  copyListItemss.splice(dragOverItemv.current, 0, dragItemContentss);
  dragItemv.current = null;
  dragOverItemv.current = null;
   setshowSize(copyListItemss);
   reAssignFieldValue();
  };
/* Size Drag End */
/* color Drag start */

const dragItemColor = useRef();
const dragOverItemColor = useRef();
const dragItemvColorv = useRef();
const dragOverItemvColorv = useRef();
const dragStartColor = (e, position) => {
dragItemColor.current = position;
dragItemvColorv.current = position;
};

const dragEnterColor = (e, position) => {
dragOverItemColor.current = position;
dragOverItemvColorv.current = position;
};

const dropColor = (e) => {
const copyListItemsc = [...color];
const dragItemContentc = copyListItemsc[dragItemColor.current];
copyListItemsc.splice(dragItemColor.current, 1);
copyListItemsc.splice(dragOverItemColor.current, 0, dragItemContentc);
dragItemColor.current = null;
dragOverItemColor.current = null;

setColor(copyListItemsc);

const copyListItemscv = [...showcolor];
const dragItemContentssc = copyListItemscv[dragItemvColorv.current];
copyListItemscv.splice(dragItemvColorv.current, 1);
copyListItemscv.splice(dragOverItemvColorv.current, 0, dragItemContentssc);
dragItemvColorv.current = null;
dragOverItemvColorv.current = null;
 setshowColor(copyListItemscv);
 reAssignFieldValue();
};

/* ReAssign Color and Size Qty zero After Drag */
const reAssignFieldValue=()=>{
  Array.from(document.querySelectorAll('.inpwidthsmall')).forEach(
    input => (input.value = "")
  );
}
/* color Drag end */
  return (
    <Fragment>
      <Col md="12" sm="12" lg="12">
        <Card>

          <CardBody>
            <OrderHeaders title="addsku" />
          </CardBody>

          <CardBody>
            <Form className="needs-validation" noValidate="">
              {/*start first row */}
               <Card className="shadow shadow-case">
                <CardBody>
                  <Row className="g-12">
                    {/*start order details */}
                    <Col lg="12" md="12" sm="12">
                      <H6>{t("sKUDetails")}</H6>
                      <Row className="g-12">
                        <Col lg="6" md="12" sm="12">
                          <FormGroup className="">
                            <Label>{t("color")}</Label>
                            <InputGroup>
                              <Input
                                type="select"
                                className="js-example-basic-single form-control"
                                isMulti
                                onChange={(e) => handleChangeColor(e)}>
                                <option selected disabled >{t("selectColor")}</option>
                                {getColor.map(colorList =>
                                (
                                  <>
                                    <option key={colorList.id} value={colorList.id}>
                                      {colorList.name}
                                    </option>
                                  </>
                                )
                                )
                                }
                              </Input>
                                {/* <Btn
                                  attrBtn={{ color: "primary", onClick: toggle }}>
                                  +Add
                                </Btn> */}
                                {(getStaff === "Staff" && getStaffPermission.includes('Color Add')) || getStaff == null ?
                                <>
                                <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                    onClick={toggle}
                                  >
                                    {t("add")}
                                  </span>
                                </InputGroupText> 
                                <AddColorModal
                                  modal={modal}
                                  toggle={toggle}
                                  inputParams={getInputParams}
                                  color={setGetColor} />
                                </>
                                : ""
                              }
                            </InputGroup>
                          </FormGroup>
                          {showcolor.map((colour, index) =>
                          (
                            <span className="btn btn-primary m-r-5 m-t-5"
                              id={colour}
                              name={colour}
                              onDragStart={(e) => dragStartColor(e, index)}
                              onDragEnter={(e) => dragEnterColor(e, index)}
                              onDragEnd={dropColor}
                              key={index}
                              draggable
                              onClick={(e) => deleteColor(e)}>
                              {colour}
                            </span>

                          )
                          )}
                        </Col>
                        <Col lg="6" md="6" sm="12">
                          <FormGroup>
                            <Label> {t("sizeCategory")} </Label>
                            <InputGroup>
                              <Input 
                                id="categoryId"
                                type="select"
                                className="js-example-basic-single form-control"
                                onChange={(e) => {handleChangeCategory(e.target.value)}}>
                                <option selected value="0">{t("selSizeCategory")}</option>
                                {sizeCategoryList.map((mapData) => (
                                  <>
                                    {mapData.category == categoryName ?
                                      <option selected >{mapData.category}</option>
                                    :
                                      <option value={mapData.category} >{mapData.category}</option>
                                    }
                                  </>
                                ))}
                              </Input>
                              {(getStaff === "Staff" && getStaffPermission.includes('Size Add')) || getStaff == null ?
                                <InputGroupText>
                                  <span
                                    className="btn selectbtn"
                                    onClick={toggleCategory}
                                  >
                                    {t("add")}
                                  </span>
                                </InputGroupText> : ""
                              }
                              <AddCategoryModal
                                modal={modalCategory}
                                toggle={toggleCategory}
                                orderId={orderId}
                                setCategoryName={setCategoryName}
                                setAllSkuDetails={setAllSkuDetails}
                                setGetColor={setGetColor}
                                setGetSize={setGetSize}
                                setSizeCategoryList={setSizeCategoryList}
                              />
                            </InputGroup>
                          </FormGroup>

                          { categoryName !=0 && 
                          <Col lg="12" md="12" sm="12">
                            <FormGroup>
                              <Label>{t("size")}</Label>
                              <InputGroup>
                                <Input 
                                  id="sizeId"
                                  type="select"
                                  className="js-example-basic-single form-control"
                                  onChange={(e) => {handleChangeSize(e)}}>
                                  <option selected disabled >{t("selectSize")}</option>
                                  { 
                                    getSize.length > 0 ?
                                    getSize
                                    // .filter( filter => filter.category == selCategoryName)
                                    .map(sizeList =>
                                    (
                                      <option key={sizeList.id} value={sizeList.id} attr-name={sizeList.name}  >
                                        {sizeList.name}
                                      </option>
                                    )
                                    )
                                  :
                                    "" 
                                  }
                                </Input>
                                {(getStaff === "Staff" && getStaffPermission.includes('Size Add')) || getStaff == null ?
                                  <InputGroupText>
                                    <span
                                      className="btn selectbtn"
                                      onClick={togglepcu}
                                    >
                                      {t("add")}
                                    </span>
                                  </InputGroupText> : ""
                                }
                                <AddSizeModal
                                  modal={modalpcu}
                                  toggle={togglepcu}
                                  sizes={getSize}
                                  setGetSize={setGetSize}
                                  setAllSkuDetails={setAllSkuDetails}
                                  setGetColor={setGetColor}
                                  categoryName={categoryName}
                                  setCategoryName={setCategoryName}
                                  orderId={orderId}
                                  setSizeCategoryList={setSizeCategoryList}
                                />
                              </InputGroup>
                            </FormGroup>
                            {
                              showsize.length > 0 ? 
                              showsize.map((sizes,index) => (
                                <span className="btn btn-primary m-r-5 m-t-5" id={sizes}
                                onDragStart={(e) => dragStartSize(e, index)}
                                onDragEnter={(e) => dragEnterSize(e, index)}
                                onDragEnd={dropSize}
                                key={index}
                                draggable
                                  onClick={(e) => deleteSize(e)}>
                                  {sizes}
                                </span>
                              )) : ""
                            }
                          </Col>
                          }
                        </Col>
                      </Row>
                    </Col>
                    {/*end order details */}
                  </Row>
                </CardBody>
              </Card>
            {color.length > 0 && size.length > 0 ?
            <>
            <Card className="mt-5 shadow shadow-case">
              <CardBody>
              <Row className="g-12 m-t-20">
                {/*start Add Qty */}
                {color.length > 0 && size.length > 0
                  ?
                    <>
                      <Row style={{ borderBottom:"1px solid #f2f2f2", marginTop:"-30px" }}>
                        <Col xl="6" className="text-right m-b-20 d-flex align-items-center">
                          <H6>{t("addQty")}</H6>
                        </Col>  
                        <Col xl="6" className="verticalAlignmentEnd m-b-20" >
                          <table style={{backgroundColor: '#f2f2f2' }}>
                              <thead  className="f-w-300 text-center"
                                  style={{ fontSize: '13px'}}>
                                  <td style={{ padding: '10px',  backgroundColor: '#f2f2f2', borderTop: '2px solid #f2f2f2', }}>
                                    {t("totalQty")}
                                  </td>
                                  <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', }}>
                                    {t("toleranceQty")}
                                  </td>
                                  <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', }}>
                                    {t("tolerance%")}
                                  </td>
                                  <td style={{ padding: '10px',  backgroundColor: '#fff', borderTop: '2px solid #f2f2f2', 
                                            borderRight: '2px solid #f2f2f2' }}>
                                    {t("orderQty")}
                                  </td>
                              </thead>
                              <tbody className="f-w-600 text-center">
                                  <td style={{ padding: '10px' }}>
                                    { qtyArray ? 
                                      Number(qtyArray.total_quantity) + Number(qtyArray.tolerance_volume) : 
                                      basicInfo.is_tolerance_req === "1"
                                      ?
                                      Number(basicInfo.tolerance_volume)+Number(basicInfo.quantity)
                                      :
                                      basicInfo.quantity
                                    }
                                  </td>
                                  <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2' }} >
                                    { qtyArray ? qtyArray.tolerance_volume : basicInfo.tolerance_volume }
                                  </td>
                                  <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2' }} >
                                    { qtyArray ? qtyArray.tolerance_perc : basicInfo.tolerance_perc }
                                  </td>
                                  <td style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '2px solid #f2f2f2',
                                              borderRight: '2px solid #f2f2f2'}}>
                                    { qtyArray ? qtyArray.total_quantity : basicInfo.quantity }
                                  </td>
                              </tbody>
                          </table>
                        </Col>
                      </Row>

                       <Col md="12" lg="12" sm="12" className="m-t-20">

                        <Row className="g-12">
                          <div className="table-responsive">
                            <form>
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">{t("color/sizeLabel")}</th>
                                    {size.map((option) => {
                                      return <th>{option.name}</th>;
                                    })}
                                    <th scope="col">{t("totalLabel")}</th>{" "}
                                  </tr>
                                </thead>
                                <tbody>
                                  {color.map((optionc) => {
                                    return (
                                      <tr>
                                        <th>{optionc.name}</th>
                                        {size.map((option) => {
                                          return (
                                            <th>
                                              <Row>
                                                <Table className="table table-striped">
                                                  <tr>
                                                    <td>
                                                      <table>
                                                        <tbody className="f-w-600 text-center">
                                                          <tr>
                                                            <td style={{ padding: '0.1em' }}>
                                                              <input
                                                                style={{ width: '90px' }}
                                                                className=" form-control inpwidthsmall"
                                                                name="userName"
                                                                id={optionc.id + "#" + option.id}
                                                                type="number"
                                                                placeholder="0"
                                                                autocomplete="off"
                                                                min="0"
                                                                onChange={(e) => { addQty(e) }}
                                                                onKeyDown={handleEnter} 
                                                                onKeyPress={(e) => handleKeyPress(e)}
                                                                />

                                                              <input
                                                                type="hidden"
                                                                style={{ width: '90px' }}
                                                                id={optionc.id + "+" + option.id}
                                                                className=" form-control inpwidthsmall"
                                                                readOnly
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td style={{ padding: '0.1em' }}
                                                              className="showperqty">
                                                              <span id={optionc.id + "v" + option.id} className="showperqty">
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="hidden"
                                                        style={{ width: '90px', marginLeft: '10px' }}
                                                        id={optionc.id + "@" + option.id}
                                                        className=" form-control inpwidthsmall"
                                                        readOnly
                                                      />
                                                    </td>
                                                  </tr>
                                                </Table>
                                              </Row>
                                            </th>
                                          );
                                        })}
                                        <th>
                                          <input
                                            className="form-control inpwidthsmall totqtyc mt-3"
                                            name="totalQuantity"
                                            type="number"
                                            defaultValue="0"
                                            readOnly
                                            placeholder={t("totalQty")}
                                            id={"totqty_" + optionc.id} />
                                        </th>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <th></th>
                                    {size.map((data) => {
                                      return (
                                        <>
                                          <th>
                                            < input
                                              className="form-control inpwidthsmall"
                                              id={"SizeId_total_quantity" + data.id}
                                              type="number"
                                              placeholder="0"
                                              autocomplete="off"
                                              readOnly
                                            />

                                          </th>
                                        </>)
                                    })}
                                    <th>
                                      <input
                                        className="form-control inpwidthsmall"
                                        id="Overall_total_quantity"
                                        type="number"
                                        placeholder="0"
                                        autocomplete="off"
                                        readOnly
                                      />
                                    </th>
                                  </tr>

                                </tbody>
                              </table>
                            </form>
                          </div>
                        </Row>
                      </Col>
                    </>
                  :
                  <div></div>}
                {/*end Add qty */}
                <Col md="12" lg="12" sm="12">
                  &nbsp;
                </Col>
              </Row>
            </CardBody>
         </Card>
               
              </>:""}
              {/*end second row*/}
              <Row className="g-12">
                <Col>
                  {" "}

                  <FormGroup className="f-right">
                    &nbsp;&nbsp;
                    <Button
                      className="btn btn-primary"
                      onClick={(e) => { addSkuData()}}
                      >
                      {t("saveBtn")}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>

            </Form>
          </CardBody>

        </Card>
      </Col>
    </Fragment>
  );
};

export default EditSku;
/***** CODE BY : RAMANAN M  *****/