import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import { Card } from 'reactstrap';
import CutIcon from "../../../assets/images/dms/icons/cutIcon.svg";
import Moment from 'moment';
import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const CutKnobChartrequired = ({cutActualTargetValue,cutTodayUpdatedValue,cutEndDate,cutPerDay}) => {
let cutEstimatedRatePercentage = Math.round((cutTodayUpdatedValue/cutActualTargetValue)*100);
let currentDate = Moment().format('YYYY-MM-DD');
// let endDate=Moment(cutEndDate).format('DD MMM YYYY');
/******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
const knobChartColor = () => {
    
    if((new Date(cutEndDate).getTime() >= new Date(currentDate).getTime())){
            return '#00B38E'; 
        }
        // else if((cutActualTargetValue>=cutPerDay)){
        //     console.log(currentDate,"2====",cutEndDate)
        //     return '#00B38E'; 
        // }
        else{
            return '#FF3169';
        }
    }
    const { t, i18n } = useTranslation();
    useEffect(() => {
        var offsetArc = Knob({
            value: cutPerDay?cutPerDay:0, 
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: cutPerDay
          });
          const cutlist = document.getElementById('cutknobrequir');
          if (cutlist.hasChildNodes()) {
            cutlist.removeChild(cutlist.children[0]);
          }
           document.getElementById('cutknobrequir').appendChild(offsetArc);
    },[cutPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align "  >
                <div className="knob text-center m-t-5" id="cutknobrequir">
                </div>
                <div className=' text-muted f-12' style={{ color: "#828282",}}>{t("reqAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                <div className=' f-w-600 ' style={{marginTop:"0px"}} >{(PHPtoJSFormatConversion.format(new Date(cutEndDate)))}</div>
                <div className=' text-muted  f-12' style={{ color: "#828282",marginTop:"0px" }}>{t("scheduleComDt")}</div>
                {/* <div style={{ backgroundColor: "#13D09A" }} className='taskCardBorder'>
                    <img src={CutIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Cutting</span>
                </div> */}
            </Card>
        </>
    );
}
export default CutKnobChartrequired;