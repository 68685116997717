import React, {  Fragment, useState, useEffect } from "react";
import { Row, Col, Label, Input, Button, } from "reactstrap";
import noData from "../../assets/images/dms/nodata.png"
import { ServerUrl } from "../../Constant";
import HeaderCard from "../Common/Component/HeaderCard";
import { Link } from "react-router-dom";
import {getLoginCompanyId,getLoginUserId,getLoginStaffId,getWorkspaceId,getWorkspaceType} from "../../Constant/LoginConstant";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { apiencrypt, apidecrypt } from "../../helper";


const TaskView = () => {
  const [SelectedOrdersTask, setgetSelectedOrdersTask] = useState([]);
  const [productionstatus, setgetproductionstatus] = useState([]);
  const [productionstartdatestatus, setgetproductionstartdatestatus] = useState();
  const [productionorderstatus, setgetproductionorderstatus] = useState();
  const [productionidrstatus, setgetproductionidrstatus] = useState();
  const [productionpcustatus, setgetproductionpcustatus] = useState();
  const [productionbuyerstatus, setgetproductionbuyerstatus] = useState();
  const [productionfactorystatus, setgetproductionfactorystatus] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    axios
      .post(ServerUrl + "/get-dashboard-settings-orders", apiencrypt({
        company_id: getLoginCompanyId,
        user_id: getLoginUserId,
        staff_id: getLoginStaffId,
        workspace_id: getWorkspaceId,
        widget_id: "2",
      }))
      .then((Notifyset) => {
        Notifyset.data = apidecrypt(Notifyset.data)
        setgetSelectedOrdersTask(Notifyset.data.data.selectedOrders);
        setgetproductionstatus(Notifyset.data.data.orders);
        setgetproductionpcustatus(Notifyset.data.data.orders[0].pcuName);
        setgetproductionbuyerstatus(Notifyset.data.data.orders[0].buyerName);
        setgetproductionfactorystatus(Notifyset.data.data.orders[0].factoryName);
        setgetproductionstartdatestatus(Notifyset.data.data.orders[0].cutting_start_date);
        setgetproductionorderstatus(Notifyset.data.data.orders[0].order_no);
        setgetproductionidrstatus(Notifyset.data.data.orders[0].Id);

      });
   
  }, []);
  const onSelectOrderList = () => {
    var array = [];
    var checkboxes;
    for (var i = 0; i < productionstatus.length; i++) {
      checkboxes = (document.getElementById("taskorder||" + productionstatus[i].id).checked);
      if (checkboxes) {
        array.push(productionstatus[i].id);
      }
    }
    return array;
  }

  const OnSubmitHandle = () => {
    let a = onSelectOrderList()
    let h = '';
    var checkboxes;
    for (var i = 0; i < productionstatus.length; i++) {
      checkboxes = (document.getElementById("taskorder||" + productionstatus[i].id).checked);
      if (productionstatus[i].status !== "1" && checkboxes === true) {
        h = '123';
      }
    }
    if (h != '') {
      Swal.fire({
        text: t("selectOnlyActiveStyles"),
        icon: "warning",
        button: t("okLabel"),
        allowOutsideClick: false
      })
    }
    else if (a.length > 5) {
      Swal.fire({
        title: t("exceededLimitTitleAlert"),
        text: t("maxiumof5Styles"),
        icon: "warning",
        button: t("okLabel"),
        allowOutsideClick: false
      })
    }
    else {
      axios
        .post(ServerUrl + "/add-dashboard-settings-orders", apiencrypt({
          company_id: getLoginCompanyId,
          user_id: getLoginUserId,
          staff_id: getLoginStaffId,
          workspace_id: getWorkspaceId,
          widget_id: "2",
          dashboardOrders: a
        })).then((response) => {
          response.data = apidecrypt(response.data)
          if (response.data.status_code == 401) {
            Swal.fire({
              
                icon: 'error',
                title: t("plsSelectStyle"),
                button: t("okLabel"),
               
            })
        }
        else {
          
          Swal.fire({
            title: t("taskStatus"),
            text: t("savedSuccessfully"),
            icon: "success",
            button: t("okLabel"),
            confirmButtonColor: "#009688",
          });
           
            
        }
        })
        .then((response) => {
          axios
            .post(ServerUrl + "/get-dashboard-settings-orders", apiencrypt({
              company_id: getLoginCompanyId,
              user_id: getLoginUserId,
              staff_id: getLoginStaffId,
              workspace_id: getWorkspaceId,
              widget_id: "2",
            }))
            .then((Notifyset) => {
              Notifyset.data = apidecrypt(Notifyset.data)
              setgetSelectedOrdersTask(Notifyset.data.data.selectedOrders);
              setgetproductionstatus(Notifyset.data.data.orders);
              setgetproductionpcustatus(Notifyset.data.data.orders[0].pcuName);
              setgetproductionbuyerstatus(Notifyset.data.data.orders[0].buyerName);
              setgetproductionfactorystatus(Notifyset.data.data.orders[0].factoryName);
              setgetproductionstartdatestatus(Notifyset.data.data.orders[0].cutting_start_date);
              setgetproductionorderstatus(Notifyset.data.data.orders[0].order_no);
              setgetproductionidrstatus(Notifyset.data.data.orders[0].Id);
            })
        });
    }
  }
  return (
    <Fragment>
      {productionstatus.length>0 ?
      <Col>
      <Col md="12" lg="12" sm="12">
        <Row>
          <Col md="8">
            {t("maxiumof5Styles")}
          </Col><br></br><br></br>
        </Row>
        <Row className="g-12 ">
          {/*start contact list */}
          <Col md="12" lg="12" sm="12">
            <Row className="g-12">
              <div className="table-responsive">
                <table className="table shadow shadow-showcase">
                  <thead className="contact-head">
                    <tr >
                      <th scope="col"></th>
                      <th scope="col">{t("orderNo/styleNo")}</th>
                      {/* {productionbuyerstatus == null ?
                        ("")
                        :
                        (<th scope="col">{t("buyer")}</th>)}
                      {productionpcustatus == null ?
                        ("")
                        :
                        (<th scope="col">{t("PCU")}</th>)}
                      {productionfactorystatus == null ?
                        ("")
                        :
                        (<th scope="col">{t("factory")}</th>)} */}
                        {getWorkspaceType=="Buyer"?
                            <>
                            <th className="text-center" scope="col">{t("PCU")}</th>
                            <th className="text-center" scope="col">{t("factory")}</th>
                            </>
                            :""}
                             {getWorkspaceType=="PCU"?
                            <>
                            <th className="text-center" scope="col">{t("buyer")}</th>
                            <th className="text-center" scope="col">{t("factory")}</th>
                            </>
                            :""}
                             {getWorkspaceType=="Factory"?
                            <>
                             <th className="text-center" scope="col">{t("buyer")}</th>
                            <th className="text-center" scope="col">{t("PCU")}</th>
                           
                            </>
                            :""}
                    </tr>
                  </thead>
                  <tbody>
                  {productionstatus.map((staffList) =>
                  (
                      <tr key={staffList.id}>
                        <td>
                          {SelectedOrdersTask.includes(JSON.stringify(staffList.id)) ?
                            (
                              <Input
                                type="checkbox"
                                className="checkbox_animated arg-min-top-10"
                                name="checkbox"
                                value={staffList.id}
                                defaultChecked
                                id={"taskorder||" + staffList.id}
                                onClick={(e) => { onSelectOrderList(e.target.value) }
                                }
                              />
                            ) : (
                              <Input
                                type="checkbox"
                                className="checkbox_animated arg-min-top-10"
                                name="checkbox"
                                value={staffList.id}
                                id={"taskorder||" + staffList.id}
                                onClick={(e) => { onSelectOrderList(e.target.value) }
                                }
                              />
                            )
                          } {""}
                        </td>
                        <td>
                          <Label for={staffList.id} className="form-label"></Label>
                          {staffList.order_no} / {staffList.style_no}&nbsp;
                          {staffList.status == '10' ? <span className="font-danger  f-w-600">({t("cancelled")})</span>
                            :
                            staffList.status == '3' ? <span className="font-danger  f-w-600">({t("deleted")})</span>
                              :
                              staffList.status == '12' ? <span className="font-primary f-w-600">({t("completed")})</span>
                              :
                              
                              ""}
                        </td>
                     
                           {getWorkspaceType=="Buyer" ?
                            <>
                             <td className="text-center">{staffList.pcuName !== null ?staffList.pcuName:"-"}</td>
                             <td className="text-center">  {staffList.factoryName!== null?staffList.factoryName:"-"} </td>
                             </>
                              :
                              
                              getWorkspaceType=="PCU"?
                            <>
                            <td className="text-center">   {staffList.buyerName!== null ?staffList.buyerName:"-"} </td>
                              <td className="text-center">  {staffList.factoryName!== null?staffList.factoryName:"-"} </td></>
                              :
                              
                              getWorkspaceType=="Factory" ?
                            <>
                              <td className="text-center">  {staffList.buyerName!== null ?staffList.buyerName:"-"} </td>
                              <td className="text-center">{staffList.pcuName !== null ?staffList.pcuName:"-"}</td></>
                              :
                             "-"} 
                      </tr>
                  )
                  )}
                  </tbody>
                </table>
              </div>
            </Row>
          </Col>
          <Col md="12" lg="12" sm="12">&nbsp;</Col>
        </Row>
      </Col>
      <Button className="text-end m-3" onClick={() => { OnSubmitHandle() }} style={{ float: "Right" }}>{t("apply")}</Button>
      <Link to="/user-settings"> <Button className="text-end m-3 btn secondaryBtn" style={{ float: "Right" }}>{t("cancel")}</Button> </Link>
      </Col>
    : <div className="align-self-center media-body centerAlign"
    style={{ marginTop: "100px" }}>
    <img src={noData} style={{ width: "200px" }} />
    <p className='f-18 m-t-10' >-- {t("noData")} --</p>
</div>  }

    </Fragment>
  );
};

export default TaskView;

//**************************Code by : Praveen****************************//

