import axios from "axios";
import React , {useState, useEffect}from "react";
import { Modal, 
        ModalBody, ModalHeader, Nav, NavItem,
        NavLink, Row, Card, Col, Input,  TabContent,
        TabPane, Form} from "reactstrap";
import { getLoginUserId, getLoginStaffId, getStaff, getStaffPermission }
      from "../../Constant/LoginConstant";
import { ServerUrl } from "../../Constant";
import { apiencrypt, PHPtoJSFormatConversion, apidecrypt } from '../../helper';

import GreenCutting from '../../../src/assets/images/dms/icons/green_cutting.svg' 
import GreenSewing from '../../assets/images/dms/icons/green_sewing.svg'
import GreenPacking from '../../assets/images/dms/icons/green_packing.svg'

import Swal from 'sweetalert2';
import Moment from 'moment';

import { useTranslation } from 'react-i18next';

const DataInputSkuModal = ({ 
    modal, toggle, 
    companyId, workspaceId, orderId, skuData, 
    colors, sizes, setSize, setColors, 
    actualDate, targetValue, sewPackSkuData, typeOfProduction, 
    productionCutDateDetails, productionSewPackDateDetails, 
    cutDayByDayUpdates, sewPackDayByDayUpdates,
    setCutDayByDayUpdates, setSewPackDayByDayUpdates,
    cutKnobChart, sewKnobChart, packKnobChart, 
    getcutKnobChart, getsewKnobChart, getpackKnobChart, 
    dateDetails, sewPackDateDetails, 
    calendarData, sewPackCalendarData, 
    prodSkuData, sewPackProdSkuData, 
    setCompletedQtyRate, setTargetValue, dataInputStatusStaffLogin,
    knobChartFn,  setCutCurrentDateTargetValue, 
    setSewCurrentDateTargetValue, setPackCurrentDateTargetValue 
  }) => 
    {
      const { t } = useTranslation();
      var currentDate = Moment().format('YYYY-MM-DD');
      var cutEstKnobColorValue = "";
      var sewEstKnobColorValue = "";
      var packEstKnobColorValue = "";

      var cutReqKnobColorValue = "";
      var sewReqKnobColorValue = "";
      var packReqKnobColorValue = "";

      ///////////////////////////////////////////////////////////////////////////////////////
      /************ API CALL[POST]-(get-calendar-data)  ( ALL THE CALENDAR DATA  )**********/
      const apiCall = () =>{


        cutEstKnobColorValue = "";
        sewEstKnobColorValue = "";
        packEstKnobColorValue = "";

        cutReqKnobColorValue = "";
        sewReqKnobColorValue = "";
        packReqKnobColorValue = "";

        axios
        .post(ServerUrl + "/get-calendar-data",
        apiencrypt({
            "company_id" : companyId,
            "workspace_id" : workspaceId,
            "order_id": orderId,
            "type_of_production": typeOfProduction
        }))
        .then( (response) => {
          response.data = apidecrypt(response.data)
                { 
                  if(typeOfProduction === 'Cut')
                  {
                      var cutTargetValue = "";
                      var cutCompletedRate = "";
                      dateDetails(response.data.data.prodDetails);
                      calendarData(response.data.data.CalendarData);
                      prodSkuData(response.data.data.skuData);
                      cutKnobChart(response.data.data.knobChart);
                      setCutDayByDayUpdates(response.data.data.dayByDayUpdates);
                      setTargetValue(targetValue);
                      
                      /***************TARGET VALUE [KNOB CHART - [MAXIMUM](Required && Estimated)] ****************/
                      if( currentDate > response.data.data.prodDetails.cutEndDate && response.data.data.knobChart.pendingQuantity > 0){
                          setCutCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                          cutTargetValue=response.data.data.knobChart.pendingQuantity;
                      }
                      else {
                            if( response.data.data.knobChart.pendingQuantity > 0 ){
                                  (response.data.data.CalendarData)
                                  .filter( data => data.date_of_production == currentDate )
                                  .map( (mapData) =>{
                                      setCutCurrentDateTargetValue(mapData.target_value);
                                      cutTargetValue=mapData.target_value;
                                  }
                                  )
                              } else{
                                  cutTargetValue=0;
                              }
                      }
                      /***************************** CUTTING QUANTITY RATE [KNOB CHART] *****************************/
                      let completedCutQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0);
                      if( completedCutQtyDateList.length !== 0 || Object.keys(completedCutQtyDateList).length !== 0){
                          let completedCutQtyDateCount = Object.keys(completedCutQtyDateList).length;
                          let completedCutQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedCutQtyDateCount));
                          setCompletedQtyRate(completedCutQtyRate);
                          cutCompletedRate = completedCutQtyRate;

                      } else {
                          setCompletedQtyRate('0');
                          cutCompletedRate = 0;
                      }
                      if(cutKnobChart.pendingQuantity === 0){ setPendingStatus("0") }
                      
                      var cutEstimatedRatePercent = cutTargetValue > 0 ? Math.floor((cutCompletedRate/cutTargetValue)*100) : 0;
                      var cutRequiredRatePercent = cutTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/cutTargetValue)*100) : 0;
                      /***************************************** ORDER STATUS  ***************************************/
                      if( currentDate >= response.data.data.prodDetails.cutStartDate && 
                          currentDate <= response.data.data.prodDetails.cutEndDate ){

                          if(response.data.data.knobChart.pendingQuantity > 0){

                              if( cutEstimatedRatePercent >= 0 && cutEstimatedRatePercent <= 50 ){
                                  cutEstKnobColorValue = "#EE335E";
                              } else if( cutEstimatedRatePercent > 50 && cutEstimatedRatePercent <= 99 ){
                                  cutEstKnobColorValue = "#FEC901";
                              } else if( cutEstimatedRatePercent > 99 ){
                                  cutEstKnobColorValue = "#009688";
                              }

                              if( cutRequiredRatePercent >= 0 && cutRequiredRatePercent <= 50 ){
                                  cutReqKnobColorValue = "#009688";
                              } else if( cutRequiredRatePercent > 50 && cutRequiredRatePercent <= 99 ){
                                  cutReqKnobColorValue = "#FEC901";
                              } else if( cutRequiredRatePercent > 99 ){
                                  cutReqKnobColorValue = "#EE335E";
                              }
                          } else{
                              cutEstKnobColorValue = "#545454";
                              cutReqKnobColorValue = "#545454";
                          }
                          
                      
                      } else{
                          if( currentDate < response.data.data.prodDetails.cutStartDate ){
                                  cutEstKnobColorValue = "#545454";
                                  cutReqKnobColorValue = "#545454";
                              }
                          
                          if( currentDate > response.data.data.prodDetails.cutEndDate ){
                              if(response.data.data.knobChart.pendingQuantity > 0){
                                  cutEstKnobColorValue = "#EE335E";
                                  cutReqKnobColorValue = "#EE335E";
                              } else{
                                  cutEstKnobColorValue = "#545454";
                                  cutReqKnobColorValue = "#545454";
                              }
                          }
                      }

                      knobChartFn( cutCompletedRate, cutTargetValue, cutEstKnobColorValue, cutReqKnobColorValue, "knob1", response.data.data.knobChart.reqOutputRate, "knob2");
                  } 
                  if(typeOfProduction === 'Sew'){
                    var sewTargetValue = "";
                    var sewCompletedRate = "";

                    sewPackDateDetails(response.data.data.prodDetails);
                    sewPackCalendarData(response.data.data.CalendarData);
                    calendarData(response.data.data.CalendarData);
                    sewPackProdSkuData(response.data.data.skuData);
                    sewKnobChart(response.data.data.knobChart);
                    setSewPackDayByDayUpdates(response.data.data.dayByDayUpdates);
                    setTargetValue(targetValue);
                    /***************TARGET VALUE [KNOB CHART - [MAXIMUM](Required && Estimated)] ****************/
                      if( currentDate > response.data.data.prodDetails.sewEndDate &&  response.data.data.knobChart.pendingQuantity > 0){
                      setSewCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                      sewTargetValue=response.data.data.knobChart.pendingQuantity;
                      }
                      else {
                        if( response.data.data.knobChart.pendingQuantity > 0 ){
                          (response.data.data.CalendarData)
                          .filter( data => data.date_of_production == Moment().format('YYYY-MM-DD') )
                          .map( (mapData) => 
                          {
                          setSewCurrentDateTargetValue(mapData.target_value);
                          sewTargetValue = mapData.target_value;
                          }
                          )
                        } else{
                            sewTargetValue = 0;
                        }
                      }
                    /***************************** SEWING QUANTITY RATE [KNOB CHART] *****************************/
                    let completedSewQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0)
                    if( completedSewQtyDateList.length !== 0 || Object.keys(completedSewQtyDateList).length !== 0 ){
                        let completedSewQtyDateCount = Object.keys(completedSewQtyDateList).length;
                        let completedSewQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedSewQtyDateCount));
                        setCompletedQtyRate(completedSewQtyRate);
                        sewCompletedRate = completedSewQtyRate;
                    } else {
                        setCompletedQtyRate('0');
                        sewCompletedRate = 0;
                    }

                    if(sewKnobChart.pendingQuantity === 0){ setPendingStatus("0") }
                    
                    var sewEstimatedRatePercent = sewTargetValue > 0 ? Math.floor((sewCompletedRate/sewTargetValue)*100) : 0;
                    var sewRequiredPcsPercent = sewTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/sewTargetValue)*100) : 0 ;
                    /***************************************** ORDER STATUS  ***************************************/
                    if( currentDate >= response.data.data.prodDetails.sewStartDate && 
                        currentDate <= response.data.data.prodDetails.sewEndDate ){

                          if(response.data.data.knobChart.pendingQuantity > 0){
                            if( sewEstimatedRatePercent >= 0 && sewEstimatedRatePercent <= 50 ){
                                sewEstKnobColorValue = "#EE335E";
                            } else if( sewEstimatedRatePercent > 50 && sewEstimatedRatePercent <= 99 ){
                                sewEstKnobColorValue = "#FEC901";
                            } else if( sewEstimatedRatePercent > 99 ){
                                sewEstKnobColorValue = "#009688";
                            }

                            if( sewRequiredPcsPercent >= 0 && sewRequiredPcsPercent <= 50 ){
                              sewReqKnobColorValue = "#009688";
                            } else if( sewRequiredPcsPercent > 50 && sewRequiredPcsPercent <= 99 ){
                                sewReqKnobColorValue = "#FEC901";
                            } else if( sewRequiredPcsPercent > 99 ){
                                sewReqKnobColorValue = "#EE335E";
                            }

                          } else{
                            sewEstKnobColorValue = "#545454";
                            sewReqKnobColorValue =  "#545454";
                        }
                    } else{
                        if( currentDate < response.data.data.prodDetails.sewStartDate ){
                                sewEstKnobColorValue = "#545454";
                                sewReqKnobColorValue =  "#545454";
                            }
                        if( currentDate > response.data.data.prodDetails.sewEndDate ){
                            if(response.data.data.knobChart.pendingQuantity > 0){
                              sewEstKnobColorValue = "#EE335E";
                              sewReqKnobColorValue =  "#EE335E";

                            } else{
                              sewEstKnobColorValue = "#545454";
                              sewReqKnobColorValue =  "#545454";
                            }
                        }
                    }

                    knobChartFn( sewCompletedRate, sewTargetValue, sewEstKnobColorValue, sewReqKnobColorValue, "knob3", response.data.data.knobChart.reqOutputRate, "knob4");

                  } 
                  if(typeOfProduction === 'Pack'){
                    var packTargetValue = "";
                    var packCompletedRate = "";
                    sewPackDateDetails(response.data.data.prodDetails);
                    sewPackCalendarData(response.data.data.CalendarData);
                    calendarData(response.data.data.CalendarData);
                    sewPackProdSkuData(response.data.data.skuData);
                    packKnobChart(response.data.data.knobChart);
                    setSewPackDayByDayUpdates(response.data.data.dayByDayUpdates);
                    setTargetValue(targetValue);
                      /***************TARGET VALUE [KNOB CHART - [MAXIMUM](Required && Estimated)] ****************/
                      if( currentDate > response.data.data.prodDetails.packEndDate &&  response.data.data.knobChart.pendingQuantity > 0){
                        setPackCurrentDateTargetValue(response.data.data.knobChart.pendingQuantity)
                        packTargetValue=response.data.data.knobChart.pendingQuantity;
                      }
                      else {
                        if( response.data.data.knobChart.pendingQuantity > 0 ){
                            (response.data.data.CalendarData)
                            .filter( data => data.date_of_production == Moment().format('YYYY-MM-DD') )
                            .map( (mapData) => 
                            {
                                setPackCurrentDateTargetValue(mapData.target_value);
                                packTargetValue = mapData.target_value;
                            }
                            )
                        } else{
                            packTargetValue = 0;
                        }
                      }
                    /***************************** PACKING QUANTITY RATE [KNOB CHART] *****************************/
                    let completedPackQtyDateList = (response.data.data.CalendarData).filter(data => data.actual_value !== 0)
                    if( completedPackQtyDateList.length !== 0 ||  Object.keys(completedPackQtyDateList).length !== 0){
                        let completedPackQtyDateCount = Object.keys(completedPackQtyDateList).length;
                        let completedPackQtyRate = Math.round((response.data.data.knobChart.completedQuantity / completedPackQtyDateCount));
                        setCompletedQtyRate(completedPackQtyRate);
                        packCompletedRate = completedPackQtyRate;
                    } else {
                        setCompletedQtyRate('0');
                        packCompletedRate = 0;
                    }

                    if(packKnobChart.pendingQuantity === 0){ setPendingStatus("0") }

                    var packEstimatedRatePercent = packTargetValue > 0 ? Math.floor((packCompletedRate/packTargetValue)*100) : 0;
                    var packRequiredRatePercent = packTargetValue > 0 ? Math.floor((response.data.data.knobChart.reqOutputRate/packTargetValue)*100) : 0 ;
                    
                    /***************************************** ORDER STATUS  ***************************************/
                    if( currentDate >= response.data.data.prodDetails.packStartDate && 
                        currentDate <= response.data.data.prodDetails.packEndDate ){
                          if(response.data.data.knobChart.pendingQuantity > 0){
                            if( packEstimatedRatePercent >= 0 && packEstimatedRatePercent <= 50 ){
                                packEstKnobColorValue = "#EE335E";
                            } else if( packEstimatedRatePercent > 50 && packEstimatedRatePercent <= 99 ){
                                packEstKnobColorValue = "#FEC901";
                            } else if( packEstimatedRatePercent > 99 ){
                                packEstKnobColorValue = "#009688";
                            }

                            if( packRequiredRatePercent >= 0 && packRequiredRatePercent <= 50 ){
                              packReqKnobColorValue = "#009688";
                            } else if( packRequiredRatePercent > 50 && packRequiredRatePercent <= 99 ){
                                packReqKnobColorValue = "#FEC901";
                            } else if( packRequiredRatePercent > 99 ){
                                packReqKnobColorValue = "#EE335E";
                            }

                          } else{
                            packEstKnobColorValue = "#545454";
                            packReqKnobColorValue = "#545454";
                          }
                    
                    } else{
                      if( currentDate < response.data.data.prodDetails.packStartDate ){
                              packEstKnobColorValue = "#545454";
                              packReqKnobColorValue = "#545454";
                          }
                      
                      if( currentDate > response.data.data.prodDetails.packEndDate ){
                          if(response.data.data.knobChart.pendingQuantity > 0){
                              packEstKnobColorValue = "#EE335E";
                              packReqKnobColorValue = "#EE335E";
                          } else{
                              packEstKnobColorValue = "#545454";
                              packReqKnobColorValue = "#545454";
                          }
                      }
                  }

                    knobChartFn( packCompletedRate, packTargetValue, packEstKnobColorValue, packReqKnobColorValue,  "knob5", response.data.data.knobChart.reqOutputRate, "knob6");

                  } 
                }

                setSize(response.data.data.sizes)
                setColors(response.data.data.colors)
                toggle();
        })

        setShowQty(false);
      };

    
      const ObjToArray = (obj) => Object.assign( [], Object.values(obj));

      const [BasicTab, setBasicTab] = useState('1');
      const [ showQty, setShowQty ] = useState(false);
      const [ totalUpdatedData,  setTotalUpdatedData ] = useState([]);
      const [ lastUpdatedData, setLastUpdatedData ] = useState("");

      let skuDataInputParams = {};
      skuDataInputParams['company_id'] = companyId;
      skuDataInputParams['workspace_id'] = workspaceId;
      skuDataInputParams['order_id'] = orderId;
      skuDataInputParams['date'] = actualDate;
      skuDataInputParams['type_of_production'] = typeOfProduction;
      skuDataInputParams['data'] = [{}];

      const [errors, setValidErrors] = useState({});

      if(getLoginUserId !== "null"){
          skuDataInputParams['user_id'] = getLoginUserId;
        }
      if(getLoginStaffId !== "null"){
          skuDataInputParams['staff_id'] = getLoginStaffId;
      }

      /***************************** Calculate  COLOR TOTAL QTY **********************/
      const addQty = (e) => 
      {    
        var id = e.target.id;
        var value = e.target.value;
        
        var splitId = id.split("#");
        var color_id = splitId[0];
        var size_id = splitId[1];
        
        var totalQuantity = 0;
        var overallTotal = 0;

          sizes.forEach((e) =>{
            var sizeId= e.size_id;
            var qtyValue = document.getElementById(color_id+"#"+sizeId).value;
            if(parseInt(qtyValue) > 0)
            {
              totalQuantity+= parseInt(qtyValue);
            }
            document.getElementById("ColorId_total_quantity"+color_id).value=totalQuantity;
          }) 
          
          overallTotalQty(e);
       
      }

      /**************************** Calculate  SIZE TOTAL QTY ***********************/
      const sizeTotalQty = (e) => 
      {
        var id = e.target.id;
        var value = e.target.value

        var splitId = id.split("#");
        var color_id = splitId[0];
        var size_id = splitId[1];

        var tot = 0;
        ObjToArray(colors).forEach( (e) => 
        {
          var colorId = e.color_id;
          var qtyValue = document.getElementById(colorId+"#"+size_id).value;

          if(parseInt(qtyValue)>0)
          {
            tot+=parseInt(qtyValue);
          }
          
          document.getElementById("SizeId_total_quantity"+size_id).value = tot;

        })
      }

      /**************************** Calculate  OVERALL TOTAL QTY ********************/
      const overallTotalQty = (e) => 
      { 
        var id = e.target.id;
        var value = e.target.value;

        var splitId = id.split("#");
        var color_id = splitId[0];
        var size_id = splitId[1];

        var sum = 0; 
        ObjToArray(colors).forEach( (data) =>
        {
          var totalQtyValue = document.getElementById("ColorId_total_quantity"+data.color_id).value;
          if( parseInt(totalQtyValue) > 0 ){
            sum+= parseInt(totalQtyValue);
          }
        })
        document.getElementById("Overall_total_quantity").value = sum;
      }

      /**************************** PAYLOAD - INPUT PARAMS (API-CALL ) **************/
      const skuDetails = (e) => {
        var obj = {};
        const breakOut=false;
        var i=0;
        var totqty=0;
        var actualTotalQtyVal = 0;

        Object.values(colors).forEach( (colorData) => 
        {
          sizes.forEach( (sizeData) => 
          {
            i++;
            var qtyValue = document.getElementById(colorData.color_id+"#"+sizeData.size_id).value;
            qtyValue=qtyValue?qtyValue:"0";
            if( typeOfProduction == "Cut" ){
              let cutDefaultValue = skuDefaultValue(cutDayByDayUpdates, colorData.color_id, sizeData.size_id, actualDate); 
              let actualQtyVal = cutDefaultValue == 0 ?
               (Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(cutDefaultValue)) 
               : Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(cutDefaultValue[0].total_quantity);
              var data = {};
              data['color_id'] = colorData.color_id;
              data['size_id'] = sizeData.size_id;
              data['quantity'] = Number(qtyValue);
              obj[i] = data;
              totqty=parseInt(totqty)+parseInt(qtyValue);
              actualTotalQtyVal=parseInt(actualTotalQtyVal)+parseInt(actualQtyVal);
            } else if( typeOfProduction == "Sew"){
              let sewDefaultValue= skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, sizeData.size_id, actualDate); 
              let actualQtyVal = sewDefaultValue == 0 ?
               (Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(sewDefaultValue)) 
               : Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(sewDefaultValue[0].total_quantity);
              var data = {};
              data['color_id'] = colorData.color_id;
              data['size_id'] = sizeData.size_id;
              data['quantity'] = Number(qtyValue);
              obj[i] = data;
              totqty=parseInt(totqty)+parseInt(qtyValue);
              actualTotalQtyVal=parseInt(actualTotalQtyVal)+parseInt(actualQtyVal);
            } else {
              let packDefaultValue= skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, sizeData.size_id, actualDate); 
              let actualQtyVal = packDefaultValue == 0 ? (Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(packDefaultValue)) : Number(document.getElementById(colorData.color_id+"#"+sizeData.size_id).value) - Number(packDefaultValue[0].total_quantity);
              var data = {};
              data['color_id'] = colorData.color_id;
              data['size_id'] = sizeData.size_id;
              data['quantity'] = Number(qtyValue);
              obj[i] = data;
              totqty=parseInt(totqty)+parseInt(qtyValue);
              actualTotalQtyVal=parseInt(actualTotalQtyVal)+parseInt(actualQtyVal);
            }
          });
        });

        if(totqty==0){
          Swal.fire({
            title: t("Please Enter a Quantity"),
            icon: "warning",
            button: t("okLabel"),
            allowOutsideClick: false
          });
        }
        else{
            if( typeOfProduction == "Cut"){
             if( Number(getcutKnobChart.totalQuantity) ==  (Number(getcutKnobChart.completedQuantity) + Number(actualTotalQtyVal)) ){
               Swal.fire({
                title: t("areYouSure"),
                text: t("completeTheCutting "),
                 icon: "warning",
                 button: t("okLabel"), 
                 showCancelButton: true,
                 allowOutsideClick: false
               })
               .then((result) => {
                 if (result.isConfirmed) {

                     axios
                     .post(ServerUrl + "/add-input-data", apiencrypt({
                       "company_id": companyId,
                       "workspace_id" : workspaceId,
                       "user_id": getLoginUserId, 
                       "staff_id": getLoginStaffId, 
                       "order_id" : orderId,
                       "data" : obj,
                       "date" : actualDate,
                       "target_value": (targetValue).toString(),
                       "type_of_production" : typeOfProduction,
                       "isOrderAccomplished" : "1"
                     }))
                     .then( (response) => {
                      response.data = apidecrypt(response.data)

                       if (response.data.status_code === 200) {
                         Swal.fire({
                           title: t("Data Updated Successfully"),
                           icon: "success",
                           button: t("okLabel"),
                           allowOutsideClick: false
                         })
                         .then( (result ) => 
                         {
                           if( result.isConfirmed){
                            apiCall();
                           }
                         })
                       }
                       else if (response.data.status_code === 201) {
                         Swal.fire({
                           title: t("Negative Quantity is not Allowed."),
                           icon: "warning",
                           button: t("okLabel"),
                         })
                       }  
                       else if (response.data.status_code === 202) {                       

                        Swal.fire({
                           // title: t(response.data.message),
                           title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                           t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                           : response.data.production_type == "pack" ?
                           t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                           :
                           t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                           icon: "warning",
                           button: t("okLabel"),
                         })
                       }
                       else if (response.data.status_code === 203) {
                         Swal.fire({
                           title: t(response.data.message),
                           icon: "warning",
                           button: t("okLabel"),
                         })
                       }
                       else if (response.data.status_code === 204) {
                         Swal.fire({
                           title: t(response.data.message),
                           icon: "warning",
                           button: t("okLabel"),
                         })
                       }
                       else if (response.data.status_code === 401){
                         Swal.fire({
                           title: t(response.data.message),
                           // title: t("Please Enter a Valid Number"),
                           icon: "warning",
                           button: t("okLabel"),
                         })
                       } 
                       else if(response.data.status_code === 600){
                        Swal.fire({
                          title: t(response.data.message),
                          icon: "error",
                          button: t("okLabel"),
                        })
                       }
                     });
                  }
               })
             } else {
              axios
              .post(ServerUrl + "/add-input-data", apiencrypt({
                "company_id": companyId,
                "workspace_id" : workspaceId,
                "user_id": getLoginUserId, 
                "staff_id": getLoginStaffId, 
                "order_id" : orderId,
                "data" : obj,
                "date" : actualDate,
                "target_value": (targetValue).toString(),
                "type_of_production" : typeOfProduction,
                "isOrderAccomplished" : "0"
              }))
              .then( (response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code === 200) {
                  Swal.fire({
                    title: t("Data Updated Successfully"),
                    icon: "success",
                    button: t("okLabel"),
                    allowOutsideClick: false
                  })
                  .then( (result ) => 
                  {
                    if( result.isConfirmed){
                      apiCall();
                    }
                  })
                }
                else if (response.data.status_code === 201) {
                  Swal.fire({
                    title: t("Negative Quantity is not Allowed."),
                    icon: "warning",
                    button: t("okLabel"),
                  })
                }  
                else if (response.data.status_code === 202) {
                  Swal.fire({
                    // title: t(response.data.message),
                    title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                    t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                    : response.data.production_type == "pack" ?
                    t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                    :
                    t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                    icon: "warning",
                    button: t("okLabel"),
                  })
                }
                else if (response.data.status_code === 203) {
                  Swal.fire({
                    title: t(response.data.message),
                    icon: "warning",
                    button: t("okLabel"),
                  })
                }
                else if (response.data.status_code === 204) {
                  Swal.fire({
                    title: t(response.data.message),
                    icon: "warning",
                    button: t("okLabel"),
                  })
                }
                else if (response.data.status_code === 401){
                  Swal.fire({
                    title: t(response.data.message),
                    // title: t("Please Enter a Valid Number"),
                    icon: "warning",
                    button: t("okLabel"),
                  })
                }
                else if(response.data.status_code === 600){
                  Swal.fire({
                    title: t(response.data.message),
                    icon: "error",
                    button: t("okLabel"),
                  })
              }
              });
            }
            } else if ( typeOfProduction == "Sew" ){
              if( Number(getsewKnobChart.totalQuantity) ==  (Number(getsewKnobChart.completedQuantity) + Number(actualTotalQtyVal)) ){
                Swal.fire({
                  title: t("areYouSure"),
                  text: t("completeTheSewing "),
                  icon: "warning",
                  button: t("okLabel"), 
                  showCancelButton: true,
                  allowOutsideClick: false
                })
                .then((result) => {
                  if (result.isConfirmed) {

                      axios
                      .post(ServerUrl + "/add-input-data", apiencrypt({
                        "company_id": companyId,
                        "workspace_id" : workspaceId,
                        "user_id": getLoginUserId, 
                        "staff_id": getLoginStaffId, 
                        "order_id" : orderId,
                        "data" : obj,
                        "date" : actualDate,
                        "target_value": (targetValue).toString(),
                        "type_of_production" : typeOfProduction,
                        "isOrderAccomplished" : "1"
                      }))
                      .then( (response) => {
                        response.data = apidecrypt(response.data)
                        if (response.data.status_code === 200) {
                          Swal.fire({
                            title: t("Data Updated Successfully"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false
                          })
                          .then( (result ) => 
                          {
                            if( result.isConfirmed){
                                apiCall();
                            }
                          })
                        }
                        else if (response.data.status_code === 201) {
                          Swal.fire({
                            title: t("Negative Quantity is not Allowed."),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }  
                        else if (response.data.status_code === 202) {
                          Swal.fire({
                            // title: t(response.data.message),
                            title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                            t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                            : response.data.production_type == "pack" ?
                            t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                            :
                            t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 203) {
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 204) {
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 401){
                          Swal.fire({
                            title: t(response.data.message),
                            // title: t("Please Enter a Valid Number"),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if(response.data.status_code === 600){
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "error",
                            button: t("okLabel"),
                          })
                      }
                      });
                    }
                })
              } else {
                axios
                .post(ServerUrl + "/add-input-data", apiencrypt({
                  "company_id": companyId,
                  "workspace_id" : workspaceId,
                  "user_id": getLoginUserId, 
                  "staff_id": getLoginStaffId, 
                  "order_id" : orderId,
                  "data" : obj,
                  "date" : actualDate,
                  "target_value": (targetValue).toString(),
                  "type_of_production" : typeOfProduction,
                  "isOrderAccomplished" : "0"
                }))
                .then( (response) => {
                  response.data = apidecrypt(response.data)
                  if (response.data.status_code === 200) {
                    Swal.fire({
                      title: t("Data Updated Successfully"),
                      icon: "success",
                      button: t("okLabel"),
                      allowOutsideClick: false
                    })
                    .then( (result ) => 
                    {
                      if( result.isConfirmed){
                        apiCall();
                      }
                    })
                  }
                  else if (response.data.status_code === 201) {
                    Swal.fire({
                      title: t("Negative Quantity is not Allowed."),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }  
                  else if (response.data.status_code === 202) {
                    Swal.fire({
                      // title: t(response.data.message),
                      title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                      t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                      : response.data.production_type == "pack" ?
                      t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                      :
                      t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 203) {
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 204) {
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 401){
                    Swal.fire({
                      title: t(response.data.message),
                      // title: t("Please Enter a Valid Number"),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if(response.data.status_code === 600){
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "error",
                      button: t("okLabel"),
                    })
                }
                });
              }
            } else {
              if( Number(getpackKnobChart.totalQuantity) ==  (Number(getpackKnobChart.completedQuantity) + Number(actualTotalQtyVal)) ){
                Swal.fire({
                  title: t("areYouSure"),
                  text: t("completeThePacking "),
                  icon: "warning",
                  button: t("okLabel"), 
                  showCancelButton: true,
                  allowOutsideClick: false
                })
                .then((result) => {
                  if (result.isConfirmed) {

                      axios
                      .post(ServerUrl + "/add-input-data", apiencrypt({
                        "company_id": companyId,
                        "workspace_id" : workspaceId,
                        "user_id": getLoginUserId, 
                        "staff_id": getLoginStaffId, 
                        "order_id" : orderId,
                        "data" : obj,
                        "date" : actualDate,
                        "target_value": (targetValue).toString(),
                        "type_of_production" : typeOfProduction,
                        "isOrderAccomplished" : "1"
                      }))
                      .then( (response) => {
                        response.data = apidecrypt(response.data)
                        if (response.data.status_code === 200) {
                          Swal.fire({
                            title: t("Data Updated Successfully"),
                            icon: "success",
                            button: t("okLabel"),
                            allowOutsideClick: false
                          })
                          .then( (result ) => 
                          {
                            if( result.isConfirmed){
                              apiCall();                            
                            }
                          })
                        }
                        else if (response.data.status_code === 201) {
                          Swal.fire({
                            title: t("Negative Quantity is not Allowed."),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }  
                        else if (response.data.status_code === 202) {
                          Swal.fire({
                            // title: t(response.data.message),
                            title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                            t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                            : response.data.production_type == "pack" ?
                            t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                            :
                            t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 203) {
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 204) {
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if (response.data.status_code === 401){
                          Swal.fire({
                            title: t(response.data.message),
                            // title: t("Please Enter a Valid Number"),
                            icon: "warning",
                            button: t("okLabel"),
                          })
                        }
                        else if(response.data.status_code === 600){
                          Swal.fire({
                            title: t(response.data.message),
                            icon: "error",
                            button: t("okLabel"),
                          })
                      }
                      });
                    }
                })
              }else {
                axios
                .post(ServerUrl + "/add-input-data", apiencrypt({
                  "company_id": companyId,
                  "workspace_id" : workspaceId,
                  "user_id": getLoginUserId, 
                  "staff_id": getLoginStaffId, 
                  "order_id" : orderId,
                  "data" : obj,
                  "date" : actualDate,
                  "target_value": (targetValue).toString(),
                  "type_of_production" : typeOfProduction,
                  "isOrderAccomplished" : "0"
                }))
                .then( (response) => {
                  response.data = apidecrypt(response.data)
                  if (response.data.status_code === 200) {
                    Swal.fire({
                      title: t("Data Updated Successfully"),
                      icon: "success",
                      button: t("okLabel"),
                      allowOutsideClick: false
                    })
                    .then( (result ) => 
                    {
                      if( result.isConfirmed){
                        apiCall();
                      }
                    })
                  }
                  else if (response.data.status_code === 201) {
                    Swal.fire({
                      title: t("Negative Quantity is not Allowed."),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }  
                  else if (response.data.status_code === 202) {
                    Swal.fire({
                      // title: t(response.data.message),
                      title:response.data.production_type == "sew" ?  // Condition verify from API response (production_type)
                      t("exceededSewTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                      : response.data.production_type == "pack" ?
                      t("exceededPackTotalQuantity",{colorName:response.data.color,sizeName:response.data.size})
                      :
                      t("exceededTotalQuantity",{colorName:response.data.color,sizeName:response.data.size}),              
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 203) {
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 204) {
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if (response.data.status_code === 401){
                    Swal.fire({
                      title: t(response.data.message),
                      // title: t("Please Enter a Valid Number"),
                      icon: "warning",
                      button: t("okLabel"),
                    })
                  }
                  else if(response.data.status_code === 600){
                    Swal.fire({
                      title: t(response.data.message),
                      icon: "error",
                      button: t("okLabel"),
                    })
                }
                });
              }
            }
        }
      }

      /**************************** TOTAL QUANTITY BASED ON COLOR, SIZE**************/
      const getColorSizeUpdatedQty=(array,colorid,sizeid)=>
      {
        let aryqty =[];
        if(array.length > 0){
          array.map((obj) => {
              let tqty= (obj.color_id === colorid &&  obj.size_id===sizeid);
              if(tqty==true){
                aryqty.push(obj.total_quantity)     
              }
            });  
          return aryqty;
        } else{
          return 0;
        }
      }

      /**************************** UPDATED SKU VALUES BASED ON COLOR, SIZE ********/
      const skuTotalUpdatedQty = ( arrayData, color_id, size_id ) => 
      {
        let obj = {};
        let array = [];
        arrayData.map( ( data) => {
            let dataValidation = ( data.color_id === color_id && data.size_id === size_id);
              if( dataValidation == true )
              {  
                obj.color_name = data.colorName;
                obj.size_name = data.sizeName;
                obj.total_quantity = data.total_quantity;
                obj.updated_quantity = data.updated_quantity;
                array.push(obj)
              }
        })
          setTotalUpdatedData(array);
      }

      /******************************************** SKU VALUES *********************/
      const skuDayByDayUpdates = ( arrayData, color_id, size_id, actual_date ) => 
      {
        let obj = {};
        let array = [];
        if( arrayData.length > 0 ){
          arrayData.map( ( data) => {
              let dataValidation = ( 
                data.color_id === color_id &&
                data.size_id === size_id && 
                data.date_updated === actual_date );
                if( dataValidation == true )
                {  
                  obj.colorName = data.colorName;
                  obj.sizeName = data.sizeName;
                  obj.total_quantity = data.total_quantity;
                  document.getElementById(color_id+"#"+size_id).setAttribute("placeholder", data.total_quantity);
                  document.getElementById(color_id+"#"+size_id).value=(data.total_quantity > 0 ? data.total_quantity:'');
                  array.push(obj)
                }
          })
          setLastUpdatedData(array);
        } else{
          setLastUpdatedData(0);
        }
      }

      /********************************** UPDATED SKU VALUES  ***********************/
      const skuDefaultValue = ( dayByDayUpdates, colorid, sizeid, actualDate ) => 
      {
        if( dayByDayUpdates == 0 ){
          return 0;
        }
        else{
          if(dayByDayUpdates.map( (data) => 
            {
              data.date_updated === actualDate 
            })){
                const filtered = dayByDayUpdates.filter(dayByDayUpdates => 
                  {
                  return dayByDayUpdates.date_updated === actualDate 
                      &&  dayByDayUpdates.color_id===colorid 
                      &&  dayByDayUpdates.size_id===sizeid;
                });
                return filtered;
              }   
        }
      }

      /*********** MOVES TO NEXT INPUT FIELD ( WHEN ENTER IS CLICKED ) **************/
      function handleEnter(event) {
          if ((event.keyCode === 13 || event.keyCode === 9) && event.target.nodeName === "INPUT") {
          setValidErrors( () => "");
          const form = event.target.form;
          const index = Array.prototype.indexOf.call(form, event.target);
          if(form.elements[index + 1].readOnly==false){
          form.elements[index + 1].focus();
          }else{
            if(form.elements[index + 2].readOnly==false){
            form.elements[index + 2].focus();
            }else{
            let index=0;
              form.elements[index].focus();
            }
          }
        }
      }

      /************************* ONLY NUMBERS VALIDATION (INPUT FIELD) *************/
      function handleKeyPress(e) {
        var key = e.key;
        var regex = /[0-9]/;
        if( !regex.test(key) ) {
            e.preventDefault();
        }
      }

      var cutOverallTotal = 0;
      var sewOverallTotal = 0;
      var packOverallTotal = 0;

    return (
      <Modal 
        isOpen={modal}
        toggle={toggle} 
        backdrop="static"
        centered 
        className="datainputmodal">


      { typeOfProduction === 'Cut' ?
        <>
          <ModalHeader className="text-center" 
                       style={{  borderBottom: 'none' }}>  
            <Row>
              <Col className="m-t-5">
                <Row>
                  <div className="f-left">
                    <Col>
                        <img className="f-left m-l-10" 
                             src={GreenCutting} width="50px" />
                    </Col>
                    <Row>
                      <Col md="12">
                          <span className="f-left m-t-5" 
                            style={{ color: '#009688', width: '125px',  fontSize: '18px' }}>
                            {t("cutting")}
                          </span>
                      </Col>
                      <Col  md="12">
                        <span className="f-left m-t-5 m-l-2" style={{ fontSize: '12px',width: '125px',}}>
                        { PHPtoJSFormatConversion.format(new Date(actualDate)) }
                        </span>   
                      </Col>              
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                  </Col>
                  <Col>
                  { productionCutDateDetails.isCutAccomplished == 1
                  ?
                  <>
                  <Row className="m-t-10 f-left m-r-10">
                    <div className="f-right m-t-10 m-r-30" 
                      style={{ color: '#009688', fontSize: '14px'}}>
                        <i 
                        className="fa fa-circle f-12 m-r-10" 
                        style={{ color: '#009688'}}></i>
                      {t("completed")}
                    </div> 
                  </Row>
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  </>
                  :
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                   onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  }
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody>

            <Row>
              <Col>
                <Nav tabs 
                    style={{ width: '75%', justifyContent: 'center', backgroundColor: '#f2f2f2'}} >
                    <Row style={{ height: '58px'}} lg="2" md="2">
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '1' 
                                ?
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''}
                                    onClick={() => {setBasicTab('1')}}
                                    style={{cursor:'pointer', backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px', 
                                            width: '125px'}}>
                                    <strong className="f-w-500 mt-1">{t("addQuantity")}</strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('1')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}>{t("addQuantity")}</strong>
                                </NavLink>
                                }
                        </NavItem >
                                
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '2' 
                                ?
                                <NavLink 
                                    className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px'}}>
                                    <strong className="f-w-500 mt-1 text-center">{t("viewSKU")}</strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2'), setShowQty(false)}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}>{t("viewSKU")}</strong>
                                </NavLink>
                                }
                        </NavItem>
                    </Row>
                </Nav> 
              </Col>
              <Col>
                <Row>   
                  <Col>
                    <Row>
                      { showQty === true ? 
                      <>
                        <Col 
                          style={{  
                          height: '60px', 
                          borderTop: '2px solid #f2f2f2',  
                          borderLeft: '2px solid #f2f2f2',  
                          borderBottom: '2px solid #f2f2f2',
                          backgroundColor: '#f2f2f2'}}>
                          <div  className="m-t-5" style={{ width: '30px' }}>
                            <table style={{ width: '30px' }}>
                              <thead  className=" f-w-300" style={{  fontSize: '13px' }}>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td placeholder={t("colorName")} >
                                    {data.color_name}
                                  </td>
                                </>
                              ))} 
                              </thead>
                              <tbody>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td className=" f-w-600">
                                  {data.size_name}
                                  </td>
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col style={{  height: '60px', border: '2px solid #f2f2f2', marginRight: '10px' }}>
                          <div  className="m-t-5" style={{ width: '240px' }}>
                            <table style={{ width: '250px' }}>
                              <thead  className=" f-w-300 text-center" style={{  fontSize: '13px' }}>
                                <td>
                                {t("total")}
                                </td>
                                <td>
                                     {t("completed")}
                                </td>
                                <td>
                                {t("Pending")}
                                </td>
                              </thead>
                              <tbody className="f-w-600 text-center">

                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td>
                                  {data.total_quantity}
                                  </td>
                                  <td>
                                  {data.updated_quantity}
                                  </td> 
                                  <td> 
                                  {(data.total_quantity) - (data.updated_quantity)}
                                  </td>                        
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </>
                      :
                      "" 
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="1">
                <Row className="g-12">
                  <Form>
                    <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center middle">
                              {t("color/sizeLabel")}
                              </th>
                              {sizes.map(( data) => 
                                {
                                  return (
                                      <th className="text-center middle">
                                        {data.sizeName}
                                      </th>   
                                  )
                                }
                              )}
                              <th className="text-center middle">
                              {t("totalLabel")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {Object.values(colors)
                              .map( (colorData ) => {
                                let cutColorTotal=0;
                                let totqty=0;
                              return (
                                <>
                                  <tr>
                                    <th className="text-center middle">{colorData.colorName}</th>
                                      {sizes.map( (data) => {
                                        let qtyValue;
                                        let getqty=getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id);
                                        totqty=parseInt(totqty)+parseInt(getqty);
                                        let cutDefaultValue= skuDefaultValue(cutDayByDayUpdates, colorData.color_id, data.size_id, actualDate);
                                        cutDefaultValue == 0 ? qtyValue = cutDefaultValue : qtyValue = parseInt(cutDefaultValue[0].total_quantity);
                                        cutColorTotal += qtyValue;
                                        return (
                                            <>
                                              <th>
                                                <Input 
                                                  className="form-control inpwidthsmall test"
                                                  name="userName"
                                                  id={colorData.color_id+"#"+data.size_id}
                                                  type="tel"
                                                  min="0"
                                                  autoComplete="off"
                                                  defaultValue={cutDefaultValue == 0  ?  '' : cutDefaultValue[0].total_quantity }
                                                  placeholder="0"
                                                  onFocus = { (e) => { 
                                                    setShowQty(true), 
                                                    skuTotalUpdatedQty(skuData, colorData.color_id, data.size_id) 
                                                    skuDayByDayUpdates( cutDayByDayUpdates, colorData.color_id, data.size_id, actualDate )
                                                  }
                                                  }
                                                  onClick = {() => { 
                                                    setShowQty(true), 
                                                    skuTotalUpdatedQty(skuData, colorData.color_id, data.size_id) 
                                                    skuDayByDayUpdates( cutDayByDayUpdates, colorData.color_id, data.size_id, actualDate )}}
                                                    onChange={(e) => {addQty(e), sizeTotalQty(e)
                                                    }}
                                                  onKeyDown={handleEnter}
                                                  onKeyPress={(e) => handleKeyPress(e)}
                                                />
                                              </th>
                                              </>
                                        );
                                      })} 
                                    <th>
                                      <Input 
                                        disabled
                                        className="form-control inpwidthsmall"
                                        name="userName"
                                        type="tel"
                                        defaultValue={cutColorTotal ? cutColorTotal : "0"}
                                        readOnly
                                        id={"ColorId_total_quantity"+colorData.color_id} 
                                      />
                                    </th>
                                  </tr>
                                </>
                              )
                            }
                            )}
                            
                            <tr>
                              <th></th>
                              {sizes.map( (sizeData) => {
                                let qtyValue;
                                var cutSizeTotal = 0;
                                {Object.values(colors).map((colorData) => {
                                  let cutDefaultValue = skuDefaultValue(cutDayByDayUpdates, colorData.color_id, sizeData.size_id, actualDate);
                                  cutDefaultValue == 0 ? qtyValue = cutDefaultValue : qtyValue = parseInt(cutDefaultValue[0].total_quantity);
                                  cutSizeTotal += qtyValue;
                                 })
                                }
                                cutOverallTotal += cutSizeTotal;
                                return (
                                <>
                                  <th>
                                    <Input 
                                      disabled
                                      className="form-control inpwidthsmall"
                                      id={"SizeId_total_quantity"+sizeData.size_id}
                                      type="tel"
                                      defaultValue={cutSizeTotal ?  cutSizeTotal : "0"}
                                      autocomplete="off"
                                      readOnly
                                      />
                                  </th>
                                </>)
                              })}
                              <th> 

                              <input 
                                defaultValue={cutOverallTotal ? cutOverallTotal : "0"}
                                disabled
                                className="form-control inpwidthsmall"
                                id="Overall_total_quantity"
                                type="tel"
                                placeholder="0"
                                autocomplete="off"
                                readOnly
                                />
                            </th>
                                 
                            </tr>

                          </tbody>
                        </table>
                    </div>  
                  </Form>
                </Row>
              </TabPane>
            </TabContent>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="2">
                <Row className="g-12">
                <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center middle" scope="col">
                            {t("color/sizeLabel")}
                            </th>
                            {sizes.map(( data) => 
                              {
                                return (
                                    <th className="text-center middle">
                                      {data.sizeName}
                                    </th>   
                                )
                              }
                            )}
                            <th className="text-center middle">
                            {t("totalLabel")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {Object.values(colors)
                            .map( (colorData ) => {
                              let totqty=0;
                            return (
                              <tr>
                                <th  className="text-center middle"> {colorData.colorName} </th>
                                  { sizes.map( (data) => {
                                      let getqty=getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id);
                                      totqty=parseInt(totqty)+parseInt(getqty);
                                      return (
                                          <th>
                                            <input 
                                              disabled
                                              className="form-control inpwidthsmall"
                                              id={colorData.color_id+"#"+data.size_id}
                                              type="number"
                                              placeholder="0"
                                              defaultValue={getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id)} 
                                            />
                                          </th>
                                      );
                                  })} 
                                <th>
                                  <input 
                                    disabled
                                    className="form-control inpwidthsmall"
                                    type="number"
                                    placeholder="0"
                                    readOnly
                                    defaultValue={totqty}
                                    id={"total_quantity"+colorData.color_id} 
                                  />
                                </th>
                              </tr>
                            )
                          }
                          )}

                        </tbody>
                      </table>
                  </div>  
                </Row>
              </TabPane>
            </TabContent>

          </ModalBody>

          <Row>
            <Col>
            </Col>
            <Col style={{ width: '100%' }}>
              <Row className="f-right" style={{  marginRight: '25px' }}>

              <div style={{ width: '120px' }}>
                <button 
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '1px solid #009688', 
                        backgroundColor: '#fff',
                        color: '#009688'}}
                    onClick={() =>{ setShowQty(false),  setValidErrors( () => ""), toggle() }}>
                        {t("cancel")}
                </button>   
              </div>

              { ( dataInputStatusStaffLogin == 1 ) || 
              (productionCutDateDetails.isCutAccomplished == 0 && getStaff == null ) ? 
               BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '0px solid #fff', 
                        backgroundColor: '#009688',
                        color: '#fff'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              :
              BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    disabled
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '0px solid #fff', 
                        backgroundColor: '#6E7881',
                        color: '#fff',
                        cursor: 'not-allowed',
                        opacity: '0.5'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              }
       
              </Row>

            </Col>
          </Row>
        </>
        :
        ""
      }

      { typeOfProduction === 'Sew' ? 
        <>
          <ModalHeader className="text-center" 
                       style={{  borderBottom: 'none' }}>  
            <Row>
              <Col className="m-t-5">
                <Row>
                  <div className="f-left">
                    <Col>
                        <img className="f-left m-l-10" 
                             src={GreenSewing} width="50px" />
                    </Col>
                    <Row>
                      <Col md="12">
                          <span className="f-left m-t-5" 
                            style={{ color: '#009688', width: '125px',  fontSize: '18px' }}>
                          {t("sewing")}
                          </span>
                      </Col>
                      <Col  md="12">
                        <span className="f-left m-t-5 m-l-2" style={{ fontSize: '12px',width: '125px',}}>
                        { PHPtoJSFormatConversion.format(new Date(actualDate)) }
                        </span>   
                      </Col>              
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                  </Col>
                  <Col>
                  { productionSewPackDateDetails.isSewAccomplished == 1
                  ?
                  <>
                  <Row className="m-t-10 f-left m-r-10">
                    <div className="f-right m-t-10 m-r-30" 
                      style={{ color: '#009688', fontSize: '14px'}}>
                        <i 
                        className="fa fa-circle f-12 m-r-10" 
                        style={{ color: '#009688'}}></i>
                      {t("completed")}
                    </div> 
                  </Row>
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  </>
                  :
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                   onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  }
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody>

            <Row>
              <Col>
                <Nav tabs 
                    style={{ width: '75%', justifyContent: 'center', backgroundColor: '#f2f2f2'}} >
                    <Row style={{ height: '58px'}} lg="2" md="2">
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '1' 
                                ?
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('1')}}
                                    style={{ cursor:'pointer', backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px', 
                                            width: '125px'}}>
                                    <strong className="f-w-500 mt-1">{t("addQuantity")}</strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('1')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}>{t("addQuantity")}</strong>
                                </NavLink>
                                }
                        </NavItem >
                                
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '2' 
                                ?
                                <NavLink 
                                    className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px'}}>
                                    <strong className="f-w-500 mt-1 text-center"> {t("viewSKU")} </strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2'), setShowQty(false)}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}> {t("viewSKU")}</strong>
                                </NavLink>
                                }
                        </NavItem>
                    </Row>
                </Nav> 
              </Col>
              <Col>
                <Row>   
                  <Col>
                    <Row>
                      { showQty === true ? 
                      <>
                        <Col 
                          style={{  
                          height: '60px', 
                          borderTop: '2px solid #f2f2f2',  
                          borderLeft: '2px solid #f2f2f2',  
                          borderBottom: '2px solid #f2f2f2',
                          backgroundColor: '#f2f2f2'}}>
                          <div  className="m-t-5" style={{ width: '30px' }}>
                            <table style={{ width: '30px' }}>
                              <thead  className=" f-w-300" style={{  fontSize: '13px' }}>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                   <td placeholder={t("colorName")}>
                                    {data.color_name}
                                  </td>
                                </>
                              ))} 
                              </thead>
                              <tbody>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td className=" f-w-600">
                                  {data.size_name}
                                  </td>
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col style={{  height: '60px', border: '2px solid #f2f2f2', marginRight: '10px' }}>
                          <div  className="m-t-5" style={{ width: '240px' }}>
                            <table style={{ width: '250px' }}>
                              <thead  className=" f-w-300 text-center" style={{  fontSize: '13px' }}>
                              <td>
                                {t("total")}
                                </td>
                                <td>
                                {t("completed")}
                                </td>
                                <td>
                                {t("Pending")}
                                </td>
                              </thead>
                              <tbody className="f-w-600 text-center">
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td>
                                  {data.total_quantity}
                                  </td>
                                  <td>
                                  {data.updated_quantity}
                                  </td> 
                                  <td> 
                                  {(data.total_quantity) - (data.updated_quantity)}
                                  </td>                        
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </>
                      :
                      "" 
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="1">
              <Form>
                <Row className="g-12">
                  <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center middle">
                            {t("color/sizeLabel")}
                            </th>
                            {sizes.map(( data) => 
                              {
                                return (
                                    <th className="text-center middle">
                                      {data.sizeName}
                                    </th>   
                                )
                              }
                            )}
                            <th className="text-center middle">
                            {t("totalLabel")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {Object.values(colors)
                            .map( (colorData ) => {
                              let totqty=0;
                              let sewColorTotal=0;
                              
                            return (
                              <>
                                <tr>
                                  <th className="text-center middle"> {colorData.colorName} </th>
                                  { sizes.map( (data) => {
                                    let qtyValue;
                                    let getqty=getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id);
                                    totqty=parseInt(totqty)+parseInt(getqty);
                                    let sewDefaultValue = skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate);    
                                    sewDefaultValue == 0 ? qtyValue = sewDefaultValue : qtyValue = parseInt(sewDefaultValue[0].total_quantity);
                                    sewColorTotal += qtyValue;

                                    return (
                                        <>
                                          <th>
                                            <Input  
                                              className="form-control inpwidthsmall"
                                              name="userName"
                                              id={colorData.color_id+"#"+data.size_id}
                                              type="tel"
                                              min="0"
                                              defaultValue={ sewDefaultValue == 0 ? '' : sewDefaultValue[0].total_quantity }
                                              autoComplete="off"
                                              placeholder="0"
                                              onFocus = { (e) => {
                                                setShowQty(true), 
                                                skuTotalUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id) 
                                                skuDayByDayUpdates( sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate )
                                              }}
                                              onClick = {() => { 
                                                setShowQty(true), 
                                                skuTotalUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id) 
                                                skuDayByDayUpdates( sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate )}}
                                                onChange={(e) => {addQty(e), sizeTotalQty(e)
                                                  }}
                                              onKeyDown={handleEnter}
                                              onKeyPress={(e) => handleKeyPress(e)}
                                            />
                                          </th>
                                          </>
                                      );
                                  })} 
                                  <th>
                                    <Input 
                                      defaultValue={sewColorTotal ? sewColorTotal : "0"}
                                      disabled
                                      className="form-control inpwidthsmall"
                                      name="userName"
                                      type="number"
                                      placeholder="0"
                                      readOnly
                                      id={"ColorId_total_quantity"+colorData.color_id} 
                                    />
                                  </th>
                                </tr>
                              </>
                            )
                          }
                          )}

                          
                          <tr>
                            <th></th>
                          {sizes.map( (data) => {
                            let qtyValue;
                            var sewSizeTotal = 0;
                            {Object.values(colors).map((colorData) => {
                              let sewDefaultValue = skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate);
                              sewDefaultValue == 0 ? qtyValue = sewDefaultValue : qtyValue = parseInt(sewDefaultValue[0].total_quantity);
                              sewSizeTotal += qtyValue;
                             })
                            }
                            sewOverallTotal += sewSizeTotal;
                            return (
                            <>
                            <th>
                              <Input
                                defaultValue={sewSizeTotal ? sewSizeTotal : "0"} 
                                disabled
                                className="form-control inpwidthsmall"
                                id={"SizeId_total_quantity"+data.size_id}
                                type="number"
                                placeholder="0"
                                autocomplete="off"
                                readOnly
                                />
                                              
                            </th>
                            </>)
                          })}
                          <th>   
                            <Input 
                              defaultValue={sewOverallTotal ? sewOverallTotal : "0"}
                              disabled
                              className="form-control inpwidthsmall"
                              id="Overall_total_quantity"
                              type="number"
                              placeholder="0"
                              autocomplete="off"
                              readOnly
                              />
                          </th>
                          </tr>

                        </tbody>
                      </table>
                  </div>  
                </Row>
              </Form>
              </TabPane>
            </TabContent>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="2">
                <Row className="g-12">
                <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center middle" scope="col">
                            {t("color/sizeLabel")}
                            </th>
                            {sizes.map(( data) => 
                              {
                                return (
                                    <th className="text-center middle">
                                      {data.sizeName}
                                    </th>   
                                )
                              }
                            )}
                            <th className="text-center middle">
                            {t("totalLabel")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {Object.values(colors)
                            .map( (colorData ) => {
                              let totqty=0;
                            return (
                              <tr>
                                <th  className="text-center middle"> {colorData.colorName} </th>
                                  { sizes.map( (data) => {
                                      let getqty=getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id);
                                      totqty=parseInt(totqty)+parseInt(getqty);
                                      return (
                                          <th>
                                            <input 
                                              disabled 
                                              className="form-control inpwidthsmall"
                                              name="userName"
                                              id={colorData.color_id+"#"+data.size_id}
                                              type="number"
                                              placeholder="0"
                                              defaultValue={getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id)} 
                                            />
                                          </th>
                                      );
                                  })} 
                                <th>
                                  <input 
                                    className="form-control inpwidthsmall"
                                    name="userName"
                                    type="number"
                                    placeholder="0"
                                    readOnly
                                    defaultValue={totqty}
                                    id={"total_quantity"+colorData.color_id} 
                                  />
                                </th>
                              </tr>
                            )
                          }
                          )}

                        </tbody>
                      </table>
                  </div>  
                </Row>
              </TabPane>
            </TabContent>

          </ModalBody>

          <Row>
            <Col>
            </Col>
            <Col style={{ width: '100%' }}>
              <Row className="f-right" style={{  marginRight: '25px' }}>

              <div style={{ width: '120px' }}>
                <button 
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '1px solid #009688', 
                        backgroundColor: '#fff',
                        color: '#009688'}}
                        onClick={() =>{ setShowQty(false), toggle(), setValidErrors(() => "")}}>
                        {t("cancel")}
                </button>   
              </div>

             
              { ( dataInputStatusStaffLogin == 1 ) || 
                (getStaff == null && productionSewPackDateDetails.isSewAccomplished == 0) ? 
               BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '0px solid #fff', 
                        backgroundColor: '#009688',
                        color: '#fff'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              :
              BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    disabled
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                    width: '105px', 
                    borderRadius: '30px', 
                    border: '0px solid #fff', 
                    backgroundColor: '#6E7881',
                    color: '#fff',
                    cursor: 'not-allowed',
                    opacity: '0.5'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              }
              </Row>

            </Col>
          </Row>
        </>
        :
        ""
      }

      { typeOfProduction === 'Pack' ? 
        <>
          <ModalHeader className="text-center" 
                       style={{  borderBottom: 'none' }}>  
            <Row>
              <Col className="m-t-5">
                <Row>
                  <div className="f-left">
                    <Col>
                        <img className="f-left m-l-10" 
                             src={GreenPacking} width="50px" />
                    </Col>
                    <Row>
                      <Col md="12">
                          <span className="f-left m-t-5" 
                            style={{ color: '#009688', width: '125px',  fontSize: '18px' }}>
                          {t("packing")}
                          </span>
                      </Col>
                      <Col  md="12">
                        <span className="f-left m-t-5 m-l-2" style={{ fontSize: '12px',width: '125px',}}>
                        { PHPtoJSFormatConversion.format(new Date(actualDate)) }
                        </span>   
                      </Col>              
                    </Row>
                  </div>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                  </Col>
                  <Col>
                  { productionSewPackDateDetails.isPackAccomplished == 1
                  ?
                  <>
                  <Row className="m-t-10 f-left m-r-10">
                    <div className="f-right m-t-10 m-r-30" 
                      style={{ color: '#009688', fontSize: '14px'}}>
                        <i 
                        className="fa fa-circle f-12 m-r-10" 
                        style={{ color: '#009688'}}></i>
                      {t("completed")}
                    </div> 
                  </Row>
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                    onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  </>
                  :
                  <div className=" m-t-20 m-r-5 f-right" 
                    style={{ color: '#6c757d', cursor: 'pointer' }} 
                   onClick={() =>{ setShowQty(false), toggle()}}>
                    X
                  </div>
                  }
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalHeader>

          <ModalBody>

            <Row>
              <Col>
                <Nav tabs 
                    style={{ width: '75%', justifyContent: 'center', backgroundColor: '#f2f2f2'}} >
                    <Row style={{ height: '58px'}} lg="2" md="2">
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '1' 
                                ?
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('1')}}
                                    style={{ cursor:'pointer', backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px', 
                                            width: '125px'}}>
                                    <strong className="f-w-500 mt-1"> {t("addQuantity")} </strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '1' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('1')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}> {t("addQuantity")} </strong>
                                </NavLink>
                                }
                        </NavItem >
                                
                        <NavItem
                            lg="`style={{ width: '50%'}}`"
                            md="`style={{ width: '50%'}}`"
                            sm="`style={{ width: '100%'}}`"
                            className="text-center mt-1" >
                                {BasicTab === '2' 
                                ?
                                <NavLink 
                                    className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2')}}
                                    style={{ cursor:'pointer',  backgroundColor: '#fff',
                                            borderColor: '#E6EDEF', height: '50px'}}>
                                    <strong className="f-w-500 mt-1 text-center"> {t("viewSKU")}</strong>
                                </NavLink>
                                :
                                <NavLink 
                                className={BasicTab === '2' ? 'active' : ''} 
                                    onClick={() => {setBasicTab('2'), setShowQty(false)}}
                                    style={{ cursor:'pointer',  backgroundColor: '#f2f2f2',
                                            height: '50px'}}>  
                                    <strong className="f-w-500 mt-1" 
                                      style={{ color: '#000'}}> {t("viewSKU")}</strong>
                                </NavLink>
                                }
                        </NavItem>
                    </Row>
                </Nav> 
              </Col>
              <Col>
                <Row>   
                  <Col>
                    <Row>
                      { showQty === true ? 
                      <>
                        <Col 
                          style={{  
                          height: '60px', 
                          borderTop: '2px solid #f2f2f2',  
                          borderLeft: '2px solid #f2f2f2',  
                          borderBottom: '2px solid #f2f2f2',
                          backgroundColor: '#f2f2f2'}}>
                          <div  className="m-t-5" style={{ width: '30px' }}>
                            <table style={{ width: '30px' }}>
                              <thead  className=" f-w-300" style={{  fontSize: '13px' }}>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td placeholder={t("colorName")}>
                                    {data.color_name}
                                  </td>
                                </>
                              ))} 
                              </thead>
                              <tbody>
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td className=" f-w-600">
                                  {data.size_name}
                                  </td>
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                        <Col style={{  height: '60px', border: '2px solid #f2f2f2', marginRight: '10px' }}>
                          <div  className="m-t-5" style={{ width: '240px' }}>
                            <table style={{ width: '250px' }}>
                              <thead  className=" f-w-300 text-center" style={{  fontSize: '13px' }}>
                              <td>
                                {t("total")}
                                </td>
                                <td>
                                     {t("completed")}
                                </td>
                                <td>
                                {t("Pending")}
                                </td>
                              </thead>
                              <tbody className="f-w-600 text-center">
                              {totalUpdatedData.map( (data ) => (
                                <>
                                  <td>
                                  {data.total_quantity}
                                  </td>
                                  <td>
                                  {data.updated_quantity}
                                  </td> 
                                  <td> 
                                  {(data.total_quantity) - (data.updated_quantity)}
                                  </td>                        
                                </>
                              ))} 
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </>
                      :
                      "" 
                      }
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="1">
                <Form>
                  <Row className="g-12">
                    <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-center middle">
                              {t("color/sizeLabel")}
                              </th>
                              {sizes.map(( data) => 
                                {
                                  return (
                                      <th className="text-center middle">
                                        {data.sizeName}
                                      </th>   
                                  )
                                }
                              )}
                              <th className="text-center middle">
                              {t("totalLabel")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {Object.values(colors)
                              .map( (colorData ) => {
                                let packColorTotal=0;
                                let totqty=0;
                              return (
                                <>
                                  <tr>
                                    <th className="text-center middle"> {colorData.colorName} </th>
                                    { sizes.map( (data) => {
                                      let qtyValue;
                                      let getqty=getColorSizeUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id);
                                      totqty=parseInt(totqty)+parseInt(getqty);
                                      let packDefaultValue = skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate);
                                      packDefaultValue == 0 ? qtyValue = packDefaultValue : qtyValue = parseInt(packDefaultValue[0].total_quantity);
                                      packColorTotal += qtyValue;
                                        return (
                                          <>
                                            <th>
                                              <Input  
                                                className="form-control inpwidthsmall"
                                                name="userName"
                                                id={colorData.color_id+"#"+data.size_id}
                                                type="tel"
                                                defaultValue={ packDefaultValue == 0 ? '' : packDefaultValue[0].total_quantity }
                                                autoComplete="off"
                                                placeholder="0"
                                                onFocus= { () => {
                                                  setShowQty(true), 
                                                  skuTotalUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id) 
                                                  skuDayByDayUpdates(sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate)}}
                                                onClick = {() => { 
                                                  setShowQty(true), 
                                                  skuTotalUpdatedQty(sewPackSkuData, colorData.color_id, data.size_id) 
                                                  skuDayByDayUpdates( sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate )}}
                                                onChange={(e) => {addQty(e), sizeTotalQty(e)}}
                                                onKeyDown={handleEnter}
                                                onKeyPress={(e) => handleKeyPress(e)}
                                              />
                                            </th>
                                            </>
                                        );
                                    })} 
                                    <th>
                                      <Input 
                                        className="form-control inpwidthsmall"
                                        name="userName"
                                        type="tel"
                                        placeholder="0"
                                        readOnly
                                        defaultValue={packColorTotal ? packColorTotal : "0"}
                                        id={"ColorId_total_quantity"+colorData.color_id} 
                                      />
                                    </th>
                                  </tr>
                                </>
                              )
                            }
                            )}
                            
                            <tr>
                              <th></th>
                            {sizes.map( (data) => {
                              let qtyValue;
                              var packSizeTotal = 0;
                              {Object.values(colors).map((colorData) => {
                                let packDefaultValue = skuDefaultValue(sewPackDayByDayUpdates, colorData.color_id, data.size_id, actualDate);
                                packDefaultValue == 0 ? qtyValue = packDefaultValue : qtyValue = parseInt(packDefaultValue[0].total_quantity);
                                packSizeTotal += qtyValue;
                               })
                              }
                              packOverallTotal += packSizeTotal;
                              return (
                              <>
                              <th>
                                <Input
                                  defaultValue={packSizeTotal ?  packSizeTotal : "0"} 
                                  className="form-control inpwidthsmall"
                                  id={"SizeId_total_quantity"+data.size_id}
                                  type="tel"
                                  placeholder="0"
                                  autocomplete="off"
                                  readOnly
                                  />
                                                
                              </th>
                              </>)
                            })}
                            <th>   
                              
                              <Input
                                defaultValue={packOverallTotal ? packOverallTotal : "0"} 
                                className="form-control inpwidthsmall"
                                id="Overall_total_quantity"
                                type="tel"
                                placeholder="0"
                                autocomplete="off"
                                readOnly
                                />
                                </th>
                            </tr>

                          </tbody>
                        </table>
                    </div>  
                  </Row>
                </Form>
              </TabPane>
            </TabContent>

            <TabContent activeTab={BasicTab}>
              <TabPane className="fade show" tabId="2">
                <Row className="g-12">
                <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center middle" scope="col">
                            {t("color/sizeLabel")}
                            </th>
                            {sizes.map(( data) => 
                              {
                                return (
                                    <th className="text-center middle">
                                      {data.sizeName}
                                    </th>   
                                )
                              }
                            )}
                            <th className="text-center middle">
                            {t("totalLabel")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {Object.values(colors)
                            .map( (colorData ) => {
                              let totqty=0;
                            return (
                              <tr>
                                <th  className="text-center middle"> {colorData.colorName} </th>
                                  { sizes.map( (data) => {
                                      let getqty=getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id);
                                      totqty=parseInt(totqty)+parseInt(getqty);
                                      return (
                                          <th>
                                            <Input 
                                              disabled 
                                              className="form-control inpwidthsmall"
                                              name="userName"
                                              id={colorData.color_id+"#"+data.size_id}
                                              type="tel"
                                              placeholder="0"
                                              defaultValue={getColorSizeUpdatedQty(skuData, colorData.color_id, data.size_id)} 
                                            />
                                          </th>
                                      );
                                  })} 
                                <th>
                                  <Input 
                                    className="form-control inpwidthsmall"
                                    name="userName"
                                    type="tel"
                                    placeholder="0"
                                    readOnly
                                    defaultValue={totqty}
                                    id={"total_quantity"+colorData.color_id} 
                                  />
                                </th>
                              </tr>
                            )
                          }
                          )}

                        </tbody>
                      </table>
                  </div>  
                </Row>
              </TabPane>
            </TabContent>

          </ModalBody>

          <Row>
            <Col>
            </Col>
            <Col style={{ width: '100%' }}>
              <Row className="f-right" style={{  marginRight: '25px' }}>

              <div style={{ width: '120px' }}>
                <button 
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '1px solid #009688', 
                        backgroundColor: '#fff',
                        color: '#009688'}}
                        onClick={() =>{ setShowQty(false), toggle()}}>
                    {t("cancel")}
                </button>   
              </div>

              { ( dataInputStatusStaffLogin == 1 ) ||
                (getStaff == null && productionSewPackDateDetails.isPackAccomplished == 0)
               ? 
               BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '0px solid #fff', 
                        backgroundColor: '#009688',
                        color: '#fff'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              :

              BasicTab === '2' ? "" : 
              <div style={{ width: '120px', border: '0px' }}>
                <button 
                    disabled
                    id="updateBtn"
                    className="mb-4" 
                    style={{ height: '45px', 
                        width: '105px', 
                        borderRadius: '30px', 
                        border: '0px solid #fff', 
                        backgroundColor: '#6E7881',
                        color: '#fff',
                        cursor: 'not-allowed',
                        opacity: '0.5'}}
                    onClick={ () => { skuDetails() }}>
                    {t("update")}
                </button>   
              </div>
              }
       
              </Row>

            </Col>
          </Row>
        </>
        :
        ""
      }
      </Modal>
    );
};

export default DataInputSkuModal;
/***** CODE BY : RAMANAN M  *****/


      