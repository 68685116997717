import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import { Breadcrumbs} from '../../AbstractElements';
import CalendarConfigurationSettings from './CalenderConfigurationSettings';

const CalenderSettings = () => {
  return (
    <Fragment>
      <Row className="pgbgcolor">
        <Breadcrumbs mainTitle='Calendar Configuration' parent="Calendar Configuration" title="Calendar Configuration" />
      </Row>
      <Container fluid={true} className="general-widget topaln">
        <div className="email-wrap bookmark-wrap">
          <Row>
            <CalendarConfigurationSettings />
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};
export default CalenderSettings;
// Code by : Praveen