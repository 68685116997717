import CompanySettings from '../Components/CompanySettings';
import AddStaff from '../Components/Staff';
import EditStaff from '../Components/Staff/EditStaff';
import Staff from '../Components/Staff/ViewStaff';
import UserSettings from '../Components/UserSettings';
import CreateOrders from '../Components/Orders/CreateOrders';
import CompanyProfile from '../Components/CompanyProfile';
import WorkSpace from '../Components/WorkSpace';
import Roles from '../Components/Roles';
import App from '../Components/Orders/CreateOrders/test';
import AddOrderContacts from '../Components/Orders/CreateOrders/AddContacts';
import AddOrderSKU from '../Components/Orders/CreateOrders/AddSku';
import AddOrderSKURatiowise from '../Components/Orders/CreateOrders/AddSkuRatiowise';
import EditOrderSKURatiowise from '../Components/Orders/EditOrders/EditSkuRatiowise';
import AddOrderTask from '../Components/Orders/CreateOrders/AddOrderTask';
import OrderProductionPlan from '../Components/Orders/CreateOrders/ProductionPlan';
import PaymentHistory from '../Components/PaymentHistory/index';
import EditOrderRegistration from '../Components/Orders/EditOrders/EditOrderRegistration'
import EditSku from '../Components/Orders/EditOrders/EditSku'
import EditContacts from '../Components/Orders/EditOrders/EditContacts'
import EditOrderTask from '../Components/Orders/EditOrders/EditOrderTask'
import TaskUpdate from '../Components/TaskUpdate/index';
import NewTaskUpdate from '../Components/NewTaskUpdate/index';
import DataInput from '../Components/DataInput';
import PendingTasks from '../Components/PendingTasks';
import PendingProduction from '../Components/PendingProduction';
import OrderStatus from '../Components/OrderStatus';
import EditProductionPlan from '../Components/Orders/EditOrders/EditProductionPlan';
import ViewColor from '../Components/Color/';
import AddColor from '../Components/Color/AddColor.jsx';
import EditColor from '../Components/Color/EditColor.jsx';
import ViewSize from '../Components/Size/';
import AddSize from '../Components/Size/AddSize';
import EditSize from '../Components/Size/EditSize';
import CalenderConfiguration from '../Components/CalenderConfiguration';
import EditCompanyProfile from '../Components/CompanyProfile/EditCompanyProfile';
import OrderView from '../Components/Orders/ViewOrder/OrderView'
import TaskReports from '../Components/Reports/Task reports';
import ProductionReports from '../Components/Reports/Production Reports';
import DataInputReports from '../Components/Reports/DataInputReports/index';
import axios from 'axios';
import NewDashboard from '../Components/NewDashboard';
import OrderReport from '../Components/Reports/OrderReports';
import DataInputLogs from '../Components/Reports/DataInputLogs/index';
import DataInputSKUReports from '../Components/Reports/DataInputSKUReports';
import OrderAccessoriesReports from '../Components/Reports/OrderAccessoriesReports/index';
import InquiryForm from '../Components/Inquiry/InquiryForm/index';
import ViewInquiry from '../Components/Inquiry/InquiryForm/ViewInquiry';
import FactoryDetailInquiry from '../Components/Inquiry/FactoryInquiry/FactoryDetailInquiry';
import FactoryViewInquiry from '../Components/Inquiry/FactoryInquiry/FactoryViewInquiry';
import InquiryContacts from '../Components/Inquiry/InquiryContacts/index';
import FeedbackForm from '../Components/Inquiry/InquiryForm/FeedbackFormInquiry';
import FactoryResponse from '../Components/Inquiry/InquiryForm/FactoryResponse';
import AddSAMDetails from '../Components/SAMDetails/AddSAMDetails';
import EditSAMDetails from '../Components/SAMDetails/EditSAMDetails';
import ViewSamDetails from '../Components/SAMDetails/ViewSamDetails';
import DailySamDetails from '../Components/SAMDetails/DailySamDetails';
import OrderFeedbackForm from './../Components/Orders/FeedbackForm/index';
import NewDashboardv1 from '../Components/NewDashboard-V-1';
import NewDashboardTable from '../Components/NewDashboard-V-1/NewDashboardTable';
import AddMaterialsLabels from '../Components/Orders/CreateOrders/AddMaterialsLabels';
import EditMaterialsLabels from '../Components/Orders/EditOrders/EditMaterialsLabels';

//import EmailSettings from '../Components/EmailSettings'; 
// let cookies = document.cookie
// .split(';')
// .map( cookie => cookie.split('='))
// .reduce((accumulator, [Key, value]) => 
//        ({ ...accumulator, [Key.trim()] : decodeURIComponent(value)}), {});
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem("apiToken");;
//axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const routes = [
        //Support Ticket
        //{ path: `${process.env.PUBLIC_URL}/starter-kits/sample-page`, Component: <SupportTickitContain /> },
        //dashboard
        // { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <Default /> },
        // { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, Component: <Ecommerce /> },
        // { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Dashboard /> },
        { path: `${process.env.PUBLIC_URL}/companysetting`, Component: <CompanySettings /> },
        { path: `${process.env.PUBLIC_URL}/workspace`, Component: <WorkSpace /> },
        { path: `${process.env.PUBLIC_URL}/addstaff`, Component: <AddStaff /> },
        { path: `${process.env.PUBLIC_URL}/editstaff`, Component: <EditStaff /> },
        { path: `${process.env.PUBLIC_URL}/staff`, Component: <Staff /> },
        { path: `${process.env.PUBLIC_URL}/user-settings`, Component: <UserSettings /> },        
        { path: `${process.env.PUBLIC_URL}/createorder`, Component: <CreateOrders /> },
        { path: `${process.env.PUBLIC_URL}/ordercontacts`, Component: <AddOrderContacts /> },
        { path: `${process.env.PUBLIC_URL}/ordersku`, Component: <AddOrderSKU /> },
        { path: `${process.env.PUBLIC_URL}/orderskuratiowise`, Component: <AddOrderSKURatiowise /> },
        //    ( OrderId: 146, /orderskuratiowise?id=VTJGc2RHVmtYMStNVWlDUnErMm1LdjlObUpGa055dVVHRkh5eDRQbnByTT0=)  
        { path: `${process.env.PUBLIC_URL}/ordertask`, Component: <AddOrderTask /> },
        { path: `${process.env.PUBLIC_URL}/ordermaterialsLabels`, Component: <AddMaterialsLabels /> },
        { path: `${process.env.PUBLIC_URL}/editordermaterialsLabels`, Component: <EditMaterialsLabels /> },
        { path: `${process.env.PUBLIC_URL}/orderproductionplan`, Component: <OrderProductionPlan /> },
        { path: `${process.env.PUBLIC_URL}/orderfeedbackform`, Component: <OrderFeedbackForm/>},
        { path: `${process.env.PUBLIC_URL}/paymenthistory`, Component: <PaymentHistory /> },

             
        { path: `${process.env.PUBLIC_URL}/company-profile`, Component: <CompanyProfile /> },
        { path: `${process.env.PUBLIC_URL}/roles`, Component: <Roles /> },
        // { path: `${process.env.PUBLIC_URL}/taskupdate`, Component: <TaskUpdate /> },
        { path: `${process.env.PUBLIC_URL}/taskupdate`, Component: <NewTaskUpdate /> },
        { path: `${process.env.PUBLIC_URL}/test`, Component: <App /> },

        { path: `${process.env.PUBLIC_URL}/editorder`, Component: <EditOrderRegistration /> },
        { path: `${process.env.PUBLIC_URL}/editsku`, Component: <EditSku /> },
        { path: `${process.env.PUBLIC_URL}/editskuratiowise`, Component: <EditOrderSKURatiowise /> },
        //   ( OrderId: 146,  /editskuratiowise?id=VTJGc2RHVmtYMStNVWlDUnErMm1LdjlObUpGa055dVVHRkh5eDRQbnByTT0=&qty_arr=VTJGc2RHVmtYMSthbnZRNVhYZFlIZGRYK1pqTGVETHRxd0tVQW1HS29qTT0=)  
        { path: `${process.env.PUBLIC_URL}/editcontacts`, Component: <EditContacts /> },
        { path: `${process.env.PUBLIC_URL}/datainput`, Component: <DataInput /> },
        { path: `${process.env.PUBLIC_URL}/pendingtasks`, Component: <PendingTasks /> },
        { path: `${process.env.PUBLIC_URL}/pendingproduction`, Component: <PendingProduction /> },
        { path: `${process.env.PUBLIC_URL}/orderstatus`, Component: <OrderStatus /> },
        { path: `${process.env.PUBLIC_URL}/editordertask`, Component: <EditOrderTask /> },
        { path: `${process.env.PUBLIC_URL}/editproductionplan`, Component: <EditProductionPlan /> },

        { path: `${process.env.PUBLIC_URL}/color`, Component: <ViewColor /> },
        { path: `${process.env.PUBLIC_URL}/addcolor`, Component: <AddColor /> },
        { path: `${process.env.PUBLIC_URL}/editcolor`, Component: <EditColor /> },

        { path: `${process.env.PUBLIC_URL}/size`, Component: <ViewSize /> },
        { path: `${process.env.PUBLIC_URL}/addsize`, Component: <AddSize /> },
        { path: `${process.env.PUBLIC_URL}/editsize`, Component: <EditSize /> },

        { path: `${process.env.PUBLIC_URL}/calendar-configuration`, Component: <CalenderConfiguration/> },
        { path: `${process.env.PUBLIC_URL}/editcompany`, Component: <EditCompanyProfile/> },
        { path: `${process.env.PUBLIC_URL}/orderview`, Component: <OrderView/> },
        
        { path: `${process.env.PUBLIC_URL}/taskreports`, Component: <TaskReports/>  },
        { path: `${process.env.PUBLIC_URL}/productionreports`, Component: <ProductionReports/>  },
        { path: `${process.env.PUBLIC_URL}/datainputreports`, Component: <DataInputReports/>  },
        { path: `${process.env.PUBLIC_URL}/orderAccessoriesreports`, Component: <OrderAccessoriesReports/>  },
        // { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <NewDashboard/> },
        { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <NewDashboardv1/> },
        { path: `${process.env.PUBLIC_URL}/dashboardtable`, Component: <NewDashboardTable/> },
        { path: `${process.env.PUBLIC_URL}/orderreports`, Component: <OrderReport/> },
        { path: `${process.env.PUBLIC_URL}/datainputlogs`, Component: <DataInputLogs/>  },
        { path: `${process.env.PUBLIC_URL}/datainputskureports`, Component: <DataInputSKUReports/>  },

        // { path: `${process.env.PUBLIC_URL}/inquiryform`, Component: <InquiryForm/>  },
        // { path: `${process.env.PUBLIC_URL}/viewinquiry`, Component: <ViewInquiry/>  },
        // { path: `${process.env.PUBLIC_URL}/factorydetailinquiry`, Component: <FactoryDetailInquiry/>  },
        // { path: `${process.env.PUBLIC_URL}/factoryviewinquiry`, Component: <FactoryViewInquiry/>  },
        // { path: `${process.env.PUBLIC_URL}/inquirycontacts`, Component: <InquiryContacts/>  },
        // { path: `${process.env.PUBLIC_URL}/feedbackform`, Component: <FeedbackForm/>},    
        // { path: `${process.env.PUBLIC_URL}/factoryresponse`, Component: <FactoryResponse/>},
        
        { path: `${process.env.PUBLIC_URL}/addsamdetails`, Component: <AddSAMDetails />},
        { path: `${process.env.PUBLIC_URL}/editsamdetails`, Component: <EditSAMDetails />},
        { path: `${process.env.PUBLIC_URL}/viewsamdetails`, Component: <ViewSamDetails />},
        { path: `${process.env.PUBLIC_URL}/dailysamdetails`, Component: <DailySamDetails/>}


        //{ path: `${process.env.PUBLIC_URL}/emailsettings`, Component: <EmailSettings/>  },        
];


