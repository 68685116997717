import React , {useState}from "react";
import { Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,  Card, CardBody, Row ,Col,Input,Label, Button, FormGroup } from "reactstrap";
import { Btn } from "../../../AbstractElements";
import { getLoginCompanyId, getWorkspaceId, getLoginUserId, getWorkspaceType, getStaff,getStaffPermission, getLoginStaffId } from "../../../Constant/LoginConstant";
import { Close, SaveChanges , ServerUrl} from "../../../Constant";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import moment from "moment/moment";
import { apidecrypt, apiencrypt } from "../../../helper";
const FilterOffCanvas = ({ modal, toggle, styleNo, dailyProdData,filterStartDate,filterEndDate,filterOperator,filterPieces,
    filterType, necessaryDetails}) => {
  const [workspace_id, setworkspace_id] = useState(getWorkspaceId);
  const [company_id, setcompany_id] = useState(getLoginCompanyId);
  const { t } = useTranslation();
  const [pieces, setPieces] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("All");
  const [operator, setOperator] = useState("");
//   const dayss = [1, 2, 3, 4, 5, 6, 7, 8, 9,10];
 
  const submitFunction = ()=>{
    let dataToSend ;
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        order_id : styleNo
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        user_id : getLoginUserId,
        order_id : styleNo
    }
    dataToSend.type = type;
    if(pieces != "" && operator !="" && type === "Excess"){
      dataToSend.no_of_excess = Math.abs(pieces);
      dataToSend.operator_symb = operator;
    }
    else if(pieces != "" && operator !="" && type === "Short"){
    //   dataToSend.type = type;
      dataToSend.no_of_short = Math.abs(pieces);
      dataToSend.operator_symb = operator;
    }
    filterType(type);
    filterOperator(operator);
    filterPieces(Math.abs(pieces));
    let startDates = document.getElementById("startDate").value;
    let endDates = document.getElementById("endDate").value;
    if(startDates != "" && endDates != ""){
      dataToSend.start_date = document.getElementById("startDate").value;
      dataToSend.end_date = document.getElementById("endDate").value;
      filterStartDate(startDates);
      filterEndDate(endDates);
    }
    axios
    .post(ServerUrl + "/get-daily-prod-report", apiencrypt(dataToSend))
    .then((response) => {
      response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){
            necessaryDetails(response.data.data.necessaryDetails);
            dailyProdData(response.data.data.prodData);
            toggle();
        }
    });
  };
  const clearFunction=()=>{
    let dataToSend ;
    getStaff === "Staff" ?  dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        staff_id : getLoginStaffId,
        order_id : styleNo,
    } : dataToSend = {
        company_id: company_id,
        workspace_id: workspace_id,
        order_id : styleNo,
    }
    setStartDate("0");
    setEndDate("0");
    setType("");
    setOperator("");
    setPieces("");
    filterStartDate("");
    filterEndDate("");
    filterType("");
    filterOperator("");
    filterPieces("");
    axios
    .post(ServerUrl + "/get-daily-prod-report", apiencrypt(dataToSend))
    .then((response) => {
      response.data = apidecrypt(response.data)
        if(response.data.status_code === 200){
            dailyProdData(response.data.data.prodData);
            toggle();
        }
    });
  };
  return (
    <Offcanvas isOpen={modal} toggle={toggle} direction={"end"}>
      <OffcanvasHeader className="bg-primary offcanvas-header"><i className="fa fa-filter f-24"></i> {t("filter")} 
        <span className="f-right cursor-pointer" onClick={toggle}>X</span>
      </OffcanvasHeader> 
      <OffcanvasBody>
        <Row className="p-2">
            <Col md={6}>
                <Label>{t("startDate")}</Label>
                <Input type="date" id="startDate"
                onChange={(e)=>{
                  setStartDate(e.target.value);
                  document.getElementById("endDate").setAttribute("min",e.target.value);
                  let targetDate = moment(e.target.value).add(3,"months").format("YYYY-MM-DD");
                  document.getElementById("endDate").setAttribute("max",targetDate);
                }}
                onKeyDown={(e) => {
                e.preventDefault();
                }}
                defaultValue={startDate}>
                </Input>
            </Col>
            <Col md={6}>
                <Label>{t("endDate")}</Label>
                <Input type="date" id="endDate" 
                onChange={(e)=>{
                  setEndDate(e.target.value);
                }}
                onKeyDown={(e) => {
                    e.preventDefault();
                    }}
                defaultValue={endDate}> 
                </Input>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormGroup className="m-l-15 m-t-20 m-checkbox-inline mb-0 custom-radio-ml">
                    {type==="All"?
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline1"
                        type="radio"
                        name="type"
                        value="All"
                        onChange={(e)=> setType(e.target.value)} checked
                        />
                        <Label className="mb-0" for="radioinline1">
                        {t("all")}
                        </Label>
                    </div> :
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline1"
                        type="radio"
                        name="type"
                        value="All"
                        onChange={(e)=> setType(e.target.value)} 
                        />
                        <Label className="mb-0" for="radioinline1">
                        {t("all")}
                        </Label>
                    </div>
                    }
                    {type === "Excess" ?
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline2"
                        type="radio"
                        name="type"
                        value="Excess"
                        onChange={(e)=> setType(e.target.value)} checked
                        />
                        <Label className="mb-0" for="radioinline2">
                        {t("excess")}
                        </Label>
                    </div> :
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline2"
                        type="radio"
                        name="type"
                        value="Excess"
                        onChange={(e)=> setType(e.target.value)}
                        />
                        <Label className="mb-0" for="radioinline2">
                        {t("excess")}
                        </Label>
                    </div>
                    }
                    {type==="Short"?
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline3"
                        type="radio"
                        name="type"
                        value="Short"
                        onChange={(e)=> setType(e.target.value)} checked
                        />
                        <Label className="mb-0" for="radioinline3">
                        {t("short")}
                        </Label>
                    </div> : 
                    <div className="radio radio-primary">
                        <Input
                        id="radioinline3"
                        type="radio"
                        name="type"
                        value="Short"
                        onChange={(e)=> setType(e.target.value)}
                        />
                        <Label className="mb-0" for="radioinline3">
                        {t("short")}
                        </Label>
                    </div>
                    }
                </FormGroup>
            </Col>
        </Row>
        {type === "Excess" ? 
            <Row className="p-2">
            <Col md={6}>
                <Label>{t("operator")}</Label>
                <Input type="select" id="operator" 
                onChange={(e)=>setOperator(e.target.value)}
                >
                <option value="" selected>{t("operator")}</option>
                {operator === "="?<option value="=" selected>=</option> : <option value="=">=</option>}
                {operator === ">="?<option value=">=" selected>{'>='}</option> : <option value=">=">{'>='}</option>}
                {operator === "<="?<option value="<=" selected>{'<='}</option> : <option value="<=">{'<='}</option>}
                </Input>
            </Col>
            <Col md={6}>
                    <Label>{t("excess")}</Label>
                    <Input type="number" defaultValue={pieces} id="number1"
                    onChange={(e)=>{
                        if(e.target.value > 0)
                            setPieces(e.target.value);
                        else{
                            document.getElementById("number2").value = Math.abs(e.target.value);
                            setPieces(Math.abs(e.target.value));
                        }
                    }}
                    placeholder={t("quantity")}
                    >
                    </Input>
            </Col>
            </Row> : "" 
        }
        {type==="Short"?
            <Row className="p-2">
            <Col md={6}>
                <Label>{t("operator")}</Label>
                <Input type="select" id="operator" 
                onChange={(e)=>setOperator(e.target.value)}
                >
                <option value="" selected>{t("operator")}</option>
                {operator === "="?<option value="=" selected>=</option> : <option value="=">=</option>}
                {operator === "<="?<option value="<=" selected>{'>='}</option> : <option value="<=">{'>='}</option>}
                {operator === ">="?<option value=">=" selected>{'<='}</option> : <option value=">=">{'<='}</option>}
                </Input>
            </Col>
            <Col md={6}>
                    <Label>{t("short")}</Label>
                    <Input type="number" defaultValue={pieces} id="number2" 
                    placeholder={t("quantity")}
                    onChange={(e)=>{
                        if(e.target.value > 0)
                            setPieces(e.target.value);
                        else{
                            document.getElementById("number2").value = Math.abs(e.target.value);
                            setPieces(Math.abs(e.target.value));
                        }
                    }}
                    min="0"
                    >
                    </Input>
            </Col>
            </Row> : ""
        }
        
        {/* <Row className="p-2">
            <Col md={12}>
                <Label>{t("personInCharge")}</Label>
                <Input type="select" defaultValue="0" name="pic" id="pic" onChange={(e)=>setPIC(e.target.value)}>
                <option value="0" selected disabled>{t("selectPIC")}</option>
                {PICs.map((pic)=>(
                PIC == pic.id? <option value={pic.id} selected>{pic.first_name+" "+pic.last_name}</option> : 
                <option value={pic.id}>{pic.first_name+" "+pic.last_name}</option>
                ))}
                </Input>
            </Col>
        </Row> */}
      </OffcanvasBody>
      <footer className="m-20 p-2">
        <Button className="btn secondaryBtn" onClick={ ()=>clearFunction() }>{t("clear")}</Button>
        <div className="f-right">
          <Btn attrBtn={{ color: "primary", onClick : ()=>submitFunction()  }}>
            {t("apply")}
          </Btn>
        </div>
      </footer>
    </Offcanvas>
  );
};

export default FilterOffCanvas;
/* Code By : Rithanesh */