import React, { Fragment, useState, useEffect } from 'react';
import {
    Col, CardBody, Card, Container, Row, CardHeader,
    Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { H4, H3, LI, P, UL } from '../AbstractElements';
import { ServerUrl } from '../Constant';
import dmslogtag from "../assets/images/dms/dms-log-with-tag.png";
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { getLanguage, getLoginCompanyId, getLoginUserId, getPlanStatus, getEmail } from "../Constant/LoginConstant";
import { apiencrypt, apidecrypt, decode } from "../helper";
import successfulPaymentStatus from '../../src/assets/images/dms/icons/successfulPaymentStatus.svg';
import failurePaymentStatus from '../../src/assets/images/dms/icons/failedPaymentStatus.svg';
import { useSearchParams } from "react-router-dom";

const SuccessfulPaymentStatus = ({ }) => {
    
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState((searchParams.get("type")));

    const { t, i18n } = useTranslation();
    const [langdropdown, setLangdropdown] = useState(false);
    const [selected, setSelected] = useState(i18n.language=='jp'?'jp':'en');
    const [timer, setTimer] = useState(5);

    /**************** SET LANGUAGE *******************/
    const changeLanguage = lng => {
        if( lng === 'EN'){
        i18n.changeLanguage('en');
        setSelected('en');
        }
        if( lng === 'JP'){
        i18n.changeLanguage('jp');
        setSelected('jp');
        }
        if( lng === 'BD'){
        i18n.changeLanguage('bd');
        setSelected('bd');
        }
        if( lng === 'IN'){
        i18n.changeLanguage('in');
        setSelected('in');
        }
    };

    const flagSelect = (lng) =>{
        if( lng === 'en'){
          return 'us'
        }
        if( lng === 'jp'){
          return 'jp'
        }
        if( lng === 'bd'){
          return 'bd'
        }
       if( lng === 'in'){
          return 'in'
        }
    };
  
    /**************** UPDATE LANGUAGE  *******************/
    const LanguageSelection = (language) => {
        if (language) {
            setLangdropdown(!language);
        } else {
            setLangdropdown(!language);
        }
    };

    /**************** API (GET) [To show the values in the card ]******/
    useEffect(() => {
        

        if (getLanguage) {
            if (getLanguage === 'en') {
                i18n.changeLanguage('en');
                setSelected('EN');
            }
            if (getLanguage === 'jp'){
                i18n.changeLanguage('jp');
                setSelected('JP');
            } 
            if( getLanguage === 'bd'){
                i18n.changeLanguage('bd');
                setSelected('bd');
                }
            if( getLanguage === 'in'){
            i18n.changeLanguage('in');
            setSelected('in');
            }
        }
        else {
            i18n.changeLanguage(i18n.language=='jp' ? 'jp' : i18n.language=='bd' ? 'bd' :i18n.language=='in'?'in':'en');
            setSelected(i18n.language=='jp' ? 'JP' : i18n.language=='bd' ? 'BD' :i18n.language=='in'?'IN':'EN');
        }


        const countdown = setInterval(() => {
            setTimer(prevTime => prevTime - 1);
          }, 1000)

          const timeout = setTimeout(() => {
            apiCallSuccessPlanPayment();
            clearInterval(countdown); // stop the countdown when timeout occurs
          }, 5000);

          return () => {
            clearInterval(countdown); // cleanup function to clear the countdown interval
            clearTimeout(timeout); // cleanup function to clear the timeout
          };
    }, [getLanguage]);

    const apiCallSuccessPlanPayment = () =>
    {
        if( paymentStatus == "sling" ){
            var apiCallEditParams = {};
            apiCallEditParams['user_email'] = getEmail;
            apiCallEditParams['type'] = 'firstTime';
            // apiCallEditParams['company_id'] = getLoginCompanyId;
            axios
            .post(ServerUrl + "/confirm-plan-payment", apiencrypt(apiCallEditParams))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code === 200) {
                    window.location.href= '/adminlogin'
                }
            });  
        } else {
            var apiCallEditParams = {};
            apiCallEditParams['user_id'] = getLoginUserId;
            apiCallEditParams['type'] = 'updation';
            apiCallEditParams['company_id'] = getLoginCompanyId;
            axios
            .post(ServerUrl + "/confirm-plan-payment", apiencrypt(apiCallEditParams))
            .then((response) => {
                response.data = apidecrypt(response.data)
                if (response.data.status_code === 200) {
                    window.location.href= '/dashboard';
                }
            }); 
        }
    };

    /*********** HTML (NAVBAR, CARDHEADER, CARD BODY etc)  ************/
    return (
        <>   
            <Card>
            <CardHeader className="pb-0">
                <Row>
                    <Col >
                        <img src={dmslogtag} width="150" />
                    </Col>
                    <Col>
                        <Row className="m-r-50 f-right " style={{ backgroundColor: '#f2f2f2' }}>
                                                <LI attrLI={{ className: 'onhover-dropdown m-l-10' }}>
                                                        <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                                                            <div className="current_lang">
                                                                <div className="lang d-flex" onClick={() => LanguageSelection(langdropdown)}>
                                                                <i className={`flag-icon flag-icon-${flagSelect((i18n.language.toLowerCase()))}`}></i> 
                                                                <span className="lang-txt m-l-10">{selected.toUpperCase()}</span>
                                                                    <i style={{ marginTop: '3px'}} className="fa fa-chevron-down m-l-10"></i>
                                                                </div>
                                                            </div>
                                                            <div className={`more_lang onhover-show-div ${langdropdown ? 'active' : ''}`}>
                                                            <div className="lang" onClick={() => {changeLanguage('EN')}}>
                                                                <i className="flag-icon flag-icon-us"></i>
                                                                <span className="lang-txt m-l-10">English</span>
                                                            </div>
                                                            <div className="lang" onClick={() => {changeLanguage('JP')}}>
                                                                <i className="flag-icon flag-icon-jp"></i>
                                                                <span className="lang-txt m-l-10">Japanese</span>
                                                            </div>
                                                            {/* <div className="lang" onClick={() => {changeLanguage('BD')}}>
                                                                <i className="flag-icon flag-icon-bd"></i>
                                                                <span className="lang-txt m-l-10">Bengali</span>
                                                            </div>
                                                            <div className="lang" onClick={() => {changeLanguage('IN')}}>
                                                                <i className="flag-icon flag-icon-in"></i>
                                                                <span className="lang-txt m-l-10">Hindi</span>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                    </LI>
                        </Row>
                    </Col>
                </Row>
            </CardHeader>
            </Card>

            <Col sm="6" md="6" lg="6" xl="6" xxl="6" 
                 className="6 offset-sm-3">
                <Card>
                    <CardBody className="grid-showcase grid-align">
                        <Row className="align-items-center">
                        <div>
                            <div className="text-center">
                            <img  src={successfulPaymentStatus} />
                            </div>
                            <H4> <span style={{ fontSize: '25px', color: '#47CBAA' }}> {t("paymentSuccessful")} </span></H4>
                            <span style={{ fontSize: '20px', color: '#242934' }}> {t("thankYou")}  </span>
                            <span style={{ fontSize: '15px', color: '#242934' }}>  {t("paymentProcessSuccess")}</span>
                            <Col className="text-center">
                            <button  
                                style={{ backgroundColor: '#47CBAA', borderRadius: '25px', color: '#fff'}} 
                                className="btn" 
                                onClick={() => {
                                    apiCallSuccessPlanPayment()
                                }}
                                > {t("continue")} </button>
                            </Col>
                            <div className='m-t-5'>
                            <Col className="text-center">
                            <P style={{ fontSize: '15px',color: '#242934'}}>{t("clickContinue")} {timer} {t("seconds")}</P>
                            </Col>
                            </div>                           
                        </div>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default SuccessfulPaymentStatus;
/*****************************************************************************************************/
///////////////////////////////////////////// CODE BY : " RAMANAN M" /////////////////////////////////
/*****************************************************************************************************/