import React, { Fragment, useEffect } from 'react';
import Knob from 'knob';
import {
    Card, CardBody, Col, Row, Table, Collapse,
    FormGroup, InputGroup, Input, Label, Progress, CardFooter
} from 'reactstrap';
import Moment from 'moment';
import SewIcon from "../../../assets/images/dms/icons/sewIcon.svg";
import { PHPtoJSFormatConversion } from '../../../helper';
import { useTranslation } from 'react-i18next';

const SewKnobChartrequired = ({sewActualTargetValue,sewTodayUpdatedValue,sewEndDate,sewPerDay}) => {
    const { t, i18n } = useTranslation();
    let sewEstimatedRatePercentage = Math.round((sewTodayUpdatedValue/sewActualTargetValue)*100);
    let currentDate = Moment().format('YYYY-MM-DD');
    // let endDate=Moment(sewEndDate).format('DD MMM YYYY');
   /******************** KNOB CHART COLOR (BASED ON PERCENTAGE ) ******************************/
    const knobChartColor = () => {
        if((new Date(sewEndDate).getTime() >= new Date(currentDate).getTime())){
            return '#00B38E'; 
        }
        // else if((sewActualTargetValue >= sewPerDay)){
        //     return '#00B38E'; 
        // } 
        else{
            
            return '#FF3169';
        }
        // if(  (sewEstimatedRatePercentage >= 0) && (sewEstimatedRatePercentage <= 40)){
        //     return '#FF3169';
        // }
        // if( (sewEstimatedRatePercentage > 40) && (sewEstimatedRatePercentage <= 70)){
        //     return '#E58F1F';
        // }
        // if( (sewEstimatedRatePercentage > 70) && (sewEstimatedRatePercentage <= 100) || (sewEstimatedRatePercentage > 100)){
        //     return '#00B38E';
        // }
    }
    useEffect(() => {
        var offsetArc = Knob({
            value: sewPerDay?sewPerDay:0,
            angleOffset: -125,
            angleArc: 250,
            thickness: 0.2,
            cursor: false,
            width: 100,
            height: 80,
            fgColor: knobChartColor(),
            readOnly: true,
            bgColor: '#f6f7fb',
            lineCap: 'round',
            displayPrevious: false,
            min: 0,
            max: sewPerDay
        });
        const sewlist = document.getElementById('sewknobrequir');
        if (sewlist.hasChildNodes()) {
            sewlist.removeChild(sewlist.children[0]);
        }
        document.getElementById('sewknobrequir').appendChild(offsetArc);
    }, [sewPerDay])

    return (
        <>
            <Card className="knob-block text-center TaskStatusCard shadow shadow-showcase knobchart-align">
                <div className="knob text-center m-t-5" id="sewknobrequir">
                </div>
                <div className=' text-muted f-12' style={{ color: "#828282",marginTop:"2px" }}>{t("reqAvgQty")}</div><hr style={{marginTop:"2px",marginBottom:"2px"}}></hr>
                <div className=' f-w-600 ' >{(PHPtoJSFormatConversion.format(new Date(sewEndDate)))}</div>
                <div className=' text-muted f-12' style={{ color: "#828282" }}>{t("scheduleComDt")}</div>
                {/* <div style={{ backgroundColor: "#5159AC" }} className='taskCardBorder'>
                    <img src={SewIcon} style={{ width: "20px", height: "20px" }} className='m-t-10 m-b-10' /><span style={{ color: "#FFFFFF", paddingLeft: "6px" }}>Sewing</span>
                </div> */}
            </Card>
        </>
    );
}
export default SewKnobChartrequired;